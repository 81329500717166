import { AppInputField } from "components/FormFields/AppInputField";
import { isOutsideRef } from "globals/helpers/generalHelper";
import {
    jsLocalDateTimeFromMoment,
    momentFromJSLocalDateTime,
} from "globals/helpers/localizationHelpers";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import $ from "jquery";
import { defaultTo, isNil } from "lodash-es";
import {
    DatePickerType,
    getDatePickerFormat,
    getDatePickerLocale,
} from "models/datepicker";
import { ErrorInfo, Optional } from "models/general";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import moment, { Moment } from "moment-timezone";
import React, {
    KeyboardEvent,
    MouseEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Image } from "react-bootstrap";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Portal } from "react-overlays";
import "./DatePicker.scss";
import { TypedReactDatePicker } from "./TypedReactDatePicker";

export interface AppReactDatePickerBaseProps
    extends Omit<
        ReactDatePickerProps,
        | "onChange"
        | "value"
        | "locale"
        | "minDate"
        | "maxDate"
        | "startDate"
        | "endDate"
    > {
    datePickerType: DatePickerType;
    value: Optional<Moment>;
    minDate?: Optional<Moment>;
    maxDate?: Optional<Moment>;
    startDate?: Optional<Moment>;
    endDate?: Optional<Moment>;
    onChange: (value: Optional<Moment>) => void;
    onRangeChange?: (val: StartAndEndDates) => void;
    label?: string;
    placeholder?: string;
    useDefault?: boolean;
    showLabel?: boolean;
    error?: ErrorInfo;
    appendToBody?: boolean;
    displayFormat?: string;
    minValue?: Moment;
    defaultValue?: Moment;
    toggleRef?: any; // in case toggle is outside (to exclude from outside click)
    className?: string;
    showClearButton?: boolean;
    showCalenderIcon?: boolean;
    forMonthStart?: boolean;
    forMonthEnd?: boolean;
    showEmptyError?: boolean;
    setOpen?: (val: boolean) => void;
    iconClass?: {
        icon?: string;
        field?: string;
        iconImage?: string;
    };
}

export const Component: React.FC<AppReactDatePickerBaseProps> = (props) => {
    const { appLocale, getDateFormatForLocale } = useLocaleHelpers();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const rootRef = useRef<any>(undefined);
    const { t } = useTranslation();
    const {
        datePickerType,
        value,
        onChange,
        onRangeChange,
        minDate,
        maxDate,
        defaultValue,
        showPopperArrow = false,
        toggleRef,
        appendToBody = false,
        forMonthStart = false,
        forMonthEnd = false,
        readOnly = false,
        label = t("common.date.name"),
        placeholder = t("common.date.name"),
        showLabel = true,
        useDefault = true,
        displayFormat: format = getDateFormatForLocale(),
        error,
        showCalenderIcon = true,
        className,
        showClearButton = false,
        iconClass,
        customInput,
        open,
        startDate,
        endDate,
        setOpen,
        yearDropdownItemNumber = 5,
        scrollableYearDropdown = true,
        preventOpenOnFocus = false,
        shouldCloseOnSelect = true,
        onFocus,
        onBlur,
        showEmptyError,
        ...rest
    } = props;

    const updateOpen = (val: boolean) => {
        if (setOpen) {
            setOpen(val);
        } else {
            setIsOpen(val);
        }
    };

    const openState = isNil(open) ? isOpen : open;

    const start = useMemo(() => {
        return jsLocalDateTimeFromMoment(startDate);
    }, [startDate?.toISOString()]);
    const end = useMemo(() => {
        return jsLocalDateTimeFromMoment(endDate);
    }, [endDate?.toISOString()]);
    const min = useMemo(() => {
        return jsLocalDateTimeFromMoment(minDate);
    }, [minDate?.toISOString()]);
    const max = useMemo(() => {
        return jsLocalDateTimeFromMoment(maxDate);
    }, [maxDate?.toISOString()]);

    const pickerContent = (
        <TypedReactDatePicker
            {...rest}
            popperContainer={
                appendToBody
                    ? ({ children }: any) => {
                          return (
                              <Portal container={document.body}>
                                  <>{children}</>
                              </Portal>
                          );
                      }
                    : rest.popperContainer
            }
            selected={jsLocalDateTimeFromMoment(value)}
            onChange={(val: Date | null | Date[]) => {
                if (!isNil(val)) {
                    if (val instanceof Date) {
                        const newVal = momentFromJSLocalDateTime(val as Date);
                        if (forMonthStart) {
                            onChange(newVal.startOf("month"));
                        } else if (forMonthEnd) {
                            onChange(newVal.endOf("month"));
                        } else {
                            onChange(newVal);
                        }
                    } else if (
                        rest.selectsRange &&
                        onRangeChange &&
                        val instanceof Array
                    ) {
                        // on range change
                        const startD = momentFromJSLocalDateTime(val[0]);
                        const endD =
                            val.length > 1 && val[1]
                                ? momentFromJSLocalDateTime(val[1])
                                : undefined;
                        const monthTypes = [
                            DatePickerType.MONTH,
                            DatePickerType.MONTH_YEAR,
                            DatePickerType.MONTH_YEAR_SHORT,
                        ];
                        if (monthTypes.includes(datePickerType)) {
                            onRangeChange({
                                StartDate: startD.startOf("month"),
                                EndDate: endD ? endD.endOf("month") : undefined,
                            });
                        } else {
                            onRangeChange({
                                StartDate: startD,
                                EndDate: endD,
                            });
                        }
                        if (shouldCloseOnSelect) {
                            updateOpen(false);
                        }
                    }
                } else {
                    onChange(undefined);
                }
                if (shouldCloseOnSelect && !rest.selectsRange) {
                    updateOpen(false);
                }
            }}
            timeCaption={t("common.time")}
            popperProps={defaultTo(rest.popperProps, {
                positionFixed: true, // use this to make the popper position: fixed
            })}
            minDate={min}
            maxDate={max}
            startDate={start}
            endDate={end}
            yearDropdownItemNumber={yearDropdownItemNumber}
            scrollableYearDropdown={scrollableYearDropdown}
            onClickOutside={(e: MouseEvent) => {
                if (
                    e?.target &&
                    $(e?.target).find(".react-datepicker-popper").length == 0 &&
                    $(e?.target).parents(".react-datepicker-popper").length ==
                        0 &&
                    isOutsideRef(rootRef, e.target) &&
                    isOutsideRef(toggleRef, e.target)
                ) {
                    updateOpen(false);
                }
            }}
            locale={getDatePickerLocale(appLocale)}
            dateFormat={getDatePickerFormat(datePickerType, appLocale)}
            showPopperArrow={showPopperArrow}
            preventOpenOnFocus={preventOpenOnFocus}
            open={openState}
            onFocus={defaultTo(onFocus, () => !readOnly && updateOpen(true))}
            onKeyDown={(e: KeyboardEvent) => {
                if (e.key === "Tab") {
                    updateOpen(false);
                }
            }}
            onBlur={defaultTo(onBlur, () => updateOpen(false))}
            customInput={
                !customInput ? (
                    <AppInputField
                        label={showLabel ? label : undefined}
                        value={
                            useDefault || value
                                ? defaultTo(
                                      value,
                                      defaultTo(defaultValue, moment())
                                  ).format(format)
                                : ""
                        }
                        placeholder={placeholder}
                        ignoreKeyPress={true}
                        onValueChange={() => onChange(undefined)}
                        readOnly={readOnly}
                        error={error}
                        className={className}
                        classes={iconClass}
                        showEmptyError={showEmptyError}
                        rightIcon={
                            showCalenderIcon ? (
                                <Image
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        top: "11px",
                                    }}
                                    src={
                                        datePickerType == DatePickerType.TIME
                                            ? ImageAssets.common.clock
                                            : ImageAssets.common.calendar
                                    }
                                    className={defaultTo(
                                        iconClass?.iconImage,
                                        ""
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!readOnly) {
                                            updateOpen(!openState);
                                        }
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                        showClearButton={showClearButton}
                    />
                ) : (
                    customInput
                )
            }
            readOnly={readOnly}
            shouldCloseOnSelect={shouldCloseOnSelect}
        />
    );
    return rest.inline ? (
        pickerContent
    ) : (
        <label className="w-100" style={{ margin: "0" }} ref={rootRef}>
            {pickerContent}
        </label>
    );
};

export const AppReactDatePickerBase = React.memo(Component);
export default AppReactDatePickerBase;
