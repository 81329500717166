import CareScopeTemplateCreateEdit from "commonPartials/careScopeTemplate/CareScopeTemplateCreateEdit";
import {
    CLONE_PARAM_KEY,
    FOLLOWUP_PARAM_KEY,
    TEMPLATE_ID_PARAM_KEY,
} from "globals/queryParams";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessType,
    Business_CareScopes,
    LW_Kindergarten_CareScopes,
} from "models";
import React from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";

export const BusinessCareScopeTemplateCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const { getPermissionMap } = useCheckPermission();
    const [searchParams] = useSearchParams();

    const parentId = searchParams.get(TEMPLATE_ID_PARAM_KEY);
    const isClone = searchParams.get(CLONE_PARAM_KEY) == "true";
    const isFollowup = searchParams.get(FOLLOWUP_PARAM_KEY) == "true";
    // const locationTemplateId = searchParams.get(LOCATION_TEMPLATE_ID_PARAM_KEY);

    const currentBusiness = useCurrentBusinessSettingsContext();

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = (
        isLwc
            ? lwcBase.kindergartens.withId()
            : linkProvider.business.businessSettings().screens
    ).careScopes;

    const permMap = getPermissionMap([
        isLwc ? LW_Kindergarten_CareScopes : Business_CareScopes,
    ]);
    const useLocationData = defaultTo(
        currentBusiness.dataItem?.LocationPreferences?.ScopeOfCare,
        true
    );

    return (
        <CareScopeTemplateCreateEdit
            id={parentId}
            currentBusiness={currentBusiness.dataItem}
            locationId={currentBusiness.dataItem?.LocationSettingId}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
            createOptions={{ clone: isClone, followup: isFollowup }}
            useLocationData={useLocationData}
        />
    );
};

export default BusinessCareScopeTemplateCreate;
