import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import { MergeCompanySelection } from "./model";

export function validateMergeCompany(
    company: MergeCompanySelection,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("mergeCompanies.sourceCompany.missing"),
            },
        ],
        company.source && company.source.Value,
        "source"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("mergeCompanies.targetHeadquarter.missing"),
            },
        ],
        company.target && company.target.Value,
        "target"
    );

    return state;
}
