// import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "globals/eventEmitter";
import { registerNumeralLocales } from "globals/helpers/localizationHelpers";
import { initSentry } from "globals/sentry";
import "numeral/locales";
import React from "react";
import ReactDOM from "react-dom";
import "theme/global.scss";
import App from "./App";

initSentry();

registerNumeralLocales();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
