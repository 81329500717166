import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    PermissionAccessMap,
    UserRoleManagementItem,
    UserSortColumn,
} from "models";
import { TableSort } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import UserManagementTableRow from "screens/business/userManagement/partials/UserManagementTableRow";
import styles from "./UserManagementTable.module.scss";

export interface UserManagementTableProps {
    users: UserRoleManagementItem[];
    onActiveStateChange: (
        user: UserRoleManagementItem,
        checked: boolean
    ) => void;
    onEditClick: (userId: string) => void;
    loading: boolean;
    sort: TableSort<UserSortColumn>;
    removeItem: (Id: string) => void;
    onSortChange: (sort: TableSort<UserSortColumn>) => void;
    accessInfo: PermissionAccessMap;
}

const COLUMNS_COUNT = 7;
export const UserManagementTable: React.FC<UserManagementTableProps> = ({
    users,
    onActiveStateChange,
    onEditClick,
    loading,
    onSortChange,
    removeItem,
    sort,
    accessInfo: hasPermission,
}) => {
    const { t } = useTranslation();
    return (
        <AppTable
            id={"scrollableDiv"}
            tableClass={"table-striped"}
            heightToAdjust={250}
            mediumViewAdjustment={100}
            mobileViewAdjustment={100}
        >
            <thead>
                <tr>
                    <AppTableSortColumnIcons
                        text={t("roleManagement.user.name")}
                        sortColumn={UserSortColumn.Name}
                        onClick={(key, order) =>
                            onSortChange({
                                SortColumn: key,
                                SortOrder: order,
                            })
                        }
                        style={getFixedCssWidths(200, false)}
                        sortOrder={
                            sort.SortColumn === UserSortColumn.Name
                                ? sort.SortOrder
                                : null
                        }
                    />

                    <th style={getFixedCssWidths(200)}>
                        {t("roleManagement.user.permissionGroup.name")}
                    </th>
                    <th style={getFixedCssWidths(280)}>
                        {t("roleManagement.user.email.name")}
                    </th>
                    <th style={getFixedCssWidths(370)}>
                        {t("roleManagement.user.description")}
                    </th>
                    <AppTableSortColumnIcons
                        text={t("roleManagement.user.active")}
                        sortColumn={UserSortColumn.IsActive}
                        onClick={(key, order) =>
                            onSortChange({
                                SortColumn: key,
                                SortOrder: order,
                            })
                        }
                        sortOrder={
                            sort.SortColumn == UserSortColumn.IsActive
                                ? sort.SortOrder
                                : null
                        }
                        className={styles.isActiveTh}
                    />

                    <th style={{ width: "60px", minWidth: "60px" }} />
                    <th
                        style={{
                            width: "70.5px",
                            minWidth: "70.5px",
                        }}
                    />
                </tr>
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>

                {users &&
                    users.length > 0 &&
                    users.map((x) => (
                        <UserManagementTableRow
                            onEditClick={onEditClick}
                            removeItem={removeItem}
                            key={x.Id as string}
                            value={x}
                            onActiveStateChange={onActiveStateChange}
                            accessMap={hasPermission}
                        />
                    ))}

                {loading && (
                    <AppTableSkeleton
                        rows={users && users.length > 0 ? 2 : 12}
                        columns={COLUMNS_COUNT}
                    />
                )}

                {users && users.length == 0 && !loading && (
                    <tr className="dummy-row">
                        <td
                            colSpan={COLUMNS_COUNT}
                            style={{ textAlign: "center" }}
                        >
                            {t("roleManagement.user.userListEmpty")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default UserManagementTable;
