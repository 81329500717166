import { ValidityState } from "models/general";
import {
    CompensationTemplateField,
    CompensationTemplateType,
    LillywaitCompensationTemplateField,
    LWClientCompensationTemplateField,
} from "models/compensationTemplate";
import React from "react";
import { LwCompensationTemplateFieldsContainer } from "./LwTemplateFields";
import { LWClientCompensationTemplateFieldsContainer } from "./LWClientFields";
import { StartAndEndDates } from "models/StartAndEndDateFields";

export interface CompensationTemplateFieldsContainerProps {
    fields: CompensationTemplateField[];
    templateType: CompensationTemplateType;
    onChange: (
        newFields: CompensationTemplateField[],
        canEdit?: boolean
    ) => void;
    readonly?: boolean;
    validityState?: ValidityState;
    canEditValue?: boolean;
    isForLocation: boolean;
    templateDates: StartAndEndDates;
    locationSettingId?: number;
    businessId: string;
}

export const CompensationTemplateFieldsContainer: React.FC<
    CompensationTemplateFieldsContainerProps
> = ({
    templateType,
    fields,
    onChange,
    readonly = false,
    validityState,
    templateDates,
    canEditValue = false,
    locationSettingId,
    isForLocation,
    businessId,
}) => {
    return templateType == CompensationTemplateType.LillywaitCompensation ? (
        <LwCompensationTemplateFieldsContainer
            fields={fields as LillywaitCompensationTemplateField[]}
            validityState={validityState}
            onChange={(newFields: LillywaitCompensationTemplateField[]) =>
                onChange(newFields)
            }
            readonly={readonly}
            locationSettingId={locationSettingId}
            businessId={businessId}
        />
    ) : (
        <LWClientCompensationTemplateFieldsContainer
            fields={fields as LWClientCompensationTemplateField[]}
            validityState={validityState}
            templateType={templateType}
            onChange={(
                newFields: LWClientCompensationTemplateField[],
                canEdit
            ) => onChange(newFields, canEdit)}
            templateDates={templateDates}
            isForLocation={isForLocation}
            canEditValue={canEditValue}
            readonly={readonly}
            locationSettingId={locationSettingId}
            businessId={businessId}
        />
    );
};

export default CompensationTemplateFieldsContainer;
