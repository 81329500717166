import { AppColoredMultiSelect } from "components/AppSelect";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppInputField, AppSwitch } from "components/FormFields";
import { useRouting } from "hooks/general/routing";
import {
    DocumentArchive,
    getDocumentArchive,
    getInitialDocumentArchiveValues,
    validateDocumentArchive,
} from "models/archive";
import { DocumentType } from "models/documentTemplates";
import { CustomColorItemOption, ValidityStateManager } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    DocumentArchiveService,
    getDocumentArchiveServiceKey,
} from "services/business";
import styles from "./DocumentArchiveCreateEditDialog.module.scss";

export interface DocumentArchiveCreateEditDialogProps {
    modalOpen: boolean;
    onClose: () => void;
    loading: boolean;
    title: string;
    onSubmit: (document: DocumentArchive) => void;
    updateArchiveId?: number;
}
export const DocumentArchiveCreateEditDialog: React.FC<
    DocumentArchiveCreateEditDialogProps
> = ({ loading, modalOpen, onClose, title, onSubmit, updateArchiveId }) => {
    const { t } = useTranslation();
    const [state, setState] = useState<DocumentArchive>(
        getInitialDocumentArchiveValues()
    );

    const { linkProvider } = useRouting();

    const documentArchiveService = new DocumentArchiveService(
        linkProvider.business.api.currentBusiness().documentArchive
    );

    const { isLoading: loadingGet, data: getResponse } = useQuery(
        getDocumentArchiveServiceKey("getDocumentArchive", {
            archiveId: updateArchiveId,
        }),
        async () => {
            if (updateArchiveId) {
                return await documentArchiveService.getDocumentArchive(
                    updateArchiveId
                );
            }
        }
    );

    const validityStateManager = useMemo(() => {
        const validationState = validateDocumentArchive(state);
        return new ValidityStateManager(validationState);
    }, [state]);
    const multiSelectOptions: CustomColorItemOption[] = [
        {
            label: t(
                `templateDocumentManager.documentType.${DocumentType.Employee.toLowerCase()}`
            ),
            value: DocumentType.Employee.toString(),
        },
        {
            label: t(
                `templateDocumentManager.documentType.${DocumentType.Client.toLowerCase()}`
            ),
            value: DocumentType.Client.toString(),
        },
    ];
    useEffect(() => {
        if (!loadingGet && getResponse?.Data) {
            setState(getDocumentArchive(getResponse.Data));
        }
    }, [loadingGet, getResponse]);
    return (
        <AppDialog
            title={title}
            onClose={onClose}
            modalOpen={modalOpen}
            footer={
                <AppDialogFooter
                    onDialogClose={onClose}
                    disableSaveButton={
                        !validityStateManager.isStateValid() || loading
                    }
                    onClickSaveButton={() => onSubmit(state)}
                />
            }
        >
            <Row>
                <Col xs={12} className="mt-2">
                    <AppInputField
                        label={t("documentArchive.name.name")}
                        placeholder={t("documentArchive.name.placeholder")}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                        value={state.Name}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                Name: val,
                            })
                        }
                    />
                </Col>
                <Col xs={12}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className={styles.label}>
                            {t("documentArchive.area")}
                        </Form.Label>
                        <AppColoredMultiSelect
                            value={multiSelectOptions.filter(
                                (x) =>
                                    x.value != null &&
                                    state.CreationArea.includes(
                                        x.value as DocumentType
                                    )
                            )}
                            useOldUi={true}
                            onChange={(value) => {
                                setState({
                                    ...state,
                                    CreationArea: value
                                        .filter((x) => x.value != null)
                                        .map(
                                            (x) =>
                                                DocumentType[
                                                    x.value as DocumentType
                                                ]
                                        ),
                                });
                            }}
                            loading={loading || loadingGet}
                            data={multiSelectOptions}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} className="mt-3">
                    <AppSwitch
                        label={t("documentArchive.allowMultiple")}
                        onChange={(checked) =>
                            setState({ ...state, AllowMultiple: checked })
                        }
                        disabled={state.Documents && state.Documents.length > 1}
                        value={state.AllowMultiple}
                        className={styles.label}
                    />
                </Col>
            </Row>
        </AppDialog>
    );
};

export default DocumentArchiveCreateEditDialog;
