import { defaultTo } from "lodash-es";
import { AppSwitch } from "components";
import { useTranslation } from "react-i18next";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { PermissionAccessMap } from "models";
import { Col, Row } from "react-bootstrap";
import { AppButton } from "components/Buttons";
import styles from "./TimeTrackingFlagsPartial.module.scss";
import classNames from "classnames";

export interface TimeTrackingFlags {
    web: boolean;
    mobile: boolean;
    iPad: boolean;
}
export interface TimeTrackingFlagsPartialProp {
    value: TimeTrackingFlags;
    onChange: (val: TimeTrackingFlags) => void;
    onSave: () => void;
    className?: string;
    disabled?: boolean;
    permMap: PermissionAccessMap;
}
export const TimeTrackingFlagsPartial: React.FC<
    TimeTrackingFlagsPartialProp
> = ({ permMap, value, onChange, onSave, className, disabled = false }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(className, styles.root)}>
            <span>{t("business.timeTrackingSettings.text")}</span>
            <Row>
                <Col sm={4}>
                    <AppSwitch
                        label={t("business.timeTrackingSettings.viaWeb")}
                        disabled={!permMap.EDIT || disabled}
                        className={styles.switch}
                        value={defaultTo(value.web, false)}
                        onChange={(checked) => {
                            onChange({
                                ...value,
                                web: checked,
                            });
                        }}
                    />
                </Col>
                <Col sm={4}>
                    <AppSwitch
                        label={t("business.timeTrackingSettings.viaMobile")}
                        disabled={!permMap.EDIT || disabled}
                        className={styles.switch}
                        value={defaultTo(value.mobile, false)}
                        onChange={(checked) => {
                            onChange({
                                ...value,
                                mobile: checked,
                            });
                        }}
                    />
                </Col>
                <Col sm={4}>
                    <AppSwitch
                        label={t("business.timeTrackingSettings.viaIpad")}
                        disabled={!permMap.EDIT || disabled}
                        className={styles.switch}
                        value={defaultTo(value.iPad, false)}
                        onChange={(checked) => {
                            onChange({
                                ...value,
                                iPad: checked,
                            });
                        }}
                    />
                </Col>
            </Row>
            {/* <div style={{ marginTop: "10px" }}>
                    <AppButton
                        padding="med"
                        className={styles.btn}
                        disabled={disabled || !permMap.EDIT}
                        onClick={() => onSave()}
                    >
                        {t("common.save")}
                    </AppButton>
                </div> */}
        </div>
    );
};

export default TimeTrackingFlagsPartial;
