import { EventEmitter } from "events";

export enum AppEmitterEvents {
    ACCESS_DENIED = "AccessDenied",
    NOT_FOUND = "NotFound",
    SESSION_TIMEOUT = "SessionTimeout",
}

export const eventEmitter = new EventEmitter();

export default eventEmitter;
