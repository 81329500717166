import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import { Role } from "models/roleManagement/model";

export function validateRole(
    role: Role,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("roleManagement.role.name.missing"),
            },
        ],
        role.Name,
        "Name"
    );
    return state;
}
