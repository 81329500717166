import classNames from "classnames";
import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { AppTable, AppTableSkeleton } from "components/Table";
import {
    getFixedCssWidths,
    getMillisecondsForMinutes,
} from "globals/helpers/generalHelper";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    BusinessHQ,
    Business_BasicData,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import {
    BusinessService,
    getBusinessServiceKey,
} from "services/business/BusinessService";
import styles from "./BusinessHQAndBranches.module.scss";
import { AdminBusinessBranchRow } from "./partials";

const COLUMNS_COUNT = 8;
export const BusinessHQAndBranches: React.FC = () => {
    const { linkProvider } = useRouting();
    const businessService = new BusinessService(linkProvider.business.api);
    const { t } = useTranslation();
    const { checkPermission: sessionCheckPermission } = useCheckPermission();
    const { getPermissionMap } = useBusinessCheckPermission();
    const [data, setData] = useState<BusinessHQ[] | undefined>(undefined);
    const { user } = useSessionUser();
    const navigate = useNavigate();

    const { isLoading, data: response } = useQuery(
        getBusinessServiceKey("getAdminBusinessesList", {
            userId: user?.Id,
        }),
        async () => await businessService.getAdminBusinessesList(),
        { cacheTime: getMillisecondsForMinutes(10) }
    );
    useEffect(() => {
        if (!isLoading && response && response.Data) {
            setData(response.Data);
        }
    }, [isLoading, response]);

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("common.administration")}
                classNameForIcon="lnr-apartment"
            >
                {sessionCheckPermission(
                    [Business_BasicData],
                    [PermissionAccessTypes.CREATE]
                ) && (
                    <AppRoundedTextIconButton
                        onClick={() =>
                            navigate(
                                linkProvider.business.screens.admin.branchCreate()
                            )
                        }
                    />
                )}
            </AppContentHeader>
            <AppTable
                id={"scrollableDiv"}
                containerClass={`${styles.tableOuter}`}
                hover={true}
                stickyHeader={true}
                heightToAdjust={190}
            >
                <thead>
                    <tr>
                        <th
                            // for toggle icon
                            style={getFixedCssWidths(50)}
                        />
                        <th style={getFixedCssWidths(250)}>
                            {t("business.branches.facilityName")}
                        </th>
                        <th style={getFixedCssWidths(200)}>
                            {t("business.branches.street")}
                        </th>
                        <th style={getFixedCssWidths(150)}>
                            {t("business.branches.streetNumber")}
                        </th>
                        <th style={getFixedCssWidths(150, false)}>
                            {t("business.branches.zipCode")}
                        </th>
                        <th style={getFixedCssWidths(150, false)}>
                            {t("business.branches.city")}
                        </th>
                        <th style={getFixedCssWidths(150)} />
                        <th style={getFixedCssWidths(50)} />
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>

                    {data &&
                        data.length > 0 &&
                        data.map((x, idx) => {
                            const permMap = getPermissionMap(
                                x.BusinessId,
                                x.Id,
                                Business_BasicData
                            );

                            return (
                                <React.Fragment key={idx}>
                                    {idx > 0 && (
                                        <tr
                                            key={`${idx}_${x.EncodedId}`}
                                            className={classNames(
                                                styles.emptyRow,
                                                "dummy-row"
                                            )}
                                        >
                                            <td colSpan={COLUMNS_COUNT} />
                                        </tr>
                                    )}
                                    <AdminBusinessBranchRow
                                        key={x.EncodedId}
                                        value={x}
                                        canDelete={checkPermissionInMap(
                                            permMap,
                                            [PermissionAccessTypes.DELETE]
                                        )}
                                        onDelete={(
                                            hqId: number,
                                            businessId?: number
                                        ) => {
                                            if (isNil(businessId)) {
                                                // deletedHq (not possible yet)
                                                setData((old) =>
                                                    old?.filter(
                                                        (x) => x.Id !== hqId
                                                    )
                                                );
                                            } else {
                                                setData((old) =>
                                                    old?.map((x) => {
                                                        if (x.Id === hqId) {
                                                            // filter deleted business
                                                            x.Branches =
                                                                x.Branches?.filter(
                                                                    (y) =>
                                                                        y.Id !==
                                                                        businessId
                                                                );
                                                        }
                                                        return x;
                                                    })
                                                );
                                            }
                                        }}
                                        onClick={
                                            // checkPermissionInMap(permMap, [
                                            //     PermissionAccessTypes.SHOW,
                                            // ])
                                            //     ?
                                            (businessId: string) =>
                                                navigate(
                                                    linkProvider.business
                                                        .businessSettings(
                                                            x.HqInfo.Type,
                                                            businessId
                                                        )
                                                        .screens.basicData()
                                                )
                                            // : undefined
                                        }
                                    />
                                </React.Fragment>
                            );
                        })}
                    {(isLoading || !data) && (
                        <AppTableSkeleton columns={COLUMNS_COUNT} rows={4} />
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default BusinessHQAndBranches;
