import { SearchField } from "components/FormFields";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import { Optional, SelectItem } from "models/general";
import {
    AccessPointAction,
    ActiveEmployeeData,
    getAccessPointActionString,
    TimeManagementTotals,
} from "models/timeManagement";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TimeManagementAvailableStaffPopper from "../poppers/TimeManagementAvailableStaffPopper";
import TimeManagementColorHighlight from "../TimeManagementColorHighlight";
import styles from "./TimeManagementDayHeader.module.scss";
import TimeManagementHeaderContainer from "./TimeManagementHeaderContainer";

export interface TimeManagementDayHeaderProps {
    value: TimeManagementTotals;
    list: SelectItem[];
    activeEmployees: ActiveEmployeeData[];
    searchValue: Optional<string>;
    className?: string;
    onSearchValueChangeHandler: (val: string) => void;
}

export const TimeManagementDayHeader: React.FC<
    TimeManagementDayHeaderProps
> = ({
    value,
    list,
    searchValue,
    onSearchValueChangeHandler,
    activeEmployees,
    className = "",
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    const options = [
        {
            value: "",
            label: t("timeManagement.allGroups"),
        },
        ...list.map((k) => ({
            value: k.Value as string,
            label: k.Text,
        })),
    ];

    const totalEmployeeIn = activeEmployees.filter(
        (x) => x.CurrentAction == AccessPointAction.IN
    ).length;

    const totalEmployeeOut = activeEmployees.length - totalEmployeeIn;

    return (
        <TimeManagementHeaderContainer
            className={`${className} ${styles.table}`}
        >
            <tbody>
                <tr>
                    <td style={{ minWidth: "300px", padding: 5 }} />
                    <td style={{ minWidth: "110px", width: "110px" }}>
                        {t("timeManagement.active")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "140px", width: "140px" }}>
                        {getAccessPointActionString(AccessPointAction.IN)}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "140px", width: "140px" }}>
                        {getAccessPointActionString(AccessPointAction.OUT)}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.careHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.maxContractHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.difference")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "180px", width: "180px" }}>
                        {t("timeManagement.neededEmployees")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "180px", width: "180px" }}>
                        <div className="d-flex algin-item-center justify-content-center">
                            <span className="mr-1">
                                {t(
                                    "timeManagement.availableStaff.availableStaff"
                                )}
                            </span>

                            <TimeManagementAvailableStaffPopper
                                activeEmployees={activeEmployees}
                                triggerElement={
                                    <Image
                                        className={styles.rightAngleImage}
                                        src={ImageAssets.common.angleRightGray}
                                    />
                                }
                            />
                        </div>
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "160px",
                            width: "160px",
                        }}
                    >
                        {t("timeManagement.info")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "190px",
                            width: "190px",
                        }}
                    >
                        {t("timeManagement.employees")}
                    </td>
                </tr>
                <tr className={styles.totals}>
                    <td>
                        <SearchField
                            value={defaultTo(searchValue, "")}
                            className={styles.search}
                            onValueChange={onSearchValueChangeHandler}
                        />
                    </td>
                    <td
                        style={{
                            color: value.TotalRecords == 0 ? "black" : "",
                        }}
                    >
                        {value.TotalRecords}
                    </td>
                    <td
                        style={{
                            color: value.IN == 0 ? "black" : "",
                        }}
                    >
                        {value.OUT && value.IN}
                    </td>
                    <td
                        style={{
                            color: value.OUT == 0 ? "black" : "",
                        }}
                    >
                        {value.OUT && value.OUT}
                    </td>
                    <td>{getFormattedTimeFromNumber(value.CareHours)}</td>
                    <td>{getFormattedTimeFromNumber(value.MaxCareHours)}</td>
                    <td>
                        <TimeManagementColorHighlight
                            differenceSumMinute={value.SumDifference}
                            isNegativeRed={false}
                            className={styles.hightLightContainer}
                        />
                    </td>
                    <td>
                        {formatNumber(
                            value.NeededEmployees,
                            FOUR_PRECISION_NUMERIC
                        )}
                    </td>
                    <td>
                        {formatNumber(
                            value.AvailableStaff,
                            FOUR_PRECISION_NUMERIC
                        )}
                    </td>
                    <td>
                        <TimeManagementColorHighlight
                            differenceSumMinute={value.InfoTotal}
                            isNegativeRed={true}
                            isTime={false}
                            className={styles.hightLightContainer}
                        />
                    </td>
                    <td style={{ minWidth: "190px", width: "190px" }}>
                        <div className={styles.employeeInOutDiv}>
                            <div className="d-flex">
                                <span
                                    className={`${
                                        totalEmployeeIn > 0 ? styles.textIn : ""
                                    } pr-1`}
                                >
                                    <span className={styles.employeeInOut}>
                                        {getAccessPointActionString(
                                            AccessPointAction.IN
                                        ).concat(":")}
                                    </span>
                                    {totalEmployeeIn}
                                </span>
                                <TimeManagementAvailableStaffPopper
                                    activeEmployees={activeEmployees.filter(
                                        (x) =>
                                            x.CurrentAction ==
                                            AccessPointAction.IN
                                    )}
                                    showInOutAndCurrentAction={false}
                                    triggerElement={
                                        <Image
                                            className={styles.rightAngleImage}
                                            src={
                                                ImageAssets.common
                                                    .angleRightGray
                                            }
                                        />
                                    }
                                />
                            </div>
                            <div className="d-flex">
                                <span
                                    className={`${
                                        totalEmployeeOut > 0
                                            ? styles.textOut
                                            : ""
                                    } pr-1`}
                                >
                                    <span className={styles.employeeInOut}>
                                        {getAccessPointActionString(
                                            AccessPointAction.OUT
                                        ).concat(":")}
                                    </span>
                                    {totalEmployeeOut}
                                </span>
                                <TimeManagementAvailableStaffPopper
                                    activeEmployees={activeEmployees.filter(
                                        (x) =>
                                            x.CurrentAction ==
                                            AccessPointAction.OUT
                                    )}
                                    showInOutAndCurrentAction={false}
                                    triggerElement={
                                        <Image
                                            className={styles.rightAngleImage}
                                            src={
                                                ImageAssets.common
                                                    .angleRightGray
                                            }
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </TimeManagementHeaderContainer>
    );
};

export default TimeManagementDayHeader;
