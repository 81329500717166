import {
    FULL_DATE_TIME_FORMAT,
    ISO8601_DATE_FORMAT,
    ISO8601_TIME_FORMAT,
} from "globals/constants";
import { isNil } from "lodash-es";
import moment from "moment";
import { TodoRequest } from "./request";
import { TodoResponse } from "./response";

export const getTodoFromResponse = (req: TodoResponse): TodoRequest => {
    const dueDate = moment(req.DueDate, FULL_DATE_TIME_FORMAT);
    return {
        ...req,
        CreatedAt: req.CreatedAt ? moment.unix(req.CreatedAt) : undefined,
        DueDate: dueDate,
        DueDateTime: dueDate,
        CompletionDate:
            req.CompletionDate != null
                ? moment(req.CompletionDate, ISO8601_DATE_FORMAT)
                : null,
        LastReOpenedOn:
            req.LastReOpenedOn != null
                ? moment(req.LastReOpenedOn, ISO8601_DATE_FORMAT)
                : null,
        DoneDate:
            req.DoneDate != null
                ? moment(req.DoneDate, ISO8601_DATE_FORMAT)
                : null,
        TaskRecreated: req.RepeatSchedule != null,
        RepeatSchedule:
            req.RepeatSchedule != null
                ? {
                      ...req.RepeatSchedule,
                      StartDate: moment(
                          req.RepeatSchedule.StartDate,
                          ISO8601_DATE_FORMAT
                      ),
                      EndDate:
                          req.RepeatSchedule.EndDate != null
                              ? moment(
                                    req.RepeatSchedule.EndDate,
                                    ISO8601_DATE_FORMAT
                                )
                              : null,
                      Time: moment(
                          req.RepeatSchedule.Time,
                          ISO8601_TIME_FORMAT
                      ),
                  }
                : null,
        SendRemainder: req.Reminder != null && isNil(req.CompletionDate),
    } as TodoRequest;
};
