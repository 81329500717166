import { AppLoader } from "components/Loaders";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";
import {
    getFilteredArray,
    getMillisecondsForMinutes,
} from "globals/helpers/generalHelper";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useWebEventHub } from "hooks/layout/useWebEventHub";
import { defaultTo } from "lodash-es";
import {
    BusinessEmployeeContext,
    EmployeeListFilters,
    EmployeeListItem,
    EmployeeSortColumn,
} from "models/employee";
import {
    changeEmployeeTypeStringToInt,
    getEmployeeFilterDefaultValue,
    getEmployeeSortColumnKeyFromEnum,
    getEmployeeTypeFromPath,
} from "models/employee/helper";
import { getParsedEmployees } from "models/employee/response";
import { Selectable, sortData, SortOrder, TableSort } from "models/general";
import { WebEventType } from "models/notification";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router";
import { validEmployeePathPatterns } from "routing/routes/business/employee";
import {
    BusinessEmployeeService,
    getBusinessEmployeeServiceKey,
} from "services/business";

const equalityCheckKeysList = ["Status", "HasMobileAccount", "HoursPerWeek"];

export const useBusinessEmployeesContext = () => {
    return useOutletContext<BusinessEmployeeContext>();
};

const sortList = (
    data: Selectable<EmployeeListItem>[],
    sort: TableSort<EmployeeSortColumn>
) =>
    sortData(data, [
        {
            col: getEmployeeSortColumnKeyFromEnum(sort.SortColumn),
            dir: sort.SortOrder,
        },
        {
            col: getEmployeeSortColumnKeyFromEnum(EmployeeSortColumn.Name),
            dir: sort.SortOrder,
        },
    ]) as EmployeeListItem[];

export const BusinessEmployeesContextProvider: React.FC = () => {
    const location = useLocation();
    const { employeeType } = useParams();
    const showErrorPage = useShowErrorPage();
    const { encodedId, id: sessionBusinessId } = useSessionBusiness();

    const [state, setState] = useState<BusinessEmployeeContext>({
        sort: {
            SortColumn: EmployeeSortColumn.DisplayId,
            SortOrder: SortOrder.ASC,
        },
        employees: null,
        onChangeCompleteResponse: () => {},
        navigateUsingCompleteResponse: true,
        setNavigateUsingCompleteResponse: () => {},
        filters: getEmployeeFilterDefaultValue(location.pathname),
        onFilterChange: () => {},
        onSortChange: () => {},
        onChangeEmployeesList: () => {},
    });
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();
    const employeeService = new BusinessEmployeeService(
        linkProvider.business.employees().api
    );
    const { signalR } = useWebEventHub();
    const {
        isFetching: fetchingEmployeeList,
        isLoading: loadingEmployeeList,
        isRefetching,
        data: employeesList,
        refetch: refetchEmployeeList,
        error: employeesFetchError,
    } = useQuery(
        getBusinessEmployeeServiceKey("getEmployeesList", {
            EmployeeType: state.filters.EmployeeType,
            encodedId,
        }),
        async () =>
            await employeeService.getEmployeesList({
                EmployeeType: changeEmployeeTypeStringToInt(
                    state.filters.EmployeeType
                ),
            }),
        {
            select: (res) => res.Data.map((x) => getParsedEmployees(x)),
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );

    useEffect(() => {
        const typeMismatch = !validEmployeePathPatterns.includes(
            defaultTo(employeeType, "")
        );

        if (employeeType && typeMismatch) {
            showErrorPage(404);
        }
    }, [location.pathname, employeeType]);

    const filterData = useCallback(
        (value?: EmployeeListFilters, data?: EmployeeListItem[]) => {
            const fullData = defaultTo(data, state.completeResponse);
            if (fullData) {
                const filteredData = getFilteredArray(
                    fullData,
                    defaultTo(value, state.filters),
                    equalityCheckKeysList
                );

                return filteredData.length > 0
                    ? sortList(filteredData, state.sort)
                    : [];
            }
            return [];
        },
        [employeesList, state.sort, state.filters]
    );
    useEffect(() => {
        if (!loadingEmployeeList) {
            if (employeesList) {
                setState((old) => ({
                    ...old,
                    completeResponse: employeesList,
                    employees: filterData(old.filters, employeesList),
                }));
            }
            if (employeesFetchError) {
                showUnexpectedErrorToast();
            }
        }
    }, [fetchingEmployeeList, loadingEmployeeList]);
    useEffect(() => {
        refetchData();
    }, [state.filters.EmployeeType]);

    useEffect(() => {
        const newType = getEmployeeTypeFromPath(location.pathname);
        if (newType !== state.filters.EmployeeType) {
            setState({
                ...state,
                filters: {
                    ...state.filters,
                    EmployeeType: newType,
                },
            });
        }
    }, [location.pathname]);

    const refetchData = () => {
        queryClient.invalidateQueries(
            getBusinessEmployeeServiceKey("getEmployeesList")
        );
        refetchEmployeeList();
    };
    const onChangeEmployeesList = (value: Selectable<EmployeeListItem>[]) => {
        setState({ ...state, employees: value });
    };
    const onChangeCompleteResponse = useCallback(
        (value: EmployeeListItem[]) => {
            setState({
                ...state,
                completeResponse: value,
                employees: filterData(state.filters, value),
            });
        },
        [state]
    );

    useEffect(() => {
        if (signalR) {
            signalR.on("eventReceived", (event: any) => {
                // used any, because signalR gives objects in camel case in response

                if (
                    sessionBusinessId == event.businessId &&
                    event.type == WebEventType.Refresh_Employee_List &&
                    !isRefetching
                ) {
                    refetchData();
                }
            });
        }
    }, []);
    return (
        <>
            {loadingEmployeeList ? (
                <AppLoader fullHeight={true} />
            ) : (
                <Outlet
                    context={{
                        ...state,
                        isLoading: loadingEmployeeList,
                        isRefetching: isRefetching,
                        completeResponse: state.completeResponse,
                        onChangeEmployeesList: onChangeEmployeesList,
                        onChangeCompleteResponse: onChangeCompleteResponse,
                        refetchData: refetchData,
                        onFilterChange: (value: EmployeeListFilters) => {
                            setState((old) => {
                                return {
                                    ...old,
                                    filters: value,
                                    employees: filterData(value),
                                };
                            });
                        },
                        setNavigateUsingCompleteResponse: (val?: boolean) =>
                            setState((old) => ({
                                ...old,
                                navigateUsingCompleteResponse: val,
                            })),
                        onSortChange: (
                            value: TableSort<EmployeeSortColumn>
                        ) => {
                            setState((old) => {
                                return {
                                    ...old,
                                    sort: value,
                                    employees:
                                        old.employees &&
                                        old.employees.length > 0
                                            ? sortList(old.employees, value)
                                            : [],
                                };
                            });
                        },
                    }}
                />
            )}
        </>
    );
};

export default BusinessEmployeesContextProvider;
