export const BusinessEnTranslations = {
    business: {
        absents: {
            cutOffDate: "Cut-Off Date",
            popupTable: {
                endDate: "End Date",
                startDate: "Start Date",
                rangeTotal: "Range Total",
                total: "Total"
            },
            table: {
                absencePercentage: "Absence in %",
                absences: "Absences",
                absencesWithoutVacations: "Absences (Without Vacations)",
                absenceWithoutReason: "Absence without reason",
                attendances: "Attendances",
                employeeName: "Employee Name",
                id: "ID",
                pregnancyLeaves: "Pregnancy Leaves",
                schoolDays: "School Days",
                sickDays: "Sick Days",
                vacations: "Vacations",
                workingDays: "Working Days"
            },
            title: "Absences"
        },
        activeBusinessChanged: "Your active company has been changed.",
        bankData: {
            accountNumber: "Account Number",
            accountOwner: "Account Owner",
            bankCode: "Bank Code",
            bankName: "Bank Name",
            BIC: "BIC",
            createTitle: "Add Bank Data",
            editTitle: "Edit Bank Data",
            IBAN: "IBAN",
            title: "Bank Data"
        },
        basicSettings: {
            titles: {
                accessControl: "Access Control",
                ageGroupPlanning: "Age-Group and Planning",
                chat: "Chat",
                timeTracking: "Time Tracking",
                vacationsPlanning: "Vacations Planning",
            },
            minChildAge: { name: "Minimum Age of Children (Months)" },
            maxChildAge: { name: "Maximum Age of Children (Months)" },
            basicWeeklyWorkHours: {
                name: "Basic Weekly Work Hours"
            },
            calculateExactEmployeeTime: {
                infoText1: `If this setting is set to "ON", the exact working time between a login and a logout is calculated.`,
                infoText2: `If there are several logins and logouts, the sum of all collected intermediate times is calculated and displayed as working time.`,
                infoText3: `If you set this setting to "OFF" only the first login and the last logout will be used to calculate the total working time.`,
                name: "Calculate exact employee working hours?"
            },
            carryForwardVacations: {
                infoText: "If true, all left-over vacations of employee from current year will be usable in next year.",
                name: "Carry forward left-over vacations to next year?"
            },
            disableInactiveChipsAfterDays: {
                name: "Disable inactive chips after days"
            },
            employeePreparationTime: {
                infoText1: 'The field indicates from how many minutes of additional working time an "overtime" or additional working time is recognized.',
                infoText2: 'If you enter "20" minutes in the input field, additional working time of more than 20 minutes per day will be recognized. If you have separate regulations on overtime in your company, these can be regulated via this input field.',
                name: "Employee Preparation Time in minutes"
            },
            externalToken: {
                infoText: "Connect your website or external services to lillywait.com . Here you can easily transfer the registrations of your institution to the registrations in lillywait.com.",
                name: "External Access Token",
                heading: "Registrations",
            },
            missingBreakStopMinutes: {
                infoText: "If an employee is present in the house and forgets to sign in and out for the break, the value entered here in minutes per day will be deducted from the working time.",
                name: "Missing break stop entry minutes"
            },
            monthChangeYear: {
                infoText: "This month is used to determine the year of birth. A child whose birthday is from the month set will be classified as vintage in the following year.",
                name: "Month Change Year"
            },
            timeTrackingStart: {
                infoText: " ",
                name: "Time Tracking Start Month"
            },
            vacationsCalculationStart: {
                infoText: " ",
                name: "Vacations Calculation Start"
            },
            weeksToLookForNewUserSeed: {
                infoText: "If a new user registers in the app, older messages are sent to the user again. Example: If the number 12 is entered, newly registered users will receive messages from the last 12 weeks.",
                name: "Send older messages to new app users (Weeks)"
            }
        },
        branches: {
            address: "Address",
            city: "City",
            facilityName: "Facility Name",
            managingDirector: "Managing Director",
            phone: "Phone",
            street: "Street",
            streetNumber: "Street Number",
            zipCode: "Postal Code"
        },
        branchType: {
            branch: "Branch",
            headquarter: "Headquarter"
        },
        businessShift: {
            addNewOption: "Add Option",
            createTitle: "Create Shift",
            criteria: {
                option: {
                    missing: "Option is required.",
                    name: "Option"
                },
                type: "Type",
                typeOptions: {
                    area: "Area",
                    floor: "Floor",
                    function: "Function",
                    group: "Group"
                }
            },
            endTime: {
                invalid: "Start Time must be less than end-time.",
                missing: "End time is required.",
                name: "End Time"
            },
            fairlyPlanned: {
                infoText: 'If you select this option, care will be taken during scheduling to ensure that this shift is "fairly" scheduled. In the time period you select, all employees should be assigned an equal number of the selected shift.',
                name: "Fairly Planned?"
            },
            invalidTotalMinValue: "Total minimum employees is invalid according to your criteria options",
            maxEmployees: {
                invalid: "Maximum Employees can't be smaller than minimum value.",
                missing: "Maximum Employees is required.",
                name: "Maximum Employees"
            },
            minEmployees: {
                invalid: "Minimum Employees can't be greater than maximum value.",
                missing: "Minimum Employees is required.",
                name: "Minimum Employees"
            },
            name: {
                missing: "Name is required.",
                name: "Name"
            },
            startTime: {
                missing: "Start Time is required.",
                name: "Start Time"
            },
            workingArea: {
                missing: "Working Area is required.",
                name: "Working Area"
            }
        },
        businessType: {
            elderlycare: "Elderly Care",
            elderlycares: "Elderly Cares",
            industries: "Industries",
            industry: "Industry",
            kindergarten: "Kindergarten",
            kindergartens: "Kindergartens",
            lw: "Lillywait",
            lws: "Lillywaits",
            school: "School",
            schools: "Schools"
        },
        changeHQ: "Change HQ...",
        chatSettings: {
            allowGroupChats: "Allow Group Chats:",
            allowSingleChats: "Allow Single Chats:",
            allowWebUsers: "Allow Web Users:",
            careGroupEmployees: "Create a separate chat-group of all Employees of each Daycare Group",
            careGroupParents: "Create a separate chat-group of all Parents of each Daycare Group",
            companyEmployeesChat: "Add all other Employee of the assigned Facility to single chat",
            companyGroupEmployees: "Create a separate chat-group of all Employees of the Facility",
            // default chat groups
            companyGroupParents: "Create a separate chat-group of all Parents of the Facility",
            companyParentsChat: "Add all other Parents of the assigned Facility to single chat",
            daycareGroupEmployeesChat: "Add Employee of the assigned Daycare Group to single chat",
            // single chats
            daycareGroupParentsChat: "Add Parents of the assigned Daycare Group to single chat",
            employeeParentCareGroup: "Create a chat-group of assigned Daycare Group together with Employee and Parents",
            employeeParentCompanyGroup: "Create a chat-group of the assigned Facility together with Employee and Parents",
            generalSettings: "General Settings",
            settingsUpdated: "Chat settings updated successfully.",
            titleEmployees: "Settings for Employees",
            titleParents: "Settings for Parents",
            webUser: {
                careGroupEmployees: "Add web user in Employees Daycare Group chat",
                careGroupParents: "Add web user in Parents Daycare Group chat",
                facilityEmployees: "Add web user in all Employees Facility chat",
                facilityParents: "Add web user in all Parents Facility chat"
            },
            // common
            webUserInEmployeeParentCareGroupChat: "Add web user in all users Daycare group chat",
            webUserInEmployeeParentCompanyChat: "Add web user in all users Facility chat"
        },
        contracts: {
            affiliates: "Affiliates",
            cancelContract: "Cancel Contract",
            cancelledOn: "Cancelled On",
            discountedPrice: "Discounted Price",
            endDate: "End Date",
            feeChildren: "Fee Children",
            feeEmployees: "Fee Employees",
            freeMonth: "Free Month",
            modules: "Module Plans",
            noContractFound: "No Contract Found...",
            price: "Price",
            redeemVoucher: "Redeem Voucher",
            renewContract: "Renew Contract",
            startDate: "Start Date",
            total: "Total",
            totalDiscount: "Total Discount",
            vouchers: {
                code: "Code",
                discount: "Discount",
                name: "Name",
                title: "Vouchers"
            }
        },
        courses: {
            createEdit: {
                courseGroups: "Course Groups",
                create: "Create Course",
                edit: "Edit Course",
                groupName: "Group Name",
                groupShortName: "Group Short Name"
            },
            missing: {
                groupName: "Group name is required",
                groupShortName: "Group short name is required",
                shortName: "Short name is required"
            },
            table: {
                area: "Area",
                floor: "Floor",
                name: "Name",
                noCoursesExist: "No courses exists...",
                shortName: "Short Name"
            }
        },
        createEdit: {
            accountNumber: {
                name: "Account Number"
            },
            accountOwner: {
                name: "Account Owner"
            },
            additionalAddress: {
                name: "Additional Address"
            },
            adminFirstName: {
                missing: "First Name is required",
                name: "Admin First Name"
            },
            desiredKids: {
                name: "Desired Kids Capacity",
                missing: "Desired capacity is required.",
                invalid: "Desired capacity should not be more than total capacity."
            },
            totalKids: {
                name: "Total Kids Capacity",
                missing: "Total capacity is required."
            },
            adminLastName: {
                missing: "Last Name is required",
                name: "Admin Last Name"
            },
            affiliateCode: {
                name: "Affiliate Code"
            },
            bankCode: {
                name: "Bank Code"
            },
            bankName: {
                name: "Bank Name"
            },
            bic: {
                name: "BIC"
            },
            branchType: {
                name: "Branch Type"
            },
            businessType: {
                name: "Business Type"
            },
            commercialRegister: {
                name: "Commercial Register"
            },
            companyId: {
                name: "Company ID"
            },
            companyName: {
                missing: "Company Name is required",
                name: "Company Name"
            },
            educationalLeadership: {
                missing: "Educational Leadership is required",
                name: "Educational Leadership"
            },
            email: {
                invalid: "Please check that your email is the correct format",
                missing: "Email is required",
                name: "Email"
            },
            facilityManager: {
                missing: "Facility Manager is required",
                name: "Facility Manager"
            },
            facilityName: {
                missing: "Facility Name is required",
                name: "Facility Name"
            },
            iban: {
                name: "IBAN"
            },
            internet: {
                name: "Internet"
            },
            managingDirector: {
                name: "Managing Director"
            },
            officePhone: {
                name: "Office Phone"
            },
            phoneCentral: {
                name: "Phone Central"
            },
            shortCompanyName: {
                missing: "Short Company Name is required",
                name: "Short Company Name"
            },
            shortInstitutionName: {
                missing: "Short Institute Name is required",
                name: "Short Institute Name"
            },
            state: {
                missing: "State is required",
                name: "State"
            },
            streetAddress: {
                missing: "Street Address is required",
                name: "Street Address"
            },
            streetNumber: {
                missing: "Street Number is required",
                name: "Street Number"
            },
            taxNumber: {
                name: "Tax Number"
            },
            telefax: {
                name: "Telefax"
            },
            timezone: {
                missing: "Timezone is required",
                name: "Timezone"
            }
        },
        hqNotAllowed: "Sorry, you don't have access for this HQ.",
        ipads: {
            name: "Name",
            noIPadExist: "No iPad exists...",
            status: {
                Approved: "Approved",
                Blocked: "Blocked",
                name: "Status",
                Pending: "Pending",
                Rejected: "Rejected"
            },
            updateIpad: "Update IPad"
        },
        kontos: {
            createKonto: "Create Konto",
            displayId: "Id",
            editKonto: "Edit Konto",
            name: "Name"
        },
        locationPreferenceMessage: "Location    data preference can't be changed because current settings are in use. Please remove all the usages to update it.",
        modulePlanOverlay: {
            upgradeMyPlan: "Upgrade my plan",
            upgradePlanExp: "Please upgrade your plan to access this feature"
        },
        packages: "Packages",
        refreshToken: "Refresh Token",
        settings: "Settings",
        switchingActiveBusiness: "Changing active company...",
        tabs: {
            accessControl: "Access Control",
            ageGroups: "Age Groups",
            backgroundJobs: "Background Jobs",
            basicData: "Basic Data",
            careGroups: "Care Groups",
            careScopes: "Scope of Care",
            chatSettings: "Chat Settings",
            compensationTemplates: "Compensation Templates",
            contracts: "Contracts",
            courses: "Courses",
            data: "Data",
            employeeKeyCalculations: "Employee Key Calculations",
            documents: "Documents",
            externalIntegration: "External Integration",
            functions: "Functions",
            hPePrinters: "HP ePrinters",
            ipads: "iPads",
            konto: "Konto",
            logo: "Logo",
            mealGroups: "Meal Groups",
            openingHours: "Opening Hours",
            printJobs: "Print Jobs",
            publicHolidays: "Public Holidays",
            salaryGroups: "Salary Groups",
            settings: "Settings",
            shifts: "Shifts",
            timeTracking: "Time Tracking",
            workingAreas: "Working Areas",
            workingGroups: "Working Groups"
        },
        timeTrackingSettings: {
            allowAutoBreakOutEmp: "Allow auto-stop for employees' break times",
            allowAutoOutClient: "Allow auto-stop for childcare times of the children",
            allowAutoOutEmp: "Allow auto-stop for employees' working times",
            autoStopChildcare: "AutoStop for Childcare times",
            autoStopWork: "AutoStop for working time",
            byClosingHours: "By closing hours",
            byClosingHours_exp: "If an employee has forgotten to log out at the end of work, he or she will be logged out automatically based on the end of the opening time.",
            byWorkingHours: "By working hours",
            byWorkingHours_exp: "If an employee has forgotten to log out at the end of work, he or she will be logged out automatically at the end of his or her contractually agreed working hours.",
            settingsUpdated: "Time-tracking settings updated successfully.",
            text: "Allow manual time recording:",
            titleEmployees: "Time tracking for Employees",
            titleParents: "Time tracking for Parents",
            turnOffAll: "Turn off all",
            viaIpad: "via company app",
            viaMobile: "via mobile app",
            viaWeb: "via web app"
        },
        unableToChangeActiveBusiness: "Unable to change active business. Try again later...",
        useSampleLillywaitTemplates: "Use sample templates of lillywait"
    },
    businessContract: {
        cancel: {
            message: "Are you sure you want to cancel your contract? This action is irreversible",
            success: "Your contract is cancelled successfully. Now, you won't be able to use this company from next month.",
            title: "Cancel Contract"
        },
        renew: {
            contractRenewedSuccessfully: "Contract renewed successfully.",
            endDate: "End Date",
            hasNoEndDate: "Has No End Date",
            startDate: "Start Date",
            title: "Renew Contract",
            titleErrorMessage: "You can no longer use the website because your contract is expired. Kindly renew contract to use again."
        },
        vouchers: {
            code: "Code",
            discount: "Discount",
            name: "Name",
            vouchers: "Vouchers"
        }
    },
    deleteLw: {
        confirmMessage: "Are you sure you want to delete this LW business and all its client businesses and their data?",
        confirmTitle: "Confirm Deletion",
        deleteAll: "Delete Lillywait",
        errorMessage: "Unable to delete LW business...",
        progressMessage: "Deleting Lillywait...",
        successMessage: "Lillywait business is being deleted. We will send you an alert when it is done."
    },
    deleteLwc: {
        confirmMessage: "Are you sure you want to delete this client business with all the clients (LWCC) and their data?",
        confirmTitle: "Confirm Deletion",
        delete: "Delete Client Business",
        errorMessage: "Unable to delete Client Business...",
        progressMessage: "Deleting Lillywait Client...",
        successMessage: "Lillywait client business is being deleted. We will send you an alert when it is done."
    }
};

export default BusinessEnTranslations;
