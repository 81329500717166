import { BusinessCreateEditFields } from "commonPartials/businessCreateEditField";
import { AppContainer } from "components/Containers";
import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { BusinessDetails, BusinessType } from "models";
import {
    LW_ElderlyCare_BasicData,
    LW_Industry_BasicData,
    LW_Kindergarten_BasicData,
    LW_School_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import { useQuery } from "react-query";
import { BusinessService, getBusinessServiceKey } from "services/business";
import { useCurrentLwcContext } from "./LwcEditLayout";

export const LwcEdit: React.FC = () => {
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const showErrorPage = useShowErrorPage();

    const currentBusiness = useCurrentLwcContext();

    let identifier: string = LW_Kindergarten_BasicData;

    switch (currentBusiness.dataItem?.Type) {
        case BusinessType.KINDERGARTEN:
            identifier = LW_Kindergarten_BasicData;
            break;
        case BusinessType.INDUSTRY:
            identifier = LW_Industry_BasicData;
            break;
        case BusinessType.SCHOOL:
            identifier = LW_School_BasicData;
            break;
        case BusinessType.ELDERLY_CARE:
            identifier = LW_ElderlyCare_BasicData;
            break;
    }

    const hasEditPermission = checkPermission(identifier, [
        PermissionAccessTypes.EDIT,
    ]);

    const businessService = new BusinessService(linkProvider.business.api);

    const { data: getBusinessResponse, isFetching: isGetBusinessLoading } =
        useQuery(
            getBusinessServiceKey("getBusinessDetail", {
                businessId: currentBusiness.dataItem?.EncodedId,
            }),
            async () => {
                if (currentBusiness.dataItem?.EncodedId) {
                    return await businessService.getBusinessDetail(
                        currentBusiness.dataItem.EncodedId
                    );
                }
            },
            {
                refetchOnMount: "always",
                onError: () => {
                    showErrorPage(404);
                },
            }
        );

    return (
        <div>
            {isGetBusinessLoading ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={false}
                    isTabContainer={true}
                    mediumViewAdjustment={-15}
                    mobileViewAdjustment={-15}
                >
                    <BusinessCreateEditFields
                        currentBusiness={
                            {
                                ...getBusinessResponse?.Data,
                                EncodedId: currentBusiness.dataItem
                                    ?.EncodedId as string,
                            } as BusinessDetails
                        }
                        isCreate={false}
                        onSuccess={() => {
                            if (currentBusiness.refetchData) {
                                currentBusiness.refetchData();
                            }
                        }}
                        isLwc={true}
                        hasEditPermission={hasEditPermission}
                    />
                </AppContainer>
            )}
        </div>
    );
};

export default LwcEdit;
