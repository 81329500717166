import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Kindergarten_Client_AccessControl,
    Kindergarten_Client_App,
    Kindergarten_Client_BankData,
    Kindergarten_Client_BasicData,
    Kindergarten_Client_Diseases,
    Kindergarten_Client_Documents,
    Kindergarten_Client_Family,
    Kindergarten_Client_Groups,
    Kindergarten_Client_Contracts,
    Kindergarten_Client_MedicalData,
    Kindergarten_Client_Vacations,
    PermissionAccessTypes,
} from "models";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import {
    KindergartenClientListing,
    KindergartenClientStatus,
} from "models/kindergartenClient";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router";

// not in edit-layout to resolve circular dependency
export const useActiveKindergartenClient = () => {
    return useOutletContext<
        TabbedLayoutActiveItem<KindergartenClientListing>
    >();
};

export const KindergartenClientTabs = ({
    activeClient,
    onActiveTabChange,
}: {
    activeClient?: KindergartenClientListing;
    onActiveTabChange: (tab: NavigationTab) => void;
}) => {
    const { linkProvider } = useRouting();
    const { urlForKindergartenClient } = useIFrameRouting();
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const { clientId } = useParams();
    const [tabs, setTabs] = useState<NavigationTab[]>([]);
    const linkBase = linkProvider.kindergarten
        .clients()
        .screens.client.withId(clientId);

    const getTranslation = (tabName: string) =>
        t(`kindergartenClient.tabs.${tabName}`);

    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(Kindergarten_Client_BasicData, [
                    PermissionAccessTypes.SHOW,
                ]),
                url: linkBase.edit(),
            },
            {
                label: getTranslation("contracts"),
                hasAccess: checkPermission(Kindergarten_Client_Contracts),
                url: linkBase.contracts(),
            },
            {
                label: getTranslation("documents"),
                hasAccess: checkPermission(Kindergarten_Client_Documents),
                url: linkBase.documents.list(),
                iframeUrl: urlForKindergartenClient("Documents"),
            },
            {
                label: getTranslation("accessControl"),
                hasAccess: checkPermission(Kindergarten_Client_AccessControl),
                url: linkBase.accessControl(),
                iframeUrl: urlForKindergartenClient("AccessControl"),
            },
            {
                label: getTranslation("diseases"),
                hasAccess: checkPermission(Kindergarten_Client_Diseases),
                url: linkBase.diseases.list(),
                iframeUrl: urlForKindergartenClient("Diseases"),
            },
            {
                label: getTranslation("vacations"),
                hasAccess: checkPermission(Kindergarten_Client_Vacations),
                url: linkBase.vacations.list(),
                iframeUrl: urlForKindergartenClient("Vacations"),
            },
            {
                label: getTranslation("medicalData"),
                hasAccess: checkPermission(Kindergarten_Client_MedicalData),
                url: linkBase.medicalData(),
                iframeUrl: urlForKindergartenClient("MedicalData"),
            },
            {
                label: getTranslation("authorizedCollector"),
                hasAccess: checkPermission(Kindergarten_Client_Family),
                url: linkBase.family.list(),
                iframeUrl: urlForKindergartenClient("Families"),
            },
            {
                label: getTranslation("careGroups"),
                hasAccess: checkPermission(Kindergarten_Client_Groups),
                url: linkBase.careGroups(),
                iframeUrl: urlForKindergartenClient("Groups"),
            },
            {
                label: getTranslation("bankData"),
                hasAccess: checkPermission(Kindergarten_Client_BankData),
                url: linkBase.bankData.list(),
            },
            {
                label: getTranslation("app"),
                hasAccess:
                    activeClient?.Status === KindergartenClientStatus.Active &&
                    checkPermission(Kindergarten_Client_App),
                url: linkBase.app(),
                iframeUrl: urlForKindergartenClient("App"),
            },
        ]);
    }, [t, checkPermission, clientId, activeClient]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            slidesToShow={7}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default KindergartenClientTabs;
