import { AuthLessRoutingContext } from "routing/routingContexts";
import { getUrlForPattern } from "routing/routingContexts/helper";
import { RedirectUrlParam } from "routing/queryParams";
import { PlatformParam } from "./queryParams";

export const noAuthRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: AuthLessRoutingContext = new AuthLessRoutingContext()
) => {
    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {
            login: () => getUrlForPattern(context, buildUrl, "login", true),
            logout: () => getUrlForPattern(context, buildUrl, "logout", true),
            me: () => getUrlForPattern(context, buildUrl, "me", true),
            renewSession: (data: { refreshToken: string }) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "renew-session",
                    true,
                    undefined,
                    data
                ),
            hqRegister: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "hq-register-request",
                    true
                ),
            getCompleteRegisterInfo: (userId: string, code: string) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    `get-complete-registration-info/:userId`,
                    true,
                    {
                        userId: userId,
                    },
                    { code: code }
                ),
            confirmEmail: (userId: string, code: string) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    `confirm-user-email`,
                    true,
                    undefined,
                    { code: code, userId: userId }
                ),
            getEmployeeInfoRegister: (userId: string) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    `employee-info-register/:userId`,
                    true,
                    {
                        userId: userId,
                    }
                ),
            employeeInfoRegister: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "employee-info-register",
                    true
                ),
            completeRegister: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "complete-registration",
                    true
                ),
            forgotPassword: () =>
                getUrlForPattern(context, buildUrl, "forgot-password", true),
            resetPassword: () =>
                getUrlForPattern(context, buildUrl, "reset-password", true),
            editProfile: () =>
                getUrlForPattern(context, buildUrl, "edit-profile", true),
        },
        screens: {
            login: (queryParams?: RedirectUrlParam) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "login",
                    false,
                    undefined,
                    queryParams
                ),
            // getDemo: () => getUrlForPattern(context, buildUrl, "get-demo"),
            sessionTimeOut: (queryParams?: RedirectUrlParam) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "session-timeout",
                    false,
                    undefined,
                    queryParams
                ),
            forgotPassword: () =>
                getUrlForPattern(context, buildUrl, "forgot-password"),
            forgotPasswordConfirmation: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "forgot-password-confirmation"
                ),
            employeeInfoRegister: () =>
                getUrlForPattern(context, buildUrl, "employee-info-register"),
            completeRegistration: () =>
                getUrlForPattern(context, buildUrl, "complete-registration"),
            registerRequestConfirmation: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "register-request-confirmation"
                ),
            emailConfirmation: (queryParams?: PlatformParam) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "confirm-email",
                    false,
                    undefined,
                    queryParams
                ),
            registerInfoCompleted: () =>
                getUrlForPattern(context, buildUrl, "registration-complete"),
            resetPassword: () =>
                getUrlForPattern(context, buildUrl, "reset-password"),
            resetPasswordConformation: (queryParams?: PlatformParam) =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "reset-password-confirmation",
                    false,
                    undefined,
                    queryParams
                ),
        },
    };
};

export default noAuthRoutes;
