export const DataImportEnTranslations = {
    clientColumns: {
        birthday: "Birthday",
        careStart: "Care Beginning",
        city1: "City (Contact-1)",
        city2: "City (Contact-2)",
        email1: "Email (Contact-1)",
        email2: "Email (Contact-2)",
        endOfContract: "End of Contract",
        firstName: "First Name",
        firstName1: "First Name (Contact-1)",
        firstName2: "First Name (Contact-2)",
        gender: "Gender",
        gender1: "Gender (Contact-1)",
        gender2: "Gender (Contact-2)",
        houseNumber1: "House Number (Contact-1)",
        houseNumber2: "House Number (Contact-2)",
        lastName: "Last Name",
        lastName1: "Last Name (Contact-1)",
        lastName2: "Last Name (Contact-2)",
        mobilePhone1: "Mobile Phone (Contact-1)",
        mobilePhone2: "Mobile Phone (Contact-2)",
        postCode1: "Postal Code (Contact-1)",
        postCode2: "Postal Code (Contact-2)",
        privatePhone1: "Private Phone (Contact-1)",
        privatePhone2: "Private Phone (Contact-2)",
        street1: "Street (Contact-1)",
        street2: "Street (Contact-2)",
        workPhone1: "Work Phone (Contact-1)",
        workPhone2: "Work Phone (Contact-2)",
    },
    clientRegistrationColumns: {
        firstName: "First Name",
        lastName: "Last Name",
        birthday: "Birthday",
        careStart: "Care Start",
        city: "City",
        care_scope: "Scope of Care",
        gender: "Gender",
        note: "Note",
        nationality: "Nationality",
        arrangedPlace: "Place confirmation has already been given",
        isSibling: "Is Sibling",
        migrationBackground: "Migration Background",
        placeChanged: "Place Changed",
        status: "Status",
        created_at: "Created At",
        firstName_mother: "Mother's First Name",
        lastName_mother: "Mother's Last Name",
        gender_mother: "Mother's Gender",
        email_mother: "Mother's Email",
        houseNumber_mother: "Mother's House Number",
        postCode_mother: "Mother's Postal Code",
        city_mother: "Mother's City",
        street_mother: "Mother's Street Address",
        workPhone_mother: "Mother's Work Phone Number",
        privatePhone_mother: "Mother's Private Phone Number",
        mobilePhone_mother: "Mother's Mobile Phone Number",
        firstName_father: "Father's First Name",
        lastName_father: "Father's Last Name",
        gender_father: "Father's Gender",
        email_father: "Father's Email",
        houseNumber_father: "Father's House Number",
        postCode_father: "Father's Postal Code",
        city_father: "Father's City",
        street_father: "Father's Street Address",
        workPhone_father: "Father's Work Phone Number",
        privatePhone_father: "Father's Private Phone Number",
        mobilePhone_father: "Father's Mobile Phone Number",
    },
    deleteClientRegistrations: {
        confirmMessage:
            "Are you sure you want to delete all child registrations for this kindergarten?",
        confirmTitle: "Confirm Deletion",
        deleteAll: "Delete Child Registrations",
        errorMessage: "Unable to delete child registrations...",
        progressMessage: "Deleting Child registrations...",
        successMessage:
            "All kindergarten child registrations deleted successfully.",
    },
    deleteClients: {
        confirmMessage:
            "Are you sure you want to delete all children data for this kindergarten?",
        confirmTitle: "Confirm Deletion",
        deleteAll: "Delete Children Data",
        errorMessage: "Unable to delete children data...",
        progressMessage: "Deleting children data...",
        successMessage: "All children data deleted successfully.",
    },
    deleteEmployees: {
        confirmMessage:
            "Are you sure you want to delete all employees for this kindergarten?",
        confirmTitle: "Confirm Deletion",
        deleteAll: "Delete Employees",
        errorMessage: "Unable to delete employees...",
        progressMessage: "Deleting Employees...",
        successMessage: "All kindergarten employees deleted successfully.",
    },
    employeeColumns: {
        birthCity: "Birth City",
        birthCountry: "Birth Country",
        birthday: "Birthday",
        birthName: "Birth Name",
        city: "City",
        email: "Email",
        firstName: "First Name",
        gender: "Gender",
        houseNumber: "House Number",
        landlineNumber: "Landline Number",
        lastName: "Last Name",
        phoneNumber: "Phone Number",
        postCode: "Post Code",
        street: "Street",
    },
    fileDialog: {
        back: "Back",
        downloadSampleTemplate: "Download Sample Template",
        fileLabel: "Select Data File",
        fileMissing: "Data file is required",
        fileNotAllowed: "Selected file type is not allowed.",
        next: "Next",
    },
    importData: {
        back: "Back",
        buttons: {
            deleteAllClientRegistrations: "Delete Child Registrations",
            deleteAllClients: "Delete Children Data",
            deleteAllEmployees: "Delete All Employees",
            importClientRegistrations: "Import Child Registrations",
            importClients: "Import Children Data",
            importEmployees: "Import Employees",
        },
        columnMappingInfo: "Fields with (*) are required",
        columnMappingTitle: "Column Mapping",
        columnMappingSubTitle: "Specify Fields Mapping",
        dateFormatSelection: "Date Format Selection",
        errorMessage: "Unable to import data...",
        import: "Import Data",
        next: "Next",
        successMessage: "Data import job created successfully.",
        unMapped: "Not Mapped (File Columns)",
        // uploadMessage: "Uploading file",
        // successMessage: "Data import is in progress. You can check again after few minutes",
        // errorMessage: "Unable to import file...",
        uploadMessage: "Importing Data...",
    },
    importHolidays: {
        errorMessage: "Unable to import data...",
        import: "Import Holidays",
        successMessage: "Holidays imported successfully.",
        uploadMessage: "Importing Data...",
    },
    passwordDialog: {
        businessNotExist: "Lillywait not exist",
        incorrectPassword: "Entered Password is incorrect",
        infoMessage: "For verification, kindly enter your password:",
        deletionPasswordLabel: "LW Deletion Password",
        deletionPasswordRequired: "LW Deletion Password is required",
        passwordLabel: "Account Password",
        passwordRequired: "Account Password is required",
    },
};

export default DataImportEnTranslations;
