import AppContentHeader from "components/AppContentHeader";
import IFrameContent from "components/IFrameContent";
import { useIFrameRouting } from "hooks/general/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./DocumentLetterhead.module.scss";

export const DocumentLetterhead: React.FC = () => {
    const { t } = useTranslation();
    const { urlForSessionBusinessAdmin } = useIFrameRouting();

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("letterhead.title")}
                classNameForIcon="pe-7s-copy-file"
            />
            <IFrameContent
                contentUrl={urlForSessionBusinessAdmin("Letterheads")}
            />
        </div>
    );
};

export default DocumentLetterhead;
