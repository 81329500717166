export enum CompanyType {
    Headquarter = "Headquarter",
    Branch = "Branch",
}
export enum BusinessStatus {
    OnAdvance = "OnAdvance",
    Active = "Active",
    Expired = "Expired",
}

export enum BusinessType {
    KINDERGARTEN = "Kindergarten",
    INDUSTRY = "Industry",
    SCHOOL = "School",
    ELDERLY_CARE = "ElderlyCare",
    LW = "LW",
}

export enum BusinessSortColumn {
    Name = 1,
    Phone = 2,
    Address = 3,
    ManagingDirector = 4,
}
