import { AppGradientButton } from "components/Buttons";
import { AuthInputField } from "components/FormFields";
import ImageAssets from "globals/images/common";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ForgotPasswordRequest, validateForgotPassword } from "models";
import {
    getValidityStateFromApiResponse,
    ValidityStateManager,
} from "models/general/validityState";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { WithAuthLoader } from "screens/auth/partials/AuthLoader";
import { AuthHeader } from "screens/auth/partials/header";
import { AuthService } from "services";
import styles from "./ForgotPasswordFields.module.scss";

export interface ForgotPasswordFieldsProps extends WithAuthLoader {}

export const ForgotPasswordFields: React.FC<ForgotPasswordFieldsProps> = ({
    setLoading,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const navigation = useNavigate();

    const redirect = () => {
        navigation(linkProvider.noAuth.screens.forgotPasswordConfirmation());
    };

    const [state, setState] = useState<ForgotPasswordRequest>({
        Email: "",
    });

    const [showError, setShowError] = useState(false);

    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isLoading: loading,
        data: response,
        mutate: forgot,
    } = useMutation(
        async (val: ForgotPasswordRequest) =>
            await authService.forgotPassword(val)
    );

    const validityStateManager = useMemo(() => {
        let validityState = validateForgotPassword(state);
        if (!showError && response) {
            setShowError(true);
            validityState = getValidityStateFromApiResponse(response);
        }
        return new ValidityStateManager(validityState);
    }, [response, state]);

    const onButtonClick = () => {
        if (validityStateManager.isStateValid()) {
            setShowError(false);
            setLoading(true);
            forgot(state);
        } else {
            setShowError(true);
        }
    };

    useEffect(() => {
        setLoading(loading);
        if (!loading && response) {
            if (response.Data) {
                redirect();
            }
        }
    }, [loading, response]);

    return (
        <>
            <AuthHeader
                mainHeading={t("auth.forgotPassword.title")}
                pageTitle={t("auth.forgotPassword.pageTitle")}
            />
            <div>
                <div className={styles.headingDesc}>
                    {t("auth.forgotPassword.desc")}
                </div>
                <div className={styles.forgetInput}>
                    <AuthInputField
                        value={state.Email}
                        useBubbleError={true}
                        onValueChange={(value: string) =>
                            setState({
                                Email: value,
                            })
                        }
                        onBlur={(value: string) =>
                            setState({
                                Email: value,
                            })
                        }
                        iconPath={ImageAssets.common.envelope}
                        placeholder={t("auth.companyEmail")}
                        error={
                            showError
                                ? defaultTo(
                                      validityStateManager.getFirstErrorInfo(
                                          "Email"
                                      ),
                                      validityStateManager.getFirstErrorInfo(
                                          "email" // from api response
                                      )
                                  )
                                : undefined
                        }
                    />
                </div>
                <AppGradientButton
                    className={styles.forgotButton}
                    disabled={showError && !validityStateManager.isStateValid()}
                    onClick={() => {
                        onButtonClick();
                    }}
                    text={t("auth.forgotPassword.sendEmail")}
                />
            </div>
        </>
    );
};

export default ForgotPasswordFields;
