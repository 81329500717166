import { StartAndEndDateFields } from "commonPartials";
import { AppSwitch, AppTooltip, ErrorMessage } from "components";
import { AppColoredMultiSelect, AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    AppInputField,
    AppNumberInput,
    AppTimePicker,
} from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import {
    ValidityStateManager,
    CustomColorItemOption,
    ValidityState,
} from "models/general";
import {
    BusinessShift,
    BusinessShiftCriteria,
    getEmptyShiftCriteria,
    validateBusinessShift,
} from "models/businessShifts";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import {
    BusinessShiftService,
    CurrentBusinessService,
    getBusinessShiftServiceKey,
    getCurrentBusinessServiceKey,
} from "services/business";
import { FORMAT_INTEGER } from "globals/constants";
import AppIconTextButton from "components/Buttons/AppIconTextButton";
import styles from "./BusinessShiftCreateEdit.module.scss";
import BusinessShiftCriteriaRow from "screens/business/settingTabs/shifts/partials/BusinessShiftCriteriaRow";
import { ImageAssets } from "globals/images";

export interface BusinessShiftCreateEditProps {
    value?: BusinessShift;
    readonly?: boolean;
    onBack: () => void;
}

const md = 4;
export const BusinessShiftCreateEdit: React.FC<
    BusinessShiftCreateEditProps
> = ({ value, readonly = false, onBack }) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [state, setState] = useState<BusinessShift>(
        defaultTo(value, {
            AreaIds: [] as number[],
        } as BusinessShift)
    );

    // both(useCurrentBusinessSettingsContext and useCurrentLwcContext)
    // return same context as context type is same
    const currentBusiness = useCurrentBusinessSettingsContext();

    const shiftService = new BusinessShiftService(
        linkProvider.business.api.currentBusiness(
            currentBusiness.dataItem?.EncodedId
        ).shift
    );

    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(async () => await shiftService.createUpdate(state), {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${res.Data.Name}' ${t(
                            `common.actions.${
                                state.Id && state.Id > 0
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getBusinessShiftServiceKey("list", {
                            businessId: currentBusiness?.dataItem?.Id,
                        })
                    );
                    onBack();
                }
            },
        });

    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );

    const validityStateManager = useMemo(() => {
        const validationState = validateBusinessShift(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    const {
        isLoading: areasLoading,
        data: areasResponse,
        isRefetching: areasRefetching,
    } = useQuery(
        getCurrentBusinessServiceKey("getBusinessAreas", {
            encodedBusinessId: currentBusiness?.dataItem?.EncodedId,
        }),
        async () => await currentBusinessService.getBusinessAreas()
    );
    // const {
    //     isLoading: functionsLoading,
    //     data: functionsResponse,
    //     isRefetching: functionsRefetching,
    // } = useQuery(
    //     getCurrentBusinessServiceKey("getBusinessShiftCriteriaOptions", {
    //         encodedBusinessId: currentBusiness?.dataItem?.EncodedId,
    //         forType:type
    //     }),
    //     async () =>
    //         await currentBusinessService.getBusinessShiftCriteriaOptions()
    // );

    useEffect(() => {
        if (
            state.AreaIds.length == 0 &&
            areasResponse?.Data &&
            areasResponse.Data.length > 0
        ) {
            setState((old) => ({
                ...old,
                AreaIds: areasResponse.Data.map((x) => Number(x.Value)),
            }));
        }
    }, [areasResponse]);

    const workingAreasOptions =
        areasResponse && areasResponse.Data
            ? areasResponse.Data.map((x) => {
                  return {
                      label: x.Text,
                      value: x.Value,
                      color: undefined,
                  } as CustomColorItemOption;
              })
            : [];
    // const functionOptions =
    //     functionsResponse && functionsResponse.Data
    //         ? functionsResponse.Data.map((x) => {
    //               return {
    //                   label: x.Text,
    //                   value: x.Value,
    //                   color: undefined,
    //               } as CustomColorItemOption;
    //           })
    //         : [];

    return (
        <AppContainer
            showHeader={true}
            onBack={onBack}
            title={
                state.Id && state.Id > 0
                    ? state.Name
                    : t("business.businessShift.createTitle")
            }
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            readonly ||
                            createUpdateLoading ||
                            !validityStateManager.isStateValid(),
                        onClick: () => {
                            createUpdate();
                        },
                    }}
                />
            }
        >
            <Row>
                <Col md={md}>
                    <AppInputField
                        value={state.Name}
                        label={t("business.businessShift.name.name")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                Name: defaultTo(val, ""),
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                    />
                </Col>
                <StartAndEndDateFields
                    colSize={md}
                    appendToBody={true}
                    value={{
                        StartDate: state.StartDate,
                        EndDate: state.EndDate,
                    }}
                    onChange={({ StartDate: startDate, EndDate: endDate }) => {
                        setState({
                            ...state,
                            StartDate: startDate,
                            EndDate: endDate,
                        });
                    }}
                    readonly={readonly}
                />
                <Col md={md}>
                    <Form.Group controlId="workingArea">
                        <Form.Label>
                            {t("business.businessShift.workingArea.name")}
                        </Form.Label>
                        <AppColoredMultiSelect
                            menuPortalTarget={document.body}
                            value={workingAreasOptions.filter(
                                (x) =>
                                    !isNil(x.value) &&
                                    state.AreaIds.filter(
                                        (y) => y == (x.value as number)
                                    ).length > 0
                            )}
                            useOldUi={true}
                            onChange={(value) => {
                                setState({
                                    ...state,
                                    AreaIds: value
                                        .filter((x) => x.value != null)
                                        .map((x) => Number(x.value)),
                                });
                            }}
                            isReadonly={readonly}
                            loading={areasLoading || areasRefetching}
                            data={workingAreasOptions}
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "AreaIds"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col md={md}>
                    <AppTimePicker
                        // appendToBody={true}
                        label={t("business.businessShift.startTime.name")}
                        value={state.StartTime}
                        // useDefault={false}
                        onChange={(val) => {
                            setState((old) => ({
                                ...old,
                                StartTime: defaultTo(val, moment()),
                            }));
                        }}
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "StartTime"
                        )}
                    />
                </Col>
                <Col md={md}>
                    <AppTimePicker
                        // appendToBody={true}
                        label={t("business.businessShift.endTime.name")}
                        value={state.EndTime}
                        // useDefault={false}
                        onChange={(val) => {
                            setState((old) => ({
                                ...old,
                                EndTime: defaultTo(val, moment()),
                            }));
                        }}
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "EndTime"
                        )}
                    />
                </Col>
                <Col md={md}>
                    <AppNumberInput
                        value={state.MinEmployees}
                        label={t("business.businessShift.minEmployees.name")}
                        showError={true}
                        placeholder={t("common.number")}
                        className={styles.minEmployeeHeight}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState({
                                ...state,
                                MinEmployees: val as number,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "MinEmployees"
                        )}
                    />
                </Col>
                <Col md={md}>
                    <AppNumberInput
                        value={state.MaxEmployees}
                        label={t("business.businessShift.maxEmployees.name")}
                        showError={true}
                        placeholder={t("common.number")}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState({
                                ...state,
                                MaxEmployees: val as number,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "MaxEmployees"
                        )}
                    />
                </Col>
                <Col md={md} style={{ marginTop: "25px" }}>
                    <AppSwitch
                        className={styles.hasNoEndDate}
                        label={t("business.businessShift.fairlyPlanned.name")}
                        disabled={readonly}
                        value={state.FairlyPlanned}
                        labelPosition="right"
                        extraContent={
                            <AppTooltip
                                content={
                                    <span>
                                        {t(
                                            "business.businessShift.fairlyPlanned.infoText"
                                        )}
                                    </span>
                                }
                                allowHtml={true}
                                trigger="mouseenter focus"
                                arrow={true}
                                maxWidth="400px"
                            >
                                <Image
                                    className={"ml-2"}
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                        marginTop: "3px",
                                    }}
                                    src={ImageAssets.common.questionCircleBlue}
                                />
                            </AppTooltip>
                        }
                        onChange={(checked) => {
                            setState({
                                ...state,
                                FairlyPlanned: checked,
                            });
                        }}
                    />
                </Col>
            </Row>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <AppIconTextButton
                    padding="med"
                    icon="plus"
                    className={styles.createBtn}
                    text={t("business.businessShift.addNewOption")}
                    disabled={readonly}
                    onClick={() => {
                        setState({
                            ...state,
                            Criterias: [
                                getEmptyShiftCriteria(state.Id),
                                ...defaultTo(state.Criterias, []),
                            ],
                        });
                    }}
                />

                <div>
                    {defaultTo(state.Criterias, []).map((c) => {
                        return (
                            <BusinessShiftCriteriaRow
                                key={c.uuid}
                                businessId={currentBusiness.dataItem?.EncodedId}
                                value={c}
                                readonly={readonly}
                                validityState={
                                    validityStateManager.getFieldState(c.uuid)
                                        ?.children
                                }
                                onChange={(field: BusinessShiftCriteria) => {
                                    setState({
                                        ...state,
                                        Criterias: state.Criterias.map((c) => {
                                            if (c.uuid == field.uuid) {
                                                return field;
                                            } else {
                                                return c;
                                            }
                                        }),
                                    });
                                }}
                                onDelete={(uuid: string) =>
                                    setState({
                                        ...state,
                                        Criterias: state.Criterias.filter(
                                            (x) => x.uuid != uuid
                                        ),
                                    })
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </AppContainer>
    );
};

export default BusinessShiftCreateEdit;
