import { defaultTo } from "lodash-es";
import { BusinessType, getBusinessTypePathString } from "models/business";
import { LillywaitRoutingContext } from "routing/routingContexts";
import { getUrlForPattern } from "routing/routingContexts/helper";

export const validLwcPathPatterns = [
    ":lwcType",
    ...Object.values(BusinessType)
        .filter((t) => t !== BusinessType.LW)
        .map((t) => `${getBusinessTypePathString(t, true).toLowerCase()}s`),
];
export const getLwcPathPattern = (lwcType?: BusinessType | ":lwcType") => {
    return validLwcPathPatterns.includes(lwcType as any)
        ? lwcType
        : `${getBusinessTypePathString(
              defaultTo(lwcType as BusinessType, BusinessType.KINDERGARTEN),
              true
          ).toLocaleLowerCase()}s`;
};
export const getLwcTypeFromPathPattern = (lwcPattern: string) => {
    return validLwcPathPatterns.includes(lwcPattern)
        ? lwcPattern === getLwcPathPattern(BusinessType.KINDERGARTEN)
            ? BusinessType.KINDERGARTEN
            : lwcPattern === getLwcPathPattern(BusinessType.SCHOOL)
            ? BusinessType.SCHOOL
            : lwcPattern === getLwcPathPattern(BusinessType.INDUSTRY)
            ? BusinessType.INDUSTRY
            : BusinessType.ELDERLY_CARE
        : BusinessType.KINDERGARTEN;
};

export const lwSupportRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: LillywaitRoutingContext = new LillywaitRoutingContext()
) => {
    const sectionBase = `support`;
    const sectionApiBase = `support`;
    const base = `${context.getBaseUrl()}/${sectionBase}`;
    const apiBase = `${context.getApiBaseUrl()}/${sectionApiBase}`;

    return {
        base: base,
        apiBase: apiBase,
        api: {
            enterSupportView: (queryData: { forLwcId: number }) => {
                return getUrlForPattern(
                    context,
                    true,
                    `${sectionApiBase}/enter-support-mode`,
                    true,
                    undefined,
                    queryData
                );
            },
            exitSupportView: () => {
                return getUrlForPattern(
                    context,
                    true,
                    `${sectionApiBase}/exit-support-mode`,
                    true
                );
            },
            mergeCompanies: (queryData: {
                sourceId: number;
                targetId: number;
            }) => {
                return getUrlForPattern(
                    context,
                    true,
                    `${sectionApiBase}/merge-company`,
                    true,
                    undefined,
                    queryData
                );
            },
        },
        screens: {
            list: (type: BusinessType | ":lwcType" = ":lwcType") => {
                return getUrlForPattern(
                    context,
                    buildUrl,
                    `${sectionBase}/${getLwcPathPattern(type)}`
                );
            },
            mergeCompanies: () => {
                return getUrlForPattern(
                    context,
                    buildUrl,
                    `${sectionBase}/merge-companies`
                );
            },
        },
    };
};

export default lwSupportRoutes;
