import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { DeleteWithPassword } from "commonPartials/deleteWithPassword";
import BooleanTickCrossIcon from "components/BooleanTickCrossIcon";
import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    showSwalLoader,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useSupportMode } from "hooks/useSupportMode";
import { LwClientListItem } from "models";
import { BusinessType, CompanyType } from "models/business";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { BusinessService, getBusinessServiceKey } from "services/business";
import styles from "./LwClientRowContent.module.scss";

export interface LwClientRowContentProps {
    value: LwClientListItem;
    onClick?: () => void;
    onDelete: (hqId: number, businessId?: number) => void;
    isSupportTable?: boolean;
    subRow?: boolean;
    canDelete?: boolean;
}

export const LwClientRowContent: React.FC<LwClientRowContentProps> = ({
    value,
    onClick,
    onDelete,
    isSupportTable = false,
    subRow = false,
    canDelete = false,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const showErrorPage = useShowErrorPage();
    const [buttonHover, setButtonHover] = useState<boolean>(false);
    const { enterSupportMode } = useSupportMode();
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    const businessService = new BusinessService(linkProvider.business.api);
    const {
        isLoading: deletingLwc,
        data: deleteLwcResponse,
        reset: deleteLwcReset,
        isError: deleteLwcError,
        mutate: deleteLwc,
    } = useMutation(
        getBusinessServiceKey("deleteLwc"),
        async (data: {
            masterPassword: string;
            password: string;
            businessId: number;
            hqId: number | null;
        }) =>
            await businessService.deleteLwc(
                data.masterPassword,
                data.password,
                data.businessId,
                data.hqId
            )
    );
    const showError = (message: string) => {
        showSweetAlertInfo(
            t("common.error.error"),
            message,
            "error",
            () => {},
            t("common.cancel")
        );
    };
    useEffect(() => {
        if (!deletingLwc && deleteLwcResponse) {
            if (deleteLwcResponse.Data) {
                showSweetAlertInfo(
                    t("common.success"),
                    t(`deleteLw.successMessage`),
                    "success",
                    () => setDeleteOpen(false)
                );
                onDelete(
                    value.HqId,
                    value.BranchType == CompanyType.Headquarter
                        ? undefined
                        : value.Id
                );
            } else {
                showError(t(`deleteLwc.errorMessage`));
            }
            deleteLwcReset();
        } else if (deleteLwcError) {
            showError(t(`passwordDialog.incorrectPassword`));
            deleteLwcReset();
        }
    }, [deletingLwc, deleteLwcResponse, deleteLwcError, deleteLwcReset]);
    onClick = !isSupportTable ? onClick : undefined;
    return (
        <>
            {deleteOpen && (
                <DeleteWithPassword
                    opened={deleteOpen}
                    requireMasterPassword={true}
                    onClose={() => setDeleteOpen(false)}
                    buttonText={t(`deleteLwc.delete`)}
                    onSubmit={(password: string, masterPassword?: string) => {
                        deleteLwc({
                            masterPassword: masterPassword as string,
                            password: password,
                            businessId: value.Id,
                            hqId:
                                value.BranchType == CompanyType.Headquarter
                                    ? value.HqId
                                    : null,
                        });
                        showSwalLoader(t(`deleteLwc.progressMessage`), "");
                    }}
                />
            )}
            {/* specifying sizes here because it will be rendered in sub-table as well */}
            <td onClick={onClick} style={getFixedCssWidths(80, false)}>
                <span>{value.DisplayId}</span>
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(250, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.CompanyName}</span>
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(250, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.Name}</span>
            </td>
            <td onClick={onClick} style={getFixedCssWidths(200, false)}>
                {value.State}
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(200, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.City}</span>
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(200, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.ActiveClients}</span>
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(200, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.ActiveEmployees}</span>
            </td>
            <td onClick={onClick} style={getFixedCssWidths(120, false)}>
                <BooleanTickCrossIcon value={value.IsHQ} />
            </td>
            <td onClick={onClick} style={getFixedCssWidths(170, false)}>
                {t(`common.status.${value.Status}`)}
            </td>
            {isSupportTable ? (
                <td
                    style={{
                        ...getFixedCssWidths(subRow ? 165 : 170),
                        padding: "4px 5px",
                        textAlign: "end",
                    }}
                >
                    <Button
                        size="sm"
                        variant={"outline-success"}
                        className={cx("w-100 btn-transition", styles.button, {
                            "text-white": buttonHover,
                        })}
                        style={{ width: "100% !important" }}
                        onMouseEnter={() => setButtonHover(true)}
                        onMouseLeave={() => setButtonHover(false)}
                        onClick={() => {
                            // enter support mode
                            if (
                                value.Type === BusinessType.KINDERGARTEN ||
                                value.Type === BusinessType.INDUSTRY
                            ) {
                                // currently only kindergarten has support-view
                                enterSupportMode(value.Id);
                            } else {
                                // not implemented page
                                showErrorPage(501);
                            }
                        }}
                    >
                        <>
                            <span className="pr-2">
                                <FontAwesomeIcon icon="headphones" />
                            </span>
                            <span className={styles.text}>
                                {t(`lwClient.openSupport`)}
                            </span>
                        </>
                    </Button>
                </td>
            ) : (
                <td
                    style={{
                        ...getFixedCssWidths(65),
                    }}
                >
                    <AppDeleteButton
                        onClick={() => {
                            setDeleteOpen(true);
                        }}
                        readonly={!canDelete || deletingLwc}
                        getConfirmation={true}
                        name={`${value.Name}${
                            value.BranchType == CompanyType.Headquarter
                                ? " (HQ)"
                                : ""
                        }`}
                    />
                </td>
            )}
        </>
    );
};

export default LwClientRowContent;
