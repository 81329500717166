import classNames from "classnames";
import {
    EmployeeClientFiltersComponent,
    StartAndEndDateFields,
} from "commonPartials";
import { AppSwitch, ErrorMessage } from "components";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppAttachedFilesList, AppFileInput } from "components/File";
import {
    AppDatePicker,
    AppInputField,
    AppTimePicker,
} from "components/FormFields";
import { AppLoader } from "components/Loaders";
import TinymceEditor, { EditorState } from "components/TinymceEditor";
import { getFileDisplayName } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useWindowSize } from "hooks/general/reactHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { BusinessType, Business_Calendar, PermissionAccessTypes } from "models";
import {
    CalendarEventTemplate,
    CalendarEventTemplateDetails,
    CalendarEventTemplateResponse,
    getDefaultCalendarEventTemplate,
    getParsedCalendarEvent,
    validateCalenderTemplate,
} from "models/calendar";
import {
    Optional,
    TemplateFile,
    UserFilterType,
    ValidityStateManager,
} from "models/general";
import moment, { Moment } from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, ModalProps, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { CalendarService, getCalendarServiceKey } from "services/business";
import styles from "./CalendarEventDialog.module.scss";

export interface CalendarEventDialogProps extends ModalProps {
    modalOpen: boolean;
    businessType: BusinessType;
    onClose: () => void;
    onChange: (event: CalendarEventTemplate) => void;
    onDelete: (id: string) => void;
    templateId: Optional<string>;
    readonly?: boolean;
    date?: Optional<Moment>;
}

export function CalendarEventDialog(props: CalendarEventDialogProps) {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const {
        onClose,
        modalOpen,
        templateId,
        businessType,
        onChange,
        onDelete,
        date,
        readonly = false,
        ...rest
    } = props;
    const calendarService = new CalendarService(
        linkProvider.business.api.calendar
    );
    const isNewEvent = isNil(templateId);
    const currDate = defaultTo(date, moment());
    const { width: windowWidth } = useWindowSize();
    const [templateSet, setTemplateLoaded] = useState<boolean>(false);
    const [template, setTemplate] = useState<CalendarEventTemplateDetails>(
        getDefaultCalendarEventTemplate(currDate, isNewEvent)
    );

    const hasCreatePermission = checkPermission(Business_Calendar, [
        PermissionAccessTypes.CREATE,
    ]);

    const hasDeletePermission = checkPermission(Business_Calendar, [
        PermissionAccessTypes.DELETE,
    ]);

    const {
        isLoading: creating,
        data: createResponse,
        mutate: createEditEvent,
    } = useMutation(
        async (template: CalendarEventTemplateDetails) =>
            await calendarService.createUpdate(template)
    );

    const {
        isFetching: loading,
        data: loadedTemplate,
        refetch: refetchTemplate,
    } = useQuery(
        getCalendarServiceKey("getCalendarEventDetails", {
            templateId: templateId,
        }),
        async () =>
            await calendarService.getCalendarEventDetails(templateId as string),
        {
            enabled: false,
            cacheTime: 0,
            select: (res) =>
                res.Data
                    ? (getParsedCalendarEvent(
                          res.Data
                      ) as CalendarEventTemplateDetails)
                    : null,
        }
    );

    const {
        isLoading: deleting,
        data: deleteResponse,
        mutate: deleteEvent,
    } = useMutation(
        async (templateId: string) => await calendarService.delete(templateId)
    );
    const {
        mutate: deleteEventFile,
        isLoading: deletingFile,
        data: deleteFileResponse,
        reset: resetDeleteFile,
    } = useMutation(
        async (data: { templateId: string; fileId: number }) =>
            await calendarService.deleteFile(data.templateId, data.fileId)
    );

    const validityStateManager = useMemo(() => {
        const state = validateCalenderTemplate(template);
        return new ValidityStateManager(state);
        // return getValidityStateFromApiResponse(createResponse, validityState);
    }, [createResponse, template, deleteResponse]);

    useEffect(() => {
        if (!isNewEvent) {
            refetchTemplate();
        }
    }, []);

    useEffect(() => {
        if (!loading && templateId && loadedTemplate) {
            setTemplate({
                ...loadedTemplate,
            });
            setTimeout(() => {
                setTemplateLoaded(true);
            }, 100);
        }
    }, [loading]);

    useEffect(() => {
        if (!creating && createResponse) {
            if (!createResponse.Errors) {
                showSweetAlertToast(
                    `${t("calendar.event").toString()} ${t(
                        "common.actions.updatedSuccessfully"
                    ).toString()}`,
                    "",
                    "success"
                );
                onChange(
                    getParsedCalendarEvent(
                        createResponse.Data as CalendarEventTemplateResponse
                    ) as CalendarEventTemplate
                );
            } else {
                let errorHtml = "";
                createResponse.Errors.forEach((error) => {
                    errorHtml = errorHtml + error.Message + "<br/>";
                });
                showSweetAlertToast(
                    t("common.error.error"),
                    errorHtml,
                    "error"
                );
            }
        }
    }, [createResponse, creating]);

    useEffect(() => {
        if (!deleting && deleteResponse) {
            if (!deleteResponse.Errors && templateId) {
                onDelete(templateId);
                showSweetAlertToast(
                    `${t("calendar.event").toString()} ${t(
                        "common.actions.deletedSuccessfully"
                    ).toString()}`,
                    "",
                    "success"
                );
            }
        }
    }, [deleteResponse, deleting]);

    const editorState = useMemo((): EditorState => {
        const defaultVal = {
            html: "",
            placeholders: [],
        };
        if (isNewEvent) {
            return defaultVal;
        } else if (!loading && loadedTemplate && template.Body) {
            return {
                html: template.Body.Template,
                placeholders: template.Body.Placeholders,
            };
        } else {
            return defaultVal;
        }
    }, [loading, template]);

    useEffect(() => {
        if (!deletingFile && deleteFileResponse) {
            const deletedFile = template.Files?.find(
                (f) => f.Id === deleteFileResponse.Data
            );

            if (deletedFile) {
                setTemplate({
                    ...template,
                    Files: template.Files?.filter(
                        (x) => x.Id != deleteFileResponse.Data
                    ),
                });
                showSweetAlertToast(
                    t("common.success"),
                    `"${
                        deletedFile ? `${getFileDisplayName(deletedFile)} ` : ""
                    }"${t("common.actions.fileDeletedSuccessfully")}`,
                    "success"
                );
            }
            resetDeleteFile();
        }
    }, [deletingFile, deleteFileResponse]);
    const buttonDisabled = loading || creating || deleting;
    const isReadonly = readonly || defaultTo(template.ForTodoId, 0) > 0;
    const canEdit = !isReadonly;
    return (
        <React.Fragment>
            <AppDialog
                modalOpen={modalOpen}
                size="lg"
                onClose={onClose}
                header={
                    <div className={styles.header}>
                        <span className={styles.title}>
                            {t(
                                `calendar.${
                                    templateId ? "updateEvent" : "newEvent"
                                }`
                            )}
                        </span>
                        {!isNewEvent ? (
                            <span className={styles.writer}>
                                {!isNil(template.UpdatedBy) &&
                                template.UpdatedBy.Name
                                    ? `${template.UpdatedBy.Name}${
                                          template.CreatedBy &&
                                          template.CreatedBy.Id !=
                                              template.UpdatedBy.Id
                                              ? ` (${t(
                                                    "calendar.createdBy"
                                                )}: ${
                                                    template.CreatedBy.Name // show only if creator is different
                                                })`
                                              : ""
                                      }`
                                    : ""}
                            </span>
                        ) : (
                            <></>
                        )}
                    </div>
                }
                footer={
                    <AppDialogFooter
                        disableSaveButton={
                            buttonDisabled ||
                            !validityStateManager.isStateValid() ||
                            !hasCreatePermission ||
                            !canEdit
                        }
                        disableDeleteButton={
                            deleting ||
                            templateId == null ||
                            !hasDeletePermission ||
                            isReadonly
                        }
                        onDialogClose={onClose}
                        onClickSaveButton={() => {
                            createEditEvent(template);
                        }}
                        showDeleteBtn={!isNewEvent}
                        onClickDeleteButton={() => {
                            deleteEvent(templateId as string);
                        }}
                    />
                }
                {...rest}
            >
                {templateId != null &&
                (loading || !template || (!templateSet && !isNewEvent)) ? (
                    <AppLoader />
                ) : (
                    <>
                        <Row>
                            <StartAndEndDateFields
                                useMonthPicker={false}
                                appendToBody={false}
                                startLabel={t("calendar.eventDay.name")}
                                toggleLabel={t("calendar.singleDayEvent")}
                                allowSameEndDate={false}
                                value={{
                                    StartDate: template.EventDay as Moment,
                                    EndDate: template.EndDate,
                                }}
                                onChange={({
                                    StartDate: startDate,
                                    EndDate: endDate,
                                }) => {
                                    setTemplate({
                                        ...template,
                                        EventDay: startDate,
                                        EndDate: endDate,
                                    });
                                }}
                            />
                            {!template.AllDay && (
                                <>
                                    <Col sm={6}>
                                        <AppTimePicker
                                            readOnly={!canEdit}
                                            label={t("calendar.startTime.name")}
                                            className={styles.label}
                                            placeholder={t(
                                                "calendar.startTime.name"
                                            )}
                                            value={template.StartTime}
                                            onChange={(val) => {
                                                setTemplate((template) => ({
                                                    ...template,
                                                    StartTime: val,
                                                }));
                                            }}
                                            error={validityStateManager.getFirstErrorInfo(
                                                "StartTime"
                                            )}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <AppTimePicker
                                            readOnly={!canEdit}
                                            className={styles.label}
                                            label={t("calendar.endTime.name")}
                                            placeholder={t(
                                                "calendar.endTime.name"
                                            )}
                                            value={template.EndTime}
                                            onChange={(val) => {
                                                setTemplate((template) => ({
                                                    ...template,
                                                    EndTime: val,
                                                }));
                                            }}
                                            error={validityStateManager.getFirstErrorInfo(
                                                "EndTime"
                                            )}
                                        />
                                    </Col>
                                </>
                            )}

                            <Col lg={6}>
                                <AppInputField
                                    disabled={!canEdit}
                                    label={t("calendar.title.name")}
                                    placeholder={t("calendar.title.name")}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "title"
                                    )}
                                    value={template.Title}
                                    showEmptyError={true}
                                    onValueChange={(val) =>
                                        setTemplate({
                                            ...template,
                                            Title: val,
                                        })
                                    }
                                />
                            </Col>
                            <Col lg={6}>
                                <div className={styles.checkBoxRow}>
                                    <AppSwitch
                                        label={t("calendar.fullDayEvent")}
                                        disabled={!canEdit}
                                        className={styles.switch}
                                        value={template.AllDay}
                                        labelPosition="left"
                                        color="blue-toggle"
                                        onChange={(checked) =>
                                            setTemplate({
                                                ...template,
                                                AllDay: checked,
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={12}
                                className={"mb-1"}
                                style={{ height: "200px" }}
                            >
                                {editorState &&
                                    (isNewEvent ||
                                        (!isNewEvent && templateSet)) && (
                                        <>
                                            <Form.Label
                                                className={styles.label}
                                            >
                                                {t("message.name")}
                                            </Form.Label>
                                            <TinymceEditor
                                                value={editorState}
                                                readonly={!canEdit}
                                                tinymceProps={{
                                                    readonly: isReadonly,
                                                    menubar: false,
                                                    contextmenu: false,
                                                    max_height: 150,
                                                    min_height: 150,
                                                    toolbar: false,
                                                }}
                                                onChange={(newState) => {
                                                    setTemplate((t) => ({
                                                        ...t,
                                                        // files: fD,
                                                        Body: {
                                                            ...(t.Body
                                                                ? t.Body
                                                                : {
                                                                      Text: null,
                                                                  }),
                                                            Template:
                                                                newState.html,
                                                            Placeholders:
                                                                newState.placeholders,
                                                        },
                                                    }));
                                                }}
                                            />
                                            <ErrorMessage
                                                showEmpty={true}
                                                errorInfo={validityStateManager.getFirstErrorInfo(
                                                    "body"
                                                )}
                                            />
                                        </>
                                    )}
                            </Col>
                        </Row>
                        <div
                            className={styles.bottomRow}
                            style={
                                !template.SendThisEvent && windowWidth > 800
                                    ? { height: "150px" }
                                    : undefined
                            }
                        >
                            <div>
                                <AppFileInput
                                    disabled={!canEdit}
                                    files={template.files}
                                    onFilesUpload={(files) => {
                                        setTemplate((template) => ({
                                            ...template,
                                            files: files,
                                        }));
                                    }}
                                    multiple={true}
                                    readOnly={true}
                                    label={t("calendar.files.name")}
                                    placeholder={t(
                                        "calendar.files.placeholder"
                                    )}
                                    className={styles.label}
                                    readonly={!hasCreatePermission || !canEdit}
                                />
                                <AppSwitch
                                    label={t("calendar.sendThisEvent")}
                                    disabled={!canEdit}
                                    className={classNames(
                                        styles.switch,
                                        "mt-2 mb-2"
                                    )}
                                    value={template.SendThisEvent}
                                    labelPosition="left"
                                    color="blue-toggle"
                                    onChange={(checked) => {
                                        if (checked) {
                                            setTemplate({
                                                ...template,
                                                SendThisEvent: checked,
                                            });
                                        } else {
                                            setTemplate({
                                                ...template,
                                                SendThisEvent: checked,
                                                ClientIds: null,
                                                EmployeeIds: null,
                                                GroupIds: null,
                                                ReceiptTypes: null,
                                            });
                                        }
                                    }}
                                />
                                {template.SendThisEvent &&
                                    (!templateId || templateSet) && (
                                        <EmployeeClientFiltersComponent
                                            allowedUserTypes={
                                                businessType !=
                                                BusinessType.KINDERGARTEN
                                                    ? [
                                                          UserFilterType.Employee,
                                                          UserFilterType.Supplier,
                                                      ]
                                                    : undefined
                                            }
                                            orientation={"vertical"}
                                            className={`${styles.receiptsSelectors} ${styles.label}`}
                                            value={{
                                                clientIds: template.ClientIds,
                                                employeeIds:
                                                    template.EmployeeIds,
                                                groupIds: template.GroupIds,
                                                userType: template.ReceiptTypes,
                                            }}
                                            originalValue={
                                                loadedTemplate
                                                    ? {
                                                          clientIds:
                                                              loadedTemplate.ClientIds,
                                                          employeeIds:
                                                              loadedTemplate.EmployeeIds,
                                                          groupIds:
                                                              loadedTemplate.GroupIds,
                                                          userType:
                                                              loadedTemplate.ReceiptTypes,
                                                      }
                                                    : undefined
                                            }
                                            fetchOnlyActiveResources={true}
                                            readonly={
                                                !template.SendThisEvent ||
                                                !canEdit
                                            }
                                            onChange={(newFilters) => {
                                                setTemplate({
                                                    ...template,
                                                    ClientIds:
                                                        newFilters.clientIds,
                                                    EmployeeIds:
                                                        newFilters.employeeIds,
                                                    GroupIds:
                                                        newFilters.groupIds,
                                                    ReceiptTypes:
                                                        newFilters.userType,
                                                });
                                            }}
                                        />
                                    )}
                            </div>
                            <div className={styles.filesView}>
                                <AppAttachedFilesList
                                    className={
                                        template.Files &&
                                        template.Files.length > 0
                                            ? "pb-3"
                                            : ""
                                    }
                                    files={
                                        template.Files &&
                                        template.Files.map((x) => {
                                            return {
                                                ...x,
                                                ParentId:
                                                    x.CalendarEventTemplateId,
                                            } as TemplateFile;
                                        })
                                    }
                                    onFileDelete={(fileId) => {
                                        deleteEventFile({
                                            templateId:
                                                template.EncodedId as string,
                                            fileId: fileId,
                                        });
                                    }}
                                    canDeleteFile={canEdit && !deletingFile}
                                />
                            </div>
                        </div>
                    </>
                )}
            </AppDialog>
        </React.Fragment>
    );
}

export default CalendarEventDialog;
