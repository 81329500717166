import { AppCheckbox } from "components/FormFields";
import { getGenderNumberToEnumValue } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import { isNil } from "lodash-es";
import {
    getKindergartenClientContactInitialValues,
    KindergartenClient,
    KindergartenClientContactInfo,
    KindergartenClientContactType,
} from "models/kindergartenClient";
import {
    checkPermissionInMap,
    Kindergarten_Client_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ClientContactInfoFields } from "screens/kindergarten/partials/ClientContactInfoFields";
import { KindergartenClientService } from "services/kindergarten";
import { ClientContactActionMenu } from "../../partials/ClientContactActionMenu";

export interface ClientContactInfoEditProps {
    title: string;
    clientContactType: KindergartenClientContactType;
    completeData: KindergartenClient;
    contactData: KindergartenClientContactInfo;
    indexId: number;
    onCustodianChange: (client: KindergartenClient) => void;
}
export const ClientContactInfoEdit: React.FC<ClientContactInfoEditProps> = ({
    title,
    completeData,
    onCustodianChange,
    clientContactType,
    contactData,
    indexId,
}) => {
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();

    const permMap = useMemo(() => {
        return getPermissionMap(Kindergarten_Client_BasicData);
    }, [getPermissionMap]);

    const hasDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const [state, setState] = useState<KindergartenClientContactInfo>(
        getKindergartenClientContactInitialValues(completeData.Id)
    );

    useEffect(() => {
        if (contactData) {
            setState(contactData);
        }
    }, [contactData]);

    const { t } = useTranslation();

    const clientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );
    const {
        isLoading: deleteLoading,
        data: deleteResponse,
        mutate: deleteClientContact,
    } = useMutation(
        async (val: { contactId: number; updateToPrimary: boolean }) =>
            await clientService.deleteClientContact({ ...val })
    );

    const {
        isLoading: loadingCreate,
        data: createResponse,
        mutate: create,
    } = useMutation(
        async (val: KindergartenClientContactInfo) =>
            await clientService.clientContactAddUpdate(val)
    );

    useEffect(() => {
        if (createResponse && !loadingCreate) {
            if (createResponse.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createResponse.Data.FirstName} ${
                        createResponse.Data.LastName
                    }" ${t("common.actions.updatedSuccessfully")}`,
                    "success"
                );

                const newContact = {
                    ...createResponse.Data,
                    Gender: isNil(createResponse.Data.Gender)
                        ? null
                        : getGenderNumberToEnumValue(
                              Number(createResponse.Data.Gender)
                          ),
                };
                setState(newContact);
                onCustodianChange({
                    ...completeData,
                    Contacts: completeData.Contacts.map((value, index) => {
                        if (index == indexId) {
                            return newContact;
                        }
                        return value;
                    }),
                });
            } else if (createResponse.Message) {
                showSweetAlertToast(
                    t("common.error.error"),
                    createResponse.Message,
                    "error"
                );
            }
        }
    }, [createResponse, loadingCreate]);

    const deleteFromContactsList = () => {
        onCustodianChange({
            ...completeData,
            Contacts: completeData.Contacts.map((v, index) => {
                if (index != indexId) {
                    return v;
                } else {
                    return {
                        ...getKindergartenClientContactInitialValues(
                            completeData.Id as number
                        ),
                        IsPrimaryContact:
                            clientContactType ==
                            KindergartenClientContactType.TYPE_PRIMARY,
                    };
                }
            }),
        });
    };
    const deleteClientContactHandler = () => {
        if (state.Id) {
            deleteClientContact({
                contactId: state.Id,
                updateToPrimary: false, // don't change primary
            });
        } else {
            deleteFromContactsList();
        }
    };
    const custodianNameStr = `${defaultTo(state.FirstName, "")} ${defaultTo(
        state.LastName,
        ""
    )}`;
    useEffect(() => {
        if (!deleteLoading && deleteResponse && deleteResponse.Data) {
            showSweetAlertToast(
                t("common.success"),
                `"${custodianNameStr}" ${t(
                    "common.actions.deletedSuccessfully"
                )}`,
                "success"
            );
            deleteFromContactsList();
        }
    }, [deleteLoading, deleteResponse]);

    return (
        <ClientContactInfoFields
            isEmailInUse={(email: string) =>
                completeData.Contacts.filter(
                    (x, index) => x.Email === email && index != indexId
                ).length === 1
            }
            allowNewSurName={true}
            title={title}
            permMap={permMap}
            loading={loadingCreate}
            value={state}
            responseErrors={createResponse?.Errors ? createResponse : undefined}
            onChange={(val) => setState((s) => ({ ...s, ...val }))}
            onSave={() => create(state)}
            HeaderActionMenu={
                <ClientContactActionMenu
                    onAddNew={() =>
                        onCustodianChange({
                            ...completeData,
                            Contacts: [
                                ...completeData.Contacts,
                                getKindergartenClientContactInitialValues(
                                    completeData.Id
                                ),
                            ],
                        })
                    }
                    deleteName={custodianNameStr}
                    onDelete={deleteClientContactHandler}
                    showAddNew={completeData.Contacts.length < 2}
                    showDelete={
                        defaultTo(contactData.Id, 0) > 0 ||
                        completeData.Contacts.length > 1
                    }
                    readonly={!hasDeletePermission}
                />
            }
            TypeCheckbox={
                <AppCheckbox
                    label={t(
                        "kindergartenClient.contactInfo.parentRepresentative"
                    )}
                    checked={state.IsParentRepresentative}
                    disabled={!permMap.EDIT}
                    onChange={(e) =>
                        setState({
                            ...state,
                            IsParentRepresentative: e.target.checked,
                        })
                    }
                />
            }
        />
    );
};

export default ClientContactInfoEdit;
