import { PdfViewerPage } from "commonPartials/pdfViewer";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { isNil } from "lodash-es";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const DOCUMENT_CACHE_PARAM_KEY = "cacheKey";
export const DOCUMENT_NAME_PARAM_KEY = "name";

export const DocumentPreview: React.FC = () => {
    const [searchParams] = useSearchParams();
    const cacheKey = searchParams.get(DOCUMENT_CACHE_PARAM_KEY);
    const nameKey = searchParams.get(DOCUMENT_NAME_PARAM_KEY);
    const showErrorPage = useShowErrorPage();

    useEffect(() => {
        if (isNil(cacheKey) || cacheKey.length === 0) {
            showErrorPage(404);
        }
    }, []);

    return (
        <PdfViewerPage
            openInNewTab={true}
            docKey={cacheKey?.toString()}
            name={nameKey?.toString()}
        />
    );
};

export default DocumentPreview;
