import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    getFilteredArray,
    getFixedCssWidths,
    getUniqueList,
} from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, orderBy, uniqBy } from "lodash-es";
import {
    BusinessEmployeeBenefit,
    EmployeeBenefitRequestParameters,
    EmployeeBenefitSortColumn,
    EmployeeBenefitStatus,
    EmployeeWithAdditionalBenefits,
    getBusinessEmployeeBenefitFromResponse,
    getEmployeeBenefitSortColumnKeyFromEnum,
} from "models/employeeAdditionalBenefit";
import { sortData, SortOrder } from "models/general";
import {
    Business_Payroll_AdditionalBenefits,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    EmployeeAdditionalBenefitService,
    getEmployeeAdditionalBenefitServiceKey,
} from "services/business";
import {
    AdditionalBenefitCreateEditDialog,
    EmployeeAdditionalBenefitFilterRow,
    EmployeeAdditionalBenefitRow,
} from "./partials";

interface EmployeeAdditionalBenefitState {
    filter: EmployeeBenefitRequestParameters;
    list: BusinessEmployeeBenefit[];
    openDialog: boolean;
    benefitId?: string; //in case of edit
}

const COLUMNS_COUNT = 9;
export const EmployeeAdditionalBenefits: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();

    const hasCreatePermission = checkPermission(
        Business_Payroll_AdditionalBenefits,
        [PermissionAccessTypes.CREATE]
    );
    const { id: sessionBusinessId } = useSessionBusiness();

    const [state, setState] = useState<EmployeeAdditionalBenefitState>({
        openDialog: false,
        filter: {
            SortColumn: EmployeeBenefitSortColumn.DisplayId,
            SortOrder: SortOrder.DESC,
            Status: EmployeeBenefitStatus.Active,
        } as EmployeeBenefitRequestParameters,
        list: [],
    });

    const employeeBenefitService = new EmployeeAdditionalBenefitService(
        linkProvider.business.api.additionalBenefits
    );

    const { mutate: addUpdateBenefit } = useMutation(
        async (value: BusinessEmployeeBenefit) => {
            if (value.Id) {
                return await employeeBenefitService.update(value);
            } else {
                return await employeeBenefitService.create(value);
            }
        },
        {
            onSuccess: (res) => {
                const benefit = getBusinessEmployeeBenefitFromResponse(
                    res.Data
                );
                const found = state.list.filter((x) => x.Id == res.Data.Id);

                let newList = state.list;
                if (found.length > 0) {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${res.Data.Reason}' ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );

                    newList = state.list.map((x) => {
                        if (benefit.Id == x.Id) {
                            return benefit;
                        } else {
                            return x;
                        }
                    });
                } else {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${res.Data.Reason}' ${t(
                            "common.actions.createdSuccessfully"
                        )}`,
                        "success"
                    );
                    newList = [benefit, ...state.list];
                }

                setState((old) => ({
                    ...old,
                    openDialog: false,
                    benefitId: undefined,
                    list: newList,
                }));
            },
        }
    );

    const { mutate: deleteBenefit } = useMutation(
        async (id: string) => await employeeBenefitService.delete(id),
        {
            onSuccess: (res) => {
                const toDelete = state.list.filter((x) => x.Id == res.Data)[0];
                showSweetAlertToast(
                    t("common.success"),
                    `'${toDelete.Reason}' ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
                setState((old) => ({
                    ...old,
                    list: old.list.filter((x) => x.Id != res.Data),
                }));
            },
        }
    );

    const { isFetching: loading, refetch } = useQuery(
        getEmployeeAdditionalBenefitServiceKey("list", {
            businessId: sessionBusinessId,
            ...state.filter,
        }),
        async () => await employeeBenefitService.list(state.filter),
        {
            enabled: false,
            onSuccess: (resp) => {
                const newList = resp.Data.map((x) =>
                    getBusinessEmployeeBenefitFromResponse(x)
                );
                setState({
                    ...state,
                    list: newList,
                });
            },
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    const onSortChange = (
        sortColumn: EmployeeBenefitSortColumn,
        sortOrder: SortOrder
    ) => {
        setState({
            ...state,
            filter: {
                ...state.filter,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };

    const list = useMemo(() => {
        const filteredList = state.list.filter(
            (b) =>
                b.Employee.Name.toLowerCase().includes(
                    defaultTo(state.filter.EmployeeName, "").toLowerCase()
                ) &&
                (!state.filter.DisplayId ||
                    b.Employee.DisplayId == state.filter.DisplayId) &&
                b.Reason.includes(defaultTo(state.filter.Reason, "")) &&
                (!state.filter.Amount || b.Amount == state.filter.Amount) &&
                (!state.filter.StartDate ||
                    b.StartDate.format(ISO8601_DATE_FORMAT).includes(
                        state.filter.StartDate.format(ISO8601_DATE_FORMAT)
                    )) &&
                (!state.filter.EndDate ||
                    b.EndDate?.format(ISO8601_DATE_FORMAT).includes(
                        state.filter.EndDate.format(ISO8601_DATE_FORMAT)
                    )) &&
                (state.filter.Status == undefined ||
                    b.Status === state.filter.Status)
        );

        let orderList = filteredList;
        orderList = orderBy(
            orderList,
            getEmployeeBenefitSortColumnKeyFromEnum(state.filter.SortColumn),
            state.filter.SortOrder == SortOrder.ASC ? "asc" : "desc"
        );

        // group benefits by employee and re-sort
        const employees = uniqBy(
            orderList
                .map((x) => {
                    return x.Employee;
                })
                .filter((x) => x != undefined),
            (x) => x?.Id
        );
        return employees.map((x) => {
            return {
                ...x,
                BusinessEmployeeBenefits: orderBy(
                    filteredList.filter((b) => b.EmployeeId == x?.Id),
                    getEmployeeBenefitSortColumnKeyFromEnum(
                        state.filter.SortColumn
                    ),
                    state.filter.SortOrder == SortOrder.ASC ? "asc" : "desc"
                ),
            } as EmployeeWithAdditionalBenefits;
        });
    }, [state]);

    return (
        <div>
            <AppContentHeader
                title={t("employeeAdditionalBenefits.table.title")}
                classNameForIcon="pe-7s-calculator"
            >
                {hasCreatePermission && (
                    <AppRoundedTextIconButton
                        onClick={() =>
                            setState({
                                ...state,
                                openDialog: true,
                                benefitId: undefined,
                            })
                        }
                    />
                )}
            </AppContentHeader>
            <div>
                {state.openDialog && (
                    <AdditionalBenefitCreateEditDialog
                        benefitId={state.benefitId}
                        dialogOpen={state.openDialog}
                        onDialogClose={() =>
                            setState({
                                ...state,
                                openDialog: false,
                                benefitId: undefined,
                            })
                        }
                        onSave={(val) => addUpdateBenefit(val)}
                    />
                )}
                <AppTable
                    heightToAdjust={190}
                    mobileViewAdjustment={50}
                    mediumViewAdjustment={50}
                    stickyHeader={true}
                    hover={true}
                >
                    <thead>
                        <tr>
                            <th style={getFixedCssWidths(60)}></th>
                            <AppTableSortColumnIcons
                                width={110}
                                text={t("employeeAdditionalBenefits.table.id")}
                                sortColumn={EmployeeBenefitSortColumn.DisplayId}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.DisplayId
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={300}
                                text={t(
                                    "employeeAdditionalBenefits.table.employeeName"
                                )}
                                sortColumn={
                                    EmployeeBenefitSortColumn.EmployeeName
                                }
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.EmployeeName
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={200}
                                text={t(
                                    "employeeAdditionalBenefits.table.startDate"
                                )}
                                sortColumn={EmployeeBenefitSortColumn.StartDate}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.StartDate
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={200}
                                text={t(
                                    "employeeAdditionalBenefits.table.endDate"
                                )}
                                sortColumn={EmployeeBenefitSortColumn.EndDate}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.EndDate
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={190}
                                text={t(
                                    "employeeAdditionalBenefits.table.status"
                                )}
                                sortColumn={EmployeeBenefitSortColumn.Status}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.Status
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={250}
                                text={t(
                                    "employeeAdditionalBenefits.table.reason"
                                )}
                                sortColumn={EmployeeBenefitSortColumn.Reason}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.Reason
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                width={150}
                                text={t(
                                    "employeeAdditionalBenefits.table.amount"
                                )}
                                sortColumn={EmployeeBenefitSortColumn.Amount}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    EmployeeBenefitSortColumn.Amount
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <th style={getFixedCssWidths(60)}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <EmployeeAdditionalBenefitFilterRow
                            value={state.filter}
                            onChange={(filter) =>
                                setState({ ...state, filter: filter })
                            }
                        />

                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                        {list?.length > 0 ? (
                            list.map((x, idx) => (
                                <EmployeeAdditionalBenefitRow
                                    key={idx}
                                    value={x}
                                    onDeleteClick={(id) => deleteBenefit(id)}
                                    onClick={(id) => {
                                        setState({
                                            ...state,
                                            benefitId: id,
                                            openDialog: true,
                                        });
                                    }}
                                />
                            ))
                        ) : loading ? (
                            <AppTableSkeleton
                                columns={COLUMNS_COUNT}
                                rows={10}
                            />
                        ) : (
                            <tr>
                                <td
                                    colSpan={COLUMNS_COUNT}
                                    style={{ textAlign: "center" }}
                                >
                                    {t(
                                        "employeeAdditionalBenefits.table.noBenefitExist"
                                    )}
                                </td>
                            </tr>
                        )}
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                    </tbody>
                </AppTable>
            </div>
        </div>
    );
};

export default EmployeeAdditionalBenefits;
