import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash-es";
import { BusinessKonto } from "models/konto";
import React from "react";
import { useTranslation } from "react-i18next";

export interface BusinessKontoTableRowProps {
    hasDeletePermission: boolean;
    value: BusinessKonto;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

export const BusinessKontoTableRow: React.FC<BusinessKontoTableRowProps> = ({
    hasDeletePermission,
    value,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <tr
            onClick={() => {
                if (value.EncodedId) {
                    onClick(value.EncodedId);
                } else {
                    onClick(defaultTo(value.Id, 0).toString());
                }
            }}
        >
            <td style={{ paddingLeft: "10px" }}>
                <span>{value.DisplayId}</span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span>{value.Name}</span>
            </td>
            <td style={getFixedCssWidths(70)}>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.Name}
                    readonly={!hasDeletePermission}
                    onClick={() => {
                        if (value.EncodedId) {
                            onDeleteBtnClick(value.EncodedId);
                        } else {
                            onDeleteBtnClick(defaultTo(value.Id, 0).toString());
                        }
                    }}
                />
            </td>
        </tr>
    );
};

export default BusinessKontoTableRow;
