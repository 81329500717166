import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";
import { useAppContext } from "./AppContextProvider";
import { defaultTo } from "lodash-es";
import { stringToAppLocale } from "globals/helpers/localizationHelpers";
import { BaseObject } from "models/general";

// to set app culture
export const CultureProvider: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}: PropsWithChildren<BaseObject>) => {
    //if culture params pas in url
    const { i18n } = useTranslation();
    const { locale, setLocale } = useAppContext();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const paramsCulture = searchParams.get("culture");
        if (paramsCulture) {
            const locale = stringToAppLocale(paramsCulture);
            i18n.changeLanguage(locale);
            setLocale(locale);
        }
    }, [i18n, locale, searchParams]);
    return <>{children}</>;
};

export default CultureProvider;
