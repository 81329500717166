import {
    FORMAT_INTEGER,
    FOUR_PRECISION_NUMERIC,
    ISO8601_DATE_FORMAT,
} from "globals/constants";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import { KindergartenClientOccupancyResponse } from "models";
import { Moment } from "moment-timezone";
import { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OccupancyTotalValue } from "./OccupancyTotalValue";
import PersonInfoPopper from "screens/kindergarten/occupancyReport/partials/PersonInfoPopper";
import styles from "./OccupancyReportTotalsTable.module.scss";
import { getFixedCssWidths } from "globals/helpers/generalHelper";

const COL_SECTION_WIDTH = 530;
export interface OccupancyReportTotalsProps {
    data: KindergartenClientOccupancyResponse;
    months: Moment[];
}
export const OccupancyReportTotalsTable = ({
    data,
    months,
}: OccupancyReportTotalsProps) => {
    const { sessionBusiness } = useSessionBusiness();

    const { formatNumber } = useLocaleHelpers();

    const { t } = useTranslation();

    const columns = 1 + months.length;

    const adjustedTotals = useMemo(() => {
        const dict: { [key in string]: number } = {};

        months.map((m) => {
            dict[m.format(ISO8601_DATE_FORMAT)] = 0;
        });

        // include total of manually added additional kids
        data.Data.map((r) => {
            r.MonthStats.map((ms) => {
                if (defaultTo(ms.AdditionalKids, 0) != 0) {
                    dict[ms.Month.format(ISO8601_DATE_FORMAT)] +=
                        ms.EmpKey * (ms.AdditionalKids as number);
                }
            });
        });

        const totals = data.Totals.map((m) => {
            return {
                ...m,
                TotalNeededEmpKey:
                    m.TotalNeededEmpKey +
                    dict[m.Month.format(ISO8601_DATE_FORMAT)],
            };
        });
        return totals;
    }, [data]);
    return (
        <Table
            borderless
            className={`${styles.table}`}
            style={{
                width: `${450 + months.length * COL_SECTION_WIDTH}px`,
                minWidth: "100%",
            }}
        >
            <thead>
                <tr style={{ height: "2px !important" }}>
                    <th
                        style={{
                            ...getFixedCssWidths(450),
                        }}
                    ></th>
                    {months.map((m) => {
                        return (
                            <th
                                key={m.toISOString()}
                                style={{
                                    width: `${COL_SECTION_WIDTH}px`,
                                    textAlign: "end",
                                }}
                            >
                                {/* <span>
                                    {m.format(DATEPICKER_MONTH_YEAR_FORMAT)}
                                </span> */}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "35px" }}>
                    {/* <td>{t("occupancyPlanning.totals.totalEmpNeeded")}</td> */}
                    <td>{t("occupancyPlanning.totals.total")}</td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <OccupancyTotalValue
                                        className={styles.totalAGKids}
                                        value={total.TotalAgeGroupChilds}
                                        highlightText={false}
                                        numberFormat={FORMAT_INTEGER}
                                    />
                                    <OccupancyTotalValue
                                        value={total.TotalNeededEmpKey}
                                        highlightText={false}
                                        numberFormat={FOUR_PRECISION_NUMERIC}
                                    />
                                </div>
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>{t("occupancyPlanning.totals.totalHoursNeeded")}</td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <OccupancyTotalValue
                                    highlightText={false}
                                    value={
                                        total.TotalNeededEmpKey *
                                        defaultTo(
                                            sessionBusiness?.BusinessSetting
                                                .WorkHoursPerWeek,
                                            39.4
                                        )
                                    }
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>{t("occupancyPlanning.totals.totalHoursAvailable")}</td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <OccupancyTotalValue
                                    highlightText={false}
                                    value={
                                        total.TotalAvailableEmpKey *
                                        defaultTo(
                                            sessionBusiness?.BusinessSetting
                                                .WorkHoursPerWeek,
                                            39.4
                                        )
                                    }
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>{t("occupancyPlanning.totals.differenceHours")}</td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <OccupancyTotalValue
                                    value={
                                        (total.TotalAvailableEmpKey -
                                            total.TotalNeededEmpKey) *
                                        defaultTo(
                                            sessionBusiness?.BusinessSetting
                                                .WorkHoursPerWeek,
                                            39.4
                                        )
                                    }
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>{t("occupancyPlanning.totals.freeOrRequiredEmp")}</td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <OccupancyTotalValue
                                    value={
                                        total.TotalAvailableEmpKey -
                                        total.TotalNeededEmpKey
                                    }
                                    numberFormat={FOUR_PRECISION_NUMERIC}
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>
                        {t("occupancyPlanning.totals.startingClientContracts") +
                            ":"}
                    </td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <PersonInfoPopper
                                    className={styles.totalValue}
                                    persons={total.ClientsInfo.filter((c) =>
                                        total.StartingClientContracts.includes(
                                            c.Id
                                        )
                                    )}
                                    title={t(
                                        "occupancyPlanning.totals.startingClientContracts"
                                    )}
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>
                        {t("occupancyPlanning.totals.endingClientContracts") +
                            ":"}
                    </td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <PersonInfoPopper
                                    className={styles.totalValue}
                                    persons={total.ClientsInfo.filter((c) =>
                                        total.EndingClientContracts.includes(
                                            c.Id
                                        )
                                    )}
                                    title={t(
                                        "occupancyPlanning.totals.endingClientContracts"
                                    )}
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>
                        {t(
                            "occupancyPlanning.totals.startingEmployeeContracts"
                        ) + ":"}
                    </td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <PersonInfoPopper
                                    className={styles.totalValue}
                                    persons={total.EmployeesInfo.filter((c) =>
                                        total.StartingEmployeeContracts.includes(
                                            c.Id
                                        )
                                    )}
                                    title={t(
                                        "occupancyPlanning.totals.startingEmployeeContracts"
                                    )}
                                />
                            </td>
                        );
                    })}
                </tr>
                <tr style={{ height: "35px" }}>
                    <td>
                        {t("occupancyPlanning.totals.endingEmployeeContracts") +
                            ":"}
                    </td>
                    {adjustedTotals.map((total) => {
                        return (
                            <td
                                key={total.Month.toISOString()}
                                className={styles.totalValue}
                            >
                                <PersonInfoPopper
                                    className={styles.totalValue}
                                    persons={total.EmployeesInfo.filter((c) =>
                                        total.EndingEmployeeContracts.includes(
                                            c.Id
                                        )
                                    )}
                                    title={t(
                                        "occupancyPlanning.totals.endingEmployeeContracts"
                                    )}
                                />
                            </td>
                        );
                    })}
                </tr>
            </tbody>
        </Table>
    );
};

export default OccupancyReportTotalsTable;
