import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import { ValidityStateManager, ValidityState } from "models/general";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { useTranslation } from "react-i18next";
import styles from "../CompensationTemplateFieldRow.module.scss";
import { CompensationTemplateCareScopeField } from "models/compensationTemplate";

export interface CompensationTemplateCareScopeFieldRowProps {
    value: CompensationTemplateCareScopeField;
    isLocation?: boolean;
    onChange: (field: CompensationTemplateCareScopeField) => void;
    validityState?: ValidityState;
    displayFieldName: string;
    onDelete?: (uuid: string) => void;
    readonly?: boolean;
}

const Component: React.FC<CompensationTemplateCareScopeFieldRowProps> = ({
    value,
    onChange,
    validityState,
    displayFieldName,
    readonly = false,
}) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <Form.Row>
                    <Col xs={12} md={4} lg={4} xl={3}>
                        <AppInputField
                            label={t("compensationTemplate.ageGroup.name")}
                            value={displayFieldName}
                            disabled={true}
                            onValueChange={(val) => {}}
                            placeholder={t(
                                "compensationTemplate.ageGroup.name"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={4} xl={3}>
                        <AppNumberInput
                            label={t("compensationTemplate.compensation")}
                            showError={true}
                            showEmptyError={true}
                            allowZero={true}
                            isForCurrency={true}
                            formatTheInitialValue={true}
                            readOnly={readonly}
                            value={defaultTo(value.FieldValue?.Value, 0)}
                            onChange={(val) => {
                                onChange({
                                    ...value,
                                    FieldValue: {
                                        ...defaultTo(value.FieldValue, {}),
                                        Value: defaultTo(val, 0),
                                    },
                                });
                            }}
                            placeholder={t("compensationTemplate.compensation")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Value"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
        </div>
    );
};
Component.displayName = "CompensationTemplateCareScopeFieldRow";
export const CompensationTemplateCareScopeFieldRow = React.memo(Component);
export default CompensationTemplateCareScopeFieldRow;
