import React from "react";
import { COLOR_GREY_LIGHTEST } from "theme/themeConstants";
import styles from "./AppCardWithColorUnderline.module.scss";

export interface AppCardWithColorUnderlineProps {
    colorCode: string;
    className?: string;
}
export const AppCardWithColorUnderline: React.FC<
    AppCardWithColorUnderlineProps
> = ({ colorCode = COLOR_GREY_LIGHTEST, className = "", children }) => {
    return (
        <div
            className={`${styles.root} ${className} `}
            style={{ boxShadow: `0px 4px ${colorCode}` }}
        >
            {children}
        </div>
    );
};

export default AppCardWithColorUnderline;
