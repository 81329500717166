import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash-es";
import { BankData } from "models/bankData";
import React from "react";
import { useTranslation } from "react-i18next";

export interface BankDataTableRowProps {
    hasDeletePermission: boolean;
    value: BankData;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

export const BankDataTableRow: React.FC<BankDataTableRowProps> = ({
    hasDeletePermission,
    value,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <tr
            onClick={() => {
                if (value.EncodedId) {
                    onClick(value.EncodedId);
                } else {
                    onClick(defaultTo(value.Id, 0).toString());
                }
            }}
        >
            <td className={"ellipsis-text single-line"}>
                <span style={{ paddingLeft: "10px" }}>{value.IBAN}</span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span>{value.AccountOwner}</span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span>{value.AccountNumber}</span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span>{value.BankName}</span>
            </td>
            <td>
                <span>{value.BankCode}</span>
            </td>
            <td>
                <span>{value.BIC}</span>
            </td>
            <td style={getFixedCssWidths(70)}>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.IBAN}
                    readonly={!hasDeletePermission}
                    onClick={() => {
                        if (value.EncodedId) {
                            onDeleteBtnClick(value.EncodedId);
                        }
                    }}
                />
            </td>
        </tr>
    );
};

export default BankDataTableRow;
