import { AppSelect } from "components/AppSelect";
import AppSelectOld from "components/AppSelect/AppSelectOld";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { LocationDataRequest, ZipCodeItem } from "models/location";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import GeneralService, { getGeneralServiceKey } from "services/GeneralService";

export interface LocationZipcodesSelectComponentProps {
    onChange: (item: ZipCodeItem) => void;
    requestParams: LocationDataRequest;
    value: ZipCodeItem | null;
    readOnly?: boolean;
    useOldUI?: boolean;
}
const Component = ({
    onChange,
    requestParams = {
        CountryCode: "de",
        RequiredResponse: "zipcodes",
    } as LocationDataRequest,
    value,
    readOnly = false,
    useOldUI = true,
}: LocationZipcodesSelectComponentProps) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const defaultItem = {
        Text: t("common.pleaseSelect").toString(),
        Value: "",
    } as ZipCodeItem;
    const generalService = new GeneralService(linkProvider.api.general);

    const {
        isLoading: loading,
        data: items,
        isRefetching,
    } = useQuery(
        getGeneralServiceKey("getLocationData", {
            requestParams,
        }),
        async () => {
            return await generalService.getLocationData(requestParams);
        }
    );

    const [zipCodes, setZipCodes] = useState<ZipCodeItem[]>([]);
    // useEffect(() => {
    //     setZipCodes([]);
    //     refetchLocations(requestParams);
    // }, [requestParams]);

    useEffect(() => {
        if (items && !loading) {
            setZipCodes(items.Data.Zipcodes);
        }
    }, [items, loading]);

    const Component = useOldUI ? AppSelectOld : AppSelect;
    return (
        <Component
            options={[defaultItem, ...zipCodes]}
            useReactWindow={true}
            isLoading={loading}
            value={defaultTo(value, defaultItem)}
            isDisabled={readOnly}
            onChange={(opt: ZipCodeItem) => {
                onChange(opt);
            }}
            getOptionLabel={(opt: ZipCodeItem) => opt.Text}
            getOptionValue={(opt: ZipCodeItem) => opt.Value}
        />
    );
};

Component.displayName = "LocationZipcodesSelectComponent";
export const LocationZipcodesSelectComponent = React.memo(Component);
export default LocationZipcodesSelectComponent;
