import BaseRoutingContext from "./BaseRoutingContext";

export class AuthLessRoutingContext extends BaseRoutingContext {
    static base: string = "account";
    static apiBase: string = "auth";
    getContextIdentifier(): string {
        return "auth-context";
    }
    getBaseUrl() {
        return `${super.getBaseUrl()}/${AuthLessRoutingContext.base}`;
    }
    getApiBaseUrl() {
        return `${super.getApiBaseUrl()}/${AuthLessRoutingContext.apiBase}`;
    }
}

export default AuthLessRoutingContext;
