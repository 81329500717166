import { defaultTo, isNil } from "lodash-es";
import { AppResponse, ErrorCodes, ErrorItem, ErrorResponse } from "./response";

export interface ErrorInfo {
    message: string;
    code: string;
}
export interface FieldState {
    children: ValidityState;
    identifier: string;
    errors: ErrorInfo[];
}

export type ValidityState = FieldState[];

export function getInitializedValidityState(
    state: ValidityState = [],
    identifiers: Readonly<string | string[]> = []
): ValidityState {
    state = defaultTo(state, [] as ValidityState);
    if (typeof identifiers === "string") {
        state = getInitializedValidityState(state, [identifiers]);
    } else {
        identifiers.forEach((f) => {
            if (!state.find((a) => a.identifier === f)) {
                state = [
                    ...state,
                    {
                        children: [],
                        errors: [],
                        identifier: f,
                    },
                ];
            }
        });
    }
    return state;
}

export class ValidityStateManager {
    state: ValidityState;
    constructor(state?: ValidityState) {
        this.state = defaultTo(state, getInitializedValidityState([], []));
    }

    getFieldState(identifier: string): FieldState | undefined {
        return getInitializedValidityState(this.state, identifier).find(
            (a) => a.identifier === identifier
        );
    }

    getFirstErrorInfo(identifier: string) {
        return this.getFieldState(identifier)?.errors[0];
    }
    getAllErrors(identifier: string) {
        return this.getFieldState(identifier)?.errors;
    }

    getErrorInfoForAny(identifiers: Readonly<string[]>) {
        let info: ErrorInfo | undefined = {} as ErrorInfo;
        identifiers.forEach((field) => {
            if (!info) {
                info = this.getFirstErrorInfo(field);
            }
        });
        return info;
    }

    isStateValid(
        recursive = true,
        state: ValidityState = this.state,
        ignoreKeys: string[] = []
    ): boolean {
        state = defaultTo<ValidityState>(state, [] as ValidityState);

        return state.reduce((pre, f) => {
            return (
                ignoreKeys.includes(f.identifier) ||
                (pre &&
                    f.errors.length === 0 &&
                    (!recursive ||
                        this.isStateValid(recursive, f.children, ignoreKeys)))
            );
        }, true as boolean);
    }

    addErrorInfo(identifier: string, error: ErrorInfo) {
        let state = getInitializedValidityState(this.state, identifier);
        state = state.map((a) => {
            if (a.identifier === identifier && error) {
                return {
                    ...a,
                    errors: [...a.errors, error],
                };
            }
            return a;
        });

        return new ValidityStateManager(state);
    }

    addChildren(identifier: string, childrens: ValidityState) {
        let state = getInitializedValidityState(this.state, identifier);
        state = state.map((a) => {
            if (a.identifier === identifier && childrens) {
                return {
                    ...a,
                    children: childrens,
                };
            }
            return a;
        });

        return new ValidityStateManager(state);
    }

    replaceErrors(identifier: string, error: ErrorInfo | null) {
        let state = getInitializedValidityState(this.state, identifier);

        state = state.map((a) => {
            if (a.identifier === identifier) {
                return {
                    ...a,
                    errors: error ? [error] : [],
                };
            }
            return a;
        });

        return new ValidityStateManager(state);
    }

    replaceFieldState(identifier: string, fieldState: FieldState) {
        const old = this.state.find((a) => a.identifier === identifier);
        let state: ValidityState = this.state;
        if (old !== undefined && old !== null) {
            if (isNil(fieldState)) {
                //delete
                state = state.filter((a) => a.identifier !== identifier);
            } else {
                //update
                state = state.map((a) => {
                    if (a.identifier === identifier) {
                        return fieldState;
                    }
                    return a;
                });
            }
        } else {
            //add
            state = [...state, fieldState];
        }
        return new ValidityStateManager(state);
    }
}
export const getFullErrorString = (
    errors: ErrorInfo[],
    separator: string = ""
) => {
    return errors.reduce((resp, err) => {
        return `${resp.length > 0 ? `${resp}${separator} ` : ""}${err.message}`;
    }, "");
};
export const getValidityStateFromApiResponse = (
    resp: AppResponse<any>,
    state = getInitializedValidityState([], [])
) => {
    let manager = new ValidityStateManager(state);
    if (resp && resp.Errors) {
        if (resp.Message) {
            manager = manager.addErrorInfo("GeneralError", {
                code: defaultTo(resp.Code, ""),
                message: resp.Message,
            });
        }
        resp.Errors.forEach((error) => {
            manager = manager.addErrorInfo(error.Field, {
                code: defaultTo(resp.Code, ""),
                message: error.Message,
            });
        });
    }

    return manager.state;
};

export const validityStateToErrorResponse = (
    state: ValidityState
): ErrorResponse => {
    const manager = new ValidityStateManager(state);
    const errorFieldIdentifiers = manager.state
        .filter(
            (x) =>
                x.identifier !== "GeneralError" &&
                (x.errors.length > 0 ||
                    !new ValidityStateManager(x.children).isStateValid())
        )
        .map((x) => x.identifier);
    const generalError = manager.getFirstErrorInfo("GeneralError");

    return {
        Code: generalError
            ? (generalError.code as ErrorCodes)
            : ErrorCodes.VALIDATION_ERROR,
        Message: generalError ? generalError.message : "",
        Errors: errorFieldIdentifiers.map((x) => {
            const errInfo = manager.getFirstErrorInfo(x);
            return {
                Field: x,
                Message: errInfo?.message,
            } as ErrorItem;
        }),
    };
};
