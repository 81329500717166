import { StartAndEndDateFields } from "commonPartials/StartAndEndDateFields";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import ErrorMessage from "components/ErrorMessage";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { AppLoader } from "components/Loaders";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessEmployeeBenefit,
    getBusinessEmployeeBenefitFromResponse,
    getDefaultBenefitValue,
    validateEmployeeAdditionalBenefit,
} from "models/employeeAdditionalBenefit";
import {
    ActiveFilterDataRequest,
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import {
    Business_Payroll_AdditionalBenefits,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    EmployeeAdditionalBenefitService,
    getBusinessGeneralServiceKey,
    getEmployeeAdditionalBenefitServiceKey,
} from "services/business";

export interface AdditionalBenefitCreateEditDialogProps {
    benefitId?: string;
    dialogOpen: boolean;
    onDialogClose: () => void;
    onSave: (value: BusinessEmployeeBenefit) => void;
}

export const AdditionalBenefitCreateEditDialog: React.FC<
    AdditionalBenefitCreateEditDialogProps
> = ({ benefitId, dialogOpen, onDialogClose, onSave }) => {
    const { t } = useTranslation();
    const { id: userId } = useSessionUser();
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();

    let readonly = false;
    if (benefitId) {
        readonly = !checkPermission(Business_Payroll_AdditionalBenefits, [
            PermissionAccessTypes.EDIT,
        ]);
    }

    const { encodedId: encodedBusinessId } = useSessionBusiness();
    const [state, setState] = useState(
        getDefaultBenefitValue(defaultTo(userId?.toString(), ""))
    );

    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    const employeeBenefitService = new EmployeeAdditionalBenefitService(
        linkProvider.business.api.additionalBenefits
    );

    useEffect(() => {
        if (benefitId) {
            refetch();
        }
    }, []);

    const {
        isLoading: getLoading,
        isRefetching: getRefetch,
        refetch,
    } = useQuery(
        getEmployeeAdditionalBenefitServiceKey("get", {
            benefitId,
        }),
        async () => {
            if (benefitId) {
                return await employeeBenefitService.get(benefitId);
            }
        },
        {
            enabled: false,
            onSuccess: (resp) => {
                if (resp?.Data) {
                    setState(getBusinessEmployeeBenefitFromResponse(resp.Data));
                }
            },
        }
    );

    const {
        isLoading: employeesLoading,
        data: employeesResponse,
        isRefetching: employeesRefetching,
    } = useQuery(
        getBusinessGeneralServiceKey("getBusinessEmployees", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () =>
            await businessGeneralService.getBusinessEmployees({
                CheckActive: false,
            } as ActiveFilterDataRequest)
    );

    const selectOption = {
        label: t("common.pleaseSelect"),
        value: undefined,
    } as SimpleOption;
    const employees = employeesResponse?.Data
        ? [
              selectOption,
              ...employeesResponse.Data.map((x) => {
                  return { label: x.Text, value: x.Value } as SimpleOption;
              }),
          ]
        : [selectOption];

    const validityStateManager = useMemo(() => {
        if (state) {
            const validationState = validateEmployeeAdditionalBenefit(state);
            return new ValidityStateManager(validationState);
        }
        return new ValidityStateManager(getInitializedValidityState([], []));
    }, [state, t]);

    return (
        <AppDialog
            title={t(
                benefitId
                    ? "employeeAdditionalBenefits.createEdit.editTitle"
                    : "employeeAdditionalBenefits.createEdit.createTitle"
            )}
            modalOpen={dialogOpen}
            onClose={onDialogClose}
            size="lg"
            footer={
                <AppDialogFooter
                    onDialogClose={onDialogClose}
                    disableSaveButton={
                        readonly || !validityStateManager.isStateValid()
                    }
                    onClickSaveButton={() => {
                        onDialogClose();
                        onSave(state);
                    }}
                    saveButtonText={t(
                        benefitId ? "common.update" : "common.create"
                    )}
                />
            }
        >
            <div style={{ minHeight: "290px", position: "relative" }}>
                {getLoading || getRefetch ? (
                    <AppLoader
                        fullHeight={false}
                        height={280}
                        useLargeLoader={true}
                    />
                ) : (
                    <Row>
                        <Col md={12}>
                            <AppInputField
                                label={t(
                                    "employeeAdditionalBenefits.createEdit.reason.name"
                                )}
                                showEmptyError={true}
                                value={state.Reason}
                                disabled={readonly}
                                onValueChange={(val) => {
                                    setState({
                                        ...state,
                                        Reason: defaultTo(val, ""),
                                    });
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Reason"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label className="text-dark">
                                    {t(
                                        "employeeAdditionalBenefits.createEdit.employee.name"
                                    )}
                                </Form.Label>
                                <AppSelectOld
                                    isLoading={
                                        employeesLoading || employeesRefetching
                                    }
                                    isDisabled={readonly}
                                    options={employees}
                                    value={
                                        state.EmployeeId
                                            ? employees.filter(
                                                  (x) =>
                                                      x.value ==
                                                      state.EmployeeId
                                              )
                                            : employees[0]
                                    }
                                    onChange={(opt: SimpleOption) => {
                                        if (!isNil(opt.value)) {
                                            setState({
                                                ...state,
                                                EmployeeId: Number(opt.value),
                                            });
                                        } else {
                                            setState({
                                                ...state,
                                                EmployeeId: undefined,
                                            });
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "EmployeeId"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <AppNumberInput
                                showError={true}
                                showIcon={false}
                                showLabel={true}
                                isForCurrency={true}
                                showPlaceHolder={false}
                                showEmptyError={true}
                                showEmptyField={true}
                                allowZero={true}
                                label={t(
                                    "employeeAdditionalBenefits.createEdit.amount.name"
                                )}
                                readonly={readonly}
                                value={defaultTo(state.Amount, 0)}
                                onChange={(val) =>
                                    setState({
                                        ...state,
                                        Amount: defaultTo(val, 0),
                                    })
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "Amount"
                                )}
                            />
                        </Col>
                        <StartAndEndDateFields
                            readonly={readonly}
                            colSize={6}
                            value={{
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            }}
                            onChange={(val: StartAndEndDates) => {
                                setState({
                                    ...state,
                                    StartDate: val.StartDate,
                                    EndDate: val.EndDate,
                                });
                            }}
                        />
                    </Row>
                )}
            </div>
        </AppDialog>
    );
};

export default AdditionalBenefitCreateEditDialog;
