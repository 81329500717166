import {
    showSwalLoader,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import defaultTo from "lodash-es/defaultTo";
import { BusinessType } from "models/business/enum";
import { ImportDataType } from "models/importData/enum";
import DeleteWithPassword from "commonPartials/deleteWithPassword/DeleteWithPassword";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import BusinessSettingLayout from "screens/business/hqAndBranches/BusinessSettingLayout";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";

export interface HandleDeleteAllProps {
    importType: ImportDataType;
    onClose: () => void;
}
export const HandleDeleteAll: React.FC<HandleDeleteAllProps> = ({
    importType,
    onClose,
}) => {
    const { linkProvider } = useRouting();
    const { businessType, businessId } = useParams();
    const [dialogOpen, setDialogOpen] = useState<boolean>(true);
    const { t } = useTranslation();
    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(
            businessId,
            defaultTo(businessType as BusinessType, ":businessType")
        )
    );
    const {
        isLoading: deletingClients,
        data: deleteClientsResponse,
        isError: deleteClientsError,
        mutate: deleteClients,
    } = useMutation(
        getCurrentBusinessServiceKey("deleteAllClients"),
        async (password: string) =>
            await businessService.deleteAllClients(password)
    );
    const {
        isLoading: deletingEmployees,
        data: deleteEmployeesResponse,
        isError: deleteEmployeesError,
        mutate: deleteEmployees,
    } = useMutation(
        getCurrentBusinessServiceKey("deleteAllEmployees"),
        async (password: string) =>
            await businessService.deleteAllEmployees(password)
    );
    const {
        isLoading: deletingClientRegistrations,
        data: deleteClientRegistrationsResponse,
        isError: deleteClientRegistrationsError,
        mutate: deleteClientRegistrations,
    } = useMutation(
        getCurrentBusinessServiceKey("deleteAllClientRegistrations"),
        async (password: string) =>
            await businessService.deleteAllClientRegistrations(password)
    );

    const selector =
        importType === ImportDataType.CLIENT
            ? "deleteClients"
            : importType === ImportDataType.CLIENT_REGISTRATIONS
            ? "deleteClientRegistrations"
            : "deleteEmployees";

    const onCancel = () => {
        setDialogOpen(false);
        onClose();
    };
    const showError = (validPassword: boolean = false) => {
        showSweetAlertInfo(
            t("common.error.error"),
            !validPassword
                ? t(`passwordDialog.incorrectPassword`)
                : t(`${selector}.errorMessage`),
            "error",
            onCancel,
            t("common.cancel")
        );
    };
    const showSuccess = () => {
        showSweetAlertInfo(
            t("common.success"),
            t(`${selector}.successMessage`),
            "success",
            onCancel
        );
    };
    useEffect(() => {
        if (!deletingClientRegistrations && deleteClientRegistrationsResponse) {
            if (deleteClientRegistrationsResponse.Data) {
                showSuccess();
            } else {
                showError(true);
            }
        }
    }, [deletingClientRegistrations, deleteClientRegistrationsResponse]);
    useEffect(() => {
        if (!deletingEmployees && deleteEmployeesResponse) {
            if (deleteEmployeesResponse.Data) {
                showSuccess();
            } else {
                showError(true);
            }
        }
    }, [deletingEmployees, deleteEmployeesResponse]);
    useEffect(() => {
        if (!deletingClients && deleteClientsResponse) {
            if (deleteClientsResponse.Data) {
                showSuccess();
            } else {
                showError(true);
            }
        }
    }, [deletingClients, deleteClientsResponse]);

    useEffect(() => {
        if (
            deleteEmployeesError ||
            deleteClientRegistrationsError ||
            deleteClientsError
        ) {
            // invalid password
            showError();
        }
    }, [
        deleteEmployeesError,
        deleteClientRegistrationsError,
        deleteClientsError,
    ]);

    return (
        <DeleteWithPassword
            opened={dialogOpen}
            onClose={onCancel}
            buttonText={t(`${selector}.deleteAll`)}
            onSubmit={(password: string) => {
                switch (importType) {
                    case ImportDataType.CLIENT:
                        deleteClients(password);
                        break;
                    case ImportDataType.CLIENT_REGISTRATIONS:
                        deleteClientRegistrations(password);
                        break;
                    case ImportDataType.EMPLOYEE:
                        deleteEmployees(password);
                        break;
                }
                setDialogOpen(false);
                showSwalLoader(t(`${selector}.progressMessage`), "");
            }}
        />
    );
};

export default HandleDeleteAll;
