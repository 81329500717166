import { ISO8601_DATE_FORMAT } from "globals/constants";
import { isNil, orderBy } from "lodash-es";
import { AbsentsTableSortColumn } from "models/businessAbsents/enum";
import {
    PersonAbsents,
} from "models/businessAbsents/model";
import {
    AbsentRecordResponse,
    PersonAbsentsResponse,
} from "models/businessAbsents/response";
import { SortOrder, TableSort } from "models/general";
import moment from "moment-timezone";

export const parsePersonAbsentRecord = (item: AbsentRecordResponse) => ({
    ...item,
    StartDate: moment(item.StartDate, ISO8601_DATE_FORMAT),
    EndDate: !isNil(item.EndDate)
        ? moment(item.EndDate, ISO8601_DATE_FORMAT)
        : undefined,
});
export const parsePersonAbsents = (
    item: PersonAbsentsResponse
): PersonAbsents => {
    const withoutVacationAbsents = item.TotalAbsences - item.TotalVacationDays;
    return {
        ...item,
        AbsencesWithoutVacations: withoutVacationAbsents,
        AbsencePercentage:
            item.TrueTotalWorkingDays > 0
                ? (withoutVacationAbsents / item.TrueTotalWorkingDays) * 100
                : 0,
        PregnantSickDays: item.PregnantSickDays.map((b) =>
            parsePersonAbsentRecord(b)
        ),
        VacationDays: item.VacationDays.map((b) => parsePersonAbsentRecord(b)),
        UndeclaredAbsents: item.UndeclaredAbsents.map((b) =>
            parsePersonAbsentRecord(b)
        ),
        SchoolDays: item.SchoolDays.map((b) => parsePersonAbsentRecord(b)),
        SickDays: item.SickDays.map((b) => parsePersonAbsentRecord(b)),
    };
};

export const sortAbsentsData = (
    data: PersonAbsents[],
    sort: TableSort<AbsentsTableSortColumn>
): PersonAbsents[] => {
    const filteredOrders = [
        {
            col: sort.SortColumn,
            dir: sort.SortOrder,
        },
        {
            col: AbsentsTableSortColumn.DisplayId,
            dir: SortOrder.DESC,
        },
    ];

    const sorted = orderBy(
        data,
        filteredOrders.map(({ col }) => col),
        filteredOrders.map(({ dir }) => (dir == SortOrder.ASC ? "asc" : "desc"))
    ) as PersonAbsents[];
    return sorted;
};
