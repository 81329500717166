import { BusinessCreateEditFields } from "commonPartials/businessCreateEditField";
import { AppContainer } from "components/Containers";
import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { BusinessDetails } from "models";
import {
    Business_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { BusinessService, getBusinessServiceKey } from "services/business";
import { useCurrentBusinessSettingsContext } from "./partials/BusinessTabs";

export const BusinessEdit: React.FC = () => {
    const { checkPermission } = useBusinessCheckPermission();
    const { linkProvider } = useRouting();
    const queryClient = useQueryClient();
    const showErrorPage = useShowErrorPage();

    const currentBusiness = useCurrentBusinessSettingsContext();
    const hasEditPermission = checkPermission(
        currentBusiness.dataItem?.Id as number,
        currentBusiness.dataItem?.HqId as number,
        Business_BasicData,
        [PermissionAccessTypes.EDIT]
    );

    const businessService = new BusinessService(linkProvider.business.api);

    const { data: getBusinessResponse, isFetching: isGetBusinessLoading } =
        useQuery(
            getBusinessServiceKey("getBusinessDetail", {
                businessId: currentBusiness.dataItem?.EncodedId,
            }),
            async () => {
                if (currentBusiness.dataItem?.EncodedId) {
                    return await businessService.getBusinessDetail(
                        currentBusiness.dataItem.EncodedId
                    );
                }
            },
            {
                refetchOnMount: "always",
                onError: () => {
                    showErrorPage(404);
                },
            }
        );

    return (
        <div>
            {isGetBusinessLoading ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={false}
                    isTabContainer={true}
                    heightToAdjust={250}
                    mediumViewAdjustment={30}
                >
                    <BusinessCreateEditFields
                        currentBusiness={
                            {
                                ...getBusinessResponse?.Data,
                                EncodedId: currentBusiness.dataItem
                                    ?.EncodedId as string,
                            } as BusinessDetails
                        }
                        isCreate={false}
                        hasEditPermission={hasEditPermission}
                        onSuccess={() => {
                            queryClient.invalidateQueries(
                                "getAdminBusinessesList"
                            );
                            if (currentBusiness.refetchData) {
                                currentBusiness.refetchData();
                            }
                        }}
                    />
                </AppContainer>
            )}
        </div>
    );
};

export default BusinessEdit;
