import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment";
import {
    LocationSetting,
    LocationSettingDetails,
    LocationSettingDetailsResponse,
    LocationSettingResponse,
} from "./model";

export const parseLocationSettingResponse = (
    resp: LocationSettingResponse
): LocationSetting => {
    return {
        ...resp,
        AgeGroupDeadline: resp.AgeGroupDeadline
            ? moment(resp.AgeGroupDeadline, ISO8601_DATE_FORMAT)
            : undefined,
        SchoolStart: resp.SchoolStart
            ? moment(resp.SchoolStart, ISO8601_DATE_FORMAT)
            : undefined,
    };
};
export const parseLocationSettingDetailsResponse = (
    resp: LocationSettingDetailsResponse
): LocationSettingDetails => {
    return {
        ...parseLocationSettingResponse(resp as LocationSettingResponse),
    };
};
