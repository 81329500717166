import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    CareScopeTemplate,
    CareScopeTemplateResponse,
} from "models/careScopeTemplate";
import {
    AppResponse,
    BusinessSettingsListDataWithLocationFlag,
} from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "./BaseService";

export interface ICareScopeTemplateService {
    getTemplates: () => Promise<
        AppResponse<
            BusinessSettingsListDataWithLocationFlag<CareScopeTemplateResponse>
        >
    >;
    getDetails: (
        id: string,
        clone?: boolean,
        followup?: boolean,
        isBusinessFollowup?: boolean
    ) => Promise<AppResponse<CareScopeTemplateResponse>>;
    create(
        request: CareScopeTemplate
    ): Promise<AppResponse<CareScopeTemplateResponse>>;
    edit(
        request: CareScopeTemplate
    ): Promise<AppResponse<CareScopeTemplateResponse>>;
    delete(templateId: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().api.careScopeTemplates(0);
type ApiLinkProviderType = typeof apiLinkProvider;

const getParsedRequest = (request: CareScopeTemplate) => {
    return {
        ...request,
        StartDate: request.StartDate.format(ISO8601_DATE_FORMAT),
        EndDate:
            request.EndDate != null
                ? request.EndDate.format(ISO8601_DATE_FORMAT)
                : null,
    };
};

export function getCareScopeTemplateServiceKey(
    name: keyof ICareScopeTemplateService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `CareScopeTemplate_${name}_${JSON.stringify(data)}`;
}
export class CareScopeTemplateService
    extends BaseService
    implements ICareScopeTemplateService
{
    public templateRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.templateRoutes = apiLinkProvider;
    }

    getTemplates(): Promise<
        AppResponse<
            BusinessSettingsListDataWithLocationFlag<CareScopeTemplateResponse>
        >
    > {
        return this.doServerXHR<
            BusinessSettingsListDataWithLocationFlag<CareScopeTemplateResponse>
        >({
            url: this.templateRoutes.list(),
            method: "get",
        });
    }

    getDetails(
        id: string,
        clone?: boolean,
        followUp?: boolean,
        isBusinessFollowup?: boolean
    ): Promise<AppResponse<CareScopeTemplateResponse>> {
        return this.doServerXHR<CareScopeTemplateResponse>({
            url: this.templateRoutes.getDetails(
                id,
                clone,
                followUp,
                isBusinessFollowup
            ),
            method: "get",
        });
    }

    create(
        request: CareScopeTemplate
    ): Promise<AppResponse<CareScopeTemplateResponse>> {
        return this.doServerXHR<CareScopeTemplateResponse>({
            url: this.templateRoutes.create(),
            method: "post",
            data: getParsedRequest(request),
        });
    }

    edit(
        request: CareScopeTemplate
    ): Promise<AppResponse<CareScopeTemplateResponse>> {
        return this.doServerXHR<CareScopeTemplateResponse>({
            url: this.templateRoutes.edit(),
            method: "post",
            data: getParsedRequest(request),
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.templateRoutes.delete(id),
            method: "delete",
        });
    }
}

export default CareScopeTemplateService;
