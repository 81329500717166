import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AuthError.module.scss";
import { defaultTo } from "lodash-es";

export interface AuthErrorProps {
    errorMsg?: string;
}
export const AuthError: React.FC<AuthErrorProps> = ({ errorMsg }) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.root}`}>
            <span className={styles.title}>{t("common.error")}</span>
            <span className={styles.text}>
                {defaultTo(
                    errorMsg,
                    t("common.error.unexpectedError").toString()
                )}
            </span>
        </div>
    );
};

export default AuthError;
