import React from "react";
import { AuthLoader, EmployeeInfoRegisterHeaderAndFields } from "./partials";

export const EmployeeInfoRegister: React.FC = () => {
    return (
        <AuthLoader
            renderContentWithLoader={(setLoading) => (
                <EmployeeInfoRegisterHeaderAndFields setLoading={setLoading} />
            )}
        />
    );
};

export default EmployeeInfoRegister;
