import { EditorType } from "models";
import { AppResponse } from "models/general/response";
import { routesForContext } from "routing/AppRoutes";
import { FieldSpec } from "tinymce/plugins/placeholder/Types";
import { BaseService } from "./BaseService";

export interface ITinymceService {
    uploadFile: (file: FormData) => Promise<AppResponse<string>>;
    getDynamicPlaceholders(
        editorType: EditorType
    ): Promise<AppResponse<FieldSpec[]>>;
}

export class TinymceService extends BaseService implements ITinymceService {
    uploadFile(fileData: FormData): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: routesForContext().api.tinymce.uploadFile(),
            method: "post",
            headers: {
                "content-type": "multipart/form-data",
            },
            data: fileData,
        });
    }
    getDynamicPlaceholders(
        editorType: EditorType
    ): Promise<AppResponse<FieldSpec[]>> {
        return this.doServerXHR<FieldSpec[]>({
            url: routesForContext().api.tinymce.getDynamicPlaceholdersForEditor(
                { editorType: editorType }
            ),
            method: "get",
        });
    }
}
export default TinymceService;
