import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ConfirmationPages, getConfirmationPageData } from "models";
import { AppPlatforms } from "models/general/enum";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import EmailConfirmation from "screens/auth/partials/confirmation/EmailConfirmation";
import { ConfirmationPageContent } from "./partials/confirmation";
import { AuthLoader } from "./partials";

export const CONFIRM_PAGE_PLATFORM_PARAM_KEY = "platform";

const hideButtonOn = [
    ConfirmationPages.FORGOT_PASSWORD,
    ConfirmationPages.REGISTER_REQUEST_CONFIRMATION,
];
export const ConfirmationPage: React.FC = () => {
    const navigate = useNavigate();

    const { linkProvider } = useRouting();

    const location = useLocation();

    const redirect = () => {
        navigate(linkProvider.noAuth.screens.login());
    };

    let type: ConfirmationPages =
        ConfirmationPages.REGISTER_REQUEST_CONFIRMATION;

    const screens = linkProvider.noAuth.screens;
    switch (location.pathname.toLowerCase()) {
        case screens.registerRequestConfirmation().toLowerCase():
            type = ConfirmationPages.REGISTER_REQUEST_CONFIRMATION;
            break;
        case screens.emailConfirmation().toLowerCase():
            type = ConfirmationPages.EMAIL_CONFIRMED;
            break;
        case screens.registerInfoCompleted().toLowerCase():
            type = ConfirmationPages.REGISTER_INFO_COMPLETED;
            break;
        case screens.forgotPasswordConfirmation().toLowerCase():
            type = ConfirmationPages.FORGOT_PASSWORD;
            break;
        case screens.resetPasswordConformation().toLowerCase():
            type = ConfirmationPages.RESET_PASSWORD;
            break;
    }

    const [searchParams] = useSearchParams();

    const platform: AppPlatforms = defaultTo(
        searchParams.get(CONFIRM_PAGE_PLATFORM_PARAM_KEY) as AppPlatforms,
        AppPlatforms.WEB
    );

    const pageData = getConfirmationPageData(type, platform);

    return (
        <AuthLoader
            renderContentWithLoader={(setLoading) => (
                <>
                    {type == ConfirmationPages.EMAIL_CONFIRMED ? (
                        <EmailConfirmation
                            setLoading={setLoading}
                            value={pageData}
                            onLogin={redirect}
                        />
                    ) : (
                        <ConfirmationPageContent
                            value={pageData}
                            onLogin={
                                platform === AppPlatforms.WEB &&
                                !hideButtonOn.includes(type)
                                    ? redirect
                                    : undefined
                            }
                        />
                    )}
                </>
            )}
        />
    );
};

export default ConfirmationPage;
