import classNames from "classnames";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import {
    DocumentTemplateManagerItem,
    DocumentTemplateRequest,
    DocumentTemplateSortColumn,
} from "models/documentTemplates";
import {
    ResponseMeta,
    SortOrder,
    TableSort,
    UpdateDisplayIdResponseAndRequest,
} from "models/general";
import {
    Business_DocumentManager_Template,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./DocumentTemplateOwnDocuments.module.scss";
import DocumentTemplateOwnDocumentsRow from "./partials/DocumentTemplateOwnDocumentsRow";

export interface DocumentTemplateOwnDocumentsProps {
    templates: DocumentTemplateManagerItem[];
    sort: TableSort<DocumentTemplateSortColumn>;
    metaState: ResponseMeta;
    updateDisplayId: (req: UpdateDisplayIdResponseAndRequest) => void;
    removeItem: (Id: number) => void;
    refetchDocumentTemplate: (pageNumber: number) => void;
    onSortChange: (sort: TableSort<DocumentTemplateSortColumn>) => void;
    filterList: () => void;
    loading: boolean;
    request: DocumentTemplateRequest;
}

const COLUMNS = 6;
export const DocumentTemplateOwnDocuments: React.FC<
    DocumentTemplateOwnDocumentsProps
> = ({
    templates,
    sort,
    filterList,
    metaState,
    refetchDocumentTemplate,
    updateDisplayId,
    removeItem,
    onSortChange,
    loading,
}) => {
    const { t } = useTranslation();
    const { getPermissionMap } = useCheckPermission();

    const permMap = useMemo(() => {
        return getPermissionMap(Business_DocumentManager_Template);
    }, [getPermissionMap]);

    const templateDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const meta: ResponseMeta = defaultTo(metaState, {
        PageNumber: 1,
        HasNextPage: metaState ? metaState.HasNextPage : false,
    });

    const checkDisplayIdAlreadyExist = (displayId: number): boolean => {
        return templates.find((x) => x.DisplayId == displayId) != null;
    };

    return (
        <InfiniteScroll
            className={styles.infiniteScroll}
            dataLength={templates && templates.length}
            next={() => {
                refetchDocumentTemplate(defaultTo(meta.PageNumber, 1) + 1);
            }}
            loader={<></>}
            hasMore={defaultTo(meta.HasNextPage, false)}
            scrollableTarget="scrollableDiv"
        >
            <AppTable
                id={"scrollableDiv"}
                tableClass={classNames(styles.tableClass, "table-striped")}
                heightToAdjust={250}
                stickyHeader={true}
                mediumViewAdjustment={110}
                mobileViewAdjustment={110}
                hover={true}
            >
                <thead>
                    <tr>
                        <AppTableSortColumnIcons
                            text={t("templateDocumentManager.id")}
                            sortColumn={DocumentTemplateSortColumn.DisplayId}
                            onClick={(key, order) =>
                                onSortChange({
                                    SortColumn: key,
                                    SortOrder: order,
                                })
                            }
                            sortOrder={
                                sort.SortColumn ==
                                DocumentTemplateSortColumn.DisplayId
                                    ? sort.SortOrder
                                    : null
                            }
                            className={styles.displayId}
                        />

                        <th style={{ minWidth: "600px" }}>
                            {t("templateDocumentManager.documentName")}
                        </th>
                        <th style={getFixedCssWidths(300)}>
                            {t("templateDocumentManager.created")}
                        </th>
                        <th style={{ minWidth: "200px", width: "200px" }}>
                            {t("templateDocumentManager.group")}
                        </th>
                        <th style={getFixedCssWidths(60)} />
                        <th style={getFixedCssWidths(70)} />
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>

                    {templates &&
                        templates.length > 0 &&
                        templates.map((x) => (
                            <DocumentTemplateOwnDocumentsRow
                                checkDisplayIdIsInRange={(displayId: number) =>
                                    (Math.max(
                                        ...templates.map((x) => x.DisplayId)
                                    ) < displayId &&
                                        sort.SortOrder == SortOrder.ASC &&
                                        metaState.HasNextPage == true) ||
                                    (Math.min(
                                        ...templates.map((x) => x.DisplayId)
                                    ) > displayId &&
                                        sort.SortOrder == SortOrder.DESC &&
                                        metaState.HasNextPage == true)
                                        ? false
                                        : true
                                }
                                updateDisplayId={updateDisplayId}
                                removeItem={removeItem}
                                key={x.Id}
                                value={x}
                                filterList={filterList}
                                checkDisplayIdAlreadyExist={
                                    checkDisplayIdAlreadyExist
                                }
                                hasDeletePermission={templateDeletePermission}
                            />
                        ))}

                    {loading && (
                        <AppTableSkeleton
                            columns={COLUMNS}
                            rows={templates && templates.length > 0 ? 2 : 12}
                        />
                    )}

                    {templates && templates.length == 0 && !loading && (
                        <tr>
                            <td
                                colSpan={COLUMNS}
                                style={{ textAlign: "center" }}
                            >
                                {t("templateDocumentManager.templateListEmpty")}
                            </td>
                        </tr>
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                </tbody>
            </AppTable>
        </InfiniteScroll>
    );
};

export default DocumentTemplateOwnDocuments;
