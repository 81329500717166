import classNames from "classnames";
import AppIconWithName from "components/AppIconWithName";
import AppPopover from "components/AppPopover";
import { FORMAT_INTEGER } from "globals/constants";
import { ImageAssets } from "globals/images/common";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { BusinessPerson } from "models/general";
import { sortData, SortOrder } from "models/general/sort";
import React from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./PersonInfoPopper.module.scss";

interface PersonInfoPopperProps {
    persons: BusinessPerson[];
    title: string;
    className?: string;
}

const Component: React.FC<PersonInfoPopperProps> = ({
    persons,
    title,
    className,
}) => {
    const sortedData = sortData(persons, [{ col: "Name", dir: SortOrder.ASC }]);
    const { formatNumber } = useLocaleHelpers();
    const trigger = (
        <span
            className={classNames(className, {
                [styles.triggerIcon]: persons.length > 0,
            })}
        >
            {formatNumber(sortedData.length, FORMAT_INTEGER)}
        </span>
    );
    return persons.length == 0 ? (
        trigger
    ) : (
        <AppPopover
            triggerOnClick={false}
            delay={200}
            trigger={"mouseenter focus"}
            containerClass={` mb-auto`}
            placement={"auto-end"}
            triggerElem={trigger}
        >
            <Container className={styles.root}>
                <Row>
                    <Col xs={12}>
                        <div className={styles.title}>
                            <div className={`d-flex ${styles.header}`}>
                                <div className={styles.iconHelp}>
                                    <Image
                                        src={ImageAssets.common.helpCircleBlue}
                                    ></Image>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className={styles.mainHeading}>
                                        {title}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.underline}></div>
                    </Col>
                    <Col xs={12}>
                        <div className={`d-flex flex-column ${styles.body}`}>
                            <Table striped className="no-gutters">
                                <tbody>
                                    {sortedData &&
                                        sortedData.map((personData) => (
                                            <tr key={uuid()}>
                                                <td>
                                                    <AppIconWithName
                                                        text={
                                                            personData.Name as string
                                                        }
                                                        iconClass={styles.icon}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AppPopover>
    );
};
export const PersonInfoPopper = React.memo(Component);
export default PersonInfoPopper;
