import { AppColoredMultiSelect, AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppNumberInput } from "components/FormFields";
import { useRouting } from "hooks/general/routing";
import {
    CustomColorItemOption,
    ValidityStateManager,
    ValidityState,
} from "models/general";
import {
    BusinessShiftCriteria,
    ShiftCriteriaType,
    StringCriteriaTypes,
} from "models/businessShifts";
import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { FORMAT_INTEGER } from "globals/constants";
import styles from "./BusinessShiftCriteriaRow.module.scss";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import AppDeleteButton from "components/Buttons/AppDeleteButton";
import ErrorMessage from "components/ErrorMessage";
import { defaultTo } from "lodash-es";

export interface BusinessShiftCriteriaRowProps {
    value: BusinessShiftCriteria;
    readonly?: boolean;
    businessId?: string;
    validityState?: ValidityState;
    onChange: (val: BusinessShiftCriteria) => void;
    onDelete: (uuid: string) => void;
}

export const BusinessShiftCriteriaRow: React.FC<
    BusinessShiftCriteriaRowProps
> = ({
    value,
    businessId,
    readonly = false,
    onChange,
    validityState,
    onDelete,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );

    const {
        isLoading: optionsLoading,
        data: optionsResponse,
        isRefetching: optionsRefetching,
    } = useQuery(
        getCurrentBusinessServiceKey("getBusinessShiftCriteriaOptions", {
            encodedBusinessId: businessId,
            forType: value.ShiftCriteriaType,
        }),
        async () =>
            await currentBusinessService.getBusinessShiftCriteriaOptions(
                value.ShiftCriteriaType
            ),
        { cacheTime: getMillisecondsForMinutes(2) }
    );

    const isStringOptions = useMemo(() => {
        return StringCriteriaTypes.includes(value.ShiftCriteriaType);
    }, [value.ShiftCriteriaType]);

    const selectOptions =
        optionsResponse && optionsResponse.Data
            ? optionsResponse.Data.map((x) => {
                  return {
                      label: x.Text,
                      value: isStringOptions ? x.Value : Number(x.Value),
                      color: undefined,
                  } as CustomColorItemOption;
              })
            : [];
    const typeOptions: SimpleOption[] = [
        ...Object.values(ShiftCriteriaType).map((x) => {
            return {
                label: t(
                    `business.businessShift.criteria.typeOptions.${x
                        .toString()
                        .toLowerCase()}`
                ),
                value: x,
            } as SimpleOption;
        }),
    ];

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(validityState);
    }, [value, t]);
    useEffect(() => {
        if (
            selectOptions.length > 0 &&
            defaultTo(value.StringOptions, []).length > 0 &&
            isStringOptions &&
            !selectOptions.some((x) =>
                value.StringOptions?.includes(x.value as string)
            )
        ) {
            // previously selected stringOption is deleted and now it has value but in UI it will show "None" value without error
            // so need to remove the old selection
            onChange({
                ...value,
                StringOptions: undefined,
            });
        }
    }, [value.StringOptions, selectOptions, isStringOptions]);
    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.uuid);
                        }
                    }}
                />
                <Row className={styles.valueField}>
                    <Col sm={6} xs={12} md={2}>
                        <Form.Group>
                            <Form.Label>
                                {t("business.businessShift.criteria.type")}
                            </Form.Label>
                            <AppSelectOld
                                menuPortalTarget={document.body}
                                options={typeOptions}
                                value={typeOptions.find(
                                    (x) => x.value == value.ShiftCriteriaType
                                )}
                                isDisabled={readonly}
                                onChange={(opt: SimpleOption) =>
                                    onChange({
                                        ...value,
                                        ShiftCriteriaType: opt.value,
                                        Options: undefined,
                                        StringOptions: undefined,
                                    })
                                }
                            />
                        </Form.Group>
                        <ErrorMessage showEmpty={true} />
                    </Col>
                    <Col sm={6} xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>
                                {t(
                                    "business.businessShift.criteria.option.name"
                                )}
                            </Form.Label>
                            <AppColoredMultiSelect
                                useOldUi={true}
                                menuPortalTarget={document.body}
                                data={selectOptions}
                                hasNoneValue={true}
                                value={selectOptions.filter(
                                    (x) =>
                                        (!isStringOptions &&
                                            value.Options?.includes(
                                                x.value as number
                                            )) ||
                                        (isStringOptions &&
                                            value.StringOptions?.includes(
                                                x.value as string
                                            ))
                                )}
                                loading={optionsLoading || optionsRefetching}
                                isReadonly={readonly}
                                onChange={(opt: CustomColorItemOption[]) => {
                                    onChange({
                                        ...value,
                                        Options: !isStringOptions
                                            ? opt.map((x) => Number(x.value))
                                            : undefined,
                                        StringOptions: isStringOptions
                                            ? opt.map((x) => x.value as string)
                                            : undefined,
                                    });
                                }}
                            />
                            <ErrorMessage
                                showEmpty={true}
                                errorInfo={validityStateManager.getFirstErrorInfo(
                                    isStringOptions
                                        ? "StringOptions"
                                        : "Options"
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} xs={12} md={3}>
                        <AppNumberInput
                            value={value.MinEmployees}
                            label={t(
                                "business.businessShift.minEmployees.name"
                            )}
                            showError={true}
                            format={FORMAT_INTEGER}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    MinEmployees: val as number,
                                })
                            }
                            allowZero={true}
                            placeholder={t("common.number")}
                            readOnly={readonly}
                            showEmptyError={true}
                            error={validityStateManager.getFirstErrorInfo(
                                "MinEmployees"
                            )}
                        />
                    </Col>
                    <Col sm={6} xs={12} md={3}>
                        <AppNumberInput
                            value={value.MaxEmployees}
                            label={t(
                                "business.businessShift.maxEmployees.name"
                            )}
                            placeholder={t("common.number")}
                            showError={true}
                            format={FORMAT_INTEGER}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    MaxEmployees: val as number,
                                })
                            }
                            readOnly={readonly}
                            showEmptyField={true}
                            showEmptyError={true}
                            error={validityStateManager.getFirstErrorInfo(
                                "MaxEmployees"
                            )}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default BusinessShiftCriteriaRow;
