import { AppInputField, AppNumberInput } from "components/FormFields";
import {
    BusinessCompanyDataField,
    CompanyDataFieldType,
} from "models/companyDataField";
import { ValidityState, ValidityStateManager } from "models/general";
import React from "react";
import { Col } from "react-bootstrap";

export interface CompanyDataFieldProps {
    value: BusinessCompanyDataField;
    onChange: (newFields: BusinessCompanyDataField) => void;
    validityState?: ValidityState;
    readonly?: boolean;
}

export const CompanyDataField: React.FC<CompanyDataFieldProps> = ({
    value,
    onChange,
    validityState,
    readonly = false,
}) => {
    const validityStateManager = validityState
        ? new ValidityStateManager(validityState)
        : ({} as ValidityStateManager);

    return (
        value && (
            <Col md={4} style={{ paddingLeft: 15, paddingRight: 15 }}>
                {value.Type == CompanyDataFieldType.Numbers ? (
                    <AppNumberInput
                        label={value.Name}
                        readonly={readonly}
                        value={value.Value ? Number(value.Value) : 0}
                        onChange={(val) =>
                            onChange({
                                ...value,
                                Value: val?.toString(),
                            })
                        }
                        showEmptyField={true}
                        error={validityStateManager.getFirstErrorInfo("Value")}
                    />
                ) : (
                    <AppInputField
                        label={value.Name}
                        disabled={readonly}
                        value={value.Value}
                        onValueChange={(val) =>
                            onChange({
                                ...value,
                                Value: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Value")}
                    />
                )}
            </Col>
        )
    );
};

export default CompanyDataField;
