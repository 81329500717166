import { AppCollapseToggleButton } from "components/Buttons";
import { AppCheckbox } from "components/FormFields";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useCollapsibleHelper } from "hooks/general/appHelpers";
import { isNil } from "lodash-es";
import {
    AccessPermissionMapping,
    AccessPermissionRequest,
    checkKeyExistInSelectedList,
    getDisableCheckBoxValue,
    getInitialAccessPermissionRequest,
    handleParentCheckDisable,
    PermissionAccessTypes,
    PermissionNeedsToDisable,
} from "models";
import { BusinessPerson, Optional } from "models/general";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PermissionManagementTableRow.module.scss";
import PermissionManagementUserDetailPopover from "./PermissionManagementUserDetailPopover";

export interface PermissionManagementTableRowsProps {
    permission: AccessPermissionMapping;
    checksNeedToDisable: PermissionNeedsToDisable;
    onPermissionChange: (
        selectPermission: AccessPermissionMapping,
        type: PermissionAccessTypes,
        checked: boolean
    ) => void;
    selectedList: AccessPermissionRequest[];
}

export const PermissionManagementTableRows =
    React.memo<PermissionManagementTableRowsProps>(
        ({
            permission,
            onPermissionChange,
            checksNeedToDisable,
            selectedList,
        }) => {
            const { t } = useTranslation();
            const collapseRowRef = useRef<Optional<any>>(undefined);
            const {
                collapse,
                toggleCollapse: toggleRow,
                initialStyle,
            } = useCollapsibleHelper(collapseRowRef, false);

            const userDetailsForParents = selectedList.filter((x) =>
                x.AccessPermissionKey?.startsWith(permission.CompleteIdentifier)
            );
            const userIds: string[] = [];
            const userPermissionsForParent: {
                [key in PermissionAccessTypes]: BusinessPerson[];
            } = {
                CREATE: [],
                DELETE: [],
                EDIT: [],
                VIEW: [],
            };
            if (userDetailsForParents.length > 0) {
                userDetailsForParents.forEach((element) => {
                    if (element.AccessUsers) {
                        element.AccessUsers.map((x) => userIds.push(x));
                    }
                    if (element.UserPermissionsMapping) {
                        element.UserPermissionsMapping.CREATE.map((user) => {
                            if (
                                userPermissionsForParent.CREATE.filter(
                                    (x) => x.UserId == user.UserId
                                ).length == 0
                            ) {
                                userPermissionsForParent.CREATE.push(user);
                            }
                        });
                        element.UserPermissionsMapping.VIEW.map((user) => {
                            if (
                                userPermissionsForParent.VIEW.filter(
                                    (x) => x.UserId == user.UserId
                                ).length == 0
                            ) {
                                userPermissionsForParent.VIEW.push(user);
                            }
                        });
                        element.UserPermissionsMapping.EDIT.map((user) => {
                            if (
                                userPermissionsForParent.EDIT.filter(
                                    (x) => x.UserId == user.UserId
                                ).length == 0
                            ) {
                                userPermissionsForParent.EDIT.push(user);
                            }
                        });
                        element.UserPermissionsMapping.DELETE.map((user) => {
                            if (
                                userPermissionsForParent.DELETE.filter(
                                    (x) => x.UserId == user.UserId
                                ).length == 0
                            ) {
                                userPermissionsForParent.DELETE.push(user);
                            }
                        });
                    }
                });
            }

            return (
                <>
                    <tr key={permission.SelfIdentifier}>
                        <td
                            style={{
                                ...getFixedCssWidths(250, false),
                                paddingLeft: "10px",
                            }}
                            className="ellipsis-text single-line"
                        >
                            <span>{permission.DisplayName}</span>
                        </td>
                        <td style={getFixedCssWidths(150)}>
                            <AppCheckbox
                                className={"float-right"}
                                disabled={
                                    !permission.IsViewPossible ||
                                    handleParentCheckDisable(
                                        checksNeedToDisable,
                                        PermissionAccessTypes.SHOW,
                                        permission
                                    )
                                }
                                onChange={(e) =>
                                    onPermissionChange(
                                        permission,
                                        PermissionAccessTypes.SHOW,
                                        e.target.checked
                                    )
                                }
                                checked={checkKeyExistInSelectedList(
                                    selectedList,
                                    PermissionAccessTypes.SHOW,
                                    permission.CompleteIdentifier,
                                    permission.Childs
                                )}
                            />
                        </td>
                        <td style={getFixedCssWidths(150)}>
                            <AppCheckbox
                                className={"float-right"}
                                disabled={
                                    !permission.IsEditPossible ||
                                    handleParentCheckDisable(
                                        checksNeedToDisable,
                                        PermissionAccessTypes.EDIT,
                                        permission
                                    )
                                }
                                onChange={(e) =>
                                    onPermissionChange(
                                        permission,
                                        PermissionAccessTypes.EDIT,
                                        e.target.checked
                                    )
                                }
                                checked={checkKeyExistInSelectedList(
                                    selectedList,
                                    PermissionAccessTypes.EDIT,
                                    permission.CompleteIdentifier,
                                    permission.Childs
                                )}
                            />
                        </td>
                        <td style={getFixedCssWidths(150)}>
                            <AppCheckbox
                                className={"float-right"}
                                disabled={
                                    !permission.IsCreatePossible ||
                                    handleParentCheckDisable(
                                        checksNeedToDisable,
                                        PermissionAccessTypes.CREATE,
                                        permission
                                    )
                                }
                                onChange={(e) =>
                                    onPermissionChange(
                                        permission,
                                        PermissionAccessTypes.CREATE,
                                        e.target.checked
                                    )
                                }
                                checked={checkKeyExistInSelectedList(
                                    selectedList,
                                    PermissionAccessTypes.CREATE,
                                    permission.CompleteIdentifier,
                                    permission.Childs
                                )}
                            />
                        </td>
                        <td style={getFixedCssWidths(150)}>
                            <AppCheckbox
                                className={"float-right"}
                                disabled={
                                    !permission.IsDeletePossible ||
                                    handleParentCheckDisable(
                                        checksNeedToDisable,
                                        PermissionAccessTypes.DELETE,
                                        permission
                                    )
                                }
                                onChange={(e) =>
                                    onPermissionChange(
                                        permission,
                                        PermissionAccessTypes.DELETE,
                                        e.target.checked
                                    )
                                }
                                checked={checkKeyExistInSelectedList(
                                    selectedList,
                                    PermissionAccessTypes.DELETE,
                                    permission.CompleteIdentifier,
                                    permission.Childs
                                )}
                            />
                        </td>
                        <td style={getFixedCssWidths(200)}>
                            {userIds.length == 0 ? (
                                t("roleManagement.role.noUser")
                            ) : (
                                <PermissionManagementUserDetailPopover
                                    userCount={
                                        userIds.filter(
                                            (item, i, ar) =>
                                                ar.indexOf(item) === i
                                        ).length
                                    }
                                    parentNode={permission.CompleteIdentifier}
                                    persons={userPermissionsForParent}
                                />
                            )}
                        </td>
                        <td
                            style={{
                                cursor: "pointer !important",
                                ...getFixedCssWidths(70),
                            }}
                            onClick={() => toggleRow()}
                        >
                            <div className="d-flex align-items-center">
                                {permission.Childs &&
                                    permission.Childs.length > 0 && (
                                        <AppCollapseToggleButton
                                            visible={collapse}
                                            onClick={toggleRow}
                                        />
                                    )}
                            </div>
                        </td>
                    </tr>

                    {permission.Childs && (
                        <tr className="row-with-innerTable">
                            <td
                                colSpan={7}
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    borderLeftWidth: "15px",
                                }}
                            >
                                <div
                                    className="child-table-container"
                                    style={{
                                        ...initialStyle.current,
                                        margin: "0px !important",
                                    }}
                                    ref={collapseRowRef}
                                >
                                    <table
                                        style={{ width: "100%" }}
                                        className={styles.childTable}
                                    >
                                        <tbody>
                                            {permission.Childs.map((child) => {
                                                const childUserDetail =
                                                    userDetailsForParents.length ==
                                                    0
                                                        ? getInitialAccessPermissionRequest()
                                                        : userDetailsForParents.find(
                                                              (x) =>
                                                                  x.AccessPermissionKey?.startsWith(
                                                                      child.CompleteIdentifier
                                                                  )
                                                          );

                                                return (
                                                    <tr
                                                        key={
                                                            child.CompleteIdentifier
                                                        }
                                                    >
                                                        <td
                                                            className={
                                                                styles.NameTd
                                                            }
                                                            style={{
                                                                ...getFixedCssWidths(
                                                                    250,
                                                                    false
                                                                ),
                                                                paddingLeft:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {child.DisplayName}
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                150
                                                            )}
                                                        >
                                                            <AppCheckbox
                                                                className={
                                                                    "float-right"
                                                                }
                                                                disabled={
                                                                    !child.IsViewPossible ||
                                                                    getDisableCheckBoxValue(
                                                                        checksNeedToDisable,
                                                                        PermissionAccessTypes.SHOW,
                                                                        child.CompleteIdentifier
                                                                    )
                                                                }
                                                                onChange={(e) =>
                                                                    onPermissionChange(
                                                                        child,
                                                                        PermissionAccessTypes.SHOW,
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                checked={checkKeyExistInSelectedList(
                                                                    selectedList,
                                                                    PermissionAccessTypes.SHOW,
                                                                    child.CompleteIdentifier
                                                                )}
                                                            />
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                150
                                                            )}
                                                        >
                                                            <AppCheckbox
                                                                className={
                                                                    "float-right"
                                                                }
                                                                disabled={
                                                                    !child.IsEditPossible
                                                                }
                                                                onChange={(e) =>
                                                                    onPermissionChange(
                                                                        child,
                                                                        PermissionAccessTypes.EDIT,
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                checked={checkKeyExistInSelectedList(
                                                                    selectedList,
                                                                    PermissionAccessTypes.EDIT,
                                                                    child.CompleteIdentifier
                                                                )}
                                                            />
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                150
                                                            )}
                                                        >
                                                            <AppCheckbox
                                                                className={
                                                                    "float-right"
                                                                }
                                                                disabled={
                                                                    !child.IsCreatePossible
                                                                }
                                                                onChange={(e) =>
                                                                    onPermissionChange(
                                                                        child,
                                                                        PermissionAccessTypes.CREATE,
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                checked={checkKeyExistInSelectedList(
                                                                    selectedList,
                                                                    PermissionAccessTypes.CREATE,
                                                                    child.CompleteIdentifier
                                                                )}
                                                            />
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                150
                                                            )}
                                                        >
                                                            <AppCheckbox
                                                                className={
                                                                    "float-right"
                                                                }
                                                                disabled={
                                                                    !child.IsDeletePossible
                                                                }
                                                                onChange={(e) =>
                                                                    onPermissionChange(
                                                                        child,
                                                                        PermissionAccessTypes.DELETE,
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                checked={checkKeyExistInSelectedList(
                                                                    selectedList,
                                                                    PermissionAccessTypes.DELETE,
                                                                    child.CompleteIdentifier
                                                                )}
                                                            />
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                200
                                                            )}
                                                        >
                                                            {childUserDetail ==
                                                                null ||
                                                            childUserDetail.UserCount ==
                                                                0 ? (
                                                                t(
                                                                    "roleManagement.role.noUser"
                                                                )
                                                            ) : (
                                                                <PermissionManagementUserDetailPopover
                                                                    userCount={
                                                                        childUserDetail.UserCount
                                                                    }
                                                                    persons={
                                                                        childUserDetail.UserPermissionsMapping
                                                                    }
                                                                    parentNode={
                                                                        isNil(
                                                                            childUserDetail.AccessPermissionKey
                                                                        )
                                                                            ? undefined
                                                                            : childUserDetail.AccessPermissionKey
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                        <td
                                                            style={getFixedCssWidths(
                                                                70
                                                            )}
                                                        />
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    )}
                </>
            );
        }
    );

export default PermissionManagementTableRows;
