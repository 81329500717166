import {
    showSwalLoader,
    showSweetAlertConfirmation,
} from "globals/helpers/sweetAlertHelper";
import ImageAssets from "globals/images/common";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AppDeleteButton.module.scss";
export interface AppDeleteButtonProps {
    readonly?: boolean;
    getConfirmation?: boolean;
    onClick?: () => void;
    name?: string;
    showLoader?: boolean;
    className?: string;
    style?: any;
}
export const AppDeleteButton: React.FC<AppDeleteButtonProps> = ({
    readonly = false,
    getConfirmation = false,
    onClick: passedOnClick,
    name,
    style,
    showLoader = false,
    className = "",
}) => {
    const { t } = useTranslation();
    const onClick = () => {
        if (passedOnClick) {
            passedOnClick();
        }
        if (showLoader) {
            showSwalLoader(t("common.actions.processingRequest"), "");
        }
    };

    const [trashImage, setTrashImage] = useState(ImageAssets.common.trashGray);
    return (
        <Image
            onMouseEnter={() => {
                if (!readonly) {
                    setTrashImage(ImageAssets.common.trashRed);
                }
            }}
            onMouseLeave={() => {
                setTrashImage(ImageAssets.common.trashGray);
            }}
            style={style}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                if (!readonly && onClick) {
                    if (getConfirmation) {
                        showSweetAlertConfirmation(
                            t("common.delete.title"),
                            `${
                                name
                                    ? t("common.deleteMessageWithName", {
                                          name: name,
                                      })
                                    : t("common.deleteMessage")
                            }`,
                            () => onClick(),
                            t("common.delete.yes")
                        );
                    } else {
                        onClick();
                    }
                }
                return false;
            }}
            className={`${styles.tdImage} ${className} ${
                readonly ? "disabled" : ""
            }`}
            src={trashImage}
        />
    );
};

export default AppDeleteButton;
