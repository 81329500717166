import { ImageAssets } from "globals/images";
import { defaultTo } from "lodash-es";
import { AvatarInfo } from "models/general";
import React from "react";
import { Image } from "react-bootstrap";
import styles from "./AvatarItem.module.scss";
interface AvatarItemProps {
    value: AvatarInfo;
    className: string;
    isOnline?: boolean;
    onlineStatuesClassName?: string;
}
export const AvatarItem = React.memo<AvatarItemProps>(
    ({
        value: channelInfoInput,
        className = "",
        onlineStatuesClassName,
        isOnline,
    }) => {
        const onlineDot = isOnline && (
            <div
                className={`${onlineStatuesClassName} ${styles.onlineDot}`}
            ></div>
        );
        const channelInfo: AvatarInfo = defaultTo(channelInfoInput, {
            Avatar: ImageAssets.common.person,
        } as AvatarInfo);
        return (
            <div className={`${className} ${styles.root}`}>
                {channelInfo.Avatar && channelInfo.Avatar.trim() != "" ? (
                    <div className={`${styles.imgRoot}`}>
                        <Image src={channelInfo.Avatar} roundedCircle />
                    </div>
                ) : (
                    <div
                        className={`${styles.imgRoot}`}
                        style={{
                            background: channelInfo.BackgroundColor,
                            border: `1px solid ${channelInfo.BorderColor}`,
                        }}
                    >
                        {channelInfo.Initial}
                    </div>
                )}
                {onlineDot}
            </div>
        );
    }
);

export default AvatarItem;
