import {
    DashboardCardsStats,
    DashBoardReportStat,
    DashBoardReportStats,
    DashBoardReportStatsWithDatesInfo,
} from "models/dashboard";
import { stringDictionaryToMomentDictionary } from "models/dashboard/helpers";
import { BusinessPerson } from "models/general/models";

export interface DashboardResponseCommon {
    DashboardCardsStats: DashboardCardsStats;
    DiseasesStats: DashBoardReportStat;
    VacationsStats: DashBoardReportStat;
    Persons: BusinessPerson[];
    maxInterval: number;
    minInterval: number;
}
export interface DashboardResponse extends DashboardResponseCommon {
    MissingStats: DashBoardReportStatsWithDatesInfo;
    DiseasesStats: DashBoardReportStatsWithDatesInfo;
    VacationsStats: DashBoardReportStatsWithDatesInfo;
}
export interface DashboardResponseModal extends DashboardResponseCommon {
    MissingStats: DashBoardReportStats;
    DiseasesStats: DashBoardReportStats;
    VacationsStats: DashBoardReportStats;
}

export const getDashboardResponseModal = (response: DashboardResponse) => {
    const req: DashboardResponseModal = {
        ...response,
        DiseasesStats: {
            ...response.DiseasesStats,
            PersonsDatesInfo: {},
        },
        VacationsStats: {
            ...response.VacationsStats,
            PersonsDatesInfo: {},
        },
        MissingStats: {
            ...response.MissingStats,
            PersonsDatesInfo: {},
        },
    };

    if (response.DiseasesStats && response.DiseasesStats.PersonsDatesInfo) {
        req.DiseasesStats.PersonsDatesInfo = stringDictionaryToMomentDictionary(
            response.DiseasesStats.PersonsDatesInfo
        );
    }
    if (response.VacationsStats && response.VacationsStats.PersonsDatesInfo) {
        req.VacationsStats.PersonsDatesInfo =
            stringDictionaryToMomentDictionary(
                response.VacationsStats.PersonsDatesInfo
            );
    }
    if (response.MissingStats && response.MissingStats.PersonsDatesInfo) {
        req.MissingStats.PersonsDatesInfo = stringDictionaryToMomentDictionary(
            response.MissingStats.PersonsDatesInfo
        );
    }
    return req;
};
