import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppButton, AppDeleteButton } from "components/Buttons";
import React, { useMemo } from "react";
import styles from "./ModulePlanCard.module.scss";
import { Image } from "react-bootstrap";
import { ImageAssets } from "globals/images";
import { useAppContext } from "hoc/providers";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Optional } from "models/general";
import { defaultTo, isNil } from "lodash-es";
import { LocationModulePlan } from "models/modulePlans";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { AppTooltip } from "components";

export interface ModulePlanCardProps {
    value: LocationModulePlan;
    disabled?: boolean;
    isLwc?: boolean;
    price?: Optional<number>;
    isSelected?: boolean;
    onButtonClick?: (id: string) => void;
}

export const ModulePlanCard: React.FC<ModulePlanCardProps> = ({
    value,
    isLwc = false,
    disabled = false,
    price,
    isSelected = false,
    onButtonClick,
}) => {
    const { locale } = useAppContext();
    const { t } = useTranslation();
    const { getFormattedCurrency } = useLocaleHelpers();

    const activeContent = useMemo(() => {
        const defaultContent = value.DefaultLocaleContent
            ? value.DefaultLocaleContent
            : value.LocaleContents?.filter((x) => x.IsDefault)[0];

        const currentContent = value.LocaleContents?.filter(
            (x) => x.Locale == locale
        )[0];

        return currentContent?.UseDefault
            ? defaultContent?.Content
            : currentContent?.Content;
    }, [locale, value]);

    const btnChildren = !isLwc ? (
        <div className={styles.planBtnDiv}>
            {t("locationSettings.modulePlan.editPackage")}
            <Image
                src={ImageAssets.common.editWhite}
                className={styles.btnImage}
            />
        </div>
    ) : (
        <>
            {t(
                `locationSettings.modulePlan.${
                    isSelected ? "packageSelected" : "selectPackage"
                }`
            )}
        </>
    );

    const title =
        value.IsBasic && isNil(activeContent?.Title)
            ? t("locationSettings.modulePlan.module.basic")
            : activeContent?.Title;

    return (
        <div
            className={classNames(styles.card, {
                [styles.cardError]: isNil(activeContent),
            })}
        >
            <div className={styles.header}>
                {!isLwc ? (
                    // <AppDeleteButton
                    //     getConfirmation={true}
                    //     className={styles.deleteBtn}
                    //     name={activeContent?.Title}
                    // />
                    <></>
                ) : (
                    <div
                        className={classNames(styles.badge, {
                            [styles.active]: isSelected,
                        })}
                        style={{
                            justifyContent: "space-around",
                            padding: "0 5px",
                        }}
                    >
                        {isSelected ? (
                            <>
                                <Image
                                    className={styles.badgeImage}
                                    src={ImageAssets.common.checkCircle}
                                />
                                <span>{t("common.status.Active")}</span>
                            </>
                        ) : (
                            <>
                                <Image
                                    className={styles.badgeImage}
                                    src={ImageAssets.common.crossCircleRed}
                                    style={{ width: "14px", height: "14px" }}
                                />
                                <span>{t("common.status.InActive")}</span>
                            </>
                        )}
                    </div>
                )}
                <div className={styles.basicPlanWithTag}>
                    <div className={styles.basicPlan}>
                        <AppTooltip
                            content={title}
                            allowHtml={false}
                            trigger="mouseenter focus"
                            arrow={true}
                        >
                            <span className={styles.basicPlanTitle}>
                                {title}
                            </span>
                        </AppTooltip>
                    </div>
                    <Image
                        src={ImageAssets.common.tagGrayLightest}
                        className={styles.tagImage}
                    />
                </div>
                {isLwc && (
                    <div className={styles.perMonthCostDiv}>
                        {!isNil(price) && getFormattedCurrency(price)}
                        <span className={styles.monthText}>
                            &nbsp;{`/ ${t("common.month").toUpperCase()}`}
                        </span>
                    </div>
                )}
                <hr />
            </div>
            <div className={styles.body}>
                <div className={styles.content}>
                    {isNil(activeContent) ? (
                        <h4 className={styles.notExistError}>
                            {t("locationSettings.modulePlan.contentMissing")}
                        </h4>
                    ) : (
                        <>
                            <div className={styles.description}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: defaultTo(
                                            activeContent?.Description,
                                            ""
                                        ),
                                    }}
                                />
                            </div>
                            <div className={styles.modules}>
                                {activeContent?.Features.map((x, idx) => (
                                    <div
                                        key={idx}
                                        className={styles.moduleItem}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                x.IsIncluded
                                                    ? "check-circle"
                                                    : "times-circle"
                                            }
                                            className={classNames(
                                                styles.check,
                                                {
                                                    [styles.minus]:
                                                        !x.IsIncluded,
                                                }
                                            )}
                                        />
                                        <span className={styles.moduleItemText}>
                                            {x.Text}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.btnContainer}>
                    <AppButton
                        padding="max"
                        rounded={true}
                        variant={isSelected ? "success" : undefined}
                        disabled={disabled}
                        className={styles.planBtn}
                        style={
                            isSelected ? { pointerEvents: "none" } : undefined
                        }
                        onClick={() => {
                            if (onButtonClick && !isSelected) {
                                onButtonClick(
                                    value.EncodedId
                                        ? value.EncodedId
                                        : value.Id.toString()
                                );
                            }
                        }}
                    >
                        {btnChildren}
                    </AppButton>
                </div>
            </div>
        </div>
    );
};

export default ModulePlanCard;
