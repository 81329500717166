import { AppCustomHeaderTable } from "components/Table";
import { defaultTo, isNil } from "lodash-es";
import {
    calculateHeaderTotals,
    defaultHeaderTotals,
    TimeTrackingRecord,
    TimeTrackingRequestParameters,
} from "models";
import { DurationPeriodType, UserType } from "models/general";
import { Moment } from "moment-timezone";
import React from "react";
import { TimeManagementEmployeeDayHeader } from "../partials/headers";
import { TimeManagementPersonDetailTable } from "../partials/tables";
import { TimeManagementState } from "../TimeManagementMain";
import styles from "./TimeManagementEmployeeDayView.module.scss";

export interface TimeManagementEmployeeDayViewProps {
    value: TimeManagementState;
    manualAllowed: boolean;
    date: Moment;
    refetchData?: () => void;
    onChange: (value: TimeManagementState) => void;
    onFilterChange: (filter: TimeTrackingRequestParameters) => void;
}
export const TimeManagementEmployeeDayView: React.FC<
    TimeManagementEmployeeDayViewProps
> = ({ value, onChange, onFilterChange, manualAllowed, refetchData, date }) => {
    const { filterRequest, headerData, records } = value;

    const header = defaultTo(headerData, { Totals: defaultHeaderTotals() });
    const getPersons = () => {
        const toRet = defaultTo(records, []);
        if (
            filterRequest.UserType == UserType.Employee &&
            filterRequest.PersonName
        ) {
            return toRet.filter((y) =>
                y.PersonName.trim()
                    .toLowerCase()
                    .includes(
                        defaultTo(filterRequest.PersonName, "")
                            .trim()
                            .toLowerCase()
                    )
            );
        }
        return toRet;
    };

    return (
        <AppCustomHeaderTable
            className={styles.tableRoot}
            header={
                <TimeManagementEmployeeDayHeader
                    className={styles.root}
                    value={
                        filterRequest.SelectedId != null
                            ? calculateHeaderTotals(
                                  records
                                      ? records.filter(
                                            (x) =>
                                                x.CurrentGroupId ==
                                                filterRequest.SelectedId
                                        )
                                      : [],
                                  header.EmpKeys
                                      ? header.EmpKeys.GroupEmpKeys[
                                            filterRequest.SelectedId
                                        ]
                                      : 0
                              )
                            : header.Totals
                    }
                    personName={filterRequest.PersonName}
                    onSelectChangeHandler={(val) =>
                        onChange({
                            ...value,
                            filterRequest: {
                                ...value.filterRequest,
                                PersonName: val,
                            },
                        })
                    }
                />
            }
            content={
                <TimeManagementPersonDetailTable
                    records={getPersons()}
                    styleHeader={false}
                    personId={filterRequest.PersonId}
                    className={styles.root}
                    manualAllowed={manualAllowed}
                    refetchData={refetchData}
                    date={date}
                    headerData={header}
                    filterRequest={filterRequest}
                    onItemChange={(item, idx) => {
                        const rec = isNil(value.records)
                            ? value.records
                            : value.records.map((nf, index) => {
                                  if (
                                      nf.AccessId == item.AccessId ||
                                      (!nf.AccessId && index == idx)
                                  ) {
                                      return item;
                                  } else {
                                      return nf;
                                  }
                              });

                        onChange({
                            ...value,
                            records: rec,
                            headerData: {
                                ...value.headerData,
                                Totals: calculateHeaderTotals(
                                    rec as TimeTrackingRecord[],
                                    header.EmpKeys?.TotalEmpKeys as number,
                                    true
                                ),
                            },
                        });
                    }}
                    onRowClick={(val) => {
                        onFilterChange({
                            ...filterRequest,
                            PersonId: val,
                            PreviousFilterType: filterRequest.FilterType,
                            FilterType: DurationPeriodType.Month,
                            PersonName: null,
                            SelectedId: null,
                        });
                    }}
                />
            }
        />
    );
};
export default TimeManagementEmployeeDayView;
