import { ImageAssets } from "globals/images";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AppCareScopeDialog.module.scss";
import { Optional, SelectItem } from "models/general";
import { defaultTo } from "lodash-es";
import { AppSelectOld } from "components/AppSelect";
import { AppTooltip } from "components";
import { AppDialogProps, AppDialog, AppDialogFooter } from "components/Dialogs";

export interface AppCareScopeDialogProps {
    value?: Optional<string>;
    dialogProps?: AppDialogProps;
    options: SelectItem[];
    onChange?: (value: SelectItem) => void;
    readonly?: boolean;
}

export const AppCareScopeDialog: React.FC<AppCareScopeDialogProps> = ({
    value = "",
    dialogProps,
    onChange: onSave,
    options,
    readonly = false,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { t } = useTranslation();
    const pleaseSelectOption = {
        Text: t("common.pleaseSelect"),
        Value: null,
    } as SelectItem;
    const [state, setState] = useState<SelectItem>(pleaseSelectOption);
    const onCloseHandler = () => {
        setDialogOpen(false);
    };
    useEffect(() => {
        setState(
            defaultTo(
                options?.find((x) => x.Value === value),
                pleaseSelectOption
            )
        );
    }, [value]);
    return (
        <>
            <AppTooltip
                content={t(
                    "kindergartenClient.registration.scopeOfCare.update"
                )}
                allowHtml={false}
                trigger="mouseenter focus"
                arrow={true}
            >
                <Image
                    className={styles.addButton}
                    onClick={() => setDialogOpen(!dialogOpen)}
                    src={ImageAssets.common.plusBlue}
                />
            </AppTooltip>
            <AppDialog
                title={t("kindergartenClient.registration.scopeOfCare.update")}
                modalOpen={dialogOpen}
                onClose={onCloseHandler}
                {...dialogProps}
            >
                <AppSelectOld
                    options={options}
                    isDisabled={readonly}
                    value={state}
                    showEmptyError={true}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    onChange={(e: SelectItem) => setState(e)}
                />
                <AppDialogFooter
                    onDialogClose={onCloseHandler}
                    disableSaveButton={readonly}
                    onClickSaveButton={() => {
                        onCloseHandler();
                        if (onSave) {
                            onSave(state);
                        }
                    }}
                    saveButtonText={t("common.update")}
                />
            </AppDialog>
        </>
    );
};

export default AppCareScopeDialog;
