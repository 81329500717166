import { AppDialog, AppDialogFooter, AppDialogProps } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ErrorInfo } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    BusinessContractService,
    getBusinessContractServiceKey,
} from "services/business";

export interface RedeemVoucherDialogProps
    extends Omit<AppDialogProps, "children"> {
    onChange: () => void;
    modalOpen: boolean;
}
export const RedeemVoucherDialog: React.FC<RedeemVoucherDialogProps> = ({
    onChange,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState("");
    const [error, setError] = useState<ErrorInfo | undefined>(undefined);
    const { linkProvider } = useRouting();
    const contractsService = new BusinessContractService(
        linkProvider.business.api.currentBusiness().contracts
    );

    const {
        mutate: redeemVoucher,
        isLoading: redeeming,
        data: response,
        reset: resetApi,
    } = useMutation(
        getBusinessContractServiceKey("redeemVoucher"),
        async (code: string) => await contractsService.redeemVoucher(code),
        {
            onSuccess: (data) => {
                if (data && data.Data) {
                    onChange();
                }
            },
        }
    );

    useEffect(() => {
        if (state.length < 2) {
            setError({
                message: t("common.errorMessages.required", {
                    field: "Code",
                }),
                code: "error",
            });
        } else if (
            !redeeming &&
            response &&
            response.Errors &&
            response.Errors.length > 0
        ) {
            resetApi();
            setError({
                message: response.Errors[0].Message,
                code: response.Code as string,
            });
        } else if (response?.Data) {
            setError(undefined);
        }
    }, [redeeming, response, state]);

    return (
        <>
            <AppDialog
                title={t("business.contracts.redeemVoucher")}
                onClose={onClose}
                {...rest}
            >
                <AppInputField
                    value={state}
                    label={t("business.contracts.vouchers.code")}
                    onValueChange={(val) => {
                        setState(defaultTo(val, ""));
                    }}
                    error={error}
                />
                <AppDialogFooter
                    onDialogClose={onClose}
                    disableSaveButton={state.length < 2 || redeeming}
                    onClickSaveButton={() => {
                        redeemVoucher(state);
                    }}
                    saveButtonText={t("common.save")}
                />
            </AppDialog>
        </>
    );
};

export default RedeemVoucherDialog;
