import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { BusinessIPad } from "./model";
import { getInitializedValidityState } from "models/general";

export function validateBusinessIPad(
    ipad: BusinessIPad,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof BusinessIPad> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                // message: t("business.courses.missing.name"),
            },
        ],
        Status: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };

    state = getInitializedValidityStateFromRules(validationRules, ipad, state);

    return state;
}
