import { ISO8601_DATE_FORMAT } from "globals/constants";
import { t } from "i18next";
import moment from "moment-timezone";
import { EmployeeVacationSortColumn, EmployeeVacationStatus } from "./enum";
import { EmployeeVacation } from "./model";
import { EmployeeVacationResponse } from "./response";
import { MetaDataFieldsResponse, parseMetaDataFieldsResponse } from "models/general";

export const getEmployeeVacationFromResponse = (
    { CreatedAt, UpdatedAt, LastUpdatedBy, LastUpdatedById, ...req }: EmployeeVacationResponse
): EmployeeVacation => {
    return {
        ...req,
        ...parseMetaDataFieldsResponse({ CreatedAt, UpdatedAt, LastUpdatedBy, LastUpdatedById } as MetaDataFieldsResponse),
        DateStart: moment(req.DateStart, ISO8601_DATE_FORMAT),
        DateEnd: req.DateEnd
            ? moment(req.DateEnd, ISO8601_DATE_FORMAT)
            : undefined,
        EntryForCurrYear: req.EntryForCurrYear
            ? getEmployeeVacationFromResponse(req.EntryForCurrYear)
            : undefined,
    };
};
export const getVacationStatusTranslation = (
    status: EmployeeVacationStatus
): string => {
    return t(
        `employee.vacations.status.${status == EmployeeVacationStatus.Requested
            ? "requested"
            : status == EmployeeVacationStatus.Approved
                ? "approved"
                : "declined"
        }`
    );
};

export const getEmployeeVacationSortColumnKeyFromEnum = (
    sortColumn: EmployeeVacationSortColumn | undefined
) => {
    switch (sortColumn) {
        case EmployeeVacationSortColumn.StartDate:
            return "DateStart";
        case EmployeeVacationSortColumn.EndDate:
            return "DateEnd";
        case EmployeeVacationSortColumn.Total:
            return "Total";
        case EmployeeVacationSortColumn.Status:
            return "Status";
        default:
            return "DateStart";
    }
};

export const getDefaultVacationValue = (empId: number): EmployeeVacation => {
    return {
        Id: undefined,
        EmployeeId: empId,
        Status: EmployeeVacationStatus.Requested,
        DateStart: moment(),
        Total: 0,
        HalfDayVacation: false,
        HalfDayGiftVacation: false,
        UnpaidVacation: false,
        DismantlingOvertime: false,
        ManualCorrection: false,
    } as EmployeeVacation;
};
