import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { Business, BusinessType, PermissionAccessTypes } from "models";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import {
    Business_AccessControl,
    Business_AgeGroups,
    Business_BasicData,
    Business_CareScopes,
    Business_Contracts,
    Business_Courses,
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_DataImport_Employee,
    Business_EmployeeKeyCalculation,
    Business_Functions,
    Business_Groups,
    Business_CompensationTemplates,
    Business_Ipads,
    // Business_Kontos,
    Business_Logo,
    Business_MealTemplates,
    Business_PrinterJobs,
    Business_Printers,
    Business_PublicHolidays,
    Business_Shifts,
    Business_WorkingArea,
    Business_WorkingHours,
    LW_Kindergarten_Courses,
    Business_SalaryGroups,
    Business_Settings,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";

// not in edit-layout to resolve circular dependency
export const useCurrentBusinessSettingsContext = () => {
    return useOutletContext<TabbedLayoutActiveItem<Business>>();
};
export interface BusinessTabProps {
    businessId: number;
    businessType: BusinessType;
    hqId: number;
    onActiveTabChange: (tab: NavigationTab) => void;
}
export const BusinessTabs: React.FC<BusinessTabProps> = ({
    businessId,
    businessType,
    hqId,
    onActiveTabChange,
}) => {
    const { linkProvider } = useRouting();
    const { urlForBusinessSettings: forBusinessSettings } = useIFrameRouting();
    const { checkPermission } = useBusinessCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.business.businessSettings().screens;
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const getTranslation = (tabName: string) => t(`business.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_BasicData
                ),
                url: linkBase.basicData(),
            },
            ...(businessType == BusinessType.KINDERGARTEN
                ? [
                      {
                          label: getTranslation("contracts"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_Contracts
                          ),
                          url: linkBase.contracts(),
                      },
                  ]
                : []),
            {
                label: getTranslation("accessControl"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_AccessControl
                ),
                url: linkBase.accessControl.list(),
                iframeUrl: forBusinessSettings("AccessControl"),
            },
            ...(businessType == BusinessType.LW ||
            businessType == BusinessType.KINDERGARTEN
                ? [
                      {
                          label: getTranslation("compensationTemplates"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_CompensationTemplates
                          ),
                          url: linkBase.compensationTemplates.list(),
                      },
                  ]
                : []),
            ...(businessType == BusinessType.KINDERGARTEN
                ? [
                      {
                          label: getTranslation("mealGroups"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_MealTemplates
                          ),
                          url: linkBase.mealTemplates.list(),
                          iframeUrl: forBusinessSettings("MealTemplates"),
                      },
                      {
                          label: getTranslation("employeeKeyCalculations"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_EmployeeKeyCalculation
                          ),
                          url: linkBase.employeeKeyCalculations.list(),
                          iframeUrl: forBusinessSettings(
                              "EmployeeCalculationKeys"
                          ),
                      },
                      {
                          label: getTranslation("careScopes"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_CareScopes
                          ),
                          url: linkBase.careScopes.list(),
                      },
                      {
                          label: getTranslation("ageGroups"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_AgeGroups
                          ),
                          url: linkBase.ageGroups.list(),
                      },
                      {
                          label: getTranslation("salaryGroups"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_SalaryGroups
                          ),
                          url: linkBase.salaryGroups.list(),
                      },
                      {
                          label: getTranslation("courses"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_Courses
                          ),
                          url: linkBase.courses.list(),
                      },
                  ]
                : []),
            {
                label: getTranslation(
                    businessType == BusinessType.KINDERGARTEN
                        ? "careGroups"
                        : "workingGroups"
                ),
                hasAccess: checkPermission(businessId, hqId, Business_Groups),
                url: linkBase.groups.list(),
                iframeUrl: forBusinessSettings("Groups"),
            },
            {
                label: getTranslation("workingAreas"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_WorkingArea
                ),
                url: linkBase.areas.list(),
                iframeUrl: forBusinessSettings("Areas"),
            },
            {
                label: getTranslation("shifts"),
                hasAccess: checkPermission(businessId, hqId, Business_Shifts),
                url: linkBase.shifts.list(),
            },
            // {
            //     label: getTranslation("konto"),
            //     hasAccess: checkPermission(businessId, hqId, Business_Kontos),
            //     url: linkBase.kontos(),
            // },
            {
                label: getTranslation("functions"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_Functions
                ),
                url: linkBase.functions.list(),
                iframeUrl: forBusinessSettings("Functions"),
            },
            {
                label: getTranslation("hPePrinters"),
                hasAccess: checkPermission(businessId, hqId, Business_Printers),
                url: linkBase.printers.list(),
                iframeUrl: forBusinessSettings("Printers"),
            },
            {
                label: getTranslation("printJobs"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_PrinterJobs
                ),
                url: linkBase.printJobs.list(),
                iframeUrl: forBusinessSettings("PrintJobs"),
            },
            {
                label: getTranslation("ipads"),
                hasAccess: checkPermission(businessId, hqId, Business_Ipads),
                url: linkBase.ipads(),
            },
            {
                label: getTranslation("publicHolidays"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_PublicHolidays
                ),
                url: linkBase.publicHolidays(),
                iframeUrl: forBusinessSettings("Holidays"),
            },
            {
                label: getTranslation("openingHours"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_WorkingHours
                ),
                url: linkBase.workingHours(),
            },
            {
                label: getTranslation("settings"),
                hasAccess: checkPermission(businessId, hqId, Business_Settings),
                url: linkBase.basicSettings(),
            },
            {
                label: getTranslation("chatSettings"),
                hasAccess: checkPermission(businessId, hqId, Business_Settings),
                url: linkBase.chatSettings(),
            },
            {
                label: getTranslation("timeTracking"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    Business_BasicData
                ),
                url: linkBase.timeTracking(),
            },
            {
                label: getTranslation("logo"),
                hasAccess: checkPermission(businessId, hqId, Business_Logo, [
                    PermissionAccessTypes.EDIT,
                ]),
                url: linkBase.logo(),
                iframeUrl: forBusinessSettings("Logo"),
            },
            ...(businessType != BusinessType.LW
                ? [
                      {
                          label: getTranslation("externalIntegration"),
                          hasAccess: checkPermission(
                              businessId,
                              hqId,
                              Business_BasicData,
                              [PermissionAccessTypes.EDIT]
                          ),
                          url: linkBase.externalIntegration(),
                      },
                  ]
                : []),
            {
                label: getTranslation("data"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    [
                        Business_DataImport_Client,
                        Business_DataImport_Employee,
                        Business_DataImport_ClientRegistration,
                    ],
                    [PermissionAccessTypes.CREATE, PermissionAccessTypes.DELETE]
                ),
                url: linkBase.data(),
                iframeUrl: forBusinessSettings("Data"),
            },
            {
                label: getTranslation("backgroundJobs"),
                hasAccess: checkPermission(
                    businessId,
                    hqId,
                    [Business_BasicData],
                    [PermissionAccessTypes.EDIT]
                ),
                url: linkBase.backgroundJobs(),
                iframeUrl: forBusinessSettings("BackgroundJobs"),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={5}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default BusinessTabs;
