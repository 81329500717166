import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { TableSort } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import { EmployeeVacationSortColumn } from "models/employeeVacation";

export interface EmployeeVacationTableHeaderProps {
    onSortChange: (sort: TableSort<EmployeeVacationSortColumn>) => void;
    sort: TableSort<EmployeeVacationSortColumn>;
}
export const EmployeeVacationTableHeader: React.FC<
    EmployeeVacationTableHeaderProps
> = ({ onSortChange, sort }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("employee.vacations.startDate")}
                style={getFixedCssWidths(150)}
                sortColumn={EmployeeVacationSortColumn.StartDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeVacationSortColumn.StartDate
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("employee.vacations.endDate")}
                style={getFixedCssWidths(170)}
                sortColumn={EmployeeVacationSortColumn.EndDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeVacationSortColumn.EndDate
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("employee.vacations.total")}
                style={getFixedCssWidths(150)}
                sortColumn={EmployeeVacationSortColumn.Total}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeVacationSortColumn.Total
                        ? sort.SortOrder
                        : null
                }
            />
            <th style={{ ...getFixedCssWidths(200), textAlign: "center" }}>
                {t("employee.vacations.dismantlingOvertime")}
            </th>
            <th style={{ ...getFixedCssWidths(180), textAlign: "center" }}>
                {t("employee.vacations.halfDayVacation")}
            </th>
            <th style={{ ...getFixedCssWidths(270), textAlign: "center" }}>
                {t("employee.vacations.halfDayVacationAsGift")}
            </th>
            <th style={{ ...getFixedCssWidths(180), textAlign: "center" }}>
                {t("employee.vacations.unpaidVacation")}
            </th>
            <th style={{ ...getFixedCssWidths(200), textAlign: "center" }}>
                {t("employee.vacations.manualCorrection")}
            </th>
            <AppTableSortColumnIcons
                text={t("employee.vacations.status.name")}
                style={getFixedCssWidths(170)}
                sortColumn={EmployeeVacationSortColumn.Status}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeVacationSortColumn.Status
                        ? sort.SortOrder
                        : null
                }
            />
            <th style={{ ...getFixedCssWidths(200) }}>
                {t("common.createdAt")}
            </th>
            <th style={{ ...getFixedCssWidths(200) }}>
                {t("common.updatedAt")}
            </th>
            <th style={{ ...getFixedCssWidths(200) }}>
                {t("common.lastUpdatedBy")}
            </th>
            <th style={{ width: "70px", minWidth: "70px" }} />
        </tr>
    );
};

export default EmployeeVacationTableHeader;
