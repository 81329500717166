import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import {
    Business_Archives,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models";
import {
    DocumentArchive,
    DocumentArchiveSort,
    DocumentArchiveSortColumn,
} from "models/archive";
import {
    ResponseMeta,
    SortOrder,
    UpdateDisplayIdResponseAndRequest,
} from "models/general";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./DocumentArchiveTable.module.scss";
import DocumentArchiveTableRows from "./DocumentArchiveTableRows";

export interface DocumentArchiveTableProps {
    documents: DocumentArchive[];
    sort: DocumentArchiveSort;
    metaState: ResponseMeta;
    updateDisplayId: (req: UpdateDisplayIdResponseAndRequest) => void;
    removeItem: (Id: number) => void;
    refetchDocumentTemplate: (pageNumber: number) => void;
    onSortChange: (sort: DocumentArchiveSort) => void;
    filterList: () => void;
    loading: boolean;
    onEditClick: (id: number) => void;
}

const COLUMNS = 6;
export const DocumentArchiveTable: React.FC<DocumentArchiveTableProps> = ({
    documents,
    filterList,
    loading,
    metaState,
    onSortChange,
    refetchDocumentTemplate,
    removeItem,
    onEditClick,
    sort,
    updateDisplayId,
}) => {
    const { t } = useTranslation();
    const checkDisplayIdAlreadyExist = (displayId: number): boolean => {
        return documents.find((x) => x.DisplayId == displayId) != null;
    };
    const { getPermissionMap } = useCheckPermission();

    const permMap = useMemo(() => {
        return getPermissionMap(Business_Archives);
    }, [getPermissionMap]);
    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const hasDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const meta: ResponseMeta = defaultTo(metaState, {
        PageNumber: 1,
        HasNextPage: metaState ? metaState.HasNextPage : false,
    });

    return (
        <>
            <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={documents && documents.length}
                next={() => {
                    refetchDocumentTemplate(defaultTo(meta.PageNumber, 1) + 1);
                }}
                loader={<></>}
                hasMore={defaultTo(meta.HasNextPage, false)}
                scrollableTarget="scrollableDiv"
            >
                <AppTable
                    id={"scrollableDiv"}
                    tableClass={"table-striped"}
                    heightToAdjust={250}
                    mediumViewAdjustment={40}
                    mobileViewAdjustment={40}
                >
                    <thead>
                        <tr>
                            <AppTableSortColumnIcons
                                text={t("documentArchive.id")}
                                sortColumn={DocumentArchiveSortColumn.DisplayId}
                                onClick={(key, order) =>
                                    onSortChange({
                                        sortColumn: key,
                                        sortOrder: order,
                                    })
                                }
                                sortOrder={
                                    sort.sortColumn ==
                                    DocumentArchiveSortColumn.DisplayId
                                        ? sort.sortOrder
                                        : null
                                }
                                className={styles.displayId}
                            />

                            <th style={{ minWidth: "150px" }}>
                                {t("documentArchive.documentName")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("documentArchive.created")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("documentArchive.area")}
                            </th>
                            <th style={getFixedCssWidths(60)} />
                            <th style={getFixedCssWidths(70.5)} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS} />
                        </tr>
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS} />
                        </tr>

                        {documents &&
                            documents.length > 0 &&
                            documents.map((x) => (
                                <DocumentArchiveTableRows
                                    onEditClick={onEditClick}
                                    checkDisplayIdIsInRange={(
                                        displayId: number
                                    ) =>
                                        (Math.max(
                                            ...documents.map((x) => x.DisplayId)
                                        ) < displayId &&
                                            sort.sortOrder == SortOrder.ASC &&
                                            metaState.HasNextPage == true) ||
                                        (Math.min(
                                            ...documents.map((x) => x.DisplayId)
                                        ) > displayId &&
                                            sort.sortOrder == SortOrder.DESC &&
                                            metaState.HasNextPage == true)
                                            ? false
                                            : true
                                    }
                                    updateDisplayId={updateDisplayId}
                                    removeItem={removeItem}
                                    key={x.Id}
                                    value={x}
                                    hasEditPermission={hasEditPermission}
                                    hasDeletePermission={hasDeletePermission}
                                    filterList={filterList}
                                    checkDisplayIdAlreadyExist={
                                        checkDisplayIdAlreadyExist
                                    }
                                />
                            ))}

                        {loading && (
                            <AppTableSkeleton
                                columns={COLUMNS}
                                rows={
                                    documents && documents.length > 0 ? 2 : 12
                                }
                            />
                        )}

                        {documents && documents.length == 0 && !loading && (
                            <tr>
                                <td
                                    colSpan={COLUMNS}
                                    style={{ textAlign: "center" }}
                                >
                                    {t("documentArchive.archiveListEmpty")}
                                </td>
                            </tr>
                        )}
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS} />
                        </tr>
                    </tbody>
                </AppTable>
            </InfiniteScroll>
        </>
    );
};

export default DocumentArchiveTable;
