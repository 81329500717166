import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { showSweetAlertInfo } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { BusinessCreditor } from "models";
import { getAddressString } from "models/general";
import { LocationSettingListItem } from "models/locationSetting";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    BusinessCreditorService,
    getBusinessCreditorServiceKey,
} from "services/business";
import {
    getLocationSettingsServiceKey,
    LocationSettingsService,
} from "services/lillywait/locationSettings";

export interface CreditorRowProps {
    value: BusinessCreditor;
    onClick?: () => void;
    canDelete?: boolean;
    onDelete: (creditorId: number) => void;
}

export const CreditorRow: React.FC<CreditorRowProps> = ({
    value,
    onClick,
    canDelete,
    onDelete,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const creditorService = new BusinessCreditorService(
        linkProvider.business.api.creditors
    );

    const {
        isLoading: deleting,
        data: deleteResponse,
        isError: deleteError,
        reset: deleteReset,
        mutate: deleteCreditor,
    } = useMutation(
        getBusinessCreditorServiceKey("delete"),
        async (creditorId: string) => await creditorService.delete(creditorId)
    );
    useEffect(() => {
        if (!deleting) {
            if (deleteResponse) {
                if (deleteResponse.Data) {
                    showSweetAlertInfo(
                        t("common.success"),
                        t(`common.delete.success`),
                        "success"
                    );
                    onDelete(value.Id);
                } else {
                    showSweetAlertInfo(
                        t("common.error.error"),
                        t(`accounting.creditors.deleteError`),
                        "error"
                    );
                }
            } else if (deleteError) {
                showSweetAlertInfo(
                    t("common.error.error"),
                    t(`common.actions.errors.unableToDelete`),
                    "error"
                );
            }
            deleteReset();
        }
    }, [deleting, deleteResponse, deleteError]);
    return (
        <>
            <tr>
                <td
                    onClick={onClick}
                    style={{ ...getFixedCssWidths(250), paddingLeft: "5px" }}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.Name}</span>
                </td>
                <td onClick={onClick} style={getFixedCssWidths(150)}>
                    <span>{value.Email}</span>
                </td>
                <td onClick={onClick} style={getFixedCssWidths(150)}>
                    <span>{value.Telephone}</span>
                </td>
                <td
                    onClick={onClick}
                    style={getFixedCssWidths(300, false)}
                    className={"ellipsis-text single-line"}
                >
                    <span>{getAddressString(value.Address)}</span>
                </td>
                <td style={{ ...getFixedCssWidths(60), paddingLeft: "10px" }}>
                    <AppDeleteButton
                        onClick={() => {
                            deleteCreditor(value.EncodedId);
                        }}
                        readonly={!canDelete || deleting}
                        getConfirmation={true}
                        name={value.Name}
                    />
                </td>
            </tr>
        </>
    );
};

export default CreditorRow;
