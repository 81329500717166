import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AppTooltip } from "components";
import AppPopover from "components/AppPopover";
import {
    getFixedCssWidths,
    getReceiptName,
} from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { UserMessageStatus } from "models/messages";
import React from "react";
import { Image, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./MessageDetailSendReceipt.module.scss";

export interface MessageDetailSendReceiptProps {
    users: UserMessageStatus[];
    width: number;
}

const MAX_ITEM_SIZE = 200;
export const MessageDetailSendReceipt: React.FC<
    MessageDetailSendReceiptProps
> = ({ users, width }) => {
    const trimLength = Math.max(width / MAX_ITEM_SIZE - 1, 1);
    const { t } = useTranslation();

    return (
        <div className={styles.outer}>
            <div>
                <div style={{ minWidth: "100px" }}>{t("message.sendTo")}:</div>
                <div style={{ display: "flex" }}>
                    {users
                        .slice(0, Math.min(users.length, trimLength))
                        .map((x, idx) => (
                            <AppTooltip key={idx} content={getReceiptName(x)}>
                                <div
                                    className={classNames(styles.badge, {
                                        [styles.badgeMargin]: !x.IsSent,
                                    })}
                                >
                                    <span className={styles.badgeTextEllipse}>
                                        {getReceiptName(x)}
                                    </span>
                                    {!x.HasMobileUser ? (
                                        <Image
                                            src={
                                                ImageAssets.common
                                                    .crossCircleRed
                                            }
                                        />
                                    ) : (
                                        x.IsSent && (
                                            <Image
                                                src={
                                                    x.IsRead
                                                        ? ImageAssets.common
                                                              .checkCircleBlue
                                                        : ImageAssets.common
                                                              .checkCircle
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </AppTooltip>
                        ))}
                    {users.length > trimLength && (
                        <AppPopover
                            arrow={true}
                            placement={"top-start"}
                            delay={100}
                            maxWidth={530}
                            containerClass={styles.popover}
                            triggerElem={
                                <div
                                    className={classNames(
                                        styles.badge,
                                        styles.more
                                    )}
                                >
                                    {t("message.more")}
                                    <span>
                                        <FontAwesomeIcon icon="angle-down" />
                                    </span>
                                </div>
                            }
                        >
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: "200px",
                                                    maxWidth: "450px",
                                                }}
                                            >
                                                <div>{t("message.sendTo")}</div>
                                            </th>
                                            <th
                                                style={getFixedCssWidths(20)}
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users
                                            .slice(trimLength)
                                            .map((x, idx) => (
                                                <tr
                                                    key={idx}
                                                    className={classNames(
                                                        styles.popoverTr
                                                    )}
                                                >
                                                    <td>
                                                        <AppTooltip
                                                            key={idx}
                                                            content={getReceiptName(
                                                                x
                                                            )}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.textEllipse
                                                                }
                                                            >
                                                                {getReceiptName(
                                                                    x
                                                                )}
                                                            </div>
                                                        </AppTooltip>
                                                    </td>
                                                    <td>
                                                        {!x.HasMobileUser ? (
                                                            <Image
                                                                src={
                                                                    ImageAssets
                                                                        .common
                                                                        .crossCircleRed
                                                                }
                                                            />
                                                        ) : (
                                                            x.IsSent && (
                                                                <Image
                                                                    src={
                                                                        x.IsRead
                                                                            ? ImageAssets
                                                                                  .common
                                                                                  .checkCircleBlue
                                                                            : ImageAssets
                                                                                  .common
                                                                                  .checkCircle
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </>
                        </AppPopover>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageDetailSendReceipt;
