import { CompensationTemplateType } from "models/compensationTemplate";

export enum ClientContractType {
    MealGroup = "MealGroup",
    ContractNumber = "ContractNumber",
    ManualCompensation = "ManualCompensation",
}
export type KindergartenClientContractType =
    | ClientContractType
    | CompensationTemplateType;
