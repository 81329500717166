import { useCheckPermission } from "hooks/permissionCheck";
import { LocationSettings_WorkingDays } from "models/permissionManagement";
import React from "react";
import { WorkingDaysSetting } from "commonPartials";
import { useCurrentLocationSettingsContext } from "screens/lillywait/locationSettings/tabs/LocationSettingTabs";

export const LocationWorkingDays: React.FC = () => {
    const { getPermissionMap } = useCheckPermission();

    const { dataItem: location } = useCurrentLocationSettingsContext();
    const permMap = getPermissionMap([LocationSettings_WorkingDays]);

    return (
        <WorkingDaysSetting
            locationId={location?.Id}
            permissionsMap={permMap}
            currentBusiness={null}
        />
    );
};

export default LocationWorkingDays;
