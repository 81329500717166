import { AppCheckbox } from "components/FormFields";
import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash";
import { EmployeeSortColumn } from "models";
import { TableSort } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./BusinessEmployeeTableHeader.module.scss";

export interface BusinessEmployeeSortingColumnsProps {
    onSortChange: (sort: TableSort<EmployeeSortColumn>) => void;
    sort: TableSort<EmployeeSortColumn>;
    allSelected: boolean;
    onCheckAll: (val: boolean) => void;
}
export const BusinessEmployeeSortingColumns: React.FC<
    BusinessEmployeeSortingColumnsProps
> = ({ onSortChange, sort, allSelected, onCheckAll }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("employee.id")}
                sortColumn={EmployeeSortColumn.DisplayId}
                className={styles.displayId}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.DisplayId
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("employee.fullName")}
                style={getFixedCssWidths(300)}
                sortColumn={EmployeeSortColumn.Name}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.Name
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("employee.contractStart")}
                style={getFixedCssWidths(170)}
                sortColumn={EmployeeSortColumn.ContractStart}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.ContractStart
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("employee.contractEnd")}
                sortColumn={EmployeeSortColumn.ContractEnd}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.ContractEnd
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(180)}
                text={t("employee.status")}
                sortColumn={EmployeeSortColumn.Status}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.Status
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(180)}
                text={t("employee.group")}
                sortColumn={EmployeeSortColumn.Group}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.Group
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("employee.employeeFunction")}
                sortColumn={EmployeeSortColumn.EmployeeFunction}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.EmployeeFunction
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(215)}
                text={t("employee.area")}
                sortColumn={EmployeeSortColumn.WorkArea}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.WorkArea
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("employee.weeklyHours")}
                sortColumn={EmployeeSortColumn.HoursPerWeek}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.HoursPerWeek
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(210)}
                text={t("employee.endOfTrialPeriod")}
                sortColumn={EmployeeSortColumn.TrialEnd}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.TrialEnd
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(240)}
                text={t("employee.expirationDate")}
                sortColumn={EmployeeSortColumn.ExpirationDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.ExpirationDate
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(150)}
                text={t("employee.holidays")}
                sortColumn={EmployeeSortColumn.Holidays}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.Holidays
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                style={getFixedCssWidths(255)}
                text={t("employee.eligibleStaff")}
                sortColumn={EmployeeSortColumn.Chargeable}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.Chargeable
                        ? sort.SortOrder
                        : null
                }
            />
            {/* 
            <AppTableSortColumnIcons
                style={getFixedCssWidths(160)}
                text={t("employee.grossSalary")}
                sortColumn={EmployeeSortColumn.GrossSalary}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.GrossSalary
                        ? sort.SortOrder
                        : null
                }
            /> */}

            <th style={{ width: "70.5px", minWidth: "70.5px" }}>
                <AppCheckbox
                    checked={defaultTo(allSelected, false)}
                    onChange={(e) => {
                        onCheckAll(e.target.checked);
                    }}
                />
            </th>

            <AppTableSortColumnIcons
                style={getFixedCssWidths(120)}
                text={t("employee.connectedToApp")}
                sortColumn={EmployeeSortColumn.App}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeSortColumn.App
                        ? sort.SortOrder
                        : null
                }
            />
            <th style={{ width: "70.5px", minWidth: "70.5px" }} />
        </tr>
    );
};

export default BusinessEmployeeSortingColumns;
