import { PermissionAccessMap } from "models";
import { BusinessCourse } from "models/businessCourses";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { AppDeleteButton } from "components/Buttons";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./BusinessCoursesRow.module.scss";

export interface BusinessCoursesRowProps {
    value: BusinessCourse;
    permMap: PermissionAccessMap;
    onDeleteClick: (id?: number) => void;
    onClick: (id: string) => void;
}

export const BusinessCoursesRow: React.FC<BusinessCoursesRowProps> = ({
    value,
    onDeleteClick,
    permMap,
    onClick,
}) => {
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { t } = useTranslation();

    return (
        <tr
            onClick={() => {
                if (permMap.EDIT && value.EncodedId) {
                    onClick(value.EncodedId);
                }
            }}
            key={value.Id}
        >
            <td>
                <div className={styles.avatar}>
                    {value?.Avatar && (
                        <Image roundedCircle src={value.Avatar.FullUrl} />
                    )}
                </div>
            </td>
            <td>
                <span className={styles.textEllipse}>{value.Name}</span>
            </td>
            <td>
                <span className={styles.textEllipse}>{value.ShortName}</span>
            </td>
            <td>
                <span className={styles.textEllipse}>{value.Floor}</span>
            </td>
            <td>
                <span className={styles.textEllipse}>{value.Area}</span>
            </td>
            <td>{value.StartDate.format(getDateFormatForLocale())}</td>
            <td>
                <span className={styles.textEllipse}>
                    {value.EndDate
                        ? value.EndDate.format(getDateFormatForLocale())
                        : t("common.endDate.hasNoEndDate")}
                </span>
            </td>
            <td>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.Name}
                    readonly={!permMap.DELETE}
                    onClick={() => onDeleteClick(value.Id)}
                />
            </td>
        </tr>
    );
};
