import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import {
    CalendarEventTemplateDetails,
    CalendarEventTemplateDetailsResponse,
    CalendarEventTemplateResponse,
    CalendarPivotItem,
} from "models/calendar";
import { CalendarPivotFilters } from "models/calendar/request";
import { AppResponse, DateRangeRequest } from "models/general";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface ICalendarService {
    getEvents: (
        filter: DateRangeRequest
    ) => Promise<AppResponse<CalendarEventTemplateResponse[]>>;
    createUpdate: (
        template: CalendarEventTemplateDetails
    ) => Promise<AppResponse<CalendarEventTemplateDetails>>;

    getCalendarEventDetails: (
        templateId: string
    ) => Promise<AppResponse<CalendarEventTemplateDetailsResponse>>;
    delete: (templateId: string) => Promise<AppResponse<boolean>>;
    deleteFile: (
        templateId: string,
        fileId: number
    ) => Promise<AppResponse<number>>;
    getPivotInfo: (
        filter: CalendarPivotFilters
    ) => Promise<AppResponse<CalendarPivotItem[]>>;
}
const apiLinkProvider = routesForContext().business.api.calendar;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getCalendarServiceKey(
    name: keyof ICalendarService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `calendar_${name}_${JSON.stringify(data)}`;
}
export class CalendarService extends BaseService implements ICalendarService {
    public calendarRoutes: ApiLinkProviderType;

    constructor(routes: ApiLinkProviderType) {
        super();
        this.calendarRoutes = routes;
    }

    getPivotInfo(
        filter: CalendarPivotFilters
    ): Promise<AppResponse<CalendarPivotItem[]>> {
        return this.doServerXHR<CalendarPivotItem[]>({
            url: this.calendarRoutes.pivot(),
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                StartDate: filter.StartDate?.format(ISO8601_DATE_FORMAT),
                EndDate: filter.EndDate?.format(ISO8601_DATE_FORMAT),
                ResourceTypes:
                    filter.ResourceTypes?.length == 0
                        ? null
                        : filter.ResourceTypes,
                EmployeeIds: filter.EmployeeIds,
                ClientIds: filter.ClientIds,
                GroupIds: filter.GroupIds?.length == 0 ? null : filter.GroupIds,
            },
        });
    }

    getEvents(
        filter: DateRangeRequest
    ): Promise<AppResponse<CalendarEventTemplateResponse[]>> {
        return this.doServerXHR<CalendarEventTemplateResponse[]>({
            url: this.calendarRoutes.eventsList(filter),
            method: "get",
        });
    }

    constructFormData(template: CalendarEventTemplateDetails) {
        const { files, ...rest } = template;

        const fD = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                fD.append("files", files[i]);
            }
        }
        const json = JSON.stringify({
            ...rest,
            EventDay: template.EventDay?.format(ISO8601_DATE_FORMAT),
            EndDate: template.EndDate?.format(ISO8601_DATE_FORMAT),
            StartTime:
                !template.AllDay && template.StartTime
                    ? template.StartTime.format(ISO8601_TIME_FORMAT)
                    : null,
            EndTime:
                !template.AllDay && template.EndTime
                    ? template.EndTime.format(ISO8601_TIME_FORMAT)
                    : null,
            ReceiptTypeIdentifierHashes: template.Receipts.filter(
                (r) => r.IdentifierHash != null
            ).map((r) => r.IdentifierHash) as any,
        } as CalendarEventTemplateDetailsResponse);

        fD.append("id", template.Id ? template.Id.toString() : "0");
        fD.append("Data", json);
        return fD;
    }

    createUpdate(
        template: CalendarEventTemplateDetails
    ): Promise<AppResponse<CalendarEventTemplateDetails>> {
        const fD = this.constructFormData(template);
        return this.doServerXHR<CalendarEventTemplateDetails>({
            url: this.calendarRoutes.createUpdate(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }

    getCalendarEventDetails(
        templateId: string
    ): Promise<AppResponse<CalendarEventTemplateDetailsResponse>> {
        return this.doServerXHR<CalendarEventTemplateDetailsResponse>({
            url: this.calendarRoutes.get(templateId),
            method: "get",
        });
    }

    delete(templateId: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.calendarRoutes.delete(templateId),
            method: "post",
        });
    }
    deleteFile(
        templateId: string,
        fileId: number
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.calendarRoutes.deleteFile(templateId, fileId),
            method: "post",
        });
    }
}
