import { AppSelectOld, AppSelectOldProps } from "components/AppSelect";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import { SelectItem } from "models/general";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface MonthsFilterDropdownProps {
    value?: number;
    readonly?: boolean;
    usePleaseSelect?: boolean;
    useShortNames?: boolean;
    useNumbers?: boolean;
    onChange: (val?: number) => void;
}

export const MonthsFilterDropdown: React.FC<
    MonthsFilterDropdownProps & Pick<AppSelectOldProps, "styles">
> = ({
    value,
    readonly,
    styles,
    usePleaseSelect = false,
    useShortNames = false,
    useNumbers = false,
    onChange: onValueChange,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();

    const { monthOptions, defaultOption } = useMemo(() => {
        const defaultOption: SelectItem = {
            Text: usePleaseSelect ? t("common.pleaseSelect") : t("common.all"),
            Value: null,
        };
        const months = useShortNames ? moment.monthsShort() : moment.months();
        return {
            defaultOption,
            monthOptions: [
                defaultOption,
                ...months.map((m, i) => {
                    return {
                        Value: i + 1,
                        Text: useNumbers ? i + 1 : m,
                    } as SelectItem;
                }),
            ],
        };
    }, [appLocale]);

    return (
        <AppSelectOld
            showDropdownIndicator={isNil(value)}
            showCustomClearIndicator={true}
            menuPortalTarget={document.body}
            styles={styles}
            options={monthOptions}
            isDisabled={readonly}
            getOptionValue={(opt: SelectItem) => opt.Value}
            value={
                value && monthOptions.find((x) => x.Value === value)
                    ? monthOptions.find((x) => x.Value === value)
                    : defaultOption
            }
            getOptionLabel={(opt: SelectItem) => opt.Text}
            onChange={(e: SelectItem) =>
                e && onValueChange(e.Value ? (e.Value as number) : undefined)
            }
        />
    );
};
