import { AppPlatforms } from "models/general";
import { UserSortColumn } from "models/userManagement/enum";
import { UserRoleManagementItem } from "./model";

export function defaultUserRoleItemValue(
    device: AppPlatforms = AppPlatforms.WEB
) {
    return {
        Description: "",
        Email: "",
        PermissionGroupId: 0,
        FirstName: "",
        LastName: "",
        AssignedRole: null,
        CreatedAt: null,
        IsActive: true,
        IsRegistered: false,
        Name: "",
        RoleId: "",
        Id: null,
    } as UserRoleManagementItem;
}

export const getUserSortColumnKeyFromEnum = (
    sortColumn: UserSortColumn | undefined
): keyof UserRoleManagementItem => {
    switch (sortColumn) {
        case UserSortColumn.CreatedAt:
            return "CreatedAt";
        case UserSortColumn.IsActive:
            return "IsActive";
        case UserSortColumn.Name:
            return "Name";
        default:
            return "Name";
    }
};
