import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import { ValidityStateManager, ValidityState } from "models/general";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { useTranslation } from "react-i18next";
import styles from "./EmpKeyTemplateRow.module.scss";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { EmpKeyTemplateField } from "models/empKeyTemplate";

export interface EmpKeyTemplateFieldRowProps {
    value: EmpKeyTemplateField;
    isLocation?: boolean;
    onChange: (field: EmpKeyTemplateField) => void;
    validityState?: ValidityState;
    displayFieldName: string;
    onDelete?: (uuid: string) => void;
    readonly?: boolean;
}

const Component: React.FC<EmpKeyTemplateFieldRowProps> = ({
    value,
    onChange,
    validityState,
    onDelete,
    displayFieldName,
    readonly = false,
}) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <Form.Row>
                    <Col xs={12} md={4} lg={4} xl={3}>
                        <AppInputField
                            label={t("templates.shortName.name")}
                            value={displayFieldName}
                            disabled={true}
                            onValueChange={(val) => {}}
                            placeholder={t("templates.shortName.name")}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={4} xl={3}>
                        <AppNumberInput
                            label={t("empKeyTemplate.employeeKey")}
                            showError={true}
                            formatTheInitialValue={true}
                            showEmptyError={false}
                            readOnly={readonly}
                            allowZero={true}
                            format={FOUR_PRECISION_NUMERIC}
                            value={value.EmpKey}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    EmpKey: defaultTo(val, 0),
                                })
                            }
                            placeholder={t("empKeyTemplate.employeeKey")}
                            error={validityStateManager.getFirstErrorInfo(
                                "EmpKey"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
        </div>
    );
};
Component.displayName = "EmpKeyTemplateFieldRow";
export const EmpKeyTemplateFieldRow = React.memo(Component);
export default EmpKeyTemplateFieldRow;
