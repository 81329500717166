import bsBs from "date-fns/locale/bs";
import csCz from "date-fns/locale/cs";
import deDe from "date-fns/locale/de";
import elEl from "date-fns/locale/el";
import enUS from "date-fns/locale/en-US";
import esEs from "date-fns/locale/es";
import etEt from "date-fns/locale/et";
import fiFi from "date-fns/locale/fi";
import frFr from "date-fns/locale/fr";
import hrHr from "date-fns/locale/hr";
import huHu from "date-fns/locale/hu";
import itIt from "date-fns/locale/it";
import nlNl from "date-fns/locale/nl";
import plPL from "date-fns/locale/pl";
import skSk from "date-fns/locale/sk";
import slSl from "date-fns/locale/sl";
import {
    BOSNIAN_DATE_FORMAT,
    CROATIAN_DATE_FORMAT,
    CZECH_DATE_FORMAT,
    DAY_MONTH_SHORT_BOSNIAN_FORMAT,
    DAY_MONTH_SHORT_CROATIAN_FORMAT,
    DAY_MONTH_SHORT_CZECH_FORMAT,
    DAY_MONTH_SHORT_DUTCH_FORMAT,
    DAY_MONTH_SHORT_ENGLISH_FORMAT,
    DAY_MONTH_SHORT_ESTONIAN_FORMAT,
    DAY_MONTH_SHORT_FINNISH_FORMAT,
    DAY_MONTH_SHORT_FRENCH_FORMAT,
    DAY_MONTH_SHORT_GERMAN_FORMAT,
    DAY_MONTH_SHORT_GREEK_FORMAT,
    DAY_MONTH_SHORT_HUNGARIAN_FORMAT,
    DAY_MONTH_SHORT_ITALIAN_FORMAT,
    DAY_MONTH_SHORT_POLISH_FORMAT,
    DAY_MONTH_SHORT_SLOVAK_FORMAT,
    DAY_MONTH_SHORT_SLOVENIAN_FORMAT,
    DAY_MONTH_SHORT_SPANISH_FORMAT,
    DUTCH_DATE_FORMAT,
    ENGLISH_DATE_FORMAT,
    ESTONIAN_DATE_FORMAT,
    FINNISH_DATE_FORMAT,
    FRENCH_DATE_FORMAT,
    GERMAN_DATE_FORMAT,
    GREEK_DATE_FORMAT,
    HUNGARIAN_DATE_FORMAT,
    ITALIAN_DATE_FORMAT,
    POLISH_DATE_FORMAT,
    SLOVAK_DATE_FORMAT,
    SLOVENIAN_DATE_FORMAT,
    SPANISH_DATE_FORMAT,
    TIME_FORMAT_12H,
    TIME_FORMAT_24H,
} from "globals/constants";
import { AppLocale, AppLocale_ISO2 } from "models/general";
import {
    DATEPICKER_BOSNIAN_DATE_FORMAT,
    DATEPICKER_CROATIAN_DATE_FORMAT,
    DATEPICKER_CZECH_DATE_FORMAT,
    DATEPICKER_DUTCH_DATE_FORMAT,
    DATEPICKER_ENGLISH_DATE_FORMAT,
    DATEPICKER_ESTONIAN_DATE_FORMAT,
    DATEPICKER_FINNISH_DATE_FORMAT,
    DATEPICKER_FRENCH_DATE_FORMAT,
    DATEPICKER_GERMAN_DATE_FORMAT,
    DATEPICKER_GREEK_DATE_FORMAT,
    DATEPICKER_HUNGARIAN_DATE_FORMAT,
    DATEPICKER_ITALIAN_DATE_FORMAT,
    DATEPICKER_SHORT_MONTH_YEAR_FORMAT,
    DATEPICKER_MONTH_FORMAT,
    DATEPICKER_MONTH_YEAR_FORMAT,
    DATEPICKER_POLISH_DATE_FORMAT,
    DATEPICKER_SLOVAK_DATE_FORMAT,
    DATEPICKER_SLOVENIAN_DATE_FORMAT,
    DATEPICKER_SPANISH_DATE_FORMAT,
    DATEPICKER_TIME_ENGLISH_FORMAT,
    DATEPICKER_TIME_GERMAN_FORMAT,
    DATEPICKER_YEAR_FORMAT,
    DATEPICKER_DATE_MONTH_FORMAT,
    DATEPICKER_SHORT_MONTH_FORMAT,
} from "./constants";
import { DatePickerType } from "./enum";

export const getDatePickerFormat = (
    type: DatePickerType,
    locale: AppLocale
): string => {
    switch (type) {
        case DatePickerType.TIME:
            return getTimeFormatForLocale(locale);
        case DatePickerType.DATE_TIME:
            return `${getDateFormatForLocale(locale)} ${getTimeFormatForLocale(
                locale
            )}`;
        case DatePickerType.DATE:
            return getDateFormatForLocale(locale);
        case DatePickerType.MONTH:
            // same for all locales
            return DATEPICKER_MONTH_FORMAT;
        case DatePickerType.SHORT_MONTH:
            // same for all locales
            return DATEPICKER_SHORT_MONTH_FORMAT;
        case DatePickerType.MONTH_YEAR:
            // same for all locales
            return DATEPICKER_MONTH_YEAR_FORMAT;
        case DatePickerType.DATE_MONTH:
            // same for all locales
            return DATEPICKER_DATE_MONTH_FORMAT;
        case DatePickerType.MONTH_YEAR_SHORT:
            // same for all locales
            return DATEPICKER_SHORT_MONTH_YEAR_FORMAT;
        case DatePickerType.YEAR:
            // same for all locales
            return DATEPICKER_YEAR_FORMAT;
    }
};

export const getDatePickerLocale = (locale: AppLocale) => {
    switch (locale) {
        case AppLocale.GERMAN:
            return deDe;
        case AppLocale.ITALIAN:
            return itIt;
        case AppLocale.BOSNIAN:
            return bsBs;
        case AppLocale.POLISH:
            return plPL;
        case AppLocale.CROATIAN:
            return hrHr;
        case AppLocale.CZECH:
            return csCz;
        case AppLocale.HUNGARIAN:
            return huHu;
        case AppLocale.SLOVAK:
            return skSk;
        case AppLocale.SLOVENIAN:
            return slSl;
        case AppLocale.FRENCH:
            return frFr;
        case AppLocale.SPANISH:
            return esEs;
        case AppLocale.DUTCH:
            return nlNl;
        case AppLocale.ESTONIAN:
            return etEt;
        case AppLocale.FINNISH:
            return fiFi;
        case AppLocale.GREEK:
            return elEl;

        default:
            return enUS;
    }
};

const getTimeFormatForLocale = (locale: AppLocale): string => {
    if (locale == AppLocale.GERMAN) {
        return DATEPICKER_TIME_GERMAN_FORMAT;
    }
    return DATEPICKER_TIME_ENGLISH_FORMAT;
};

const getDateFormatForLocale = (locale: AppLocale): string => {
    switch (locale) {
        case AppLocale.GERMAN:
            return DATEPICKER_GERMAN_DATE_FORMAT;
        case AppLocale.BOSNIAN:
            return DATEPICKER_BOSNIAN_DATE_FORMAT;
        case AppLocale.CROATIAN:
            return DATEPICKER_CROATIAN_DATE_FORMAT;
        case AppLocale.CZECH:
            return DATEPICKER_CZECH_DATE_FORMAT;
        case AppLocale.DUTCH:
            return DATEPICKER_DUTCH_DATE_FORMAT;
        case AppLocale.ESTONIAN:
            return DATEPICKER_ESTONIAN_DATE_FORMAT;
        case AppLocale.FINNISH:
            return DATEPICKER_FINNISH_DATE_FORMAT;
        case AppLocale.FRENCH:
            return DATEPICKER_FRENCH_DATE_FORMAT;
        case AppLocale.GREEK:
            return DATEPICKER_GREEK_DATE_FORMAT;
        case AppLocale.HUNGARIAN:
            return DATEPICKER_HUNGARIAN_DATE_FORMAT;
        case AppLocale.ITALIAN:
            return DATEPICKER_ITALIAN_DATE_FORMAT;
        case AppLocale.POLISH:
            return DATEPICKER_POLISH_DATE_FORMAT;
        case AppLocale.SLOVAK:
            return DATEPICKER_SLOVAK_DATE_FORMAT;
        case AppLocale.SLOVENIAN:
            return DATEPICKER_SLOVENIAN_DATE_FORMAT;
        case AppLocale.SPANISH:
            return DATEPICKER_SPANISH_DATE_FORMAT;

        default:
            return DATEPICKER_ENGLISH_DATE_FORMAT;
    }
};
export function getMomentTimeFormat(
    locale: AppLocale | AppLocale_ISO2 | undefined
) {
    return locale === AppLocale.GERMAN || locale === "de"
        ? TIME_FORMAT_24H
        : TIME_FORMAT_12H;
}
export function getMomentDateTimeFormat(
    locale: AppLocale | AppLocale_ISO2 | undefined
) {
    return `${getMomentDateFormat(locale)} ${getMomentTimeFormat(locale)}`;
}

export function getMomentDateFormat(
    locale: AppLocale | AppLocale_ISO2 | undefined
) {
    switch (locale) {
        case AppLocale.GERMAN:
        case "de":
            return GERMAN_DATE_FORMAT;
        case AppLocale.BOSNIAN:
        case "bs":
            return BOSNIAN_DATE_FORMAT;
        case AppLocale.CROATIAN:
        case "hr":
            return CROATIAN_DATE_FORMAT;
        case AppLocale.CZECH:
        case "cs":
            return CZECH_DATE_FORMAT;
        case AppLocale.DUTCH:
        case "nl":
            return DUTCH_DATE_FORMAT;
        case AppLocale.ESTONIAN:
        case "et":
            return ESTONIAN_DATE_FORMAT;
        case AppLocale.FINNISH:
        case "fi":
            return FINNISH_DATE_FORMAT;
        case AppLocale.FRENCH:
        case "fr":
            return FRENCH_DATE_FORMAT;
        case AppLocale.GREEK:
        case "el":
            return GREEK_DATE_FORMAT;
        case AppLocale.HUNGARIAN:
        case "hu":
            return HUNGARIAN_DATE_FORMAT;
        case AppLocale.ITALIAN:
        case "it":
            return ITALIAN_DATE_FORMAT;
        case AppLocale.POLISH:
        case "pl":
            return POLISH_DATE_FORMAT;
        case AppLocale.SLOVAK:
        case "sk":
            return SLOVAK_DATE_FORMAT;
        case AppLocale.SLOVENIAN:
        case "sl":
            return SLOVENIAN_DATE_FORMAT;
        case AppLocale.SPANISH:
        case "es":
            return SPANISH_DATE_FORMAT;

        default:
            return ENGLISH_DATE_FORMAT;
    }
}
export function getMomentDayMonthShortFormat(
    locale: AppLocale | AppLocale_ISO2 | undefined
) {
    switch (locale) {
        case AppLocale.GERMAN:
        case "de":
            return DAY_MONTH_SHORT_GERMAN_FORMAT;
        case AppLocale.BOSNIAN:
        case "bs":
            return DAY_MONTH_SHORT_BOSNIAN_FORMAT;
        case AppLocale.CROATIAN:
        case "hr":
            return DAY_MONTH_SHORT_CROATIAN_FORMAT;
        case AppLocale.CZECH:
        case "cs":
            return DAY_MONTH_SHORT_CZECH_FORMAT;
        case AppLocale.DUTCH:
        case "nl":
            return DAY_MONTH_SHORT_DUTCH_FORMAT;
        case AppLocale.ESTONIAN:
        case "et":
            return DAY_MONTH_SHORT_ESTONIAN_FORMAT;
        case AppLocale.FINNISH:
        case "fi":
            return DAY_MONTH_SHORT_FINNISH_FORMAT;
        case AppLocale.FRENCH:
        case "fr":
            return DAY_MONTH_SHORT_FRENCH_FORMAT;
        case AppLocale.GREEK:
        case "el":
            return DAY_MONTH_SHORT_GREEK_FORMAT;
        case AppLocale.HUNGARIAN:
        case "hu":
            return DAY_MONTH_SHORT_HUNGARIAN_FORMAT;
        case AppLocale.ITALIAN:
        case "it":
            return DAY_MONTH_SHORT_ITALIAN_FORMAT;
        case AppLocale.POLISH:
        case "pl":
            return DAY_MONTH_SHORT_POLISH_FORMAT;
        case AppLocale.SLOVAK:
        case "sk":
            return DAY_MONTH_SHORT_SLOVAK_FORMAT;
        case AppLocale.SLOVENIAN:
        case "sl":
            return DAY_MONTH_SHORT_SLOVENIAN_FORMAT;
        case AppLocale.SPANISH:
        case "es":
            return DAY_MONTH_SHORT_SPANISH_FORMAT;
        default:
            return DAY_MONTH_SHORT_ENGLISH_FORMAT;
    }
}
