import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetisMenuContentType } from "models/metisMenu";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { generalNav } from "hooks/layout/sidebar/generalNav";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { defaultTo } from "lodash-es";
import { BusinessType } from "models/business";
import { SideMenuTypes } from "models/general";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { businessRoutesMap } from "routing/routes/business/routesMap";
import { lwSupportRoutesMap } from "routing/routes/lillywait/support/routesMap";
import { adminNav } from "./adminNav";
import { supportNav } from "./supportNav";
import { useSupportMode } from "hooks/useSupportMode";

export const useAppSidebarMenu = () => {
    const { t } = useTranslation();

    const { linkProvider, relativePathProvider } = useRouting();

    const { exitSupportMode } = useSupportMode();
    const { sessionBusiness } = useSessionBusiness();
    const { checkPermission, userAccessInfo } = useCheckPermission();
    const {
        hasBusinessGeneralPermissions,
        hasBusinessAdminPermissions,
        hasLwAdminPermissions,
        hasSupportViewPermissions,
        isSupportViewEnabled,
        supportBusinessType,
    } = userAccessInfo();

    const businessType = defaultTo(
        sessionBusiness?.Type,
        BusinessType.KINDERGARTEN
    );

    const getSideBarMenu = useCallback(
        (isAdminView?: boolean, isSupportView?: boolean) => {
            isAdminView = defaultTo(isAdminView, false);
            isSupportView = defaultTo(isSupportView, false);

            const navType: SideMenuTypes =
                !isSupportView && !isAdminView && !isSupportViewEnabled
                    ? SideMenuTypes.GENERAL
                    : isAdminView
                    ? SideMenuTypes.ADMIN
                    : isSupportView
                    ? SideMenuTypes.SUPPORT_VIEW
                    : SideMenuTypes.GENERAL;

            return {
                navType: navType,
                isGeneralNav: navType === SideMenuTypes.GENERAL,
                isAdminNav: navType === SideMenuTypes.ADMIN,
                isSupportNav: navType === SideMenuTypes.SUPPORT_VIEW,
                mainMenu:
                    navType === "general"
                        ? generalNav(
                              linkProvider,
                              relativePathProvider,
                              businessType,
                              linkProvider.screens.home(checkPermission),
                              !hasBusinessGeneralPermissions
                          )
                        : navType === "admin"
                        ? adminNav(
                              linkProvider,
                              relativePathProvider,
                              businessType,
                              !hasBusinessAdminPermissions &&
                                  !hasLwAdminPermissions
                          )
                        : supportNav(
                              linkProvider,
                              relativePathProvider,
                              !hasSupportViewPermissions
                          ),
                bottomMenu: (isSupportViewEnabled && hasSupportViewPermissions
                    ? [
                          // from support to LwSupportNav
                          {
                              label: t("sidebar.goBack"),
                              icon: (
                                  <FontAwesomeIcon
                                      className="sidebarIcon"
                                      icon={"arrow-circle-left"}
                                  />
                              ),
                              onItemClick: () => {
                                  exitSupportMode();
                              },
                              permissions: defaultTo(
                                  lwSupportRoutesMap[
                                      relativePathProvider.lillywait
                                          .support()
                                          .screens.list()
                                  ].permKeys,
                                  []
                              ),
                          },
                      ]
                    : isSupportView && hasLwAdminPermissions
                    ? [
                          // from supportNav to Lw Admin
                          {
                              label: t("sidebar.goBack"),
                              classItem: "app-theme-admin",
                              icon: (
                                  <FontAwesomeIcon
                                      className="sidebarIcon"
                                      icon={"arrow-circle-left"}
                                  />
                              ),
                              to: linkProvider.business.screens.admin.companies(),
                              permissions: defaultTo(
                                  businessRoutesMap[
                                      relativePathProvider.business.screens.admin.companies()
                                  ].permKeys,
                                  []
                              ),
                          },
                      ]
                    : isAdminView
                    ? [
                          // from admin view
                          ...(hasSupportViewPermissions
                              ? [
                                    {
                                        label: t("sidebar.support"),
                                        icon: (
                                            <FontAwesomeIcon
                                                icon={"users-cog"}
                                            />
                                        ),
                                        to: linkProvider.lillywait
                                            .support()
                                            .screens.list(
                                                BusinessType.KINDERGARTEN
                                            ),
                                        classItem: "app-theme-support",
                                        permissions: defaultTo(
                                            lwSupportRoutesMap[
                                                relativePathProvider.lillywait
                                                    .support()
                                                    .screens.list()
                                            ].permKeys,
                                            []
                                        ),
                                    },
                                ]
                              : []),
                          {
                              label: t("sidebar.goBack"),
                              icon: (
                                  <FontAwesomeIcon
                                      className="sidebarIcon"
                                      icon={"arrow-circle-left"}
                                  />
                              ),
                              to: linkProvider.screens.home(), // home
                              classItem: "app-theme-blue",
                              permissions: defaultTo(
                                  businessRoutesMap[
                                      relativePathProvider.business.screens.dashboard()
                                  ].permKeys,
                                  []
                              ),
                          },
                      ]
                    : !isAdminView &&
                      !isSupportView &&
                      hasBusinessAdminPermissions
                    ? [
                          // general view
                          {
                              label: t("sidebar.administration"),
                              icon: (
                                  <FontAwesomeIcon
                                      className="sidebarIcon"
                                      icon={"users-cog"}
                                  />
                              ),
                              to: linkProvider.business.screens.admin.companies(),
                              activeIfPatternLike:
                                  SideMenuTypes.ADMIN.toString(),
                              permissions: defaultTo(
                                  businessRoutesMap[
                                      relativePathProvider.business.screens.admin.companies()
                                  ].permKeys,
                                  []
                              ),
                              classItem: "app-theme-admin",
                          },
                      ]
                    : []) as MetisMenuContentType[],
            };
        },
        [
            sessionBusiness,
            checkPermission,
            linkProvider,
            t,
            hasSupportViewPermissions,
        ]
    );
    return { getSideBarMenu };
};

export default useAppSidebarMenu;
