import { useEffect, useState } from "react";
import { defaultTo } from "lodash-es";
import { AppSwitch } from "components";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";
import { useRouting } from "hooks/general/routing";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { useMutation } from "react-query";
import styles from "./ExplanationModeToggle.module.scss";

export interface ExplanationModeToggleProps {
    value: boolean;
    readonly?: boolean;
}

export function ExplanationModeToggle(props: ExplanationModeToggleProps) {
    const { value, readonly = false } = props;
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] = useState<boolean>(defaultTo(value, false));
    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );

    const {
        isLoading: updating,
        data: updateResponse,
        mutate: updateStatus,
    } = useMutation(
        getCurrentBusinessServiceKey("updateExplanationModeVisibility"),
        async (flag: boolean) =>
            await businessService.updateExplanationModeVisibility(flag)
    );

    useEffect(() => {
        if (!updating && updateResponse) {
            if (updateResponse.Data != null) {
                if (updateResponse.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("common.successMessage")}`,
                        "success"
                    );
                }
            } else if (updateResponse.Code != null) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("common.error.requestFailed")}`,
                    "error"
                );
                setState((old) => !old);
            }
        }
    }, [updating, updateResponse]);

    return (
        <AppSwitch
            label={t("explanationMode.toggleLabel")}
            disabled={readonly}
            className={styles.root}
            value={state}
            color={"green-toggle"}
            id={"explanationMode"}
            labelPosition="left"
            onChange={(checked) => {
                updateStatus(checked);
                setState(checked);
            }}
        />
    );
}

export default ExplanationModeToggle;
