import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import { BusinessCreditor } from "models/creditors";

export interface IBusinessCreditorService {
    list(): Promise<AppResponse<BusinessCreditor[]>>;
    get(id?: string): Promise<AppResponse<BusinessCreditor>>;
    createUpdate(
        model: BusinessCreditor
    ): Promise<AppResponse<BusinessCreditor>>;
    delete(id?: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.creditors;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessCreditorServiceKey(
    name: keyof IBusinessCreditorService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessCreditorService_${name}_${JSON.stringify(data)}`;
}

export class BusinessCreditorService
    extends BaseService
    implements IBusinessCreditorService
{
    public kontoRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.kontoRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<BusinessCreditor[]>> {
        return this.doServerXHR<BusinessCreditor[]>({
            url: this.kontoRoutes.list(),
            method: "get",
        });
    }

    get(id?: string): Promise<AppResponse<BusinessCreditor>> {
        return this.doServerXHR<BusinessCreditor>({
            url: this.kontoRoutes.withId(id).get(),
            method: "get",
        });
    }

    createUpdate(
        model: BusinessCreditor
    ): Promise<AppResponse<BusinessCreditor>> {
        return this.doServerXHR<BusinessCreditor>({
            url: this.kontoRoutes.createUpdate(),
            method: "post",
            data: {
                ...model,
                ...model.Address,
            },
        });
    }

    delete(id?: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.kontoRoutes.withId(id).delete(),
            method: "delete",
        });
    }
}

export default BusinessCreditorService;
