import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import styles from "../CompensationTemplateFieldRow.module.scss";
import {
    CompensationTemplateField,
    CompensationTemplateFieldType,
} from "models/compensationTemplate";
import { ValidityStateManager, ValidityState } from "models/general";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { useTranslation } from "react-i18next";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { DragHandle } from "components/Sortable";
import { AppDeleteButton } from "components/Buttons";

export interface CompensationTemplateFieldRowProps {
    value: CompensationTemplateField;
    isLocation?: boolean;
    onChange: (field: CompensationTemplateField) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
    canEditValue?: boolean;
}

export const CompensationTemplateFieldRow: React.FC<
    CompensationTemplateFieldRowProps
> = ({
    value,
    onChange,
    validityState,
    onDelete,
    readonly = false,
    canEditValue = false,
    isLocation = false,
}) => {
    const { t } = useTranslation();
    const { currencySymbol } = useLocaleHelpers();

    const validityStateManager = new ValidityStateManager(validityState);

    const minCol = isLocation ? 6 : 4;
    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                {value.Type == CompensationTemplateFieldType.Option &&
                    !value.IsDefault && (
                        <AppDeleteButton
                            readonly={readonly}
                            className={styles.rowDel}
                            onClick={() => {
                                if (!readonly && onDelete) {
                                    onDelete(value.Uuid);
                                }
                            }}
                        />
                    )}
                <Form.Row>
                    {value.Type == CompensationTemplateFieldType.Option && (
                        <React.Fragment>
                            <Col sm={minCol} xs={12} md={minCol}>
                                <AppInputField
                                    label={t("templates.name.name")}
                                    showEmptyError={true}
                                    value={value.Name}
                                    readOnly={readonly || value.IsDefault}
                                    onValueChange={(val) => {
                                        onChange({
                                            ...value,
                                            Name: defaultTo(val, ""),
                                        });
                                    }}
                                    placeholder={t("templates.name.name")}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "Name"
                                    )}
                                />
                            </Col>
                            <Col sm={minCol} xs={12} md={minCol}>
                                <AppInputField
                                    label={t("templates.shortName.name")}
                                    showEmptyError={true}
                                    value={value.ShortName}
                                    readOnly={readonly || value.IsDefault}
                                    onValueChange={(val) => {
                                        onChange({
                                            ...value,
                                            ShortName: defaultTo(val, ""),
                                        });
                                    }}
                                    placeholder={t("templates.shortName.name")}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "ShortName"
                                    )}
                                />
                            </Col>

                            <Col sm={minCol} xs={12} md={minCol}>
                                <AppNumberInput
                                    label={`${t(
                                        "compensationTemplate.value.name"
                                    )} in ${currencySymbol}`}
                                    showError={true}
                                    showEmptyError={true}
                                    allowZero={true}
                                    isForCurrency={true}
                                    formatTheInitialValue={true}
                                    value={value.FieldValue?.Value}
                                    readOnly={readonly || value.IsDefault}
                                    onChange={(val) => {
                                        onChange({
                                            ...value,
                                            FieldValue: {
                                                ...defaultTo(
                                                    value.FieldValue,
                                                    {}
                                                ),
                                                Value: val,
                                            },
                                        });
                                    }}
                                    placeholder={t(
                                        "compensationTemplate.value.name"
                                    )}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "Value"
                                    )}
                                />
                            </Col>
                        </React.Fragment>
                    )}

                    {value.Type == CompensationTemplateFieldType.Simple && (
                        <Col md={12}>
                            <Form.Row className={styles.valueField}>
                                {!canEditValue && (
                                    <Col sm={8} xs={12} md={8}>
                                        <AppNumberInput
                                            label={`${t(
                                                "compensationTemplate.value.name"
                                            )} in ${currencySymbol}`}
                                            showError={true}
                                            formatTheInitialValue={true}
                                            showEmptyError={true}
                                            allowZero={true}
                                            isForCurrency={true}
                                            value={value.FieldValue?.Value}
                                            readOnly={readonly}
                                            onChange={(val) =>
                                                onChange({
                                                    ...value,
                                                    FieldValue: {
                                                        ...defaultTo(
                                                            value.FieldValue,
                                                            {}
                                                        ),
                                                        Value: val,
                                                    },
                                                })
                                            }
                                            placeholder={t(
                                                "compensationTemplate.value.name"
                                            )}
                                            error={validityStateManager.getFirstErrorInfo(
                                                "Value"
                                            )}
                                        />
                                    </Col>
                                )}
                            </Form.Row>
                        </Col>
                    )}
                </Form.Row>
            </div>
            {!readonly &&
                value.Type == CompensationTemplateFieldType.Option &&
                !value.IsDefault && <DragHandle />}
        </div>
    );
};

export default CompensationTemplateFieldRow;
