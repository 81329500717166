import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppInputField } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ValidityStateManager } from "models/general";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import {
    BusinessCreditorService,
    getBusinessCreditorServiceKey,
} from "services/business";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { validateBusinessCreditor } from "models/creditors/helper";
import { BusinessCreditor } from "models/creditors";
import AddressFields from "commonPartials/AddressFields";

export interface CreditorCreateEditFieldsProps {
    value?: BusinessCreditor;
    readonly?: boolean;
    onBack?: () => void;
    onSuccess?: () => void;
}

const md = 4;
export const CreditorCreateEditFields: React.FC<
    CreditorCreateEditFieldsProps
> = ({ value, readonly = false, onBack, onSuccess }) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { id: businessId } = useSessionBusiness();
    const queryClient = useQueryClient();
    const [state, setState] = useState<BusinessCreditor>(
        defaultTo(value, {
            BusinessId: businessId,
            Address: {},
        } as BusinessCreditor)
    );

    const creditorService = new BusinessCreditorService(
        linkProvider.business.api.creditors
    );

    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(async () => await creditorService.createUpdate(state), {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${res.Data.Name}' ${t(
                            `common.actions.${
                                state.Id && state.Id > 0
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getBusinessCreditorServiceKey("list")
                    );
                    if (onBack) {
                        onBack();
                    }
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            },
        });

    const validityStateManager = useMemo(() => {
        const validationState = validateBusinessCreditor(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    const isEdit = state.Id && state.Id > 0 ? true : false;

    return (
        <AppContainer
            onBack={onBack}
            isTabContainer={isEdit}
            heightToAdjust={165}
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            readonly ||
                            createUpdateLoading ||
                            !validityStateManager.isStateValid(),
                        onClick: () => {
                            createUpdate();
                        },
                    }}
                />
            }
        >
            <Row>
                <Col md={md}>
                    <AppInputField
                        value={state.Name}
                        label={t("accounting.creditors.name.name")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                Name: defaultTo(val, ""),
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                    />
                </Col>
                <Col md={md}>
                    <AppInputField
                        label={t("accounting.creditors.email.name")}
                        value={state.Email}
                        readOnly={readonly}
                        onValueChange={(val) =>
                            setState({ ...state, Email: val as string })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Email")}
                    />
                </Col>
                <Col md={md}>
                    <AppInputField
                        label={t("accounting.creditors.contactName.name")}
                        value={state.ContactName}
                        readOnly={readonly}
                        onValueChange={(val) =>
                            setState({ ...state, ContactName: val as string })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "ContactName"
                        )}
                    />
                </Col>
                <Col md={md}>
                    <AppInputField
                        label={t("accounting.creditors.telephone.name")}
                        value={state.Telephone}
                        readOnly={readonly}
                        onValueChange={(val) =>
                            setState({ ...state, Telephone: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "Telephone"
                        )}
                    />
                </Col>
                <Col md={md}>
                    <AppInputField
                        label={t("accounting.creditors.fax.name")}
                        value={state.Fax}
                        readOnly={readonly}
                        onValueChange={(val) =>
                            setState({ ...state, Fax: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Fax")}
                    />
                </Col>
                <Col md={md}>
                    <AppInputField
                        label={t("accounting.creditors.webAddress.name")}
                        value={state.Url}
                        readOnly={readonly}
                        onValueChange={(val) =>
                            setState({ ...state, Url: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Url")}
                    />
                </Col>
                <AddressFields
                    value={state.Address}
                    readonly={readonly}
                    colSize={md}
                    validityState={
                        validityStateManager.getFieldState("Address")
                            ? validityStateManager.getFieldState("Address")
                                  ?.children
                            : undefined
                    }
                    onChange={(val) => {
                        setState((old) => ({ ...old, Address: val }));
                    }}
                />
            </Row>
        </AppContainer>
    );
};

export default CreditorCreateEditFields;
