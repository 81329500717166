import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import { AppResponse } from "models/general/response";
import { BusinessShift, BusinessShiftResponse } from "models/businessShifts";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IBusinessShiftService {
    list(): Promise<AppResponse<BusinessShiftResponse[]>>;
    get(id: string): Promise<AppResponse<BusinessShiftResponse>>;
    createUpdate(
        model: BusinessShift
    ): Promise<AppResponse<BusinessShiftResponse>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.currentBusiness().shift;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessShiftServiceKey(
    name: keyof IBusinessShiftService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessShiftService_${name}_${JSON.stringify(data)}`;
}

const constructFormData = (model: BusinessShift) => {
    const ob = {
        ...model,
        StartDate: model.StartDate?.format(ISO8601_DATE_FORMAT),
        EndDate: model.EndDate
            ? model.EndDate.format(ISO8601_DATE_FORMAT)
            : undefined,
        StartTime: model.StartTime.format(ISO8601_TIME_FORMAT),
        EndTime: model.EndTime.format(ISO8601_TIME_FORMAT),
        Areas: model.AreaIds,
    };
    return ob;
};

export class BusinessShiftService
    extends BaseService
    implements IBusinessShiftService
{
    public shiftRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.shiftRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<BusinessShiftResponse[]>> {
        return this.doServerXHR<BusinessShiftResponse[]>({
            url: this.shiftRoutes.list(),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<BusinessShiftResponse>> {
        return this.doServerXHR<BusinessShiftResponse>({
            url: this.shiftRoutes.get(id),
            method: "get",
        });
    }

    createUpdate(
        model: BusinessShift
    ): Promise<AppResponse<BusinessShiftResponse>> {
        const fD = constructFormData(model);
        return this.doServerXHR<BusinessShiftResponse>({
            url: this.shiftRoutes.createUpdate(),
            method: "post",
            data: fD,
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.shiftRoutes.delete(id),
            method: "post",
        });
    }
}

export default BusinessShiftService;
