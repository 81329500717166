import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import {
    CareScopeTemplate,
    CareScopeTemplateField,
    CareScopeTemplateSurchargeField,
    CareScopeTemplateSurchargeFieldGroup,
} from "./model";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";

export function validateCareScopeTemplateField(
    field: CareScopeTemplateField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof CareScopeTemplateField> = {
        CareStartInMinutes: [
            {
                rule: Validations.REQUIRED,
                message: t("careScopeTemplate.startTime.missing"),
            },
            {
                rule: Validations.NUMBER,
                message: t("careScopeTemplate.startTime.invalid"),
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 1 },
                message: t("common.errorMessages.positiveNum"),
            },
            {
                rule: Validations.LESSER_THAN,
                options: { value: field.CareEndInMinutes },
            },
        ],
        CareEndInMinutes: [
            {
                rule: Validations.NUMBER,
                message: t("careScopeTemplate.endTime.invalid"),
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: Math.max(field.CareStartInMinutes, 1) },
                message:
                    field.CareStartInMinutes <= 0
                        ? t("common.errorMessages.positiveNum")
                        : undefined,
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    return state;
}

export function validateCareScopeTemplateSurchargeFieldGroup(
    fieldGroup: CareScopeTemplateSurchargeFieldGroup,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof CareScopeTemplateSurchargeFieldGroup> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.name.missing"),
            },
        ],
        Fields: [
            {
                rule: Validations.ARRAY_REQUIRED,
                message: t("careScopeTemplate.surchargeGroup.empty"),
            },
        ],
    };

    let manager = new ValidityStateManager(
        getInitializedValidityStateFromRules(rules, fieldGroup, state)
    );
    fieldGroup.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateCareScopeTemplateSurchargeField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });
    return manager.state;
}

export function validateCareScopeTemplateSurchargeField(
    field: CareScopeTemplateSurchargeField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof CareScopeTemplateSurchargeField> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.name.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.shortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(rules, field, state);

    return state;
}

export function validateCareScopeTemplate(
    template: CareScopeTemplate,
    state = getInitializedValidityState([], [])
) {
    const careScopeValidationRules: ValidationRules<keyof CareScopeTemplate> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateName.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateShortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        careScopeValidationRules,
        template,
        state
    );

    let manager = new ValidityStateManager(state);
    template.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateCareScopeTemplateField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });
    template.SurchargeFieldGroups.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateCareScopeTemplateSurchargeFieldGroup(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });

    return manager.state;
}
