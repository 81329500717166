import { DashboardSection, DashboardSections, DashboardTabs } from "models";
import { BusinessPerson } from "models/general";
import { Moment } from "moment-timezone";
import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DashboardPersonInfoPopper } from "./DashboardPersonInfoPopper";
import styles from "./DashboardSectionCol.module.scss";
interface DashboardSectionColProps {
    section: DashboardSection;
    sectionType: DashboardSections;
    persons?: BusinessPerson[];
    selectedTab: DashboardTabs;
    headingTitle: string;
    personsDatesInfo?: { [key in number]: Moment[] | null };
}
export const DashboardSectionCol: React.FC<DashboardSectionColProps> = ({
    sectionType,
    section,
    persons,
    selectedTab,
    headingTitle,
    personsDatesInfo,
}) => {
    const { t } = useTranslation();
    const getPersons = () => {
        if (section.countWithPersonInfo.PersonIds.length > 0) {
            return persons?.filter(
                (x) =>
                    section.countWithPersonInfo.PersonIds.filter(
                        (y: number) => y === x.Id
                    ).length !== 0
            );
        }
        return [];
    };

    return (
        <Col md={4} lg={3} sm={6} style={{ overflowX: "auto" }}>
            <div className={styles.sectionContainer}>
                <div className={styles.infoContainer}>
                    <span className={styles.title}>{section.title}</span>
                    <span className={styles.subtitle}>{section.subtitle}</span>
                </div>
                <div
                    className={`${styles.numberCount} ${section.countClassName}`}
                >
                    {section.countWithPersonInfo &&
                        section.countWithPersonInfo.Count}
                    {section.showPersonInfo &&
                        section.countWithPersonInfo.Count > 0 && (
                            <DashboardPersonInfoPopper
                                mainHeading={`${headingTitle} ${
                                    selectedTab === DashboardTabs.CLIENTS
                                        ? t("common.clients")
                                        : t("common.employees")
                                }`}
                                duration={section.sectionRange}
                                subHeading={section.subtitle}
                                persons={getPersons()}
                                personsDatesInfo={personsDatesInfo}
                            />
                        )}
                </div>
            </div>
        </Col>
    );
};

export default DashboardSectionCol;
