import { EmployeeClientFiltersComponent } from "commonPartials/employeeClientFilter";
import { AppGoBackButton } from "components/Buttons";
import AppButton from "components/Buttons/AppButton";
import { AppContainer } from "components/Containers";
import { ErrorMessage } from "components/ErrorMessage";
import { AppInputField, AppSwitch, EmojiPicker } from "components/FormFields";
import { AppOverlayLoader } from "components/Loaders";
import TinymceEditor, {
    appendContentInActiveEditor,
} from "components/TinymceEditor";
import { BaseEmoji } from "emoji-mart";
import { showSweetAlertConfirmation } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useAppContext } from "hoc/providers";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { BusinessType } from "models/business";
import {
    AvatarInfo,
    getValidityStateFromApiResponse,
    UserFilterType,
    ValidityStateManager,
} from "models/general";
import {
    getDefaultMessageTemplate,
    MessageTemplate,
    validateMessageTemplate,
} from "models/messages";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { MessageService } from "services/business";
import { INPUT_COLOR } from "theme/themeConstants";
import styles from "./MessagesCreate.module.scss";
import { useMessageLayoutContext } from "./MessagesLayout";
import layoutStyles from "./MessagesLayout.module.scss";

interface CreateMessageState {
    message: MessageTemplate;
    loading: boolean;
}

export const MessagesCreate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { refetchMessageCount } = useMessageLayoutContext();

    const { locale } = useAppContext();
    const { linkProvider } = useRouting();

    const { user } = useSessionUser();

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const { sessionBusiness } = useSessionBusiness();
    const businessType = defaultTo(
        sessionBusiness?.Type,
        BusinessType.KINDERGARTEN
    );

    const onBack = () => {
        refetchMessageCount();
        navigate(linkProvider.business.screens.mailbox.list());
    };

    const [hasError, setHasError] = useState(false);
    const [state, setState] = useState<CreateMessageState>({
        message: getDefaultMessageTemplate({
            Name: user?.Name,
            Email: user?.Email,
            Avatar: user?.Avatar,
        } as AvatarInfo),
        loading: false,
    });

    const {
        isLoading: sendLoading,
        data: response,
        mutate: sendMessage,
    } = useMutation(
        async (template: MessageTemplate) =>
            await messageService.create(template) // automatically refetch based on key change
    );

    const fileRef: any = useRef(null);

    const onFileClickHandler = () => {
        if (fileRef !== null && fileRef.current !== null) {
            fileRef.current.click();
        }
    };

    const onChangeHandler = (e: any) => {
        if (hasError) {
            // remove files error on change
            setTimeout(() => setHasError(false), 500);
        }
        setState({
            ...state,
            message: { ...state.message, files: e.target.files },
        });
    };
    const validityStateManager = useMemo(() => {
        let validityState = validateMessageTemplate(state.message);
        if (hasError && response) {
            validityState = getValidityStateFromApiResponse(response);
        }
        return new ValidityStateManager(validityState);
    }, [response, state, hasError, t]);

    const onLoading = (val: boolean) => {
        setState({
            ...state,
            loading: val,
        });
    };

    useEffect(() => {
        onLoading(sendLoading);
        if (response && !sendLoading) {
            if (!response.Errors && response.Data) {
                onBack();
            } else {
                setHasError(true);
            }
        }
    }, [response, sendLoading]);

    const onBackWithValidation = useCallback(() => {
        state.message.Title ||
        state.message.Body.Template ||
        (state.message.files?.length && state.message.files?.length > 0)
            ? showSweetAlertConfirmation(
                  t("common.warning"),
                  t("common.dataLost"),
                  () => onBack(),
                  t("common.ok")
              )
            : onBack();
    }, [onBack, state]);
    return (
        <div className={layoutStyles.cardStyling}>
            {state.loading && <AppOverlayLoader fullHeight={false} />}
            <AppContainer
                showHeader={true}
                heightToAdjust={185}
                mediumViewAdjustment={45}
                onBack={() => onBackWithValidation()}
                title={
                    <span className={styles.headerText}>
                        {t("message.newMessage")}
                    </span>
                }
            >
                <ErrorMessage
                    errorInfo={validityStateManager.getFirstErrorInfo(
                        "GeneralError"
                    )}
                />
                <div className={styles.createBody}>
                    <div
                        className={styles.marginBottom}
                        style={{ flexWrap: "wrap" }}
                    >
                        <span className={styles.columnLabel}>
                            {t("message.from")}:
                        </span>
                        <span className={styles.fromName}>{user?.Name}</span>
                        {false && (
                            <AppSwitch
                                className={styles.allowAnswerSwitch}
                                label={t("message.allowAnswer")}
                                value={true}
                                onChange={() => {}}
                            />
                        )}
                    </div>
                    <div className={styles.marginBottom}>
                        <div className={styles.columnLabel}>
                            {t("message.for")}:
                        </div>
                        <EmployeeClientFiltersComponent
                            useOldSelect={true}
                            className={styles.receiptsSelectors}
                            fetchOnlyActiveResources={true}
                            allowedUserTypes={
                                businessType != BusinessType.KINDERGARTEN
                                    ? [
                                          UserFilterType.Employee,
                                          UserFilterType.Supplier,
                                      ]
                                    : undefined
                            }
                            value={{
                                clientIds: state.message.ClientIds,
                                employeeIds: state.message.EmployeeIds,
                                groupIds: state.message.GroupIds,
                                userType: state.message.ReceiptTypes,
                            }}
                            readonly={false}
                            onChange={(newFilters) => {
                                setState({
                                    ...state,
                                    message: {
                                        ...state.message,
                                        ClientIds: newFilters.clientIds,
                                        EmployeeIds: newFilters.employeeIds,
                                        GroupIds: newFilters.groupIds,
                                        ReceiptTypes: newFilters.userType,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <div
                            className={styles.columnLabel}
                            style={{ marginTop: "12px" }}
                        >
                            {`${t("message.title.name")}:`}
                        </div>
                        <div className={styles.titleField}>
                            <AppInputField
                                classes={{ field: styles.titleInput }}
                                value={defaultTo(state.message.Title, "")}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        message: {
                                            ...state.message,
                                            Title: defaultTo(val, ""),
                                        },
                                    })
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "title"
                                )}
                                showEmptyError={true}
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            className={styles.columnLabel}
                            style={{ marginTop: "12px" }}
                        >
                            {`${t("message.body.name")}:`}
                        </div>
                        <div className={styles.textArea}>
                            <TinymceEditor
                                value={{
                                    placeholders:
                                        state.message.Body.Placeholders,
                                    html: state.message.Body.Template,
                                }}
                                allowedPlaceholderTypes={[]}
                                fontColor={INPUT_COLOR}
                                tinymceProps={{
                                    menubar: false,
                                    contextmenu: false,
                                    max_height: 200,
                                    min_height: 200,
                                    toolbar: false,
                                }}
                                onChange={(newState) => {
                                    setState({
                                        ...state,
                                        message: {
                                            ...state.message,
                                            Body: {
                                                ...state.message.Body,
                                                Placeholders:
                                                    newState.placeholders,
                                                Template: newState.html,
                                            },
                                        },
                                    });
                                }}
                            />
                            <ErrorMessage
                                errorInfo={validityStateManager.getFirstErrorInfo(
                                    "body"
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.attachmentsLabel}>
                        {t(
                            `common.fileSelected.${
                                defaultTo(state.message.files?.length, 0) == 1
                                    ? "single"
                                    : "multiple"
                            }`,
                            {
                                count: defaultTo(
                                    state.message.files?.length,
                                    0
                                ),
                            }
                        )}
                    </div>
                    <div className={styles.attachmentsLabel}>
                        <ErrorMessage
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "files"
                            )}
                        />
                    </div>
                    <div className={styles.attachmentSave}>
                        <div>
                            <Form.File
                                ref={fileRef}
                                id="paperclip"
                                style={{ display: "none" }}
                                name="file"
                                accept={
                                    ".jpeg, .gif, .jpg, .png, .pdf, .docs, .doc, .docx, .xls, .xlsx"
                                }
                                multiple={true}
                                onChange={onChangeHandler}
                            />
                            <Image
                                onClick={() => onFileClickHandler()}
                                src={ImageAssets.common.paperClip}
                            />
                        </div>
                        <div>
                            <EmojiPicker
                                trigger={
                                    <Image src={ImageAssets.common.smiley} />
                                }
                                placement={"right"}
                                onSelection={(emoji: BaseEmoji) => {
                                    appendContentInActiveEditor(emoji.native);
                                }}
                            />
                        </div>
                        <div className={styles.sendButton}>
                            <AppButton
                                disabled={!validityStateManager.isStateValid()}
                                onClick={() => {
                                    onLoading(true);
                                    sendMessage(state.message);
                                }}
                            >
                                <>
                                    <span>{t("chat.send")}</span>
                                    <span className={styles.sendIcon}>
                                        <Image src={ImageAssets.common.send} />
                                    </span>
                                </>
                            </AppButton>
                        </div>
                    </div>
                </div>
            </AppContainer>
        </div>
    );
};

export default MessagesCreate;
