import { ErrorMessage } from "components";
import AppContentHeader from "components/AppContentHeader";
import { AppSelectOld } from "components/AppSelect";
import { AppButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useLwcBusinessesSelectList } from "hooks/generalApiCalls/useLwcBusinessesList";
import { isNil } from "lodash-es";
import {
    BusinessSelectItem,
    MergeCompanySelection,
    validateMergeCompany,
} from "models";
import { ValidityStateManager } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    getLillywaitSupportServiceKey,
    LillywaitSupportService,
} from "services/lillywait";
import styles from "./MergeCompanies.module.scss";

export const MergeCompanies: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();

    const { loading, refetch, data: businesses } = useLwcBusinessesSelectList();
    const supportService = new LillywaitSupportService(
        linkProvider.lillywait.support().api
    );

    const {
        isLoading: mergeCompanyLoading,
        data: mergeCompanyResponse,
        isError: mergeCompanyError,
        mutate: mergeCompany,
    } = useMutation(
        getLillywaitSupportServiceKey("mergeCompanies"),
        async (data: { sourceId: number; targetId: number }) =>
            await supportService.mergeCompanies(data.sourceId, data.targetId)
    );
    const pleaseSelectOption: BusinessSelectItem = useMemo(
        () => ({
            Text: t("common.pleaseSelect"),
            Value: null,
            IsHq: false,
        }),
        [t]
    );

    const [state, setState] = useState<{
        businessList: BusinessSelectItem[];
        selectedItem: MergeCompanySelection;
    }>({
        businessList: [],
        selectedItem: {
            source: pleaseSelectOption,
            target: pleaseSelectOption,
        },
    });

    const validityStateManager = useMemo(() => {
        const validationState = validateMergeCompany(state.selectedItem);
        return new ValidityStateManager(validationState);
    }, [state.selectedItem]);

    const targetHqList = useMemo(() => {
        const temp = state.businessList.filter((x) => x.IsHq);
        const items =
            state.selectedItem && state.selectedItem.source
                ? temp.filter((x) => x.HqId !== state.selectedItem.source?.HqId)
                : temp;
        return [pleaseSelectOption, ...items];
    }, [state.businessList, state.selectedItem, pleaseSelectOption, t]);
    const sourceList = useMemo(() => {
        const temp = state.businessList;
        const items =
            state.selectedItem && state.selectedItem.target
                ? temp.filter(
                      (x) => x.Value !== state.selectedItem.target?.Value
                  )
                : temp;

        return [pleaseSelectOption, ...items];
    }, [state.businessList, state.selectedItem, pleaseSelectOption, t]);

    useEffect(() => {
        if (!loading) {
            if (businesses) {
                setState({
                    ...state,
                    businessList: [...businesses.Data],
                });
            }
        }
    }, [loading]);

    useEffect(() => {
        if (!mergeCompanyLoading) {
            if (mergeCompanyResponse && mergeCompanyResponse.Data) {
                const source = state.businessList.find(
                    (x) => x.Value === state.selectedItem.source?.Value
                );
                const sourceName = source
                    ? source.Text
                    : t("mergeCompanies.sourceCompany.name");
                const target = state.businessList.find(
                    (x) => x.Value === state.selectedItem.target?.Value
                );
                const targetName = target
                    ? target.Text
                    : t("mergeCompanies.targetCompany.name");

                showSweetAlertToast(
                    t("common.success"),
                    `"${sourceName}" ${t(
                        "mergeCompanies.successfullyMovedToHQ"
                    )} "${targetName}"`,
                    "success"
                );
                setState({
                    ...state,
                    selectedItem: {
                        source: pleaseSelectOption,
                        target: pleaseSelectOption,
                    },
                });
                refetch();
            } else if (mergeCompanyResponse?.Code || mergeCompanyError) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("mergeCompanies.unableToMove")}`,
                    "error"
                );
            }
        }
    }, [
        mergeCompanyResponse,
        mergeCompanyLoading,
        mergeCompanyError,
        pleaseSelectOption,
    ]);

    useEffect(() => {
        // to update pleaseSelect translations
        if (state.selectedItem) {
            const newItem = { ...state.selectedItem };
            if (newItem.source && isNil(newItem.source.Value)) {
                newItem.source = pleaseSelectOption;
            }
            if (newItem.target && isNil(newItem.target.Value)) {
                newItem.target = pleaseSelectOption;
            }
            setState({ ...state, selectedItem: newItem });
        }
    }, [pleaseSelectOption]);
    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("mergeCompanies.title")}
                classNameForIcon="fas fa-users-cog"
            />
            <AppContainer>
                <>
                    <Row style={{ minHeight: "110px" }}>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t("mergeCompanies.sourceCompany.name")}
                                </Form.Label>
                                <AppSelectOld
                                    options={sourceList}
                                    menuPortalTarget={document.body}
                                    value={state.selectedItem.source}
                                    isLoading={loading}
                                    getOptionLabel={(opt: BusinessSelectItem) =>
                                        opt.IsHq ? `${opt.Text} (HQ)` : opt.Text
                                    }
                                    getOptionValue={(opt: BusinessSelectItem) =>
                                        opt.Value
                                    }
                                    onChange={(val: BusinessSelectItem) => {
                                        setState({
                                            ...state,
                                            selectedItem: {
                                                source: val,
                                                target:
                                                    state.selectedItem.target &&
                                                    val.HqId != null &&
                                                    val.HqId ==
                                                        state.selectedItem
                                                            .target.HqId
                                                        ? null
                                                        : state.selectedItem
                                                              .target,
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "source"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>
                                    {t("mergeCompanies.targetHeadquarter.name")}
                                </Form.Label>
                                <AppSelectOld
                                    options={targetHqList}
                                    menuPortalTarget={document.body}
                                    value={state.selectedItem.target}
                                    isLoading={loading}
                                    getOptionLabel={(opt: BusinessSelectItem) =>
                                        opt.IsHq ? `${opt.Text} (HQ)` : opt.Text
                                    }
                                    getOptionValue={(opt: BusinessSelectItem) =>
                                        opt.Value
                                    }
                                    onChange={(val: BusinessSelectItem) => {
                                        setState({
                                            ...state,
                                            selectedItem: {
                                                target: val,
                                                source:
                                                    state.selectedItem.source &&
                                                    val.Value ==
                                                        state.selectedItem
                                                            .source.Value
                                                        ? null
                                                        : state.selectedItem
                                                              .source,
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "target"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {t("common.note")}:
                                <br />
                                {t("mergeCompanies.note.paragraph1")}
                                <br />
                                {t("mergeCompanies.note.paragraph2")}
                            </p>
                            <p className={"text-danger"}>
                                {`${t("common.warning")}: ${t(
                                    "mergeCompanies.warningDesc"
                                )}`}
                            </p>
                        </Col>
                        <Col
                            xs={12}
                            className="d-flex justify-content-end mt-1"
                        >
                            <AppButton
                                disabled={
                                    !validityStateManager.isStateValid() ||
                                    mergeCompanyLoading ||
                                    loading
                                }
                                onClick={() => {
                                    if (
                                        state.selectedItem.source &&
                                        !isNil(
                                            state.selectedItem.source.Value
                                        ) &&
                                        state.selectedItem.target &&
                                        !isNil(state.selectedItem.target.Value)
                                    ) {
                                        mergeCompany({
                                            sourceId:
                                                state.selectedItem.source.Value,
                                            targetId:
                                                state.selectedItem.target.Value,
                                        });
                                    }
                                }}
                            >
                                {t("common.saveChanges")}
                            </AppButton>
                        </Col>
                    </Row>
                </>
            </AppContainer>
        </div>
    );
};

export default MergeCompanies;
