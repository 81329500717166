import classNames from "classnames";
import AppContentHeader from "components/AppContentHeader";
import { AppDeleteButton, AppIconButton } from "components/Buttons";
import IFrameContent from "components/IFrameContent";
import { showSweetAlertInfo } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import {
    Business_DocumentManager_Template,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import {
    DocumentTemplateService,
    getDocumentTemplateServiceKey,
} from "services/business";
import styles from "./DocumentTemplateCreateAndEditIframe.module.scss";

export const DocumentTemplateCreateAndEditIframe: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();
    const { urlForSessionBusinessAdmin } = useIFrameRouting();
    const showErrorPage = useShowErrorPage();

    const { id: templateId } = useParams();

    const permMap = useMemo(() => {
        return getPermissionMap(Business_DocumentManager_Template);
    }, [getPermissionMap]);

    const templateCreatePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const templateDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const documentTemplateService = new DocumentTemplateService(
        linkProvider.business.api.currentBusiness().documentTemplate
    );

    const redirect = () => {
        navigate(linkProvider.business.screens.admin.documentManager.list());
    };

    const {
        isLoading: getTemplateLoading,
        refetch: refetchTemplate,
        data: getTemplateRes,
    } = useQuery(
        getDocumentTemplateServiceKey("getTemplateById", {
            templateId: templateId,
        }),
        async () => {
            if (templateId) {
                return await documentTemplateService.getTemplateById(
                    templateId
                );
            }
        }
    );

    useEffect(() => {
        if (!getTemplateLoading && getTemplateRes?.Errors) {
            showErrorPage(404);
        }
    }, [getTemplateRes, getTemplateLoading]);

    const { isLoading: cloneTemplateLoading, mutate: cloneTemplate } =
        useMutation(
            async (templateId: string) =>
                await documentTemplateService.cloneBusinessTemplate(templateId),
            {
                onSuccess: () => {
                    showSweetAlertInfo(
                        t("common.success"),
                        t(`documentTemplate.cloneSuccess`),
                        "success"
                    );
                },
            }
        );

    const { isLoading: deleteTemplateLoading, mutate: deleteTemplate } =
        useMutation(
            async (templateId: string) =>
                await documentTemplateService.delete(templateId),
            {
                onSuccess: () => {
                    showSweetAlertInfo(
                        t("common.success"),
                        t(`documentTemplate.deletedSuccess`),
                        "success"
                    );
                    redirect();
                },
            }
        );

    const iFrame = useMemo(() => {
        const pattern = templateId
            ? `Documents/Edit/${templateId}`
            : "Documents/Create";

        return (
            <IFrameContent
                contentUrl={urlForSessionBusinessAdmin(pattern)}
                onUpdate={() => refetchTemplate()}
                className={styles.iframeRoot}
                onRedirect={(encodedId) => {
                    if (encodedId) {
                        navigate(
                            linkProvider.business.screens.admin.documentManager.edit(
                                encodedId
                            )
                        );
                    } else {
                        redirect();
                    }
                }}
            />
        );
    }, [templateId]);
    return (
        <div className={styles.root}>
            <AppContentHeader
                showTitleSkeleton={true}
                titleLoading={getTemplateLoading}
                title={
                    templateId
                        ? defaultTo(getTemplateRes?.Data?.Name, "")
                        : t("templateDocumentManager.documents")
                }
                icon={ImageAssets.common.fileBlue}
                hasGoBack={true}
                onBack={() => redirect()}
            >
                {templateId && !getTemplateLoading && (
                    <div style={{ display: "flex" }}>
                        <AppIconButton
                            className={classNames(styles.documentBtn, "mr-4")}
                            buttonType="Clone"
                            showToolTip={true}
                            readonly={
                                !templateCreatePermission ||
                                cloneTemplateLoading ||
                                deleteTemplateLoading
                            }
                            tooltipText={t(
                                "templateDocumentManager.cloneTemplate"
                            )}
                            onClick={() => cloneTemplate(templateId)}
                        />
                        <AppDeleteButton
                            readonly={
                                deleteTemplateLoading ||
                                !templateDeletePermission
                            }
                            getConfirmation={true}
                            className={classNames(styles.documentBtn, "mr-3")}
                            onClick={() => deleteTemplate(templateId)}
                        />
                    </div>
                )}
            </AppContentHeader>
            {iFrame}
        </div>
    );
};

export default DocumentTemplateCreateAndEditIframe;
