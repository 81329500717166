import { AppInputField, AppSwitch } from "components/FormFields";
import { DragHandle } from "components/Sortable";
import { defaultTo, isNil } from "lodash-es";
import React, { useEffect, useRef, useState } from "react";
import styles from "./LocationSettingModuleField.module.scss";
import { useTranslation } from "react-i18next";
import { ModuleFeatureInfo } from "models/modulePlans";
import { AppDeleteButton } from "components/Buttons";

export interface LocationSettingModuleFieldProps {
    value: ModuleFeatureInfo;
    readonly: boolean;
    contentFeatures: ModuleFeatureInfo[];
    onChange: (feature: ModuleFeatureInfo) => void;
    onDelete: (uuid: string) => void;
}

interface LocationSettingModuleFieldState {
    featureText: string;
    toggleInputField: boolean;
}

export const LocationSettingModuleField: React.FC<
    LocationSettingModuleFieldProps
> = ({ value, readonly, contentFeatures, onChange, onDelete }) => {
    const { t } = useTranslation();
    const inputFieldRef = useRef<any>(undefined);
    const [state, setState] = useState<LocationSettingModuleFieldState>({
        featureText: value.Text,
        toggleInputField: false,
    });

    useEffect(() => {
        if (state.toggleInputField && inputFieldRef.current && !readonly) {
            inputFieldRef.current.focus();
        }
    }, [state.toggleInputField]);

    const changeFeatureText = () => {
        let newState: LocationSettingModuleFieldState = {
            ...state,
            toggleInputField: false,
        };
        if (
            !readonly &&
            !isNil(state.featureText) &&
            state.featureText.length > 0 &&
            contentFeatures.filter((x) => x.Text == state.featureText).length ==
                0
        ) {
            onChange({
                ...value,
                Text: state.featureText,
            });
        } else {
            newState = {
                ...newState,
                featureText: value.Text,
            };
        }
        setState(newState);
    };

    return (
        <div className={styles.outer}>
            <div className={styles.row}>
                <div className={styles.field}>
                    {state.toggleInputField && !readonly ? (
                        <AppInputField
                            ref={inputFieldRef}
                            className="mb-0"
                            value={state.featureText}
                            onValueChange={(val) => {
                                setState({
                                    ...state,
                                    featureText: defaultTo(val, ""),
                                });
                            }}
                            onBlur={() => changeFeatureText()}
                        />
                    ) : (
                        <div
                            style={{ marginLeft: "11px" }}
                            onClick={() => {
                                setState({
                                    ...state,
                                    toggleInputField: true,
                                });
                            }}
                        >
                            <span className={styles.featureText}>
                                {value.Text}
                            </span>
                        </div>
                    )}
                </div>
                <AppSwitch
                    id={`IsIncluded.${value.Uuid}`}
                    className={styles.switch}
                    disabled={readonly}
                    label={t("locationSettings.modulePlan.isIncluded")}
                    value={value.IsIncluded}
                    onChange={(checked) =>
                        onChange({
                            ...value,
                            IsIncluded: checked,
                        })
                    }
                />
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.trash}
                    onClick={() => onDelete(value.Uuid)}
                />
            </div>
            <DragHandle className={styles.dragHandler} />
        </div>
    );
};

export default LocationSettingModuleField;
