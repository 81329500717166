import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { BusinessType } from "models";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { BusinessShiftCreateEdit } from "./partials";

export const BusinessShiftCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { lwcId } = useParams();
    const isLwc = !isNil(lwcId);
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = isLwc
        ? (currentBusiness?.Type == BusinessType.KINDERGARTEN
              ? lwcBase.kindergartens
              : lwcBase.industries
          ).withId()
        : linkProvider.business.businessSettings().screens;

    return (
        <BusinessShiftCreateEdit
            onBack={() => navigate(linkProviderBase.shifts.list())}
        />
    );
};

export default BusinessShiftCreate;
