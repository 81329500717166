import { ISO8601_TIME_FORMAT } from "globals/constants";
import {
    WorkingDays,
    WorkingDaysWithTime,
    WorkingDaysWithTimeJson,
} from "models";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService, headerToIgnoreContractExpire } from "./BaseService";

export interface IWorkingDaysService {
    getWorkingDays(): Promise<AppResponse<WorkingDaysWithTimeJson>>;
    updateWorkingDays(
        request: WorkingDaysWithTime
    ): Promise<AppResponse<boolean>>;
}

const apiLinkProvider = routesForContext().api.workingHours(0);
type ApiLinkProviderType = typeof apiLinkProvider;

export function getWorkingDaysServiceKey(
    name: keyof IWorkingDaysService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `WorkingDays_${name}_${JSON.stringify(data)}`;
}
export class WorkingDaysService
    extends BaseService
    implements IWorkingDaysService
{
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    updateWorkingDays(
        request: WorkingDaysWithTime
    ): Promise<AppResponse<boolean>> {
        const temp: WorkingDaysWithTimeJson = {} as WorkingDaysWithTimeJson;
        Object.keys(request).map((dayName: string) => {
            const day = dayName as WorkingDays;
            temp[day] = {
                ...request[day],
                From: request[day].From.format(ISO8601_TIME_FORMAT),
                To: request[day].To.format(ISO8601_TIME_FORMAT),
            };
        });

        return this.doServerXHR<boolean>({
            url: this.routes.updateWorkingDays(),
            method: "post",
            data: {
                WorkingDays: temp,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    getWorkingDays(): Promise<AppResponse<WorkingDaysWithTimeJson>> {
        return this.doServerXHR<WorkingDaysWithTimeJson>({
            url: this.routes.getWorkingDays(),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }
}

export default WorkingDaysService;
