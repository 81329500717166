import classNames from "classnames";
import { AppCollapseToggleButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash-es";
import $ from "jquery";
import {
    EmployeeBenefitStatus,
    EmployeeWithAdditionalBenefits,
} from "models/employeeAdditionalBenefit";
import { Optional } from "models/general";
import React, { useCallback, useRef, useState } from "react";
import EmployeeAdditionalBenefitRowContent from "./EmployeeAdditionalBenefitRowContent";
import styles from "./EmployeeAdditionalBenefitRow.module.scss";
import { COLOR_LIGHT_GREEN } from "theme/themeConstants";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_Payroll_AdditionalBenefits,
    PermissionAccessTypes,
} from "models/permissionManagement";

export interface EmployeeAdditionalBenefitRowProps {
    value: EmployeeWithAdditionalBenefits;
    onDeleteClick: (id: string) => void;
    onClick: (id: string) => void;
}

const COLUMNS_COUNT = 9;
export const EmployeeAdditionalBenefitRow: React.FC<
    EmployeeAdditionalBenefitRowProps
> = ({ value, onDeleteClick, onClick }) => {
    const { checkPermission } = useCheckPermission();
    const hasEditPermission = checkPermission(
        Business_Payroll_AdditionalBenefits,
        [PermissionAccessTypes.EDIT]
    );
    const [collapse, setCollapse] = useState<boolean>(true);
    const collapseRowRef = useRef<Optional<any>>(undefined);
    const initialStyle = useRef<any>(
        !collapse ? { display: "none" } : undefined
    );

    const benefits = defaultTo(value.BusinessEmployeeBenefits, []);
    const toggleRow = useCallback(() => {
        if (collapseRowRef && collapseRowRef.current) {
            if (collapse) {
                setCollapse(false);
                $(collapseRowRef.current).slideUp(200);
            } else {
                setCollapse(true);
                $(collapseRowRef.current).slideDown(300);
            }
        }
    }, [collapseRowRef.current, collapse]);

    const hasBenefits = value.BusinessEmployeeBenefits.length > 0;
    return (
        <>
            <tr onClick={() => (hasBenefits ? toggleRow() : {})}>
                <td style={{ cursor: "pointer !important" }}>
                    <div className={"styles.toggleIcon"}>
                        {hasBenefits && (
                            <AppCollapseToggleButton visible={collapse} />
                        )}
                    </div>
                </td>
                <td>{value.DisplayId}</td>
                <td>
                    <span className={styles.textEllipse}>{value.Name}</span>
                </td>
                <td colSpan={6}></td>
            </tr>
            {hasBenefits && (
                <tr
                    className="row-with-innerTable"
                    style={collapse ? { border: "none !important" } : undefined}
                >
                    <td colSpan={COLUMNS_COUNT}>
                        <div
                            className="child-table-container"
                            style={initialStyle.current}
                            ref={collapseRowRef}
                        >
                            <table
                                className={classNames(
                                    "table-striped",
                                    styles.childTable
                                )}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    {benefits.map((b, idx) => (
                                        <tr
                                            key={b.Id}
                                            onClick={() => {
                                                if (hasEditPermission) {
                                                    onClick(b.EncodedId);
                                                }
                                            }}
                                            style={{
                                                ...(idx ===
                                                    benefits.length - 1 && {
                                                    borderBottom:
                                                        "none !important",
                                                }),
                                                ...(b.Status ==
                                                    EmployeeBenefitStatus.Active && {
                                                    backgroundColor:
                                                        COLOR_LIGHT_GREEN,
                                                }),
                                                ...(hasEditPermission && {
                                                    cursor: "pointer",
                                                }),
                                            }}
                                        >
                                            <td style={getFixedCssWidths(60)} />
                                            <EmployeeAdditionalBenefitRowContent
                                                value={b}
                                                onDeleteClick={onDeleteClick}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default EmployeeAdditionalBenefitRow;
