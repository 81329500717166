import { handleGeneralResponseError } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import isNil from "lodash-es/isNil";
import { AgeGroupTemplate, getAgeGroupTemplateFromResponse } from "models";
import { Business } from "models/business";
import {
    BusinessSettingsListDataWithLocationFlag,
    Optional,
} from "models/general";
import { PermissionAccessMap } from "models/permissionManagement";
import React, { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router";
import { businessSettingRoutes } from "routing/routes/business/settings";
import {
    AgeGroupTemplateService,
    getAgeGroupTemplateServiceKey,
} from "services";
import { AgeGroupTemplateTable } from "./partials";

const basePath = businessSettingRoutes().screens.ageGroups;
type PathBase = typeof basePath;
export interface AgeGroupTemplatesListProps {
    currentBusiness: Optional<Business>; // null for location-setting views
    locationId?: number; // for lwc it will be lwc location-setting id
    basePathProvider: PathBase;
    permissionsMap: PermissionAccessMap;
    useLocationData?: boolean; // if true, in business and LWC new data can't be added/updated
    isLwc?: boolean;
    setCanChangeLocationPreference?: (val: boolean) => void;
}

export const AgeGroupTemplatesList: React.FC<AgeGroupTemplatesListProps> = ({
    locationId,
    currentBusiness,
    basePathProvider,
    setCanChangeLocationPreference,
    ...rest
}) => {
    const { t } = useTranslation();
    const { encodedId } = useSessionBusiness();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const [list, setList] = useState<AgeGroupTemplate[]>([]);

    const templateService = new AgeGroupTemplateService(
        linkProvider.api.ageGroupTemplates(
            locationId ? (locationId as number) : 0,
            currentBusiness?.Id
        )
    );

    const queryKey = getAgeGroupTemplateServiceKey("getTemplates", {
        businessId: currentBusiness?.Id,
        locationId: locationId,
        sessionBusinessId: encodedId,
    });
    const {
        isFetching: isLoading,
        isRefetching,
        data: listRes,
        isStale,
        refetch: refetchTemplates,
    } = useQuery(
        queryKey,
        async () => {
            return await templateService.getTemplates();
        },
        {
            select: (listRes) => {
                if (listRes && listRes.Data) {
                    if (setCanChangeLocationPreference) {
                        setTimeout(() => {
                            setCanChangeLocationPreference(
                                listRes.Data.CanChangeLocationPreference
                            );
                        }, 500);
                    }
                    return listRes.Data.Data.length > 0
                        ? listRes.Data.Data.map((x) => {
                              return getAgeGroupTemplateFromResponse(x);
                          })
                        : [];
                }
                return list; // return old data
            },
        }
    );

    useEffect(() => {
        if (listRes) {
            setList(listRes);
        }
    }, [isLoading, isRefetching]);
    useEffect(() => {
        if (isStale) {
            refetchTemplates();
        }
    }, []);

    const { mutate: deleteTemplate, isLoading: deleting } = useMutation(
        async (id: string) => await templateService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    const toDelete = list.filter(
                        (x) =>
                            x.Id == deleteRes.Data ||
                            x.FollowUpTemplates?.filter(
                                (x) => x.Id == deleteRes?.Data
                            )
                    )[0];

                    showSweetAlertToast(
                        t("common.success"),
                        `"${toDelete.Name}" ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );

                    const filteredList = list
                        .filter((x) => x.Id != deleteRes.Data)
                        .map((x) => {
                            return {
                                ...x,
                                FollowUpTemplates: x.FollowUpTemplates?.filter(
                                    (x) => x.Id != deleteRes.Data
                                ),
                            };
                        });
                    // to retch data after delete
                    // queryClient.invalidateQueries(queryKey);
                    setList(filteredList);
                } else {
                    handleGeneralResponseError(deleteRes);
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    return (
        <AgeGroupTemplateTable
            list={list}
            loading={isLoading}
            deleting={deleting}
            isForLocationSetting={isNil(currentBusiness)}
            createPath={basePathProvider.create()}
            onClick={(id) => {
                navigate(basePathProvider.edit(id));
            }}
            onDeleteClick={(id) => deleteTemplate(id)}
            {...rest}
        />
    );
};

export default AgeGroupTemplatesList;
