import { AppFieldsWrapperContainer } from "components/Containers";
import { isNil } from "lodash-es";
import {
    AgeGroupTemplateField,
    CareScopeTemplateField,
    CareScopeTemplateSurchargeField,
    EmpKeyTemplateField,
} from "models";
import { ValidityState, ValidityStateManager } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import EmpKeyTemplateFieldRow from "./EmpKeyTemplateFieldRow";
export interface EmpKeyTemplateFieldsContainerProps {
    fields: EmpKeyTemplateField[];
    onChange: (newFields: EmpKeyTemplateField[]) => void;
    readonly?: boolean;
    validityState?: ValidityState;
    ageGroups: AgeGroupTemplateField[];
    careScopes: CareScopeTemplateField[];
    subCharges: CareScopeTemplateSurchargeField[];
}

export const EmpKeyTemplateFieldsContainer = ({
    fields,
    onChange,
    readonly = false,
    validityState,
    ageGroups,
    careScopes,
    subCharges,
}: EmpKeyTemplateFieldsContainerProps) => {
    const { t } = useTranslation();

    const renderField = (field: EmpKeyTemplateField, name: string) => {
        const stateManager = new ValidityStateManager(validityState);

        return (
            <EmpKeyTemplateFieldRow
                key={field.Uuid}
                validityState={stateManager.getFieldState(field.Uuid)?.children}
                readonly={readonly}
                value={field}
                displayFieldName={name}
                onChange={(field) => {
                    const oldIndex = fields.findIndex(
                        (f) => f.Uuid == field.Uuid
                    );
                    if (oldIndex >= 0) {
                        onChange(
                            fields.map((nf) => {
                                if (nf.Uuid == field.Uuid) {
                                    return field;
                                } else {
                                    return nf;
                                }
                            })
                        );
                    } else {
                        onChange([...fields, field]);
                    }
                }}
            />
        );
    };

    return (
        <>
            {fields &&
                ageGroups.map((ag) => {
                    const filteredFields = fields.filter(
                        (f) => f.AgeGroupFieldUuid == ag.Uuid
                    );

                    return filteredFields.length > 0 ? (
                        <AppFieldsWrapperContainer
                            key={ag.Uuid}
                            title={t("empKeyTemplate.ageGroup", {
                                name: ag.ShortName,
                            })}
                            isCollapsible={true}
                        >
                            <>
                                {filteredFields.some(
                                    (f) => !isNil(f.CareScopeFieldUuid)
                                ) && (
                                    <AppFieldsWrapperContainer
                                        key={`${ag.Uuid}_CareScopes`}
                                        title={t("careScopeTemplate.title")}
                                        isCollapsible={true}
                                    >
                                        {careScopes.map((cs) => {
                                            const f = filteredFields.find(
                                                (f) =>
                                                    f.CareScopeFieldUuid ==
                                                    cs.Uuid
                                            );
                                            return f ? (
                                                renderField(f, cs.ShortName)
                                            ) : (
                                                <></>
                                            );
                                        })}
                                    </AppFieldsWrapperContainer>
                                )}
                                {filteredFields.some(
                                    (f) => !isNil(f.SurchargeFieldUuid)
                                ) && (
                                    <AppFieldsWrapperContainer
                                        key={`${ag.Uuid}_Surcharges`}
                                        title={t(
                                            "careScopeTemplate.surcharges"
                                        )}
                                        isCollapsible={true}
                                    >
                                        {subCharges.map((s) => {
                                            const f = filteredFields.find(
                                                (f) =>
                                                    f.SurchargeFieldUuid ==
                                                    s.Uuid
                                            );
                                            return f ? (
                                                renderField(f, s.ShortName)
                                            ) : (
                                                <></>
                                            );
                                        })}
                                    </AppFieldsWrapperContainer>
                                )}
                            </>
                        </AppFieldsWrapperContainer>
                    ) : (
                        <React.Fragment key={ag.Uuid}></React.Fragment>
                    );
                })}
        </>
    );
};

export default EmpKeyTemplateFieldsContainer;
