import { defaultTo } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppSurNameDialog } from "../Dialogs/AppSurNameDialog";
import { AppInputField, AppInputFieldProps } from "./AppInputField";
export interface AppLastNameSurnameFieldsProps {
    lastName: string;
    surName: string;
    onLastNameChange: (value: string) => void;
    onSurnameChange: (value: string) => void;
    showAddSurName?: boolean;
    lastNameProps?: AppInputFieldProps;
}
export const AppLastNameSurnameFields: React.FC<
    AppLastNameSurnameFieldsProps
> = ({
    lastName,
    onLastNameChange,
    onSurnameChange,
    surName,
    lastNameProps,
    showAddSurName = true,
}) => {
    const { t } = useTranslation();
    return (
        <AppInputField
            showEmptyError={true}
            {...lastNameProps}
            label={t("kindergartenClient.basicInfo.lastName.name")}
            value={
                // eslint-disable-next-line no-extra-boolean-cast
                Boolean(surName) ? surName : lastName
            }
            onValueChange={(value) => {
                const val = defaultTo(value, "");
                if (surName) {
                    onSurnameChange(val);
                } else {
                    onLastNameChange(val);
                }
            }}
            rightIcon={
                showAddSurName && (
                    <AppSurNameDialog
                        readonly={lastNameProps?.disabled}
                        value={defaultTo(surName, "")}
                        onSave={(e) => onSurnameChange(e)}
                        oldValue={lastName}
                    />
                )
            }
        />
    );
};

export default AppLastNameSurnameFields;
