import { AppContentHeader } from "components";
import { ImageAssets } from "globals/images";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { KindergartenClient } from "models/kindergartenClient";
import { Moment } from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { getKindergartenClientServiceKey } from "services/kindergarten";
import { ClientCreateEditBasicData } from "./createEdit";

export const KindergartenClientCreate = () => {
    const [state, setState] = useState<KindergartenClient>();
    const { sessionBusiness, sessionBusinessLocation } = useSessionBusiness();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    return (
        <>
            <AppContentHeader
                title={t("kindergartenClient.title")}
                icon={ImageAssets.common.clients}
                hasGoBack={true}
                onBack={() =>
                    navigate(
                        linkProvider.kindergarten
                            .clients()
                            .screens.client.list()
                    )
                }
            />
            <ClientCreateEditBasicData
                isEdit={false}
                onSuccess={() => {
                    queryClient.invalidateQueries(
                        getKindergartenClientServiceKey("getClientsList")
                    );
                }}
                schoolStart={sessionBusinessLocation?.SchoolStart}
                deadLine={sessionBusiness?.BusinessSetting.AgeGroupDeadline}
                value={state}
            />
        </>
    );
};

export default KindergartenClientCreate;
