import classNames from "classnames";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { allOrValues } from "globals/helpers/generalHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    AppResponse,
    Optional,
    SelectItem,
    SortOrder,
    UserFilterType,
    UserType,
    sortData,
} from "models/general";
import moment, { Moment } from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business";
import styles from "./EmployeeClientFiltersComponent.module.scss";
import FilterMultiSelectComponent, {
    FilterType,
    MultiSelectItem,
} from "./FilterMultiSelectComponent";
import { UserFilterResponse } from "models/business/response";
import { COLOR_BLUE_LIGHT, COLOR_YELLOW_LIGHT } from "theme/themeConstants";

export interface EmployeeClientFiltersState {
    userType: UserFilterType[] | null;
    groupIds: number[] | null;
    changed?: boolean;
    clientIds: number[] | null;
    employeeIds: number[] | null;
}

const getNewIdsSelection = (
    newClientIds: number[] | null,
    newEmployeeIds: number[] | null,
    totalClients: SelectItem[] | null,
    totalEmployees: SelectItem[] | null
) => {
    const clientIds = allOrValues(newClientIds, totalClients);
    const employeeIds = allOrValues(newEmployeeIds, totalEmployees);

    return {
        clientIds:
            clientIds == null ? (employeeIds == null ? null : []) : clientIds,
        employeeIds:
            employeeIds == null ? (clientIds == null ? null : []) : employeeIds,
    };
};
export interface EmployeeClientFiltersComponentProps {
    onChange: (newState: EmployeeClientFiltersState) => void;
    value: EmployeeClientFiltersState;
    originalValue?: EmployeeClientFiltersState; // fixed will not change (to use all original IDs on filter change if needed until user change it manually)
    allowedUserTypes?: UserFilterType[];
    readonly?: boolean;
    useColoredTypes?: boolean;
    forDate?: Optional<Moment>;
    fetchOnlyActiveResources?: boolean;
    triggerChangeOnUserSelection?: boolean;
    className?: string;
    orientation?: "horizontal" | "vertical";
    useOldSelect?: boolean;
}

export const EmployeeClientFiltersComponent: React.FC<
    EmployeeClientFiltersComponentProps
> = ({
    onChange: propOnChange,
    value,
    originalValue = value,
    useColoredTypes = false,
    orientation = "horizontal",
    className = "",
    fetchOnlyActiveResources = true,
    forDate = fetchOnlyActiveResources ? moment() : undefined,
    triggerChangeOnUserSelection = true,
    allowedUserTypes = [
        UserFilterType.Client,
        UserFilterType.Employee,
        UserFilterType.Supplier,
    ],
    readonly = false,
    useOldSelect = false,
}) => {
    const { t } = useTranslation();
    const userTypeOptions = useMemo(() => {
        return allowedUserTypes.map(
            (k) =>
                ({
                    value: k,
                    label: t(
                        `employeeClientFilter.${(k as string).toLowerCase()}`
                    ),
                    color: useColoredTypes
                        ? k == UserFilterType.Employee
                            ? COLOR_BLUE_LIGHT
                            : k == UserFilterType.Supplier
                            ? COLOR_YELLOW_LIGHT
                            : undefined
                        : undefined,
                } as MultiSelectItem)
        );
    }, [allowedUserTypes]);
    const addSpacing = orientation != "vertical";
    const { linkProvider } = useRouting();
    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    const [state, setState] = useState<EmployeeClientFiltersState>({
        ...value,
        changed: false,
    });
    const [groupsResp, setGroupsResp] = useState<MultiSelectItem[]>([]);

    const {
        isFetching: loading,
        data: response,
        isRefetching,
    } = useQuery(
        getBusinessGeneralServiceKey("getUserFilterOptions", {
            resourceType: allOrValues(state.userType, allowedUserTypes),
            groupIds: state.groupIds,
            forDate: forDate?.format(ISO8601_DATE_FORMAT),
        }),
        async () =>
            await businessGeneralService.getUserFilterOptions({
                resourceType: allOrValues(state.userType, allowedUserTypes),
                groupIds: state.groupIds,
                onlyResourcesWithActiveContracts: fetchOnlyActiveResources,
                forDate: forDate,
            }),
        {
            cacheTime: 0,
            select: (res: AppResponse<UserFilterResponse>) => {
                if (res && res.Data) {
                    return {
                        ...res.Data,
                        Groups: sortData(res.Data.Groups, [
                            {
                                col: "Text",
                                dir: SortOrder.ASC,
                            },
                        ]),
                        Clients: sortData(res.Data.Clients, [
                            {
                                col: "Text",
                                dir: SortOrder.ASC,
                            },
                        ]),
                        Employees: sortData(res.Data.Employees, [
                            {
                                col: "Text",
                                dir: SortOrder.ASC,
                            },
                        ]),
                        Suppliers: sortData(res.Data.Suppliers, [
                            {
                                col: "Text",
                                dir: SortOrder.ASC,
                            },
                        ]),
                    };
                }
                return {
                    Clients: [],
                    Employees: [],
                    Suppliers: [],
                    Groups: [],
                };
            },
        }
    );

    const onChange = (
        newState: EmployeeClientFiltersState,
        changed?: boolean
    ) => {
        const totalUsers = response
            ? [
                  ...response.Clients,
                  ...response.Employees,
                  ...response.Suppliers,
              ]
            : null;
        const isPersonIdsChanged = defaultTo(
            changed,
            defaultTo(newState.changed, false)
        );

        const newStateForUpdate = {
            ...newState,
            ...getNewIdsSelection(
                isPersonIdsChanged
                    ? newState.clientIds
                    : originalValue.clientIds,
                isPersonIdsChanged
                    ? newState.employeeIds
                    : originalValue.employeeIds,
                defaultTo(response?.Clients, null),
                response ? [...response.Employees, ...response.Suppliers] : null
            ),
            changed: isPersonIdsChanged,
            userType: allOrValues(newState.userType, allowedUserTypes),
            groupIds: allOrValues(newState.groupIds, groupsResp),
        };

        setState(newStateForUpdate);
        if (triggerChangeOnUserSelection) {
            // if it is true, then state is changed so we need to refetch data hence trigger propOnChange as well
            propOnChange(newStateForUpdate);
        }
    };

    // to remove selection if selected value is not in new fetched data
    useEffect(() => {
        if (!loading && !isRefetching && response) {
            const newState = { ...state };

            // after response, re-update selected Ids for ALL
            let updateState: boolean = false;
            if (
                state.groupIds != null &&
                state.groupIds?.length == response.Groups.length
            ) {
                updateState = true;
                newState.groupIds = null;
            }
            if (
                state.clientIds != null &&
                state.clientIds?.length == response.Clients.length &&
                (state.userType == null ||
                    state.userType.includes(UserFilterType.Client))
            ) {
                updateState = true;
                newState.clientIds = null;
            }
            if (
                state.employeeIds != null &&
                state.employeeIds?.length ==
                    response.Employees.length + response.Suppliers.length &&
                (state.userType == null ||
                    state.userType.includes(UserFilterType.Employee))
            ) {
                updateState = true;
                newState.employeeIds = null;
            }
            if (
                state.userType != null &&
                state.userType?.length == allowedUserTypes.length
            ) {
                updateState = true;
                newState.userType = null;
            }

            if (updateState) {
                newState.changed = true;
                setState(newState);
                propOnChange(newState);
                return;
            }

            setGroupsResp(
                response.Groups.map((x) => ({
                    value: x.Value as string,
                    label: x.Text as string,
                    type: FilterType.Group,
                }))
            );

            const clientIdsToUse = newState.changed
                ? newState.clientIds
                : originalValue.clientIds;
            const filteredClients = clientIdsToUse
                ? clientIdsToUse.filter((x) =>
                      response.Clients.find((y) => y.Value == x)
                  )
                : clientIdsToUse;

            const employeeIdsToUse = newState.changed
                ? newState.employeeIds
                : originalValue.employeeIds;

            const filteredEmployees = employeeIdsToUse
                ? employeeIdsToUse.filter((x) =>
                      [...response.Employees, ...response.Suppliers].find(
                          (y) => y.Value == x
                      )
                  )
                : employeeIdsToUse;

            const newIds = getNewIdsSelection(
                filteredClients,
                filteredEmployees,
                response.Clients,
                [...response.Employees, ...response.Suppliers]
            );

            if (
                (newState.clientIds != null && newIds.clientIds == null) ||
                (newState.employeeIds != null && newIds.employeeIds == null) ||
                defaultTo(newState.clientIds, []).length !=
                    defaultTo(newIds.clientIds, []).length ||
                defaultTo(newState.employeeIds, []).length !=
                    defaultTo(newIds.employeeIds, []).length ||
                newState.groupIds != value.groupIds
            ) {
                propOnChange({
                    ...state,
                    ...newIds,
                    groupIds:
                        state.groupIds && state.groupIds.length == 0
                            ? null // groupIds should never be of length 0, either null or should have value
                            : state.groupIds,
                });
            }
        }
    }, [loading, isRefetching]);

    return (
        <div className={`${styles.filterRoot} ${className}`}>
            <Form.Group
                controlId={"resourceType"}
                className={classNames(
                    styles.formGroup,
                    "pl-0",
                    orientation == "horizontal" ? "col-md-4" : "col-md-12",
                    {
                        [styles.paddingRight]: addSpacing,
                    }
                )}
            >
                <Form.Label>{t("employeeClientFilter.resource")}</Form.Label>
                <FilterMultiSelectComponent
                    data={userTypeOptions}
                    value={
                        state.userType
                            ? userTypeOptions.filter((x) =>
                                  state.userType?.includes(
                                      x.value as UserFilterType
                                  )
                              )
                            : []
                    }
                    isReadonly={readonly}
                    useOldSelect={useOldSelect}
                    onChange={(v: MultiSelectItem[]) =>
                        onChange({
                            ...value,
                            changed: state.changed,
                            userType:
                                v && v.length > 0
                                    ? (v.map(
                                          (x) => x.value
                                      ) as UserFilterType[])
                                    : null,
                        })
                    }
                />
            </Form.Group>

            <Form.Group
                className={classNames(
                    styles.formGroup,
                    "pl-0",
                    orientation == "horizontal" ? "col-md-4" : "col-md-12",
                    {
                        [styles.paddingRight]: addSpacing,
                    }
                )}
                controlId={"resourceTypeGroup"}
            >
                <Form.Label>{t("employeeClientFilter.group")}</Form.Label>
                <FilterMultiSelectComponent
                    data={groupsResp}
                    loading={loading}
                    value={
                        state.groupIds
                            ? state.groupIds.map((x) => ({
                                  value: x,
                                  type: FilterType.Group,
                                  label: "",
                              }))
                            : []
                    }
                    isReadonly={readonly}
                    useOldSelect={useOldSelect}
                    onChange={(v: MultiSelectItem[]) => {
                        onChange({
                            ...value,
                            changed: state.changed,
                            groupIds: v.map((x) => x.value as number),
                        });
                    }}
                />
            </Form.Group>

            <Form.Group
                className={classNames(
                    styles.formGroup,
                    "pl-0",
                    orientation == "horizontal" ? "col-md-4" : "col-md-12"
                )}
                controlId={"resourceTypeUser"}
            >
                <Form.Label>{t("employeeClientFilter.users")}</Form.Label>
                <FilterMultiSelectComponent
                    data={
                        response
                            ? [
                                  ...response.Clients.map((x) => ({
                                      value: x.Value as string,
                                      label: `${x.Text as string} (${t(
                                          "common.userType.Client"
                                      )})`,
                                      type: FilterType.Client,
                                  })),
                                  ...response.Employees.map((x) => ({
                                      value: x.Value as string,
                                      label: `${x.Text as string} (${t(
                                          "common.userType.Employee"
                                      )})`,
                                      type: FilterType.Employee,
                                  })),
                                  ...response.Suppliers.map((x) => ({
                                      value: x.Value as string,
                                      label: `${x.Text as string} (${t(
                                          "common.userType.Supplier"
                                      )})`,
                                      type: FilterType.Employee,
                                  })),
                              ]
                            : []
                    }
                    sort={false}
                    loading={loading || isRefetching}
                    useOldSelect={useOldSelect}
                    value={[
                        ...(state.clientIds
                            ? state.clientIds.map((x) => ({
                                  value: x,
                                  type: FilterType.Client,
                                  label: "",
                              }))
                            : []),
                        ...(state.employeeIds
                            ? state.employeeIds.map((x) => ({
                                  value: x,
                                  type: FilterType.Employee,
                                  label: "",
                              }))
                            : []),
                    ]}
                    isReadonly={readonly}
                    onChange={(v: MultiSelectItem[]) => {
                        onChange(
                            {
                                ...value,
                                changed: true,
                                clientIds: v
                                    .filter((x) => x.type == FilterType.Client)
                                    .map((x) => x.value as number),
                                employeeIds: v
                                    .filter(
                                        (x) => x.type == FilterType.Employee
                                    )
                                    .map((x) => x.value as number),
                            },
                            true
                        );
                    }}
                />
            </Form.Group>
        </div>
    );
};

export default EmployeeClientFiltersComponent;
