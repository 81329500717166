import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import React from "react";
import {
    CountdownCircleTimer,
    Props as CountdownCircleTimerProps,
} from "react-countdown-circle-timer";
import { COLOR_PRIMARY, COLOR_RED } from "theme/themeConstants";
import styles from "./AppTimer.module.scss";

export interface AppTimerProps
    extends Omit<
        CountdownCircleTimerProps,
        "duration" | "initialRemainingTime"
    > {
    maxDuration: number; //In Minutes
    remainingTime: number; //Value in Minutes
    isRecurring?: boolean;
    recursionDelayInSeconds?: number;
    onComplete: () => void;
}

export const AppTimer: React.FC<AppTimerProps> = ({
    maxDuration,
    remainingTime,
    isRecurring = true,
    recursionDelayInSeconds = 0,
    onComplete,
    ...rest
}) => {
    const timeInSeconds = remainingTime * 60;
    const maxDurationInSeconds = maxDuration * 60;
    if (!rest.colors) {
        rest.colors = [COLOR_PRIMARY, COLOR_RED] as any;
    }

    return (
        <CountdownCircleTimer
            {...(rest as any)}
            duration={Math.max(timeInSeconds, maxDurationInSeconds)}
            initialRemainingTime={timeInSeconds}
            onComplete={() => {
                onComplete();
                return {
                    shouldRepeat: isRecurring,
                    delay: recursionDelayInSeconds * 1000,
                };
            }}
            strokeWidth={3}
        >
            {({ remainingTime: remainingTimeInSeconds }) => {
                return (
                    <div className={styles.progressChild}>
                        {remainingTimeInSeconds &&
                            getFormattedTimeFromNumber(
                                remainingTimeInSeconds,
                                "seconds",
                                "mm:ss"
                            )}
                    </div>
                );
            }}
        </CountdownCircleTimer>
    );
};

export default AppTimer;
