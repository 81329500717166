import { AppContainer, AppContainerFooter } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { ValidityStateManager } from "models/general";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { BankDataService, getBankDataServiceKey } from "services/business";
import { BankData, BankDataRequest, validateBankData } from "models/bankData";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import AppInputField from "components/FormFields/AppInputField";

export interface BankDataCreateEditProps {
    value?: BankData;
    request: BankDataRequest;
    readonly?: boolean;
    onBack: () => void;
}

export const BankDataCreateEdit: React.FC<BankDataCreateEditProps> = ({
    value,
    readonly = false,
    onBack,
    request,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { encodedId } = useSessionBusiness();

    const [state, setState] = useState<BankData>(
        defaultTo(value, {
            ...request,
        } as BankData)
    );

    const service = new BankDataService(linkProvider.business.api.bankDatas);

    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(async () => await service.createUpdate(state), {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("business.bankData.title")} ${t(
                            `common.actions.${
                                state.Id && state.Id > 0
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getBankDataServiceKey("list", {
                            ...request,
                            businessId: encodedId,
                        })
                    );
                    onBack();
                }
            },
        });

    const validityStateManager = useMemo(() => {
        const validationState = validateBankData(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    return (
        <AppContainer
            showHeader={true}
            onBack={onBack}
            title={
                !isNil(state.Id)
                    ? t("business.bankData.editTitle")
                    : t("business.bankData.createTitle")
            }
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            readonly ||
                            createUpdateLoading ||
                            !validityStateManager.isStateValid(),
                        onClick: () => {
                            createUpdate();
                        },
                    }}
                />
            }
        >
            <Row>
                <Col md={6}>
                    <AppInputField
                        value={state.AccountOwner}
                        label={t("business.bankData.accountOwner")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                AccountOwner: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AccountOwner"
                        )}
                    />
                </Col>
                <Col md={6}>
                    <AppInputField
                        value={state.IBAN}
                        label={t("business.bankData.IBAN")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                IBAN: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("IBAN")}
                    />
                </Col>
                <Col md={6}>
                    <AppInputField
                        value={state.AccountNumber}
                        label={t("business.bankData.accountNumber")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                AccountNumber: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AccountNumber"
                        )}
                    />
                </Col>
                <Col md={6}>
                    <AppInputField
                        value={state.BIC}
                        label={t("business.bankData.BIC")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                BIC: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("BIC")}
                    />
                </Col>
                <Col md={6}>
                    <AppInputField
                        value={state.BankName}
                        label={t("business.bankData.bankName")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                BankName: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "BankName"
                        )}
                    />
                </Col>
                <Col md={6}>
                    <AppInputField
                        value={state.BankCode}
                        label={t("business.bankData.bankCode")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                BankCode: val,
                            })
                        }
                        readOnly={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "BankCode"
                        )}
                    />
                </Col>
            </Row>
        </AppContainer>
    );
};

export default BankDataCreateEdit;
