export enum ChatMessageType {
    TEXT = "text",
    MEDIA = "media",
}
export enum ChannelType {
    ONE_TO_ONE = 0, // simple chat
    BUSINESS_USERS_GROUP = 1, // channel created for a business-group
    CUSTOM_GROUP = 2, // channel created by LWC user
    BUSINESS_DEFAULT_GROUP = 3, //a channel for all business clients and all business employees
}
export enum TwilioChannelUserType {
    // for default and employee group
    Parent = 0,
    Employee = 1,
    ParentAndEmployee = 2,
}
export enum ChatBusinessUserType {
    USER_CLIENT_CONTACT = "Client",
    USER_EMPLOYEE = "Employee",
    USER_EMPLOYEE_AND_CONTACT = "ContactAndEmployee",
}
export enum ChatTabsType {
    GROUPS = "Groups",
    CHATS = "Chats",
    ADD_MEMBERS = "ADD_MEMBERS",
    REMOVE_MEMBERS = "REMOVE_MEMBERS",
}
