import { AppLoader, AppOverlayLoader } from "components/Loaders";
import {
    DATE_TIME,
    MAX_CHAT_MESSAGE_LENGTH,
    TWILIO_MESSAGE_TOO_LONG,
    TWILIO_USER_NO_LONGER_EXIST_ERROR_CODE,
    TWILIO_USER_UNAUTHORIZED_ERROR_CODE,
} from "globals/constants";
import { getUniqueList, parseObject } from "globals/helpers/generalHelper";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useChatHelper } from "hooks/business/chatHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import $ from "jquery";
import { debounce, defaultTo } from "lodash-es";
import {
    ChannelResponseModel,
    ChannelType,
    ChatTabsType,
    getChannelName,
    MemberAttribute,
    MessageAttribute,
    TwilioPaginator,
} from "models/chat";
import { Optional } from "models/general";
import moment, { Moment } from "moment-timezone";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import StickyBox from "react-sticky-box";
import { ChatService, getChatServiceKey } from "services/business/ChatService";
import { Channel, Client, Member, Message } from "twilio-chat";
import styles from "./ChatBox.module.scss";
import { ChatBoxHeader, ChatBubbleItem } from "./partials";
import { ChatMessageField } from "./partials/ChatMessageField";
interface ChatBoxProps {
    selectedChannel: ChannelResponseModel;
    updateSelectedChannel: (c: ChannelResponseModel) => void;
    client: Optional<Client>;
    userId: string;
    removeChannelFromLocal: (channelSid: string) => void;
    setLoading: (val: boolean) => void;
    onModelOpenHandler: (tab: ChatTabsType, channelSid: string) => void;
    readAllMessagesHandler: (channelSid: string) => void;
    updateClearChatAt: (channelSid: string, chatClearAt: Moment) => void;
    onChannelUpdatedHandler: (channel: any) => void;
    blockMembershipRoleSid: string;
    twilioChannelLoading: boolean;
}
interface MessageState {
    loading: boolean;
    text: string;
    file: Optional<File>;
}
export interface MessageEditState {
    message: Optional<Message>;
    isEdit: boolean;
}
export interface ChannelMessageState {
    date: string;
    messages: Message[];
}
interface TypingIndicatorState {
    member: Optional<Member>;
    typing: boolean;
}
export const ChatBox: React.FC<ChatBoxProps> = ({
    selectedChannel,
    client,
    userId,
    removeChannelFromLocal,
    onModelOpenHandler,
    readAllMessagesHandler,
    setLoading,
    updateClearChatAt,
    onChannelUpdatedHandler,
    updateSelectedChannel: setSelectedChannel,
    blockMembershipRoleSid,
    twilioChannelLoading,
}) => {
    const [messageEditState, setMessageEditState] = useState<MessageEditState>({
        message: null,
        isEdit: false,
    });

    const [scrollDone, setScrollDone] = useState(false);
    const [isBlock, setIsBlock] = useState(false);
    const [channel, setChannel] = useState<Optional<Channel>>(null);
    const [channelMessages, setChannelMessages] = useState<Message[]>([]);
    const [channelMessagesPaginator, setChannelMessagesPaginator] =
        useState<TwilioPaginator<Message> | null>(null);
    const [channelMembers, setChannelMembers] = useState<Member[]>([]);
    const [messageState, setMessageState] = useState<MessageState>({
        loading: false,
        text: "",
        file: null,
    });

    const handleMessageAdded = useRef<any>(null);
    const handleMessageUpdate = useRef<any>(null);
    const [typingIndicator, setTypingIndicator] =
        useState<TypingIndicatorState>({
            member: null,
            typing: false,
        });
    const scrollDiv = useRef<any>(null);
    const { todayYesterdayOtherDateHandler } = useChatHelper();
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const handlerMemberLastConsumeIndxUpdate = useRef<any>(null);
    const { t } = useTranslation();
    const { getDateFormatForLocale, appLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const chatService = new ChatService(linkProvider.business.api.chat);
    const {
        isLoading: deleteChatLoading,
        data: deleteChatResponse,
        mutate: deleteChat,
    } = useMutation(
        getChatServiceKey("deleteChat"),
        async (value: { id: string; forceDelete?: boolean }) =>
            await chatService.deleteChat(value.id, value.forceDelete)
    );
    const { mutate: updateDeleteFlag } = useMutation(
        getChatServiceKey("updateDeleteFlag"),
        async (id: string) => await chatService.updateDeleteFlag(id)
    );
    //update message in local list after edit

    //When select channel change get all the message
    //and apply event to the new selected channel
    const removeListeners = useCallback(() => {
        if (channel != null) {
            channel.removeAllListeners();
            if (channelMembers.length > 0) {
                for (const member of channelMembers) {
                    member.removeAllListeners();
                }
            }
            channel.on("updated", onChannelUpdatedHandler);
        }
    }, [channel]);

    const handleTypingStarted = (member: Member) => {
        //process the member to show typing
        if (
            member.identity != userId &&
            !selectedChannel.IsOnline &&
            selectedChannel.ChannelType == ChannelType.ONE_TO_ONE
        ) {
            // if status is previously offline make it online
            setSelectedChannel({ ...selectedChannel, IsOnline: true });
        }
        setTypingIndicator({ member: member, typing: true });
    };
    const handleTypingEnded = (member: Member) => {
        //process the member to show typing
        setTypingIndicator({ member: member, typing: false });
    };
    const getMessagesToShow = (data: Message[]) => {
        //process the member to show typing
        if (selectedChannel && selectedChannel.ChatClearAt) {
            return data.filter(
                (x) =>
                    moment(x.dateCreated) > (selectedChannel.ChatClearAt as any)
            );
        }
        return data;
    };

    const loadMoreMessages = useRef<() => Promise<any> | void>(() => {});
    const initLogic = useMemo(
        () =>
            debounce(async () => {
                if (selectedChannel && client) {
                    setLoadingMessage(true);
                    removeListeners();
                    const channelInfo = await client.getChannelBySid(
                        defaultTo(selectedChannel.ChannelId, "")
                    );

                    const members = defaultTo(
                        await channelInfo.getMembers(),
                        []
                    );

                    const otherMember = members.find(
                        (x) => x.identity == userId
                    );
                    if (otherMember) {
                        setIsBlock(
                            otherMember.roleSid === blockMembershipRoleSid
                        );
                    }
                    setChannelMembers(members);
                    const messagesResponse = await channelInfo.getMessages();

                    channelInfo.setAllMessagesConsumed();
                    setChannel(channelInfo);
                    setChannelMessages(
                        getMessagesToShow(messagesResponse.items)
                    );
                    setChannelMessagesPaginator(messagesResponse);

                    readAllMessagesHandler(channelInfo.sid);

                    channelInfo.on("messageAdded", handleMessageAdded.current);
                    channelInfo.on(
                        "messageUpdated",
                        handleMessageUpdate.current
                    );
                    channelInfo.on("typingStarted", handleTypingStarted);
                    channelInfo.on("typingEnded", handleTypingEnded);
                    setChannel(channelInfo);
                    for (const member of members) {
                        member.on(
                            "updated",
                            handlerMemberLastConsumeIndxUpdate.current
                        );
                    }
                }
                setLoadingMessage(false);
                scrollToBottom();
            }, 50),
        [selectedChannel?.ChannelId, client]
    );
    useEffect(() => {
        if (selectedChannel && client) {
            setScrollDone(false);
            setChannelMessages([]);
            initLogic();
        }
    }, [selectedChannel?.ChannelId, initLogic]);

    useEffect(() => {
        loadMoreMessages.current = async () => {
            if (
                channel &&
                channelMessagesPaginator?.hasPrevPage &&
                !showOverlayLoader &&
                scrollDiv.current
            ) {
                if (
                    !channelMessagesPaginator ||
                    (channelMessagesPaginator &&
                        !channelMessagesPaginator.items.some(
                            (x) =>
                                moment(x.dateCreated) <
                                (selectedChannel.ChatClearAt as any)
                        ))
                ) {
                    // fetch new page only if there is no message older than chatClearAt in old response
                    const oldHeight = scrollDiv.current.scrollHeight;
                    setLoadingMessage(true);
                    const messagesResponse =
                        await channelMessagesPaginator.prevPage();

                    setChannelMessages((old) => [
                        ...getMessagesToShow(messagesResponse.items),
                        ...old,
                    ]);

                    setChannelMessagesPaginator(messagesResponse);

                    setLoadingMessage(false);
                    setTimeout(() => {
                        if (scrollDiv.current) {
                            const newHeight = scrollDiv.current.scrollHeight;
                            const scrollTop = newHeight - oldHeight;

                            $(scrollDiv.current).scrollTop(
                                scrollTop > 0 ? scrollTop : 0
                            );
                        }
                    }, 500);
                }
            }
        };
    }, [channel, channelMessagesPaginator, loadingMessage]);

    useEffect(() => {
        return () => {
            initLogic.cancel();
            removeListeners();
        };
    }, []);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (
                scrollDiv.current &&
                scrollDiv.current.scrollHeight &&
                scrollDiv.current.clientHeight
            ) {
                const scrollHeight = scrollDiv.current.scrollHeight;
                const height = scrollDiv.current.clientHeight;
                const maxScrollTop = scrollHeight - height;
                // $(scrollDiv.current).animate(
                //     {
                //         scrollTop: maxScrollTop > 0 ? maxScrollTop : 0,
                //     },
                //     200
                // );

                $(scrollDiv.current).scrollTop(
                    maxScrollTop > 0 ? maxScrollTop : 0
                );
                setTimeout(() => setScrollDone(true), 250);
            }
        }, 400);
    };

    useEffect(() => {
        handleMessageAdded.current = (message: Message) => {
            setChannelMessages((prevState) => {
                if (
                    prevState.findIndex((item) => item.sid === message.sid) ===
                    -1
                ) {
                    return [...prevState, message];
                } else {
                    return [...prevState];
                }
            });

            setChannel((pervious) => {
                if (pervious) {
                    pervious
                        .updateLastConsumedMessageIndex(message.index)
                        .then(function () {
                            // updated
                        });
                }
                return pervious;
            });
            scrollToBottom();
        };

        //Update the channel message state
        //When message update in twilio
        handleMessageUpdate.current = (message: any) => {
            if (message.updateReasons[0] === "body") {
                setChannelMessages((prevState) => {
                    const index = prevState.findIndex(
                        (item) => item.sid === message.sid
                    );
                    const previousCopy = [...prevState];
                    previousCopy[index] = message;
                    return [...previousCopy];
                });
            }
        };
    }, [channelMessages, setChannelMessages, appLocale]);

    React.useMemo(() => {
        handlerMemberLastConsumeIndxUpdate.current = (member: any) => {
            if (member.updateReasons[0] === "lastConsumedMessageIndex") {
                setChannelMembers((previous) => {
                    const copyMembers = [...previous];
                    const index = copyMembers.findIndex(
                        (x) => x.sid === member.member.sid
                    );
                    copyMembers[index] = member.member;
                    return copyMembers;
                });
            }
        };
    }, [channelMembers]);

    //This method is setting the text state when
    //user write something in message filed

    const onMessageChange = (text: string) => {
        setMessageState({ ...messageState, text: text });
        if (text.length > 0 && channel) {
            channel.typing();
        }
    };

    const sendMessage = () => {
        if (messageState.text && String(messageState.text).trim()) {
            if (messageState.text.length > MAX_CHAT_MESSAGE_LENGTH) {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("chat.errors.messageTooLong"),
                    "error"
                );
                return;
            }
            setMessageState({ ...messageState, loading: true });

            const messageAttribute: MessageAttribute = {
                Name: defaultTo(selectedChannel.AdminName, ""),
            };
            if (channel) {
                channel
                    .sendMessage(messageState.text, messageAttribute)
                    .then(async () => {
                        //in case of one to one chat
                        //if other member already delete the chat
                        //then set deleteChat false so he/she can
                        //get new message
                        if (
                            selectedChannel.ChannelType ===
                            ChannelType.ONE_TO_ONE
                        ) {
                            const member = await channel.getMemberBySid(
                                defaultTo(selectedChannel.OtherMemberSid, "")
                            );
                            const memberAttr = parseObject(
                                member.attributes
                            ) as MemberAttribute;
                            if (memberAttr.DeleteChat) {
                                memberAttr.DeleteChat = false;
                                member.updateAttributes(memberAttr);
                                // do api to update delete flag in DB as well
                                if (selectedChannel.ChannelId) {
                                    await updateDeleteFlag(
                                        selectedChannel.ChannelId
                                    );
                                }
                            }
                        }

                        setMessageState({
                            ...messageState,
                            loading: false,
                            text: "",
                        });
                        if (isBlock) {
                            setIsBlock(false);
                        }
                        scrollToBottom();
                    })
                    .catch((error) => {
                        if (
                            error.code ===
                            TWILIO_USER_NO_LONGER_EXIST_ERROR_CODE
                        ) {
                            showSweetAlertToast(
                                t("common.error.error"),
                                t("chat.errors.noLongerMemberOfTheGroup"),
                                "error"
                            );
                            removeChannelFromLocal(channel.sid);
                        } else if (
                            error.code === TWILIO_USER_UNAUTHORIZED_ERROR_CODE
                        ) {
                            showSweetAlertToast(
                                t("common.error.error"),
                                t("chat.blockMessage"),
                                "error"
                            );
                            setIsBlock(true);
                        } else if (error.code === TWILIO_MESSAGE_TOO_LONG) {
                            showSweetAlertToast(
                                t("common.error.error"),
                                t("chat.errors.messageTooLong"),
                                "error"
                            );
                        } else {
                            // showUnexpectedErrorToast();
                            showSweetAlertToast(
                                t("common.error.error"),
                                t("chat.errors.noLongerMemberOfTheGroup"),
                                "error"
                            );
                            removeChannelFromLocal(channel.sid);
                        }
                        setMessageState({
                            ...messageState,
                            loading: false,
                            text: "",
                        });
                    });
            }
        }
    };

    //This method will update the membership attributes for the user
    //it will add the last date created date to the memberAttributes.ChatClearAt

    const onClearChatHandler = async () => {
        if (
            channelMessages[channelMessages.length - 1] &&
            channel &&
            selectedChannel &&
            selectedChannel.MemberSid
        ) {
            const member = await channel.getMemberBySid(
                selectedChannel.MemberSid
            );

            const memberAttributes = parseObject(
                member.attributes
            ) as MemberAttribute;
            const newTime = moment(
                channelMessages[channelMessages.length - 1].dateCreated,
                DATE_TIME
            );
            memberAttributes.ChatClearAt = newTime.valueOf();
            await member.updateAttributes(memberAttributes);
            setChannelMessages([]);
            updateClearChatAt(channel.sid, newTime);
        }
    };

    //For delete one to one chat
    //get the membership of current user and set delete true
    //if other member delete the chat then delete the one to one
    //channel and force delete from database as well
    const onDeleteChatHandler = async () => {
        if (twilioChannelLoading) {
            return;
        }
        removeListeners();
        if (channel) {
            const member = await channel.getMemberBySid(
                defaultTo(selectedChannel.MemberSid, "")
            );
            const otherMember = await channel.getMemberBySid(
                defaultTo(selectedChannel.OtherMemberSid, "")
            );
            const memberAttribute = parseObject(
                member.attributes
            ) as MemberAttribute;
            const OtherMemberAttribute = parseObject(
                otherMember.attributes
            ) as MemberAttribute;
            if (
                OtherMemberAttribute &&
                OtherMemberAttribute.DeleteChat &&
                selectedChannel.ChannelId
            ) {
                deleteChat({
                    id: selectedChannel.ChannelId,
                    forceDelete: true,
                });
            } else {
                memberAttribute.DeleteChat = true;
                await member.updateAttributes(memberAttribute);
                if (selectedChannel.ChannelId) {
                    deleteChat({ id: selectedChannel.ChannelId });
                }
            }
        }
    };

    useEffect(() => {
        setLoading(deleteChatLoading);
        if (
            !deleteChatLoading &&
            deleteChatResponse &&
            deleteChatResponse.Data
        ) {
            removeChannelFromLocal(
                defaultTo(
                    deleteChatResponse.Data,
                    defaultTo(selectedChannel.ChannelId, "")
                )
            );
        } else if (
            !deleteChatLoading &&
            deleteChatResponse &&
            deleteChatResponse.Code
        ) {
            console.error(deleteChatResponse.Code);
        }
    }, [deleteChatResponse, deleteChatLoading]);

    //This method will remove the custom channel groups
    //and remove the channel from local channels list
    const onDeleteChannelHandler = async () => {
        if (twilioChannelLoading) {
            return;
        }
        if (channel) {
            deleteChat({ id: channel.sid, forceDelete: true });
        }
    };

    const handlerTypingMemberName = () => {
        if (typingIndicator.member) {
            const member = parseObject(
                typingIndicator.member.attributes
            ) as MemberAttribute;
            return `${member.MemberInfo?.Name} ${t("chat.isTyping")}`;
        }
    };

    //when user click on the edit button in-front of the message
    const MessageEditClickHandler = (message: Message) => {
        setMessageEditState({
            message: message,
            isEdit: true,
        });
        setMessageState({ ...messageState, text: message.body });
    };
    //where user click the edit button
    const onMessageEditHandler = () => {
        if (messageEditState && messageEditState.message) {
            if (
                messageEditState.message.body.length > MAX_CHAT_MESSAGE_LENGTH
            ) {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("chat.errors.messageTooLong"),
                    "error"
                );
            } else {
                messageEditState.message.updateBody(messageState.text);
                setMessageEditState({ ...messageEditState, isEdit: false });
                setMessageState({ ...messageState, text: "" });
            }
        }
    };

    const onMessageEditCancelHandler = () => {
        setMessageEditState({ message: null, isEdit: false });
        setMessageState({ ...messageState, text: "" });
    };
    const listOfDates = getUniqueList(
        channelMessages.map((item) =>
            moment(item.dateCreated).format(getDateFormatForLocale())
        )
    );

    const onFileUpload = (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        if (channel) {
            setShowOverlayLoader(true);
            channel
                .sendMessage(formData)
                .catch((e) => {
                    if (e.code == 413) {
                        showSweetAlertToast(
                            t("common.error.error"),
                            t("chat.errors.attachmentTooBig"),
                            "error"
                        );
                    } else {
                        showUnexpectedErrorToast();
                    }
                })
                .finally(() => setShowOverlayLoader(false));
        }
    };

    const messageReadHandler = (message: Message) => {
        for (const member of channelMembers) {
            if (
                member.lastConsumedMessageIndex &&
                member.lastConsumedMessageIndex >= message.index
                // eslint-disable-next-line no-empty
            ) {
            } else {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <Card className={styles.root}>
                {showOverlayLoader && <AppOverlayLoader />}
                <Card.Title>
                    <ChatBoxHeader
                        twilioChannelLoading={twilioChannelLoading}
                        selectedChannel={selectedChannel}
                        clearChatHandler={onClearChatHandler}
                        deleteChatHandler={onDeleteChatHandler}
                        deleteChannelHandler={onDeleteChannelHandler}
                        loading={loadingMessage}
                        addToGroupHandler={() =>
                            onModelOpenHandler(
                                ChatTabsType.ADD_MEMBERS,
                                defaultTo(channel?.sid, "")
                            )
                        }
                        removeMemberFromGroup={() =>
                            onModelOpenHandler(
                                ChatTabsType.REMOVE_MEMBERS,
                                defaultTo(channel?.sid, "")
                            )
                        }
                    />
                </Card.Title>
                <Card.Body className={styles.bodyRoot}>
                    {(loadingMessage || !scrollDone) && (
                        <AppLoader
                            fullHeight={false}
                            className={styles.scrollLoader}
                        />
                    )}
                    {!loadingMessage && (
                        <div
                            className={styles.chatMessagesContainer}
                            ref={(ref) => {
                                scrollDiv.current = ref;
                                if (scrollDiv.current) {
                                    $(scrollDiv.current).off("scroll");
                                    let scrollHeight = 0;
                                    $(scrollDiv.current).on("scroll", () => {
                                        if (scrollDiv.current) {
                                            const scrollTop =
                                                scrollDiv.current.scrollTop;

                                            if (scrollTop < scrollHeight) {
                                                // scrolling upward
                                                if (scrollTop < 100) {
                                                    loadMoreMessages.current();
                                                }
                                            }
                                            scrollHeight = scrollTop;
                                        }
                                    });
                                }
                            }}
                            style={{
                                transition: "opacity 0.5s",
                                opacity: !scrollDone ? 0 : 1,
                            }}
                        >
                            {listOfDates.map((date, index) => {
                                return (
                                    <div key={index}>
                                        {/* @ts-ignore */}
                                        <StickyBox
                                            className={styles.stickyRoot}
                                        >
                                            <div className={styles.stickyDiv}>
                                                {todayYesterdayOtherDateHandler(
                                                    date,
                                                    false,
                                                    true
                                                )}
                                            </div>
                                        </StickyBox>

                                        {channelMessages
                                            .filter(
                                                (x) =>
                                                    moment(
                                                        x.dateCreated
                                                    ).format(
                                                        getDateFormatForLocale()
                                                    ) === date
                                            )
                                            .map((message) => (
                                                <ChatBubbleItem
                                                    key={message.sid}
                                                    isPrivateChat={
                                                        selectedChannel.ChannelType ==
                                                        ChannelType.ONE_TO_ONE
                                                    }
                                                    message={message}
                                                    userId={userId}
                                                    messageEditClickHandler={
                                                        MessageEditClickHandler
                                                    }
                                                    isRead={messageReadHandler(
                                                        message
                                                    )}
                                                />
                                            ))}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div>
                        {selectedChannel && (
                            <>
                                <div className={styles.typingDiv}>
                                    {isBlock ? (
                                        <>
                                            <div className={styles.blockText}>
                                                {`${t("chat.blockMessage")} 
                                                "${getChannelName(
                                                    selectedChannel.ChannelOriginalName,
                                                    selectedChannel.ChannelType,
                                                    selectedChannel.ChannelUserType
                                                )}"`}
                                            </div>
                                        </>
                                    ) : (
                                        typingIndicator.typing &&
                                        handlerTypingMemberName()
                                    )}
                                    {}
                                </div>

                                <ChatMessageField
                                    text={messageState.text}
                                    onMessageChange={onMessageChange}
                                    btnLoading={messageState.loading}
                                    onClick={sendMessage}
                                    isEdit={messageEditState.isEdit}
                                    onEdit={onMessageEditHandler}
                                    onEditCancel={onMessageEditCancelHandler}
                                    onFileUpload={onFileUpload}
                                    disabled={isBlock}
                                />
                            </>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ChatBox;
