import { AppDeleteButton, AppIconButton } from "components/Buttons";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useAppContext } from "hoc/providers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { PermissionAccessMap, PermissionAccessTypes, Role } from "models";
import { AppPlatforms } from "models/general";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import RoleManagementUserDetailPopover from "screens/business/roleManagement/partials/RoleManagementUserDetailPopover";
import {
    getRoleManagementServiceKey,
    RoleManagementService,
} from "services/business";

export interface RoleManagementTableRowProp {
    value: Role;
    openDialog: (roleId: string) => void;
    removeItem: (Id: string) => void;
    cloneRole: (roleId: string) => void;
    deviceType: AppPlatforms;
    permissionMap: PermissionAccessMap;
}

export const RoleManagementTableRow: React.FC<RoleManagementTableRowProp> = ({
    value,
    deviceType,
    openDialog: onEditClick,
    cloneRole,
    removeItem,
    permissionMap: hasPermission,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { userInfo } = useAppContext();

    const service = new RoleManagementService(
        linkProvider.business.api.roleManagement
    );
    const onEditRolePermissions = (roleId: string) => {
        navigate(
            linkProvider.business.screens.admin.rolesManagement.permissionEdit(
                roleId,
                deviceType.toLocaleLowerCase() as any
            )
        );
    };

    const {
        mutate: deleteRole,
        isLoading: loadingDel,
        data: responseDel,
        error: delError,
    } = useMutation(
        getRoleManagementServiceKey("delete"),
        async (roleId: string) => await service.delete(roleId, deviceType)
    );

    useEffect(() => {
        if (!loadingDel) {
            if (responseDel && responseDel.Data) {
                removeItem(value.Id);
                showSweetAlertToast(
                    t("common.success"),
                    `"${value.Name} ${t("common.actions.deletedSuccessfully")}`,
                    "success"
                );
            } else if (
                !loadingDel &&
                responseDel &&
                responseDel.Code != null &&
                responseDel.Errors
            ) {
                showSweetAlertToast(
                    t("common.error.error"),
                    responseDel.Errors[0].Message,
                    "error"
                );
            } else if (responseDel || delError) {
                showUnexpectedErrorToast();
            }
        }
    }, [loadingDel, responseDel]);

    return (
        <tr>
            <td
                onClick={() => onEditClick(value.Id)}
                className={"ellipsis-text single-line"}
                style={{ paddingLeft: "10px" }}
            >
                <span>{value.Name}</span>
            </td>
            <td
                onClick={() => onEditClick(value.Id)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.Description}</span>
            </td>
            <td onClick={() => onEditClick(value.Id)}>
                {value.UserAssignments.length == 0 ? (
                    t("roleManagement.role.noUser")
                ) : (
                    <RoleManagementUserDetailPopover
                        assignmentSummary={
                            value.UserAssignments.length == 1
                                ? `1 ${t("roleManagement.role.user")}`
                                : `${value.UserAssignments.length} ${t(
                                      "roleManagement.role.users"
                                  )}`
                        }
                        mainHeading={t("roleManagement.role.assignToUsers")}
                        persons={value.UserAssignments}
                    />
                )}
            </td>
            <td>
                <AppIconButton
                    onClick={() => onEditRolePermissions(value.Id)}
                    buttonType="Edit"
                    readonly={
                        !hasPermission[PermissionAccessTypes.SHOW] || loadingDel
                    }
                    showToolTip={true}
                    tooltipText={t("roleManagement.role.editRolePermissions")}
                />
            </td>
            <td>
                <AppIconButton
                    buttonType="Clone"
                    showToolTip={true}
                    readonly={!hasPermission[PermissionAccessTypes.CREATE]}
                    tooltipText={t("common.clone")}
                    onClick={() => cloneRole(value.Id)}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() => deleteRole(value.Id)}
                    getConfirmation={true}
                    name={value.Name}
                    readonly={
                        !value.IsDeletable ||
                        value.UserAssignments.length > 0 ||
                        !hasPermission[PermissionAccessTypes.DELETE] ||
                        loadingDel ||
                        defaultTo(
                            userInfo &&
                                userInfo.AccessInfo.ActiveRoleScope.RoleId ==
                                    value.Id,
                            false
                        )
                    }
                />
            </td>
        </tr>
    );
};

export default RoleManagementTableRow;
