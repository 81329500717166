import { AppLoader, AppOverlayLoader } from "components/Loaders";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import {
    KindergartenClient,
    KindergartenClientContactType,
    parseKindergartenClientResponseToClient,
} from "models/kindergartenClient";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
    getKindergartenClientServiceKey,
    KindergartenClientService,
} from "services/kindergarten";
import {
    ClientCreateEditBasicData,
    ClientEditAdditionalInfo,
} from "./createEdit";
import { ClientContactActionMenu } from "../partials";
import { useKindergartenClientContext } from "./KindergartenClientsContextProvider";
import { useActiveKindergartenClient } from "./tabs";
import ClientContactInfoEdit from "screens/kindergarten/clients/createEdit/ClientContactInfoEdit";
import { Moment } from "moment-timezone";

export const KindergartenClientEdit = () => {
    const [state, setState] = useState<KindergartenClient | undefined>(
        undefined
    );
    const currentClient = useActiveKindergartenClient();
    const queryClient = useQueryClient();
    const { sessionBusiness, sessionBusinessLocation } = useSessionBusiness();
    const onCustodianChange = (client: KindergartenClient) => {
        setState({ ...client });
    };

    const { onChangeClientList, clients } = useKindergartenClientContext();
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const clientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );
    const { isFetching, data, refetch } = useQuery(
        getKindergartenClientServiceKey("getClient", {
            id: currentClient.dataItem?.EncodedId,
        }),
        async () => {
            if (currentClient.dataItem) {
                return await clientService.getClient(
                    currentClient.dataItem?.EncodedId
                );
            }
        }
    );
    useEffect(() => {
        refetch();
    }, [currentClient.dataItem?.EncodedId]);

    useEffect(() => {
        if (
            !isFetching &&
            data &&
            data.Data &&
            data.Data.EncodedId == currentClient.dataItem?.EncodedId
        ) {
            setState(parseKindergartenClientResponseToClient(data.Data));
        }
    }, [isFetching, data]);

    return state === undefined ||
        data === undefined ||
        isFetching ||
        currentClient.dataItem?.EncodedId != state?.EncodedId ? (
        <AppLoader />
    ) : (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div>
                <ClientCreateEditBasicData
                    isEdit={true}
                    isLoading={state === undefined || isFetching}
                    value={state}
                    onSuccess={() => {
                        if (currentClient.refetchData) {
                            currentClient.refetchData();
                        }
                        queryClient.invalidateQueries(
                            getKindergartenClientServiceKey("getClient")
                        );
                    }}
                    onChange={(val) => {
                        if (clients && val) {
                            onChangeClientList(
                                clients.map((x) => {
                                    if (val.Id === x.Id) {
                                        return {
                                            ...x,
                                            FirstName: val.FirstName,
                                            LastName: val.LastName,
                                            Name: `${val.FirstName} ${val.LastName}`,
                                        };
                                    }
                                    return x;
                                })
                            );
                        }
                    }}
                    schoolStart={sessionBusinessLocation?.SchoolStart}
                    deadLine={sessionBusiness?.BusinessSetting.AgeGroupDeadline}
                />
            </div>
            {state &&
                state.Contacts &&
                state.Contacts.map((contact, index) => (
                    <ClientContactInfoEdit
                        indexId={index}
                        key={index}
                        clientContactType={
                            contact.IsPrimaryContact
                                ? KindergartenClientContactType.TYPE_PRIMARY
                                : KindergartenClientContactType.TYPE_SECONDARY
                        }
                        completeData={state}
                        contactData={contact}
                        onCustodianChange={onCustodianChange}
                        title={t("kindergartenClient.contactInfo.title", {
                            count: contact.IsPrimaryContact ? 1 : 2,
                        })}
                    />
                ))}

            {state && (
                <ClientEditAdditionalInfo
                    value={state}
                    onChange={onCustodianChange}
                />
            )}
        </div>
    );
};

export default KindergartenClientEdit;
