import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { AppCheckbox } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_ToDo,
    PermissionAccessTypes,
    TodoRequest,
    TodoStatus,
} from "models";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { TodoService } from "services/business";
import styles from "./TodoManagementTableRow.module.scss";

export interface TodoManagementTableRowProps {
    value: TodoRequest;
    onFilterChange: () => void;
}

export const TodoManagementTableRow: React.FC<TodoManagementTableRowProps> = ({
    value,
    onFilterChange,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { checkPermission } = useCheckPermission();
    const todoService = new TodoService(linkProvider.business.api.todo);

    const navigate = useNavigate();
    const onEditClick = (todoId: string) => {
        navigate(linkProvider.business.screens.todoManagement.edit(todoId));
    };

    const hasTodoDeletePermission = checkPermission(Business_ToDo, [
        PermissionAccessTypes.DELETE,
    ]);

    const {
        isLoading: deleteLoading,
        data: deleteResp,
        mutate: deleteTodo,
    } = useMutation(async (todoId: string) => await todoService.delete(todoId));

    useEffect(() => {
        if (!deleteLoading && deleteResp) {
            onFilterChange();
            showSweetAlertToast(
                `${t("todoManagement.task.name").toString()} ${t(
                    "common.actions.deletedSuccessfully"
                ).toString()}`,
                "",
                "success"
            );
        }
    }, [deleteResp, deleteLoading]);

    return (
        <tr
            onClick={() => {
                if (
                    value.EncodedId &&
                    hasTodoDeletePermission &&
                    !deleteLoading
                ) {
                    onEditClick(value.EncodedId);
                }
            }}
        >
            <td style={{ paddingLeft: "10px" }}>{value.Priority}</td>
            <td
                style={{ minWidth: "200px", maxWidth: "200px", width: "200px" }}
            >
                <p className={styles.textEllipse}>{value.Task}</p>
            </td>
            <td
                style={{ minWidth: "200px", maxWidth: "200px", width: "200px" }}
            >
                <p className={styles.textEllipse}>{value.Description.Text}</p>
            </td>
            <td
                style={{ minWidth: "200px", maxWidth: "200px", width: "200px" }}
            >
                <p className={styles.textEllipse}>
                    {value.WorkingArea && value.WorkingArea.Name}
                </p>
            </td>
            <td
                style={{ minWidth: "200px", maxWidth: "200px", width: "200px" }}
            >
                <p className={styles.textEllipse}>
                    {value.AssignedToEmployee && value.AssignedToEmployee.Name}
                </p>
            </td>
            <td>
                {value.CreatedAt &&
                    value.CreatedAt.format(getDateFormatForLocale())}
            </td>
            <td>{value.DueDate.format(getDateFormatForLocale())}</td>
            <td>
                {value.Status &&
                    value.Status === TodoStatus.Status_Reopened && (
                        <AppCheckbox
                            disabled={true}
                            checked={
                                value.Status &&
                                value.Status === TodoStatus.Status_Reopened
                            }
                        />
                    )}
            </td>
            <td>
                {value.CompletionDate &&
                    value.CompletionDate.format(getDateFormatForLocale())}
            </td>
            <td>
                {value.DoneDate &&
                    value.DoneDate.format(getDateFormatForLocale())}
            </td>
            <td>
                <AppIconButton
                    buttonType={"Edit"}
                    readonly={deleteLoading}
                    onClick={() => {
                        if (value.EncodedId) {
                            onEditClick(value.EncodedId);
                        }
                    }}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() => {
                        if (value.EncodedId) {
                            deleteTodo(value.EncodedId);
                        }
                    }}
                    getConfirmation={true}
                    readonly={!hasTodoDeletePermission || deleteLoading}
                    name={value.Task}
                />
            </td>
        </tr>
    );
};

export default TodoManagementTableRow;
