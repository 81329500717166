import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetisMenuContentType } from "models/metisMenu";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { BusinessType } from "models/business";
import {
    GeneralUserProfileUpdate,
    LW_Support_ElderlyCare,
    LW_Support_Industry,
    LW_Support_Kindergartens,
    LW_Support_Schools,
} from "models/permissionManagement/permissionConstants";
import routesForContext from "routing/AppRoutes";
import { lwSupportRoutesMap } from "routing/routes/lillywait/support/routesMap";

const routesTemp = routesForContext();
export const supportNav = (
    linkProvider: typeof routesTemp,
    relativePathProvider: typeof routesTemp,
    hasNoAccess: boolean
): MetisMenuContentType[] => {
    return hasNoAccess
        ? [
              {
                  label: t("sidebar.editProfile"),
                  icon: <FontAwesomeIcon icon={"user-cog"} />,
                  to: linkProvider.authUser.screens.editProfile(),
                  permissions: [GeneralUserProfileUpdate],
              },
          ]
        : [
              {
                  iconClass: "pe-7s-user",
                  label: t("sidebar.lwClients.title"),
                  activeIfPatternLike: [
                      "support/kindergartens",
                      "support/schools",
                      "support/industries",
                      "support/elderlycares",
                  ],
                  subMenu: [
                      {
                          label: t("sidebar.lwClients.kindergarten"),
                          activeIfPatternLike: ["support/kindergartens"],
                          to: linkProvider.lillywait
                              .support()
                              .screens.list(BusinessType.KINDERGARTEN),
                          permissions: [LW_Support_Kindergartens],
                      },
                      {
                          label: t("sidebar.lwClients.industry"),
                          activeIfPatternLike: ["support/industries"],
                          to: linkProvider.lillywait
                              .support()
                              .screens.list(BusinessType.INDUSTRY),
                          permissions: [LW_Support_Industry],
                      },
                      {
                          label: t("sidebar.lwClients.school"),
                          activeIfPatternLike: ["support/schools"],
                          to: linkProvider.lillywait
                              .support()
                              .screens.list(BusinessType.SCHOOL),
                          permissions: [LW_Support_Schools],
                      },
                      {
                          label: t("sidebar.lwClients.elderlyCare"),
                          activeIfPatternLike: ["support/elderly-cares"],
                          permissions: [LW_Support_ElderlyCare],
                          to: linkProvider.lillywait
                              .support()
                              .screens.list(BusinessType.ELDERLY_CARE),
                      },
                  ],
              },
              {
                  icon: <FontAwesomeIcon icon={"users-cog"} />,
                  label: t("sidebar.lw.mergeCompanies"),
                  to: linkProvider.lillywait.support().screens.mergeCompanies(),
                  permissions: defaultTo(
                      lwSupportRoutesMap[
                          relativePathProvider.lillywait
                              .support()
                              .screens.mergeCompanies()
                      ].permKeys,
                      []
                  ),
              },
          ];
};
