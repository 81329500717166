import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { getRegisterDefaultValue, RegisterRequest } from "models";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WithAuthLoader } from "screens/auth/partials/AuthLoader";
import { AuthService, getAuthServiceKey } from "services";
import { RegisterFields } from "./fields";
import { AuthHeader } from "./header";
import ErrorMessage from "components/ErrorMessage";
import LinkExpired from "screens/auth/partials/LinkExpired";
import { ErrorCodes } from "models/general";
import AuthError from "screens/auth/partials/AuthError";

const USER_ID_PARAM_KEY = "userId";
const CODE_PARAM_KEY = "code";
export interface CompleteRegistrationHeaderAndFieldsProps
    extends WithAuthLoader {}

export const CompleteRegistrationHeaderAndFields: React.FC<
    CompleteRegistrationHeaderAndFieldsProps
> = ({ setLoading, loading }) => {
    const { linkProvider } = useRouting();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const userId = searchParams.get(USER_ID_PARAM_KEY);
    const code = searchParams.get(CODE_PARAM_KEY);

    const [state, setState] = useState(getRegisterDefaultValue());
    const [errorCode, setError] = useState<string | undefined>(undefined);

    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isFetching,
        error,
        data: response,
    } = useQuery(
        getAuthServiceKey("getEmployeeInfoRegister", {
            userId: userId,
            code: code,
        }),
        async () =>
            userId &&
            (await authService.getCompleteRegisterInfo(userId, code as string))
    );
    const showErrorPage = useShowErrorPage();

    useEffect(() => {
        if (isNil(userId) || isNil(code)) {
            showErrorPage(404);
        }
    }, []);
    useEffect(() => {
        if (error) {
            showErrorPage(500);
        } else if (errorCode == ErrorCodes.UNKNOWN_USER) {
            showErrorPage(404);
        }
    }, [error, errorCode]);

    useEffect(() => {
        if (isFetching !== loading) {
            setLoading(isFetching);
        }
        if (!isFetching) {
            if (response) {
                if (response.Data) {
                    const res = response.Data;
                    setState({
                        ...state,
                        Id: res.Id,
                        FirstName: res.FirstName,
                        LastName: res.LastName,
                        Email: res.Email as string,
                    });
                } else if (response.Code) {
                    setError(response.Code);
                }
            }
        }
    }, [isFetching]);

    const {
        isLoading: updating,
        data: postResponse,
        mutate: completeRegistration,
    } = useMutation(
        async (request: RegisterRequest) =>
            userId && (await authService.completeRegister(request))
    );

    useEffect(() => {
        setLoading(updating);
    }, [updating]);

    return errorCode && errorCode == ErrorCodes.BAD_INPUT ? (
        <LinkExpired />
    ) : errorCode ? (
        <AuthError
            errorMsg={
                errorCode == ErrorCodes.USER_ALREADY_CONFIRMED
                    ? t("auth.userAlreadyRegistered")
                    : undefined
            }
        />
    ) : (
        <>
            <AuthHeader
                mainHeading={t("auth.completeUserRegistration.title")}
                pageTitle={t("auth.registration.pageTitle")}
            />
            <RegisterFields
                value={state}
                onChange={(val) => setState(val)}
                onClick={completeRegistration}
                response={postResponse ? postResponse : undefined}
                loading={updating}
                isRegistrationComplete={true}
                redirectUrl={linkProvider.noAuth.screens.registerInfoCompleted()}
            />
        </>
    );
};

export default CompleteRegistrationHeaderAndFields;
