import { AppCustomHeaderTable, AppTable } from "components/Table";
import { defaultTo } from "lodash-es";
import {
    TimeTrackingRequestParameters,
    ClientsDayViewTableData,
    calculateHeaderTotals,
    TimeTrackingEntry,
    TimeTrackingRecord,
    defaultHeaderTotals,
} from "models";
import { SelectItem, DurationPeriodType } from "models/general";
import { useState } from "react";
import { TimeManagementDayHeader, TimeManagementDayTable } from "../partials";
import { v4 as uuid } from "uuid";
import styles from "./TimeManagementDayView.module.scss";
import { TimeManagementState } from "screens/business/timeTracking/TimeManagementMain";
import { useTranslation } from "react-i18next";
import { Moment } from "moment-timezone";

export interface TimeManagementDayViewProps {
    values: TimeManagementState;
    date: Moment;
    refetchData?: () => void;
    manualAllowed: boolean;
    onFilterChange: (filter: TimeTrackingRequestParameters) => void;
}

export const TimeManagementDayView: React.FC<TimeManagementDayViewProps> = ({
    values,
    manualAllowed,
    refetchData,
    date,
    onFilterChange,
}) => {
    const { t } = useTranslation();
    const { filterRequest, headerData, records } = values;
    const [visible, setVisibility] = useState(true);

    const searchInClients = (
        val: string,
        clients: TimeTrackingEntry[]
    ): TimeTrackingEntry[] => {
        return clients.filter((x) =>
            x.PersonName.trim()
                .toLocaleLowerCase()
                .includes(
                    defaultTo(filterRequest.SearchValue, "")
                        .trim()
                        .toLocaleLowerCase()
                )
        );
    };
    const getGroups = (): ClientsDayViewTableData[] => {
        //search value is undefined then map the data into table data
        if (!filterRequest.SearchValue && headerData?.Groups) {
            return headerData.Groups.map(
                (x) =>
                    ({
                        groupName: x.Name,
                        groupId: x.Id,
                        records: records?.filter(
                            (y) => y.CurrentGroupId === x.Id
                        ),
                    } as ClientsDayViewTableData)
            );
        }
        const filterResult: ClientsDayViewTableData[] = [];
        const filterValue = defaultTo(filterRequest.SearchValue, "")
            .trim()
            .toLocaleLowerCase();
        headerData?.Groups?.map((y) => {
            const clients = records?.filter(
                (x) => x.CurrentGroupId === y.Id
            ) as TimeTrackingRecord[];

            const childSearchResult = searchInClients(
                filterValue,
                clients
            ) as TimeTrackingRecord[];
            if (y.Name.trim().toLocaleLowerCase().includes(filterValue)) {
                if (childSearchResult.length > 0) {
                    //if search value exist in childs then return child search filterResult
                    //else return all the children
                    return filterResult.push({
                        groupId: y.Id,
                        groupName: y.Name,
                        records: childSearchResult,
                    });
                } else {
                    return filterResult.push({
                        groupId: y.Id,
                        groupName: y.Name,
                        records: clients,
                    });
                }
            } else {
                //search value dose not exist in group name then search in all the children
                if (childSearchResult.length > 0) {
                    return filterResult.push({
                        groupId: y.Id,
                        groupName: y.Name,
                        records: childSearchResult,
                    });
                }
            }
        });
        return filterResult;
    };
    const tableToggle = () => {
        setVisibility(!visible);
    };

    const groupsData = getGroups().map((group) => {
        const totals = calculateHeaderTotals(
            group.records,
            defaultTo(headerData?.EmpKeys?.GroupEmpKeys[group.groupId], 0)
        );

        return {
            main: group,
            totals: totals,
        };
    });

    return (
        <AppCustomHeaderTable
            header={
                <TimeManagementDayHeader
                    className={styles.root}
                    list={
                        headerData && headerData.Groups
                            ? headerData.Groups.map(
                                  (k) =>
                                      ({
                                          Value: k.Id,
                                          Text: k.Name,
                                      } as SelectItem)
                              )
                            : []
                    }
                    searchValue={filterRequest.SearchValue}
                    value={
                        headerData ? headerData.Totals : defaultHeaderTotals()
                    }
                    activeEmployees={defaultTo(headerData?.ActiveEmployees, [])}
                    onSearchValueChangeHandler={(val) => {
                        onFilterChange({
                            ...filterRequest,
                            SearchValue: val,
                        });
                    }}
                />
            }
            content={
                groupsData && groupsData.length > 0 ? (
                    groupsData.map((data) => {
                        return (
                            <TimeManagementDayTable
                                key={uuid()}
                                className={styles.root}
                                date={date}
                                manualAllowed={manualAllowed}
                                refetchData={refetchData}
                                headerData={{
                                    ...headerData,
                                    Totals: data.totals,
                                }}
                                onClick={(val) => {
                                    onFilterChange({
                                        ...filterRequest,
                                        PersonId: val,
                                        PreviousFilterType:
                                            filterRequest.FilterType,
                                        FilterType: DurationPeriodType.Month,
                                        PersonName: null,
                                        SelectedId: null,
                                    });
                                }}
                                record={data.main.records}
                                visible={visible}
                                forGroup={{
                                    Text: data.main.groupName,
                                    Value: data.main.groupId,
                                }}
                                tableToggle={tableToggle}
                            />
                        );
                    })
                ) : (
                    <AppTable
                        containerClass={styles.root}
                        styleHeader={true}
                        useMaxHeight={false}
                    >
                        <tbody>
                            <tr className="dummy-row">
                                <td></td>
                            </tr>
                            <tr className="dummy-row">
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            fontSize: "1rem",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {t("common.noDataFound")}
                                    </div>
                                </td>
                            </tr>
                            <tr className="dummy-row">
                                <td></td>
                            </tr>
                        </tbody>
                    </AppTable>
                )
            }
        />
    );
};
export default TimeManagementDayView;
