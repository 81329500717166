import { CompanyType } from "models/business";
import { LwClientHQ } from "./model";

export const parseLwClientHQItemResponse = (item: LwClientHQ) => {
    return {
        ...item,
        HqInfo: {
            ...item.HqInfo,
            IsHQ: item.HqInfo.BranchType === CompanyType.Headquarter,
        },
        Branches: item.Branches
            ? item.Branches.map((b) => ({
                  ...b,
                  IsHQ: b.BranchType === CompanyType.Headquarter,
              }))
            : undefined,
    };
};
