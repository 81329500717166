export const TodoManagementEnTranslations = {
    todoManagement: {
        all: "All",
        AppCalendarEvent: "App Calendar",
        AppMessage: "App Message",
        area: "Area",
        assignee: "Assignee",
        assignToMe: "Assigned to Me",
        before: "Before",
        completed: "Completed",
        createdByMe: "Created by Me",
        creationDate: "Creation Date",
        description: {
            name: "Description",
            placeholder: "Text",
        },
        done: "Done",
        doneDate: "Done Date",
        doneSubmitted: "Done Submitted",
        dueDate: "Due Date",
        employees: {
            missing: "Employee is required",
            name: "Employees",
        },
        listEmpty: "No record found",
        new: "New",
        priority: "Priority",
        recreated: "Recreated",
        recreateSchedule: {
            missing: "Schedule is not set.",
            name: "Recreate Schedule",
        },
        reOpen: "Re-Open",
        sendReminderToAssignee: "Send reminder to Assignee",
        setSchedule: "Set Schedule",
        task: {
            missing: "Task is required.",
            name: "Task",
        },
        taskDone: "Task Done",
        taskRecreated: "Task will be recreated",
        todo: "To Do",
        via: "via",
        WebsiteNotification: "Website Notification",
        workingArea: {
            missing: "Working Area is required",
            name: "Working Area",
        },
    },
    
};
