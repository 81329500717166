import { AppRouteParams } from "hooks/general/routing";
import { defaults, isNil } from "lodash-es";
import { BusinessType } from "models/business";
import { KindergartenRoutingContext } from "routing/routingContexts";
import {
    DefaultRouteParams,
    getUrlForPattern,
} from "routing/routingContexts/helper";
import kindergartenClientRoutes from "./client/routes";

export const kindergartenRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    params?: AppRouteParams
) => {
    const currParams = defaults({}, params, DefaultRouteParams);
    const context = new KindergartenRoutingContext(
        currParams.sessionBusinessType !== ":sessionBusinessType"
            ? BusinessType.KINDERGARTEN
            : currParams.sessionBusinessType,
        currParams.sessionBusinessId
    );

    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {
            kindergartenReports: ((planningBase: string) => {
                return {
                    occupancyPlanningReport: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${planningBase}/occupancy-planing-report`,
                            true
                        ),
                    ageGroupPlanningReport: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${planningBase}/age-group-planing-report`,
                            true
                        ),
                };
            })("reports"),
        },
        screens: {
            occupancyPlanning: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "reports/occupancy-planning"
                ),
            ageGroupPlanning: () =>
                getUrlForPattern(
                    context,
                    buildUrl,
                    "reports/age-group-planning"
                ),
        },
        clients: (id?: string) =>
            kindergartenClientRoutes(
                buildUrl,
                context,
                !isNil(id) ? id : currParams.clientId
            ),
    };
};

export default kindergartenRoutes;
