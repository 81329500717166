export function isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
export function isChromeOrEdge() {
    return (
        navigator.userAgent.toLowerCase().indexOf("chrome") > -1 ||
        navigator.userAgent.toLowerCase().indexOf("edge") > -1 ||
        navigator.userAgent.toLowerCase().indexOf("edg/") > -1
    );
}
export function isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
}
