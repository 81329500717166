// Error Messages
import e400 from "assets/images/errorPage/error-400.png";
import e403 from "assets/images/errorPage/error-403.png";
import e401 from "assets/images/errorPage/error-401.png";
import e404 from "assets/images/errorPage/error-404.png";
import e500 from "assets/images/errorPage/error-500.png";
import e501 from "assets/images/errorPage/error-501.png";
import background from "assets/images/errorPage/background.png";

export const errorPageImages = {
    background: background,
    e400: e400,
    e403: e403,
    e401: e401,
    e404: e404,
    e500: e500,
    e501: e501,
};
