import { LW_Kindergarten_IPads } from "models/permissionManagement/permissionConstants/constants";
import {
    Business_AccessControl,
    Business_Archives,
    Business_BasicData,
    Business_Calendar,
    Business_Calendar_Pivot,
    Business_Chat,
    Business_Contracts,
    Business_Dashboard_Client,
    Business_Dashboard_Employee,
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_DataImport_Employee,
    Business_DocumentManager_Letterhead,
    Business_DocumentManager_Template,
    Business_Downloads,
    Business_EmailTemplate,
    Business_EmployeeKeyCalculation,
    Business_Employee_AccessControl,
    Business_Employee_App,
    Business_Employee_BankData,
    Business_Employee_BasicData,
    Business_Employee_ContractData,
    Business_Employee_Diseases,
    Business_Employee_Documents,
    Business_Employee_Groups,
    Business_Employee_Shifts,
    Business_Employee_SalaryStatement,
    Business_Employee_Absences,
    Business_Employee_Vacations,
    Business_Functions,
    Business_Groups,
    Business_CompensationTemplates,
    Business_Ipads,
    Business_Logo,
    Business_MailboxMessages,
    Business_MealTemplates,
    Business_MealTemplates_Meal,
    Business_MealTemplates_MealGroup,
    Business_ModuleSelect,
    Business_Payroll_Absences,
    Business_Payroll_AdditionalBenefits,
    Business_PrinterJobs,
    Business_Printers,
    Business_PublicHolidays,
    Business_RoleManagement_PermissionGroups_Ipad,
    Business_RoleManagement_PermissionGroups_Mobile,
    Business_RoleManagement_PermissionGroups_Web,
    Business_RoleManagement_UserRoles_Mobile,
    Business_RoleManagement_UserRoles_Web,
    Business_Shifts,
    Business_TimeTracking_Clients,
    Business_TimeTracking_Employees,
    Business_TimeTracking_Suppliers,
    Business_ToDo,
    Business_WebNotification,
    // Business_WebRegistration,
    Business_WorkingArea,
    Business_WorkingHours,
    GeneralUserPasswordChange,
    GeneralUserProfileUpdate,
    Kindergarten_Client_AccessControl,
    Kindergarten_Client_App,
    Kindergarten_Client_BankData,
    Kindergarten_Client_BasicData,
    Kindergarten_Client_Diseases,
    Kindergarten_Client_Documents,
    Kindergarten_Client_ExternalRegistrations,
    Kindergarten_Client_Family,
    Kindergarten_Client_Groups,
    Kindergarten_Client_Contracts,
    Kindergarten_Client_MedicalData,
    Kindergarten_Client_Vacations,
    LocationSettings_Affiliates,
    LocationSettings_Areas,
    LocationSettings_BasicData,
    LocationSettings_CompanyData,
    LocationSettings_DocumentLetterheads,
    LocationSettings_DocumentTemplates,
    LocationSettings_EmailTemplate,
    LocationSettings_EmployeeKeyCalculation,
    LocationSettings_ExplanationMode,
    LocationSettings_CareScopes,
    LocationSettings_SalaryGroups,
    LocationSettings_Functions,
    LocationSettings_CompensationTemplates,
    LocationSettings_MealTemplates,
    LocationSettings_MealTemplates_Meal,
    LocationSettings_MealTemplates_MealGroup,
    LocationSettings_ModulePlan,
    LocationSettings_PublicHolidays,
    LocationSettings_Vouchers,
    LocationSettings_WorkingDays,
    LW_ElderlyCare_BasicData,
    LW_ElderlyCare_Contracts,
    LW_ElderlyCare_Documents,
    LW_ElderlyCare_PublicHolidays,
    LW_ElderlyCare_WorkingDays,
    LW_HqRegisterRequests,
    LW_Kindergarten_AccessControl,
    LW_Kindergarten_BasicData,
    LW_Kindergarten_Contracts,
    LW_Kindergarten_Documents,
    LW_Kindergarten_EmployeeKeyCalculation,
    LW_Kindergarten_Functions,
    LW_Kindergarten_Groups,
    LW_Kindergarten_CompensationTemplates,
    LW_Kindergarten_PublicHolidays,
    LW_Kindergarten_Shifts,
    // LW_Kindergarten_WebRegistration,
    LW_Kindergarten_WorkingAreas,
    // LW_Kindergarten_Kontos,
    LW_Kindergarten_WorkingDays,
    LW_School_BasicData,
    LW_School_Contracts,
    LW_School_Documents,
    LW_School_PublicHolidays,
    LW_School_WorkingDays,
    LW_Support_ElderlyCare,
    LW_Support_Kindergartens,
    LW_Support_MergeCompanies,
    LW_Support_Schools,
    Business_ShiftPlan,
    Business_Creditor_BasicData,
    Business_Creditor_BankData,
    // Business_Kontos,
    Business_AgeGroups,
    Business_CareScopes,
    Business_SalaryGroups,
    LocationSettings_AgeGroups,
    LW_Kindergarten_AgeGroups,
    LW_Kindergarten_CareScopes,
    LW_Kindergarten_SalaryGroups,
    Business_Courses,
    LW_Kindergarten_Courses,
    LW_Kindergarten_MealTemplates,
    Business_WebRegistration,
    LW_Kindergarten_WebRegistration,
    LW_Industry_AccessControl,
    LW_Industry_BasicData,
    LW_Industry_Contracts,
    LW_Industry_Courses,
    LW_Industry_Documents,
    LW_Industry_Functions,
    LW_Industry_Groups,
    LW_Industry_IPads,
    LW_Industry_PublicHolidays,
    LW_Industry_Shifts,
    LW_Industry_WorkingAreas,
    LW_Industry_WorkingDays,
    LW_Support_Industry,
    Business_Settings,
    LW_Kindergarten_Settings,
    LW_Industry_Settings,
    LW_ElderlyCare_Settings,
    LW_School_Settings,
} from "./constants";

export const GeneralPermissions = [
    Business_WebNotification,
    Business_Downloads,
    GeneralUserPasswordChange,
    GeneralUserProfileUpdate,
];
// Business General
export const BusinessGeneralPermissions = [
    Business_TimeTracking_Employees,
    Business_TimeTracking_Suppliers,
    Business_Payroll_AdditionalBenefits,
    Business_Payroll_Absences,
    Business_ToDo,
    Business_Calendar,
    Business_Calendar_Pivot,
    Business_Dashboard_Employee,
    Business_Chat,
    Business_ShiftPlan,
    Business_Creditor_BasicData,
    Business_Creditor_BankData,
    Business_MailboxMessages,
    Business_Employee_BasicData,
    Business_Employee_ContractData,
    Business_Employee_Documents,
    Business_Employee_AccessControl,
    Business_Employee_Diseases,
    Business_Employee_Vacations,
    Business_Employee_Groups,
    Business_Employee_Shifts,
    Business_Employee_Absences,
    Business_Employee_SalaryStatement,
    Business_Employee_BankData,
    Business_Employee_App,
];
// Business Admin
export const BusinessAdminPermissions = [
    Business_BasicData,
    Business_ModuleSelect,
    // Business_Kontos,
    Business_AccessControl,
    Business_Contracts,
    Business_CompensationTemplates,
    Business_EmployeeKeyCalculation,
    Business_AgeGroups,
    Business_CareScopes,
    Business_SalaryGroups,
    Business_Groups,
    Business_Functions,
    Business_WorkingArea,
    Business_Shifts,
    Business_Printers,
    Business_PrinterJobs,
    Business_WorkingHours,
    Business_PublicHolidays,
    Business_Ipads,
    Business_Logo,
    Business_MealTemplates,
    Business_MealTemplates_Meal,
    Business_MealTemplates_MealGroup,
    Business_DataImport_Employee,
    Business_EmailTemplate,
    Business_Archives,
    Business_Settings,

    Business_DocumentManager_Template,
    Business_DocumentManager_Letterhead,
    Business_RoleManagement_UserRoles_Web,
    Business_RoleManagement_UserRoles_Mobile,
    Business_RoleManagement_PermissionGroups_Web,
    Business_RoleManagement_PermissionGroups_Mobile,
    Business_RoleManagement_PermissionGroups_Ipad,
];

// Kindergarten General
export const KindergartenGeneralPermissions = [
    // Kindergarten specific (for now)
    Business_Dashboard_Client,
    Business_TimeTracking_Clients,
    //Kindergarten childs
    Kindergarten_Client_BasicData,
    Kindergarten_Client_Contracts,
    Kindergarten_Client_Documents,
    Kindergarten_Client_AccessControl,
    Kindergarten_Client_Diseases,
    Kindergarten_Client_Vacations,
    Kindergarten_Client_Family,
    Kindergarten_Client_Groups,
    Kindergarten_Client_BankData,
    Kindergarten_Client_MedicalData,
    Kindergarten_Client_App,
    Kindergarten_Client_ExternalRegistrations,
];
// KindergartenAdmin
export const KindergartenAdminPermissions = [
    Business_Courses,
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_WebRegistration,
];

// LW General
export const LWGeneralPermissions = [
    LW_Kindergarten_BasicData,
    LW_Kindergarten_Contracts,
    LW_Kindergarten_Documents,
    LW_Kindergarten_WorkingDays,
    LW_Kindergarten_PublicHolidays,
    LW_Kindergarten_AccessControl,
    LW_Kindergarten_CompensationTemplates,
    LW_Kindergarten_EmployeeKeyCalculation,
    LW_Kindergarten_AgeGroups,
    LW_Kindergarten_CareScopes,
    LW_Kindergarten_SalaryGroups,
    LW_Kindergarten_Groups,
    LW_Kindergarten_Settings,
    LW_Kindergarten_IPads,
    LW_Kindergarten_Courses,
    LW_Kindergarten_WorkingAreas,
    LW_Kindergarten_Shifts,
    LW_Kindergarten_Functions,
    LW_Kindergarten_WebRegistration,
    LW_Kindergarten_MealTemplates,
    // LW_Kindergarten_Kontos,
    LW_Industry_BasicData,
    LW_Industry_Contracts,
    LW_Industry_AccessControl,
    LW_Industry_Documents,
    LW_Industry_WorkingDays,
    LW_Industry_Settings,
    LW_Industry_PublicHolidays,
    LW_Industry_Groups,
    LW_Industry_WorkingAreas,
    LW_Industry_Shifts,
    LW_Industry_Courses,
    LW_Industry_IPads,
    LW_Industry_Functions,
    LW_School_BasicData,
    LW_School_Contracts,
    LW_School_Documents,
    LW_School_Settings,
    LW_School_WorkingDays,
    LW_School_PublicHolidays,
    LW_ElderlyCare_BasicData,
    LW_ElderlyCare_Settings,
    LW_ElderlyCare_Contracts,
    LW_ElderlyCare_Documents,
    LW_ElderlyCare_WorkingDays,
    LW_ElderlyCare_PublicHolidays,
];
// LW Admin side
export const LWAdminPermissions = [
    LocationSettings_BasicData,
    LocationSettings_CompensationTemplates,
    LocationSettings_EmployeeKeyCalculation,
    LocationSettings_AgeGroups,
    LocationSettings_CareScopes,
    LocationSettings_SalaryGroups,
    LocationSettings_Functions,
    LocationSettings_Areas,
    LocationSettings_Functions,
    LocationSettings_ModulePlan,
    LocationSettings_PublicHolidays,
    LocationSettings_DocumentTemplates,
    LocationSettings_DocumentLetterheads,
    LocationSettings_WorkingDays,
    LocationSettings_EmailTemplate,
    LocationSettings_CompanyData,
    LocationSettings_Vouchers,
    LocationSettings_Affiliates,
    LocationSettings_ExplanationMode,
    LocationSettings_MealTemplates,
    LocationSettings_MealTemplates_Meal,
    LocationSettings_MealTemplates_MealGroup,
    LW_HqRegisterRequests,
];
// Support view
export const LWSupportPermissions = [
    LW_Support_MergeCompanies,
    LW_Support_Kindergartens,
    LW_Support_Schools,
    LW_Support_Industry,
    LW_Support_ElderlyCare,
];

export const AllowedSupportViewPermissions = [
    ...BusinessGeneralPermissions,
    ...KindergartenGeneralPermissions,
];
