import { AgeGroupTemplateCreateEdit } from "commonPartials";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { LocationSettings_AgeGroups } from "models";
import React from "react";
import { useParams } from "react-router";
import { useCurrentLocationSettingsContext } from "../LocationSettingTabs";

export const LocationAgeGroupTemplateEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();

    const { id } = useParams();
    const currentLocation = useCurrentLocationSettingsContext();

    const permMap = getPermissionMap([LocationSettings_AgeGroups]);

    const linkProviderBase = linkProvider.lillywait
        .locationSettings()
        .screens.withId().ageGroups;
    return (
        <AgeGroupTemplateCreateEdit
            id={id}
            currentBusiness={null}
            locationId={currentLocation.dataItem?.Id}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
        />
    );
};

export default LocationAgeGroupTemplateEdit;
