import { FULL_DATE_TIME_FORMAT, ISO8601_DATE_FORMAT } from "globals/constants";
import { isNil } from "lodash-es";
import moment from "moment";
import { Moment } from "moment-timezone";
import { AvatarInfo, Optional } from "../general";
import {
    ChannelType,
    ChatBusinessUserType,
    TwilioChannelUserType,
} from "./enum";
import { ChannelCacheDTO, ChannelMessage } from "./models";
export interface TwilioTokenResponse {
    Token: string;
    BlockRoleSid: string;
}
export interface MemberListResponse {
    UserId: string;
    Type: ChatBusinessUserType;
    MemberInfo: AvatarInfo;
    ChannelType: ChannelType;
}
export interface TwilioPaginator<T> {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    items: T[];
    /**
     * Request next page.
     * Does not modify existing object.
     * @return {Promise<Paginator<T>>}
     */
    nextPage(): Promise<TwilioPaginator<T>>;
    /**
     * Request previous page.
     * Does not modify existing object.
     * @return {Promise<Paginator<T>>}
     */
    prevPage(): Promise<TwilioPaginator<T>>;
}
export interface ChannelResponseModel extends AvatarInfo {
    ChannelId: Optional<string>;
    IsPinned: Optional<boolean>;
    ChannelOriginalName: string;
    ChannelDisplayName?: string;
    LastMessage: Optional<ChannelMessage>;
    UnReadMessageCount: Optional<number>;
    ChannelType: Optional<ChannelType>;
    ChannelUserType?: TwilioChannelUserType;
    LastMessageTime: Optional<Moment>;
    MemberCount: Optional<number>;
    ChannelInfo?: AvatarInfo[];
    //In case of one to one
    IsOnline?: boolean;
    MemberSid: Optional<string>;
    OtherMemberIdentityId?: string;
    OtherMemberSid?: string;
    MembersDetails?: AvatarInfo[];
    AdminName: Optional<string>;
    CreatedDate?: Moment;
    LastMessageActualDate: Optional<Moment>;
    ChatClearAt?: Optional<Moment>;
    PinnedAt: Optional<Moment>;
}

export const getChatCacheResponse = (
    channels: ChannelCacheDTO[]
): ChannelResponseModel[] => {
    return channels.map((x) => {
        return {
            ...x,
            CreatedDate: x.CreatedDate
                ? moment(x.CreatedDate, ISO8601_DATE_FORMAT)
                : null,
            PinnedAt: x.PinnedAt
                ? moment(x.PinnedAt, ISO8601_DATE_FORMAT)
                : null,
            ChatClearAt: x.ChatClearAt
                ? moment(x.ChatClearAt, ISO8601_DATE_FORMAT)
                : null,
            LastMessageActualDate: x.LastMessageActualDate
                ? moment(x.LastMessageActualDate, FULL_DATE_TIME_FORMAT)
                : null,
            LastMessageTime: x.LastMessageTime
                ? moment.unix(x.LastMessageTime / 1000)
                : null,
            ChannelDisplayName: !isNil((x as any).ChannelName) // old value (not in use now but kept to handle old cached responses)
                ? (x as any).ChannelName
                : x.ChannelDisplayName,
        } as ChannelResponseModel;
    }) as ChannelResponseModel[];
};
