import { FULL_DATE_TIME_FORMAT } from "globals/constants";
import { t } from "i18next";
import { defaultTo, isNil } from "lodash-es";
import moment from "moment";
import {
    Channel,
    ChannelDescriptor,
    Client,
    Member,
    Message,
} from "twilio-chat";
import { AvatarInfo, Optional, sortData, SortOrder } from "../general";
import { ChannelType, ChatMessageType, TwilioChannelUserType } from "./enum";
import { ChannelAttribute, ChannelMessage, MemberAttribute } from "./models";
import { ChannelResponseModel } from "./response";
export function getChannelName(
    name: string = "",
    type: Optional<ChannelType>,
    userType?: TwilioChannelUserType
) {
    if (
        type === ChannelType.BUSINESS_USERS_GROUP ||
        type === ChannelType.BUSINESS_DEFAULT_GROUP
    ) {
        if (userType === TwilioChannelUserType.Parent) {
            name = `${name} (${t("common.parents")})`;
        } else if (userType === TwilioChannelUserType.Employee) {
            name = `${name} (${t("common.employees")})`;
        } else if (userType === TwilioChannelUserType.ParentAndEmployee) {
            name = `${name} (${t("common.all")})`;
        }
    } else if (type === ChannelType.ONE_TO_ONE && !isNil(userType)) {
        // for channel create/edit dialog
        if (userType === TwilioChannelUserType.Parent) {
            name = `${name} (${t("common.userType.Parent")})`;
        } else if (userType === TwilioChannelUserType.Employee) {
            name = `${name} (${t("common.userType.Employee")})`;
        }
        // for both (show none)
    }
    return name;
}
export const getMembersInfoOfGroupsByChannel = (
    members: Member[],
    itemCount?: number
): AvatarInfo[] => {
    const membersInfo: AvatarInfo[] = [];
    for (const memberResponse of members) {
        const memberAttr = memberResponse.attributes as MemberAttribute;
        if (memberAttr && memberAttr.MemberInfo) {
            const memberInfo: AvatarInfo = {
                Avatar: memberAttr.MemberInfo.Avatar,
                Initial: memberAttr.MemberInfo.Initial,
                BackgroundColor: memberAttr.MemberInfo.BackgroundColor,
                BorderColor: memberAttr.MemberInfo.BorderColor,
                Email: memberAttr.MemberInfo.Email,
                Name: memberAttr.MemberInfo.Name,
                Children: memberAttr.MemberInfo.Children,
            };
            membersInfo.push(memberInfo);
        }
    }
    if (itemCount) {
        return membersInfo.slice(0, itemCount);
    }
    return membersInfo;
};
export const getChannelMessage = (
    message?: Message
): Optional<ChannelMessage> => {
    if (isNil(message)) {
        return null;
    }
    return {
        Type:
            message.type === ChatMessageType.MEDIA
                ? ChatMessageType.MEDIA
                : ChatMessageType.TEXT,
        Body: message.body,
        FileName: message.media ? message.media.filename : null,
        Size: message.media ? message.media.size : null,
    };
};

export const getChannelResponseModelObj = async (
    userId: string,
    client: Client,
    channel: Channel,
    userChannelSubscription: Optional<ChannelDescriptor>,
    handleOtherClientUpdate?: (user: any) => void
): Promise<Optional<ChannelResponseModel>> => {
    const channelAttributes = channel.attributes as ChannelAttribute;
    const members = await channel.getMembers();
    if (members == null || members.length == 0) {
        return null;
    }
    const member = members.find((x) => x.identity == userId);
    if (member == null) {
        return null;
    }

    const memberAttributes = member.attributes as MemberAttribute;

    if (memberAttributes.DeleteChat) {
        return null;
    }
    const lastMessage = channel.lastMessage
        ? (await channel.getMessages(1)).items.slice(-1)[0]
        : undefined;

    const channelName = getChannelName(
        channel.friendlyName,
        channelAttributes.ChannelType,
        channelAttributes.ChannelUserType
    );

    const channelObj: ChannelResponseModel = {
        ChannelId: channel.sid,
        LastMessageActualDate:
            channel.lastMessage && moment(channel.lastMessage.dateCreated),
        OtherMemberSid: "",
        MemberSid: member.sid,
        AdminName: memberAttributes.MemberInfo?.Name,
        ChannelOriginalName: channel.friendlyName,
        ChannelDisplayName: channelName,
        MemberCount: userChannelSubscription
            ? userChannelSubscription.membersCount
            : 0,
        ChannelType: channelAttributes.ChannelType,
        ChannelUserType: channelAttributes.ChannelUserType,
        IsPinned: memberAttributes.IsPinned,
        PinnedAt: moment(memberAttributes.PinnedAt),
        ChatClearAt: memberAttributes.ChatClearAt
            ? moment(memberAttributes.ChatClearAt)
            : null,
        OtherMemberIdentityId: "",
        ChannelInfo: [],
        LastMessageTime:
            channel.lastMessage &&
            moment(channel.lastMessage.dateCreated, FULL_DATE_TIME_FORMAT),
        LastMessage: getChannelMessage(lastMessage),
        UnReadMessageCount:
            userChannelSubscription &&
            userChannelSubscription.descriptor.unread_messages_count
                ? userChannelSubscription.descriptor.unread_messages_count
                : 0,
        CreatedDate: moment(channel.dateCreated as string),
    };

    if (channelObj.ChannelType != ChannelType.ONE_TO_ONE) {
        //handling members avatar in case of custom and other groups
        channelObj.MembersDetails = getMembersInfoOfGroupsByChannel(members);

        if (
            channelObj.ChannelType === ChannelType.CUSTOM_GROUP &&
            channelAttributes.ChannelInfo.Avatar != null
        ) {
            channelObj.ChannelInfo = [
                {
                    Avatar: channelAttributes.ChannelInfo.Avatar,
                    BackgroundColor: "",
                    BorderColor: "",
                    Initial: "",
                    Name: channelObj.ChannelDisplayName,
                },
            ];
        } else {
            //handling chat box header member avatars
            //channel must not be one to one
            //it can be custom group with no avatar set
            //it can default groups
            //get at lest 3 members for chat box header
            channelObj.ChannelInfo = channelObj.MembersDetails.slice(0, 3);
        }
    }

    //handling channel avatar and members info in case of one to one
    if (channelObj.ChannelType === ChannelType.ONE_TO_ONE) {
        if (channelObj.ChatClearAt && lastMessage) {
            if (
                moment(channel.lastMessage.dateCreated) <=
                channelObj.ChatClearAt
            ) {
                channelObj.LastMessage = null;
                channelObj.LastMessageTime = null;
            }
        }
        let otherMember: Optional<Member> = members.find(
            (item) => item.identity != userId
        );

        if (otherMember == null) {
            otherMember = member;
        }
        if (otherMember) {
            channelObj.MembersDetails = getMembersInfoOfGroupsByChannel(
                [otherMember],
                1
            );
        }
        const otherUser = await client.getUser(otherMember.identity);
        if (handleOtherClientUpdate) {
            otherUser.on("updated", handleOtherClientUpdate);
        }
        channelObj.OtherMemberIdentityId = otherUser.identity;
        channelObj.OtherMemberSid = otherMember.sid;

        channelObj.IsOnline =
            otherUser.online === null ? false : otherUser.online;
        const otherMembersAttributes =
            otherMember.attributes as MemberAttribute;
        if (otherMembersAttributes && otherMembersAttributes.MemberInfo) {
            channelObj.Avatar = otherMembersAttributes.MemberInfo.Avatar;
            channelObj.BackgroundColor =
                otherMembersAttributes.MemberInfo.BackgroundColor;
            channelObj.BorderColor =
                otherMembersAttributes.MemberInfo.BorderColor;
            channelObj.Initial = otherMembersAttributes.MemberInfo.Initial;
            channelObj.ChannelOriginalName = defaultTo(
                otherMembersAttributes.MemberInfo.Name,
                ""
            );
            channelObj.ChannelDisplayName = channelObj.ChannelOriginalName;
            // getChannelName(
            //     channelObj.ChannelOriginalName,
            //     channelObj.ChannelType,
            //     channelObj.ChannelUserType
            // );
            channelObj.ChannelInfo = [
                {
                    Avatar: otherMembersAttributes.MemberInfo.Avatar,
                    BackgroundColor:
                        otherMembersAttributes.MemberInfo.BackgroundColor,
                    BorderColor: otherMembersAttributes.MemberInfo.BorderColor,
                    Initial: otherMembersAttributes.MemberInfo.Initial,
                    Name: otherMembersAttributes.MemberInfo.Name,
                    Email: otherMembersAttributes.MemberInfo.Email,
                    Children: otherMembersAttributes.MemberInfo.Children,
                },
            ];
        }
    } else {
        //handling channel avatar in case of other than one to one group
        channelObj.Avatar = channelAttributes.ChannelInfo.Avatar;
        channelObj.BackgroundColor =
            channelAttributes.ChannelInfo.BackgroundColor;
        channelObj.BorderColor = channelAttributes.ChannelInfo.BorderColor;
        channelObj.Initial = channelAttributes.ChannelInfo.Initial;
    }
    return channelObj;
};

export const getSortedChannels = (
    channelsList: ChannelResponseModel[]
): ChannelResponseModel[] => {
    // first sort by last message (desc), then for channels without message sort by their name

    return [
        ...sortData(
            channelsList.filter((c) => c.LastMessageActualDate),
            [{ col: "LastMessageActualDate", dir: SortOrder.DESC }]
        ),
        // ...sortData(
        //     channelsList.filter((c) => !c.LastMessageActualDate),
        //     [{ col: "CreatedDate", dir: SortOrder.DESC }]
        // ),
        ...sortData(
            channelsList.filter((c) => !c.LastMessageActualDate),
            [{ col: "ChannelDisplayName", dir: SortOrder.ASC }]
        ),
    ];
};
export const getChannelsList = async (
    client: Optional<Client>,
    businessId: number,
    userId: string,
    onChannelUpdatedHandler: Optional<(channel: any) => void>,
    handleOtherClientUpdate: Optional<(user: any) => void>,
    channelSid?: string
): Promise<ChannelResponseModel[]> => {
    let channels: Channel[] = [];

    if (channelSid && client) {
        channels.push(await client.getChannelBySid(channelSid));
    } else if (client) {
        let subscribedChannels = await client.getSubscribedChannels();
        let firstPage = true;
        do {
            if (subscribedChannels.hasNextPage && !firstPage) {
                subscribedChannels = await subscribedChannels.nextPage();
            }
            firstPage = false;
            channels = [
                ...channels,
                ...subscribedChannels.items.filter((x) => {
                    const attributes = x.attributes as ChannelAttribute;
                    if (attributes.BusinessId === businessId) {
                        return x;
                    }
                }),
            ];
        } while (subscribedChannels.hasNextPage);
    }
    let userChannels: ChannelDescriptor[] = [];
    if (client) {
        let userChannelDescriptor = await client.getUserChannelDescriptors();

        //Checking if there is next page of the channel descriptor
        do {
            if (userChannels.length > 0) {
                userChannelDescriptor = await userChannelDescriptor.nextPage();
            }
            userChannels = [...userChannels, ...userChannelDescriptor.items];
        } while (userChannelDescriptor.hasNextPage);
    }

    const channelsList: ChannelResponseModel[] = [];

    // console.debug("channels loaded, now loading messages...", new Date());
    for (const channel of channels) {
        if (onChannelUpdatedHandler) {
            channel.on("updated", onChannelUpdatedHandler);
        }
        const userChannelSubscription: Optional<ChannelDescriptor> =
            userChannels.find((x) => x.sid == channel.sid);

        const channelObj =
            client &&
            handleOtherClientUpdate &&
            (await getChannelResponseModelObj(
                userId,
                client,
                channel,
                userChannelSubscription,
                handleOtherClientUpdate
            ));
        if (channelObj) {
            channelsList.push(channelObj);
        }
    }
    // console.debug("messages loaded...", new Date());
    return getSortedChannels(channelsList);
};
