import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "localization/i18next";
import { getInitializedValidityState } from "models/general";
import { EditProfileRequest } from "./request";

export function validateEditProfile(
    val: EditProfileRequest,
    state = getInitializedValidityState([], [])
) {
    const addPasswordValidation =
        Boolean(val.OldPassword) ||
        Boolean(val.NewPassword) ||
        Boolean(val.ConfirmPassword);
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("commonErrors.required", {
                    name: i18next.t("userProfile.firstName"),
                }),
            },
        ],
        val.FirstName,
        "FirstName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("commonErrors.required", {
                    name: i18next.t("userProfile.lastName"),
                }),
            },
        ],
        val.LastName,
        "LastName"
    );
    if (addPasswordValidation) {
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.PASSWORD,
                },
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("auth.errorMessages.password.required"),
                },
            ],
            val.OldPassword,
            "OlPassword"
        );
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.PASSWORD,
                },
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("auth.errorMessages.password.required"),
                },
            ],
            val.NewPassword,
            "NewPassword"
        );
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.PASSWORD,
                },
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("auth.errorMessages.password.required"),
                },
            ],
            val.ConfirmPassword,
            "ConfirmPassword"
        );
    }
    return state;
}
