import { ISO8601_DATE_FORMAT } from "globals/constants";
import { getUniqueList } from "globals/helpers/generalHelper";
import { t } from "i18next";
import { parseBusinessShiftModelResponse } from "models/businessShifts";
import { Optional, sortData, SortOrder } from "models/general";
import moment from "moment";
import { BusinessShiftPlanData, ShiftPlanRowTrack } from "./model";
import { BusinessShiftPlanDataResponse } from "./response";
import { defaultTo } from "lodash-es";

export const parseBusinessShiftPlanData = (
    res: BusinessShiftPlanDataResponse
): BusinessShiftPlanData => {
    return {
        ...res,
        Employees: res.Employees.map((x) => ({
            ...x,
            UnAvailableOnDates: x.UnAvailableOnDates.map((d) =>
                moment(d, ISO8601_DATE_FORMAT)
            ),
        })).sort((x, y) => {
            const nameA = defaultTo(x.Name?.toUpperCase(), ""); // ignore upper and lowercase
            const nameB = defaultTo(y.Name?.toUpperCase(), ""); // ignore upper and lowercase

            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        }),
        Shifts: res.Shifts
            ? res.Shifts.map((x) => parseBusinessShiftModelResponse(x))
            : undefined,
        ShiftAssignments: res.ShiftAssignments.map((x) => ({
            ...x,
            Date: moment(x.Date, ISO8601_DATE_FORMAT),
            Shift: x.Shift
                ? parseBusinessShiftModelResponse(x.Shift)
                : undefined,
        })),
    } as BusinessShiftPlanData;
};

// will return the order for showing employees in shift-plan
// so that same order is used in both left employees-info section
// and shift-plan table on right
export function getShiftPlanMappingInfo(
    data: Optional<Omit<BusinessShiftPlanData, "ShiftAssignments">>,
    groupByGroups: boolean = false,
    groupByShifts: boolean = false
): ShiftPlanRowTrack[] {
    const toRet: ShiftPlanRowTrack[] = [];

    if (data) {
        if (!groupByGroups && !groupByShifts) {
            toRet.push({ text: t("common.employees") });
            data.Employees.map((e) => {
                toRet.push({ EmployeeId: e.Id });
            });
        } else {
            const assignedEmployees = getUniqueList([
                ...(groupByShifts && data.Shifts
                    ? data.Shifts.reduce(
                          (res, s) => [...res, ...s.EmployeeIds],
                          [] as number[]
                      )
                    : []),
                ...(groupByGroups && data.Groups
                    ? data.Groups.reduce(
                          (res, g) => [...res, ...g.EmployeeIds],
                          [] as number[]
                      )
                    : []),
            ]);

            const unassignedEmployees = data.Employees.filter(
                (e) => !assignedEmployees.includes(e.Id)
            );

            if (groupByGroups) {
                data.Groups.map((g) => {
                    const groupEmployees = sortData(
                        data.Employees.filter((f) =>
                            g.EmployeeIds.includes(f.Id)
                        ),
                        [{ col: "Name" as any, dir: SortOrder.ASC }]
                    );
                    toRet.push({ text: g.Name, GroupId: g.Id });
                    groupEmployees.map((e) => {
                        toRet.push({ EmployeeId: e.Id, GroupId: g.Id });
                    });
                });
            }
            if (groupByShifts && data.Shifts) {
                data.Shifts.map((s) => {
                    const shiftEmployees = sortData(
                        data.Employees.filter((f) =>
                            s.EmployeeIds.includes(f.Id)
                        ),
                        [{ col: "Name" as any, dir: SortOrder.ASC }]
                    );
                    toRet.push({ text: s.Name, ShiftId: s.Id });
                    shiftEmployees.map((e) => {
                        toRet.push({ EmployeeId: e.Id, ShiftId: s.Id });
                    });
                });
            }

            if (unassignedEmployees.length > 0) {
                toRet.push({ text: t("shiftPlan.unassigned") });
                unassignedEmployees.map((e) => {
                    toRet.push({ EmployeeId: e.Id });
                });
            }
        }
    }
    return toRet;
}
