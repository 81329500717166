export const EmployeeAdditionalBenefitsEnTranslations = {
    employeeAdditionalBenefits: {
        createEdit: {
            createTitle: "Create Additional Benefit",
            editTitle: "Edit Additional Benefit",
            amount: {
                name: "Amount",
            },
            employee: {
                missing: "Employee is required",
                name: "Employee",
            },
            reason: {
                missing: "Reason is required",
                name: "Reason",
            },
        },
        table: {
            id: "ID",
            amount: "Amount",
            employeeName: "Employee Name",
            endDate: "End Date",
            reason: "Reason",
            startDate: "Start Date",
            status: "Status",
            title: "Additional Benefits",
            noBenefitExist: "No benefits exist...",
        },
    },
};

export default EmployeeAdditionalBenefitsEnTranslations;
