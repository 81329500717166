import classNames from "classnames";
import { AppTooltip, ErrorMessage, ValidationTooltip } from "components";
import { AppSelectOld } from "components/AppSelect";
import { AppImageUploaderWithPreview } from "components/File";
import { AppInputField, AuthInputField } from "components/FormFields";
import { stringToAppLocale } from "globals/helpers/localizationHelpers";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash";
import { EditProfileRequest, FieldType, validateEditProfile } from "models";
import {
    AppLocale,
    LocaleEnumToTranslationKey,
    SelectItem,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import React, { useEffect, useMemo } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./EditProfileFields.module.scss";
export interface EditProfileFieldsProps {
    state: EditProfileRequest;
    setState: (value: EditProfileRequest) => void;
    setIsValid: (value: boolean) => void;
    validityState?: ValidityState;
    canChangePassword?: boolean;
}
export const EditProfileFields: React.FC<EditProfileFieldsProps> = ({
    state,
    setState,
    validityState: receivedState,
    canChangePassword = true,
    setIsValid,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const validityStateManager = useMemo(() => {
        const validityState = validateEditProfile(state, receivedState);
        return new ValidityStateManager(validityState);
    }, [state, receivedState]);

    useEffect(() => {
        setIsValid(
            validityStateManager.isStateValid(true, undefined, ["file"])
        );
    }, [validityStateManager.isStateValid()]);

    useEffect(() => {
        if (state && stringToAppLocale(state.Locale) !== appLocale) {
            setState({ ...state, Locale: appLocale });
        }
    }, []);
    const localSelectOption: SelectItem[] = [
        ...Object.keys(LocaleEnumToTranslationKey).map((k) => {
            return {
                Text: t(
                    `common.language.${
                        LocaleEnumToTranslationKey[k as AppLocale]
                    }`
                ),
                Value: k,
            } as SelectItem;
        }),
    ];

    const avatarHelpTooltip = (
        <AppTooltip
            content={
                <span>
                    {t("userProfile.avatar.helpMessage")}
                    {` "`}
                    <strong>250</strong> x <strong>250</strong>
                    {`"`}
                </span>
            }
            allowHtml={true}
            trigger="mouseenter focus"
            arrow={true}
            maxWidth="400px"
        >
            <Image
                className={classNames(styles.avatarHelpIcon, "ml-2")}
                src={ImageAssets.common.questionCircleBlue}
            />
        </AppTooltip>
    );

    return (
        <Row>
            <Col xs={6}>
                <div className="d-flex align-items-center mb-2">
                    <div style={{ fontSize: "1rem" }}>
                        {t("userProfile.manageProfile")}
                    </div>
                </div>
                <AppInputField
                    className={styles.appInputFormGroup}
                    label={t("userProfile.firstName")}
                    value={state.FirstName}
                    onValueChange={(value) =>
                        setState({
                            ...state,
                            FirstName: defaultTo(value, ""),
                        })
                    }
                    error={validityStateManager.getFirstErrorInfo("FirstName")}
                />
                <AppInputField
                    className={styles.appInputFormGroup}
                    label={t("userProfile.lastName")}
                    value={state.LastName}
                    onValueChange={(value) =>
                        setState({
                            ...state,
                            LastName: defaultTo(value, ""),
                        })
                    }
                    error={validityStateManager.getFirstErrorInfo("LastName")}
                />
                <Form.Group>
                    <Form.Label>{t("common.language.name")}</Form.Label>
                    <AppSelectOld
                        menuPosition={"fixed"}
                        menuShouldBlockScroll={true}
                        options={localSelectOption}
                        value={localSelectOption.find(
                            (x) => x.Value === state.Locale
                        )}
                        getOptionLabel={(opt: SelectItem) => opt.Text}
                        getOptionValue={(opt: SelectItem) => opt.Value}
                        onChange={(e: SelectItem) => {
                            setState({
                                ...state,
                                Locale: e.Value as string,
                            });
                        }}
                    />
                </Form.Group>
                <div className="d-flex align-items-center">
                    <Form.Group
                        controlId="avatar"
                        style={{ marginTop: "18px" }}
                    >
                        <Form.Label>
                            {t("userProfile.avatar.name")}
                            {avatarHelpTooltip}
                        </Form.Label>

                        <AppImageUploaderWithPreview
                            avatarUrl={state.Avatar}
                            className={styles.updateAvatar}
                            file={state.fileRequest}
                            onUpload={(file: File) => {
                                setState({ ...state, fileRequest: file });
                            }}
                        />
                    </Form.Group>

                    <div className={styles.avatarRight}>
                        <Form.File
                            type="file"
                            name="file"
                            accept="image/*"
                            className={styles.fileField}
                            onChange={(e: any) => {
                                setState({
                                    ...state,
                                    fileRequest: e.target.files[0],
                                });
                            }}
                        />
                        <ErrorMessage
                            errorInfo={validityStateManager.getAllErrors(
                                "file"
                            )}
                        />
                    </div>
                </div>
            </Col>
            {canChangePassword && (
                <Col xs={6}>
                    <div className="d-flex align-items-center mb-2">
                        <div style={{ fontSize: "1rem" }}>
                            {t("userProfile.changePassword")}
                        </div>
                        <div className="ml-2">
                            <ValidationTooltip type={FieldType.PASSWORD} />
                        </div>
                    </div>
                    <AuthInputField
                        value={state.OldPassword}
                        type="password"
                        disableAutoComplete={true}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                OldPassword: value,
                            })
                        }
                        disabled={!canChangePassword}
                        iconEndClassName={styles.authFiledIconEnd}
                        label={t("userProfile.oldPassword")}
                        fieldClassName={styles.authFields}
                        onBlur={(value) =>
                            setState({
                                ...state,
                                OldPassword: value,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "OldPassword"
                        )}
                    />
                    <AuthInputField
                        value={state.NewPassword}
                        type="password"
                        disableAutoComplete={true}
                        disabled={!canChangePassword}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                NewPassword: value,
                            })
                        }
                        iconEndClassName={styles.authFiledIconEnd}
                        label={t("userProfile.newPassword")}
                        fieldClassName={styles.authFields}
                        onBlur={(value) =>
                            setState({
                                ...state,
                                NewPassword: value,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "NewPassword"
                        )}
                    />
                    <AuthInputField
                        value={state.ConfirmPassword}
                        type="password"
                        disableAutoComplete={true}
                        disabled={!canChangePassword}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                ConfirmPassword: value,
                            })
                        }
                        iconEndClassName={styles.authFiledIconEnd}
                        label={t("userProfile.confirmPassword")}
                        fieldClassName={styles.authFields}
                        onBlur={(value) =>
                            setState({
                                ...state,
                                ConfirmPassword: value,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "ConfirmPassword"
                        )}
                    />
                </Col>
            )}
        </Row>
    );
};

export default EditProfileFields;
