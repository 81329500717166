import { AppContainer } from "components/Containers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    Business_CareScopes,
    LocationSettings_CareScopes,
    LW_Kindergarten_CareScopes,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useCurrentLocationSettingsContext } from "../LocationSettingTabs";
import styles from "commonPartials/commonStyles.module.scss";
import AppIconTextButton from "components/Buttons/AppIconTextButton";
import { CareScopeTemplatesList } from "commonPartials/careScopeTemplate";

export const LocationCareScopeTemplateList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { getPermissionMap } = useCheckPermission();

    const { dataItem: location, refetchData } =
        useCurrentLocationSettingsContext();

    const permMap = getPermissionMap([LocationSettings_CareScopes]);

    const linkProviderBase = linkProvider.lillywait
        .locationSettings()
        .screens.withId().careScopes;

    return (
        <AppContainer classes={{ body: styles.appContainerWithLessTopPadding }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ height: "45px" }}>
                    <AppIconTextButton
                        padding="med"
                        className="mb-2"
                        icon="plus"
                        disabled={!permMap[PermissionAccessTypes.CREATE]}
                        text={t("common.create")}
                        onClick={() => {
                            navigate(linkProviderBase.create());
                        }}
                    />
                </div>
            </div>
            <CareScopeTemplatesList
                currentBusiness={null}
                locationId={location?.Id}
                basePathProvider={linkProviderBase}
                permissionsMap={permMap}
            />
        </AppContainer>
    );
};

export default LocationCareScopeTemplateList;
