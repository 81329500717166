import { AppOverlayLoader } from "components/Loaders";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { MessageTemplateWithStatuses } from "models/messages";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MessageService, {
    getMessageServiceKey,
} from "services/business/MessageService";
import { useMessageLayoutContext } from "./MessagesLayout";
import styles from "./MessagesLayout.module.scss";
import { MessageDetailPartial } from "./partials/messageDetail";

export interface MessageDetailState {
    message: MessageTemplateWithStatuses;
    loading: boolean;
}

export const MessagesDetail: React.FC = () => {
    const { linkProvider } = useRouting();
    const { id } = useParams();
    const showErrorPage = useShowErrorPage();
    const { refetchMessageCount } = useMessageLayoutContext();

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const {
        isLoading: getMessageLoading,
        isRefetching: getMessageRefetching,
        data: template,
    } = useQuery(
        getMessageServiceKey("getMessageTemplate", { messageId: id }),
        async () => {
            if (id) {
                return await messageService.getMessageTemplate(id);
            } else {
                return undefined;
            }
        },
        {
            onError: () => showErrorPage(404),
            cacheTime: getMillisecondsForMinutes(10),
            staleTime: getMillisecondsForMinutes(10),
        }
    );

    const [state, setState] = useState<MessageDetailState>({
        loading: true,
    } as MessageDetailState);

    useEffect(() => {
        if (!getMessageLoading && template) {
            if (template.Data) {
                setState({
                    ...state,
                    message: template.Data,
                    loading: false,
                });
            } else if (template.Errors) {
                setState({
                    ...state,
                    loading: false,
                });
                showErrorPage(404);
            }
        }
    }, [template, getMessageLoading]);

    const setLoading = (val: boolean) => {
        setState({ ...state, loading: val });
    };

    return (
        <div className={styles.cardStyling}>
            {(state.loading || getMessageRefetching || !state.message) && (
                <AppOverlayLoader fullHeight={false} />
            )}
            {state.message && (
                <MessageDetailPartial
                    value={state.message}
                    onChange={(item) => {
                        setState({
                            ...state,
                            loading: false,
                            message: {
                                ...state.message,
                                Message: item,
                            },
                        });
                        refetchMessageCount();
                    }}
                    showLoading={() => setLoading(true)}
                />
            )}
        </div>
    );
};

export default MessagesDetail;
