import IFrameContent from "components/IFrameContent";
import {
    Kindergarten_Client_AccessControl,
    Kindergarten_Client_App,
    Kindergarten_Client_BankData,
    Kindergarten_Client_BasicData,
    Kindergarten_Client_Diseases,
    Kindergarten_Client_Documents,
    Kindergarten_Client_ExternalRegistrations,
    Kindergarten_Client_Family,
    Kindergarten_Client_Groups,
    Kindergarten_Client_Contracts,
    Kindergarten_Client_MedicalData,
    Kindergarten_Client_Vacations,
    PermissionAccessTypes,
} from "models";
import {
    Feature_Kindergarten_Client_AccessControl,
    Feature_Kindergarten_Client_AddUpdate,
    Feature_Kindergarten_Client_App,
    Feature_Kindergarten_Client_BankData,
    Feature_Kindergarten_Client_BankData_AddUpdate,
    Feature_Kindergarten_Client_Diseases,
    Feature_Kindergarten_Client_Diseases_AddUpdate,
    Feature_Kindergarten_Client_Documents,
    Feature_Kindergarten_Client_Family,
    Feature_Kindergarten_Client_Group,
    Feature_Kindergarten_Client_Index,
    Feature_Kindergarten_Client_Contracts,
    Feature_Kindergarten_Client_MedicalData,
    Feature_Kindergarten_Client_Registrations,
    Feature_Kindergarten_Client_Registrations_AddUpdate,
    Feature_Kindergarten_Client_Vacations,
    Feature_Kindergarten_Client_Vacations_AddUpdate,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import {
    ClientBankDataCreate,
    ClientBankDataEdit,
    ClientBankDataList,
    KindergartenClientContractsListScreen,
    KindergartenClientCreate,
    KindergartenClientEdit,
    KindergartenClientEditLayout,
    KindergartenClientsContextProvider,
    KindergartenClientsList,
    KindergartenClientRegistrationMain,
    KindergartenClientRegistrationList,
    KindergartenClientRegistrationEdit,
    KindergartenClientRegistrationCreate,
} from "screens";
import Dummy from "screens/Dummy";
import kindergartenClientRoutes from "./routes";

const relativeRoutes = kindergartenClientRoutes(false).screens;
const fullRoutes = kindergartenClientRoutes().screens;

export const kindergartenClientRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.registrations.list()]: {
        component: KindergartenClientRegistrationMain,
        fullPattern: fullRoutes.registrations.list(),
        pattern: relativeRoutes.registrations.list(),
        permKeys: [Kindergarten_Client_ExternalRegistrations],
        featureKeys: [Feature_Kindergarten_Client_Registrations],
        nestedScreens: {
            [relativeRoutes.registrations.list()]: {
                index: true,
                component: KindergartenClientRegistrationList,
                fullPattern: fullRoutes.registrations.list(),
            },
            [relativeRoutes.registrations.create()]: {
                pattern: relativeRoutes.registrations.create(),
                fullPattern: fullRoutes.registrations.create(),
                component: KindergartenClientRegistrationCreate,
                permKeys: [Kindergarten_Client_ExternalRegistrations],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [
                    Feature_Kindergarten_Client_Registrations_AddUpdate,
                ],
            },
            [relativeRoutes.registrations.edit()]: {
                pattern: relativeRoutes.registrations.edit(),
                fullPattern: fullRoutes.registrations.edit(),
                component: KindergartenClientRegistrationEdit,
                permKeys: [Kindergarten_Client_ExternalRegistrations],
                featureKeys: [
                    Feature_Kindergarten_Client_Registrations_AddUpdate,
                ],
            },
        },
    },

    [relativeRoutes.client.create()]: {
        pattern: relativeRoutes.client.create(),
        fullPattern: fullRoutes.client.create(),
        component: KindergartenClientCreate,
        permKeys: [Kindergarten_Client_BasicData],
        featureKeys: [Feature_Kindergarten_Client_AddUpdate],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.client.list()]: {
        component: KindergartenClientsContextProvider,
        fullPattern: fullRoutes.client.list(),
        pattern: relativeRoutes.client.list(),
        permKeys: [Kindergarten_Client_BasicData],
        featureKeys: [Feature_Kindergarten_Client_Index],
        nestedScreens: {
            [relativeRoutes.client.list()]: {
                index: true,
                component: KindergartenClientsList,
                fullPattern: fullRoutes.client.list(),
            },
            [relativeRoutes.client.withId().base()]: {
                pattern: relativeRoutes.client.withId().base(),
                fullPattern: fullRoutes.client.withId().base(),
                component: KindergartenClientEditLayout,
                permKeys: [Kindergarten_Client_BasicData],
                featureKeys: [Feature_Kindergarten_Client_AddUpdate],
                nestedScreens: {
                    [relativeRoutes.client.withId().edit()]: {
                        component: KindergartenClientEdit,
                        index: true,
                        pattern: relativeRoutes.client.withId().edit(),
                        fullPattern: fullRoutes.client.withId().edit(),
                        permKeys: [Kindergarten_Client_BasicData],
                        featureKeys: [Feature_Kindergarten_Client_AddUpdate],
                        permAccessTypes: [PermissionAccessTypes.SHOW],
                    },
                    [relativeRoutes.client.withId().contracts()]: {
                        component: KindergartenClientContractsListScreen,
                        fullPattern: fullRoutes.client.withId().contracts(),
                        pattern: relativeRoutes.client.withId().contracts(),
                        permKeys: [Kindergarten_Client_Contracts],
                        featureKeys: [Feature_Kindergarten_Client_Contracts],
                    },
                    [relativeRoutes.client.withId().app()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().app(),
                        pattern: relativeRoutes.client.withId().app(),
                        permKeys: [Kindergarten_Client_App],
                        featureKeys: [Feature_Kindergarten_Client_App],
                    },
                    [relativeRoutes.client.withId().medicalData()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().medicalData(),
                        pattern: relativeRoutes.client.withId().medicalData(),
                        permKeys: [Kindergarten_Client_MedicalData],
                        featureKeys: [Feature_Kindergarten_Client_MedicalData],
                    },
                    [relativeRoutes.client.withId().careGroups()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().careGroups(),
                        pattern: relativeRoutes.client.withId().careGroups(),
                        permKeys: [Kindergarten_Client_Groups],
                        featureKeys: [Feature_Kindergarten_Client_Group],
                    },
                    [relativeRoutes.client.withId().accessControl()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().accessControl(),
                        pattern: relativeRoutes.client.withId().accessControl(),
                        permKeys: [Kindergarten_Client_AccessControl],
                        featureKeys: [
                            Feature_Kindergarten_Client_AccessControl,
                        ],
                    },
                    [relativeRoutes.client.withId().diseases.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().diseases.list(),
                        pattern: relativeRoutes.client.withId().diseases.list(),
                        permKeys: [Kindergarten_Client_Diseases],
                        featureKeys: [Feature_Kindergarten_Client_Diseases],
                    },
                    [relativeRoutes.client.withId().diseases.create()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client
                            .withId()
                            .diseases.create(),
                        pattern: relativeRoutes.client
                            .withId()
                            .diseases.create(),
                        permKeys: [Kindergarten_Client_Diseases],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Kindergarten_Client_Diseases_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().diseases.edit()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client.withId().diseases.edit(),
                        pattern: relativeRoutes.client.withId().diseases.edit(),
                        permKeys: [Kindergarten_Client_Diseases],
                        featureKeys: [
                            Feature_Kindergarten_Client_Diseases_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().vacations.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client
                            .withId()
                            .vacations.list(),
                        pattern: relativeRoutes.client
                            .withId()
                            .vacations.list(),
                        permKeys: [Kindergarten_Client_Vacations],
                        featureKeys: [Feature_Kindergarten_Client_Vacations],
                    },
                    [relativeRoutes.client.withId().vacations.create()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client
                            .withId()
                            .vacations.create(),
                        pattern: relativeRoutes.client
                            .withId()
                            .vacations.create(),
                        permKeys: [Kindergarten_Client_Vacations],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Kindergarten_Client_Vacations_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().vacations.edit()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client
                            .withId()
                            .vacations.edit(),
                        pattern: relativeRoutes.client
                            .withId()
                            .vacations.edit(),
                        permKeys: [Kindergarten_Client_Vacations],
                        featureKeys: [
                            Feature_Kindergarten_Client_Vacations_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().family.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client.withId().family.list(),
                        pattern: relativeRoutes.client.withId().family.list(),
                        permKeys: [Kindergarten_Client_Family],
                        featureKeys: [Feature_Kindergarten_Client_Family],
                    },
                    [relativeRoutes.client.withId().family.create()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client.withId().family.create(),
                        pattern: relativeRoutes.client.withId().family.create(),
                        permKeys: [Kindergarten_Client_Family],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [Feature_Kindergarten_Client_Family],
                    },
                    [relativeRoutes.client.withId().family.edit()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client.withId().family.edit(),
                        pattern: relativeRoutes.client.withId().family.edit(),
                        permKeys: [Kindergarten_Client_Family],
                        featureKeys: [Feature_Kindergarten_Client_Family],
                    },
                    [relativeRoutes.client.withId().bankData.list()]: {
                        component: ClientBankDataList,
                        fullPattern: fullRoutes.client.withId().bankData.list(),
                        pattern: relativeRoutes.client.withId().bankData.list(),
                        permKeys: [Kindergarten_Client_BankData],
                        featureKeys: [Feature_Kindergarten_Client_BankData],
                    },
                    [relativeRoutes.client.withId().bankData.create()]: {
                        component: ClientBankDataCreate,
                        fullPattern: fullRoutes.client
                            .withId()
                            .bankData.create(),
                        pattern: relativeRoutes.client
                            .withId()
                            .bankData.create(),
                        permKeys: [Kindergarten_Client_BankData],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Kindergarten_Client_BankData_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().bankData.edit()]: {
                        component: ClientBankDataEdit,
                        fullPattern: fullRoutes.client.withId().bankData.edit(),
                        pattern: relativeRoutes.client.withId().bankData.edit(),
                        permKeys: [Kindergarten_Client_BankData],
                        featureKeys: [
                            Feature_Kindergarten_Client_BankData_AddUpdate,
                        ],
                    },
                    [relativeRoutes.client.withId().documents.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.client
                            .withId()
                            .documents.list(),
                        pattern: relativeRoutes.client
                            .withId()
                            .documents.list(),
                        permKeys: [Kindergarten_Client_Documents],
                        featureKeys: [Feature_Kindergarten_Client_Documents],
                    },
                    [relativeRoutes.client
                        .withId()
                        .documents.fillEditableSections()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.client
                            .withId()
                            .documents.fillEditableSections(),
                        pattern: relativeRoutes.client
                            .withId()
                            .documents.fillEditableSections(),
                        permKeys: [Kindergarten_Client_Documents],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [Feature_Kindergarten_Client_Documents],
                    },
                },
            },
        },
    },
};
