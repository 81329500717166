import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EditProfileDialog } from "screens";
import { Logout } from "./Logout";
import styles from "./UserInfoDialog.module.scss";
export interface UserInfoPopupMenuProps {
    userName: string;
    userAvatar: string;
    closeMenu: () => void;
}

export const UserInfoPopupMenu: React.FC<UserInfoPopupMenuProps> = ({
    userName,
    userAvatar,
    closeMenu,
}) => {
    const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
    const { t } = useTranslation();
    const openDialog = () => {
        setOpenEditProfileDialog(true);
    };
    const closeDialog = () => {
        setOpenEditProfileDialog(false);
    };
    return (
        <>
            {openEditProfileDialog && (
                <EditProfileDialog
                    close={closeDialog}
                    open={openEditProfileDialog}
                />
            )}
            <div className={`${styles.root} shadow  `}>
                <div className={styles.title}>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ width: "100%" }}
                    >
                        <div className="d-flex align-items-center">
                            <Image
                                roundedCircle
                                src={userAvatar}
                                className={styles.avatarImg}
                            />
                            <div className={styles.userName}>
                                <span>{userName}</span>
                            </div>
                        </div>
                        <Logout />
                    </div>
                </div>
                <div className={styles.account}>
                    <span
                        onClick={() => {
                            closeMenu();
                            openDialog();
                        }}
                        style={{
                            cursor: "pointer",
                            textTransform: "uppercase",
                        }}
                    >
                        {t("header.myAccount")}
                    </span>
                </div>
            </div>
        </>
    );
};

export default UserInfoPopupMenu;
