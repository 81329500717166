/* eslint-disable @typescript-eslint/no-unused-vars */
import { EventApi } from "@fullcalendar/core";
import classNames from "classnames";
import { EmployeeClientFiltersComponent } from "commonPartials";
import { AppContentHeader, FullCalendarComponent } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { momentFromJSLocalDateTime } from "globals/helpers/localizationHelpers";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil, toNumber } from "lodash-es";
import {
    BusinessType,
    Business_Calendar,
    Business_Calendar_Pivot,
    PermissionAccessTypes,
} from "models";
import {
    CalendarEventTemplate,
    CalendarPivotEvent,
    CalendarPivotResource,
    getParsedCalendarEvent,
    mapDurationItemsToCalendarItems,
} from "models/calendar";
import { CalendarPivotFilters } from "models/calendar/request";
import { DateRangeRequest, Optional, UserFilterType } from "models/general";
import moment, { Moment } from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
    BusinessGeneralService,
    CalendarService,
    getBusinessGeneralServiceKey,
    getCalendarServiceKey,
} from "services/business";
import styles from "./Calendar.module.scss";
import CalendarEventDialog, {
    CalendarEventDialogProps,
} from "./partials/CalendarEventDialog";

const CALENDAR_PARAM_VIEW_KEY = "view";
const CALENDAR_PARAM__DATE_KEY = "date";
export function Calendar() {
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const { sessionBusiness } = useSessionBusiness();
    const [searchParams, setSearchParams] = useSearchParams();

    const currentView = searchParams.get(CALENDAR_PARAM_VIEW_KEY);
    const initialDate = searchParams.get(CALENDAR_PARAM__DATE_KEY);
    const initialParams = useRef<
        { date?: Optional<Date>; view?: Optional<string> } | undefined
    >({
        view: currentView,
        date: initialDate ? new Date(initialDate) : new Date(),
    });

    const businessType = sessionBusiness?.Type;

    const [modalState, setModalState] = useState<
        Partial<CalendarEventDialogProps>
    >({
        modalOpen: false,
    });

    const [calendarState, updateCalendarState] = useState<{
        resources: CalendarPivotResource[];
        events: (CalendarPivotEvent | CalendarEventTemplate)[];
        isPivot: boolean;
    }>({
        resources: [],
        events: [] as CalendarEventTemplate[],
        isPivot: false,
    });

    const [loaded, setLoaded] = useState<boolean>(true);
    const calendarService = new CalendarService(
        linkProvider.business.api.calendar
    );
    const businessService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const [filter, updateFilter] = useState<DateRangeRequest>({
        EndDate: null,
        StartDate: null,
    });

    const [pivotFilter, updatePivotFilter] = useState<CalendarPivotFilters>({
        EndDate: null,
        StartDate: null,
        ResourceTypes: null,
        ClientIds: null,
        EmployeeIds: null,
        GroupIds: null,
    });

    const hasCreatePermission = checkPermission(Business_Calendar, [
        PermissionAccessTypes.CREATE,
    ]);
    const hasEditPermission = checkPermission(Business_Calendar, [
        PermissionAccessTypes.EDIT,
    ]);

    const openEventDialog = (date?: Date, eventId?: string) => {
        setModalState({
            date: date ? momentFromJSLocalDateTime(date) : null,
            modalOpen: true,
            templateId: eventId,
        });
    };
    const closeEventDialog = () => {
        setModalState({
            modalOpen: false,
            date: null,
            templateId: null,
        });
    };

    const calendarRef = useRef(null);

    const {
        isFetching: loading,
        data: calendarEvents,
        refetch: refetchEvents,
    } = useQuery(
        getCalendarServiceKey(
            "getEvents" // don't add filter in key because I need to refetch manually
        ),
        async () => await calendarService.getEvents(filter),
        {
            enabled: false,
            select: (res) => res.Data.map((x) => getParsedCalendarEvent(x)),
        }
    );
    const { isFetching: loadingHolidays, data: holidays } = useQuery(
        getBusinessGeneralServiceKey(
            "getBusinessHolidays" // don't add filter in key because I need to refetch manually
        ),
        async () => await businessService.getBusinessHolidays(),
        {
            enabled: true,
        }
    );
    const {
        isFetching: loadingPivot,
        data: pivotResponse,
        refetch: refetchPivotDetails,
    } = useQuery(
        getCalendarServiceKey("getPivotInfo", JSON.stringify(pivotFilter)),
        async () => await calendarService.getPivotInfo(pivotFilter),
        { enabled: false }
    );

    useEffect(() => {
        if (!loading && calendarEvents) {
            updateCalendarState({
                resources: [],
                events: calendarEvents,
                isPivot: false,
            });
            setLoaded(true);
        }
    }, [loading]);

    useEffect(() => {
        if (!loadingPivot && pivotResponse && pivotResponse.Data) {
            const data = pivotResponse.Data;
            const resources = data.map((i) => {
                return {
                    id: `${i.Type}-${i.Id.toString()}`,
                    sortTitle: `${
                        // need to first show employees in sorted order, then clients in sorted order
                        i.Type == UserFilterType.Client
                            ? "kinder"
                            : i.Type == UserFilterType.Supplier
                            ? "empSup"
                            : "emp"
                    }-${i.Info.Name}`,
                    title: i.Info.Name,
                    type: i.Type,
                } as CalendarPivotResource;
            });

            const events = data.reduce(
                (result: CalendarPivotEvent[], currentItem) => {
                    const uniqueId = `${
                        currentItem.Type
                    }-${currentItem.Id.toString()}`;
                    result = [
                        ...result,
                        ...mapDurationItemsToCalendarItems(
                            currentItem.Diseases,
                            uniqueId
                        ),
                        ...mapDurationItemsToCalendarItems(
                            currentItem.Vacations,
                            uniqueId
                        ),
                        ...(currentItem.SchoolDays
                            ? mapDurationItemsToCalendarItems(
                                  currentItem.SchoolDays,
                                  uniqueId
                              )
                            : []),
                    ];
                    return result;
                },
                []
            );
            updateCalendarState({
                resources: resources,
                events: events,
                isPivot: true,
            });
            setLoaded(true);
        }
    }, [loadingPivot]);

    useEffect(() => {
        if (filter && filter.StartDate) {
            refetchEvents();
            setLoaded(false);
        }
    }, [filter]);

    useEffect(() => {
        if (pivotFilter && pivotFilter.StartDate) {
            refetchPivotDetails();
            setLoaded(false);
        }
    }, [pivotFilter]);

    const hasPivotAccess = checkPermission(Business_Calendar_Pivot);

    const isLoading = loading || loadingPivot || !loaded;

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t(`calendar.calendar`)}
                classNameForIcon="pe-7s-date"
            >
                {hasCreatePermission && (
                    <AppRoundedTextIconButton
                        className={classNames(styles.createBtn)}
                        text={t(`calendar.createButton`)}
                        onClick={() => openEventDialog()}
                    />
                )}
            </AppContentHeader>

            <div
                className={classNames(styles.calendarContainer, {
                    [styles.pivotView]: calendarState.isPivot,
                })}
            >
                {calendarState.isPivot && (
                    <div className="row">
                        <div className="col-sm-12">
                            <EmployeeClientFiltersComponent
                                allowedUserTypes={
                                    businessType != BusinessType.KINDERGARTEN
                                        ? [
                                              UserFilterType.Employee,
                                              UserFilterType.Supplier,
                                          ]
                                        : undefined // default
                                }
                                useColoredTypes={true}
                                value={{
                                    clientIds: pivotFilter.ClientIds,
                                    employeeIds: pivotFilter.EmployeeIds,
                                    groupIds: pivotFilter.GroupIds,
                                    userType:
                                        pivotFilter.ResourceTypes as UserFilterType[],
                                }}
                                forDate={defaultTo(
                                    pivotFilter.StartDate,
                                    moment()
                                )}
                                onChange={(newFilters) => {
                                    updatePivotFilter((filters) => ({
                                        ...filters,
                                        ClientIds: newFilters.clientIds,
                                        EmployeeIds: newFilters.employeeIds,
                                        GroupIds: newFilters.groupIds,
                                        ResourceTypes:
                                            newFilters.userType as string[],
                                    }));
                                }}
                            />
                        </div>
                    </div>
                )}
                <FullCalendarComponent
                    ref={calendarRef}
                    loading={isLoading}
                    onDateClick={(event) => {
                        const clickedDate: Date = event.date;
                        openEventDialog(clickedDate);
                    }}
                    initialView={initialParams.current?.view?.toString()}
                    holidays={defaultTo(holidays && holidays.Data, [])}
                    isPivot={calendarState.isPivot}
                    onDatesChange={(newFilter, isPivot: boolean) => {
                        if (
                            (!newFilter.StartDate?.isSame(filter.StartDate) ||
                                !newFilter.EndDate?.isSame(filter.EndDate) ||
                                calendarState.isPivot) &&
                            !isPivot
                        ) {
                            updateCalendarState({
                                ...calendarState,
                                isPivot: false,
                            });
                            updateFilter(newFilter);
                        }

                        if (
                            (!newFilter.StartDate?.isSame(
                                pivotFilter.StartDate
                            ) ||
                                !newFilter.EndDate?.isSame(
                                    pivotFilter.EndDate
                                ) ||
                                !calendarState.isPivot) &&
                            isPivot
                        ) {
                            //for pivot
                            updatePivotFilter((filters) => ({
                                ...filters,
                                EndDate: newFilter.EndDate,
                                StartDate: newFilter.StartDate,
                            }));
                            updateCalendarState({
                                ...calendarState,
                                isPivot: true,
                            });
                        }
                    }}
                    resources={isLoading ? [] : calendarState.resources}
                    events={isLoading ? [] : calendarState.events}
                    showPivot={hasPivotAccess}
                    initialDate={defaultTo(
                        initialParams.current?.date,
                        new Date()
                    )}
                    updateQueryParam={(tabView: string, date: Moment) =>
                        setSearchParams({
                            ...searchParams,
                            view: tabView,
                            date: date.format(ISO8601_DATE_FORMAT),
                        })
                    }
                    onEventClick={(eventObj: EventApi) => {
                        openEventDialog(undefined, eventObj.id);
                    }}
                />
            </div>
            {modalState.modalOpen && (
                <CalendarEventDialog
                    modalOpen={modalState.modalOpen}
                    date={modalState.date}
                    businessType={defaultTo(
                        businessType,
                        BusinessType.KINDERGARTEN
                    )}
                    readonly={
                        (modalState.templateId && !hasEditPermission) ||
                        (isNil(modalState.templateId) && !hasCreatePermission)
                    }
                    templateId={modalState.templateId}
                    onChange={(template: CalendarEventTemplate) => {
                        const index = calendarState.events.findIndex(
                            (x) => x.Id == template.Id
                        );
                        if (index >= 0) {
                            const events = (
                                calendarState.events as CalendarEventTemplate[]
                            ).map((e: CalendarEventTemplate) => {
                                if (e.Id === template.Id) {
                                    return template;
                                } else {
                                    return e;
                                }
                            });
                            updateCalendarState({
                                ...calendarState,
                                events: events,
                            });
                        } else {
                            updateCalendarState({
                                ...calendarState,
                                events: [...calendarState.events, template],
                            });
                        }

                        closeEventDialog();
                    }}
                    onDelete={(templateId: string) => {
                        const isNum = parseInt(templateId);

                        closeEventDialog();
                        updateCalendarState({
                            ...calendarState,
                            events: calendarState.events.filter((e) => {
                                return (
                                    (!isNum && e.EncodedId !== templateId) ||
                                    (isNum && e.Id !== toNumber(templateId))
                                );
                            }),
                        });
                    }}
                    onClose={() => closeEventDialog()}
                />
            )}
        </div>
    );
}

export default Calendar;
