import React, { forwardRef, Ref, useRef } from "react";
import ReactSelect, { components } from "react-select";
import { v4 as uuid } from "uuid";
import { CustomSelectOld } from "./partials";
import CrossIndicator from "./partials/CrossIndicator";
import { ComposedSelect, SelectProps, SimpleOption } from "./partials/types";

const ReactSelectComponents = components as any;
export type AppSelectOldProps<OptionType = SimpleOption> = ComposedSelect<
    SelectProps<OptionType>,
    OptionType
> & { showCustomClearIndicator?: boolean };

export const CustomSelectInput = (props: any) => {
    const uuidStr = uuid();
    return (
        <ReactSelectComponents.Input
            {...props}
            // id={uuidStr}
            autoComplete={uuidStr}
            type="text"
        />
    );
};
function Component(props: AppSelectOldProps) {
    return (
        <CustomSelectOld
            isClearable={
                props.showDropdownIndicator
                    ? false
                    : props.showCustomClearIndicator
            }
            components={{
                Input: CustomSelectInput,
                ClearIndicator: props.showCustomClearIndicator
                    ? (indicationProps: any) => (
                          <CrossIndicator
                              {...indicationProps}
                              onChange={props.onChange}
                          />
                      )
                    : null,
            }}
            onReload={() => {}}
            {...props}
            Component={ReactSelect}
        />
    );
}

export const AppSelectOld = Component;
export default AppSelectOld;
