import { AppDeleteButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import {
    AppTable,
    appTableFilterSelectStyles,
    AppTableSkeleton,
} from "components/Table";
import { getFixedCssWidths, isNumber } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Business_Ipads, LW_Kindergarten_IPads } from "models";
import {
    AppQrCodeType,
    getQrCodeDefaultValues,
    parseQrCodeResponse,
    QRCode,
    SelectItem,
} from "models/general";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import styles from "commonPartials/commonStyles.module.scss";
import { useTranslation } from "react-i18next";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { BusinessDeviceStatus, BusinessIPad } from "models/businessIpad";
import BusinessIpadService, {
    getBusinessIpadServiceKey,
} from "services/business/BusinessIpadService";
import { AppSelectOld } from "components/AppSelect";
import { BusinessIPadEditDialog } from "screens/business/settingTabs/iPads/BusinessIPadEditDialog";
import AppQrCodeSection from "commonPartials/AppQrCodeSection";
import { useWebNotificationHub } from "hooks/layout/useWebNotificationHub";
import { WebNotificationType } from "models/notification";

interface BusinessIPadsState {
    statusFilter?: BusinessDeviceStatus;
    list: BusinessIPad[];
    qrCode: QRCode | null;
    openDialog: boolean;
    guid?: string; //in case of edit
}

const COLUMNS_COUNT = 3;
export const BusinessIPadsList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useBusinessCheckPermission();
    const { t } = useTranslation();
    const { lwcId } = useParams();
    const { signalR } = useWebNotificationHub();
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();
    const isLwc = !isNil(lwcId);
    const {
        id: sessionBusinessId,
        encodedId,
        hqId: sessionHqId,
    } = useSessionBusiness();
    const permMap = !isLwc
        ? getPermissionMap(
              currentBusiness?.Id as number,
              currentBusiness?.HqId as number,
              Business_Ipads
          )
        : getPermissionMap(
              sessionBusinessId,
              sessionHqId,
              LW_Kindergarten_IPads
          );

    const [state, setState] = useState<BusinessIPadsState>({
        list: [],
        openDialog: false,
        qrCode: null,
    });

    const ipadService = new BusinessIpadService(
        linkProvider.business.api.currentBusiness(lwcId).ipads
    );

    const {
        isFetching: loading,
        data,
        refetch,
    } = useQuery(
        getBusinessIpadServiceKey("list", {
            businessId: currentBusiness?.Id,
            status: state.statusFilter,
        }),
        async () => await ipadService.list(state.statusFilter)
    );

    // useEffect(() => {
    //     refetch();
    // }, [state.statusFilter]);

    useEffect(() => {
        if (!loading && data && data.Data) {
            setState({
                ...state,
                list: data.Data.Ipads,
                qrCode: !isNil(data.Data.QRCode)
                    ? parseQrCodeResponse(data.Data.QRCode as any)
                    : getQrCodeDefaultValues(currentBusiness?.Id as number),
            });
        }
    }, [loading, data]);

    const { statusOptions, allOption } = useMemo(() => {
        const allOption: SelectItem = {
            Text: t("common.all"),
            Value: null,
        };
        return {
            allOption,
            statusOptions: Object.values(BusinessDeviceStatus)
                .filter((x) => !isNumber(x))
                .map((x) => {
                    return {
                        Text: t(`business.ipads.status.${x.toString()}`),
                        Value: BusinessDeviceStatus[x as any],
                    } as SelectItem;
                }),
        };
    }, [t]);
    const { mutate: deleteIpad, isLoading: deleting } = useMutation(
        async (guid: string) => await ipadService.delete(guid),
        {
            onSuccess: (res) => {
                const toDelete = state.list.filter(
                    (x) => x.Guid == res.Data
                )[0];
                showSweetAlertToast(
                    t("common.success"),
                    `"${toDelete.Name}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
                setState((old) => ({
                    ...old,
                    list: old.list.filter((x) => x.Guid != res.Data),
                }));
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );
    const { mutate: updateIpad } = useMutation(
        async (value: BusinessIPad) => {
            return await ipadService.updateIpad(value);
        },
        {
            onSuccess: (res) => {
                const found = state.list.filter((x) => x.Guid == res.Data.Guid);

                let newList = state.list;
                if (found.length > 0) {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${res.Data.Name}' ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );

                    if (
                        isNil(state.statusFilter) ||
                        res.Data.Status == state.statusFilter
                    ) {
                        newList = state.list.map((x) => {
                            if (x.Guid == res.Data.Guid) {
                                return res.Data;
                            } else {
                                return x;
                            }
                        });
                    } else {
                        newList = newList.filter(
                            (x) => x.Guid != res.Data.Guid
                        );
                    }
                }

                setState((old) => ({
                    ...old,
                    openDialog: false,
                    guid: undefined,
                    list: newList,
                }));
            },
        }
    );

    useEffect(() => {
        if (signalR) {
            signalR.on("notificationReceived", (notification: any) => {
                //any applies because signalR gives objects in camel case in response
                if (
                    currentBusiness?.Id == notification.businessId &&
                    notification.notificationType ==
                        WebNotificationType.IpadRegister
                ) {
                    refetch();
                }
            });
        }
    }, []);

    const StatusSelect = useMemo(() => {
        return ({
            onChange,
            value,
            showClear = false,
        }: {
            onChange: (e: SelectItem) => void;
            value?: BusinessDeviceStatus;
            showClear?: boolean;
        }) => {
            return (
                <AppSelectOld
                    showDropdownIndicator={!showClear || isNil(value)}
                    showCustomClearIndicator={showClear}
                    menuPortalTarget={document.body}
                    options={
                        showClear
                            ? [allOption, ...statusOptions] // for filter header
                            : statusOptions
                    }
                    styles={{
                        ...appTableFilterSelectStyles,
                        singleValue: !isNil(value)
                            ? {
                                  color: "white !important",
                              }
                            : {},
                        indicatorsContainer: !isNil(value)
                            ? {
                                  "> div > svg": {
                                      color: "white !important",
                                  },
                              }
                            : {},
                        control: {
                            ...appTableFilterSelectStyles.control,
                            background:
                                value == BusinessDeviceStatus.Approved
                                    ? "#2fc47d" //green
                                    : value == BusinessDeviceStatus.Pending
                                    ? "#ffcc00" //yellow
                                    : !isNil(value)
                                    ? "#ff5353" //red
                                    : "inherit",
                            borderRadius: "4px",
                        },
                    }}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        isNil(value)
                            ? allOption
                            : statusOptions.find((x) => x.Value === value)
                    }
                    onChange={onChange}
                />
            );
        };
    }, [statusOptions, allOption]);
    return (
        <div>
            <AppContainer
                heightToAdjust={250}
                classes={{ body: styles.appContainerWithLessTopPadding }}
            >
                {state.openDialog && state.guid && (
                    <BusinessIPadEditDialog
                        value={
                            state.list.find(
                                (x) => x.Guid == state.guid
                            ) as BusinessIPad
                        }
                        statusOptions={statusOptions.filter(
                            (x) => !isNil(x.Value)
                        )}
                        readonly={!permMap.EDIT}
                        dialogOpen={state.openDialog}
                        onDialogClose={() =>
                            setState({
                                ...state,
                                openDialog: false,
                                guid: undefined,
                            })
                        }
                        onSave={(val: BusinessIPad) => updateIpad(val)}
                    />
                )}

                {state.qrCode && (
                    <div className={"d-flex justify-content-center mb-3"}>
                        <AppQrCodeSection
                            permMap={permMap}
                            businessId={
                                (currentBusiness
                                    ? currentBusiness?.EncodedId
                                    : encodedId) as string
                            }
                            forArea={AppQrCodeType.BUSINESS}
                            value={state.qrCode}
                            personInfo={null}
                        />
                    </div>
                )}
                <div>
                    <AppTable
                        // heightToAdjust={490}
                        // mobileViewAdjustment={50}
                        // mediumViewAdjustment={50}
                        hover={true}
                    >
                        <thead>
                            <tr>
                                <th>{t("business.ipads.name")}</th>
                                <th style={getFixedCssWidths(200)}>
                                    {t("business.ipads.status.name")}
                                </th>
                                <th style={getFixedCssWidths(60)}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="filters-row">
                                <td></td>
                                <td>
                                    <StatusSelect
                                        value={state.statusFilter}
                                        showClear={true}
                                        onChange={(e: SelectItem) => {
                                            if (e) {
                                                setState({
                                                    ...state,
                                                    statusFilter: defaultTo(
                                                        e.Value as BusinessDeviceStatus,
                                                        undefined
                                                    ),
                                                });
                                            }
                                        }}
                                    />
                                </td>
                                <td></td>
                            </tr>

                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                            {state.list?.length > 0 ? (
                                state.list.map((ipad) => (
                                    <tr key={ipad.Guid}>
                                        <td
                                            style={{ paddingLeft: "10px" }}
                                            onClick={() => {
                                                if (permMap.EDIT && ipad.Guid) {
                                                    setState({
                                                        ...state,
                                                        guid: ipad.Guid,
                                                        openDialog: true,
                                                    });
                                                }
                                            }}
                                            className={
                                                "ellipsis-text single-line"
                                            }
                                        >
                                            <span>{ipad.Name}</span>
                                        </td>
                                        <td>
                                            <StatusSelect
                                                value={ipad.Status}
                                                onChange={(e: SelectItem) => {
                                                    if (e) {
                                                        updateIpad({
                                                            ...ipad,
                                                            Status: defaultTo(
                                                                e.Value as BusinessDeviceStatus,
                                                                BusinessDeviceStatus.Pending
                                                            ),
                                                        });
                                                    }
                                                }}
                                            />

                                            {/* <span
                                                className={styles.textEllipse}
                                            >
                                                {
                                                    defaultTo(
                                                        statusOptions.find(
                                                            (o) =>
                                                                o.Value ==
                                                                ipad.Status
                                                        ),
                                                        { Text: "" }
                                                    ).Text
                                                }
                                            </span> */}
                                        </td>
                                        <td>
                                            <AppDeleteButton
                                                getConfirmation={true}
                                                name={ipad.Name}
                                                readonly={
                                                    !permMap.DELETE || deleting
                                                }
                                                onClick={() =>
                                                    deleteIpad(ipad.Guid)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : loading ? (
                                <AppTableSkeleton
                                    columns={COLUMNS_COUNT}
                                    rows={10}
                                />
                            ) : (
                                <tr>
                                    <td
                                        colSpan={COLUMNS_COUNT}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t("business.ipads.noIPadExist")}
                                    </td>
                                </tr>
                            )}
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                        </tbody>
                    </AppTable>
                </div>
            </AppContainer>
        </div>
    );
};
export default BusinessIPadsList;
