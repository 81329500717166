import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppFilesListComponent, FilesListAndRequest } from "components/File";
import { AppCheckbox } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash";
import {
    ClientRegistrationAdditionalInfo,
    getKindergartenClientRegistrationFromResponse,
    KindergartenClientRegistration,
} from "models/kindergartenClientRegistration";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useOutletContext } from "react-router";
import { KindergartenClientRegistrationOutletContext } from "screens/kindergarten/clientRegistration/KindergartenClientRegistrationMain";
import { KindergartenClientRegistrationService } from "services/kindergarten/KindergartenClientRegistrationService";

export interface ClientRegistrationEditAdditionalInfoProps {
    value: KindergartenClientRegistration;
    onChange: (client: KindergartenClientRegistration) => void;
}

export const ClientRegistrationEditAdditionalInfo: React.FC<
    ClientRegistrationEditAdditionalInfoProps
> = ({ onChange, value }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();

    const { permMap } =
        useOutletContext<KindergartenClientRegistrationOutletContext>();
    const clientRegistrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );

    const hasEditPermission = permMap.EDIT;

    const [state, setState] = useState<ClientRegistrationAdditionalInfo>({
        MigrationBackground: value.MigrationBackground,
        PlaceChange: value.PlaceChange,
        ArrangedPlace: value.ArrangedPlace,
        IsSibling: value.IsSibling,
        Files: value.Files,
        Note: value.Note,
    });

    useEffect(() => {
        // on update
        setState({ ...state, Files: value.Files, files: undefined });
    }, [value.Files]);

    const {
        isLoading: loadingCreate,
        data: createResponse,
        mutate: addUpdate,
    } = useMutation(
        async (val: KindergartenClientRegistration) =>
            await clientRegistrationService.createUpdate(val),
        {
            onSuccess: (resp) => {
                if (resp.Data) {
                    onChange(
                        getKindergartenClientRegistrationFromResponse(resp.Data)
                    );
                }
            },
        }
    );

    const {
        isLoading: loadingDeleteFile,
        data: deleteFileResponse,
        mutate: deleteFile,
    } = useMutation(
        async (id: number) => await clientRegistrationService.deleteFile(id)
    );

    useEffect(() => {
        if (!loadingDeleteFile && deleteFileResponse) {
            const deletedFile = state.Files.find(
                (x) => x.Id == deleteFileResponse.Data
            );

            if (deletedFile) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${deletedFile.OriginalName}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
            }
            const newState = {
                ...state,
                Files: state.Files.filter(
                    (x) => x.Id != deleteFileResponse.Data
                ),
            };
            setState(newState);
            onChange({ ...value, ...newState });
        }
    }, [loadingDeleteFile, deleteFileResponse]);

    useEffect(() => {
        if (!loadingCreate && createResponse) {
            showSweetAlertToast(
                t("common.success"),
                `"${createResponse.Data.Name}" ${t(
                    "common.actions.updatedSuccessfully"
                )}`,
                "success"
            );
        }
    }, [loadingCreate, createResponse]);

    return (
        <div className="mt-4">
            <AppContainer
                showHeader={true}
                // isCollapsible={true}
                // initiallyOpen={true}
                title={t("kindergartenClient.additionalInfo.title")}
                footer={
                    <AppContainerFooter
                        primaryButtonProps={{
                            disabled: !hasEditPermission || loadingCreate,
                            onClick: () => addUpdate({ ...value, ...state }),
                        }}
                    />
                }
            >
                <Row>
                    <Col xs={12} sm={6} md={4}>
                        <Form.Group controlId="description">
                            <Form.Label>
                                {t("kindergartenClient.additionalInfo.notes")}
                            </Form.Label>
                            <Form.Control
                                style={{
                                    height: "150px",
                                    resize: "none",
                                    fontSize: "0.9rem",
                                }}
                                as="textarea"
                                disabled={!hasEditPermission}
                                type="textarea"
                                value={defaultTo(value.Note, "")}
                                onChange={(e) => {
                                    onChange({
                                        ...value,
                                        Note: e.target.value,
                                    });
                                }}
                            />{" "}
                            {/* <ErrorMessage
                                showEmpty={true}
                                errorInfo={validityStateManager.getFirstErrorInfo(
                                    "Note"
                                )}
                            /> */}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <AppCheckbox
                                label={t(
                                    "kindergartenClient.registration.isSibling"
                                )}
                                checked={state.IsSibling}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        IsSibling: e.target.checked,
                                    })
                                }
                            />
                            <AppCheckbox
                                label={t(
                                    "kindergartenClient.registration.arrangedPlace"
                                )}
                                checked={state.ArrangedPlace}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        ArrangedPlace: e.target.checked,
                                    })
                                }
                            />
                            <AppCheckbox
                                label={t(
                                    "kindergartenClient.registration.placeChange"
                                )}
                                checked={state.PlaceChange}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        PlaceChange: e.target.checked,
                                    })
                                }
                            />
                            <AppCheckbox
                                label={t(
                                    "kindergartenClient.registration.migrationBackground"
                                )}
                                checked={state.MigrationBackground}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        MigrationBackground: e.target.checked,
                                    })
                                }
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppFilesListComponent
                            value={
                                {
                                    Files: state.Files,
                                    files: state.files,
                                } as FilesListAndRequest
                            }
                            maxHeight={"200px"}
                            permMap={permMap}
                            onChange={(val: FilesListAndRequest) => {
                                setState(
                                    (s) =>
                                        ({
                                            ...s,
                                            ...val,
                                        } as any)
                                );
                            }}
                            onDelete={(id: number) => deleteFile(id)}
                        />
                    </Col>
                </Row>
            </AppContainer>
        </div>
    );
};

export default ClientRegistrationEditAdditionalInfo;
