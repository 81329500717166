import React from "react";
import styles from "./AuthCircleIcon.module.scss";

export interface AppCircleIcon {
    color: "pink" | "blue";
    className?: string;
}

export const AuthCircleIcon: React.FC<AppCircleIcon> = ({
    color,
    className,
}) => {
    return (
        <span
            className={`${styles.icon} ${
                color === "pink" ? styles.pinkIcon : styles.blueIcon
            } ${className}`}
        />
    );
};

export default AuthCircleIcon;
