import ImageAssets from "globals/images/common";
import { useRouting } from "hooks/general/routing";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router";
import styles from "./AuthLayout.module.scss";

export const AuthLayout: React.FC = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    useEffect(() => {
        if (
            location.pathname.endsWith("/account") ||
            location.pathname.endsWith("account/")
        ) {
            // used base layout pathname only
            navigate(linkProvider.noAuth.screens.login(), { replace: true });
        }
    }, [location.pathname]);
    return (
        <div>
            <div className={styles.backgroundImage}>
                <Image src={ImageAssets.layout.authBack} />
            </div>
            <div className={styles.main}>
                <div className={styles.mainLeft}>
                    <div className={styles.imgBaloon}>
                        <Image src={ImageAssets.layout.fullBalloon} />
                    </div>
                </div>
                <div className={`${styles.mainRight}`}>
                    {/* To inject nested routes */}
                    <Outlet />
                </div>
            </div>
            <div className={styles.backgroundRight}>
                <Image src={ImageAssets.layout.circlesBg} />
            </div>
        </div>
    );
};

export default AuthLayout;
