import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { Business_Creditor_BankData } from "models/permissionManagement";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { BankDataService, getBankDataServiceKey } from "services/business";
import { BankDataCreateEdit } from "commonPartials";
import { useCurrentCreditorContext } from "screens/business/creditors/tabs/CreditorTabs";
import { isNil } from "lodash-es";

export const CreditorBankDataEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { creditorId, id } = useParams();

    const { dataItem: creditor } = useCurrentCreditorContext();
    const { getPermissionMap } = useCheckPermission();
    const permMap = getPermissionMap(Business_Creditor_BankData);

    const linkProviderBase =
        linkProvider.business.screens.creditors.withId().bankData;

    const service = new BankDataService(linkProvider.business.api.bankDatas);

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: res,
    } = useQuery(
        getBankDataServiceKey("get", {
            creditorId: creditorId,
            id: id,
        }),
        async () => {
            if (id) {
                return await service.get(id);
            }
        }
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && res) {
            if (res.Errors) {
                showErrorPage(404);
            }
        }
    }, [getLoading, getRefetching]);
    const hasEditPermission = permMap.EDIT;

    return (
        <div style={{ position: "relative" }}>
            {isNil(creditor) || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <BankDataCreateEdit
                    onBack={() => navigate(linkProviderBase.list())}
                    value={res?.Data}
                    request={{ CreditorId: creditor.Id }}
                    readonly={!hasEditPermission}
                />
            )}
        </div>
    );
};

export default CreditorBankDataEdit;
