import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppTooltip } from "components";
import ImageAssets from "globals/images/common";
import { isNil } from "lodash-es";
import React from "react";
import { Image } from "react-bootstrap";
import styles from "./AppIconButton.module.scss";
export interface AppIconButtonProps {
    className?: string;
    readonly?: boolean;
    onClick: () => void;
    image?: string;
    icon?: IconName; //font-awesome icon
    showToolTip?: boolean;
    tooltipText?: string;
    buttonType?: "Other" | "Edit" | "Clone";
}
export const AppIconButton = React.memo<AppIconButtonProps>(
    ({
        onClick,
        readonly,
        showToolTip: showTopTip,
        icon,
        tooltipText: toolTipText,
        buttonType = "Other",
        image,
        className = "",
    }) => {
        let imageUrl = image;
        if (!imageUrl && buttonType != "Other") {
            imageUrl =
                buttonType === "Clone"
                    ? ImageAssets.common.copyYellow
                    : ImageAssets.common.editBlue;
            if (readonly) {
                imageUrl =
                    buttonType === "Clone"
                        ? ImageAssets.common.copyGray
                        : ImageAssets.common.editGray;
            }
        }

        const handleClick = () => {
            if (!readonly) {
                onClick();
            }
        };
        const content = isNil(icon) ? (
            <Image
                onClick={handleClick}
                className={`${styles.icon} ${className} ${
                    readonly ? "disabled" : ""
                }`}
                src={imageUrl}
            />
        ) : (
            <span onClick={handleClick}>
                <FontAwesomeIcon
                    icon={icon as IconName}
                    className={`${styles.icon} ${className} ${
                        readonly ? "disabled" : ""
                    }`}
                />
            </span>
        );
        return (
            <div className={styles.root}>
                {showTopTip && !readonly ? (
                    <AppTooltip
                        content={toolTipText}
                        allowHtml={false}
                        trigger="mouseenter focus"
                        arrow={true}
                    >
                        {content}
                    </AppTooltip>
                ) : (
                    content
                )}
            </div>
        );
    }
);

export default AppIconButton;
