import {
    getHumanFriendlySize,
    getImageExtensions,
    openUrlInTab,
} from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { TemplateFile } from "models/general";
import React from "react";
import { Image } from "react-bootstrap";
import styles from "./MessageDetailFile.module.scss";

export interface MessageDetailFileProps {
    file: TemplateFile;
}
const imgExtensions = getImageExtensions();
export function MessageDetailFile(props: MessageDetailFileProps) {
    const { file } = props;

    const fileSize: string = getHumanFriendlySize(file.SizeInBytes);

    return (
        <div className={styles.imageContainer}>
            {imgExtensions.includes(file.FileExtension) ? (
                <Image src={file.FullUrl}></Image>
            ) : (
                <Image
                    src={
                        file.FileExtension == "pdf"
                            ? ImageAssets.documentManager.pdf
                            : file.FileExtension == "xls" ||
                              file.FileExtension == "xlsx"
                            ? ImageAssets.documentManager.excel
                            : ImageAssets.documentManager.doc
                    }
                />
            )}
            <div className={styles.animateDiv}>
                <div className={styles.imageDes}>
                    <p className={`${styles.imageName} ellipsis-text`}>
                        {file.FileName}
                    </p>
                    <span className={styles.imageSize}>
                        {fileSize != "0 B" ? fileSize : ""}
                    </span>
                </div>
                <div className={styles.download}>
                    <a
                        onClick={() =>
                            openUrlInTab(file.FullUrl, file.FileName)
                        }
                    >
                        <Image src={ImageAssets.common.downloadArrowWhite} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MessageDetailFile;
