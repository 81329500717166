import { CareScopeTemplateCreateEdit } from "commonPartials/careScopeTemplate";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessType,
    Business_CareScopes,
    LW_Kindergarten_CareScopes,
} from "models";
import React from "react";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";

export const BusinessCareScopeTemplateEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap: getBusinessPermissionMap } =
        useBusinessCheckPermission();
    const { getPermissionMap } = useCheckPermission();

    const { id, lwcId } = useParams();
    const currentBusiness = useCurrentBusinessSettingsContext();

    const isLw = currentBusiness?.dataItem?.Type == BusinessType.LW;

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = (
        isLwc
            ? lwcBase.kindergartens.withId()
            : linkProvider.business.businessSettings().screens
    ).careScopes;

    const permMap = !isLwc
        ? getBusinessPermissionMap(
              currentBusiness?.dataItem?.Id as number,
              currentBusiness?.dataItem?.HqId as number,
              [Business_CareScopes]
          )
        : getPermissionMap([LW_Kindergarten_CareScopes]);

    const useLocationData = defaultTo(
        currentBusiness.dataItem?.LocationPreferences?.ScopeOfCare,
        true
    );
    return (
        <CareScopeTemplateCreateEdit
            id={id}
            currentBusiness={currentBusiness.dataItem}
            locationId={currentBusiness.dataItem?.LocationSettingId}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
            useLocationData={useLocationData}
        />
    );
};

export default BusinessCareScopeTemplateEdit;
