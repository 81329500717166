import { AppDeleteButton } from "components/Buttons";
import { Form, Col } from "react-bootstrap";
import { CourseGroupField } from "models/businessCourses";
import { ValidityStateManager, ValidityState } from "models/general";
import styles from "./CoursesGroupRow.module.scss";
import { AppInputField } from "components/FormFields";
import { defaultTo } from "lodash-es";
import { DragHandle } from "components/Sortable";
import { useTranslation } from "react-i18next";

export interface CourseGroupsFieldRowProps {
    value: CourseGroupField;
    onChange: (field: CourseGroupField) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
}

export const CourseGroupsFieldRow: React.FC<CourseGroupsFieldRowProps> = ({
    value,
    onChange,
    validityState,
    onDelete,
    readonly = false,
}) => {
    const validityStateManager = new ValidityStateManager(validityState);
    const { t } = useTranslation();
    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />
                <Form.Row>
                    <Col sm={4} xs={12} md={4}>
                        <AppInputField
                            label={t("business.courses.createEdit.groupName")}
                            showEmptyError={true}
                            value={value.GroupName}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    GroupName: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t(
                                "business.courses.createEdit.groupName"
                            )}
                            error={validityStateManager.getFirstErrorInfo(
                                "GroupName"
                            )}
                        />
                    </Col>
                    <Col sm={3} xs={12} md={3}>
                        <AppInputField
                            label={t(
                                "business.courses.createEdit.groupShortName"
                            )}
                            showEmptyError={true}
                            value={value.GroupShortName}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    GroupShortName: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t(
                                "business.courses.createEdit.groupShortName"
                            )}
                            error={validityStateManager.getFirstErrorInfo(
                                "GroupShortName"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default CourseGroupsFieldRow;
