import {
    DocumentTemplateItemResponse,
    DocumentTemplateRequest,
    DocumentTemplateResponse,
} from "models/documentTemplates";
import { UpdateDisplayIdResponseAndRequest } from "models/general";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IDocumentTemplateService {
    getTemplateById(
        templateId: string
    ): Promise<AppResponse<DocumentTemplateItemResponse>>;
    getBusinessOrLocationTemplateList(
        request: DocumentTemplateRequest
    ): Promise<AppResponse<DocumentTemplateResponse>>;
    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>>;
    cloneLocationTemplate(id: number): Promise<AppResponse<boolean>>;
    cloneBusinessTemplate(id: string): Promise<AppResponse<boolean>>;
    delete(id: string): Promise<AppResponse<boolean>>;
}

const apiLinkProvider =
    routesForContext().business.api.currentBusiness().documentTemplate;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getDocumentTemplateServiceKey(
    name: keyof IDocumentTemplateService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `documentTemplate_${name}_${JSON.stringify(data)}`;
}
export class DocumentTemplateService
    extends BaseService
    implements IDocumentTemplateService
{
    public documentRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.documentRoutes = apiLinkProvider;
    }

    getTemplateById(
        templateId: string
    ): Promise<AppResponse<DocumentTemplateItemResponse>> {
        return this.doServerXHR<DocumentTemplateItemResponse>({
            url: this.documentRoutes.getTemplateById(templateId),
            method: "get",
        });
    }

    getBusinessOrLocationTemplateList(
        request: DocumentTemplateRequest
    ): Promise<AppResponse<DocumentTemplateResponse>> {
        return this.doServerXHR<DocumentTemplateResponse>({
            url: this.documentRoutes.getBusinessOrLocationTemplateList(request),
            method: "get",
        });
    }
    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>> {
        const formData = new FormData();
        formData.append("displayId", req.DisplayId.toString());
        formData.append("id", req.Id.toString());
        if (req.userType) {
            formData.append("userType", req.userType.toString());
        }

        return this.doServerXHR<boolean>({
            url: this.documentRoutes.updateDisplayId(),
            method: "post",
            data: formData,
        });
    }
    cloneLocationTemplate(id: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.cloneLocationTemplate(id),
            method: "post",
        });
    }
    cloneBusinessTemplate(id: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.cloneBusinessTemplate(id),
            method: "post",
        });
    }
    delete(id: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.delete(id),
            method: "post",
        });
    }
}

export default DocumentTemplateService;
