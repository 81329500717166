import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { BusinessType, PermissionAccessTypes } from "models";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import { LocationSetting } from "models/locationSetting/model";
import {
    LocationSettings_Affiliates,
    LocationSettings_Areas,
    LocationSettings_BasicData,
    LocationSettings_CompanyData,
    LocationSettings_DocumentLetterheads,
    LocationSettings_DocumentTemplates,
    LocationSettings_EmailTemplate,
    LocationSettings_EmployeeKeyCalculation,
    LocationSettings_ExplanationMode,
    LocationSettings_CareScopes,
    LocationSettings_Functions,
    LocationSettings_CompensationTemplates,
    LocationSettings_MealTemplates,
    LocationSettings_PublicHolidays,
    LocationSettings_Vouchers,
    LocationSettings_WorkingDays,
    LocationSettings_AgeGroups,
    LocationSettings_SalaryGroups,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router";

// not in edit-layout to resolve circular dependency
export const useCurrentLocationSettingsContext = () => {
    return useOutletContext<TabbedLayoutActiveItem<LocationSetting>>();
};
export interface LocationSettingTabsProps {
    onActiveTabChange: (tab: NavigationTab) => void;
    forBusiness: BusinessType;
}
export const LocationSettingTabs: React.FC<LocationSettingTabsProps> = ({
    onActiveTabChange,
    forBusiness,
}) => {
    const { linkProvider } = useRouting();
    const { locationId } = useParams();
    const { urlForLocationSettings: forLocationSettings } = useIFrameRouting();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.lillywait.locationSettings().screens.withId();
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const isLw = Boolean(forBusiness == BusinessType.LW);
    const isKindergarten = Boolean(forBusiness == BusinessType.KINDERGARTEN);
    const isIndustry = Boolean(forBusiness == BusinessType.INDUSTRY);

    const idBase = defaultTo(locationId, ":locationId");
    const getTranslation = (tabName: string) =>
        t(`locationSettings.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission([LocationSettings_BasicData]),
                url: linkBase.edit(),
                iframeUrl: forLocationSettings(`Edit/${idBase}`),
            },
            ...(isKindergarten || isLw
                ? [
                      {
                          label: getTranslation("compensationTemplates"),
                          hasAccess: checkPermission(
                              LocationSettings_CompensationTemplates
                          ),
                          url: linkBase.compensationTemplates.list(),
                      },
                  ]
                : []),
            ...(isKindergarten
                ? [
                      {
                          label: getTranslation("mealGroups"),
                          hasAccess: checkPermission(
                              LocationSettings_MealTemplates
                          ),
                          url: linkBase.mealTemplates.list(),
                          iframeUrl: forLocationSettings(
                              `${idBase}/MealTemplates`
                          ),
                      },
                      {
                          label: getTranslation("employeeKeyCalculations"),
                          hasAccess: checkPermission(
                              LocationSettings_EmployeeKeyCalculation
                          ),
                          url: linkBase.employeeKeyCalculations.list(),
                          iframeUrl: forLocationSettings(
                              `${idBase}/EmployeeCalculationKeys`
                          ),
                      },
                      {
                          label: getTranslation("careScopes"),
                          hasAccess: checkPermission(
                              LocationSettings_CareScopes
                          ),
                          url: linkBase.careScopes.list(),
                      },
                      {
                          label: getTranslation("ageGroups"),
                          hasAccess: checkPermission(
                              LocationSettings_AgeGroups
                          ),
                          url: linkBase.ageGroups.list(),
                      },
                      {
                          label: getTranslation("salaryGroups"),
                          hasAccess: checkPermission(
                              LocationSettings_SalaryGroups
                          ),
                          url: linkBase.salaryGroups.list(),
                      },
                  ]
                : []),
            ...(isLw
                ? [
                      {
                          label: getTranslation("packages"),
                          hasAccess: checkPermission(
                              LocationSettings_Affiliates
                          ),
                          url: linkBase.modules(),
                          iframeUrl: forLocationSettings(
                              `${idBase}/Affiliates`
                          ),
                      },
                  ]
                : []),
            {
                label: getTranslation("workingAreas"),
                hasAccess: checkPermission(LocationSettings_Areas),
                url: linkBase.areas.list(),
                iframeUrl: forLocationSettings(`${idBase}/Areas`),
            },
            {
                label: getTranslation("functions"),
                hasAccess: checkPermission(LocationSettings_Functions),
                url: linkBase.functions.list(),
                iframeUrl: forLocationSettings(`${idBase}/Functions`),
            },
            {
                label: getTranslation("publicHolidays"),
                hasAccess: checkPermission(LocationSettings_PublicHolidays),
                url: linkBase.publicHolidays.list(),
                iframeUrl: forLocationSettings(`${idBase}/Holidays`),
            },
            {
                label: getTranslation("settings"),
                hasAccess: checkPermission(LocationSettings_BasicData, [
                    PermissionAccessTypes.EDIT,
                ]),
                url: linkBase.settings(),
                iframeUrl: forLocationSettings(`Deadline/${idBase}`),
            },
            {
                label: getTranslation("documentTemplate"),
                hasAccess: checkPermission(LocationSettings_DocumentTemplates),
                url: linkBase.documentTemplates.list(),
                iframeUrl: forLocationSettings(`${idBase}/Templates`),
            },
            {
                label: getTranslation("letterheads"),
                hasAccess: checkPermission(
                    LocationSettings_DocumentLetterheads
                ),
                url: linkBase.letterheads.list(),
                iframeUrl: forLocationSettings(`${idBase}/Letterheads`),
            },
            {
                label: getTranslation("workingDays"),
                hasAccess: checkPermission(LocationSettings_WorkingDays),
                url: linkBase.workingHours(),
            },
            // {
            //     label: getTranslation("emailTemplates"),
            //     hasAccess: checkPermission(LocationSettings_EmailTemplate),
            //     url: linkBase.emailTemplate.list(),
            //     iframeUrl: forLocationSettings(`${idBase}/EmailTemplates`),
            // },
            {
                label: getTranslation("explanationMode"),
                hasAccess: checkPermission(LocationSettings_ExplanationMode),
                url: linkBase.explanationMode.list(),
                iframeUrl: forLocationSettings(`${idBase}/ExplanationMode`),
            },
            {
                label: getTranslation("companyData"),
                hasAccess: checkPermission(LocationSettings_CompanyData),
                url: linkBase.companyDataFields.list(),
                iframeUrl: forLocationSettings(`${idBase}/CompanyDatas`),
            },
            {
                label: getTranslation("voucher"),
                hasAccess: checkPermission(LocationSettings_Vouchers),
                url: linkBase.vouchers.list(),
                iframeUrl: forLocationSettings(`${idBase}/Vouchers`),
            },
            {
                label: getTranslation("affiliate"),
                hasAccess: checkPermission(LocationSettings_Affiliates),
                url: linkBase.affiliate.list(),
                iframeUrl: forLocationSettings(`${idBase}/Affiliates`),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={5}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default LocationSettingTabs;
