import { DocumentTemplateSearchSection } from "commonPartials";
import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { AppTabButtonProps, AppTabButtons } from "components/Tabs";
import { ImageAssets } from "globals/images";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import { orderBy } from "lodash-es";
import {
    DocumentTemplateFilter,
    DocumentTemplateManagerItem,
    DocumentTemplateRequest,
    DocumentTemplateSortColumn,
    DocumentTemplateTabType,
    parseDocumentTemplateResponse,
} from "models/documentTemplates";
import {
    CustomColorItemOption,
    ResponseMeta,
    SortOrder,
    TableSort,
    UpdateDisplayIdResponseAndRequest,
} from "models/general";
import {
    Business_DocumentManager_Template,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
    DocumentTemplateService,
    getDocumentTemplateServiceKey,
} from "services/business";
import DocumentTemplateLibrary from "./DocumentTemplateLibrary";
import styles from "./DocumentTemplateManager.module.scss";
import DocumentTemplateOwnDocuments from "./DocumentTemplateOwnDocuments";

interface DocumentTemplateManagerState {
    selectedTab: DocumentTemplateTabType;
    filter: DocumentTemplateFilter;
    meta: ResponseMeta;
    sort: TableSort<DocumentTemplateSortColumn>;
    list: DocumentTemplateManagerItem[];
    selectList: CustomColorItemOption[];
    selectedTags: CustomColorItemOption[];
}

const PAGE_SIZE = 25;
export const DOCUMENT_TEMPLATE_DOCUMENT_TYPE_PARAM_KEY = "documentType";
export const DocumentTemplateManager: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { id: sessionBusinessId } = useSessionBusiness();

    const [searchParams, createSearchParams] = useSearchParams();

    const documentTypeParm = searchParams.get(
        DOCUMENT_TEMPLATE_DOCUMENT_TYPE_PARAM_KEY
    );
    const documentType =
        documentTypeParm != null
            ? (documentTypeParm as DocumentTemplateTabType)
            : DocumentTemplateTabType.OWN_DOCUMENTS;

    const documentTemplateService = new DocumentTemplateService(
        linkProvider.business.api.currentBusiness().documentTemplate
    );

    const [state, setState] = useState<DocumentTemplateManagerState>({
        selectedTab: documentType,
        filter: { search: "", tags: "" },
        meta: { PageNumber: 1, PageSize: PAGE_SIZE, HasNextPage: false },
        sort: {
            SortColumn: DocumentTemplateSortColumn.DisplayId,
            SortOrder: SortOrder.ASC,
        },
        list: [],
        selectList: [],
        selectedTags: [],
    });

    const { checkPermission } = useCheckPermission();

    const hasCreatePermission = checkPermission(
        Business_DocumentManager_Template,
        [PermissionAccessTypes.CREATE]
    );

    const { sessionBusinessLocation } = useSessionBusiness();
    const locationId = sessionBusinessLocation?.Id;

    const request: DocumentTemplateRequest = {
        Page: state.meta.PageNumber,
        PageSize: PAGE_SIZE,
        DocumentTabType: state.selectedTab,
        Name: state.filter.search,
        SortColumn: state.sort.SortColumn,
        SortOrder: state.sort.SortOrder,
        DocumentTypes: state.selectedTags.map((x) =>
            x.value?.toString()
        ) as string[],
    };

    const {
        isLoading: loading,
        isRefetching: refetching,
        data: response,
        refetch: refetch,
    } = useQuery(
        getDocumentTemplateServiceKey("getBusinessOrLocationTemplateList", {
            ...request,
            businessId: sessionBusinessId,
            locationId: locationId,
        }),
        async () => {
            if (
                state.selectedTab == DocumentTemplateTabType.OWN_DOCUMENTS ||
                (locationId && locationId > 0)
            ) {
                return await documentTemplateService.getBusinessOrLocationTemplateList(
                    request
                );
            }
        },
        {
            refetchOnMount: true,
        }
    );

    const onFilterChange = (filter: DocumentTemplateFilter) => {
        setState((oldState) => ({
            ...oldState,
            filter: filter,
            meta: {
                ...state.meta,
                PageNumber: 1,
            },
        }));
    };
    const filterList = () => {
        setState({
            ...state,
            list: orderBy(
                state.list,
                ["DisplayId"],
                state.sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
            ) as DocumentTemplateManagerItem[],
        });
    };

    const onTabChange = (tab: DocumentTemplateTabType) => {
        if (tab != state.selectedTab) {
            if (tab == DocumentTemplateTabType.OWN_DOCUMENTS) {
                searchParams.delete(DOCUMENT_TEMPLATE_DOCUMENT_TYPE_PARAM_KEY);
            } else {
                searchParams.append(
                    DOCUMENT_TEMPLATE_DOCUMENT_TYPE_PARAM_KEY,
                    tab
                );
            }
            createSearchParams(searchParams);
            setState({
                ...state,
                meta: {
                    ...state.meta,
                    PageNumber: 1,
                    PageSize: PAGE_SIZE,
                },
                selectedTab: tab,
                sort: {
                    SortColumn: DocumentTemplateSortColumn.DisplayId,
                    SortOrder: SortOrder.ASC,
                },
                filter: { search: "", tags: "" },
                selectedTags: [],
                list: [],
            });
        }
    };
    const onSelectedTabChange = (values: CustomColorItemOption[]) => {
        setState({
            ...state,
            selectedTags: values,
        });
    };

    useEffect(() => {
        if (!loading && response) {
            const documents = parseDocumentTemplateResponse(
                response.Data.DocumentTemplates,
                response.Data.Tags
            );

            setState({
                ...state,
                list:
                    response.Meta?.PageNumber == 1
                        ? documents
                        : [...state.list, ...documents],
                meta: {
                    ...response.Meta,
                    PageSize:
                        response.Meta?.PageSize != PAGE_SIZE
                            ? PAGE_SIZE
                            : response.Meta.PageSize,
                },
                selectList: response.Data.Tags.map((x) => {
                    return {
                        value: x.label,
                        label: t(
                            `templateDocumentManager.documentType.${x.label.toLocaleLowerCase()}`
                        ),
                        color: x.color,
                    };
                }),
            });
        }
    }, [loading, response]);

    const refetchDocumentTemplates = (pageNumber: number) => {
        setState({
            ...state,
            meta: {
                ...state.meta,
                PageNumber: pageNumber,
            },
        });
    };

    const updateDisplayId = (req: UpdateDisplayIdResponseAndRequest) => {
        setState({
            ...state,
            list: state.list.map((x) => {
                if (x.Id == req.Id) {
                    x.DisplayId = req.DisplayId;
                }
                return x;
            }),
        });
    };

    const removeItem = (Id: number) => {
        setState({
            ...state,
            list: state.list.filter((x) => x.Id != Id),
            meta: {
                ...state.meta,
                PageNumber:
                    state.meta.HasNextPage &&
                    defaultTo(state.meta.PageNumber, 1) > 1
                        ? 1
                        : state.meta.PageNumber,
            },
        });

        if (state.meta.HasNextPage && state.meta.PageNumber == 1) {
            refetch();
        }
    };

    const renderTabs = () => {
        if (state.selectedTab == DocumentTemplateTabType.OWN_DOCUMENTS) {
            return (
                <DocumentTemplateOwnDocuments
                    templates={state.list}
                    sort={state.sort}
                    updateDisplayId={updateDisplayId}
                    removeItem={removeItem}
                    metaState={state.meta}
                    refetchDocumentTemplate={refetchDocumentTemplates}
                    onSortChange={(sort) => {
                        setState({
                            ...state,
                            sort: sort,
                            meta: {
                                ...state.meta,
                                PageNumber: 1,
                            },
                        });
                    }}
                    filterList={filterList}
                    loading={loading || refetching}
                    request={{
                        DocumentTabType: state.selectedTab,
                        DocumentTypes: state.selectedTags.map(
                            (x) => x.value as string
                        ),
                        Name: state.filter.search,
                        Page: state.meta.PageNumber,
                        PageSize: state.meta.PageSize,
                        SortColumn: state.sort.SortColumn,
                        SortOrder: state.sort.SortOrder,
                    }}
                />
            );
        } else if (
            state.selectedTab == DocumentTemplateTabType.TEMPLATE_LIBRARY
        ) {
            return (
                <DocumentTemplateLibrary
                    templates={state.list}
                    metaState={state.meta}
                    refetchDocumentTemplate={refetchDocumentTemplates}
                    onSortChange={(sort) => {
                        setState({ ...state, sort: sort });
                    }}
                    sort={state.sort}
                    loading={loading || refetching}
                    request={{
                        DocumentTabType: state.selectedTab,
                        DocumentTypes: state.selectedTags.map(
                            (x) => x.value as string
                        ),
                        Name: state.filter.search,
                        Page: state.meta.PageNumber,
                        PageSize: state.meta.PageSize,
                        SortColumn: state.sort.SortColumn,
                        SortOrder: state.sort.SortOrder,
                    }}
                />
            );
        }
    };

    return (
        <>
            <AppContentHeader
                title={t("templateDocumentManager.documents")}
                icon={ImageAssets.common.fileBlue}
            >
                <div>
                    <AppTabButtons
                        btnClass={styles.tabButton}
                        activeClass={styles.activeTabBtn}
                        tabButtons={[
                            {
                                label: t(
                                    "templateDocumentManager.tab.ownDocuments"
                                ),
                                onClick: () =>
                                    onTabChange(
                                        DocumentTemplateTabType.OWN_DOCUMENTS
                                    ),
                                active:
                                    state.selectedTab ==
                                    DocumentTemplateTabType.OWN_DOCUMENTS,
                                className: styles.ownDocumentTabBtn,
                            } as AppTabButtonProps,
                            {
                                label: t(
                                    "templateDocumentManager.tab.templateLibrary"
                                ),
                                onClick: () =>
                                    onTabChange(
                                        DocumentTemplateTabType.TEMPLATE_LIBRARY
                                    ),
                                active:
                                    state.selectedTab ==
                                    DocumentTemplateTabType.TEMPLATE_LIBRARY,
                                className: styles.templateLibraryTabBtn,
                            } as AppTabButtonProps,
                        ]}
                    />
                </div>
                <div className={styles.createButtonDiv}>
                    {state.selectedTab ==
                        DocumentTemplateTabType.OWN_DOCUMENTS &&
                        hasCreatePermission && (
                            <>
                                <AppRoundedTextIconButton
                                    href={linkProvider.business.screens.admin.documentManager.create()}
                                />
                            </>
                        )}
                </div>
            </AppContentHeader>
            <DocumentTemplateSearchSection
                filter={state.filter}
                showTags={
                    state.selectedTab ==
                    DocumentTemplateTabType.TEMPLATE_LIBRARY
                }
                onFilterChange={onFilterChange}
                selectListData={state.selectList}
                loading={loading || refetching}
                onChange={onSelectedTabChange}
                selectedTags={state.selectedTags}
            />
            {renderTabs()}
        </>
    );
};

export default DocumentTemplateManager;
