import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, max } from "lodash-es";
import {
    Business_Employee_Shifts,
    EmployeeShift,
    EmployeeShiftSortColumn,
    getEmployeeShiftSortKeyFromEnum,
    parseEmployeeShiftResponse,
    PermissionAccessTypes,
} from "models";
import { sortData, SortOrder, TableSort } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { EmployeeShiftTable } from "./partials";
import styles from "commonPartials/commonStyles.module.scss";
import EmployeeShiftService, {
    getEmployeeShiftServiceKey,
} from "services/business/EmployeeShiftService";
import moment from "moment";
import AppYearNavigationComponent from "components/AppYearNavigationComponent";

interface EmployeeShiftListState {
    data: EmployeeShift[];
    year: number;
    sort: TableSort<EmployeeShiftSortColumn>;
}

export const EmployeeShiftList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { employeeId } = useParams();
    const { state: locationState } = useLocation();

    const { checkPermission } = useCheckPermission();

    const [state, setState] = useState<EmployeeShiftListState>({
        data: [],
        year:
            locationState && (locationState as any).year
                ? (locationState as any).year
                : moment().year(),
        sort: {
            SortColumn: EmployeeShiftSortColumn.StartDate,
            SortOrder: SortOrder.DESC,
        },
    });

    const shiftService = new EmployeeShiftService(
        linkProvider.business.employees().api.withId().shifts
    );

    const { isFetching: isLoading, data: listRes } = useQuery(
        getEmployeeShiftServiceKey("list", {
            employeeId: employeeId,
        }),
        async () => await shiftService.list(),
        {
            select: (resp) =>
                resp && resp.Data
                    ? {
                          Data: resp.Data.Data.map((x) =>
                              parseEmployeeShiftResponse(x)
                          ),
                          YearsRange: resp.Data.YearsRange,
                      }
                    : null,
        }
    );
    useEffect(() => {
        if (!isLoading && listRes) {
            const toUpdate = { ...state, data: listRes.Data };

            if (
                listRes.YearsRange &&
                listRes.YearsRange.length > 0 &&
                !listRes.YearsRange.includes(state.year)
            ) {
                toUpdate.year = max(listRes.YearsRange) as number;
            }
            setState(toUpdate);
        }
    }, [isLoading]);

    const { mutate: deleteTemplate, isLoading: deleting } = useMutation(
        async (id: string) => await shiftService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("employee.shift.shift.name")} ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );

                    setState({
                        ...state,
                        data: state.data.filter((x) => x.Id != deleteRes.Data),
                    });
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const linkProviderBase = linkProvider.business.employees().screens.withId();

    const hasDeletePermission = checkPermission(
        [Business_Employee_Shifts],
        [PermissionAccessTypes.DELETE]
    );

    const processedData = useMemo(() => {
        if (state.data) {
            const sortedList = sortData(
                state.data.filter(
                    (x) =>
                        x.StartDate.year() == state.year ||
                        x.EndDate?.year() == state.year
                ),
                [
                    {
                        col: getEmployeeShiftSortKeyFromEnum(
                            state.sort.SortColumn
                        ) as any,
                        dir: state.sort.SortOrder,
                    },
                ]
            );
            return sortedList;
        }
        return [];
    }, [state]);

    return (
        <AppContainer classes={{ body: styles.appContainerWithLessTopPadding }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                }}
            >
                <AppIconTextButton
                    padding="med"
                    className="mb-2"
                    icon="plus"
                    style={{
                        marginRight: "20px",
                        marginBottom: "0px !important",
                    }}
                    text={t("common.create")}
                    onClick={() => {
                        navigate(linkProviderBase.shifts.create());
                    }}
                />
                <AppYearNavigationComponent
                    years={defaultTo(listRes?.YearsRange, [])}
                    value={state.year}
                    readonly={isLoading}
                    onChange={(y: number) => setState({ ...state, year: y })}
                />
            </div>
            <EmployeeShiftTable
                list={processedData}
                sort={state.sort}
                onClick={(id) => {
                    navigate(linkProviderBase.shifts.edit(id));
                }}
                onSortChange={(sort: TableSort<EmployeeShiftSortColumn>) => {
                    setState({
                        ...state,
                        sort: sort,
                    });
                }}
                onDeleteBtnClick={(id) => deleteTemplate(id)}
                loading={isLoading}
                hasDeletePermission={hasDeletePermission}
            />
        </AppContainer>
    );
};

export default EmployeeShiftList;
