import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppPopover } from "components";
import { AppLoader } from "components/Loaders";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import {
    AccessPointAction,
    ControllerType,
    getAccessPointActionString,
    parseDailyAccessControlEntry,
} from "models/timeManagement";
import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    getTimeTrackingServiceKey,
    TimeTrackingService,
} from "services/business";
import styles from "./TimeManagementAccessEventsInfo.module.scss";
interface TimeManagementAccessEventsInfoProps {
    accessControlId: number;
    useGreenColor?: boolean;
    showTypeInTitle?: boolean;
    controllerType?: ControllerType;
}

export const TimeManagementAccessEventsInfo: React.FC<
    TimeManagementAccessEventsInfoProps
> = ({
    accessControlId,
    controllerType = ControllerType.Main,
    useGreenColor = false,
    showTypeInTitle = false,
}) => {
    const { t } = useTranslation();
    const { getTimeFormatForLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const timeTrackingService = new TimeTrackingService(
        linkProvider.business.api.timeTracking
    );
    const {
        isLoading: loading,
        refetch,
        data: response,
    } = useQuery(
        getTimeTrackingServiceKey(
            "getDailyAccessDetails",
            JSON.stringify({ id: accessControlId, type: controllerType })
        ),
        async () =>
            await timeTrackingService.getDailyAccessDetails(
                accessControlId,
                controllerType
            ),
        {
            enabled: false,
            select: (data) =>
                data.Data
                    ? data.Data.map((x) => parseDailyAccessControlEntry(x))
                    : [],
        }
    );
    const onDetailsClick = () => {
        refetch();
    };

    const titleExtension = showTypeInTitle
        ? ` (${t("timeManagement.controllers." + controllerType)})`
        : "";
    const title = `${t("timeManagement.accessDetails")}${titleExtension}`;
    const timeFormat = getTimeFormatForLocale();
    const stopPropagationHandler = (e: any) => {
        e.stopPropagation();
    };

    return (
        <div onClick={stopPropagationHandler}>
            <AppPopover
                onClick={onDetailsClick}
                triggerElem={
                    <a
                        className={`${styles.playButton} ${
                            useGreenColor ? styles.green : ""
                        }`}
                    >
                        <FontAwesomeIcon icon="play" />
                    </a>
                }
                placement={"right-start"}
            >
                {response == null || loading ? (
                    <AppLoader fullHeight={false} width={200} height={150} />
                ) : (
                    <div
                        className={styles.container}
                        onClick={stopPropagationHandler}
                    >
                        <div style={{ textAlign: "start" }}>{title}</div>
                        <Table style={{ margin: "0" }}>
                            <tbody>
                                {response.length == 0 ? (
                                    <tr>
                                        <td>
                                            <span
                                                style={{
                                                    display: "block",
                                                    width: "100%",
                                                }}
                                            >
                                                {t("common.noDataFound")}
                                            </span>
                                        </td>
                                    </tr>
                                ) : (
                                    response.map((x) => (
                                        <tr
                                            className={
                                                x.IsAutoGenerated
                                                    ? styles.textPrimary
                                                    : x.IsActionUpdated
                                                    ? styles.textOrange
                                                    : ""
                                            }
                                            style={{
                                                color: x.Ignored ? "red" : "",
                                            }}
                                            key={x.Id}
                                        >
                                            <td
                                                style={{
                                                    paddingRight: "0",
                                                    paddingLeft: "5px",
                                                }}
                                            >
                                                <FontAwesomeIcon icon="business-time" />
                                            </td>
                                            <td style={{ paddingLeft: "0" }}>
                                                {x.Time
                                                    ? x.Time.format(timeFormat)
                                                    : ""}
                                            </td>
                                            <td
                                                style={{
                                                    paddingRight: "0",
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        x.ActionType ==
                                                            AccessPointAction.IN ||
                                                        AccessPointAction.START
                                                            ? "door-open"
                                                            : "door-closed"
                                                    }
                                                />
                                            </td>
                                            <td
                                                style={{
                                                    paddingLeft: "0",
                                                    paddingRight: "5px",
                                                }}
                                            >
                                                {getAccessPointActionString(
                                                    x.ActionType
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                )}
            </AppPopover>
        </div>
    );
};

export default TimeManagementAccessEventsInfo;
