import ErrorMessage from "components/ErrorMessage";
import {
    getInitializedValidityStateFromRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { defaultTo } from "lodash";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { LocationDataRequest, ZipCodeItem } from "models/location";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LocationSelectComponent from "./LocationSelectComponent";
import LocationZipcodesSelectComponent from "./LocationZipcodesSelectComponent";

interface Props {
    onChange: (val: ZipCodeItem, forCity?: boolean) => void;
    postCode: string | null;
    city: string | null;
    country: string;
    readOnly?: boolean;
    useOldUI?: boolean;
}

export const ZipCodeAndCitySelector = ({
    onChange,
    country,
    city,
    postCode,
    ...rest
}: Props) => {
    const { t } = useTranslation();

    const state = {
        City: city,
        Value: postCode,
        Text: postCode,
    } as ZipCodeItem;

    const stateManager = useMemo(() => {
        const validityState = getInitializedValidityStateFromRules(
            {
                City: [
                    {
                        rule: Validations.REQUIRED,
                        message: t("location.city.missing"),
                    },
                ],
                Value: [
                    {
                        rule: Validations.REQUIRED,
                        message: t("location.postalCode.missing"),
                    },
                ],
            },
            state,
            getInitializedValidityState([], [])
        );
        return new ValidityStateManager(validityState);
    }, [state]);

    const cityDataRequest = useMemo(() => {
        return {
            CountryCode: country,
            ZipCodes:
                state && defaultTo(state.Value as string, "").length > 0
                    ? [state.Value as string]
                    : undefined,
            RequiredResponse: "cities",
        } as LocationDataRequest;
    }, [state.Value]);

    return (
        <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1, minWidth: "50%", paddingRight: "15px" }}>
                <LocationSelectComponent
                    value={defaultTo(state.City, "")}
                    onChange={(v) => {
                        const newState: ZipCodeItem =
                            v.length > 0
                                ? {
                                      ...state,
                                      City: v,
                                  }
                                : {
                                      ...state,
                                      City: null,
                                  };
                        onChange(newState, true);
                    }}
                    refetchDependency={["country", "zipcodes"]}
                    fieldName={"city"}
                    isReadonly={rest.readOnly}
                    requestParams={cityDataRequest}
                    errorInfo={stateManager.getFirstErrorInfo("City")}
                />
            </div>
            <Form.Group
                controlId={"postalCode"}
                style={{ flexGrow: 1, paddingLeft: "15px" }}
            >
                <Form.Label>{t(`location.postalCode.name`)}</Form.Label>
                <LocationZipcodesSelectComponent
                    value={state.Value ? state : null}
                    onChange={(v: ZipCodeItem) => {
                        const newState: ZipCodeItem =
                            (v.Value as string).length == 0
                                ? {
                                      ...state,
                                      Value: null,
                                      Text: "",
                                  }
                                : v;
                        onChange(newState);
                    }}
                    requestParams={
                        {
                            CountryCode: country,
                            Cities:
                                state && defaultTo(state.City, "").length > 0
                                    ? [state.City as string]
                                    : null,
                            RequiredResponse: "zipcodes",
                        } as LocationDataRequest
                    }
                    readOnly={rest.readOnly}
                />
                <ErrorMessage
                    errorInfo={stateManager.getFirstErrorInfo("Value")}
                />
            </Form.Group>
        </div>
    );
};

export default ZipCodeAndCitySelector;
