import { AppContentHeader } from "components";
import { AppSelect } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppRoundedTextIconButton } from "components/Buttons";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { orderBy } from "lodash-es";
import {
    BusinessType,
    LocationSettings_BasicData,
    PermissionAccessTypes,
} from "models";
import { Optional, SortOrder, TableSort } from "models/general";
import {
    getLocationSettingSortColumnKeyFromEnum,
    LocationSettingSortColumn,
} from "models/locationSetting";
import { LocationSettingListItem } from "models/locationSetting/model";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { LocationSettingRow } from "screens/lillywait/locationSettings/partials";
import {
    getLocationSettingsServiceKey,
    LocationSettingsService,
} from "services/lillywait/locationSettings";
import styles from "./LocationSettingsList.module.scss";

const COLUMNS_COUNT = 7;
export const LocationSettingsList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const locationService = new LocationSettingsService(
        linkProvider.lillywait.locationSettings().api
    );
    const [filter, setFilter] = useState<Optional<BusinessType>>(null);
    const [data, setData] = useState<LocationSettingListItem[] | undefined>(
        undefined
    );

    const [sort, setSort] = useState<TableSort<LocationSettingSortColumn>>({
        SortColumn: LocationSettingSortColumn.Name,
        SortOrder: SortOrder.DESC,
    });
    const navigate = useNavigate();
    const canDelete = checkPermission(
        [LocationSettings_BasicData],
        [PermissionAccessTypes.DELETE]
    );
    const { isLoading, data: response } = useQuery(
        getLocationSettingsServiceKey("getList"),
        async () => await locationService.getList(filter)
    );
    useEffect(() => {
        if (!isLoading && response && response.Data) {
            setData(response.Data);
        }
    }, [isLoading, response]);
    useEffect(() => {
        if (sort) {
            if (data) {
                const sortedData = orderBy(
                    data,
                    getLocationSettingSortColumnKeyFromEnum(sort.SortColumn),
                    sort.SortOrder == SortOrder.ASC ? "asc" : "desc"
                );
                setData(sortedData);
            }
        }
    }, [sort]);
    useEffect(() => {
        if (response && response.Data) {
            setData(
                filter
                    ? response.Data.filter((x) => x.ForBusiness === filter)
                    : response.Data
            );
        }
    }, [filter]);

    const businessTypes: SimpleOption[] = [
        {
            label: t("common.all").toString(),
            value: null,
        },
        ...Object.values(BusinessType).map((x) => {
            return {
                label: t(`business.businessType.${x.toString().toLowerCase()}`),
                value: x,
            };
        }),
    ];

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("locationSettings.title")}
                classNameForIcon="lnr-map-marker"
            >
                <div className={styles.headerRight}>
                    <AppSelect
                        className={styles.typeFilter}
                        options={businessTypes}
                        isDisabled={isLoading || !data}
                        value={businessTypes.find((x) => x.value === filter)}
                        onChange={(opt: SimpleOption) => {
                            setFilter(opt.value);
                        }}
                    />
                    <AppRoundedTextIconButton
                        onClick={() =>
                            navigate(
                                linkProvider.lillywait
                                    .locationSettings()
                                    .screens.create()
                            )
                        }
                    />
                </div>
            </AppContentHeader>
            <AppTable
                id={"scrollableDiv"}
                containerClass={`${styles.tableOuter}`}
                hover={true}
                stickyHeader={true}
                heightToAdjust={185}
            >
                <thead>
                    <tr>
                        <AppTableSortColumnIcons
                            width={250}
                            text={t("locationSettings.columns.name")}
                            sortColumn={LocationSettingSortColumn.Name}
                            onClick={(key, order) =>
                                setSort({
                                    SortColumn: key,
                                    SortOrder: order,
                                })
                            }
                            sortOrder={
                                sort.SortColumn ==
                                LocationSettingSortColumn.Name
                                    ? sort.SortOrder
                                    : null
                            }
                        />
                        <AppTableSortColumnIcons
                            width={150}
                            text={t("locationSettings.columns.branch")}
                            sortColumn={LocationSettingSortColumn.ForBusiness}
                            onClick={(key, order) =>
                                setSort({
                                    SortColumn: key,
                                    SortOrder: order,
                                })
                            }
                            sortOrder={
                                sort.SortColumn ==
                                LocationSettingSortColumn.ForBusiness
                                    ? sort.SortOrder
                                    : null
                            }
                        />
                        <AppTableSortColumnIcons
                            width={200}
                            text={t("locationSettings.columns.country")}
                            sortColumn={LocationSettingSortColumn.Country}
                            onClick={(key, order) =>
                                setSort({
                                    SortColumn: key,
                                    SortOrder: order,
                                })
                            }
                            sortOrder={
                                sort.SortColumn ==
                                LocationSettingSortColumn.Country
                                    ? sort.SortOrder
                                    : null
                            }
                        />
                        <AppTableSortColumnIcons
                            width={200}
                            text={t("locationSettings.columns.state")}
                            sortColumn={LocationSettingSortColumn.State}
                            onClick={(key, order) =>
                                setSort({
                                    SortColumn: key,
                                    SortOrder: order,
                                })
                            }
                            sortOrder={
                                sort.SortColumn ==
                                LocationSettingSortColumn.State
                                    ? sort.SortOrder
                                    : null
                            }
                        />
                        <th style={getFixedCssWidths(300, false)}>
                            {t("locationSettings.columns.city")}
                        </th>
                        <th style={getFixedCssWidths(300)}>
                            {t("locationSettings.columns.zipCode")}
                        </th>
                        <th style={getFixedCssWidths(50)} />
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>

                    {data &&
                        data.length > 0 &&
                        data.map((x) => {
                            return (
                                <LocationSettingRow
                                    key={x.EncodedId}
                                    value={x}
                                    canDelete={canDelete}
                                    onDelete={(locationId: number) => {
                                        // deletedHq (not possible yet)
                                        setData((old) =>
                                            old?.filter(
                                                (x) => x.Id !== locationId
                                            )
                                        );
                                    }}
                                    onClick={() =>
                                        navigate(
                                            linkProvider.lillywait
                                                .locationSettings()
                                                .screens.withId(x.EncodedId)
                                                .edit()
                                        )
                                    }
                                />
                            );
                        })}
                    {(isLoading || !data) && (
                        <AppTableSkeleton rows={5} columns={COLUMNS_COUNT} />
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default LocationSettingsList;
