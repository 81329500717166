import { defaultTo } from "lodash-es";
import { AppSwitch } from "components";
import { useTranslation } from "react-i18next";
import { PermissionAccessMap } from "models";
import { Col, Row } from "react-bootstrap";
import styles from "./ChatSettingsFlagsPartial.module.scss";
import classNames from "classnames";
import { UserType } from "models/general";

export interface ChatFlags {
    businessDefaultGroup: boolean;
    businessCareGroup: boolean;
    chatWithCareGroupParents: boolean;
    webUserCareGroupChannel: boolean;
    webUserFacilityChannel: boolean;
    chatWithCareGroupEmployees: boolean;
    chatWithAllParents: boolean;
    chatWithAllEmployees: boolean;
}
export interface ChatSettingsFlagsPartialProp {
    value: ChatFlags;
    onChange: (val: ChatFlags) => void;
    title: string;
    className?: string;
    userType?: UserType;
    permMap: PermissionAccessMap;
}
const colSize = 12;
export const ChatSettingsFlagsPartial: React.FC<
    ChatSettingsFlagsPartialProp
> = ({ permMap, value, onChange, title, className, userType }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(className, styles.root)}>
            <div className={styles.title}>{title}</div>
            <div className={styles.flagsRoot}>
                <span>{t("business.chatSettings.allowGroupChats")}</span>
                <Row>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.companyGroup${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(value.businessDefaultGroup, false)}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    businessDefaultGroup: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.careGroup${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(value.businessCareGroup, false)}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    businessCareGroup: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.webUser.facility${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={
                                !permMap.EDIT || !value.businessDefaultGroup
                            }
                            className={styles.switch}
                            value={defaultTo(
                                value.webUserFacilityChannel,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    webUserFacilityChannel: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.webUser.careGroup${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={!permMap.EDIT || !value.businessCareGroup}
                            className={styles.switch}
                            value={defaultTo(
                                value.webUserCareGroupChannel,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    webUserCareGroupChannel: checked,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <br />
            <div className={styles.flagsRoot}>
                <span>{t("business.chatSettings.allowSingleChats")}</span>
                <Row>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                "business.chatSettings.daycareGroupParentsChat"
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(
                                value.chatWithCareGroupParents,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    chatWithCareGroupParents: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                "business.chatSettings.daycareGroupEmployeesChat"
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(
                                value.chatWithCareGroupEmployees,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    chatWithCareGroupEmployees: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                "business.chatSettings.companyEmployeesChat"
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(value.chatWithAllEmployees, false)}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    chatWithAllEmployees: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                "business.chatSettings.companyParentsChat"
                            )}
                            disabled={!permMap.EDIT}
                            className={styles.switch}
                            value={defaultTo(value.chatWithAllParents, false)}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    chatWithAllParents: checked,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
            {/* <br />
            <div className={styles.flagsRoot}>
                <span>{t("business.chatSettings.allowWebUsers")}</span>
                <Row>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.webUser.careGroup${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={!permMap.EDIT || !value.businessCareGroup}
                            className={styles.switch}
                            value={defaultTo(
                                value.webUserCareGroupChannel,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    webUserCareGroupChannel: checked,
                                });
                            }}
                        />
                    </Col>
                    <Col sm={colSize}>
                        <AppSwitch
                            label={t(
                                `business.chatSettings.webUser.facility${
                                    userType == UserType.Parent
                                        ? "Parents"
                                        : "Employees"
                                }`
                            )}
                            disabled={
                                !permMap.EDIT || !value.businessDefaultGroup
                            }
                            className={styles.switch}
                            value={defaultTo(
                                value.webUserFacilityChannel,
                                false
                            )}
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    webUserFacilityChannel: checked,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div> */}
        </div>
    );
};

export default ChatSettingsFlagsPartial;
