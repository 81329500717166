import { AppLayout } from "layouts/appLayout";
import { RouteInfo } from "routing/models";
import { businessRoutes, noAuthRoutes } from "routing/routes";
import AuthLayout from "./AuthLayout";

// causing circular dependencies so had to use this
export const layoutsRouteMap: { [index: string]: RouteInfo } = {
    [noAuthRoutes().base]: {
        component: AuthLayout,
        fullPattern: noAuthRoutes().base,
        pattern: noAuthRoutes().base,
    },
    [businessRoutes().base]: {
        component: AppLayout,
        fullPattern: businessRoutes().base,
        pattern: businessRoutes().base,
    },
};

export default layoutsRouteMap;
