import {
    AppDatePicker,
    AppMonthYearPicker,
    AppSwitch,
} from "components/FormFields";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { defaultTo, isNil, size } from "lodash-es";
import { Optional, ValidityStateManager } from "models/general";
import {
    StartAndEndDates,
    validateStartEndDate,
} from "models/StartAndEndDateFields";
import moment from "moment";
import { Moment } from "moment-timezone";
import React, { useEffect, useMemo, useRef } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./StartAndEndDateFields.module.scss";

export interface StartAndEndDateFieldsProps {
    readonly?: boolean;
    requiredEndDate?: boolean;
    colSize?: number;
    minStartDate?: Optional<Moment>;
    maxStartDate?: Optional<Moment>;
    maxEndDate?: Optional<Moment>;
    appendToBody?: boolean;
    useMonthPicker?: boolean;
    startLabel?: string;
    toggleLabel?: string;
    value: StartAndEndDates;
    allowSameEndDate?: boolean;
    useNoEndDate?: boolean;
    onChange: (res: StartAndEndDates) => void;
}

const computeMinEndDate = (allowSame: boolean, val: Moment) => {
    const date = defaultTo(val, moment()).clone();
    return allowSame ? date : date.add(1, "d");
};
const Component: React.FC<StartAndEndDateFieldsProps> = ({
    readonly,
    requiredEndDate = false,
    colSize = 6,
    minStartDate,
    maxStartDate,
    maxEndDate,
    startLabel,
    toggleLabel,
    appendToBody,
    useMonthPicker: monthPicker = false,
    allowSameEndDate = true,
    useNoEndDate = true,
    value,
    onChange,
}) => {
    const { t } = useTranslation();
    const onChangeRef = useRef(onChange);

    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    const validityStateManager = useMemo(() => {
        const validationState = validateStartEndDate(value);
        return new ValidityStateManager(validationState);
    }, [value]);

    const RenderedFields = useMemo(() => {
        // to avoid re-rendering if moment object changes
        const DatePicker = monthPicker ? AppMonthYearPicker : AppDatePicker;
        return (
            <>
                <Col md={colSize}>
                    <DatePicker
                        readOnly={readonly}
                        appendToBody={appendToBody}
                        label={
                            startLabel ? startLabel : t("common.startDate.name")
                        }
                        value={value.StartDate}
                        minDate={minStartDate}
                        maxDate={maxStartDate}
                        forMonthStart={monthPicker}
                        onChange={(val: Optional<Moment>) => {
                            if (!isNil(val)) {
                                onChangeRef.current({
                                    StartDate: val,
                                    EndDate:
                                        value.EndDate && value.EndDate <= val
                                            ? computeMinEndDate(
                                                  allowSameEndDate,
                                                  val
                                              )
                                            : value.EndDate,
                                });
                            }
                        }}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "StartDate"
                        )}
                    />
                </Col>
                {(value.EndDate || !useNoEndDate) && (
                    <Col md={useNoEndDate ? Math.ceil(colSize / 2) : colSize}>
                        <DatePicker
                            readOnly={readonly}
                            appendToBody={appendToBody}
                            label={t("common.endDate.name")}
                            value={value.EndDate}
                            maxDate={maxEndDate}
                            minDate={computeMinEndDate(
                                allowSameEndDate,
                                value.StartDate
                            )}
                            forMonthEnd={monthPicker}
                            onChange={(val: Optional<Moment>) => {
                                if (!isNil(val)) {
                                    onChangeRef.current({
                                        ...value,
                                        EndDate: val,
                                    });
                                } else {
                                    onChangeRef.current({
                                        ...value,
                                        EndDate: null,
                                    });
                                }
                            }}
                            showEmptyError={true}
                            error={validityStateManager.getFirstErrorInfo(
                                "EndDate"
                            )}
                        />
                    </Col>
                )}
                {useNoEndDate && (
                    <Col md={value.EndDate ? Math.floor(colSize / 2) : colSize}>
                        <AppSwitch
                            className={styles.hasNoEndDate}
                            label={
                                toggleLabel
                                    ? toggleLabel
                                    : t("common.endDate.hasNoEndDate")
                            }
                            disabled={readonly || requiredEndDate}
                            value={value.EndDate == null}
                            labelPosition="right"
                            onChange={(checked) => {
                                if (checked == true) {
                                    onChangeRef.current({
                                        ...value,
                                        EndDate: null,
                                    });
                                } else {
                                    onChangeRef.current({
                                        ...value,
                                        EndDate: computeMinEndDate(
                                            allowSameEndDate,
                                            value.StartDate
                                        ),
                                    });
                                }
                            }}
                        />
                    </Col>
                )}
            </>
        );
    }, [
        t,
        value.StartDate?.format(ISO8601_DATE_FORMAT),
        value.EndDate?.format(ISO8601_DATE_FORMAT),
        maxEndDate,
        maxStartDate,
        minStartDate,
    ]);
    return RenderedFields;
};

export const StartAndEndDateFields = React.memo(Component);
export default StartAndEndDateFields;
