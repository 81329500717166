import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import {
    getFilteredArray,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { isNil, orderBy } from "lodash-es";
import {
    BusinessType,
    Business_Courses,
    LW_Industry_Courses,
    LW_Kindergarten_Courses,
} from "models";
import { BusinessCoursesSortColumn } from "models/businessCourses/enum";
import {
    getBusinessCoursesFromResponse,
    getBusinessCoursesSortColumnKeyFromEnum,
} from "models/businessCourses/helper";
import {
    BusinessCourse,
    BusinessCourseRequestParameters,
} from "models/businessCourses";
import { SortOrder, TableSort } from "models/general";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import BusinessCoursesFilterRow from "./partials/table/BusinessCoursesFilterRow";
import { BusinessCoursesRow } from "./partials/table/BusinessCoursesRow";
import BusinessCoursesService, {
    getBusinessCoursesServiceKey,
} from "services/business/BusinessCoursesService";
import styles from "commonPartials/commonStyles.module.scss";
import { useTranslation } from "react-i18next";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";

interface BusinessCoursesState {
    filter: BusinessCourseRequestParameters;
    sort: TableSort<BusinessCoursesSortColumn>;
    list: BusinessCourse[];
    courseId?: string; //in case of edit
}

const COLUMNS_COUNT = 8;
export const BusinessCoursesList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useBusinessCheckPermission();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { lwcId } = useParams();
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();
    const isLwc = !isNil(lwcId);
    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();
    const permMap = !isLwc
        ? getPermissionMap(
              currentBusiness?.Id as number,
              currentBusiness?.HqId as number,
              Business_Courses
          )
        : getPermissionMap(
              sessionBusinessId,
              sessionHqId,
              currentBusiness?.Type == BusinessType.KINDERGARTEN
                  ? LW_Kindergarten_Courses
                  : LW_Industry_Courses
          );

    const [state, setState] = useState<BusinessCoursesState>({
        filter: {} as BusinessCourseRequestParameters,
        sort: {
            SortColumn: BusinessCoursesSortColumn.Name,
            SortOrder: SortOrder.ASC,
        },
        list: [],
    });

    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = isLwc
        ? (currentBusiness?.Type == BusinessType.KINDERGARTEN
              ? lwcBase.kindergartens
              : lwcBase.industries
          ).withId()
        : linkProvider.business.businessSettings().screens;

    const businessCoursesService = new BusinessCoursesService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).courses
    );

    const { isFetching: loading, refetch } = useQuery(
        getBusinessCoursesServiceKey("list", {
            businessId: sessionBusinessId,
            ...state.filter,
        }),
        async () => await businessCoursesService.list(),
        {
            enabled: false,
            onSuccess: (resp) => {
                const newList = resp.Data.map((x) =>
                    getBusinessCoursesFromResponse(x)
                );
                setState({
                    ...state,
                    list: newList,
                });
            },
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    const onSortChange = (
        sortColumn: BusinessCoursesSortColumn,
        sortOrder: SortOrder
    ) => {
        setState({
            ...state,
            sort: {
                ...state.sort,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };

    const list = useMemo(() => {
        const filteredList = getFilteredArray(state.list, state.filter);

        let orderList = filteredList;
        orderList = orderBy(
            orderList,
            getBusinessCoursesSortColumnKeyFromEnum(state.sort.SortColumn),
            state.sort.SortOrder == SortOrder.ASC ? "asc" : "desc"
        );
        return orderList;
    }, [state]);

    const { mutate: deleteCourse } = useMutation(
        async (id?: number) => await businessCoursesService.delete(id),
        {
            onSuccess: (res) => {
                const toDelete = state.list.filter((x) => x.Id == res.Data)[0];
                showSweetAlertToast(
                    t("common.success"),
                    `"${toDelete.Name}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
                setState((old) => ({
                    ...old,
                    list: old.list.filter((x) => x.Id != res.Data),
                }));
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    return (
        <div>
            <AppContainer
                classes={{ body: styles.appContainerWithLessTopPadding }}
            >
                {permMap.CREATE && (
                    <AppIconTextButton
                        padding="med"
                        className="mb-2"
                        icon="plus"
                        text={t("common.create")}
                        onClick={() => {
                            navigate(linkProviderBase.courses.create());
                        }}
                    />
                )}

                <div>
                    <AppTable
                        heightToAdjust={190}
                        mobileViewAdjustment={50}
                        mediumViewAdjustment={50}
                        hover={true}
                    >
                        <thead>
                            <tr>
                                <td style={getFixedCssWidths(60)}></td>
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t("business.courses.table.name")}
                                    sortColumn={BusinessCoursesSortColumn.Name}
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.Name
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t("business.courses.table.shortName")}
                                    sortColumn={
                                        BusinessCoursesSortColumn.ShortName
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.ShortName
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t("business.courses.table.floor")}
                                    sortColumn={BusinessCoursesSortColumn.Floor}
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.Floor
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t("business.courses.table.area")}
                                    sortColumn={BusinessCoursesSortColumn.Area}
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.Area
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t("common.startDate.name")}
                                    sortColumn={
                                        BusinessCoursesSortColumn.StartDate
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.StartDate
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t("common.endDate.name")}
                                    sortColumn={
                                        BusinessCoursesSortColumn.EndDate
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        BusinessCoursesSortColumn.EndDate
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />

                                <th style={getFixedCssWidths(60)}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <BusinessCoursesFilterRow
                                value={state.filter}
                                onChange={(filter) =>
                                    setState({ ...state, filter: filter })
                                }
                            />

                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                            {list?.length > 0 ? (
                                list.map((b, idx) => (
                                    <BusinessCoursesRow
                                        key={`${idx}_${b.Id}`}
                                        value={b}
                                        permMap={permMap}
                                        onDeleteClick={(id) => deleteCourse(id)}
                                        onClick={(id) => {
                                            navigate(
                                                linkProviderBase.courses.edit(
                                                    id
                                                )
                                            );
                                        }}
                                    />
                                ))
                            ) : loading ? (
                                <AppTableSkeleton
                                    columns={COLUMNS_COUNT}
                                    rows={10}
                                />
                            ) : (
                                <tr>
                                    <td
                                        colSpan={COLUMNS_COUNT}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t(
                                            "business.courses.table.noCoursesExist"
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                        </tbody>
                    </AppTable>
                </div>
            </AppContainer>
        </div>
    );
};
export default BusinessCoursesList;
