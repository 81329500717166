import { getGenderNumberToEnumValue } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import { isNil } from "lodash-es";
import {
    ClientRegistrationContact,
    ClientRegistrationCustodianType,
    getClientRegistrationContactInitialValues,
    KindergartenClientRegistration,
} from "models/kindergartenClientRegistration";
import {
    checkPermissionInMap,
    Kindergarten_Client_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ClientContactInfoFields } from "screens/kindergarten/partials/ClientContactInfoFields";
import { KindergartenClientRegistrationService } from "services/kindergarten/KindergartenClientRegistrationService";
import { ClientContactActionMenu } from "../../partials/ClientContactActionMenu";

export interface ClientRegistrationContactInfoEditProps {
    title: string;
    type: ClientRegistrationCustodianType;
    completeData: KindergartenClientRegistration;
    contactData: ClientRegistrationContact;
    indexId: number;
    onCustodianChange: (client: KindergartenClientRegistration) => void;
}
export const ClientRegistrationContactInfoEdit: React.FC<
    ClientRegistrationContactInfoEditProps
> = ({
    title,
    completeData,
    onCustodianChange,
    type: type,
    contactData,
    indexId,
}) => {
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();

    const permMap = useMemo(() => {
        return getPermissionMap(Kindergarten_Client_BasicData);
    }, [getPermissionMap]);

    const hasDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const [state, setState] = useState<ClientRegistrationContact>(
        getClientRegistrationContactInitialValues(completeData.Id, type)
    );

    useEffect(() => {
        if (contactData) {
            setState(contactData);
        }
    }, [contactData]);

    const { t } = useTranslation();

    const clientRegistrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );
    const {
        isLoading: deleteLoading,
        data: deleteResponse,
        mutate: deleteClientContact,
    } = useMutation(
        async (val: { contactId: string }) =>
            await clientRegistrationService.deleteContact(
                val.contactId,
                completeData.EncodedId as string
            )
    );

    const {
        isLoading: loadingCreate,
        data: createResponse,
        mutate: create,
    } = useMutation(
        async (val: ClientRegistrationContact) =>
            await clientRegistrationService.contactCreateUpdate(
                val,
                completeData.EncodedId as string
            )
    );

    useEffect(() => {
        if (createResponse && !loadingCreate) {
            if (createResponse.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createResponse.Data.FirstName} ${
                        createResponse.Data.LastName
                    }" ${t("common.actions.updatedSuccessfully")}`,
                    "success"
                );

                const newContact = {
                    ...createResponse.Data,
                    Gender: isNil(createResponse.Data.Gender)
                        ? null
                        : getGenderNumberToEnumValue(
                              Number(createResponse.Data.Gender)
                          ),
                };
                setState(newContact);
                onCustodianChange({
                    ...completeData,
                    Parents: completeData.Parents.map((value, index) => {
                        if (index == indexId) {
                            return newContact;
                        }
                        return value;
                    }),
                });
            } else if (createResponse.Message) {
                showSweetAlertToast(
                    t("common.error.error"),
                    createResponse.Message,
                    "error"
                );
            }
        }
    }, [createResponse, loadingCreate]);

    const deleteFromContactsList = () => {
        onCustodianChange({
            ...completeData,
            Parents: completeData.Parents.map((v, index) => {
                if (index != indexId) {
                    return v;
                } else {
                    // add new empty custodian of same type
                    return {
                        ...getClientRegistrationContactInitialValues(
                            completeData.Id as number,
                            type
                        ),
                        Type: type,
                    };
                }
            }),
        });
    };
    const deleteClientContactHandler = () => {
        if (state.Id) {
            deleteClientContact({
                contactId: state.Id.toString(),
            });
        } else {
            deleteFromContactsList();
        }
    };
    const custodianNameStr = `${defaultTo(state.FirstName, "")} ${defaultTo(
        state.LastName,
        ""
    )}`;
    useEffect(() => {
        if (!deleteLoading && deleteResponse && deleteResponse.Data) {
            showSweetAlertToast(
                t("common.success"),
                `"${custodianNameStr}" ${t(
                    "common.actions.deletedSuccessfully"
                )}`,
                "success"
            );
            deleteFromContactsList();
        }
    }, [deleteLoading, deleteResponse]);

    return (
        <ClientContactInfoFields
            isEmailInUse={(email: string) =>
                completeData.Parents.filter(
                    (x, index) => x.Email === email && index != indexId
                ).length > 0
            }
            title={title}
            permMap={permMap}
            loading={loadingCreate}
            value={state}
            onChange={(val) => setState((s) => ({ ...s, ...val }))}
            onSave={() => create(state)}
            responseErrors={createResponse?.Errors ? createResponse : undefined}
            HeaderActionMenu={
                <ClientContactActionMenu
                    onAddNew={() =>
                        onCustodianChange({
                            ...completeData,
                            Parents: [
                                ...completeData.Parents,
                                getClientRegistrationContactInitialValues(
                                    completeData.Id,
                                    type
                                ),
                            ],
                        })
                    }
                    deleteName={custodianNameStr}
                    onDelete={deleteClientContactHandler}
                    showAddNew={completeData.Parents.length < 2}
                    showDelete={
                        defaultTo(contactData.Id, 0) > 0 ||
                        completeData.Parents.length > 1
                    }
                    readonly={!hasDeletePermission}
                />
            }
        />
    );
};

export default ClientRegistrationContactInfoEdit;
