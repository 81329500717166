export enum NotificationStatus {
    INFO = "Info",
    FAILURE = "Failure",
    SUCCESS = "Success",
    WARNING = "Warning",
}

export enum NotificationFilterEnum {
    All = "all",
    UNREAD = "unRead",
    RECENT = "recent",
}

export enum WebNotificationType {
    IpadRegister,
    TodoRepeat,
    TodoReminder,
    LwcDelete,
    LwDelete,
    ClientsImport,
    ClientRegistrationsImport,
    DeleteAllClients,
    DeleteAllClientRegistrations,
    EmployeesImport,
    DeleteAllEmployees,
    HqRegisterRequest,
    ClientEmployeeDocumentCompilation,
    KindergartenClientAccountingExcel,
    BusinessEmployeeAccountingExcel,
    KindergartenExternalRegistrationFromApi,
    BusinessSupplierAccountingExcel
}
export enum WebEventType {
    Refresh_Kindergarten_Client_List = 0,
    Refresh_Kindergarten_Client_Registration_List = 1,
    Refresh_Employee_List = 2,
}
