import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { BusinessType } from "models";
import { NavigationTab } from "models/general";
import {
    LW_ElderlyCare_BasicData,
    LW_ElderlyCare_Contracts,
    LW_ElderlyCare_Documents,
    LW_ElderlyCare_PublicHolidays,
    LW_ElderlyCare_WorkingDays,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export interface LwcElderlyCareTabsProps {
    onActiveTabChange: (tab: NavigationTab) => void;
}
export const LwcElderlyCareTabs: React.FC<LwcElderlyCareTabsProps> = ({
    onActiveTabChange,
}) => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const { urlForLwc: iframeRouteForLwc } = useIFrameRouting();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.lillywait
        .clients()
        .screens.elderlyCares.withId(lwcId);
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const getTranslation = (tabName: string) => t(`business.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(LW_ElderlyCare_BasicData),
                url: linkBase.edit(),
                iframeUrl: iframeRouteForLwc(
                    "BasicData",
                    BusinessType.ELDERLY_CARE
                ), // to-do remove this and use BusinessEditFields
            },
            {
                label: getTranslation("contracts"),
                hasAccess: checkPermission([LW_ElderlyCare_Contracts]),
                url: linkBase.contracts(),
                iframeUrl: iframeRouteForLwc(
                    "Contracts",
                    BusinessType.ELDERLY_CARE
                ),
            },
            {
                label: getTranslation("documents"),
                hasAccess: checkPermission(LW_ElderlyCare_Documents),
                url: linkBase.documents(),
                iframeUrl: iframeRouteForLwc(
                    "Documents",
                    BusinessType.ELDERLY_CARE
                ),
            },
            {
                label: getTranslation("publicHolidays"),
                hasAccess: checkPermission(LW_ElderlyCare_PublicHolidays),
                url: linkBase.publicHolidays(),
            },
            {
                label: getTranslation("openingHours"),
                hasAccess: checkPermission(LW_ElderlyCare_WorkingDays),
                url: linkBase.workingHours(),
            },
            {
                label: getTranslation("externalIntegration"),
                hasAccess: checkPermission(LW_ElderlyCare_BasicData),
                url: linkBase.externalIntegration(),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={6}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default LwcElderlyCareTabs;
