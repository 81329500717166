import { SalaryGroupTemplateCreateEdit } from "commonPartials";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { LocationSettings_SalaryGroups } from "models";
import React from "react";
import { useParams } from "react-router";
import { useCurrentLocationSettingsContext } from "../LocationSettingTabs";

export const LocationSalaryGroupTemplateEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();

    const { id } = useParams();
    const currentLocation = useCurrentLocationSettingsContext();

    const permMap = getPermissionMap([LocationSettings_SalaryGroups]);

    const linkProviderBase = linkProvider.lillywait
        .locationSettings()
        .screens.withId().salaryGroups;
    return (
        <SalaryGroupTemplateCreateEdit
            id={id}
            currentBusiness={null}
            locationId={currentLocation.dataItem?.Id}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
        />
    );
};

export default LocationSalaryGroupTemplateEdit;
