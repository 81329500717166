import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import { Validations } from "globals/helpers/validationHelpers";
import {
    ValidityStateManager,
    getInitializedValidityState,
    Optional,
} from "models/general";
import { useMemo, useState } from "react";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PASSWORD_KEY: string = "password";
const MASTER_PASSWORD_KEY: string = "masterPassword";
export interface DeleteConfirmPasswordDialogProps extends ModalProps {
    modalOpen: boolean;
    onClose: () => void;
    requireMasterPassword?: boolean;
    onChange: (password: string, masterPassword?: string) => void;
    buttonText?: string;
}

interface DeleteWithPasswordState {
    password: Optional<string>;
    masterPassword: Optional<string>;
    // isPasswordDialogOpen: boolean;
}
export function DeleteConfirmPasswordDialog(
    props: DeleteConfirmPasswordDialogProps
) {
    const { t } = useTranslation();
    const {
        onClose,
        onChange,
        requireMasterPassword = false,
        buttonText = t("common.submit"),
        ...rest
    } = props;
    const [state, setState] = useState<DeleteWithPasswordState>({
        password: "",
        masterPassword: null,
    });

    const validityStateManager = useMemo(() => {
        let mgr = new ValidityStateManager(getInitializedValidityState([], []));
        if (!state.password || state.password.length == 0) {
            mgr = mgr.replaceErrors(PASSWORD_KEY, {
                message: t(`passwordDialog.passwordRequired`),
                code: Validations.REQUIRED,
            });
        }
        if (
            requireMasterPassword &&
            (!state.masterPassword || state.masterPassword.length == 0)
        ) {
            mgr = mgr.replaceErrors(MASTER_PASSWORD_KEY, {
                message: t(`passwordDialog.deletionPasswordRequired`),
                code: Validations.REQUIRED,
            });
        }
        return mgr;
    }, [state]);

    return (
        <AppDialog
            onClose={onClose}
            footer={
                <AppDialogFooter
                    saveButtonText={buttonText}
                    disableSaveButton={!validityStateManager.isStateValid()}
                    onClickSaveButton={() =>
                        onChange(
                            state.password as string,
                            state.masterPassword as string
                        )
                    }
                    onDialogClose={onClose}
                    variant={"danger"}
                />
            }
            {...rest}
        >
            <div className="flex-column" style={{ margin: "10px 0px" }}>
                <p>{t(`passwordDialog.infoMessage`)}</p>
            </div>
            <AppInputField
                label={t(`passwordDialog.passwordLabel`)}
                type="password"
                value={state.password}
                onValueChange={(val) => {
                    setState({ ...state, password: val });
                }}
                error={validityStateManager.getFirstErrorInfo(PASSWORD_KEY)}
            />
            {requireMasterPassword && (
                <AppInputField
                    label={t(`passwordDialog.deletionPasswordLabel`)}
                    type="password"
                    value={state.masterPassword}
                    onValueChange={(val) => {
                        setState({ ...state, masterPassword: val });
                    }}
                    error={validityStateManager.getFirstErrorInfo(
                        MASTER_PASSWORD_KEY
                    )}
                />
            )}
        </AppDialog>
    );
}
export default DeleteConfirmPasswordDialog;
