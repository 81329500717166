import { AppDeleteButton } from "components/Buttons";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { PermissionAccessMap } from "models";
import { EmployeeContract } from "models/employeeContract";
import { isTemplateActive } from "models/general";
import { useTranslation } from "react-i18next";
import { COLOR_LIGHT_GREEN } from "theme/themeConstants";
import styles from "./EmployeeContractRow.module.scss";

export interface EmployeeContractDataRowProps {
    value: EmployeeContract;
    permMap: PermissionAccessMap;
    onDeleteClick: (id?: number) => void;
    onClick: (id: string) => void;
}

export const EmployeeContractRow: React.FC<EmployeeContractDataRowProps> = ({
    value,
    onDeleteClick,
    permMap,
    onClick,
}) => {
    const { getDateFormatForLocale, formatNumber } = useLocaleHelpers();
    const { t } = useTranslation();

    return (
        <tr
            onClick={() => {
                if (permMap.EDIT && value.EncodedId) {
                    onClick(value.EncodedId);
                }
            }}
            key={value.Id}
            style={
                isTemplateActive({
                    StartDate: value.ContractStart,
                    EndDate: value.ContractEnd,
                })
                    ? {
                          backgroundColor: COLOR_LIGHT_GREEN,
                      }
                    : undefined
            }
        >
            <td className={"ellipsis-text single-line"}>
                <span style={{ paddingLeft: "10px" }}>
                    {value.ContractStart?.format(getDateFormatForLocale())}
                </span>
            </td>

            <td className={"ellipsis-text single-line"}>
                {value.ContractEnd
                    ? value.ContractEnd.format(getDateFormatForLocale())
                    : t("common.endDate.hasNoEndDate")}
            </td>

            <td className={"ellipsis-text single-line"}>
                <span className={styles.textEllipse}>
                    {value.MonthsTrialPeriod}
                </span>
            </td>
            <td className={"ellipsis-text single-line"}>
                {value.ContractEnd?.format(getDateFormatForLocale())}
            </td>
            <td className={"ellipsis-text single-line"}>
                <span className={styles.textEllipse}>
                    {value.MonthsExpiration}
                </span>
            </td>
            <td className={"ellipsis-text single-line"}>
                {value.ExpirationDate?.format(getDateFormatForLocale())}
            </td>
            <td className={"ellipsis-text single-line"}>
                <span className={styles.textEllipse}>
                    {formatNumber(value.RealWeeklyHours)}
                </span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span className={styles.textEllipse}>
                    {value.WorkingDaysAWeek}
                </span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <span className={styles.textEllipse}>
                    {value.PauseInMinutes}
                </span>
            </td>
            <td className={"ellipsis-text single-line"}>
                <AppDeleteButton
                    getConfirmation={true}
                    readonly={!permMap.DELETE}
                    onClick={() => onDeleteClick(value.Id ? value.Id : 0)}
                />
            </td>
        </tr>
    );
};
export default EmployeeContractRow;
