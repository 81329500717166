import { HandleOutsideClick } from "hoc";
import { isNil } from "lodash-es";
import { Optional } from "models/general";
import { MutableRefObject, ReactNode, useRef } from "react";
import { Animated, AnimationString } from "react-animated-css";

export interface AnimatedAppHeaderContainerProps {
    open: boolean;
    children?: ReactNode | undefined;
    triggerOnClick?: boolean;
    positionTop?: number;
    positionRight?: number;
    setOpen: (val: boolean, useHoverLogic?: boolean) => void;
    triggerRef?: MutableRefObject<Optional<HTMLDivElement>>;
    animationIn?: AnimationString;
    animationOut?: AnimationString;
}

function Component(props: AnimatedAppHeaderContainerProps) {
    const {
        open,
        setOpen,
        children,
        triggerOnClick = true,
        positionTop = 50,
        positionRight,
        triggerRef: tRef,
        animationIn,
        animationOut,
    } = props;
    const contentRef = useRef<any>(undefined);
    // Normally, just render children
    const animatedDiv = (
        // @ts-ignore
        <Animated
            animationIn={animationIn}
            animationOut={animationOut}
            animationInDuration={500}
            animationOutDuration={200}
            animateOnMount={false}
            style={{
                position: "absolute",
                top: `${positionTop}px`,
                ...(!isNil(tRef) && tRef.current
                    ? {
                          right:
                              positionRight != undefined
                                  ? `${positionRight}px`
                                  : `${
                                        tRef.current.clientWidth
                                            ? tRef.current.clientWidth
                                            : 0
                                    }px`,
                      }
                    : {}),
            }}
            isVisible={open}
        >
            <div ref={contentRef}>{children}</div>
        </Animated>
    );
    return (
        <HandleOutsideClick
            onClick={() => {
                setOpen(false);
            }}
            otherAllowedTargets={tRef ? [tRef, contentRef.current] : []}
        >
            {triggerOnClick ? (
                animatedDiv
            ) : (
                <div
                    onMouseLeave={() => {
                        setOpen(false);
                    }}
                    onMouseEnter={() => {
                        setOpen(true);
                    }}
                    onTouchEnd={(e) => {
                        // to handle touch events
                        setOpen(!open, false);
                        e.preventDefault();
                    }}
                >
                    {animatedDiv}
                </div>
            )}
        </HandleOutsideClick>
    );
}
export const AnimatedAppHeaderContainer = Component;
export default AnimatedAppHeaderContainer;
