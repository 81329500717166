import { ClientEmployeeListHeaderState } from "../ClientEmployeeListHeader";
import { EditableSectionContainer } from "../../editableSection";
import AppIconWithName from "components/AppIconWithName";
import { AppDialog } from "components/Dialogs";
import { AppOverlayLoader } from "components/Loaders";
import { AppTable } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useOpenDocumentPreview } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import {
    DocumentCompileRequest,
    DocumentEditableSectionRequest,
} from "models/documentCompile";
import {
    DocumentTag,
    DocumentTemplateManagerItem,
    DocumentType,
} from "models/documentTemplates";
import { EmployeeType } from "models/employee";
import { UserType } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { DocumentCompileService } from "services/business";
import styles from "./ListDocumentCompilationDialog.module.scss";

export interface ListDocumentCompilationDialogProps {
    selectedIds?: number[];
    documents: DocumentTemplateManagerItem[];
    dialogState: ClientEmployeeListHeaderState;
    setDialogState: (val: ClientEmployeeListHeaderState) => void;
    userType: UserType;
    employeeType?: EmployeeType;
    hasCreatePermission: boolean;
}

export const ListDocumentCompilationDialog: React.FC<
    ListDocumentCompilationDialogProps
> = ({
    documents,
    dialogState,
    selectedIds,
    setDialogState,
    userType,
    hasCreatePermission,
    employeeType,
}) => {
    const { t } = useTranslation();
    const openDocumentPreviewTab = useOpenDocumentPreview();
    const { linkProvider, pathProvider } = useRouting();
    const { getDateTimeFormatForLocale } = useLocaleHelpers();
    const [selectedTemplate, setSelectedTemplate] =
        useState<DocumentTemplateManagerItem>();

    // const [openPdf, setOpenPdf] = useState<boolean>(false);

    const documentCompileService = new DocumentCompileService(
        linkProvider.business.api.documentCompile
    );

    const {
        data: editableCompileRes,
        isLoading: editableCompileLoading,
        mutate: compileWithEditableSection,
    } = useMutation(async (editableRequest: DocumentEditableSectionRequest) =>
        documentCompileService.compileWithEditableSection(editableRequest)
    );

    const getDocumentArea = (types: string[]): DocumentType => {
        const clientOrEmployeeExists =
            selectedIds &&
            selectedIds.length > 0 &&
            types.filter(
                (y) =>
                    y ==
                    (userType == UserType.Client
                        ? DocumentTag.Client
                        : DocumentTag.Employee)
            ).length > 0;

        if (clientOrEmployeeExists) {
            return userType == UserType.Client
                ? DocumentType.Client
                : DocumentType.Employee;
        } else {
            return userType == UserType.Client
                ? DocumentType.Client_List
                : DocumentType.Employee_List;
        }
    };

    const {
        data: nonEditableCompileRes,
        isLoading: nonEditableCompileLoading,
        mutate: compile,
    } = useMutation(async (compileReq: DocumentCompileRequest) =>
        documentCompileService.compile(compileReq)
    );

    const onAllDialogClose = () =>
        setDialogState({
            ...dialogState,
            documentTableDialogOpen: false,
            editableDialogOpen: false,
        });

    const onEditableDialogHandler = (val: boolean) => {
        setDialogState({
            ...dialogState,
            editableDialogOpen: val,
        });
    };

    const directToUrl = (template: DocumentTemplateManagerItem) => {
        if (!dialogState.isEditable) {
            const compileReq = {
                TemplateId: template.Id,
                DocumentArea: getDocumentArea(
                    template.Types.map((x) => x.value as string)
                ),
                SelectedIds:
                    selectedIds && selectedIds.length > 0
                        ? selectedIds
                        : undefined,
                EmployeeType: employeeType,
            } as DocumentCompileRequest;
            compile(compileReq);
        } else {
            if (hasCreatePermission) {
                setSelectedTemplate(template);
                onEditableDialogHandler(true);
            } else {
                showSweetAlertToast(
                    t("common.missingPermission.title"),
                    t("common.missingPermission.desc"),
                    "warning"
                );
            }
        }
    };

    const onFail = () =>
        showSweetAlertToast(
            t("common.error.error"),
            t("documentTemplate.previewError"),
            "error"
        );

    useEffect(() => {
        if (!nonEditableCompileLoading && nonEditableCompileRes) {
            onAllDialogClose();
            if (
                nonEditableCompileRes.Data &&
                nonEditableCompileRes.Data.length > 0
            ) {
                openDocumentPreviewTab(nonEditableCompileRes.Data);

                showSweetAlertToast(
                    t("common.success"),
                    t("documentTemplate.createdSuccess"),
                    "success"
                );
            } else if (
                nonEditableCompileRes.Data != null &&
                nonEditableCompileRes.Data.length == 0
            ) {
                //only for zip file creation
                showSweetAlertToast(
                    t("common.info"),
                    t(
                        "common.documentCompilationDialog.documentCompileMessage"
                    ),
                    "info"
                );
            } else {
                onFail();
            }
        }
    }, [nonEditableCompileLoading, nonEditableCompileRes]);

    useEffect(() => {
        if (!editableCompileLoading && editableCompileRes) {
            onAllDialogClose();
            if (editableCompileRes.Data && editableCompileRes.Data.length > 0) {
                // setOpenPdf(true);
                openDocumentPreviewTab(editableCompileRes.Data);

                showSweetAlertToast(
                    t("common.success"),
                    t("documentTemplate.createdSuccess"),
                    "success"
                );
            } else if (
                editableCompileRes.Data != null &&
                editableCompileRes.Data.length == 0
            ) {
                //only for zip file creation
                showSweetAlertToast(
                    t("common.info"),
                    t(
                        "common.documentCompilationDialog.documentCompileMessage"
                    ),
                    "info"
                );
            } else {
                onFail();
            }
        }
    }, [editableCompileLoading, editableCompileRes]);

    return (
        <>
            {
                !dialogState.editableDialogOpen ? (
                    <AppDialog
                        title={t("templateDocumentManager.documents")}
                        modalOpen={dialogState.documentTableDialogOpen}
                        size="xl"
                        keyboard={false}
                        onClose={() =>
                            setDialogState({
                                ...dialogState,
                                documentTableDialogOpen: false,
                            })
                        }
                    >
                        {(editableCompileLoading ||
                            nonEditableCompileLoading) && (
                            <AppOverlayLoader fullHeight={false} />
                        )}
                        <AppTable
                            containerClass={`${styles.tableOuter}`}
                            tableClass={"table-striped"}
                            hover={true}
                        >
                            <thead>
                                <tr>
                                    <th style={getFixedCssWidths(500)}>
                                        {t(
                                            "templateDocumentManager.documentName"
                                        )}
                                    </th>
                                    <th style={getFixedCssWidths(200)}>
                                        {t(
                                            "common.documentCompilationDialog.documentArea"
                                        )}
                                    </th>
                                    <th style={getFixedCssWidths(200)}>
                                        {t("templateDocumentManager.created")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={3} />
                                </tr>
                                <tr>
                                    <td colSpan={3} />
                                </tr>
                                {documents &&
                                    documents.length &&
                                    documents
                                        .filter(
                                            (x) =>
                                                x.IsEditable ==
                                                dialogState.isEditable
                                        )
                                        .map((x, idx) => (
                                            <tr
                                                key={idx}
                                                onClick={() => directToUrl(x)}
                                            >
                                                <td>
                                                    <AppIconWithName
                                                        text={x.Name}
                                                        className={
                                                            styles.iconName
                                                        }
                                                        iconPath={
                                                            x.IsEditable
                                                                ? ImageAssets
                                                                      .documentManager
                                                                      .doc
                                                                : ImageAssets
                                                                      .documentManager
                                                                      .pdf
                                                        }
                                                        iconClass={styles.icon}
                                                    />
                                                </td>
                                                <td>
                                                    {x.Types.map((i, index) => (
                                                        <span key={index}>
                                                            {t(
                                                                `templateDocumentManager.documentType.${i.label.toLocaleLowerCase()}`
                                                            )}
                                                            {index + 1 !=
                                                                x.Types
                                                                    .length &&
                                                                ", "}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td>
                                                    {x.CreatedAt.format(
                                                        getDateTimeFormatForLocale()
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                <tr>
                                    <td colSpan={3} />
                                </tr>
                            </tbody>
                        </AppTable>
                    </AppDialog>
                ) : (
                    // editable dialog
                    selectedTemplate && (
                        <AppDialog
                            title={t("templateDocumentManager.documents")}
                            modalOpen={dialogState.editableDialogOpen}
                            size="xl"
                            keyboard={false}
                            onClose={() => onEditableDialogHandler(false)}
                        >
                            <EditableSectionContainer
                                documentType={getDocumentArea(
                                    selectedTemplate.Types.map(
                                        (x) => x.value as string
                                    )
                                )}
                                userType={userType}
                                employeeType={employeeType}
                                templateId={selectedTemplate.Id}
                                selectedIds={selectedIds}
                                onResumeClick={(req) => {
                                    compileWithEditableSection(req);
                                    onEditableDialogHandler(false);
                                }}
                            />
                        </AppDialog>
                    )
                )
                // : (
                //     <PdfViewerPage
                //         docKey={
                //             dialogState.isEditable
                //                 ? editableCompileRes?.Data
                //                 : nonEditableCompileRes?.Data
                //         }
                //         openInNewTab={true}
                //         onCloseClick={() => {
                //             setOpenPdf(false);
                //         }}
                //     />
                // )
            }
        </>
    );
};

export default ListDocumentCompilationDialog;
