import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import { ValidityStateManager, ValidityState } from "models/general";
import { AppInputField } from "components/FormFields";
import { useTranslation } from "react-i18next";
import { DragHandle } from "components/Sortable";
import { AppDeleteButton } from "components/Buttons";
import { CareScopeTemplateSurchargeField } from "models/careScopeTemplate";
import styles from "./CareScopeTemplateRow.module.scss";

export interface CareScopeTemplateSurchargeFieldRowProps {
    value: CareScopeTemplateSurchargeField;
    isLocation?: boolean;
    onChange: (field: CareScopeTemplateSurchargeField) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
}

export const CareScopeTemplateSurchargeFieldRow: React.FC<
    CareScopeTemplateSurchargeFieldRowProps
> = ({ value, onChange, validityState, onDelete, readonly = false }) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />

                <Form.Row>
                    <Col sm={4} xs={12} md={4}>
                        <AppInputField
                            label={t("templates.name.name")}
                            showEmptyError={true}
                            value={value.Name}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Name: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.name.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Name"
                            )}
                        />
                    </Col>
                    <Col sm={3} xs={12} md={3}>
                        <AppInputField
                            label={t("templates.shortName.name")}
                            showEmptyError={true}
                            value={value.ShortName}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    ShortName: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.shortName.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "ShortName"
                            )}
                        />
                    </Col>
                    <Col sm={5} xs={12} md={5}>
                        <AppInputField
                            label={t("templates.description")}
                            showEmptyError={true}
                            value={value.Description}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Description: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.description")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Description"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default CareScopeTemplateSurchargeFieldRow;
