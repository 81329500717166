import { BusinessSettingRoutingContext } from "routing/routingContexts";
import {
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";

// for company setting routes
export const businessSettingRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: BusinessSettingRoutingContext = new BusinessSettingRoutingContext()
) => {
    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {},
        screens: {
            basicData: () => getUrlForPattern(context, buildUrl, "basic-data"),
            contracts: () => getUrlForPattern(context, buildUrl, "contracts"),
            renewContract: () =>
                getUrlForPattern(context, buildUrl, "contracts/renew-contract"),
            ipads: () => getUrlForPattern(context, buildUrl, "ipads"),
            publicHolidays: () =>
                getUrlForPattern(context, buildUrl, "public-holidays"),
            kontos: () => getUrlForPattern(context, buildUrl, "kontos"),
            workingHours: () =>
                getUrlForPattern(context, buildUrl, "working-hours"),
            basicSettings: () =>
                getUrlForPattern(context, buildUrl, "basic-settings"),
            chatSettings: () =>
                getUrlForPattern(context, buildUrl, "chat-settings"),
            timeTracking: () =>
                getUrlForPattern(context, buildUrl, "time-tracking"),
            logo: () => getUrlForPattern(context, buildUrl, "logo"),
            externalIntegration: () => getUrlForPattern(context, buildUrl, "external-integration"),
            backgroundJobs: () =>
                getUrlForPattern(context, buildUrl, "background-jobs"),
            data: () => getUrlForPattern(context, buildUrl, "data"),
            accessControl: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`access-control`),
            compensationTemplates: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`compensation-templates`),
            careScopes: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`care-scopes`),
            ageGroups: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`age-groups`),
            salaryGroups: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`salary-groups`),
            employeeKeyCalculations: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`employee-key-calculations`),
            courses: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`courses`),
            groups: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`groups`),
            areas: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`areas`),
            shifts: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`shifts`),
            functions: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`functions`),
            printers: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`printers`),
            printJobs: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`print-jobs`),
            mealTemplates: ((sectionBase: string) => {
                return {
                    ...getCrudUrlsForSection(sectionBase, context, buildUrl),
                    meals: (encodedId: string | ":id" = ":id") =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/${encodedId}/meals`
                        ),
                };
            })(`meal-templates`),
        },
    };
};

export default businessSettingRoutes;
