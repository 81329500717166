import { AppDatePicker, AppNumberInput } from "components/FormFields";
import { FORMAT_INTEGER } from "globals/constants";
import { EmployeeContractFilters } from "models/employeeContract";
import { Optional } from "models/general";
import { Moment } from "moment-timezone";

export interface EmployeeContractFilterRowProps {
    value: EmployeeContractFilters;
    onChange: (value: EmployeeContractFilters) => void;
}

export const EmployeeContractFilterRow: React.FC<
    EmployeeContractFilterRowProps
> = ({ value, onChange }) => {
    return (
        <tr className="filters-row">
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    appendToBody={true}
                    value={value.ContractStart}
                    onChange={(val: Optional<Moment>) => {
                        onChange({
                            ...value,
                            ContractStart: val as Moment,
                        });
                    }}
                    useDefault={false}
                />
            </td>
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    value={value.ContractEnd}
                    appendToBody={true}
                    onChange={(val: Optional<Moment>) => {
                        onChange({
                            ...value,
                            ContractEnd: val,
                        });
                    }}
                    useDefault={false}
                />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <AppNumberInput
                    className="mb-0"
                    showClearButton={true}
                    showPlaceHolder={false}
                    showLabel={false}
                    showEmptyField={true}
                    value={value.RealWeeklyHours}
                    allowZero={true}
                    format={FORMAT_INTEGER}
                    onChange={(val) =>
                        onChange({
                            ...value,
                            RealWeeklyHours: val as number,
                        })
                    }
                />
            </td>
        </tr>
    );
};

export default EmployeeContractFilterRow;
