import { AppInputField, AppNumberInput } from "components/FormFields";
import { FORMAT_INTEGER } from "globals/constants";
import { AbsentsTableFilters } from "models";
import React from "react";

export interface AbsentsTableFilterRowProps {
    value: AbsentsTableFilters;
    onChange: (val: AbsentsTableFilters) => void;
}

export const AbsentsTableFilterRow: React.FC<AbsentsTableFilterRowProps> = ({
    value,
    onChange,
}) => {
    return (
        <tr className="filters-row">
            <td>
                <AppNumberInput
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    showPlaceHolder={false}
                    format={FORMAT_INTEGER}
                    value={value?.DisplayId}
                    onChange={(e) => {
                        onChange({ ...value, DisplayId: e });
                    }}
                />
            </td>
            <td>
                <AppInputField
                    showClearButton={true}
                    value={value.Name}
                    onValueChange={(val) => onChange({ ...value, Name: val })}
                />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    );
};

export default AbsentsTableFilterRow;
