import { AppContentHeader } from "components";
import AppFloatingButton from "components/Buttons/AppFloatingButton";
import SearchField from "components/FormFields/SearchField";
import { AppLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import useFetchAllBusinessModuleKeys from "hooks/generalApiCalls/useFetchAllBusinessModuleKeys";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    accessLevelCheckWithAccessPermission,
    AccessPermissionMapping,
    AccessPermissionRequest,
    bindApiResponseViewAccessPermissionModal,
    bindMandatoryWithApiResponse,
    Business_RoleManagement_PermissionGroups_Ipad,
    Business_RoleManagement_PermissionGroups_Mobile,
    Business_RoleManagement_PermissionGroups_Web,
    collectMandatoryNeedToAdd,
    getDefaultSelectedPermissions,
    MandatoryDetail,
    mandatoryPermissionsForAllUsers,
    Permission,
    PermissionAccessTypes,
    PermissionNeedsToDisable,
    PermissionsStateChangeHelper,
    RolePermissionUpdateRequest,
    satisfySearchQuery,
} from "models";
import {
    AppPlatforms,
    DeviceTypeParam,
    deviceTypeParamToEnum,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import {
    getRoleManagementServiceKey,
    RoleManagementService,
} from "services/business";
import { PermissionManagementTable } from "./partials";

export interface PermissionEditState {
    searchValue: string;
    list: AccessPermissionMapping[] | null;
    selectedList: AccessPermissionRequest[];
    checksNeedToDisable: PermissionNeedsToDisable;
    mandatoryList: MandatoryDetail[];
}
export const PermissionEdit: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const showErrorPage = useShowErrorPage();
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();
    const navigate = useNavigate();
    const {
        dataLoaded: allowedPermissionsListLoaded,
        Website: businessAllWebPermissions,
        IPad: businessAllIpadPermissions,
        Mobile: businessAllMobilePermissions,
    } = useFetchAllBusinessModuleKeys();

    const service = new RoleManagementService(
        linkProvider.business.api.roleManagement
    );
    const [state, setState] = useState<PermissionEditState>({
        searchValue: "",
        list: null,
        selectedList: getDefaultSelectedPermissions(),
        checksNeedToDisable: {
            [PermissionAccessTypes.CREATE]: [],
            [PermissionAccessTypes.DELETE]: [],
            [PermissionAccessTypes.EDIT]: [],
            [PermissionAccessTypes.SHOW]: mandatoryPermissionsForAllUsers,
        } as PermissionNeedsToDisable,
        mandatoryList: [],
    });

    const { id, deviceType } = useParams();
    useEffect(() => {
        if (
            id &&
            deviceType &&
            !Object.values(AppPlatforms)
                .map((x) => x.toLocaleLowerCase())
                .includes(deviceType)
        ) {
            showErrorPage(404);
        }
    }, [location.pathname, deviceType, id]);

    const {
        isFetching: loadingGetRolePermissions,
        refetch: refetchRolePermissions,
        data: rolePermissionsResponse,
        error: fetchError,
    } = useQuery(
        getRoleManagementServiceKey("getRolePermissions", id),
        async () => await service.getRolePermissions(defaultTo(id, ""))
    );

    useEffect(() => {
        if (
            !loadingGetRolePermissions &&
            allowedPermissionsListLoaded &&
            rolePermissionsResponse &&
            rolePermissionsResponse.Data
        ) {
            const bindResponseList = bindApiResponseViewAccessPermissionModal(
                rolePermissionsResponse.Data,
                rolePermissionsResponse.Data.Device
            );

            setState({
                ...state,
                selectedList:
                    bindResponseList.length > 0
                        ? bindResponseList
                        : [...state.selectedList],
                list:
                    rolePermissionsResponse.Data.Device === AppPlatforms.WEB
                        ? businessAllWebPermissions
                        : rolePermissionsResponse.Data.Device ===
                          AppPlatforms.MOBILE
                        ? businessAllMobilePermissions
                        : businessAllIpadPermissions,
                checksNeedToDisable: {
                    ...state.checksNeedToDisable,
                    VIEW: [
                        ...state.checksNeedToDisable.VIEW,
                        ...rolePermissionsResponse.Data.Permissions.filter(
                            (x) => {
                                if (x.CanCreate || x.CanDelete || x.CanEdit) {
                                    return x.Identifier;
                                }
                            }
                        ).map((x) => x.Identifier),
                    ],
                },
                mandatoryList: bindMandatoryWithApiResponse([
                    ...rolePermissionsResponse.Data.Permissions,
                ]),
            });
        }
    }, [
        loadingGetRolePermissions,
        businessAllWebPermissions,
        businessAllIpadPermissions,
        businessAllMobilePermissions,
    ]);

    const canEdit = useMemo(() => {
        let permissionString = "";
        switch (deviceTypeParamToEnum(deviceType as DeviceTypeParam)) {
            case AppPlatforms.MOBILE:
                permissionString =
                    Business_RoleManagement_PermissionGroups_Mobile;
                break;
            case AppPlatforms.IPAD:
                permissionString =
                    Business_RoleManagement_PermissionGroups_Ipad;
                break;
            case AppPlatforms.WEB:
                permissionString = Business_RoleManagement_PermissionGroups_Web;
                break;
        }

        return (
            checkPermission(permissionString, [PermissionAccessTypes.EDIT]) &&
            rolePermissionsResponse &&
            rolePermissionsResponse.Data &&
            rolePermissionsResponse.Data.IsEditable
        );
    }, [rolePermissionsResponse, deviceType, checkPermission]);

    useEffect(() => {
        if (
            state.list &&
            rolePermissionsResponse &&
            rolePermissionsResponse.Data
        ) {
            const search = state.searchValue.toLocaleLowerCase();
            const completePermissionList =
                rolePermissionsResponse.Data.Device == AppPlatforms.WEB
                    ? businessAllWebPermissions
                    : rolePermissionsResponse.Data.Device == AppPlatforms.MOBILE
                    ? businessAllMobilePermissions
                    : businessAllIpadPermissions;

            const filterList = completePermissionList
                ? completePermissionList
                      .map((x) => {
                          return satisfySearchQuery(x, search);
                      })
                      .filter((x) => x != null)
                : null;
            setState({
                ...state,
                list: isNil(filterList)
                    ? null
                    : (filterList as AccessPermissionMapping[]),
            });
        }
    }, [state.searchValue]);

    const onPermissionChange = (
        selectPermission: AccessPermissionMapping,
        type: PermissionAccessTypes,
        checked: boolean
    ) => {
        const disabledList = { ...state.checksNeedToDisable };
        const mandatoryNeedToAdd: MandatoryDetail[] = [...state.mandatoryList];
        const permissionNeedToAdd: AccessPermissionRequest[] = [];
        const permissionsNeedToUpdate: AccessPermissionRequest[] = [];

        let copySelectedList = [...state.selectedList];
        if (selectPermission.Childs) {
            selectPermission.Childs.forEach((child) => {
                if (!child.Childs) {
                    if (
                        disabledList[type].find(
                            (x) => x == child.CompleteIdentifier
                        ) == null
                    ) {
                        PermissionsStateChangeHelper(
                            state.selectedList,
                            child,
                            permissionNeedToAdd,
                            permissionsNeedToUpdate,
                            type,
                            checked,
                            disabledList,
                            mandatoryNeedToAdd
                        );
                    }
                } else {
                    child.Childs.forEach((lastChild) => {
                        if (
                            disabledList[type].find(
                                (x) => x == child.CompleteIdentifier
                            ) == null
                        ) {
                            PermissionsStateChangeHelper(
                                state.selectedList,
                                lastChild,
                                permissionNeedToAdd,
                                permissionsNeedToUpdate,
                                type,
                                checked,
                                disabledList,
                                mandatoryNeedToAdd
                            );
                        }
                    });
                }
            });
        } else {
            PermissionsStateChangeHelper(
                state.selectedList,
                selectPermission,
                permissionNeedToAdd,
                permissionsNeedToUpdate,
                type,
                checked,
                disabledList,
                mandatoryNeedToAdd
            );
        }

        if (permissionNeedToAdd.length > 0) {
            copySelectedList = [...copySelectedList, ...permissionNeedToAdd];
        }
        if (permissionsNeedToUpdate.length > 0) {
            copySelectedList = copySelectedList.filter((x) => {
                const itemForUpdate = permissionsNeedToUpdate.find(
                    (y) => y.AccessPermissionKey == x.AccessPermissionKey
                );
                if (
                    itemForUpdate &&
                    (x.CanCreate != false ||
                        x.CanDelete != false ||
                        x.CanView != false ||
                        x.CanEdit != false)
                ) {
                    return itemForUpdate;
                }
                if (!itemForUpdate) {
                    return x;
                }
            });
        }

        collectMandatoryNeedToAdd(copySelectedList, mandatoryNeedToAdd);

        setState({
            ...state,
            selectedList: copySelectedList,
            checksNeedToDisable: disabledList,
            mandatoryList: mandatoryNeedToAdd,
        });
    };
    useMemo(() => {
        const copyState = { ...state };

        copyState.mandatoryList.forEach((mandatory) => {
            //collection all the mandatory which are already selected
            const mandatoryAlreadyExistCheck = copyState.selectedList.filter(
                (x) => x.ParentKey == mandatory.ParentKey
            );
            //if there is only one select mandatory exist
            //it might be possible that this one entry is the mandatory it self and there is not check selected
            //in this case we have to disable it
            //and if length is greater than 1 than check mandatories already selected or not

            if (
                (mandatoryAlreadyExistCheck.length == 1 &&
                    mandatoryAlreadyExistCheck[0].AccessPermissionKey !=
                        mandatory.Identifier) ||
                mandatoryAlreadyExistCheck.length > 1
            ) {
                if (!mandatory.IsProcessed) {
                    if (
                        copyState.selectedList.find(
                            (x) => x.AccessPermissionKey == mandatory.Identifier
                        ) == null
                    ) {
                        copyState.selectedList.push({
                            AccessPermissionKey: mandatory.Identifier,
                            ParentKey: mandatory.ParentKey,
                            CanCreate:
                                mandatory.AccessLevel ==
                                PermissionAccessTypes.CREATE,
                            CanDelete:
                                mandatory.AccessLevel ==
                                PermissionAccessTypes.DELETE,
                            CanEdit:
                                mandatory.AccessLevel ==
                                PermissionAccessTypes.EDIT,
                            CanView:
                                mandatory.AccessLevel ==
                                PermissionAccessTypes.SHOW,
                            UserCount: 0,
                        });
                        if (
                            copyState.checksNeedToDisable[
                                mandatory.AccessLevel
                            ].filter((x) => x == mandatory.Identifier).length ==
                            0
                        ) {
                            copyState.checksNeedToDisable[
                                mandatory.AccessLevel
                            ].push(mandatory.Identifier);
                        }
                        const index = copyState.mandatoryList.findIndex(
                            (x) =>
                                x.Identifier == mandatory.Identifier &&
                                x.AccessLevel == mandatory.AccessLevel
                        );
                        copyState.mandatoryList[index].IsProcessed = true;
                    }
                }
            }
            //disabling the mandatories for which childs are not select and removing it from the mandatory list
            //disabling the mandatory if there is only the mandatory itself  selected
            else {
                if (
                    mandatoryAlreadyExistCheck.length == 1 &&
                    accessLevelCheckWithAccessPermission(
                        mandatory.AccessLevel,
                        mandatoryAlreadyExistCheck[0]
                    )
                ) {
                    copyState.checksNeedToDisable[mandatory.AccessLevel] =
                        copyState.checksNeedToDisable[
                            mandatory.AccessLevel
                        ].filter((x) => x != mandatory.Identifier);
                    copyState.mandatoryList = copyState.mandatoryList.filter(
                        (x) => x.Identifier != mandatory.Identifier
                    );
                }
            }
        });
        setState(copyState);
    }, [state.mandatoryList]);
    const {
        data: updatePermissionResponse,
        mutate: updateRolePermissions,
        isLoading: updatingRolePermissions,
    } = useMutation(
        getRoleManagementServiceKey("updateRolePermission"),
        async (data: RolePermissionUpdateRequest) =>
            await service.updateRolePermission(data)
    );

    const onUpdatePermissionHandler = () => {
        if (rolePermissionsResponse && rolePermissionsResponse.Data) {
            updateRolePermissions({
                ScopeId: rolePermissionsResponse.Data.ScopeId,
                RoleId: rolePermissionsResponse.Data.RoleId,
                Permissions: state.selectedList.map((x) => {
                    return {
                        CanCreate: x.CanCreate,
                        CanDelete: x.CanDelete,
                        CanEdit: x.CanEdit,
                        CanView: x.CanView,
                        Identifier: x.AccessPermissionKey,
                        Id: x.AccessPermissionId ? x.AccessPermissionId : 0,
                    } as Permission;
                }),
            });
        }
    };
    useMemo(() => {
        if (
            !updatingRolePermissions &&
            updatePermissionResponse &&
            updatePermissionResponse.Data
        ) {
            showSweetAlertToast(
                t("common.success"),
                `${t("roleManagement.role.role")} ${t(
                    "common.actions.updatedSuccessfully"
                )}`,
                "success"
            );
        } else if (
            !updatingRolePermissions &&
            updatePermissionResponse &&
            updatePermissionResponse.Code != null &&
            updatePermissionResponse.Errors
        ) {
            showSweetAlertToast(
                t("common.error.error"),
                updatePermissionResponse.Errors[0].Message,
                "error"
            );
        }
    }, [updatePermissionResponse, updatingRolePermissions]);

    return (
        <>
            <AppContentHeader
                title={defaultTo(
                    rolePermissionsResponse &&
                        rolePermissionsResponse.Data &&
                        rolePermissionsResponse.Data.RoleName,
                    ""
                )}
                hasGoBack={true}
                onBack={() => {
                    navigate({
                        pathname:
                            linkProvider.business.screens.admin.rolesManagement.list(),
                        search: `?tab=${deviceTypeParamToEnum(
                            deviceType as DeviceTypeParam
                        )}`,
                    });
                }}
                icon={ImageAssets.common.userTagGreen}
            >
                <div>
                    <SearchField
                        value={state.searchValue}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                searchValue: value as string,
                            })
                        }
                    />
                </div>
            </AppContentHeader>
            {state.list && !loadingGetRolePermissions ? (
                <>
                    {state.list &&
                        state.list.map((permission, index) => (
                            // eslint-disable-next-line react/jsx-key
                            <PermissionManagementTable
                                key={index}
                                loading={loadingGetRolePermissions}
                                checksNeedToDisable={state.checksNeedToDisable}
                                permission={permission}
                                tableName={permission.DisplayName}
                                defaultToggleValue={true}
                                onPermissionChange={
                                    canEdit ? onPermissionChange : () => {}
                                }
                                tableId={permission.SelfIdentifier}
                                selectedList={state.selectedList}
                            />
                        ))}
                    <AppFloatingButton
                        onClick={() => canEdit && onUpdatePermissionHandler()}
                        disabled={updatingRolePermissions || !canEdit}
                    />
                </>
            ) : (
                <AppLoader fullHeight={true} />
            )}
        </>
    );
};

export default PermissionEdit;
