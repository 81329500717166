import { AppContainer } from "components/Containers";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessContractsListResponse,
    BusinessType,
    parseBusinessContractItemResponse,
    parseContractDataResponse,
} from "models";
import { AppResponse } from "models/general";
import {
    Business_Contracts,
    LW_ElderlyCare_Contracts,
    LW_Kindergarten_Contracts,
    LW_School_Contracts,
} from "models/permissionManagement";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import BusinessContractDatasTable from "screens/business/settingTabs/contracts/partials/BusinessContractDatasTable";
import BusinessMonthlyContracts from "screens/business/settingTabs/contracts/partials/BusinessMonthlyContracts";
import {
    BusinessContractService,
    getBusinessContractServiceKey,
} from "services/business";

export const BusinessContracts: React.FC = () => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const isLwc = !isNil(lwcId);
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();

    const { getPermissionMap: getBusinessPermissionMap } =
        useBusinessCheckPermission();
    const { getPermissionMap } = useCheckPermission();

    const contractsService = new BusinessContractService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).contracts
    );

    const { isLoading, isFetching, data, error, refetch } = useQuery(
        getBusinessContractServiceKey("getContractsDetail", {
            encodedBusinessId: currentBusiness?.EncodedId,
        }),
        async () => await contractsService.getContractsDetail(), // automatically refetch based on key change
        {
            select: (data: AppResponse<BusinessContractsListResponse>) => {
                if (data && data.Data) {
                    return {
                        ...data.Data,
                        Contracts: data.Data.Contracts.map((x) =>
                            parseContractDataResponse(x)
                        ),
                        ContractItems: data.Data.ContractItems.map((x) =>
                            parseBusinessContractItemResponse(x)
                        ),
                    };
                }
                return null;
            },
        }
    );

    const contractPermMap = useMemo(() => {
        return !isLwc
            ? getBusinessPermissionMap(
                  currentBusiness?.Id as number,
                  currentBusiness?.HqId as number,
                  [Business_Contracts]
              )
            : getPermissionMap([
                  currentBusiness?.Type == BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_Contracts
                      : currentBusiness?.Type == BusinessType.ELDERLY_CARE
                      ? LW_ElderlyCare_Contracts
                      : LW_School_Contracts,
              ]);
    }, [getPermissionMap]);

    return (
        <AppContainer>
            <BusinessContractDatasTable
                value={defaultTo(data?.Contracts, [])}
                permMap={contractPermMap}
                loading={isLoading}
                canCreate={!isLwc && !currentBusiness?.HasNonCancelledContract}
                onChange={() => refetch()}
            />
            <BusinessMonthlyContracts
                value={defaultTo(data?.ContractItems, [])}
                years={data?.YearsRange}
                type={currentBusiness?.Type}
                permMap={contractPermMap}
                refetching={isFetching}
                loading={isLoading}
                isLwc={isLwc}
                hasContract={currentBusiness?.HasNonCancelledContract}
                refetchData={() => refetch()}
            />
        </AppContainer>
    );
};

export default BusinessContracts;
