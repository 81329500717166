import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ModulePlanCard,
    ModulePlanCardSkelton,
} from "commonPartials/modulePlanCard";
import { AppButton } from "components/Buttons";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { orderBy } from "lodash-es";
import { LocationModulePlan } from "models/modulePlans";
import {
    checkPermissionInMap,
    LocationSettings_ModulePlan,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    getLocationSettingModulePlanServiceKey,
    LocationSettingModulePlanService,
} from "services/lillywait/locationSettings";
import { useCurrentLocationSettingsContext } from "../LocationSettingTabs";
import styles from "./LocationSettingModulePlans.module.scss";
import { LocationSettingModulePlanDialog } from "./partials";

interface LocationSettingModulePlansState {
    openDialog: boolean;
    list: LocationModulePlan[];
    editId?: string;
}

export const LocationSettingModulePlans: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const currentLocation = useCurrentLocationSettingsContext();
    const { getPermissionMap } = useCheckPermission();

    const permMap = useMemo(() => {
        return getPermissionMap(LocationSettings_ModulePlan);
    }, [getPermissionMap]);
    const hasCreatePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.CREATE,
    ]);
    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);
    const hasDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const [state, setState] = useState<LocationSettingModulePlansState>({
        openDialog: false,
        list: [] as LocationModulePlan[],
    });

    const locationSettingModulePlanService =
        new LocationSettingModulePlanService(
            linkProvider.lillywait.locationSettings().api.withId().modulePlans
        );

    const { isRefetching, isLoading, data } = useQuery(
        getLocationSettingModulePlanServiceKey("list", {
            locationId: currentLocation?.dataItem?.Id,
        }),
        async () => await locationSettingModulePlanService.list()
    );

    useEffect(() => {
        if (!isRefetching && !isLoading && data?.Data) {
            setState({
                ...state,
                list: orderBy(data.Data, ["IsBasic"], ["desc"]),
            });
        }
    }, [isRefetching, isLoading]);

    return (
        <div className={styles.outer}>
            {state.openDialog && (
                <LocationSettingModulePlanDialog
                    editId={state.editId}
                    dialogOpen={state.openDialog}
                    readonly={!hasEditPermission}
                    hasDeletePermission={hasDeletePermission}
                    onDeleteSuccess={() =>
                        setState({
                            ...state,
                            list: orderBy(
                                state.list.filter(
                                    (x) => x.EncodedId != state.editId
                                ),
                                ["IsBasic"],
                                ["desc"]
                            ),
                            openDialog: false,
                            editId: undefined,
                        })
                    }
                    onChange={(modulePlan, close?: boolean) => {
                        let list = state.list;
                        if (modulePlan) {
                            const isNew =
                                state.list.filter((x) => x.Id == modulePlan.Id)
                                    .length == 0;
                            if (isNew) {
                                list = [...state.list, modulePlan];
                            } else {
                                list = state.list.map((x) => {
                                    if (x.Id == modulePlan.Id) {
                                        return modulePlan;
                                    }
                                    return x;
                                });
                            }
                        }
                        setState({
                            ...state,
                            list: orderBy(list, ["IsBasic"], ["desc"]),
                            openDialog: !close,
                            editId: close
                                ? undefined
                                : modulePlan?.Id.toString(),
                        });
                    }}
                />
            )}
            {hasCreatePermission && (
                <div
                    className={styles.createDiv}
                    onClick={() => setState({ ...state, openDialog: true })}
                >
                    <AppButton
                        padding="med"
                        rounded={true}
                        className={styles.btn}
                    >
                        <FontAwesomeIcon icon="plus" />
                    </AppButton>
                    <p className={styles.text}>
                        {t("locationSettings.modulePlan.createNewPackage")}
                    </p>
                </div>
            )}
            {!isLoading && !isRefetching ? (
                state.list.map((x, idx) => (
                    <ModulePlanCard
                        value={x}
                        key={idx}
                        onButtonClick={(editId) => {
                            setState({
                                ...state,
                                editId: editId,
                                openDialog: true,
                            });
                        }}
                    />
                ))
            ) : (
                <ModulePlanCardSkelton count={2} />
            )}
        </div>
    );
};
