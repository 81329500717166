import { AppRouteParams } from "hooks/general/routing";
import { BaseObjectInterface } from "models/general/models";
import BaseRoutingContext from "routing/routingContexts/BaseRoutingContext";

export const DefaultRouteParams: AppRouteParams = {
    sessionBusinessId: ":sessionBusinessId",
    sessionBusinessType: ":sessionBusinessType",
    businessId: ":businessId",
    businessType: ":businessType",
    clientId: ":clientId",
    employeeId: ":employeeId",
    employeeType: ":employeeType",
    clientBusinessId: ":lwcId",
    creditorId: ":creditorId",
    clientBusinessType: ":lwcType",
    locationId: ":locationId",
};
export function getUrlForPattern<T extends BaseObjectInterface>(
    context: BaseRoutingContext,
    buildUrl: boolean = true,
    pattern: string,
    forApi: boolean = false,
    params: Record<string, string> | undefined = undefined,
    queryParams: T | undefined = undefined
): string {
    return buildUrl
        ? context.buildUrl(pattern, forApi, params, queryParams)
        : pattern;
}
export function getCrudUrlsForSection(
    sectionBase: string,
    context: BaseRoutingContext,
    buildUrl: boolean = true,
    hasCreate: boolean = true,
    createPattern: string = "create",
    editPattern: string = "edit",
    hasNesting: boolean = false
) {
    const subPathBase = buildUrl || !hasNesting ? `${sectionBase}/` : "";

    return {
        list: () => getUrlForPattern(context, buildUrl, sectionBase),
        create: () =>
            hasCreate
                ? getUrlForPattern(
                      context,
                      buildUrl,
                      `${subPathBase}${createPattern}`
                  )
                : "",
        edit: (encodedId: string | ":id" = ":id") =>
            getUrlForPattern(
                context,
                buildUrl,
                `${subPathBase}${encodedId}/${editPattern}`
            ),
    };
}
