import { AppSelectOld } from "components/AppSelect";
import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppCareScopeDialog } from "./AppCareScopeDialog";
import ErrorMessage from "components/ErrorMessage";
import { AppDatePicker, AppInputField } from "components/FormFields";
import { CountriesDropdown } from "components/FormFields/CountriesDropdown";
import { AppLoader } from "components/Loaders";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { getKindergartenClientAgeGroupYear, PermissionAccessMap } from "models";
import {
    DateRangeRequest,
    ErrorCodes,
    Gender,
    Optional,
    SelectItem,
    ValidityStateManager,
} from "models/general";
import {
    ClientRegistrationStatus,
    geKindergartenClientRegistrationBasicDataInitialValues,
    getKindergartenClientRegistrationFromResponse,
} from "models/kindergartenClientRegistration";
import { KindergartenClientRegistration } from "models/kindergartenClientRegistration/model";
import { validateClientRegistrationBasicInfo } from "models/kindergartenClientRegistration/validate";
import moment from "moment";
import { Moment } from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useOutletContext } from "react-router";
import { KindergartenClientRegistrationOutletContext } from "screens/kindergarten/clientRegistration/KindergartenClientRegistrationMain";
import {
    getLocationBusinessOptionsServiceKey,
    LocationBusinessOptionsService,
} from "services";
import { KindergartenClientRegistrationService } from "services/kindergarten/KindergartenClientRegistrationService";

const selectStyles = {
    input: (base: any) => ({
        ...base,
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
    }),
};
export interface ClientRegistrationCreateEditBasicDataProps {
    value?: Optional<KindergartenClientRegistration>;
    isEdit: boolean;
    deadLine?: Optional<Moment>;
    isLoading?: boolean;
    onSuccess?: () => void;
    onChange?: (value: KindergartenClientRegistration) => void;
    permMap: PermissionAccessMap;
}
export const ClientRegistrationCreateEditBasicData: React.FC<
    ClientRegistrationCreateEditBasicDataProps
> = ({ deadLine, value, isEdit, isLoading, permMap, onSuccess, onChange }) => {
    const { t } = useTranslation();
    const { basePath } =
        useOutletContext<KindergartenClientRegistrationOutletContext>();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { id: sessionBusinessId, locationId: locationSettingId } =
        useSessionBusiness();

    const optionsService = new LocationBusinessOptionsService(
        // for location templates set currBusiness to null so it only fetches option for location
        linkProvider.api.locationBusinessOptions(
            locationSettingId as number,
            sessionBusinessId
        )
    );
    const registrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );

    const [state, setState] = useState<KindergartenClientRegistration>(
        value ? value : geKindergartenClientRegistrationBasicDataInitialValues()
    );
    const { validityStateManager, pleaseSelectOption } = useMemo(() => {
        const validationState = validateClientRegistrationBasicInfo(state);
        return {
            validityStateManager: new ValidityStateManager(validationState),
            pleaseSelectOption: {
                Text: t("common.pleaseSelect"),
                Value: null,
            },
        };
    }, [state, t]);

    const {
        isFetching: loadingCareScope,
        refetch: refetchCareScope,
        data: careScopes,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getCareScopeOptions", {
            businessId: sessionBusinessId,
            locationId: locationSettingId,
            start: moment().format(ISO8601_DATE_FORMAT),
        }),
        async () => {
            return await optionsService.getCareScopeOptions({
                StartDate: moment().startOf("month"),
                EndDate: moment().endOf("month"),
            } as DateRangeRequest);
        },
        {
            enabled: true,
            select: (resp) => {
                return defaultTo(resp.Data, []);
            },
        }
    );

    const {
        isLoading: updating,
        data: createUpdateResponse,
        mutate: createUpdate,
    } = useMutation(
        async (val: KindergartenClientRegistration) =>
            await registrationService.createUpdate(val),
        {
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess();
                }
            },
        }
    );

    const { careScopeOptions } = useMemo(() => {
        return {
            careScopeOptions: [
                pleaseSelectOption,
                ...defaultTo(careScopes, []).map((x) => {
                    return {
                        Text: x.ShortName,
                        Value: x.Uuid,
                    };
                }),
            ],
        };
    }, [careScopes, pleaseSelectOption]);

    useEffect(() => {
        if (!updating && createUpdateResponse && createUpdateResponse.Data) {
            if (isEdit) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createUpdateResponse.Data.Name}" ${t(
                        "common.actions.updatedSuccessfully"
                    )}`,
                    "success"
                );
            } else {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createUpdateResponse.Data.Name}" ${t(
                        "common.actions.createdSuccessfully"
                    )}`,
                    "success"
                );
                if (!isEdit) {
                    // navigate to client edit
                    navigate(
                        basePath.edit(
                            createUpdateResponse.Data.EncodedId
                                ? createUpdateResponse.Data.EncodedId
                                : ""
                        )
                    );
                }
            }
            if (onChange) {
                onChange(
                    getKindergartenClientRegistrationFromResponse(
                        createUpdateResponse.Data
                    )
                );
            }
        } else if (
            !updating &&
            createUpdateResponse &&
            createUpdateResponse.Code != null &&
            createUpdateResponse.Code == ErrorCodes.VALIDATION_ERROR
        ) {
            showSweetAlertToast(
                t("common.error.error"),
                createUpdateResponse.Errors &&
                    createUpdateResponse.Errors[0].Message
                    ? createUpdateResponse.Errors[0].Message
                    : "",
                "error"
            );
        }
    }, [createUpdateResponse, updating]);

    const onSubmitHandler = () => {
        createUpdate(state);
        if (!isNil(state.Id) && onChange) {
            onChange(state);
        }
    };

    useEffect(() => {
        if (!loadingCareScope) {
            refetchCareScope();
        }
    }, [state.Birthday]);

    const { genderOptions, statusOptions } = useMemo(() => {
        return {
            statusOptions: [
                ...Object.values(ClientRegistrationStatus).map((x) => {
                    return {
                        Text: t(`common.status.${x.toString()}`),
                        Value: x,
                    } as SelectItem;
                }),
            ],
            // progressOptions: [
            //     ...Object.values(ClientRegistrationProgressStatus).map((x) => {
            //         return {
            //             Text: t(
            //                 `kindergartenClient.registration.progressStatus.${x.toString()}`
            //             ),
            //             Value: x,
            //         } as SelectItem;
            //     }),
            // ],
            genderOptions: [
                pleaseSelectOption,
                ...Object.values(Gender).map((x) => {
                    return {
                        Text: t(`common.gender.${x.toString()}`),
                        Value: x,
                    } as SelectItem;
                }),
            ],
        };
    }, [t]);
    const hasEditPermission = permMap.EDIT;

    const renderedFields = useMemo(() => {
        return (
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        showEmptyError={true}
                        label={t("kindergartenClient.basicInfo.firstName.name")}
                        value={state.FirstName}
                        error={validityStateManager.getFirstErrorInfo(
                            "FirstName"
                        )}
                        readOnly={!hasEditPermission}
                        onValueChange={(val) => {
                            setState({
                                ...state,
                                FirstName: defaultTo(val, ""),
                            });
                        }}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        showEmptyError={true}
                        label={t("kindergartenClient.basicInfo.lastName.name")}
                        value={state.LastName}
                        error={validityStateManager.getFirstErrorInfo(
                            "LastName"
                        )}
                        readOnly={!hasEditPermission}
                        onValueChange={(val) => {
                            setState({
                                ...state,
                                LastName: defaultTo(val, ""),
                            });
                        }}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group>
                        <Form.Label>{t("common.gender.name")}</Form.Label>
                        <AppSelectOld
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            options={genderOptions}
                            value={genderOptions.find(
                                (x) => x.Value == state.Gender
                            )}
                            isDisabled={!hasEditPermission}
                            showEmptyError={true}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    Gender: e.Value as string,
                                })
                            }
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "Gender"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        useDefault={false}
                        label={t("kindergartenClient.basicInfo.birthday.name")}
                        appendToBody={true}
                        value={state.Birthday}
                        readOnly={!hasEditPermission}
                        showEmptyError={true}
                        onChange={(e) =>
                            setState({
                                ...state,
                                Birthday: e as Moment,
                                Year: e
                                    ? getKindergartenClientAgeGroupYear(
                                          e as Moment,
                                          deadLine
                                      )
                                    : 0,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "Birthday"
                        )}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        appendToBody={true}
                        useDefault={false}
                        readOnly={!hasEditPermission}
                        showEmptyError={true}
                        label={t("kindergartenClient.registration.careStart")}
                        //maxDate={state.EndOfCOntract}
                        value={state.CareStart}
                        onChange={(e) =>
                            setState({
                                ...state,
                                CareStart: e as Moment,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "CareStart"
                        )}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <CountriesDropdown
                        label={t(
                            "kindergartenClient.registration.nationality.name"
                        )}
                        readonly={!hasEditPermission}
                        value={state.CountryCode}
                        onChange={(val) => {
                            setState({
                                ...state,
                                CountryCode: val ? (val as string) : null,
                            });
                        }}
                        error={validityStateManager.getFirstErrorInfo(
                            "CountryCode"
                        )}
                    />
                </Col>
                {state.NationalityRaw && (
                    <Col xs={12} sm={6} md={2}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.basicInfo.nationalityRaw.name"
                            )}
                            value={state.NationalityRaw}
                            readOnly={true}
                        />
                    </Col>
                )}
                <Col xs={12} sm={6} md={state.NationalityRaw ? 2 : 4}>
                    <Form.Group controlId="formBasicScopeOfCare">
                        <Form.Label>
                            {t(
                                "kindergartenClient.registration.scopeOfCare.name"
                            )}
                        </Form.Label>
                        {isEdit && !isNil(value?.CareScopeFieldUuid) ? (
                            <AppInputField
                                showEmptyError={false}
                                value={
                                    // eslint-disable-next-line no-extra-boolean-cast
                                    defaultTo(state?.CareScopeFieldName, "")
                                }
                                readOnly={true}
                                onValueChange={(value) => {}}
                                rightIcon={
                                    <AppCareScopeDialog
                                        readonly={
                                            !hasEditPermission ||
                                            loadingCareScope
                                        }
                                        options={careScopeOptions}
                                        value={state.CareScopeFieldUuid}
                                        onChange={(newVal: SelectItem) =>
                                            setState({
                                                ...state,
                                                CareScopeFieldName:
                                                    newVal.Text as string,
                                                CareScopeFieldUuid:
                                                    newVal.Value as string,
                                            })
                                        }
                                    />
                                }
                            />
                        ) : (
                            <AppSelectOld
                                isLoading={loadingCareScope}
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                options={careScopeOptions}
                                isDisabled={
                                    !hasEditPermission || loadingCareScope
                                }
                                value={
                                    state.CareScopeFieldUuid
                                        ? careScopeOptions?.find(
                                              (x) =>
                                                  x.Value ===
                                                  state.CareScopeFieldUuid
                                          )
                                        : pleaseSelectOption
                                }
                                showEmptyError={true}
                                getOptionLabel={(opt: SelectItem) => opt.Text}
                                getOptionValue={(opt: SelectItem) => opt.Value}
                                onChange={(e: SelectItem) =>
                                    setState({
                                        ...state,
                                        CareScopeFieldName: e.Text as string,
                                        CareScopeFieldUuid: e.Value as string,
                                    })
                                }
                            />
                        )}
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "CareScopeFieldUuid"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        label={t("kindergartenClient.registration.year")}
                        value={defaultTo(state.Year, "")}
                        showEmptyError={true}
                        disabled={true}
                        error={validityStateManager.getFirstErrorInfo("Year")}
                        onValueChange={(val) => {
                            setState({
                                ...state,
                                Year: defaultTo(val, 0) as number,
                            });
                        }}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                            {t("kindergartenClient.registration.status.name")}
                        </Form.Label>
                        <AppSelectOld
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            options={statusOptions}
                            value={statusOptions.find(
                                (x) => x.Value == state.Status
                            )}
                            isDisabled={!hasEditPermission}
                            showEmptyError={true}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    Status: e.Value as ClientRegistrationStatus,
                                })
                            }
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "Status"
                            )}
                        />
                    </Form.Group>
                </Col>
                {/* {isEdit && (
                <Col xs={12} sm={6} md={4}>
                    <Form.Group>
                        <Form.Label>
                            {t(
                                "kindergartenClient.registration.progressStatus.name"
                            )}
                        </Form.Label>
                        <AppSelectOld
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            options={progressOptions}
                            value={progressOptions.find(
                                (x) => x.Value == state.ProgressStatus
                            )}
                            isDisabled={!hasEditPermission}
                            showEmptyError={true}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    ProgressStatus:
                                        e.Value as ClientRegistrationProgressStatus,
                                })
                            }
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "ProgressStatus"
                            )}
                        />
                    </Form.Group>
                </Col>
            )} */}
            </Row>
        );
    }, [
        state,
        hasEditPermission,
        genderOptions,
        careScopeOptions,
        statusOptions,
        validityStateManager,
        deadLine,
    ]);
    return isLoading ? (
        <AppLoader />
    ) : (
        <AppContainer
            title={t("kindergartenClient.basicInfo.title")}
            useMaxHeight={true}
            heightToAdjust={190}
            mobileViewAdjustment={0}
            mediumViewAdjustment={0}
            showHeader={true}
            onBack={() => navigate(basePath.list())}
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            (isEdit && !permMap.EDIT) ||
                            !validityStateManager.isStateValid() ||
                            updating,
                        onClick: onSubmitHandler,
                    }}
                />
            }
        >
            {renderedFields}
        </AppContainer>
    );
};
export default ClientRegistrationCreateEditBasicData;
