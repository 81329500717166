export const LwClientEnTranslations = {
    lwClient: {
        closingSupportMode: "Closing support mode...",
        openingSupportMode: "Opening support mode...",
        openSupport: "Open Support",
        table: {
            activeClients: "Active Clients",
            activeEmployees: "Active Employees",
            city: "City",
            companyName: "Company Name",
            facilityName: "Facility Name",
            hq: "HQ",
            id: "ID",
            postalCode: "Postal Code",
            state: "State",
            status: "Status",
            street: "Street",
            streetNumber: "Street Number",
        },
        total: "Total",
        clientTotal: "Client Total",
        employeeTotal: "Employee Total",
        unableToEnterSupportMode: "Unable to enter support mode...",
        unableToExitSupportMode: "Unable to exit support mode...",
    },
    mergeCompanies: {
        note: {
            paragraph1: `This will move "Source company" into "Target Headquarter" as its branch.`,
            paragraph2: `If "Source Company" is a headquarter with multiple branches then all its branches will also be moved as well.`,
        },
        sourceCompany: {
            missing: "Source company is required.",
            name: "Source Company",
        },
        successfullyMovedToHQ: "successfully moved to HQ",
        targetHeadquarter: {
            missing: "Target headquarter is required.",
            name: "Target Headquarter",
        },
        title: "Merge Companies",
        unableToMove: "Unable to move source Business to target HQ.",
        warningDesc: "This action is irreversible.",
    },
};

export default LwClientEnTranslations;
