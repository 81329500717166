import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { showSweetAlertInfo } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { LocationSettingListItem } from "models/locationSetting";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    getLocationSettingsServiceKey,
    LocationSettingsService,
} from "services/lillywait/locationSettings";

export interface LocationSettingRowProps {
    value: LocationSettingListItem;
    onClick?: () => void;
    canDelete?: boolean;
    onDelete: (locationId: number) => void;
}

export const LocationSettingRow: React.FC<LocationSettingRowProps> = ({
    value,
    onClick,
    canDelete,
    onDelete,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const locationService = new LocationSettingsService(
        linkProvider.lillywait.locationSettings().api
    );

    const {
        isLoading: deleting,
        data: deleteLocationSettingResponse,
        isError: deleteLocationSettingError,
        reset: deleteLocationSettingReset,
        mutate: deleteLocationSetting,
    } = useMutation(
        getLocationSettingsServiceKey("delete"),
        async (locationId: string) => await locationService.delete(locationId)
    );
    useEffect(() => {
        if (!deleting) {
            if (deleteLocationSettingResponse) {
                if (deleteLocationSettingResponse.Data) {
                    showSweetAlertInfo(
                        t("common.success"),
                        t(`common.delete.success`),
                        "success"
                    );
                    onDelete(value.Id);
                } else {
                    showSweetAlertInfo(
                        t("common.error.error"),
                        t(`locationSettings.deleteError`),
                        "error"
                    );
                }
            } else if (deleteLocationSettingError) {
                showSweetAlertInfo(
                    t("common.error.error"),
                    t(`common.actions.errors.unableToDelete`),
                    "error"
                );
            }
            deleteLocationSettingReset();
        }
    }, [deleting, deleteLocationSettingResponse, deleteLocationSettingError]);
    return (
        <>
            <tr>
                <td
                    onClick={onClick}
                    style={{ ...getFixedCssWidths(250), paddingLeft: "5px" }}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.Name}</span>
                </td>
                <td onClick={onClick} style={getFixedCssWidths(150)}>
                    {t(
                        `business.businessType.${value.ForBusiness.toLowerCase()}`
                    )}
                </td>
                <td
                    onClick={onClick}
                    style={getFixedCssWidths(200)}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.Country}</span>
                </td>
                <td
                    onClick={onClick}
                    style={getFixedCssWidths(200)}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.State}</span>
                </td>
                <td
                    onClick={onClick}
                    style={getFixedCssWidths(300, false)}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.City}</span>
                </td>
                <td
                    onClick={onClick}
                    style={getFixedCssWidths(300)}
                    className={"ellipsis-text single-line"}
                >
                    <span>{value.ZipCodes}</span>
                </td>
                <td style={{ ...getFixedCssWidths(60), paddingLeft: "10px" }}>
                    <AppDeleteButton
                        onClick={() => {
                            deleteLocationSetting(value.EncodedId);
                        }}
                        readonly={!canDelete || deleting}
                        getConfirmation={true}
                        name={value.Name}
                    />
                </td>
            </tr>
        </>
    );
};

export default LocationSettingRow;
