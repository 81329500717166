import { StartAndEndDateFields } from "commonPartials/StartAndEndDateFields";
import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppInputField } from "components/FormFields";
import { AppLoader } from "components/Loaders";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { Business } from "models/business";
import {
    CareScopeTemplate,
    CareScopeTemplateField,
    CareScopeTemplateFieldType,
    CareScopeTemplateResponse,
    CareScopeTemplateSurchargeField,
    CareScopeTemplateSurchargeFieldGroup,
    getCareScopeTemplateFromResponse,
    getDefaultCareScopeTemplate,
    validateCareScopeTemplate,
} from "models/careScopeTemplate";
import { AppResponse, Optional, ValidityStateManager } from "models/general";
import { PermissionAccessMap } from "models/permissionManagement/model";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { businessSettingRoutes } from "routing/routes/business/settings";
import CareScopeTemplateService, {
    getCareScopeTemplateServiceKey,
} from "services/CareScopeTemplateService";
import { CareScopeTemplateFieldsContainer } from "./partials/createEditPartials";

const basePath = businessSettingRoutes().screens.careScopes;
type PathBase = typeof basePath;
export interface CareScopeTemplateCreateEditProps {
    id: Optional<string>;
    currentBusiness: Optional<Business>; // null for location-setting views
    locationId?: number; // for lwc it will be lwc location-setting id
    basePathProvider: PathBase;
    createOptions?: { clone: boolean; followup: boolean };
    permissionsMap: PermissionAccessMap;
    isLwc?: boolean;
    useLocationData?: boolean;
}

export const CareScopeTemplateCreateEdit: React.FC<
    CareScopeTemplateCreateEditProps
> = ({
    id,
    currentBusiness,
    locationId,
    useLocationData,
    createOptions = { clone: false, followup: false },
    permissionsMap,
    isLwc,
    basePathProvider,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const navigate = useNavigate();
    const { encodedId } = useSessionBusiness();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();

    const [state, setState] = useState<CareScopeTemplate | undefined>(
        !isNil(id)
            ? undefined
            : getDefaultCareScopeTemplate(
                  isNil(currentBusiness) ? locationId : undefined,
                  currentBusiness?.Id
              )
    );
    const careScopeService = new CareScopeTemplateService(
        linkProvider.api.careScopeTemplates(
            locationId ? (locationId as number) : 0,
            currentBusiness?.Id
        )
    );

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: templateRes,
    } = useQuery(
        getCareScopeTemplateServiceKey("getDetails", {
            businessId: currentBusiness?.Id,
            locationId: locationId,
            id: id,
        }),
        async () => {
            if (id) {
                return await careScopeService.getDetails(
                    id,
                    createOptions.clone,
                    createOptions.followup,
                    !isNil(currentBusiness) && createOptions.followup
                        ? true
                        : false
                );
            }
        },
        {
            cacheTime: 0,
            select: (listRes) => {
                if (listRes?.Data) {
                    return getCareScopeTemplateFromResponse(listRes.Data);
                } else if (!isNil(id)) {
                    showErrorPage(404);
                }
            },
        }
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && templateRes) {
            setState({
                ...state,
                ...templateRes,
            });
        }
    }, [getLoading, getRefetching]);

    const {
        isLoading: editLoading,
        mutate: edit,
        data: editRes,
    } = useMutation(async () => {
        if (state) {
            return await careScopeService.edit(state);
        }
    });
    const {
        isLoading: createLoading,
        mutate: create,
        data: createRes,
    } = useMutation(async () => {
        if (state) {
            return await careScopeService.create(state);
        }
    });

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            state ? validateCareScopeTemplate(state) : undefined
        );
    }, [state, appLocale]);

    const { title, minStartDate, canEdit, isParentTemplate } = useMemo(() => {
        const isParent =
            isNil(state?.FollowUpBusinessParentId) &&
            isNil(state?.FollowUpLocationParentId);
        const isForLocation = !isNil(state?.LocationSettingId);
        return {
            canEdit:
                isNil(currentBusiness) || // location-setting view
                (!isNil(currentBusiness) && !isForLocation && !useLocationData), // can edit only non-location templates in business settings
            isParentTemplate: isParent,
            minStartDate:
                state &&
                (state.FollowUpBusinessParentId ||
                    state.FollowUpLocationParentId) &&
                !isNil(state.ParentEndDate)
                    ? state.ParentEndDate.clone()
                          .add(1, "month")
                          .startOf("month")
                    : undefined,
            title:
                state && state.Id > 0
                    ? state.Name
                    : isParent
                    ? t("templates.createTemplate")
                    : t("templates.addFollowUpTemplate", {
                          name: state?.Name,
                      }),
        };
    }, [state?.Name, state]);

    const handleResponse = (
        response: AppResponse<CareScopeTemplateResponse>,
        isEdit: boolean = false
    ) => {
        if (response.Data) {
            showSweetAlertToast(
                t("common.success"),
                `"${response.Data.Name}" ${t(
                    `common.actions.${
                        isEdit ? "updatedSuccessfully" : "createdSuccessfully"
                    }`
                )}`,
                "success"
            );

            queryClient.invalidateQueries(
                getCareScopeTemplateServiceKey("getTemplates", {
                    businessId: currentBusiness?.Id,
                    locationId: locationId,
                    sessionBusinessId: encodedId,
                })
            );

            navigate(basePathProvider.list());
        } else if (response.Message) {
            showSweetAlertToast(
                t("common.error.error"),
                response.Message,
                "error"
            );
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t(
                    `common.actions.errors.${
                        isEdit ? "unableToUpdate" : "unableToCreate"
                    }`
                ),
                "error"
            );
        }
    };
    useEffect(() => {
        if (!editLoading && editRes) {
            handleResponse(editRes, true);
        }
    }, [editLoading, editRes]);
    useEffect(() => {
        if (!createLoading && createRes) {
            handleResponse(createRes);
        }
    }, [createLoading, createRes]);

    const readonly = !(
        canEdit && (!isNil(id) ? permissionsMap.EDIT : permissionsMap.CREATE)
    );

    return (
        <div style={{ position: "relative" }}>
            {!state || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={true}
                    onBack={() => navigate(basePathProvider.list())}
                    title={title}
                    hasInnerScroll={true}
                    footer={
                        !readonly ? (
                            <AppContainerFooter
                                primaryButtonProps={{
                                    disabled:
                                        !validityStateManager.isStateValid() ||
                                        editLoading ||
                                        createLoading,
                                    onClick: () => {
                                        if (!readonly) {
                                            if (state.Id > 0) {
                                                // edit
                                                edit();
                                            } else {
                                                create();
                                            }
                                        }
                                    },
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Row>
                        <Col md={6}>
                            <AppInputField
                                label={t("templates.templateName.name")}
                                value={state.Name}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Name: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Name"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInputField
                                label={t("templates.templateShortName.name")}
                                value={state.ShortName}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        ShortName: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "ShortName"
                                )}
                            />
                        </Col>
                        <StartAndEndDateFields
                            requiredEndDate={
                                state.Id > 0 && !state.CanSetEndDateNull
                            }
                            minStartDate={minStartDate}
                            useMonthPicker={true}
                            appendToBody={true}
                            readonly={readonly}
                            value={{
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            }}
                            onChange={({
                                StartDate: StartDate,
                                EndDate: EndDate,
                            }) => {
                                setState({
                                    ...state,
                                    StartDate: StartDate,
                                    EndDate: EndDate,
                                });
                            }}
                        />
                    </Row>
                    <CareScopeTemplateFieldsContainer<CareScopeTemplateField>
                        onChange={(newFields) => {
                            setState({
                                ...state,
                                Fields: newFields.map((f, idx) => {
                                    return { ...f, Order: idx };
                                }),
                            });
                        }}
                        validityState={validityStateManager.state}
                        fieldType={CareScopeTemplateFieldType.MAIN}
                        fields={state.Fields}
                        readonly={readonly}
                        businessId={currentBusiness?.EncodedId}
                        locationSettingId={locationId?.toString()}
                    />
                    <CareScopeTemplateFieldsContainer<CareScopeTemplateSurchargeFieldGroup>
                        onChange={(newFields) => {
                            setState({
                                ...state,
                                SurchargeFieldGroups: newFields.map(
                                    (f, idx) => {
                                        return { ...f, Order: idx };
                                    }
                                ),
                            });
                        }}
                        validityState={validityStateManager.state}
                        fieldType={CareScopeTemplateFieldType.SURCHARGE_GROUP}
                        fields={state.SurchargeFieldGroups}
                        readonly={readonly}
                        businessId={currentBusiness?.EncodedId}
                        locationSettingId={locationId?.toString()}
                    />
                </AppContainer>
            )}
        </div>
    );
};

export default CareScopeTemplateCreateEdit;
