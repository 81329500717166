import { useEffect, useMemo, useState } from "react";
import { defaultTo, isNil } from "lodash-es";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";
import { useRouting } from "hooks/general/routing";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { useMutation } from "react-query";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { AppContainer } from "components/Containers";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import {
    BusinessBasicSettingsUpdateRequest,
    BusinessType,
    Business_Settings,
    LW_Industry_Settings,
    LW_Kindergarten_Settings,
    validateBusinessBasicSettings,
} from "models";
import { Col, Form, Image, Row } from "react-bootstrap";
import { AppButton } from "components/Buttons";
import {
    AppDateMonthPicker,
    AppMonthYearPicker,
    AppNumberInput,
} from "components/FormFields";
import moment, { Moment } from "moment-timezone";
import { AppLoader } from "components/Loaders";
import { Optional, ValidityStateManager } from "models/general";
import { FORMAT_INTEGER } from "globals/constants";
import { useParams } from "react-router";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { AppSwitch, AppTooltip } from "components";
import classNames from "classnames";
import { ImageAssets } from "globals/images";
import styles from "./BusinessBasicSettings.module.scss";

const minAgeGroup = moment().startOf("year");
export const BusinessBasicSettings: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] =
        useState<BusinessBasicSettingsUpdateRequest | null>(null);
    const { dataItem: currentBusiness, refetchData } =
        useCurrentBusinessSettingsContext();

    const { lwcId } = useParams();
    const isLwc = !isNil(lwcId);

    const { getPermissionMap } = useBusinessCheckPermission();

    const {
        id: sessionBusinessId,
        encodedId,
        hqId: sessionHqId,
    } = useSessionBusiness();

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(lwcId)
    );

    useEffect(() => {
        if (currentBusiness && currentBusiness.BusinessSetting) {
            const setting = currentBusiness.BusinessSetting;
            setState({
                AgeGroupDeadline: setting.AgeGroupDeadline,
                MinChildAgeGroupAgeMonth: setting.MinChildAgeGroupAgeMonth,
                MaxChildAgeGroupAgeMonth: setting.MaxChildAgeGroupAgeMonth,
                TimeTrackingStart: setting.TimeTrackingStart,
                VacationsCalculationStart: setting.VacationsCalculationStart,
                WorkHoursPerWeek: setting.WorkHoursPerWeek,
                WeeksToLookForNewUserSeed: setting.WeeksToLookForNewUserSeed,
                CarryForwardLeftoverVacations:
                    setting.CarryForwardLeftoverVacations,
                MissingBreakStopMinutes: setting.MissingBreakStopMinutes,
                CalculateExactEmployeeWorkingHours:
                    setting.CalculateExactEmployeeWorkingHours,
                DisableChipsAfterInactiveForDays:
                    setting.DisableChipsAfterInactiveForDays,
                EmployeePreparationTime: setting.EmployeePreparationTime,
            });
        }
    }, [currentBusiness]);
    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            validateBusinessBasicSettings(defaultTo(state, {} as any))
        );
    }, [state]);

    const {
        isLoading: updating,
        data: updateResponse,
        mutate: updateBasicSettings,
    } = useMutation(
        getCurrentBusinessServiceKey("updateBasicSettings"),
        async () =>
            !isNil(state)
                ? await businessService.updateBasicSettings(state)
                : false
    );
    const readonly = useMemo(() => {
        const permMap = !isLwc
            ? getPermissionMap(
                  currentBusiness?.Id as number,
                  currentBusiness?.HqId as number,
                  Business_Settings
              )
            : getPermissionMap(
                  sessionBusinessId,
                  sessionHqId,
                  currentBusiness?.Type == BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_Settings
                      : LW_Industry_Settings
              );
        return !permMap.CREATE && !permMap.EDIT;
    }, [getPermissionMap]);
    useEffect(() => {
        if (!updating && updateResponse) {
            if (updateResponse.Data != null) {
                if (updateResponse.Data) {
                    if (refetchData) {
                        refetchData();
                    }
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("common.successMessage")}`,
                        "success"
                    );
                }
            } else if (updateResponse.Code != null) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("common.error.requestFailed")}`,
                    "error"
                );
            }
        }
    }, [updating, updateResponse]);

    return isNil(state) ? (
        <AppLoader />
    ) : (
        <AppContainer>
            {currentBusiness?.Type != BusinessType.LW && (
                <Row style={{ marginBottom: "15px" }}>
                    <Col md={12} className={styles.title}>
                        {t("business.basicSettings.titles.ageGroupPlanning")}
                    </Col>
                    <Col md={4}>
                        <Form.Label
                            style={{
                                color: "#0d1c26",
                                fontSize: "0.9rem",
                            }}
                        >
                            {t("business.basicSettings.monthChangeYear.name")}
                            <AppTooltip
                                content={
                                    <span>
                                        {t(
                                            "business.basicSettings.monthChangeYear.infoText"
                                        )}
                                    </span>
                                }
                                allowHtml={true}
                                trigger="mouseenter focus"
                                arrow={true}
                                maxWidth="400px"
                            >
                                <Image
                                    className={classNames("ml-2")}
                                    style={{ width: "16px", height: "16px" }}
                                    src={ImageAssets.common.questionCircleBlue}
                                />
                            </AppTooltip>
                        </Form.Label>
                        <AppDateMonthPicker
                            readOnly={readonly}
                            appendToBody={true}
                            showLabel={false}
                            value={state?.AgeGroupDeadline}
                            minDate={minAgeGroup}
                            onChange={(val: Optional<Moment>) => {
                                setState((s) => ({
                                    ...(s as BusinessBasicSettingsUpdateRequest),
                                    AgeGroupDeadline: val,
                                }));
                            }}
                            showEmptyError={true}
                            error={validityStateManager.getFirstErrorInfo(
                                "AgeGroupDeadline"
                            )}
                        />
                    </Col>
                    <Col md={4}>
                        <AppNumberInput
                            showError={true}
                            label={t("business.basicSettings.minChildAge.name")}
                            value={state?.MinChildAgeGroupAgeMonth}
                            readOnly={readonly}
                            showPlaceHolder={false}
                            format={FORMAT_INTEGER}
                            onChange={(val) =>
                                setState((s) => ({
                                    ...(s as BusinessBasicSettingsUpdateRequest),
                                    MinChildAgeGroupAgeMonth: val,
                                }))
                            }
                            error={validityStateManager.getFirstErrorInfo(
                                "MinChildAgeGroupAgeMonth"
                            )}
                            showEmptyError={true}
                        />
                    </Col>
                    <Col md={4}>
                        <AppNumberInput
                            showError={true}
                            label={t("business.basicSettings.maxChildAge.name")}
                            value={state?.MaxChildAgeGroupAgeMonth}
                            readOnly={readonly}
                            showPlaceHolder={false}
                            format={FORMAT_INTEGER}
                            onChange={(val) =>
                                setState((s) => ({
                                    ...(s as BusinessBasicSettingsUpdateRequest),
                                    MaxChildAgeGroupAgeMonth: val,
                                }))
                            }
                            error={validityStateManager.getFirstErrorInfo(
                                "MaxChildAgeGroupAgeMonth"
                            )}
                            showEmptyError={true}
                        />
                    </Col>
                </Row>
            )}
            <Row style={{ marginBottom: "15px" }}>
                <Col md={12} className={styles.title}>
                    {t("business.basicSettings.titles.timeTracking")}
                </Col>
                <Col md={4}>
                    <AppMonthYearPicker
                        readOnly={readonly}
                        appendToBody={true}
                        label={t(
                            "business.basicSettings.timeTrackingStart.name"
                        )}
                        value={state?.TimeTrackingStart}
                        forMonthStart={true}
                        onChange={(val: Optional<Moment>) => {
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                TimeTrackingStart: val,
                            }));
                        }}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "TimeTrackingStart"
                        )}
                    />
                </Col>{" "}
                <Col md={4}>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t(
                            "business.basicSettings.employeePreparationTime.name"
                        )}
                        <AppTooltip
                            content={
                                <span>
                                    {t(
                                        "business.basicSettings.employeePreparationTime.infoText1"
                                    )}
                                    <br />
                                    {t(
                                        "business.basicSettings.employeePreparationTime.infoText2"
                                    )}
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppNumberInput
                        showError={true}
                        showLabel={false}
                        value={state?.EmployeePreparationTime}
                        readOnly={readonly}
                        allowZero={true}
                        showPlaceHolder={false}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                EmployeePreparationTime: val,
                            }))
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "EmployeePreparationTime"
                        )}
                        showEmptyError={true}
                    />
                </Col>
                <Col md={4}>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t(
                            "business.basicSettings.missingBreakStopMinutes.name"
                        )}
                        <AppTooltip
                            content={
                                <span>
                                    {t(
                                        "business.basicSettings.missingBreakStopMinutes.infoText"
                                    )}
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppNumberInput
                        showError={true}
                        showLabel={false}
                        value={state?.MissingBreakStopMinutes}
                        readOnly={readonly}
                        allowZero={true}
                        showPlaceHolder={false}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                MissingBreakStopMinutes: val,
                            }))
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "MissingBreakStopMinutes"
                        )}
                        showEmptyError={true}
                    />
                </Col>
                <Col md={4}>
                    <AppNumberInput
                        showError={true}
                        label={t(
                            "business.basicSettings.basicWeeklyWorkHours.name"
                        )}
                        value={state?.WorkHoursPerWeek}
                        readOnly={readonly}
                        allowZero={false}
                        showPlaceHolder={false}
                        onChange={(val) =>
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                WorkHoursPerWeek: val,
                            }))
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "WorkHoursPerWeek"
                        )}
                        showEmptyError={true}
                    />
                </Col>
                <Col md={4}>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t(
                            "business.basicSettings.calculateExactEmployeeTime.name"
                        )}
                        <AppTooltip
                            content={
                                <span>
                                    <span>
                                        {t(
                                            "business.basicSettings.calculateExactEmployeeTime.infoText1"
                                        )}
                                        <br />
                                        {t(
                                            "business.basicSettings.calculateExactEmployeeTime.infoText2"
                                        )}
                                        <br />
                                        {t(
                                            "business.basicSettings.calculateExactEmployeeTime.infoText3"
                                        )}
                                    </span>
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppSwitch
                        label={""}
                        hideLabel={true}
                        disabled={readonly}
                        value={defaultTo(
                            state.CalculateExactEmployeeWorkingHours,
                            false
                        )}
                        onChange={(checked) => {
                            setState({
                                ...state,
                                CalculateExactEmployeeWorkingHours: checked,
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
                <Col md={12} className={styles.title}>
                    {t("business.basicSettings.titles.vacationsPlanning")}
                </Col>
                <Col md={4}>
                    <AppMonthYearPicker
                        readOnly={readonly}
                        appendToBody={true}
                        label={t(
                            "business.basicSettings.vacationsCalculationStart.name"
                        )}
                        value={state?.VacationsCalculationStart}
                        forMonthStart={true}
                        onChange={(val: Optional<Moment>) => {
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                VacationsCalculationStart: val,
                            }));
                        }}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "VacationsCalculationStart"
                        )}
                    />
                </Col>
                <Col md={4}>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t("business.basicSettings.carryForwardVacations.name")}
                        <AppTooltip
                            content={
                                <span>
                                    <span>
                                        {t(
                                            "business.basicSettings.carryForwardVacations.infoText"
                                        )}
                                    </span>
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppSwitch
                        label={""}
                        hideLabel={true}
                        disabled={readonly}
                        value={defaultTo(
                            state.CarryForwardLeftoverVacations,
                            false
                        )}
                        onChange={(checked) => {
                            setState({
                                ...state,
                                CarryForwardLeftoverVacations: checked,
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
                <Col md={4}>
                    <span className={styles.title}>
                        {t("business.basicSettings.titles.chat")}
                    </span>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t(
                            "business.basicSettings.weeksToLookForNewUserSeed.name"
                        )}
                        <AppTooltip
                            content={
                                <span>
                                    {t(
                                        "business.basicSettings.weeksToLookForNewUserSeed.infoText"
                                    )}
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppNumberInput
                        showError={true}
                        showLabel={false}
                        value={state?.WeeksToLookForNewUserSeed}
                        readOnly={readonly}
                        allowZero={false}
                        showPlaceHolder={false}
                        onChange={(val) =>
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                WeeksToLookForNewUserSeed: val,
                            }))
                        }
                        format={FORMAT_INTEGER}
                        error={validityStateManager.getFirstErrorInfo(
                            "WeeksToLookForNewUserSeed"
                        )}
                        showEmptyError={true}
                    />
                </Col>
                <Col md={4}>
                    <span className={styles.title}>
                        {t("business.basicSettings.titles.accessControl")}
                    </span>
                    <AppNumberInput
                        showError={true}
                        label={t(
                            "business.basicSettings.disableInactiveChipsAfterDays.name"
                        )}
                        value={state?.DisableChipsAfterInactiveForDays}
                        readOnly={readonly}
                        allowZero={true}
                        showPlaceHolder={false}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState((s) => ({
                                ...(s as BusinessBasicSettingsUpdateRequest),
                                DisableChipsAfterInactiveForDays: val,
                            }))
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "DisableChipsAfterInactiveForDays"
                        )}
                        showEmptyError={true}
                    />
                </Col>
            </Row>
            <div style={{ marginTop: "10px" }}>
                <AppButton
                    padding="med"
                    disabled={updating || readonly}
                    onClick={() => updateBasicSettings()}
                >
                    {t("common.saveChanges")}
                </AppButton>
            </div>
        </AppContainer>
    );
};

export default BusinessBasicSettings;
