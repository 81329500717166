import { AppContentHeader } from "components";
import IFrameContent from "components/IFrameContent";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CreditorCreateEditFields from "screens/business/creditors/partials/CreditorCreateEditFields";
import styles from "./CreditorsCreate.module.scss";

export const CreditorsCreate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const backUrl = linkProvider.business.screens.creditors.list();

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("accounting.creditors.createTitle")}
                classNameForIcon="lnr-briefcase"
                hasGoBack={true}
                backUrl={backUrl}
            />
            <CreditorCreateEditFields onBack={() => navigate(backUrl)} />
        </div>
    );
};

export default CreditorsCreate;
