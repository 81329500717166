import classNames from "classnames";
import { ErrorMessage } from "components";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import SearchField from "components/FormFields/SearchField";
import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import {
    AddChannelRequestModel,
    ChannelType,
    ChatBusinessUserType,
    ChatTabsType,
    GetUsersForChannelRequest,
    MemberListResponse,
    TwilioChannelUserType,
} from "models/chat";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import {
    Business_Chat,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useMemo, useState } from "react";
import { Col, Form, Image, ListGroup, ModalProps, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ChatService, getChatServiceKey } from "services/business/ChatService";
import styles from "./ChannelUserDialog.module.scss";
import { ChannelItem, ChatChannelLoader } from "./partials";

interface AddNewChannelModelProps extends ModalProps {
    modalOpen: boolean;
    onClose: () => void;
    tabType: GetUsersForChannelRequest;
    onChange: (event: AddChannelRequestModel) => void;
    loading: boolean;
}
export const ChannelUserDialog: React.FC<AddNewChannelModelProps> = ({
    modalOpen,
    onChange,
    tabType,
    onClose,
    loading,
    ...rest
}) => {
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const hasCreatePermission = checkPermission(Business_Chat, [
        PermissionAccessTypes.CREATE,
    ]);
    const { linkProvider } = useRouting();
    const [memberSearchValue, setMemberSearchValue] = useState<string>("");
    const [state, setState] = useState<AddChannelRequestModel>({
        AdminId: "",
        GroupName: "",
        MembersIdsList: [],
        file: null,
        ChannelType:
            tabType.UsersType === ChatTabsType.CHATS
                ? ChannelType.ONE_TO_ONE
                : ChannelType.CUSTOM_GROUP,
    });
    const chatService = new ChatService(linkProvider.business.api.chat);

    const { isFetching: userListLoading, data: response } = useQuery(
        getChatServiceKey("getUsersList", JSON.stringify(tabType)),
        async () => {
            return await chatService.getUsersList(tabType);
        },
        { enabled: true, cacheTime: 0 }
    );

    const onChangeHandler = (e: any) => {
        if (e.target.name === "file") {
            setState({ ...state, [e.target.name]: e.target.files[0] });
        } else {
            setState({ ...state, [e.target.name]: e.target.value });
        }
    };

    const filterMember = React.useMemo((): MemberListResponse[] => {
        if (response != null && response.Data != null) {
            return response.Data.filter((item) => {
                if (item && item.MemberInfo && item.MemberInfo.Name) {
                    return item.MemberInfo.Name.toLowerCase().includes(
                        memberSearchValue.toLowerCase()
                    );
                }
            });
        }
        return [];
    }, [memberSearchValue, response]);

    const fileRef = React.useRef<any>(null);
    const onItemClickHandler = (value: MemberListResponse) => {
        const index = state.MembersIdsList.indexOf(value.UserId);
        if (index !== -1) {
            const members = [...state.MembersIdsList];
            members.splice(index, 1);
            setState({
                ...state,
                MembersIdsList: members,
            });
        } else {
            if (tabType.UsersType === ChatTabsType.CHATS) {
                setState({
                    ...state,
                    MembersIdsList: [value.UserId],
                });
            } else {
                setState({
                    ...state,
                    MembersIdsList: [...state.MembersIdsList, value.UserId],
                });
            }
        }
    };
    const onFileClickHandler = () => {
        fileRef.current.click();
    };
    let title = t("chat.title.newChat");
    let submitText = t("chat.button.createChat");

    if (tabType.UsersType === ChatTabsType.REMOVE_MEMBERS) {
        title = t("chat.title.removeMembers");
        submitText = t("chat.button.remove");
    } else if (tabType.UsersType === ChatTabsType.ADD_MEMBERS) {
        title = t("chat.title.addMembers");
        submitText = t("chat.button.add");
    } else if (tabType.UsersType === ChatTabsType.GROUPS) {
        title = t("chat.title.newGroup");
        submitText = t("chat.button.createGroup");
    }

    const validityManager = useMemo(() => {
        let temp = getInitializedValidityState([], []);
        let validationRules: ValidationRules<keyof AddChannelRequestModel> = {
            MembersIdsList: [
                {
                    rule: Validations.ARRAY_REQUIRED,
                    message: t("common.noOptionSelected"),
                },
            ],
        };
        if (tabType.UsersType === ChatTabsType.GROUPS) {
            validationRules = {
                ...validationRules,
                GroupName: [
                    {
                        rule: Validations.REQUIRED,
                        message: t("chat.groupName.missing"),
                    },
                ],
            };
        }
        temp = getInitializedValidityStateFromRules(
            validationRules,
            state,
            temp
        );
        return new ValidityStateManager(temp);
    }, [state]);

    return !loading ? (
        <AppDialog
            title={title}
            onClose={onClose}
            modalOpen={modalOpen}
            {...rest}
        >
            <Row>
                {tabType.UsersType === ChatTabsType.GROUPS && (
                    <>
                        <Col xs={12} className={styles.header}>
                            <Form.File
                                ref={fileRef}
                                id="avatarInput"
                                label="avatarInput"
                                style={{ display: "none" }}
                                name="file"
                                multiple={false}
                                onChange={onChangeHandler}
                            />
                            {state.file != null ? (
                                <Image
                                    src={URL.createObjectURL(state.file)}
                                    roundedCircle
                                    className={styles.image}
                                    onClick={onFileClickHandler}
                                />
                            ) : (
                                <div
                                    className={styles.updateImage}
                                    onClick={onFileClickHandler}
                                >
                                    <Image src={ImageAssets.common.camera} />
                                </div>
                            )}

                            <div className={styles.GroupNameDiv}>
                                <Form.Group>
                                    <Form.Control
                                        className={styles.groupNameField}
                                        size="lg"
                                        type="text"
                                        value={state.GroupName}
                                        name="GroupName"
                                        onChange={onChangeHandler}
                                        placeholder={t("chat.groupName.name")}
                                    />
                                    <ErrorMessage
                                        errorInfo={validityManager.getFirstErrorInfo(
                                            "GroupName"
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                    </>
                )}
                <Col xs={12} className={styles.searchDiv}>
                    <SearchField
                        value={memberSearchValue}
                        onValueChange={(value) => setMemberSearchValue(value)}
                        className={styles.searchField}
                    />
                </Col>
                <Col xs={12} className={styles.memberListDiv}>
                    <ChatChannelLoader loading={userListLoading} res={response}>
                        {() => (
                            <ListGroup>
                                {filterMember.length > 0 ? (
                                    filterMember.map((member) => {
                                        const isCheck =
                                            state.MembersIdsList.includes(
                                                member.UserId
                                            );

                                        return (
                                            <ListGroup.Item
                                                key={member.UserId}
                                                className={classNames(
                                                    styles.listItem,
                                                    { [styles.active]: isCheck }
                                                )}
                                            >
                                                <ChannelItem
                                                    className={
                                                        styles.popperItem
                                                    }
                                                    onClick={() =>
                                                        onItemClickHandler(
                                                            member
                                                        )
                                                    }
                                                    channel={{
                                                        ...member.MemberInfo,
                                                        ChannelOriginalName:
                                                            defaultTo(
                                                                member
                                                                    .MemberInfo
                                                                    .Name,
                                                                ""
                                                            ),
                                                        LastMessage: null,
                                                        PinnedAt: null,
                                                        IsPinned: null,
                                                        ChannelId: null,
                                                        LastMessageActualDate:
                                                            null,
                                                        UnReadMessageCount:
                                                            null,
                                                        ChannelUserType:
                                                            member.Type ==
                                                            ChatBusinessUserType.USER_CLIENT_CONTACT
                                                                ? TwilioChannelUserType.Parent
                                                                : member.Type ==
                                                                  ChatBusinessUserType.USER_EMPLOYEE
                                                                ? TwilioChannelUserType.Employee
                                                                : ChatBusinessUserType.USER_EMPLOYEE_AND_CONTACT
                                                                ? TwilioChannelUserType.ParentAndEmployee
                                                                : undefined,
                                                        ChannelType:
                                                            ChannelType.ONE_TO_ONE, // to show Employee,Client after user name in bracket
                                                        LastMessageTime: null,
                                                        MemberCount: null,
                                                        MemberSid: null,
                                                        AdminName: null,
                                                    }}
                                                    message={
                                                        member.MemberInfo.Email
                                                    }
                                                    readOnly={true}
                                                />

                                                {isCheck && (
                                                    <div>
                                                        <Image
                                                            className={
                                                                styles.tick
                                                            }
                                                            src={
                                                                tabType.UsersType ===
                                                                ChatTabsType.REMOVE_MEMBERS
                                                                    ? ImageAssets
                                                                          .common
                                                                          .crossRed
                                                                    : ImageAssets
                                                                          .common
                                                                          .tickBlue
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </ListGroup.Item>
                                        );
                                    })
                                ) : (
                                    <span className={styles.noChatUserMsg}>
                                        {t("chat.noChatUserFound")}
                                    </span>
                                )}
                            </ListGroup>
                        )}
                    </ChatChannelLoader>
                </Col>

                <Col xs={12}>
                    <AppDialogFooter
                        onDialogClose={onClose}
                        onClickSaveButton={() => onChange(state)}
                        disableSaveButton={
                            !validityManager.isStateValid() ||
                            !hasCreatePermission
                        }
                        saveButtonText={submitText}
                    />
                </Col>
            </Row>
        </AppDialog>
    ) : (
        <></>
    );
};

export default ChannelUserDialog;
