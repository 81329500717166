export enum EmployeeBenefitStatus {
    OnAdvance,
    Active,
    Expired,
}

export enum EmployeeBenefitSortColumn {
    DisplayId,
    EmployeeName,
    StartDate,
    EndDate,
    Status,
    Reason,
    Amount,
}
