import { cookieAvailable } from "globals/helpers/cookieHelper";
import { constructFullUrl } from "globals/helpers/generalHelper";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { defaultTo } from "lodash-es";
import { BaseObject, CookiesKey } from "models/general";
import { PropsWithChildren, useMemo } from "react";
import { Navigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { noAuthRoutes } from "routing/routes";

const fullAuthRoutes = noAuthRoutes().screens;
const noAuthOnlyRoutes = [
    fullAuthRoutes.login(),
    // fullAuthRoutes.getDemo(),
    fullAuthRoutes.sessionTimeOut(),
    fullAuthRoutes.forgotPassword(),
    fullAuthRoutes.forgotPasswordConfirmation(),
];

// will filter and handle redirection for routes that is only allowed if user is not logged in
export const NoAuthRoute: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}) => {
    const { isAuthenticated, loaded } = useSessionUser();
    const { sessionTimeOut } = useAppContext();
    const { checkPermission } = useCheckPermission();
    const [searchParams] = useSearchParams();
    const { linkProvider } = useRouting();
    const location = useLocation();
    const hasUserCookie = cookieAvailable(CookiesKey.USER_COOKIE);

    if (
        // On login if user cookie is available still redirect to home
        // As there is a possibility that user has valid token saved in cookie
        (isAuthenticated || hasUserCookie) &&
        !sessionTimeOut && // if on session timeOut don't redirect
        noAuthOnlyRoutes.some(
            (p) => p.toLowerCase() === location.pathname.toLocaleLowerCase()
        )
    ) {
        const redirectUrl = defaultTo(
            // if url has param then redirect to that and redirect to full url otherwise it appends on current path and returns 404
            constructFullUrl(searchParams.get("redirectUrl")),
            linkProvider.screens.home(
                !isAuthenticated ? undefined : checkPermission
            )
        );
        if (redirectUrl.includes("http")) {
            window.location.replace(redirectUrl);
            return <></>;
        }
        return <Navigate to={redirectUrl} replace />;
    }
    return <>{children}</>;
};

export default NoAuthRoute;
