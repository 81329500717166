import i18n from "i18next";
import {
    getMomentDateFormat,
    getMomentDateTimeFormat,
} from "models/datepicker";
import { AppLocale_ISO2 } from "models/general";
import moment from "moment-timezone";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";
import {
    bsTranslation,
    csTranslation,
    deTranslation,
    elTranslation,
    enTranslation,
    esTranslation,
    etTranslation,
    fiTranslation,
    frTranslation,
    hrTranslation,
    huTranslation,
    itTranslation,
    nlTranslation,
    plTranslation,
    skTranslation,
    slTranslation,
} from "./translations";

i18n.use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        debug: false,
        resources: {
            en: enTranslation,
            de: deTranslation,
            it: itTranslation,
            bs: bsTranslation,
            el: elTranslation,
            cs: csTranslation,
            es: esTranslation,
            et: etTranslation,
            fi: fiTranslation,
            fr: frTranslation,
            hr: hrTranslation,
            hu: huTranslation,
            pl: plTranslation,
            nl: nlTranslation,
            sk: skTranslation,
            sl: slTranslation,
        },
        fallbackLng: navigator.language,
        interpolation: {
            escapeValue: false,
            format: function (value, format, lng) {
                if (typeof value == "number") {
                    return numeral(value).format(format);
                } else if (value instanceof moment) {
                    if (format === "dateFormat") {
                        return moment(value).format(
                            getMomentDateFormat(lng as AppLocale_ISO2)
                        );
                    } else if (format === "dateTimeFormat") {
                        return moment(value).format(
                            getMomentDateTimeFormat(lng as AppLocale_ISO2)
                        );
                    } else {
                        return "";
                    }
                } else {
                    return value;
                }
            },
        },
    });

export default i18n;
