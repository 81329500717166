import { LW_HqRegisterRequests } from "models";
import { Feature_LW_Hq_Register_Request } from "models/applicationFeatures";
import { RoutesMap } from "routing";
import { lillywaitClientRoutesMap } from "routing/routes/lillywait/client/routesMap";
import { locationSettingRoutesMap } from "routing/routes/lillywait/locationSetting/routesMap";
import {
    SetupLwcHQ,
    HqRegisterRequests,
} from "screens/lillywait/registrationRequests";
import lillywaitRoutes from "./routes";

const relativeRoutes = lillywaitRoutes(false).admin;
const fullRoutes = lillywaitRoutes().admin;

export const lillywaitRoutesMap: RoutesMap = {
    [relativeRoutes.registrationRequests.list()]: {
        component: HqRegisterRequests,
        fullPattern: fullRoutes.registrationRequests.list(),
        pattern: relativeRoutes.registrationRequests.list(),
        permKeys: [LW_HqRegisterRequests],
        featureKeys: [Feature_LW_Hq_Register_Request],
    },
    [relativeRoutes.registrationRequests.createHq()]: {
        component: SetupLwcHQ,
        fullPattern: fullRoutes.registrationRequests.createHq(),
        pattern: relativeRoutes.registrationRequests.createHq(),
        permKeys: [LW_HqRegisterRequests],
        featureKeys: [Feature_LW_Hq_Register_Request],
    },
    [relativeRoutes.registrationRequests.createHqWithoutRequest()]: {
        component: SetupLwcHQ,
        fullPattern: fullRoutes.registrationRequests.createHqWithoutRequest(),
        pattern: relativeRoutes.registrationRequests.createHqWithoutRequest(),
        permKeys: [LW_HqRegisterRequests],
        featureKeys: [Feature_LW_Hq_Register_Request],
    },
    ...lillywaitClientRoutesMap,
    ...locationSettingRoutesMap,
};
