import { defaultTo } from "lodash-es";
import { BusinessType, getBusinessTypePathString } from "models/business";
import BaseRoutingContext from "../BaseRoutingContext";

export class BusinessRoutingContext extends BaseRoutingContext {
    protected businessType: BusinessType | ":sessionBusinessType";
    protected businessId: string;
    getBusinessType(): string {
        return this.businessType;
    }
    getBusinessId(): string {
        return this.businessId;
    }
    constructor(
        businessType?: BusinessType | ":sessionBusinessType" | undefined,
        businessId?: string | undefined
    ) {
        super();
        this.businessType = defaultTo(businessType, ":sessionBusinessType");
        this.businessId = defaultTo(businessId, ":sessionBusinessId");
    }
    getContextIdentifier(): string {
        return "business-context";
    }
    private resolveBusinessType(): string {
        const bType =
            this.businessType !== ":sessionBusinessType"
                ? getBusinessTypePathString(this.businessType)
                : this.businessType;
        return !bType.includes(":") ? bType.toLowerCase() : bType;
    }
    getBaseUrl() {
        return `${super.getBaseUrl()}/${this.resolveBusinessType()}/${
            this.businessId
        }`;
    }
    getBaseApiUrl() {
        // for using in business specific contexts
        return super.getApiBaseUrl();
    }
    getApiBaseUrl() {
        return `${super.getApiBaseUrl()}/business/${this.businessId}`; // for common business APIs
    }
}

export default BusinessRoutingContext;
