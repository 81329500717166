import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { CareScopeTemplate } from "models";
import React from "react";
import { useTranslation } from "react-i18next";

export interface CareScopeTemplateSubRowContentProps {
    template: CareScopeTemplate;
    isParentTemplate: boolean;
    hasDeletePermission?: boolean;
    canClone?: boolean;
    isForLocationSetting?: boolean;
    showDelete?: boolean;
    onClick: () => void;
    onClone: () => void;
    onDeleteClick: (id: string) => void;
}

export const CareScopeTemplateSubRowContent: React.FC<
    CareScopeTemplateSubRowContentProps
> = ({
    template,
    hasDeletePermission = false,
    showDelete = true,
    canClone = false,
    isForLocationSetting = false,
    onClone,
    onClick,
    isParentTemplate,
    onDeleteClick,
}) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();

    return (
        <>
            <td
                style={{ width: isForLocationSetting ? "47.5%" : "37.5%" }}
                onClick={onClick}
            >
                {template.StartDate.format(getDateFormatForLocale())}
            </td>
            <td onClick={onClick}>
                {template.EndDate
                    ? template.EndDate.format(getDateFormatForLocale())
                    : t("common.endDate.hasNoEndDate")}
            </td>
            <td style={getFixedCssWidths(120)}>
                <div
                    style={{
                        width: "65px",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {canClone && (
                        <AppIconButton
                            buttonType="Clone"
                            showToolTip={true}
                            tooltipText={t("common.clone")}
                            onClick={() => {
                                onClone();
                            }}
                        />
                    )}
                    {showDelete && (
                        <AppDeleteButton
                            getConfirmation={true}
                            name={template.Name}
                            readonly={!hasDeletePermission || isParentTemplate}
                            onClick={() => {
                                if (template.EncodedId) {
                                    onDeleteClick(template.EncodedId);
                                }
                            }}
                        />
                    )}
                </div>
            </td>
        </>
    );
};

export default CareScopeTemplateSubRowContent;
