/* eslint-disable react/display-name */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styles from "./AppFloatingButton.module.scss";

export interface AppFloatingButton extends ButtonProps {
    icon?: IconProp;
    containerClass?: string;
    greenBtn?: boolean;
    className?: string;
}
export const AppFloatingButton = React.memo<AppFloatingButton>(
    ({
        icon = "save",
        containerClass,
        className,
        greenBtn = true,
        ...rest
    }) => {
        return (
            <div className={classNames(containerClass, styles.root)}>
                <Button
                    {...rest}
                    className={classNames(className, styles.floatingBtn, {
                        ["green-btn"]: greenBtn,
                    })}
                >
                    <FontAwesomeIcon icon={icon} />
                </Button>
            </div>
        );
    }
);

export default AppFloatingButton;
