import { AppDatePicker, AppInputField } from "components/FormFields";
import { defaultTo } from "lodash-es";
import { BusinessCourseRequestParameters } from "models/businessCourses/request";
import { Optional } from "models/general";
import { Moment } from "moment-timezone";

export interface BusinessCoursesFilterRowProps {
    value: BusinessCourseRequestParameters;
    onChange: (value: BusinessCourseRequestParameters) => void;
}

export const BusinessCoursesFilterRow: React.FC<
    BusinessCoursesFilterRowProps
> = ({ value, onChange }) => {
    return (
        <tr className="filters-row">
            <td></td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.Name, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            Name: val,
                        })
                    }
                />
            </td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.ShortName, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            ShortName: val,
                        })
                    }
                />
            </td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.Floor, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            Floor: val,
                        })
                    }
                />
            </td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.Area, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            Area: val,
                        })
                    }
                />
            </td>
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    appendToBody={true}
                    value={value.StartDate}
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onChange({
                                ...value,
                                StartDate: val,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    value={value.EndDate}
                    appendToBody={true}
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onChange({
                                ...value,
                                EndDate: val,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
        </tr>
    );
};

export default BusinessCoursesFilterRow;
