import { StartAndEndDateFields } from "commonPartials";
import { ErrorMessage } from "components";
import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppImageUploaderWithPreview } from "components/File";
import { AppInputField } from "components/FormFields";
import { AppLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessType,
    Business_Courses,
    LW_Industry_Courses,
    LW_Kindergarten_Courses,
    PermissionAccessTypes,
} from "models";
import {
    getBusinessCoursesCreateEdit,
    getBusinessCoursesFromResponse,
    getDefaultBusinessCourses,
} from "models/businessCourses/helper";
import {
    BusinessCourseCreateEditRequest,
    BusinessCourse,
    CourseGroupField,
} from "models/businessCourses";
import { validateBusinessCourses } from "models/businessCourses/validate";
import { ValidityStateManager } from "models/general";
import { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import CourseGroupsFieldsContainer from "./partials/createEditPartials/CourseGroupsFieldsContainer";
import BusinessCoursesService, {
    getBusinessCoursesServiceKey,
} from "services/business/BusinessCoursesService";
import styles from "./BusinessCourses.module.scss";

export const BusinessCoursesCreateEdit: React.FC = () => {
    const { appLocale } = useLocaleHelpers();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();
    const { id, lwcId } = useParams();
    const { checkPermission } = useBusinessCheckPermission();
    const { t } = useTranslation();
    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();

    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();
    const isLwc = !isNil(lwcId);

    const businessCoursesService = new BusinessCoursesService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).courses
    );

    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = isLwc
        ? (currentBusiness?.Type == BusinessType.KINDERGARTEN
              ? lwcBase.kindergartens
              : lwcBase.industries
          ).withId()
        : linkProvider.business.businessSettings().screens;

    const [state, setState] = useState<BusinessCourseCreateEditRequest>(
        getDefaultBusinessCourses()
    );

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: coursesRes,
        refetch: fetchDetails,
    } = useQuery(
        getBusinessCoursesServiceKey("get", {
            id: id,
            businessId: currentBusiness?.Id,
        }),
        async () => {
            if (id) {
                return await businessCoursesService.get(id);
            }
        },
        {
            enabled: false,
            select: (listRes) => {
                if (listRes?.Data) {
                    return getBusinessCoursesFromResponse(listRes.Data);
                } else if (!isNil(id)) {
                    showErrorPage(404);
                }
            },
        }
    );
    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(
            async () => await businessCoursesService.createUpdate(state),
            {
                onSuccess: (res) => {
                    if (res.Data) {
                        showSweetAlertToast(
                            t("common.success"),
                            `"${res.Data.Name}" ${t(
                                `common.actions.${
                                    id
                                        ? "updatedSuccessfully"
                                        : "createdSuccessfully"
                                }`
                            )}`,
                            "success"
                        );
                        queryClient.invalidateQueries(
                            getBusinessCoursesServiceKey("list", {
                                businessId: currentBusiness?.Id,
                            })
                        );
                        navigate(linkProviderBase.courses.list());
                    } else if (res.Message) {
                        showSweetAlertToast("Error", res.Message, "error");
                    } else {
                        showSweetAlertToast(
                            t("common.error.error"),
                            t(
                                `common.actions.errors.${
                                    id ? "unableToUpdate" : "unableToCreate"
                                }`
                            ),
                            "error"
                        );
                    }
                },
            }
        );

    useEffect(() => {
        if (id) {
            fetchDetails();
        }
    }, [id]);
    useEffect(() => {
        if (!getLoading && !getRefetching && coursesRes) {
            setState({
                ...state,
                ...getBusinessCoursesCreateEdit(coursesRes as BusinessCourse),
            });
        }
    }, [getLoading, getRefetching]);

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            state ? validateBusinessCourses(state) : undefined
        );
    }, [state, appLocale]);

    const readonly = !(!isLwc
        ? checkPermission(
              currentBusiness?.Id as number,
              currentBusiness?.HqId as number,
              Business_Courses,
              [
                  !isNil(id)
                      ? PermissionAccessTypes.EDIT
                      : PermissionAccessTypes.CREATE,
              ]
          )
        : checkPermission(
              sessionBusinessId,
              sessionHqId,
              currentBusiness?.Type == BusinessType.KINDERGARTEN
                  ? LW_Kindergarten_Courses
                  : LW_Industry_Courses,
              [
                  !isNil(id)
                      ? PermissionAccessTypes.EDIT
                      : PermissionAccessTypes.CREATE,
              ]
          ));

    return (
        <div style={{ position: "relative" }}>
            {!state || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={true}
                    onBack={() => navigate(linkProviderBase.courses.list())}
                    title={
                        id
                            ? state.Name
                            : t("business.courses.createEdit.create")
                    }
                    hasInnerScroll={true}
                    footer={
                        !readonly ? (
                            <AppContainerFooter
                                primaryButtonProps={{
                                    disabled:
                                        createUpdateLoading ||
                                        !validityStateManager.isStateValid(),
                                    onClick: () => {
                                        if (!readonly) {
                                            createUpdate();
                                        }
                                    },
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Row>
                        <Col md={6}>
                            <AppInputField
                                label={t("business.courses.table.name")}
                                value={state.Name}
                                readOnly={readonly}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Name: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Name"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInputField
                                label={t("business.courses.table.shortName")}
                                value={state.ShortName}
                                readOnly={readonly}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        ShortName: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "ShortName"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInputField
                                label={t("business.courses.table.floor")}
                                value={state.Floor}
                                readOnly={readonly}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Floor: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Floor"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInputField
                                label={t("business.courses.table.area")}
                                value={state.Area}
                                readOnly={readonly}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Area: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Area"
                                )}
                            />
                        </Col>
                        <StartAndEndDateFields
                            appendToBody={true}
                            readonly={readonly}
                            value={{
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            }}
                            onChange={({
                                StartDate: StartDate,
                                EndDate: EndDate,
                            }) => {
                                setState({
                                    ...state,
                                    StartDate: StartDate,
                                    EndDate: EndDate,
                                });
                            }}
                        />
                    </Row>
                    <CourseGroupsFieldsContainer<CourseGroupField>
                        onChange={(newFields) => {
                            setState({
                                ...state,
                                Groups: newFields.map((f, idx) => {
                                    return { ...f, Order: idx };
                                }),
                            });
                        }}
                        validityState={validityStateManager.state}
                        fields={state.Groups}
                        readonly={readonly}
                    />
                    <div className="d-flex align-items-center">
                        <Form.Group
                            controlId="avatar"
                            style={{ marginTop: "18px" }}
                        >
                            <AppImageUploaderWithPreview
                                avatarUrl={state.Avatar?.FullUrl}
                                className={styles.updateAvatar}
                                file={state.fileRequest}
                                onUpload={(file: File) => {
                                    setState({ ...state, fileRequest: file });
                                }}
                            />
                        </Form.Group>
                        <div className={styles.avatarRight}>
                            <Form.File
                                type="file"
                                name="file"
                                accept="image/*"
                                className={styles.fileField}
                                onChange={(e: any) => {
                                    setState({
                                        ...state,
                                        fileRequest: e.target.files[0],
                                    });
                                }}
                            />
                            <ErrorMessage
                                errorInfo={validityStateManager.getAllErrors(
                                    "file"
                                )}
                            />
                        </div>
                    </div>
                </AppContainer>
            )}
        </div>
    );
};
export default BusinessCoursesCreateEdit;
