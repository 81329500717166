import { AppIconWithName, AppPopover } from "components";
import { ImageAssets } from "globals/images";
import { BusinessPerson } from "models/general";
import React from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./RoleManagementUserDetailPopover.module.scss";
export interface RoleManagementUserDetailPopoverProps {
    mainHeading: string;
    assignmentSummary: string;
    className?: string;
    persons: BusinessPerson[];
}
export const RoleManagementUserDetailPopover =
    React.memo<RoleManagementUserDetailPopoverProps>(
        ({ mainHeading, persons, className, assignmentSummary }) => {
            return (
                <>
                    <AppPopover
                        triggerOnClick={false}
                        delay={200}
                        trigger={"mouseenter focus"}
                        containerClass={`${className} mb-auto`}
                        placement={"auto-end"}
                        triggerElem={<span>{assignmentSummary} </span>}
                    >
                        <Container className={styles.root}>
                            <Row>
                                <Col xs={12}>
                                    <div className={styles.title}>
                                        <div
                                            className={`d-flex ${styles.header}`}
                                        >
                                            <div className={styles.iconHelp}>
                                                <Image
                                                    src={
                                                        ImageAssets.common
                                                            .helpCircleBlue
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span
                                                    className={
                                                        styles.mainHeading
                                                    }
                                                >
                                                    {mainHeading}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.underline} />
                                </Col>
                                <Col xs={12}>
                                    <div
                                        className={`d-flex flex-column ${styles.body}`}
                                    >
                                        <Table striped className="no-gutters">
                                            <tbody>
                                                {persons &&
                                                    persons.map((x) => (
                                                        <tr key={uuid()}>
                                                            <td>
                                                                <AppIconWithName
                                                                    text={
                                                                        x.Name as string
                                                                    }
                                                                    iconClass={
                                                                        styles.icon
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </AppPopover>
                </>
            );
        }
    );

export default RoleManagementUserDetailPopover;
