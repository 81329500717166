import { AppGradientButton } from "components/Buttons";
import { AuthInputField } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import ImageAssets from "globals/images/common";
import { defaultTo } from "lodash-es";
import { RegisterRequest, validateRegister } from "models";
import { AppResponse, ErrorCodes } from "models/general/response";
import {
    getValidityStateFromApiResponse,
    ValidityStateManager,
} from "models/general/validityState";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TermsAndCondition } from "../TermsAndCondition";
import styles from "./RegisterFields.module.scss";

export interface RegisterFieldsProps {
    value: RegisterRequest;
    onChange: (value: RegisterRequest) => void;
    onClick: (data: RegisterRequest) => void;
    loading: boolean;
    response: AppResponse<boolean> | undefined;
    isRegistrationComplete?: boolean;
    submitText?: string;
    redirectUrl: string;
}

export const RegisterFields: React.FC<RegisterFieldsProps> = ({
    value,
    onChange,
    onClick,
    loading,
    response,
    isRegistrationComplete = false,
    redirectUrl,
    submitText,
}) => {
    const { t } = useTranslation();
    submitText = defaultTo(submitText, t("common.update").toString());
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const redirect = () => {
        navigate(redirectUrl);
    };

    const [showError, setShowError] = useState(false);

    const validityStateManager = useMemo(() => {
        let state = validateRegister(value, isRegistrationComplete);
        if (!showError && response) {
            if (
                response.Errors &&
                response.Code &&
                response.Code === ErrorCodes.INTERNAL_SERVER_ERROR
            ) {
                showSweetAlertToast(
                    t("auth.errorMessages.registration"),
                    "",
                    "error"
                );
            }
            setShowError(true);
            state = getValidityStateFromApiResponse(response);
        }
        return new ValidityStateManager(state);
    }, [value, isRegistrationComplete, response, t]);

    const onButtonClick = () => {
        if (validityStateManager.isStateValid()) {
            setShowError(false);
            onClick(value);
        } else {
            setShowError(true);
        }
    };

    useEffect(() => {
        if (!loading && response && response.Data) {
            redirect();
        }
    }, [loading, redirect, response]);

    return (
        <div>
            <AuthInputField
                value={value.FirstName}
                useBubbleError={true}
                onValueChange={(val: string) =>
                    onChange({
                        ...value,
                        FirstName: val,
                    })
                }
                onBlur={(val: string) =>
                    onChange({
                        ...value,
                        FirstName: val,
                    })
                }
                disabled={isRegistrationComplete}
                iconPath={ImageAssets.common.userOutlineGray}
                placeholder={t("auth.firstName")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("FirstName")
                        : undefined
                }
            />
            <AuthInputField
                value={value.LastName}
                useBubbleError={true}
                onValueChange={(val: string) =>
                    onChange({
                        ...value,
                        LastName: val,
                    })
                }
                onBlur={(val: string) =>
                    onChange({
                        ...value,
                        LastName: val,
                    })
                }
                disabled={isRegistrationComplete}
                iconPath={ImageAssets.common.userOutlineGray}
                placeholder={t("auth.lastName")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("LastName")
                        : undefined
                }
            />
            {!isRegistrationComplete && (
                <>
                    <AuthInputField
                        value={value.CompanyName}
                        useBubbleError={true}
                        onValueChange={(val: string) =>
                            onChange({
                                ...value,
                                CompanyName: val,
                            })
                        }
                        onBlur={(val: string) =>
                            onChange({
                                ...value,
                                CompanyName: val,
                            })
                        }
                        disabled={isRegistrationComplete}
                        iconPath={ImageAssets.common.userOutlineGray}
                        placeholder={t("auth.companyName")}
                        error={
                            showError
                                ? validityStateManager.getFirstErrorInfo(
                                      "CompanyName"
                                  )
                                : undefined
                        }
                    />
                    <AuthInputField
                        value={value.FacilityName}
                        useBubbleError={true}
                        onValueChange={(val: string) =>
                            onChange({
                                ...value,
                                FacilityName: val,
                            })
                        }
                        onBlur={(val: string) =>
                            onChange({
                                ...value,
                                FacilityName: val,
                            })
                        }
                        disabled={isRegistrationComplete}
                        iconPath={ImageAssets.common.userOutlineGray}
                        placeholder={t("auth.facilityName")}
                        error={
                            showError
                                ? validityStateManager.getFirstErrorInfo(
                                      "FacilityName"
                                  )
                                : undefined
                        }
                    />
                </>
            )}

            <AuthInputField
                value={value.Email}
                showHelp={!isRegistrationComplete}
                useBubbleError={true}
                onValueChange={(val: string) =>
                    onChange({
                        ...value,
                        Email: val,
                    })
                }
                onBlur={(val: string) =>
                    onChange({
                        ...value,
                        Email: val,
                    })
                }
                disabled={isRegistrationComplete}
                iconPath={ImageAssets.common.envelope}
                placeholder={t("auth.email")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("Email")
                        : undefined
                }
            />
            <AuthInputField
                value={value.Password}
                showHelp={true}
                useBubbleError={true}
                type="password"
                onValueChange={(val: string) =>
                    onChange({
                        ...value,
                        Password: val,
                    })
                }
                onBlur={(val: string) =>
                    onChange({
                        ...value,
                        Password: val,
                    })
                }
                iconPath={ImageAssets.common.lockGray}
                placeholder={t("auth.password")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("Password")
                        : undefined
                }
            />
            <AuthInputField
                value={value.ConfirmPassword}
                useBubbleError={true}
                type="password"
                onValueChange={(val: string) =>
                    onChange({
                        ...value,
                        ConfirmPassword: val,
                    })
                }
                onBlur={(val: string) =>
                    onChange({
                        ...value,
                        ConfirmPassword: val,
                    })
                }
                iconPath={ImageAssets.common.lockGray}
                placeholder={t("auth.confirmPassword")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo(
                              "ConfirmPassword"
                          )
                        : undefined
                }
            />
            <TermsAndCondition />
            <AppGradientButton
                className={styles.registerButton}
                disabled={showError && !validityStateManager.isStateValid()}
                onClick={() => onButtonClick()}
                text={submitText}
            />
        </div>
    );
};

export default RegisterFields;
