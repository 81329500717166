import { AppSelectOld } from "components/AppSelect";
import { AppInputField } from "components/FormFields";
import { appTableFilterSelectStyles } from "components/Table/AppTable";
import { FORMAT_INTEGER } from "globals/constants";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import { BusinessStatus } from "models";
import { BoolToYesNo, SelectItem } from "models/general";
import { LwClientFilters } from "models/lwClient";
import React from "react";
import { useTranslation } from "react-i18next";

export interface LwClientsFilterRowProps {
    value: LwClientFilters;
    isForSupportTable?: boolean;
    clientTotal: number;
    employeeTotal: number;
    onChange: (val: LwClientFilters) => void;
}

export const LwClientsFilterRow: React.FC<LwClientsFilterRowProps> = ({
    value,
    clientTotal,
    employeeTotal,
    onChange,
    isForSupportTable = false,
}) => {
    const { t } = useTranslation();
    const allOption: SelectItem = {
        Text: t("common.all"),
        Value: null,
    };
    const { formatNumber } = useLocaleHelpers();
    const statusOptions: SelectItem[] = [
        allOption,
        ...Object.values(BusinessStatus).map((x) => {
            return {
                Text: t(`common.status.${x.toString()}`),
                Value: x.toString(),
            } as SelectItem;
        }),
    ];

    const isHqOptions: SelectItem[] = [
        allOption,
        ...Object.values(BoolToYesNo)
            .filter((x) => typeof x === "number")
            .map((x) => {
                return {
                    Text: t(`common.${x === 1 ? "yes" : "no"}`),
                    Value: x.toString(),
                } as SelectItem;
            }),
    ];

    return (
        <tr className="filters-row">
            <td></td>
            <td></td>
            <td>
                <AppInputField
                    showClearButton={true}
                    value={value.CompanyName}
                    onValueChange={(val) =>
                        onChange({ ...value, CompanyName: val })
                    }
                />
            </td>
            <td>
                <AppInputField
                    showClearButton={true}
                    value={value.Name}
                    onValueChange={(val) => onChange({ ...value, Name: val })}
                />
            </td>
            <td></td>
            <td></td>
            <td>
                {formatNumber(clientTotal, FORMAT_INTEGER) + " "}
                {t("lwClient.clientTotal")}
            </td>
            <td>
                {formatNumber(employeeTotal, FORMAT_INTEGER) + " "}
                {t("lwClient.employeeTotal")}
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.IsHQ)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        value.IsHQ == null
                            ? allOption
                            : isHqOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          value.IsHQ
                                  )
                    }
                    options={isHqOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                IsHQ:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    menuPortalTarget={document.body}
                    options={statusOptions}
                    styles={appTableFilterSelectStyles}
                    showDropdownIndicator={isNil(value.Status)}
                    showCustomClearIndicator={true}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        value.Status == null
                            ? allOption
                            : statusOptions.find(
                                  (x) => x.Value === value.Status
                              )
                    }
                    onChange={(e: SelectItem) => {
                        onChange({
                            ...value,
                            Status: e ? (e.Value as BusinessStatus) : null,
                        });
                    }}
                />
            </td>
            <td></td>
        </tr>
    );
};

export default LwClientsFilterRow;
