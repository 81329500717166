import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./AppGoBackButton.module.scss";

export interface AppGoBackButtonProps {
    backText?: string;
    hasText?: boolean;
    backPath?: string;
    onClick?: () => void;
    className?: string;
}
export const AppGoBackButton: React.FC<AppGoBackButtonProps> = ({
    backPath,
    backText,
    hasText = true,
    onClick,
    className,
}) => {
    const { t } = useTranslation();

    const content = (
        <>
            <div className={styles.arrow}>
                <FontAwesomeIcon icon={"arrow-left"} />
            </div>
            {hasText ? (
                <span className={styles.backText}>
                    {defaultTo(backText, t("common.goBack"))}
                </span>
            ) : (
                ""
            )}
        </>
    );
    const classes = classNames(
        styles.root,
        className,
        { [styles.btnWidth]: hasText },
        {
            [styles.hoverEffect]: !hasText,
        }
    );
    return (
        <>
            {backPath ? (
                <Link
                    to={backPath}
                    className={classes}
                    onClick={defaultTo(onClick, undefined)}
                >
                    {content}
                </Link>
            ) : (
                <button
                    onClick={onClick}
                    className={`${styles.button} ${classes} btn`}
                >
                    {content}
                </button>
            )}
        </>
    );
};

export default AppGoBackButton;
