import { ISO8601_DATE_FORMAT } from "globals/constants";
import { AppRouteParams } from "hooks/general/routing";
import { defaults, defaultTo, isNil } from "lodash-es";
import { ApplicationFeatureRequest } from "models/applicationFeatures/request";
import { BusinessType, UserFilterRequest } from "models/business";
import { CompanyType } from "models/business/enum";
import { PinChannelRequest } from "models/chat";
import { DashboardTabs } from "models/dashboard";
import { DocumentTag } from "models/documentTemplates/enum";
import { EmployeeType } from "models/employee/enum";
import {
    ActiveFilterDataRequest,
    AppPlatforms,
    DateRangeRequest,
    DeviceTypeParam,
    Optional,
    serializeDateRangeRequest,
} from "models/general";
import { EmployeeAbsencesRequestParameters } from "models/businessAbsents";
import { RolePaginationRequest } from "models/roleManagement/request";
import { ControllerType } from "models/timeManagement/enum";
import { TimeTrackingRequestParameters } from "models/timeManagement/request";
import { UserListRequest } from "models/userManagement/request";
import { businessEmployeeRoutes } from "routing/routes/business/employee";
import { businessSettingRoutes } from "routing/routes/business/settings";
import {
    BusinessRoutingContext,
    BusinessSettingRoutingContext,
} from "routing/routingContexts";
import {
    DefaultRouteParams,
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";
import {
    ShiftPlanRequest,
    ShiftPlanOptionsRequest,
    ShiftCriteriaType,
    BankDataRequest,
} from "models";
import { DocumentTemplateRequest } from "models/documentTemplates";

export const businessRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    params?: AppRouteParams
) => {
    const currParams = defaults({}, params, DefaultRouteParams);
    const context = new BusinessRoutingContext(
        currParams.sessionBusinessType,
        currParams.sessionBusinessId
    );

    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    const creditorId = currParams.creditorId;

    return {
        base: base,
        apiBase: apiBase,
        employees: (type?: EmployeeType | ":employeeType", id?: string) => {
            return businessEmployeeRoutes(
                buildUrl,
                context,
                !isNil(type) ? type : currParams.employeeType,
                !isNil(id) ? id : currParams.employeeId
            );
        },
        businessSettings: (type?: BusinessType, id?: string) => {
            return businessSettingRoutes(
                buildUrl,
                new BusinessSettingRoutingContext(
                    !isNil(type) ? type : currParams.businessType,
                    !isNil(id) ? id : currParams.businessId
                )
            );
        },
        api: {
            changeActiveHq: (data: { hqId: number }) =>
                getUrlForPattern(
                    context,
                    true,
                    `change-activeHq`,
                    true,
                    undefined,
                    data
                ),
            getContactInfo: (data: { email: string }) =>
                getUrlForPattern(
                    context,
                    true,
                    `get-contact-business-info`,
                    true,
                    undefined,
                    data
                ),
            changeSessionBusiness: (data: { targetBusinessId: number }) =>
                getUrlForPattern(
                    context,
                    true,
                    `change-session-business`,
                    true,
                    undefined,
                    data
                ),
            deleteBusiness: (data: { id: number }) =>
                getUrlForPattern(
                    context,
                    true,
                    `delete-business`,
                    true,
                    undefined,
                    data
                ),
            getAdminBusinessesList: (queryData?: {
                companyType: CompanyType;
            }) =>
                getUrlForPattern(
                    context,
                    true,
                    `get-admin-businesses-list`,
                    true,
                    undefined,
                    queryData
                ),
            updateModulePlan: (
                contractId: string,
                fieldUuid: string,
                basicPckgUuid: string,
                active: boolean = true
            ) =>
                getUrlForPattern(
                    context,
                    true,
                    `select-module-plan`,
                    true,
                    undefined,
                    {
                        contractId: contractId,
                        fieldUuid: fieldUuid,
                        basicPckgUuid: basicPckgUuid,
                        active: active,
                    }
                ),
            getBusinessDetail: (id: string) =>
                getUrlForPattern(
                    context,
                    true,
                    `get-business-detail/:id`,
                    true,
                    { id: id.toString() }
                ),
            createBusiness: () =>
                getUrlForPattern(context, true, `create-business`, true),
            updateBusiness: (businessId: string) =>
                getUrlForPattern(context, true, `update-business/:id`, true, {
                    id: businessId,
                }),
            getCompanyDataFieldValues: (currentBusinessId: string) =>
                getUrlForPattern(
                    context,
                    true,
                    `get-company-data-field-values/:id`,
                    true,
                    { id: currentBusinessId }
                ),
            deleteAllClients: () =>
                getUrlForPattern(context, true, `delete-clients`, true),
            deleteAllEmployees: () =>
                getUrlForPattern(context, true, `delete-employees`, true),
            deleteAllClientRegistrations: () =>
                getUrlForPattern(
                    context,
                    true,
                    `delete-all-client-registrations`,
                    true
                ),
            deleteLw: () => getUrlForPattern(context, true, `delete-lw`, true),
            deleteLwc: () =>
                getUrlForPattern(context, true, `delete-lwc`, true),
            currentBusiness: (
                id?: string,
                type?: BusinessType | ":businessType"
            ) => {
                const businessContext = new BusinessSettingRoutingContext(
                    !isNil(type) ? type : currParams.businessType,
                    !isNil(id) ? id : currParams.businessId
                );

                return {
                    updateExplanationModeVisibility: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `explanation-mode-visibility`,
                            true
                        ),
                    updateBusinessLocationSettingPreferences: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `update-business-locationSetting-preference`,
                            true
                        ),
                    updateTimeTrackingSettings: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `update-time-tracking-settings`,
                            true
                        ),
                    getBasicInfo: (queryData?: { encodedId: string }) =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-basic-info`,
                            true,
                            undefined,
                            queryData
                        ),
                    updateBasicSettings: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `update-basic-settings`,
                            true
                        ),
                    refreshExternalToken: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `refresh-external-token`,
                            true
                        ),
                    getChatSettings: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-chat-settings`,
                            true
                        ),
                    updateChatSettings: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `update-chat-settings`,
                            true
                        ),
                    getBusinessAreas: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-business-areas`,
                            true
                        ),
                    getBusinessGroups: (request?: { Date?: string, FilterOnlyPastGroups: boolean }) =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-business-groups`,
                            true,
                            undefined,
                            request
                        ),
                    getBusinessFunctions: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-business-functions`,
                            true
                        ),
                    getBusinessShiftCriteriaOptions: (queryData?: {
                        forType: ShiftCriteriaType;
                    }) =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `get-shift-criteria-options`,
                            true,
                            undefined,
                            queryData
                        ),
                    deleteAllClients: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `delete-clients`,
                            true
                        ),
                    deleteAllEmployees: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `delete-employees`,
                            true
                        ),
                    deleteAllClientRegistrations: () =>
                        getUrlForPattern(
                            businessContext,
                            true,
                            `delete-all-client-registrations`,
                            true
                        ),
                    getModulePlanOptions: (locationSettingId?: string) => {
                        return getUrlForPattern(
                            businessContext,
                            true,
                            `get-module-plan-options`,
                            true,
                            {
                                locationSettingId: defaultTo(
                                    locationSettingId,
                                    "0"
                                ),
                            }
                        );
                    },
                    getBusinessDocumentTemplates: () => {
                        return getUrlForPattern(
                            businessContext,
                            true,
                            `get-business-document-templates`,
                            true
                        );
                    },
                    contracts: ((contractBase: string) => ({
                        renewContract: () =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/renew-contract`,
                                true
                            ),
                        list: (year?: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/list`,
                                true,
                                undefined,
                                year ? { year: year } : undefined
                            ),
                        copyContractModules: (id: string, limit?: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/copy-contract-modules`,
                                true,
                                undefined,
                                { contractId: id, limit: limit }
                            ),
                        updateAdjustedPrice: (id: string, price?: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/update-adjusted-price`,
                                true,
                                undefined,
                                { contractId: id, price: price }
                            ),
                        redeemVoucher: (code: string) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/redeem-voucher`,
                                true,
                                undefined,
                                { code: code }
                            ),
                        cancelContract: () =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${contractBase}/cancel-contract`,
                                true
                            ),
                    }))("contracts"),
                    qrCodes: ((qrBase: string) => ({
                        refreshCode: () =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${qrBase}/refresh-code`,
                                true
                            ),
                        sendEmail: (codeId: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${qrBase}/send-email/:codeId`,
                                true,
                                { codeId: codeId.toString() }
                            ),
                        generatePdf: (id: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${qrBase}/generate-pdf/:codeId`,
                                true,
                                { codeId: id.toString() }
                            ),
                    }))("app-qr-code"),
                    ipads: ((ipadBase: string) => ({
                        list: (status?: number) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${ipadBase}/list`,
                                true,
                                undefined,
                                !isNil(status) ? { status: status } : undefined
                            ),
                        update: () =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${ipadBase}/update`,
                                true
                            ),
                        delete: (id: string) =>
                            getUrlForPattern(
                                businessContext,
                                true,
                                `${ipadBase}/delete/:id`,
                                true,
                                { id: id }
                            ),
                    }))("ipads"),
                    importData: ((importDataBase: string) => {
                        return {
                            getColumns: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${importDataBase}/get-columns-list`,
                                    true
                                ),
                            importClients: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${importDataBase}/import-clients`,
                                    true
                                ),
                            importClientRegistrations: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${importDataBase}/import-client-registrations`,
                                    true
                                ),
                            importEmployees: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${importDataBase}/import-employees`,
                                    true
                                ),
                        };
                    })("import-data"),
                    konto: ((kontoBase: string) => {
                        return {
                            list: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${kontoBase}/list`,
                                    true
                                ),
                            get: (id: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${kontoBase}/:id`,
                                    true,
                                    { id: id }
                                ),
                            createUpdate: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${kontoBase}/create-update`,
                                    true
                                ),
                            delete: (id: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${kontoBase}/delete/:id`,
                                    true,
                                    { id: id }
                                ),
                        };
                    })("kontos"),
                    shift: ((shiftBase: string) => {
                        return {
                            list: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${shiftBase}/list`,
                                    true
                                ),
                            get: (id: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${shiftBase}/:id`,
                                    true,
                                    { id: id }
                                ),
                            createUpdate: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${shiftBase}/create-update`,
                                    true
                                ),
                            delete: (id: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${shiftBase}/delete/:id`,
                                    true,
                                    { id: id }
                                ),
                        };
                    })("shift"),
                    courses: ((coursesBase: string) => {
                        return {
                            list: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${coursesBase}/list`,
                                    true,
                                    undefined
                                ),
                            createUpdate: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${coursesBase}/create-update`,
                                    true
                                ),
                            get: (id?: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${coursesBase}/get/${id}`,
                                    true
                                ),
                            delete: (id?: number) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${coursesBase}/delete/${id}`,
                                    true
                                ),
                        };
                    })("courses"),
                    internalCompensationTemplate: ((
                        internalCompensationBase: string
                    ) => {
                        return {
                            list: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/list`,
                                    true
                                ),
                            locationTemplates: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/location-templates`,
                                    true
                                ),
                            getTemplateById: (
                                templateId: string,
                                clone: boolean = false,
                                followup: boolean = false,
                                locationTemplateId?: number
                            ) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/:templateId`,
                                    true,
                                    { templateId: templateId },
                                    {
                                        clone: clone,
                                        followup: followup,
                                        ...(locationTemplateId
                                            ? {
                                                locationTemplateId:
                                                    locationTemplateId,
                                            }
                                            : {}),
                                    }
                                ),
                            create: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/create`,
                                    true
                                ),
                            edit: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/edit`,
                                    true
                                ),
                            delete: (templateId: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${internalCompensationBase}/delete/:templateId`,
                                    true,
                                    { templateId: templateId }
                                ),
                        };
                    })("internal-compensation-template"),
                    documentTemplate: ((documentBase: string) => {
                        return {
                            getTemplateById: (templateId: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/:templateId`,
                                    true,
                                    { templateId: templateId }
                                ),
                            getBusinessOrLocationTemplateList: (
                                request: DocumentTemplateRequest
                            ) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/get-document-templates`,
                                    true,
                                    undefined,
                                    { ...request }
                                ),
                            updateDisplayId: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/update-display-id`,
                                    true
                                ),
                            cloneLocationTemplate: (templateId: number) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/clone-location-template/:templateId`,
                                    true,
                                    { templateId: templateId.toString() }
                                ),
                            cloneBusinessTemplate: (templateId: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/clone-business-template/:templateId`,
                                    true,
                                    { templateId: templateId }
                                ),
                            delete: (templateId: string) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${documentBase}/delete/:templateId`,
                                    true,
                                    { templateId: templateId }
                                ),
                        };
                    })("document-template"),
                    documentArchive: ((archiveBase: string) => {
                        return {
                            createUpdate: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/create-update`,
                                    true
                                ),
                            uploadFiles: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/upload-files`,
                                    true
                                ),
                            updateDisplayId: () =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/update-display-id`,
                                    true
                                ),
                            getDocumentArchiveList: ({
                                request,
                            }: {
                                request: any;
                            }) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/get-document-archive-list`,
                                    true,
                                    undefined,
                                    request
                                ),
                            delete: (documentArchiveId: number) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/delete/:id`,
                                    true,
                                    { id: documentArchiveId.toString() }
                                ),
                            get: (documentArchiveId: number) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${archiveBase}/:id`,
                                    true,
                                    { id: documentArchiveId.toString() }
                                ),
                            deleteFile: (
                                documentArchiveId: number,
                                fileId: number
                            ) =>
                                getUrlForPattern(
                                    businessContext,
                                    true,
                                    `${archiveBase}/:documentArchiveId/delete-file/:fileId`,
                                    true,
                                    {
                                        documentArchiveId:
                                            documentArchiveId.toString(),
                                        fileId: fileId.toString(),
                                    }
                                ),
                        };
                    })("document-archive"),
                };
            },
            // for common business api's
            dashboard: ((dashboardBase: string) => {
                return {
                    getDashboardStats: (data?: { tabType: DashboardTabs }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${dashboardBase}/get-dashboard-stats`,
                            true,
                            undefined,
                            data
                        ),
                };
            })(`dashboard`),
            todo: ((todoBase: string) => {
                return {
                    list: ({ request }: { request: any }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${todoBase}/list`,
                            true,
                            undefined,
                            request
                        ),

                    createUpdate: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${todoBase}/create-update`,
                            true
                        ),
                    updateStatus: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${todoBase}/update-status`,
                            true
                        ),
                    withId: (todoId: string) =>
                        ((idBase: string) => {
                            return {
                                get: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        idBase,
                                        true
                                    ),
                                deleteFile: (id: number) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${todoBase}/delete-file/${todoId}/:id`,
                                        true,
                                        { id: id.toString() }
                                    ),
                                delete: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${todoBase}/delete/:id`,
                                        true,
                                        { id: todoId.toString() }
                                    ),
                            };
                        })(`${todoBase}/${todoId}`),
                };
            })(`todo`),
            bankDatas: ((bankDataBase: string) => ({
                list: (req: BankDataRequest) =>
                    getUrlForPattern(
                        context,
                        true,
                        `${bankDataBase}/list`,
                        true,
                        undefined,
                        { ...req, BusinessBaseId: req.BusinessId }
                    ),
                get: (id: string) =>
                    getUrlForPattern(
                        context,
                        true,
                        `${bankDataBase}/:id`,
                        true,
                        { id: id }
                    ),
                createUpdate: () =>
                    getUrlForPattern(
                        context,
                        true,
                        `${bankDataBase}/create-update`,
                        true
                    ),
                delete: (id: string) =>
                    getUrlForPattern(
                        context,
                        true,
                        `${bankDataBase}/delete/:id`,
                        true,
                        { id: id }
                    ),
            }))("bank-datas"),
            creditors: ((creditorBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${creditorBase}/list`,
                            true
                        ),
                    createUpdate: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${creditorBase}/create-update`,
                            true
                        ),
                    withId: (encodedId?: string | ":creditorId") =>
                        ((idBase: string) => {
                            return {
                                get: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        idBase,
                                        true
                                    ),
                                delete: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/delete`,
                                        true
                                    ),
                            };
                        })(
                            `${creditorBase}/${encodedId ? encodedId : creditorId
                            }`
                        ),
                };
            })("creditors"),
            message: ((messageBase: string) => {
                return {
                    list: ({ request }: { request: any }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${messageBase}/list`,
                            true,
                            undefined,
                            request
                        ),
                    messageCount: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${messageBase}/message-count`,
                            true
                        ),
                    create: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${messageBase}/create`,
                            true
                        ),
                    withId: (templateId: string) =>
                        ((idBase: string) => {
                            return {
                                get: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        idBase,
                                        true
                                    ),
                                updateFavorite: (val: boolean) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/update-favourite/:val`,
                                        true,
                                        { val: val.toString() }
                                    ),
                                trash: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/trash`,
                                        true
                                    ),
                                delete: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/delete`,
                                        true
                                    ),
                            };
                        })(`${messageBase}/${templateId}`),
                    bulkTrash: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${messageBase}/bulk-trash`,
                            true
                        ),
                    bulkDelete: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${messageBase}/bulk-delete`,
                            true
                        ),
                };
            })("message-templates"),
            notification: ((notificationBase: string) => {
                return {
                    list: ({ request }: { request: any }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${notificationBase}/list`,
                            true,
                            undefined,
                            request
                        ),
                    markRead: (id: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${notificationBase}/mark-read/:id`,
                            true,
                            { id: id.toString() }
                        ),
                    markAllRead: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${notificationBase}/mark-all-read`,
                            true
                        ),
                    delete: (todoId: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${notificationBase}/delete/:id`,
                            true,
                            { id: todoId.toString() }
                        ),
                    bulkDelete: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${notificationBase}/bulk-delete`,
                            true
                        ),
                };
            })("notification"),
            additionalBenefits: ((additionalBenefitBase: string) => {
                return {
                    list: ({ request }: { request: any }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${additionalBenefitBase}/get-employee-benefits`,
                            true,
                            undefined,
                            request
                        ),
                    get: (id: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${additionalBenefitBase}/${id}`,
                            true
                        ),
                    create: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${additionalBenefitBase}/create`,
                            true
                        ),
                    update: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${additionalBenefitBase}/update`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${additionalBenefitBase}/delete/${id}`,
                            true
                        ),
                };
            })("employee-benefit"),
            absences: ((absencesBase: string) => {
                return {
                    getEmployeeAbsences: (
                        reqParams: EmployeeAbsencesRequestParameters
                    ) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${absencesBase}/get-employee-absences`,
                            true,
                            undefined,
                            {
                                ...reqParams,
                                StartDate:
                                    reqParams.StartDate.format(
                                        ISO8601_DATE_FORMAT
                                    ),
                                EndDate: reqParams.EndDate
                                    ? reqParams.EndDate.format(
                                        ISO8601_DATE_FORMAT
                                    )
                                    : undefined,
                            }
                        );
                    },
                };
            })("absents"),
            shiftPlan: ((shiftPlanBase: string) => {
                const parseShiftPlanRequest = (req: ShiftPlanRequest) => {
                    return {
                        ...req,
                        WeekStart: req.WeekStart.format(ISO8601_DATE_FORMAT),
                        WeekEnd: req.WeekEnd.format(ISO8601_DATE_FORMAT),
                    };
                };
                return {
                    getWeeklyShiftPlan: (reqParams: ShiftPlanRequest) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/weekly-plan`,
                            true,
                            undefined,
                            parseShiftPlanRequest(reqParams)
                        );
                    },
                    getWeeklyShiftPlanPDF: (reqParams: ShiftPlanRequest) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/weekly-plan/pdf`,
                            true,
                            undefined,
                            parseShiftPlanRequest(reqParams)
                        );
                    },
                    createWeeklyShiftPlan: (reqParams: ShiftPlanRequest) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/create-weekly-plan`,
                            true,
                            undefined,
                            parseShiftPlanRequest(reqParams)
                        );
                    },
                    deleteWeeklyShiftPlan: (reqParams: ShiftPlanRequest) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/delete-weekly-plan`,
                            true,
                            undefined,
                            parseShiftPlanRequest(reqParams)
                        );
                    },
                    getShiftOptions: (reqParams: ShiftPlanOptionsRequest) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/get-shift-options-for-employee`,
                            true,
                            undefined,
                            {
                                ...reqParams,
                                Date: reqParams.Date.format(
                                    ISO8601_DATE_FORMAT
                                ),
                            }
                        );
                    },
                    deleteShiftAssignment: (id: number) => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/delete-shift-assignment/:shiftId`,
                            true,
                            { shiftId: id.toString() }
                        );
                    },
                    addUpdateShiftAssignment: () => {
                        return getUrlForPattern(
                            context,
                            true,
                            `${shiftPlanBase}/add-update-shift-assignment`,
                            true
                        );
                    },
                };
            })("shift-plan"),
            timeTracking: ((timeManagementBase: string) => {
                return {
                    getEventList: (reqParams: TimeTrackingRequestParameters) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/time-events-data`,
                            true,
                            undefined,
                            reqParams
                        ),
                    addNewEntry: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/add-new-entry`,
                            true
                        ),
                    updateManualEntry: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/update-manual-entry`,
                            true
                        ),
                    dailyAccessDetails: (
                        accessControlId: number,
                        type = ControllerType.Main
                    ) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/daily-time-events`,
                            true,
                            undefined,
                            {
                                accessControlId: accessControlId,
                                forControllerType: type,
                            }
                        ),
                    childFamilyInfo: (clientId: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/child-family-info`,
                            true,
                            undefined,
                            { clientId: clientId }
                        ),
                    syncBusiness: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${timeManagementBase}/sync-business-access-events`,
                            true
                        ),
                };
            })("time-tracking"),
            roleManagement: ((roleManagementBase: string) => {
                return {
                    list: (reqParams: RolePaginationRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/list`,
                            true,
                            undefined,
                            reqParams
                        ),
                    create: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/create-role`,
                            true
                        ),
                    update: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/update-role`,
                            true
                        ),
                    delete: (roleId: string, deviceType: AppPlatforms) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/delete`,
                            true,
                            undefined,
                            { id: roleId, deviceType: deviceType }
                        ),
                    clone: (roleId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/clone-role`,
                            true,
                            undefined,
                            { roleId: roleId }
                        ),
                    getRolePermission: (roleId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/role-permissions`,
                            true,
                            undefined,
                            { roleId: roleId }
                        ),
                    updateRolePermissions: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${roleManagementBase}/update-role-permissions`,
                            true
                        ),
                };
            })("role-management"),
            userManagement: ((userManagementBase: string) => {
                return {
                    list: (reqParams: UserListRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${userManagementBase}/list`,
                            true,
                            undefined,
                            reqParams
                        ),
                    create: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${userManagementBase}/create-user`,
                            true
                        ),
                    update: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${userManagementBase}/update-user`,
                            true
                        ),
                    delete: (reqParams: { userId: string; roleId: string }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${userManagementBase}/delete`,
                            true,
                            undefined,
                            reqParams
                        ),
                };
            })("users-role-management"),
            calendar: ((calendarBase: string) => {
                return {
                    pivot: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}/pivot`,
                            true
                        ),
                    eventsList: (filter: DateRangeRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}`,
                            true,
                            undefined,
                            serializeDateRangeRequest(filter)
                        ),
                    createUpdate: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}/create-update`,
                            true
                        ),
                    get: (id: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}/${id}`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}/${id}/delete`,
                            true
                        ),
                    deleteFile: (templateId: string, id: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${calendarBase}/${templateId}/delete-file/${id}`,
                            true
                        ),
                };
            })("calendar-events"),
            chat: ((chatBase: string) => {
                return {
                    setCachedChannels: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/set-chat-cache`,
                            true,
                            undefined
                        ),
                    getCachedChannels: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/get-chat-cache`,
                            true,
                            undefined
                        ),
                    getToken: (userId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/token`,
                            true,
                            undefined,
                            { identity: userId }
                        ),
                    deleteChat: (
                        channelId: string,
                        forceDelete: boolean = false
                    ) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/delete-chat`,
                            true,
                            undefined,
                            { channelSid: channelId, forceDelete: forceDelete }
                        ),
                    updateDeleteFlag: (channelId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/update-delete-flag`,
                            true,
                            undefined,
                            { channelSid: channelId }
                        ),
                    getUsersList: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/get-users`,
                            true,
                            undefined
                        ),
                    createChannel: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}`,
                            true,
                            undefined
                        ),
                    addMembers: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/add-members`,
                            true,
                            undefined
                        ),
                    removeMembers: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/remove-members`,
                            true,
                            undefined
                        ),
                    pinChannel: (pinChannelRequest: PinChannelRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${chatBase}/pin-channel`,
                            true,
                            undefined,
                            { ...pinChannelRequest }
                        ),
                };
            })("web-chat"),
            documentCompile: ((documentBase: string) => {
                return {
                    getEditableData: (templateId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/get-editable-data/:templateId`,
                            true,
                            { templateId: templateId }
                        ),
                    getPdf: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/get-pdf`,
                            true
                        ),
                    compileWithEditableSection: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/compile-with-editable-section`,
                            true
                        ),
                    compileDocument: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/compile`,
                            true
                        ),
                    getPdfFromKey: (cacheKey: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/get-pdf/:cacheKey`,
                            true,
                            { cacheKey: cacheKey }
                        ),
                    downloadPdfFromKey: (cacheKey: string, name?: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${documentBase}/download-pdf/:cacheKey`,
                            true,
                            { cacheKey: cacheKey },
                            name ? { name: name } : undefined
                        ),
                };
            })("document-compile"),
            general: ((generalBase: string) => {
                return {
                    getAllBusinessModuleKeys: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-all-business-module-keys`,
                            true
                        ),
                    getBusinessShifts: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-shifts`,
                            true
                        ),
                    getBusinessKontos: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-kontos`,
                            true
                        ),
                    calculateClientAgeGroup: (birthday: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/calculate-client-age-group`,
                            true,
                            undefined,
                            { birthday: birthday }
                        ),
                    getBusinessEmployees: (data: ActiveFilterDataRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-employees`,
                            true,
                            undefined,
                            data
                        ),
                    getBusinessRolesByDevice: (queryData: {
                        device: AppPlatforms;
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-roles`,
                            true,
                            undefined,
                            queryData
                        ),
                    getContactInfo: (queryData: { email: string }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-contact-business-info`,
                            true,
                            undefined,
                            queryData
                        ),
                    getBusinessWorkSchedule: (queryData?: {
                        businessId: number; // if want to get data for non-session business
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-work-schedule`,
                            true,
                            undefined,
                            queryData
                        ),
                    getLWCModulePlanCards: (queryData?: {
                        locationSettingId: number; // if want to get data for non-session business
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-module-plans-for-lwc`,
                            true,
                            undefined,
                            queryData
                        ),
                    getIpadRoles: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-ipad-roles`,
                            true
                        ),
                    updateIpadRole: (queryData: {
                        employeeId: number;
                        ipadRoleId: string;
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/update-employee-ipad-role`,
                            true,
                            undefined,
                            queryData
                        ),
                    getPersonEmails: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-person-emails`,
                            true
                        ),
                    getBusinessHolidays: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-holidays`,
                            true
                        ),
                    userFilterOptions: (request: UserFilterRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/user-filter-options`,
                            true,
                            undefined,
                            request
                        ),
                    getBusinessSelectList: (queryData?: {
                        companyType: CompanyType;
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-business-select-list`,
                            true,
                            undefined,
                            queryData
                        ),
                    sendTestPrint: (queryData?: { printerId: number }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/send-test-print`,
                            true,
                            undefined,
                            queryData
                        ),
                    sendTestPrintJob: (queryData?: { printerJobId: number }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/send-test-print-job`,
                            true,
                            undefined,
                            queryData
                        ),
                    getHqBranchesWithAccess: (queryData?: { hqId: number }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-hq-branchesWithAccess`,
                            true,
                            undefined,
                            queryData
                        ),
                    getApplicationFeatures: (
                        queryData?: ApplicationFeatureRequest
                    ) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-application-features`,
                            true,
                            undefined,
                            queryData
                        ),
                    getDocumentTemplate: (queryData: {
                        type: DocumentTag;
                        loadEditable: boolean;
                    }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${generalBase}/get-document-templates`,
                            true,
                            undefined,
                            queryData
                        ),
                };
            })(`general`),
        },
        screens: {
            dashboard: () => {
                // no need to get tabs-param here as I am already fetching it from context in component
                return getUrlForPattern(context, buildUrl, "dashboard");
            },
            contractExpired: () =>
                getUrlForPattern(context, buildUrl, "contract-expired"),
            chat: () => {
                return getUrlForPattern(context, buildUrl, "chat");
            },
            calendar: () => {
                return getUrlForPattern(context, buildUrl, "calendar");
            },
            todoManagement: ((sectionBase: string) => {
                return getCrudUrlsForSection(sectionBase, context, buildUrl);
            })(`to-do`),
            payroll: ((sectionBase: string) => {
                return {
                    additionalBenefits: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/additional-benefits`
                        ),
                    absences: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/employee-absences`
                        ),
                };
            })("payroll"),
            shiftPlan: ((sectionBase: string) => {
                return {
                    main: () =>
                        getUrlForPattern(context, buildUrl, `${sectionBase}`),
                    shifts: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/shifts`
                        ),
                    groups: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/groups`
                        ),
                };
            })("shift-plan"),
            timeTracking: ((sectionBase: string) => {
                return {
                    clientView: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/clients`
                        ),
                    employeeView: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/employees`
                        ),
                    supplierView: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${sectionBase}/suppliers`
                        ),
                };
            })(`time-tracking`),
            mailbox: ((sectionBase: string) => {
                return getCrudUrlsForSection(
                    sectionBase,
                    context,
                    buildUrl,
                    true,
                    undefined,
                    "details",
                    true
                );
            })(`mailbox`),
            notifications: () => {
                return getUrlForPattern(context, buildUrl, "notifications");
            },
            downloads: () => {
                return getUrlForPattern(context, buildUrl, "downloads");
            },
            creditors: ((sectionBase: string) => {
                return {
                    list: () => {
                        return getUrlForPattern(context, buildUrl, sectionBase);
                    },
                    create: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${sectionBase}/create`
                        ),
                    withId: (
                        creditorEncodedId?: Optional<string> | ":creditorId"
                    ) =>
                        ((idBase: string) => {
                            const subPathBase = buildUrl
                                ? `${sectionBase}/${idBase}/`
                                : ``;
                            return {
                                base: () => `${sectionBase}/${idBase}`,
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}edit`
                                    );
                                },
                                bankData: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}bank-data`),
                            };
                        })(
                            `${creditorEncodedId
                                ? creditorEncodedId
                                : currParams.creditorId
                            }`
                        ),
                };
            })("accounting/creditors"),
            admin: ((adminBase: string) => {
                return {
                    companies: () => {
                        return getUrlForPattern(
                            context,
                            buildUrl,
                            `${adminBase}/branches`
                        );
                    },
                    selectModules: () => {
                        return getUrlForPattern(
                            context,
                            buildUrl,
                            `${adminBase}/select-modules`
                        );
                    },
                    branchCreate: () =>
                        getUrlForPattern(
                            context,
                            buildUrl,
                            `${adminBase}/branches/create`
                        ),
                    archiveManager: () =>
                        getUrlForPattern(context, buildUrl, "archive"),
                    usersManagement: ((sectionBase: string) => {
                        return getCrudUrlsForSection(
                            sectionBase,
                            context,
                            buildUrl, // always send build url because I need complete route for navigation
                            undefined,
                            undefined,
                            undefined,
                            true
                        );
                    })(`${adminBase}/users`),
                    rolesManagement: ((sectionBase: string) => {
                        return {
                            ...getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl,
                                undefined,
                                undefined,
                                undefined,
                                true
                            ),
                            permissionEdit: (
                                id: string | ":id" = ":id",
                                deviceType: DeviceTypeParam = ":deviceType"
                            ) =>
                                getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${sectionBase}/${deviceType}/${id}/permissions`,
                                    false,
                                    { id: id, deviceType: deviceType }
                                ),
                        };
                    })(`${adminBase}/roles`),
                    emailManager: ((sectionBase: string) => {
                        return getCrudUrlsForSection(
                            sectionBase,
                            context,
                            buildUrl
                        );
                    })(`${adminBase}/email-templates`),
                    documentManager: ((sectionBase: string) => {
                        return getCrudUrlsForSection(
                            sectionBase,
                            context,
                            buildUrl
                        );
                    })(`${adminBase}/documents`),
                    letterheadsManager: ((sectionBase: string) => {
                        return getCrudUrlsForSection(
                            sectionBase,
                            context,
                            buildUrl
                        );
                    })(`${adminBase}/letterheads`),
                };
            })("admin"),
        },
    };
};

export default businessRoutes;
