//to encode and decode data
//and store in local storage

import SecureStorage from "secure-web-storage";
import config from "globals/config";
import { AES, enc } from "crypto-js";
import sha256 from "crypto-js/sha256";
export const secureStorage =
    typeof Storage !== "undefined"
        ? new SecureStorage(localStorage, {
              hash: function hash(key) {
                  key = sha256(key, config.encryptionSecret);

                  return key.toString();
              },
              encrypt: function (data) {
                  data = AES.encrypt(data, config.encryptionSecret);

                  data = data.toString();

                  return data;
              },
              decrypt: function decrypt(data) {
                  data = AES.decrypt(data, config.encryptionSecret);

                  data = data.toString(enc.Utf8);

                  return data;
              },
          })
        : undefined;
