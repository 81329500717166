import {
    BusinessDeviceStatus,
    BusinessIPad,
    BusinessIPadListResponse,
} from "models/businessIpad";
import { AppResponse } from "models/general";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface IBusinessIpadService {
    list(
        status?: BusinessDeviceStatus
    ): Promise<AppResponse<BusinessIPadListResponse>>;
    updateIpad(model: BusinessIPad): Promise<AppResponse<BusinessIPad>>;
    delete(id: string): Promise<AppResponse<string>>;
}

const apiLinkProvider = routesForContext().business.api.currentBusiness().ipads;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessIpadServiceKey(
    name: keyof IBusinessIpadService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessIPad_${name}_${JSON.stringify(data)}`;
}
export class BusinessIpadService
    extends BaseService
    implements IBusinessIpadService
{
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    list(
        status?: BusinessDeviceStatus
    ): Promise<AppResponse<BusinessIPadListResponse>> {
        return this.doServerXHR<BusinessIPadListResponse>({
            url: this.routes.list(status),
            method: "get",
        });
    }
    delete(id: string): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.routes.delete(id),
            method: "post",
        });
    }
    updateIpad(model: BusinessIPad): Promise<AppResponse<BusinessIPad>> {
        return this.doServerXHR<BusinessIPad>({
            url: this.routes.update(),
            method: "post",
            data: model,
        });
    }
}

export default BusinessIpadService;
