import { AppPopover } from "components";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { v4 as uuid } from "uuid";
import { BusinessContractDiscountItem } from "models";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ContractVouchersSummaryPopper.module.scss";

export interface ContractVouchersSummaryPopperProps {
    value: BusinessContractDiscountItem[];
}
export const ContractVouchersSummaryPopper: React.FC<
    ContractVouchersSummaryPopperProps
> = ({ value }) => {
    const { t } = useTranslation();
    const { getFormattedCurrency } = useLocaleHelpers();
    return (
        <>
            <AppPopover
                triggerOnClick={false}
                triggerElem={
                    <span>
                        {getFormattedCurrency(
                            value.reduce((a, x) => a + x.Discount, 0)
                        )}
                    </span>
                }
                placement={"right-start"}
            >
                <div className={`${styles.table} ${styles.container}`}>
                    <div style={{ textAlign: "center", fontWeight: 600 }}>
                        {t("business.contracts.vouchers.title")}
                    </div>
                    <Table style={{ margin: "0" }}>
                        <thead>
                            <tr>
                                <th>{t("business.contracts.vouchers.name")}</th>
                                <th>{t("business.contracts.vouchers.code")}</th>
                                <th>
                                    {t("business.contracts.vouchers.discount")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.map((x) => (
                                <tr key={uuid()}>
                                    <td>{x.Name}</td>
                                    <td>{x.Code}</td>
                                    <td>{getFormattedCurrency(x.Discount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </AppPopover>
        </>
    );
};

export default ContractVouchersSummaryPopper;
