import {
    extractVimeoPlayerUrl,
    FeatureHelpItem,
    getNumberToHelpItemEnumTypeBinding,
    HelpItemType,
} from "models/applicationFeatures";
import React from "react";
import styles from "./FeatureHelpPlayerOrDetail.module.scss";

export interface FeatureHelpPlayerOrDetailProps {
    value: FeatureHelpItem;
}
export const FeatureHelpPlayerOrDetail: React.FC<
    FeatureHelpPlayerOrDetailProps
> = ({ value }) => {
    const haveVideo =
        value &&
        getNumberToHelpItemEnumTypeBinding(value.HelpItem.Type) ===
            HelpItemType.VIDEO;
    return (
        <>
            {value && value.Title && (
                <div className={`${styles.title}`}>
                    <span>{value.Title}</span>
                </div>
            )}
            {haveVideo && value.HelpItem.Url && (
                <div className="embed-container">
                    <iframe
                        src={extractVimeoPlayerUrl(value.HelpItem.Url)}
                        frameBorder="0"
                        allowFullScreen={true}
                    />
                </div>
            )}
        </>
    );
};

export default FeatureHelpPlayerOrDetail;
