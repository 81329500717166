import AppDropdownMenu, { DropdownMenuItem } from "components/AppDropdownMenu";
import { showSweetAlertConfirmation } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { ChannelResponseModel, ChannelType, getChannelName } from "models/chat";
import {
    Business_Chat,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import { Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChannelHeaderInfo } from "./ChannelHeaderInfo";
import styles from "./ChatBoxHeader.module.scss";

interface ChatBoxHeaderProps {
    selectedChannel: ChannelResponseModel;
    loading?: boolean;
    clearChatHandler: () => void;
    deleteChatHandler: () => void;
    deleteChannelHandler: () => void;
    addToGroupHandler: () => void;
    removeMemberFromGroup: () => void;
    twilioChannelLoading: boolean;
}

// eslint-disable-next-line react/display-name
export const ChatBoxHeader = React.memo<ChatBoxHeaderProps>(
    ({
        selectedChannel,
        addToGroupHandler,
        clearChatHandler,
        deleteChannelHandler,
        deleteChatHandler,
        removeMemberFromGroup,
        twilioChannelLoading,
        loading = true,
    }) => {
        const { checkPermission } = useCheckPermission();
        const { t } = useTranslation();
        const hasDeletePermission = checkPermission(Business_Chat, [
            PermissionAccessTypes.DELETE,
        ]);
        const hasEditPermission = checkPermission(Business_Chat, [
            PermissionAccessTypes.EDIT,
        ]);
        const menuList: DropdownMenuItem[] = [];
        //if user have create permission and group type is custom then add 'add to group' menu

        //if user have delete permission and group type is custom then add 'remove from channel and delete group' menu
        if (selectedChannel.ChannelType === ChannelType.CUSTOM_GROUP) {
            if (hasDeletePermission) {
                menuList.push(
                    ...[
                        {
                            label: t("chat.menu.deleteGroup"),
                            onClick: () => {
                                if (!twilioChannelLoading) {
                                    showSweetAlertConfirmation(
                                        t("chat.menu.deleteGroup"),
                                        t(
                                            "chat.alertMessages.areYouSureYouWantToDeleteThisGroup"
                                        ),
                                        deleteChannelHandler,
                                        t("common.delete.yes"),
                                        "warning",
                                        true
                                    );
                                }
                            },
                        },
                    ]
                );
            }
            if (hasEditPermission) {
                menuList.push(
                    ...[
                        {
                            label: t("chat.menu.addToGroup"),
                            onClick: addToGroupHandler,
                        },
                        {
                            label: t("chat.menu.removeFromGroup"),
                            onClick: removeMemberFromGroup,
                        },
                    ]
                );
            }
        }
        //if user have create permission and group type is onr to one then add 'add clear chat and delete chant' menu
        else if (selectedChannel.ChannelType === ChannelType.ONE_TO_ONE) {
            menuList.push({
                label: t("chat.menu.clearChat"),
                onClick: clearChatHandler,
            });
            if (hasDeletePermission) {
                menuList.push(
                    ...[
                        {
                            label: t("chat.menu.deleteChat"),
                            onClick: () => {
                                if (!twilioChannelLoading) {
                                    showSweetAlertConfirmation(
                                        t("chat.menu.deleteChat"),
                                        t(
                                            "chat.alertMessages.areYouSureYouWantToDeleteThisChat"
                                        ),
                                        deleteChatHandler,
                                        t("common.delete.yes"),
                                        "warning",
                                        true
                                    );
                                }
                            },
                        },
                    ]
                );
            }
        }

        return (
            <>
                <Card className={styles.root}>
                    <Card.Body>
                        {selectedChannel ? (
                            <div className={styles.rootDiv}>
                                <div className={styles.infoSection}>
                                    <ChannelHeaderInfo
                                        channelInfos={defaultTo(
                                            selectedChannel.ChannelInfo,
                                            []
                                        )}
                                        channelName={getChannelName(
                                            selectedChannel.ChannelOriginalName,
                                            selectedChannel.ChannelType,
                                            selectedChannel.ChannelType ==
                                                ChannelType.ONE_TO_ONE
                                                ? undefined // don't show for single chat
                                                : selectedChannel.ChannelUserType
                                        )}
                                        members={defaultTo(
                                            selectedChannel.MemberCount,
                                            0
                                        )}
                                        showOnlineState={
                                            selectedChannel.ChannelType ===
                                            ChannelType.ONE_TO_ONE
                                        }
                                        tooltip={
                                            selectedChannel.ChannelType ===
                                                ChannelType.ONE_TO_ONE &&
                                            selectedChannel.ChannelInfo
                                                ? selectedChannel.ChannelInfo[0]
                                                      .Email
                                                : undefined
                                        }
                                        membersDetails={defaultTo(
                                            selectedChannel.MembersDetails,
                                            []
                                        )}
                                        onlineState={selectedChannel.IsOnline}
                                    />
                                </div>
                                {(selectedChannel.ChannelType ===
                                    ChannelType.ONE_TO_ONE ||
                                    selectedChannel.ChannelType ===
                                        ChannelType.CUSTOM_GROUP) &&
                                    !loading && (
                                        <div className={styles.menuSection}>
                                            {menuList &&
                                                menuList.length > 0 && (
                                                    <AppDropdownMenu
                                                        className={
                                                            selectedChannel.ChannelType !=
                                                            ChannelType.ONE_TO_ONE
                                                                ? styles.menuCard
                                                                : ""
                                                        }
                                                        trigger={
                                                            <Image
                                                                className={
                                                                    styles.menuIcon
                                                                }
                                                                src={
                                                                    ImageAssets
                                                                        .common
                                                                        .menu
                                                                }
                                                            />
                                                        }
                                                        menuItems={menuList}
                                                    />
                                                )}
                                        </div>
                                    )}
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    }
);

export default ChatBoxHeader;
