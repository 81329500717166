import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import {
    ValidityStateManager,
    ValidityState,
    DurationPeriodType,
} from "models/general";
import {
    AppInputField,
    AppNumberInput,
    AppSwitch,
} from "components/FormFields";
import { useTranslation } from "react-i18next";
import { DragHandle } from "components/Sortable";
import { AppDeleteButton } from "components/Buttons";
import styles from "./AgeGroupTemplateRow.module.scss";
import { FORMAT_INTEGER } from "globals/constants";
import { AgeGroupTemplateField } from "models/ageGroupTemplate";

export interface AgeGroupTemplateFieldRowProps {
    value: AgeGroupTemplateField;
    isLocation?: boolean;
    onChange: (field: AgeGroupTemplateField) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
}

export const AgeGroupTemplateFieldRow: React.FC<
    AgeGroupTemplateFieldRowProps
> = ({ value, onChange, validityState, onDelete, readonly = false }) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />

                <Form.Row>
                    <Col xs={12} md={4} lg={4} xl={3}>
                        <AppInputField
                            label={t("templates.name.name")}
                            showEmptyError={true}
                            value={value.Name}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Name: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.name.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Name"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={1}>
                        <AppInputField
                            label={t("templates.shortName.name")}
                            showEmptyError={true}
                            value={value.ShortName}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    ShortName: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.shortName.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "ShortName"
                            )}
                        />
                    </Col>
                    <Col xs={12} sm={4} lg={3} xl={1} className={`mt-4 pl-3`}>
                        <AppSwitch
                            disabled={readonly}
                            id={"hasValue-" + value.Uuid}
                            value={
                                value.TimeUnit == DurationPeriodType.Day
                                    ? false
                                    : true
                            }
                            onChange={(checked) => {
                                onChange({
                                    ...value,
                                    TimeUnit: checked
                                        ? DurationPeriodType.Month
                                        : DurationPeriodType.Day,
                                });
                            }}
                            label={
                                value.TimeUnit == DurationPeriodType.Month
                                    ? t("common.month")
                                    : t("common.day")
                            }
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppNumberInput
                            label={t("ageGroupTemplate.ageStart.name", {
                                unit:
                                    value.TimeUnit == DurationPeriodType.Month
                                        ? t("common.month")
                                        : t("common.day"),
                            })}
                            showError={true}
                            formatTheInitialValue={true}
                            showEmptyError={true}
                            readOnly={readonly}
                            allowZero={true}
                            format={FORMAT_INTEGER}
                            value={value.AgeStart}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    AgeStart: defaultTo(val, 0),
                                })
                            }
                            placeholder={t("ageGroupTemplate.ageStart.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "AgeStart"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppNumberInput
                            label={t("ageGroupTemplate.ageEnd.name", {
                                unit:
                                    value.TimeUnit == DurationPeriodType.Month
                                        ? t("common.month")
                                        : t("common.day"),
                            })}
                            showError={true}
                            formatTheInitialValue={true}
                            showEmptyError={true}
                            allowZero={true}
                            readOnly={readonly}
                            format={FORMAT_INTEGER}
                            value={value.AgeEnd}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    AgeEnd: defaultTo(val, 0),
                                })
                            }
                            placeholder={t("ageGroupTemplate.ageEnd.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "AgeEnd"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={8} lg={4} xl={3}>
                        <AppInputField
                            label={t("templates.description")}
                            showEmptyError={true}
                            value={value.Description}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Description: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.description")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Description"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default AgeGroupTemplateFieldRow;
