export enum ReminderUnit {
    Day = 0,
    Hour = 1,
    Minute = 2,
}

export enum RemainderType {
    AppMessage = 0,
    AppCalendarEvent = 1,
    WebsiteNotification = 2,
}
