import { ISO8601_DATE_FORMAT } from "globals/constants";
import { v4 } from "uuid";
import moment from "moment";
import { DurationPeriodType } from "models/general";
import { EmpKeyTemplateResponse } from "./response";
import { EmpKeyTemplate, EmpKeyTemplateField } from "./model";

export const getEmpKeyTemplateFromResponse = (
    req: EmpKeyTemplateResponse
): EmpKeyTemplate => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        ParentEndDate: req.ParentEndDate
            ? moment(req.ParentEndDate, ISO8601_DATE_FORMAT)
            : undefined,
        FollowUpTemplates:
            req.FollowUpTemplates?.length > 0
                ? req.FollowUpTemplates.map((x) => {
                      return getEmpKeyTemplateFromResponse(x);
                  })
                : ([] as EmpKeyTemplate[]),
    } as EmpKeyTemplate;
};

export const getDefaultEmpKeyTemplate = (
    locationId?: number,
    businessId?: number
): EmpKeyTemplate => {
    const fields: EmpKeyTemplateField[] = [];

    return {
        StartDate: moment(),
        Id: 0,
        Name: "",
        LocationSettingId: locationId,
        BusinessId: businessId,
        ShortName: "",
        Fields: fields,
    } as EmpKeyTemplate;
};
