import classNames from "classnames";
import { JobScheduleFields } from "commonPartials/jobScheduleFields";
import AppContentHeader from "components/AppContentHeader";
import { AppColoredMultiSelect, AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials/types";
import AppSlider from "components/AppSlider";
import { AppButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { ErrorMessage } from "components/ErrorMessage";
import {
    AppDatePicker,
    AppInputField,
    AppSwitch,
    AppTimePicker,
} from "components/FormFields";
import { AppLoader } from "components/Loaders";
import { TinymceEditor } from "components/TinymceEditor";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash";
import { getRemainderDefaultValue, RemainderType } from "models/appReminder";
import {
    ActiveFilterDataRequest,
    CustomColorItemOption,
    ErrorInfo,
    getInitializedValidityState,
    Optional,
    ValidityStateManager,
} from "models/general";
import {
    Business_ToDo,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { getJobScheduleDefaultValue } from "models/jobSchedule";
import {
    getTodoDefaultValue,
    getTodoFromResponse,
    TodoRequest,
    TodoStatus,
    validateTodo,
} from "models/todo";
import moment from "moment";
import { Moment } from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import {
    BusinessGeneralService,
    CurrentBusinessService,
    getBusinessGeneralServiceKey,
    getCurrentBusinessServiceKey,
    getTodoServiceKey,
    TodoService,
} from "services/business";
import { COLOR_DARK_YELLOW } from "theme/themeConstants";
import { TodoManagementSendRemainder } from "./partials";
import styles from "./TodoManagementCreateEdit.module.scss";
import AppFilesListComponent from "components/File/AppFilesListComponent";

export interface TodoManagementCreateEditProp {
    currentWorkingDayClosingTime: Moment;
}

export const TodoManagementCreateEdit: React.FC<
    TodoManagementCreateEditProp
> = ({ currentWorkingDayClosingTime }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const {
        appLocale: locale,
        getTimePickerFormatForLocale: getLocaleTimePickerFormat,
    } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();
    const showErrorPage = useShowErrorPage();
    const { encodedId: encodedBusinessId } = useSessionBusiness();

    const readonly = false;
    const { id } = useParams();

    const navigate = useNavigate();
    const redirectToList = () => {
        navigate(linkProvider.business.screens.todoManagement.list());
    };

    const todoService = new TodoService(linkProvider.business.api.todo);

    const [state, setState] = useState(
        !id ? getTodoDefaultValue(currentWorkingDayClosingTime) : null
    );

    const permMap = useMemo(() => {
        return getPermissionMap(Business_ToDo);
    }, [getPermissionMap]);
    const hasTodoEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const hasTodoDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const { isLoading: getTodoLoading, data: getTodoRes } = useQuery(
        getTodoServiceKey("getTodo", {
            todoId: id,
        }),
        async () => {
            if (id) {
                return await todoService.getTodo(id);
            }
        },
        {
            refetchOnMount: "always",
        }
    );

    const onMutationSuccess = () => {
        queryClient.invalidateQueries(getTodoServiceKey("getTodoList"));
        queryClient.invalidateQueries(getTodoServiceKey("getTodo"));
    };

    const {
        isLoading: todoCreateUpdateLoading,
        data: createUpdateRes,
        mutate: createUpdateTodo,
    } = useMutation(
        async (request: TodoRequest) => await todoService.createUpdate(request),
        {
            onSuccess: onMutationSuccess,
        }
    );

    const {
        isLoading: deleteFileLoading,
        data: delFileRes,
        mutate: deleteFile,
    } = useMutation(
        async (request: { id: number; todoId: string }) =>
            await todoService.deleteFile(request.id, request.todoId),
        {
            onSuccess: onMutationSuccess,
        }
    );

    const {
        isLoading: updateStatusLoading,
        data: updateStatusRes,
        mutate: updateStatus,
    } = useMutation(
        async (request: { todoId: string; status: string }) =>
            await todoService.updateStatus(request.todoId, request.status),
        {
            onSuccess: onMutationSuccess,
        }
    );

    useEffect(() => {
        if (!getTodoLoading && getTodoRes && getTodoRes.Data) {
            const parsedResp = getTodoFromResponse(getTodoRes.Data);
            setState({
                ...parsedResp,
                Reminder:
                    parsedResp.Reminder != null
                        ? parsedResp.Reminder
                        : getRemainderDefaultValue(),
                RepeatSchedule:
                    parsedResp.RepeatSchedule != null
                        ? parsedResp.RepeatSchedule
                        : getJobScheduleDefaultValue(),
            });
        } else if (!getTodoLoading && getTodoRes?.Errors) {
            showErrorPage(404);
        }
    }, [getTodoRes, getTodoLoading]);

    useEffect(() => {
        if (!updateStatusLoading && updateStatusRes?.Data) {
            const parsedResp = getTodoFromResponse(updateStatusRes.Data);
            setState({
                ...parsedResp,
                Files: state !== null ? state.Files : [],
                files: state !== null ? state.files : [],
                Reminder:
                    parsedResp.Reminder != null
                        ? parsedResp.Reminder
                        : getRemainderDefaultValue(),
                RepeatSchedule:
                    parsedResp.RepeatSchedule != null
                        ? parsedResp.RepeatSchedule
                        : getJobScheduleDefaultValue(),
            });
            showSweetAlertToast(
                `${t("todoManagement.task.name").toString()} ${t(
                    "common.actions.updatedSuccessfully"
                ).toString()}`,
                "",
                "success"
            );
        }
    }, [updateStatusRes, updateStatusLoading]);

    useEffect(() => {
        if (!todoCreateUpdateLoading && createUpdateRes) {
            if (!id) {
                redirectToList();
            } else {
                const parsedResp = getTodoFromResponse(createUpdateRes.Data);
                setState({
                    ...parsedResp,
                    Reminder:
                        parsedResp.Reminder != null
                            ? parsedResp.Reminder
                            : getRemainderDefaultValue(),
                    RepeatSchedule:
                        parsedResp.RepeatSchedule != null
                            ? parsedResp.RepeatSchedule
                            : getJobScheduleDefaultValue(),
                });

                showSweetAlertToast(
                    `${t("todoManagement.task.name").toString()} ${t(
                        "common.actions.updatedSuccessfully"
                    ).toString()}`,
                    "",
                    "success"
                );
            }
        }
    }, [createUpdateRes, todoCreateUpdateLoading]);

    useEffect(() => {
        if (!deleteFileLoading && delFileRes?.Data && state != null) {
            if (!id) {
                redirectToList();
            } else {
                setState({
                    ...state,
                    Files: [
                        ...state.Files.filter((x) => x.Id != delFileRes.Data),
                    ],
                });
                showSweetAlertToast(
                    t("common.actions.fileDeletedSuccessfully"),
                    "",
                    "success"
                );
            }
        }
    }, [delFileRes, deleteFileLoading]);

    const selectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;

    const [dialogOpen, setDialogOpen] = useState(false);

    const validityStateManager = useMemo(() => {
        if (state) {
            const validationState = validateTodo(state);
            return new ValidityStateManager(validationState);
        }
        return new ValidityStateManager(getInitializedValidityState([], []));
    }, [state, t]);

    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(encodedBusinessId as string)
    );

    const {
        isLoading: employeesLoading,
        data: employeesResponse,
        isRefetching: employeesRefetching,
    } = useQuery(
        getBusinessGeneralServiceKey("getBusinessEmployees", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () =>
            await businessGeneralService.getBusinessEmployees({
                CheckActive: true,
            } as ActiveFilterDataRequest)
    );

    const {
        isLoading: areasLoading,
        data: areasResponse,
        isRefetching: areasRefetching,
    } = useQuery(
        getCurrentBusinessServiceKey("getBusinessAreas", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () => await currentBusinessService.getBusinessAreas()
    );

    const scheduleValidityState =
        validityStateManager.getFieldState("RepeatSchedule")?.children;

    const assigneeOptions = [
        ...Object.keys(RemainderType)
            .filter((k) => !isNaN(Number(k)))
            .map((k) => ({
                value: k,
                label: t(
                    `todoManagement.${RemainderType[parseInt(k)] as string}`
                ),
            })),
    ] as CustomColorItemOption[];

    useEffect(() => {
        if (
            state &&
            employeesResponse &&
            employeesResponse.Data &&
            state.AssigneeToEmployeeId > 0 &&
            employeesResponse.Data.length > 0
        ) {
            if (
                !employeesResponse.Data.some(
                    (x) =>
                        x.Value?.toString() ==
                        state.AssigneeToEmployeeId.toString()
                )
            ) {
                // remove selection (as current one not active anymore)
                setState({
                    ...state,
                    AssigneeToEmployeeId: 0,
                });
            }
        }
    }, [employeesResponse, state]);
    const employees =
        employeesResponse && employeesResponse.Data
            ? [
                  selectOption,
                  ...employeesResponse.Data.map((x) => {
                      return {
                          label: x.Text,
                          value: x.Value?.toString(),
                      } as SimpleOption;
                  }),
              ]
            : [selectOption];

    const workingAreas =
        areasResponse && areasResponse.Data
            ? [
                  selectOption,
                  ...areasResponse.Data.map((x) => {
                      return {
                          label: x.Text,
                          value: x.Value?.toString(),
                      } as SimpleOption;
                  }),
              ]
            : [selectOption];

    const updateTodoStatus = (status: TodoStatus) => {
        if (state !== null) {
            updateStatus({
                todoId: state.Id.toString(),
                status: status,
            });
        }
    };

    return (
        <div>
            <AppContentHeader
                title={t("todoManagement.todo")}
                classNameForIcon="pe-7s-users"
                hasGoBack={true}
                backUrl={linkProvider.business.screens.todoManagement.list()}
            />
            {state && state.RepeatSchedule && (
                <AppDialog
                    modalOpen={dialogOpen}
                    size="lg"
                    onClose={() => setDialogOpen(false)}
                    title={t("todoManagement.recreateSchedule.name")}
                    footer={
                        <AppDialogFooter
                            disableSaveButton={
                                readonly ||
                                !new ValidityStateManager(
                                    scheduleValidityState
                                ).isStateValid()
                            }
                            onClickSaveButton={() => setDialogOpen(false)}
                            showCancel={false}
                        />
                    }
                >
                    <JobScheduleFields
                        readonly={readonly}
                        value={state.RepeatSchedule}
                        canEditStartDate={false}
                        validityState={
                            validityStateManager.getFieldState(
                                "RepeatSchedule"
                            ) && scheduleValidityState
                                ? scheduleValidityState
                                : undefined
                        }
                        onChange={(schedular) =>
                            setState({
                                ...state,
                                RepeatSchedule: schedular,
                            })
                        }
                    />
                </AppDialog>
            )}
            {state == null ? (
                <AppLoader fullHeight />
            ) : (
                <AppContainer
                    showHeader={true}
                    heightToAdjust={175}
                    mobileViewAdjustment={-15}
                    mediumViewAdjustment={-15}
                    hasInnerScroll={true}
                    loadingValue={
                        todoCreateUpdateLoading ||
                        updateStatusLoading ||
                        deleteFileLoading
                    }
                    title={
                        <div className={styles.root}>
                            <div className={styles.content}>
                                <div className={styles.headerText}>
                                    {state.Id == 0
                                        ? t("todoManagement.new")
                                        : `${state.Task}${
                                              state.RecreatedFromId
                                                  ? `(${t(
                                                        "todoManagement.recreated"
                                                    ).toString()})`
                                                  : ""
                                          }`}
                                </div>
                                {state.Id > 0 && (
                                    <div>
                                        {!state.DoneDate && (
                                            <AppButton
                                                className={"ml-2"}
                                                variant={"primary"}
                                                onClick={() =>
                                                    updateTodoStatus(
                                                        TodoStatus.Status_Done
                                                    )
                                                }
                                            >
                                                {t("todoManagement.taskDone")}
                                            </AppButton>
                                        )}
                                        {state.DoneDate && (
                                            <>
                                                {!state.CompletionDate && (
                                                    <AppButton
                                                        className={"ml-2"}
                                                        variant={"success"}
                                                        onClick={() =>
                                                            updateTodoStatus(
                                                                TodoStatus.Status_Complete
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            "todoManagement.completed"
                                                        )}
                                                    </AppButton>
                                                )}

                                                <AppButton
                                                    className={"ml-2"}
                                                    variant={"danger"}
                                                    onClick={() =>
                                                        updateTodoStatus(
                                                            TodoStatus.Status_Reopened
                                                        )
                                                    }
                                                >
                                                    {t("todoManagement.reOpen")}
                                                </AppButton>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                >
                    <Row>
                        <Col md={4}>
                            <AppInputField
                                label={t("todoManagement.task.name")}
                                placeholder={t("todoManagement.task.name")}
                                value={defaultTo(state.Task, "")}
                                onValueChange={(val) => {
                                    setState({
                                        ...state,
                                        Task: val,
                                    });
                                }}
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Task"
                                )}
                            />

                            <Form.Group
                                controlId="employees"
                                style={{ height: "90px" }}
                            >
                                <Form.Label>
                                    {t("todoManagement.employees.name")}
                                </Form.Label>
                                <AppSelectOld
                                    isDisabled={
                                        readonly ||
                                        employeesLoading ||
                                        employeesRefetching
                                    }
                                    isLoading={
                                        employeesLoading || employeesRefetching
                                    }
                                    options={employees}
                                    value={
                                        employees.length > 0 &&
                                        state.AssigneeToEmployeeId > 0
                                            ? employees.find(
                                                  (x) =>
                                                      x.value ==
                                                      state.AssigneeToEmployeeId.toString()
                                              )
                                            : selectOption
                                    }
                                    getOptionLabel={(opt: SimpleOption) =>
                                        opt.label
                                    }
                                    getOptionValue={(opt: SimpleOption) =>
                                        opt.value
                                    }
                                    onChange={(opt: SimpleOption) => {
                                        setState({
                                            ...state,
                                            AssigneeToEmployeeId: parseInt(
                                                opt.value
                                            ),
                                        });
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "AssigneeToEmployeeId"
                                    )}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="workingArea"
                                style={{ height: "90px" }}
                            >
                                <Form.Label>
                                    {t("todoManagement.workingArea.name")}
                                </Form.Label>
                                <AppSelectOld
                                    isDisabled={
                                        readonly ||
                                        areasLoading ||
                                        areasRefetching
                                    }
                                    options={workingAreas}
                                    isLoading={areasLoading}
                                    value={
                                        workingAreas.length > 0 &&
                                        state.WorkingAreaId > 0
                                            ? workingAreas.find(
                                                  (x: any) =>
                                                      x.value ==
                                                      state.WorkingAreaId.toString()
                                              )
                                            : selectOption
                                    }
                                    getOptionLabel={(opt: SimpleOption) =>
                                        opt.label
                                    }
                                    getOptionValue={(opt: SimpleOption) =>
                                        opt.value
                                    }
                                    onChange={(opt: SimpleOption) => {
                                        setState({
                                            ...state,
                                            WorkingAreaId: parseInt(opt.value),
                                        });
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "WorkingAreaId"
                                    )}
                                />
                            </Form.Group>
                            <div
                                className={`${styles.displayFlex} align-items-end`}
                            >
                                <AppDatePicker
                                    appendToBody={true}
                                    label={t("todoManagement.dueDate")}
                                    value={state.DueDate}
                                    className={classNames(
                                        styles.dateField,
                                        "mr-1"
                                    )}
                                    onChange={(val: Optional<Moment>) => {
                                        if (!isNil(val)) {
                                            setState({
                                                ...state,
                                                DueDate: val,
                                                RepeatSchedule: {
                                                    ...state.RepeatSchedule,
                                                    StartDate: val,
                                                },
                                            });
                                        }
                                    }}
                                    readOnly={readonly}
                                    minValue={moment()}
                                />
                                <AppTimePicker
                                    // appendToBody={true}
                                    className="ml-1"
                                    label=""
                                    readOnly={readonly}
                                    value={defaultTo(
                                        state.DueDateTime,
                                        moment()
                                    )}
                                    showEmptyError={false}
                                    onChange={(val) => {
                                        if (val) {
                                            setState({
                                                ...state,
                                                DueDateTime: val,
                                            });
                                        }
                                    }}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "Time"
                                    )}
                                />
                            </div>
                            {state.Id > 0 && state.DoneDate && (
                                <AppDatePicker
                                    label={t("todoManagement.doneDate")}
                                    value={state.DoneDate}
                                    onChange={(val) => {
                                        if (val) {
                                            setState({
                                                ...state,
                                                DueDate: val,
                                            });
                                        }
                                    }}
                                    className={styles.dateField}
                                    readOnly={true}
                                    useDefault={false}
                                    placeholder={""}
                                />
                            )}
                        </Col>
                        <Col md={4}>
                            <Form.Group
                                controlId="priority"
                                style={{ height: "90px" }}
                            >
                                <Form.Label
                                    style={{
                                        paddingBottom: "16px",
                                    }}
                                >
                                    {t("todoManagement.priority")}
                                </Form.Label>
                                <AppSlider
                                    value={state.Priority}
                                    color={COLOR_DARK_YELLOW}
                                    disabled={readonly}
                                    onChange={(val) => {
                                        setState({
                                            ...state,
                                            Priority: val,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <div style={{ height: "96px" }}>
                                <div className={styles.displayFlex}>
                                    <div>
                                        {t("todoManagement.taskRecreated")}
                                    </div>

                                    <AppSwitch
                                        id={"TaskRecreated"}
                                        value={state.TaskRecreated}
                                        disabled={
                                            readonly ||
                                            state.RecreatedFromId != null
                                        }
                                        onChange={(val) =>
                                            setState({
                                                ...state,
                                                TaskRecreated: val,
                                            })
                                        }
                                        label={""}
                                    />
                                </div>
                                <div
                                    className={styles.displayFlex}
                                    style={{
                                        justifyContent: "start",
                                    }}
                                >
                                    <AppButton
                                        className={"mt-2"}
                                        style={{
                                            marginRight: "15px",
                                        }}
                                        onClick={() => setDialogOpen(true)}
                                        disabled={!state.TaskRecreated}
                                    >
                                        {t("todoManagement.setSchedule")}
                                    </AppButton>
                                    <ErrorMessage
                                        errorInfo={
                                            !validityStateManager.isStateValid(
                                                true,
                                                scheduleValidityState
                                            )
                                                ? ({
                                                      code: "ScheduleNotSet",
                                                      message: t(
                                                          "todoManagement.recreateSchedule.missing"
                                                      ),
                                                  } as ErrorInfo)
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ height: "220px" }}>
                                <div className={`${styles.displayFlex} mb-2`}>
                                    <div>
                                        {t(
                                            "todoManagement.sendReminderToAssignee"
                                        )}
                                    </div>
                                    <AppSwitch
                                        id={"SendRemainder"}
                                        value={state.SendRemainder}
                                        disabled={
                                            readonly ||
                                            !isNil(state.CompletionDate)
                                        }
                                        onChange={(val) =>
                                            setState({
                                                ...state,
                                                SendRemainder: val,
                                            })
                                        }
                                        label={""}
                                    />
                                </div>

                                <Form.Group
                                    controlId="reminderTypes"
                                    className={styles.displayFlex}
                                    style={{
                                        height: "60px",
                                        justifyContent: "start",
                                        alignItems: "start",
                                        marginTop: "8px",
                                    }}
                                >
                                    <div
                                        className={styles.displayFlex}
                                        style={{ width: "100%" }}
                                    >
                                        <Form.Label
                                            style={{
                                                minWidth: "60px",
                                                alignSelf: "center",
                                            }}
                                        >
                                            {t("todoManagement.via")}
                                        </Form.Label>
                                        <div
                                            style={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <AppColoredMultiSelect
                                                isReadonly={
                                                    !state.SendRemainder ||
                                                    readonly
                                                }
                                                value={
                                                    state.Reminder &&
                                                    assigneeOptions.filter(
                                                        (x) =>
                                                            state.Reminder.ReminderTypes.includes(
                                                                parseInt(
                                                                    !isNil(
                                                                        x.value
                                                                    )
                                                                        ? (x.value?.toString() as string)
                                                                        : "0"
                                                                )
                                                            )
                                                    )
                                                }
                                                useOldUi={true}
                                                onChange={(value) => {
                                                    setState({
                                                        ...state,
                                                        Reminder: {
                                                            ...state.Reminder,
                                                            ReminderTypes:
                                                                value.map((x) =>
                                                                    parseInt(
                                                                        !isNil(
                                                                            x.value
                                                                        )
                                                                            ? (x.value?.toString() as string)
                                                                            : "0"
                                                                    )
                                                                ),
                                                        },
                                                    });
                                                }}
                                                data={assigneeOptions}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        {t(`todoManagement.before`)}
                                    </Form.Label>
                                    <TodoManagementSendRemainder
                                        readonly={
                                            !state.SendRemainder || readonly
                                        }
                                        unitValue={state.Reminder.UnitValue}
                                        activeButton={
                                            state.Reminder.RecurrenceLevel
                                        }
                                        onUnitValueChange={(
                                            val: number | null
                                        ) => {
                                            if (val !== null) {
                                                setState({
                                                    ...state,
                                                    Reminder: {
                                                        ...state.Reminder,
                                                        UnitValue: val,
                                                    },
                                                });
                                            }
                                        }}
                                        onSelectButtonChange={(val) =>
                                            setState({
                                                ...state,
                                                Reminder: {
                                                    ...state.Reminder,
                                                    RecurrenceLevel: val,
                                                },
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        errorInfo={validityStateManager.getFirstErrorInfo(
                                            "UnitValue"
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Form.Group
                                controlId="description"
                                className={styles.displayFlex}
                                style={{
                                    height: "180px",
                                    flexDirection: "column",
                                    minHeight: "180px",
                                    alignItems: "start",
                                }}
                            >
                                <Form.Label>
                                    {t("todoManagement.description.name")}
                                </Form.Label>
                                <div className={styles.textArea}>
                                    <TinymceEditor
                                        readonly={readonly}
                                        value={{
                                            placeholders:
                                                state.Description.Placeholders,
                                            html: state.Description.Template,
                                        }}
                                        tinymceProps={{
                                            readonly: readonly,
                                            menubar: false,
                                            contextmenu: false,
                                            max_height: 150,
                                            min_height: 150,
                                            toolbar: false,
                                        }}
                                        onChange={(newState) => {
                                            setState({
                                                ...state,
                                                Description: {
                                                    ...state.Description,
                                                    Placeholders:
                                                        newState.placeholders,
                                                    Template: newState.html,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <AppFilesListComponent
                                className={styles.marginTop_33}
                                value={{
                                    Files: defaultTo(state?.Files, []),
                                    files: defaultTo(state?.files, []),
                                }}
                                maxHeight={"200px"}
                                permMap={permMap}
                                onChange={(val) => {
                                    setState(
                                        (s) =>
                                            ({
                                                ...defaultTo(s, {}),
                                                ...val,
                                            } as any)
                                    );
                                }}
                                onDelete={(id: number) =>
                                    deleteFile({
                                        id: id,
                                        todoId: state.Id.toString(),
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={styles.modalFooterRightButtons}>
                                <AppButton
                                    disabled={
                                        (state.Id > 0 &&
                                            !hasTodoEditPermission) ||
                                        !validityStateManager.isStateValid()
                                    }
                                    style={{ minWidth: "100px" }}
                                    onClick={() => createUpdateTodo(state)}
                                >
                                    {t("common.save")}
                                </AppButton>
                            </div>
                        </Col>
                    </Row>
                </AppContainer>
            )}
        </div>
    );
};

export default TodoManagementCreateEdit;
