import IFrameContent from "components/IFrameContent";
import {
    Business_AccessControl,
    Business_AgeGroups,
    Business_BasicData,
    Business_CareScopes,
    Business_Contracts,
    Business_Courses,
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_DataImport_Employee,
    Business_EmployeeKeyCalculation,
    Business_Functions,
    Business_Groups,
    Business_CompensationTemplates,
    Business_Ipads,
    // Business_Kontos,
    Business_Logo,
    Business_MealTemplates,
    Business_MealTemplates_Meal,
    Business_MealTemplates_MealGroup,
    Business_PrinterJobs,
    Business_Printers,
    Business_PublicHolidays,
    Business_Shifts,
    Business_WorkingArea,
    Business_WorkingHours,
    ModulePlans,
    PermissionAccessTypes,
    Business_SalaryGroups,
    Business_Settings,
} from "models";
import {
    Feature_Business_Konto,
    Feature_Business_AccessControl,
    Feature_Business_AddUpdate,
    Feature_Business_BackgroundJobs,
    Feature_Business_Branches,
    Feature_Business_Contracts,
    Feature_Business_Data,
    Feature_Business_EmployeeKey,
    Feature_Business_EmployeeKey_AddUpdate,
    Feature_Business_Functions,
    Feature_Business_Functions_AddUpdate,
    Feature_Business_Group,
    Feature_Business_Group_AddUpdate,
    Feature_Business_Holidays,
    Feature_Business_Printers,
    Feature_Business_Printers_AddUpdate,
    Feature_Business_Print_Job,
    Feature_Business_Print_Job_AddUpdate,
    Feature_Business_Shift,
    Feature_Business_Shift_AddUpdate,
    Feature_Business_CompensationTemplates,
    Feature_Business_CompensationTemplates_AddUpdate,
    Feature_Business_UploadLogos,
    Feature_Business_WorkingArea,
    Feature_Business_WorkingArea_AddUpdate,
    Feature_Business_WorkingHours,
    Feature_Kindergarten_Ipad,
    Feature_Kindergarten_MealTemplates,
    Feature_Kindergarten_MealTemplates_AddUpdate,
    Feature_Business_CareScope,
    Feature_Business_CareScope_AddUpdate,
    Feature_Business_AgeGroup_AddUpdate,
    Feature_Business_AgeGroup,
    Feature_Business_SalaryGroup_AddUpdate,
    Feature_Business_SalaryGroup,
    Feature_Business_Courses,
    Feature_Business_Courses_AddUpdate,
    Feature_Business_Settings,
    Feature_Business_Chat_Settings,
    Feature_Business_ExternalIntegration,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import { BusinessEdit } from "screens/business/hqAndBranches";
import {
    BusinessCompensationTemplateCreate,
    BusinessCompensationTemplateEdit,
    BusinessCompensationTemplateList,
} from "screens/business/settingTabs/compensationTemplate";
import { ImportDataTab } from "screens/business/settingTabs/dataImports";
import Dummy from "screens/Dummy";
import businessSettingRoutes from "./routes";
import BusinessContracts from "screens/business/settingTabs/contracts/BusinessContracts";
import { RenewContractView } from "screens/business/settingTabs/contracts/RenewContractView";
import {
    BusinessAgeGroupTemplateCreate,
    BusinessAgeGroupTemplateEdit,
    BusinessAgeGroupTemplateList,
    BusinessBasicSettings,
    BusinessCareScopeTemplateCreate,
    BusinessCareScopeTemplateEdit,
    BusinessCareScopeTemplateList,
    BusinessChatSettingsLayout,
    BusinessEmpKeyTemplateCreate,
    BusinessEmpKeyTemplateEdit,
    BusinessEmpKeyTemplateList,
    BusinessShiftCreate,
    BusinessShiftEdit,
    BusinessShiftList,
    BusinessWorkingDays,
    TimeTrackingSettings,
} from "screens/business/settingTabs";
import { BusinessKontoList } from "screens/business/settingTabs/kontos";
import { BusinessCoursesList } from "screens/business/settingTabs/courses/BusinessCoursesList";
import { BusinessCoursesCreateEdit } from "screens/business/settingTabs/courses/BusinessCoursesCreateEdit";
import { BusinessIPadsList } from "screens/business/settingTabs/iPads";
import {
    BusinessSalaryGroupTemplateList,
    BusinessSalaryGroupTemplateCreate,
    BusinessSalaryGroupTemplateEdit,
} from "screens/business/settingTabs/salaryGroupTemplate";
import BusinessExternalIntegration from "screens/business/settingTabs/BusinessExternalIntegration";

const relativeRoutes = businessSettingRoutes(false);
const fullRoutes = businessSettingRoutes();

export const businessSettingRoutesMap: RoutesMap = {
    [relativeRoutes.screens.basicData()]: {
        component: BusinessEdit,
        fullPattern: fullRoutes.screens.basicData(),
        pattern: relativeRoutes.screens.basicData(),
        permKeys: [Business_BasicData],
        featureKeys: [Feature_Business_Branches],
    },
    [relativeRoutes.screens.contracts()]: {
        component: BusinessContracts,
        fullPattern: fullRoutes.screens.contracts(),
        pattern: relativeRoutes.screens.contracts(),
        permKeys: [Business_Contracts],
        featureKeys: [Feature_Business_Contracts],
    },
    [relativeRoutes.screens.renewContract()]: {
        component: RenewContractView,
        fullPattern: fullRoutes.screens.renewContract(),
        pattern: relativeRoutes.screens.renewContract(),
        permKeys: [Business_Contracts],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.screens.ipads()]: {
        component: BusinessIPadsList,
        fullPattern: fullRoutes.screens.ipads(),
        pattern: relativeRoutes.screens.ipads(),
        permKeys: [Business_Ipads],
        featureKeys: [Feature_Kindergarten_Ipad],
    },
    [relativeRoutes.screens.publicHolidays()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.publicHolidays(),
        pattern: relativeRoutes.screens.publicHolidays(),
        permKeys: [Business_PublicHolidays],
        featureKeys: [Feature_Business_Holidays],
    },
    [relativeRoutes.screens.workingHours()]: {
        component: BusinessWorkingDays,
        fullPattern: fullRoutes.screens.workingHours(),
        pattern: relativeRoutes.screens.workingHours(),
        permKeys: [Business_WorkingHours],
        featureKeys: [Feature_Business_WorkingHours],
    },
    [relativeRoutes.screens.basicSettings()]: {
        component: BusinessBasicSettings,
        fullPattern: fullRoutes.screens.basicSettings(),
        pattern: relativeRoutes.screens.basicSettings(),
        permKeys: [Business_Settings],
        featureKeys: [Feature_Business_Settings],
    },
    [relativeRoutes.screens.chatSettings()]: {
        component: BusinessChatSettingsLayout,
        fullPattern: fullRoutes.screens.chatSettings(),
        pattern: relativeRoutes.screens.chatSettings(),
        permKeys: [Business_Settings],
        featureKeys: [Feature_Business_Chat_Settings],
    },
    [relativeRoutes.screens.timeTracking()]: {
        component: TimeTrackingSettings,
        fullPattern: fullRoutes.screens.timeTracking(),
        pattern: relativeRoutes.screens.timeTracking(),
        permKeys: [Business_Settings],
    },
    [relativeRoutes.screens.logo()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.logo(),
        pattern: relativeRoutes.screens.logo(),
        permKeys: [Business_Logo],
        permAccessTypes: [PermissionAccessTypes.EDIT],
        featureKeys: [Feature_Business_UploadLogos],
    },
    [relativeRoutes.screens.externalIntegration()]: {
        component: BusinessExternalIntegration,
        fullPattern: fullRoutes.screens.externalIntegration(),
        pattern: relativeRoutes.screens.externalIntegration(),
        permKeys: [Business_BasicData],
        permAccessTypes: [PermissionAccessTypes.EDIT],
        featureKeys: [Feature_Business_ExternalIntegration],
    },
    [relativeRoutes.screens.backgroundJobs()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.backgroundJobs(),
        pattern: relativeRoutes.screens.backgroundJobs(),
        permKeys: [Business_BasicData],
        featureKeys: [Feature_Business_BackgroundJobs],
    },
    [relativeRoutes.screens.data()]: {
        component: ImportDataTab,
        fullPattern: fullRoutes.screens.data(),
        pattern: relativeRoutes.screens.data(),
        permKeys: [
            Business_DataImport_Client,
            Business_DataImport_ClientRegistration,
            Business_DataImport_Employee,
        ],
        permAccessTypes: [
            PermissionAccessTypes.DELETE,
            PermissionAccessTypes.CREATE,
        ],
        featureKeys: [Feature_Business_Data],
    },
    [relativeRoutes.screens.accessControl.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.accessControl.list(),
        pattern: relativeRoutes.screens.accessControl.list(),
        permKeys: [Business_AccessControl],
        requiredModule: [ModulePlans.TimeTracking],
        requiredModuleRootCheck: false,
        featureKeys: [Feature_Business_AccessControl],
    },
    [relativeRoutes.screens.accessControl.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.accessControl.create(),
        pattern: relativeRoutes.screens.accessControl.create(),
        permKeys: [Business_AccessControl],
        requiredModule: [ModulePlans.TimeTracking],
        requiredModuleRootCheck: false,
        permAccessTypes: [PermissionAccessTypes.CREATE],
        featureKeys: [Feature_Business_AccessControl],
    },
    [relativeRoutes.screens.accessControl.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.accessControl.edit(),
        pattern: relativeRoutes.screens.accessControl.edit(),
        permKeys: [Business_AccessControl],
        requiredModule: [ModulePlans.TimeTracking],
        requiredModuleRootCheck: false,
        permAccessTypes: [PermissionAccessTypes.EDIT],
        featureKeys: [Feature_Business_AccessControl],
    },
    [relativeRoutes.screens.compensationTemplates.list()]: {
        component: BusinessCompensationTemplateList,
        fullPattern: fullRoutes.screens.compensationTemplates.list(),
        pattern: relativeRoutes.screens.compensationTemplates.list(),
        permKeys: [Business_CompensationTemplates],
        featureKeys: [Feature_Business_CompensationTemplates],
    },
    [relativeRoutes.screens.compensationTemplates.create()]: {
        component: BusinessCompensationTemplateCreate,
        fullPattern: fullRoutes.screens.compensationTemplates.create(),
        pattern: relativeRoutes.screens.compensationTemplates.create(),
        permKeys: [Business_CompensationTemplates],
        permAccessTypes: [PermissionAccessTypes.CREATE],
        featureKeys: [Feature_Business_CompensationTemplates_AddUpdate],
    },
    [relativeRoutes.screens.compensationTemplates.edit()]: {
        component: BusinessCompensationTemplateEdit,
        fullPattern: fullRoutes.screens.compensationTemplates.edit(),
        pattern: relativeRoutes.screens.compensationTemplates.edit(),
        permKeys: [Business_CompensationTemplates],
        permAccessTypes: [PermissionAccessTypes.EDIT],
        featureKeys: [Feature_Business_CompensationTemplates_AddUpdate],
    },
    [relativeRoutes.screens.employeeKeyCalculations.list()]: {
        component: BusinessEmpKeyTemplateList,
        fullPattern: fullRoutes.screens.employeeKeyCalculations.list(),
        pattern: relativeRoutes.screens.employeeKeyCalculations.list(),
        permKeys: [Business_EmployeeKeyCalculation],
        featureKeys: [Feature_Business_EmployeeKey],
    },
    [relativeRoutes.screens.employeeKeyCalculations.create()]: {
        component: BusinessEmpKeyTemplateCreate,
        fullPattern: fullRoutes.screens.employeeKeyCalculations.create(),
        pattern: relativeRoutes.screens.employeeKeyCalculations.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_EmployeeKeyCalculation],
        featureKeys: [Feature_Business_EmployeeKey],
    },
    [relativeRoutes.screens.employeeKeyCalculations.edit()]: {
        component: BusinessEmpKeyTemplateEdit,
        fullPattern: fullRoutes.screens.employeeKeyCalculations.edit(),
        pattern: relativeRoutes.screens.employeeKeyCalculations.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_EmployeeKeyCalculation],
        featureKeys: [
            Feature_Business_EmployeeKey,
            Feature_Business_EmployeeKey_AddUpdate,
        ],
    },
    [relativeRoutes.screens.courses.list()]: {
        component: BusinessCoursesList,
        fullPattern: fullRoutes.screens.courses.list(),
        pattern: relativeRoutes.screens.courses.list(),
        permKeys: [Business_Courses],
        featureKeys: [Feature_Business_Courses],
    },
    [relativeRoutes.screens.courses.create()]: {
        component: BusinessCoursesCreateEdit,
        fullPattern: fullRoutes.screens.courses.create(),
        pattern: relativeRoutes.screens.courses.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_Courses],
        featureKeys: [Feature_Business_Courses_AddUpdate],
    },
    [relativeRoutes.screens.courses.edit()]: {
        component: BusinessCoursesCreateEdit,
        fullPattern: fullRoutes.screens.courses.edit(),
        pattern: relativeRoutes.screens.courses.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_Courses],
        featureKeys: [Feature_Business_Courses_AddUpdate],
    },
    [relativeRoutes.screens.careScopes.list()]: {
        component: BusinessCareScopeTemplateList,
        fullPattern: fullRoutes.screens.careScopes.list(),
        pattern: relativeRoutes.screens.careScopes.list(),
        permKeys: [Business_CareScopes],
        featureKeys: [Feature_Business_CareScope],
    },
    [relativeRoutes.screens.careScopes.create()]: {
        component: BusinessCareScopeTemplateCreate,
        fullPattern: fullRoutes.screens.careScopes.create(),
        pattern: relativeRoutes.screens.careScopes.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_CareScopes],
        featureKeys: [Feature_Business_CareScope_AddUpdate],
    },
    [relativeRoutes.screens.careScopes.edit()]: {
        component: BusinessCareScopeTemplateEdit,
        fullPattern: fullRoutes.screens.careScopes.edit(),
        pattern: relativeRoutes.screens.careScopes.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_CareScopes],
        featureKeys: [Feature_Business_CareScope_AddUpdate],
    },
    [relativeRoutes.screens.ageGroups.list()]: {
        component: BusinessAgeGroupTemplateList,
        fullPattern: fullRoutes.screens.ageGroups.list(),
        pattern: relativeRoutes.screens.ageGroups.list(),
        permKeys: [Business_AgeGroups],
        featureKeys: [Feature_Business_AgeGroup],
    },
    [relativeRoutes.screens.ageGroups.create()]: {
        component: BusinessAgeGroupTemplateCreate,
        fullPattern: fullRoutes.screens.ageGroups.create(),
        pattern: relativeRoutes.screens.ageGroups.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_AgeGroups],
        featureKeys: [Feature_Business_AgeGroup_AddUpdate],
    },
    [relativeRoutes.screens.ageGroups.edit()]: {
        component: BusinessAgeGroupTemplateEdit,
        fullPattern: fullRoutes.screens.ageGroups.edit(),
        pattern: relativeRoutes.screens.ageGroups.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_AgeGroups],
        featureKeys: [Feature_Business_AgeGroup_AddUpdate],
    },
    [relativeRoutes.screens.salaryGroups.list()]: {
        component: BusinessSalaryGroupTemplateList,
        fullPattern: fullRoutes.screens.salaryGroups.list(),
        pattern: relativeRoutes.screens.salaryGroups.list(),
        permKeys: [Business_SalaryGroups],
        featureKeys: [Feature_Business_SalaryGroup],
    },
    [relativeRoutes.screens.salaryGroups.create()]: {
        component: BusinessSalaryGroupTemplateCreate,
        fullPattern: fullRoutes.screens.salaryGroups.create(),
        pattern: relativeRoutes.screens.salaryGroups.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_SalaryGroups],
        featureKeys: [Feature_Business_SalaryGroup_AddUpdate],
    },
    [relativeRoutes.screens.salaryGroups.edit()]: {
        component: BusinessSalaryGroupTemplateEdit,
        fullPattern: fullRoutes.screens.salaryGroups.edit(),
        pattern: relativeRoutes.screens.salaryGroups.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_SalaryGroups],
        featureKeys: [Feature_Business_SalaryGroup_AddUpdate],
    },
    [relativeRoutes.screens.groups.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.groups.list(),
        pattern: relativeRoutes.screens.groups.list(),
        permKeys: [Business_Groups],
        featureKeys: [Feature_Business_Group],
    },
    [relativeRoutes.screens.groups.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.groups.create(),
        pattern: relativeRoutes.screens.groups.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_Groups],
        featureKeys: [Feature_Business_Group_AddUpdate],
    },
    [relativeRoutes.screens.groups.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.groups.edit(),
        pattern: relativeRoutes.screens.groups.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_Groups],
        featureKeys: [Feature_Business_Group_AddUpdate],
    },
    [relativeRoutes.screens.areas.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.areas.list(),
        pattern: relativeRoutes.screens.areas.list(),
        permKeys: [Business_WorkingArea],
        featureKeys: [Feature_Business_WorkingArea],
    },
    [relativeRoutes.screens.areas.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.areas.create(),
        pattern: relativeRoutes.screens.areas.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_WorkingArea],
        featureKeys: [Feature_Business_WorkingArea_AddUpdate],
    },
    [relativeRoutes.screens.areas.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.areas.edit(),
        pattern: relativeRoutes.screens.areas.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_WorkingArea],
        featureKeys: [Feature_Business_WorkingArea_AddUpdate],
    },
    [relativeRoutes.screens.shifts.list()]: {
        component: BusinessShiftList,
        fullPattern: fullRoutes.screens.shifts.list(),
        pattern: relativeRoutes.screens.shifts.list(),
        permKeys: [Business_Shifts],
        featureKeys: [Feature_Business_Shift],
    },
    [relativeRoutes.screens.shifts.create()]: {
        component: BusinessShiftCreate,
        fullPattern: fullRoutes.screens.shifts.create(),
        pattern: relativeRoutes.screens.shifts.create(),
        permKeys: [Business_Shifts],
        permAccessTypes: [PermissionAccessTypes.CREATE],
        featureKeys: [Feature_Business_Shift_AddUpdate],
    },
    [relativeRoutes.screens.shifts.edit()]: {
        component: BusinessShiftEdit,
        fullPattern: fullRoutes.screens.shifts.edit(),
        pattern: relativeRoutes.screens.shifts.edit(),
        permKeys: [Business_Shifts],
        featureKeys: [Feature_Business_Shift_AddUpdate],
    },
    [relativeRoutes.screens.kontos()]: {
        component: BusinessKontoList,
        fullPattern: fullRoutes.screens.kontos(),
        pattern: relativeRoutes.screens.kontos(),
        // permKeys: [Business_Kontos],
        featureKeys: [Feature_Business_Konto],
    },
    [relativeRoutes.screens.functions.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.functions.list(),
        pattern: relativeRoutes.screens.functions.list(),
        permKeys: [Business_Functions],
        featureKeys: [Feature_Business_Functions],
    },
    [relativeRoutes.screens.functions.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.functions.create(),
        pattern: relativeRoutes.screens.functions.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_Functions],
        featureKeys: [Feature_Business_Functions_AddUpdate],
    },
    [relativeRoutes.screens.functions.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.functions.edit(),
        pattern: relativeRoutes.screens.functions.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_Functions],
        featureKeys: [Feature_Business_Functions_AddUpdate],
    },
    [relativeRoutes.screens.printers.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.printers.list(),
        pattern: relativeRoutes.screens.printers.list(),
        permKeys: [Business_Printers],
        featureKeys: [Feature_Business_Printers],
    },
    [relativeRoutes.screens.printers.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.printers.create(),
        pattern: relativeRoutes.screens.printers.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_Printers],
        featureKeys: [Feature_Business_Printers_AddUpdate],
    },
    [relativeRoutes.screens.printers.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.printers.edit(),
        pattern: relativeRoutes.screens.printers.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_Printers],
        featureKeys: [Feature_Business_Printers_AddUpdate],
    },
    [relativeRoutes.screens.printJobs.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.printJobs.list(),
        pattern: relativeRoutes.screens.printJobs.list(),
        permKeys: [Business_PrinterJobs],
        featureKeys: [Feature_Business_Print_Job],
    },
    [relativeRoutes.screens.printJobs.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.printJobs.create(),
        pattern: relativeRoutes.screens.printJobs.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_PrinterJobs],
        featureKeys: [Feature_Business_Print_Job_AddUpdate],
    },
    [relativeRoutes.screens.printJobs.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.printJobs.edit(),
        pattern: relativeRoutes.screens.printJobs.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_PrinterJobs],
        featureKeys: [Feature_Business_Print_Job_AddUpdate],
    },
    [relativeRoutes.screens.mealTemplates.list()]: {
        component: IFrameContent,
        fullPattern: fullRoutes.screens.mealTemplates.list(),
        pattern: relativeRoutes.screens.mealTemplates.list(),
        permKeys: [Business_MealTemplates],
        featureKeys: [Feature_Kindergarten_MealTemplates],
    },
    [relativeRoutes.screens.mealTemplates.create()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.mealTemplates.create(),
        pattern: relativeRoutes.screens.mealTemplates.create(),
        permAccessTypes: [PermissionAccessTypes.CREATE],
        permKeys: [Business_MealTemplates],
        featureKeys: [Feature_Kindergarten_MealTemplates_AddUpdate],
    },
    [relativeRoutes.screens.mealTemplates.edit()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.mealTemplates.edit(),
        pattern: relativeRoutes.screens.mealTemplates.edit(),
        permAccessTypes: [PermissionAccessTypes.EDIT],
        permKeys: [Business_MealTemplates],
        featureKeys: [Feature_Kindergarten_MealTemplates_AddUpdate],
    },
    [relativeRoutes.screens.mealTemplates.meals()]: {
        component: Dummy,
        fullPattern: fullRoutes.screens.mealTemplates.meals(),
        pattern: relativeRoutes.screens.mealTemplates.meals(),
        permKeys: [
            Business_MealTemplates_Meal,
            Business_MealTemplates_MealGroup,
        ],
        featureKeys: [Feature_Kindergarten_MealTemplates_AddUpdate],
    },
};
