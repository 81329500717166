import { AppIconWithName, AppSwitch } from "components";
import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import {
    PermissionAccessMap,
    PermissionAccessTypes,
    UserRoleManagementItem,
} from "models";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    getUsersRoleManagementServiceKey,
    UsersRoleManagementService,
} from "services/business";
import styles from "./UserManagementTableRow.module.scss";

export interface UserManagementTableRowProp {
    value: UserRoleManagementItem;
    onEditClick: (userId: string) => void;
    removeItem: (id: string) => void;
    onActiveStateChange: (
        user: UserRoleManagementItem,
        checked: boolean
    ) => void;
    accessMap: PermissionAccessMap;
}

export const UserManagementTableRow: React.FC<UserManagementTableRowProp> = ({
    value,
    onEditClick,
    onActiveStateChange,
    removeItem,
    accessMap,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { id: userId } = useSessionUser();
    const service = new UsersRoleManagementService(
        linkProvider.business.api.userManagement
    );
    const {
        mutate: deleteUser,
        isLoading: loadingDel,
        data: responseDel,
    } = useMutation(
        getUsersRoleManagementServiceKey("delete"),
        async (data: { userId: string; roleId: string }) =>
            await service.delete(data)
    );

    useEffect(() => {
        if (!loadingDel && responseDel) {
            if (responseDel.Data) {
                if (value.Id) {
                    removeItem(value.Id);
                }
                showSweetAlertToast(
                    t("common.success"),
                    `"${value.Name}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
            } else if (responseDel.Code != null && responseDel.Errors) {
                showSweetAlertToast(
                    t("common.error.error"),
                    responseDel.Errors[0].Message,
                    "error"
                );
            }
        }
    }, [loadingDel, responseDel]);

    return (
        <tr>
            <td className={styles.NameTd}>
                <AppIconWithName
                    text={`${value.FirstName} ${value.LastName}`}
                />
            </td>
            <td>{value.AssignedRole?.Name}</td>
            <td className="ellipsis-text single-line">
                <span>{value.Email}</span>
            </td>
            <td className="ellipsis-text single-line">
                <span>{value.Description}</span>
            </td>
            <td>
                {value && (
                    <AppSwitch
                        value={value.IsActive}
                        label={value.Id as string}
                        hideLabel={true}
                        onChange={(val) => onActiveStateChange(value, val)}
                        disabled={
                            userId === value.Id ||
                            !accessMap[PermissionAccessTypes.EDIT]
                        }
                    />
                )}
            </td>
            <td>
                <AppIconButton
                    onClick={() => value.Id && onEditClick(value.Id)}
                    buttonType="Edit"
                    readonly={
                        loadingDel || !accessMap[PermissionAccessTypes.SHOW]
                    }
                    tooltipText={t("common.edit")}
                    showToolTip={true}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() =>
                        deleteUser({
                            userId: value.Id as string,
                            roleId: value.RoleId,
                        })
                    }
                    getConfirmation={true}
                    name={`${value.FirstName} ${value.LastName}`}
                    readonly={
                        userId == value.Id ||
                        !accessMap[PermissionAccessTypes.DELETE] ||
                        loadingDel
                    }
                />
            </td>
        </tr>
    );
};

export default UserManagementTableRow;
