/* eslint-disable prefer-spread */
import React from "react";
import Skeleton from "react-loading-skeleton";
export interface AppTableSkeletonProps {
    rows: number;
    columns: number;
}
export const AppTableSkeleton: React.FC<AppTableSkeletonProps> = ({
    columns,
    rows,
}) => {
    return (
        <>
            {Array(rows)
                .fill(rows)
                .map((e, i) => (
                    <tr key={i}>
                        {Array(columns)
                            .fill(columns)
                            .map((e, i) => (
                                <td key={i} style={{ paddingLeft: "5px" }}>
                                    <Skeleton />
                                </td>
                            ))}
                    </tr>
                ))}
        </>
    );
};

export default AppTableSkeleton;
