import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toNumber } from "globals/helpers/localizationHelpers";
import { errorPageImages, ImageAssets } from "globals/images";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { defaultTo } from "lodash-es";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import {
    Error400,
    Error401,
    Error403,
    Error404,
    Error500,
    Error501,
} from "screens/errorPages";
import styles from "./ErrorLayout.module.scss";

export const ERROR_CODE_PARAM_KEY = "errorCode";

export type AppErrorCodes = 400 | 401 | 403 | 404 | 501 | 500;

const getPageForCode = (codeStr: string | null | AppErrorCodes) => {
    const code = toNumber(codeStr);

    const page404 = <Error404 />;
    if (!code) {
        return page404;
    }

    switch (code) {
        case 400:
            return <Error400 />;
        case 401:
            return <Error401 />;
        case 403:
            return <Error403 />;
        case 404:
            return page404;
        case 500:
            return <Error500 />;
        case 501:
            return <Error501 />;
        default:
            return page404;
    }
};
export interface ErrorLayoutProps {
    errorCode?: AppErrorCodes;
}
export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
    errorCode,
}: PropsWithChildren<ErrorLayoutProps>) => {
    const { linkProvider } = useRouting();
    const { isAuthenticated } = useSessionUser();
    const { checkPermission } = useCheckPermission();

    const homePath = linkProvider.screens.home(checkPermission);
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    const errorPage = () => {
        const code = defaultTo(
            errorCode,
            searchParams.get(ERROR_CODE_PARAM_KEY)
        );
        if (!code) {
            setSearchParams(
                {
                    ...searchParams,
                    [ERROR_CODE_PARAM_KEY]: "404",
                },
                { replace: true }
            ); // show 404 if not error route but is redirected here
        }
        return getPageForCode(code);
    };

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.errorBackgroundImage}>
                    <img src={errorPageImages.background} alt="" />
                </div>
                <div className={styles.errorMain}>
                    <div className={styles.errorHeader}>
                        <div className={styles.errorLogo}>
                            <Link to={homePath}>
                                <img src={ImageAssets.logo.fullLogo} alt="" />
                            </Link>
                        </div>
                        <div className={styles.headerButton}>
                            <Link
                                className={`btn gradient-button ${styles.errorButton}`}
                                to={
                                    isAuthenticated
                                        ? homePath
                                        : linkProvider.noAuth.screens.login()
                                }
                            >
                                {isAuthenticated ? (
                                    <>
                                        <FontAwesomeIcon icon="home" />
                                        {t("errorPage.button.home")}
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={ImageAssets.common.userOutline}
                                            alt=""
                                        />
                                        {t("errorPage.button.login")}
                                    </>
                                )}
                            </Link>
                        </div>
                    </div>
                    {errorPage()}
                </div>
            </div>
        </div>
    );
};

export default ErrorLayout;
