import React from "react";
import Skeleton from "react-loading-skeleton";
import itemStyles from "./NotificationItemSkeleton.module.scss";

export interface NotificationItemSkeletonProps {
    count: number;
}

export const NotificationItemSkeleton: React.FC<
    NotificationItemSkeletonProps
> = ({ count }) => {
    return (
        <>
            {" "}
            {Array(count)
                .fill(count)
                .map((e, i) => (
                    <React.Fragment key={i}>
                        <div className={itemStyles.itemOuter}>
                            <Skeleton
                                height={20}
                                width={20}
                                style={{ padding: "0 3px" }}
                            />
                            <div
                                className={itemStyles.circleImage}
                                style={{ marginLeft: "25px" }}
                            >
                                <Skeleton
                                    circle={true}
                                    height={45}
                                    width={45}
                                />
                            </div>
                            <div className={itemStyles.textDiv}>
                                <Skeleton height={15} width={200} />
                                <Skeleton
                                    height={10}
                                    style={{ marginTop: "10px" }}
                                />
                            </div>
                            <span className={itemStyles.rightPortion}>
                                <div>
                                    <div>
                                        <Skeleton
                                            height={10}
                                            width={100}
                                            style={{ marginRight: "20px" }}
                                        />
                                    </div>
                                    <div className={itemStyles.newText}>
                                        <Skeleton height={10} width={30} />
                                    </div>
                                </div>
                                <Skeleton height={10} width={30} />
                            </span>
                        </div>
                        <div className="nav-item-divider nav-item"></div>
                    </React.Fragment>
                ))}
        </>
    );
};

export default NotificationItemSkeleton;
