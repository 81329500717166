import {
    BusinessContextResponse,
    BusinessDetails,
    BusinessHQ,
    ClientContact,
    CompanyType,
} from "models";
import { BusinessCompanyDataField } from "models/companyDataField";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService, headerToIgnoreContractExpire } from "../BaseService";

const businessApiLinkProvider = routesForContext().business.api;
type ApiLinkProviderType = typeof businessApiLinkProvider;

export interface IBusinessService {
    getAdminBusinessesList: (
        request?: CompanyType
    ) => Promise<AppResponse<BusinessHQ[]>>;
    getBusinessDetail: (
        businessId: string
    ) => Promise<AppResponse<BusinessDetails>>;
    createBusiness(
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>>;
    updateBusiness(
        businessId: string,
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>>;
    changeActiveHq: (
        hqId: number
    ) => Promise<AppResponse<BusinessContextResponse>>;
    changeSessionBusiness(
        id: number
    ): Promise<AppResponse<BusinessContextResponse>>;
    getCompanyDataFieldValues(
        currentBusinessId: string
    ): Promise<AppResponse<BusinessCompanyDataField[]>>;
    updateModulePlan(
        contractId: string,
        fieldUuid: string,
        basicPckgUuid: string,
        active: boolean
    ): Promise<AppResponse<string>>;
    deleteLw: (
        password: string,
        businessId: number,
        hqId: number | null
    ) => Promise<AppResponse<boolean>>;
    deleteLwc: (
        masterPassword: string,
        password: string,
        businessId: number,
        hqId: number | null
    ) => Promise<AppResponse<boolean>>;
    deleteBusiness(businessId: number): Promise<AppResponse<boolean>>;
}

export function getBusinessServiceKey(
    name: keyof IBusinessService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `business_${name}_${JSON.stringify(data)}`;
}

export class BusinessService extends BaseService implements IBusinessService {
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }
    getAdminBusinessesList(
        request?: CompanyType
    ): Promise<AppResponse<BusinessHQ[]>> {
        return this.doServerXHR<BusinessHQ[]>({
            url: this.baseRoute.getAdminBusinessesList(
                request ? { companyType: request } : undefined
            ),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }

    getBusinessDetail(
        businessId: string
    ): Promise<AppResponse<BusinessDetails>> {
        return this.doServerXHR<BusinessDetails>({
            url: this.baseRoute.getBusinessDetail(businessId),
            method: "get",
        });
    }

    createBusiness(
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>> {
        return this.doServerXHR<BusinessDetails>({
            url: this.baseRoute.createBusiness(),
            method: "post",
            data: businessDetail,
        });
    }

    updateBusiness(
        businessId: string,
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>> {
        return this.doServerXHR<BusinessDetails>({
            url: this.baseRoute.updateBusiness(businessId),
            method: "post",
            data: businessDetail,
        });
    }

    getCompanyDataFieldValues(
        currentBusinessId: string
    ): Promise<AppResponse<BusinessCompanyDataField[]>> {
        return this.doServerXHR<BusinessCompanyDataField[]>({
            url: this.baseRoute.getCompanyDataFieldValues(currentBusinessId),
            method: "get",
        });
    }
    updateModulePlan(
        contractId: string,
        fieldUuid: string,
        basicPckgUuid: string,
        active: boolean = true
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.baseRoute.updateModulePlan(
                contractId,
                fieldUuid,
                basicPckgUuid,
                active
            ),
            method: "post",
        });
    }

    // if clicked on HQ
    changeActiveHq(
        hqId: number
    ): Promise<AppResponse<BusinessContextResponse>> {
        return this.doServerXHR<BusinessContextResponse>({
            url: this.baseRoute.changeActiveHq({ hqId: hqId }),
            method: "post",
        });
    }
    // if clicked on branch
    changeSessionBusiness(
        id: number
    ): Promise<AppResponse<BusinessContextResponse>> {
        return this.doServerXHR<BusinessContextResponse>({
            url: this.baseRoute.changeSessionBusiness({ targetBusinessId: id }),
            method: "post",
        });
    }

    deleteLw(
        password: string,
        businessId: number,
        hqId: number | null
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteLw(),
            method: "delete",
            data: {
                Password: password,
                BusinessId: businessId,
                HqId: hqId,
            },
        });
    }
    deleteLwc(
        masterPassword: string,
        password: string,
        businessId: number,
        hqId: number | null
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteLwc(),
            method: "delete",
            data: {
                MasterPassword: masterPassword,
                Password: password,
                BusinessId: businessId,
                HqId: hqId,
            },
        });
    }
    deleteBusiness(businessId: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteBusiness({ id: businessId }),
            method: "delete",
        });
    }
    getContactInfo(email: string): Promise<AppResponse<ClientContact>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.getContactInfo({ email: email }),
            method: "delete",
        });
    }
}
