import { AppResponse } from "models/general/response";
import {
    MailboxListRequest,
    MessageCount,
    MessageTemplate,
    MessageTemplateWithStatuses,
} from "models/messages";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IMessageService {
    getMessageTemplate: (
        messageId: string
    ) => Promise<AppResponse<MessageTemplateWithStatuses>>;
    updateTemplateFavorite(
        messageId: string,
        val: boolean
    ): Promise<AppResponse<boolean>>;
    create(template: MessageTemplate): Promise<AppResponse<MessageTemplate>>;
    getMessageTemplatesList(
        reqParams: MailboxListRequest
    ): Promise<AppResponse<MessageTemplate[]>>;
    getMessageCount(): Promise<AppResponse<MessageCount>>;
    trash(messageId: string): Promise<AppResponse<boolean>>;
    bulkTrash(ids: number[]): Promise<AppResponse<boolean>>;
    bulkDelete(ids: number[]): Promise<AppResponse<boolean>>;
    delete(messageId: string): Promise<AppResponse<boolean>>;
}

const apiLinkProvider = routesForContext().business.api.message;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getMessageServiceKey(name: keyof IMessageService, data?: any) {
    if (!data) {
        return name;
    }
    return `mailbox_${name}_${JSON.stringify(data)}`;
}
export class MessageService extends BaseService implements IMessageService {
    public messageRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.messageRoutes = apiLinkProvider;
    }

    constructFormData(template: MessageTemplate) {
        const { files, ...rest } = template;
        const fD = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                fD.append("files", files[i]);
            }
        }
        const json = JSON.stringify(rest);
        fD.append("Data", json);
        return fD;
    }

    getMessageTemplate(
        messageId: string
    ): Promise<AppResponse<MessageTemplateWithStatuses>> {
        return this.doServerXHR<MessageTemplate>({
            url: this.messageRoutes.withId(messageId).get(),
            method: "get",
        });
    }
    updateTemplateFavorite(
        messageId: string,
        val: boolean
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.messageRoutes.withId(messageId).updateFavorite(val),
            method: "put",
            data: {
                val: val,
            },
        });
    }
    create(template: MessageTemplate): Promise<AppResponse<MessageTemplate>> {
        const fD = this.constructFormData(template);
        return this.doServerXHR<MessageTemplate>({
            url: this.messageRoutes.create(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    getMessageTemplatesList(
        request: MailboxListRequest
    ): Promise<AppResponse<MessageTemplate[]>> {
        return this.doServerXHR<AppResponse<MessageTemplate[]>>({
            url: this.messageRoutes.list({ request }),
            method: "get",
        });
    }

    getMessageCount(): Promise<AppResponse<MessageCount>> {
        return this.doServerXHR<AppResponse<MessageCount>>({
            url: this.messageRoutes.messageCount(),
            method: "get",
        });
    }

    trash(messageId: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.messageRoutes.withId(messageId).trash(),
            method: "post",
        });
    }

    bulkTrash(ids: number[]): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.messageRoutes.bulkTrash(),
            method: "post",
            data: {
                ids: ids,
            },
        });
    }

    bulkDelete(ids: number[]): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.messageRoutes.bulkDelete(),
            method: "post",
            data: {
                ids: ids,
            },
        });
    }

    delete(messageId: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.messageRoutes.withId(messageId).delete(),
            method: "post",
        });
    }
}

export default MessageService;
