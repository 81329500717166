export enum LoginRegisterTabs {
    SESSION_TIME_OUT = "SessionTimeOut",
    LOGIN = "Login",
    REGISTER = "Register",
}

export enum FieldType {
    EMAIL = "email",
    PASSWORD = "password",
}

export enum ConfirmationPages {
    REGISTER_REQUEST_CONFIRMATION = "Register_Request_Confirmation",
    EMAIL_CONFIRMED = "Email_Confirmed",
    REGISTER_INFO_COMPLETED = "RegisterInfo_Completed",
    FORGOT_PASSWORD = "ForgotPassword_Confirmation",
    RESET_PASSWORD = "ResetPassword_Confirmation",
}

export enum HqRegisterRequestStatus {
    STATUS_PENDING = "Pending",
    STATUS_INPROGRESS = "InProgress",
    STATUS_COMPLETED = "Completed",
    STATUS_REJECTED = "Rejected",
}
