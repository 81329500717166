import { TWO_PRECISION_NUMERIC } from "globals/constants";
import { isNumber } from "globals/helpers/generalHelper";
import { defaults, defaultTo, isNil } from "lodash-es";
import { Optional } from "models/general/models";
import numeral from "numeral";
import { useLocaleHelpers } from "./localeHelpers";
import { useCallback, useEffect, useState } from "react";

export const useNumberInput = (
    onChangeValue: (value: number | null) => void,
    defaultValue: Optional<number>,
    options?: {
        format?: string;
        allowZero?: boolean;
        formatOnBlur?: boolean;
        formatTheInitialValue?: boolean;
    }
) => {
    options = defaults(options, {
        format: TWO_PRECISION_NUMERIC,
        formatOnBlur: true,
        allowZero: false,
        formatTheInitialValue: true,
    });
    const { formatNumber } = useLocaleHelpers();
    const [numState, setNumState] = useState(
        isNil(defaultValue) ? null : defaultValue
    ); // only to be used on locale change
    const [textState, setTextState] = useState(
        isNil(defaultValue) ? "" : numeral(defaultValue).format(options.format)
    );

    const toNum = (value: string) => {
        return isNumber(value) ? numeral(value).value() : null;
    };

    const onInputChange = useCallback(
        (value: string | null) => {
            value = defaultTo(value, "");
            value = value.trim();
            setTextState(value);

            const num = toNum(value);
            setNumState(num);
            onChangeValue(num);

            return num;
        },
        [onChangeValue]
    );

    const onBlur = useCallback(() => {
        let v = defaultTo(textState, "");

        if (options) {
            if (v.length > 0) {
                if (options.formatOnBlur) {
                    if (toNum(v) != 0 || options.allowZero) {
                        const num = numeral(v);
                        v = num.format(options.format);
                        // if there is some character then it will be removed here
                        setNumState(num.value());
                        onChangeValue(num.value());
                    } else {
                        v = "";
                    }

                    setTextState(v);
                }
            } else if (v === "0" && !options.allowZero) {
                return null; // don't show zero and also don't change value to zero
            }
        }

        return toNum(v);
    }, [options, textState]);

    useEffect(() => {
        if (options && options.formatTheInitialValue) {
            onBlur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTextState(
            formatNumber(
                numState,
                options && options.format ? options.format : undefined,
                false
            )
        );
    }, [formatNumber]);
    return {
        inputValue: textState,
        onInputChange,
        onBlur,
        value: toNum(textState),
    };
};

export default useNumberInput;
