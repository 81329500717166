import { EmployeeContractSortColumn } from "./enum";
import { EmployeeContract } from "./model";
import moment, { Moment } from "moment-timezone";
import { defaultTo } from "lodash-es";

export const EMPLOYEE_WEEKLY_HOURS = 39;

export const getEmployeeContractEmptyObject = (
    businessId: number,
    startDate: Moment,
    employeeId?: number
): EmployeeContract => {
    return {
        Id: undefined,
        MonthsTrialPeriod: 0,
        MonthsExpiration: 0,
        RealWeeklyHours: 0.0,
        RealGrossSalary: 0,
        HoursPerWeek: EMPLOYEE_WEEKLY_HOURS,
        GrossSalary: 0,
        Holidays: 0,
        WorkingDaysAWeek: 0,
        PauseInMinutes: 0,
        Chargeable: false,
        HourlySalaryNet: 0.0,
        HourlySalaryGross: 0.0,
        BusinessId: businessId,
        EmployeeId: defaultTo(employeeId, 0),
        ContractStart: startDate,
    } as EmployeeContract;
};
export const calculateGrossSalary = (
    contract: EmployeeContract
): EmployeeContract => {
    return {
        ...contract,
        GrossSalary:
            contract.RealWeeklyHours > 0
                ? (contract.RealGrossSalary / contract.RealWeeklyHours) *
                  contract.HoursPerWeek
                : 0,
    };
};
export const getEmployeeContractSortColumnKeyFromEnum = (
    sortColumn: EmployeeContractSortColumn | undefined
) => {
    switch (sortColumn) {
        case EmployeeContractSortColumn.ContractStart:
            return "ContractStart";
        case EmployeeContractSortColumn.ContractEnd:
            return "ContractEnd";
        case EmployeeContractSortColumn.MonthsTrialPeriod:
            return "MonthsTrialPeriod";
        case EmployeeContractSortColumn.EndOfTrialPeriod:
            return "EndOfTrialPeriod";
        case EmployeeContractSortColumn.MonthsExpiration:
            return "MonthsExpiration";
        case EmployeeContractSortColumn.ExpirationDate:
            return "ExpirationDate";
        case EmployeeContractSortColumn.RealWeeklyHours:
            return "RealWeeklyHours";
        case EmployeeContractSortColumn.WorkingDaysAWeek:
            return "WorkingDaysAWeek";
        case EmployeeContractSortColumn.PauseInMinutes:
            return "PauseInMinutes";

        default:
            return "ContractStart";
    }
};

export const getEmployeeContractCreateEdit = (
    req: EmployeeContract
): EmployeeContract => {
    return {
        ...req,
    } as EmployeeContract;
};

export const getCalculateEmployeeContractDates = (
    date: Moment,
    period: number
): Moment | null => {
    const startDate = date.add(-1, "d");
    if (period > 0) {
        return startDate.add(period, "M");
    }
    return null;
};
