import { AppFieldsWrapperContainer } from "components/Containers";
import { AppLoader } from "components/Loaders";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import {
    CompensationTemplateType,
    CompensationTemplateFieldType,
    CompensationTemplateSalaryGroupField,
} from "models";
import {
    DateRangeRequest,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import { v4 as uuid } from "uuid";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    LocationBusinessOptionsService,
    getLocationBusinessOptionsServiceKey,
} from "services";
import CompensationTemplateSalaryGroupFieldRow from "commonPartials/compensationTemplate/partials/createEditPartials/LWClientFields/CompensationTemplateSalaryGroupFieldRow";

export interface CompensationTemplateSalaryGroupFieldsContainerProps {
    fields: CompensationTemplateSalaryGroupField[];
    onChange: (newFields: CompensationTemplateSalaryGroupField[]) => void;
    readonly?: boolean;
    isForLocation: boolean;
    validityState?: ValidityState;
    templateDates: StartAndEndDates;
    templateId: number;
    templateType: CompensationTemplateType;
    locationSettingId?: number; // locationSettingId for location template, session location id for business template
    businessId?: string; // null for location templates
}

export const CompensationTemplateSalaryGroupFieldsContainer = ({
    fields,
    onChange,
    readonly = false,
    validityState,
    templateType,
    templateDates,
    businessId,
    isForLocation,
    templateId,
    locationSettingId,
}: CompensationTemplateSalaryGroupFieldsContainerProps) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const isForSalaryGroup =
        templateType == CompensationTemplateType.SalaryGroup;
    const businessIdToUse = isForLocation ? undefined : businessId;
    const optionsService = new LocationBusinessOptionsService(
        // for location templates set currBusiness to null so it only fetches option for location
        linkProvider.api.locationBusinessOptions(
            locationSettingId as number,
            businessIdToUse
        )
    );

    const {
        isFetching: loadingAgeGroups,
        refetch: refetchAgeGroups,
        data: ageGroups,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getAgeGroupOptions", {
            businessId: businessIdToUse,
            locationId: locationSettingId,
            start: templateDates?.StartDate,
            end: templateDates?.EndDate,
        }),
        async () => {
            return await optionsService.getAgeGroupOptions({
                StartDate: templateDates.StartDate,
                EndDate: templateDates.EndDate,
            } as DateRangeRequest);
        },
        {
            enabled: false,
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    const {
        isFetching: loadingCareScopes,
        refetch: refetchCareScopes,
        data: careScopes,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getCareScopeOptions", {
            businessId: businessIdToUse,
            locationId: locationSettingId,
            start: templateDates?.StartDate,
            end: templateDates?.EndDate,
        }),
        async () => {
            return await optionsService.getCareScopeOptions({
                StartDate: templateDates.StartDate,
                EndDate: templateDates.EndDate,
            } as DateRangeRequest);
        },
        {
            enabled: false,
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    const {
        isFetching: loadingSalaryGroups,
        refetch: refetchSalaryGroups,
        data: salaryGroups,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getSalaryGroupOptions", {
            businessId: businessIdToUse,
            locationId: locationSettingId,
            start: templateDates.StartDate,
            end: templateDates.EndDate,
        }),
        async () => {
            return await optionsService.getSalaryGroupOptions({
                StartDate: templateDates.StartDate,
                EndDate: templateDates.EndDate,
            } as DateRangeRequest);
        },
        {
            enabled: false,
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    useEffect(() => {
        if (
            !loadingAgeGroups &&
            ((isForSalaryGroup && !loadingCareScopes) ||
                (!isForSalaryGroup && !loadingSalaryGroups)) &&
            templateDates.StartDate
        ) {
            if (templateDates.StartDate) {
                refetchAgeGroups();
                refetchCareScopes();
                refetchSalaryGroups();
            }
        }
    }, [templateDates, templateType]);

    useEffect(() => {
        if (
            !loadingAgeGroups &&
            ageGroups &&
            !loadingCareScopes &&
            careScopes &&
            !loadingSalaryGroups &&
            salaryGroups
        ) {
            // update existing fields according to the current options

            const newFields = [...fields];

            ageGroups.map((ag) => {
                careScopes.map((cS) => {
                    salaryGroups.map((sG) => {
                        const uuidMatched = newFields.some(
                            (f) =>
                                f.AgeGroupFieldUuid == ag.Uuid &&
                                f.CareScopeFieldUuid == cS.Uuid &&
                                f.SalaryGroupFieldUuid == sG.Uuid
                        );
                        if (!uuidMatched) {
                            // UUID not matched, so need to add new field with currently active age-group/care-scopes

                            let fieldExist:
                                | CompensationTemplateSalaryGroupField
                                | undefined;
                            // if field exist with matching short-names, then copy its value for new field
                            const matchingFields = newFields.filter(
                                (f) =>
                                    f.AgeGroupShortName == ag.ShortName &&
                                    f.SalaryGroupShortName == sG.ShortName &&
                                    f.CareScopeShortName == cS.ShortName
                            );

                            if (matchingFields.length > 0) {
                                fieldExist = matchingFields.at(
                                    matchingFields.length - 1
                                );
                            }

                            // add field if does not exist
                            newFields.push({
                                Uuid: uuid(),
                                Type: CompensationTemplateFieldType.SalaryGroup,
                                AgeGroupFieldUuid: ag.Uuid,
                                CompensationTemplateId: templateId,
                                CareScopeFieldUuid: cS.Uuid,
                                SalaryGroupFieldUuid: sG.Uuid,
                                Order: newFields.length + 1,
                                FieldValue: {
                                    Value:
                                        fieldExist && fieldExist.FieldValue
                                            ? fieldExist.FieldValue.Value
                                            : 0,
                                },
                            } as CompensationTemplateSalaryGroupField);
                        }
                    });
                });
            });

            // remove fields for which options are no longer available
            const toRemove = newFields
                .filter((f) => {
                    const missingId =
                        isNil(f.AgeGroupFieldUuid) ||
                        isNil(f.CareScopeFieldUuid) ||
                        isNil(f.SalaryGroupFieldUuid);
                    return (
                        missingId ||
                        (ageGroups &&
                            !ageGroups.some(
                                (a) => a.Uuid == f.AgeGroupFieldUuid
                            )) ||
                        (careScopes &&
                            !careScopes.some(
                                (c) => c.Uuid == f.CareScopeFieldUuid
                            )) ||
                        (salaryGroups &&
                            !salaryGroups.some(
                                (s) => s.Uuid == f.SalaryGroupFieldUuid
                            ))
                    );
                })
                .map((f) => f.Uuid);

            onChange(newFields.filter((x) => !toRemove.includes(x.Uuid)));
        }
    }, [
        ageGroups,
        careScopes,
        salaryGroups,
        loadingAgeGroups,
        loadingCareScopes,
        loadingSalaryGroups,
    ]);

    const renderField = (
        field: CompensationTemplateSalaryGroupField,
        name: string
    ) => {
        const stateManager = new ValidityStateManager(validityState);

        return (
            <CompensationTemplateSalaryGroupFieldRow
                key={field.Uuid}
                validityState={stateManager.getFieldState(field.Uuid)?.children}
                readonly={readonly}
                value={field}
                displayFieldName={name}
                onChange={(field) => {
                    const oldIndex = fields.findIndex(
                        (f) => f.Uuid == field.Uuid
                    );
                    if (oldIndex >= 0) {
                        onChange(
                            fields.map((nf) => {
                                if (nf.Uuid == field.Uuid) {
                                    return field;
                                } else {
                                    return nf;
                                }
                            })
                        );
                    } else {
                        onChange([...fields, field]);
                    }
                }}
            />
        );
    };

    return loadingAgeGroups || loadingCareScopes || loadingSalaryGroups ? (
        <AppLoader />
    ) : (
        <>
            {fields && ageGroups && salaryGroups && careScopes ? (
                ageGroups.map((aG) => {
                    const filteredFields = fields.filter(
                        (f) => f.AgeGroupFieldUuid == aG.Uuid
                    );
                    return filteredFields.length > 0 ? (
                        <AppFieldsWrapperContainer
                            key={aG.Uuid}
                            title={t("compensationTemplate.ageGroupWithName", {
                                name: aG.ShortName,
                            })}
                            isCollapsible={true}
                        >
                            {careScopes.map((cS) => {
                                const filteredFields2 = filteredFields.filter(
                                    (f) => f.CareScopeFieldUuid == cS.Uuid
                                );
                                return filteredFields2.length > 0 ? (
                                    <AppFieldsWrapperContainer
                                        key={`${aG.Uuid}_${cS.Uuid}`}
                                        title={t(
                                            "compensationTemplate.careScope",
                                            {
                                                name: cS.ShortName,
                                            }
                                        )}
                                        isCollapsible={true}
                                    >
                                        <>
                                            {filteredFields2.some(
                                                (f) =>
                                                    !isNil(f.CareScopeFieldUuid)
                                            ) &&
                                                // <AppFieldsWrapperContainer
                                                //     key={`${aG.Uuid}_${cS.Uuid}_SalaryGroups`}
                                                //     title={t(
                                                //         "salaryGroupTemplate.title"
                                                //     )}
                                                //     isCollapsible={true}
                                                // >
                                                salaryGroups.map((sG) => {
                                                    const f =
                                                        filteredFields2.find(
                                                            (f) =>
                                                                f.SalaryGroupFieldUuid ==
                                                                sG.Uuid
                                                        );
                                                    return f ? (
                                                        renderField(
                                                            f,
                                                            sG.ShortName
                                                        )
                                                    ) : (
                                                        <></>
                                                    );
                                                })}
                                        </>
                                    </AppFieldsWrapperContainer>
                                ) : (
                                    <React.Fragment
                                        key={aG.Uuid}
                                    ></React.Fragment>
                                );
                            })}
                        </AppFieldsWrapperContainer>
                    ) : (
                        <></>
                    );
                })
            ) : (
                <></>
            )}
        </>
    );
};

export default CompensationTemplateSalaryGroupFieldsContainer;
