import { ISO8601_DATE_FORMAT } from "globals/constants";
import { toNumber } from "globals/helpers/localizationHelpers";
import moment from "moment";
import { Moment } from "moment-timezone";

export function stringDictionaryToMomentDictionary(personsDatesInfo: {
    [key in number]: string[];
}) {
    const momentDictionary: { [key in number]: Moment[] | null } = {};
    for (const key of Object.keys(personsDatesInfo)) {
        const numberKey = toNumber(key);
        momentDictionary[numberKey] = personsDatesInfo[numberKey]
            ? personsDatesInfo[numberKey].map((x) =>
                  moment(x, ISO8601_DATE_FORMAT)
              )
            : null;
    }
    return momentDictionary;
}
