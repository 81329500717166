import AppPopover from "components/AppPopover";
import { BaseEmoji, EmojiData, Picker, PickerProps } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { ImageAssets } from "globals/images";
import { defaultTo } from "lodash-es";
import React, { useCallback, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Placement } from "tippy.js";
import styles from "./EmojiPicker.module.scss";

export interface EmojiPickerProps extends PickerProps {
    value?: string;
    trigger?: any;
    className?: any;
    dismissOnSelect?: boolean;
    placement?: Placement;
    // one will be used at a time, if onChange is passed, value will be required
    onSelection?: (emoji: BaseEmoji) => void;
    onChange?: (updatedVal: string) => void;
}

const PickerComponent = Picker as any;

export const EmojiPicker = React.memo<EmojiPickerProps>((props) => {
    const { t } = useTranslation();
    const {
        trigger = (
            <Image className={styles.icon} src={ImageAssets.common.smiley} />
        ),
        className,
        dismissOnSelect = false,
        value,
        placement = "top",
        onSelection,
        onChange,
        ...rest
    } = props;

    const [showPicker, setPickerState] = useState<boolean | null>(null);
    const dismissPicker = useCallback(() => {
        setPickerState(false);
        setTimeout(() => {
            setPickerState(null);
        }, 500);
    }, [setPickerState]);

    const addEmoji = (emoji: EmojiData) => {
        if ("native" in emoji) {
            if (onChange && value != null) {
                onChange(`${defaultTo(value, "")}${emoji.native}`);
            } else if (onSelection) {
                onSelection(emoji);
            }
            if (dismissOnSelect) {
                dismissPicker();
            }
        }
    };
    return (
        <AppPopover
            className={`${className} ${styles.root}`}
            triggerElem={trigger}
            menuOpen={dismissOnSelect && showPicker}
            placement={placement}
        >
            <PickerComponent
                emoji=""
                title={t("emojiPicker.title")}
                native={true}
                onSelect={addEmoji}
                autoFocus={true}
                showPreview={true}
                emojiTooltip={true}
                i18n={t("emojiPicker", { returnObjects: true })}
                emojiSize={20}
                {...rest}
            />
        </AppPopover>
    );
});

export default EmojiPicker;
