import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    AvatarInfo,
    getInitializedValidityState,
    PaginationInfo,
    SortOrder,
    Templatable,
    TemplateFile,
    UserFilterType,
} from "models/general";
import { MessageSortColumn } from "./enum";
import { MessageNavigation } from "./model";

export interface MailboxListRequest extends PaginationInfo {
    SearchTerm?: string;
    SortOrder: SortOrder;
    SortColumn: MessageSortColumn;
    ActiveMenu: MessageNavigation;
}

export interface MessageTemplate {
    Id: number;
    EncodedId?: string;
    SenderInfo: AvatarInfo;
    RecipientsInfo?: AvatarInfo;
    Body: Templatable;
    Title: string;
    ClientIds: number[] | null;
    EmployeeIds: number[] | null;
    GroupIds: number[] | null;
    ReceiptTypes: UserFilterType[] | null;
    Files: TemplateFile[];
    files?: any[]; // formData for IFormFile
    IsFavorite?: boolean;
    CreatedAt?: number;
    ForToDoId?: number;
}

export function validateMessageTemplate(
    val: MessageTemplate,
    state = getInitializedValidityState([], [])
) {
    if (!val.files || (val.files && val.files.length == 0)) {
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("message.body.missing"),
                },
            ],
            val.Body ? val.Body.Template : "",
            "body"
        );
    }

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("message.title.missing"),
            },
        ],
        val.Title,
        "title"
    );

    return state;
}
