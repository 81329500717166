import moment from "moment";

export const JS_LOCAL_DATE_TIME_PARSE_FORMAT = "M/D/YYYY HH:mm:ss";
export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";
export const ISO8601_TIME_FORMAT = "HH:mm:ss";

export const TIME_FORMAT_12H = "hh:mm A";
export const TIME_FORMAT_24H = "HH:mm";

// For data-import files
export const US_DATE_FORMAT = "MM/DD/YYYY";
export const US_SHORT_YEAR_DATE_FORMAT = "MM/DD/YY";
export const GERMAN_SHORT_YEAR_DATE_FORMAT = "DD.MM.YY";
export const Hyphenated_Date_Format = "YYYY-M-D";
export const Hyphenated_ShortDate_Format = "YYYY-MM-DD";

export const ENGLISH_DATE_FORMAT = "M/D/YYYY";
export const GERMAN_DATE_FORMAT = "DD.MM.YYYY";
export const BOSNIAN_DATE_FORMAT = "D. M. YYYY";
export const ITALIAN_DATE_FORMAT = "DD/MM/YYYY";
export const POLISH_DATE_FORMAT = "DD.MM.YYYY";
export const CROATIAN_DATE_FORMAT = "D.M.YYYY";
export const CZECH_DATE_FORMAT = "DD.MM.YYYY";
export const HUNGARIAN_DATE_FORMAT = "YYYY. MM. DD.";
export const SLOVAK_DATE_FORMAT = "D. M. YYYY";
export const SLOVENIAN_DATE_FORMAT = "D. MM. YYYY";
export const FRENCH_DATE_FORMAT = "DD/MM/YYYY";
export const SPANISH_DATE_FORMAT = "DD/MM/YYYY";
export const DUTCH_DATE_FORMAT = "D-M-YYYY";
export const ESTONIAN_DATE_FORMAT = "DD.MM.YYYY";
export const FINNISH_DATE_FORMAT = "D.M.YYYY";
export const GREEK_DATE_FORMAT = "D/M/YYYY";

export const DAY_MONTH_SHORT_ENGLISH_FORMAT = "M/D";
export const DAY_MONTH_SHORT_GERMAN_FORMAT = "DD.MM";
export const DAY_MONTH_SHORT_BOSNIAN_FORMAT = "D.M";
export const DAY_MONTH_SHORT_ITALIAN_FORMAT = "DD/MM";
export const DAY_MONTH_SHORT_POLISH_FORMAT = "DD.MM";
export const DAY_MONTH_SHORT_CROATIAN_FORMAT = "D.M";
export const DAY_MONTH_SHORT_CZECH_FORMAT = "DD.MM";
export const DAY_MONTH_SHORT_HUNGARIAN_FORMAT = "MM.DD";
export const DAY_MONTH_SHORT_SLOVAK_FORMAT = "D. M";
export const DAY_MONTH_SHORT_SLOVENIAN_FORMAT = "D. MM";
export const DAY_MONTH_SHORT_FRENCH_FORMAT = "DD/MM";
export const DAY_MONTH_SHORT_SPANISH_FORMAT = "DD/MM";
export const DAY_MONTH_SHORT_DUTCH_FORMAT = "D-M";
export const DAY_MONTH_SHORT_ESTONIAN_FORMAT = "DD.MM";
export const DAY_MONTH_SHORT_FINNISH_FORMAT = "D.M";
export const DAY_MONTH_SHORT_GREEK_FORMAT = "D/M";

export const FULL_DATE_TIME_FORMAT = `${ISO8601_DATE_FORMAT} ${ISO8601_TIME_FORMAT}`;

export const LONG_DATE_TIME_FORMAT = "Do, MMMM YYYY, hh:mm A";
export const LONG_DATE_TIME_GERMAN_FORMAT = "Do MMMM YYYY, HH:mm";

export const FULL_DATE = "DD MMMM YYYY";
export const YEAR_MONTH_DATE_FORMAT = "MMMM YYYY";
export const FULL_DATE_RANGE_FORMAT = "DD MMM YYYY";
export const YEAR_MONTH_RANGE_DATE_FORMAT = "DD MMM YYYY";

export const DATEPICKER_MONTH_YEAR_MOMENT_FORMAT = "YYYY, MM";
export const DATEPICKER_DATE_MOMENT_FORMAT = "M/D/YYYY";
export const MONTH_DATE_MOMENT_FORMAT = "MMMM DD";

export const DATE_TIME = "M/D/YYYY H:mm";

export const TWO_PRECISION_NUMERIC = "0,0.00";
export const OPTIONAL_TWO_PRECISION_NUMERIC = "0,0.[00]";
export const FOUR_PRECISION_NUMERIC = "0,0.0000";
export const FORMAT_INTEGER = "0,0";
export const FORMAT_YEAR = "0";

export const SEARCH_DELAY_TIME = 1000;

export const MAX_CHAT_MESSAGE_LENGTH = 30000; // allowed body size is 32KB
export const TWILIO_USER_NO_LONGER_EXIST_ERROR_CODE = 50400;
export const TWILIO_MESSAGE_TOO_LONG = 50504;
export const TWILIO_USER_UNAUTHORIZED_ERROR_CODE = 50107;

export const LONG_DATA_CACHE_MINUTES = 1200;

export const COOKIE_EXPIRY_DATE = moment().add(30, "days").toDate();
export const COOKIE_SHORT_EXPIRY = () => moment().add(30, "seconds").toDate();
