export enum DatePickerType {
    TIME,
    DATE_TIME,
    DATE,
    MONTH,
    SHORT_MONTH,
    DATE_MONTH,
    MONTH_YEAR,
    MONTH_YEAR_SHORT,
    YEAR,
}
