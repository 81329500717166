import React from "react";
import { Spinner } from "react-bootstrap";
import styles from "./AppLoader.module.scss";

export interface AppLoaderProps {
    message?: string;
    iconClass?: string;
    className?: string;
    hasError?: boolean;
    fullHeight?: boolean;
    useLargeLoader?: boolean;
    height?: number;
    width?: number;
}

export const AppLoader: React.FC<AppLoaderProps> = ({
    hasError = false,
    message = "Unexpected error occurred...",
    fullHeight = true,
    useLargeLoader = false,
    iconClass = "",
    className = "",
    height,
    width,
}) => {
    const inlineStyles: any = {
        height: height ? `${height}px !important` : "auto",
    };
    if (height) {
        inlineStyles.minHeight = `${height}px`;
    }
    if (width) {
        inlineStyles.width = `${width}px`;
    }

    return (
        <div
            className={`${styles.progressSpinner} ${className} ${
                fullHeight ? styles.fullHeight : ""
            }`}
            style={inlineStyles}
        >
            {!hasError && (
                <Spinner
                    className={`${styles.spinnerIcon} ${iconClass} ${
                        fullHeight || useLargeLoader ? styles.large : ""
                    }`}
                    animation="border"
                />
            )}

            {hasError && (
                <p className={styles.apiErrorMessage}>{message.trim()}</p>
            )}
        </div>
    );
};

export default AppLoader;
