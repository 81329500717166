import { AppTableSortColumnIcons } from "components/Table";
import { t } from "i18next";
import { AbsentsTableSortColumn } from "models/businessAbsents/enum";
import { SortOrder, TableSort } from "models/general";
import React from "react";

export interface AbsentsTableHeaderRowProps {
    value: TableSort<AbsentsTableSortColumn>;
    onChange: (key: AbsentsTableSortColumn, order: SortOrder) => void;
}

export const AbsentsTableHeaderRow: React.FC<AbsentsTableHeaderRowProps> = ({
    value,
    onChange,
}) => {
    return (
        <tr>
            <AppTableSortColumnIcons
                width={100}
                text={t("business.absents.table.id")}
                sortColumn={AbsentsTableSortColumn.DisplayId}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.DisplayId
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={250}
                text={t("business.absents.table.employeeName")}
                sortColumn={AbsentsTableSortColumn.Name}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.Name
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={200}
                text={t("business.absents.table.workingDays")}
                sortColumn={AbsentsTableSortColumn.TotalWorkingDays}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalWorkingDays
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={150}
                text={t("business.absents.table.schoolDays")}
                sortColumn={AbsentsTableSortColumn.TotalSchoolDays}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalSchoolDays
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={150}
                text={t("business.absents.table.vacations")}
                sortColumn={AbsentsTableSortColumn.TotalVacationDays}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalVacationDays
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={170}
                text={t("business.absents.table.sickDays")}
                sortColumn={AbsentsTableSortColumn.TotalSickDays}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalSickDays
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={240}
                text={t("business.absents.table.absenceWithoutReason")}
                sortColumn={AbsentsTableSortColumn.TotalUndeclaredAbsents}
                sortOrder={
                    value.SortColumn ==
                    AbsentsTableSortColumn.TotalUndeclaredAbsents
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={180}
                text={t("business.absents.table.attendances")}
                sortColumn={AbsentsTableSortColumn.TotalAttendances}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalAttendances
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={300}
                text={t("business.absents.table.absencesWithoutVacations")}
                sortColumn={AbsentsTableSortColumn.AbsencesWithoutVacations}
                sortOrder={
                    value.SortColumn ==
                    AbsentsTableSortColumn.AbsencesWithoutVacations
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={170}
                text={t("business.absents.table.absences")}
                sortColumn={AbsentsTableSortColumn.TotalAbsences}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.TotalAbsences
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                width={200}
                text={t("business.absents.table.absencePercentage")}
                sortColumn={AbsentsTableSortColumn.AbsencePercentage}
                sortOrder={
                    value.SortColumn == AbsentsTableSortColumn.AbsencePercentage
                        ? value.SortOrder
                        : null
                }
                onClick={onChange}
            />
        </tr>
    );
};

export default AbsentsTableHeaderRow;
