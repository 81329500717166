import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppTooltipError, ErrorMessage, ValidationTooltip } from "components";
import ImageAssets from "globals/images/common";
import { defaultTo } from "lodash-es";
import { FieldType } from "models";
import { ErrorInfo } from "models/general/validityState";
import React, { useState } from "react";
import {
    Form,
    FormControl,
    FormControlProps,
    Image,
    InputGroup,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./AuthInputField.module.scss";

export interface AuthInputFieldProps extends Omit<FormControlProps, "onBlur"> {
    onValueChange: (value: string) => void;
    onBlur?: (value: string) => void;
    onKeyDown?: (e: any) => void;
    placeholder?: string;
    iconPath?: string;
    isCssIcon?: boolean;
    showHelp?: boolean;
    useBubbleError?: boolean;
    disableAutoComplete?: boolean;
    type?: string;
    fieldClassName?: string;
    error?: ErrorInfo;
    label?: string;
    iconEndClassName?: string;
}
export const AuthInputField = React.memo<AuthInputFieldProps>((props) => {
    const {
        value,
        onValueChange,
        onBlur,
        onKeyDown,
        className = "",
        placeholder,
        iconPath,
        isCssIcon = false,
        showHelp = false,
        disableAutoComplete = false,
        useBubbleError = false,
        type = "text",
        fieldClassName,
        error,
        label,
        iconEndClassName,
        ...rest
    } = props;

    const [passVisibililty, setPassVisibility] = useState(false);

    return (
        <>
            <Form.Group
                className={`${className} ${styles.inputContainer}`}
                controlId={uuid()}
            >
                {label && <Form.Label>{label}</Form.Label>}
                <InputGroup>
                    <FormControl
                        type={
                            type === "password" && !passVisibililty
                                ? "password"
                                : "text"
                        }
                        value={defaultTo(value, "")}
                        onChange={(e) => {
                            onValueChange(e.target.value);
                        }}
                        onBlur={(e: any) => {
                            if (onBlur) {
                                onBlur(e.target.value);
                            }
                        }}
                        autoComplete={
                            disableAutoComplete ? "new-password" : undefined
                        }
                        placeholder={placeholder}
                        className={`${defaultTo(fieldClassName, "")} ${
                            styles.field
                        } ${
                            type === "password" || type === "email"
                                ? styles.passwordField
                                : styles.textField
                        }`}
                        onKeyDown={onKeyDown}
                        {...rest}
                    />
                    {iconPath && (
                        <div className={styles.iconStart}>
                            {!isCssIcon && <Image src={iconPath} />}
                            {isCssIcon && (
                                <FontAwesomeIcon icon={iconPath as IconProp} />
                            )}
                        </div>
                    )}
                    {type === "password" && (
                        <div
                            className={`${defaultTo(iconEndClassName, "")} ${
                                styles.iconEnd
                            }`}
                        >
                            <Image
                                src={
                                    passVisibililty
                                        ? ImageAssets.common.eyeCross
                                        : ImageAssets.common.eye
                                }
                                onClick={() =>
                                    setPassVisibility(!passVisibililty)
                                }
                            ></Image>
                        </div>
                    )}
                    {type === "email" && error && (
                        <div
                            className={styles.iconEnd}
                            style={{ cursor: "default" }}
                        >
                            <Image src={ImageAssets.common.crossRed}></Image>
                        </div>
                    )}
                    <div className={`${styles.tooltipContainer}`}>
                        {showHelp && (
                            <div className={styles.tooltip}>
                                <ValidationTooltip
                                    type={
                                        type === "password"
                                            ? FieldType.PASSWORD
                                            : FieldType.EMAIL
                                    }
                                />
                            </div>
                        )}
                        {useBubbleError === true && error && (
                            <div className={styles.tooltip}>
                                <AppTooltipError
                                    trigger="mouseenter focus"
                                    arrow={true}
                                    error={error}
                                >
                                    <Image
                                        src={
                                            ImageAssets.common.exclamationCircle
                                        }
                                    />
                                </AppTooltipError>
                            </div>
                        )}
                    </div>
                </InputGroup>
                {!defaultTo(useBubbleError, false) && (
                    <div className={styles.errorMessageDiv}>
                        {error && <ErrorMessage errorInfo={error} />}
                    </div>
                )}
            </Form.Group>
        </>
    );
});

export default AuthInputField;
