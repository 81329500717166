import { defaultTo } from "lodash-es";
import React, { ReactNode } from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import styles from "./AppCustomHeaderTable.module.scss";

export interface AppCustomHeaderTableProps {
    header: ReactNode;
    content: ReactNode;
    className?: string;
    contentClass?: string;
    useMaxHeight?: boolean;
    heightToAdjust?: number;
    mobileViewAdjustment?: number;
    mediumViewAdjustment?: number;
}

export const AppCustomHeaderTable: React.FC<AppCustomHeaderTableProps> = ({
    header,
    content,
    className = "",
    contentClass = "",
    useMaxHeight = true,
    heightToAdjust = 270,
    mobileViewAdjustment = 15,
    mediumViewAdjustment = 15,
}) => {
    const { width, ref } = useResizeDetector();
    return (
        <div ref={ref} className={`${styles.root} ${className}`}>
            <div>{header}</div>
            <div
                className={`${styles.content} ${contentClass}`}
                style={
                    useMaxHeight
                        ? {
                              maxHeight: `calc(100vh - ${
                                  defaultTo(width, 860) >= 860
                                      ? heightToAdjust
                                      : defaultTo(width, 450) > 450
                                      ? heightToAdjust + mediumViewAdjustment
                                      : heightToAdjust + mobileViewAdjustment
                              }px)`,
                          }
                        : undefined
                }
            >
                {content}
            </div>
        </div>
    );
};

export default AppCustomHeaderTable;
