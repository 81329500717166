import { AppColoredMultiSelect, AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import ErrorMessage from "components/ErrorMessage";
import { AppSwitch } from "components/FormFields";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import {
    CopyLWCDocumentTemplatesRequest,
    DocumentTemplateTabType,
} from "models/documentTemplates";
import {
    AppResponse,
    CustomColorItemOption,
    getInitializedValidityState,
    SelectItem,
    ValidityStateManager,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    DocumentTemplateService,
    getDocumentTemplateServiceKey,
} from "services/business";
import {
    getLocationSettingsServiceKey,
    LocationDocumentTemplateService,
    LocationSettingsService,
} from "services/lillywait";

interface CloneLWCDocumentTemplatesDialogProps {
    onClose: () => void;
    onChange: () => void;
}
export const CloneLWCDocumentTemplatesDialog: React.FC<
    CloneLWCDocumentTemplatesDialogProps
> = ({ onClose, onChange }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] = useState<CopyLWCDocumentTemplatesRequest>({
        BusinessTemplateIds: [],
        CopyLetterhead: true,
    });

    const locationSettingsService = new LocationSettingsService(
        linkProvider.lillywait.locationSettings().api
    );
    const documentTemplatesService = new LocationDocumentTemplateService(
        linkProvider.lillywait.locationSettings().api.withId().documentTemplates
    );
    const { isFetching: fetchingSources, data: sources } = useQuery(
        getLocationSettingsServiceKey("getLocationClients"),
        async () => await locationSettingsService.getLocationClients(),
        {
            enabled: true,
            cacheTime: getMillisecondsForMinutes(5),
            select: (resp) => {
                return resp.Data
                    ? resp.Data.map((x) => {
                          return { value: x.Id, label: x.Name } as SimpleOption;
                      })
                    : [];
            },
        }
    );
    const { isFetching: fetchingTemplates, data: templates } = useQuery(
        getDocumentTemplateServiceKey(
            "getBusinessOrLocationTemplateList",
            state.BusinessId
        ),
        async () => {
            const service = new DocumentTemplateService(
                linkProvider.business.api.currentBusiness(
                    defaultTo(state.BusinessId, 0).toString()
                ).documentTemplate
            );
            return await service.getBusinessOrLocationTemplateList({
                DocumentTabType: DocumentTemplateTabType.OWN_DOCUMENTS,
            });
        },
        {
            enabled: !isNil(state.BusinessId),
            cacheTime: getMillisecondsForMinutes(5),
            select: (resp) => {
                return resp.Data && resp.Data.DocumentTemplates
                    ? resp.Data.DocumentTemplates.map((x) => {
                          return {
                              value: x.Id,
                              label: x.Name,
                          } as CustomColorItemOption;
                      })
                    : [];
            },
        }
    );
    const { isFetching: fetchingLetterheads, data: letterheads } = useQuery(
        getLocationSettingsServiceKey("getLetterheadOptions"),
        async () => await locationSettingsService.getLetterheadOptions(),
        {
            enabled: true,
            cacheTime: getMillisecondsForMinutes(5),
            select: (resp: AppResponse<SelectItem[]> | boolean) => {
                return typeof resp != "boolean" && resp.Data
                    ? resp.Data.map((x) => {
                          return {
                              value: x.Value,
                              label: x.Text,
                          } as SimpleOption;
                      })
                    : [];
            },
        }
    );
    const {
        isLoading: copyingTemplates,
        mutate: copyTemplates,
        data: copyResp,
        error: copyError,
    } = useMutation(async (req: CopyLWCDocumentTemplatesRequest) => {
        return await documentTemplatesService.copyLWCTemplates(req);
    });

    const processMutationResponse = (
        response?: AppResponse<boolean>,
        error?: any
    ) => {
        if (response) {
            if (response.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    t(`documentTemplate.documentsCopyDialog.templatesCopied`),
                    "success"
                );
                onChange();
            } else if (response.Code != null) {
                if (response.Errors && response.Errors.length > 0) {
                    showSweetAlertToast(
                        t("common.error.error"),
                        response.Errors[0].Message,
                        "error"
                    );
                }
            } else {
                showUnexpectedErrorToast();
            }
        } else if (error) {
            showUnexpectedErrorToast();
        }
    };
    useEffect(() => {
        if (!copyingTemplates) {
            processMutationResponse(copyResp, copyError);
        }
    }, [copyResp, copyingTemplates]);

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;
    const validityStateManager = useMemo(() => {
        const rules: ValidationRules<keyof CopyLWCDocumentTemplatesRequest> = {
            BusinessId: [
                {
                    rule: Validations.REQUIRED,
                    message: t(
                        `documentTemplate.documentsCopyDialog.validations.sourceCompany`
                    ),
                },
            ],
            BusinessTemplateIds: [
                {
                    rule: Validations.REQUIRED,
                    message: t(
                        `documentTemplate.documentsCopyDialog.validations.templateIds`
                    ),
                },
            ],
        };

        const validationState = getInitializedValidityStateFromRules(
            rules,
            state,
            getInitializedValidityState([], [])
        );
        return new ValidityStateManager(validationState);
    }, [state]);

    return (
        <AppDialog
            title={t(`documentTemplate.documentsCopyDialog.copyLWCDocuments`)}
            onClose={() => onClose()}
            modalOpen={true}
        >
            <Row>
                <Col sm={8}>
                    <Form.Group>
                        <Form.Label>
                            {t("documentTemplate.documentsCopyDialog.facility")}
                        </Form.Label>
                        <AppSelectOld
                            options={[
                                pleaseSelectOption,
                                ...defaultTo(sources, []),
                            ]}
                            value={
                                state.BusinessId
                                    ? defaultTo(sources, []).filter(
                                          (x) => x.value == state.BusinessId
                                      )
                                    : pleaseSelectOption
                            }
                            isLoading={fetchingSources}
                            onChange={(opt: SimpleOption) => {
                                setState({
                                    ...state,
                                    BusinessId: opt.value,
                                });
                            }}
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "BusinessId"
                            )}
                        />
                    </Form.Group>
                </Col>
                {state.BusinessId && (
                    <>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>
                                    {t(
                                        "documentTemplate.documentsCopyDialog.documentTemplates"
                                    )}
                                </Form.Label>
                                <AppColoredMultiSelect
                                    data={defaultTo(templates, [])}
                                    loading={fetchingTemplates}
                                    hasNoneValue={true}
                                    onChange={(value) =>
                                        setState({
                                            ...state,
                                            BusinessTemplateIds: value.map(
                                                (x) => x.value
                                            ) as number[],
                                        })
                                    }
                                    value={defaultTo(templates, []).filter(
                                        (x) =>
                                            state.BusinessTemplateIds.includes(
                                                x.value as number
                                            )
                                    )}
                                    useOldUi={true}
                                />
                                <ErrorMessage
                                    showEmpty={true}
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "BusinessTemplateIds"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={5}>
                            <AppSwitch
                                label={t(
                                    "documentTemplate.documentsCopyDialog.cloneLetterhead"
                                )}
                                value={state.CopyLetterhead}
                                style={{ height: "80px", alignItems: "center" }}
                                labelPosition="right"
                                onChange={(checked: boolean) => {
                                    setState({
                                        ...state,
                                        CopyLetterhead: checked,
                                    });
                                }}
                            />
                        </Col>
                        {!state.CopyLetterhead && (
                            <Col sm={7}>
                                <Form.Group>
                                    <Form.Label>
                                        {t(
                                            "documentTemplate.documentsCopyDialog.letterheads"
                                        )}
                                    </Form.Label>
                                    <AppSelectOld
                                        options={[
                                            pleaseSelectOption,
                                            ...defaultTo(letterheads, []),
                                        ]}
                                        value={
                                            state.LetterheadId
                                                ? defaultTo(
                                                      letterheads,
                                                      []
                                                  ).filter(
                                                      (x) =>
                                                          x.value ==
                                                          state.LetterheadId
                                                  )
                                                : pleaseSelectOption
                                        }
                                        isLoading={fetchingLetterheads}
                                        onChange={(opt: SimpleOption) => {
                                            setState({
                                                ...state,
                                                LetterheadId: opt.value,
                                            });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        )}
                    </>
                )}

                <Col xs={12}>
                    <AppDialogFooter
                        onDialogClose={onClose}
                        onClickSaveButton={() => copyTemplates(state)}
                        disableSaveButton={
                            !validityStateManager.isStateValid() ||
                            copyingTemplates
                        }
                    />
                </Col>
            </Row>
        </AppDialog>
    );
};

export default CloneLWCDocumentTemplatesDialog;
