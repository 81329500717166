import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    Business_Shifts,
    LW_Industry_Shifts,
    LW_Kindergarten_Shifts,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { parseBusinessShiftModelResponse } from "models/businessShifts/helper";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import {
    BusinessShiftService,
    getBusinessShiftServiceKey,
} from "services/business";
import { BusinessShiftCreateEdit } from "./partials";
import { BusinessType } from "models";

export const BusinessShiftEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { id, lwcId } = useParams();
    const { checkPermission } = useCheckPermission();

    // both(useCurrentBusinessSettingsContext and useCurrentLwcContext)
    // return same context as context type is same
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();

    const shiftService = new BusinessShiftService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).shift
    );

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: res,
    } = useQuery(
        getBusinessShiftServiceKey("get", {
            businessId: currentBusiness?.Id,
            id: id,
        }),
        async () => {
            if (id) {
                return await shiftService.get(id);
            }
        },
        { cacheTime: 0 } // disable caching
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && res) {
            if (res.Errors) {
                showErrorPage(404);
            }
        }
    }, [getLoading, getRefetching]);

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = isLwc
        ? (currentBusiness?.Type == BusinessType.KINDERGARTEN
              ? lwcBase.kindergartens
              : lwcBase.industries
          ).withId()
        : linkProvider.business.businessSettings().screens;

    const hasEditPermission = checkPermission(
        [
            isLwc
                ? currentBusiness?.Type == BusinessType.KINDERGARTEN
                    ? LW_Kindergarten_Shifts
                    : LW_Industry_Shifts
                : Business_Shifts,
        ],
        [PermissionAccessTypes.EDIT]
    );

    return (
        <div style={{ position: "relative" }}>
            {getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <BusinessShiftCreateEdit
                    onBack={() => navigate(linkProviderBase.shifts.list())}
                    value={
                        res?.Data
                            ? parseBusinessShiftModelResponse(res?.Data)
                            : undefined
                    }
                    readonly={!hasEditPermission}
                />
            )}
        </div>
    );
};

export default BusinessShiftEdit;
