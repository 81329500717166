export enum ClientRegistrationCustodianType {
    Father = "Father",
    Mother = "Mother",
}
export enum ClientRegistrationStatus {
    Active = "Active",
    InActive = "InActive",
}
export enum DataSourceTypes {
    LwDashboard = "LwDashboard",
    Excel = "Excel",
    External = "External",
}
export enum ClientRegistrationProgressStatus {
    Pending = "Pending",
    InProgress = "InProgress",
    InviteSent = "InviteSent",
    ContractSent = "ContractSent",
    Declined = "Declined",
}
export enum ClientRegistrationSortColumn {
    Id,
    Name,
    Gender,
    Year,
    CareStart,
    AgeGroup,
    CareScope,
    Status,
    IsSibling,
    Birthday,
    ArrangedPlace,
    CreatedAt,
    ProgressStatus,
    Source
}
