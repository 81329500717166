import { CustomColorItemOption } from "models/general";
import moment from "moment";
import { DocumentTemplateManagerItem } from "./model";
import { DocumentTemplateItemResponse } from "./response";

export const parseDocumentTemplateResponse = (
    val: DocumentTemplateItemResponse[],
    selectList: CustomColorItemOption[]
): DocumentTemplateManagerItem[] => {
    return val.map((i) => {
        return {
            CreatedAt: moment.unix(i.CreatedAt),
            Id: i.Id,
            EncodedId: i.EncodedId,
            DisplayId: i.DisplayId,
            Name: i.Name,
            IsEditable: i.IsEditable,
            Types: selectList.filter(
                (x) => i.Types.filter((y) => y === x.value).length != 0
            ),
        };
    });
};
