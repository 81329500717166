import {
    applyValidations,
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { defaultTo } from "lodash-es";
import { DurationPeriodTypeNumber } from "./enum";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "./validityState";
import { Address } from "./models";

export function validateAddressFields(
    address: Address,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof Address> = {
        City: [
            {
                rule: Validations.REQUIRED,
            },
        ],
        State: [
            {
                rule: Validations.REQUIRED,
            },
        ],
        PostCode: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };
    state = getInitializedValidityStateFromRules(rules, address, state);
    return state;
}
