import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { AppResponse } from "models/general";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const useDeleteRow = () => {
    const { t } = useTranslation();
    const [deleteInfo, setDeleteInfo] = useState<
        { id: string | number; displayName?: string } | undefined
    >(undefined);

    const handleResponse = useCallback(
        (
            onDeleteSuccess: (id: number) => void,
            deleteResponse?: AppResponse<number>,
            deleteError?: any
        ) => {
            if (deleteInfo) {
                const errorToast = () =>
                    showSweetAlertToast(
                        t("common.error.error"),
                        `"${deleteInfo.displayName}" ${t(
                            "common.actions.errors.unableToDelete"
                        )}`,
                        "error"
                    );
                if (deleteResponse) {
                    if (deleteResponse.Data) {
                        showSweetAlertToast(
                            t("common.success"),
                            `"${deleteInfo.displayName}" ${t(
                                "common.actions.deletedSuccessfully"
                            )}`,
                            "success"
                        );
                        onDeleteSuccess(deleteResponse.Data as number);
                        setDeleteInfo(undefined);
                    } else {
                        errorToast();
                    }
                } else if (deleteError) {
                    errorToast();
                }
            }
        },
        [deleteInfo, t]
    );

    return { deleteInfo, setDeleteInfo, handleResponse };
};

export default useDeleteRow;
