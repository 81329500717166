import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { Business_Employee_BankData } from "models/permissionManagement";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { BankDataService, getBankDataServiceKey } from "services/business";
import { BankDataCreateEdit } from "commonPartials";

import { isNil } from "lodash-es";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";

export const EmployeeBankDataEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { EmployeeId, id } = useParams();

    const { dataItem: employee } = useActiveBusinessEmployee();
    const { getPermissionMap } = useCheckPermission();
    const permMap = getPermissionMap(Business_Employee_BankData);

    const linkProviderBase = linkProvider.business
        .employees()
        .screens.withId().bankData;

    const service = new BankDataService(linkProvider.business.api.bankDatas);

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: res,
    } = useQuery(
        getBankDataServiceKey("get", {
            EmployeeId: EmployeeId,
            id: id,
        }),
        async () => {
            if (id) {
                return await service.get(id);
            }
        }
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && res) {
            if (res.Errors) {
                showErrorPage(404);
            }
        }
    }, [getLoading, getRefetching]);
    const hasEditPermission = permMap.EDIT;

    return (
        <div style={{ position: "relative" }}>
            {isNil(employee) || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <BankDataCreateEdit
                    onBack={() => navigate(linkProviderBase.list())}
                    value={res?.Data}
                    request={{ EmployeeId: employee.Id }}
                    readonly={!hasEditPermission}
                />
            )}
        </div>
    );
};

export default EmployeeBankDataEdit;
