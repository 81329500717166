import { ISO8601_DATE_FORMAT } from "globals/constants";
import { BaseObject, DateRangeRequest, Gender } from "models/general";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import { Moment } from "moment-timezone";
import { KindergartenClientStatus } from "./enum";
import { KindergartenClient } from "./model";

export interface KindergartenClientRequest extends BaseObject {
    Id?: number;
    DisplayId?: number;
    FirstName: string;
    LastName: string;
    Gender: Gender;
    CustomerNumber: string;
    ShorthandSymbol: string;
    AgeGroup: number;
    Status: KindergartenClientStatus;
    Notes: string;
    KindergartenId?: number;
    Birthday: string;
    CareBeginning: string;
    SchoolStart: string;
    Provision: boolean;
    EndOfCOntract: string;
}

export const getKindergartenClientRequest = (
    state: KindergartenClient
): KindergartenClientRequest => {
    return {
        ...state,
        Birthday: state.Birthday
            ? state.Birthday.format(ISO8601_DATE_FORMAT)
            : null,
        EndOfCOntract: state.EndOfCOntract
            ? state.EndOfCOntract.format(ISO8601_DATE_FORMAT)
            : null,
        SchoolStart: state.SchoolStart
            ? state.SchoolStart.format(ISO8601_DATE_FORMAT)
            : null,
        CareBeginning: state.CareBeginning
            ? state.CareBeginning.format(ISO8601_DATE_FORMAT)
            : null,
    } as KindergartenClientRequest;
};
export interface KindergartenClientContactDeleteRequest {
    contactId: number;
    updateToPrimary: boolean;
}
