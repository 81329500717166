import classNames from "classnames";
import { SearchField } from "components/FormFields";
import { ImageAssets } from "globals/images";
import { MessageCount, MessageNavigation } from "models/messages";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./MessagesNav.module.scss";

export interface MessagesNavProps {
    isCreate: boolean;
    activeMenu: MessageNavigation;
    messageCount: MessageCount;
    search?: string;
    setSearch: (search?: string) => void;
    onChange(Value: MessageNavigation): void;
}

export const MessagesNav: React.FC<MessagesNavProps> = ({
    isCreate,
    activeMenu,
    messageCount,
    search,
    setSearch,
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.MessageNav}`}>
            <ul className="nav flex-column">
                <li className={classNames("nav-item", styles.searchField)}>
                    <SearchField value={search} onValueChange={setSearch} />
                </li>
                <li
                    className={
                        activeMenu == MessageNavigation.Inbox
                            ? `${styles.navItem} ${styles.active}`
                            : `${styles.navItem}`
                    }
                >
                    <a
                        className={styles.navLink}
                        onClick={() => onChange(MessageNavigation.Inbox)}
                    >
                        <div className={styles.navLinkIcon}>
                            <Image
                                src={
                                    activeMenu == MessageNavigation.Inbox
                                        ? ImageAssets.common.inboxIn
                                        : ImageAssets.common.inboxInGray
                                }
                            />
                        </div>
                        <span>{t("message.inbox")}</span>
                        {messageCount.InboxMessages > 0 && (
                            <div className={`${styles.badge} ml-auto`}>
                                {messageCount.InboxMessages}
                            </div>
                        )}
                    </a>
                </li>
                <li
                    className={
                        !isCreate && activeMenu == MessageNavigation.Sent
                            ? `${styles.navItem} ${styles.active}`
                            : `${styles.navItem}`
                    }
                >
                    <a
                        className={styles.navLink}
                        onClick={() => onChange(MessageNavigation.Sent)}
                    >
                        <div className={styles.navLinkIcon}>
                            <Image
                                src={
                                    !isCreate &&
                                    activeMenu == MessageNavigation.Sent
                                        ? ImageAssets.common.sendBlue
                                        : ImageAssets.common.sendGray
                                }
                            />
                        </div>
                        <span>{t("message.sentMail")}</span>
                        {messageCount.SentMessages > 0 && (
                            <div className={`${styles.badge} ml-auto`}>
                                {messageCount.SentMessages}
                            </div>
                        )}
                    </a>
                </li>
                <li
                    className={
                        !isCreate && activeMenu == MessageNavigation.Favorite
                            ? `${styles.navItem} ${styles.active}`
                            : `${styles.navItem}`
                    }
                    style={{ marginBottom: "0px" }}
                >
                    <a
                        className={styles.navLink}
                        onClick={() => onChange(MessageNavigation.Favorite)}
                    >
                        <div className={styles.navLinkIcon}>
                            <Image
                                src={
                                    !isCreate &&
                                    activeMenu == MessageNavigation.Favorite
                                        ? ImageAssets.common.starBlue
                                        : ImageAssets.common.star
                                }
                            />
                        </div>
                        <span>{t("message.starred")}</span>
                        {messageCount.FavMessages > 0 && (
                            <div className={`${styles.badge} ml-auto`}>
                                {messageCount.FavMessages}
                            </div>
                        )}
                    </a>
                </li>

                <li className="nav-item-divider nav-item mt-3 mb-3"></li>

                <li
                    className={
                        !isCreate && activeMenu == MessageNavigation.Trash
                            ? `${styles.navItem} ${styles.active}`
                            : `${styles.navItem}`
                    }
                >
                    <a
                        className={styles.navLink}
                        onClick={() => onChange(MessageNavigation.Trash)}
                    >
                        <div className={styles.navLinkIcon}>
                            <Image
                                src={
                                    !isCreate &&
                                    activeMenu == MessageNavigation.Trash
                                        ? ImageAssets.common.trashBlue
                                        : ImageAssets.common.trashGray
                                }
                            />
                        </div>
                        <span>{t("message.trash")}</span>
                        {messageCount.TrashMessages > 0 && (
                            <div className={`${styles.badge} ml-auto`}>
                                {messageCount.TrashMessages}
                            </div>
                        )}
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default MessagesNav;
