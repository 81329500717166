import { useRouting } from "hooks/general/routing";
import $ from "jquery";
import { AppErrorCodes, ERROR_CODE_PARAM_KEY } from "layouts/ErrorLayout";
import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useShowErrorPage = (replace: boolean = true) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const showErrorPage = useCallback(
        (errorCode: AppErrorCodes) => {
            setSearchParams(
                {
                    ...searchParams,
                    [ERROR_CODE_PARAM_KEY]: errorCode.toString(),
                },
                { replace: replace }
            );
        },
        [setSearchParams, searchParams]
    );

    return showErrorPage;
};
export const useOpenDocumentPreview = () => {
    const { linkProvider } = useRouting();
    const openDocumentPreviewTab = useCallback(
        (cacheKey: string, name?: string) => {
            const newWindow = (window as any).open(
                linkProvider.screens.documentPreview({
                    cacheKey: cacheKey,
                    name: name,
                }),
                "_blank"
            );
            if (newWindow) {
                newWindow.focus();
            }
        },
        [linkProvider]
    );

    return openDocumentPreviewTab;
};

export const useCollapsibleHelper = (
    collapseContainerRef: MutableRefObject<any>,
    initialCollapse = true // collapse= true (body open)
) => {
    const [collapse, setCollapse] = useState<boolean>(initialCollapse);
    const initialStyle = useRef<any>(
        !collapse ? { display: "none" } : undefined
    );

    const toggleCollapse = useCallback(() => {
        if (collapseContainerRef && collapseContainerRef.current) {
            if (collapse) {
                setCollapse(false);
                $(collapseContainerRef.current).slideUp(200);
            } else {
                setCollapse(true);
                $(collapseContainerRef.current).slideDown(300);
            }
        }
    }, [collapseContainerRef.current, collapse]);

    return { toggleCollapse, collapse, initialStyle, setCollapse };
};
