import { AppContentHeader } from "components";
import AppRoundedTextIconButton from "components/Buttons/AppRoundedTextIconButton";
import { SearchField } from "components/FormFields";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash";
import { HqRegisterRequestModel } from "models/auth";
import { ResponseMeta } from "models/general";
import {
    getHqRegisterRequestFromResponse,
    HqRegisterFilterRequest,
} from "models/registrationRequests";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import {
    getRegistrationRequestsServiceKey,
    LillywaitRegistrationRequestsService,
} from "services/lillywait/LillywaitRegistrationRequestsService";
import { HqRegisterRequestTable } from "./partials";

export interface HqRegisterRequestState {
    data: HqRegisterRequestModel[];
    meta: ResponseMeta;
    filter: HqRegisterFilterRequest;
}

const PAGE_SIZE = 25;
export const HqRegisterRequests: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const [state, setState] = useState<HqRegisterRequestState>({
        data: [],
        meta: {
            HasNextPage: false,
        },
        filter: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
        },
    });

    const registrationRequestsService =
        new LillywaitRegistrationRequestsService(
            linkProvider.lillywait.api.registerRequest
        );

    const { isFetching: loading } = useQuery(
        getRegistrationRequestsServiceKey("list", state.filter),
        async () => await registrationRequestsService.list(state.filter),
        {
            refetchOnMount: true,
            onSuccess: (resp) => {
                if (resp.Meta && resp.Data) {
                    const list = resp.Data.map((x) => {
                        return getHqRegisterRequestFromResponse(x);
                    });
                    setState({
                        ...state,
                        data:
                            resp.Meta.PageNumber == 1
                                ? list
                                : [...state.data, ...list],
                        meta: resp.Meta,
                    });
                }
            },
        }
    );

    const navigate = useNavigate();

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         if (list) {
    //             // to avoid execution on initial load
    //             refetchRegisterRequest(state.filter);
    //         }
    //     }, SEARCH_DELAY_TIME);

    //     return () => clearTimeout(delayDebounceFn);
    // }, [state.filter.Search]);

    // useEffect(() => {
    //     refetchRegisterRequest(state.filter);
    // }, [
    //     state.filter.PageNumber,
    //     state.filter.SortColumn,
    //     state.filter.SortOrder,
    // ]);

    return (
        <>
            <AppContentHeader
                title={t("hqRegisterRequestManagement.createClients")}
                classNameForIcon="pe-7s-plus"
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <SearchField
                        value={defaultTo(state.filter.Search, "")}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                filter: {
                                    ...state.filter,
                                    Search: value,
                                },
                            })
                        }
                    />
                    <AppRoundedTextIconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                            navigate(
                                linkProvider.lillywait.admin.registrationRequests.createHqWithoutRequest()
                            )
                        }
                    />
                </div>
            </AppContentHeader>
            <HqRegisterRequestTable
                value={state}
                loading={loading}
                onChange={(val) => setState(val)}
            />
        </>
    );
};

export default HqRegisterRequests;
