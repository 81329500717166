import {
    BusinessCompanyDataField,
    validateBusinessCompanyDataFields,
} from "models/companyDataField";
import { ValidityStateManager } from "models/general";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CompanyDataField from "./CompanyDataField";

export interface CompanyDataFieldContainerProps {
    value: BusinessCompanyDataField[];
    onChange: (newFields: BusinessCompanyDataField[]) => void;
    readonly?: boolean;
    isLoading: boolean;
}

export const CompanyDataContainerField: React.FC<
    CompanyDataFieldContainerProps
> = ({ value = [], onChange, readonly = false, isLoading }) => {
    const validityState = useMemo(() => {
        return new ValidityStateManager(
            validateBusinessCompanyDataFields(value)
        );
    }, [value]);

    return (
        <Row>
            {!isLoading
                ? value &&
                  value.map((f: BusinessCompanyDataField) => (
                      <CompanyDataField
                          key={f.Id}
                          value={f}
                          readonly={readonly}
                          validityState={
                              validityState.getFieldState(f.Id.toString())
                                  ?.children
                          }
                          onChange={(field) =>
                              onChange(
                                  value.map((nf) => {
                                      if (nf.Id == field.Id) {
                                          return field;
                                      } else {
                                          return nf;
                                      }
                                  })
                              )
                          }
                      />
                  ))
                : Array(3)
                      .fill(3)
                      .map((x, idx) => (
                          <Col md={4} key={idx}>
                              <Skeleton height={86.5} />
                          </Col>
                      ))}
        </Row>
    );
};

export default CompanyDataContainerField;
