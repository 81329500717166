import Tippy, { TippyProps } from "@tippyjs/react";
import React from "react";

export interface AppTooltipProps extends TippyProps {
    className?: string;
    arrow?: boolean;
    allowHtml?: boolean;
    trigger?: string;
}

export const AppTooltip = React.memo<AppTooltipProps>((props) => {
    const {
        className = "",
        arrow = false,
        allowHtml = false,
        trigger = "mouseenter focus",
        ...rest
    } = props;
    return (
        <Tippy
            className={className}
            arrow={arrow}
            allowHTML={allowHtml}
            animation="fade"
            delay={100}
            trigger={trigger}
            role="tooltip"
            {...rest}
        />
    );
});

export default AppTooltip;
