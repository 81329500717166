import { getGeneralAcceptFile } from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { defaultTo, isNil } from "lodash-es";
import { Optional } from "models/general";
import React, { useRef } from "react";
import {
    Button,
    Col,
    Form,
    FormControlProps,
    Image,
    Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AppFileInput.module.scss";

interface AppFileInputProps extends FormControlProps {
    onFilesUpload: (files: FileList) => void;
    files?: FileList;
    label: string;
    placeholder: string;
    readonly?: boolean;
    isValid?: boolean;
    multiple?: boolean;
    acceptFiles?: string[];
}
export const AppFileInput: React.FC<AppFileInputProps> = ({
    onFilesUpload,
    files,
    readonly = false,
    isValid = true,
    label,
    placeholder,
    className,
    multiple,
    acceptFiles = getGeneralAcceptFile(),
    ...rest
}) => {
    const { t } = useTranslation();
    const fileRef = useRef<Optional<HTMLInputElement>>(null);
    const onFileClickHandler = () => {
        if (!isNil(fileRef.current)) {
            fileRef.current.click();
        }
    };

    const onFileChangeHandler = () => {
        if (fileRef.current && fileRef.current.files) {
            onFilesUpload(fileRef.current.files);
        }
    };

    return (
        <>
            <Form.File
                {...rest}
                ref={fileRef}
                id="exampleFormControlFile2"
                style={{ display: "none" }}
                name="file"
                multiple={multiple}
                onChange={onFileChangeHandler}
                type="file"
                accept={acceptFiles.join(",")}
                readOnly={true}
                onClick={onFileClickHandler}
                disabled={readonly}
            />
            <Form.Group controlId="formBasicEmail" className={className}>
                <Form.Label>{label}</Form.Label>
                <div className={styles.row}>
                    <div className="flex-grow-1">
                        <Form.Control
                            className={`${styles.input} ${
                                !readonly ? "" : "disabled"
                            }`}
                            placeholder={placeholder}
                            disabled={readonly}
                            onChange={() => {}}
                            onClick={onFileClickHandler}
                            value={defaultTo(
                                files &&
                                    t(
                                        `common.fileSelected.${
                                            files.length == 1
                                                ? "single"
                                                : "multiple"
                                        }`,
                                        {
                                            count: files.length,
                                        }
                                    ).toString(),
                                ""
                            )}
                        />
                    </div>
                    <div>
                        <Button
                            className={`${styles.icon} ${
                                !readonly ? "" : "disabled"
                            }`}
                            onClick={onFileClickHandler}
                            disabled={readonly}
                        >
                            <Image
                                style={{ marginBottom: "5px" }}
                                src={ImageAssets.common.attachment}
                            />
                        </Button>
                    </div>
                </div>
                {!isValid && (
                    <Form.Text className="text-muted">
                        {t("calendar.files.missing")}
                    </Form.Text>
                )}
            </Form.Group>
        </>
    );
};

export default AppFileInput;
