export enum EmployeeContractSortColumn {
    ContractStart,
    ContractEnd,
    MonthsTrialPeriod,
    EndOfTrialPeriod,
    MonthsExpiration,
    ExpirationDate,
    RealWeeklyHours,
    WorkingDaysAWeek,
    PauseInMinutes,
}
