import { AppResponse } from "models/general/response";
import { NotificationRequest, NotificationResponse } from "models/notification";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface INotificationService {
    getNotificationList(
        reqParams: NotificationRequest
    ): Promise<AppResponse<NotificationResponse[]>>;
    markRead(id: number): Promise<AppResponse<boolean>>;
    markAllRead(ids: number[]): Promise<AppResponse<boolean>>;
    bulkDelete(ids: number[]): Promise<AppResponse<number>>;
    delete(id: number): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.notification;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getNotificationServiceKey(
    name: keyof INotificationService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `notifications_${name}_${JSON.stringify(data)}`;
}
export class NotificationService
    extends BaseService
    implements INotificationService
{
    public notificationRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.notificationRoutes = apiLinkProvider;
    }

    getNotificationList(
        request: NotificationRequest
    ): Promise<AppResponse<NotificationResponse[]>> {
        return this.doServerXHR<AppResponse<NotificationResponse[]>>({
            url: this.notificationRoutes.list({ request }),
            method: "get",
        });
    }
    markRead(id: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.notificationRoutes.markRead(id),
            method: "post",
        });
    }
    markAllRead(ids: number[]): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.notificationRoutes.markAllRead(),
            method: "post",
            data: {
                ids: ids,
            },
        });
    }
    bulkDelete(ids: number[]): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.notificationRoutes.bulkDelete(),
            method: "post",
            data: {
                ids: ids,
            },
        });
    }
    delete(id: number): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.notificationRoutes.delete(id),
            method: "post",
        });
    }
}

export default NotificationService;
