import {
    hideSwalLoader,
    showSwalLoader,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import { useAppContext } from "hoc/providers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { t } from "i18next";
import { defaultTo, isNil } from "lodash-es";
import { BusinessContext, BusinessContextResponse, BusinessType } from "models";
import { ErrorCodes } from "models/general";
import path from "path";
import { useCallback, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router";
import {
    getLillywaitSupportServiceKey,
    LillywaitSupportService,
} from "services/lillywait";

export const useSupportMode = () => {
    const { userInfo, setUserInfo, supportViewData, setSupportViewData } =
        useAppContext();

    const { linkProvider } = useRouting(
        // here we need to generate url for actual session business (LW) not support business
        supportViewData?.actualSessionBusinessInfo.SessionBusiness.EncodedId
    );
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const showErrorPage = useShowErrorPage();
    const supportService = new LillywaitSupportService(
        linkProvider.lillywait.support().api
    );
    const {
        isLoading: enteringSupportMode,
        data: enterSupportModeResponse,
        isError: enterSupportModeError,
        mutate: callEnterSupportMode,
    } = useMutation(
        getLillywaitSupportServiceKey("enterSupportMode"),
        async (businessId: number) =>
            await supportService.enterSupportMode(businessId)
    );
    const {
        isLoading: closingSupportMode,
        data: closeSupportModeResponse,
        isError: closeSupportModeError,
        mutate: callCloseSupportMode,
    } = useMutation(
        getLillywaitSupportServiceKey("exitSupportMode"),
        async () => await supportService.exitSupportMode()
    );
    useEffect(() => {
        if (
            pathname.includes("support") &&
            supportViewData?.isSupportViewEnabled
        ) {
            navigate(linkProvider.screens.home());
        }
    }, [userInfo]);
    const enterSupportMode = useCallback(
        (newContext: BusinessContextResponse) => {
            if (
                !isNil(userInfo) &&
                userInfo.SessionBusiness.Id != newContext.SessionBusiness.Id
            ) {
                // save current businessInfo

                setSupportViewData({
                    isSupportViewEnabled: true,
                    actualSessionBusinessInfo: {
                        SessionBusiness: userInfo.SessionBusiness,
                        SessionBusinessLocation:
                            userInfo.SessionBusinessLocation,
                    } as BusinessContext,
                });

                // set new businessInfo to enter support mode for the business
                setUserInfo({
                    ...userInfo,
                    // don't over-ride all data
                    SessionBusiness: newContext.SessionBusiness,
                    SessionBusinessLocation: newContext.SessionBusinessLocation,
                });
            }
        },
        [userInfo]
    );
    const exitSupportMode = useCallback(() => {
        if (!isNil(userInfo) && !isNil(supportViewData)) {
            const lwcType =
                supportViewData.actualSessionBusinessInfo.SessionBusiness.Type;

            // delete save info
            setUserInfo({
                ...userInfo,
                ...supportViewData.actualSessionBusinessInfo, // set saved info back in main context
            });
            setSupportViewData(null);

            navigate(
                linkProvider.lillywait
                    .support()
                    .screens.list(defaultTo(lwcType, BusinessType.KINDERGARTEN))
            );
        }
    }, [userInfo, supportViewData]);

    useEffect(() => {
        if (enteringSupportMode) {
            showSwalLoader(
                t("common.pleaseWait"),
                t("lwClient.openingSupportMode")
            );
        }
        if (!enteringSupportMode && enterSupportModeResponse) {
            if (enterSupportModeResponse?.Data) {
                const data = enterSupportModeResponse.Data;

                if (
                    enterSupportModeResponse.Code ===
                    ErrorCodes.UNKNOWN_BUSINESS
                ) {
                    showErrorPage(404);
                } else if (!enterSupportModeResponse.Code && data) {
                    // update context
                    enterSupportMode(data);
                }
                hideSwalLoader();
            }
        } else if (enterSupportModeError) {
            showSweetAlertInfo(
                t("common.error.error"),
                t(`lwClient.unableToEnterSupportMode`),
                "error"
            );
            hideSwalLoader();
        }
    }, [
        enteringSupportMode,
        enterSupportModeResponse,
        enterSupportModeError,
        enterSupportMode,
    ]);
    useEffect(() => {
        if (closingSupportMode) {
            showSwalLoader(
                t("common.pleaseWait"),
                t("lwClient.closingSupportMode")
            );
        }
        if (!closingSupportMode && closeSupportModeResponse) {
            if (closeSupportModeResponse?.Data) {
                const data = closeSupportModeResponse.Data;
                if (data) {
                    // update context
                    exitSupportMode();
                }
                hideSwalLoader();
            }
        } else if (closeSupportModeError) {
            showSweetAlertInfo(
                t("common.error.error"),
                t(`lwClient.unableToExitSupportMode`),
                "error"
            );
            hideSwalLoader();
        }
    }, [
        closingSupportMode,
        closeSupportModeResponse,
        closeSupportModeError,
        exitSupportMode,
    ]);

    return {
        enterSupportMode: (lwcId: number) => {
            if (!enteringSupportMode) {
                callEnterSupportMode(lwcId);
            }
        },
        exitSupportMode: () => callCloseSupportMode(),
    };
};
