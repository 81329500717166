import { AppIconWithName } from "components";
import { AppSelectOld } from "components/AppSelect";
import { AppButton, AppDeleteButton } from "components/Buttons";
import { AppContainer, AppContainerFooter } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import { SelectItem } from "models/general";
import {
    getKindergartenClientRequest,
    KindergartenClient,
    KindergartenClientRequest,
} from "models/kindergartenClient";
import {
    Kindergarten_Client_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    getKindergartenClientServiceKey,
    KindergartenClientService,
} from "services/kindergarten";
import { COLOR_TEXT_DARK } from "theme/themeConstants";
import styles from "./ClientCommonStyle.module.scss";
export interface ClientEditAdditionalInfoProps {
    value: KindergartenClient;
    onChange: (client: KindergartenClient) => void;
}

const selectStyles = {
    input: (base: any) => ({
        ...base,
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
    }),
};
export interface ClientEditAdditionalInfoState {
    notes: string;
    siblingList: SelectItem[];
    selectedSibling: SelectItem;
    siblingSelectItems: SelectItem[];
}
export const ClientEditAdditionalInfo: React.FC<
    ClientEditAdditionalInfoProps
> = ({ onChange, value }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();
    const clientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );

    const hasEditPermission = checkPermission(Kindergarten_Client_BasicData, [
        PermissionAccessTypes.EDIT,
    ]);

    const pleaseSelect: SelectItem = {
        Text: t("common.pleaseSelect"),
        Value: null,
    };
    const [state, setState] = useState<ClientEditAdditionalInfoState>({
        notes: "",
        siblingList: [],
        selectedSibling: pleaseSelect,
        siblingSelectItems: [pleaseSelect],
    });

    const {
        isFetching: loadingSiblingsItems,
        data: siblingItemResponse,
        refetch: refetchSiblingItem,
    } = useQuery(
        getKindergartenClientServiceKey("getSiblings", value.Id),
        async () => await clientService.getSiblings()
    );

    const {
        isLoading: loadingCreate,
        data: createResponse,
        mutate: create,
    } = useMutation(
        async (val: KindergartenClientRequest) =>
            await clientService.addUpdate(val)
    );

    const {
        isLoading: loadingAddSibling,
        data: addSiblingResponse,
        mutate: addSibling,
    } = useMutation(async (val: number) => await clientService.addSibling(val));

    const {
        isLoading: loadingDeleteSibling,
        data: deleteSiblingResponse,
        mutate: deleteSibling,
    } = useMutation(
        async (val: number) => await clientService.deleteSibling(val)
    );

    useEffect(() => {
        if (!loadingAddSibling && addSiblingResponse) {
            showSweetAlertToast(
                t("common.success"),
                `"${addSiblingResponse.Data.Text}" ${t(
                    "common.actions.updatedSuccessfully"
                )}`,
                "success"
            );
            onChange({
                ...value,
                Siblings: [...state.siblingList, addSiblingResponse.Data],
            });
            refetchSiblingItem();
        }
    }, [loadingAddSibling, addSiblingResponse]);

    useEffect(() => {
        if (!loadingDeleteSibling && deleteSiblingResponse) {
            const deleteValue = state.siblingList.find(
                (x) => Number(x.Value) == deleteSiblingResponse.Data
            );
            if (deleteValue) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${deleteValue.Text}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
            }
            onChange({
                ...value,
                Siblings: state.siblingList.filter(
                    (x) => Number(x.Value) != deleteSiblingResponse.Data
                ),
            });
            refetchSiblingItem();
        }
    }, [loadingDeleteSibling, deleteSiblingResponse]);

    useEffect(() => {
        setState((old) => ({
            ...old,
            siblingList: [...value.Siblings],
            selectedSibling: pleaseSelect,
        }));
    }, [value.Id, value.Siblings, t]);

    useEffect(() => {
        if (!loadingCreate && createResponse) {
            showSweetAlertToast(
                t("common.success"),
                `"${createResponse.Data.FirstName}" ${t(
                    "common.actions.updatedSuccessfully"
                )}`,
                "success"
            );
        }
    }, [loadingCreate, createResponse]);
    useEffect(() => {
        if (
            !loadingSiblingsItems &&
            siblingItemResponse &&
            siblingItemResponse.Data
        ) {
            setState((old) => ({
                ...old,
                siblingSelectItems: [pleaseSelect, ...siblingItemResponse.Data],
                selectedSibling: pleaseSelect,
            }));
        }
    }, [loadingSiblingsItems, siblingItemResponse]);
    return (
        <div className="mt-4">
            <AppContainer
                showHeader={true}
                // isCollapsible={true}
                // initiallyOpen={true}
                title={t("kindergartenClient.additionalInfo.title")}
                footer={
                    <AppContainerFooter
                        primaryButtonProps={{
                            disabled: !hasEditPermission,
                            onClick: () =>
                                create(getKindergartenClientRequest(value)),
                        }}
                    />
                }
            >
                <Row>
                    <Col xs={12} sm={6} md={4}>
                        <div
                            style={{
                                color: COLOR_TEXT_DARK,
                                fontWeight: 500,
                            }}
                        >
                            {t("kindergartenClient.additionalInfo.allChilds")}
                        </div>
                        <Row className="mt-2">
                            {state.siblingList.length == 0 ? (
                                <Col
                                    xs={12}
                                    style={{
                                        fontWeight: 500,
                                        textAlign: "center",
                                    }}
                                >
                                    {t(
                                        "kindergartenClient.additionalInfo.noSiblingSelected"
                                    )}
                                </Col>
                            ) : (
                                state.siblingList.map((sibling) => (
                                    <Col
                                        className="mt-1"
                                        lg={"12"}
                                        md={"12"}
                                        sm={"12"}
                                        xs={"12"}
                                        xl={"6"}
                                        key={sibling.Value}
                                        style={{ fontWeight: 600 }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <AppIconWithName
                                                textEllipseClass={
                                                    styles.appIconWithName
                                                }
                                                text={sibling.Text}
                                            />
                                            <AppDeleteButton
                                                name={sibling.Text}
                                                readonly={!hasEditPermission}
                                                onClick={() =>
                                                    deleteSibling(
                                                        Number(sibling.Value)
                                                    )
                                                }
                                                getConfirmation={true}
                                            />
                                        </div>
                                    </Col>
                                ))
                            )}
                        </Row>
                        <AppSelectOld
                            className="mt-3"
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            menuPlacement="top"
                            showEmptyError={true}
                            isLoading={loadingSiblingsItems}
                            options={state.siblingSelectItems}
                            isDisabled={!hasEditPermission}
                            value={
                                state.selectedSibling.Value == null
                                    ? pleaseSelect
                                    : state.siblingSelectItems.find(
                                          (x) =>
                                              x.Value ==
                                              state.selectedSibling.Value
                                      )
                            }
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    selectedSibling: e,
                                })
                            }
                        />
                        <AppButton
                            className="mt-3"
                            style={{
                                width: "105px",
                                height: "43px",
                            }}
                            disabled={
                                state.selectedSibling.Value === null ||
                                !hasEditPermission
                            }
                            onClick={() =>
                                addSibling(
                                    state.selectedSibling.Value as number
                                )
                            }
                            padding="med"
                        >
                            {t("common.add")}
                        </AppButton>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                        <Form.Group controlId="description">
                            <Form.Label>
                                {t("kindergartenClient.additionalInfo.notes")}
                            </Form.Label>
                            <Form.Control
                                style={{
                                    height: "150px",
                                    resize: "none",
                                    fontSize: "0.9rem",
                                }}
                                as="textarea"
                                disabled={!hasEditPermission}
                                type="textarea"
                                value={defaultTo(value.Notes, "")}
                                onChange={(e) => {
                                    onChange({
                                        ...value,
                                        Notes: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </AppContainer>
        </div>
    );
};

export default ClientEditAdditionalInfo;
