import { AvatarInfo } from "models/general";
import { MessageTemplate } from "./request";

export function getDefaultMessageTemplate(
    userInfo: AvatarInfo
): MessageTemplate {
    return {
        Id: 0,
        SenderInfo: userInfo,
        Body: {
            Template: "",
            Text: null,
            Version: "0",
            Placeholders: [],
        },
        Title: "",
        ClientIds: null,
        EmployeeIds: null,
        GroupIds: null,
        ReceiptTypes: null,
        Files: [],
        files: [],
    };
}
