import classNames from "classnames";
import { AppTooltip } from "components";
import { AppSelect } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { Optional } from "models/general";
import { EmployeeShiftAssignment, ShiftPlanRowTrack } from "models/shiftPlan";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { getShiftPlanServiceKey, ShiftPlanService } from "services/business";
import { COLOR_TEXT_DARK } from "theme/themeConstants";
import styles from "./ShiftPlanTableCellItem.module.scss";

export const selectStyles = {
    control: {
        height: "31px !important",
        padding: "0px 12px",
    },
    option: {
        fontSize: "0.85rem",
    },
    indicatorsContainer: {
        display: "none",
    },
    valueContainer: {
        minHeight: "31px",
        height: "31px",
        padding: "0px",
        color: COLOR_TEXT_DARK,
        fontSize: "0.85rem",
    },
};

interface ShiftPlanTableCellItemProps {
    value?: EmployeeShiftAssignment;
    disabled?: boolean;
    isEditView: boolean;
    isAvailable: boolean;
    isWorkingDay: boolean;
    editCell: Optional<string>;
    setEditCell: (val: Optional<string>) => void;
    date: Moment;
    canDelete?: boolean;
    canEdit?: boolean;
    areaId: number;
    rowInfo: ShiftPlanRowTrack;
    onChange: () => void;
}

const Component: React.FC<ShiftPlanTableCellItemProps> = ({
    value,
    disabled,
    isEditView,
    isAvailable,
    date,
    isWorkingDay,
    editCell,
    setEditCell,
    canEdit = false,
    canDelete = false,
    rowInfo,
    areaId,
    onChange,
}) => {
    const [selectedShiftId, setSelectedShiftId] = useState(value?.ShiftId); // to fetch available shift options for employee and update shift

    const cellIdentifier = `${rowInfo.EmployeeId}-${
        rowInfo.ShiftId
    }-${date.format(ISO8601_DATE_FORMAT)}`;
    const { linkProvider } = useRouting();
    const { getTimeFormatForLocale } = useLocaleHelpers();
    const { t } = useTranslation();

    const shiftPlanService = new ShiftPlanService(
        linkProvider.business.api.shiftPlan
    );

    const editEnabled = editCell == cellIdentifier;
    const {
        isFetching: loadingOptions,
        data: shiftOptions,
        refetch: refetchShiftOptions,
        remove: removeOptionsCache,
    } = useQuery(
        getShiftPlanServiceKey("fetchShiftOptionsForEmployee"),
        async () =>
            await shiftPlanService.fetchShiftOptionsForEmployee({
                AreaId: areaId,
                EmployeeId: rowInfo.EmployeeId as number,
                Date: date,
            }),
        {
            enabled: false,
        }
    );
    useEffect(() => {
        if (editEnabled) {
            refetchShiftOptions();
        }
    }, [editEnabled]);
    useEffect(() => {
        if (value && value.ShiftId) {
            setSelectedShiftId(value.ShiftId);
        }
    }, [value?.ShiftId]);
    const { isLoading: deleting, mutate: deleteShiftAssignment } = useMutation(
        async (id: number) => {
            return await shiftPlanService.deleteShiftAssignment(id);
        },
        {
            onSuccess: (data) => {
                if (data.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        t(`shiftPlan.assignmentDeleted`),
                        "success"
                    );
                    onChange();
                } else if (data.Message) {
                    showSweetAlertToast(
                        t("common.error.error"),
                        t("common.actions.errors.unableToDelete"),
                        "error"
                    );
                }
            },
        }
    );
    const { isLoading: updating, mutate: updateAssignment } = useMutation(
        async (data: EmployeeShiftAssignment) => {
            return await shiftPlanService.addUpdateShiftAssignment(data);
        },
        {
            onSuccess: (data) => {
                if (data.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        t(`shiftPlan.shiftUpdated`),
                        "success"
                    );
                    onChange();
                    setEditCell(undefined);
                    setSelectedShiftId(undefined);
                    removeOptionsCache();
                } else if (data.Message) {
                    showSweetAlertToast(
                        t("common.error.error"),
                        t("common.actions.errors.unableToUpdate"),
                        "error"
                    );
                }
            },
        }
    );

    const shiftAssignmentBadge = value && value.Shift && (
        <AppTooltip content={value.Shift.Name}>
            <span
                className={classNames(styles.badge, {
                    [styles.red]: !isAvailable,
                })}
            >{`${value.Shift.StartTime.format(
                getTimeFormatForLocale()
            )} - ${value.Shift.EndTime.format(
                getTimeFormatForLocale()
            )}`}</span>
        </AppTooltip>
    );

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;
    const shiftsOptions = [
        pleaseSelectOption,
        ...(shiftOptions && shiftOptions.Data
            ? shiftOptions.Data.map((x) => {
                  return {
                      label: x.Name,
                      value: x.Id as number,
                  } as SimpleOption;
              })
            : []),
        ...(value &&
        value.Shift &&
        isNil(shiftOptions?.Data.find((x) => x.Id == value.ShiftId))
            ? [
                  // also show current shift in options
                  {
                      label: value.Shift.Name,
                      value: value.Shift.Id as number,
                  } as SimpleOption,
              ]
            : []),
    ] as SimpleOption[];

    const enterEditMode = () => {
        if (!editEnabled && !deleting && !disabled) {
            setEditCell(cellIdentifier);
            setSelectedShiftId(undefined);
        }
    };
    const isCorrectShiftEntryCell =
        !value || !rowInfo.ShiftId || rowInfo.ShiftId == value?.ShiftId;

    return (
        <div className={styles.root}>
            {isCorrectShiftEntryCell && // for else case don't show cell
                (!isEditView ? (
                    shiftAssignmentBadge
                ) : isAvailable || (!isAvailable && value) ? (
                    <div className={styles.editItem}>
                        {!editEnabled ? (
                            !isNil(value) ? (
                                <>
                                    {value.Shift && (
                                        <AppTooltip
                                            content={t("shiftPlan.clickToEdit")}
                                            arrow={true}
                                        >
                                            <span
                                                className={styles.shiftLabel}
                                                onClick={enterEditMode}
                                            >
                                                {value.Shift.Name}
                                            </span>
                                        </AppTooltip>
                                    )}
                                    {canDelete && (
                                        <AppDeleteButton
                                            getConfirmation={true}
                                            className={styles.icon}
                                            readonly={!canDelete || deleting}
                                            onClick={() => {
                                                if (value.Id) {
                                                    deleteShiftAssignment(
                                                        value.Id
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </>
                            ) : (
                                <AppTooltip content={t("common.edit")}>
                                    <span
                                        onClick={enterEditMode}
                                        style={{ width: "100%" }}
                                    >
                                        <AppIconButton
                                            onClick={() => {}}
                                            buttonType="Edit"
                                            className={styles.icon}
                                            readonly={
                                                !canEdit ||
                                                updating ||
                                                !isWorkingDay
                                            }
                                        />
                                    </span>
                                </AppTooltip>
                            )
                        ) : (
                            <>
                                {/* show app-select and save button */}
                                <AppSelect
                                    isLoading={loadingOptions}
                                    isDisabled={
                                        !canEdit ||
                                        updating ||
                                        deleting ||
                                        loadingOptions
                                    }
                                    styles={selectStyles}
                                    className={styles.shiftSelect}
                                    value={
                                        selectedShiftId
                                            ? shiftsOptions.filter(
                                                  (x) =>
                                                      x.value == selectedShiftId
                                              )
                                            : pleaseSelectOption
                                    }
                                    options={shiftsOptions}
                                    onChange={(value: SimpleOption) => {
                                        setSelectedShiftId(value.value);
                                    }}
                                />
                                {selectedShiftId && (
                                    <AppIconButton
                                        readonly={!canEdit || updating}
                                        showToolTip={true}
                                        icon={"check"}
                                        className={styles.icon}
                                        tooltipText={t("common.update")}
                                        onClick={() => {
                                            if (
                                                isNil(value) ||
                                                value.ShiftId != selectedShiftId
                                            ) {
                                                updateAssignment({
                                                    Id: value?.Id,
                                                    AreaId: areaId,
                                                    EmployeeId:
                                                        rowInfo.EmployeeId,
                                                    ShiftId: selectedShiftId,
                                                    Date: date,
                                                } as EmployeeShiftAssignment);
                                            } else {
                                                setEditCell(undefined);
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <span
                        className={classNames(styles.red, styles.unavailable)}
                    >
                        {t("shiftPlan.unavailable")}
                    </span>
                ))}
        </div>
    );
};

Component.displayName = "ShiftPlanTableCellItem";
export const ShiftPlanTableCellItem = React.memo(Component);
export default ShiftPlanTableCellItem;
