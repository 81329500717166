import { Table } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { AppPopover } from "components";
import { AppLoader } from "components/Loaders";
import { useRouting } from "hooks/general/routing";
import {
    ChildFamilyResponse,
    TimeManagementMedicalInfoEntity,
    getDayTypeTranslation,
} from "models/timeManagement";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    TimeTrackingService,
    getTimeTrackingServiceKey,
} from "services/business";
import { defaultTo, isNil } from "lodash-es";
import styles from "./TimeManagementClientInfoTags.module.scss";
import classNames from "classnames";
import { DayType } from "models/general";

export interface TimeManagementClientInfoTagsProps {
    values: TimeManagementMedicalInfoEntity;
    personId: number;
    dayType: DayType;
}

export const TimeManagementClientInfoTags: React.FC<
    TimeManagementClientInfoTagsProps
> = ({ values, personId, dayType }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const timeTrackingService = new TimeTrackingService(
        linkProvider.business.api.timeTracking
    );
    const {
        isLoading: loading,
        data: response,
        refetch,
    } = useQuery(
        getTimeTrackingServiceKey("getChildFamilyInfo", personId),
        async () => await timeTrackingService.getChildFamilyInfo(personId),
        {
            enabled: false,
            select: (data) =>
                defaultTo(data && data.Data, {
                    ClientId: personId,
                    FamilyMembers: [],
                } as ChildFamilyResponse),
        }
    );
    const stopPropagationHandler = (e: any) => {
        e.stopPropagation();
    };
    return (
        <div className={styles.root}>
            <div className={styles.outerDiv} onClick={stopPropagationHandler}>
                <>
                    {values && (
                        <>
                            <div className={`${styles.badge} ml-auto`}>
                                {values.Vegetarian && (
                                    <div className={` ${styles.badgeGreen}`}>
                                        {t("timeManagement.infoTags.v")}
                                    </div>
                                )}
                            </div>
                            <div className={`${styles.badge} ml-auto`}>
                                {(values.Drug ||
                                    values.FamilyDoctorContactInfo ||
                                    values.FoodIntolerances ||
                                    values.Vaccinations) && (
                                    <AppPopover
                                        triggerElem={
                                            <div className={styles.badgeRed}>
                                                {t("timeManagement.infoTags.u")}
                                            </div>
                                        }
                                        placement={"right-start"}
                                    >
                                        <>
                                            <div
                                                className={classNames(
                                                    styles.flexCenter,
                                                    styles.table
                                                )}
                                                style={{
                                                    maxHeight: "500px",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        textAlign: "start",
                                                    }}
                                                    className="font-weight-bold"
                                                >
                                                    {t(
                                                        "timeManagement.infoTags.medicalInfo"
                                                    )}
                                                </span>
                                                {values.Drug && (
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {t(
                                                                        "timeManagement.infoTags.drugs"
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        values.Drug
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                                {values.FamilyDoctorContactInfo && (
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {t(
                                                                        "timeManagement.infoTags.familyDoctorContactInfo"
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        values.FamilyDoctorContactInfo
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                                {values.FoodIntolerances && (
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {t(
                                                                        "timeManagement.infoTags.foodIntolerances"
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        values.FoodIntolerances
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                                {values.Miscellaneous && (
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {t(
                                                                        "timeManagement.infoTags.miscellaneous"
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        values.Miscellaneous
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </div>
                                        </>
                                    </AppPopover>
                                )}
                            </div>
                            <div className={`${styles.badge} ml-auto`}>
                                {values.Allergy && (
                                    <AppPopover
                                        triggerElem={
                                            <div className={styles.badgeBlue}>
                                                {t("timeManagement.infoTags.a")}
                                            </div>
                                        }
                                        placement={"right-start"}
                                    >
                                        <div
                                            className={classNames(
                                                styles.flexCenter
                                            )}
                                        >
                                            <span className="font-weight-bold">
                                                {t(
                                                    "timeManagement.infoTags.allergies"
                                                )}
                                            </span>
                                            <Table className={styles.table}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {values.Allergy}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </AppPopover>
                                )}
                            </div>
                        </>
                    )}
                    <div className={`${styles.badge} ml-auto`}>
                        <AppPopover
                            onClick={() => refetch()}
                            triggerElem={
                                <div className={styles.badgeYellow}>
                                    {t("timeManagement.infoTags.f")}
                                </div>
                            }
                            placement={"auto"}
                        >
                            {isNil(response) || loading ? (
                                <AppLoader
                                    fullHeight={false}
                                    width={200}
                                    height={150}
                                />
                            ) : (
                                <>
                                    {response.FamilyMembers.length > 0 ? (
                                        <div
                                            className={classNames(
                                                styles.flexCenter
                                            )}
                                        >
                                            <span className="font-weight-bold">
                                                {t(
                                                    "timeManagement.infoTags.family.family"
                                                )}
                                            </span>
                                            <Table
                                                style={{
                                                    margin: "0",
                                                    cursor: "default !important",
                                                }}
                                                className={
                                                    styles.familyInfoTable
                                                }
                                            >
                                                <thead>
                                                    <tr>
                                                        <th
                                                            style={{
                                                                cursor: "default !important",
                                                            }}
                                                        >
                                                            {t(
                                                                "timeManagement.infoTags.family.firstName"
                                                            )}
                                                        </th>
                                                        <th
                                                            style={{
                                                                cursor: "default !important",
                                                            }}
                                                        >
                                                            {t(
                                                                "timeManagement.infoTags.family.lastName"
                                                            )}
                                                        </th>
                                                        <th
                                                            style={{
                                                                cursor: "default !important",
                                                            }}
                                                        >
                                                            {t(
                                                                "timeManagement.infoTags.family.idNumber"
                                                            )}
                                                        </th>
                                                        <th
                                                            style={{
                                                                cursor: "default !important",
                                                            }}
                                                        >
                                                            {t(
                                                                "timeManagement.infoTags.family.contactNumber"
                                                            )}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {response.FamilyMembers.map(
                                                        (x) => (
                                                            <tr key={uuid()}>
                                                                <td
                                                                    style={{
                                                                        cursor: "default !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        x.FirstName
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        cursor: "default !important",
                                                                    }}
                                                                >
                                                                    {x.LastName}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        cursor: "default !important",
                                                                    }}
                                                                >
                                                                    {
                                                                        x.IdentityCard
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.cursorDefault
                                                                    }
                                                                >
                                                                    {
                                                                        x.ContactNumber
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <span>{t("common.noDataFound")}</span>
                                    )}
                                </>
                            )}
                        </AppPopover>
                    </div>
                </>
            </div>
            <span className={`${styles.dayType}`}>
                {dayType && getDayTypeTranslation(dayType, false)}
            </span>
        </div>
    );
};

export default TimeManagementClientInfoTags;
