import { AppCheckbox } from "components/FormFields";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { AppLocale } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TermsAndCondition.module.scss";

export interface TermsAndConditionProps {
    value?: boolean;
    showCheckbox?: boolean;
    onChange?: (val: boolean) => void;
}

export const TermsAndCondition: React.FC<TermsAndConditionProps> = (
    props: TermsAndConditionProps
) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const { value = false, showCheckbox = false, onChange } = props;

    return (
        <div className={`d-flex ${styles.termsConditions}`}>
            {showCheckbox && (
                <AppCheckbox
                    style={{ float: "left" }}
                    checked={value}
                    onChange={(e) => {
                        onChange && onChange(e.target.checked);
                    }}
                />
            )}
            <div onClick={() => onChange && onChange(!value)}>
                {t("auth.registration.termsConditions.context1")}
                <span className={styles.colorPrimary}>
                    {t("auth.registration.termsConditions.context2")}
                </span>
                {t("auth.registration.termsConditions.context3")}
                <span className={styles.colorPrimary}>
                    {t("auth.registration.termsConditions.context4")}
                </span>
                {appLocale == AppLocale.GERMAN ? " einverstanden" : ""}.
                <br />
                <span>{t("auth.registration.termsConditions.context5")}</span>
            </div>
        </div>
    );
};

export default TermsAndCondition;
