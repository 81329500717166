import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContentHeader } from "components";
import { AppLoader } from "components/Loaders/AppLoader";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { Optional, TabbedLayoutActiveItem } from "models/general";
import { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { useShowErrorPage } from "hooks/general/appHelpers";
import EmployeeTabs from "screens/business/employees/tabs/EmployeeTabs";
import { EmployeeListItem } from "models";
import { useBusinessEmployeesContext } from "./BusinessEmployeesContextProvider";
import styles from "./BusinessEmployeeEditLayout.module.scss";
import { validEmployeePathPatterns } from "routing/routes/business/employee";
import classNames from "classnames";

export const BusinessEmployeeEditLayout = () => {
    const {
        employees,
        completeResponse,
        navigateUsingCompleteResponse,
        filters,
        setNavigateUsingCompleteResponse,
        isRefetching: reloadingList,
        refetchData,
    } = useBusinessEmployeesContext();
    const showErrorPage = useShowErrorPage();
    const { employeeId, employeeType: typePattern } = useParams();
    const location = useLocation();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const [state, setState] = useState<
        TabbedLayoutActiveItem<EmployeeListItem>
    >({ index: undefined, dataItem: undefined, tab: undefined });
    const [retest404, setRetest404] = useState(false);
    useEffect(() => {
        if (!validEmployeePathPatterns.includes(defaultTo(typePattern, ""))) {
            showErrorPage(404);
        }
    }, [typePattern]);

    useEffect(() => {
        if (
            location.pathname.endsWith(`${typePattern}/${employeeId}`) ||
            location.pathname.endsWith(`${typePattern}/${employeeId}/`)
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(
                linkProvider.business.employees().screens.withId().edit(),
                { replace: true }
            );
        }
    }, [location.pathname, employeeId, typePattern]);

    const targetSearchList = useMemo(() => {
        return navigateUsingCompleteResponse ? completeResponse : employees;
    }, [completeResponse, employees, navigateUsingCompleteResponse]);

    const navigateToIndex = useCallback(
        (targetIdx: Optional<number>) => {
            if (targetSearchList && !isNil(targetIdx)) {
                const target = targetSearchList[targetIdx];
                if (!isNil(target)) {
                    // only need to change employeeId Id not the url Path, but react-router provide no way to do this so manually changing ID in path
                    const newPath = location.pathname.replace(
                        `${typePattern}/${employeeId}`,
                        `${typePattern}/${target.EncodedId}`
                    );

                    navigate(newPath);
                }
            }
        },
        [targetSearchList, employeeId, location.pathname]
    );
    useEffect(() => {
        if (
            targetSearchList &&
            targetSearchList.length > 0 &&
            !reloadingList &&
            employeeId
        ) {
            let found = targetSearchList.findIndex(
                (x) => x.EncodedId === employeeId
            );
            let item: EmployeeListItem | null = null;
            if (found < 0) {
                if (completeResponse) {
                    found = completeResponse.findIndex(
                        (x) => x.EncodedId === employeeId
                    );
                }
                if (found < 0) {
                    // once try refetch
                    if (!retest404 && refetchData) {
                        setRetest404(true);
                        refetchData();
                    } else {
                        showErrorPage(404);
                    }
                } else {
                    item =
                        completeResponse && completeResponse.length > found
                            ? completeResponse[found]
                            : null;
                    setNavigateUsingCompleteResponse(true);
                }
            } else {
                item = targetSearchList[found];
            }
            setState((old) => ({
                ...old,
                index: found,
                dataItem: item,
            }));
        }
    }, [employeeId, targetSearchList, reloadingList]);

    const isBaseRoute = useMemo(() => {
        let isBase = true;
        if (location.pathname && !isNil(state.dataItem)) {
            // navigation buttons should be visible only on base routes
            const firstLevel = location.pathname.split(
                `/${typePattern}/${state.dataItem.EncodedId}/`
            );
            if (firstLevel.length > 1) {
                isBase = firstLevel[1].split("/").length === 1;
            }
        }
        return isBase;
    }, [location.pathname, state]);

    const employeeInfo = state.dataItem;
    const currIndex = state.index;

    const showNavigationButtons = !isNil(currIndex) && isBaseRoute;
    return isNil(employeeInfo) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={employeeInfo.Name}
                classNameForIcon={"pe-7s-user"}
                hasGoBack={true}
                onBack={() => {
                    navigate(
                        linkProvider.business
                            .employees(filters.EmployeeType)
                            .screens.list()
                    );
                    // TODO: instead use query invalidationOnMutation
                    if (refetchData) {
                        // refetch data in-case something is updated
                        refetchData();
                    }
                }}
            >
                {showNavigationButtons && (
                    <div className={styles.headerNavigation}>
                        <Button
                            onClick={() => navigateToIndex(currIndex - 1)}
                            disabled={currIndex === 0}
                        >
                            <FontAwesomeIcon icon="chevron-left" />
                        </Button>
                        <Button
                            onClick={() => navigateToIndex(currIndex + 1)}
                            disabled={
                                currIndex + 1 === targetSearchList?.length
                            }
                        >
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </div>
                )}
            </AppContentHeader>
            <div className={styles.tabs}>
                <EmployeeTabs
                    activeEmployee={employeeInfo}
                    onActiveTabChange={(tab) =>
                        setState((old) => ({ ...old, tab: tab }))
                    }
                />
                <div
                    className={classNames(styles.content, {
                        [styles.withoutNavigation]: !showNavigationButtons,
                    })}
                >
                    <Outlet context={state} />
                </div>
            </div>
        </div>
    );
};

export default BusinessEmployeeEditLayout;
