import { isNil } from "lodash-es";
import { IsNavigationTabActive } from "models/general";
import { NavigationTab } from "models/general/models";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import styles from "./AppSlidingTabs.module.scss";
import { AppSlidingTabArrows, AppSlidingTabButton } from "./partials";

export interface AppSlidingTabsProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    setting?: Settings;
    slidesToShow?: number;
    onActiveChange?: (tab: NavigationTab) => void;
    tabs: NavigationTab[];
}

export const AppSlidingTabs: React.FC<AppSlidingTabsProps> = ({
    setting,
    tabs: tabsInput,
    className = "",
    onActiveChange,
    slidesToShow: initialSlideToShow = 8,
    ...rest
}) => {
    const carouselRef = useRef<any>(null);
    const [slideToShow, setSlideToShow] = useState(initialSlideToShow);

    const defaultSettings: Settings = {
        speed: 500,
        dots: false,
        draggable: false,
        slidesToShow: slideToShow,
        slidesToScroll: slideToShow,
        infinite: false,
        variableWidth: false,
        // eslint-disable-next-line react/display-name
        onReInit: () => setSlideToShow(initialSlideToShow),
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: initialSlideToShow + 6,
                    slidesToScroll: initialSlideToShow + 6,
                    onReInit: () => setSlideToShow(initialSlideToShow + 6),
                },
            },
            {
                breakpoint: 2800,
                settings: {
                    slidesToShow: initialSlideToShow + 4,
                    slidesToScroll: initialSlideToShow + 4,
                    onReInit: () => setSlideToShow(initialSlideToShow + 4),
                },
            },
            {
                breakpoint: 2200,
                settings: {
                    slidesToShow: initialSlideToShow + 2,
                    slidesToScroll: initialSlideToShow + 2,
                    onReInit: () => setSlideToShow(initialSlideToShow + 2),
                },
            },
            {
                breakpoint: 1950,
                settings: {
                    slidesToShow: initialSlideToShow,
                    slidesToScroll: initialSlideToShow,
                    onReInit: () => setSlideToShow(initialSlideToShow),
                },
            },
            {
                breakpoint: 1610,
                settings: {
                    slidesToShow: initialSlideToShow - 2,
                    slidesToScroll: initialSlideToShow - 2,
                    onReInit: () => setSlideToShow(initialSlideToShow - 2),
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: initialSlideToShow - 3,
                    slidesToScroll: initialSlideToShow - 3,
                    onReInit: () => setSlideToShow(initialSlideToShow - 3),
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    onReInit: () => setSlideToShow(3),
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    onReInit: () => setSlideToShow(2),
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    onReInit: () => setSlideToShow(1),
                },
            },
        ],
        ...setting,
    };

    const tabs = useMemo(() => {
        return tabsInput.filter((t) => t.hasAccess);
    }, [tabsInput]);
    useEffect(() => {
        if (carouselRef.current && carouselRef.current.slickGoTo) {
            const tab = tabsInput.find((x) =>
                IsNavigationTabActive(x, location.pathname)
            );
            let newIndex = tab ? tabsInput.indexOf(tab) : 0;

            if (newIndex > 0 && slideToShow > 1) {
                newIndex = Math.floor(newIndex / slideToShow) * slideToShow;
            }

            carouselRef.current.slickGoTo(
                Math.min(tabs.length, Math.max(0, newIndex)),
                true
            );
        }
    }, [slideToShow]);

    defaultSettings.nextArrow = (
        <AppSlidingTabArrows position={"right"} slidesToShow={slideToShow} />
    );
    defaultSettings.prevArrow = (
        <AppSlidingTabArrows position={"left"} slidesToShow={slideToShow} />
    );

    return (
        <div {...rest} className={`${styles.root} ${className}`}>
            {/* @ts-ignore */}
            <Slider
                ref={carouselRef}
                className={`${styles.slider} ${
                    tabs.length > slideToShow ? styles.slidePadding : ""
                }`}
                {...defaultSettings}
            >
                {tabs.map((tab, index) => (
                    <AppSlidingTabButton
                        key={index.toString()}
                        value={tab}
                        onChange={() => {
                            if (onActiveChange) {
                                onActiveChange(tab);
                            }
                        }}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default AppSlidingTabs;
