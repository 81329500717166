import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { PermissionAccessMap, Role, RoleSortColumn } from "models";
import { AppPlatforms, TableSort } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import RoleManagementTableRow from "screens/business/roleManagement/partials/RoleManagementTableRow";
import styles from "./RoleManagementTable.module.scss";

export interface RoleManagementTableProps {
    roles: Role[] | undefined;
    openDialog: (roleId?: string) => void;
    loading: boolean;
    onDelete: (id: string) => void;
    onSortChange: (sort: TableSort<RoleSortColumn>) => void;
    sort: TableSort<RoleSortColumn>;
    cloneRole: (roleId: string) => void;
    deviceType: AppPlatforms;
    permissionMap: PermissionAccessMap;
}
const COLUMNS_COUNT = 6;
export const RoleManagementTable: React.FC<RoleManagementTableProps> = ({
    roles,
    openDialog,
    onDelete,
    loading,
    onSortChange,
    cloneRole,
    sort,
    deviceType,
    permissionMap,
}) => {
    const { t } = useTranslation();
    return (
        <AppTable
            id={"scrollableDiv"}
            tableClass={`${styles.table} table-striped`}
            hover={true}
            heightToAdjust={245}
            mediumBreak={914}
            mediumViewAdjustment={100}
            mobileViewAdjustment={105}
        >
            <thead>
                <tr>
                    <AppTableSortColumnIcons
                        text={t("roleManagement.role.name.name")}
                        sortColumn={RoleSortColumn.Name}
                        onClick={(key, order) =>
                            onSortChange({
                                SortColumn: key,
                                SortOrder: order,
                            })
                        }
                        style={getFixedCssWidths(200, false)}
                        sortOrder={
                            sort.SortColumn == RoleSortColumn.Name
                                ? sort.SortOrder
                                : null
                        }
                    />

                    <th style={getFixedCssWidths(400, false)}>
                        {t("roleManagement.role.description.name")}
                    </th>
                    <th style={getFixedCssWidths(300, false)}>
                        {t("roleManagement.role.assignmentSummary")}
                    </th>
                    <th style={getFixedCssWidths(60)} />
                    <th style={getFixedCssWidths(60)} />
                    <th style={getFixedCssWidths(70.5, false)} />
                </tr>
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>

                {roles &&
                    roles.length > 0 &&
                    roles.map((x) => (
                        <RoleManagementTableRow
                            openDialog={openDialog}
                            key={x.Id}
                            value={x}
                            cloneRole={cloneRole}
                            removeItem={onDelete}
                            deviceType={deviceType}
                            permissionMap={permissionMap}
                        />
                    ))}

                {loading && (
                    <AppTableSkeleton
                        rows={roles && roles.length > 0 ? 2 : 12}
                        columns={COLUMNS_COUNT}
                    />
                )}

                {roles && roles.length == 0 && !loading && (
                    <tr className="dummy-row">
                        <td
                            colSpan={COLUMNS_COUNT}
                            style={{ textAlign: "center" }}
                        >
                            {t("roleManagement.role.roleListEmpty")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default RoleManagementTable;
