import { useRouting } from "hooks/general/routing";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EmployeeVacationCreateEdit } from "./partials";

export const EmployeeVacationCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const linkProviderBase = linkProvider.business.employees().screens.withId();

    return (
        <EmployeeVacationCreateEdit
            onBack={(year?: number) =>
                navigate(
                    linkProviderBase.vacations.list(),
                    year ? { state: { year: year } } : undefined
                )
            }
        />
    );
};

export default EmployeeVacationCreate;
