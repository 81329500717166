import { Kindergarten_Age_Group_Planing, Kindergarten_Occupancy_Planing } from "models";
import { RoutesMap } from "routing";
import { KindergartenOccupancyPlanning } from "screens";
import { kindergartenClientRoutesMap } from "./client/routesMap";
import kindergartenRoutes from "./routes";
import { Feature_Kindergarten_AgeGroup_Planing, Feature_Kindergarten_Occupancy_Planing } from "models/applicationFeatures";
import { KindergartenAgeGroupPlanning } from "screens/kindergarten/ageGroupPlaning";

const relativeRoutes = kindergartenRoutes(false).screens;
const fullRoutes = kindergartenRoutes().screens;

export const kindergartenRoutesMap: RoutesMap = {
    [relativeRoutes.occupancyPlanning()]: {
        component: KindergartenOccupancyPlanning,
        index: true,
        fullPattern: fullRoutes.occupancyPlanning(),
        pattern: relativeRoutes.occupancyPlanning(),
        permKeys: [Kindergarten_Occupancy_Planing],
        featureKeys: [Feature_Kindergarten_Occupancy_Planing],
    },
    [relativeRoutes.ageGroupPlanning()]: {
        component: KindergartenAgeGroupPlanning,
        index: true,
        fullPattern: fullRoutes.ageGroupPlanning(),
        pattern: relativeRoutes.ageGroupPlanning(),
        permKeys: [Kindergarten_Age_Group_Planing],
        featureKeys: [Feature_Kindergarten_AgeGroup_Planing],
    },
    ...kindergartenClientRoutesMap,
};
