import { cookieAvailable } from "globals/helpers/cookieHelper";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { isNil } from "lodash-es";
import {
    Business,
    BusinessContextResponse,
    BusinessContract,
    BusinessLogo,
    BusinessType,
} from "models";
import { User } from "models/auth/model";
import { DashboardTabs } from "models/dashboard";
import { CookiesKey } from "models/general";
import { LocationSettingDetails } from "models/locationSetting";
import { useCallback, useMemo } from "react";
import { useInitUserInfo } from "./authHelpers";
import { setUser } from "@sentry/react";

export const useSessionBusiness = () => {
    const { userInfo, isContextLoaded } = useAppContext();
    const { isAuthenticated } = useSessionUser();
    const toReturn = useMemo(() => {
        if (
            isContextLoaded &&
            isAuthenticated &&
            userInfo &&
            userInfo.SessionBusiness
        ) {
            const isLw = userInfo.SessionBusiness.Type === BusinessType.LW;
            return {
                id: userInfo.SessionBusiness.Id,
                isLw: isLw,
                hqId: userInfo.SessionBusiness.HqId,
                encodedId: userInfo.SessionBusiness.EncodedId,
                sessionBusiness: userInfo.SessionBusiness,
                logo: userInfo.SessionBusiness.UseCompanyLogo
                    ? userInfo.SessionBusiness.CompanyLogo
                    : userInfo.SessionBusiness.FacilityLogo,
                isContractExpired:
                    !userInfo.SessionBusiness.HasActiveContract && !isLw,
                locationId: userInfo.SessionBusinessLocation?.Id,
                sessionBusinessLocation: userInfo.SessionBusinessLocation,
            };
        }
        return {
            id: 0,
            hqId: 0,
            encodedId: null,
            isContractExpired: false,
            sessionBusiness: null,
            sessionBusinessLocation: null,
            locationId: null,
        };
    }, [isAuthenticated, userInfo, isContextLoaded]);

    return toReturn;
};
export const useSessionBusinessContractInfo = () => {
    const { userInfo, setUserInfo, isContextLoaded } = useAppContext();
    const { isAuthenticated } = useSessionUser();

    const setBusinessContractInfo = useCallback(
        (contract: BusinessContract) => {
            if (!isNil(userInfo)) {
                setUserInfo({ ...userInfo, CurrentContractModules: contract });
            }
        },
        [userInfo]
    );
    return {
        setBusinessContractInfo,
        currentContract:
            isAuthenticated && isContextLoaded
                ? userInfo?.CurrentContractModules
                : null,
    };
};
export const useSessionUser = () => {
    const { userInfo, isContextLoaded } = useAppContext();
    //first of all check cookies are available or not
    const hasCookie = cookieAvailable(CookiesKey.USER_COOKIE);

    const toReturn = useMemo(() => {
        if (isContextLoaded && hasCookie && userInfo && userInfo.User) {
            return {
                id: userInfo.User.Id,
                user: userInfo.User,
                isAuthenticated: true,
                loaded: isContextLoaded,
            };
        }
        return {
            id: null,
            user: null,
            loaded: isContextLoaded,
            isAuthenticated: false,
        };
    }, [userInfo, isContextLoaded, hasCookie]);

    return toReturn;
};

export const useActiveDashboardTab = () => {
    const { userInfo, setUserInfo } = useAppContext();

    const activeTab = useMemo(() => {
        return userInfo && userInfo.ActiveDashboardTab
            ? userInfo.ActiveDashboardTab
            : DashboardTabs.CLIENTS;
    }, [userInfo]);
    const setActiveTab = useCallback(
        (tab: DashboardTabs) => {
            if (userInfo) {
                setUserInfo({ ...userInfo, ActiveDashboardTab: tab });
            }
        },
        [userInfo]
    );

    return { activeTab, setActiveTab };
};
export const useUpdateSessionBusinessInfo = () => {
    const { userInfo, setUserInfo } = useAppContext();

    const setSessionBusinessAndLocation = useCallback(
        (newContext: BusinessContextResponse) => {
            if (!isNil(userInfo)) {
                setUserInfo({ ...userInfo, ...newContext });
            }
        },
        [userInfo]
    );
    const setSessionBusiness = useCallback(
        (business: Business) => {
            if (!isNil(userInfo)) {
                setUserInfo({ ...userInfo, SessionBusiness: business });
            }
        },
        [userInfo]
    );
    const updateSessionBusinessLogo = useCallback(
        (newLogo: BusinessLogo) => {
            if (!isNil(userInfo)) {
                setUserInfo({
                    ...userInfo,
                    SessionBusiness: {
                        ...userInfo.SessionBusiness,
                        UseCompanyLogo: newLogo.UseCompanyLogo,
                        CompanyLogo: newLogo.CompanyLogo,
                        FacilityLogo: newLogo.FacilityLogo,
                    },
                });
            }
        },
        [userInfo]
    );
    const setSessionBusinessLocation = useCallback(
        (locationSetting: LocationSettingDetails) => {
            if (!isNil(userInfo)) {
                setUserInfo({
                    ...userInfo,
                    SessionBusinessLocation: locationSetting,
                });
            }
        },
        [userInfo]
    );

    return {
        setSessionBusinessAndLocation,
        setSessionBusiness,
        setSessionBusinessLocation,
        updateSessionBusinessLogo,
    };
};
export function useUpdateSessionUser() {
    const { setLocale, userInfo } = useAppContext();
    const { initUserInfo: updateSessionUser } = useInitUserInfo();

    const updateUserInfo = useCallback(
        (data: User, updateLocale: boolean = false) => {
            if (updateSessionUser && userInfo) {
                updateSessionUser({ ...userInfo, User: data }, true);

                setUser({ email: data.Email, id: data.Id });
                if (updateLocale) {
                    setLocale(data.Locale);
                }
            }
        },
        [updateSessionUser, userInfo]
    );
    return { updateUserInfo };
}
