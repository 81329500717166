import {
    AppDatePicker,
    AppNumberInput,
    AppSwitch,
    AppTimePicker,
} from "components/FormFields";
import { WeekDayCheckboxes } from "commonPartials/WeekDayCheckboxes";
import { FORMAT_INTEGER, ISO8601_DATE_FORMAT } from "globals/constants";
import { defaultTo, isNil } from "lodash-es";
import {
    DurationPeriodTypeNumber,
    getInitializedValidityState,
    Optional,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import { JobSchedule, validateJobSchedule } from "models/jobSchedule";
import { WorkingDaysNumber } from "models/workingDays";
import moment from "moment";
import { Moment } from "moment-timezone";
import React, { useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./JobScheduleFields.module.scss";

export interface JobScheduleFieldsProps {
    value: JobSchedule;
    readonly: boolean;
    canEditStartDate?: boolean;
    forDialog?: boolean;
    onChange: (value: JobSchedule) => void;
    validityState?: ValidityState;
}

const calenderFilterList = Object.keys(DurationPeriodTypeNumber)
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x));

export const JobScheduleFields: React.FC<JobScheduleFieldsProps> = ({
    value,
    readonly,
    canEditStartDate = true,
    forDialog = false,
    onChange,
    validityState = null,
}) => {
    const { t } = useTranslation();

    const colSize = forDialog ? 12 : 6;

    const validityStateManager = useMemo(() => {
        const validationState = validateJobSchedule(
            value,
            defaultTo(validityState, getInitializedValidityState([], []))
        );
        return new ValidityStateManager(validationState);
    }, [value, validityState, value.noLimitRecurrence]);

    return (
        <div className={styles.root}>
            <Row>
                <Col xs={colSize}>
                    <AppDatePicker
                        readOnly={readonly || !canEditStartDate}
                        label={t("common.startDate.name")}
                        value={moment(value.StartDate, ISO8601_DATE_FORMAT)}
                        minDate={moment()}
                        className={styles.label}
                        onChange={(val: Optional<Moment>) => {
                            if (!isNil(val)) {
                                onChange({
                                    ...value,
                                    StartDate: val,
                                });
                            }
                        }}
                        error={validityStateManager.getFirstErrorInfo(
                            "StartDate"
                        )}
                    />
                </Col>
                <Col xs={colSize} className="d-flex align-items-center">
                    <div style={{ width: "100%" }} className="mr-3">
                        <AppTimePicker
                            label={t("jobSchedule.time.name")}
                            placeholder={t("jobSchedule.time.name")}
                            readOnly={readonly}
                            value={defaultTo(value.Time, moment())}
                            onChange={(val: Optional<Moment>) => {
                                if (!isNil(val)) {
                                    onChange({
                                        ...value,
                                        Time: val,
                                    });
                                }
                            }}
                            error={validityStateManager.getFirstErrorInfo(
                                "Time"
                            )}
                        />
                    </div>
                    <div className={`${styles.repetaJob} `}>
                        <AppSwitch
                            label={t("jobSchedule.repeat")}
                            disabled={readonly}
                            className="d-flex align-items-center"
                            value={value.IsRecurring}
                            labelPosition="right"
                            onChange={(checked) => {
                                const newVal = {
                                    ...value,
                                    IsRecurring: checked,
                                };
                                if (!checked) {
                                    newVal.EndDate = undefined;
                                }
                                if (checked) {
                                    if (newVal.RecurrenceLevel == undefined) {
                                        newVal.RecurrenceLevel =
                                            DurationPeriodTypeNumber.Week;
                                    }
                                }
                                onChange(newVal);
                            }}
                        />
                    </div>
                </Col>
                {value.IsRecurring && (
                    <>
                        {value.EndDate && (
                            <Col xs={colSize}>
                                <AppDatePicker
                                    className={styles.label}
                                    label={t("common.endDate.name")}
                                    readOnly={readonly}
                                    value={moment(
                                        value.EndDate,
                                        ISO8601_DATE_FORMAT
                                    )}
                                    minValue={defaultTo(
                                        value.StartDate,
                                        moment()
                                    )}
                                    onChange={(val: Optional<Moment>) => {
                                        if (val !== null) {
                                            onChange({
                                                ...value,
                                                EndDate: val,
                                            });
                                        }
                                    }}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "EndDate"
                                    )}
                                />
                            </Col>
                        )}
                        <Col
                            xs={6}
                            className={`${styles.recurrenceSwitch} mb-2 d-flex align-items-center`}
                        >
                            <AppSwitch
                                className={styles.hasNoEndDate}
                                label={t("common.endDate.hasNoEndDate")}
                                disabled={readonly}
                                value={value.EndDate == null}
                                labelPosition="right"
                                onChange={(checked) => {
                                    if (checked == true) {
                                        onChange({
                                            ...value,
                                            EndDate: undefined,
                                        });
                                    } else {
                                        onChange({
                                            ...value,
                                            EndDate: moment(),
                                        });
                                    }
                                }}
                            />
                        </Col>

                        <Col
                            xs={12}
                            className={
                                value.EndDate != null &&
                                value.RecurrenceLevel !=
                                    DurationPeriodTypeNumber.Day
                                    ? "mb-4"
                                    : ""
                            }
                        >
                            {calenderFilterList.map((x) => (
                                <Button
                                    disabled={readonly}
                                    active={
                                        value.RecurrenceLevel != null &&
                                        value.RecurrenceLevel ==
                                            (x as DurationPeriodTypeNumber)
                                    }
                                    key={x}
                                    className={`${styles.filterButton} ${
                                        value.RecurrenceLevel != null &&
                                        value.RecurrenceLevel ==
                                            (x as DurationPeriodTypeNumber) &&
                                        styles.active
                                    } mr-2 `}
                                    onClick={() => {
                                        const newVal: JobSchedule = {
                                            ...value,
                                            RecurrenceLevel:
                                                x as DurationPeriodTypeNumber,
                                        };
                                        if (
                                            x == DurationPeriodTypeNumber.Day &&
                                            newVal.RecurrenceDays == null
                                        ) {
                                            newVal.RecurrenceDays = [];
                                        }
                                        onChange(newVal);
                                    }}
                                >
                                    {t(
                                        `common.${DurationPeriodTypeNumber[x]
                                            .toString()
                                            .toLocaleLowerCase()}`
                                    )}
                                </Button>
                            ))}
                        </Col>
                        <Col xs={12} className={`${styles.conditionalFields}`}>
                            {value.RecurrenceLevel !=
                                DurationPeriodTypeNumber.Day &&
                            value.EndDate == null ? (
                                <div className={styles.hasNoLimitDiv}>
                                    <div className={styles.noLimit}>
                                        <AppSwitch
                                            label={t(
                                                "jobSchedule.noLimitRecurrence"
                                            )}
                                            disabled={readonly}
                                            value={value.noLimitRecurrence}
                                            labelPosition="right"
                                            onChange={(checked) => {
                                                {
                                                    onChange({
                                                        ...value,
                                                        RecurrenceCount: 0,
                                                        noLimitRecurrence:
                                                            checked,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    {!value.noLimitRecurrence && (
                                        <div style={{ width: "95%" }}>
                                            <AppNumberInput
                                                className={styles.numberField}
                                                readonly={readonly}
                                                label={t("jobSchedule.repeat")}
                                                showError={true}
                                                value={defaultTo(
                                                    value.RecurrenceCount,
                                                    0
                                                )}
                                                showLabel={false}
                                                error={validityStateManager.getFirstErrorInfo(
                                                    "RecurrenceCount"
                                                )}
                                                onChange={(newVal) => {
                                                    onChange({
                                                        ...value,
                                                        RecurrenceCount:
                                                            newVal != null
                                                                ? newVal
                                                                : undefined,
                                                    });
                                                }}
                                                format={FORMAT_INTEGER}
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : value.RecurrenceLevel ==
                              DurationPeriodTypeNumber.Day ? (
                                <WeekDayCheckboxes
                                    value={value.RecurrenceDays}
                                    error={
                                        value.RecurrenceLevel ==
                                            DurationPeriodTypeNumber.Day &&
                                        value.RecurrenceDays.length == 0
                                            ? validityStateManager.getFirstErrorInfo(
                                                  "RecurrenceDays"
                                              )
                                            : undefined
                                    }
                                    readonly={readonly}
                                    onChange={(val: WorkingDaysNumber[]) => {
                                        onChange({
                                            ...value,
                                            RecurrenceDays: val,
                                        });
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
};

export default JobScheduleFields;
