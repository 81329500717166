import { isNil } from "lodash-es";
import { ErrorInfo, getFullErrorString } from "models/general/validityState";
import React, { CSSProperties } from "react";

export interface ErrorMessageProps {
    errorInfo?: ErrorInfo | ErrorInfo[] | null;
    showEmpty?: boolean;
    style?: CSSProperties;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
    errorInfo,
    showEmpty = false,
    style = {},
}) => {
    const error = errorInfo ? (
        <span
            className="text-danger"
            style={{ display: "block", paddingTop: "3px" }}
        >
            {!isNil(errorInfo) && errorInfo instanceof Array
                ? getFullErrorString(errorInfo)
                : errorInfo.message}
        </span>
    ) : null;
    return showEmpty ? (
        <div
            style={{
                ...style,
                display: "block",
                minHeight: "17px",
                lineHeight: 1,
            }}
        >
            {error}
        </div>
    ) : (
        error
    );
};

export default ErrorMessage;
