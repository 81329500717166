import { AppIconTextButton } from "components/Buttons";
import { AppSortable } from "components/Sortable";
import { ValidityStateManager, ValidityState } from "models/general";
import {
    CompensationTemplateType,
    CompensationTemplateFieldType,
    CompensationTemplateFieldTypeManager,
    LillywaitCompensationTemplateMainField,
    LillywaitCompensationTemplateField,
    LillywaitCompensationTemplateModulePriceField,
    LWCompensationTemplateFieldCategory,
} from "models/compensationTemplate";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { arrayMove, SortableElement } from "react-sortable-hoc";
import LwCompensationTemplatePaymentsFieldRow from "./LwCompensationTemplatePaymentsFieldRow";
import LwCompensationTemplateModulePricingFieldRow from "./LwCompensationTemplateModulePricingFieldRow";
import { useRouting } from "hooks/general/routing";
import { SimpleOption } from "components/AppSelect/partials";
import { useQuery } from "react-query";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";

const sortableVariablePaymentField: any = SortableElement(
    LwCompensationTemplatePaymentsFieldRow
);

const sortableModulePricingField: any = SortableElement(
    LwCompensationTemplateModulePricingFieldRow
);

export interface LwCompensationTemplateFieldsContainerProps {
    fields: LillywaitCompensationTemplateField[];
    validityState?: ValidityState;
    onChange: (newFields: LillywaitCompensationTemplateField[]) => void;
    readonly?: boolean;
    locationSettingId?: number;
    businessId?: string;
}

export const LwCompensationTemplateFieldsContainer: React.FC<
    LwCompensationTemplateFieldsContainerProps
> = ({
    fields,
    onChange,
    validityState,
    readonly = false,
    locationSettingId,
    businessId,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();

    const stateManager = useMemo(() => {
        return new ValidityStateManager(validityState);
    }, [fields, t]);

    const variablePaymentFieldManager =
        CompensationTemplateFieldTypeManager[
            CompensationTemplateFieldType.LillywaitCompensation
        ];

    const lillywaitModuleFieldManager =
        CompensationTemplateFieldTypeManager[
            CompensationTemplateFieldType.LillywaitModule
        ];

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(businessId)
    );

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;

    const { isRefetching: getModulePlanLoading, data: modulePlans } = useQuery(
        getCurrentBusinessServiceKey("getModulePlanOptions", {
            locationSettingId,
        }),
        async () =>
            await businessService.getModulePlanOptions(
                locationSettingId?.toString()
            )
    );

    const modulePlanOptions =
        modulePlans?.Data && modulePlans.Data.length > 0
            ? [
                  pleaseSelectOption,
                  ...modulePlans?.Data.map((x) => {
                      return { label: x.Text, value: x.Value } as SimpleOption;
                  }),
              ]
            : [pleaseSelectOption];
    const handleVariablePaymentSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (!readonly) {
            onChange([
                ...fields.filter(
                    (x) =>
                        x.Type ==
                            CompensationTemplateFieldType.LillywaitModule ||
                        (x as LillywaitCompensationTemplateMainField)
                            .Category ==
                            LWCompensationTemplateFieldCategory.Slab
                ),
                ...arrayMove(
                    fields.filter(
                        (x) =>
                            (x as LillywaitCompensationTemplateMainField)
                                .Category ==
                            LWCompensationTemplateFieldCategory.Range
                    ),
                    oldIndex,
                    newIndex
                ),
            ]);
        }
    };

    const handleModulePricingSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (!readonly) {
            onChange([
                ...fields.filter(
                    (x) =>
                        x.Type ==
                        CompensationTemplateFieldType.LillywaitCompensation
                ),
                ...arrayMove(
                    fields.filter(
                        (x) =>
                            x.Type ==
                            CompensationTemplateFieldType.LillywaitModule
                    ),
                    oldIndex,
                    newIndex
                ),
            ]);
        }
    };

    const variablePaymentFields = fields
        .filter(
            (x) =>
                x.Type == CompensationTemplateFieldType.LillywaitCompensation &&
                (x as LillywaitCompensationTemplateMainField).Category ==
                    LWCompensationTemplateFieldCategory.Range
        )
        .map((x) => x as LillywaitCompensationTemplateMainField);
    const moduleFields = fields
        .filter(
            (x) =>
                (x as LillywaitCompensationTemplateModulePriceField).Type ==
                CompensationTemplateFieldType.LillywaitModule
        )
        .map((x) => x as LillywaitCompensationTemplateModulePriceField);
    return (
        <Row>
            <Col md={12}>
                <h6 className="font-weight-bold">
                    {t("compensationTemplate.fixedPayments")}
                </h6>
            </Col>
            <Col md={12}>
                {fields
                    .filter(
                        (x) =>
                            (x as LillywaitCompensationTemplateMainField)
                                .Category ==
                            LWCompensationTemplateFieldCategory.Slab
                    )
                    .map((f) => (
                        <LwCompensationTemplatePaymentsFieldRow
                            key={f.Uuid}
                            validityState={
                                stateManager.getFieldState(f.Uuid)?.children
                            }
                            value={f as LillywaitCompensationTemplateMainField}
                            readonly={readonly}
                            onChange={(field) =>
                                onChange(
                                    fields.map((nf) => {
                                        if (nf.Uuid == field.Uuid) {
                                            return field;
                                        } else {
                                            return nf;
                                        }
                                    })
                                )
                            }
                        />
                    ))}
            </Col>
            <Col md={12} className="mt-3">
                <h6 className="font-weight-bold">
                    {t("compensationTemplate.variablePayments")}
                </h6>
            </Col>
            <Col md={12}>
                <AppIconTextButton
                    padding="med"
                    icon="plus"
                    text={t("compensationTemplate.addNewCriteria")}
                    disabled={readonly}
                    onClick={() => {
                        onChange([
                            ...fields,
                            variablePaymentFieldManager.defaultValue(
                                LWCompensationTemplateFieldCategory.Range
                            ),
                        ]);
                    }}
                />
            </Col>
            <Col md={12}>
                <AppSortable<LillywaitCompensationTemplateMainField>
                    SortableItem={sortableVariablePaymentField}
                    handleSortEnd={handleVariablePaymentSortEnd}
                    list={variablePaymentFields}
                    validityState={validityState}
                    readonly={readonly}
                    onChange={(field: LillywaitCompensationTemplateMainField) =>
                        onChange(
                            fields.map((nf) => {
                                if (nf.Uuid == field.Uuid) {
                                    return field;
                                } else {
                                    return nf;
                                }
                            })
                        )
                    }
                    onDelete={(uuid: string) =>
                        onChange(fields.filter((x) => x.Uuid != uuid))
                    }
                />
            </Col>

            <Col md={12} className="mt-3">
                <h6 className="font-weight-bold">
                    {t("compensationTemplate.modulesPricing.name")}
                </h6>
            </Col>
            <Col md={12}>
                <AppIconTextButton
                    padding="med"
                    icon="plus"
                    text={t("compensationTemplate.modulesPricing.add")}
                    disabled={readonly}
                    onClick={() => {
                        onChange([
                            ...fields,
                            lillywaitModuleFieldManager.defaultValue(),
                        ]);
                    }}
                />
            </Col>
            <Col md={12}>
                <AppSortable<LillywaitCompensationTemplateModulePriceField>
                    SortableItem={sortableModulePricingField}
                    handleSortEnd={handleModulePricingSortEnd}
                    list={moduleFields}
                    validityState={stateManager.state}
                    readonly={readonly}
                    itemCommonProps={{
                        loading: getModulePlanLoading,
                        modulePlanOptions: modulePlanOptions,
                        existingModules: moduleFields.map((x) =>
                            x.LocationModuleId?.toString()
                        ),
                    }}
                    onChange={(
                        field: LillywaitCompensationTemplateModulePriceField
                    ) => {
                        onChange(
                            fields.map((nf) => {
                                if (nf.Uuid == field.Uuid) {
                                    return field;
                                } else {
                                    return nf;
                                }
                            })
                        );
                    }}
                    onDelete={(uuid: string) =>
                        onChange(fields.filter((x) => x.Uuid != uuid))
                    }
                />
            </Col>
        </Row>
    );
};

export default LwCompensationTemplateFieldsContainer;
