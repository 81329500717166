import { AppTable, AppTableSkeleton } from "components/Table";
import { useRouting } from "hooks/general/routing";
import { ResponseMeta, SortOrder, TableSort } from "models/general";
import {
    ClientRegistrationSortColumn,
    ClientRegistrationStatus,
    KindergartenClientRegistrationsFilterSelectOptions,
} from "models/kindergartenClientRegistration";
import {
    getKindergartenClientRegistrationFromResponse,
    getKindergartenClientRegistrationRequest,
} from "models/kindergartenClientRegistration/helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    getKindergartenClientRegistrationServiceKey,
    KindergartenClientRegistrationService,
} from "services/kindergarten/KindergartenClientRegistrationService";
import { useCheckPermission } from "hooks/permissionCheck";
import { Kindergarten_Client_ExternalRegistrations } from "models";
import { useNavigate } from "react-router";
import KindergartenClientRegistrationFilterRow from "screens/kindergarten/clientRegistration/partials/table/KindergartenClientRegistrationFilterRow";
import {
    KindergartenClientRegistrationHeaderRow,
    KindergartenClientRegistrationRow,
} from "screens/kindergarten/clientRegistration/partials";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce, defaultTo } from "lodash-es";
import { AppTableTotal } from "commonPartials/AppTableTotal";
import {
    KindergartenClientRegistration,
    KindergartenClientRegistrationFilter,
} from "models/kindergartenClientRegistration/model";
import styles from "./KindergartenClientRegistration.module.scss";
import classNames from "classnames";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { useWebEventHub } from "hooks/layout/useWebEventHub";
import { WebEventType } from "models/notification";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";

interface ClientRegistrationState {
    filter: KindergartenClientRegistrationFilter;
    sort: TableSort<ClientRegistrationSortColumn>;
    list: KindergartenClientRegistration[] | undefined;
    filterSelectOptions: KindergartenClientRegistrationsFilterSelectOptions;
    total: number;
    meta: ResponseMeta;
}
const PAGE_SIZE = 100;
const COLUMNS_COUNT = 12;
export const KindergartenClientRegistrationList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const { signalR } = useWebEventHub();
    const { getPermissionMap } = useCheckPermission();
    const { id: sessionBusinessId } = useSessionBusiness();
    const permMap = getPermissionMap(Kindergarten_Client_ExternalRegistrations);

    const linkProviderBase =
        linkProvider.kindergarten.clients().screens.registrations;

    const registrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );

    const [state, setState] = useState<ClientRegistrationState>({
        filter: {
            Status: ClientRegistrationStatus.Active,
        } as KindergartenClientRegistrationFilter,
        sort: {
            SortColumn: ClientRegistrationSortColumn.CreatedAt,
            SortOrder: SortOrder.DESC,
        },
        filterSelectOptions: {
            AgeGroups: [],
            CareScopes: [],
            CareStartYears: [],
            BirthdayYears: [],
            Years: [
                {
                    Text: new Date().getFullYear().toString(),
                    Value: new Date().getFullYear(),
                },
            ],
        },
        list: undefined,
        total: 0,
        meta: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
            HasNextPage: false,
        } as ResponseMeta,
    });

    const listRequest = getKindergartenClientRegistrationRequest(
        state.filter,
        state.sort,
        state.meta
    );

    const {
        isLoading: loading,
        isRefetching: refetching,
        data: listResponse,
        refetch,
    } = useQuery(
        getKindergartenClientRegistrationServiceKey("list", listRequest),
        async () => await registrationService.list(listRequest),
        {
            enabled: false,
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );
    const debouncedRefetch = useCallback(debounce(refetch, 1000), [refetch]);

    useEffect(() => {
        if (signalR) {
            signalR.on("eventReceived", (event: any) => {
                // used any, because signalR gives objects in camel case in response
                if (
                    sessionBusinessId == event.businessId &&
                    event.type ==
                        WebEventType.Refresh_Kindergarten_Client_Registration_List
                ) {
                    refetch();
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!refetching && !loading && listResponse?.Meta) {
            const convertedData = listResponse.Data.Records.map((x) =>
                getKindergartenClientRegistrationFromResponse(x)
            );
            setState({
                ...state,
                list:
                    listResponse.Meta.PageNumber == 1
                        ? convertedData
                        : [...defaultTo(state.list, []), ...convertedData],
                filterSelectOptions: listResponse.Data.FilterOptions,
                meta: listResponse.Meta,
                total: listResponse.Meta.TotalItemCount as number,
            });
        }
    }, [refetching, loading]);

    useEffect(() => {
        if (state.list && !loading && !refetching && debouncedRefetch) {
            debouncedRefetch();
        }
    }, [debouncedRefetch, state.filter, state.sort]);

    useEffect(() => {
        if (!refetching) {
            refetch();
        }
    }, [state.meta.PageNumber]);

    const { mutate: deleteRegistration, isLoading: deleting } = useMutation(
        async (id: string) => await registrationService.delete(id),
        {
            onSuccess: (res) => {
                const result = defaultTo(state.list, []).filter(
                    (x) => x.Id == res.Data
                );
                if (result.length > 0) {
                    const toDelete = result[0];
                    showSweetAlertToast(
                        t("common.success"),
                        `"${toDelete.Name}" ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );
                    setState((old) => ({
                        ...old,
                        total: Math.max(0, old.total - 1),
                        list: defaultTo(old.list, []).filter(
                            (x) => x.Id != res.Data
                        ),
                    }));
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const onSortChange = (
        sortColumn: ClientRegistrationSortColumn,
        sortOrder: SortOrder
    ) => {
        setState({
            ...state,
            meta: {
                ...state.meta,
                PageNumber: 1,
            },
            sort: {
                ...state.sort,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };

    const dataList = useMemo(() => defaultTo(state.list, []), [state.list]);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <InfiniteScroll
                    className={styles.infiniteScroll}
                    dataLength={dataList.length}
                    next={() => {
                        if (state.meta.HasNextPage) {
                            setState({
                                ...state,
                                meta: {
                                    ...state.meta,
                                    PageNumber:
                                        defaultTo(state.meta.PageNumber, 1) + 1,
                                },
                            });
                        }
                    }}
                    loader={<></>}
                    hasMore={defaultTo(state.meta.HasNextPage, false)}
                    scrollableTarget="scrollableDiv"
                >
                    <AppTable
                        id="scrollableDiv"
                        tableClass={classNames("table-striped")}
                        hover={true}
                        stickyHeader={true}
                    >
                        <thead>
                            <KindergartenClientRegistrationHeaderRow
                                value={state.sort}
                                onChange={(
                                    key: ClientRegistrationSortColumn,
                                    order: SortOrder
                                ) => onSortChange(key, order)}
                            />
                        </thead>
                        <tbody>
                            <KindergartenClientRegistrationFilterRow
                                value={state.filter}
                                filterSelectOptions={state.filterSelectOptions}
                                onChange={(filter) =>
                                    setState({
                                        ...state,
                                        filter: filter,
                                        meta: {
                                            ...state.meta,
                                            PageNumber: 1,
                                        },
                                    })
                                }
                            />
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                            {dataList.length > 0 &&
                                dataList.map((b, idx) => (
                                    <KindergartenClientRegistrationRow
                                        key={`${idx}_${b.Id}`}
                                        value={b}
                                        permMap={permMap}
                                        onClick={(id) => {
                                            navigate(linkProviderBase.edit(id));
                                        }}
                                        loading={deleting}
                                        onDeleteClick={(id) =>
                                            deleteRegistration(id)
                                        }
                                    />
                                ))}

                            {state.list &&
                                state.list.length == 0 &&
                                !refetching &&
                                !loading && (
                                    <tr>
                                        <td
                                            colSpan={COLUMNS_COUNT}
                                            style={{ textAlign: "center" }}
                                        >
                                            {t("common.noDataFound")}
                                        </td>
                                    </tr>
                                )}

                            {(loading || refetching) && (
                                <AppTableSkeleton
                                    columns={COLUMNS_COUNT}
                                    rows={dataList.length > 0 ? 2 : 12}
                                />
                            )}
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                        </tbody>
                    </AppTable>
                </InfiniteScroll>
            </div>
            <AppTableTotal
                text={t("kindergartenClient.registration.totalRecords")}
                count={dataList.length}
                total={state.total}
            />
        </div>
    );
};
export default KindergartenClientRegistrationList;
