import classNames from "classnames";
import { MetisMenuCommonProps, MetisMenuItemType } from "models/metisMenu";
import React from "react";
import { Link } from "react-router-dom";

export interface MetisMenuLinkProps extends MetisMenuCommonProps {
    toggleMenu?: (value: number) => void;
    children: React.ReactNode;
    item: MetisMenuItemType;
    className?: string;
    onClick?: (value: MetisMenuItemType) => void;
}
export const MetisMenuLinker = React.memo<MetisMenuLinkProps>(
    ({ classStore, toggleMenu, children, item, className, onClick }) => {
        const { id, active, hasActiveChild, to, hasSubMenu, subMenuItems } =
            item;

        const redirectTo = to
            ? to
            : hasSubMenu &&
              subMenuItems &&
              subMenuItems?.length > 0 &&
              subMenuItems[0].to
            ? subMenuItems[0].to
            : "#";

        return (
            <Link
                className={classNames(
                    classStore.classLink,
                    className,
                    active && classStore.classLinkActive,
                    hasActiveChild && classStore.classLinkHasActiveChild
                )}
                to={redirectTo}
                onClick={(e) => {
                    if (e.ctrlKey) {
                        return false;
                    }
                    if (toggleMenu) {
                        toggleMenu(id);
                    }
                    if (onClick) {
                        onClick(item);
                    }
                }}
            >
                {children}
            </Link>
        );
    }
);

export default MetisMenuLinker;
