import { SearchField } from "components/FormFields";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import { defaultTo } from "lodash";
import { Optional } from "models/general";
import {
    AccessPointAction,
    getAccessPointActionString,
    TimeManagementTotals,
} from "models/timeManagement";
import React from "react";
import { useTranslation } from "react-i18next";
import TimeManagementColorHighlight from "../TimeManagementColorHighlight";
import styles from "./TimeManagementEmployeeDayHeader.module.scss";
import TimeManagementHeaderContainer from "./TimeManagementHeaderContainer";
export interface TimeManagementEmployeeDayHeaderProps {
    value: TimeManagementTotals;
    personName: Optional<string>;
    className?: string;
    onSelectChangeHandler: (val: string) => void;
}
export const TimeManagementEmployeeDayHeader: React.FC<
    TimeManagementEmployeeDayHeaderProps
> = ({
    value,
    personName: PersonName,
    onSelectChangeHandler,
    className = "",
}) => {
    const { t } = useTranslation();
    return (
        <TimeManagementHeaderContainer
            className={`${className} ${styles.table}`}
        >
            <tbody>
                <tr>
                    <td style={{ minWidth: "260px", padding: 5 }} />
                    <td style={{ minWidth: "90px", width: "90px" }}>
                        {t("timeManagement.active")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "140px", width: "140px" }}>
                        {getAccessPointActionString(AccessPointAction.IN)}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "140px", width: "140px" }}>
                        {getAccessPointActionString(AccessPointAction.OUT)}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "150px",
                            width: "150px",
                            maxWidth: "150px",
                        }}
                    >
                        {t("timeManagement.totalBreak")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.workingHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.maxWorkingHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "200px", width: "200px" }}>
                        {t("timeManagement.difference")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td style={{ minWidth: "170px", width: "170px" }}>
                        {t("timeManagement.manualEntry")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "250px",
                            width: "250px",
                        }}
                    >
                        {t("timeManagement.manualEntryComment")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "250px",
                            width: "250px",
                        }}
                    >
                        {t("timeManagement.description")}
                    </td>
                </tr>
                <tr className={styles.totals}>
                    <td>
                        <SearchField
                            value={defaultTo(PersonName, "")}
                            className={styles.search}
                            onValueChange={onSelectChangeHandler}
                        />
                    </td>
                    <td
                        style={{
                            color: value.TotalRecords == 0 ? "black" : "",
                        }}
                    >
                        {value.TotalRecords}
                    </td>
                    <td
                        style={{
                            color: value.IN == 0 ? "black" : "",
                        }}
                    >
                        {value.OUT && value.IN}
                    </td>
                    <td
                        style={{
                            color: value.OUT == 0 ? "black" : "",
                        }}
                    >
                        {value.OUT && value.OUT}
                    </td>
                    <td>{getFormattedTimeFromNumber(value.BreakMinutes)}</td>
                    <td>{getFormattedTimeFromNumber(value.CareHours)}</td>
                    <td>{getFormattedTimeFromNumber(value.MaxCareHours)}</td>
                    <td>
                        <TimeManagementColorHighlight
                            differenceSumMinute={value.SumDifference}
                            isNegativeRed={true}
                            className={styles.hightLightContainer}
                        />
                    </td>
                    <td>
                        {getFormattedTimeFromNumber(value.ManualEntryDuration)}
                    </td>
                    <td />
                    <td />
                </tr>
            </tbody>
        </TimeManagementHeaderContainer>
    );
};

export default TimeManagementEmployeeDayHeader;
