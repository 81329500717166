import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { DurationPeriodTypeNumber } from "models/general/enum";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general/validityState";
import { WorkingDaysNumber } from "models/workingDays";
import moment from "moment";
import { Moment } from "moment-timezone";

export interface JobSchedule {
    Id: number;
    StartDate: Moment;
    EndDate?: Moment;
    Time: Moment;
    RecurrenceLevel?: DurationPeriodTypeNumber;
    RecurrenceCount?: number;
    RecurrenceDays: WorkingDaysNumber[];
    IsRecurring: boolean;
    noLimitRecurrence: boolean; // only for front-end
}

export function validateJobSchedule(
    jobSchedule: JobSchedule,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("jobSchedule.time.missing"),
            },
        ],
        jobSchedule.Time,
        "Time"
    );

    if (jobSchedule.IsRecurring) {
        if (!jobSchedule.noLimitRecurrence) {
            state = applyValidations(
                state,
                jobSchedule.EndDate == null &&
                    jobSchedule.RecurrenceLevel !== DurationPeriodTypeNumber.Day
                    ? [
                          {
                              rule: Validations.NUMBER,
                          },
                          {
                              rule: Validations.MIN,
                              message: i18next.t(
                                  "common.errorMessages.positiveNum"
                              ),
                              options: {
                                  value: 1,
                              },
                          },
                      ]
                    : [],
                jobSchedule.RecurrenceCount,
                "RecurrenceCount"
            );
        }

        if (
            jobSchedule.RecurrenceLevel === DurationPeriodTypeNumber.Day &&
            (!jobSchedule.RecurrenceDays ||
                (jobSchedule.RecurrenceDays &&
                    jobSchedule.RecurrenceDays.length === 0))
        ) {
            let mngr = new ValidityStateManager(state);
            mngr = mngr.addErrorInfo("RecurrenceDays", {
                code: "DaySelection",
                message: i18next.t("jobSchedule.recurrenceDayMissing"),
            });
            state = mngr.state;
        }
    }

    return state;
}

export const getJobScheduleDefaultValue = (): JobSchedule => {
    const time = moment();
    const diff = time.minutes() % 5;
    time.add(diff > 2 ? 5 - diff : -diff, "minutes");
    time.add(-time.seconds(), "seconds");
    return {
        Id: 0,
        StartDate: moment(),
        EndDate: undefined,
        Time: time,
        IsRecurring: true,
        RecurrenceLevel: DurationPeriodTypeNumber.Day,
        RecurrenceCount: 0,
        RecurrenceDays: [],
        noLimitRecurrence: false,
    };
};
