import { AppLoader, AppOverlayLoader } from "components/Loaders";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { toNumber } from "globals/helpers/localizationHelpers";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, sum } from "lodash-es";
import {
    calculateHeaderTotals,
    EmployeeType,
    getTimeTrackingHeaderDataEmpty,
    isBusinessCurrentlyOpen,
    parseTimeTrackingTableResponse,
    TimeTrackingHeaderData,
    TimeTrackingRecord,
    TimeTrackingRequestParameters,
} from "models";
import { DurationPeriodType, ErrorItem, UserType } from "models/general";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
    getTimeTrackingServiceKey,
    TimeTrackingService,
} from "services/business/TimeTrackingService";
import {
    TimeManagementDateRangeView,
    TimeManagementDayView,
    TimeManagementEmployeeDayView,
    TimeManagementPersonDetailView,
} from "./mainViews";
import TimeManagementMainHeader from "./partials/TimeManagementMainHeader";
import styles from "./TimeManagementMain.module.scss";

export interface TimeManagementMainProps {
    userType: UserType;
    employeeType?: EmployeeType;
}
export interface TimeManagementState {
    filterRequest: TimeTrackingRequestParameters;
    records: TimeTrackingRecord[] | null;
    manualAllowed: boolean;
    headerData: TimeTrackingHeaderData | null;
}

export const TimeManagementMain: React.FC<TimeManagementMainProps> = ({
    userType,
    employeeType,
}) => {
    const { t } = useTranslation();
    const { sessionBusiness, sessionBusinessLocation } = useSessionBusiness();
    const { linkProvider } = useRouting();
    const [searchParams, setSearchParams] = useSearchParams();

    const timeTrackingService = new TimeTrackingService(
        linkProvider.business.api.timeTracking
    );

    const [state, setState] = useState<TimeManagementState>({
        filterRequest: {
            BusinessId: sessionBusiness ? sessionBusiness.Id : 0,
            BusinessLocationId: sessionBusinessLocation
                ? sessionBusinessLocation.Id
                : 0,
            Date: moment(
                defaultTo(
                    searchParams.get("date"),
                    moment().format(ISO8601_DATE_FORMAT)
                ),
                ISO8601_DATE_FORMAT
            ),
            FilterType: defaultTo(
                searchParams.get("tab") as DurationPeriodType,
                DurationPeriodType.Day
            ),
            PersonId: toNumber(searchParams.get("personId")),
            ForEmployeeType: employeeType,
            UserType: userType,
            SelectedId: null,
            SearchValue: null,
            PersonName: null,
        } as TimeTrackingRequestParameters,
        records: null,
        manualAllowed: false,
        headerData: null,
    });
    useEffect(() => {
        refetch();
        setState((old) => ({
            ...old,
            filterRequest: {
                ...old.filterRequest,
                ForEmployeeType: employeeType,
                UserType: userType,
                PersonId: toNumber(searchParams.get("personId")),
                FilterType: defaultTo(
                    searchParams.get("tab") as DurationPeriodType,
                    DurationPeriodType.Day
                ),
            },
            records: null,
            manualAllowed: false,
            headerData: null,
        }));
    }, [userType, employeeType, searchParams.get("tab")]);
    const {
        data: response,
        isFetching,
        isLoading: loading,
        refetch,
        error,
    } = useQuery(
        getTimeTrackingServiceKey(
            "getEventList",
            JSON.stringify([
                state.filterRequest.Date,
                state.filterRequest.FilterType,
                state.filterRequest.UserType,
                state.filterRequest.PersonId,
            ])
        ),
        async () => {
            return await timeTrackingService.getEventList(state.filterRequest);
        },
        {
            cacheTime: 30 * 1000,
        }
    );

    useEffect(() => {
        if (response) {
            if (response.Data) {
                const parsedData = parseTimeTrackingTableResponse(
                    response.Data
                );

                const totalAvailableStaff =
                    parsedData.HeaderData && parsedData.HeaderData.EmpKeys
                        ? defaultTo(
                              sum(
                                  Object.values(
                                      parsedData.HeaderData.EmpKeys.GroupEmpKeys
                                  )
                              ),
                              parsedData.HeaderData.EmpKeys.TotalEmpKeys
                          )
                        : 0;

                let manualAllowed = defaultTo(
                    userType == UserType.Employee
                        ? parsedData.AllowEmployeeManualTimeTracking
                        : parsedData.AllowClientManualTimeTracking,
                    false
                );
                if (manualAllowed && parsedData.Timings) {
                    if (!isBusinessCurrentlyOpen(parsedData.Timings)) {
                        manualAllowed = false;
                    }
                }
                setState({
                    ...state,
                    records: parsedData.Records,
                    manualAllowed: manualAllowed,
                    headerData: {
                        ...parsedData.HeaderData,
                        Totals: calculateHeaderTotals(
                            parsedData.Records,
                            totalAvailableStaff,
                            userType == UserType.Employee
                        ),
                    },
                });
            } else if (response.Code && response.Errors) {
                let errorHtml = "";
                response.Errors.forEach((error: ErrorItem) => {
                    errorHtml = errorHtml + error.Message + "<br/>";
                });
                showSweetAlertToast(
                    t("common.error.error"),
                    errorHtml,
                    "error"
                );
            }
        } else if (error) {
            showUnexpectedErrorToast();
            setState({
                ...state,
                records: [],
                headerData: getTimeTrackingHeaderDataEmpty(),
                filterRequest: { ...state.filterRequest, PersonId: null },
            });
        }
    }, [isFetching, response, error]);

    React.useMemo(() => {
        if (state.filterRequest.SearchValue != null) {
            setState({
                ...state,
                filterRequest: {
                    ...state.filterRequest,
                    SearchValue: null,
                    SelectedId: null,
                    PersonName: null,
                },
            });
        }
    }, [
        state.filterRequest.Date,
        state.filterRequest.FilterType,
        state.filterRequest.UserType,
        state.filterRequest.PersonId,
    ]);
    const onFilterChange = useCallback(
        (filter: TimeTrackingRequestParameters) => {
            // if (
            //     filter.UserType === UserType.Client ||
            //     filter.ForEmployeeType === EmployeeType.Employee
            // ) {
            setSearchParams({
                ...searchParams,
                tab: filter.FilterType,
                ...(filter.Date
                    ? {
                          date: filter.Date.format(ISO8601_DATE_FORMAT),
                      }
                    : {}),
                ...(filter.PersonId
                    ? { personId: filter.PersonId.toString() }
                    : {}),
            });
            // }
            setState((old) => ({
                ...old,
                filterRequest: filter,
            }));
        },
        [searchParams]
    );

    const renderTabs = () => {
        if (
            state.filterRequest.FilterType == DurationPeriodType.Day &&
            state.filterRequest.PersonId == null
        ) {
            if (state.filterRequest.UserType === UserType.Client) {
                return (
                    <TimeManagementDayView
                        values={state}
                        manualAllowed={state.manualAllowed}
                        refetchData={refetch}
                        date={state.filterRequest.Date}
                        onFilterChange={onFilterChange}
                    />
                );
            } else {
                return (
                    <TimeManagementEmployeeDayView
                        refetchData={refetch}
                        value={state}
                        date={state.filterRequest.Date}
                        manualAllowed={state.manualAllowed}
                        onChange={(value) => setState(value)}
                        onFilterChange={onFilterChange}
                    />
                );
            }
        } else if (state.filterRequest.PersonId == null) {
            return (
                <TimeManagementDateRangeView
                    values={state}
                    onFilterChange={onFilterChange}
                />
            );
        } else {
            return (
                <TimeManagementPersonDetailView
                    values={state}
                    personId={state.filterRequest.PersonId}
                    manualAllowed={state.manualAllowed}
                    refetchData={refetch}
                    date={state.filterRequest.Date}
                    onBackClick={() => {
                        const filterReq = {
                            ...state.filterRequest,
                            PersonId: null,
                            FilterType: state.filterRequest.PreviousFilterType
                                ? state.filterRequest.PreviousFilterType
                                : state.filterRequest.FilterType,
                            PreviousFilterType: undefined,
                        } as TimeTrackingRequestParameters;
                        setState({
                            ...state,
                            filterRequest: filterReq,
                        });
                        onFilterChange(filterReq);
                    }}
                    onChange={(rec) =>
                        setState({
                            ...state,
                            records: rec,
                            headerData: {
                                ...state.headerData,
                                Totals: calculateHeaderTotals(
                                    rec,
                                    defaultTo(
                                        response?.Data.HeaderData.EmpKeys
                                            ?.TotalEmpKeys,
                                        0
                                    ),
                                    userType === UserType.Employee
                                ),
                            },
                        })
                    }
                />
            );
        }
    };

    return (
        <div className={styles.root}>
            <TimeManagementMainHeader
                filter={state.filterRequest}
                loading={loading || isFetching}
                refetch={() => refetch()}
                onFilterChange={onFilterChange}
            />
            {state.headerData == null && state.records == null ? (
                <AppLoader fullHeight={true} />
            ) : loading ? (
                <AppOverlayLoader blur={false} className={styles.loader} />
            ) : (
                <div className={styles.contentMain}>{renderTabs()}</div>
            )}
        </div>
    );
};

export default TimeManagementMain;
