export const ShiftPlanEnTranslations = {
    shiftPlan: {
        title: "Shift Plan",
        actualHours:
            "Actual value - Indicates the current number of hours in total to which the employee has been scheduled in shifts for the displayed period.",
        contractHours:
            "Target value - Indicates the current agreed number of hours from the employee's contract data for the displayed period.",
        pdfTitle: "Shift Plan Document.pdf",
        currentWeek: "Current Week",
        clickToEdit: "Click to change shift",
        unassigned: "Unassigned",
        shiftUpdated: "Employee shift updated successfully.",
        workingArea: "Working Area",
        unavailable: "Unavailable",
        pdfSuccess: "Weekly shift plan PDF compiled successfully.",
        createSuccess: "Weekly shift plan created successfully.",
        deleteSuccess: "Weekly shift plan deleted successfully.",
        shiftAssignmentChangeSuccess: "Employee shift updated successfully.",
        assignmentDeleted: "Employee shift assignment deleted successfully.",
        weekNum: "CW {{weekNum}}",
        deleting: "Deleting shift plan...",
        actions: {
            new: "New",
            edit: "Edit",
            delete: "Delete",
        },
    },
};
export default ShiftPlanEnTranslations;
