import { defaultTo } from "lodash-es";
import { v4 as uuid } from "uuid";
import { HelpItemType } from "./enum";
import { FeatureHelpItem, HelpItem } from "./model";

export function getHelpItemIdentifier(item: FeatureHelpItem): string {
    return item.newItem ? item.newItem : defaultTo(item.Id, 0).toString();
}

export const extractVimeoPlayerUrl = (url: string) => {
    const urlSplit = url.split("/");
    return "https://player.vimeo.com/video/" + urlSplit[urlSplit.length - 1];
};
export const getNumberToHelpItemEnumTypeBinding = (
    value: number
): HelpItemType => {
    switch (value) {
        case 1:
            return HelpItemType.VIDEO;
        default:
            return HelpItemType.EXPLANATION;
    }
};

export function getDefaultFeatureHelpItem(featureId: string): FeatureHelpItem {
    return {
        Id: 0,
        FeatureId: featureId,
        LocationSettingId: 0,
        Order: 0,
        Title: "",
        newItem: uuid(),
        HelpItem: {
            Id: 0,
            Type: HelpItemType.VIDEO,
            Description: "",
        } as HelpItem,
    };
}

// function sortThenBy<T>(
//     key: string,
//     sortOrder: SortOrder,
//     a: T,
//     b: T
// ): number | undefined {
//     if (!key) {
//         return 0;
//     }
//     const type = (a as any)[key];

//     if (typeof type == "boolean") {
//         if (sortOrder == SortOrder.ASC) {
//             return Number((b as any)[key]) - Number((a as any)[key]);
//         } else {
//             return Number((a as any)[key]) - Number((b as any)[key]);
//         }
//     } else if (typeof type == "string") {
//         if (sortOrder == SortOrder.ASC) {
//             return (a as any)[key].localeCompare((b as any)[key]);
//         } else {
//             return (b as any)[key].localeCompare((a as any)[key]);
//         }
//     } else if (typeof type == "number") {
//         if (sortOrder == SortOrder.ASC) {
//             return (a as any)[key] < (b as any)[key] ? -1 : 1;
//         } else {
//             return (a as any)[key] > (b as any)[key] ? -1 : 1;
//         }
//     } else if ((type as any) instanceof moment) {
//         if (sortOrder == SortOrder.ASC) {
//             return moment((a as any)[key]).diff((b as any)[key]);
//         } else {
//             return moment((b as any)[key]).diff((a as any)[key]);
//         }
//     }
// }
// export function getSortedArray<T extends BaseObject>(
//     key: keyof T | string,
//     list: T[],
//     sortOrder: SortOrder,
//     thenBykey?: keyof T | string,
//     thenBySortOrder: SortOrder = SortOrder.ASC
// ): T[] {
//     if (list.length <= 0) {
//         return [];
//     }
//     //return the whole list if all the values are null
//     const findNotNullValue = list.find((x) => x[key] != null);
//     if (!findNotNullValue) {
//         return list;
//     }
//     //if all values are not null find the value of first key
//     const type = findNotNullValue[key];

//     if (typeof type == "boolean") {
//         if (sortOrder == SortOrder.ASC) {
//             return list.sort((a, b) => Number(b[key]) - Number(a[key]));
//         } else {
//             return list.sort((a, b) => Number(a[key]) - Number(b[key]));
//         }
//     }
//     //collect all the null values to append them in the end
//     const nullValues = list.filter(
//         (x) => x[key] != 0 && Boolean((x as any)[key]) === false
//     );

//     //remove all the null values from the searching list
//     list = list.filter(
//         (x) => (x as any)[key] == 0 || isNil((x as any)[key]) === true
//     );
//     //if type is string use localeCompare
//     if (typeof type == "string") {
//         if (sortOrder == SortOrder.ASC) {
//             list = list.sort((a, b) => {
//                 const localeCompareResult = (a as any)[key].localeCompare(
//                     b[key]
//                 );
//                 //if values are equal then check order by then
//                 return localeCompareResult != 0
//                     ? localeCompareResult
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         } else {
//             list = list.sort((a, b) => {
//                 const localeCompareResult = (b as any)[key].localeCompare(
//                     a[key]
//                 );
//                 //if values are equal then check order by then
//                 return localeCompareResult != 0
//                     ? localeCompareResult
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         }
//     } else if (typeof type == "number") {
//         if (sortOrder == SortOrder.ASC) {
//             list = list.sort((a, b) => {
//                 return (a as any)[key] != (b as any)[key]
//                     ? a[key] < b[key]
//                         ? -1
//                         : 1
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         } else {
//             list = list.sort((a, b) => {
//                 return a[key] != b[key]
//                     ? a[key] > b[key]
//                         ? -1
//                         : 1
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         }
//     } else if ((type as any) instanceof moment) {
//         if (sortOrder == SortOrder.ASC) {
//             list = list.sort((a, b) => {
//                 const difference = moment(a[key]).diff(b[key]);
//                 return difference != 0
//                     ? difference
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         } else {
//             list = list.sort((a, b) => {
//                 const difference = moment(b[key]).diff(a[key]);
//                 return difference != 0
//                     ? difference
//                     : sortThenBy(thenBykey as string, thenBySortOrder, a, b);
//             });
//         }
//     }

//     return nullValues && nullValues.length > 0
//         ? [...list, ...nullValues]
//         : [...list];
// }
