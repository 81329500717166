import { AppTooltip } from "components";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { v4 as uuid } from "uuid";
import ContractVouchersSummaryPopper from "./ContractVouchersSummaryPopper";
import { debounce, isNil } from "lodash-es";
import {
    BusinessContractDetailItem,
    checkPermissionInMap,
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models";
import { DATEPICKER_MONTH_FORMAT } from "models/datepicker";
import { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./BusinessMonthlyContracts.module.scss";
import {
    BusinessContractService,
    getBusinessContractServiceKey,
} from "services/business";
import { AppNumberInput } from "components/FormFields";
import { AppResponse } from "models/general";
import { useMutation } from "react-query";

export interface BusinessMonthlyContractRowProps {
    value: BusinessContractDetailItem;
    hasVouchers?: boolean;
    hasAffiliates?: boolean;
    hasFreeMonths?: boolean;
    updating: boolean;
    setUpdating: (val: boolean) => void;
    isKindergarten?: boolean;
    isLwc?: boolean;
    loading: boolean;
    permMap: PermissionAccessMap;
    onChange: () => void;
}

export const BusinessMonthlyContractRow: React.FC<
    BusinessMonthlyContractRowProps
> = ({
    value,
    permMap,
    hasVouchers = false,
    hasAffiliates = false,
    hasFreeMonths = false,
    isLwc = false,
    loading,
    updating,
    setUpdating,
    onChange,
    isKindergarten = true,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { appLocale, formatDate, getFormattedCurrency } = useLocaleHelpers();
    const contractsService = new BusinessContractService(
        linkProvider.business.api.currentBusiness().contracts
    );

    const [val, setVal] = useState(value.AdjustedPrice);

    // const { mutate: copyContract, isLoading: copying } = useMutation(
    //     getBusinessContractServiceKey("copyContractModules"),
    //     async (data: { id: string; limit?: number }) =>
    //         await contractsService.copyContractModules(data.id, data.limit),
    //     {
    //         onSuccess: (data) => {
    //             if (data && !isNil(data.Data)) {
    //                 onChange();
    //             }
    //         },
    //     }
    // );
    const { mutate: updateAdjustedPrice, isLoading: updatingPrice } =
        useMutation(
            getBusinessContractServiceKey("updateAdjustedPrice"),
            async (data: { id: string; price?: number }) => {
                setUpdating(true);
                return await contractsService.updateAdjustedPrice(
                    data.id,
                    data.price
                );
            },
            {
                onSuccess: (data: AppResponse<number>) => {
                    if (data && !isNil(data.Data)) {
                        onChange(); // to refetchData
                    }
                },
            }
        );
    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);
    const updateDebounce = useCallback(
        debounce(() => {
            if (!loading && !updatingPrice) {
                setUpdating(false);
            }
        }, 500),
        [loading, updatingPrice]
    );
    useEffect(() => {
        if (updating) {
            updateDebounce();
        }
    }, [loading, updateDebounce]);
    let moduleText = "";

    if (value.ModulePlansRate && value.ModulePlansRate.length > 0) {
        moduleText += value.ModulePlansRate.map((r) => {
            // const toRet = `${
            //     r.LocaleNamesMap[appLocale]
            // } (${getFormattedCurrency(
            //     r.Rate +
            //         (x.ModulePlansRate.length == 1
            //             ? x.BasePlanTotal
            //             : 0)
            // )})`;
            return r.LocaleNamesMap[appLocale];
        }).join(", ");
    } else {
        moduleText = t("locationSettings.modulePlan.module.basic");
        // `${t(
        //     "locationSettings.modulePlan.module.basic"
        // )} (${getFormattedCurrency(x.BasePlanTotal)})`;
    }

    return (
        <tr key={`${value.EncodedId}_${uuid()}`}>
            <td style={{ paddingLeft: "10px" }}>
                {formatDate(value.MonthYear as Moment, DATEPICKER_MONTH_FORMAT)}
            </td>
            {isKindergarten && (
                <>
                    <td>
                        {getFormattedCurrency(
                            value.HasData && value.ClientsFee.BasePrice
                                ? value.ClientsFee.BasePrice
                                : 0
                        )}
                    </td>
                    <td>
                        {getFormattedCurrency(
                            value.HasData
                                ? value.ClientsFee.PerUnitPrice *
                                      value.ClientsFee.Count
                                : 0
                        )}
                    </td>
                </>
            )}
            <td>
                {getFormattedCurrency(
                    value.HasData && value.EmployeesFee.BasePrice
                        ? value.EmployeesFee.BasePrice
                        : 0
                )}
            </td>
            <td>
                {getFormattedCurrency(
                    value.HasData
                        ? value.EmployeesFee.PerUnitPrice *
                              value.EmployeesFee.Count
                        : 0
                )}
            </td>
            <td>{moduleText}</td>
            {hasVouchers && (
                <td>
                    <ContractVouchersSummaryPopper
                        value={value.VoucherDiscounts}
                    />
                </td>
            )}
            {hasAffiliates && (
                <td>
                    {getFormattedCurrency(
                        value.AffiliateDiscount
                            ? value.AffiliateDiscount.Discount
                            : 0
                    )}
                </td>
            )}
            {/* cover cell */}
            <td></td>
            <td>
                <div className={styles.valueCell}>
                    <AppNumberInput
                        disabled={!hasEditPermission || updating || !isLwc} // should be changeable only by LW Admin
                        isForCurrency={true}
                        className={styles.inputField}
                        style={{
                            width: "100%",
                            height: "39px",
                            padding: "8px",
                            minHeight: "39px",
                            margin: "0px",
                        }}
                        showLabel={false}
                        formatTheInitialValue={true}
                        showEmptyField={true}
                        showPlaceHolder={true}
                        value={val}
                        onBlur={(e) => {
                            if (isLwc && (e as number) != val) {
                                setVal(e as number);
                                updateAdjustedPrice({
                                    id: value.Id.toString(),
                                    price: e as number,
                                });
                            }
                        }}
                        onChange={() => {}}
                        placeholder={t("business.contracts.price")}
                    />
                </div>
            </td>
            {(hasAffiliates || hasVouchers || hasFreeMonths) && (
                <td>
                    {getFormattedCurrency(
                        value.IsFreeMonth ? value.Total : value.TotalDiscount
                    )}
                </td>
            )}
            <td>
                {value.IsFreeMonth ? (
                    <AppTooltip content={t("business.contracts.freeMonth")}>
                        <span>{getFormattedCurrency(0)}</span>
                    </AppTooltip>
                ) : (
                    getFormattedCurrency(
                        !isNil(value.AdjustedPrice)
                            ? value.AdjustedPrice
                            : value.Total - value.TotalDiscount
                    )
                )}
            </td>
        </tr>
    );
};

export default BusinessMonthlyContractRow;
