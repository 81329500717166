import { ImageAssets } from "globals/images";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppInputField } from "../FormFields/AppInputField";
import { AppTooltip } from "../Tooltips";
import { AppDialog, AppDialogProps } from "./AppDialog";
import { AppDialogFooter } from "./AppDialogFooter";
import styles from "./AppSurNameDialog.module.scss";
export interface AppSurNameDialogProps {
    value?: string;
    onChange?: (value: string) => void;
    dialogProps?: AppDialogProps;
    onSave?: (value: string) => void;
    oldValue?: string;
    readonly?: boolean;
}
export const AppSurNameDialog: React.FC<AppSurNameDialogProps> = ({
    onChange,
    value = "",
    dialogProps,
    onSave,
    oldValue,
    readonly = false,
}) => {
    const [surNameDialogOpen, setSurNameDialogOpen] = useState(false);
    const { t } = useTranslation();
    const [state, setState] = useState(value);
    const onCloseHandler = () => {
        setSurNameDialogOpen(false);
    };
    useEffect(() => {
        setState(value);
    }, [value]);
    return (
        <>
            {Boolean(oldValue) && Boolean(value) ? (
                <AppTooltip
                    content={t("kindergartenClient.oldLastName", {
                        name: oldValue,
                    })}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    <Image
                        className={styles.addButton}
                        src={ImageAssets.common.plusBlue}
                    />
                </AppTooltip>
            ) : (
                <AppTooltip
                    content={t("kindergartenClient.surNameDialog.title", {
                        name: oldValue,
                    })}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    <Image
                        className={styles.addButton}
                        onClick={() => setSurNameDialogOpen(!surNameDialogOpen)}
                        src={ImageAssets.common.plusBlue}
                    />
                </AppTooltip>
            )}
            <AppDialog
                title={t("kindergartenClient.surNameDialog.title")}
                modalOpen={surNameDialogOpen}
                onClose={onCloseHandler}
                {...dialogProps}
            >
                <AppInputField
                    value={state}
                    disabled={readonly}
                    onValueChange={(val) => {
                        if (val) {
                            setState(val);
                            if (onChange) {
                                onChange(val);
                            }
                        }
                    }}
                />
                <AppDialogFooter
                    onDialogClose={onCloseHandler}
                    disableSaveButton={readonly}
                    onClickSaveButton={() => {
                        onCloseHandler();
                        onSave && onSave(state);
                    }}
                    saveButtonText={t("common.update")}
                />
            </AppDialog>
        </>
    );
};

export default AppSurNameDialog;
