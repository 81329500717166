import { AppLocale, BusinessPerson, SelectItem } from "models/general";
import { AppResponse } from "models/general/response";
import { LocationDataRequest, LocationOptionResponse } from "models/location";
import { routesForContext } from "routing/AppRoutes";
import { BaseService } from "./BaseService";

export interface IGeneralService {
    getEncodedValue(id: number): Promise<AppResponse<string>>;
    getUserInfo(email: string): Promise<AppResponse<BusinessPerson>>;
    getTimezones: (locale: AppLocale) => Promise<AppResponse<SelectItem[]>>;
    getCountries: (locale: AppLocale) => Promise<AppResponse<SelectItem[]>>;
    getLocationData(
        request: LocationDataRequest
    ): Promise<AppResponse<LocationOptionResponse>>;
}

export function getGeneralServiceKey(name: keyof IGeneralService, data?: any) {
    if (!data) {
        return name;
    }
    return `general_${name}_${JSON.stringify(data)}`;
}

const apiLinkProvider = routesForContext().api.general;
type ApiLinkProviderType = typeof apiLinkProvider;

export class GeneralService extends BaseService implements IGeneralService {
    public generalRoutes: ApiLinkProviderType;
    constructor(routes: ApiLinkProviderType) {
        super();
        this.generalRoutes = routes;
    }

    getEncodedValue(id: number): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.generalRoutes.getEncodedValue(id),
            method: "get",
        });
    }

    getTimezones(locale: AppLocale): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<string>({
            url: this.generalRoutes.getTimezones(locale.toString()),
            method: "get",
        });
    }

    getCountries(locale: AppLocale): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<string>({
            url: this.generalRoutes.getCountries(locale.toString()),
            method: "get",
        });
    }

    getUserInfo(email: string): Promise<AppResponse<BusinessPerson>> {
        return this.doServerXHR<BusinessPerson>({
            url: this.generalRoutes.getUserInfo(email),
            method: "get",
        });
    }
    getLocationData(
        request: LocationDataRequest
    ): Promise<AppResponse<LocationOptionResponse>> {
        return this.doServerXHR<LocationOptionResponse>({
            url: this.generalRoutes.getLocationsData(),
            method: "post",
            data: request,
        });
    }
}
export default GeneralService;
