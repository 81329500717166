import { ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { enumFromStringValue } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash-es";
import {
    FeatureHelpItem,
    getHelpItemIdentifier,
    getNumberToHelpItemEnumTypeBinding,
    HelpItemType,
} from "models/applicationFeatures";
import {
    SelectItem,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SortableHandle } from "react-sortable-hoc";
import styles from "./FeatureHelpItemRow.module.scss";

export interface FeatureHelpItemRowProps {
    value: FeatureHelpItem;
    onChange: (field: FeatureHelpItem) => void;
    validityState?: ValidityState;
    onDelete: (id: string) => void;
    readonly?: boolean;
}

const DragHandle = SortableHandle(() => (
    <div className={styles.rowDragHandle}>
        <i className={`fa fa-bars ${styles.icon}`} />
    </div>
));
export function FeatureHelpItemRow(props: FeatureHelpItemRowProps) {
    const {
        value,
        onChange,
        validityState,
        onDelete,
        readonly = false,
    } = props;

    const { t } = useTranslation();
    const validityStateManager = new ValidityStateManager(validityState);
    const id = getHelpItemIdentifier(value);

    const helperTypeItemList: SelectItem[] = Object.keys(HelpItemType)
        .slice(2, 4)
        .map((key) => {
            return {
                Text: t(`helpItem.type.${key}`),
                Value: enumFromStringValue(HelpItemType, key),
            } as SelectItem;
        });

    return (
        <div>
            <div className={styles.fieldRowRoot}>
                <div className={styles.rowContent}>
                    <a
                        className={`${styles.rowDel} `}
                        onClick={() => {
                            if (!readonly) {
                                onDelete(getHelpItemIdentifier(value));
                            }
                        }}
                    >
                        <i
                            className={`fas fa-trash ${styles.deleteIcon} ${
                                readonly ? styles.disabled : ""
                            }`}
                        />
                    </a>
                    <Form.Row>
                        <React.Fragment>
                            <Col md={4}>
                                <Form.Group controlId={"helperType"}>
                                    <Form.Label>
                                        {t(`helpItem.type.title`)}
                                    </Form.Label>
                                    <AppSelectOld
                                        menuShouldBlockScroll={true}
                                        options={helperTypeItemList}
                                        getOptionLabel={(opt: SelectItem) =>
                                            opt.Text
                                        }
                                        getOptionValue={(opt: SelectItem) =>
                                            opt.Value
                                        }
                                        value={helperTypeItemList.find(
                                            (x) =>
                                                x.Value == value.HelpItem.Type
                                        )}
                                        isDisabled={readonly}
                                        onChange={(e: SelectItem) => {
                                            const type =
                                                getNumberToHelpItemEnumTypeBinding(
                                                    e.Value as number
                                                );
                                            onChange({
                                                ...value,
                                                HelpItem: {
                                                    ...value.HelpItem,
                                                    Type: type,
                                                    Url:
                                                        type ==
                                                        HelpItemType.VIDEO
                                                            ? value.HelpItem.Url
                                                            : undefined,
                                                },
                                            });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {value.HelpItem.Type === HelpItemType.VIDEO && (
                                <Col md={4}>
                                    <Form.Group controlId={"VideoUrl-" + id}>
                                        <Form.Label>
                                            {t("helpItem.url.name")}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={defaultTo(
                                                value.HelpItem.Url,
                                                ""
                                            )}
                                            disabled={readonly}
                                            onChange={(e) => {
                                                onChange({
                                                    ...value,
                                                    HelpItem: {
                                                        ...value.HelpItem,
                                                        Url: e.target.value,
                                                    },
                                                });
                                            }}
                                            placeholder={t(
                                                "helpItem.url.placeholder"
                                            )}
                                        />
                                        <ErrorMessage
                                            errorInfo={validityStateManager.getFirstErrorInfo(
                                                "Url"
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            <Col
                                md={
                                    value.HelpItem.Type === HelpItemType.VIDEO
                                        ? 4
                                        : 8
                                }
                            >
                                <Form.Group controlId={"title-" + id}>
                                    <Form.Label>
                                        {t("helpItem.title.name")}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={value.Title}
                                        disabled={readonly}
                                        onChange={(e) => {
                                            onChange({
                                                ...value,
                                                Title: e.target.value,
                                            });
                                        }}
                                        placeholder={t(
                                            "helpItem.title.placeholder"
                                        )}
                                    />
                                    <ErrorMessage
                                        errorInfo={validityStateManager.getFirstErrorInfo(
                                            "Title"
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId={"description-" + id}>
                                    <Form.Label>
                                        {t("helpItem.description.name")}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        as={"textarea"}
                                        style={{ height: "auto !important" }}
                                        rows={3}
                                        value={value.HelpItem.Description}
                                        disabled={readonly}
                                        onChange={(e) => {
                                            onChange({
                                                ...value,
                                                HelpItem: {
                                                    ...value.HelpItem,
                                                    Description: e.target.value,
                                                },
                                            });
                                        }}
                                        placeholder={t(
                                            "helpItem.description.placeholder"
                                        )}
                                    />
                                    <ErrorMessage
                                        errorInfo={validityStateManager.getFirstErrorInfo(
                                            "Description"
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </React.Fragment>
                    </Form.Row>
                </div>
                {/* @ts-ignore */}
                <DragHandle />
            </div>
        </div>
    );
}

export default FeatureHelpItemRow;
