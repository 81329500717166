import { NoAuthRoute } from "hoc/routeModifiers/NoAuthRoute";
import { RoutesMap } from "routing/models";
import {
    CompleteRegistration,
    ConfirmationPage,
    EmployeeInfoRegister,
    ForgotPassword,
    LoginRegister,
    ResetPassword,
} from "screens/auth";
import { noAuthRoutes } from "./routes";

const relativeRoutes = noAuthRoutes(false).screens;
const fullRoutes = noAuthRoutes().screens;

export const noAuthRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.login()]: {
        component: LoginRegister,
        routeRestrictor: NoAuthRoute,
        fullPattern: fullRoutes.login(),
        pattern: relativeRoutes.login(),
    },
    // [relativeRoutes.getDemo()]: {
    //     component: LoginRegister,
    //     routeRestrictor: NoAuthRoute,
    //     fullPattern: fullRoutes.getDemo(),
    //     pattern: relativeRoutes.getDemo(),
    // },
    [relativeRoutes.sessionTimeOut()]: {
        component: LoginRegister,
        routeRestrictor: NoAuthRoute,
        fullPattern: fullRoutes.sessionTimeOut(),
        pattern: relativeRoutes.sessionTimeOut(),
    },
    [relativeRoutes.forgotPassword()]: {
        component: ForgotPassword,
        routeRestrictor: NoAuthRoute,
        fullPattern: fullRoutes.forgotPassword(),
        pattern: relativeRoutes.forgotPassword(),
    },
    [relativeRoutes.forgotPasswordConfirmation()]: {
        component: ConfirmationPage,
        routeRestrictor: NoAuthRoute,
        fullPattern: fullRoutes.forgotPasswordConfirmation(),
        pattern: relativeRoutes.forgotPasswordConfirmation(),
    },
    [relativeRoutes.employeeInfoRegister()]: {
        component: EmployeeInfoRegister,
        fullPattern: fullRoutes.employeeInfoRegister(),
        pattern: relativeRoutes.employeeInfoRegister(),
    },
    [relativeRoutes.completeRegistration()]: {
        component: CompleteRegistration,
        fullPattern: fullRoutes.completeRegistration(),
        pattern: relativeRoutes.completeRegistration(),
    },
    [relativeRoutes.registerRequestConfirmation()]: {
        component: ConfirmationPage,
        fullPattern: fullRoutes.registerRequestConfirmation(),
        pattern: relativeRoutes.registerRequestConfirmation(),
    },
    [relativeRoutes.emailConfirmation()]: {
        component: ConfirmationPage,
        fullPattern: fullRoutes.emailConfirmation(),
        pattern: relativeRoutes.emailConfirmation(),
    },
    [relativeRoutes.registerInfoCompleted()]: {
        component: ConfirmationPage,
        fullPattern: fullRoutes.registerInfoCompleted(),
        pattern: relativeRoutes.registerInfoCompleted(),
    },
    [relativeRoutes.resetPassword()]: {
        component: ResetPassword,
        fullPattern: fullRoutes.resetPassword(),
        pattern: relativeRoutes.resetPassword(),
    },
    [relativeRoutes.resetPasswordConformation()]: {
        component: ConfirmationPage,
        fullPattern: fullRoutes.resetPasswordConformation(),
        pattern: relativeRoutes.resetPasswordConformation(),
    },
};

export default noAuthRoutesMap;
