export const Feature_Business_Dashboard = "Business_Dashboard";

// Kindergarten
export const Feature_Kindergarten_Client_Index = "Kindergarten_Client_Index";
export const Feature_Kindergarten_Client_AddUpdate =
    "Kindergarten_Client_AddUpdate";
export const Feature_Kindergarten_Client_Contracts =
    "Kindergarten_Client_Contracts";
export const Feature_Kindergarten_Client_Documents =
    "Kindergarten_Client_Documents";
export const Feature_Kindergarten_Client_AccessControl =
    "Kindergarten_Client_AccessControl";
export const Feature_Kindergarten_Client_AccessControl_AddUpdate =
    "Kindergarten_Client_AccessControl_AddUpdate";
export const Feature_Kindergarten_Client_Diseases =
    "Kindergarten_Client_Diseases";
export const Feature_Kindergarten_Client_Diseases_AddUpdate =
    "Kindergarten_Client_Diseases_AddUpdate";
export const Feature_Kindergarten_Client_Vacations =
    "Kindergarten_Client_Vacations";
export const Feature_Kindergarten_Client_Vacations_AddUpdate =
    "Kindergarten_Client_Vacations_AddUpdate";
export const Feature_Kindergarten_Client_MedicalData =
    "Kindergarten_Client_MedicalData";
export const Feature_Kindergarten_Client_Family = "Kindergarten_Client_Family";
export const Feature_Kindergarten_Client_Group = "Kindergarten_Client_Group";
export const Feature_Kindergarten_Client_BankData =
    "Kindergarten_Client_BankData";
export const Feature_Kindergarten_Client_BankData_AddUpdate =
    "Kindergarten_Client_BankData_AddUpdate";
export const Feature_Kindergarten_Client_App = "Kindergarten_Client_App";
export const Feature_Kindergarten_Client_Registrations =
    "Kindergarten_Client_Registrations";
export const Feature_Kindergarten_Client_Registrations_AddUpdate =
    "Kindergarten_Client_Registrations_AddUpdate";

export const Feature_Kindergarten_Time_Tracking_Client =
    "Kindergarten_Time_Tracking_Client";
export const Feature_Kindergarten_Occupancy_Planing =
    "Kindergarten_Occupancy_Planing";
export const Feature_Kindergarten_AgeGroup_Planing =
    "Kindergarten_AgeGroup_Planing";
export const Feature_Kindergarten_Ipad = "Kindergarten_Ipad";
export const Feature_Kindergarten_Ipad_Update = "Kindergarten_Ipad_Update";

export const Feature_Kindergarten_MealTemplates = "Kindergarten_MealTemplates";
export const Feature_Kindergarten_MealTemplates_AddUpdate =
    "Kindergarten_MealTemplates_AddUpdate";

// Employee
export const Feature_Business_Employees = "Business_Employee";
export const Feature_Business_Suppliers = "Business_Supplier";
export const Feature_Business_Employee_AddUpdate =
    "Business_Employee_AddUpdate";
export const Feature_Business_Employee_ContractData =
    "Business_Employee_ContractData";
export const Feature_Business_Employee_Documents =
    "Business_Employee_Documents";
export const Feature_Business_Employee_AccessControl =
    "Business_Employee_AccessControl";
export const Feature_Business_Employee_AccessControl_AddUpdate =
    "Business_Employee_AccessControl_AddUpdate";
export const Feature_Business_Employee_Diseases = "Business_Employee_Diseases";
export const Feature_Business_Employee_Diseases_AddUpdate =
    "Business_Employee_Diseases_AddUpdate";
export const Feature_Business_Employee_Vacations =
    "Business_Employee_Vacations";
export const Feature_Business_Employee_Vacations_AddUpdate =
    "Business_Employee_Vacations_AddUpdate";
export const Feature_Business_Employee_Absent = "Business_Employee_Absent";
export const Feature_Business_Employee_Absent_AddUpdate =
    "Business_Employee_Absent_AddUpdate";
export const Feature_Business_Employee_Shift = "Business_Employee_Shift";
export const Feature_Business_Employee_Shift_AddUpdate =
    "Business_Employee_Shift_AddUpdate";
export const Feature_Business_Employee_SalaryStatements =
    "Business_Employee_SalaryStatements";
export const Feature_Business_Employee_BankData = "Business_Employee_BankData";
export const Feature_Business_Employee_BankData_AddUpdate =
    "Business_Employee_BankData_AddUpdate";
export const Feature_Business_Employee_Group = "Business_Employee_Group";
export const Feature_Business_Employee_App = "Business_Employee_App";

// Business
export const Feature_Business_Branches = "Business_Branches";
export const Feature_Business_Branches_AddUpdate =
    "Business_Branches_AddUpdate";
export const Feature_Business_Calendar = "Business_Calendar";
export const Feature_Business_Data = "Business_Data";
export const Feature_Business_Contracts = "Business_Contracts";
export const Feature_Business_AddUpdate = "Business_AddUpdate";
export const Feature_Business_Settings = "Business_Settings";
export const Feature_Business_Chat_Settings = "Business_Chat_Settings";
export const Feature_Business_RenewContract = "Business_RenewContract";
export const Feature_Business_ContractExpired = "Business_ContractExpired";
export const Feature_Business_WorkingHours = "Business_WorkingHours";
export const Feature_Business_Holidays = "Business_Holidays";
export const Feature_Business_Konto = "Business_Konto";
export const Feature_Business_ModuleSelect = "Business_ModuleSelect";

export const Feature_Business_AccessControl = "Business_AccessControl";
export const Feature_Business_AccessControl_AddUpdate =
    "Business_AccessControl_AddUpdate";
export const Feature_Business_CompensationTemplates =
    "Business_CompensationTemplates";
export const Feature_Business_CompensationTemplates_AddUpdate =
    "Business_CompensationTemplates_AddUpdate";

export const Feature_Business_BackgroundJobs = "Business_BackgroundJobs";
export const Feature_Business_UploadLogos = "Business_UploadLogos";
export const Feature_Business_ExternalIntegration = "Business_ExternalIntegration";
export const Feature_Business_Group = "Business_Group";
export const Feature_Business_Group_AddUpdate = "Business_Group_AddUpdate";
export const Feature_Business_WorkingArea = "Business_WorkingArea";
export const Feature_Business_WorkingArea_AddUpdate =
    "Business_WorkingArea_AddUpdate";
export const Feature_Business_Shift = "Business_Shift";
export const Feature_Business_Shift_AddUpdate = "Business_Shift_AddUpdate";

export const Feature_Business_Printers = "Business_Printers";
export const Feature_Business_Printers_AddUpdate =
    "Business_Printers_AddUpdate";
export const Feature_Business_Print_Job = "Business_Print_Job";
export const Feature_Business_Print_Job_AddUpdate =
    "Business_Print_Job_AddUpdate";
export const Feature_Business_Functions = "Business_Functions";
export const Feature_Business_Functions_AddUpdate =
    "Business_Functions_AddUpdate";

export const Feature_Business_Creditor = "Business_Creditor";
export const Feature_Business_Creditor_AddUpdate =
    "Business_Creditor_AddUpdate";
export const Feature_Business_Creditor_BankData = "Business_Creditor_BankData";
export const Feature_Business_Creditor_BankData_AddUpdate =
    "Business_Creditor_BankData_AddUpdate";
export const Feature_Business_EmailTemplates = "Business_EmailTemplates";
export const Feature_Business_EmailTemplates_AddUpdate =
    "Business_EmailTemplates_AddUpdate";
export const Feature_Business_Chat = "Business_Chat";
export const Feature_Business_ShiftPlan = "Business_ShiftPlan";
export const Feature_Business_Document_Archive = "Business_Document_Archive";
export const Feature_Business_Messages = "Business_Messages";
export const Feature_Business_DocumentTemplates = "Business_DocumentTemplates";
export const Feature_Business_DocumentTemplates_AddUpdate =
    "Business_DocumentTemplates_AddUpdate";
export const Feature_Business_DocumentLetterheads =
    "Business_DocumentLetterheads";
export const Feature_Business_DocumentLetterheads_AddUpdate =
    "Business_DocumentLetterheads_AddUpdate";
export const Feature_Business_EmployeeKey = "Business_EmployeeKey";
export const Feature_Business_EmployeeKey_AddUpdate =
    "Business_EmployeeKey_AddUpdate";
export const Feature_Business_AgeGroup = "Business_AgeGroup";
export const Feature_Business_AgeGroup_AddUpdate =
    "Business_AgeGroup_AddUpdate";
export const Feature_Business_SalaryGroup = "Business_SalaryGroup";
export const Feature_Business_SalaryGroup_AddUpdate =
    "Business_SalaryGroup_AddUpdate";
export const Feature_Business_CareScope = "Business_CareScope";
export const Feature_Business_CareScope_AddUpdate =
    "Business_CareScope_AddUpdate";
export const Feature_Business_ToDo = "Business_ToDo";
export const Feature_Business_Time_Tracking_Employee =
    "Business_Time_Tracking_Employee";
export const Feature_Business_Time_Tracking_Supplier =
    "Business_Time_Tracking_Supplier";
export const Feature_Business_Payroll_AdditionalBenefits =
    "Business_Payroll_AdditionalBenefits";
export const Feature_Business_Payroll_Absences = "Business_Payroll_Absences";
export const Feature_Business_WebNotification = "Business_WebNotification";
export const Feature_Business_Downloads = "Business_Downloads";
export const Feature_Business_User_Access_Management =
    "Business_User_Access_Management";
export const Feature_Business_Role_Management = "Business_Role_Management";
export const Feature_Business_Courses = "Business_Courses";
export const Feature_Business_Courses_AddUpdate = "Business_Courses_AddUpdate";

// Lillywait Clients
export const Feature_LW_Clients_Kindergartens = "LW_Clients_Kindergartens";
export const Feature_LW_Clients_Industries = "LW_Clients_Industries";
export const Feature_LW_Clients_Schools = "LW_Clients_Schools";
export const Feature_LW_Clients_ElderlyCares = "LW_Clients_ElderlyCares";
export const Feature_LW_Clients_Kindergartens_Support =
    "LW_Clients_Kindergartens_Support";
export const Feature_LW_Clients_Schools_Support = "LW_Clients_Schools_Support";
export const Feature_LW_Clients_ElderlyCares_Support =
    "LW_Clients_ElderlyCares_Support";
export const Feature_LW_Clients_Industries_Support =
    "LW_Clients_Industries_Support";
export const Feature_LW_Support_MergeCompanies = "LW_Support_MergeCompanies";

export const Feature_LW_Business_School_Details = "LW_Business_School_Details";
export const Feature_LW_Business_School_Settings =
    "LW_Business_School_Settings";
export const Feature_LW_Business_School_Contracts =
    "LW_Business_School_Contracts";
export const Feature_LW_Business_School_Holidays =
    "LW_Business_School_Holidays";
export const Feature_LW_Business_School_Document =
    "LW_Business_School_Document";
export const Feature_LW_Business_School_WorkingHours =
    "LW_Business_School_WorkingHours";

export const Feature_LW_Business_ElderlyCare_Details =
    "LW_Business_ElderlyCare_Details";
export const Feature_LW_Business_ElderlyCare_Settings =
    "LW_Business_ElderlyCare_Settings";
export const Feature_LW_Business_ElderlyCare_Contracts =
    "LW_Business_ElderlyCare_Contracts";
export const Feature_LW_Business_ElderlyCare_Holidays =
    "LW_Business_ElderlyCare_Holidays";
export const Feature_LW_Business_ElderlyCare_Documents =
    "LW_Business_ElderlyCare_Documents";
export const Feature_LW_Business_ElderlyCare_WorkingHours =
    "LW_Business_ElderlyCare_WorkingHours";

export const Feature_LW_Business_Industry_Details =
    "LW_Business_Industry_Details";
export const Feature_LW_Business_Industry_Settings =
    "LW_Business_Industry_Settings";
export const Feature_LW_Business_Industry_Chat_Settings =
    "LW_Business_Industry_Chat_Settings";
export const Feature_LW_Business_Industry_Contracts =
    "LW_Business_Industry_Contracts";
export const Feature_LW_Business_Industry_Holidays =
    "LW_Business_Industry_Holidays";
export const Feature_LW_Business_Industry_AccessControl =
    "LW_Business_Industry_AccessControl";
export const Feature_LW_Business_Industry_AccessControl_ViewUpdate =
    "LW_Business_Industry_AccessControl_ViewUpdate";
export const Feature_LW_Business_Industry_Group = "LW_Business_Industry_Group";
export const Feature_LW_Business_Industry_Group_AddUpdate =
    "LW_Business_Industry_Group_AddUpdate";
export const Feature_LW_Business_Industry_IPads = "LW_Business_Industry_IPads";
export const Feature_LW_Business_Industry_Courses =
    "LW_Business_Industry_Courses";
export const Feature_LW_Business_Industry_Courses_AddUpdate =
    "LW_Business_Industry_Courses_AddUpdate";
export const Feature_LW_Business_Industry_WorkingHours =
    "LW_Business_Industry_WorkingHours";
export const Feature_LW_Business_Industry_WorkingArea =
    "LW_Business_Industry_WorkingArea";
export const Feature_LW_Business_Industry_WorkingArea_AddUpdate =
    "LW_Business_Industry_WorkingArea_AddUpdate";
export const Feature_LW_Business_Industry_Shift = "LW_Business_Industry_Shift";
export const Feature_LW_Business_Industry_Shift_AddUpdate =
    "LW_Business_Industry_Shift_AddUpdate";
export const Feature_LW_Business_Industry_Functions =
    "LW_Business_Industry_Functions";
export const Feature_LW_Business_Industry_Functions_AddUpdate =
    "LW_Business_Industry_Functions_AddUpdate";
export const Feature_LW_Business_Industry_Document =
    "LW_Business_Industry_Document";

export const Feature_LW_Business_Kindergarten_Details =
    "LW_Business_Kindergarten_Details";
export const Feature_LW_Business_Kindergarten_Settings =
    "LW_Business_Kindergarten_Settings";
export const Feature_LW_Business_Kindergarten_Chat_Settings =
    "LW_Business_Kindergarten_Chat_Settings";
export const Feature_LW_Business_Kindergarten_Contracts =
    "LW_Business_Kindergarten_Contracts";
export const Feature_LW_Business_Kindergarten_Holidays =
    "LW_Business_Kindergarten_Holidays";
export const Feature_LW_Business_Kindergarten_IPads =
    "LW_Business_Kindergarten_IPads";
export const Feature_LW_Business_Kindergarten_AccessControl =
    "LW_Business_Kindergarten_AccessControl";
export const Feature_LW_Business_Kindergarten_AccessControl_ViewUpdate =
    "LW_Business_Kindergarten_AccessControl_ViewUpdate";
export const Feature_LW_Business_Kindergarten_TemplateExternalCompensations =
    "LW_Business_Kindergarten_TemplateExternalCompensations";
export const Feature_LW_Business_Kindergarten_TemplateExternalCompensations_View =
    "LW_Business_Kindergarten_TemplateExternalCompensations_View";
export const Feature_LW_Business_Kindergarten_CompensationTemplates =
    "LW_Business_Kindergarten_CompensationTemplates";
export const Feature_LW_Business_Kindergarten_CompensationTemplates_AddUpdate =
    "LW_Business_Kindergarten_CompensationTemplates_AddUpdate";
export const Feature_LW_Business_Kindergarten_Group =
    "LW_Business_Kindergarten_Group";
export const Feature_LW_Business_Kindergarten_Group_AddUpdate =
    "LW_Business_Kindergarten_Group_AddUpdate";
export const Feature_LW_Business_Kindergarten_WorkingHours =
    "LW_Business_Kindergarten_WorkingHours";
export const Feature_LW_Business_Kindergarten_MealTemplates =
    "LW_Business_Kindergarten_MealTemplates";
export const Feature_LW_Business_Kindergarten_MealTemplates_AddUpdate =
    "LW_Business_Kindergarten_MealTemplates_AddUpdate";
export const Feature_LW_Business_Kindergarten_Konto =
    "LW_Business_Kindergarten_Konto";
export const Feature_LW_Business_Kindergarten_WorkingArea =
    "LW_Business_Kindergarten_WorkingArea";
export const Feature_LW_Business_Kindergarten_WorkingArea_AddUpdate =
    "LW_Business_Kindergarten_WorkingArea_AddUpdate";
export const Feature_LW_Business_Kindergarten_Shift =
    "LW_Business_Kindergarten_Shit";
export const Feature_LW_Business_Kindergarten_Shift_AddUpdate =
    "LW_Business_Kindergarten_Shit_AddUpdate";
export const Feature_LW_Business_Kindergarten_Courses =
    "LW_Business_Kindergarten_Courses";
export const Feature_LW_Business_Kindergarten_Courses_AddUpdate =
    "LW_Business_Kindergarten_Courses_AddUpdate";
export const Feature_LW_Business_Kindergarten_Functions =
    "LW_Business_Kindergarten_Functions";
export const Feature_LW_Business_Kindergarten_Functions_AddUpdate =
    "LW_Business_Kindergarten_Functions_AddUpdate";
export const Feature_LW_Business_Kindergarten_OnlineRegistration =
    "LW_Business_Kindergarten_OnlineRegistration";
export const Feature_LW_Business_Kindergarten_EmployeeKey =
    "LW_Business_Kindergarten_EmployeeKeys";
export const Feature_LW_Business_Kindergarten_EmployeeKey_AddUpdate =
    "LW_Business_Kindergarten_EmployeeKey_AddUpdate";
export const Feature_LW_Business_Kindergarten_CareScope =
    "LW_Business_Kindergarten_CareScope";
export const Feature_LW_Business_Kindergarten_CareScope_AddUpdate =
    "LW_Business_Kindergarten_CareScope_AddUpdate";
export const Feature_LW_Business_Kindergarten_SalaryGroup =
    "LW_Business_Kindergarten_SalaryGroups";
export const Feature_LW_Business_Kindergarten_SalaryGroup_AddUpdate =
    "LW_Business_Kindergarten_SalaryGroup_AddUpdate";
export const Feature_LW_Business_Kindergarten_AgeGroup =
    "LW_Business_Kindergarten_AgeGroup";
export const Feature_LW_Business_Kindergarten_AgeGroup_AddUpdate =
    "LW_Business_Kindergarten_AgeGroup_AddUpdate";
export const Feature_LW_Business_Kindergarten_Document =
    "LW_Business_Kindergarten_Document";

// Lillywait
export const Feature_LW_Hq_Register_Request = "LW_Hq_Register_Request";
export const Feature_LW_LocationSetting = "LW_LocationSetting";
export const Feature_LW_LocationSetting_AddUpdate =
    "LW_LocationSetting_AddUpdate";
export const Feature_LW_Print_Job = "LW_Print_Job";
export const Feature_LW_Print_Job_AddUpdate = "LW_Print_Job_AddUpdate";
export const Feature_LW_LocationSetting_CompensationTemplates =
    "LW_LocationSetting_CompensationTemplates";
export const Feature_LW_LocationSetting_CompensationTemplates_AddUpdate =
    "LW_LocationSetting_CompensationTemplate_AddUpdate";
export const Feature_LW_LocationSetting_MealTemplates =
    "LW_LocationSetting_MealTemplates";
export const Feature_LW_LocationSetting_MealTemplates_AddUpdate =
    "LW_LocationSetting_MealTemplates_AddUpdate";
export const Feature_LW_LocationSetting_WorkingArea =
    "LW_LocationSetting_WorkingArea";
export const Feature_LW_LocationSetting_WorkingArea_AddUpdate =
    "LW_LocationSetting_WorkingArea_AddUpdate";
export const Feature_LW_LocationSetting_Functions =
    "LW_LocationSetting_Functions";
export const Feature_LW_LocationSetting_Functions_AddUpdate =
    "LW_LocationSetting_Functions_AddUpdate";
export const Feature_LW_LocationSetting_Holidays =
    "LW_LocationSetting_Holidays";
export const Feature_LW_LocationSetting_Holidays_AddUpdate =
    "LW_LocationSetting_Holidays_AddUpdate";
export const Feature_LW_LocationSetting_AgeGroupDeadline =
    "LW_LocationSetting_AgeGroupDeadline";
export const Feature_LW_LocationSetting_Letterhead =
    "LW_LocationSetting_Letterhead";
export const Feature_LW_LocationSetting_Letterhead_AddUpdate =
    "LW_LocationSetting_Letterhead_AddUpdate";
export const Feature_LW_LocationSetting_DocumentTemplate =
    "LW_LocationSetting_DocumentTemplate";
export const Feature_LW_LocationSetting_DocumentTemplate_AddUpdate =
    "LW_LocationSetting_DocumentTemplate_AddUpdate";
export const Feature_LW_LocationSetting_WorkingDays =
    "LW_LocationSetting_WorkingDays";
export const Feature_LW_LocationSetting_EmailTemplate =
    "LW_LocationSetting_EmailTemplate";
export const Feature_LW_LocationSetting_EmailTemplate_AddUpdate =
    "LW_LocationSetting_EmailTemplate_AddUpdate";
export const Feature_LW_LocationSetting_ApplicationFeature =
    "LW_LocationSetting_ApplicationFeature";
export const Feature_LW_LocationSetting_ApplicationFeature_AddUpdate =
    "LW_LocationSetting_ApplicationFeature_AddUpdate";
export const Feature_LW_LocationSetting_EmployeeKey =
    "LW_LocationSetting_EmployeeKey";
export const Feature_LW_LocationSetting_EmployeeKey_AddUpdate =
    "LW_LocationSetting_EmployeeKey_AddUpdate";
export const Feature_LW_LocationSetting_AgeGroup =
    "LW_LocationSetting_AgeGroup";
export const Feature_LW_LocationSetting_AgeGroup_AddUpdate =
    "LW_LocationSetting_AgeGroup_AddUpdate";
export const Feature_LW_LocationSetting_SalaryGroup =
    "LW_LocationSetting_SalaryGroup";
export const Feature_LW_LocationSetting_SalaryGroup_AddUpdate =
    "LW_LocationSetting_SalaryGroup_AddUpdate";
export const Feature_LW_LocationSetting_CareScopes =
    "LW_LocationSetting_CareScope";
export const Feature_LW_LocationSetting_CareScope_AddUpdate =
    "LW_LocationSetting_CareScope_AddUpdate";
export const Feature_LW_LocationSetting_CompanyData =
    "LW_LocationSetting_CompanyData";
export const Feature_LW_LocationSetting_CompanyData_AddUpdate =
    "LW_LocationSetting_CompanyData_AddUpdate";
export const Feature_LW_LocationSetting_Voucher = "LW_LocationSetting_Voucher";
export const Feature_LW_LocationSetting_Voucher_AddUpdate =
    "LW_LocationSetting_Voucher_AddUpdate";
export const Feature_LW_LocationSetting_Affiliate =
    "LW_LocationSetting_Affiliate";
export const Feature_LW_LocationSetting_Affiliate_AddUpdate =
    "LW_LocationSetting_Affiliate_AddUpdate";
export const Feature_LW_LocationSetting_ModulePlan =
    "LW_LocationSetting_ModulePlan";
