import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";

export interface AppCheckboxProps extends FormCheckProps {}

export const AppCheckbox: React.FC<AppCheckboxProps> = ({
    value = false,
    ...rest
}) => {
    return (
        <Form.Check
            custom={true}
            type="checkbox"
            checked={value as boolean}
            {...rest}
        />
    );
};

export default AppCheckbox;
