import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { isNil } from "lodash-es";
import { CalendarEventTemplateDetails } from "models/calendar";
import {
    FieldState,
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { validateStartEndDate } from "models/StartAndEndDateFields";
import moment from "moment";
import { Moment } from "moment-timezone";

export function validateCalenderTemplate(
    val: CalendarEventTemplateDetails,
    state = getInitializedValidityState([], [])
) {
    if (
        (!val.files || (val.files && val.files.length == 0)) &&
        (!val.Files || (val.Files && val.Files.length == 0))
    ) {
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("calendar.body.missing"),
                },
            ],
            val.Body ? val.Body.Template : "",
            "body"
        );
    }
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("calendar.title.missing"),
            },
        ],
        val.Title,
        "title"
    );
    if (!isNil(val.EndDate)) {
        const dateRangeErrors = new ValidityStateManager(
            validateStartEndDate({
                StartDate: val.EventDay as Moment,
                EndDate: val.EndDate,
            })
        );

        let mngr = new ValidityStateManager(state);
        mngr = mngr.replaceFieldState("EventDay", {
            ...dateRangeErrors.getFieldState("startDate"),
            identifier: "EventDay",
        } as FieldState);
        mngr = mngr.replaceFieldState("EndDate", {
            ...dateRangeErrors.getFieldState("endDate"),
            identifier: "EndDate",
        } as FieldState);
        state = mngr.state;
    }
    if (!val.AllDay) {
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("calendar.startTime.missing"),
                },
            ],
            val.StartTime && moment(val.StartTime, "HH:mm"),
            "StartTime"
        );
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t("calendar.endTime.missing"),
                },
                ...(val.StartTime
                    ? [
                          {
                              rule: Validations.GREATER_DATE,
                              options: {
                                  value: moment(
                                      val.StartTime,
                                      "HH:mm"
                                  ).toDate(),
                              },
                              message: i18next.t(
                                  "calendar.endTime.lessThanStart"
                              ),
                          },
                      ]
                    : []),
            ],
            val.EndTime && moment(val.EndTime, "HH:mm"),
            "EndTime"
        );
    }

    return state;
}
