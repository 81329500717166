import classNames from "classnames";
import { AppCollapseToggleButton } from "components/Buttons";
import AppContainerHeader from "components/Containers/appContentContainer/AppContainerHeader";
import { useCollapsibleHelper } from "hooks/general/appHelpers";
import { defaultTo, defaults } from "lodash-es";
import { Optional } from "models/general";
import React, { HTMLProps, ReactElement, useRef } from "react";
import styles from "./AppFieldsWrapperContainer.module.scss";

export interface AppFieldsWrapperContainerClasses {
    root: string;
    header: string;
    body: string;
    collapseToggle?: string;
}
export interface AppFieldsWrapperContainerProps {
    title: string;
    classes?: AppFieldsWrapperContainerClasses;
    isCollapsible?: boolean;
    initiallyOpen?: boolean;
    children: React.ReactNode;
}

export const AppFieldsWrapperContainer: React.FC<
    AppFieldsWrapperContainerProps
> = ({
    title,
    classes,
    isCollapsible = false,
    initiallyOpen = true,
    children,
}) => {
    classes = defaults(classes, { root: "", header: "", body: "" });
    const collapseContainerRef = useRef<Optional<any>>(undefined);

    const { collapse, toggleCollapse, initialStyle } = useCollapsibleHelper(
        collapseContainerRef,
        initiallyOpen
    );

    return (
        <div className={`${styles.root} ${classes.root}`}>
            <div
                className={classNames(
                    styles.header,
                    classes.header,
                    {
                        [styles.collapsible]: isCollapsible,
                    },
                    {
                        [styles.openedBorder]: collapse,
                    }
                )}
                onClick={isCollapsible ? () => toggleCollapse() : undefined}
            >
                {title}
                {isCollapsible && (
                    <AppCollapseToggleButton
                        visible={collapse}
                        onClick={() => toggleCollapse()}
                        className={classes.collapseToggle}
                        iconClassName={styles.collapseToggleIcon}
                    />
                )}
            </div>
            <div
                ref={(newRef) => {
                    collapseContainerRef.current = newRef;
                }}
                className={classNames(
                    styles.body,
                    defaultTo(classes?.body, "")
                )}
                style={{
                    ...(isCollapsible ? initialStyle.current : {}),
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default AppContainerHeader;
