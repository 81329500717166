import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./MessageListEnd.module.scss";

export interface MessageListEndProps {
    totalItemCount: number;
}

export const MessageListEnd: React.FC<MessageListEndProps> = ({
    totalItemCount,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <h4>
                {totalItemCount && totalItemCount > 0
                    ? t("message.noMoreMessages")
                    : t("message.messageListEmpty")}
            </h4>
        </div>
    );
};

export default MessageListEnd;
