import React from "react";
import { AuthLoader, ForgotPasswordFields } from "./partials";

export const ForgotPassword: React.FC = () => {
    return (
        <AuthLoader
            renderContentWithLoader={(setLoading) => (
                <ForgotPasswordFields setLoading={setLoading} />
            )}
        />
    );
};

export default ForgotPassword;
