import classNames from "classnames";
import AppPopover from "components/AppPopover";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import styles from "./PersonInfoPopper.module.scss";

interface EmpKeyGroupPopperProps {
    value?: { [key in string]: number };
    text: string;
    className?: string;
}

const Component: React.FC<EmpKeyGroupPopperProps> = ({
    value,
    text,
    className,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    const trigger = <span className={classNames(className)}>{text}</span>;
    return isNil(value) ? (
        trigger
    ) : (
        <AppPopover
            triggerOnClick={false}
            delay={200}
            trigger={"mouseenter focus"}
            containerClass={` mb-auto`}
            placement={"auto-end"}
            triggerElem={trigger}
        >
            <Container className={styles.root}>
                <Row>
                    <Col xs={12}>
                        <div className={`d-flex flex-column ${styles.body}`}>
                            <Table striped className="no-gutters">
                                <thead>
                                    <tr>
                                        <th>
                                            {t("occupancyPlanning.ageGroup")}
                                        </th>
                                        <th>
                                            {t(
                                                `occupancyPlanning.table.employeeKey`
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(value).map((a) => (
                                        <tr key={uuid()}>
                                            <td>{a}</td>
                                            <td>
                                                {formatNumber(
                                                    value[a],
                                                    FOUR_PRECISION_NUMERIC
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AppPopover>
    );
};
export const EmpKeyGroupPopper = React.memo(Component);
export default EmpKeyGroupPopper;
