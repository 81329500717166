import { StartAndEndDateFields } from "../StartAndEndDateFields";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer, AppContainerFooter } from "components/Containers";
import ErrorMessage from "components/ErrorMessage";
import {
    AppInputField,
    AppNumberInput,
    AppSwitch,
} from "components/FormFields";
import { FORMAT_INTEGER } from "globals/constants";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { Business, BusinessType } from "models/business";
import {
    AppResponse,
    getInitializedValidityState,
    Optional,
    ValidityStateManager,
} from "models/general";
import {
    CompensationTemplateType,
    validateCompensationTemplate,
    getDefaultCompensationTemplate,
    CompensationTemplate,
    CompensationTemplateResponse,
    getCompensationTemplateFromResponse,
    getDefaultFieldsForCompensationType,
} from "models/compensationTemplate";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    getLocationSettingsServiceKey,
    LocationSettingsService,
} from "services/lillywait/locationSettings";
import { CompensationTemplateFieldsContainer } from "./partials/createEditPartials";
import { businessSettingRoutes } from "routing/routes/business/settings";
import { PermissionAccessMap } from "models/permissionManagement";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useNavigate } from "react-router";
import CompensationTemplateService, {
    getCompensationTemplateServiceKey,
} from "services/CompensationTemplateService";
import { useCurrentLocationSettingsContext } from "screens/lillywait/locationSettings/tabs/LocationSettingTabs";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { AppLoader } from "components/Loaders";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import moment from "moment";

const basePath = businessSettingRoutes().screens.compensationTemplates;
type PathBase = typeof basePath;
export interface CompensationTemplateCreateEditProps {
    id: Optional<string>;
    currentBusiness: Optional<Business>; // null for location-setting views
    locationId?: number; // for lwc it will be lwc location-setting id
    basePathProvider: PathBase;
    createOptions?: { clone: boolean; followup: boolean };
    permissionsMap: PermissionAccessMap;
    isLwc?: boolean;
    useLocationData?: boolean;
}

export const CompensationTemplateCreateEdit: React.FC<
    CompensationTemplateCreateEditProps
> = ({
    id,
    currentBusiness,
    locationId,
    useLocationData,
    createOptions = { clone: false, followup: false },
    permissionsMap,
    isLwc,
    basePathProvider,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const navigate = useNavigate();
    const { encodedId } = useSessionBusiness();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();
    const locationSettingData = useCurrentLocationSettingsContext(); // only for location settings
    const [state, setState] = useState<CompensationTemplate | undefined>(
        getDefaultCompensationTemplate(
            CompensationTemplateType.MultiOption,
            locationSettingData?.dataItem?.ForBusiness == BusinessType.LW ||
                currentBusiness?.Type == BusinessType.LW
        )
    );

    const templateService = new CompensationTemplateService(
        linkProvider.api.compensationTemplates(
            locationId ? (locationId as number) : 0,
            currentBusiness?.Id
        )
    );

    const {
        isLoading: getLoading,
        isFetching,
        data: templateRes,
    } = useQuery(
        getCompensationTemplateServiceKey("getDetails", {
            businessId: currentBusiness?.Id,
            locationId: locationId,
            id: id,
        }),
        async () => {
            if (id) {
                return await templateService.getDetails(
                    id,
                    createOptions.clone,
                    createOptions.followup,
                    !isNil(currentBusiness) && createOptions.followup
                        ? true
                        : false
                );
            }
        },
        {
            cacheTime: 0,
            select: (resp) => {
                if (resp?.Data) {
                    return getCompensationTemplateFromResponse(resp.Data);
                } else if (!isNil(id)) {
                    showErrorPage(404);
                }
            },
        }
    );

    useEffect(() => {
        if (!getLoading && !isFetching && templateRes) {
            setState({
                ...state,
                ...templateRes,
            });
        }
    }, [getLoading, isFetching]);

    const {
        isLoading: editLoading,
        mutate: edit,
        data: editRes,
    } = useMutation(async () => {
        if (state) {
            return await templateService.edit(state);
        }
    });
    const {
        isLoading: createLoading,
        mutate: create,
        data: createRes,
    } = useMutation(async () => {
        if (state) {
            return await templateService.create(state);
        }
    });

    const pleaseSelect = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;
    const isLw =
        currentBusiness?.Type == BusinessType.LW ||
        locationSettingData?.dataItem?.ForBusiness == BusinessType.LW;

    const businessTypes = [
        pleaseSelect,
        ...Object.values(BusinessType)
            .filter((x) => x != BusinessType.LW)
            .map((x) => {
                return {
                    label: t(
                        `business.businessType.${x.toString().toLowerCase()}`
                    ),
                    value: x,
                } as SimpleOption;
            }),
    ];

    // for NonLW templates need to show template type dropdown
    const templateTypes = [
        ...Object.values(CompensationTemplateType)
            .filter((x) => x != CompensationTemplateType.LillywaitCompensation)
            .map((x) => {
                return {
                    label: t(`compensationTemplate.types.${x.toString()}`),
                    value: x,
                } as SimpleOption;
            }),
    ];

    const locationSettingsService = new LocationSettingsService(
        linkProvider.lillywait.locationSettings().api
    );

    const {
        isFetching: loadingLocationOptions,
        isRefetching: refetchingLocationOptions,
        data,
        refetch: refetchLocations,
    } = useQuery(
        getLocationSettingsServiceKey("getList", {
            BusinessType: state?.ForBusiness,
            LillywaitId: currentBusiness?.Id,
        }),
        async () => {
            if (state?.Type == CompensationTemplateType.LillywaitCompensation) {
                return await locationSettingsService.getList(
                    state.ForBusiness,
                    currentBusiness?.Id.toString()
                );
            }
        }
    );

    const getLocationLoading =
        loadingLocationOptions || refetchingLocationOptions;

    useEffect(() => {
        if (
            state &&
            state.Type == CompensationTemplateType.LillywaitCompensation
        ) {
            refetchLocations();
        }
    }, [state?.ForBusiness]);

    const locationOptions =
        data && data?.Data.length > 0
            ? [
                  pleaseSelect,
                  ...data.Data.map((x) => {
                      {
                          return {
                              label: x.Name,
                              value: x.Id,
                          } as SimpleOption;
                      }
                  }),
              ]
            : [pleaseSelect];

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            state
                ? validateCompensationTemplate(state, isLw)
                : getInitializedValidityState([], [])
        );
    }, [state, appLocale]);
    const templateDates = useMemo(() => {
        return {
            StartDate: state ? state.StartDate : moment(),
            EndDate: state ? state.EndDate : null,
        } as StartAndEndDates;
    }, [state?.StartDate, state?.EndDate]);

    const {
        title,
        minStartDate,
        canEdit,
        isParentTemplate,
        isFollowupTemplate,
    } = useMemo(() => {
        const isParent =
            isNil(state?.FollowUpBusinessParentId) &&
            isNil(state?.FollowUpLocationParentId);
        const isFollowup =
            defaultTo(state?.FollowUpBusinessParentId, 0) > 0 ||
            defaultTo(state?.FollowUpLocationParentId, 0) > 0;
        const isForLocation = !isNil(state?.LocationSettingId);
        return {
            canEdit:
                isNil(currentBusiness) || // location-setting view
                (!isNil(currentBusiness) && !isForLocation && !useLocationData), // can edit only non-location templates in business settings
            isParentTemplate: isParent,
            isFollowupTemplate: isFollowup,
            minStartDate:
                state &&
                (state.FollowUpBusinessParentId ||
                    state.FollowUpLocationParentId) &&
                !isNil(state.ParentEndDate)
                    ? state.ParentEndDate.clone()
                          .add(1, "month")
                          .startOf("month")
                    : undefined,
            title:
                state && state.Id > 0
                    ? state.Name
                    : isParent
                    ? t("templates.createTemplate")
                    : t("templates.addFollowUpTemplate", {
                          name: state?.Name,
                      }),
        };
    }, [state?.Name, state]);

    const handleResponse = (
        response: AppResponse<CompensationTemplateResponse>,
        isEdit: boolean = false
    ) => {
        if (response.Data) {
            showSweetAlertToast(
                t("common.success"),
                `"${response.Data.Name}" ${t(
                    `common.actions.${
                        isEdit ? "updatedSuccessfully" : "createdSuccessfully"
                    }`
                )}`,
                "success"
            );

            queryClient.invalidateQueries(
                getCompensationTemplateServiceKey("getTemplates", {
                    businessId: currentBusiness?.Id,
                    locationId: locationId,
                    sessionBusinessId: encodedId,
                })
            );

            navigate(basePathProvider.list());
        } else if (response.Message) {
            showSweetAlertToast(
                t("common.error.error"),
                response.Message,
                "error"
            );
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t(
                    `common.actions.errors.${
                        isEdit ? "unableToUpdate" : "unableToCreate"
                    }`
                ),
                "error"
            );
        }
    };
    useEffect(() => {
        if (!editLoading && editRes) {
            handleResponse(editRes, true);
        }
    }, [editLoading, editRes]);
    useEffect(() => {
        if (!createLoading && createRes) {
            handleResponse(createRes);
        }
    }, [createLoading, createRes]);

    const readonly = !(
        canEdit && (!isNil(id) ? permissionsMap.EDIT : permissionsMap.CREATE)
    );

    return (
        <div style={{ position: "relative" }}>
            {!state || getLoading || isFetching ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={true}
                    onBack={() => navigate(basePathProvider.list())}
                    title={title}
                    hasInnerScroll={true}
                    footer={
                        !readonly ? (
                            <AppContainerFooter
                                primaryButtonProps={{
                                    disabled:
                                        !validityStateManager.isStateValid() ||
                                        editLoading ||
                                        createLoading,
                                    onClick: () => {
                                        if (!readonly) {
                                            if (state.Id > 0) {
                                                // edit
                                                edit();
                                            } else {
                                                create();
                                            }
                                        }
                                    },
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Row>
                        <Col md={6}>
                            <AppInputField
                                label={t("templates.templateName.name")}
                                value={state.Name}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Name: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Name"
                                )}
                            />
                        </Col>
                        <Col md={!isLw ? 3 : 6}>
                            <AppInputField
                                label={t("templates.templateShortName.name")}
                                value={state.ShortName}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        ShortName: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "ShortName"
                                )}
                            />
                        </Col>
                        {!isLw && (
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>
                                        {t("compensationTemplate.type.name")}
                                    </Form.Label>
                                    <AppSelectOld
                                        menuPortalTarget={document.body}
                                        options={templateTypes}
                                        value={
                                            state.Type
                                                ? templateTypes.find(
                                                      (x) =>
                                                          x.value == state.Type
                                                  )
                                                : templateTypes[0]
                                        }
                                        isDisabled={
                                            readonly ||
                                            isFollowupTemplate ||
                                            !defaultTo(
                                                state.CanSetEndDateNull,
                                                true
                                            ) // if can't set end-date to null then either followup exist or template is followup
                                        }
                                        onChange={(opt: SimpleOption) => {
                                            setState({
                                                ...state,
                                                Type: opt.value,
                                                Fields: getDefaultFieldsForCompensationType(
                                                    opt.value,
                                                    [],
                                                    state.CanEditValue
                                                ),
                                            });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        )}
                        <StartAndEndDateFields
                            requiredEndDate={
                                state.Id > 0 && !state.CanSetEndDateNull
                            }
                            minStartDate={minStartDate}
                            colSize={!isLw ? 5 : undefined}
                            useMonthPicker={true}
                            readonly={readonly}
                            appendToBody={true}
                            value={{
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            }}
                            onChange={({ StartDate, EndDate }) => {
                                setState((old) => ({
                                    ...defaultTo(old, {} as any),
                                    StartDate: StartDate,
                                    EndDate: EndDate,
                                }));
                            }}
                        />
                        {!isLw && (
                            <Col md={2}>
                                <AppSwitch
                                    style={{
                                        height: "60px",
                                        paddingTop: "27px",
                                    }}
                                    value={state.IsInternal}
                                    disabled={readonly}
                                    onChange={(checked: boolean) => {
                                        setState({
                                            ...state,
                                            IsInternal: checked,
                                        });
                                    }}
                                    label={t("compensationTemplate.isInternal")}
                                />
                            </Col>
                        )}
                        {isLw && (
                            <>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                "compensationTemplate.forBusiness.name"
                                            )}
                                        </Form.Label>
                                        <AppSelectOld
                                            menuPortalTarget={document.body}
                                            options={businessTypes}
                                            value={
                                                state.ForBusiness
                                                    ? businessTypes.find(
                                                          (x) =>
                                                              x.value ==
                                                              state.ForBusiness
                                                      )
                                                    : businessTypes[0]
                                            }
                                            isDisabled={readonly}
                                            onChange={(opt: SimpleOption) => {
                                                setState({
                                                    ...state,
                                                    ForBusiness: opt.value,
                                                    AppliesToLocationSettingId:
                                                        undefined,
                                                });
                                            }}
                                        />
                                        <ErrorMessage
                                            showEmpty={true}
                                            errorInfo={validityStateManager.getFirstErrorInfo(
                                                "ForBusiness"
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <AppNumberInput
                                        label={t(
                                            "compensationTemplate.freeMonths.name"
                                        )}
                                        showError={false}
                                        showEmptyError={true}
                                        showEmptyField={true}
                                        allowZero={false}
                                        isForCurrency={false}
                                        formatTheInitialValue={true}
                                        format={FORMAT_INTEGER}
                                        value={state.FreeMonths}
                                        disabled={readonly}
                                        onChange={(val) =>
                                            setState({
                                                ...state,
                                                FreeMonths: val as number,
                                            })
                                        }
                                        placeholder={t(
                                            "compensationTemplate.freeMonths.name"
                                        )}
                                        error={validityStateManager.getFirstErrorInfo(
                                            "FreeMonths"
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                "compensationTemplate.forLocation.name"
                                            )}
                                        </Form.Label>
                                        <AppSelectOld
                                            menuPortalTarget={document.body}
                                            options={locationOptions}
                                            value={
                                                state.AppliesToLocationSettingId
                                                    ? locationOptions.find(
                                                          (x) =>
                                                              x.value ==
                                                              state.AppliesToLocationSettingId
                                                      )
                                                    : locationOptions[0]
                                            }
                                            isLoading={getLocationLoading}
                                            isDisabled={readonly}
                                            onChange={(opt: SimpleOption) => {
                                                setState({
                                                    ...state,
                                                    AppliesToLocationSettingId:
                                                        opt.value,
                                                });
                                            }}
                                        />
                                        <ErrorMessage
                                            showEmpty={true}
                                            errorInfo={validityStateManager.getFirstErrorInfo(
                                                "AppliesToLocationSettingId"
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>

                    <CompensationTemplateFieldsContainer
                        onChange={(newFields, canEdit?: boolean) => {
                            const newCanEdit =
                                canEdit &&
                                state.Type == CompensationTemplateType.Simple;

                            const newState = {
                                ...state,
                                CanEditValue: !isNil(canEdit)
                                    ? newCanEdit
                                    : state.CanEditValue,
                            } as CompensationTemplate;

                            if (!isNil(canEdit) && newCanEdit) {
                                newState.Fields = []; // remove field if value should be entered in contract
                            } else {
                                newState.Fields =
                                    getDefaultFieldsForCompensationType(
                                        newState.Type,
                                        newFields.map((f, idx) => {
                                            return { ...f, Order: idx };
                                        }),
                                        newCanEdit
                                    );
                            }

                            setState({
                                ...state,
                                ...newState,
                            });
                        }}
                        validityState={validityStateManager.state}
                        templateType={state.Type}
                        fields={state.Fields}
                        canEditValue={state.CanEditValue}
                        templateDates={templateDates}
                        readonly={readonly}
                        isForLocation={!isNil(state?.LocationSettingId)}
                        businessId={
                            (currentBusiness
                                ? currentBusiness.EncodedId
                                : encodedId) as string
                        }
                        locationSettingId={locationId}
                    />
                </AppContainer>
            )}
        </div>
    );
};

export default CompensationTemplateCreateEdit;
