import { FeatureHelpItem } from "models/applicationFeatures";
import React from "react";
import styles from "./FeatureHelpDetailItem.module.scss";
export interface FeatureHelpDetailItemProps {
    value: FeatureHelpItem;
    onSelectItem: () => void;
}
export const FeatureHelpDetailItem: React.FC<FeatureHelpDetailItemProps> = ({
    value,
    onSelectItem,
}) => {
    return (
        <>
            <div className={styles.root}>
                <div
                    className={styles.colorLine}
                    style={{ background: value.Color }}
                />
                <div className="ml-3" style={{ overflow: "hidden" }}>
                    <div className={`${styles.title}`}>
                        <span>{value.Title}</span>
                    </div>
                    {value && value.HelpItem.Description && (
                        <div className={`${styles.explanation}`}>
                            <p>{value.HelpItem.Description}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FeatureHelpDetailItem;
