import { ISO8601_DATE_FORMAT } from "globals/constants";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    EmployeeVacationListResponse,
    EmployeeVacationResponse,
    EmployeeVacation,
} from "models";

export interface IEmployeeVacationService {
    list(year?: number): Promise<AppResponse<EmployeeVacationListResponse>>;
    get(id: string): Promise<AppResponse<EmployeeVacationResponse>>;
    createUpdate(
        model: EmployeeVacation
    ): Promise<AppResponse<EmployeeVacationResponse>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext()
    .business.employees()
    .api.withId().vacations;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getEmployeeVacationServiceKey(
    name: keyof IEmployeeVacationService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `EmployeeVacationService_${name}_${JSON.stringify(data)}`;
}

export class EmployeeVacationService
    extends BaseService
    implements IEmployeeVacationService
{
    public vacationRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.vacationRoutes = apiLinkProvider;
    }

    list(year?: number): Promise<AppResponse<EmployeeVacationListResponse>> {
        return this.doServerXHR<EmployeeVacationListResponse>({
            url: this.vacationRoutes.list(year),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<EmployeeVacationResponse>> {
        return this.doServerXHR<EmployeeVacationResponse>({
            url: this.vacationRoutes.get(id),
            method: "get",
        });
    }

    createUpdate(
        model: EmployeeVacation
    ): Promise<AppResponse<EmployeeVacationResponse>> {
        return this.doServerXHR<EmployeeVacationResponse>({
            url: this.vacationRoutes.createUpdate(),
            method: "post",
            data: {
                ...model,
                StartDate: model.DateStart?.format(ISO8601_DATE_FORMAT),
                EndDate: model.DateEnd
                    ? model.DateEnd.format(ISO8601_DATE_FORMAT)
                    : undefined,
            },
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.vacationRoutes.delete(id),
            method: "post",
        });
    }
}

export default EmployeeVacationService;
