import {
    DocumentArchive,
    DocumentArchiveItemResponse,
    DocumentArchiveRequest,
} from "models/archive";
import { DocumentType } from "models/documentTemplates";
import { UpdateDisplayIdResponseAndRequest, UserType } from "models/general";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IDocumentArchiveService {
    getDocumentArchive(
        id: number
    ): Promise<AppResponse<DocumentArchiveItemResponse>>;
    createUpdate(
        documentArchive: DocumentArchive
    ): Promise<AppResponse<DocumentArchive>>;
    uploadDocumentArchiveFiles(
        documentArchive: DocumentArchive,
        userType: UserType,
        userId?: number,
        isLWC?: boolean
    ): Promise<AppResponse<boolean>>;
    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest,
        userId?: number,
        isLWC?: boolean
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>>;
    getDocumentArchiveList(
        reqParams?: DocumentArchiveRequest
    ): Promise<AppResponse<DocumentArchiveItemResponse[]>>;
    delete(id: number): Promise<AppResponse<boolean>>;
    deleteFile(
        businessDocumentArchiveId: number,
        fileId: number
    ): Promise<AppResponse<number>>;
}

const apiLinkProvider =
    routesForContext().business.api.currentBusiness().documentArchive;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getDocumentArchiveServiceKey(
    name: keyof IDocumentArchiveService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `docArchive_${name}_${JSON.stringify(data)}`;
}
export class DocumentArchiveService
    extends BaseService
    implements IDocumentArchiveService
{
    public archiveRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.archiveRoutes = apiLinkProvider;
    }

    constructFormData(
        documentArchive: DocumentArchive,
        userType: UserType,
        userId?: number,
        isLWC: boolean = false
    ) {
        const { Files, Id, ...rest } = documentArchive;

        const fD = new FormData();
        if (Files) {
            for (let i = 0; i < Files.length; i++) {
                fD.append("files", Files[i]);
            }
        }
        if (userId) {
            fD.append("userId", userId.toString());
        }
        fD.append("isLWC", isLWC.toString());
        fD.append("businessDocumentArchiveId", Id ? Id.toString() : "0");
        fD.append("userType", userType);

        return fD;
    }

    getDocumentArchive(
        id: number
    ): Promise<AppResponse<DocumentArchiveItemResponse>> {
        return this.doServerXHR<DocumentArchiveItemResponse>({
            url: this.archiveRoutes.get(id),
            method: "get",
        });
    }
    createUpdate(
        documentArchive: DocumentArchive
    ): Promise<AppResponse<DocumentArchive>> {
        const { Id, ...rest } = documentArchive;
        const fD = new FormData();
        if (rest.CreationArea.length == 0) {
            rest.CreationArea.push(DocumentType.Client);
            rest.CreationArea.push(DocumentType.Employee);
        }
        const json = JSON.stringify({
            ...rest,
        });
        fD.append("id", Id ? Id.toString() : "0");
        fD.append("Data", json);
        return this.doServerXHR<DocumentArchive>({
            url: this.archiveRoutes.createUpdate(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    uploadDocumentArchiveFiles(
        documentArchive: DocumentArchive,
        userType: UserType,
        userId?: number,
        isLWC: boolean = false
    ): Promise<AppResponse<boolean>> {
        const fD = this.constructFormData(
            documentArchive,
            userType,
            userId,
            isLWC
        );
        return this.doServerXHR<DocumentArchive>({
            url: this.archiveRoutes.uploadFiles(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest,
        userId?: number,
        isLWC: boolean = false
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>> {
        const formData = new FormData();
        formData.append("displayId", req.DisplayId.toString());
        formData.append("businessArchiveId", req.Id.toString());
        if (req.userType) {
            formData.append("userType", req.userType.toString());
        }
        if (userId) {
            formData.append("userId", userId.toString());
        }
        formData.append("isLWC", isLWC.toString());
        return this.doServerXHR<boolean>({
            url: this.archiveRoutes.updateDisplayId(),
            method: "post",
            data: formData,
        });
    }
    getDocumentArchiveList(
        request: DocumentArchiveRequest
    ): Promise<AppResponse<DocumentArchiveItemResponse[]>> {
        return this.doServerXHR<AppResponse<DocumentArchiveItemResponse[]>>({
            url: this.archiveRoutes.getDocumentArchiveList({ request }),
            method: "get",
        });
    }
    delete(id: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.archiveRoutes.delete(id),
            method: "post",
        });
    }

    deleteFile(
        businessDocumentArchiveId: number,
        fileId: number
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.archiveRoutes.deleteFile(
                businessDocumentArchiveId,
                fileId
            ),
            method: "post",
        });
    }
}

export default DocumentArchiveService;
