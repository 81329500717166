export enum EditorType {
    DocumentTemplate = "Document_Template",
    DocumentLetterhead = "Document_Letterhead",
    Simple = "Editor_Simple",
    ClientRegistrationEmail = "ClientRegistrationEmail",
}
export enum PlaceholderType {
    GenderSpecific = "GenderSpecific",
    OpenInput = "OpenInput",
    DynamicInput = "DynamicInput",
    EditableSection = "EditableSection",
}
