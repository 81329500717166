import { AuthenticatedUserRoutingContext } from "routing/routingContexts";
import { getUrlForPattern } from "routing/routingContexts/helper";
import { businessRoutes } from "../business";

export const authUserRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: AuthenticatedUserRoutingContext = new AuthenticatedUserRoutingContext()
) => {
    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {},
        screens: {
            editProfile: () => {
                // no need to get tabs here as I am already fetching it from context in component
                return getUrlForPattern(
                    context,
                    buildUrl,
                    "edit-profile",
                    false
                );
            },
        },
    };
};

export default businessRoutes;
