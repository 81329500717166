import AppHelmet from "components/AppHelmet";
import React from "react";
import AuthCircleIcon from "./AuthCircleIcon";
import styles from "./AuthHeader.module.scss";

export interface AuthHeaderProps {
    mainHeading: string;
    pageTitle: string;
}

export const AuthHeader: React.FC<AuthHeaderProps> = ({
    mainHeading,
    pageTitle,
}) => {
    return (
        <div className={styles.root}>
            <AppHelmet title={pageTitle} />
            <div className={`${styles.circleIconDiv}`}>
                <AuthCircleIcon color={"pink"} className={styles.pinkIcon} />
                <AuthCircleIcon color={"blue"} />
            </div>
            <div className={styles.mainHeading}>{mainHeading}</div>
        </div>
    );
};

export default AuthHeader;
