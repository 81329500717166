import { useAuthenticatedPageContext } from "hoc/providers";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { useRouting } from "hooks/general/routing";
import { PermissionAccessTypes } from "models/permissionManagement";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useParams } from "react-router";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { useQuery } from "react-query";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { isNil } from "lodash-es";
import { Business } from "models";
import { AppLoader } from "components/Loaders";

// will filter and handle redirection for routes that is only allowed for specific permissions
export const RestrictedRoute: React.FC<
    PropsWithChildren<{
        permKeys: string[] | undefined;
        applicationFeatureKeys?: string[] | undefined;
        requiredModules?: string[] | undefined;
        requiredModuleRootCheck?: boolean;
        accessType?: PermissionAccessTypes[];
        checkPermissionForParam?: "businessId";
    }>
> = ({
    children,
    permKeys,
    requiredModules,
    applicationFeatureKeys,
    requiredModuleRootCheck = true,
    accessType,
    checkPermissionForParam,
}) => {
    const {
        setPageFeatureKey,
        setPagePermissions,
        setRequiredModules,
        setRequiredModuleRootCheck,
    } = useAuthenticatedPageContext();
    const { isAuthenticated, loaded } = useSessionUser();
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );

    const { businessId } = useParams();
    const [businessInfo, setBusinessInfo] = useState<Business | undefined>(
        undefined
    );

    const businessIdForCall = useRef<string | undefined>(undefined);
    const { checkPermission: checkBusinessPermission } =
        useBusinessCheckPermission();

    useEffect(() => {
        setPageFeatureKey(applicationFeatureKeys);
        setRequiredModules(requiredModules);
        setRequiredModuleRootCheck(requiredModuleRootCheck);
        setPagePermissions(permKeys);
    }, [
        permKeys,
        applicationFeatureKeys,
        requiredModules,
        requiredModuleRootCheck,
    ]);

    const {
        isFetching: isFetchingInfo,
        refetch: fetchBusinessInfo,
        isRefetching: refetchingInfo,
    } = useQuery(
        "",
        async () =>
            await businessService.getBasicInfo(businessIdForCall.current),
        {
            queryKeyHashFn: () =>
                // as key is dynamic and can be wrong in first render
                getCurrentBusinessServiceKey("getBasicInfo", {
                    businessId: businessIdForCall.current,
                }),
            select: (data) => {
                if (
                    data &&
                    data.Data &&
                    (isNil(businessInfo) || data.Data.Id != businessInfo.Id)
                ) {
                    setBusinessInfo(data.Data);
                }
                return data && data.Data;
            },
            enabled: false,
            cacheTime: 10000,
        }
    );

    useEffect(() => {
        if (!isNil(checkPermissionForParam)) {
            switch (checkPermissionForParam) {
                case "businessId":
                    businessIdForCall.current = businessId;
                    fetchBusinessInfo();
                    break;
            }
        }
    }, [checkPermissionForParam, businessId]);

    // const toReturnRef = useRef<ReactNode>(<></>);
    const toReturn = useMemo(() => {
        const fetching = isFetchingInfo || refetchingInfo;
        const infoLoaded =
            !checkPermissionForParam ||
            (checkPermissionForParam && !fetching && !isNil(businessInfo));

        if (!loaded || !infoLoaded) {
            return <AppLoader />;
        } else {
            let hasPermission = isAuthenticated && !permKeys;
            if (!hasPermission && permKeys && permKeys.length > 0) {
                const hasSessionBusinessPermission =
                    isNil(checkPermissionForParam) &&
                    checkPermission(permKeys, accessType);
                const hasBusinessPermission =
                    checkPermissionForParam && !fetching && businessInfo
                        ? checkBusinessPermission(
                              businessInfo.Id,
                              businessInfo.HqId,
                              permKeys,
                              accessType
                          )
                        : false;

                hasPermission =
                    hasSessionBusinessPermission || hasBusinessPermission;
            }
            if (!hasPermission) {
                //user don't have permission for the page
                //redirectTo to the home()
                const redirectTo = linkProvider.screens.home(checkPermission);

                console.debug("no permission...redirecting...", redirectTo);
                return <Navigate to={redirectTo} replace />;
            }

            return <>{children}</>;
        }
    }, [
        accessType,
        checkPermission,
        children,
        checkPermissionForParam,
        isAuthenticated,
        linkProvider.screens,
        isFetchingInfo,
        refetchingInfo,
        businessInfo,
        loaded,
        permKeys,
    ]);

    return <>{toReturn}</>;
};

export default RestrictedRoute;
