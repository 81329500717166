import AppDropdownMenu, { DropdownMenuItem } from "components/AppDropdownMenu";
import { AppCheckbox } from "components/FormFields";
import { getParamName } from "globals/helpers/generalHelper";
import {
    showSweetAlertConfirmation,
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { isFirefox } from "globals/helpers/webInfoHelper";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { Selectable } from "models/general";
import {
    AppNotificationType,
    NotificationModel,
    translateTitleAndDetail,
} from "models/notification";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { NotificationService } from "services/business";
import styles from "./NotificationItem.module.scss";

export interface NotificationItemProps {
    value: Selectable<NotificationModel>;
    type: AppNotificationType;
    canEdit: boolean;
    canDelete: boolean;
    onChange: (template: Selectable<NotificationModel>) => void;
    removeClickHandler(id: number): void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
    value,
    type,
    onChange,
    canEdit,
    canDelete,
    removeClickHandler,
}) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();

    const notificationService = new NotificationService(
        linkProvider.business.api.notification
    );

    value = translateTitleAndDetail(value, getDateFormatForLocale());

    const {
        isLoading: markReadLoading,
        data: markReadResponse,
        mutate: markRead,
    } = useMutation(async () => await notificationService.markRead(value.Id));

    const {
        isLoading: loading,
        data: response,
        mutate: deleteNotification,
    } = useMutation(async () => await notificationService.delete(value.Id));

    useEffect(() => {
        if (!markReadLoading && markReadResponse) {
            showSweetAlertToast(
                t("common.success"),
                t(
                    `notificationManagement.${
                        type === "downloads" ? "downloadSuccess" : "readSuccess"
                    }`
                ),
                "success"
            );
            onChange({
                ...value,
                IsRead: true,
            });
        }
    }, [markReadLoading, markReadResponse]);

    useEffect(() => {
        if (!loading && response) {
            showSweetAlertInfo(
                t("common.success"),
                t(`notificationManagement.deleteSuccess`),
                "success"
            );
            removeClickHandler(value.Id);
        }
    }, [response, loading]);

    const linkChildren = (
        <>
            <span
                className={styles.imgSpan}
                style={{ backgroundColor: value.BackgroundColor }}
            >
                <Image src={value.IconUrl} rounded />
            </span>

            <div className={`${styles.textDiv}`}>
                <p
                    className={`${styles.title} ${
                        value.IsRead == false ? "opacity-10" : "opacity-7"
                    }`}
                >
                    {value.Title}
                </p>
                <span
                    className={`${styles.description} ${
                        value.IsRead == false ? "opacity-10" : "opacity-7"
                    }`}
                >
                    <p>{value.Detail}</p>
                </span>
            </div>
        </>
    );

    return (
        value && (
            <div className={`${styles.itemOuter}`}>
                <div className={styles.itemLeft}>
                    <div className={styles.checkBox}>
                        <AppCheckbox
                            checked={defaultTo(value.isChecked, false)}
                            onChange={() => {
                                onChange({
                                    ...value,
                                    isChecked: !value.isChecked,
                                });
                            }}
                        />
                    </div>
                </div>
                {type == "notifications" ? (
                    <Link
                        className={`${styles.content} ${
                            value.TargetUrl ? styles.cursorPointer : ""
                        }`}
                        onClick={() => {
                            if (!value.IsRead) {
                                markRead();
                            }
                        }}
                        to={defaultTo(getParamName(value.TargetUrl), "")}
                        target={"blank"}
                    >
                        {linkChildren}
                    </Link>
                ) : (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                        className={`${styles.content} ${
                            value.TargetUrl ? styles.cursorPointer : ""
                        }`}
                        onClick={() => {
                            if (!value.IsRead) {
                                markRead();
                            }
                        }}
                        target={isFirefox() ? "_blank" : "_self"} // firefox blocks click and clears signalR if opened in same tab
                        href={value.TargetUrl}
                    >
                        {linkChildren}
                    </a>
                )}
                <div className={styles.itemRight}>
                    <Image
                        style={{
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "10px",
                        }}
                        src={ImageAssets.common.clockGray}
                    />
                    <div
                        style={{ minWidth: "50px", marginTop: "-2px" }}
                        className={styles.dateAndNewDiv}
                    >
                        <div style={{ paddingBottom: "5px" }}>
                            {value.TimeStamp.fromNow()}
                        </div>
                        {(value.IsRead == null || value.IsRead == false) && (
                            <div className={styles.newText}>
                                {t("common.new")}
                            </div>
                        )}
                    </div>
                    {(value.IsRead == null ||
                        value.IsRead == false ||
                        canDelete) && (
                        <div className={styles.optionMenu}>
                            <AppDropdownMenu
                                trigger={
                                    <Image
                                        className={styles.menuIcon}
                                        src={ImageAssets.common.menu}
                                    />
                                }
                                menuItems={
                                    [
                                        ...(value.IsRead == null ||
                                        value.IsRead == false
                                            ? [
                                                  {
                                                      label: t(
                                                          "notificationManagement.markRead"
                                                      ),
                                                      onClick: () => {
                                                          markRead();
                                                      },
                                                  },
                                              ]
                                            : []),
                                        ...(canDelete
                                            ? [
                                                  {
                                                      label: t(
                                                          "notificationManagement.remove"
                                                      ),
                                                      onClick: () => {
                                                          showSweetAlertConfirmation(
                                                              t(
                                                                  `common.deleteSelected`
                                                              ),
                                                              t(
                                                                  "common.deleteSelectedConfirmation"
                                                              ),
                                                              () => {
                                                                  deleteNotification();
                                                              },
                                                              t(
                                                                  "common.delete.yes"
                                                              ),
                                                              "question",
                                                              true
                                                          );
                                                      },
                                                  },
                                              ]
                                            : []),
                                    ] as DropdownMenuItem[]
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default NotificationItem;
