import { AppTooltip } from "components";
import { AppCollapseToggleButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { useAppContext } from "hoc/providers";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { defaultTo } from "lodash-es";
import React, { useRef, useState } from "react";
import { Image } from "react-bootstrap";
import AnimatedAppHeaderContainer from "./AnimatedAppHeaderContainer";
import styles from "./UserHeaderSection.module.scss";
import { UserInfoPopupMenu } from "./UserInfoDialog";
export interface UserBoxState {
    active: boolean;
}

export const UserHeaderSection = () => {
    const { user } = useSessionUser();
    const { userInfo } = useAppContext();
    const { sessionBusiness } = useSessionBusiness();

    const [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);
    const userName = user?.FirstName + " " + user?.LastName;
    const userAvatar = user?.Avatar ? user.Avatar : ImageAssets.common.person;
    const ref = useRef<HTMLDivElement>();

    let textEllipse = false;

    const ellipseNameUsingLength = (name: string, length: number) => {
        if (name && name.length > length) {
            textEllipse = true;
            return `${name.substring(0, length)}...`;
        }
        return name;
    };

    const companyNameAndRoleName = `${ellipseNameUsingLength(
        defaultTo(sessionBusiness?.Name, ""),
        30
    )} - ${ellipseNameUsingLength(
        defaultTo(userInfo?.AccessInfo?.ActiveRoleScope?.Name, ""),
        20
    )}`;

    return (
        <div
            className="d-flex justify-content-end align-items-center"
            style={{ position: "relative" }}
        >
            <div
                ref={ref as any}
                style={{ display: "flex", alignItems: "center" }}
            >
                <Image
                    roundedCircle
                    src={user?.Avatar ? user.Avatar : ImageAssets.common.person}
                    className={styles.avatarImg}
                    onClick={() => {
                        setOpenUserInfoDialog(!openUserInfoDialog);
                    }}
                />
                <AppCollapseToggleButton
                    visible={openUserInfoDialog}
                    className={styles.toggleButton}
                    iconClassName={styles.toggleButtonIcon}
                    onClick={() => {
                        setOpenUserInfoDialog(!openUserInfoDialog);
                    }}
                />
            </div>

            <AnimatedAppHeaderContainer
                triggerRef={ref}
                open={openUserInfoDialog}
                setOpen={setOpenUserInfoDialog}
                positionRight={40}
            >
                <UserInfoPopupMenu
                    userName={userName}
                    userAvatar={userAvatar}
                    closeMenu={() => setOpenUserInfoDialog(false)}
                />
            </AnimatedAppHeaderContainer>

            <div>
                <div className="widget-content-left  ml-3 header-user-info">
                    <div className={styles.userName}>
                        <span>{userName}</span>
                    </div>
                    <div className={styles.subHeading}>
                        {!textEllipse ? (
                            <span>{companyNameAndRoleName}</span>
                        ) : (
                            <AppTooltip
                                content={`${defaultTo(
                                    sessionBusiness?.Name,
                                    ""
                                )} - ${defaultTo(
                                    userInfo?.AccessInfo?.ActiveRoleScope?.Name,
                                    ""
                                )}`}
                            >
                                <span>{companyNameAndRoleName}</span>
                            </AppTooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserHeaderSection;
