import { FeatureHelpItem } from "models/applicationFeatures";
import React from "react";
import { Image } from "react-bootstrap";
import { COLOR_GREY_LIGHTER } from "theme/themeConstants";
import { FeatureHelpThumbnailOverlay } from "./FeatureHelpThumbnailOverlay";
import styles from "./FeatureHelpViewThumbnail.module.scss";

export interface FeatureHelpViewThumbnailProps {
    value: FeatureHelpItem;
    onSelectItem: () => void;
    isLast?: boolean;
}
// eslint-disable-next-line react/display-name
export const FeatureHelpViewThumbnail: React.FC<
    FeatureHelpViewThumbnailProps
> = ({ value, onSelectItem, isLast = false }) => {
    const videoId = value.HelpItem.Url?.split("/").pop();
    const hasDescription = value && value.HelpItem.Description;
    return (
        <>
            <div
                className={`${styles.root}`}
                style={{
                    borderBottom: !isLast
                        ? `1px solid ${COLOR_GREY_LIGHTER}`
                        : "",
                }}
            >
                <div
                    className={styles.container}
                    onClick={(e) => {
                        e.stopPropagation();
                        onSelectItem();
                    }}
                >
                    <div
                        className={`${styles.thumbnailContainer}`}
                        style={{
                            marginBottom: hasDescription ? "0px" : "20px",
                        }}
                    >
                        <Image
                            src={`https://vumbnail.com/${videoId}.jpg`}
                            className=""
                            alt={`thumbnail_${videoId}`}
                        />
                        <FeatureHelpThumbnailOverlay title={value.Title} />
                    </div>
                    {hasDescription && (
                        <div className={`${styles.explanation}`}>
                            <p>{value.HelpItem.Description}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FeatureHelpViewThumbnail;
