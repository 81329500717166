import CompanyDataFieldContainer from "commonPartials/companyDataFields/CompanyDataFieldContainer";
import { ZipCodeAndCitySelector } from "commonPartials/location";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppButton } from "components/Buttons";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { FORMAT_INTEGER } from "globals/constants";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useAppContext } from "hoc/providers";
import {
    useSessionBusiness,
    useUpdateSessionBusinessInfo,
} from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    BusinessCreateEditRequest,
    BusinessDetails,
    BusinessType,
    CompanyType,
    validateBusinessDetail,
} from "models/business";
import { BusinessCompanyDataField } from "models/companyDataField";
import {
    getValidityStateFromApiResponse,
    Optional,
    ValidityStateManager,
} from "models/general";
import { ZipCodeItem } from "models/location";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    BusinessService,
    getBusinessServiceKey,
    getCurrentBusinessServiceKey,
} from "services/business";
import GeneralService, { getGeneralServiceKey } from "services/GeneralService";
import {
    getRegistrationRequestsServiceKey,
    LillywaitRegistrationRequestsService,
} from "services/lillywait/LillywaitRegistrationRequestsService";

function getShortName(name?: string) {
    if (name) {
        return name.length > 4
            ? name.substring(0, 2) + name.substring(name.length - 2)
            : name;
    }
}

export interface BusinessCreateEditFieldsProps {
    branchType?: CompanyType;
    isCreate: boolean;
    isHqRequestSection?: boolean;
    hasEditPermission?: boolean;
    isLwc?: boolean;
    currentBusiness?: Optional<BusinessCreateEditRequest>; //currentBusiness is undefined in create
    registrationRequestId?: string;
    onSuccess: () => void;
    setLoading?: (loadingValue: boolean) => void;
}

export const BusinessCreateEditFields: React.FC<
    BusinessCreateEditFieldsProps
> = ({
    branchType = CompanyType.Branch,
    isCreate,
    hasEditPermission,
    isLwc = false,
    isHqRequestSection = false,
    currentBusiness,
    registrationRequestId,
    onSuccess,
    setLoading,
}) => {
    const paramBusinessId = !isCreate ? currentBusiness?.EncodedId : undefined;
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { locale } = useAppContext();
    const generalService = new GeneralService(linkProvider.api.general);
    const { hqId, sessionBusiness } = useSessionBusiness();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const { setSessionBusiness } = useUpdateSessionBusinessInfo();

    //handle edit case
    const readOnly = paramBusinessId !== undefined && !hasEditPermission;

    //handle create hq case
    const registrationRequestsService =
        new LillywaitRegistrationRequestsService(
            linkProvider.lillywait.api.registerRequest
        );

    const {
        isFetching: getRegistrationRequestLoading,
        data: registrationRequestResp,
    } = useQuery(
        getRegistrationRequestsServiceKey("getById", { registrationRequestId }),
        async () => {
            if (registrationRequestId) {
                return await registrationRequestsService.getById(
                    registrationRequestId
                );
            }
        },
        {
            refetchOnMount: true,
            onError: () => {
                showErrorPage(404);
            },
        }
    );

    useEffect(() => {
        if (setLoading) {
            setLoading(getRegistrationRequestLoading);
        }
        if (!getRegistrationRequestLoading && registrationRequestResp) {
            if (registrationRequestResp?.Data) {
                setState({
                    ...state,
                    BranchType: CompanyType.Headquarter,
                    Type: BusinessType.KINDERGARTEN,
                    Name: registrationRequestResp.Data.FacilityName,
                    Email: registrationRequestResp.Data.Email,
                    PhoneCentral: registrationRequestResp.Data.Phone,
                    BusinessRegistrationRequestId:
                        registrationRequestResp.Data.Id,
                    ShortInstitutionName: getShortName(
                        registrationRequestResp.Data.FacilityName
                    ),
                });
            }
        }
    }, [getRegistrationRequestLoading]);

    const {
        isLoading: createHqLoading,
        data: createHqRes,
        mutate: createHq,
    } = useMutation(async (request: BusinessDetails) => {
        if (validityStateManager.isStateValid()) {
            return await registrationRequestsService.createHq(request);
        }
    });

    useEffect(() => {
        if (!createHqLoading && createHqRes) {
            if (createHqRes.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `${t(
                        `business.businessType.${state.Type.toString().toLowerCase()}`
                    )} ${t("common.actions.createdSuccessfully")}`,
                    "success"
                );
                onSuccess();
            } else if (createHqRes.Errors) {
                setHasError(true);
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToCreate"),
                    "error"
                );
            }
        }
    }, [createHqLoading, createHqRes]);

    const [hasError, setHasError] = useState(false);
    const [state, setState] = useState<BusinessCreateEditRequest>(
        defaultTo(currentBusiness, {
            BranchType: branchType,
            Type:
                !registrationRequestId && branchType == CompanyType.Headquarter
                    ? BusinessType.KINDERGARTEN
                    : defaultTo(
                          sessionBusiness?.Type,
                          BusinessType.KINDERGARTEN
                      ),
            HqId: branchType == CompanyType.Branch ? hqId : 0,
            LillywaitBusinessId: isHqRequestSection
                ? sessionBusiness?.Id
                : sessionBusiness?.LillywaitBusinessId,
            CountryCode: "DE",
            CurrencyCode: sessionBusiness?.CurrencyCode,
            Timezone:
                branchType == CompanyType.Branch
                    ? sessionBusiness?.Timezone
                    : Intl.DateTimeFormat().resolvedOptions().timeZone,
            CompanyDataFieldValues: [] as BusinessCompanyDataField[],
        } as BusinessCreateEditRequest)
    );

    const businessService = new BusinessService(linkProvider.business.api);

    const canChangeBusinessType =
        !isCreate &&
        currentBusiness &&
        currentBusiness.BranchType == CompanyType.Headquarter &&
        isLwc;

    const {
        isRefetching: isGetCompanyDataFieldLoading,
        data: getGetCompanyDataFieldResponse,
    } = useQuery(
        getBusinessServiceKey("getCompanyDataFieldValues", {
            id: paramBusinessId,
            type: currentBusiness?.Type,
        }),
        async () =>
            await businessService.getCompanyDataFieldValues(
                defaultTo(
                    isCreate ? sessionBusiness?.EncodedId : paramBusinessId,
                    ""
                )
            )
        // { cacheTime: 0 }
    );

    useEffect(() => {
        if (!isGetCompanyDataFieldLoading && getGetCompanyDataFieldResponse) {
            setState({
                ...state,
                CompanyDataFieldValues: getGetCompanyDataFieldResponse.Data.map(
                    (x) => {
                        return { ...x, Value: isCreate ? undefined : x.Value };
                    }
                ),
            });
        }
    }, [isGetCompanyDataFieldLoading, getGetCompanyDataFieldResponse]);

    const { data: timezoneResponse } = useQuery(
        getGeneralServiceKey("getTimezones", { locale }),
        async () => await generalService.getTimezones(locale) // automatically refetch based on key change
    );
    const {
        isLoading: createLoading,
        data: createRes,
        mutate: createBusiness,
    } = useMutation(async (request: BusinessDetails) => {
        if (validityStateManager.isStateValid()) {
            return await businessService.createBusiness(request);
        }
    });

    useEffect(() => {
        if (!createLoading && createRes) {
            if (createRes.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `${t(
                        `business.businessType.${state.Type.toString().toLowerCase()}`
                    )} ${t("common.actions.createdSuccessfully")}`,
                    "success"
                );
                onSuccess();
            } else if (createRes.Errors) {
                setHasError(true);
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToCreate"),
                    "error"
                );
            }
        }
    }, [createLoading, createRes]);

    const {
        isLoading: updateLoading,
        data: updateRes,
        mutate: updateBusiness,
    } = useMutation(
        async (request: BusinessDetails) => {
            if (validityStateManager.isStateValid() && paramBusinessId) {
                return await businessService.updateBusiness(
                    paramBusinessId,
                    request
                );
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    getBusinessServiceKey("getBusinessDetail")
                );
                queryClient.invalidateQueries(
                    getBusinessServiceKey("getCompanyDataFieldValues")
                );
                queryClient.invalidateQueries(
                    getCurrentBusinessServiceKey("getBasicInfo")
                );
                onSuccess();
            },
        }
    );

    useEffect(() => {
        if (!updateLoading && updateRes) {
            if (updateRes.Data) {
                if (updateRes.Data.Id == sessionBusiness?.Id) {
                    setSessionBusiness({
                        ...updateRes.Data,
                        EncodedId: sessionBusiness.EncodedId,
                    });
                }

                showSweetAlertToast(
                    t("common.success"),
                    `${t(
                        `business.businessType.${state.Type.toString().toLowerCase()}`
                    )} ${t("common.actions.updatedSuccessfully")}`,
                    "success"
                );
            } else if (updateRes.Errors) {
                setHasError(true);
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToUpdate"),
                    "error"
                );
            }
        }
    }, [updateLoading, updateRes]);

    const validityStateManager = useMemo(() => {
        let validityState = validateBusinessDetail(state);
        if (hasError && createRes) {
            validityState = getValidityStateFromApiResponse(createRes);
        }
        return new ValidityStateManager(validityState);
    }, [createRes, state, hasError, t]);

    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    }, [state, t]);

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;

    const timezones = timezoneResponse
        ? [
              pleaseSelectOption,
              ...timezoneResponse.Data.map((x) => {
                  return {
                      label: x.Text,
                      value: x.Value,
                  } as SimpleOption;
              }),
          ]
        : [pleaseSelectOption];

    const branchTypes = Object.values(CompanyType).map((x) => {
        return {
            label: t(`business.branchType.${x.toString().toLowerCase()}`),
            value: x,
        } as SimpleOption;
    });
    const businessTypes = Object.values(BusinessType)
        .filter((x) => !isLwc || x != BusinessType.LW)
        .map((x) => {
            return {
                label: t(`business.businessType.${x.toString().toLowerCase()}`),
                value: x,
            } as SimpleOption;
        });

    const lgSize = 4;
    const mdSize = 6;

    return (
        <div style={{ position: "relative" }}>
            <Row>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.companyName.name")}
                        value={state.CompanyName}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, CompanyName: val })
                        }
                        showEmptyError={true}
                        onBlur={() => {
                            setState({
                                ...state,
                                ShortCompanyName: getShortName(
                                    state.CompanyName
                                ),
                            });
                        }}
                        error={validityStateManager.getFirstErrorInfo(
                            "CompanyName"
                        )}
                    />
                </Col>
                {!registrationRequestId &&
                    branchType == CompanyType.Headquarter && (
                        <>
                            <Col lg={lgSize} md={mdSize}>
                                <AppInputField
                                    label={t(
                                        "business.createEdit.adminFirstName.name"
                                    )}
                                    value={state.AdminFirstName}
                                    readOnly={readOnly}
                                    onValueChange={(val) =>
                                        setState({
                                            ...state,
                                            AdminFirstName: val,
                                        })
                                    }
                                    showEmptyError={true}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "AdminFirstName"
                                    )}
                                />
                            </Col>
                            <Col lg={lgSize} md={mdSize}>
                                <AppInputField
                                    label={t(
                                        "business.createEdit.adminLastName.name"
                                    )}
                                    value={state.AdminLastName}
                                    readOnly={readOnly}
                                    onValueChange={(val) =>
                                        setState({
                                            ...state,
                                            AdminLastName: val,
                                        })
                                    }
                                    showEmptyError={true}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "AdminLastName"
                                    )}
                                />
                            </Col>
                        </>
                    )}
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.email.name")}
                        value={state.Email}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, Email: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Email")}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.facilityName.name")}
                        value={state.Name}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, Name: defaultTo(val, "") })
                        }
                        showEmptyError={true}
                        onBlur={() => {
                            setState({
                                ...state,
                                ShortInstitutionName: getShortName(state.Name),
                            });
                        }}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                    />
                </Col>
                {!isCreate && (
                    <Col lg={lgSize} md={mdSize}>
                        <AppInputField
                            label={t("business.createEdit.companyId.name")}
                            value={state.CompanyId}
                            readOnly={true}
                            onBlur={() => {}}
                        />
                    </Col>
                )}
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.phoneCentral.name")}
                        value={state.PhoneCentral}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, PhoneCentral: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "PhoneCentral"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.internet.name")}
                        value={state.Internet}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, Internet: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "Internet"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.telefax.name")}
                        value={state.Telefax}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, Telefax: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "Telefax"
                        )}
                    />
                </Col>
                <Col lg={lgSize / 2} md={mdSize / 2}>
                    <AppInputField
                        label={t("business.createEdit.streetAddress.name")}
                        value={state.StreetAddress}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, StreetAddress: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "StreetAddress"
                        )}
                    />
                </Col>
                <Col lg={lgSize / 2} md={mdSize / 2}>
                    <AppInputField
                        label={t("business.createEdit.streetNumber.name")}
                        value={state.StreetNumber}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, StreetNumber: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "StreetNumber"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t(
                            `business.createEdit.${
                                state.Type == BusinessType.KINDERGARTEN
                                    ? "educationalLeadership"
                                    : "facilityManager"
                            }.name`
                        )}
                        value={state.EducationalLeadership}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                EducationalLeadership: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "EducationalLeadership"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.officePhone.name")}
                        value={state.PhoneOffice}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, PhoneOffice: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "PhoneOffice"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <ZipCodeAndCitySelector
                        onChange={(obj: ZipCodeItem) => {
                            setState({
                                ...state,
                                City: obj.City,
                                State: obj.State,
                                PostCode: obj.Text,
                            });
                        }}
                        country={state.CountryCode}
                        postCode={
                            state.PostCode ? state.PostCode.toString() : null
                        }
                        city={state.City}
                        readOnly={readOnly}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <Form.Group>
                        <Form.Label>
                            {t("business.createEdit.branchType.name")}
                        </Form.Label>
                        <AppSelectOld
                            menuPortalTarget={document.body}
                            options={branchTypes}
                            value={branchTypes.filter(
                                (x) => x.value == state.BranchType
                            )}
                            isDisabled={true}
                            onChange={(opt: SimpleOption) => {
                                setState({
                                    ...state,
                                    BranchType: opt.value,
                                });
                            }}
                            error={validityStateManager.getFirstErrorInfo(
                                "BranchType"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.managingDirector.name")}
                        value={state.ManagingDirector}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                ManagingDirector: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "ManagingDirector"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.state.name")}
                        value={state.State}
                        readOnly={true}
                        onValueChange={(val) =>
                            setState({ ...state, State: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("State")}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.additionalAddress.name")}
                        value={state.AdditionalAddress}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                AdditionalAddress: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AdditionalAddress"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.taxNumber.name")}
                        value={state.TaxNumber}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, TaxNumber: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "TaxNumber"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <Form.Group>
                        <Form.Label>
                            {t("business.createEdit.timezone.name")}
                        </Form.Label>
                        <AppSelectOld
                            menuPortalTarget={document.body}
                            options={timezones}
                            value={
                                state.Timezone
                                    ? timezones.filter(
                                          (x) => x.value == state.Timezone
                                      )
                                    : timezones[0]
                            }
                            isDisabled={readOnly}
                            onChange={(opt: SimpleOption) => {
                                setState({
                                    ...state,
                                    Timezone: opt.value,
                                });
                            }}
                            error={validityStateManager.getFirstErrorInfo(
                                "Timezone"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.accountOwner.name")}
                        value={state.AccountOwner}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, AccountOwner: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AccountOwner"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.accountNumber.name")}
                        value={state.AccountNumber}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, AccountNumber: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AccountNumber"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.bankName.name")}
                        value={state.BankName}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, BankName: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "BankName"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.bankCode.name")}
                        value={state.BankCode}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, BankCode: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "BankCode"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.iban.name")}
                        value={state.IBAN}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, IBAN: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("IBAN")}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.bic.name")}
                        value={state.BIC}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, BIC: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("BIC")}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.commercialRegister.name")}
                        value={state.CommercialRegister}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                CommercialRegister: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "CommercialRegister"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <Form.Group>
                        <Form.Label>
                            {t("business.createEdit.businessType.name")}
                        </Form.Label>
                        <AppSelectOld
                            menuPortalTarget={document.body}
                            options={businessTypes}
                            value={businessTypes.find(
                                (x) => x.value === state.Type
                            )}
                            isDisabled={!canChangeBusinessType}
                            onChange={(opt: SimpleOption) => {
                                setState({
                                    ...state,
                                    Type: opt.value,
                                });
                            }}
                            error={validityStateManager.getFirstErrorInfo(
                                "Type"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.shortCompanyName.name")}
                        value={state.ShortCompanyName}
                        readOnly={true}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                ShortCompanyName: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "ShortCompanyName"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t(
                            "business.createEdit.shortInstitutionName.name"
                        )}
                        value={state.ShortInstitutionName}
                        readOnly={true}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                ShortInstitutionName: val,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "ShortInstitutionName"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppInputField
                        label={t("business.createEdit.affiliateCode.name")}
                        value={state.AffiliateCode}
                        readOnly={readOnly}
                        onValueChange={(val) =>
                            setState({ ...state, AffiliateCode: val })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "AffiliateCode"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppNumberInput
                        label={t("business.createEdit.totalKids.name")}
                        value={state.TotalKidsCapacity}
                        readOnly={readOnly}
                        format={FORMAT_INTEGER}
                        allowZero={true}
                        onChange={(val) =>
                            setState({
                                ...state,
                                TotalKidsCapacity: val as number,
                            })
                        }
                        showError={true}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "TotalKidsCapacity"
                        )}
                    />
                </Col>
                <Col lg={lgSize} md={mdSize}>
                    <AppNumberInput
                        label={t("business.createEdit.desiredKids.name")}
                        value={state.DesiredKidsCapacity}
                        readOnly={readOnly}
                        showError={true}
                        allowZero={true}
                        format={FORMAT_INTEGER}
                        onChange={(val) =>
                            setState({
                                ...state,
                                DesiredKidsCapacity: val as number,
                            })
                        }
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "DesiredKidsCapacity"
                        )}
                    />
                </Col>
            </Row>
            <CompanyDataFieldContainer
                readonly={readOnly}
                value={state.CompanyDataFieldValues}
                isLoading={isGetCompanyDataFieldLoading}
                onChange={(values) =>
                    setState({
                        ...state,
                        CompanyDataFieldValues: values,
                    })
                }
            />
            <Row>
                <Col md={12}>
                    <AppButton
                        className="float-right"
                        disabled={
                            readOnly || !validityStateManager.isStateValid()
                        }
                        onClick={() => {
                            if (!paramBusinessId) {
                                if (!isHqRequestSection) {
                                    createBusiness(state);
                                } else {
                                    createHq(state);
                                }
                            } else {
                                updateBusiness(state);
                            }
                        }}
                    >
                        <span>{t("common.save")}</span>
                    </AppButton>
                </Col>
            </Row>
        </div>
    );
};

export default BusinessCreateEditFields;
