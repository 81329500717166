import { DraggableColItem } from "models/importData";
import React from "react";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProps,
    DroppableProvided,
    DroppableStateSnapshot,
} from "react-beautiful-dnd";
import styles from "./ColumnDroppableList.module.scss";

export interface ColumnDroppableList extends Omit<DroppableProps, "children"> {
    colName: string;
    isRequired?: boolean;
    items: DraggableColItem[];
}

const TypescriptDroppable: any = Droppable;
const TypescriptDraggable: any = Draggable;
function Component(props: ColumnDroppableList) {
    const { isRequired = false, colName, items, ...rest } = props;

    return (
        <TypescriptDroppable {...rest}>
            {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot
            ) => {
                return (
                    <div
                        className={`${styles.list} ${
                            snapshot.isDraggingOver ? styles.active : ""
                        }`}
                        ref={provided.innerRef}
                    >
                        <span className={styles.listName}>
                            {colName}
                            {isRequired ? "*" : ""}
                        </span>
                        {items.map((item, idx) => {
                            return (
                                <TypescriptDraggable
                                    draggableId={item.id}
                                    key={item.id}
                                    index={idx}
                                >
                                    {(
                                        provided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot
                                    ) => (
                                        <div
                                            className={`${styles.card} ${
                                                snapshot.isDragging
                                                    ? styles.active
                                                    : ""
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {item.name}
                                        </div>
                                    )}
                                </TypescriptDraggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                );
            }}
        </TypescriptDroppable>
    );
}

Component.displayName = "ColumnDroppableList";
export const ColumnDroppableList = React.memo(Component);
export default ColumnDroppableList;
