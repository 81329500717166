import styles from "./TabContentWithHeadingSection.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";

export interface TabContentWithHeadingSectionProp {
    title: string;
    className?: string;
    children?: ReactNode;
}
export const TabContentWithHeadingSection: React.FC<
    TabContentWithHeadingSectionProp
> = ({ title, className, children }) => {
    return (
        <div className={classNames(className, styles.root)}>
            <div className={styles.title}>{title}</div>
            {children && <div className={styles.flagsRoot}>{children}</div>}
        </div>
    );
};

export default TabContentWithHeadingSection;
