export const CommonEnTranslations = {
    common: {
        createdAt: "Created At",
        updatedAt: "Updated At",
        lastUpdatedBy: "Last Updated By",
        unknown: "Unknown",
        actions: {
            clonedSuccessfully: "cloned successfully...",
            createdSuccessfully: "created successfully...",
            deletedSuccessfully: "deleted successfully...",
            displayIdUpdatedSuccessfully: "Display ID updated successfully...",
            errors: {
                unableToClone: "Unable to clone",
                unableToCreate: "Unable to create",
                unableToDelete: "Unable to delete",
                unableToDeleteBecause:
                    'Unable to delete {{current}} because it is being used in "{{dueTo}}".',
                unableToUpdate: "Unable to update",
            },
            fileDeletedSuccessfully: "File deleted successfully...",
            filesUploadedSuccessfully: "files uploaded successfully...",
            processingRequest: "Processing request...",
            updatedSuccessfully: "updated successfully...",
        },
        openingHours: "Opening Hours",
        generatePdf: "Generate PDF",
        add: "Add",
        addNew: "Add New",
        administration: "Administration",
        all: "All",
        cancel: "Cancel",
        clearInfo: "Clear info",
        client: "Client",
        clientEmployeeListHeader: {
            createAccountingExcel: "Create Accounting Excel",
            accountingExcelSuccess: "Accounting Excel Created Successfully",
            accountingExcelFailure: "Unable to generate accounting excel.",
            missingEmail: {
                desc: "The selected records don't have any email address.",
                title: "Missing Email",
            },
            sendEmail: "Send Email",
        },
        clients: "Clients",
        clone: "Clone",
        copied: "Copied",
        copiedToClipboard: "Text copied to clipboard",
        copy: "Copy",
        copyDescriptionText: "Copy description text",
        copyToEnd: "Copy to End",
        create: "Create",
        count: "Count",
        createNew: "Create New",
        dataLost: "Written text will be lost.",
        date: {
            name: "Date",
            today: "Today",
            yesterday: "Yesterday",
        },
        address: {
            state: { name: "State" },
            city: { name: "City" },
            postCode: { name: "Postal Code" },
            streetNumber: { name: "Street Number" },
            streetAddress: { name: "Street Address" },
        },
        day: "Day",
        days: {
            Friday: "Friday",
            Monday: "Monday",
            Saturday: "Saturday",
            shortName: {
                Friday: "Fr",
                Monday: "Mo",
                Saturday: "Sa",
                Sunday: "Su",
                Thursday: "Th",
                Tuesday: "Tu",
                Wednesday: "We",
            },
            Sunday: "Sunday",
            Thursday: "Thursday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
        },
        delete: {
            cancel: "Cancel",
            error: "Unable to delete!",
            success: "Record has been deleted!",
            title: "Are you sure?",
            yes: "Yes, delete it",
        },
        deleteMessage: "Are you sure you want to delete it?",
        deleteMessageWithName: 'Are you sure you want to delete "{{name}}"?',
        deleteSelected: "Delete Selected",
        deleteSelectedConfirmation:
            "Are you sure you want to delete selected items?",
        deleteText: "Delete",
        disabled: "Disabled",
        diseases: "Diseases",
        documentCompilationDialog: {
            documentArea: "Document Area",
            documentCompileMessage:
                "Template is being compiled for the selected records. We will send you notification when it is done.",
        },
        edit: "Edit",
        emailCriteria: {
            criteria1:
                "The email must start and end with some alpha-numeric characters",
            criteria2: "The email must contain '@'",
            criteria3: `The email should not end with a "."`,
            title: "Email Criteria",
        },
        emojiPicker: {
            title: "Pick Emoji",
        },
        employee: "Employee",
        employees: "Employees",
        enabled: "Enabled",
        endDate: {
            followUpCaseRequiredMsg: "End Date is required for followups.",
            hasNoEndDate: "Has No End Date",
            invalid: "End Date must be greater than start-date.",
            missing: "End Date is required.",
            name: "End Date",
        },
        error: {
            contractExpired:
                "Unable to fetch data because your contract has expired...",
            error: "Error",
            requestFailed: "Unable to complete request... Try again later",
            unexpectedError: "Unexpected error occurred... Try again later",
        },
        errorMessages: {
            compare: "{{field}} doesn't match",
            allOptionsCantBeSelected: "All options can't be selected.",
            email: "Entered email is invalid",
            greaterThanInvalid: "Value must be greater than start.",
            length: "{{field}} should be of length {{value}}",
            lessThanInvalid: "Value must be less than end.",
            maxLength: "Length is greater than {{val}}",
            maxNumber: "Value is greater than the maximum allowed limit",
            minLength: "Length is smaller than the minimum allowed limit",
            minNumber: "Value is smaller than the minimum allowed limit",
            number: "Not a number",
            password:
                "Password should be at-least 8 characters long and must contain lowercase letters, uppercase letters, digits and special character.",
            positiveNum: "Value should be greater than zero.",
            required: "{{field}} is required",
            URL: "Entered email is invalid",
        },
        fileInput: {
            chooseFile: "Choose File...",
        },
        fileSelected: {
            multiple: "{{count}} files selected",
            single: "{{count}} file selected",
        },
        files: {
            name: "Files",
            addFiles: "Add Files",
            noFileFound: "No file found...",
        },
        for: "for",
        gender: {
            name: "Gender",
            missing: "Gender is required",
            Female: "Female",
            Male: "Male",
        },
        goBack: "Go Back",
        hour: "Hour",
        info: "Info",
        kindergarten: {
            clients: "Clients",
        },
        language: {
            bosnian: "Bosnian",
            croatian: "Croatian",
            czech: "Czech",
            dutch: "Dutch",
            english: "English",
            estonian: "Estonian",
            finnish: "Finnish",
            french: "French",
            german: "German",
            greek: "Greek",
            hungarian: "Hungarian",
            italian: "Italian",
            name: "Language",
            polish: "Polish",
            slovak: "Slovak",
            slovenian: "Slovenian",
            spanish: "Spanish",
        },
        messages: "Messages",
        minute: "Minute",
        missing: "Missing",
        missingPermission: {
            desc: "Sorry! You don't have enough permission(s)",
            title: "Missing Permission(s)",
        },
        month: "Month",
        months: "Months",
        new: "New",
        no: "No",
        noDataFound: "No data found",
        noItems: "No Items",
        none: "None",
        noOptionSelected: "Please select at-least one option",
        noSiblings: "No Siblings Selected",
        note: "Note",
        offline: "Offline",
        ok: "Okay",
        online: "Online",
        parents: "Parents",
        passwordCriteria: {
            criteria1: "Password must be at-least 8 characters long",
            criteria2: "The password must contain upper-case letters (A-Z)",
            criteria3: "The password must contain lower-case letters (a-z)",
            criteria4: "The password must contain numbers (0-9)",
            criteria5:
                'Must contain at-least 1 special character, e.g. @ " & $ € ! @ # ? etc.',
            title: "Password Criteria",
        },
        person: "Person",
        persons: "Persons",
        pleaseSelect: "Please Select",
        pleaseWait: "Please wait...",
        primary: "Primary",
        resume: "Resume",
        next: "Next",
        continue: "Continue",
        save: "Save",
        reset: "Reset",
        saveChanges: "Save Changes",
        search: "Search",
        secondary: "Secondary",
        selectAll: "Select All",
        send: "Send",
        startDate: {
            invalid: "Start Date must be less than end-date.",
            missing: "Start Date is required.",
            name: "Start Date",
        },
        status: {
            Active: "Active",
            InActive: "In-Active",
            Expired: "Expired",
            OnAdvance: "On-Advance",
            notActive: "In-Active", // for templates
        },
        sourceTypes: {
            LwDashboard: "LW Dashboard",
            Excel: "Excel Import",
            External: "External API",
        },
        submit: "Submit",
        success: "Success",
        successMessage: "Task successful",
        time: "Time",
        today: "Today",
        total: "Total",
        trashSelected: "Trash Selected",
        yesTrashIt: "Yes, move to trash",
        trashSelectedConfirmation:
            "Are you sure you want to move selected items to trash?",
        update: "Update",
        userType: {
            Client: "Client",
            Employee: "Employee",
            Parent: "Parent",
            Supplier: "Supplier",
        },
        vacations: "Vacations",
        validations: {
            invalidEmail: "The email is not in the correct format",
            invalidPassword:
                "Password should be at-least 8 characters long and must contain lowercase letters, uppercase letters, digits and special character.",
        },
        value: "Value",
        number: "Number",
        valueRequired: "Value is required",
        warning: "Warning",
        week: "Week",
        year: "Year",
        yes: "Yes",
    },
};

export default CommonEnTranslations;
