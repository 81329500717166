import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDatePicker, AppInputField } from "components/FormFields";
import { appTableFilterSelectStyles } from "components/Table/AppTable";
import { ISO8601_DATE_FORMAT, SEARCH_DELAY_TIME } from "globals/constants";
import { defaultTo } from "lodash";
import { Optional } from "models/general";
import { TodoFilterRequest } from "models/todo";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export interface TodoManagementTableFilterRow {
    areas: SimpleOption[];
    employees: SimpleOption[];
    filter: TodoFilterRequest;
    onFilterChange: (filter: TodoFilterRequest) => void;
}

export const TodoManagementTableFilterRow: React.FC<
    TodoManagementTableFilterRow
> = ({ areas, employees, filter, onFilterChange }) => {
    const { t } = useTranslation();

    const selectOption = {
        label: t("common.all"),
        value: undefined,
    } as SimpleOption;

    const employeesList: SimpleOption[] = employees
        ? [selectOption, ...employees]
        : [];

    const workingAreas: SimpleOption[] = areas ? [selectOption, ...areas] : [];

    const [description, setDescription] = useState(filter.Description);
    const [task, setTask] = useState(filter.Task);

    useEffect(() => {
        if (task != null) {
            const delayDebounceFn = setTimeout(() => {
                onFilterChange({
                    ...filter,
                    Task: task,
                });
            }, SEARCH_DELAY_TIME);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [task]);

    useEffect(() => {
        if (description != null) {
            const delayDebounceFn = setTimeout(() => {
                onFilterChange({
                    ...filter,
                    Description: description,
                });
            }, SEARCH_DELAY_TIME);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [description]);

    return (
        <tr className="filters-row">
            <td
                style={{
                    width: "120px",
                    minWidth: "120px",
                    maxWidth: "120px",
                }}
            />
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            >
                <AppInputField
                    showClearButton={true}
                    value={defaultTo(task, "")}
                    onValueChange={setTask}
                />
            </td>
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            >
                <AppInputField
                    showClearButton={true}
                    value={defaultTo(description, "")}
                    onValueChange={setDescription}
                />
            </td>
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            >
                <AppSelectOld
                    isLoading={workingAreas && workingAreas.length === 0}
                    options={workingAreas}
                    styles={appTableFilterSelectStyles}
                    value={
                        workingAreas && filter.AreaId && filter.AreaId > 0
                            ? workingAreas.find(
                                  (x) => x.value === filter.AreaId
                              )
                            : workingAreas[0]
                    }
                    menuPortalTarget={document.body}
                    getOptionLabel={(opt: SimpleOption) => opt.label}
                    getOptionValue={(opt: SimpleOption) => opt.value}
                    onChange={(opt: SimpleOption) => {
                        if (filter.AreaId !== opt.value) {
                            onFilterChange({
                                ...filter,
                                AreaId: opt.value,
                            });
                        }
                    }}
                />
            </td>
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            >
                <AppSelectOld
                    menuPortalTarget={document.body}
                    isLoading={employeesList && employeesList.length === 0}
                    options={employeesList}
                    styles={appTableFilterSelectStyles}
                    value={
                        employeesList &&
                        filter.AssigneeId &&
                        filter.AssigneeId > 0
                            ? employeesList.find(
                                  (x) => x.value === filter.AssigneeId
                              )
                            : employeesList[0]
                    }
                    getOptionLabel={(opt: SimpleOption) => opt.label}
                    getOptionValue={(opt: SimpleOption) => opt.value}
                    onChange={(opt: SimpleOption) => {
                        if (filter.AssigneeId !== opt.value) {
                            onFilterChange({
                                ...filter,
                                AssigneeId: opt.value,
                            });
                        }
                    }}
                />
            </td>
            <td
                style={{
                    width: "210px",
                    minWidth: "210px",
                    maxWidth: "210px",
                }}
            >
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    appendToBody={true}
                    value={
                        filter.CreationDate != null
                            ? moment(filter.CreationDate, ISO8601_DATE_FORMAT)
                            : undefined
                    }
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onFilterChange({
                                ...filter,
                                CreationDate: val
                                    ? val.format(ISO8601_DATE_FORMAT)
                                    : undefined,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            >
                <AppDatePicker
                    showClearButton={true}
                    showCalenderIcon={false}
                    showLabel={false}
                    className={"m-0"}
                    appendToBody={true}
                    value={
                        filter.DueDate != null
                            ? moment(filter.DueDate, ISO8601_DATE_FORMAT)
                            : undefined
                    }
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onFilterChange({
                                ...filter,
                                DueDate: val
                                    ? val.format(ISO8601_DATE_FORMAT)
                                    : undefined,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
            <td
                style={{
                    width: "100px",
                    minWidth: "100px",
                    maxWidth: "100px",
                }}
            />
            <td
                style={{
                    width: "200px",
                    minWidth: "200px",
                    maxWidth: "200px",
                }}
            />
            <td
                style={{
                    width: "150px",
                    minWidth: "150px",
                    maxWidth: "150px",
                }}
            />
            <td
                style={{
                    width: "50px",
                    minWidth: "50px",
                    maxWidth: "50px",
                }}
            />
            <td
                style={{
                    width: "50px",
                    minWidth: "50px",
                    maxWidth: "50px",
                }}
            />
        </tr>
    );
};

export default TodoManagementTableFilterRow;
