export const AccountingEnTranslations = {
    accounting: {
        creditors: {
            title: "Creditors",
            createTitle: "Add New Creditor",
            deleteError: "Unable to delete creditor. Try again later...",
            name: {
                name: "Name",
            },
            fax: {
                name: "Fax",
            },
            contactName: {
                name: "Contact Name",
            },
            telephone: {
                name: "Telephone",
            },
            webAddress: {
                name: "Web Address",
            },
            email: {
                name: "Email",
            },
            tabs: {
                basicData: "Basic Data",
                bankData: "Bank Data",
            },
            columns: {
                address: "Address",
                name: "Name",
                email: "Email",
                telephone: "Telephone",
            },
        },
    },
};

export default AccountingEnTranslations;
