import { AppTableTotal } from "commonPartials";
import { AppContentHeader } from "components";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";
import {
    getFixedCssWidths,
    getMillisecondsForMinutes,
} from "globals/helpers/generalHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil, sum } from "lodash-es";
import {
    BusinessType,
    checkPermissionInMap,
    LW_ElderlyCare_BasicData,
    LW_Industry_BasicData,
    LW_Kindergarten_BasicData,
    LW_School_BasicData,
    PermissionAccessTypes,
} from "models";
import { SortOrder, TableSort } from "models/general";
import {
    filterLwClientsList,
    getLwClientsFilterDefaultValue,
    LwClientFilters,
    LwClientHQ,
    LwClientSortColumn,
    parseLwClientHQItemResponse,
    sortLwClientsList,
} from "models/lwClient";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getLillywaitServiceKey, LillywaitService } from "services/lillywait";
import styles from "./LwClientsData.module.scss";
import { LwClientRow, LwClientsFilterRow } from "./partials";

export interface LwClientsDataProps {
    lwcType: BusinessType;
    isSupportTable?: boolean;
    heightToAdjust?: number;
    mediumViewAdjustment?: number;
    mobileViewAdjustment?: number;
}
const COLUMNS_COUNT = 11;

export const LwClientsData: React.FC<LwClientsDataProps> = ({
    lwcType,
    isSupportTable = false,
    heightToAdjust = 210,
    mediumViewAdjustment = -15,
    mobileViewAdjustment = -15,
}) => {
    const { linkProvider } = useRouting();
    const lwService = new LillywaitService(linkProvider.lillywait);
    const { getPermissionMap } = useCheckPermission();
    const { t } = useTranslation();
    const [data, setData] = useState<LwClientHQ[] | undefined>(undefined);
    const [filters, setFilters] = useState<LwClientFilters>(
        getLwClientsFilterDefaultValue()
    );
    const [sort, setSort] = useState<TableSort<LwClientSortColumn>>({
        SortColumn: LwClientSortColumn.DisplayId,
        SortOrder: SortOrder.DESC,
    });
    const navigate = useNavigate();

    const {
        isLoading,
        isFetching,
        data: response,
    } = useQuery(
        getLillywaitServiceKey("getLwClientsList", {
            type: lwcType,
        }),
        async () => await lwService.getLwClientsList(true, lwcType),
        {
            select: (res) =>
                res.Data.map((x) => parseLwClientHQItemResponse(x)),
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );
    const filterData = useCallback(
        (value?: LwClientFilters, data?: LwClientHQ[]) => {
            if (response) {
                const filteredData = filterLwClientsList(
                    defaultTo(data, response),
                    defaultTo(value, filters)
                );
                return filteredData.length > 0
                    ? sortLwClientsList(filteredData, sort)
                    : [];
            }
            return [];
        },
        [response, sort]
    );

    useEffect(() => {
        if (!isLoading && response) {
            setData(filterData(undefined, response));
        }
    }, [isLoading, isFetching, response]);

    useEffect(() => {
        if (filters) {
            setData(filterData(filters));
        }
    }, [filters]);
    useEffect(() => {
        if (sort) {
            if (data) {
                setData(sortLwClientsList(data, sort));
            }
        }
    }, [sort]);

    const totalsInfo = useMemo(() => {
        return {
            clientTotal: defaultTo(
                sum(
                    data?.map(
                        (x) => defaultTo(x.HqInfo.ActiveClients, 0) // branches count already included in HQ total
                        // sum([
                        //     defaultTo(x.HqInfo.ActiveClients, 0),
                        //     ...defaultTo(
                        //         x.Branches?.map((y) => y.ActiveClients),
                        //         []
                        //     ),
                        // ])
                    )
                ),
                0
            ),
            employeeTotal: defaultTo(
                sum(
                    data?.map(
                        (x) => defaultTo(x.HqInfo.ActiveEmployees, 0)
                        // sum([
                        //     defaultTo(x.HqInfo.ActiveEmployees, 0),
                        //     ...defaultTo(
                        //         x.Branches?.map((y) => y.ActiveEmployees),
                        //         []
                        //     ),
                        // ])
                    )
                ),
                0
            ),
        };
    }, [data]);
    const permMap = getPermissionMap(
        lwcType
            ? [
                  lwcType === BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_BasicData
                      : lwcType === BusinessType.SCHOOL
                      ? LW_School_BasicData
                      : lwcType === BusinessType.ELDERLY_CARE
                      ? LW_ElderlyCare_BasicData
                      : LW_Industry_BasicData,
              ]
            : [
                  LW_Kindergarten_BasicData,
                  LW_School_BasicData,
                  LW_Industry_BasicData,
                  LW_ElderlyCare_BasicData,
              ]
    );
    const columnsCount = COLUMNS_COUNT;

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={`${t(
                    `business.businessType.${
                        lwcType === BusinessType.KINDERGARTEN
                            ? "kindergartens"
                            : lwcType === BusinessType.SCHOOL
                            ? "schools"
                            : lwcType === BusinessType.ELDERLY_CARE
                            ? "elderlycares"
                            : "industries"
                    }`
                )}`}
                classNameForIcon="pe-7s-diamond"
            />
            <div className={styles.content}>
                <AppTable
                    containerClass={`${styles.tableOuter}`}
                    hover={!isSupportTable}
                    stickyHeader={true}
                    heightToAdjust={heightToAdjust}
                    mediumViewAdjustment={mediumViewAdjustment}
                    mobileViewAdjustment={mobileViewAdjustment}
                >
                    <thead>
                        <tr>
                            <th
                                // for toggle icon
                                style={getFixedCssWidths(50)}
                            />
                            <AppTableSortColumnIcons
                                width={80}
                                text={t("lwClient.table.id")}
                                sortColumn={LwClientSortColumn.DisplayId}
                                onClick={(key, order) =>
                                    setSort({
                                        SortColumn: key,
                                        SortOrder: order,
                                    })
                                }
                                sortOrder={
                                    sort.SortColumn ==
                                    LwClientSortColumn.DisplayId
                                        ? sort.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                width={250}
                                text={t("lwClient.table.companyName")}
                                sortColumn={LwClientSortColumn.CompanyName}
                                onClick={(key, order) =>
                                    setSort({
                                        SortColumn: key,
                                        SortOrder: order,
                                    })
                                }
                                sortOrder={
                                    sort.SortColumn ==
                                    LwClientSortColumn.CompanyName
                                        ? sort.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                width={250}
                                text={t("lwClient.table.facilityName")}
                                sortColumn={LwClientSortColumn.FacilityName}
                                onClick={(key, order) =>
                                    setSort({
                                        SortColumn: key,
                                        SortOrder: order,
                                    })
                                }
                                sortOrder={
                                    sort.SortColumn ==
                                    LwClientSortColumn.FacilityName
                                        ? sort.SortOrder
                                        : null
                                }
                            />
                            <th style={getFixedCssWidths(200)}>
                                {t("lwClient.table.state")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("lwClient.table.city")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("lwClient.table.activeClients")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("lwClient.table.activeEmployees")}
                            </th>
                            <th style={getFixedCssWidths(120, false)}>
                                {t("lwClient.table.hq")}
                            </th>
                            <AppTableSortColumnIcons
                                width={170}
                                text={t("lwClient.table.status")}
                                sortColumn={LwClientSortColumn.Status}
                                onClick={(key, order) =>
                                    setSort({
                                        SortColumn: key,
                                        SortOrder: order,
                                    })
                                }
                                sortOrder={
                                    sort.SortColumn == LwClientSortColumn.Status
                                        ? sort.SortOrder
                                        : null
                                }
                            />
                            {isSupportTable ? (
                                <th style={getFixedCssWidths(170)} /> // for support button
                            ) : (
                                <th style={getFixedCssWidths(70)}></th> // for delete
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <LwClientsFilterRow
                            value={filters}
                            onChange={setFilters}
                            clientTotal={totalsInfo.clientTotal}
                            employeeTotal={totalsInfo.employeeTotal}
                            isForSupportTable={isSupportTable}
                        />
                        <tr className="dummy-row">
                            <td colSpan={columnsCount} />
                        </tr>
                        {isLoading || !data ? (
                            <AppTableSkeleton
                                columns={columnsCount}
                                rows={10}
                            />
                        ) : data.length > 0 ? (
                            data.map((x, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <LwClientRow
                                            colCount={columnsCount}
                                            key={x.EncodedId}
                                            value={x}
                                            canDelete={permMap.DELETE}
                                            onDelete={(
                                                hqId: number,
                                                businessId?: number
                                            ) => {
                                                if (isNil(businessId)) {
                                                    // deletedHq (not possible yet)
                                                    setData((old) =>
                                                        old?.filter(
                                                            (x) => x.Id !== hqId
                                                        )
                                                    );
                                                } else {
                                                    setData((old) =>
                                                        old?.map((x) => {
                                                            if (x.Id === hqId) {
                                                                // filter deleted business
                                                                x.Branches =
                                                                    x.Branches?.filter(
                                                                        (y) =>
                                                                            y.Id !==
                                                                            businessId
                                                                    );
                                                            }
                                                            return x;
                                                        })
                                                    );
                                                }
                                            }}
                                            isSupportTable={isSupportTable}
                                            onClick={
                                                checkPermissionInMap(permMap, [
                                                    PermissionAccessTypes.SHOW,
                                                ])
                                                    ? (lwcId: string) => {
                                                          const base =
                                                              linkProvider.lillywait.clients()
                                                                  .screens;

                                                          navigate(
                                                              (lwcType ===
                                                              BusinessType.KINDERGARTEN
                                                                  ? base.kindergartens
                                                                  : lwcType ===
                                                                    BusinessType.SCHOOL
                                                                  ? base.schools
                                                                  : lwcType ===
                                                                    BusinessType.ELDERLY_CARE
                                                                  ? base.elderlyCares
                                                                  : base.industries
                                                              )
                                                                  .withId(lwcId)
                                                                  .edit()
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan={columnsCount}
                                    style={{ textAlign: "center" }}
                                >
                                    {t("common.noDataFound")}
                                </td>
                            </tr>
                        )}

                        <tr className="dummy-row">
                            <td colSpan={columnsCount} />
                        </tr>
                    </tbody>
                </AppTable>
                <AppTableTotal
                    text={`${t("lwClient.total").toString()}:`}
                    count={
                        data
                            ? data.reduce((count: number, val: LwClientHQ) => {
                                  return (
                                      count +
                                      1 +
                                      (val.Branches ? val.Branches?.length : 0)
                                  );
                              }, 0 as number)
                            : 0
                    }
                />
            </div>
        </div>
    );
};

export default LwClientsData;
