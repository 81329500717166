import { ZipCodeAndCitySelector } from "commonPartials/location";
import { AppInputField } from "components/FormFields";
import { defaults, defaultTo } from "lodash-es";
import {
    Address,
    validateAddressFields,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import { ZipCodeItem } from "models/location";
import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface AddressFieldsProps {
    value: Address;
    readonly: boolean;
    colSize?: number;
    onChange: (value: Address) => void;
    validityState?: ValidityState;
}

export const AddressFields: React.FC<AddressFieldsProps> = ({
    value,
    readonly,
    colSize = 4,
    onChange,
    validityState = null,
}) => {
    const { t } = useTranslation();

    value = defaults(value, { CountryCode: "DE" } as Address);
    const validityStateManager = useMemo(() => {
        const validationState = defaultTo(
            validityState,
            validateAddressFields(value)
        );
        return new ValidityStateManager(validationState);
    }, [value, validityState]);

    return (
        <>
            <Col md={colSize * 2}>
                <ZipCodeAndCitySelector
                    onChange={(obj: ZipCodeItem) => {
                        onChange({
                            ...value,
                            City: obj.City as string,
                            State: obj.State,
                            PostCode: obj.Text,
                        });
                    }}
                    country={value.CountryCode}
                    postCode={value.PostCode ? value.PostCode.toString() : null}
                    city={value.City}
                    readOnly={readonly}
                />
            </Col>
            <Col md={colSize}>
                <AppInputField
                    label={t("common.address.state.name")}
                    value={value.State}
                    readOnly={true}
                    onValueChange={(val) => {}}
                    showEmptyError={true}
                    error={validityStateManager.getFirstErrorInfo("State")}
                />
            </Col>
            <Col md={colSize}>
                <AppInputField
                    label={t("common.address.streetAddress.name")}
                    value={value.StreetAddress}
                    readOnly={readonly}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            StreetAddress: val as string,
                        })
                    }
                    showEmptyError={true}
                    error={validityStateManager.getFirstErrorInfo(
                        "StreetAddress"
                    )}
                />
            </Col>
            <Col md={colSize}>
                <AppInputField
                    label={t("common.address.streetNumber.name")}
                    value={value.StreetNumber}
                    readOnly={readonly}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            StreetNumber: val,
                        })
                    }
                    showEmptyError={true}
                    error={validityStateManager.getFirstErrorInfo(
                        "StreetNumber"
                    )}
                />
            </Col>
        </>
    );
};

export default AddressFields;
