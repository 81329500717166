import React from "react";
import { AuthLoader, CompleteRegistrationHeaderAndFields } from "./partials";

export const CompleteRegistration: React.FC = () => {
    return (
        <AuthLoader
            renderContentWithLoader={(setLoading, loading) => (
                <CompleteRegistrationHeaderAndFields
                    setLoading={setLoading}
                    loading={loading}
                />
            )}
        />
    );
};

export default CompleteRegistration;
