import {
    ClientImportColumns,
    ClientRegistrationImportColumns,
    EmployeeImportColumns,
    ImportDataType,
} from "./enum";
import { ColumnNameMapping, ColumnSpec } from "./model";

export const RequiredClientCols: ClientImportColumns[] = [
    ClientImportColumns.BIRTHDAY,
    ClientImportColumns.FIRST_NAME,
    ClientImportColumns.LAST_NAME,
    ClientImportColumns.END_OF_CONTRACT,
    ClientImportColumns.CARE_START,
];
export const RequiredClientRegistrationCols: ClientRegistrationImportColumns[] =
    [
        ClientRegistrationImportColumns.FIRST_NAME,
        ClientRegistrationImportColumns.LAST_NAME,
        ClientRegistrationImportColumns.BIRTHDAY,
        ClientRegistrationImportColumns.CARE_START,
        ClientRegistrationImportColumns.GENDER,
    ];

export const RequiredEmployeeCols: EmployeeImportColumns[] = [
    EmployeeImportColumns.FIRST_NAME,
    EmployeeImportColumns.LAST_NAME,
];

export const TargetColumnNames: {
    [key in ImportDataType]: ColumnNameMapping;
} = {
    [ImportDataType.EMPLOYEE]: {
        ...(Object.values(EmployeeImportColumns).reduce((result, curr) => {
            return {
                ...result,
                [curr]: {
                    name: `employeeColumns.${curr}`,
                    required: RequiredEmployeeCols.includes(curr),
                } as ColumnSpec,
            };
        }, {}) as ColumnNameMapping),
    },
    [ImportDataType.CLIENT]: {
        ...(Object.values(ClientImportColumns).reduce((result, curr) => {
            return {
                ...result,
                [curr]: {
                    name: `clientColumns.${curr}`,
                    required: RequiredClientCols.includes(curr),
                } as ColumnSpec,
            };
        }, {}) as ColumnNameMapping),
    },
    [ImportDataType.CLIENT_REGISTRATIONS]: {
        ...(Object.values(ClientRegistrationImportColumns).reduce(
            (result, curr) => {
                return {
                    ...result,
                    [curr]: {
                        name: `clientRegistrationColumns.${curr}`,
                        required: RequiredClientRegistrationCols.includes(curr),
                    } as ColumnSpec,
                };
            },
            {}
        ) as ColumnNameMapping),
    },
};
