import classNames from "classnames";
import useBusinessWorkSchedule from "hooks/generalApiCalls/useBusinessWorkSchedule";
import { isNil } from "lodash-es";
import {
    weekDaysStringToNumDict,
    WorkingDays,
    WorkingDaysNumber,
} from "models";
import React from "react";
import { useTranslation } from "react-i18next";
import { getUniqueList } from "globals/helpers/generalHelper";
import { ErrorMessage } from "components";
import { AppCheckbox } from "components/FormFields";
import { ErrorInfo } from "models/general";
import styles from "./WeekDayCheckboxes.module.scss";

export interface WeekDayCheckboxesProps {
    className?: string;
    error?: ErrorInfo;
    value: WorkingDaysNumber[];
    onChange: (value: WorkingDaysNumber[]) => void;
    useOnlyWorkingDays?: boolean;
    readonly?: boolean;
    showBorder?: boolean;
    businessId?: number;
}
export const WeekDayCheckboxes: React.FC<WeekDayCheckboxesProps> = ({
    useOnlyWorkingDays = true,
    error,
    onChange,
    readonly = false,
    className,
    showBorder = false,
    value,
    businessId,
}) => {
    const { t } = useTranslation();

    const { data: workingDays, loading: businessWorkScheduleLoading } =
        useBusinessWorkSchedule(businessId);

    const onDaysChange = (workingDay: WorkingDaysNumber, check: boolean) => {
        const recurrenceDays = check
            ? [...value, workingDay]
            : value.filter((x) => x != workingDay);

        onChange(getUniqueList(recurrenceDays));
    };

    return (
        <div
            className={classNames(
                className,
                { [styles.border]: showBorder },
                styles.daysCheckBoxDiv
            )}
        >
            <div className="d-flex">
                {!businessWorkScheduleLoading &&
                    workingDays &&
                    Object.keys(workingDays)
                        .filter((x) => x != "WeekDays")
                        .map((key: string) => {
                            const day = key as WorkingDays;
                            const isActive = !useOnlyWorkingDays
                                ? true
                                : workingDays[day].IsWorkingDay;
                            return (
                                <AppCheckbox
                                    key={day}
                                    className={styles.daysCheckBox}
                                    label={t(`common.days.shortName.${key}`)}
                                    onChange={(check) =>
                                        onDaysChange(
                                            weekDaysStringToNumDict[day],
                                            check.target.checked
                                        )
                                    }
                                    disabled={!isActive || readonly}
                                    checked={
                                        value.find((x) => {
                                            return (
                                                x ==
                                                weekDaysStringToNumDict[day]
                                            );
                                        }) != null
                                    }
                                />
                            );
                        })}
            </div>
            {/* {!isNil(error) && value.length == 0 && ( */}
            <ErrorMessage showEmpty={true} errorInfo={error} />
            {/* )} */}
        </div>
    );
};
