import { AppOverlayLoader } from "components/Loaders";
import React, { ReactNode, useRef, useState } from "react";
import { useLocation } from "react-router";
import styles from "./AuthLoader.module.scss";

export interface WithAuthLoader {
    setLoading: (value: boolean) => void;
    loading?: boolean;
}
export interface AuthLoaderProps {
    renderContentWithLoader: (
        setValue: (value: boolean) => void,
        loading: boolean
    ) => ReactNode;
}
export const AuthLoader: React.FC<AuthLoaderProps> = ({
    renderContentWithLoader,
}) => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const isHeightSmall = window.innerHeight < 1000;
    const isLoginRegister =
        (path.includes("login") || path.includes("register")) &&
        !path.includes("confirm");

    return (
        <div className={`${styles.outer} ${loading ? styles.hideScroll : ""}`}>
            {loading && <AppOverlayLoader className={styles.loader} />}
            <div
                ref={rootRef}
                className={`${styles.mainContent} ${
                    !isLoginRegister && isHeightSmall
                        ? styles.justifyCenter
                        : ""
                } ${
                    loading
                        ? `${styles.hideScroll} ${
                              rootRef.current != null &&
                              rootRef.current.offsetHeight > window.innerHeight
                                  ? styles.justifyInitial
                                  : ""
                          }`
                        : ""
                }`}
            >
                {renderContentWithLoader(setLoading, loading)}
            </div>
        </div>
    );
};

export default AuthLoader;
