import classNames from "classnames";
import { AppButton } from "components/Buttons";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./FeatureNotAvailableOverlay.module.scss";

interface FeatureNotAvailableOverlayProps {
    fullHeight?: boolean;
    blur?: boolean;
    className?: string;
}

export const FeatureNotAvailableOverlay: React.FC<
    FeatureNotAvailableOverlayProps
> = ({ blur = false, fullHeight = true, className = "" }) => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userAccessInfo } = useCheckPermission();
    const { isSupportViewEnabled } = userAccessInfo();
    return isSupportViewEnabled ? (
        <></>
    ) : (
        <div
            className={classNames(
                className,
                styles.overlay,
                { [styles.fullHeight]: fullHeight },
                {
                    [styles.blur]: blur,
                }
            )}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className={styles.content}>
                <p>{t("business.modulePlanOverlay.upgradePlanExp")}</p>
                <AppButton
                    variant="success"
                    onClick={() =>
                        navigate(
                            linkProvider.business.screens.admin.selectModules()
                        )
                    }
                >
                    {t("business.modulePlanOverlay.upgradeMyPlan")}
                </AppButton>
            </div>
        </div>
    );
};

export default FeatureNotAvailableOverlay;
