import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppPopover } from "components";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import {
    AccessPointAction,
    ActiveEmployeeData,
    getAccessPointActionString,
} from "models/timeManagement";
import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./TimeManagementAvailableStaffPopper.module.scss";
interface TimeManagementAvailableStaffPopperProps {
    activeEmployees: ActiveEmployeeData[];
    className?: string;
    showInOutAndCurrentAction?: boolean;
    triggerElement?: React.ReactNode;
}
export const TimeManagementAvailableStaffPopper: React.FC<
    TimeManagementAvailableStaffPopperProps
> = ({ activeEmployees, triggerElement, showInOutAndCurrentAction = true }) => {
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    const totalIn = activeEmployees.filter(
        (x) => x.CurrentAction == AccessPointAction.IN
    ).length;
    const stopPropagationHandler = (e: any) => {
        e.stopPropagation();
    };
    return (
        <div onClick={stopPropagationHandler} className={styles.root}>
            <AppPopover
                triggerElem={
                    triggerElement ? (
                        triggerElement
                    ) : (
                        <a className={styles.playButton}>
                            <FontAwesomeIcon icon="play" />
                        </a>
                    )
                }
                placement={"right-start"}
            >
                <>
                    {activeEmployees && activeEmployees.length > 0 ? (
                        <>
                            <div className={styles.headerDiv}>
                                {showInOutAndCurrentAction && (
                                    <span className={styles.box}>
                                        {getAccessPointActionString(
                                            AccessPointAction.IN
                                        )}
                                        :
                                        <span className={styles.in}>
                                            {totalIn}
                                        </span>
                                    </span>
                                )}
                                <span
                                    className={`${styles.box} ${
                                        !showInOutAndCurrentAction
                                            ? "mx-auto"
                                            : ""
                                    }`}
                                >
                                    {t(
                                        "timeManagement.availableStaff.availableStaff"
                                    )}
                                </span>
                                {showInOutAndCurrentAction && (
                                    <span className={styles.box}>
                                        {getAccessPointActionString(
                                            AccessPointAction.OUT
                                        )}
                                        :
                                        <span className={styles.out}>
                                            {activeEmployees.length - totalIn}
                                        </span>
                                    </span>
                                )}
                            </div>
                            <Table className={styles.staffTable}>
                                <thead>
                                    <tr>
                                        <th
                                            style={getFixedCssWidths(220)}
                                            className={styles.header}
                                        >
                                            {t(
                                                "timeManagement.availableStaff.name"
                                            )}
                                        </th>
                                        <th
                                            className={styles.header}
                                            style={getFixedCssWidths(130)}
                                        >
                                            {t(
                                                "timeManagement.availableStaff.employeeKey"
                                            )}
                                        </th>
                                        {showInOutAndCurrentAction && (
                                            <th
                                                className={styles.header}
                                                style={getFixedCssWidths(110)}
                                            >
                                                {t(
                                                    "timeManagement.availableStaff.currentAction"
                                                )}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeEmployees.map((x) => (
                                        <tr key={x.Id}>
                                            <td>{x.Name}</td>
                                            <td style={{ textAlign: "center" }}>
                                                {formatNumber(
                                                    x.EmpKey,
                                                    FOUR_PRECISION_NUMERIC
                                                )}
                                            </td>
                                            {showInOutAndCurrentAction && (
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {getAccessPointActionString(
                                                        x.CurrentAction
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <span style={{ fontWeight: 300 }}>
                            {t("common.noDataFound")}
                        </span>
                    )}
                </>
            </AppPopover>
        </div>
    );
};

export default TimeManagementAvailableStaffPopper;
