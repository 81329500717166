export const ChatEnTranslations = {
    chat: {
        tabs: {
            chats: "Single Chat",
            groups: "Group Chat",
        },
        errors: {
            noLongerMemberOfTheGroup: "No longer member of this group",
            messageTooLong:
                "Message length is too long. You can't send messages longer than 30,000 characters.",
            attachmentTooBig: "Unable to send file because it is too big.",
        },
        emptyMessage: "No chat found...",
        noChatUserFound: "No new user available for chat...",
        membersInfo: "Member's Info",
        alertMessages: {
            areYouSureYouWantToDeleteThisChat:
                "Are you sure you want to delete this chat?",
            areYouSureYouWantToDeleteThisGroup:
                "Are you sure you want to delete this group?",
        },
        blockMessage:
            "Sorry, this user has blocked you and you can no longer chat with",
        button: {
            add: "Add",
            createChat: "Create Chat",
            createGroup: "Create Group",
            remove: "Remove",
        },
        chats: "Chats",
        groupName: {
            missing: "Group name is required",
            name: "Group Name...",
        },
        groups: "Groups",
        isTyping: "is typing...",
        menu: {
            addToGroup: "Add to group",
            clearChat: "Clear chat",
            deleteChat: "Delete chat",
            deleteGroup: "Delete group",
            removeFromGroup: "Remove from group",
        },
        messageCopied: "Message Copied",
        send: "Send",
        showLatest: "Show latest",
        title: {
            title: "Chat",
            addMembers: "Add Members",
            newChat: "New Chat",
            newGroup: "New Group",
            removeMembers: "Remove Members",
        },
        typeYourMessage: "Type your message..",
        you: "You",
    },
};
export default ChatEnTranslations;
