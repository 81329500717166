import { AppColoredMultiSelect } from "components/AppSelect";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    defaultRoleValue,
    parseRoleResponse,
    Role,
    RoleCreateEditRequest,
    RoleResponse,
    validateRole,
} from "models";
import {
    AppPlatforms,
    AppResponse,
    CustomColorItemOption,
    Optional,
    ValidityStateManager,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business/BusinessGeneralService";
import {
    getRoleManagementServiceKey,
    RoleManagementService,
} from "services/business/RoleManagementService";

interface RoleCreateEditDialogProps {
    value: Optional<Role>;
    modalOpen: boolean;
    onClose: () => void;
    loading: boolean;
    activeHqId: number;
    title: string;
    isHq: boolean;
    readonly?: boolean;
    onChange: (value: Role) => void;
    deviceType: AppPlatforms;
}
export const RoleCreateEditDialog: React.FC<RoleCreateEditDialogProps> = ({
    value,
    loading,
    modalOpen,
    onClose,
    title,
    onChange,
    isHq,
    readonly = false,
    deviceType,
}) => {
    const isEdit = value && value.Id;
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] = useState<Role>(
        isEdit ? value : defaultRoleValue()
    );
    const { sessionBusiness } = useSessionBusiness();
    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const service = new RoleManagementService(
        linkProvider.business.api.roleManagement
    );
    const {
        isFetching: fetchingHqBranches,
        data: hqBranches,
        refetch: refetchHqBranches,
    } = useQuery(
        getBusinessGeneralServiceKey("getHqBranchesWithAccess"),
        async () =>
            await businessGeneralService.getHqBranchesWithAccess(
                defaultTo(sessionBusiness?.HqId, 0)
            ),
        { enabled: false }
    );
    const {
        isLoading: loadingUpdate,
        data: updateResponse,
        mutate: updateRole,
        error: updateError,
    } = useMutation(
        getRoleManagementServiceKey("update"),
        async (req: RoleCreateEditRequest) => await service.update(req)
    );
    const {
        isLoading: loadingCreate,
        data: createResponse,
        mutate: createRole,
        error: createError,
    } = useMutation(
        getRoleManagementServiceKey("create"),
        async (req: RoleCreateEditRequest) => await service.create(req)
    );
    useEffect(() => {
        if (isHq && sessionBusiness) {
            refetchHqBranches();
        }
    }, []);

    const validityStateManager = useMemo(() => {
        const validationState = validateRole(state);
        return new ValidityStateManager(validationState);
    }, [state]);

    const hqBranchesOptions = useMemo(() => {
        return hqBranches && hqBranches.Data
            ? hqBranches.Data.map((x) => {
                  return {
                      label: x.Text,
                      value: x.Value,
                      color: undefined,
                  } as CustomColorItemOption;
              })
            : [];
    }, [hqBranches]);

    const onCreateOrEdit = (value: Role) => {
        const val = {
            ...value,
            BranchIds: value.BranchIds,
            ForDevice: deviceType.toString(),
            DbName: undefined,
        } as RoleCreateEditRequest;

        if (!value.Id) {
            createRole(val);
        } else {
            updateRole({
                ...val,
                Id: value.Id,
            });
        }
    };

    const processMutationResponse = (
        response?: AppResponse<RoleResponse>,
        error?: any,
        isCreate: boolean = false
    ) => {
        if (response) {
            if (response.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${response.Data.Name}" ${t(
                        `common.actions.${
                            isCreate
                                ? "createdSuccessfully"
                                : "updatedSuccessfully"
                        }`
                    )}`,
                    "success"
                );
                onChange(parseRoleResponse(response.Data));
            } else if (response.Code != null) {
                if (response.Errors && response.Errors.length > 0) {
                    showSweetAlertToast(
                        t("common.error.error"),
                        response.Errors[0].Message,
                        "error"
                    );
                }
            } else {
                showUnexpectedErrorToast();
            }
        } else if (error) {
            showUnexpectedErrorToast();
        }
    };
    useEffect(() => {
        if (!loadingCreate) {
            processMutationResponse(createResponse, createError, true);
        }
    }, [createResponse, loadingCreate]);

    useEffect(() => {
        if (!loadingUpdate) {
            processMutationResponse(updateResponse, updateError);
        }
    }, [updateResponse, loadingUpdate]);

    return (
        <AppDialog
            title={title}
            onClose={() => onClose()}
            modalOpen={modalOpen}
        >
            <Row>
                <Col xs={12} className="mt-2">
                    <AppInputField
                        label={t("roleManagement.role.name.name")}
                        placeholder={t("roleManagement.role.name.placeholder")}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                        value={state.Name}
                        onValueChange={(v) =>
                            setState({
                                ...state,
                                Name: v as string,
                            })
                        }
                        disabled={readonly}
                    />
                </Col>
                {isHq && (
                    <Col xs={12}>
                        <Form.Group controlId="hqBranchesId">
                            <Form.Label>
                                {t("roleManagement.role.hqBranches")}
                            </Form.Label>
                            <AppColoredMultiSelect
                                data={hqBranchesOptions}
                                isReadonly={readonly}
                                loading={fetchingHqBranches}
                                onChange={(value) =>
                                    setState({
                                        ...state,
                                        BranchIds: value.map(
                                            (x) => x.value
                                        ) as number[],
                                    })
                                }
                                value={hqBranchesOptions.filter(
                                    (x) =>
                                        state.BranchIds &&
                                        state.BranchIds.filter(
                                            (y) => y == (x.value as number)
                                        ).length > 0
                                )}
                                useOldUi={true}
                            />
                        </Form.Group>
                    </Col>
                )}
                <Col xs={12} className={`mt-2`}>
                    <AppInputField
                        label={t("roleManagement.role.description.name")}
                        showEmptyError={true}
                        value={state.Description}
                        placeholder={t(
                            "roleManagement.role.description.placeholder"
                        ).toString()}
                        as="textarea"
                        type="textarea"
                        disabled={readonly}
                        style={{
                            height: "150px",
                            resize: "none",
                            fontSize: "0.9rem",
                        }}
                        onValueChange={(v) =>
                            setState((o) => ({
                                ...o,
                                Description: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={12}>
                    <AppDialogFooter
                        onDialogClose={onClose}
                        onClickSaveButton={() => onCreateOrEdit(state)}
                        disableSaveButton={
                            !validityStateManager.isStateValid() ||
                            loading ||
                            readonly
                        }
                    />
                </Col>
            </Row>
        </AppDialog>
    );
};

export default RoleCreateEditDialog;
