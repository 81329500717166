import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { defaultTo } from "lodash-es";
import { ShiftCriteriaType } from "models/businessShifts/enum";
import { StringCriteriaTypes } from "models/businessShifts/helper";
import {
    getInitializedValidityState,
    ValidityStateManager,
    ValidityState,
} from "models/general";
import { BusinessShift, BusinessShiftCriteria } from "./model";

export function validateBusinessShift(
    shift: BusinessShift,
    state = getInitializedValidityState([], [])
) {
    const totalMinRequired = defaultTo(shift.Criterias, []).reduce(
        (total, val) => total + val.MinEmployees,
        0
    );
    const valueValidationRules: ValidationRules<keyof BusinessShift> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("business.businessShift.name.missing"),
            },
        ],
        StartTime: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("business.businessShift.startTime.missing"),
            },
        ],
        MinEmployees: [
            {
                rule: Validations.REQUIRED,
            },
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.MIN,
                message:
                    shift.MinEmployees > 0
                        ? shift.MinEmployees >= totalMinRequired
                            ? i18next.t(
                                  "business.businessShift.minEmployees.invalid"
                              )
                            : i18next.t(
                                  "business.businessShift.invalidTotalMinValue"
                              )
                        : i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: Math.max(totalMinRequired, 1),
                },
            },
            ...(shift.MaxEmployees && shift.MinEmployees > 0
                ? [
                      {
                          rule: Validations.MAX,
                          message: i18next.t(
                              "business.businessShift.minEmployees.invalid"
                          ),
                          options: {
                              value: shift.MaxEmployees,
                          },
                      },
                  ]
                : []),
        ],
        MaxEmployees: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.MIN,
                message:
                    shift.MaxEmployees && shift.MaxEmployees > 0
                        ? i18next.t(
                              "business.businessShift.maxEmployees.invalid"
                          )
                        : i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: Math.max(shift.MinEmployees, 1),
                },
            },
        ],
        EndTime: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("business.businessShift.endTime.missing"),
            },
            // {
            //     rule: Validations.GREATER_DATE,
            //     message: i18next.t("business.businessShift.endTime.invalid"),
            //     options: {
            //         value: shift.StartTime,
            //     },
            // },
        ],
        AreaIds: [
            {
                rule: Validations.ARRAY_REQUIRED,
                message: i18next.t(
                    "business.businessShift.workingArea.missing"
                ),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        shift,
        state
    );

    let manager = new ValidityStateManager(state);
    defaultTo(shift.Criterias, []).forEach((criteria) => {
        manager = manager.replaceFieldState(criteria.uuid, {
            errors: [],
            identifier: criteria.uuid,
            children: validateShiftCriteriaRecord(
                criteria,
                manager.getFieldState(criteria.uuid)?.children
            ),
        });
    });

    return manager.state;
}

const validateShiftCriteriaRecord = (
    criteria: BusinessShiftCriteria,
    state = getInitializedValidityState([], [])
): ValidityState => {
    const rules: ValidationRules<keyof BusinessShiftCriteria> = {
        ...(StringCriteriaTypes.includes(criteria.ShiftCriteriaType)
            ? {
                  StringOptions: [
                      {
                          rule: Validations.ARRAY_REQUIRED,
                          message: i18next.t(
                              "business.businessShift.criteria.option.missing"
                          ),
                      },
                  ],
              }
            : {
                  Options: [
                      {
                          rule: Validations.ARRAY_REQUIRED,
                          message: i18next.t(
                              "business.businessShift.criteria.option.missing"
                          ),
                      },
                  ],
              }),
        MinEmployees: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.MIN,
                message:
                    criteria.MinEmployees && criteria.MinEmployees >= 0
                        ? i18next.t(
                              "business.businessShift.minEmployees.invalid"
                          )
                        : i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: Math.min(defaultTo(criteria.MaxEmployees, 0), 0),
                },
            },
            ...(criteria.MaxEmployees && criteria.MinEmployees > 0
                ? [
                      {
                          rule: Validations.MAX,
                          message: i18next.t(
                              "business.businessShift.minEmployees.invalid"
                          ),
                          options: {
                              value: criteria.MaxEmployees,
                          },
                      },
                  ]
                : []),
        ],
        ...(criteria.MaxEmployees
            ? {
                  MaxEmployees: [
                      {
                          rule: Validations.NUMBER,
                      },
                      {
                          rule: Validations.MIN,
                          message:
                              criteria.MaxEmployees > 0
                                  ? i18next.t(
                                        "business.businessShift.maxEmployees.invalid"
                                    )
                                  : i18next.t(
                                        "common.errorMessages.positiveNum"
                                    ),
                          options: {
                              value: Math.max(criteria.MinEmployees, 1),
                          },
                      },
                  ],
              }
            : {}),
    };

    return getInitializedValidityStateFromRules(rules, criteria, state);
};
