import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import { AppResponse } from "models/general/response";
import {
    BusinessToDoFilterResponseWithStringDate,
    TodoFilterRequest,
    TodoRequest,
    TodoResponse,
} from "models/todo";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface ITodoService {
    getTodoList(
        request: TodoFilterRequest
    ): Promise<AppResponse<BusinessToDoFilterResponseWithStringDate>>;

    getTodo(todoId: string): Promise<AppResponse<TodoResponse>>;

    createUpdate(request: TodoRequest): Promise<AppResponse<TodoResponse>>;

    updateStatus(
        todoId: string,
        updateStatus: string
    ): Promise<AppResponse<TodoResponse>>;

    deleteFile(id: number, todoId: string): Promise<AppResponse<number>>;

    delete(todoId: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.todo;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getTodoServiceKey(name: keyof ITodoService, data?: any) {
    if (!data) {
        return name;
    }
    return `todo_${name}_${JSON.stringify(data)}`;
}

export class TodoService extends BaseService implements ITodoService {
    public todoRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.todoRoutes = apiLinkProvider;
    }

    getTodoList(
        request: TodoFilterRequest
    ): Promise<AppResponse<BusinessToDoFilterResponseWithStringDate>> {
        return this.doServerXHR<BusinessToDoFilterResponseWithStringDate>({
            url: this.todoRoutes.list({ request }),
            method: "get",
        });
    }

    getTodo(todoId: string): Promise<AppResponse<TodoResponse>> {
        return this.doServerXHR<TodoResponse>({
            url: this.todoRoutes.withId(todoId).get(),
            method: "get",
        });
    }

    constructFormData(todoModel: TodoRequest) {
        const { files, ...rest } = todoModel;

        const fD = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                fD.append("files", files[i]);
            }
        }

        const dueDateTime = todoModel.DueDate.clone()
            .startOf("D")
            .add(todoModel.DueDateTime.get("hours"), "hours")
            .add(todoModel.DueDateTime.get("minutes"), "minutes");

        const json = JSON.stringify({
            ...rest,
            DueDateTime: dueDateTime.unix(),
            Reminder: todoModel.SendRemainder ? rest.Reminder : null,
            RepeatSchedule:
                todoModel.RepeatSchedule != null && todoModel.TaskRecreated
                    ? {
                          ...rest.RepeatSchedule,
                          StartDate:
                              todoModel.RepeatSchedule.StartDate.format(
                                  ISO8601_DATE_FORMAT
                              ),
                          EndDate:
                              todoModel.RepeatSchedule.EndDate != null
                                  ? todoModel.RepeatSchedule.EndDate.format(
                                        ISO8601_DATE_FORMAT
                                    )
                                  : null,
                          Time: todoModel.RepeatSchedule.Time.format(
                              ISO8601_TIME_FORMAT
                          ),
                      }
                    : null,
        });
        fD.append("Data", json);
        return fD;
    }

    createUpdate(todoModel: TodoRequest): Promise<AppResponse<TodoResponse>> {
        const fD = this.constructFormData(todoModel);
        return this.doServerXHR<TodoResponse>({
            url: this.todoRoutes.createUpdate(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }

    updateStatus(
        todoId: string,
        updateStatus: string
    ): Promise<AppResponse<TodoResponse>> {
        return this.doServerXHR<TodoResponse>({
            url: this.todoRoutes.updateStatus(),
            method: "post",
            data: { Id: todoId, Status: updateStatus },
        });
    }

    deleteFile(id: number, todoId: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.todoRoutes.withId(todoId).deleteFile(id),
            method: "post",
        });
    }

    delete(todoId: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.todoRoutes.withId(todoId).delete(),
            method: "post",
        });
    }
}

export default TodoService;
