import AppIconWithName from "components/AppIconWithName";
import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { formatNumericValue, isNumber } from "globals/helpers/generalHelper";
import {
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { DocumentTemplateManagerItem } from "models/documentTemplates";
import { UpdateDisplayIdResponseAndRequest } from "models/general";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { LocationDocumentTemplateService } from "services/lillywait";
import styles from "./DocumentTemplateRow.module.scss";

export interface DocumentTemplateRowProps {
    value: DocumentTemplateManagerItem;
    updateDisplayId: (req: UpdateDisplayIdResponseAndRequest) => void;
    removeItem: (Id: number) => void;
    hasDeletePermission: boolean;
    checkDisplayIdAlreadyExist: (displayId: number) => boolean;
    filterList: () => void;
    checkDisplayIdIsInRange: (displayId: number) => boolean;
}

export const DocumentTemplateRow: React.FC<DocumentTemplateRowProps> = ({
    value,
    updateDisplayId,
    removeItem,
    hasDeletePermission,
    filterList,
    checkDisplayIdAlreadyExist,
    checkDisplayIdIsInRange,
}) => {
    const { t } = useTranslation();
    const { getDateTimeFormatForLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const documentTemplateService = new LocationDocumentTemplateService(
        linkProvider.lillywait.locationSettings().api.withId().documentTemplates
    );

    const [isDisplayIdEditable, setDisplayIdEditable] = useState(false);
    const [inputValue, setInputValue] = useState(value.DisplayId.toString());

    const {
        isLoading: loading,
        data: response,
        mutate: refetch,
    } = useMutation(
        async (request: UpdateDisplayIdResponseAndRequest) =>
            await documentTemplateService.updateDisplayId(request)
    );

    const {
        isLoading: deleteLoading,
        data: deleteResponse,
        mutate: deleteTemplate,
    } = useMutation(
        async (templateId: string) =>
            await documentTemplateService.delete(templateId)
    );

    useEffect(() => {
        if (!loading && response && response.Data) {
            if (!checkDisplayIdIsInRange(response.Data.DisplayId)) {
                removeItem(value.Id);
            } else {
                updateDisplayId(response.Data);
                filterList();
            }
            showSweetAlertToast(
                t("common.success"),
                t("common.actions.displayIdUpdatedSuccessfully"),
                "success"
            );
        } else if (
            !loading &&
            response?.Errors &&
            response.Errors.length > 0 &&
            response.Code
        ) {
            setInputValue(value.DisplayId.toString());
            showSweetAlertToast(
                t("common.error.error"),
                response.Errors && response.Errors[0].Message,
                "error"
            );
        }
        setDisplayIdEditable(false);
    }, [response, loading]);

    const onDisplayIdBlur = () => {
        if (checkDisplayIdAlreadyExist(parseInt(inputValue))) {
            showSweetAlertToast(
                t("common.error.error"),
                t("templateDocumentManager.displayIdAlreadyInUse"),
                "error"
            );
            setDisplayIdEditable(false);
            setInputValue(value.DisplayId.toString());
            return;
        }
        if (inputValue && isNumber(inputValue) && parseInt(inputValue) > 0) {
            refetch({
                DisplayId: parseInt(inputValue),
                Id: value.Id,
            });
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t("templateDocumentManager.displayIdInvalid"),
                "error"
            );
            setDisplayIdEditable(false);
            setInputValue(value.DisplayId.toString());
        }
    };

    useEffect(() => {
        if (!deleteLoading && deleteResponse) {
            showSweetAlertInfo(
                t("common.success"),
                t(`documentTemplate.deletedSuccess`),
                "success"
            );

            removeItem(value.Id);
        }
    }, [deleteResponse, deleteLoading]);

    const editUrl = linkProvider.lillywait
        .locationSettings()
        .screens.withId()
        .documentTemplates.edit(value.EncodedId);
    const onRowClickHandler = () => {
        navigate(editUrl);
    };
    return (
        <tr>
            <td
                onClick={() => {
                    setDisplayIdEditable(true);
                }}
                className={styles.displayId}
            >
                {isDisplayIdEditable ? (
                    <Form.Control
                        className={styles.displayFiled}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        autoFocus
                        onBlur={() => onDisplayIdBlur()}
                    />
                ) : (
                    <span>{formatNumericValue(value.DisplayId, "000")}</span>
                )}
            </td>
            <td onClick={onRowClickHandler}>
                <AppIconWithName
                    text={value.Name}
                    iconPath={
                        value.IsEditable
                            ? ImageAssets.documentManager.doc
                            : ImageAssets.documentManager.pdf
                    }
                    iconClass={styles.icon}
                />
            </td>
            <td onClick={onRowClickHandler}>
                {value.CreatedAt.format(getDateTimeFormatForLocale())}
            </td>
            <td onClick={onRowClickHandler}>
                <span className="ellipsis-text">
                    {value.Types.map(
                        (x, index) =>
                            t(
                                `templateDocumentManager.documentType.${x.label.toLocaleLowerCase()}`
                            ) + (index + 1 != value.Types.length && ", ")
                    )}
                </span>
            </td>
            <td onClick={onRowClickHandler}>
                <AppIconButton
                    buttonType={"Edit"}
                    readonly={deleteLoading}
                    onClick={() => {}}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() => deleteTemplate(value.Id.toString())}
                    readonly={!hasDeletePermission || deleteLoading}
                    getConfirmation={true}
                    name={value.Name}
                />
            </td>
        </tr>
    );
};

export default DocumentTemplateRow;
