import { AppButton } from "components/Buttons";
import { SelectFileDialog } from "components/File";
import React from "react";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface ImportDataDialogProps extends ModalProps {
    file?: File;
    modalOpen: boolean;
    sampleFileUrl: string;
    onClose: (file?: File) => void;
}

export const ImportDataDialog: React.FC<ImportDataDialogProps> = ({
    sampleFileUrl: samplefileurl,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <SelectFileDialog
            header={
                <div className="d-flex justify-content-between flex-wrap">
                    {t("fileDialog.fileLabel").toString()}
                    <AppButton
                        padding={"low"}
                        variant={"success"}
                        style={{ borderRadius: "0.25rem" }}
                        onClick={() => (window.location.href = samplefileurl)}
                    >
                        {t("fileDialog.downloadSampleTemplate")}
                    </AppButton>
                </div>
            }
            {...rest}
        />
    );
};

export default ImportDataDialog;
