import classNames from "classnames";
import { AppDeleteButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import IFrameContent from "components/IFrameContent";
import { showSweetAlertInfo } from "globals/helpers/sweetAlertHelper";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import {
    checkPermissionInMap,
    LocationSettings_DocumentTemplates,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getDocumentTemplateServiceKey } from "services/business";
import { LocationDocumentTemplateService } from "services/lillywait/locationSettings";
import styles from "./LocationDocumentTemplateCreateAndEditIframe.module.scss";

export const LocationDocumentTemplateCreateAndEditIframe: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();
    const { urlForLocationSettings } = useIFrameRouting();
    const showErrorPage = useShowErrorPage();

    const { id: templateId, locationId } = useParams();

    const permMap = useMemo(() => {
        return getPermissionMap(LocationSettings_DocumentTemplates);
    }, [getPermissionMap]);

    const templateDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const documentTemplateService = new LocationDocumentTemplateService(
        linkProvider.lillywait.locationSettings().api.withId().documentTemplates
    );

    const routeBase = linkProvider.lillywait
        .locationSettings()
        .screens.withId().documentTemplates;
    const redirect = () => {
        navigate(routeBase.list());
    };

    const {
        isLoading: getTemplateLoading,
        refetch: refetchTemplate,
        data: getTemplateRes,
    } = useQuery(
        getDocumentTemplateServiceKey("getTemplateById", {
            templateId: templateId,
        }),
        async () => {
            if (templateId) {
                return await documentTemplateService.getTemplateById(
                    templateId
                );
            }
        }
    );

    useEffect(() => {
        if (!getTemplateLoading && getTemplateRes?.Errors) {
            showErrorPage(404);
        }
    }, [getTemplateRes, getTemplateLoading]);

    const { isLoading: deleteTemplateLoading, mutate: deleteTemplate } =
        useMutation(
            async (templateId: string) =>
                await documentTemplateService.delete(templateId),
            {
                onSuccess: () => {
                    showSweetAlertInfo(
                        t("common.success"),
                        t(`documentTemplate.deletedSuccess`),
                        "success"
                    );
                    redirect();
                },
            }
        );

    const iFrame = useMemo(() => {
        const pattern = templateId
            ? `${locationId}/Templates/Edit/${templateId}`
            : `${locationId}/Templates/Create`;

        return (
            <IFrameContent
                contentUrl={urlForLocationSettings(pattern)}
                onUpdate={() => refetchTemplate()}
                onRedirect={(encodedId) => {
                    if (encodedId) {
                        navigate(routeBase.edit(encodedId));
                    } else {
                        redirect();
                    }
                }}
            />
        );
    }, [templateId]);
    return (
        <AppContainer
            showHeader={true}
            showTitleSkeleton={true}
            titleLoading={getTemplateLoading}
            title={
                templateId
                    ? defaultTo(getTemplateRes?.Data?.Name, "")
                    : t("documentTemplate.locationDocuments")
            }
            heightToAdjust={240}
            mediumViewAdjustment={25}
            mobileViewAdjustment={25}
            onBack={() => redirect()}
            headerRightContent={
                templateId ? (
                    <AppDeleteButton
                        readonly={
                            deleteTemplateLoading || !templateDeletePermission
                        }
                        getConfirmation={true}
                        className={classNames(styles.documentBtn, "mr-3")}
                        onClick={() =>
                            templateId ? deleteTemplate(templateId) : {}
                        }
                    />
                ) : (
                    <></>
                )
            }
        >
            <div className={styles.iframeRoot}>{iFrame}</div>
        </AppContainer>
    );
};

export default LocationDocumentTemplateCreateAndEditIframe;
