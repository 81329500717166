import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import {
    AppDatePicker,
    AppInputField,
    AppNumberInput,
} from "components/FormFields";
import { appTableFilterSelectStyles } from "components/Table";
import { FORMAT_INTEGER } from "globals/constants";
import { defaultTo, isNil } from "lodash-es";
import {
    EmployeeBenefitRequestParameters,
    EmployeeBenefitStatus,
} from "models/employeeAdditionalBenefit";
import { Optional } from "models/general";
import { Moment } from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";

export interface EmployeeAdditionalBenefitFilterRowProps {
    value: EmployeeBenefitRequestParameters;
    onChange: (value: EmployeeBenefitRequestParameters) => void;
}

export const EmployeeAdditionalBenefitFilterRow: React.FC<
    EmployeeAdditionalBenefitFilterRowProps
> = ({ value, onChange }) => {
    const { t } = useTranslation();
    const selectOption = {
        label: t("common.pleaseSelect"),
    } as SimpleOption;
    const statusOptions = [
        selectOption,
        ...Object.values(EmployeeBenefitStatus)
            .filter((x: any) => !isNaN(x))
            .map((x) => {
                return {
                    label: t(
                        `common.status.${EmployeeBenefitStatus[Number(x)]}`
                    ),
                    value: x,
                } as SimpleOption;
            }),
    ];

    return (
        <tr className="filters-row">
            <td></td>
            <td>
                <AppNumberInput
                    format={FORMAT_INTEGER}
                    className="mb-0"
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    showPlaceHolder={false}
                    value={value.DisplayId}
                    onChange={(e) => {
                        onChange({
                            ...value,
                            DisplayId: e != null ? e : undefined,
                        });
                    }}
                />
            </td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.EmployeeName, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            EmployeeName: val,
                        })
                    }
                />
            </td>
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    className={"m-0"}
                    appendToBody={true}
                    value={value.StartDate}
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onChange({
                                ...value,
                                StartDate: val,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
            <td>
                <AppDatePicker
                    showClearButton={true}
                    showLabel={false}
                    showCalenderIcon={false}
                    appendToBody={true}
                    className={"m-0"}
                    value={value.EndDate}
                    onChange={(val: Optional<Moment>) => {
                        if (val !== null) {
                            onChange({
                                ...value,
                                EndDate: val,
                            });
                        }
                    }}
                    useDefault={false}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.Status)}
                    showCustomClearIndicator={true}
                    options={statusOptions}
                    styles={appTableFilterSelectStyles}
                    value={
                        value.Status != undefined
                            ? statusOptions.find(
                                  (x) => x.value === value.Status
                              )
                            : statusOptions[0]
                    }
                    menuPortalTarget={document.body}
                    getOptionLabel={(opt: SimpleOption) => opt.label}
                    getOptionValue={(opt: SimpleOption) => opt.value}
                    onChange={(opt: SimpleOption) => {
                        onChange({
                            ...value,
                            Status: opt
                                ? (opt.value as EmployeeBenefitStatus)
                                : undefined,
                        });
                    }}
                />
            </td>
            <td>
                <AppInputField
                    className="mb-0"
                    showClearButton={true}
                    value={defaultTo(value.Reason, "")}
                    onValueChange={(val) =>
                        onChange({
                            ...value,
                            Reason: val,
                        })
                    }
                />
            </td>
            <td>
                <AppNumberInput
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    isForCurrency={true}
                    showPlaceHolder={false}
                    value={value.Amount}
                    onChange={(e) => {
                        onChange({
                            ...value,
                            Amount: e != null ? e : undefined,
                        });
                    }}
                />
            </td>
            <td></td>
        </tr>
    );
};

export default EmployeeAdditionalBenefitFilterRow;
