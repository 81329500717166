import { GeneralUserProfileUpdate } from "models/permissionManagement/permissionConstants";
import { RoutesMap } from "routing";
import { EditProfile } from "screens/authUser";
import { authUserRoutes } from "./routes";

const relativeRoutes = authUserRoutes(false).screens;
const fullRoutes = authUserRoutes().screens;

export const authUserRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.editProfile()]: {
        component: EditProfile,
        fullPattern: fullRoutes.editProfile(),
        pattern: relativeRoutes.editProfile(),
        permKeys: [GeneralUserProfileUpdate],
    },
};
