export const DATEPICKER_TIME_ENGLISH_FORMAT = "hh:mm a";
export const DATEPICKER_TIME_GERMAN_FORMAT = "HH:mm";

export const DATEPICKER_MONTH_FORMAT = "MMMM";
export const DATEPICKER_SHORT_MONTH_FORMAT = "MMM";
export const DATEPICKER_MONTH_YEAR_FORMAT = "MMMM yyyy";
export const DATEPICKER_DATE_MONTH_FORMAT = "dd MMMM";
export const DATEPICKER_SHORT_MONTH_YEAR_FORMAT = "MMM yyyy";
export const DATEPICKER_YEAR_FORMAT = "yyyy";

export const DATEPICKER_ENGLISH_DATE_FORMAT = "M/d/yyyy"; // For ENGLISH
export const DATEPICKER_GERMAN_DATE_FORMAT = "dd.MM.yyyy";
export const DATEPICKER_BOSNIAN_DATE_FORMAT = "d. M. yyyy.";
export const DATEPICKER_ITALIAN_DATE_FORMAT = "dd/MM/yyyy";
export const DATEPICKER_POLISH_DATE_FORMAT = "dd.MM.yyyy";
export const DATEPICKER_CROATIAN_DATE_FORMAT = "d.M.yyyy.";
export const DATEPICKER_CZECH_DATE_FORMAT = "dd.MM.yyyy";
export const DATEPICKER_HUNGARIAN_DATE_FORMAT = "yyyy. MM. dd.";
export const DATEPICKER_SLOVAK_DATE_FORMAT = "d. M. yyyy";
export const DATEPICKER_SLOVENIAN_DATE_FORMAT = "d. MM. yyyy";
export const DATEPICKER_FRENCH_DATE_FORMAT = "dd/MM/yyyy";
export const DATEPICKER_SPANISH_DATE_FORMAT = "dd/MM/yyyy";
export const DATEPICKER_DUTCH_DATE_FORMAT = "d-M-yyyy";
export const DATEPICKER_ESTONIAN_DATE_FORMAT = "dd.MM.yyyy";
export const DATEPICKER_FINNISH_DATE_FORMAT = "d.M.yyyy";
export const DATEPICKER_GREEK_DATE_FORMAT = "d/M/yyyy";
