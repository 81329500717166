import { AppButton, AppButtonProps } from "components/Buttons";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AppContainerFooter.module.scss";
export interface AppContainerFooterProps {
    primaryButtonProps?: AppButtonProps;
    primaryButtonText?: string;
}
export const AppContainerFooter: React.FC<AppContainerFooterProps> = ({
    primaryButtonText,
    primaryButtonProps,
}) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex justify-content-end align-items-center">
            <AppButton className={styles.saveBtn} {...primaryButtonProps}>
                <span>{defaultTo(primaryButtonText, t("common.save"))}</span>
            </AppButton>
        </div>
    );
};

export default AppContainerFooter;
