import { AppOverlayLoader } from "components/Loaders";
import {
    cookieAvailable,
    getCookie,
    removeCookie,
} from "globals/helpers/cookieHelper";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { AuthenticatedPageContextProvider } from "hoc/providers/AuthenticatedPageContextProvider";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import {
    renewSessionAndUpdateCookie,
    useInitUserInfo,
    useInitUserSession,
    useOnLogoutUser,
} from "hooks/general/authHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { defaultTo } from "lodash-es";
import { getBusinessTypePathString, UserCookie } from "models";
import { AxiosCommonHeaders, BaseObject, CookiesKey } from "models/general";
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useQuery } from "react-query";
import { Navigate, useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import AuthService, { getAuthServiceKey } from "services/AuthService";
import { initAxios } from "services/helpers";

const MAX_RETRY_COUNT = 2;
// will filter and handle redirection for routes that is only allowed if user is authenticated (logged-in)
export const AuthenticatedRoute: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}) => {
    const { isAuthenticated } = useSessionUser();
    const { sessionBusiness, encodedId, isContractExpired } =
        useSessionBusiness();
    const { checkPermission } = useCheckPermission();
    const [searchParams] = useSearchParams();
    const { sessionBusinessType } = useParams();
    const location = useLocation();
    const [state, setState] = useState<{
        loading: boolean;
        retryCount: number;
    }>({ loading: true, retryCount: 0 });

    const {
        setSessionTimeOut,
        isContextLoaded,
        setIsContextLoaded,
        sessionTimeOut,
    } = useAppContext();
    const { initUserInfo } = useInitUserInfo();
    const { renewSession } = useInitUserSession();
    const onUserLogout = useOnLogoutUser();
    const { linkProvider } = useRouting();
    const authService = new AuthService(linkProvider.noAuth.api);
    const { isLoading, data, refetch, isError, error } = useQuery(
        getAuthServiceKey("me"),
        async () => await authService.me(),
        { enabled: false }
    );

    useEffect(() => {
        const cookieJson = getCookie(CookiesKey.USER_COOKIE);
        if (cookieJson && !isAuthenticated && !isContextLoaded && !data) {
            // cookie is available but data is not in context
            const cookie = JSON.parse(cookieJson) as UserCookie;
            if (cookie.AccessToken) {
                initAxios(AxiosCommonHeaders.AUTHORIZATION, cookie.AccessToken);
                refetch(); // refetch me-data
            }
        } else {
            setState({ ...state, loading: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renewTokenAndRefetch = useCallback(async () => {
        //checking isRememberMe Checked or not
        const userCookieJson = getCookie(CookiesKey.USER_COOKIE);
        const onEnd = (sessionTimeout?: boolean) => {
            onUserLogout(sessionTimeout);
            setState({ ...state, loading: false });
        };
        if (userCookieJson) {
            const userClaimCookie = JSON.parse(userCookieJson) as UserCookie;
            if (!userClaimCookie.RememberMe) {
                //if remember me is false
                //set session time out true in context for navigate to session time routes
                onEnd(true);
            } else {
                if (state.retryCount <= MAX_RETRY_COUNT) {
                    //if rememberMe is true
                    //refetch new token
                    setState({ ...state, retryCount: state.retryCount + 1 });
                    await renewSessionAndUpdateCookie(
                        userClaimCookie,
                        authService.renewSession,
                        renewSession
                    );

                    //fetching user info again
                    refetch();
                } else {
                    setIsContextLoaded(true);
                    onEnd();
                }
            }
        }
    }, [setSessionTimeOut, state, refetch, setIsContextLoaded]);

    useEffect(() => {
        const processResponse = async () => {
            if (!isLoading && data && !isAuthenticated && !isContextLoaded) {
                //setting user context
                initUserInfo(data.Data, true);
                setState((old) => ({ ...old, loading: false }));
            } else if (isError && error) {
                await renewTokenAndRefetch();
            }
        };
        processResponse();
    }, [
        isLoading,
        data,
        isAuthenticated,
        isContextLoaded,
        isError,
        error,
        initUserInfo,
        renewTokenAndRefetch,
    ]);

    if (state.loading) {
        return <AppOverlayLoader />;
    }
    if (sessionTimeOut || (!isAuthenticated && isContextLoaded)) {
        //user is not authenticated and going to authorized path
        //move to the login or sessionTimeout
        const screens = linkProvider.noAuth.screens;

        let redirectTo = screens.login;

        if (sessionTimeOut) {
            redirectTo = screens.sessionTimeOut;
            setSessionTimeOut(false);
        }
        const isLogout = cookieAvailable(CookiesKey.USER_LOGOUT);
        if (isLogout) {
            removeCookie(CookiesKey.USER_LOGOUT);
        }

        return (
            <Navigate
                to={redirectTo(
                    !isLogout
                        ? {
                              redirectUrl: encodeURIComponent(
                                  location.pathname
                              ),
                          }
                        : undefined
                )}
                state={{ from: location }}
                replace
            />
        );
    }

    const sessionBusinessTypeMismatch =
        location.pathname.includes(":sessionBusinessId") ||
        (sessionBusiness &&
            !!sessionBusinessType &&
            getBusinessTypePathString(
                sessionBusiness.Type
            ).toLocaleLowerCase() !== sessionBusinessType);

    // route ending with baseRoute are invalid and can be only entered by hand
    const baseRoute = `/${sessionBusinessType}/${encodedId}`;
    const isInvalidRoutes = [baseRoute, `${baseRoute}/`].includes(
        location.pathname
    );

    const sessionBusinessIdMismatch =
        sessionBusinessType && // URL is for session business but id in url is not of session-business
        encodedId &&
        !location.pathname.includes(encodedId);

    const contractExpiredAllowedPages = ["contract-expired", "edit-profile"];
    if (
        (isContractExpired &&
            contractExpiredAllowedPages.every(
                (p) => !location.pathname.includes(p)
            )) ||
        (!cookieAvailable(CookiesKey.IGNORE_WRONG_URL_PARAM) &&
            (sessionBusinessTypeMismatch ||
                sessionBusinessIdMismatch ||
                isInvalidRoutes))
    ) {
        // redirect to home() if user enters any wrong business type or businessId in URL by hand
        const defaultUrl = linkProvider.screens.home(
            checkPermission,
            isContractExpired
        );
        // to add real data in URL
        let redirectUrl = defaultTo(
            searchParams.get("redirectUrl"), // if url has param then redirect to that
            defaultUrl
        );

        if (redirectUrl !== defaultUrl) {
            redirectUrl = defaultUrl;
        }

        return <Navigate to={redirectUrl} replace />;
    }
    return (
        <AuthenticatedPageContextProvider>
            {children}
        </AuthenticatedPageContextProvider>
    );
};

export default AuthenticatedRoute;
