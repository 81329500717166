import IFrameContent from "components/IFrameContent";
import {
    Business_Employee_AccessControl,
    Business_Employee_App,
    Business_Employee_BankData,
    Business_Employee_BasicData,
    Business_Employee_ContractData,
    Business_Employee_Diseases,
    Business_Employee_Documents,
    Business_Employee_Groups,
    Business_Employee_Shifts,
    Business_Employee_SalaryStatement,
    Business_Employee_Absences,
    Business_Employee_Vacations,
    PermissionAccessTypes,
} from "models";
import {
    Feature_Business_Employees,
    Feature_Business_Employee_AccessControl,
    Feature_Business_Employee_AddUpdate,
    Feature_Business_Employee_App,
    Feature_Business_Employee_BankData,
    Feature_Business_Employee_BankData_AddUpdate,
    Feature_Business_Employee_ContractData,
    Feature_Business_Employee_Diseases,
    Feature_Business_Employee_Diseases_AddUpdate,
    Feature_Business_Employee_Documents,
    Feature_Business_Employee_Group,
    Feature_Business_Employee_Shift,
    Feature_Business_Employee_Shift_AddUpdate,
    Feature_Business_Employee_SalaryStatements,
    Feature_Business_Employee_Absent,
    Feature_Business_Employee_Absent_AddUpdate,
    Feature_Business_Employee_Vacations,
    Feature_Business_Employee_Vacations_AddUpdate,
    Feature_Business_Suppliers,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import {
    BusinessEmployeeCreate,
    BusinessEmployeeEditLayout,
    BusinessEmployeesContextProvider,
    BusinessEmployeesList,
    EmployeeShiftCreate,
    EmployeeShiftEdit,
    EmployeeShiftList,
    EmployeeContractCreateEdit,
    EmployeeContractList,
    EmployeeVacationList,
    EmployeeVacationCreate,
    EmployeeVacationEdit,
} from "screens/business/employees";
import {
    EmployeeBankDataCreate,
    EmployeeBankDataEdit,
    EmployeeBankDataList,
} from "screens/business/employees/tabs/bankData";
import Dummy from "screens/Dummy";
import businessEmployeeRoutes from "./routes";

const relativeRoutes = businessEmployeeRoutes(false).screens;
const fullRoutes = businessEmployeeRoutes().screens;

export const businessEmployeeRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.create()]: {
        pattern: relativeRoutes.create(),
        fullPattern: fullRoutes.create(),
        component: BusinessEmployeeCreate,
        permKeys: [Business_Employee_BasicData],
        featureKeys: [Feature_Business_Employee_AddUpdate],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.list()]: {
        component: BusinessEmployeesContextProvider,
        fullPattern: fullRoutes.list(),
        pattern: relativeRoutes.list(),
        permKeys: [Business_Employee_BasicData],
        featureKeys: [Feature_Business_Employees, Feature_Business_Suppliers],
        nestedScreens: {
            [relativeRoutes.list()]: {
                index: true,
                component: BusinessEmployeesList,
                fullPattern: fullRoutes.list(),
            },
            [relativeRoutes.withId().base()]: {
                pattern: relativeRoutes.withId().base(),
                fullPattern: fullRoutes.withId().base(),
                component: BusinessEmployeeEditLayout,
                permKeys: [Business_Employee_BasicData],
                featureKeys: [Feature_Business_Employee_AddUpdate],
                nestedScreens: {
                    [relativeRoutes.withId().edit()]: {
                        component: IFrameContent,
                        index: true,
                        pattern: relativeRoutes.withId().edit(),
                        fullPattern: fullRoutes.withId().edit(),
                        permKeys: [Business_Employee_BasicData],
                        featureKeys: [Feature_Business_Employee_AddUpdate],
                        permAccessTypes: [PermissionAccessTypes.SHOW],
                    },
                    [relativeRoutes.withId().app()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().app(),
                        pattern: relativeRoutes.withId().app(),
                        permKeys: [Business_Employee_App],
                        featureKeys: [Feature_Business_Employee_App],
                    },
                    [relativeRoutes.withId().careGroups()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().careGroups(),
                        pattern: relativeRoutes.withId().careGroups(),
                        permKeys: [Business_Employee_Groups],
                        featureKeys: [Feature_Business_Employee_Group],
                    },
                    [relativeRoutes.withId().accessControl()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().accessControl(),
                        pattern: relativeRoutes.withId().accessControl(),
                        permKeys: [Business_Employee_AccessControl],
                        featureKeys: [Feature_Business_Employee_AccessControl],
                    },
                    [relativeRoutes.withId().diseases.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().diseases.list(),
                        pattern: relativeRoutes.withId().diseases.list(),
                        permKeys: [Business_Employee_Diseases],
                        featureKeys: [Feature_Business_Employee_Diseases],
                    },
                    [relativeRoutes.withId().diseases.create()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.withId().diseases.create(),
                        pattern: relativeRoutes.withId().diseases.create(),
                        permKeys: [Business_Employee_Diseases],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Business_Employee_Diseases_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().diseases.edit()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.withId().diseases.edit(),
                        pattern: relativeRoutes.withId().diseases.edit(),
                        permKeys: [Business_Employee_Diseases],
                        featureKeys: [
                            Feature_Business_Employee_Diseases_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().absences.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().absences.list(),
                        pattern: relativeRoutes.withId().absences.list(),
                        permKeys: [Business_Employee_Absences],
                        featureKeys: [Feature_Business_Employee_Absent],
                    },
                    [relativeRoutes.withId().absences.create()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.withId().absences.create(),
                        pattern: relativeRoutes.withId().absences.create(),
                        permKeys: [Business_Employee_Absences],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Business_Employee_Absent_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().absences.edit()]: {
                        component: Dummy,
                        fullPattern: fullRoutes.withId().absences.edit(),
                        pattern: relativeRoutes.withId().absences.edit(),
                        permKeys: [Business_Employee_Absences],
                        featureKeys: [
                            Feature_Business_Employee_Absent_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().shifts.list()]: {
                        component: EmployeeShiftList,
                        fullPattern: fullRoutes.withId().shifts.list(),
                        pattern: relativeRoutes.withId().shifts.list(),
                        permKeys: [Business_Employee_Shifts],
                        featureKeys: [Feature_Business_Employee_Shift],
                    },
                    [relativeRoutes.withId().shifts.create()]: {
                        component: EmployeeShiftCreate,
                        fullPattern: fullRoutes.withId().shifts.create(),
                        pattern: relativeRoutes.withId().shifts.create(),
                        permKeys: [Business_Employee_Shifts],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Business_Employee_Shift_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().shifts.edit()]: {
                        component: EmployeeShiftEdit,
                        fullPattern: fullRoutes.withId().shifts.edit(),
                        pattern: relativeRoutes.withId().shifts.edit(),
                        permKeys: [Business_Employee_Shifts],
                        featureKeys: [
                            Feature_Business_Employee_Shift_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().vacations.list()]: {
                        component: EmployeeVacationList,
                        fullPattern: fullRoutes.withId().vacations.list(),
                        pattern: relativeRoutes.withId().vacations.list(),
                        permKeys: [Business_Employee_Vacations],
                        featureKeys: [Feature_Business_Employee_Vacations],
                    },
                    [relativeRoutes.withId().vacations.create()]: {
                        component: EmployeeVacationCreate,
                        fullPattern: fullRoutes.withId().vacations.create(),
                        pattern: relativeRoutes.withId().vacations.create(),
                        permKeys: [Business_Employee_Vacations],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Business_Employee_Vacations_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().vacations.edit()]: {
                        component: EmployeeVacationEdit,
                        fullPattern: fullRoutes.withId().vacations.edit(),
                        pattern: relativeRoutes.withId().vacations.edit(),
                        permKeys: [Business_Employee_Vacations],
                        featureKeys: [
                            Feature_Business_Employee_Vacations_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().contract.list()]: {
                        component: EmployeeContractList,
                        fullPattern: fullRoutes.withId().contract.list(),
                        pattern: relativeRoutes.withId().contract.list(),
                        permKeys: [Business_Employee_ContractData],
                        featureKeys: [Feature_Business_Employee_ContractData],
                    },
                    [relativeRoutes.withId().contract.create()]: {
                        component: EmployeeContractCreateEdit,
                        fullPattern: fullRoutes.withId().contract.create(),
                        pattern: relativeRoutes.withId().contract.create(),
                        permKeys: [Business_Employee_ContractData],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [Feature_Business_Employee_ContractData],
                    },
                    [relativeRoutes.withId().contract.edit()]: {
                        component: EmployeeContractCreateEdit,
                        fullPattern: fullRoutes.withId().contract.edit(),
                        pattern: relativeRoutes.withId().contract.edit(),
                        permKeys: [Business_Employee_ContractData],
                        featureKeys: [Feature_Business_Employee_ContractData],
                    },
                    [relativeRoutes.withId().bankData.list()]: {
                        component: EmployeeBankDataList,
                        fullPattern: fullRoutes.withId().bankData.list(),
                        pattern: relativeRoutes.withId().bankData.list(),
                        permKeys: [Business_Employee_BankData],
                        featureKeys: [Feature_Business_Employee_BankData],
                    },
                    [relativeRoutes.withId().bankData.create()]: {
                        component: EmployeeBankDataCreate,
                        fullPattern: fullRoutes.withId().bankData.create(),
                        pattern: relativeRoutes.withId().bankData.create(),
                        permKeys: [Business_Employee_BankData],
                        permAccessTypes: [PermissionAccessTypes.CREATE],
                        featureKeys: [
                            Feature_Business_Employee_BankData_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().bankData.edit()]: {
                        component: EmployeeBankDataEdit,
                        fullPattern: fullRoutes.withId().bankData.edit(),
                        pattern: relativeRoutes.withId().bankData.edit(),
                        permKeys: [Business_Employee_BankData],
                        featureKeys: [
                            Feature_Business_Employee_BankData_AddUpdate,
                        ],
                    },
                    [relativeRoutes.withId().documents.list()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().documents.list(),
                        pattern: relativeRoutes.withId().documents.list(),
                        permKeys: [Business_Employee_Documents],
                        featureKeys: [Feature_Business_Employee_Documents],
                    },
                    [relativeRoutes.withId().salaryStatement()]: {
                        component: IFrameContent,
                        fullPattern: fullRoutes.withId().salaryStatement(),
                        pattern: relativeRoutes.withId().salaryStatement(),
                        permKeys: [Business_Employee_SalaryStatement],
                        featureKeys: [
                            Feature_Business_Employee_SalaryStatements,
                        ],
                    },
                    [relativeRoutes.withId().documents.fillEditableSections()]:
                        {
                            component: Dummy,
                            fullPattern: fullRoutes
                                .withId()
                                .documents.fillEditableSections(),
                            pattern: relativeRoutes
                                .withId()
                                .documents.fillEditableSections(),
                            permKeys: [Business_Employee_Documents],
                            permAccessTypes: [PermissionAccessTypes.CREATE],
                            featureKeys: [Feature_Business_Employee_Documents],
                        },
                },
            },
        },
    },
};
