import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDeleteButton } from "components/Buttons";
import ErrorMessage from "components/ErrorMessage";
import { AppNumberInput } from "components/FormFields";
import { DragHandle } from "components/Sortable";
import { defaultTo } from "lodash-es";
import { ValidityState, ValidityStateManager } from "models/general";
import { LillywaitCompensationTemplateModulePriceField } from "models/compensationTemplate";
import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../CompensationTemplateFieldRow.module.scss";

export interface LwCompensationTemplateModulePricingFieldRowProps {
    value: LillywaitCompensationTemplateModulePriceField;
    onChange: (field: LillywaitCompensationTemplateModulePriceField) => void;
    validityState?: ValidityState;
    modulePlanOptions: SimpleOption[];
    existingModules?: string[];
    readonly?: boolean;
    loading?: boolean;
    onDelete?: (uuid: string) => void;
}

export const LwCompensationTemplateModulePricingFieldRow: React.FC<
    LwCompensationTemplateModulePricingFieldRowProps
> = ({
    value,
    onChange,
    validityState,
    readonly = false,
    loading = false,
    existingModules = [],
    onDelete,
    modulePlanOptions,
}) => {
    const { t } = useTranslation();

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(validityState);
    }, [value]);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    className={styles.rowDel}
                    readonly={value.IsBasic || readonly}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />
                <Row className={styles.valueField}>
                    <Col sm={6} xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>
                                {t("compensationTemplate.modules.name")}
                            </Form.Label>
                            <AppSelectOld
                                menuPortalTarget={document.body}
                                options={modulePlanOptions.filter(
                                    (x) =>
                                        value.LocationModuleId == x.value ||
                                        !existingModules.includes(x.value)
                                )}
                                value={
                                    value.LocationModuleId
                                        ? modulePlanOptions.filter(
                                              (x) =>
                                                  x.value ==
                                                  value.LocationModuleId
                                          )
                                        : modulePlanOptions[0]
                                }
                                isLoading={loading}
                                isDisabled={readonly || value.IsBasic}
                                onChange={(opt: SimpleOption) =>
                                    onChange({
                                        ...value,
                                        LocationModuleId: opt.value,
                                    })
                                }
                                error={validityStateManager?.getFirstErrorInfo(
                                    "LocationModuleId"
                                )}
                            />
                            <ErrorMessage
                                showEmpty={true}
                                errorInfo={validityStateManager?.getFirstErrorInfo(
                                    "LocationModuleId"
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <AppNumberInput
                            showEmptyError={true}
                            showError={true}
                            isForCurrency={true}
                            allowZero={true}
                            label={t("compensationTemplate.rate.name")}
                            value={value.FieldValue?.Value}
                            formatTheInitialValue={true}
                            disabled={readonly}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    FieldValue: {
                                        ...defaultTo(value.FieldValue, {}),
                                        Value: val,
                                    },
                                })
                            }
                            placeholder={t("compensationTemplate.rate.name")}
                            error={validityStateManager?.getFirstErrorInfo(
                                "Value"
                            )}
                        />
                    </Col>
                </Row>
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default LwCompensationTemplateModulePricingFieldRow;
