import AppSelectOld from "components/AppSelect/AppSelectOld";
import { ErrorMessage } from "components/ErrorMessage";
import { useRouting } from "hooks/general/routing";
import { ErrorInfo, SelectItem } from "models/general";
import { LocationDataRequest } from "models/location";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import GeneralService, { getGeneralServiceKey } from "services/GeneralService";

export interface LocationSelectComponentProps {
    onChange: (val: string) => void;
    value: string;
    requestParams: LocationDataRequest;
    fieldName: string;
    refetchDependency?: ("country" | "zipcodes")[];
    isReadonly?: boolean;
    onLocationLoad?: () => void;
    errorInfo?: ErrorInfo;
}

const Dropdown = React.memo(
    (props: {
        items: SelectItem[];
        onChange: (val: string) => void;
        value: string;
        isReadonly: boolean;
        loading: boolean;
    }) => {
        const { t } = useTranslation();
        const pleaseSelect = useMemo(
            () => ({
                Text: t("common.pleaseSelect"),
                Value: "",
            }),
            [t]
        );
        const propsRef = useRef(props);
        propsRef.current = props;
        const { value, items, onChange } = props;
        const currentItem = useMemo(() => {
            let item: SelectItem | undefined = undefined;
            if (items) {
                item = items.find((x) => x.Value == value);
            }
            if (item == null) {
                item = pleaseSelect;
            }
            return item;
        }, [value, items, pleaseSelect]);

        return (
            <AppSelectOld
                options={[pleaseSelect, ...items]}
                // menuShouldBlockScroll={true}
                // menuIsOpen={true}
                useReactWindow={true}
                isLoading={props.loading}
                value={currentItem}
                isDisabled={props.isReadonly}
                onChange={(opt: SelectItem) => onChange(opt.Value as string)}
                getOptionLabel={(opt: SelectItem) => opt.Text}
                getOptionValue={(opt: SelectItem) => opt.Value}
            />
        );
    }
);

export function LocationSelectComponent(props: LocationSelectComponentProps) {
    const {
        onChange,
        value,
        fieldName,
        requestParams,
        onLocationLoad = () => {},
        errorInfo,
        refetchDependency = ["country"],
        isReadonly = false,
    } = props;

    const { t } = useTranslation();
    const { linkProvider } = useRouting();

    const generalService = new GeneralService(linkProvider.api.general);

    const {
        isLoading: loading,
        data: items,
        mutate: refetchLocationData,
    } = useMutation(
        getGeneralServiceKey("getLocationData", {
            requestParams,
        }),
        async (params: LocationDataRequest) => {
            return await generalService.getLocationData(params);
        }
    );

    useEffect(() => {
        refetchLocationData(requestParams);
    }, [requestParams]);

    useEffect(() => {
        if (items && !loading) {
            onLocationLoad();
        }
    }, [items, loading]);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const onChangeDropdown = useCallback(
        (val: string) => onChangeRef.current(val),
        []
    );

    return (
        <Form.Group controlId={fieldName}>
            <Form.Label>{t(`location.${fieldName}.name`)}</Form.Label>
            <Dropdown
                items={items ? items.Data.Items : []}
                isReadonly={isReadonly || loading}
                value={value}
                loading={loading}
                onChange={onChangeDropdown}
            />
            {errorInfo && <ErrorMessage errorInfo={errorInfo} />}
        </Form.Group>
    );
}

export default LocationSelectComponent;
