import classNames from "classnames";
import { DocumentTemplateTags } from "commonPartials/documentTemplates";
import AppIconWithName from "components/AppIconWithName";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import {
    formatNumericValue,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useCheckPermission } from "hooks/permissionCheck";
import defaultTo from "lodash-es/defaultTo";
import {
    DocumentTemplateManagerItem,
    DocumentTemplateRequest,
    DocumentTemplateSortColumn,
} from "models/documentTemplates";
import { ResponseMeta, TableSort } from "models/general";
import {
    Business_DocumentManager_Template,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuid } from "uuid";
import styles from "./DocumentTemplateLibrary.module.scss";
import { DocumentTemplateClone } from "./partials";

interface DocumentTemplateLibraryProps {
    templates: DocumentTemplateManagerItem[];
    metaState: ResponseMeta;
    sort: TableSort<DocumentTemplateSortColumn>;
    onSortChange: (sort: TableSort<DocumentTemplateSortColumn>) => void;
    loading: boolean;
    refetchDocumentTemplate: (pageNumber: number) => void;
    request: DocumentTemplateRequest;
}

const COLUMNS = 4;
export const DocumentTemplateLibrary: React.FC<
    DocumentTemplateLibraryProps
> = ({
    templates,
    metaState,
    onSortChange,
    sort,
    refetchDocumentTemplate,
    loading,
}) => {
    const { t } = useTranslation();

    const { sessionBusinessLocation } = useSessionBusiness();

    const locationId = sessionBusinessLocation?.Id;

    const { checkPermission } = useCheckPermission();
    const hasTemplateCreatePermission = checkPermission(
        Business_DocumentManager_Template,
        [PermissionAccessTypes.CREATE]
    );

    const meta: ResponseMeta = defaultTo(metaState, {
        PageNumber: 1,
        HasNextPage: metaState ? metaState.HasNextPage : false,
    });

    return (
        <>
            {locationId && locationId > 0 ? (
                <InfiniteScroll
                    dataLength={templates && templates.length}
                    next={() => {
                        refetchDocumentTemplate(
                            defaultTo(meta.PageNumber, 1) + 1
                        );
                    }}
                    hasMore={defaultTo(meta.HasNextPage, false)}
                    loader={<></>}
                    scrollableTarget="scrollableDiv"
                >
                    <AppTable
                        id={"scrollableDiv"}
                        tableClass={classNames(
                            styles.tableClass,
                            "table-striped"
                        )}
                        stickyHeader={true}
                        heightToAdjust={250}
                        mediumViewAdjustment={60}
                        mobileBreak={800}
                        mobileViewAdjustment={110}
                    >
                        <thead>
                            <tr>
                                <AppTableSortColumnIcons
                                    text={t("templateDocumentManager.id")}
                                    sortColumn={
                                        DocumentTemplateSortColumn.DisplayId
                                    }
                                    onClick={(key, order) =>
                                        onSortChange({
                                            SortColumn: key,
                                            SortOrder: order,
                                        })
                                    }
                                    sortOrder={
                                        sort.SortColumn ==
                                        DocumentTemplateSortColumn.DisplayId
                                            ? sort.SortOrder
                                            : null
                                    }
                                    className={styles.displayId}
                                />
                                <th style={{ minWidth: "600px" }}>
                                    {t("templateDocumentManager.documentName")}
                                </th>
                                <th
                                    style={{
                                        minWidth: "150px",
                                        width: "150px",
                                    }}
                                >
                                    {t("templateDocumentManager.tags")}
                                </th>
                                <th
                                    style={{
                                        ...getFixedCssWidths(170),
                                        textAlign: "center",
                                    }}
                                >
                                    {t("templateDocumentManager.adopt")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS} />
                            </tr>
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS} />
                            </tr>

                            {templates &&
                                templates.length > 0 &&
                                templates.map((x) => (
                                    <tr key={uuid()}>
                                        <td className={styles.displayIdSpan}>
                                            <span>
                                                {formatNumericValue(
                                                    x.DisplayId,
                                                    "000"
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <AppIconWithName
                                                text={x.Name}
                                                iconPath={
                                                    x.IsEditable
                                                        ? ImageAssets
                                                              .documentManager
                                                              .doc
                                                        : ImageAssets
                                                              .documentManager
                                                              .pdf
                                                }
                                                iconClass={styles.icon}
                                            />
                                        </td>
                                        <td>
                                            <DocumentTemplateTags
                                                values={x.Types}
                                            />
                                        </td>
                                        <td className={styles.adoptDiv}>
                                            <DocumentTemplateClone
                                                templateId={x.Id}
                                                readonly={
                                                    !hasTemplateCreatePermission
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}

                            {loading && (
                                <AppTableSkeleton
                                    columns={COLUMNS}
                                    rows={
                                        templates && templates.length > 0
                                            ? 2
                                            : 12
                                    }
                                />
                            )}

                            {templates && templates.length == 0 && !loading && (
                                <tr>
                                    <td
                                        colSpan={COLUMNS}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t(
                                            "templateDocumentManager.templateListEmpty"
                                        )}
                                    </td>
                                </tr>
                            )}

                            <tr className="dummy-row">
                                <td colSpan={COLUMNS} />
                            </tr>
                        </tbody>
                    </AppTable>
                </InfiniteScroll>
            ) : (
                <div className={styles.noLocationMessageDiv}>
                    <h3 className="text-danger">
                        {t("templateDocumentManager.noLocation.firstLine")}
                        <br />
                        {t("templateDocumentManager.noLocation.secondLine")}
                    </h3>
                </div>
            )}
        </>
    );
};

export default DocumentTemplateLibrary;
