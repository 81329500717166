import { AppSelectOld } from "components/AppSelect";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    AppInputField,
    AppLastNameSurnameFields,
    AppCheckbox,
} from "components/FormFields";
import { getGenderNumberToEnumValue } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    ContactInfo,
    PermissionAccessMap,
    validateClientContactInfo,
} from "models";
import {
    AppResponse,
    Gender,
    getValidityStateFromApiResponse,
    Optional,
    SelectItem,
    ValidityStateManager,
} from "models/general";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business";

const selectStyles = {
    input: (base: any) => ({
        ...base,
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
    }),
};
export interface ClientContactInfoFieldsProps {
    title: string;
    value: ContactInfo;
    permMap: PermissionAccessMap;
    allowNewSurName?: boolean;
    loading: boolean;
    isEmailInUse: (email: string) => boolean;
    onChange: (val: ContactInfo) => void;
    onSave: () => void;
    responseErrors?: AppResponse<any>;
    HeaderActionMenu?: ReactNode;
    TypeCheckbox?: ReactNode;
}
export const ClientContactInfoFields: React.FC<
    ClientContactInfoFieldsProps
> = ({
    title,
    value,
    loading,
    permMap,
    onChange,
    isEmailInUse,
    allowNewSurName = false,
    HeaderActionMenu,
    responseErrors,
    TypeCheckbox,
    onSave,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [showError, setShowError] = useState(false);
    const validityStateManager = useMemo(() => {
        let validationState = validateClientContactInfo(value);
        if (!showError && responseErrors) {
            setShowError(true);
            validationState = getValidityStateFromApiResponse(
                responseErrors,
                validationState
            );
        }
        return new ValidityStateManager(validationState);
    }, [value, responseErrors, t]);
    const [hasError, setHasError] = useState(false);
    const contactEmailApiRef = useRef<Optional<string>>(value?.Email);

    const businessService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    useEffect(() => {
        if (loading && showError) {
            setShowError(false);
        }
    }, [loading]);

    const {
        isFetching: getContactInfoLoading,
        data: getContactInfoResponse,
        refetch: getContactInfo,
    } = useQuery(
        getBusinessGeneralServiceKey("getContactInfo", value.Email),
        async () => {
            if (value.Email) {
                contactEmailApiRef.current = value.Email;
                return await businessService.getContactInfo(value.Email);
            }
        },
        { enabled: false }
    );

    const onEmailUpdate = (email: string) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(email)) {
            if (isEmailInUse(email)) {
                setHasError(true);
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("kindergartenClient.contactInfo.contactAlreadyExist", {
                        email: email,
                    })}`,
                    "error"
                );
            } else if (
                email &&
                email != "" &&
                contactEmailApiRef.current != email &&
                !validityStateManager.getFirstErrorInfo("Email")
            ) {
                getContactInfo();
                setHasError(false);
            }
        }
    };
    useEffect(() => {
        if (
            !getContactInfoLoading &&
            getContactInfoResponse &&
            getContactInfoResponse.Data
        ) {
            const { Data } = getContactInfoResponse;

            onChange({
                ...value,
                City: Data.City,
                ConnectedToApp: Data.ConnectedToApp,
                CountryCode: Data.CountryCode,
                FirstName: Data.FirstName,
                Gender: getGenderNumberToEnumValue(Number(Data.Gender)),
                HouseNumber: Data.HouseNumber,
                Id: Data.Id,
                LastName: Data.LastName,
                NewLastName: Data.NewLastName,
                MobileNumber: Data.MobileNumber,
                PrivatePhone: Data.PrivatePhone,
                PostCode: Data.PostCode,
                StreetAddress: Data.StreetAddress,
                WorkPhone: Data.WorkPhone,
            });
        }
    }, [getContactInfoLoading, getContactInfoResponse]);
    const pleaseSelectItem: SelectItem = {
        Text: t("common.pleaseSelect"),
        Value: null,
    };
    const genderOptions: SelectItem[] = [
        pleaseSelectItem,
        ...Object.values(Gender).map((x) => {
            return {
                Text: t(`common.gender.${x.toString()}`),
                Value: x,
            } as SelectItem;
        }),
    ];

    return (
        <div className="mt-4">
            <AppContainer
                title={title}
                showHeader={true}
                heightToAdjust={190}
                mobileViewAdjustment={0}
                mediumViewAdjustment={0}
                // isCollapsible={true}
                // initiallyOpen={defaultTo(contactData.Id, 0) > 0}
                footer={
                    <AppContainerFooter
                        primaryButtonProps={{
                            disabled:
                                !permMap.CREATE ||
                                loading ||
                                // getContactInfoLoading ||
                                // contactEmailApiRef.current != state.Email ||  // to handle info loading check if user clicks save immediately on blur of email
                                !validityStateManager.isStateValid() ||
                                hasError,
                            onClick: () => {
                                onSave();
                            },
                        }}
                    />
                }
                actionMenu={HeaderActionMenu}
            >
                <Row>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.firstName.name"
                            )}
                            value={value.FirstName}
                            error={validityStateManager.getFirstErrorInfo(
                                "FirstName"
                            )}
                            showEmptyError={true}
                            disabled={!permMap.EDIT}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    FirstName: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t("kindergartenClient.contactInfo.street")}
                            value={value.StreetAddress}
                            disabled={!permMap.EDIT}
                            showEmptyError={true}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    StreetAddress: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.privateNumber"
                            )}
                            showEmptyError={true}
                            disabled={!permMap.EDIT}
                            value={value.PrivatePhone}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    PrivatePhone: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        {allowNewSurName ? (
                            <AppLastNameSurnameFields
                                lastName={value.LastName}
                                surName={value.NewLastName}
                                onLastNameChange={(e) =>
                                    onChange({ ...value, LastName: e })
                                }
                                onSurnameChange={(e) =>
                                    onChange({ ...value, NewLastName: e })
                                }
                                lastNameProps={{
                                    disabled: !permMap.EDIT,
                                    error: validityStateManager.getFirstErrorInfo(
                                        "LastName"
                                    ),
                                }}
                            />
                        ) : (
                            <AppInputField
                                label={t(
                                    "kindergartenClient.contactInfo.lastName.name"
                                )}
                                value={value.LastName}
                                error={validityStateManager.getFirstErrorInfo(
                                    "LastName"
                                )}
                                showEmptyError={true}
                                disabled={!permMap.EDIT}
                                onValueChange={(val) =>
                                    onChange({
                                        ...value,
                                        LastName: defaultTo(val, ""),
                                    })
                                }
                            />
                        )}
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.homeNumber"
                            )}
                            disabled={!permMap.EDIT}
                            value={value.HouseNumber}
                            showEmptyError={true}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    HouseNumber: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.mobileNumber"
                            )}
                            disabled={!permMap.EDIT}
                            value={value.MobileNumber}
                            showEmptyError={true}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    MobileNumber: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                                {t("kindergartenClient.gender")}
                            </Form.Label>
                            <AppSelectOld
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                options={genderOptions}
                                value={genderOptions.find(
                                    (x) => x.Value == value.Gender
                                )}
                                showEmptyError={true}
                                isDisabled={!permMap.EDIT}
                                getOptionLabel={(opt: SelectItem) => opt.Text}
                                getOptionValue={(opt: SelectItem) => opt.Value}
                                onChange={(e: SelectItem) =>
                                    onChange({
                                        ...value,
                                        Gender: e.Value as Gender,
                                    })
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.postalCode"
                            )}
                            value={value.PostCode}
                            showEmptyError={true}
                            disabled={!permMap.EDIT}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    PostCode: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.workPhoneNumber"
                            )}
                            value={value.WorkPhone}
                            showEmptyError={true}
                            disabled={!permMap.EDIT}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    WorkPhone: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t(
                                "kindergartenClient.contactInfo.email.title"
                            )}
                            disabled={!permMap.EDIT}
                            value={value.Email}
                            showEmptyError={true}
                            error={validityStateManager.getFirstErrorInfo(
                                "Email"
                            )}
                            onBlur={(e) => onEmailUpdate(e)}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    Email: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <AppInputField
                            label={t("kindergartenClient.contactInfo.city")}
                            value={value.City}
                            disabled={!permMap.EDIT}
                            showEmptyError={true}
                            onValueChange={(val) =>
                                onChange({
                                    ...value,
                                    City: defaultTo(val, ""),
                                })
                            }
                        />
                    </Col>
                    {TypeCheckbox && (
                        <Col xs={4} style={{ marginTop: "35px" }}>
                            <div
                                style={{ height: "100%" }}
                                className="d-flex flex-column"
                            >
                                {TypeCheckbox}
                            </div>
                        </Col>
                    )}
                </Row>
            </AppContainer>
        </div>
    );
};
