import {
    AddChannelRequestModel,
    AddRemoveMemberRequest,
    ChannelCacheDTO,
    GetUsersForChannelRequest,
    MemberListResponse,
    PinChannelRequest,
    TwilioTokenResponse,
} from "models/chat";
import { AppResponse } from "models/general";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IChatService {
    getUsersList: (
        request: GetUsersForChannelRequest
    ) => Promise<AppResponse<MemberListResponse[]>>;
    getToken: (useId: string) => Promise<AppResponse<TwilioTokenResponse>>;
    setCachedChannels(
        request: ChannelCacheDTO[]
    ): Promise<AppResponse<boolean>>;
    getCachedChannels: () => Promise<AppResponse<string>>;
    pinChannel: (useId: PinChannelRequest) => Promise<AppResponse<boolean>>;
    removeMembers(
        addMemberReq: AddRemoveMemberRequest
    ): Promise<AppResponse<boolean>>;

    addMembers(
        addMemberReq: AddRemoveMemberRequest
    ): Promise<AppResponse<boolean>>;
    deleteChat(
        channelSid: string,
        forceDelete?: boolean
    ): Promise<AppResponse<string>>;
    updateDeleteFlag(channelSid: string): Promise<AppResponse<boolean>>;
    createChannel(
        createChannelReq: AddChannelRequestModel
    ): Promise<AppResponse<string>>;
}
const apiLinkProvider = routesForContext().business.api.chat;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getChatServiceKey(name: keyof IChatService, data?: any) {
    if (!data) {
        return name;
    }
    return `chat_${name}_${JSON.stringify(data)}`;
}
export class ChatService extends BaseService implements IChatService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }
    setCachedChannels(
        request: ChannelCacheDTO[]
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<string>({
            url: this.routes.setCachedChannels(),
            method: "post",
            data: { Channels: request },
        });
    }
    getUsersList(
        request: GetUsersForChannelRequest
    ): Promise<AppResponse<MemberListResponse[]>> {
        const formData = new FormData();
        formData.append("ChannelSid", request.ChannelSid);
        formData.append("UsersType", request.UsersType);
        return this.doServerXHR<boolean>({
            url: this.routes.getUsersList(),
            method: "post",
            data: formData,
        });
    }
    getToken(useId: string): Promise<AppResponse<TwilioTokenResponse>> {
        return this.doServerXHR<AppResponse<TwilioTokenResponse>>({
            url: this.routes.getToken(useId),
            method: "get",
        });
    }
    getCachedChannels(): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.routes.getCachedChannels(),
            method: "get",
        });
    }

    deleteChat(
        channelSid: string,
        forceDelete?: boolean
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.routes.deleteChat(channelSid, forceDelete),
            method: "delete",
        });
    }
    updateDeleteFlag(channelSid: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.updateDeleteFlag(channelSid),
            method: "get",
        });
    }
    pinChannel(
        pinChannelReq: PinChannelRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.pinChannel(pinChannelReq),
            method: "post",
        });
    }

    createChannel(
        createChannelReq: AddChannelRequestModel
    ): Promise<AppResponse<string>> {
        const formData = new FormData();
        createChannelReq.file && formData.append("file", createChannelReq.file);
        formData.append("GroupName", createChannelReq.GroupName);
        formData.append(
            "MembersIdsList",
            JSON.stringify(createChannelReq.MembersIdsList)
        );
        formData.append("AdminId", createChannelReq.AdminId);
        formData.append("ChannelType", createChannelReq.ChannelType.toString());

        return this.doServerXHR<string>({
            url: this.routes.createChannel(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    }

    removeMembers(
        addMemberReq: AddRemoveMemberRequest
    ): Promise<AppResponse<boolean>> {
        const formData = new FormData();
        formData.append("ChannelSid", addMemberReq.ChannelSid);
        formData.append(
            "MembersIdentities",
            JSON.stringify(addMemberReq.MembersIdentities)
        );

        return this.doServerXHR<boolean>({
            url: this.routes.removeMembers(),
            method: "post",
            data: formData,
        });
    }
    addMembers(
        addMemberReq: AddRemoveMemberRequest
    ): Promise<AppResponse<boolean>> {
        const formData = new FormData();
        formData.append("ChannelSid", addMemberReq.ChannelSid);
        formData.append(
            "MembersIdentities",
            JSON.stringify(addMemberReq.MembersIdentities)
        );

        return this.doServerXHR<boolean>({
            url: this.routes.addMembers(),
            method: "post",
            data: formData,
        });
    }
}
