import { StartAndEndDateFields, WeekDayCheckboxes } from "commonPartials";
import { AppSwitch, ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { ValidityStateManager } from "models/general";
import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business";
import {
    EmployeeShift,
    EmployeeShiftType,
    validateEmployeeShift,
} from "models/employeeShifts";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";
import EmployeeShiftService, {
    getEmployeeShiftServiceKey,
} from "services/business/EmployeeShiftService";
import { WorkingDaysNumber } from "models";

export interface EmployeeShiftCreateEditProps {
    value?: EmployeeShift;
    readonly?: boolean;
    onBack: (year?: number) => void;
}

export const EmployeeShiftCreateEdit: React.FC<
    EmployeeShiftCreateEditProps
> = ({ value, readonly = false, onBack }) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { dataItem: employee } = useActiveBusinessEmployee();
    const [state, setState] = useState<EmployeeShift>(
        defaultTo(value, {
            EmployeeId: employee && employee.Id,
            Type: EmployeeShiftType.Exclusion,
        } as EmployeeShift)
    );

    const generalService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const goBack = () => {
        onBack(state.StartDate ? state.StartDate.year() : undefined);
    };

    const shiftService = new EmployeeShiftService(
        linkProvider.business.employees().api.withId().shifts
    );

    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(async () => await shiftService.createUpdate(state), {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        t(
                            `common.actions.${
                                state.Id && state.Id > 0
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        ),
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getEmployeeShiftServiceKey("list", {
                            employeeId: employee?.EncodedId,
                        })
                    );
                    goBack();
                } else if (res.Message) {
                    showSweetAlertToast("Error", res.Message, "error");
                } else {
                    showUnexpectedErrorToast();
                }
            },
        });

    const validityStateManager = useMemo(() => {
        const validationState = validateEmployeeShift(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    const { isLoading: shiftsLoading, data: shiftsResponse } = useQuery(
        getBusinessGeneralServiceKey("getBusinessShifts", {
            businessId: employee?.BusinessId,
        }),
        async () => await generalService.getBusinessShifts()
    );

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;
    const shiftsOptions = [
        pleaseSelectOption,
        ...(shiftsResponse && shiftsResponse.Data
            ? shiftsResponse.Data.map((x) => {
                  return {
                      label: x.Text,
                      value: x.Value,
                  } as SimpleOption;
              })
            : []),
    ];
    const typeOptions = [
        pleaseSelectOption,
        ...Object.values(EmployeeShiftType).map((x) => {
            return {
                label: t(`employee.shift.typeOptions.${x.toLowerCase()}`),
                value: x,
            } as SimpleOption;
        }),
    ];

    return (
        <AppContainer
            showHeader={true}
            onBack={goBack}
            title={
                !isNil(state.Id)
                    ? t("employee.shift.editTitle")
                    : t("employee.shift.createTitle")
            }
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            readonly ||
                            createUpdateLoading ||
                            !validityStateManager.isStateValid(),
                        onClick: () => {
                            createUpdate();
                        },
                    }}
                />
            }
        >
            <Row>
                <Col md={6}>
                    <Form.Group controlId="shift">
                        <Form.Label>
                            {t("employee.shift.shift.name")}
                        </Form.Label>
                        <AppSelectOld
                            value={
                                state.ShiftId
                                    ? shiftsOptions.filter(
                                          (x) => x.value == state.ShiftId
                                      )
                                    : pleaseSelectOption
                            }
                            menuPortalTarget={document.body}
                            isLoading={shiftsLoading}
                            isDisabled={readonly}
                            onChange={(value: SimpleOption) => {
                                setState({
                                    ...state,
                                    ShiftId: value.value,
                                });
                            }}
                            options={shiftsOptions}
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "ShiftId"
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="shift">
                        <Form.Label>{t("employee.shift.type.name")}</Form.Label>
                        <AppSelectOld
                            value={
                                state.Type
                                    ? typeOptions.filter(
                                          (x) => x.value == state.Type
                                      )
                                    : pleaseSelectOption
                            }
                            menuPortalTarget={document.body}
                            isDisabled={readonly}
                            onChange={(value: SimpleOption) => {
                                setState({
                                    ...state,
                                    Type: value.value,
                                });
                            }}
                            options={typeOptions}
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "Type"
                            )}
                        />
                    </Form.Group>
                </Col>
                <StartAndEndDateFields
                    colSize={6}
                    appendToBody={true}
                    value={{
                        StartDate: state.StartDate,
                        EndDate: state.EndDate,
                    }}
                    onChange={({ StartDate: startDate, EndDate: endDate }) => {
                        setState({
                            ...state,
                            StartDate: startDate,
                            EndDate: endDate,
                        });
                    }}
                    readonly={readonly}
                />
                {!isNil(state.Days) && (
                    <Col md={6}>
                        <WeekDayCheckboxes
                            showBorder={true}
                            value={state.Days}
                            error={validityStateManager.getFirstErrorInfo(
                                "Days"
                            )}
                            readonly={readonly}
                            onChange={(val: WorkingDaysNumber[]) => {
                                setState({
                                    ...state,
                                    Days: val,
                                });
                            }}
                        />
                    </Col>
                )}
                <Col md={6}>
                    <AppSwitch
                        label={t("employee.shift.forSelectedDays")}
                        disabled={readonly}
                        value={!isNil(state.Days)}
                        style={{ height: "34px" }}
                        labelPosition="right"
                        onChange={(checked: boolean) => {
                            if (checked == true) {
                                setState({
                                    ...state,
                                    Days: [],
                                });
                            } else {
                                setState({
                                    ...state,
                                    Days: undefined,
                                });
                            }
                        }}
                    />
                </Col>
            </Row>
        </AppContainer>
    );
};

export default EmployeeShiftCreateEdit;
