import {

    ISO8601_DATE_FORMAT,
} from "globals/constants";
import {
    Gender,
    Optional,
    ResponseMeta,
    TableSort,
} from "models/general";
import moment from "moment-timezone";
import { KindergartenClientRegistrationResponse } from "models/kindergartenClientRegistration/response";
import {
    ClientRegistrationContact,
    KindergartenClientRegistration,
    KindergartenClientRegistrationFilter,
} from "models/kindergartenClientRegistration/model";
import {
    ClientRegistrationCustodianType,
    ClientRegistrationProgressStatus,
    ClientRegistrationSortColumn,
    ClientRegistrationStatus,
    DataSourceTypes,
} from "models/kindergartenClientRegistration/enum";
import { KindergartenClientRegistrationRequest } from "models/kindergartenClientRegistration/request";
import {
    getClientContactInitialValues,
} from "models/kindergartenClient/helper";
import { getGenderNumberToEnumValue } from "globals/helpers/generalHelper";
import { isNil } from "lodash-es";

const YEAR_COLORS_ARRAY = [
    "#ffc1c1",
    "#d299c2",
    "#ffffd4",
    "#a9e2b4",
    "#e0ffc2",
    "#f7cac9",
    "#f9e0e3",
    "#d7e8f0",
    "#d0e3f0",
    "#e5edef",
    "#ffe0b2",
    "#d2d1f0",
    "#ffecd4",
    "#b2d2b4",
    "#d0e0f7"
];
export const getKindergartenClientRegistrationFromResponse = (
    val: KindergartenClientRegistrationResponse
): KindergartenClientRegistration => {
    const parseContact = (contact: ClientRegistrationContact) => ({
        ...contact,
        ...(!isNil(contact.Gender)
            ? {
                Gender: getGenderNumberToEnumValue(Number(contact.Gender)),
            }
            : {}),
        RegistrationId: val.Id as number,
    });

    const response: KindergartenClientRegistration = {
        ...val,
        CareStart: val.CareStart
            ? moment(val.CareStart, ISO8601_DATE_FORMAT)
            : null,
        Birthday: val.Birthday
            ? moment(val.Birthday, ISO8601_DATE_FORMAT)
            : null,
        CreatedAt: val.CreatedAt ? moment.unix(val.CreatedAt) : null,
        Gender: !isNil(val.Gender)
            ? getGenderNumberToEnumValue(Number(val.Gender))
            : null,
        Status: !isNil(val.Status) ? val.Status : null,
        Parents: val.Parents.map((x) => parseContact(x)),
    } as KindergartenClientRegistration;

    if (
        !response.Parents.some(
            (p) => p.Type == ClientRegistrationCustodianType.Father
        )
    ) {
        response.Parents = [
            ...response.Parents,
            getClientRegistrationContactInitialValues(
                val.Id,
                ClientRegistrationCustodianType.Father
            ),
        ];
    }
    if (
        !response.Parents.some(
            (p) => p.Type == ClientRegistrationCustodianType.Mother
        )
    ) {
        response.Parents = [
            ...response.Parents,
            getClientRegistrationContactInitialValues(
                val.Id,
                ClientRegistrationCustodianType.Mother
            ),
        ];
    }
    response.Parents = response.Parents.sort((a, b) =>
        a.Type == b.Type ? 0 : a.Type < b.Type ? -1 : 1
    );
    return response;
};

export const getKindergartenClientRegistrationRequest = (
    filter: KindergartenClientRegistrationFilter,
    sort: TableSort<ClientRegistrationSortColumn>,
    meta: ResponseMeta
) => {
    return {
        ...sort,
        ...filter,
        PageSize: meta.PageSize,
        PageNumber: meta.PageNumber,
    } as KindergartenClientRegistrationRequest;
};

export const getClientRegistrationContactInitialValues = (
    registrationId: Optional<number>,
    type: ClientRegistrationCustodianType
): ClientRegistrationContact => {
    return {
        ClientRegistrationId: registrationId,
        Type: type,
        ...getClientContactInitialValues(),
        Gender:
            type == ClientRegistrationCustodianType.Father
                ? Gender.Male
                : Gender.Female,
    };
};
export const geKindergartenClientRegistrationBasicDataInitialValues =
    (): KindergartenClientRegistration => {
        return {
            Id: null,
            EncodedId: null,
            FirstName: "",
            LastName: "",
            Gender: null,
            CountryCode: "DE",
            Year: moment().year(),
            AgeGroupFieldUuid: null,
            CareScopeFieldUuid: null,
            Status: ClientRegistrationStatus.Active,
            ProgressStatus: ClientRegistrationProgressStatus.Pending,
            FromSource: DataSourceTypes.LwDashboard,
            Note: "",
            MigrationBackground: false,
            PlaceChange: false,
            IsSibling: false,
            ArrangedPlace: false,
            BusinessId: 0,
            ClientId: 0,
            Birthday: moment(),
            CareStart: moment(),
            CreatedAt: moment(),
            Files: [],
            Parents: [],
        };
    };

export const getYearColor = (year: number = 0) => {
    // will use 10 distinct colors and cycle them based on mod
    return YEAR_COLORS_ARRAY[Math.ceil(year) % 15];
}