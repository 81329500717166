import { AppTable, AppTableSkeleton } from "components/Table";
import { TableSort } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import EmployeeVacationTableHeader from "./EmployeeVacationTableHeader";
import EmployeeVacationTableRow from "./EmployeeVacationTableRow";
import {
    EmployeeVacation,
    EmployeeVacationSortColumn,
} from "models/employeeVacation";

export interface EmployeeVacationTableProps {
    hasDeletePermission: boolean;
    loading: boolean;
    deleting?: boolean;
    list: EmployeeVacation[];
    sort: TableSort<EmployeeVacationSortColumn>;
    onSortChange: (sort: TableSort<EmployeeVacationSortColumn>) => void;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

const COLUMNS_COUNT = 13;
export const EmployeeVacationTable: React.FC<EmployeeVacationTableProps> = ({
    hasDeletePermission,
    loading,
    deleting = false,
    list,
    sort,
    onSortChange,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <AppTable
            hover={list.length > 0}
            heightToAdjust={470}
            mediumViewAdjustment={20}
            mobileViewAdjustment={20}
            stickyHeader={true}
        >
            <thead>
                <EmployeeVacationTableHeader
                    sort={sort}
                    onSortChange={onSortChange}
                />
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                {loading ? (
                    <AppTableSkeleton columns={COLUMNS_COUNT} rows={10} />
                ) : list.length > 0 ? (
                    list.map((x, idx) => (
                        <EmployeeVacationTableRow
                            key={idx}
                            value={x}
                            onClick={onClick}
                            loading={deleting}
                            onDeleteBtnClick={onDeleteBtnClick}
                            hasDeletePermission={hasDeletePermission}
                        />
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={COLUMNS_COUNT}
                            style={{ textAlign: "center" }}
                        >
                            {t("common.noDataFound")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default EmployeeVacationTable;
