import { COOKIE_EXPIRY_DATE, COOKIE_SHORT_EXPIRY } from "globals/constants";
import Cookies from "js-cookie";
import { LoginResponse, UserCookie } from "models";
import { CookiesKey } from "models/general/enum";
import { BaseObject } from "models/general/models";
export interface CookiesOption {
    expires?: Date;
    path?: string;
    domain?: string;
    secure?: boolean;
}

export const getCookie = (key: string): string | undefined => {
    return Cookies.get(key);
};

export function updateCookie<T extends BaseObject>(key: string, value: T) {
    removeCookie(key);
    setCookie(key, JSON.stringify(value));
}
export const setCookie = (
    key: string,
    value: any,
    option?: CookiesOption | undefined
) => {
    Cookies.set(key, value, option);
};
export const setSidebarCookie = (value: boolean) => {
    setCookie(CookiesKey.IS_SIDEBAR_OPEN, value);
};
export const removeCookie = (key: string): void => {
    Cookies.remove(key);
};
export const removeAllCookies = (): void => {
    Cookies.remove(CookiesKey.USER_COOKIE.toString());
};

export const cookieAvailable = (key: CookiesKey): boolean => {
    if (getCookie(key.toString()) !== undefined) {
        return true;
    } else {
        return false;
    }
};
//User cookie consist of remember me and user refresh token to now
export const createUserAuthCookies = (
    user: LoginResponse,
    rememberMe: boolean = false
) => {
    const userCookie: UserCookie = {
        RememberMe: rememberMe,
        RefreshToken: user.RefreshToken,
        AccessToken: user.AccessToken,
    };
    setCookie(CookiesKey.USER_COOKIE, JSON.stringify(userCookie), {
        expires: COOKIE_EXPIRY_DATE,
    });
    //Culture can also be set by url but only for single request (will not be changed in cookie)
    //it will remain save in cookie after logout
    setCookie(CookiesKey.USER_LOCALE, user.Culture, {
        expires: COOKIE_EXPIRY_DATE,
    });
};
export const createSessionExpireCookie = () => {
    // for using in BaseService as in baseService I can't use context
    setCookie(CookiesKey.SESSION_EXPIRE, true, {
        expires: COOKIE_SHORT_EXPIRY(),
    });
    removeUserAuthCookies();
};

export const removeUserAuthCookies = (): void => {
    // don't remove locale cookie
    Cookies.remove(CookiesKey.USER_COOKIE);
};
