import { BusinessCreateEditFields } from "commonPartials/businessCreateEditField";
import AppContentHeader from "components/AppContentHeader";
import { AppContainer } from "components/Containers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { CompanyType } from "models/business";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getRegistrationRequestsServiceKey } from "services/lillywait/LillywaitRegistrationRequestsService";

export const SetupLwcHQ: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();
    const { id: registrationRequestId } = useParams();

    return (
        <div>
            <AppContentHeader
                title={t("hqRegisterRequestManagement.createHq")}
                classNameForIcon="pe-7s-plus"
                hasGoBack={true}
                backUrl={linkProvider.lillywait.admin.registrationRequests.list()}
            />
            <AppContainer
                showHeader={false}
                loadingValue={loading}
                heightToAdjust={200}
                mobileViewAdjustment={-40}
                mediumViewAdjustment={-40}
            >
                <BusinessCreateEditFields
                    branchType={CompanyType.Headquarter}
                    isCreate={true}
                    isHqRequestSection={true}
                    setLoading={setLoading}
                    onSuccess={() => {
                        queryClient.invalidateQueries(
                            getRegistrationRequestsServiceKey("list")
                        );
                        navigate(
                            linkProvider.lillywait.admin.registrationRequests.list()
                        );
                    }}
                    registrationRequestId={registrationRequestId}
                />
            </AppContainer>
        </div>
    );
};

export default SetupLwcHQ;
