import classNames from "classnames";
import { AppTooltip } from "components/Tooltips";
import { IsNavigationTabActive, NavigationTab } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { Link, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import styles from "./AppSlidingTabButton.module.scss";
export interface AppSlidingTabButtonProps {
    value: NavigationTab;
    onChange?: () => void;
    ellipseText?: boolean;
}

export const AppSlidingTabButton: React.FC<AppSlidingTabButtonProps> = ({
    value,
    ellipseText = false,
    onChange = () => {},
}) => {
    const { hasAccess, label, url } = value;
    const location = useLocation();
    const [isActive, setActive] = useState<boolean>(
        IsNavigationTabActive(value, location.pathname)
    );
    const { width: btnWidth, ref: btnRef } = useResizeDetector();
    // const [btnRef, setBtnRef] = useState<HTMLButtonElement | null>(null);
    useEffect(() => {
        // for first load (if directly opened a tabbed-layout from url)
        if (isActive) {
            onChange();
        }
    }, []);
    const showTooltip = useMemo(() => {
        if (btnRef && btnRef.current && btnRef.current.parentElement) {
            return (
                Math.max(
                    btnRef.current.scrollWidth + 18,
                    btnRef.current.scrollWidth * 1.09
                ) >= btnRef.current.parentElement.scrollWidth
            );
        }
        return label.length > 20;
    }, [label, btnRef, btnWidth]);
    useEffect(() => {
        const newActive = IsNavigationTabActive(value, location.pathname);

        if (isActive != newActive) {
            setActive(newActive);
            if (newActive) {
                onChange();
            }
        }
    }, [location.pathname, url, isActive]);
    const onClickHandler = (e: any) => {
        if (isActive) {
            e.preventDefault();
        }
    };
    if (hasAccess) {
        return (
            <Link
                to={url}
                style={{ display: "flex" }}
                onClick={onClickHandler}
                key={label ? label : uuid()}
            >
                <Button
                    ref={btnRef}
                    className={classNames(
                        styles.tabAnchorTag,
                        { [styles.removeCursor]: isActive },
                        { [styles.active]: isActive },
                        { [styles.ellipse]: ellipseText || showTooltip }
                    )}
                >
                    {showTooltip ? (
                        <AppTooltip
                            content={label}
                            allowHtml={false}
                            trigger="mouseenter focus"
                            arrow={true}
                        >
                            <span>{label}</span>
                        </AppTooltip>
                    ) : (
                        label
                    )}
                </Button>
            </Link>
        );
    }
    return <></>;
};

export default AppSlidingTabButton;
