import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import { UserRoleManagementItem } from "models/userManagement/model";

export function validateUser(
    user: UserRoleManagementItem,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("roleManagement.user.firstName.missing"),
            },
        ],
        user.FirstName,
        "FirstName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("roleManagement.user.lastName.missing"),
            },
        ],
        user.LastName,
        "LastName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "roleManagement.user.permissionGroup.missing"
                ),
            },
        ],
        user.RoleId,
        "RoleId"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.EMAIL,
                message: i18next.t("roleManagement.user.email.missing"),
            },
        ],
        user.Email,
        "Email"
    );
    return state;
}
