import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { AppEmitterEvents, eventEmitter } from "globals/eventEmitter";
import config from "globals/config";
import {
    createSessionExpireCookie,
    getCookie,
} from "globals/helpers/cookieHelper";
import { getInitialLocale } from "globals/helpers/localizationHelpers";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { isChromeOrEdge } from "globals/helpers/webInfoHelper";
import { renewSessionAndUpdateCookie } from "hooks/general/authHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { UserCookie, UserTokenResponse } from "models";
import { CookiesKey } from "models/general/enum";
import { WithPaginationParams } from "models/general/pagination";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";

export type QueryResultParams<T> = WithPaginationParams<T> | T;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["UseCookieAuth"] = "false";
axios.defaults.headers.common["Accept-Language"] = getInitialLocale();
axios.defaults.headers.common["Access-Control-Max-Age"] = "600";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.withCredentials = true; // to send session cookie in every request

export const headerToIgnoreContractExpire = {
    headers: {
        IgnoreContractExpire: "true",
    },
};

export class BaseService {
    protected doXHR<T>(config: AxiosRequestConfig) {
        return axios.request<T>(config);
    }

    public doServerXHR<T>(
        config: AxiosRequestConfig,
        handleError: boolean = true
    ) {
        return this.handleResponse<T>(
            axios.request<AppResponse<T>>(config),
            () => {
                return axios.request<AppResponse<T>>(config);
            },
            handleError
        );
    }

    protected handleResponse<T>(
        promise: AxiosPromise<AppResponse<T>>,
        retry?: () => AxiosPromise<AppResponse<T>>,
        handleError: boolean = true
    ) {
        return promise
            .then((data) => {
                return data.data;
            })
            .catch(async (data) => {
                if (handleError) {
                    return await handleApiError(data, this, retry);
                }
            });
    }
}
export default BaseService;

async function handleApiError(
    data: any,
    service?: BaseService,
    retry?: () => AxiosPromise<AppResponse<any>>
): Promise<any> {
    const toExclude = [400, 401, 404, 422];

    if (!data.response) {
        if (isChromeOrEdge()) {
            // firefox also displays this on page-change if any current api request is in-progress
            showUnexpectedErrorToast();
        }
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 500 } }; // for firefox
    } else {
        if (data.response.status && data.response.status === 401) {
            //sessionTimeout
            //checking if user checked the rememberMe

            const userCookieJson = getCookie(CookiesKey.USER_COOKIE);
            if (userCookieJson && retry && service) {
                const userCookie = JSON.parse(userCookieJson) as UserCookie;
                if (userCookie.RememberMe) {
                    //refetch new token
                    await renewSessionAndUpdateCookie(
                        userCookie,
                        (
                            refreshToken: string
                        ): Promise<AppResponse<UserTokenResponse>> => {
                            // can't use AuthService here because of circular dependency
                            return defaultTo(
                                service,
                                new BaseService()
                            ).doServerXHR<AppResponse<UserTokenResponse>>({
                                url: routesForContext().noAuth.api.renewSession(
                                    { refreshToken: refreshToken }
                                ),
                                method: "post",
                            });
                        }
                    );

                    // retry request
                    return retry()
                        .then((data) => {
                            return data.data;
                        })
                        .catch(async (data) => {
                            return await handleApiError(data);
                        });
                }
            }

            createSessionExpireCookie();
            eventEmitter.emit(AppEmitterEvents.SESSION_TIMEOUT);
            return;
        }

        if (!toExclude.includes(data.response.status)) {
            if (data.response.status === 426) {
                showSweetAlertToast(
                    t("common.warning"),
                    `${t("common.error.contractExpired")}`,
                    "warning"
                );
            } else if (data.response.status === 403) {
                eventEmitter.emit(AppEmitterEvents.ACCESS_DENIED);
            } else {
                showUnexpectedErrorToast();
            }
            throw data;
        } else {
            return data.response.data;
        }
    }
}
