import classNames from "classnames";
import { DeleteWithPassword } from "commonPartials/deleteWithPassword";
import { AppTooltip } from "components";
import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import {
    showSwalLoader,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import ImageAssets from "globals/images/common";
import { useOpenDocumentPreview } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import {
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { checkPermissionInMap } from "models/permissionManagement/helper";
import { ShiftPlanRequest } from "models/shiftPlan/request";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import ShiftPlanService from "services/business/ShiftPlanService";
import styles from "./ShiftPlanHeaderContent.module.scss";

export interface ShiftPlanHeaderContentProps {
    isEditView: boolean;
    onEdit: (val: boolean) => void;
    onLoadChange: (val: boolean) => void;
    onChange: () => void;
    disabled?: boolean;
    request: ShiftPlanRequest;
    permMap: PermissionAccessMap;
}
export const ShiftPlanHeaderContent: React.FC<ShiftPlanHeaderContentProps> = ({
    isEditView,
    onEdit,
    disabled = false,
    permMap,
    onLoadChange,
    onChange,
    request,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const openDocumentPreviewTab = useOpenDocumentPreview();
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const canEdit = checkPermissionInMap(permMap, [PermissionAccessTypes.EDIT]);
    const canCreate = checkPermissionInMap(permMap, [
        PermissionAccessTypes.CREATE,
    ]);
    const canDelete = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);
    const shiftPlanService = new ShiftPlanService(
        linkProvider.business.api.shiftPlan
    );
    const { isLoading: creating, mutate: createPlan } = useMutation(
        async () => await shiftPlanService.createWeeklyShiftPlan(request),
        {
            onSuccess: (resp) => {
                if (resp) {
                    if (resp.Data) {
                        showSweetAlertToast(
                            t("common.success"),
                            t("shiftPlan.createSuccess"),
                            "success"
                        );
                        onChange();
                    } else if (resp.Message) {
                        showSweetAlertToast(
                            t("common.error.error"),
                            resp.Message,
                            "error"
                        );
                    }
                }
            },
        }
    );
    const { isLoading: generatingPdf, mutate: generatePdf } = useMutation(
        async () =>
            await shiftPlanService.getWeeklyShiftPlanPDF({
                ...request,
                WeekEnd: request.WeekEnd.clone().add(5, "weeks"), // to show 6 weeks total
            }),
        {
            onSuccess: (resp) => {
                if (resp) {
                    if (resp.Data) {
                        openDocumentPreviewTab(
                            resp.Data,
                            t("shiftPlan.pdfTitle")
                        );
                        showSweetAlertToast(
                            t("common.success"),
                            t("shiftPlan.pdfSuccess"),
                            "success"
                        );
                        onChange();
                    } else if (resp.Message) {
                        showSweetAlertToast(
                            t("common.error.error"),
                            resp.Message,
                            "error"
                        );
                    }
                }
            },
        }
    );
    const { isLoading: deleting, mutate: deletePlan } = useMutation(
        async (password: string) =>
            await shiftPlanService.deleteWeeklyShiftPlan(request, password),
        {
            onSuccess: (resp) => {
                if (resp) {
                    if (resp.Data) {
                        showSweetAlertToast(
                            t("common.success"),
                            t("shiftPlan.deleteSuccess"),
                            "success"
                        );
                        onChange();
                    } else if (resp.Message) {
                        showSweetAlertToast(
                            t("common.error.error"),
                            resp.Message,
                            "error"
                        );
                    }
                }
            },
        }
    );

    useEffect(() => {
        onLoadChange(creating || deleting || generatingPdf);
    }, [creating, deleting, generatingPdf]);

    const [iconHoverState, setIconHoverState] = useState({
        create: false,
        edit: false,
        delete: false,
        cancel: false,
    });

    return (
        <AppContentHeader
            title={t(`shiftPlan.title`)}
            // classNameForIcon="pe-7s-date"
            icon={ImageAssets.common.tableBlue}
            className={styles.header}
        >
            <div className={styles.buttons}>
                <AppTooltip
                    content={t("common.generatePdf")}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    <Image
                        onClick={() => {
                            if (!generatingPdf) {
                                generatePdf();
                            }
                        }}
                        className={styles.pdfIcon}
                        src={ImageAssets.documentManager.pdf}
                    />
                </AppTooltip>
                {isEditView ? (
                    <>
                        <AppRoundedTextIconButton
                            variant="outline-primary"
                            className={classNames(
                                styles.headerBtn,
                                styles.styled
                            )}
                            disabled={disabled}
                            iconUrl={
                                iconHoverState.cancel
                                    ? ImageAssets.common.crossWhite
                                    : ImageAssets.common.crossRed
                            }
                            onMouseEnter={() => {
                                setIconHoverState((o) => ({
                                    ...o,
                                    cancel: true,
                                }));
                            }}
                            onMouseLeave={() => {
                                setIconHoverState((o) => ({
                                    ...o,
                                    cancel: false,
                                }));
                            }}
                            text={t(`common.cancel`)}
                            onClick={() => {
                                onEdit(false);
                                setIconHoverState((o) => ({
                                    ...o,
                                    cancel: false,
                                }));
                            }}
                        />
                    </>
                ) : (
                    <>
                        {canCreate && (
                            <AppRoundedTextIconButton
                                variant="outline-primary"
                                className={classNames(
                                    styles.headerBtn,
                                    styles.styled,
                                    styles.largeImg
                                )}
                                iconUrl={
                                    iconHoverState.create
                                        ? ImageAssets.common.plus
                                        : ImageAssets.common.plusBlue
                                }
                                onMouseEnter={() => {
                                    setIconHoverState((o) => ({
                                        ...o,
                                        create: true,
                                    }));
                                }}
                                onMouseLeave={() => {
                                    setIconHoverState((o) => ({
                                        ...o,
                                        create: false,
                                    }));
                                }}
                                text={t(`shiftPlan.actions.new`)}
                                onClick={() => {
                                    createPlan();
                                }}
                            />
                        )}
                        {canEdit && (
                            <AppRoundedTextIconButton
                                className={classNames(
                                    styles.headerBtn,
                                    styles.styled,
                                    {
                                        [styles.largeImg]: iconHoverState.edit,
                                    }
                                )}
                                variant="outline-primary"
                                text={t(`shiftPlan.actions.edit`)}
                                iconUrl={
                                    iconHoverState.edit
                                        ? ImageAssets.common.editWhite
                                        : ImageAssets.common.editBlue
                                }
                                onMouseEnter={() => {
                                    setIconHoverState((o) => ({
                                        ...o,
                                        edit: true,
                                    }));
                                }}
                                onMouseLeave={() => {
                                    setIconHoverState((o) => ({
                                        ...o,
                                        edit: false,
                                    }));
                                }}
                                onClick={() => {
                                    onEdit(true);
                                    setIconHoverState((o) => ({
                                        ...o,
                                        edit: false,
                                    }));
                                }}
                            />
                        )}
                        {canDelete && (
                            <>
                                <AppRoundedTextIconButton
                                    variant="outline-primary"
                                    className={classNames(
                                        styles.headerBtn,
                                        styles.styled
                                    )}
                                    text={t(`shiftPlan.actions.delete`)}
                                    iconUrl={
                                        iconHoverState.delete
                                            ? ImageAssets.common.trashGray
                                            : ImageAssets.common.trashRed
                                    }
                                    onMouseEnter={() => {
                                        setIconHoverState((o) => ({
                                            ...o,
                                            delete: true,
                                        }));
                                    }}
                                    onMouseLeave={() => {
                                        setIconHoverState((o) => ({
                                            ...o,
                                            delete: false,
                                        }));
                                    }}
                                    onClick={() => {
                                        setDeleteOpen(true);
                                    }}
                                />
                                <DeleteWithPassword
                                    opened={deleteOpen}
                                    onClose={() => setDeleteOpen(false)}
                                    onSubmit={(password: string) => {
                                        deletePlan(password);
                                        setDeleteOpen(false);
                                        showSwalLoader(
                                            t(`shiftPlan.deleting`),
                                            ""
                                        );
                                    }}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </AppContentHeader>
    );
};

export default ShiftPlanHeaderContent;
