import { AppTooltip } from "components";
import { AppCircleIconButton } from "components/Buttons";
import { AppDatePicker } from "components/FormFields";
import { AppOverlayLoader } from "components/Loaders";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useOpenDocumentPreview } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { t } from "i18next";
import { defaults } from "lodash";
import { PermissionAccessMap } from "models";
import {
    AppQrCodeType,
    Optional,
    parseQrCodeResponse,
    QRCode,
    QrCodePersonInfo,
} from "models/general";
import React, { useMemo, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { AppQRCodeService } from "services/business";
import styles from "./AppQrCodeSection.module.scss";

export interface AppQrCodeSectionProps {
    className?: string;
    forArea: AppQrCodeType;
    value: QRCode;
    personInfo: Optional<QrCodePersonInfo>;
    permMap: PermissionAccessMap;
    businessId: string;
}
export const AppQrCodeSection: React.FC<AppQrCodeSectionProps> = ({
    className = "",
    businessId,
    forArea,
    value,
    permMap,
    personInfo,
}) => {
    const { linkProvider } = useRouting();

    const hasEditPermission = permMap.EDIT;
    const readonly = personInfo && personInfo.HasMobileAccount;

    const qrService = new AppQRCodeService(
        linkProvider.business.api.currentBusiness(businessId).qrCodes
    );
    const openDocumentPreviewTab = useOpenDocumentPreview();
    const [state, setState] = useState(value);
    const errorMessage = useMemo(() => {
        if (
            (personInfo && personInfo.HasMobileAccount) ||
            state.IsExpired ||
            state.IsUsed
        ) {
            return t(
                personInfo && personInfo.HasMobileAccount
                    ? "qrCode.personAlreadyRegistered"
                    : "qrCode.codeExpired"
            );
        } else {
            return "";
        }
    }, [state.IsExpired, t, state.IsUsed, personInfo]);

    const { isLoading: refreshing, mutate: refreshCode } = useMutation(
        async (data: { targetEntity: number; id: number }) => {
            return await qrService.refreshCode(
                forArea,
                data.targetEntity,
                data.id
            );
        },
        {
            onSuccess: (res) => {
                if (res.Data) {
                    setState({
                        ...parseQrCodeResponse(res.Data),
                        TargetEntityId: state.TargetEntityId,
                    });
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("qrCode.codeGenerated")}`,
                        "success"
                    );
                } else if (res.Errors) {
                    showUnexpectedErrorToast();
                }
            },
        }
    );
    const { isLoading: sendingEmail, mutate: sendEmail } = useMutation(
        async (id: number) => {
            return await qrService.sendEmail(id);
        },
        {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `'${t("qrCode.messageSent")}`,
                        "success"
                    );
                } else if (res.Errors) {
                    showUnexpectedErrorToast();
                }
            },
        }
    );
    const { isLoading: generatingPdf, mutate: generatePdf } = useMutation(
        async (id: number) => {
            return await qrService.generatePdf(id);
        },
        {
            onSuccess: (res) => {
                if (res.Data) {
                    openDocumentPreviewTab(
                        res.Data,
                        t("qrCode.pdfTitle", { name: personInfo?.Name })
                    );
                    showSweetAlertToast(
                        t("common.success"),
                        t("qrCode.pdfSuccess"),
                        "success"
                    );
                } else if (res.Errors) {
                    showUnexpectedErrorToast();
                }
            },
        }
    );
    const containerMargin = "40px";
    return (
        <div
            className={`${styles.root} ${className}`}
            style={{ position: "relative", height: "100%" }}
        >
            {(refreshing || sendingEmail || generatingPdf) && (
                <AppOverlayLoader fullHeight={false} blur={true} />
            )}
            <div className={`p-4`}>
                <Row>
                    {forArea != AppQrCodeType.BUSINESS && personInfo && (
                        <>
                            <Col
                                md={12}
                                className={"d-flex align-items-center"}
                                style={{
                                    marginBottom: "5px",
                                }}
                            >
                                <Image
                                    className={styles.userIcon}
                                    src={
                                        state.Id > 0
                                            ? ImageAssets.common.userCheckBlue
                                            : ImageAssets.common.userOutlineBlue
                                    }
                                />
                                <div className={styles.userText}>
                                    {personInfo.Name}
                                </div>
                                <div className="ml-auto">
                                    <AppTooltip
                                        content={t(
                                            `qrCode.${
                                                personInfo.HasMobileAccount
                                                    ? "connectedToApp"
                                                    : "notConnectedToApp"
                                            }`
                                        )}
                                        allowHtml={false}
                                        trigger="mouseenter focus"
                                        arrow={true}
                                    >
                                        <Image
                                            className={styles.mobileIcon}
                                            src={
                                                personInfo.HasMobileAccount
                                                    ? ImageAssets.common
                                                          .mobileConnected
                                                    : ImageAssets.common
                                                          .mobileNotConnected
                                            }
                                        />
                                    </AppTooltip>
                                </div>
                            </Col>
                        </>
                    )}
                    <Col
                        md={12}
                        style={{
                            marginBottom: "15px",
                            color: "red",
                            height: "20px",
                        }}
                    >
                        {errorMessage}
                    </Col>
                    <Col
                        md={12}
                        className={
                            "d-flex align-items-center justify-content-center"
                        }
                        style={{ marginBottom: containerMargin }}
                    >
                        {defaults(state.Code, ["", "", ""]).map((x, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && (
                                    <span className={styles.inputFieldDash}>
                                        -
                                    </span>
                                )}
                                <span className={styles.codeText}>{x}</span>
                            </React.Fragment>
                        ))}
                    </Col>
                </Row>
                <div className={styles.mainSection}>
                    <div>
                        <Image
                            className={styles.qrCodeImage}
                            style={!state.QRCodeBase64 ? { padding: 20 } : {}}
                            src={
                                state.QRCodeBase64
                                    ? state.QRCodeBase64
                                    : ImageAssets.common.cameraWithBgTriangle
                            }
                        />
                    </div>

                    <div>
                        <AppDatePicker
                            showLabel={true}
                            placeholder={t("qrCode.validTill")}
                            readOnly={true}
                            value={state.ValidTill}
                            onChange={() => {}}
                            useDefault={false}
                        />
                        <div
                            className={`${styles.actions}`}
                            style={{
                                minHeight: "55px",
                            }}
                        >
                            <Button
                                className={styles.refreshBtn}
                                disabled={
                                    readonly || !hasEditPermission || refreshing
                                }
                                style={{
                                    width: state.Id > 0 ? "140px" : "180px",
                                }}
                                onClick={() =>
                                    refreshCode({
                                        targetEntity: state.TargetEntityId,
                                        id: state.Id,
                                    })
                                }
                            >
                                <div className={styles.refreshText}>
                                    {t(
                                        `qrCode.${
                                            state.Id > 0
                                                ? "refresh"
                                                : "generate"
                                        }`
                                    )}
                                </div>
                                <Image
                                    className={styles.refreshIcon}
                                    src={ImageAssets.common.repeatWhite}
                                />
                            </Button>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "130px",
                                }}
                            >
                                {state.Id > 0 && (
                                    <>
                                        {forArea != AppQrCodeType.BUSINESS && (
                                            <AppCircleIconButton
                                                className={`${styles.btnCircle} `}
                                                onClick={() =>
                                                    sendEmail(state.Id)
                                                }
                                                readonly={
                                                    readonly ||
                                                    state.IsExpired ||
                                                    state.IsUsed
                                                }
                                                iconUrl={
                                                    ImageAssets.common
                                                        .envelopeWhite
                                                }
                                            />
                                        )}
                                        <AppCircleIconButton
                                            className={`${styles.btnCircle} ${styles.pdfBtn}`}
                                            onClick={() =>
                                                generatePdf(state.Id)
                                            }
                                            readonly={
                                                readonly ||
                                                state.IsExpired ||
                                                state.IsUsed
                                            }
                                            iconUrl={
                                                ImageAssets.common.pdfFileWhite
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppQrCodeSection;
