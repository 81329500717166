import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetisMenuContentType } from "models/metisMenu";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { EmployeeType, GeneralUserProfileUpdate } from "models";
import { BusinessType } from "models/business";
import routesForContext from "routing/AppRoutes";
import { businessEmployeeRoutesMap } from "routing/routes/business/employee/routesMap";
import { businessRoutesMap } from "routing/routes/business/routesMap";
import { kindergartenClientRoutesMap } from "routing/routes/kindergarten/client/routesMap";
import { lillywaitClientRoutesMap } from "routing/routes/lillywait/client/routesMap";
import { ImageAssets } from "globals/images";
import { Image } from "react-bootstrap";
import { kindergartenRoutesMap } from "routing/routes/kindergarten/routesMap";

const routesTemp = routesForContext();
export const generalNav = (
    linkProvider: typeof routesTemp,
    relativePathProvider: typeof routesTemp,
    businessType: BusinessType,
    homePath: string,
    hasNoAccess: boolean
): MetisMenuContentType[] => {
    const isLw = businessType === BusinessType.LW;
    const isKindergarten = businessType === BusinessType.KINDERGARTEN;

    return hasNoAccess
        ? [
              {
                  label: t("sidebar.editProfile"),
                  icon: <FontAwesomeIcon icon={"user-cog"} />,
                  activeIfPatternLike: "editProfile",
                  to: linkProvider.authUser.screens.editProfile(),
                  permissions: [GeneralUserProfileUpdate],
              },
          ]
        : [
              {
                  iconClass: "pe-7s-home",
                  label: t("sidebar.home"),
                  to: homePath,
              },
              ...(isLw || isKindergarten
                  ? [
                        {
                            iconClass: "pe-7s-users",
                            label: t(
                                `${
                                    isLw
                                        ? "sidebar.lwClients.title"
                                        : "sidebar.clients"
                                }`
                            ),
                            activeIfPatternLike: isKindergarten
                                ? "/clients"
                                : "/lw-clients",
                            activeIfPatternNotLike: ["time-tracking"],
                            subMenu: isKindergarten
                                ? [
                                      {
                                          label: t(
                                              "sidebar.kindergartenClients.contracts"
                                          ),
                                          activeIfPatternLike: "clients",
                                          activeIfPatternNotLike: [
                                              "registrations",
                                              "time-tracking",
                                          ],
                                          to: linkProvider.kindergarten
                                              .clients()
                                              .screens.client.list(),
                                          permissions: defaultTo(
                                              kindergartenClientRoutesMap[
                                                  relativePathProvider.kindergarten
                                                      .clients()
                                                      .screens.client.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                      {
                                          label: t(
                                              "sidebar.kindergartenClients.registrations"
                                          ),
                                          activeIfPatternLike:
                                              "clients/registrations",
                                          to: linkProvider.kindergarten
                                              .clients()
                                              .screens.registrations.list(),
                                          permissions: defaultTo(
                                              kindergartenClientRoutesMap[
                                                  relativePathProvider.kindergarten
                                                      .clients()
                                                      .screens.registrations.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                  ]
                                : [
                                      // for LW
                                      {
                                          label: t(
                                              "sidebar.lwClients.kindergarten"
                                          ),
                                          to: linkProvider.lillywait
                                              .clients()
                                              .screens.kindergartens.list(),
                                          activeIfPatternLike:
                                              "lw-clients/kindergartens",
                                          permissions: defaultTo(
                                              lillywaitClientRoutesMap[
                                                  relativePathProvider.lillywait
                                                      .clients()
                                                      .screens.kindergartens.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                      {
                                          label: t(
                                              "sidebar.lwClients.industry"
                                          ),
                                          to: linkProvider.lillywait
                                              .clients()
                                              .screens.industries.list(),
                                          activeIfPatternLike:
                                              "lw-clients/industries",
                                          permissions: defaultTo(
                                              lillywaitClientRoutesMap[
                                                  relativePathProvider.lillywait
                                                      .clients()
                                                      .screens.industries.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                      {
                                          label: t("sidebar.lwClients.school"),
                                          activeIfPatternLike:
                                              "lw-clients/schools",
                                          to: linkProvider.lillywait
                                              .clients()
                                              .screens.schools.list(),
                                          permissions: defaultTo(
                                              lillywaitClientRoutesMap[
                                                  relativePathProvider.lillywait
                                                      .clients()
                                                      .screens.schools.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                      {
                                          label: t(
                                              "sidebar.lwClients.elderlyCare"
                                          ),
                                          activeIfPatternLike:
                                              "lw-clients/elderly-cares",
                                          to: linkProvider.lillywait
                                              .clients()
                                              .screens.elderlyCares.list(),
                                          permissions: defaultTo(
                                              lillywaitClientRoutesMap[
                                                  relativePathProvider.lillywait
                                                      .clients()
                                                      .screens.elderlyCares.list()
                                              ].permKeys,
                                              []
                                          ),
                                      },
                                  ],
                        },
                    ]
                  : []),
              {
                  iconClass: "pe-7s-user",
                  label: t("sidebar.employees.title"),
                  activeIfPatternLike: ["/employees", "/suppliers"],
                  activeIfPatternNotLike: ["time-tracking"],
                  subMenu: [
                      {
                          label: t("sidebar.employees.contracts"),
                          to: linkProvider.business
                              .employees(EmployeeType.Employee)
                              .screens.list(),
                          activeIfPatternLike: "/employees",
                          activeIfPatternNotLike: ["time-tracking"],
                          permissions: defaultTo(
                              businessEmployeeRoutesMap[
                                  relativePathProvider.business
                                      .employees()
                                      .screens.list()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.employees.suppliers"),
                          activeIfPatternLike: "/suppliers",
                          activeIfPatternNotLike: ["time-tracking"],
                          to: linkProvider.business
                              .employees(EmployeeType.Supplier)
                              .screens.list(),
                          permissions: defaultTo(
                              businessEmployeeRoutesMap[
                                  relativePathProvider.business
                                      .employees()
                                      .screens.list()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              {
                  iconClass: "pe-7s-edit",
                  label: t("sidebar.toDo"),
                  activeIfPatternLike: "/to-do",
                  to: linkProvider.business.screens.todoManagement.list(),
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.todoManagement.list()
                      ].permKeys,
                      []
                  ),
              },
              {
                  iconClass: "pe-7s-mail",
                  label: t("sidebar.messages"),
                  activeIfPatternLike: "/mailbox",
                  to: linkProvider.business.screens.mailbox.list(),
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.mailbox.list()
                      ].permKeys,
                      []
                  ),
              },
              {
                  iconClass: "pe-7s-chat",
                  label: t("sidebar.chat"),
                  activeIfPatternLike: "/chat",
                  activeIfPatternNotLike: ["/chat-settings"],
                  to: linkProvider.business.screens.chat(),
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.chat()
                      ].permKeys,
                      []
                  ),
              },
              {
                  iconClass: "pe-7s-date",
                  label: t("sidebar.calendar"),
                  to: linkProvider.business.screens.calendar(),
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.calendar()
                      ].permKeys,
                      []
                  ),
              },
              {
                  iconClass: "pe-7s-timer",
                  label: t("sidebar.timeManagement.title"),
                  activeIfPatternLike: "/time-tracking",
                  subMenu: [
                      ...(isKindergarten
                          ? [
                                {
                                    label: t("sidebar.clients"),
                                    to: linkProvider.business.screens.timeTracking.clientView(),
                                    permissions: defaultTo(
                                        businessRoutesMap[
                                            relativePathProvider.business.screens.timeTracking.clientView()
                                        ].permKeys,
                                        []
                                    ),
                                },
                            ]
                          : []),
                      {
                          label: t("sidebar.timeManagement.employees"),
                          to: linkProvider.business.screens.timeTracking.employeeView(),

                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.timeTracking.employeeView()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.timeManagement.suppliers"),
                          to: linkProvider.business.screens.timeTracking.supplierView(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.timeTracking.supplierView()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              {
                  iconClass: "pe-7s-date",
                  label: t("sidebar.shiftPlan.title"),
                  activeIfPatternLike: "/shift-plan",
                  subMenu: [
                      {
                          label: t("sidebar.shiftPlan.employees"),
                          to: linkProvider.business.screens.shiftPlan.main(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.shiftPlan.main()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.shiftPlan.shifts"),
                          to: linkProvider.business.screens.shiftPlan.shifts(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.shiftPlan.shifts()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.shiftPlan.groups"),
                          to: linkProvider.business.screens.shiftPlan.groups(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.shiftPlan.groups()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              {
                  iconClass: "pe-7s-calculator",
                  label: t("sidebar.payroll.title"),
                  activeIfPatternLike: "/payroll",
                  subMenu: [
                      {
                          label: t("sidebar.payroll.additionalBenefits"),
                          to: linkProvider.business.screens.payroll.additionalBenefits(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.payroll.additionalBenefits()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.payroll.absences"),
                          to: linkProvider.business.screens.payroll.absences(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.payroll.absences()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              ...(!isKindergarten
                  ? []
                  : [
                        {
                            iconClass: "pe-7s-note2",
                            label: t("sidebar.reports.title"),
                            activeIfPatternLike: "/reports",
                            subMenu: [
                                {
                                    label: t("sidebar.reports.occupancy"),
                                    to: linkProvider.kindergarten.screens.occupancyPlanning(),
                                    permissions: defaultTo(
                                        kindergartenRoutesMap[
                                            relativePathProvider.kindergarten.screens.occupancyPlanning()
                                        ].permKeys,
                                        []
                                    ),
                                },
                                {
                                    label: t("sidebar.reports.ageGroupPlaning"),
                                    to: linkProvider.kindergarten.screens.ageGroupPlanning(),
                                    permissions: defaultTo(
                                        kindergartenRoutesMap[
                                            relativePathProvider.kindergarten.screens.ageGroupPlanning()
                                        ].permKeys,
                                        []
                                    ),
                                },
                            ],
                        },
                    ]),
              {
                  iconClass: "lnr-chart-bars",
                  label: t("sidebar.accounting.title"),
                  activeIfPatternLike: "/accounting",
                  subMenu: [
                      {
                          label: t("sidebar.accounting.creditors"),
                          to: linkProvider.business.screens.creditors.list(),
                          activeIfPatternLike: "/creditors",
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.creditors.list()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
          ];
};
