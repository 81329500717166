export enum MissingDayType {
    PERSON_VACATION = "PERSON_VACATION",
    SCHOOL_DAY = "SCHOOL_DAY",
    DISEASE_DAY = "DISEASE_DAY",
    NO_REASON = "NO_REASON",
    PREGNANT = "PREGNANT",
}
export enum AbsentsTableSortColumn {
    DisplayId = "DisplayId",
    Name = "Name",
    TotalSchoolDays = "TotalSchoolDays",
    TotalVacationDays = "TotalVacationDays",
    TotalSickDays = "TotalSickDays",
    TotalUndeclaredAbsents = "TotalUndeclaredAbsents",
    TotalWorkingDays = "TrueTotalWorkingDays",
    TotalAttendances = "TotalAttendances",
    TotalAbsences = "TotalAbsences",
    AbsencesWithoutVacations = "AbsencesWithoutVacations",
    AbsencePercentage = "AbsencePercentage",
}
