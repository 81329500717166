import { AppCustomHeaderTable } from "components/Table";
import { defaultTo } from "lodash";
import { TimeTrackingRecord, TimeTrackingRequestParameters } from "models";
import React from "react";
import { TimeManagementState } from "screens/business/timeTracking/TimeManagementMain";
import {
    TimeManagementDateRangeHeader,
    TimeManagementDateRangeTable,
} from "../partials";
import styles from "./TimeManagementDateRangeView.module.scss";

export interface TimeManagementDateRangeViewProps {
    values: TimeManagementState;
    onFilterChange: (filter: TimeTrackingRequestParameters) => void;
}
export const TimeManagementDateRangeView: React.FC<
    TimeManagementDateRangeViewProps
> = ({ values, onFilterChange }) => {
    const { filterRequest, records } = values;

    const getPersons = () => {
        return records
            ? records.filter((y) =>
                  y.PersonName.trim()
                      .toLowerCase()
                      .includes(
                          defaultTo(
                              filterRequest.SearchValue?.trim().toLowerCase(),
                              ""
                          )
                      )
              )
            : ([] as TimeTrackingRecord[]);
    };
    return (
        <AppCustomHeaderTable
            className={styles.tableRoot}
            header={
                <TimeManagementDateRangeHeader
                    // className={styles.root}
                    type={filterRequest.UserType}
                    searchValue={filterRequest.SearchValue}
                    onSearchValueChangeHandler={(val) =>
                        onFilterChange({
                            ...filterRequest,
                            SearchValue: val,
                        })
                    }
                />
            }
            content={
                <TimeManagementDateRangeTable
                    // className={styles.root}
                    record={getPersons()}
                    userType={filterRequest.UserType}
                    onClick={(val) => {
                        onFilterChange({
                            ...filterRequest,
                            PersonId: val,
                            PersonName: null,
                            SelectedId: null,
                        });
                    }}
                />
            }
        />
    );
};

export default TimeManagementDateRangeView;
