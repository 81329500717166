import { StartAndEndDateFields } from "commonPartials/StartAndEndDateFields";
import { AppContainer, AppContainerFooter } from "components/Containers";
import { AppInputField } from "components/FormFields";
import { AppLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { v4 as uuid } from "uuid";
import {
    EmpKeyTemplateField,
    getEmpKeyTemplateFromResponse,
    EmpKeyTemplate,
    getDefaultEmpKeyTemplate,
    validateEmpKeyTemplate,
    EmpKeyTemplateResponse,
} from "models";
import { Business } from "models/business";
import {
    AppResponse,
    DateRangeRequest,
    Optional,
    ValidityStateManager,
} from "models/general";
import { PermissionAccessMap } from "models/permissionManagement/model";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { businessSettingRoutes } from "routing/routes/business/settings";
import {
    EmpKeyTemplateService,
    getEmpKeyTemplateServiceKey,
    getLocationBusinessOptionsServiceKey,
    LocationBusinessOptionsService,
} from "services";
import { EmpKeyTemplateFieldsContainer } from "./partials/createEditPartials";

const basePath = businessSettingRoutes().screens.careScopes;
type PathBase = typeof basePath;
export interface EmpKeyTemplateCreateEditProps {
    id: Optional<string>;
    currentBusiness: Optional<Business>; // null for location-setting views
    locationId?: number; // for lwc it will be lwc location-setting id
    basePathProvider: PathBase;
    createOptions?: { clone: boolean; followup: boolean };
    permissionsMap: PermissionAccessMap;
    isLwc?: boolean;
    useLocationData?: boolean;
}

export const EmpKeyTemplateCreateEdit: React.FC<
    EmpKeyTemplateCreateEditProps
> = ({
    id,
    currentBusiness,
    locationId,
    useLocationData,
    createOptions = { clone: false, followup: false },
    permissionsMap,
    isLwc,
    basePathProvider,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const navigate = useNavigate();
    const { encodedId } = useSessionBusiness();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [state, setState] = useState<EmpKeyTemplate | undefined>(
        !isNil(id)
            ? undefined
            : getDefaultEmpKeyTemplate(
                  isNil(currentBusiness) ? locationId : undefined,
                  currentBusiness?.Id
              )
    );
    const templateService = new EmpKeyTemplateService(
        linkProvider.api.empKeyTemplates(
            locationId ? (locationId as number) : 0,
            currentBusiness?.Id
        )
    );

    const optionsService = new LocationBusinessOptionsService(
        // for location templates set currBusiness to null so it only fetches option for location
        linkProvider.api.locationBusinessOptions(
            locationId ? (locationId as number) : 0,
            state?.LocationSettingId ? undefined : currentBusiness?.Id
        )
    );

    const {
        isLoading: loadingAgeGroups,
        refetch: refetchAgeGroups,
        data: ageGroups,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getAgeGroupOptions", {
            businessId: state?.LocationSettingId ? null : currentBusiness?.Id,
            locationId: locationId,
            start: state?.StartDate,
            end: state?.EndDate,
        }),
        async () => {
            if (state) {
                return await optionsService.getAgeGroupOptions({
                    StartDate: state?.StartDate,
                    EndDate: state?.EndDate,
                } as DateRangeRequest);
            }
        },
        {
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    const {
        isLoading: loadingCareScopes,
        refetch: refetchCareScopes,
        data: careScopes,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getCareScopeOptions", {
            businessId: currentBusiness?.Id,
            locationId: locationId,
            start: state?.StartDate,
            end: state?.EndDate,
        }),
        async () => {
            if (state) {
                return await optionsService.getCareScopeOptions({
                    StartDate: state?.StartDate,
                    EndDate: state?.EndDate,
                } as DateRangeRequest);
            }
        },
        {
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    const {
        isLoading: loadingSurcharges,
        refetch: refetchSurcharges,
        data: surCharges,
    } = useQuery(
        getLocationBusinessOptionsServiceKey("getSurchargeOptions", {
            businessId: currentBusiness?.Id,
            locationId: locationId,
            start: state?.StartDate,
            end: state?.EndDate,
        }),
        async () => {
            if (state) {
                return await optionsService.getSurchargeOptions({
                    StartDate: state?.StartDate,
                    EndDate: state?.EndDate,
                } as DateRangeRequest);
            }
        },
        {
            select: (resp) => {
                return resp?.Data ? resp.Data : [];
            },
        }
    );
    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: templateRes,
    } = useQuery(
        getEmpKeyTemplateServiceKey("getDetails", {
            businessId: currentBusiness?.Id,
            locationId: locationId,
            id: id,
        }),
        async () => {
            if (id) {
                return await templateService.getDetails(
                    id,
                    createOptions.clone,
                    createOptions.followup,
                    !isNil(currentBusiness) && createOptions.followup
                        ? true
                        : false
                );
            }
        },
        {
            cacheTime: 0,
            select: (resp) => {
                if (resp?.Data) {
                    return getEmpKeyTemplateFromResponse(resp.Data);
                } else if (!isNil(id)) {
                    showErrorPage(404);
                }
            },
        }
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && templateRes && !state) {
            setState(templateRes);
        }
    }, [getLoading, getRefetching]);
    useEffect(() => {
        if (!getLoading && !getRefetching && state?.StartDate) {
            refetchAgeGroups();
            refetchCareScopes();
            refetchSurcharges();
            if (loaded) {
                setLoaded(false);
            }
        }
    }, [state?.StartDate, state?.EndDate]);

    useEffect(() => {
        if (
            !loadingAgeGroups &&
            !loadingCareScopes &&
            !loadingSurcharges &&
            ageGroups &&
            careScopes &&
            surCharges &&
            !loaded
        ) {
            // update existing fields according to the current options
            const newState = state
                ? { ...state }
                : getDefaultEmpKeyTemplate(
                      isNil(currentBusiness) ? locationId : undefined,
                      currentBusiness?.Id
                  );

            // remove fields for which options are no longer available
            const toRemove = defaultTo(newState?.Fields, [])
                .filter((f) => {
                    return (
                        !ageGroups.some((s) => s.Uuid == f.AgeGroupFieldUuid) ||
                        (f.SurchargeFieldUuid &&
                            !surCharges.some(
                                (s) => s.Uuid == f.SurchargeFieldUuid
                            )) ||
                        (f.CareScopeFieldUuid &&
                            !careScopes.some(
                                (s) => s.Uuid == f.CareScopeFieldUuid
                            ))
                    );
                })
                .map((f) => f.Uuid);
            const newFields = defaultTo(newState?.Fields, []).filter(
                (x) => !toRemove.includes(x.Uuid)
            );

            ageGroups.map((ag) => {
                careScopes.map((cS) => {
                    const uuidMatched = newFields.some(
                        (f) =>
                            f.AgeGroupFieldUuid == ag.Uuid &&
                            f.CareScopeFieldUuid == cS.Uuid
                    );
                    if (!uuidMatched) {
                        // UUID not matched, so need to add new field with currently active age-group/care-scopes
                        let fieldExist: EmpKeyTemplateField | undefined;
                        // if field exist with matching short-names, then copy its value for new field
                        const matchingFields = newFields.filter(
                            (f) =>
                                f.AgeGroupShortName == ag.ShortName &&
                                f.CareScopeShortName == cS.ShortName
                        );

                        if (matchingFields.length > 0) {
                            fieldExist = matchingFields.at(
                                matchingFields.length - 1
                            );
                        }

                        // add field if does not exist
                        newFields.push({
                            Uuid: uuid(),
                            AgeGroupFieldUuid: ag.Uuid,
                            EmpKeyCalculationId: newState.Id,
                            CareScopeFieldUuid: cS.Uuid,
                            EmpKey: fieldExist ? fieldExist.EmpKey : 0,
                        } as EmpKeyTemplateField);
                    }
                });
                surCharges.map((s) => {
                    const uuidMatched = newFields.some(
                        (f) =>
                            f.AgeGroupFieldUuid == ag.Uuid &&
                            f.SurchargeFieldUuid == s.Uuid
                    );
                    if (!uuidMatched) {
                        // UUID not matched, so need to add new field with currently active age-group/care-scopes
                        let fieldExist: EmpKeyTemplateField | undefined;
                        // if field exist with matching short-names, then copy its value for new field
                        const matchingFields = newFields.filter(
                            (f) =>
                                f.AgeGroupShortName == ag.ShortName &&
                                f.SurchargeShortName == s.ShortName
                        );

                        if (matchingFields.length > 0) {
                            fieldExist = matchingFields.at(
                                matchingFields.length - 1
                            );
                        }

                        // add field if does not exist
                        newFields.push({
                            Uuid: uuid(),
                            AgeGroupFieldUuid: ag.Uuid,
                            EmpKeyCalculationId: newState.Id,
                            SurchargeFieldUuid: s.Uuid,
                            EmpKey: fieldExist ? fieldExist.EmpKey : 0,
                        } as EmpKeyTemplateField);
                    }
                });
            });

            setState({
                ...newState,
                Fields: newFields,
            });
            if (!loaded) {
                setLoaded(true);
            }
        }
    }, [loaded, state, loadingAgeGroups, loadingCareScopes, loadingSurcharges]);

    const {
        isLoading: editLoading,
        mutate: edit,
        data: editRes,
    } = useMutation(async () => {
        if (state) {
            return await templateService.edit(state);
        }
    });
    const {
        isLoading: createLoading,
        mutate: create,
        data: createRes,
    } = useMutation(async () => {
        if (state) {
            return await templateService.create(state);
        }
    });

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            state ? validateEmpKeyTemplate(state) : undefined
        );
    }, [state, appLocale]);

    const { title, minStartDate, canEdit, isParentTemplate } = useMemo(() => {
        const isParent =
            isNil(state?.FollowUpBusinessParentId) &&
            isNil(state?.FollowUpLocationParentId);
        const isForLocation = !isNil(state?.LocationSettingId);
        return {
            canEdit:
                isNil(currentBusiness) || // location-setting view
                (!isNil(currentBusiness) && !isForLocation && !useLocationData), // can edit only non-location templates in business settings
            isParentTemplate: isParent,
            minStartDate:
                state &&
                (state.FollowUpBusinessParentId ||
                    state.FollowUpLocationParentId) &&
                !isNil(state.ParentEndDate)
                    ? state.ParentEndDate.clone()
                          .add(1, "month")
                          .startOf("month")
                    : undefined,
            title:
                state && state.Id > 0
                    ? state.Name
                    : isParent
                    ? t("templates.createTemplate")
                    : t("templates.addFollowUpTemplate", {
                          name: state?.Name,
                      }),
        };
    }, [state?.Name, state]);

    const handleResponse = (
        response: AppResponse<EmpKeyTemplateResponse>,
        isEdit: boolean = false
    ) => {
        if (response.Data) {
            showSweetAlertToast(
                t("common.success"),
                `"${response.Data.Name}" ${t(
                    `common.actions.${
                        isEdit ? "updatedSuccessfully" : "createdSuccessfully"
                    }`
                )}`,
                "success"
            );

            queryClient.invalidateQueries(
                getEmpKeyTemplateServiceKey("getTemplates", {
                    businessId: currentBusiness?.Id,
                    locationId: locationId,
                    sessionBusinessId: encodedId,
                })
            );

            navigate(basePathProvider.list());
        } else if (response.Message) {
            showSweetAlertToast(
                t("common.error.error"),
                response.Message,
                "error"
            );
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t(
                    `common.actions.errors.${
                        isEdit ? "unableToUpdate" : "unableToCreate"
                    }`
                ),
                "error"
            );
        }
    };
    useEffect(() => {
        if (!editLoading && editRes) {
            handleResponse(editRes, true);
        }
    }, [editLoading, editRes]);
    useEffect(() => {
        if (!createLoading && createRes) {
            handleResponse(createRes);
        }
    }, [createLoading, createRes]);

    const readonly = !(
        canEdit && (!isNil(id) ? permissionsMap.EDIT : permissionsMap.CREATE)
    );

    return (
        <div style={{ position: "relative" }}>
            {!state || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={true}
                    onBack={() => navigate(basePathProvider.list())}
                    title={title}
                    hasInnerScroll={true}
                    footer={
                        !readonly ? (
                            <AppContainerFooter
                                primaryButtonProps={{
                                    disabled:
                                        !validityStateManager.isStateValid() ||
                                        editLoading ||
                                        createLoading,
                                    onClick: () => {
                                        if (!readonly) {
                                            if (state.Id > 0) {
                                                // edit
                                                edit();
                                            } else {
                                                create();
                                            }
                                        }
                                    },
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Row>
                        <Col md={6}>
                            <AppInputField
                                label={t("templates.templateName.name")}
                                value={state.Name}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        Name: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Name"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <AppInputField
                                label={t("templates.templateShortName.name")}
                                value={state.ShortName}
                                readOnly={readonly || !isParentTemplate}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        ShortName: defaultTo(val, ""),
                                    })
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "ShortName"
                                )}
                            />
                        </Col>
                        <StartAndEndDateFields
                            requiredEndDate={
                                state.Id > 0 && !state.CanSetEndDateNull
                            }
                            minStartDate={minStartDate}
                            useMonthPicker={true}
                            appendToBody={true}
                            readonly={readonly}
                            value={{
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            }}
                            onChange={({
                                StartDate: StartDate,
                                EndDate: EndDate,
                            }) => {
                                setState({
                                    ...state,
                                    StartDate: StartDate,
                                    EndDate: EndDate,
                                });
                            }}
                        />
                    </Row>
                    {loadingAgeGroups ||
                    loadingCareScopes ||
                    !loaded ||
                    loadingSurcharges ? (
                        <AppLoader />
                    ) : (
                        <EmpKeyTemplateFieldsContainer
                            onChange={(newFields) => {
                                setState({
                                    ...state,
                                    Fields: newFields,
                                });
                            }}
                            ageGroups={defaultTo(ageGroups, [])}
                            careScopes={defaultTo(careScopes, [])}
                            subCharges={defaultTo(surCharges, [])}
                            validityState={validityStateManager.state}
                            fields={state.Fields}
                            readonly={readonly}
                        />
                    )}
                </AppContainer>
            )}
        </div>
    );
};

export default EmpKeyTemplateCreateEdit;
