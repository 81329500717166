import { AppResponse } from "models/general/response";
import {
    UserCreateEditRequest,
    UserListRequest,
} from "models/userManagement/request";
import { UserRoleManagementItemResponse } from "models/userManagement/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IUsersRoleManagementService {
    create: (
        user: UserCreateEditRequest
    ) => Promise<AppResponse<UserRoleManagementItemResponse>>;
    update: (
        user: UserCreateEditRequest
    ) => Promise<AppResponse<UserRoleManagementItemResponse>>;
    getUserList: (
        reqParams: UserListRequest
    ) => Promise<AppResponse<UserRoleManagementItemResponse[]>>;
    delete: (params: {
        userId: string;
        roleId: string;
    }) => Promise<AppResponse<boolean>>;
}

const apiLinkProvider = routesForContext().business.api.userManagement;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getUsersRoleManagementServiceKey(
    name: keyof IUsersRoleManagementService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `usersRoleManagement_${name}_${JSON.stringify(data)}`;
}
export class UsersRoleManagementService
    extends BaseService
    implements IUsersRoleManagementService
{
    public routes: ApiLinkProviderType;
    constructor(routes: ApiLinkProviderType) {
        super();
        this.routes = routes;
    }
    getUserList(
        reqParams: UserListRequest
    ): Promise<AppResponse<UserRoleManagementItemResponse[]>> {
        return this.doServerXHR<AppResponse<UserRoleManagementItemResponse[]>>({
            url: this.routes.list(reqParams),
            method: "get",
        });
    }

    create(
        user: UserCreateEditRequest
    ): Promise<AppResponse<UserRoleManagementItemResponse>> {
        return this.doServerXHR<UserRoleManagementItemResponse>({
            url: this.routes.create(),
            method: "post",
            data: user,
        });
    }

    update(
        user: UserCreateEditRequest
    ): Promise<AppResponse<UserRoleManagementItemResponse>> {
        return this.doServerXHR<UserRoleManagementItemResponse>({
            url: this.routes.update(),
            method: "post",
            data: user,
        });
    }

    delete(params: {
        userId: string;
        roleId: string;
    }): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.delete(params),
            method: "post",
        });
    }
}

export default UsersRoleManagementService;
