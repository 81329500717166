import { useAppContext } from "hoc/providers/AppContextProvider";
import { useAuthenticatedPageContext } from "hoc/providers/AuthenticatedPageContextProvider";
import {
    useSessionBusiness,
    useSessionBusinessContractInfo,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { defaultTo } from "lodash-es";
import { BusinessType } from "models/business/enum";
import {
    BusinessAdminPermissions,
    BusinessGeneralPermissions,
    constructAllowedPermissionObject,
    createPermissionMap,
    extractActiveRolePermissions,
    LWAdminPermissions,
    LWGeneralPermissions,
    LWSupportPermissions,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { PermissionAccessMap } from "models/permissionManagement/model";
import { useCallback, useMemo } from "react";

// for checking permission in active role of session business
export const useCheckPermission = () => {
    const { isAuthenticated } = useSessionUser();
    const { userInfo, supportViewData } = useAppContext();
    const { sessionBusiness, isLw } = useSessionBusiness();
    const { currentContract } = useSessionBusinessContractInfo();
    const permissions = useMemo(() => {
        return extractActiveRolePermissions(isAuthenticated ? userInfo : null);
    }, [isAuthenticated, userInfo]);

    const { requiredModules, requiredModuleRootCheck } =
        useAuthenticatedPageContext();

    const hasFeatureAccess =
        !isLw && defaultTo(requiredModules, []).length > 0
            ? defaultTo(currentContract?.ContractModules, []).length > 0
                ? currentContract?.ContractModules?.some((c) =>
                      requiredModules?.includes(c)
                  )
                : false
            : true;

    const getPermissionMap = useCallback(
        (identifier: string | string[]): PermissionAccessMap => {
            if ("string" === typeof identifier) {
                identifier = [identifier];
            }
            const permission =
                !supportViewData || !supportViewData.isSupportViewEnabled
                    ? permissions.filter((x) =>
                          identifier.includes(x.Identifier)
                      )
                    : constructAllowedPermissionObject(identifier);
            return createPermissionMap(permission);
        },
        [permissions, supportViewData]
    );
    const checkPermission = useCallback(
        (
            identifiers: string | string[],
            permissionAccessType: PermissionAccessTypes[] = [
                PermissionAccessTypes.SHOW,
            ]
        ): boolean => {
            const permMap = getPermissionMap(identifiers);
            return permissionAccessType.some((p) => permMap[p]);
        },
        [getPermissionMap]
    );
    const userAccessInfo = useCallback(() => {
        const isSupportViewEnabled = defaultTo(
            supportViewData?.isSupportViewEnabled,
            false
        );
        const businessType = defaultTo(
            sessionBusiness?.Type,
            BusinessType.KINDERGARTEN
        ) as BusinessType;

        const hasBusinessGeneralPermissions =
            isSupportViewEnabled || checkPermission(BusinessGeneralPermissions);

        const hasLwGeneralPermissions =
            !isSupportViewEnabled &&
            hasBusinessGeneralPermissions &&
            businessType === BusinessType.LW &&
            checkPermission(LWGeneralPermissions);

        const hasLwAdminPermissions =
            hasLwGeneralPermissions && checkPermission(LWAdminPermissions);
        const hasBusinessAdminPermissions = checkPermission(
            BusinessAdminPermissions
        );

        return {
            isSupportViewEnabled: isSupportViewEnabled,
            supportBusinessType: isSupportViewEnabled
                ? supportViewData?.actualSessionBusinessInfo.SessionBusiness
                      .Type
                : undefined,
            hasBusinessGeneralPermissions: hasBusinessGeneralPermissions,
            hasBusinessAdminPermissions: hasBusinessAdminPermissions,
            hasLwGeneralPermissions: hasLwGeneralPermissions,
            hasLwAdminPermissions: hasLwAdminPermissions,
            hasSupportViewPermissions:
                (isSupportViewEnabled || hasLwAdminPermissions) &&
                checkPermission(LWSupportPermissions),
        };
    }, [checkPermission]);

    return {
        checkPermission,
        moduleAccess: {
            hasAccess: defaultTo(hasFeatureAccess, false),
            showOnRoot: requiredModuleRootCheck,
        },
        getPermissionMap,
        userAccessInfo,
    };
};

export default useCheckPermission;
