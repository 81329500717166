import { ImageAssets } from "globals/images";
import { FeatureHelpItem } from "models/applicationFeatures";
import { Optional } from "models/general";
import React, { useEffect } from "react";
import { Animated } from "react-animated-css";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FeatureHelpDetailItem } from "../FeatureHelpDetailItem";
import { FeatureHelpViewThumbnail } from "../FeatureHelpViewThumbnail";
import styles from "./FeatureHelpSideBarMenuDialog.module.scss";

export interface FeatureHelpSideBarMenuDialogProps {
    listOfVideos: FeatureHelpItem[];
    listOfExplanation: FeatureHelpItem[];
    open: boolean;
    onSelectItem: (value: Optional<FeatureHelpItem>) => void;
    onClose: () => void;
}
const Component: React.FC<FeatureHelpSideBarMenuDialogProps> = ({
    onClose,
    onSelectItem,
    open,
    listOfExplanation,
    listOfVideos,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        // to close video on dialog close
        if (!open) {
            onSelectItem(null);
        }
    }, [open]);
    return (
        <div className={styles.sideMenu}>
            {/* @ts-ignore */}
            <Animated
                animationIn="slideInRight"
                animationOut="slideOutRight"
                animationInDuration={400}
                animationOutDuration={400}
                isVisible={open}
                className={styles.animated}
                animateOnMount={false}
            >
                <div className={styles.closeBtn} onClick={() => onClose()}>
                    <Image src={ImageAssets.common.crossBlue} />
                </div>
                {listOfVideos && listOfVideos.length > 0 && (
                    <>
                        <div
                            className={styles.header}
                            style={{
                                borderTop: 0,
                            }}
                        >
                            <span>{t("helpItem.explanatoryVideo")}</span>
                        </div>
                        <div className={styles.overFlowContainer}>
                            <div className={styles.videosContainer}>
                                {listOfVideos.map((detail, index) => (
                                    <FeatureHelpViewThumbnail
                                        key={detail.Id}
                                        value={detail}
                                        onSelectItem={() =>
                                            onSelectItem(detail)
                                        }
                                        isLast={
                                            index == listOfVideos.length - 1
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
                {listOfExplanation && listOfExplanation.length > 0 && (
                    <>
                        <div className={styles.header}>
                            <span>{t("helpItem.fieldsExplained")}</span>
                        </div>
                        <div className={styles.overFlowContainer}>
                            {listOfExplanation.map((detail) => (
                                <FeatureHelpDetailItem
                                    key={detail.Id}
                                    value={detail}
                                    onSelectItem={() => onSelectItem(detail)}
                                />
                            ))}
                        </div>
                    </>
                )}
            </Animated>
        </div>
    );
};

Component.displayName = "FeatureHelpSideBarMenuDialog";
export const FeatureHelpSideBarMenuDialog = React.memo(Component);
export default React.memo(Component);
