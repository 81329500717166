import { AppCheckbox } from "components/FormFields";
import {
    ENGLISH_DATE_FORMAT,
    GERMAN_DATE_FORMAT,
    GERMAN_SHORT_YEAR_DATE_FORMAT,
    Hyphenated_Date_Format,
    Hyphenated_ShortDate_Format,
    US_DATE_FORMAT,
    US_SHORT_YEAR_DATE_FORMAT,
} from "globals/constants";
import { isNil } from "lodash-es";
import { SelectItem } from "models/general";
import React from "react";
import styles from "./ImportDataDateFormatPicker.module.scss";

export interface ImportDataDateFormatPickerProps {
    onChange: (dateFormat: string) => void;
    value?: string;
    readonly?: boolean;
}

export const ImportDateFormats: SelectItem[] = [
    {
        Text: US_DATE_FORMAT,
        Value: "MM/dd/yyyy",
    },
    {
        Text: US_SHORT_YEAR_DATE_FORMAT,
        Value: "MM/dd/yy",
    },
    {
        Text: ENGLISH_DATE_FORMAT,
        Value: "M/d/yyyy",
    },
    {
        Text: GERMAN_DATE_FORMAT,
        Value: `dd.MM.yyyy`,
    },
    {
        Text: GERMAN_SHORT_YEAR_DATE_FORMAT,
        Value: `dd.MM.yy`,
    },
    {
        Text: Hyphenated_Date_Format,
        Value: "yyyy-M-d",
    },
    {
        Text: Hyphenated_ShortDate_Format,
        Value: "yyyy-MM-dd",
    },
    {
        Text: `${Hyphenated_Date_Format} HH:mm:ss.fffffff`,
        Value: "yyyy-M-d HH:mm:ss.fffffff",
    },
];

export const ImportDataDateFormatPicker: React.FC<
    ImportDataDateFormatPickerProps
> = ({ value, onChange, readonly = false }) => {
    return (
        <div className={styles.root}>
            {ImportDateFormats.map((x, idx) => (
                <AppCheckbox
                    key={`dateFormat_${idx}`}
                    id={`dateFormat_${idx}`}
                    checked={value == x.Value}
                    disabled={readonly}
                    onChange={(e) => {
                        // const checked = e.target.checked;
                        if (!isNil(x.Value)) {
                            onChange(x.Value as string);
                        }
                    }}
                    label={x.Text}
                />
            ))}
        </div>
    );
};

export default ImportDataDateFormatPicker;
