import { mode } from "crypto-js";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    BusinessCourseCreateEditRequest,
    BusinessCourseResponse,
    BusinessCourseRequestParameters,
    BusinessCourse,
} from "models/businessCourses";
import { AppResponse } from "models/general";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";
import { json } from "stream/consumers";

export interface IBusinessCoursesService {
    list(
        request: BusinessCourseRequestParameters
    ): Promise<AppResponse<BusinessCourseResponse[]>>;
    createUpdate(
        model: BusinessCourseCreateEditRequest
    ): Promise<AppResponse<BusinessCourseResponse>>;
    get(id: string): Promise<AppResponse<BusinessCourseResponse>>;
    delete(id: number): Promise<AppResponse<number>>;
}

const apiLinkProvider =
    routesForContext().business.api.currentBusiness().courses;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessCoursesServiceKey(
    name: keyof IBusinessCoursesService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessCourses_${name}_${JSON.stringify(data)}`;
}
export class BusinessCoursesService
    extends BaseService
    implements IBusinessCoursesService
{
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    list(): Promise<AppResponse<BusinessCourseResponse[]>> {
        return this.doServerXHR<BusinessCourseResponse[]>({
            url: this.routes.list(),
            method: "get",
        });
    }
    constructFormData(model: BusinessCourseCreateEditRequest) {
        const ob = {
            ...model,
            StartDate: model.StartDate?.format(ISO8601_DATE_FORMAT),
            EndDate: model.EndDate
                ? model.EndDate.format(ISO8601_DATE_FORMAT)
                : undefined,
        };
        const fd = new FormData();
        if (model.fileRequest) {
            fd.append("file", model.fileRequest);
        }
        fd.append("Data", JSON.stringify(ob));
        return fd;
    }
    createUpdate(
        model: BusinessCourseCreateEditRequest
    ): Promise<AppResponse<BusinessCourseResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<BusinessCourseResponse>({
            url: this.routes.createUpdate(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    get(id?: string): Promise<AppResponse<BusinessCourseResponse>> {
        return this.doServerXHR<BusinessCourseResponse>({
            url: this.routes.get(id),
            method: "get",
        });
    }
    delete(id?: number): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.routes.delete(id),
            method: "post",
        });
    }
}

export default BusinessCoursesService;
