import { LocationSettingSortColumn } from "./enum";
import { LocationSettingListItem } from "./model";

export const getLocationSettingSortColumnKeyFromEnum = (
    sortColumn: LocationSettingSortColumn | undefined
): keyof LocationSettingListItem => {
    switch (sortColumn) {
        case LocationSettingSortColumn.State:
            return `State`;
        case LocationSettingSortColumn.ForBusiness:
            return `ForBusiness`;
        case LocationSettingSortColumn.Country:
            return `Country`;
        default:
            return `Name`;
    }
};
