import { AppLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { DocumentTemplateService } from "services/business";
import styles from "./DocumentTemplateClone.module.scss";

export interface DocumentTemplateCloneProps {
    templateId: number;
    readonly: boolean;
}
export const DocumentTemplateClone: React.FC<DocumentTemplateCloneProps> = ({
    templateId,
    readonly,
}) => {
    const { t } = useTranslation();

    const { linkProvider } = useRouting();

    const documentTemplateService = new DocumentTemplateService(
        linkProvider.business.api.currentBusiness().documentTemplate
    );

    const {
        isLoading: cloneLoading,
        data: response,
        mutate: cloneLocationTemplate,
    } = useMutation(
        async (templateId: number) =>
            await documentTemplateService.cloneLocationTemplate(templateId),
        {
            onSuccess: () => {
                showSweetAlertToast(
                    t("templateDocumentManager.cloneSuccess"),
                    "",
                    "success"
                );
            },
        }
    );

    return (
        <>
            {cloneLoading ? (
                <AppLoader fullHeight={false} iconClass={styles.loader} />
            ) : (
                <Image
                    className={`${styles.cloneImage} ${
                        readonly ? "disabled" : ""
                    }`}
                    onClick={() => {
                        if (!readonly) {
                            cloneLocationTemplate(templateId);
                        }
                    }}
                    src={ImageAssets.common.inboxIn}
                />
            )}
        </>
    );
};

export default DocumentTemplateClone;
