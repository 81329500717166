import {
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from "@microsoft/signalr";
import { config } from "globals/config";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useCallback, useEffect } from "react";

const handleException = (e: any, retry?: () => void) => {
    console.debug("Catched signal-R error:\n", e);
    if (retry) {
        console.debug('Unable to connect with events-signalR, retrying after 10sec');
        setTimeout(() => {
            retry();
        }, 10000);
    }
}

export function useWebEventHub() {
    const { signalREventInstance, setSignalREventInstance } = useAppContext();

    const { id: businessId } = useSessionBusiness();
    const configure = () => {
        const eventsHubConnection = new HubConnectionBuilder()
            .withUrl(`${config.serverBaseUrl}/signal-r/web-events`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
        const startConnection = () =>
            eventsHubConnection.start().catch(e => {
                handleException(e, () => startConnection())
            });

        eventsHubConnection.onreconnecting(() =>
            console.debug("Re-connecting events signal-R.")
        );
        eventsHubConnection.onreconnected(() =>
            console.debug("Re-connected events signal-R successfully.")
        );
        eventsHubConnection.onclose(() => {
            setTimeout(() => {
                // reconnect again after 5sec because we need to continuously listen for notifications
                startConnection();
            }, 5000);
            console.debug("Disconnected events SignalR. Retrying after 5sec.");
        });

        startConnection();
        setSignalREventInstance(eventsHubConnection);
    };

    const dispose = useCallback(() => {
        if (signalREventInstance) {
            signalREventInstance.stop().catch(handleException);
        }
        console.debug('Events signal-r disposed.')
        setSignalREventInstance(null);
    }, [signalREventInstance]);

    useEffect(() => {
        // register on businessId group on connection
        try {
            if (
                signalREventInstance &&
                signalREventInstance.state == HubConnectionState.Connected &&
                businessId
            ) {
                signalREventInstance.invoke(
                    "AddToGroup",
                    businessId.toString()
                );
            }
        } catch (e) { }
        return () => {
            if (
                signalREventInstance &&
                signalREventInstance.state == HubConnectionState.Connected &&
                businessId
            ) {

                try {
                    signalREventInstance.invoke(
                        "RemoveFromGroup",
                        businessId.toString()
                    );
                } catch (e) { }
            }
        };
    }, [signalREventInstance, businessId]);

    return { configure, dispose, signalR: signalREventInstance };
}
