import defaultTo from "lodash-es/defaultTo";
import { EmployeeType } from "models/employee";
import { EmployeesListRequest } from "models/employee/request";
import { Optional } from "models/general";
import { BusinessRoutingContext } from "routing/routingContexts";
import {
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";

export const validEmployeePathPatterns = [
    ":employeeType",
    ...Object.keys(EmployeeType).map((t) => `${t.toLocaleLowerCase()}s`),
];
export const getEmployeePathPattern = (
    employeeType?: EmployeeType | ":employeeType"
) => {
    return validEmployeePathPatterns.includes(employeeType as any)
        ? employeeType
        : `${defaultTo(
            employeeType,
            EmployeeType.Employee
        ).toLocaleLowerCase()}s`;
};
export const businessEmployeeRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: BusinessRoutingContext = new BusinessRoutingContext(),
    employeeType: EmployeeType | ":employeeType" = ":employeeType",
    employeeId: string = ":employeeId"
) => {
    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    const type = getEmployeePathPattern(employeeType);
    return {
        base: base,
        apiBase: apiBase,
        api: ((empApiBase: string) => ({
            getEmployeesList: (reqParams?: EmployeesListRequest) =>
                getUrlForPattern(
                    context,
                    true,
                    `${empApiBase}/employees-list`,
                    true,
                    undefined,
                    reqParams
                ),
            generateAccountingExcel: () =>
                getUrlForPattern(
                    context,
                    true,
                    `${empApiBase}/generate-accounting-excel`,
                    true
                ),
            addUpdate: () =>
                getUrlForPattern(context, true, `create-update`, true),
            withId: (employeeEncodeId?: Optional<string> | ":employeeId") =>
                ((idBase: string) => {
                    // here clientId might not be in context so passing it manually
                    return {
                        basicInfo: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/basic-info`,
                                true
                            ),
                        deleteEmployee: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/delete`,
                                true
                            ),
                        generateUniqueIpadCode: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/generate-unique-ipad-code`,
                                true
                            ),
                        vacations: ((vacationBase: string) => {
                            return {
                                list: (year?: number) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${vacationBase}/vacations-list`,
                                        true,
                                        undefined,
                                        year ? { Year: year } : undefined
                                    ),
                                get: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${vacationBase}/:id`,
                                        true,
                                        { id: id }
                                    ),
                                createUpdate: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${vacationBase}/create-update`,
                                        true
                                    ),
                                delete: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${vacationBase}/delete/:id`,
                                        true,
                                        { id: id }
                                    ),
                            };
                        })(`${idBase}/vacations`),
                        shifts: ((shiftBase: string) => {
                            return {
                                list: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${shiftBase}/list`,
                                        true
                                    ),
                                get: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${shiftBase}/:id`,
                                        true,
                                        { id: id }
                                    ),
                                createUpdate: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${shiftBase}/add-update`,
                                        true
                                    ),
                                delete: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${shiftBase}/delete/:id`,
                                        true,
                                        { id: id }
                                    ),
                            };
                        })(`${idBase}/shifts`),
                        employeeContract: ((employeeContractBase: string) => {
                            return {
                                list: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${employeeContractBase}/list`,
                                        true
                                    ),
                                get: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${employeeContractBase}/:id`,
                                        true,
                                        { id: id }
                                    ),
                                create: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${employeeContractBase}/create`,
                                        true
                                    ),
                                update: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${employeeContractBase}/update`,
                                        true
                                    ),
                                delete: (id: number) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${employeeContractBase}/delete/${id}`,
                                        true
                                    ),
                            };
                        })(`${idBase}/employee-contract`),
                    };
                })(
                    `${empApiBase}/${employeeEncodeId ? employeeEncodeId : employeeId
                    }`
                ),
        }))(`employee`),
        screens: {
            ...((employeeBase: string) => {
                return {
                    list: () => {
                        return getUrlForPattern(
                            context,
                            buildUrl,
                            employeeBase,
                            false
                        );
                    },
                    create: () => {
                        return getUrlForPattern(
                            context,
                            buildUrl,
                            `${employeeBase}/create`
                        );
                    },
                    withId: (
                        employeeEncodeId?: Optional<string> | ":employeeId"
                    ) =>
                        ((employeeEditBase: string) => {
                            const subPathBase = buildUrl
                                ? `${employeeBase}/${employeeEditBase}/`
                                : ``;

                            return {
                                base: () => {
                                    // for edit-layout
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        buildUrl
                                            ? `${employeeBase}/${employeeEditBase}`
                                            : `${employeeEditBase}`
                                    );
                                },
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}edit`
                                    );
                                },
                                app: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}app`
                                    );
                                },
                                salaryStatement: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}salary-statements`
                                    );
                                },
                                careGroups: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}groups`
                                    );
                                },
                                accessControl: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}access-control`
                                    );
                                },
                                documents: ((documentBase: string) => {
                                    return {
                                        list: () =>
                                            getUrlForPattern(
                                                context,
                                                buildUrl,
                                                documentBase
                                            ),
                                        fillEditableSections: (
                                            docId: string = ":docId"
                                        ) =>
                                            getUrlForPattern(
                                                context,
                                                buildUrl,
                                                `${documentBase}/${docId}/fill-editable-sections`
                                            ),
                                    };
                                })(`${subPathBase}documents`),
                                contract: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}contract-data`),
                                shifts: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}shifts`),
                                absences: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}absences`),
                                diseases: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}diseases`),
                                vacations: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}vacations`),
                                bankData: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}bank-data`),
                            };
                        })(
                            `${employeeEncodeId ? employeeEncodeId : employeeId
                            }`
                        ),
                };
            })(`${type}`),
        },
    };
};

export default businessEmployeeRoutes;
