import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
    faAngleDown,
    faBars,
    faCheckCircle,
    faMinusCircle,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCheckSquare,
    faCheck,
    faTimes,
    faUserCog,
    faHome,
    faPlus,
    faPhone,
    faCopy,
    faPlay,
    faSave,
    faHeadset,
    faArrowCircleLeft,
    faArrowLeft,
    faAngleRight,
    faAngleLeft,
    faUsersCog,
    faHeadphones,
    faCog,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faSort,
    faSortUp,
    faDollarSign,
    faEuroSign,
    faSortDown,
    faBusinessTime,
    faDoorClosed,
    faLessThan,
    faStop,
    faGreaterThan,
    faMinus,
    faInfoCircle,
    faDoorOpen,
    faFileImport,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faBell,
    faCheckSquare,
    faCheck,
    faTimes,
    faUserCog,
    faMinus,
    faHome,
    faPlus,
    faStop,
    faPhone,
    faPlay,
    faHeadset,
    faArrowCircleLeft,
    faArrowLeft,
    faAngleRight,
    faAngleLeft,
    faCopy,
    faUsersCog,
    faHeadphones,
    faCog,
    faChevronUp,
    faLessThan,
    faGreaterThan,
    faSave,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faSort,
    faSortUp,
    faDollarSign,
    faEuroSign,
    faSortDown,
    faBusinessTime,
    faDoorClosed,
    faDoorOpen,
    faFileImport,
    faAngleDown,
    faTrash,
    faBars,
    faCheckCircle,
    faMinusCircle,
    faTimesCircle,
    faInfoCircle
);
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();
