/* eslint-disable react/display-name */

import { AppPopover } from "components";
import { ImageAssets } from "globals/images";
import { defaultTo } from "lodash-es";
import { AvatarInfo } from "models/general";
import React from "react";
import { Image, ListGroup } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { ChannelItem } from "./ChannelItem";
import styles from "./ChatUsersDetailPopover.module.scss";
interface ChatUsersDetailPopoverProps {
    mainHeading: string;
    targetElement: React.ReactNode;
    className?: string;
    persons: AvatarInfo[];
}
export const ChatUsersDetailPopover = React.memo<ChatUsersDetailPopoverProps>(
    ({ mainHeading, persons, className, targetElement }) => {
        return (
            <AppPopover
                triggerOnClick={false}
                delay={200}
                trigger={"mouseenter focus"}
                containerClass={`${className} mb-auto`}
                placement={"auto-end"}
                triggerElem={targetElement}
            >
                <div className={styles.root}>
                    <div className={styles.title}>
                        <div className={`d-flex ${styles.header}`}>
                            <div className={styles.iconHelp}>
                                <Image
                                    src={ImageAssets.common.helpCircleBlue}
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <span className={styles.mainHeading}>
                                    {mainHeading}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.underline} />
                    <div>
                        <ListGroup as={"div"}>
                            {persons &&
                                persons.map((person) => (
                                    <ChannelItem
                                        key={uuid()}
                                        channel={{
                                            ...person,
                                            ChannelOriginalName: defaultTo(
                                                person.Name,
                                                ""
                                            ),
                                            LastMessage: null,
                                            PinnedAt: null,
                                            IsPinned: null,
                                            ChannelId: null,
                                            LastMessageActualDate: null,
                                            UnReadMessageCount: null,
                                            ChannelType: null,
                                            LastMessageTime: null,
                                            MemberCount: null,
                                            MemberSid: null,
                                            AdminName: null,
                                        }}
                                        className={styles.popperItem}
                                        message={person.Email}
                                        readOnly={true}
                                    />
                                ))}
                        </ListGroup>
                    </div>
                </div>
            </AppPopover>
        );
    }
);

export default ChatUsersDetailPopover;
