import { setUser } from "@sentry/react";
import {
    removeUserAuthCookies,
    setCookie,
    updateCookie,
} from "globals/helpers/cookieHelper";
import { sleep } from "globals/helpers/generalHelper";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { isNil } from "lodash-es";
import {
    MeResponse,
    UserCookie,
    UserCredentials,
    UserTokenResponse,
} from "models";
import { AppResponse } from "models/general";
import { AxiosCommonHeaders, CookiesKey } from "models/general/enum";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { initAxios } from "services/helpers";

let renewingSession: boolean = false;
// can't add in cookieHelper because of circular dependency
export const renewSessionAndUpdateCookie = async (
    userCookie: UserCookie,
    fetchData: (token: string) => Promise<AppResponse<UserTokenResponse>>,
    onSuccess?: (data: UserTokenResponse) => void // Init credentials hook function
) => {
    if (!renewingSession) {
        renewingSession = true;
        const response = await fetchData(userCookie.RefreshToken);

        renewingSession = false;
        if (response.Data) {
            const { Data } = response;

            if (onSuccess) {
                onSuccess(Data);
            } else {
                initAxios(AxiosCommonHeaders.AUTHORIZATION, Data.AccessToken);
                updateCookie(CookiesKey.USER_COOKIE, {
                    ...userCookie,
                    AccessToken: Data.AccessToken,
                    RefreshToken: Data.RefreshToken,
                });
            }
        }
    } else {
        console.info("already another renew session request in progress");
        while (renewingSession) {
            // console.log("renewing session", renewingSession.toString());
            await sleep(1000);
        }
    }
};

export function useOnLogoutUser() {
    const {
        setUserCredentials,
        setUserInfo,
        setSessionTimeOut,
        setRememberMe,
        setSupportViewData,
    } = useAppContext();
    const queryClient = useQueryClient();

    const onUserLogout = useCallback(
        (sessionTimeOut?: boolean) => {
            //removing all the cookies of user and credentials + info from our context

            setCookie(CookiesKey.USER_LOGOUT, "true"); // to avoid redirect URl in route
            removeUserAuthCookies();

            queryClient.invalidateQueries("me");
            setUser(null);
            setTimeout(() => {
                // without timeout it refetches dashboard and notifications immediately
                queryClient.invalidateQueries(); // invalidate all query cache's to avoid issue
            }, 200);
            // queryClient.invalidateQueries(); // invalidate all query cache's to avoid issue

            setUserCredentials(null);
            setUserInfo(null);
            setSupportViewData(null);
            setRememberMe(null);
            if (sessionTimeOut) {
                // to redirect to session-timeout
                setSessionTimeOut(false);
                console.debug("unable to login because of no active user role");
            }
        },
        [setUserInfo, setUserCredentials]
    );
    return onUserLogout;
}
// Will set user cookie and token (NOT USER DATA)
// use after the login response
// and before me response.
export function useInitUserSession() {
    const {
        setUserCredentials,
        userCredentials,
        setIsContextLoaded,
        setLocale,
    } = useAppContext();
    const { i18n } = useTranslation();

    const initSession = useCallback(
        (data: UserCredentials, rememberMe: boolean = false) => {
            if (data) {
                //Setting Bearer Token
                initAxios(AxiosCommonHeaders.AUTHORIZATION, data.AccessToken);

                if (data.Culture) {
                    setLocale(data.Culture);
                }
                // context is to be loaded (me request is in progress)
                setIsContextLoaded(false);
                //setting user tokens in context
                setUserCredentials(data);
            }
        },
        [i18n, setLocale, setUserCredentials]
    );
    const renewSession = useCallback(
        (data: UserTokenResponse) => {
            if (userCredentials) {
                //setting user tokens in context
                initSession({ ...userCredentials, ...data }, true);
            }
        },
        [userCredentials, initSession, initSession]
    );

    return { initSession, renewSession };
}
//for update the user info in context
export function useInitUserInfo() {
    const { setUserInfo, setLocale, setIsContextLoaded } = useAppContext();
    const onUserLogout = useOnLogoutUser();
    const setContextLoaded = useCallback(() => {
        // context is to be loaded (me request is in progress)
        setIsContextLoaded(true);
    }, [setIsContextLoaded]);
    const initUserInfo = useCallback(
        (data: MeResponse, setLoaded: boolean = false) => {
            //setting user tokens in context
            if (data.User) {
                setLocale(data.User.Locale);
                setUser({ email: data.User.Email, id: data.User.Id });
            }
            setUserInfo(data);

            if (data.AccessInfo && isNil(data.AccessInfo.ActiveRoleScope)) {
                // if active-role is null then user's session is either expired or DB is changed
                // or in another TAB user has logged in with some other credentials
                // Because user must have some active role
                onUserLogout(true);
            }

            if (setLoaded) {
                setContextLoaded();
            }
        },
        [setUserInfo, setContextLoaded]
    );
    return { initUserInfo, setContextLoaded };
}
//for update local in session and i18next
