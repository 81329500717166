import { CustomColorItemOption } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./DocumentTemplateTags.module.scss";
interface DocumentTemplateTagsProps {
    values: CustomColorItemOption[];
}
export const DocumentTemplateTags: React.FC<DocumentTemplateTagsProps> = ({
    values,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {values.map((x) => (
                <span
                    key={x.label}
                    className={styles.text}
                    style={{
                        background: x.color,
                    }}
                >
                    {t(
                        `templateDocumentManager.documentType.${x.label.toLocaleLowerCase()}`
                    )}
                </span>
            ))}
        </>
    );
};

export default DocumentTemplateTags;
