import { StartAndEndDateFields } from "commonPartials";
import { AppTooltip, ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    AppCheckbox,
    AppDatePicker,
    AppInputField,
    AppNumberInput,
} from "components/FormFields";
import { AppLoader } from "components/Loaders";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Business_Employee_ContractData } from "models";
import {
    calculateGrossSalary,
    EmployeeContract,
    getEmployeeContractCreateEdit,
    getEmployeeContractEmptyObject,
    getParsedEmployeeContract,
    getCalculateEmployeeContractDates,
} from "models/employeeContract";
import { validateEmployeeContract } from "models/employeeContract/validate";
import {
    AppCurrencyCode,
    getInitializedValidityState,
    getValidityStateFromApiResponse,
    Optional,
    ValidityStateManager,
} from "models/general";
import moment, { Moment } from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import BusinessEmployeeContractService, {
    getBusinessEmployeeContractServiceKey,
} from "services/business/BusinessEmployeeContractService";
import {
    FORMAT_INTEGER,
    ISO8601_DATE_FORMAT,
    TWO_PRECISION_NUMERIC,
} from "globals/constants";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "theme/themeConstants";
import { ImageAssets } from "globals/images";
import styles from "./EmployeeContract.module.scss";
import { EMPLOYEE_CONTRACT_START_DATE_PARAM } from "screens/business/employees/tabs/employeeContracts/EmployeeContractList";
import { useSearchParams } from "react-router-dom";
import { isNumber } from "globals/helpers/generalHelper";

const lgSize = 3;
const mdSize = 3;
export const EmployeeContractCreateEdit: React.FC = () => {
    const { getPermissionMap } = useCheckPermission();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const showErrorPage = useShowErrorPage();
    const { currencyCode, formatNumber } = useLocaleHelpers();
    const queryClient = useQueryClient();
    const { linkProvider } = useRouting();
    const { id } = useParams();
    const [hasError, setHasError] = useState(false); // to handle API response errors

    const { t } = useTranslation();
    const { encodedId: encodedBusinessId, id: businessId } =
        useSessionBusiness();
    const { refetchData, dataItem: employee } = useActiveBusinessEmployee();

    const contractStart = searchParams.get(EMPLOYEE_CONTRACT_START_DATE_PARAM);
    const startDate = contractStart
        ? moment(contractStart, ISO8601_DATE_FORMAT)
        : null;
    const linkProviderBase = linkProvider.business
        .employees()
        .screens.withId().contract;

    const contractService = new BusinessEmployeeContractService(
        linkProvider.business.employees().api.withId().employeeContract
    );

    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(encodedBusinessId as string)
    );

    const [state, setState] = useState<Optional<EmployeeContract>>(
        id
            ? null
            : getEmployeeContractEmptyObject(
                  businessId,
                  defaultTo(startDate, moment()),
                  employee?.Id
              )
    );

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: contractDataRes,
        refetch: fetchDetails,
    } = useQuery(
        getBusinessEmployeeContractServiceKey("get", {
            id: id,
        }),
        async () => {
            if (id) {
                return await contractService.get(id);
            }
        },
        {
            enabled: false,
            cacheTime: 0,
            select: (listRes) => {
                if (listRes?.Data) {
                    return getParsedEmployeeContract(listRes.Data);
                } else if (!isNil(id)) {
                    showErrorPage(404);
                }
            },
        }
    );

    const {
        isLoading: createUpdateLoading,
        mutate: createUpdate,
        data: updateResponse,
    } = useMutation(
        async () => {
            if (state) {
                if (state?.Id) {
                    return await contractService.update(state);
                } else {
                    return await contractService.create(state);
                }
            }
        },
        {
            onSuccess: (res) => {
                if (res?.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("employee.contract.employeeContract")} ${t(
                            `common.actions.${
                                id
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getBusinessEmployeeContractServiceKey("list", {})
                    );
                    if (refetchData) {
                        refetchData();
                    }
                    navigate(linkProviderBase.list());
                } else if (res?.Errors) {
                    setHasError(true);

                    showSweetAlertToast(
                        t("common.error.error"),
                        res?.Message
                            ? res.Message
                            : t(
                                  `common.actions.errors.${
                                      id ? "unableToUpdate" : "unableToCreate"
                                  }`
                              ),
                        "error"
                    );
                } else {
                    showUnexpectedErrorToast();
                }
            },
        }
    );

    useEffect(() => {
        if (hasError) {
            // on state change reset response errors
            setTimeout(() => setHasError(false), 500);
        }
    }, [state]);
    useEffect(() => {
        if (id) {
            fetchDetails();
        }
    }, [id]);
    useEffect(() => {
        if (!getLoading && !getRefetching && contractDataRes) {
            setState({
                ...state,
                ...getEmployeeContractCreateEdit(
                    contractDataRes as EmployeeContract
                ),
            });
        }
    }, [getLoading, getRefetching]);
    const permMap = getPermissionMap(Business_Employee_ContractData);
    const readonly = !(permMap.CREATE || permMap.EDIT);

    const selectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;

    const {
        isLoading: areasLoading,
        data: areasResponse,
        isRefetching: areasRefetching,
    } = useQuery(
        getCurrentBusinessServiceKey("getBusinessAreas", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () => await currentBusinessService.getBusinessAreas()
    );

    const {
        isLoading: functionsLoading,
        data: functionsResponse,
        isRefetching: functionsRefetching,
    } = useQuery(
        getCurrentBusinessServiceKey("getBusinessFunctions", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () => await currentBusinessService.getBusinessFunctions()
    );

    const areas =
        areasResponse && areasResponse.Data
            ? [
                  selectOption,
                  ...areasResponse.Data.map((x) => {
                      return {
                          label: x.Text,
                          value: x.Value?.toString(),
                      } as SimpleOption;
                  }),
              ]
            : [selectOption];

    const functions =
        functionsResponse && functionsResponse.Data
            ? [
                  selectOption,
                  ...functionsResponse.Data.map((x) => {
                      return {
                          label: x.Text,
                          value: x.Value?.toString(),
                      } as SimpleOption;
                  }),
              ]
            : [selectOption];

    const validityStateManager = useMemo(() => {
        let validityState = state
            ? validateEmployeeContract(state)
            : getInitializedValidityState();
        if (hasError && updateResponse) {
            validityState = getValidityStateFromApiResponse(updateResponse);
        }
        return new ValidityStateManager(validityState);
    }, [state, t, hasError, updateResponse]);

    const updateGrossSalaryDependentFields = (
        key: "RealWeeklyHours" | "RealGrossSalary" | "HoursPerWeek",
        value: number
    ) => {
        if (state) {
            const newContract = calculateGrossSalary({
                ...state,
                [key]: value,
            });
            setState(newContract);
        }
    };

    const updateDateFields = (
        fieldKey: "MonthsTrialPeriod" | "MonthsExpiration",
        dateKey: "EndOfTrialPeriod" | "ExpirationDate",
        value: number
    ) => {
        if (state) {
            const updatedDate = getCalculateEmployeeContractDates(
                state.ContractStart.clone(),
                value
            );
            setState({
                ...state,
                [dateKey]: updatedDate,
                [fieldKey]: value,
            });
        }
    };
    return (
        <>
            {!state || getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <AppContainer
                    showHeader={true}
                    mobileViewAdjustment={-30}
                    mediumViewAdjustment={-30}
                    onBack={() => navigate(linkProviderBase.list())}
                    title={
                        id
                            ? t("employee.contract.createEdit.edit")
                            : t("employee.contract.createEdit.create")
                    }
                    hasInnerScroll={true}
                    footer={
                        !readonly ? (
                            <AppContainerFooter
                                primaryButtonProps={{
                                    disabled:
                                        createUpdateLoading ||
                                        !validityStateManager.isStateValid(),
                                    onClick: () => {
                                        if (!readonly) {
                                            createUpdate();
                                        }
                                    },
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Row>
                        <StartAndEndDateFields
                            colSize={3}
                            appendToBody={true}
                            minStartDate={startDate}
                            startLabel={t("employee.contract.contractStart")}
                            readonly={readonly}
                            value={
                                {
                                    StartDate: state?.ContractStart,
                                    EndDate: state?.ContractEnd,
                                } as StartAndEndDates
                            }
                            onChange={(resp: StartAndEndDates) => {
                                setState((old) => ({
                                    ...defaultTo(old, {} as any),
                                    ContractStart: resp.StartDate,
                                    ContractEnd: resp.EndDate,
                                }));
                            }}
                        />
                    </Row>
                    <Row>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t("employee.contract.monthsTrialPeriod")}
                                value={state?.MonthsTrialPeriod}
                                readOnly={readonly}
                                showPlaceHolder={false}
                                format={FORMAT_INTEGER}
                                showEmptyField={true}
                                onChange={(val) =>
                                    updateDateFields(
                                        "MonthsTrialPeriod",
                                        "EndOfTrialPeriod",
                                        val as number
                                    )
                                }
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "MonthsTrialPeriod"
                                )}
                            />
                        </Col>

                        <Col lg={lgSize} md={mdSize}>
                            <AppDatePicker
                                showLabel={true}
                                label={t("employee.contract.endOfTrialPeriod")}
                                className={"m-0"}
                                appendToBody={true}
                                readOnly={true}
                                value={state?.EndOfTrialPeriod}
                                onChange={(val: Optional<Moment>) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            EndOfTrialPeriod: !isNil(val)
                                                ? val
                                                : state.EndOfTrialPeriod,
                                        });
                                    }
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "EndOfTrialPeriod"
                                )}
                                useDefault={false}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t("employee.contract.monthsExpiration")}
                                value={state?.MonthsExpiration}
                                format={FORMAT_INTEGER}
                                showPlaceHolder={false}
                                showEmptyField={true}
                                readOnly={readonly}
                                onChange={(val) =>
                                    updateDateFields(
                                        "MonthsExpiration",
                                        "ExpirationDate",
                                        val as number
                                    )
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "MonthsExpiration"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppDatePicker
                                showLabel={true}
                                label={t("employee.contract.expirationDate")}
                                className={"m-0"}
                                appendToBody={true}
                                readOnly={true}
                                value={state?.ExpirationDate}
                                onChange={(val: Optional<Moment>) => {
                                    if (val !== null) {
                                        if (state) {
                                            setState({
                                                ...state,
                                                ExpirationDate: val
                                                    ? val
                                                    : state.ExpirationDate,
                                            });
                                        }
                                    }
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "ExpirationDate"
                                )}
                                useDefault={false}
                            />
                        </Col>

                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t("employee.contract.realWeeklyHours")}
                                value={state?.RealWeeklyHours}
                                readOnly={readonly}
                                allowZero={true}
                                showPlaceHolder={false}
                                onChange={(val) =>
                                    updateGrossSalaryDependentFields(
                                        "RealWeeklyHours",
                                        defaultTo(val, 0)
                                    )
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "RealWeeklyHours"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t(
                                    "employee.contract.createEdit.realGrossSalary"
                                )}
                                value={state?.RealGrossSalary}
                                format={TWO_PRECISION_NUMERIC}
                                isForCurrency={true}
                                showPlaceHolder={false}
                                readOnly={readonly}
                                allowZero={true}
                                onChange={(val) =>
                                    updateGrossSalaryDependentFields(
                                        "RealGrossSalary",
                                        defaultTo(val as number, 0)
                                    )
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "RealGrossSalary"
                                )}
                                showEmptyError={true}
                            />
                        </Col>

                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={
                                    <span className="d-flex align-items-center">
                                        {t("employee.contract.hoursPerWeek")}
                                        <AppTooltip
                                            maxWidth={"380px"}
                                            content={
                                                <span
                                                    style={{
                                                        textAlign: "justify",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {t(
                                                        "employee.contract.createEdit.workingHoursToolTipLine1"
                                                    )}
                                                    <br />
                                                    <br />
                                                    {t(
                                                        "employee.contract.createEdit.workingHoursToolTipLine2"
                                                    )}
                                                </span>
                                            }
                                            trigger="mouseenter focus"
                                            arrow={true}
                                        >
                                            <Image
                                                className={styles.tooltipIcon}
                                                src={
                                                    ImageAssets.common
                                                        .questionCircleBlue
                                                }
                                            />
                                        </AppTooltip>
                                    </span>
                                }
                                value={state?.HoursPerWeek}
                                showPlaceHolder={false}
                                allowZero={true}
                                onChange={(val) =>
                                    updateGrossSalaryDependentFields(
                                        "HoursPerWeek",
                                        defaultTo(val as number, 0)
                                    )
                                }
                                error={validityStateManager.getFirstErrorInfo(
                                    "HoursPerWeek"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppInputField
                                label={t(
                                    "employee.contract.createEdit.salaryFictitiousHours"
                                )}
                                value={formatNumber(state?.GrossSalary)}
                                rightIcon={
                                    <FontAwesomeIcon
                                        icon={
                                            currencyCode === AppCurrencyCode.USD
                                                ? "dollar-sign"
                                                : "euro-sign"
                                        }
                                        style={{ color: COLOR_PRIMARY }}
                                    />
                                }
                                readOnly={true}
                                onValueChange={() => {}}
                                showEmptyError={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "GrossSalary"
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={lgSize} md={mdSize}>
                            <Form.Group
                                controlId="functions"
                                style={{ height: "90px" }}
                            >
                                <Form.Label>
                                    {t(
                                        "employee.contract.createEdit.function.name"
                                    )}
                                </Form.Label>
                                <AppSelectOld
                                    isDisabled={
                                        readonly ||
                                        functionsLoading ||
                                        functionsRefetching
                                    }
                                    options={functions}
                                    isLoading={functionsLoading}
                                    value={
                                        functions.length > 0 &&
                                        !isNil(state?.BusinessFunctionId)
                                            ? functions.find(
                                                  (x: any) =>
                                                      x.value ==
                                                      state?.BusinessFunctionId
                                              )
                                            : selectOption
                                    }
                                    getOptionLabel={(opt: SimpleOption) =>
                                        opt.label
                                    }
                                    getOptionValue={(opt: SimpleOption) =>
                                        opt.value
                                    }
                                    onChange={(opt: SimpleOption) => {
                                        if (state) {
                                            setState({
                                                ...state,
                                                BusinessFunctionId: isNumber(
                                                    opt.value
                                                )
                                                    ? Number(opt.value)
                                                    : 0,
                                            });
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "BusinessFunctionId"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <Form.Group
                                controlId="areas"
                                style={{ height: "90px" }}
                            >
                                <Form.Label>
                                    {t(
                                        "employee.contract.createEdit.area.name"
                                    )}
                                </Form.Label>
                                <AppSelectOld
                                    isDisabled={
                                        readonly ||
                                        areasLoading ||
                                        areasRefetching
                                    }
                                    options={areas}
                                    isLoading={areasLoading}
                                    value={
                                        areas.length > 0 &&
                                        state?.BusinessAreaId != null
                                            ? areas.find(
                                                  (x: any) =>
                                                      x.value ==
                                                      state?.BusinessAreaId
                                              )
                                            : selectOption
                                    }
                                    getOptionLabel={(opt: SimpleOption) =>
                                        opt.label
                                    }
                                    getOptionValue={(opt: SimpleOption) =>
                                        opt.value
                                    }
                                    onChange={(opt: SimpleOption) => {
                                        if (state) {
                                            setState({
                                                ...state,
                                                BusinessAreaId: isNumber(
                                                    opt.value
                                                )
                                                    ? Number(opt.value)
                                                    : 0,
                                            });
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "BusinessAreaId"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t(
                                    "employee.contract.createEdit.holidayPerYear"
                                )}
                                value={state?.Holidays}
                                format={FORMAT_INTEGER}
                                readOnly={readonly}
                                allowZero={true}
                                showPlaceHolder={false}
                                onChange={(val) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            Holidays: defaultTo(val, 0),
                                        });
                                    }
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "Holidays"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t("employee.contract.workingDaysAWeek")}
                                value={state?.WorkingDaysAWeek}
                                format={FORMAT_INTEGER}
                                readOnly={readonly}
                                showPlaceHolder={false}
                                allowZero={true}
                                onChange={(val) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            WorkingDaysAWeek: defaultTo(val, 0),
                                        });
                                    }
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "WorkingDaysAWeek"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t("employee.contract.pauseInMinutes")}
                                value={state?.PauseInMinutes}
                                format={FORMAT_INTEGER}
                                readOnly={readonly}
                                showPlaceHolder={false}
                                allowZero={true}
                                error={validityStateManager.getFirstErrorInfo(
                                    "PauseInMinutes"
                                )}
                                onChange={(val) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            PauseInMinutes: defaultTo(val, 0),
                                        });
                                    }
                                }}
                                showEmptyError={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t(
                                    "employee.contract.createEdit.hourlyNetSalary"
                                )}
                                value={state?.HourlySalaryNet}
                                format={TWO_PRECISION_NUMERIC}
                                isForCurrency={true}
                                allowZero={true}
                                readOnly={readonly}
                                showPlaceHolder={false}
                                error={validityStateManager.getFirstErrorInfo(
                                    "HourlySalaryNet"
                                )}
                                onChange={(val) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            HourlySalaryNet: defaultTo(val, 0),
                                        });
                                    }
                                }}
                                showEmptyError={true}
                            />
                        </Col>
                        <Col lg={lgSize} md={mdSize}>
                            <AppNumberInput
                                showError={true}
                                label={t(
                                    "employee.contract.createEdit.hourlySalaryGross"
                                )}
                                value={state?.HourlySalaryGross}
                                format={TWO_PRECISION_NUMERIC}
                                allowZero={true}
                                isForCurrency={true}
                                readOnly={readonly}
                                showPlaceHolder={false}
                                onChange={(val) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            HourlySalaryGross: defaultTo(
                                                val,
                                                0
                                            ),
                                        });
                                    }
                                }}
                                error={validityStateManager.getFirstErrorInfo(
                                    "HourlySalaryGross"
                                )}
                                showEmptyError={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} xs={12} md={3}>
                            <AppCheckbox
                                label={t(
                                    "employee.contract.createEdit.chargeable"
                                )}
                                onChange={(check) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            Chargeable: check.target.checked,
                                        });
                                    }
                                }}
                                disabled={readonly}
                                checked={state?.Chargeable}
                            />
                        </Col>
                        <Col sm={3} xs={12} md={3}>
                            <AppCheckbox
                                label={t(
                                    "employee.contract.createEdit.useContract"
                                )}
                                onChange={(check) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            UseDataForContract:
                                                check.target.checked,
                                        });
                                    }
                                }}
                                disabled={readonly}
                                checked={state?.UseDataForContract}
                            />
                        </Col>
                        <Col sm={6} xs={12} md={6}>
                            <AppCheckbox
                                label={t(
                                    "employee.contract.createEdit.ignoreFromDashboard"
                                )}
                                onChange={(check) => {
                                    if (state) {
                                        setState({
                                            ...state,
                                            IsExcludedFromDashboardStats:
                                                check.target.checked,
                                        });
                                    }
                                }}
                                disabled={readonly}
                                checked={state?.IsExcludedFromDashboardStats}
                            />
                        </Col>
                    </Row>
                </AppContainer>
            )}
        </>
    );
};
export default EmployeeContractCreateEdit;
