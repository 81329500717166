import { AppTooltip } from "components";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { Optional } from "models/general";
import React from "react";
import styles from "./TimeManagementColorHighlight.module.scss";

export interface TimeManagementColorHighlightProps {
    differenceSumMinute: Optional<number>;
    isNegativeRed?: boolean;
    isTime?: boolean;
    className?: string;
    tooltipTitle?: string;
}

export const TimeManagementColorHighlight: React.FC<
    TimeManagementColorHighlightProps
> = ({
    differenceSumMinute,
    isNegativeRed = true,
    isTime = true,
    className = "",
    tooltipTitle,
}) => {
    const { formatNumber } = useLocaleHelpers();
    let colorClass = "";
    if (differenceSumMinute != null && differenceSumMinute < 0) {
        colorClass = isNegativeRed ? styles.bgRed : styles.bgGreen;
    } else if (differenceSumMinute != null && differenceSumMinute > 0) {
        colorClass = isNegativeRed ? styles.bgGreen : styles.bgRed;
    }

    const content = (
        <div
            className={`${className} ${colorClass}  ${styles.highlight} text-center`}
        >
            {isTime
                ? getFormattedTimeFromNumber(differenceSumMinute)
                : formatNumber(differenceSumMinute, FOUR_PRECISION_NUMERIC)}
        </div>
    );
    return (
        <>
            {tooltipTitle ? (
                <AppTooltip
                    content={tooltipTitle}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    {content}
                </AppTooltip>
            ) : (
                content
            )}
        </>
    );
};

export default TimeManagementColorHighlight;
