import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getRemainderDefaultValue, validateReminder } from "models/appReminder";
import { SortOrder } from "models/general/sort";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general/validityState";
import {
    getJobScheduleDefaultValue,
    validateJobSchedule,
} from "models/jobSchedule";
import moment from "moment";
import { Moment } from "moment-timezone";
import { TodoSortColumn } from "./enum";
import { TodoModelBase } from "./model";

export interface TodoFilterRequest {
    PageSize?: number;
    PageNumber?: number;
    Priority?: number;
    Task?: string;
    AssignedToMe?: boolean;
    CreatedByMe?: boolean;
    Description?: string;
    AreaId?: number;
    AssigneeId?: number;
    CreationDate?: string;
    DueDate?: string;
    CompletionDate?: Moment;
    DoneDate?: Moment;
    SearchString?: string;
    SortColumn?: TodoSortColumn;
    SortOrder?: SortOrder;
}

export interface TodoRequest extends TodoModelBase {
    DueDate: Moment; // only for UI, will not be used in backend (createUpdate request)
    DueDateTime: Moment;
    DoneDate?: Moment;
    CompletionDate?: Moment;
    LastReOpenedOn?: Moment;
    CreatedAt?: Moment;
}

export function getTodoDefaultValue(dueDateTime?: Moment) {
    const dueTime = dueDateTime ? dueDateTime : moment();
    return {
        Id: 0,
        Task: undefined,
        WorkingAreaId: 0,
        AssigneeToEmployeeId: 0,
        DueDate: moment(),
        DueDateTime: dueTime,
        Priority: 50,
        Description: {
            Template: "",
            Text: "",
            Version: "0",
            Placeholders: [],
        },
        Reminder: getRemainderDefaultValue(),
        RepeatSchedule: { ...getJobScheduleDefaultValue(), Time: dueTime },
        Files: [],
        TaskRecreated: false,
        SendRemainder: false,
    } as TodoRequest;
}

export function validateTodo(
    todo: TodoRequest,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof TodoRequest> = {
        AssigneeToEmployeeId: [
            {
                rule: Validations.MIN,
                message: i18next.t("todoManagement.employees.missing"),
                options: {
                    value: 1,
                },
            },
        ],
        WorkingAreaId: [
            {
                rule: Validations.MIN,
                message: i18next.t("todoManagement.workingArea.missing"),
                options: {
                    value: 1,
                },
            },
        ],
        Task: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("todoManagement.task.missing"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(validationRules, todo, state);

    if (todo.Reminder && todo.SendRemainder) {
        state = validateReminder(todo.Reminder, state);
    }

    let manager = new ValidityStateManager(state);
    if (todo.RepeatSchedule && todo.TaskRecreated) {
        const validations = validateJobSchedule(todo.RepeatSchedule);
        manager = manager.addChildren("RepeatSchedule", validations);
    }

    return manager.state;
}
