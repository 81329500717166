import React from "react";
import { Table } from "react-bootstrap";
import styles from "./TimeManagementHeaderContainer.module.scss";

interface TimeManagementTotalCountContainerProps {
    className?: string;
    children: React.ReactNode;
}
const TimeManagementTotalCountContainer: React.FC<
    TimeManagementTotalCountContainerProps
> = ({ className = "", children }) => {
    return (
        <Table borderless className={`${styles.table} ${className}`}>
            {children}
        </Table>
    );
};

export default TimeManagementTotalCountContainer;
