import classNames from "classnames";
import { defaultTo, isNil, uniq } from "lodash-es";
import {
    MetisMenuClassStoreType,
    MetisMenuContentType,
    MetisMenuItemType,
    MetisMenuProps,
} from "./model";

export const processMenuItems = (
    content: MetisMenuContentType,
    index: number
): MetisMenuItemType => {
    const result: MetisMenuItemType = {
        ...content,
        id: index,
        classItem: content.classItem
            ? classNames("metismenu-item", content.classItem)
            : undefined,
    };

    if (content.subMenu && content.subMenu.length > 0) {
        result.hasSubMenu = true;
        result.subMenuItems = defaultTo(
            content.subMenu.map((element, index) => {
                return processMenuItems(element, index);
            }),
            []
        );
        // parent-toggle will open first sub-menu link (after sub-menu is set)
        result.to = content.subMenu[0].to;
    }
    return result;
};
export const updateActiveLink = (
    contents: MetisMenuItemType[],
    currentLink: string
): MetisMenuItemType[] => {
    // map after copying array
    return [...contents].map((x: MetisMenuItemType) => {
        if (x.subMenuItems) {
            // first set active in sub-menu (if any)
            x.subMenuItems = updateActiveLink(x.subMenuItems, currentLink);
        }

        // convert to array for easy comparison
        const activeIfPatternLike = !isNil(x.activeIfPatternLike)
            ? typeof x.activeIfPatternLike === "string"
                ? [x.activeIfPatternLike]
                : x.activeIfPatternLike
            : [];

        const hasActiveChild = x.subMenuItems?.some((y) => y?.active);
        if (
            hasActiveChild ||
            // if pathname equals target pathname
            x.to === currentLink ||
            // or pathname contains any of the pattern in list
            (activeIfPatternLike.some((l) => currentLink.includes(l)) &&
                defaultTo(x.activeIfPatternNotLike, []).every(
                    (l) => !currentLink.includes(l)
                ))
        ) {
            return {
                ...x,
                active: true,
                isSubMenuVisible: hasActiveChild,
                hasActiveChild: hasActiveChild,
            };
        }
        return { ...x, active: false, hasActiveChild: undefined };
    });
};
export const handleMenuToggle = (
    contents: MetisMenuItemType[],
    id: number,
    allowMultipleCollapse: boolean = false
) => {
    return contents.map((x) => {
        if (x.id === id) {
            return {
                ...x,
                isSubMenuVisible: !x.isSubMenuVisible,
            };
        }

        if (allowMultipleCollapse) {
            return x;
        }
        return { ...x, isSubMenuVisible: false };
    });
};
export const getParentPermissions = (item: MetisMenuItemType): string[] => {
    let permissions = defaultTo(item.permissions, []);
    if (item.hasSubMenu && item.subMenuItems) {
        // parent permissions are permission for all its childs
        permissions = item.subMenuItems.reduce((perms, sI) => {
            return [...perms, ...defaultTo(getParentPermissions(sI), [])];
        }, permissions);
    }
    return uniq(permissions);
};
export const MetisMenuClassStore = (
    props: MetisMenuClassStoreType
): MetisMenuClassStoreType => {
    return {
        className: props.className,
        classMainWrapper: classNames(
            { metismenu: !props.noBuiltInClassNames },
            "vertical-nav-menu"
        ),
        classContainer:
            typeof props.classContainer === "function"
                ? props.classContainer
                : classNames(
                      { "metismenu-container": !props.noBuiltInClassNames },
                      props.classContainer
                  ),
        classContainerVisible: classNames(
            { visible: !props.noBuiltInClassNames },
            props.classContainerVisible
        ),
        classItem: classNames(
            { "metismenu-item": !props.noBuiltInClassNames },
            props.classItem
        ),
        classLink: classNames(
            { "metismenu-link": !props.noBuiltInClassNames },
            props.classLink
        ),
        classItemActive: props.classItemActive,
        classItemHasActiveChild: props.classItemHasActiveChild,
        classItemHasVisibleChild: props.classItemHasVisibleChild,
        classLinkActive: classNames(
            { active: !props.noBuiltInClassNames },
            props.classLinkActive
        ),
        classLinkHasActiveChild: classNames(
            { "has-active-child": !props.noBuiltInClassNames },
            props.classLinkHasActiveChild
        ),
        classIcon: classNames(
            { "metismenu-icon": !props.noBuiltInClassNames },
            props.classIcon
        ),
        classStateIcon: classNames(
            { "metismenu-state-icon": !props.noBuiltInClassNames },
            props.classStateIcon
        ),
        iconNameStateHidden: props.iconNameStateHidden
            ? props.iconNameStateHidden
            : "pe-7s-angle-down caret-left",
        iconNameStateVisible: props.iconNameStateVisible
            ? props.iconNameStateVisible
            : "pe-7s-angle-down caret-left rotate-minus-90",
    };
};
export const MetisMenuDefaultProps: MetisMenuProps = {
    content: [],
    noBuiltInClassNames: false,
    className: "",
    classContainer: "",
    classContainerVisible: "",
    classItem: "",
    classItemActive: "",
    classItemHasActiveChild: "",
    classItemHasVisibleChild: "",
    classLink: "",
    classLinkActive: "",
    classLinkHasActiveChild: "",
    classIcon: "",
    classStateIcon: "",
    iconNameStateHidden: "caret-left",
    iconNameStateVisible: "caret-left rotate-minus-90",
    showActiveLink: false,
    classMainWrapper: "",
};
