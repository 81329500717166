import { AppContainer } from "components/Containers";
import { AppCheckbox } from "components/FormFields";
import {
    showSweetAlertConfirmation,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { Business_MailboxMessages } from "models";
import { ResponseMeta, Selectable } from "models/general";
import { MessageNavigation, MessageTemplate } from "models/messages";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation } from "react-query";
import { MessageService } from "services/business";
import MessagesItem from "./MessageItem";
import MessageListEnd from "./MessageListEnd";
import styles from "./MessagesListPartial.module.scss";
import { MessageItemSkeleton } from "./skelton";

export interface MessagesListPartialProps {
    messages: Selectable<MessageTemplate>[];
    readonly?: boolean;
    loading: boolean;
    activeMenu: MessageNavigation;
    meta: ResponseMeta | undefined;
    refetchMessages: (pageNumber: number, refetchCount?: boolean) => void;
    onChange: (
        list: Selectable<MessageTemplate>[],
        refetchCount?: boolean
    ) => void;
    startLoading: () => void;
}

export const MessagesListPartial: React.FC<MessagesListPartialProps> = ({
    messages,
    readonly,
    loading,
    activeMenu,
    meta: metaState,
    refetchMessages,
    onChange,
    startLoading,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const [itemsListContainerRef, setItemsListContainerRef] =
        useState<HTMLDivElement | null>(null);
    const meta: ResponseMeta = defaultTo(metaState, {
        PageNumber: 1,
        HasNextPage: false,
    });

    const {
        data: bulkResponse,
        isLoading: bulkLoading,
        mutate: bulkDelete,
    } = useMutation(async (ids: number[]) => {
        if (activeMenu == MessageNavigation.Trash) {
            return await messageService.bulkDelete(ids);
        } else {
            return await messageService.bulkTrash(ids);
        }
    });

    useEffect(() => {
        if (itemsListContainerRef) {
            itemsListContainerRef.scrollTo(0, 0);
        }
    }, [activeMenu]);

    useEffect(() => {
        if (!bulkLoading && bulkResponse) {
            showSweetAlertInfo(
                t("common.success"),
                t(
                    `message.${
                        activeMenu == MessageNavigation.Trash
                            ? "deletedSuccessfully"
                            : "selectedMovedToTrash"
                    }`
                ),
                "success"
            );

            refetchMessages(1, true);
        }
    }, [bulkLoading, bulkResponse]);
    const checkedIds = messages.filter((x) => x.isChecked).map((x) => x.Id);
    let allSelected = false;
    if (messages.length > 0) {
        allSelected = messages.filter((x) => !x.isChecked).length == 0;
    }

    const permissionMap = getPermissionMap(Business_MailboxMessages);

    return (
        <div className={styles.root}>
            <AppContainer
                heightToAdjust={185}
                mediumViewAdjustment={0}
                showHeader={true}
                minHeight={400}
                classes={{ body: styles.contentBody }}
                title={
                    <div className={styles.indexHeader}>
                        <div className={styles.headerDelete}>
                            {permissionMap.DELETE &&
                                messages &&
                                messages.length > 0 && (
                                    <div className={styles.checkBox}>
                                        <AppCheckbox
                                            checked={allSelected}
                                            onChange={(e) => {
                                                onChange(
                                                    messages.map((x) => {
                                                        return {
                                                            ...x,
                                                            isChecked:
                                                                e.target
                                                                    .checked,
                                                        };
                                                    })
                                                );
                                            }}
                                            disabled={readonly}
                                        />
                                    </div>
                                )}
                            {permissionMap.DELETE && checkedIds.length > 0 && (
                                <a
                                    onClick={() => {
                                        showSweetAlertConfirmation(
                                            t(
                                                `common.${
                                                    activeMenu ==
                                                    MessageNavigation.Trash
                                                        ? "deleteSelected"
                                                        : "trashSelected"
                                                }`
                                            ),
                                            t(
                                                `common.${
                                                    activeMenu ==
                                                    MessageNavigation.Trash
                                                        ? "deleteSelectedConfirmation"
                                                        : "trashSelectedConfirmation"
                                                }`
                                            ),
                                            () => {
                                                bulkDelete(checkedIds);
                                                startLoading();
                                            },
                                            activeMenu ==
                                                MessageNavigation.Trash
                                                ? t("common.delete.yes")
                                                : t("common.yesTrashIt"),
                                            "question",
                                            true
                                        );
                                    }}
                                >
                                    <div className={styles.iconDelete}>
                                        <Image
                                            src={ImageAssets.common.trashBlue}
                                        />
                                    </div>
                                    <span className={styles.deleteText}>
                                        {t(
                                            `common.${
                                                activeMenu ==
                                                MessageNavigation.Trash
                                                    ? "deleteSelected"
                                                    : "trashSelected"
                                            }`
                                        )}
                                    </span>
                                </a>
                            )}
                        </div>
                    </div>
                }
            >
                <div style={{ overflowX: "auto" }}>
                    <div
                        className={styles.indexBody}
                        ref={setItemsListContainerRef}
                    >
                        {loading && meta.PageNumber == 1 ? (
                            <MessageItemSkeleton count={8} />
                        ) : (
                            itemsListContainerRef && (
                                <InfiniteScroll
                                    className={styles.infiniteScroll}
                                    dataLength={messages.length}
                                    next={() => {
                                        refetchMessages(
                                            defaultTo(meta.PageNumber, 1) + 1,
                                            false
                                        );
                                    }}
                                    loader={<MessageItemSkeleton count={2} />}
                                    scrollableTarget={itemsListContainerRef}
                                    hasMore={defaultTo(meta.HasNextPage, true)}
                                    endMessage={
                                        !loading && (
                                            <MessageListEnd
                                                totalItemCount={messages.length}
                                            />
                                        )
                                    }
                                >
                                    {messages.length > 0 &&
                                        messages.map((x, index) => (
                                            <MessagesItem
                                                key={index}
                                                value={x}
                                                activeMenu={activeMenu}
                                                canEdit={permissionMap.EDIT}
                                                onChange={(item) => {
                                                    let refetchCount = false;
                                                    onChange(
                                                        messages.map((oV) => {
                                                            if (
                                                                oV.Id == item.Id
                                                            ) {
                                                                refetchCount =
                                                                    oV.IsFavorite !=
                                                                    item.IsFavorite;
                                                                return item;
                                                            } else {
                                                                return oV;
                                                            }
                                                        }),
                                                        refetchCount
                                                    );
                                                }}
                                            />
                                        ))}
                                </InfiniteScroll>
                            )
                        )}
                    </div>
                </div>
            </AppContainer>
        </div>
    );
};

export default MessagesListPartial;
