import { ISO8601_DATE_FORMAT } from "globals/constants";
import { isNil } from "lodash-es";
import { DateRangeRequest } from "./request";
import { AppLocale, AppPlatforms } from "./enum";
import { Address, DeviceTypeParam, MetaDataFields, NavigationTab, QRCode } from "./models";
import moment from "moment";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import { MetaDataFieldsResponse, QRCodeResponse } from "models/general/response";

export function deviceTypeParamToEnum(device: DeviceTypeParam) {
    switch (device) {
        case "ipad":
            return AppPlatforms.IPAD;
        case "mobile":
            return AppPlatforms.MOBILE;
        case "website":
            return AppPlatforms.WEB;
        default:
            return AppPlatforms.WEB;
    }
}
export function getAddressString(address: Address) {
    return `${address.StreetAddress ? `${address.StreetAddress} ` : ""}${address.StreetNumber ? `${address.StreetNumber}` : ""
        } ${address.PostCode ? ` ${address.PostCode} ` : ""} ${address.City ? `, ${address.City}` : ""
        }`;
}

export function parseQrCodeResponse(qrCode: QRCodeResponse): QRCode {
    const chunks: string[] = [];
    const chunkSize = 4;
    if (qrCode.Code) {
        for (
            let i = 0, charsLength = qrCode.Code.length;
            i < charsLength;
            i += chunkSize
        ) {
            chunks.push(qrCode.Code.substring(i, i + chunkSize));
        }
    }
    return {
        ...qrCode,
        Code: chunks,
        CreatedAt: moment.unix(qrCode.CreatedAt),
        ValidTill: moment(qrCode.ValidTill, ISO8601_DATE_FORMAT),
    } as QRCode;
}

export function getQrCodeDefaultValues(targetEntityId: number) {
    return {
        Id: 0,
        Code: [],
        TargetEntityId: targetEntityId,
        QRCodeBase64: "",
        IsForOneTimeUse: false,
        IsExpired: false,
        IsUsed: false,
        CreatedAt: moment(),
        ValidTill: moment(),
    } as QRCode;
}

export const LocaleEnumToTranslationKey: { [key in AppLocale]: string } = {
    [AppLocale.ENGLISH]: "english",
    [AppLocale.GERMAN]: "german",
    [AppLocale.ITALIAN]: "italian",
    [AppLocale.BOSNIAN]: "bosnian",
    [AppLocale.POLISH]: "polish",
    [AppLocale.CROATIAN]: "croatian",
    [AppLocale.CZECH]: "czech",
    [AppLocale.HUNGARIAN]: "hungarian",
    [AppLocale.SLOVAK]: "slovak",
    [AppLocale.SLOVENIAN]: "slovenian",
    [AppLocale.FRENCH]: "french",
    [AppLocale.SPANISH]: "spanish",
    [AppLocale.DUTCH]: "dutch",
    [AppLocale.ESTONIAN]: "estonian",
    [AppLocale.FINNISH]: "finnish",
    [AppLocale.GREEK]: "greek",
};

export const IsNavigationTabActive = (
    tab: NavigationTab,
    currUrl: string
): boolean => {
    return (
        !isNil(tab) &&
        !isNil(currUrl) &&
        (tab.url == currUrl || currUrl.includes(tab.url))
    );
};
export const serializeDateRangeRequest = (req: DateRangeRequest) => {
    return {
        StartDate: req.StartDate?.format(ISO8601_DATE_FORMAT),
        EndDate: req.EndDate?.format(ISO8601_DATE_FORMAT),
    };
};

export const parseMetaDataFieldsResponse = (resp: MetaDataFieldsResponse): MetaDataFields => {
    return {
        ...resp,
        CreatedAt: resp.CreatedAt ? moment.unix(resp.CreatedAt) : null,
        UpdatedAt: resp.UpdatedAt ? moment.unix(resp.UpdatedAt) : null,
    } as MetaDataFields
}
export const isTemplateActive = <T extends StartAndEndDates>(
    template: T
): boolean => {
    const today = moment();
    if (
        template.StartDate <= today &&
        (isNil(template.EndDate) || template.EndDate >= today)
    ) {
        return true;
    }
    return false;
};


// send positive percent to lighten color
// send negative percent to darken color
export const shadeColor = (color: string, percent: number) => {


    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent) / 100) as any);
    G = parseInt((G * (100 + percent) / 100) as any);
    B = parseInt((B * (100 + percent) / 100) as any);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    R = Math.round(R)
    G = Math.round(G)
    B = Math.round(B)

    const RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    const GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    const BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    // console.log(color, 'shade color result', "#" + RR + GG + BB)
    return "#" + RR + GG + BB;
}