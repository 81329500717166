import { isOutsideRef } from "globals/helpers/generalHelper";
import React, {
    MutableRefObject,
    PropsWithChildren,
    useEffect,
    useRef,
} from "react";

export type HandleOutsideClickProps = PropsWithChildren<{
    otherAllowedTargets?: MutableRefObject<any>[];
    onClick: (e?: MouseEvent) => void;
}>;
export const HandleOutsideClick: React.FC<HandleOutsideClickProps> = ({
    otherAllowedTargets = [],
    onClick,
    children,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                isOutsideRef(ref, event.target) &&
                (otherAllowedTargets.length === 0 ||
                    otherAllowedTargets.every((r) =>
                        isOutsideRef(r, event.target)
                    ))
            ) {
                onClick(event);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [onClick]);
    // Normally, just render children
    return <div ref={ref}>{children}</div>;
};
export default HandleOutsideClick;
