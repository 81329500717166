import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, max, toNumber } from "lodash-es";
import {
    Business_Employee_Vacations,
    ContractVacationsInfo,
    EmployeeVacation,
    EmployeeVacationSortColumn,
    getEmployeeVacationFromResponse,
    getEmployeeVacationSortColumnKeyFromEnum,
    PermissionAccessTypes,
} from "models";
import { sortData, SortOrder, TableSort } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { EmployeeVacationTable } from "./partials";
import commonStyles from "commonPartials/commonStyles.module.scss";
import styles from "./EmployeeVacationList.module.scss";
import moment from "moment";
import AppYearNavigationComponent from "components/AppYearNavigationComponent";
import {
    EmployeeVacationService,
    getEmployeeVacationServiceKey,
} from "services/business";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { OPTIONAL_TWO_PRECISION_NUMERIC } from "globals/constants";

interface EmployeeVacationListState {
    data: EmployeeVacation[];
    year: number;
    stats?: ContractVacationsInfo;
    sort: TableSort<EmployeeVacationSortColumn>;
}

export const EmployeeVacationList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const { t } = useTranslation();
    const { employeeId } = useParams();
    const { formatNumber } = useLocaleHelpers();

    const { checkPermission } = useCheckPermission();

    const [state, setState] = useState<EmployeeVacationListState>({
        data: [],
        year:
            locationState && (locationState as any).year
                ? (locationState as any).year
                : moment().year(),
        sort: {
            SortColumn: EmployeeVacationSortColumn.StartDate,
            SortOrder: SortOrder.DESC,
        },
    });

    const vacationService = new EmployeeVacationService(
        linkProvider.business.employees().api.withId().vacations
    );

    const {
        isLoading,
        isFetching,
        data: listRes,
        refetch,
    } = useQuery(
        getEmployeeVacationServiceKey("list", {
            employeeId: employeeId,
        }),
        async () => await vacationService.list(state.year),
        {
            select: (resp) =>
                resp && resp.Data
                    ? {
                          Data: resp.Data.Data.map((x) =>
                              getEmployeeVacationFromResponse(x)
                          ),
                          Stats: resp.Data.VacationStats,
                          YearsRange: resp.Data.YearsRange,
                      }
                    : null,
        }
    );
    useEffect(() => {
        if (!isFetching) {
            refetch();
        }
    }, [state.year]);
    useEffect(() => {
        if (!isFetching && listRes) {
            const toUpdate = {
                ...state,
                data: listRes.Data,
                stats: listRes.Stats,
            };

            if (
                listRes.YearsRange &&
                listRes.YearsRange.length > 0 &&
                !listRes.YearsRange.includes(state.year)
            ) {
                toUpdate.year = max(listRes.YearsRange) as number;
            }
            setState(toUpdate);
        }
    }, [isFetching]);

    const { mutate: deleteVacation, isLoading: deleting } = useMutation(
        async (id: string) => await vacationService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("employee.vacations.vacation")} ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );

                    setState({
                        ...state,
                        data: state.data.filter((x) => x.Id != deleteRes.Data),
                    });
                    refetch();
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const linkProviderBase = linkProvider.business.employees().screens.withId();

    const hasDeletePermission = checkPermission(
        [Business_Employee_Vacations],
        [PermissionAccessTypes.DELETE]
    );

    const processedData = useMemo(() => {
        if (state.data) {
            const sortedList = sortData(
                state.data.filter(
                    (x) =>
                        x.DateStart.year() == state.year ||
                        x.DateEnd?.year() == state.year
                ),
                [
                    {
                        col: getEmployeeVacationSortColumnKeyFromEnum(
                            state.sort.SortColumn
                        ) as any,
                        dir: state.sort.SortOrder,
                    },
                ]
            );
            return sortedList;
        }
        return [];
    }, [state]);

    return (
        <AppContainer
            classes={{ body: commonStyles.appContainerWithLessTopPadding }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                }}
            >
                <AppIconTextButton
                    padding="med"
                    className="mb-2"
                    icon="plus"
                    style={{
                        marginRight: "20px",
                        marginBottom: "0px !important",
                    }}
                    text={t("common.create")}
                    onClick={() => {
                        navigate(linkProviderBase.vacations.create());
                    }}
                />
                <AppYearNavigationComponent
                    years={defaultTo(listRes?.YearsRange, [])}
                    value={state.year}
                    readonly={isLoading}
                    onChange={(y: number) => setState({ ...state, year: y })}
                />
            </div>
            <EmployeeVacationTable
                list={processedData}
                sort={state.sort}
                onClick={(id) => {
                    navigate(linkProviderBase.vacations.edit(id));
                }}
                onSortChange={(sort: TableSort<EmployeeVacationSortColumn>) => {
                    setState({
                        ...state,
                        sort: sort,
                    });
                }}
                onDeleteBtnClick={(id) => deleteVacation(id)}
                loading={isFetching}
                hasDeletePermission={hasDeletePermission}
            />
            {
                <div className={styles.totalsContainer}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `employee.vacations.totals.totalVacationsForYear`,
                                {
                                    count: formatNumber(
                                        defaultTo(
                                            state.stats
                                                ?.TotalContractVacationsForYear,
                                            0
                                        ),
                                        OPTIONAL_TWO_PRECISION_NUMERIC
                                    ),
                                } as any
                            ),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `employee.vacations.totals.totalAllowed`,
                                {
                                    count: formatNumber(
                                        defaultTo(
                                            state.stats?.TotalAllowedThisYear,
                                            0
                                        ),
                                        OPTIONAL_TWO_PRECISION_NUMERIC
                                    ),
                                } as any
                            ),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `employee.vacations.totals.additionalVacations`,
                                {
                                    count: formatNumber(
                                        defaultTo(state.stats?.Additional, 0),
                                        OPTIONAL_TWO_PRECISION_NUMERIC
                                    ),
                                } as any
                            ),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `employee.vacations.totals.manualCorrections`,
                                {
                                    count: formatNumber(
                                        defaultTo(
                                            state.stats?.ManuallyAddedThisYear,
                                            0
                                        ),
                                        OPTIONAL_TWO_PRECISION_NUMERIC
                                    ),
                                } as any
                            ),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(`employee.vacations.totals.totalUsed`, {
                                count: formatNumber(
                                    defaultTo(state.stats?.TotalUsed, 0),
                                    OPTIONAL_TWO_PRECISION_NUMERIC
                                ),
                            } as any),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `employee.vacations.totals.remainingVacations`,
                                {
                                    count: formatNumber(
                                        defaultTo(state.stats?.Remaining, 0) +
                                            defaultTo(
                                                state.stats
                                                    ?.ManuallyAddedThisYear,
                                                0
                                            ),
                                        OPTIONAL_TWO_PRECISION_NUMERIC
                                    ),
                                    year: state.year,
                                } as any
                            ),
                        }}
                    ></span>
                </div>
            }
        </AppContainer>
    );
};

export default EmployeeVacationList;
