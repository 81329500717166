import { BusinessType } from "models/business";
import { DocumentTag } from "models/documentTemplates";
import { NotificationStatus, WebEventType, WebNotificationType } from "./enum";

export type AppNotificationType = "downloads" | "notifications";

export const DownloadableNotifications = [
    WebNotificationType.ClientEmployeeDocumentCompilation,
    WebNotificationType.KindergartenClientAccountingExcel,
    WebNotificationType.BusinessEmployeeAccountingExcel,
    WebNotificationType.BusinessSupplierAccountingExcel,
];

export interface WebNotificationMetaBase {
    Name: string;
    ForBusinessType: BusinessType;
    DocumentArea: DocumentTag;
}
export interface SignalRWebEvent {
    ForUserId?: string;
    BusinessId: number;
    Type: WebEventType;
}

export interface NotificationModelBase {
    Id: number;
    WebNotificationId: number;
    ForUserId: string;
    IsRead: boolean;
    BusinessId: number;
    StatusType: NotificationStatus;
    NotificationType: WebNotificationType;
    TargetUrl: string;
}
