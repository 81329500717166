import byteSize from "byte-size";
import { secureStorage } from "globals/helpers/secureStorage";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { t } from "i18next";
import { isNil, uniq, uniqBy } from "lodash";
import { defaultTo } from "lodash-es";
import { AppResponse } from "models/general";
import { Gender } from "models/general/enum";
import {
    BaseObjectInterface,
    HtmlCoordinates,
    IFile,
    Optional,
    Person,
} from "models/general/models";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import { MutableRefObject } from "react";

export const getFixedCssWidths = (
    width: number,
    useMax: boolean = true,
    useMin: boolean = true
) => {
    const pxWidth = `${width}px`;
    const obj: { width: string; minWidth?: string; maxWidth?: string } = {
        width: pxWidth,
    };
    if (useMin) {
        obj.minWidth = pxWidth;
    }
    if (useMax) {
        obj.maxWidth = pxWidth;
    }
    return obj;
};
export const getMillisecondsForMinutes = (minutes: number): number => {
    return minutes * 60 * 1000;
};
export const getBusinessSettingFlagsPreferences = (
    useLocationData: boolean,
    isForLocationSetting: boolean,
    locationSettingId?: number,
    businessId?: number
) => {
    return {
        isDisabled: isForLocationSetting
            ? false // never disable if we are in location settings
            : (useLocationData && businessId) ||
              (!useLocationData && locationSettingId)
            ? true // disable if template is for business but user has setting to use location-data or vice versa
            : false,
        canDelete:
            isForLocationSetting ||
            (!isForLocationSetting && isNil(locationSettingId)),
        canClone:
            !isForLocationSetting && !useLocationData && locationSettingId
                ? true // is user has setting to manually add info and template is for location-setting
                : false,
    };
};
export const getGenderEnumToNumberValue = (gender: Gender): number => {
    switch (gender) {
        case Gender.Male:
            return 0;
        case Gender.Female:
            return 1;
    }
};
export const getGenderNumberToEnumValue = (num: number): Gender => {
    switch (num) {
        case 0:
            return Gender.Male;
        case 1:
            return Gender.Female;
        default:
            return Gender.Male;
    }
};
export const isOutsideRef = (
    r: MutableRefObject<Optional<any>>,
    target: any
): boolean => {
    return (
        !r ||
        !r.current ||
        (!r.current.contains(target) && r.current !== target)
    );
};
export function constructFullUrl(url: string | null): Optional<string> {
    return url
        ? `${window.location.protocol}//${
              window.location.host
          }${decodeURIComponent(url)}`
        : undefined;
}

export const getImageExtensions = () => {
    return ["jpg", "png", "jpeg", "gif", "bmp"];
};

export const getGeneralAcceptFile = () => {
    return [
        ...getImageExtensions().map((f) => `.${f}`),
        ".pdf",
        ".docs",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
    ];
};

export function enumFromStringValue<T>(
    enm: { [s: string]: T },
    value: string
): T | undefined {
    return (Object.values(enm) as unknown as string[]).includes(value)
        ? (value as unknown as T)
        : undefined;
}
export const getFileDisplayName = (f: IFile) => {
    return defaultTo(f.OriginalName, defaultTo(f.FileName, ""));
};
export const getHtmlCoordinates = (element: HTMLElement): HtmlCoordinates => {
    const htmlCoordinates: HtmlCoordinates = {
        left: element.offsetLeft,
        top: element.offsetTop,
    };
    return htmlCoordinates;
};
export const handleGeneralResponseError = (resp: AppResponse<any>) => {
    if (resp && !resp.Data) {
        if (resp.Message) {
            showSweetAlertToast(t("common.error.error"), resp.Message, "error");
        } else {
            showUnexpectedErrorToast();
        }
    }
};
export const thirdPartyCookieEnabled = (
    iFrameUri: string,
    onFail?: () => void,
    onSuccess?: () => void
) => {
    const frame: any = document.createElement("iframe");

    const messageHandler = (event: any) => {
        // check for trusted origins here
        if (typeof event.data === "object") {
            if (event.data.eventId == "cookieCheck") {
                let strData = event.data.response as string;
                if (!defaultTo(strData, "").startsWith("{")) {
                    strData = "{}";
                }
                try {
                    const data = JSON.parse(strData) as any;
                    if (data) {
                        if (data.result && onSuccess) {
                            onSuccess();
                        }
                        if (!data.result && onFail) {
                            onFail();
                        }
                    }
                } catch (e) {
                    console.log("checking", e);
                }
                window.removeEventListener("message", messageHandler);
                document.body.removeChild(frame);
            }
        }
    };
    window.removeEventListener("message", messageHandler);
    setTimeout(() => {
        window.addEventListener("message", messageHandler);
    }, 500);

    frame.src = iFrameUri;
    frame.sandbox = "allow-scripts allow-same-origin";
    frame.style = `display:none;opacity:0;z-index:-10`;
    frame.onload = (e: any) => {
        frame.contentWindow.postMessage(
            JSON.stringify({ test: "cookie" }),
            "*"
        );
    };
    document.body.appendChild(frame);
};
export function base64ToBlob(
    base64: string,
    type = "application/octet-stream"
) {
    const binStr = Buffer.from(base64, "base64");

    return new Blob([binStr], { type: type });
}

export function copyToClipboard(data: string) {
    if (!isNil(navigator.clipboard)) {
        navigator.clipboard.writeText(data);
    } else {
        const temp = document.createElement("input");
        document.body.append(temp);
        temp.value = data;
        temp.select();
        document.execCommand("copy");
        temp.remove();
    }
    return true;
}

export function getHumanFriendlySize(val: number): string {
    const res = byteSize(val);
    //{ value: '1.6', unit: 'kB', long: 'kilobytes' }
    return `${res.value} ${res.unit}`;
}

export function openUrlInTab(
    url: string,
    fileName: string = "download",
    target: string = "_blank"
) {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = target;
    anchor.download = fileName;
    anchor.click();
}

export function constructFormDataByObject<T>(value: T): FormData {
    const fD = new FormData();
    Object.keys(value as any).map((x) => {
        fD.append(x, (value as any)[x]);
    });

    return fD;
}

export function allOrValues(values: any[] | null, total: any[] | null) {
    return values == null ||
        values.length == 0 ||
        total == null ||
        total.length == values.length
        ? null // will return null for All
        : values;
}
export function getUniqueList<T>(list: T[], key?: keyof T): T[] {
    if (key) {
        return uniqBy(list, key);
    } else {
        return uniq(list);
    }
}

export function getPersonShortName(firstName: string, lastName: string) {
    let first = "",
        second = "";
    if (firstName && firstName.length > 0) {
        first = firstName.substring(0, Math.min(firstName.length, 2));
    }
    if (lastName && lastName.length > 0) {
        second = lastName.substring(0, Math.min(lastName.length, 2));
    }
    return `${first}${second}`;
}
export function sleep(milliseconds: number) {
    // let now = new Date();
    // const stop = now.getTime() + milliseconds;
    // // eslint-disable-next-line no-constant-condition
    // while (true) {
    //     now = new Date();
    //     if (now.getTime() > stop) {
    //         return;
    //     }
    // }
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
export function saveInStorage(key: string, data: any) {
    if (typeof Storage !== "undefined") {
        secureStorage.setItem(key, JSON.stringify(data));
        return true;
    } else {
        // Sorry! No Web Storage support..
        return false;
    }
}

export function loadFromStorage(key: string) {
    if (typeof Storage !== "undefined") {
        const found = secureStorage.getItem(key);
        try {
            return found ? JSON.parse(found) : found;
        } catch (e) {
            // wrong or corrupted data
            secureStorage.removeItem(key);
            return undefined;
        }
    }
    // Sorry! No Web Storage support..
    return undefined;
}
// equalityCheckKey consist of keys which might be string type but need to check with == instead of include like enum e.t.c
export function getFilteredArray<T extends BaseObjectInterface>(
    data: T[],
    filterObject: { [key in keyof T]?: any },
    equalityCheckKey?: string[],
    compareOnlyMonths?: string[]
): T[] {
    const filteredList = data.filter((value) => {
        let result = true;

        Object.keys(filterObject).some((key) => {
            //if value of the filter is null do nothing

            if (isNil(filterObject[key])) {
                return;
            }

            //if values are moment type
            if (
                filterObject[key] instanceof moment &&
                value[key] instanceof moment
            ) {
                if (
                    !(filterObject[key] as Moment).isSame(
                        value[key],
                        compareOnlyMonths?.includes(key) ? "month" : undefined
                    )
                ) {
                    result = false;
                }
            }
            //if value is not string but exist in equality check list then equal check
            else if (
                (equalityCheckKey && equalityCheckKey.includes(key)) ||
                (typeof filterObject[key] !== "string" &&
                    typeof value[key] !== "string")
            ) {
                if (filterObject[key] !== value[key]) {
                    result = false;
                }
            } else if (
                // if value is array check for any single match
                filterObject[key] instanceof Array &&
                !(value[key] instanceof Array)
            ) {
                if (!filterObject[key].includes(value[key])) {
                    result = false;
                }
            }
            //if value is string check contains
            else {
                if (!isNil(value[key])) {
                    if (
                        !(value as any)[key]
                            .toString()
                            .trim()
                            .toLocaleLowerCase()
                            .includes(
                                (filterObject as any)[key]
                                    .toString()
                                    .trim()
                                    .toLocaleLowerCase()
                            )
                    ) {
                        result = false;
                    }
                } else {
                    result = false;
                }
            }
        });
        return result;
    });

    return filteredList;
}

export const formatNumericValue = (
    value?: number,
    format: string = "0,0.00"
): string => {
    return numeral(defaultTo(value, 0)).format(format);
};

export const parseObject = (obj?: string | any): any => {
    return typeof obj === "string" ? JSON.parse(obj as string) : obj;
};
export const getIconByExtension = (extension?: string): string => {
    if (!extension || extension == "") {
        return ImageAssets.common.upload;
    }
    if (extension == "pdf") {
        return ImageAssets.documentManager.pdf;
    } else if (extension.includes("doc")) {
        return ImageAssets.documentManager.doc;
    } else {
        return ImageAssets.documentManager.images;
    }
};

export function isNumber(value: any, allowNull: boolean = false) {
    if (value != null && numeral.locale().toString().includes("de")) {
        return numeral(value).value() != null;
    } else {
        if (value == null && allowNull) {
            return true;
        }
        return numeral(value).value() < 0
            ? true
            : numeral.validate(value, numeral.locale())
            ? true // validate not working for negative values
            : numeral(value).value(); // for values containing string character validate returns false
    }
}

export const getParamName = (targetUrl: string) => {
    if (targetUrl && !isNil(targetUrl) && targetUrl.indexOf("/") != -1) {
        return `/${targetUrl.split("/").slice(3).join("/")}`;
    } else {
        return targetUrl;
    }
};

export const getReceiptName = (user: Person) => {
    let name = "";
    if (!isNil(user.FirstName)) {
        name = user.FirstName;
    }
    if (!isNil(user.LastName)) {
        name += ` ${user.LastName}`;
    }
    if (isNil(user.FirstName) && !isNil(user.Email)) {
        name = user.Email;
    }
    return name;
};

export const ellipseTextUsingLength = (name: string, length: number) => {
    if (name && name.length > length + 3) {
        return `${name.substring(0, length)}...`;
    }
    return name;
};
