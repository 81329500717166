import classNames from "classnames";
import { AppCustomHeaderTable } from "components/Table";
import { TimeTrackingHeaderData, TimeTrackingRecord } from "models";
import { UserType } from "models/general/enum";
import { Moment } from "moment-timezone";
import React from "react";
import {
    TimeManagementPersonDetailHeader,
    TimeManagementPersonDetailTable,
} from "../partials";
import { TimeManagementState } from "../TimeManagementMain";
import styles from "./TimeManagementPersonDetailView.module.scss";

export interface TimeManagementPersonDetailViewProps {
    values: TimeManagementState;
    onBackClick: () => void;
    refetchData?: () => void;
    onChange: (records: TimeTrackingRecord[]) => void;
    personId?: number;
    manualAllowed: boolean;
    date: Moment;
    className?: string;
}
export const TimeManagementPersonDetailView: React.FC<
    TimeManagementPersonDetailViewProps
> = ({
    values,
    manualAllowed,
    date,
    personId,
    className: rootClass,
    onBackClick,
    onChange,
    refetchData,
}) => {
    const { filterRequest, headerData, records } = values;

    const userType = values.filterRequest.UserType;
    const className =
        userType != UserType.Client ? styles.rootEmployee : styles.root;
    return (
        <AppCustomHeaderTable
            className={classNames(rootClass, styles.tableRoot)}
            header={
                <TimeManagementPersonDetailHeader
                    className={className}
                    manualAllowed={manualAllowed}
                    userType={userType}
                    onBack={() => onBackClick()}
                />
            }
            content={
                <TimeManagementPersonDetailTable
                    records={records as TimeTrackingRecord[]}
                    className={className}
                    headerData={headerData as TimeTrackingHeaderData}
                    personId={personId}
                    date={date}
                    manualAllowed={manualAllowed}
                    refetchData={refetchData}
                    filterRequest={filterRequest}
                    onItemChange={(item, idx) =>
                        onChange(
                            (records as TimeTrackingRecord[]).map(
                                (nf, index) => {
                                    if (
                                        nf.AccessId == item.AccessId ||
                                        (!nf.AccessId && index == idx)
                                    ) {
                                        return item;
                                    } else {
                                        return nf;
                                    }
                                }
                            )
                        )
                    }
                />
            }
        />
    );
};

export default TimeManagementPersonDetailView;
