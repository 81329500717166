import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { NavigationTab } from "models/general";
import {
    LW_Kindergarten_AccessControl,
    LW_Kindergarten_AgeGroups,
    LW_Kindergarten_BasicData,
    LW_Kindergarten_CareScopes,
    LW_Kindergarten_Contracts,
    LW_Kindergarten_Courses,
    LW_Kindergarten_Documents,
    LW_Kindergarten_EmployeeKeyCalculation,
    LW_Kindergarten_Functions,
    LW_Kindergarten_Groups,
    LW_Kindergarten_CompensationTemplates,
    // LW_Kindergarten_Kontos,
    LW_Kindergarten_PublicHolidays,
    LW_Kindergarten_Shifts,
    LW_Kindergarten_WorkingAreas,
    LW_Kindergarten_WorkingDays,
    LW_Kindergarten_IPads,
    LW_Kindergarten_MealTemplates,
    LW_Kindergarten_SalaryGroups,
    LW_Kindergarten_Settings,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export interface LwcKindergartenTabsProps {
    onActiveTabChange: (tab: NavigationTab) => void;
}
export const LwcKindergartenTabs: React.FC<LwcKindergartenTabsProps> = ({
    onActiveTabChange,
}) => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const { urlForLwc: iframeRouteForLwc } = useIFrameRouting();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.lillywait
        .clients()
        .screens.kindergartens.withId(lwcId);
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const getTranslation = (tabName: string) => t(`business.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(LW_Kindergarten_BasicData),
                url: linkBase.edit(),
                iframeUrl: iframeRouteForLwc("BasicData"), // to-do remove this and use BusinessEditFields
            },
            {
                label: getTranslation("contracts"),
                hasAccess: checkPermission([LW_Kindergarten_Contracts]),
                url: linkBase.contracts(),
            },
            {
                label: getTranslation("accessControl"),
                hasAccess: checkPermission(LW_Kindergarten_AccessControl),
                url: linkBase.accessControl.list(),
                iframeUrl: iframeRouteForLwc("AccessControl"),
            },
            {
                label: getTranslation("compensationTemplates"),
                hasAccess: checkPermission(
                    LW_Kindergarten_CompensationTemplates
                ),
                url: linkBase.compensationTemplates.list(),
            },
            {
                label: getTranslation("mealGroups"),
                hasAccess: checkPermission(LW_Kindergarten_MealTemplates),
                url: linkBase.mealTemplates.list(),
                iframeUrl: iframeRouteForLwc("MealTemplates"),
            },
            {
                label: getTranslation("employeeKeyCalculations"),
                hasAccess: checkPermission(
                    LW_Kindergarten_EmployeeKeyCalculation
                ),
                url: linkBase.employeeKeyCalculations.list(),
                iframeUrl: iframeRouteForLwc("EmployeeKeyCalculations"),
            },
            {
                label: getTranslation("careScopes"),
                hasAccess: checkPermission(LW_Kindergarten_CareScopes),
                url: linkBase.careScopes.list(),
            },
            {
                label: getTranslation("ageGroups"),
                hasAccess: checkPermission(LW_Kindergarten_AgeGroups),
                url: linkBase.ageGroups.list(),
            },
            {
                label: getTranslation("salaryGroups"),
                hasAccess: checkPermission(LW_Kindergarten_SalaryGroups),
                url: linkBase.salaryGroups.list(),
            },
            {
                label: getTranslation("careGroups"),
                hasAccess: checkPermission(LW_Kindergarten_Groups),
                url: linkBase.groups.list(),
                iframeUrl: iframeRouteForLwc("Groups"),
            },
            {
                label: getTranslation("courses"),
                hasAccess: checkPermission(LW_Kindergarten_Courses),
                url: linkBase.courses.list(),
            },
            {
                label: getTranslation("documents"),
                hasAccess: checkPermission(LW_Kindergarten_Documents),
                url: linkBase.documents(),
                iframeUrl: iframeRouteForLwc("Documents"),
            },
            {
                label: getTranslation("workingAreas"),
                hasAccess: checkPermission(LW_Kindergarten_WorkingAreas),
                url: linkBase.areas.list(),
                iframeUrl: iframeRouteForLwc("Areas"),
            },
            {
                label: getTranslation("shifts"),
                hasAccess: checkPermission(LW_Kindergarten_Shifts),
                url: linkBase.shifts.list(),
                iframeUrl: iframeRouteForLwc("Shifts"),
            },
            // {
            //     label: getTranslation("konto"),
            //     hasAccess: checkPermission(LW_Kindergarten_Kontos),
            //     url: linkBase.kontos(),
            // },
            {
                label: getTranslation("functions"),
                hasAccess: checkPermission(LW_Kindergarten_Functions),
                url: linkBase.functions.list(),
                iframeUrl: iframeRouteForLwc("Functions"),
            },
            {
                label: getTranslation("publicHolidays"),
                hasAccess: checkPermission(LW_Kindergarten_PublicHolidays),
                url: linkBase.publicHolidays(),
                iframeUrl: iframeRouteForLwc("Holidays"),
            },
            {
                label: getTranslation("openingHours"),
                hasAccess: checkPermission(LW_Kindergarten_WorkingDays),
                url: linkBase.workingHours(),
            },
            {
                label: getTranslation("settings"),
                hasAccess: checkPermission(LW_Kindergarten_BasicData),
                url: linkBase.basicSettings(),
            },
            {
                label: getTranslation("chatSettings"),
                hasAccess: checkPermission(LW_Kindergarten_Settings),
                url: linkBase.chatSettings(),
            },
            {
                label: getTranslation("timeTracking"),
                hasAccess: checkPermission(LW_Kindergarten_BasicData),
                url: linkBase.timeTracking(),
            },
            {
                label: getTranslation("ipads"),
                hasAccess: checkPermission(LW_Kindergarten_IPads),
                url: linkBase.ipads(),
                iframeUrl: iframeRouteForLwc("Ipads"),
            },
            {
                label: getTranslation("externalIntegration"),
                hasAccess: checkPermission(LW_Kindergarten_BasicData),
                url: linkBase.externalIntegration(),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={5}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default LwcKindergartenTabs;
