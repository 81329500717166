import { useRouting } from "hooks/general/routing";
import { getRegisterDefaultValue, RegisterRequest } from "models";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { AuthService, getAuthServiceKey } from "services";
import { RegisterFields } from "./fields";
import { AuthHeader } from "./header";

const EMPLOYEE_INFO_REGISTER_USER_ID_CACHE_PARAM_KEY = "userId";
export interface EmployeeInfoRegisterHeaderAndFieldsProps {
    setLoading: (loading: boolean) => void;
}

export const EmployeeInfoRegisterHeaderAndFields: React.FC<
    EmployeeInfoRegisterHeaderAndFieldsProps
> = ({ setLoading }) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const userId = searchParams.get(
        EMPLOYEE_INFO_REGISTER_USER_ID_CACHE_PARAM_KEY
    );

    const [state, setState] = useState(getRegisterDefaultValue());

    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isLoading: loading,
        data: response,
        refetch: getEmployeeRegisterInfo,
    } = useQuery(
        getAuthServiceKey("getEmployeeInfoRegister", { userId: userId }),
        async () =>
            userId && (await authService.getEmployeeInfoRegister(userId))
    );

    useEffect(() => {
        getEmployeeRegisterInfo();
    }, []);

    useEffect(() => {
        setLoading(loading);
        if (response && response.Data) {
            const res = response.Data;
            setState({
                ...state,
                Id: res.Id,
                FirstName: res.FirstName,
                LastName: res.LastName,
                Email: res.Email as string,
            });
        }
    }, [loading, response]);

    const {
        isLoading: setRegistrationLoading,
        data: postResponse,
        mutate: employeeRegisterInfo,
    } = useMutation(
        async (request: RegisterRequest) =>
            userId && (await authService.setEmployeeInfoRegister(request))
    );

    useEffect(() => {
        setLoading(setRegistrationLoading);
    }, [setLoading, setRegistrationLoading]);

    return (
        <>
            <AuthHeader
                mainHeading={t("auth.employeeInfoRegister.title")}
                pageTitle={t("auth.registration.pageTitle")}
            />
            <RegisterFields
                value={state}
                onChange={(val: RegisterRequest) => setState(val)}
                onClick={employeeRegisterInfo}
                response={postResponse ? postResponse : undefined}
                loading={setRegistrationLoading}
                isRegistrationComplete={true}
                redirectUrl={linkProvider.noAuth.screens.registerInfoCompleted()}
            />
        </>
    );
};

export default EmployeeInfoRegisterHeaderAndFields;
