import classNames from "classnames";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY } from "theme/themeConstants";
import styles from "./AppTableTotal.module.scss";

export interface AppTableTotalProps {
    text?: string;
    count?: number;
    total?: number;
    className?: string;
}
export const AppTableTotal: React.FC<AppTableTotalProps> = ({
    text,
    count = 0,
    total,
    className,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classNames(styles.root, className)}>
            <div className="d-flex">
                <div className={styles.totalText}>
                    {defaultTo(text, t("common.total"))}
                </div>
                <div
                    className={styles.countText}
                    style={{
                        color: count > 0 ? COLOR_PRIMARY : "",
                    }}
                >
                    {total ? `${count} / ${total}` : count}
                </div>
            </div>
        </div>
    );
};
