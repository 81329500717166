import { AppDeleteButton } from "components/Buttons";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY } from "theme/themeConstants";

export interface ClientContactActionMenuProps {
    onDelete: () => void;
    onAddNew: () => void;
    showDelete: boolean;
    showAddNew: boolean;
    deleteName: string;
    readonly: boolean;
}
export const ClientContactActionMenu: React.FC<
    ClientContactActionMenuProps
> = ({ onDelete, showDelete, onAddNew, showAddNew, readonly, deleteName }) => {
    const { t } = useTranslation();
    return !readonly ? (
        <div style={{ display: "flex" }}>
            {showAddNew && (
                <div
                    style={{
                        color: COLOR_PRIMARY,
                        marginRight: "15px",
                        textAlign: "end",
                        minWidth: "110px",
                        fontWeight: 500,
                        fontSize: "0.9rem",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onAddNew();
                    }}
                >
                    {t("common.addNew")}
                </div>
            )}{" "}
            {showDelete && (
                <AppDeleteButton
                    // style={{ marginRight: "10px" }}
                    onClick={() => {
                        onDelete();
                    }}
                    getConfirmation={deleteName.length > 1}
                    name={deleteName}
                >
                    {t("common.deleteText")}
                </AppDeleteButton>
            )}
        </div>
    ) : (
        <></>
    );
};

export default ClientContactActionMenu;
