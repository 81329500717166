import { AppOverlayLoader } from "components/Loaders";
import { SEARCH_DELAY_TIME } from "globals/constants";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { debounce, defaultTo } from "lodash-es";
import { ResponseMeta, Selectable, SortOrder } from "models/general";
import {
    MailboxListRequest,
    MessageNavigation,
    MessageSortColumn,
    MessageTemplate,
} from "models/messages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import MessageService, {
    getMessageServiceKey,
} from "services/business/MessageService";
import { useMessageLayoutContext } from "./MessagesLayout";
import styles from "./MessagesLayout.module.scss";
import { MessagesListPartial } from "./partials/messageLists";

type MailboxListMeta = ResponseMeta & { menu: MessageNavigation };
interface MessagesListState {
    messages: Selectable<MessageTemplate>[];
    meta: MailboxListMeta;
    loading: boolean;
    search?: string;
}

const PAGE_SIZE = 30;
export const MessagesList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { id: sessionBusinessId } = useSessionBusiness();
    const isCreate = location.pathname.endsWith("create");
    const isDetail = location.pathname.endsWith("details");

    const { activeMenu, refetchMessageCount, search } =
        useMessageLayoutContext();
    const queryClient = useQueryClient();
    const [state, setState] = useState<MessagesListState>({
        messages: [],
        meta: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
            HasNextPage: false,
            menu: activeMenu,
        } as MailboxListMeta,
        loading: false,
    });

    const refetchDebounce = useCallback(
        debounce((searchStr?: string) => {
            setState((old) => ({
                ...old,
                meta: {
                    ...old.meta,
                    PageNumber: 1,
                } as MailboxListMeta,
                search: defaultTo(searchStr, old.search),
            }));
        }, SEARCH_DELAY_TIME),
        [setState]
    );

    useEffect(() => {
        if (search != null && !isCreate && !isDetail) {
            refetchDebounce(search);
        }
    }, [search, refetchDebounce]);

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    useEffect(() => {
        setState((old) => ({
            ...old,
            meta: {
                ...old.meta,
                PageNumber:
                    activeMenu != old.meta.menu ? 1 : old.meta.PageNumber,
                menu: activeMenu,
            },
        }));
    }, [activeMenu]);
    const listRequest: MailboxListRequest = useMemo(() => {
        return {
            Page: state.meta.PageNumber,
            PageSize: PAGE_SIZE,
            SortOrder: SortOrder.DESC,
            SortColumn: MessageSortColumn.DateCreated,
            SearchTerm: state.search,
            ActiveMenu: state.meta.menu,
        };
    }, [state.meta, state.search]);

    const {
        isLoading,
        isFetching: loading,
        data: messagesResponse,
        refetch: refetchList,
    } = useQuery(
        getMessageServiceKey("getMessageTemplatesList", {
            ...listRequest,
            businessId: sessionBusinessId,
        }),
        async () => await messageService.getMessageTemplatesList(listRequest), // automatically refetch based on key change
        {
            cacheTime: getMillisecondsForMinutes(10),
            staleTime: 3000, //3sec
        }
    );

    useEffect(() => {
        if (
            (!loading || !isLoading) &&
            messagesResponse &&
            messagesResponse.Data
        ) {
            setState({
                ...state,
                messages:
                    messagesResponse?.Meta?.PageNumber == 1
                        ? messagesResponse.Data
                        : [...state.messages, ...messagesResponse.Data],
                meta: {
                    ...state.meta,
                    ...messagesResponse.Meta,
                } as MailboxListMeta,
                loading: false,
            });
        }
    }, [loading, messagesResponse]);
    const startLoading = () => setState({ ...state, loading: true });

    return (
        <div className={styles.cardStyling}>
            {state.loading && <AppOverlayLoader fullHeight={false} />}
            <MessagesListPartial
                messages={state.messages}
                activeMenu={activeMenu}
                meta={state.meta}
                loading={isLoading}
                refetchMessages={(
                    pageNumber: number,
                    refetchCount: boolean = false
                ) => {
                    if (pageNumber == state.meta.PageNumber) {
                        refetchList();
                    } else {
                        setState({
                            ...state,
                            meta: {
                                ...state.meta,
                                PageNumber: pageNumber,
                            } as MailboxListMeta,
                            loading: false,
                        });
                    }
                    if (refetchCount == true) {
                        refetchMessageCount();
                        queryClient.invalidateQueries(
                            getMessageServiceKey("getMessageTemplatesList")
                        );
                    }
                }}
                onChange={(newList, refetchCount = false) => {
                    setState({
                        ...state,
                        messages: newList,
                        loading: false,
                    });
                    if (refetchCount == true) {
                        refetchMessageCount();
                    }
                }}
                startLoading={startLoading}
            />
        </div>
    );
};

export default MessagesList;
