import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LinkExpired.module.scss";

export const LinkExpired: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.root}`}>
            <span className={styles.title}>{t("auth.linkExpired.title")}</span>
            <span className={styles.text}>{t("auth.linkExpired.text")}</span>
        </div>
    );
};

export default LinkExpired;
