import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styles from "./ChannelListSkeleton.module.scss";

export const ChannelListSkeleton = ({ count = 12 }: { count?: number }) => {
    return (
        <>
            {Array(count)
                .fill(count)
                .map((e, i) => (
                    <React.Fragment key={i}>
                        <Container className={styles.root}>
                            <div className={styles.left}>
                                <Skeleton
                                    circle={true}
                                    height={48}
                                    width={48}
                                />
                            </div>
                            <div className={styles.middle}>
                                <Skeleton
                                    count={2}
                                    style={{ paddingLeft: 30 }}
                                />
                            </div>
                            <div className={styles.right}>
                                <Skeleton count={2} />
                            </div>
                        </Container>
                    </React.Fragment>
                ))}
        </>
    );
};

export default ChannelListSkeleton;
