import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { TableSort } from "models/general";
import { BusinessShiftSortColumn } from "models/businessShifts";
import React from "react";
import { useTranslation } from "react-i18next";

export interface BusinessShiftSortingColumnsProps {
    onSortChange: (sort: TableSort<BusinessShiftSortColumn>) => void;
    sort: TableSort<BusinessShiftSortColumn>;
}
export const BusinessShiftSortingColumns: React.FC<
    BusinessShiftSortingColumnsProps
> = ({ onSortChange, sort }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("business.businessShift.name.name")}
                style={getFixedCssWidths(280)}
                sortColumn={BusinessShiftSortColumn.Name}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.Name
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("business.businessShift.startTime.name")}
                style={getFixedCssWidths(170)}
                sortColumn={BusinessShiftSortColumn.StartTime}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.StartTime
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("business.businessShift.endTime.name")}
                style={getFixedCssWidths(170)}
                sortColumn={BusinessShiftSortColumn.EndTime}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.EndTime
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("business.businessShift.workingArea.name")}
                style={getFixedCssWidths(250)}
                sortColumn={BusinessShiftSortColumn.WorkingAreaId}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.WorkingAreaId
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("common.startDate.name")}
                style={getFixedCssWidths(210)}
                sortColumn={BusinessShiftSortColumn.StartDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.StartDate
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("common.endDate.name")}
                style={getFixedCssWidths(210)}
                sortColumn={BusinessShiftSortColumn.EndDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BusinessShiftSortColumn.EndDate
                        ? sort.SortOrder
                        : null
                }
            />

            <th style={{ width: "70.5px", minWidth: "70.5px" }} />
        </tr>
    );
};

export default BusinessShiftSortingColumns;
