import { AppSwitch, ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { AppDialog } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import {
    defaultUserRoleItemValue,
    parseUserItemResponse,
    UserCreateEditRequest,
    UserRoleManagementItem,
    UserRoleManagementItemResponse,
    validateUser,
} from "models";
import {
    AppPlatforms,
    AppResponse,
    Optional,
    SelectItem,
    ValidityStateManager,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { GeneralService, getGeneralServiceKey } from "services";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business/BusinessGeneralService";
import UsersRoleManagementService, {
    getUsersRoleManagementServiceKey,
} from "services/business/UsersRoleManagementService";
import styles from "./UserManagementCreateEditDialog.module.scss";

export interface UserManagementCreateEditDialogProps {
    modalOpen: boolean;
    onClose: () => void;
    loading: boolean;
    onChange: (value: UserRoleManagementItem) => void;
    value: Optional<UserRoleManagementItem>;
    readonly?: boolean;
    title: string;
    deviceType: AppPlatforms;
}

export const UserManagementCreateEditDialog: React.FC<
    UserManagementCreateEditDialogProps
> = ({
    value,
    loading,
    modalOpen,
    onClose,
    onChange,
    title,
    readonly = false,
    deviceType,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const service = new UsersRoleManagementService(
        linkProvider.business.api.userManagement
    );
    const isEdit = value && value.Id;
    const generalService = new GeneralService(linkProvider.api.general);
    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const { isFetching: fetchingRoles, data: roles } = useQuery(
        getBusinessGeneralServiceKey("getBusinessRolesByDevice", deviceType),
        async () =>
            await businessGeneralService.getBusinessRolesByDevice(deviceType)
    );
    const {
        isFetching: gettingUserInfo,
        data: userInfoForEmail,
        refetch: getUserInfoForEmail,
    } = useQuery(
        getGeneralServiceKey("getUserInfo"),
        async () =>
            state?.Email && (await generalService.getUserInfo(state.Email)),
        { enabled: false }
    );

    const {
        data: createResponse,
        mutate: createUser,
        error: createError,
        isLoading: loadingCreate,
    } = useMutation(
        getUsersRoleManagementServiceKey("create"),
        async (req: UserCreateEditRequest) => await service.create(req)
    );
    const {
        isLoading: loadingUpdate,
        data: updateResponse,
        error: updateError,
        mutate: updateUser,
    } = useMutation(
        getUsersRoleManagementServiceKey("update"),
        async (req: UserCreateEditRequest) => await service.update(req)
    );

    const onSubmitHandler = (user: UserRoleManagementItem) => {
        const userCreateEditRequest: UserCreateEditRequest = {
            ...user,
            UserId: user.Id as string,
            ForDevice: deviceType.toString(),
        };

        if (isEdit) {
            updateUser(userCreateEditRequest);
        } else {
            createUser(userCreateEditRequest);
        }
    };
    const [state, setState] = useState<UserRoleManagementItem>(
        isEdit ? value : defaultUserRoleItemValue(deviceType)
    );
    const validityStateManager = useMemo(() => {
        const validationState = validateUser(state);
        return new ValidityStateManager(validationState);
    }, [state]);

    const onEmailBlurHandler = () => {
        if (
            !isNil(state.Email) &&
            !validityStateManager.getFirstErrorInfo("Email")
        ) {
            getUserInfoForEmail();
        }
    };
    useEffect(() => {
        if (!gettingUserInfo && userInfoForEmail && userInfoForEmail.Data) {
            const info = userInfoForEmail.Data;
            setState((old) => ({
                ...old,
                Name: info.Name,
                FirstName: info.FirstName,
                LastName: info.LastName,
                Id: info.UserId,
            }));
        }
    }, [gettingUserInfo]);

    const selectedRole = useMemo(() => {
        return (
            state.RoleId != null &&
            roles &&
            roles.Data &&
            roles.Data.find((x) => x.Value == state.RoleId)
        );
    }, [roles, state.RoleId]);

    const processMutationResponse = (
        response?: AppResponse<UserRoleManagementItemResponse>,
        error?: any,
        isCreate: boolean = false
    ) => {
        if (response) {
            if (response.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${response.Data.Name}" ${t(
                        `common.actions.${
                            isCreate
                                ? "createdSuccessfully"
                                : "updatedSuccessfully"
                        }`
                    )}`,
                    "success"
                );
                onChange(parseUserItemResponse(response.Data));
            } else if (response.Code != null) {
                let message = "Error";
                if (response.Errors && response.Errors.length > 0) {
                    message = response.Errors[0].Message;
                } else if (response.Message) {
                    message = response.Message;
                }
                showSweetAlertToast(t("common.error.error"), message, "error");
            }
        } else if (error) {
            showUnexpectedErrorToast();
        }
    };
    useEffect(() => {
        if (!loadingCreate) {
            processMutationResponse(createResponse, createError, true);
        }
    }, [createResponse, loadingCreate]);

    useEffect(() => {
        if (!loadingUpdate) {
            processMutationResponse(updateResponse, updateError);
        }
    }, [updateResponse, loadingUpdate]);

    return (
        <AppDialog title={title} onClose={onClose} modalOpen={modalOpen}>
            <Row>
                <Col xs={12}>
                    <AppInputField
                        label={t("roleManagement.user.email.name")}
                        placeholder={t("roleManagement.user.email.placeholder")}
                        disabled={!isNil(value?.Id) || readonly}
                        onBlur={() => onEmailBlurHandler()}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Email")}
                        value={state.Email}
                        onValueChange={(v) =>
                            setState((o) => ({
                                ...o,
                                Email: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={6} className="mt-1">
                    <AppInputField
                        label={t("roleManagement.user.firstName.name")}
                        placeholder={t(
                            "roleManagement.user.firstName.placeholder"
                        )}
                        disabled={state.Id != null || readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "FirstName"
                        )}
                        value={state.FirstName}
                        onValueChange={(v?: string) =>
                            setState((o) => ({
                                ...o,
                                FirstName: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={6}>
                    <AppInputField
                        label={t("roleManagement.user.lastName.name")}
                        placeholder={t(
                            "roleManagement.user.lastName.placeholder"
                        )}
                        disabled={state.Id != null || readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo(
                            "LastName"
                        )}
                        value={state.LastName}
                        onValueChange={(v) =>
                            setState((o) => ({
                                ...o,
                                LastName: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={12}>
                    <Form.Group controlId="roleId">
                        <Form.Label>
                            {t("roleManagement.user.permissionGroup.name")}
                        </Form.Label>
                        <AppSelectOld
                            menuPosition={"fixed"}
                            menuShouldBlockScroll={true}
                            options={roles && roles.Data}
                            loading={fetchingRoles}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            value={selectedRole}
                            isLoading={roles == null}
                            isDisabled={readonly}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    RoleId: e.Value as string,
                                })
                            }
                        />
                        <ErrorMessage
                            showEmpty={true}
                            errorInfo={validityStateManager.getFirstErrorInfo(
                                "RoleId"
                            )}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <AppSwitch
                        label={t("roleManagement.user.active")}
                        value={state.IsActive}
                        labelPosition="left"
                        color="blue-toggle"
                        disabled={readonly}
                        onChange={(checked) => {
                            setState({
                                ...state,
                                IsActive: checked,
                            });
                        }}
                    />
                </Col>
                <Col xs={12} className="mt-2">
                    <AppInputField
                        label={t("roleManagement.user.description")}
                        showEmptyError={true}
                        value={state.Description}
                        as="textarea"
                        type="textarea"
                        disabled={readonly}
                        classes={{ field: styles.textArea }}
                        onValueChange={(v) =>
                            setState((o) => ({
                                ...o,
                                Description: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={12} className={styles.footer}>
                    <Button className={styles.cancel} onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <Button
                        className={styles.saveBtn}
                        disabled={
                            loading ||
                            fetchingRoles ||
                            !validityStateManager.isStateValid() ||
                            readonly
                        }
                        onClick={() => {
                            onSubmitHandler({ ...state });
                        }}
                    >
                        {t("common.save")}
                    </Button>
                </Col>
            </Row>
        </AppDialog>
    );
};

export default UserManagementCreateEditDialog;
