import { ImageAssets } from "globals/images";
import i18next from "i18next";
import { defaultTo } from "lodash-es";
import moment from "moment";
import {
    COLOR_GREEN,
    COLOR_LIGHT_RED2,
    COLOR_ORANGE_LIGHT,
    COLOR_PRIMARY,
} from "theme/themeConstants";
import { NotificationStatus, WebNotificationType } from "./enum";
import { NotificationModel } from "./request";
import { NotificationResponse } from "./response";

export const translateTitleAndDetail = (
    model: NotificationModel,
    dateFormat: string
): NotificationModel => {
    const businessType = model.Meta.ForBusinessType.toString();
    const lowercaseBusinessType =
        businessType.toLocaleLowerCase();

    const documentArea = i18next.t(
        `notificationManagement.documentArea.${defaultTo(
            model.Meta.DocumentArea,
            ""
        )
            .toString()
            .toLocaleLowerCase()}`
    );
    const lowercaseDocumentArea = documentArea.toLocaleLowerCase();

    const translationBase = `notificationManagement.notificationMessages.${WebNotificationType[model.NotificationType]
        }`;

    const name = model.Meta && model.Meta.Name ? model.Meta.Name : "";

    return {
        ...model,
        Title: i18next.t(`${translationBase}.title`, {
            name,
            businessType: businessType,
        }),
        Detail: i18next.t(
            `${translationBase}.message.${model.StatusType.toString().toLocaleLowerCase()}`,
            {
                interpolation: {
                    escapeValue: false,
                },
                businessType: businessType,
                lowercaseBusinessType: lowercaseBusinessType,
                documentArea,
                lowercaseDocumentArea,
                name,
                dueDateTime:
                    model.Meta && model.Meta.DueDateTime != null
                        ? model.Meta.DueDateTime.format(dateFormat)
                        : "",
            }
        ),
    } as NotificationModel;
};

export const constructNotificationModel = (
    req: NotificationModel,
    dateFormat: string
): NotificationModel => {
    let iconUrl: string;

    switch (req.StatusType) {
        case NotificationStatus.INFO:
            iconUrl = ImageAssets.common.fileYellow;
            break;
        case NotificationStatus.FAILURE:
            iconUrl = ImageAssets.common.exclamationTriangleRed;
            break;
        case NotificationStatus.SUCCESS:
            iconUrl = ImageAssets.common.commentCheckGreen;
            break;
        case NotificationStatus.WARNING:
            iconUrl = ImageAssets.common.exclamationTriangleRed;
            break;
    }
    return translateTitleAndDetail(
        {
            ...req,
            IconUrl: iconUrl,
        } as NotificationModel,
        dateFormat
    );
};

export const constructStatusBaseImageAndColor = (
    req: NotificationModel
): NotificationModel => {
    let backgroundColor: string;
    let iconUrl: string;

    switch (req.StatusType) {
        case NotificationStatus.INFO:
            backgroundColor = COLOR_PRIMARY;
            iconUrl = ImageAssets.common.fileBlack;
            break;
        case NotificationStatus.FAILURE:
            backgroundColor = COLOR_LIGHT_RED2;
            iconUrl = ImageAssets.common.exclamationTriangleBlack;
            break;
        case NotificationStatus.SUCCESS:
            backgroundColor = COLOR_GREEN;
            iconUrl = ImageAssets.common.commentCheck;
            break;
        case NotificationStatus.WARNING:
            backgroundColor = COLOR_ORANGE_LIGHT;
            iconUrl = ImageAssets.common.exclamationTriangleBlack;
            break;
    }
    return {
        ...req,
        BackgroundColor: backgroundColor,
        IconUrl: iconUrl,
    } as NotificationModel;
};

export const getNotificationFromResponse = (
    req: NotificationResponse
): NotificationModel => {
    return {
        ...req,
        TimeStamp: moment.unix(req.TimeStamp),
        Meta: {
            ...req.Meta,
            DueDateTime:
                req.Meta.DueDateTime != null
                    ? moment.unix(req.Meta.DueDateTime)
                    : null,
        },
    } as NotificationModel;
};
