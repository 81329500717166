export const MessagesEnTranslations = {
    message: {
        multipleReceivers: "Multiple Receivers",
        allowAnswer: "Allow Answer",
        allReceipts: {
            message:
                "Are you sure you want to send the message to all recipients?",
            title: "Are you sure?",
        },
        body: {
            missing: "Message body is required.",
            name: "Text",
        },
        deletedSuccessfully: "Selected messages deleted successfully.",
        favorites: {
            markAsFavorites: "Message marked as favorite",
            removedFromFavorite: "Message removed from favorites",
        },
        files: {
            name: "Attachments",
        },
        for: "For",
        from: "From",
        inbox: "Inbox",
        messageListEmpty: "No message found",
        more: "More",
        movedToTrash: "Message successfully moved to trash.",
        selectedMovedToTrash: "Selected messages successfully moved to trash.",
        name: "Message",
        newMessage: "New Message",
        noMessage: "No messages found...",
        noMoreMessages: "No more messages...",
        others: "Others",
        receipts: {
            missing: "Message recipient is required.",
            name: "To",
            placeholder: "Recipients",
        },
        send: "Send",
        sendTo: "Sent To",
        sentMail: "Sent",
        starred: "Starred",
        status: "Status",
        title: {
            missing: "Title is required.",
            name: "Title",
            placeholder: "Title",
        },
        trash: "Trash",
    },
};

export default MessagesEnTranslations;
