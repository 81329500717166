import { AppTab, AppTabs } from "components/Tabs";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import {
    AuthTabModel,
    getLoginRegisterPageData,
    LoginRegisterTabs,
} from "models";
import { AppLocale } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import styles from "./LoginRegister.module.scss";
import { AuthHeader, AuthLoader, LoginFields, RequestHQDemo } from "./partials";

export const LoginRegister: React.FC = () => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const location = useLocation();

    let active: LoginRegisterTabs;
    const loginPath = linkProvider.noAuth.screens.login();
    // const getDemoPath = linkProvider.noAuth.screens.getDemo();
    // if (location.pathname.toLowerCase() === getDemoPath.toLowerCase()) {
    //     active = LoginRegisterTabs.REGISTER;
    // } else
    if (location.pathname.toLowerCase() === loginPath.toLowerCase()) {
        active = LoginRegisterTabs.LOGIN;
    } else {
        active = LoginRegisterTabs.SESSION_TIME_OUT;
    }

    const pageData: AuthTabModel = getLoginRegisterPageData(active);

    const AppTabFunc = (setLoading: (value: boolean) => void): AppTab[] => {
        return [
            {
                key: LoginRegisterTabs.LOGIN,
                title: t("auth.login.name"),
                isActive: active === LoginRegisterTabs.LOGIN,
                onButtonClick: () => {
                    navigate(loginPath);
                },
                content: <LoginFields setLoading={setLoading} />,
            },
            // {
            //     key: LoginRegisterTabs.REGISTER,
            //     title: t("auth.hqRegisterRequest.name"),
            //     isActive: active === LoginRegisterTabs.REGISTER,
            //     onButtonClick: () => {
            //         navigate(getDemoPath);
            //     },
            //     content: <RequestHQDemo setLoading={setLoading} />,
            // },
        ];
    };

    return (
        <AuthLoader
            renderContentWithLoader={(setLoading) => (
                <>
                    <AuthHeader
                        mainHeading={pageData.mainHeading}
                        pageTitle={pageData.pageTitle}
                    />
                    {/* {active !== LoginRegisterTabs.SESSION_TIME_OUT ? (
                        <AppTabs
                            tabs={AppTabFunc(setLoading)}
                            classes={
                                appLocale === AppLocale.GERMAN
                                    ? { tabButtonsContainer: styles.root }
                                    : undefined
                            }
                        />
                    ) : ( */}
                    {
                        AppTabFunc(setLoading).find(
                            (x) => x.key === LoginRegisterTabs.LOGIN
                        )?.content
                    }
                    {/* )} */}
                </>
            )}
        />
    );
};

export default LoginRegister;
