import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { AgeGroupTemplate, AgeGroupTemplateField } from "./model";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";

export function validateAgeGroupTemplateField(
    field: AgeGroupTemplateField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof AgeGroupTemplateField> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.name.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.shortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
        AgeStart: [
            {
                rule: Validations.REQUIRED,
                message: t("ageGroupTemplate.ageStart.missing"),
            },
            {
                rule: Validations.NUMBER,
                message: t("ageGroupTemplate.ageStart.invalid"),
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 1 },
                message: t("common.errorMessages.positiveNum"),
            },
            {
                rule: Validations.LESSER_THAN,
                options: { value: field.AgeEnd },
            },
        ],
        AgeEnd: [
            {
                rule: Validations.NUMBER,
                message: t("ageGroupTemplate.ageEnd.invalid"),
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: Math.max(field.AgeStart, 1) },
                message:
                    field.AgeStart <= 0
                        ? t("common.errorMessages.positiveNum")
                        : undefined,
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    return state;
}

export function validateAgeGroupTemplate(
    template: AgeGroupTemplate,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof AgeGroupTemplate> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateName.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateShortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        template,
        state
    );

    let manager = new ValidityStateManager(state);
    template.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateAgeGroupTemplateField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });

    return manager.state;
}
