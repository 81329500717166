import AppSelectOld from "components/AppSelect/AppSelectOld";
import { SimpleOption } from "components/AppSelect/partials";
import { AppDeleteButton } from "components/Buttons";
import ErrorMessage from "components/ErrorMessage";
import {
    AppInputField,
    AppNumberInput,
    AppSwitch,
} from "components/FormFields";
import { DragHandle } from "components/Sortable";
import { FORMAT_INTEGER } from "globals/constants";
import { defaultTo } from "lodash-es";
import { ValidityState, ValidityStateManager } from "models/general";
import {
    LwCompensationTemplateAppliesTo,
    LillywaitCompensationTemplateMainField,
    LWCompensationTemplateFieldCategory,
} from "models/compensationTemplate";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../CompensationTemplateFieldRow.module.scss";

export interface LwCompensationTemplatePaymentsFieldRowProps {
    value: LillywaitCompensationTemplateMainField;
    onChange: (field: LillywaitCompensationTemplateMainField) => void;
    validityState?: ValidityState;
    readonly?: boolean;
    onDelete?: (uuid: string) => void;
}

export const LwCompensationTemplatePaymentsFieldRow: React.FC<
    LwCompensationTemplatePaymentsFieldRowProps
> = ({ value, onChange, validityState, onDelete, readonly = false }) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    const options = Object.values(LwCompensationTemplateAppliesTo).map(
        (x) =>
            ({
                value: x,
                label:
                    x == LwCompensationTemplateAppliesTo.Client
                        ? t("common.client")
                        : t("common.employee"),
            } as SimpleOption)
    );
    const dummyOption = {
        label: t("common.pleaseSelect"),
        value: "",
    };

    const isRange = value.Category == LWCompensationTemplateFieldCategory.Range;

    const appliesToField = (
        <Form.Group controlId={"type-" + value.Uuid}>
            <Form.Label>{t("compensationTemplate.appliesTo")}</Form.Label>
            <AppSelectOld
                options={[dummyOption, ...options]}
                getOptionLabel={(opt: SimpleOption) => opt.label}
                getOptionValue={(opt: SimpleOption) => opt.value}
                value={
                    value.AppliesTo == null
                        ? dummyOption
                        : options.find((x) => x.value == value.AppliesTo)
                }
                isDisabled={!isRange || readonly}
                onChange={(val: SimpleOption) =>
                    onChange({
                        ...value,
                        AppliesTo:
                            val.value == ""
                                ? null
                                : (val.value as LwCompensationTemplateAppliesTo),
                    })
                }
            />
            <ErrorMessage
                showEmpty={true}
                errorInfo={validityStateManager.getFirstErrorInfo("AppliesTo")}
            />
        </Form.Group>
    );

    const rateField = (
        <AppNumberInput
            showEmptyError={true}
            isForCurrency={true}
            showError={true}
            allowZero={true}
            label={t("compensationTemplate.rate.name")}
            value={value.FieldValue?.Value}
            disabled={readonly}
            onChange={(val) =>
                onChange({
                    ...value,
                    FieldValue: {
                        ...defaultTo(value.FieldValue, {}),
                        Value: val,
                    },
                })
            }
            placeholder={t("compensationTemplate.rate.name")}
            error={validityStateManager.getFirstErrorInfo("Value")}
        />
    );

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                {isRange && (
                    <AppDeleteButton
                        className={styles.rowDel}
                        onClick={() => {
                            if (!readonly && onDelete) {
                                onDelete(value.Uuid);
                            }
                        }}
                    />
                )}
                <Row className={styles.valueField}>
                    {!isRange ? (
                        <>
                            <Col sm={6} xs={12} md={3}>
                                {appliesToField}
                            </Col>
                            <Col sm={6} xs={12} md={3}>
                                {rateField}
                            </Col>
                            <Col sm={6} xs={12} md={3}>
                                <AppNumberInput
                                    label={t(
                                        "compensationTemplate.minRate.name"
                                    )}
                                    showError={true}
                                    formatTheInitialValue={true}
                                    isForCurrency={true}
                                    showEmptyError={true}
                                    readOnly={readonly}
                                    allowZero={true}
                                    value={value.MinRate}
                                    onChange={(val) =>
                                        onChange({
                                            ...value,
                                            MinRate: defaultTo(val, 0),
                                        })
                                    }
                                    placeholder={t(
                                        "compensationTemplate.minRate.name"
                                    )}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "MinRate"
                                    )}
                                />
                            </Col>
                            <Col sm={6} xs={12} md={3}>
                                <AppNumberInput
                                    label={t(
                                        "compensationTemplate.maxRate.name"
                                    )}
                                    showError={true}
                                    showEmptyError={true}
                                    allowZero={true}
                                    formatTheInitialValue={true}
                                    value={value.MaxRate}
                                    isForCurrency={true}
                                    readOnly={readonly}
                                    onChange={(val) =>
                                        onChange({
                                            ...value,
                                            MaxRate: defaultTo(val, 0),
                                        })
                                    }
                                    placeholder={t(
                                        "compensationTemplate.maxRate.name"
                                    )}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "MaxRate"
                                    )}
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col sm={6} xs={12} md={6}>
                                <AppInputField
                                    label={t("templates.name.name")}
                                    showEmptyError={true}
                                    value={defaultTo(value.Name, "")}
                                    disabled={readonly}
                                    onValueChange={(val) => {
                                        onChange({
                                            ...value,
                                            Name: val,
                                        });
                                    }}
                                    placeholder={t("templates.name.name")}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "Name"
                                    )}
                                />
                            </Col>
                            <Col sm={6} xs={12} md={6}>
                                {appliesToField}
                            </Col>
                            <Col sm={4} xs={12} md={4}>
                                <AppNumberInput
                                    label={t(
                                        "compensationTemplate.rangeStart.name"
                                    )}
                                    showError={true}
                                    allowZero={true}
                                    showEmptyError={true}
                                    formatTheInitialValue={true}
                                    value={value.RangeStart}
                                    format={FORMAT_INTEGER}
                                    onChange={(val) =>
                                        onChange({
                                            ...value,
                                            RangeStart: defaultTo(val, 0),
                                        })
                                    }
                                    placeholder={t(
                                        "compensationTemplate.rangeStart.name"
                                    )}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "RangeStart"
                                    )}
                                />
                            </Col>
                            <Col
                                sm={4}
                                xs={12}
                                md={4}
                                className={`${styles.formGroupWithToggle}`}
                            >
                                {value.RangeEnd != null && (
                                    <AppNumberInput
                                        label={t(
                                            "compensationTemplate.rangeEnd.name"
                                        )}
                                        showError={true}
                                        allowZero={true}
                                        showEmptyError={true}
                                        formatTheInitialValue={true}
                                        format={FORMAT_INTEGER}
                                        value={value.RangeEnd}
                                        onChange={(val) =>
                                            onChange({
                                                ...value,
                                                RangeEnd: val,
                                            })
                                        }
                                        placeholder={t(
                                            "compensationTemplate.rangeEnd.name"
                                        )}
                                        error={validityStateManager.getFirstErrorInfo(
                                            "RangeEnd"
                                        )}
                                    />
                                )}
                                <AppSwitch
                                    label={t(
                                        "compensationTemplate.rangeEnd.noLimit"
                                    )}
                                    id={value.Uuid}
                                    className={styles.switch}
                                    disabled={readonly}
                                    value={value.RangeEnd == null}
                                    labelPosition="right"
                                    onChange={(checked) => {
                                        if (checked == true) {
                                            onChange({
                                                ...value,
                                                RangeEnd: null,
                                            });
                                        } else {
                                            onChange({
                                                ...value,
                                                RangeEnd: value.RangeStart,
                                            });
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={4} xs={12} md={4}>
                                {rateField}
                            </Col>
                        </>
                    )}
                </Row>
            </div>
            {isRange && !readonly && <DragHandle />}
        </div>
    );
};

export default LwCompensationTemplatePaymentsFieldRow;
