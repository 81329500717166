import AppInputField from "components/FormFields/AppInputField";
import { TIME_FORMAT_24H, TIME_FORMAT_12H } from "globals/constants";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import i18next from "i18next";
import { defaultTo, isNil } from "lodash-es";
import { AppLocale, ErrorInfo, Optional } from "models/general";
import moment from "moment";
import $ from "jquery";
import { Image } from "react-bootstrap";
import { Moment } from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AppReactDatePickerBase,
    AppReactDatePickerBaseProps,
} from "./partials";

const jsExternal: any = $;
export interface AppTimePickerProps {
    onChange: (newDate: Optional<Moment>) => void;
    label?: string;
    placeholder?: string;
    value: Optional<Moment>;
    format?: "12h" | "24h";
    error?: ErrorInfo;
    readOnly?: boolean;
    showEmptyError?: boolean;
    useDefault?: boolean;
    showIcon?: boolean;
    className?: string;
    step?: number;
}

export function AppTimePicker(props: AppTimePickerProps) {
    const {
        onChange,
        label,
        readOnly = false,
        placeholder = i18next.t("common.time"),
        value,
        className,
        showIcon = true,
        useDefault = true,
        showEmptyError = true,
        format = "24h",
        error = null,
        step = 1,
    } = props;

    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const timeFormat =
        format == "12h" || appLocale == AppLocale.ENGLISH
            ? TIME_FORMAT_12H
            : TIME_FORMAT_24H;
    const propsRef = useRef<AppTimePickerProps>(props);
    propsRef.current = props;

    const inputRef = useRef(null);
    const [reRender, setReRender] = useState(false);

    // let [date, setDate] = useState(value.format(DATEPICKER_DATE_FORMAT));
    const [pickerOpen, setPickerOpen] = useState(false);

    useEffect(() => {
        setReRender(true);
    }, [appLocale]);
    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);
    useEffect(() => {
        if (useDefault && isNil(value)) {
            onChange(moment().seconds(0));
        }
        if (inputRef.current && !readOnly) {
            jsExternal(inputRef.current)
                .datetimepicker({
                    format: timeFormat,
                    keepOpen: true,
                    stepping: step,
                    locale: appLocale,
                    // debug: true,
                })
                .on("dp.change", (newDate: any) => {
                    // for some reason it returns old value in newDate.date if I don't re initialize
                    // let newVal = moment.unix(newDate.date.unix()) as Moment; //moment object
                    const newVal = newDate.date as Moment; //moment object

                    // console.log("changed", newVal.format(TIME_FORMAT_24H));
                    propsRef.current.onChange(
                        // only set time part not date because otherwise after 12AM date changes and cause validation issues
                        moment(newVal.format(TIME_FORMAT_24H), TIME_FORMAT_24H)
                    );
                })
                .on("dp.show", () => {
                    setPickerOpen(true);
                })
                .on("dp.hide", () => {
                    setTimeout(() => {
                        setPickerOpen(false);
                    }, 300);
                });
        }
    }, [readOnly, inputRef.current]);
    return !reRender ? (
        <AppInputField
            label={label}
            value={
                useDefault || value
                    ? defaultTo(value, moment()).format(timeFormat)
                    : ""
            }
            ref={inputRef}
            placeholder={placeholder}
            ignoreKeyPress={true}
            onValueChange={() => {}}
            readOnly={readOnly}
            error={error}
            className={className}
            showEmptyError={showEmptyError}
            rightIcon={
                showIcon ? (
                    <Image
                        style={{
                            width: "20px",
                            height: "20px",
                            top: "11px",
                        }}
                        src={ImageAssets.common.clock}
                        // className={defaultTo(
                        //     iconClass?.iconImage,
                        //     ""
                        // )}
                        onClick={(e) => {
                            e.preventDefault();
                            if (inputRef.current && !readOnly) {
                                if (!pickerOpen) {
                                    jsExternal(inputRef.current).focus();
                                }
                            }
                            if (e) {
                                e.stopPropagation();
                            }
                            return false;
                        }}
                    />
                ) : (
                    <></>
                )
            }
        />
    ) : (
        <></>
    );
}

export default AppTimePicker;
