import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppDropdownMenu, { DropdownMenuItem } from "components/AppDropdownMenu";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import AppButton, { AppButtonProps } from "./AppButton";
import AppIconTextButton from "./AppIconTextButton";
import styles from "./AppMenuButton.module.scss";

export interface AppMenuButtonProps
    extends Omit<AppButtonProps, "padding" | "children"> {
    menuItems: DropdownMenuItem[];
    icon?: IconName;
    text?: string;
}

export const AppMenuButton: React.FC<AppMenuButtonProps> = ({
    menuItems,
    className,
    icon = "plus",
    text,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <div className={`d-flex ${className}`}>
            <AppIconTextButton
                icon={icon}
                text={defaultTo(text, t("common.create"))}
                className={styles.createBtn}
                padding="default"
                {...rest}
            />
            {menuItems && menuItems.length > 0 && (
                <AppDropdownMenu
                    trigger={
                        <AppButton
                            className={styles.optionBtn}
                            padding="default"
                        >
                            <FontAwesomeIcon icon={"angle-down"} />
                        </AppButton>
                    }
                    menuItems={menuItems}
                />
            )}
        </div>
    );
};

export default AppMenuButton;
