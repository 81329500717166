import IFrameContent from "components/IFrameContent";
import {
    LW_ElderlyCare_BasicData,
    LW_ElderlyCare_Contracts,
    LW_ElderlyCare_Documents,
    LW_ElderlyCare_PublicHolidays,
    LW_Kindergarten_AccessControl,
    LW_Kindergarten_AgeGroups,
    LW_Kindergarten_BasicData,
    LW_Kindergarten_CareScopes,
    LW_Kindergarten_Contracts,
    LW_Kindergarten_Courses,
    LW_Kindergarten_Documents,
    LW_Kindergarten_EmployeeKeyCalculation,
    LW_Kindergarten_Functions,
    LW_Kindergarten_Groups,
    LW_Kindergarten_CompensationTemplates,
    // LW_Kindergarten_Kontos,
    LW_Kindergarten_PublicHolidays,
    LW_Kindergarten_Shifts,
    LW_Kindergarten_WorkingAreas,
    LW_Kindergarten_WorkingDays,
    LW_School_BasicData,
    LW_School_Contracts,
    LW_School_Documents,
    LW_School_PublicHolidays,
    LW_School_WorkingDays,
    PermissionAccessTypes,
    LW_Kindergarten_IPads,
    LW_Kindergarten_MealTemplates,
    LW_Kindergarten_SalaryGroups,
    LW_Industry_AccessControl,
    LW_Industry_BasicData,
    LW_Industry_Contracts,
    LW_Industry_Courses,
    LW_Industry_Documents,
    LW_Industry_Functions,
    LW_Industry_Groups,
    LW_Industry_IPads,
    LW_Industry_MealTemplates,
    LW_Industry_PublicHolidays,
    LW_Industry_Shifts,
    LW_Industry_WorkingAreas,
    LW_Industry_WorkingDays,
    LW_ElderlyCare_WorkingDays,
    LW_Kindergarten_Settings,
    LW_Industry_Settings,
} from "models";
import {
    Feature_LW_Business_ElderlyCare_Contracts,
    Feature_LW_Business_ElderlyCare_Details,
    Feature_LW_Business_ElderlyCare_Documents,
    Feature_LW_Business_ElderlyCare_Holidays,
    Feature_LW_Business_ElderlyCare_WorkingHours,
    Feature_LW_Business_Kindergarten_AccessControl,
    Feature_LW_Business_Kindergarten_AccessControl_ViewUpdate,
    Feature_LW_Business_Kindergarten_AgeGroup,
    Feature_LW_Business_Kindergarten_AgeGroup_AddUpdate,
    Feature_LW_Business_Kindergarten_CareScope,
    Feature_LW_Business_Kindergarten_CareScope_AddUpdate,
    Feature_LW_Business_Kindergarten_Contracts,
    Feature_LW_Business_Kindergarten_Courses,
    Feature_LW_Business_Kindergarten_Courses_AddUpdate,
    Feature_LW_Business_Kindergarten_Details,
    Feature_LW_Business_Kindergarten_Document,
    Feature_LW_Business_Kindergarten_EmployeeKey,
    Feature_LW_Business_Kindergarten_EmployeeKey_AddUpdate,
    Feature_LW_Business_Kindergarten_Functions,
    Feature_LW_Business_Kindergarten_Functions_AddUpdate,
    Feature_LW_Business_Kindergarten_Group,
    Feature_LW_Business_Kindergarten_Group_AddUpdate,
    Feature_LW_Business_Kindergarten_Holidays,
    Feature_LW_Business_Kindergarten_Konto,
    Feature_LW_Business_Kindergarten_Shift,
    Feature_LW_Business_Kindergarten_Shift_AddUpdate,
    Feature_LW_Business_Kindergarten_CompensationTemplates,
    Feature_LW_Business_Kindergarten_CompensationTemplates_AddUpdate,
    Feature_LW_Business_Kindergarten_WorkingArea,
    Feature_LW_Business_Kindergarten_WorkingArea_AddUpdate,
    Feature_LW_Business_Kindergarten_WorkingHours,
    Feature_LW_Business_School_Contracts,
    Feature_LW_Business_School_Details,
    Feature_LW_Business_School_Document,
    Feature_LW_Business_School_Holidays,
    Feature_LW_Business_School_WorkingHours,
    Feature_LW_Clients_ElderlyCares,
    Feature_LW_Clients_Kindergartens,
    Feature_LW_Clients_Schools,
    Feature_LW_Business_Kindergarten_IPads,
    Feature_LW_Business_Kindergarten_MealTemplates,
    Feature_LW_Business_Kindergarten_MealTemplates_AddUpdate,
    Feature_LW_Business_Kindergarten_SalaryGroup,
    Feature_LW_Business_Kindergarten_SalaryGroup_AddUpdate,
    Feature_LW_Business_Industry_AccessControl,
    Feature_LW_Business_Industry_AccessControl_ViewUpdate,
    Feature_LW_Business_Industry_Contracts,
    Feature_LW_Business_Industry_Courses,
    Feature_LW_Business_Industry_Courses_AddUpdate,
    Feature_LW_Business_Industry_Details,
    Feature_LW_Business_Industry_Document,
    Feature_LW_Business_Industry_Functions,
    Feature_LW_Business_Industry_Functions_AddUpdate,
    Feature_LW_Business_Industry_Group,
    Feature_LW_Business_Industry_Group_AddUpdate,
    Feature_LW_Business_Industry_Holidays,
    Feature_LW_Business_Industry_IPads,
    Feature_LW_Business_Industry_Shift,
    Feature_LW_Business_Industry_Shift_AddUpdate,
    Feature_LW_Business_Industry_WorkingArea,
    Feature_LW_Business_Industry_WorkingArea_AddUpdate,
    Feature_LW_Business_Industry_WorkingHours,
    Feature_LW_Clients_Industries,
    Feature_LW_Business_Kindergarten_Settings,
    Feature_LW_Business_Kindergarten_Chat_Settings,
    Feature_LW_Business_Industry_Settings,
    Feature_LW_Business_Industry_Chat_Settings,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import {
    BusinessCompensationTemplateCreate,
    BusinessCompensationTemplateEdit,
    BusinessCompensationTemplateList,
} from "screens/business/settingTabs/compensationTemplate";
import {
    BusinessAgeGroupTemplateCreate,
    BusinessAgeGroupTemplateEdit,
    BusinessAgeGroupTemplateList,
    BusinessBasicSettings,
    BusinessCareScopeTemplateCreate,
    BusinessCareScopeTemplateEdit,
    BusinessCareScopeTemplateList,
    BusinessChatSettingsLayout,
    BusinessContracts,
    BusinessEmpKeyTemplateCreate,
    BusinessEmpKeyTemplateEdit,
    BusinessEmpKeyTemplateList,
    BusinessExternalIntegration,
    BusinessSalaryGroupTemplateCreate,
    BusinessSalaryGroupTemplateEdit,
    BusinessSalaryGroupTemplateList,
    BusinessShiftCreate,
    BusinessShiftEdit,
    BusinessShiftList,
    BusinessWorkingDays,
    TimeTrackingSettings,
} from "screens/business/settingTabs";
import { BusinessKontoList } from "screens/business/settingTabs/kontos";
import Dummy from "screens/Dummy";
import { LwcEdit } from "screens/lillywait/common";
import {
    ElderlyCares,
    Kindergartens,
    LwcElderlyCareEditLayout,
    LwcKindergartenEditLayout,
    LwcSchoolEditLayout,
    Schools,
} from "screens/lillywait/lwClients";
import lillywaitClientRoutes from "./routes";
import { BusinessCoursesList } from "screens/business/settingTabs/courses/BusinessCoursesList";
import { BusinessCoursesCreateEdit } from "screens/business/settingTabs/courses";
import { BusinessIPadsList } from "screens/business/settingTabs/iPads";
import {
    Industries,
    LwcIndustryEditLayout,
} from "screens/lillywait/lwClients/industry";

const relativeRoutes = lillywaitClientRoutes(false).screens;
const fullRoutes = lillywaitClientRoutes().screens;

const KindergartenRoutesMap = {
    [relativeRoutes.kindergartens.withId().base()]: {
        component: LwcKindergartenEditLayout,
        fullPattern: fullRoutes.kindergartens.withId().base(),
        pattern: relativeRoutes.kindergartens.withId().base(),
        permKeys: [LW_Kindergarten_BasicData],
        featureKeys: [Feature_LW_Clients_Kindergartens],
        nestedScreens: {
            [relativeRoutes.kindergartens.withId().edit()]: {
                component: LwcEdit,
                index: true,
                pattern: relativeRoutes.kindergartens.withId().edit(),
                fullPattern: fullRoutes.kindergartens.withId().edit(),
                permKeys: [LW_Kindergarten_BasicData],
                featureKeys: [Feature_LW_Business_Kindergarten_Details],
            },
            [relativeRoutes.kindergartens.withId().contracts()]: {
                component: BusinessContracts,
                pattern: relativeRoutes.kindergartens.withId().contracts(),
                fullPattern: fullRoutes.kindergartens.withId().contracts(),
                featureKeys: [Feature_LW_Business_Kindergarten_Contracts],
                permKeys: [LW_Kindergarten_Contracts],
            },
            [relativeRoutes.kindergartens.withId().publicHolidays()]: {
                component: IFrameContent,
                pattern: relativeRoutes.kindergartens.withId().publicHolidays(),
                fullPattern: fullRoutes.kindergartens.withId().publicHolidays(),
                featureKeys: [Feature_LW_Business_Kindergarten_Holidays],
                permKeys: [LW_Kindergarten_PublicHolidays],
            },
            [relativeRoutes.kindergartens.withId().documents()]: {
                component: IFrameContent,
                pattern: relativeRoutes.kindergartens.withId().documents(),
                fullPattern: fullRoutes.kindergartens.withId().documents(),
                permKeys: [LW_Kindergarten_Documents],
                featureKeys: [Feature_LW_Business_Kindergarten_Document],
            },
            [relativeRoutes.kindergartens.withId().workingHours()]: {
                component: BusinessWorkingDays,
                pattern: relativeRoutes.kindergartens.withId().workingHours(),
                fullPattern: fullRoutes.kindergartens.withId().workingHours(),
                permKeys: [LW_Kindergarten_WorkingDays],
                featureKeys: [Feature_LW_Business_Kindergarten_WorkingHours],
            },
            [relativeRoutes.kindergartens.withId().ipads()]: {
                component: BusinessIPadsList,
                pattern: relativeRoutes.kindergartens.withId().ipads(),
                fullPattern: fullRoutes.kindergartens.withId().ipads(),
                permKeys: [LW_Kindergarten_IPads],
                featureKeys: [Feature_LW_Business_Kindergarten_IPads],
            },
            [relativeRoutes.kindergartens.withId().basicSettings()]: {
                component: BusinessBasicSettings,
                pattern: relativeRoutes.kindergartens.withId().basicSettings(),
                fullPattern: fullRoutes.kindergartens.withId().basicSettings(),
                permKeys: [LW_Kindergarten_Settings],
                featureKeys: [Feature_LW_Business_Kindergarten_Settings],
            },
            [relativeRoutes.kindergartens.withId().externalIntegration()]: {
                component: BusinessExternalIntegration,
                pattern: relativeRoutes.kindergartens.withId().externalIntegration(),
                fullPattern: fullRoutes.kindergartens.withId().externalIntegration(),
                permKeys: [LW_Kindergarten_BasicData],
                featureKeys: [Feature_LW_Business_Kindergarten_Details],
            },
            [relativeRoutes.kindergartens.withId().chatSettings()]: {
                component: BusinessChatSettingsLayout,
                pattern: relativeRoutes.kindergartens.withId().chatSettings(),
                fullPattern: fullRoutes.kindergartens.withId().chatSettings(),
                permKeys: [LW_Kindergarten_Settings],
                featureKeys: [Feature_LW_Business_Kindergarten_Chat_Settings],
            },
            [relativeRoutes.kindergartens.withId().timeTracking()]: {
                component: TimeTrackingSettings,
                pattern: relativeRoutes.kindergartens.withId().timeTracking(),
                fullPattern: fullRoutes.kindergartens.withId().timeTracking(),
                permKeys: [LW_Kindergarten_Settings],
                featureKeys: [Feature_LW_Business_Kindergarten_Settings],
            },
            [relativeRoutes.kindergartens.withId().accessControl.list()]: {
                component: IFrameContent,
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .accessControl.list(),
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .accessControl.list(),
                featureKeys: [Feature_LW_Business_Kindergarten_AccessControl],
                permKeys: [LW_Kindergarten_AccessControl],
            },
            [relativeRoutes.kindergartens.withId().accessControl.create()]: {
                component: Dummy,
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .accessControl.create(),
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .accessControl.create(),
                featureKeys: [
                    Feature_LW_Business_Kindergarten_AccessControl_ViewUpdate,
                ],
                permKeys: [LW_Kindergarten_AccessControl],
            },
            [relativeRoutes.kindergartens.withId().accessControl.edit()]: {
                component: Dummy,
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .accessControl.edit(),
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .accessControl.edit(),
                featureKeys: [
                    Feature_LW_Business_Kindergarten_AccessControl_ViewUpdate,
                ],
                permKeys: [LW_Kindergarten_AccessControl],
            },
            [relativeRoutes.kindergartens
                .withId()
                .compensationTemplates.list()]: {
                component: BusinessCompensationTemplateList,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .compensationTemplates.list(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .compensationTemplates.list(),
                permKeys: [LW_Kindergarten_CompensationTemplates],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_CompensationTemplates,
                ],
            },
            [relativeRoutes.kindergartens
                .withId()
                .compensationTemplates.create()]: {
                component: BusinessCompensationTemplateCreate,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .compensationTemplates.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .compensationTemplates.create(),
                permKeys: [LW_Kindergarten_CompensationTemplates],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_CompensationTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens
                .withId()
                .compensationTemplates.edit()]: {
                component: BusinessCompensationTemplateEdit,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .compensationTemplates.edit(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .compensationTemplates.edit(),
                permKeys: [LW_Kindergarten_CompensationTemplates],
                permAccessTypes: [PermissionAccessTypes.EDIT],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_CompensationTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens
                .withId()
                .employeeKeyCalculations.list()]: {
                component: BusinessEmpKeyTemplateList,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.list(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.list(),
                permKeys: [LW_Kindergarten_EmployeeKeyCalculation],
                featureKeys: [Feature_LW_Business_Kindergarten_EmployeeKey],
            },
            [relativeRoutes.kindergartens
                .withId()
                .employeeKeyCalculations.create()]: {
                component: BusinessEmpKeyTemplateCreate,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_EmployeeKeyCalculation],
                featureKeys: [Feature_LW_Business_Kindergarten_EmployeeKey],
            },
            [relativeRoutes.kindergartens
                .withId()
                .employeeKeyCalculations.edit()]: {
                component: BusinessEmpKeyTemplateEdit,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.edit(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .employeeKeyCalculations.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_EmployeeKeyCalculation],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_EmployeeKey_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().ageGroups.list()]: {
                component: BusinessAgeGroupTemplateList,
                fullPattern: fullRoutes.kindergartens.withId().ageGroups.list(),
                pattern: relativeRoutes.kindergartens.withId().ageGroups.list(),
                permKeys: [LW_Kindergarten_AgeGroups],
                featureKeys: [Feature_LW_Business_Kindergarten_AgeGroup],
            },
            [relativeRoutes.kindergartens.withId().ageGroups.create()]: {
                component: BusinessAgeGroupTemplateCreate,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .ageGroups.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .ageGroups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_AgeGroups],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_AgeGroup_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().ageGroups.edit()]: {
                component: BusinessAgeGroupTemplateEdit,
                fullPattern: fullRoutes.kindergartens.withId().ageGroups.edit(),
                pattern: relativeRoutes.kindergartens.withId().ageGroups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_AgeGroups],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_AgeGroup_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().salaryGroups.list()]: {
                component: BusinessSalaryGroupTemplateList,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .salaryGroups.list(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .salaryGroups.list(),
                permKeys: [LW_Kindergarten_SalaryGroups],
                featureKeys: [Feature_LW_Business_Kindergarten_SalaryGroup],
            },
            [relativeRoutes.kindergartens.withId().salaryGroups.create()]: {
                component: BusinessSalaryGroupTemplateCreate,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .salaryGroups.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .salaryGroups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_SalaryGroups],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_SalaryGroup_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().salaryGroups.edit()]: {
                component: BusinessSalaryGroupTemplateEdit,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .salaryGroups.edit(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .salaryGroups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_SalaryGroups],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_SalaryGroup_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().careScopes.list()]: {
                component: BusinessCareScopeTemplateList,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .careScopes.list(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .careScopes.list(),
                permKeys: [LW_Kindergarten_CareScopes],
                featureKeys: [Feature_LW_Business_Kindergarten_CareScope],
            },
            [relativeRoutes.kindergartens.withId().careScopes.create()]: {
                component: BusinessCareScopeTemplateCreate,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .careScopes.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .careScopes.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_CareScopes],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_CareScope_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().careScopes.edit()]: {
                component: BusinessCareScopeTemplateEdit,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .careScopes.edit(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .careScopes.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_CareScopes],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_CareScope_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().groups.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.kindergartens.withId().groups.list(),
                pattern: relativeRoutes.kindergartens.withId().groups.list(),
                permKeys: [LW_Kindergarten_Groups],
                featureKeys: [Feature_LW_Business_Kindergarten_Group],
            },
            [relativeRoutes.kindergartens.withId().groups.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens.withId().groups.create(),
                pattern: relativeRoutes.kindergartens.withId().groups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_Groups],
                featureKeys: [Feature_LW_Business_Kindergarten_Group_AddUpdate],
            },
            [relativeRoutes.kindergartens.withId().groups.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens.withId().groups.edit(),
                pattern: relativeRoutes.kindergartens.withId().groups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_Groups],
                featureKeys: [Feature_LW_Business_Kindergarten_Group_AddUpdate],
            },
            [relativeRoutes.kindergartens.withId().courses.list()]: {
                component: BusinessCoursesList,
                fullPattern: fullRoutes.kindergartens.withId().courses.list(),
                pattern: relativeRoutes.kindergartens.withId().courses.list(),
                permKeys: [LW_Kindergarten_Courses],
                featureKeys: [Feature_LW_Business_Kindergarten_Courses],
            },
            [relativeRoutes.kindergartens.withId().courses.create()]: {
                component: BusinessCoursesCreateEdit,
                fullPattern: fullRoutes.kindergartens.withId().courses.create(),
                pattern: relativeRoutes.kindergartens.withId().courses.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_Courses],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_Courses_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().courses.edit()]: {
                component: BusinessCoursesCreateEdit,
                fullPattern: fullRoutes.kindergartens.withId().courses.edit(),
                pattern: relativeRoutes.kindergartens.withId().courses.edit(),
                permKeys: [LW_Kindergarten_Courses],
                permAccessTypes: [PermissionAccessTypes.EDIT],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_Courses_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().areas.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.kindergartens.withId().areas.list(),
                pattern: relativeRoutes.kindergartens.withId().areas.list(),
                permKeys: [LW_Kindergarten_WorkingAreas],
                featureKeys: [Feature_LW_Business_Kindergarten_WorkingArea],
            },
            [relativeRoutes.kindergartens.withId().areas.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens.withId().areas.create(),
                pattern: relativeRoutes.kindergartens.withId().areas.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_WorkingAreas],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_WorkingArea_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().areas.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens.withId().areas.edit(),
                pattern: relativeRoutes.kindergartens.withId().areas.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_WorkingAreas],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_WorkingArea_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().mealTemplates.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .mealTemplates.list(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .mealTemplates.list(),
                permKeys: [LW_Kindergarten_MealTemplates],
                featureKeys: [Feature_LW_Business_Kindergarten_MealTemplates],
            },
            [relativeRoutes.kindergartens.withId().mealTemplates.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .mealTemplates.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .mealTemplates.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_MealTemplates],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().mealTemplates.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .mealTemplates.edit(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .mealTemplates.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_MealTemplates],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().mealTemplates.meals()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .mealTemplates.meals(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .mealTemplates.meals(),
                permKeys: [LW_Kindergarten_MealTemplates],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().kontos()]: {
                component: BusinessKontoList,
                fullPattern: fullRoutes.kindergartens.withId().kontos(),
                pattern: relativeRoutes.kindergartens.withId().kontos(),
                // permKeys: [LW_Kindergarten_Kontos],
                featureKeys: [Feature_LW_Business_Kindergarten_Konto],
            },
            [relativeRoutes.kindergartens.withId().shifts.list()]: {
                component: BusinessShiftList,
                fullPattern: fullRoutes.kindergartens.withId().shifts.list(),
                pattern: relativeRoutes.kindergartens.withId().shifts.list(),
                permKeys: [LW_Kindergarten_Shifts],
                featureKeys: [Feature_LW_Business_Kindergarten_Shift],
            },
            [relativeRoutes.kindergartens.withId().shifts.create()]: {
                component: BusinessShiftCreate,
                fullPattern: fullRoutes.kindergartens.withId().shifts.create(),
                pattern: relativeRoutes.kindergartens.withId().shifts.create(),
                permKeys: [LW_Kindergarten_Shifts],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [Feature_LW_Business_Kindergarten_Shift_AddUpdate],
            },
            [relativeRoutes.kindergartens.withId().shifts.edit()]: {
                component: BusinessShiftEdit,
                fullPattern: fullRoutes.kindergartens.withId().shifts.edit(),
                pattern: relativeRoutes.kindergartens.withId().shifts.edit(),
                permKeys: [LW_Kindergarten_Shifts],
                featureKeys: [Feature_LW_Business_Kindergarten_Shift_AddUpdate],
            },
            [relativeRoutes.kindergartens.withId().functions.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.kindergartens.withId().functions.list(),
                pattern: relativeRoutes.kindergartens.withId().functions.list(),
                permKeys: [LW_Kindergarten_Functions],
                featureKeys: [Feature_LW_Business_Kindergarten_Functions],
            },
            [relativeRoutes.kindergartens.withId().functions.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .functions.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .functions.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Kindergarten_Functions],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_Functions_AddUpdate,
                ],
            },
            [relativeRoutes.kindergartens.withId().functions.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens.withId().functions.edit(),
                pattern: relativeRoutes.kindergartens.withId().functions.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Kindergarten_Functions],
                featureKeys: [
                    Feature_LW_Business_Kindergarten_Functions_AddUpdate,
                ],
            },
        },
    },
};

const IndustryRoutesMap = {
    [relativeRoutes.industries.withId().base()]: {
        component: LwcIndustryEditLayout,
        fullPattern: fullRoutes.industries.withId().base(),
        pattern: relativeRoutes.industries.withId().base(),
        permKeys: [LW_Industry_BasicData],
        featureKeys: [Feature_LW_Clients_Industries],
        nestedScreens: {
            [relativeRoutes.industries.withId().edit()]: {
                component: LwcEdit,
                index: true,
                pattern: relativeRoutes.industries.withId().edit(),
                fullPattern: fullRoutes.industries.withId().edit(),
                permKeys: [LW_Industry_BasicData],
                featureKeys: [LW_Industry_BasicData],
            },
            [relativeRoutes.industries.withId().chatSettings()]: {
                component: BusinessChatSettingsLayout,
                pattern: relativeRoutes.kindergartens.withId().chatSettings(),
                fullPattern: fullRoutes.kindergartens.withId().chatSettings(),
                permKeys: [LW_Industry_Settings],
                featureKeys: [Feature_LW_Business_Industry_Chat_Settings],
            },
            [relativeRoutes.industries.withId().contracts()]: {
                component: BusinessContracts,
                pattern: relativeRoutes.industries.withId().contracts(),
                fullPattern: fullRoutes.industries.withId().contracts(),
                featureKeys: [Feature_LW_Business_Industry_Contracts],
                permKeys: [LW_Industry_Contracts],
            },
            [relativeRoutes.industries.withId().publicHolidays()]: {
                component: IFrameContent,
                pattern: relativeRoutes.industries.withId().publicHolidays(),
                fullPattern: fullRoutes.industries.withId().publicHolidays(),
                featureKeys: [Feature_LW_Business_Industry_Holidays],
                permKeys: [LW_Industry_PublicHolidays],
            },
            [relativeRoutes.industries.withId().documents()]: {
                component: IFrameContent,
                pattern: relativeRoutes.industries.withId().documents(),
                fullPattern: fullRoutes.industries.withId().documents(),
                permKeys: [LW_Industry_Documents],
                featureKeys: [Feature_LW_Business_Industry_Document],
            },
            [relativeRoutes.industries.withId().workingHours()]: {
                component: BusinessWorkingDays,
                pattern: relativeRoutes.industries.withId().workingHours(),
                fullPattern: fullRoutes.industries.withId().workingHours(),
                permKeys: [LW_Industry_WorkingDays],
                featureKeys: [Feature_LW_Business_Industry_WorkingHours],
            },
            [relativeRoutes.industries.withId().ipads()]: {
                component: BusinessIPadsList,
                pattern: relativeRoutes.industries.withId().ipads(),
                fullPattern: fullRoutes.industries.withId().ipads(),
                permKeys: [LW_Industry_IPads],
                featureKeys: [Feature_LW_Business_Industry_IPads],
            },
            [relativeRoutes.industries.withId().basicSettings()]: {
                component: BusinessBasicSettings,
                pattern: relativeRoutes.industries.withId().basicSettings(),
                fullPattern: fullRoutes.industries.withId().basicSettings(),
                permKeys: [LW_Industry_Settings],
                featureKeys: [Feature_LW_Business_Industry_Settings],
            },
            [relativeRoutes.industries.withId().externalIntegration()]: {
                component: BusinessExternalIntegration,
                pattern: relativeRoutes.industries.withId().externalIntegration(),
                fullPattern: fullRoutes.industries.withId().externalIntegration(),
                permKeys: [LW_Industry_BasicData],
                featureKeys: [Feature_LW_Business_Industry_Details],
            },
            [relativeRoutes.industries.withId().timeTracking()]: {
                component: TimeTrackingSettings,
                pattern: relativeRoutes.industries.withId().timeTracking(),
                fullPattern: fullRoutes.industries.withId().timeTracking(),
                permKeys: [LW_Industry_Settings],
                featureKeys: [Feature_LW_Business_Industry_Settings],
            },
            [relativeRoutes.industries.withId().accessControl.list()]: {
                component: IFrameContent,
                pattern: relativeRoutes.industries
                    .withId()
                    .accessControl.list(),
                fullPattern: fullRoutes.industries
                    .withId()
                    .accessControl.list(),
                featureKeys: [Feature_LW_Business_Industry_AccessControl],
                permKeys: [LW_Industry_AccessControl],
            },
            [relativeRoutes.industries.withId().accessControl.create()]: {
                component: Dummy,
                pattern: relativeRoutes.industries
                    .withId()
                    .accessControl.create(),
                fullPattern: fullRoutes.industries
                    .withId()
                    .accessControl.create(),
                featureKeys: [
                    Feature_LW_Business_Industry_AccessControl_ViewUpdate,
                ],
                permKeys: [LW_Industry_AccessControl],
            },
            [relativeRoutes.industries.withId().accessControl.edit()]: {
                component: Dummy,
                pattern: relativeRoutes.industries
                    .withId()
                    .accessControl.edit(),
                fullPattern: fullRoutes.industries
                    .withId()
                    .accessControl.edit(),
                featureKeys: [
                    Feature_LW_Business_Industry_AccessControl_ViewUpdate,
                ],
                permKeys: [LW_Industry_AccessControl],
            },
            [relativeRoutes.industries.withId().groups.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.industries.withId().groups.list(),
                pattern: relativeRoutes.industries.withId().groups.list(),
                permKeys: [LW_Industry_Groups],
                featureKeys: [Feature_LW_Business_Industry_Group],
            },
            [relativeRoutes.industries.withId().groups.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.industries.withId().groups.create(),
                pattern: relativeRoutes.industries.withId().groups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Industry_Groups],
                featureKeys: [Feature_LW_Business_Industry_Group_AddUpdate],
            },
            [relativeRoutes.industries.withId().groups.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.industries.withId().groups.edit(),
                pattern: relativeRoutes.industries.withId().groups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Industry_Groups],
                featureKeys: [Feature_LW_Business_Industry_Group_AddUpdate],
            },
            [relativeRoutes.industries.withId().courses.list()]: {
                component: BusinessCoursesList,
                fullPattern: fullRoutes.industries.withId().courses.list(),
                pattern: relativeRoutes.industries.withId().courses.list(),
                permKeys: [LW_Industry_Courses],
                featureKeys: [Feature_LW_Business_Industry_Courses],
            },
            [relativeRoutes.industries.withId().courses.create()]: {
                component: BusinessCoursesCreateEdit,
                fullPattern: fullRoutes.industries.withId().courses.create(),
                pattern: relativeRoutes.industries.withId().courses.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Industry_Courses],
                featureKeys: [Feature_LW_Business_Industry_Courses_AddUpdate],
            },
            [relativeRoutes.industries.withId().courses.edit()]: {
                component: BusinessCoursesCreateEdit,
                fullPattern: fullRoutes.industries.withId().courses.edit(),
                pattern: relativeRoutes.industries.withId().courses.edit(),
                permKeys: [LW_Industry_Courses],
                permAccessTypes: [PermissionAccessTypes.EDIT],
                featureKeys: [Feature_LW_Business_Industry_Courses_AddUpdate],
            },
            [relativeRoutes.industries.withId().areas.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.industries.withId().areas.list(),
                pattern: relativeRoutes.industries.withId().areas.list(),
                permKeys: [LW_Industry_WorkingAreas],
                featureKeys: [Feature_LW_Business_Industry_WorkingArea],
            },
            [relativeRoutes.industries.withId().areas.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.industries.withId().areas.create(),
                pattern: relativeRoutes.industries.withId().areas.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Industry_WorkingAreas],
                featureKeys: [
                    Feature_LW_Business_Industry_WorkingArea_AddUpdate,
                ],
            },
            [relativeRoutes.industries.withId().areas.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.industries.withId().areas.edit(),
                pattern: relativeRoutes.industries.withId().areas.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Industry_WorkingAreas],
                featureKeys: [
                    Feature_LW_Business_Industry_WorkingArea_AddUpdate,
                ],
            },
            // [relativeRoutes.industries.withId().kontos()]: {
            //     component: BusinessKontoList,
            //     fullPattern: fullRoutes.industries.withId().kontos(),
            //     pattern: relativeRoutes.industries.withId().kontos(),
            //     // permKeys: [LW_Industry_Kontos],
            //     featureKeys: [Feature_LW_Business_Industry_Konto],
            // },
            [relativeRoutes.industries.withId().shifts.list()]: {
                component: BusinessShiftList,
                fullPattern: fullRoutes.industries.withId().shifts.list(),
                pattern: relativeRoutes.industries.withId().shifts.list(),
                permKeys: [LW_Industry_Shifts],
                featureKeys: [Feature_LW_Business_Industry_Shift],
            },
            [relativeRoutes.industries.withId().shifts.create()]: {
                component: BusinessShiftCreate,
                fullPattern: fullRoutes.industries.withId().shifts.create(),
                pattern: relativeRoutes.industries.withId().shifts.create(),
                permKeys: [LW_Industry_Shifts],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [Feature_LW_Business_Industry_Shift_AddUpdate],
            },
            [relativeRoutes.industries.withId().shifts.edit()]: {
                component: BusinessShiftEdit,
                fullPattern: fullRoutes.industries.withId().shifts.edit(),
                pattern: relativeRoutes.industries.withId().shifts.edit(),
                permKeys: [LW_Industry_Shifts],
                featureKeys: [Feature_LW_Business_Industry_Shift_AddUpdate],
            },
            [relativeRoutes.industries.withId().functions.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.industries.withId().functions.list(),
                pattern: relativeRoutes.industries.withId().functions.list(),
                permKeys: [LW_Industry_Functions],
                featureKeys: [Feature_LW_Business_Industry_Functions],
            },
            [relativeRoutes.industries.withId().functions.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.kindergartens
                    .withId()
                    .functions.create(),
                pattern: relativeRoutes.kindergartens
                    .withId()
                    .functions.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LW_Industry_Functions],
                featureKeys: [Feature_LW_Business_Industry_Functions_AddUpdate],
            },
            [relativeRoutes.industries.withId().functions.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.industries.withId().functions.edit(),
                pattern: relativeRoutes.industries.withId().functions.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LW_Industry_Functions],
                featureKeys: [Feature_LW_Business_Industry_Functions_AddUpdate],
            },
        },
    },
};

export const lillywaitClientRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.kindergartens.list()]: {
        component: Kindergartens,
        fullPattern: fullRoutes.kindergartens.list(),
        pattern: relativeRoutes.kindergartens.list(),
        permKeys: [LW_Kindergarten_BasicData],
        featureKeys: [Feature_LW_Clients_Kindergartens],
    },
    ...KindergartenRoutesMap,
    [relativeRoutes.industries.list()]: {
        component: Industries,
        fullPattern: fullRoutes.industries.list(),
        pattern: relativeRoutes.industries.list(),
        permKeys: [LW_Industry_BasicData],
        featureKeys: [Feature_LW_Clients_Industries],
    },
    ...IndustryRoutesMap,
    [relativeRoutes.schools.list()]: {
        component: Schools,
        fullPattern: fullRoutes.schools.list(),
        pattern: relativeRoutes.schools.list(),
        permKeys: [LW_School_BasicData],
        featureKeys: [Feature_LW_Clients_Schools],
    },
    [relativeRoutes.schools.withId().base()]: {
        component: LwcSchoolEditLayout,
        fullPattern: fullRoutes.schools.withId().base(),
        pattern: relativeRoutes.schools.withId().base(),
        permKeys: [LW_School_BasicData],
        featureKeys: [Feature_LW_Clients_Schools],
        nestedScreens: {
            [relativeRoutes.schools.withId().edit()]: {
                component: LwcEdit,
                index: true,
                pattern: relativeRoutes.schools.withId().edit(),
                fullPattern: fullRoutes.schools.withId().edit(),
                permKeys: [LW_School_BasicData],
                featureKeys: [Feature_LW_Business_School_Details],
            },
            [relativeRoutes.schools.withId().externalIntegration()]: {
                component: BusinessExternalIntegration,
                pattern: relativeRoutes.schools.withId().externalIntegration(),
                fullPattern: fullRoutes.schools.withId().externalIntegration(),
                permKeys: [LW_School_BasicData],
                featureKeys: [Feature_LW_Business_School_Details],
            },
            [relativeRoutes.schools.withId().publicHolidays()]: {
                component: IFrameContent,
                pattern: relativeRoutes.schools.withId().publicHolidays(),
                fullPattern: fullRoutes.schools.withId().publicHolidays(),
                permKeys: [LW_School_PublicHolidays],
                featureKeys: [Feature_LW_Business_School_Holidays],
            },
            [relativeRoutes.schools.withId().contracts()]: {
                component: BusinessContracts,
                pattern: relativeRoutes.schools.withId().contracts(),
                fullPattern: fullRoutes.schools.withId().contracts(),
                featureKeys: [Feature_LW_Business_School_Contracts],
                permKeys: [LW_School_Contracts],
            },
            [relativeRoutes.schools.withId().documents()]: {
                component: IFrameContent,
                pattern: relativeRoutes.schools.withId().documents(),
                fullPattern: fullRoutes.schools.withId().documents(),
                featureKeys: [Feature_LW_Business_School_Document],
                permKeys: [LW_School_Documents],
            },
            [relativeRoutes.schools.withId().workingHours()]: {
                component: BusinessWorkingDays,
                pattern: relativeRoutes.schools.withId().workingHours(),
                fullPattern: fullRoutes.schools.withId().workingHours(),
                permKeys: [LW_School_WorkingDays],
                featureKeys: [Feature_LW_Business_School_WorkingHours],
            },
        },
    },
    [relativeRoutes.elderlyCares.list()]: {
        component: ElderlyCares,
        fullPattern: fullRoutes.elderlyCares.list(),
        pattern: relativeRoutes.elderlyCares.list(),
        permKeys: [LW_ElderlyCare_BasicData],
        featureKeys: [Feature_LW_Clients_ElderlyCares],
    },
    [relativeRoutes.elderlyCares.withId().base()]: {
        component: LwcElderlyCareEditLayout,
        fullPattern: fullRoutes.elderlyCares.withId().base(),
        pattern: relativeRoutes.elderlyCares.withId().base(),
        permKeys: [LW_ElderlyCare_BasicData],
        featureKeys: [Feature_LW_Clients_Schools],
        nestedScreens: {
            [relativeRoutes.elderlyCares.withId().edit()]: {
                component: LwcEdit,
                index: true,
                pattern: relativeRoutes.elderlyCares.withId().edit(),
                fullPattern: fullRoutes.elderlyCares.withId().edit(),
                permKeys: [LW_ElderlyCare_BasicData],
                featureKeys: [Feature_LW_Business_ElderlyCare_Details],
            },
            [relativeRoutes.elderlyCares.withId().externalIntegration()]: {
                component: BusinessExternalIntegration,
                pattern: relativeRoutes.elderlyCares.withId().externalIntegration(),
                fullPattern: fullRoutes.elderlyCares.withId().externalIntegration(),
                permKeys: [LW_ElderlyCare_BasicData],
                featureKeys: [Feature_LW_Business_ElderlyCare_Details],
            },
            [relativeRoutes.elderlyCares.withId().publicHolidays()]: {
                component: IFrameContent,
                pattern: relativeRoutes.elderlyCares.withId().publicHolidays(),
                fullPattern: fullRoutes.elderlyCares.withId().publicHolidays(),
                permKeys: [LW_ElderlyCare_PublicHolidays],
                featureKeys: [Feature_LW_Business_ElderlyCare_Holidays],
            },
            [relativeRoutes.elderlyCares.withId().contracts()]: {
                component: BusinessContracts,
                pattern: relativeRoutes.elderlyCares.withId().contracts(),
                fullPattern: fullRoutes.elderlyCares.withId().contracts(),
                featureKeys: [Feature_LW_Business_ElderlyCare_Contracts],
                permKeys: [LW_ElderlyCare_Contracts],
            },
            [relativeRoutes.elderlyCares.withId().documents()]: {
                component: IFrameContent,
                pattern: relativeRoutes.elderlyCares.withId().documents(),
                fullPattern: fullRoutes.elderlyCares.withId().documents(),
                permKeys: [LW_ElderlyCare_Documents],
                featureKeys: [Feature_LW_Business_ElderlyCare_Documents],
            },
            [relativeRoutes.elderlyCares.withId().workingHours()]: {
                component: BusinessWorkingDays,
                pattern: relativeRoutes.elderlyCares.withId().workingHours(),
                fullPattern: fullRoutes.elderlyCares.withId().workingHours(),
                permKeys: [LW_ElderlyCare_WorkingDays],
                featureKeys: [Feature_LW_Business_ElderlyCare_WorkingHours],
            },
        },
    },
};
