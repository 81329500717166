export enum Gender {
    Male = "Male",
    Female = "Female",
}
export enum CookiesKey {
    USER_COOKIE = "UserCookie",
    USER_LOCALE = "UserLocale",
    USER_LOGOUT = "UserLogout",
    SESSION_EXPIRE = "SessionExpired",
    IS_SIDEBAR_OPEN = "IsSidebarOpen",
    IGNORE_WRONG_URL_PARAM = "IgnoreWrongUrlParam",
}
export enum AppPlatforms {
    WEB = "Website",
    MOBILE = "Mobile",
    IPAD = "IPad",
}

export enum AppQrCodeType {
    CLIENT_CONTACT,
    EMPLOYEE,
    BUSINESS,
}
export enum AppLocale {
    GERMAN = "de-DE",
    ENGLISH = "en-US",
    ITALIAN = "it-IT",
    BOSNIAN = "bs",
    POLISH = "pl-PL",
    CROATIAN = "hr-HR",
    CZECH = "cs-CZ",
    HUNGARIAN = "hu-HU",
    SLOVAK = "sk-SK",
    SLOVENIAN = "sl-SI",
    FRENCH = "fr-FR",
    SPANISH = "es-ES",
    DUTCH = "nl-NL",
    ESTONIAN = "et-EE",
    FINNISH = "fi-FI",
    GREEK = "el-GR",
}
export enum AppCurrencyCode {
    USD = "USD",
    EUR = "EUR",
}
export enum AxiosCommonHeaders {
    ACCEPT_LANGUAGE = "Accept-Language",
    AUTHORIZATION = "Authorization",
}

export enum UserType {
    Client = "Client",
    Parent = "Parent",
    Employee = "Employee",
    Supplier = "Supplier",
}
export enum BoolToYesNo {
    No = 0,
    Yes = 1,
}
export enum DurationPeriodType {
    Day = "Day",
    Week = "Week",
    Month = "Month",
    Year = "Year",
}
export enum DurationPeriodTypeNumber {
    Day = 0,
    Week = 1,
    Month = 2,
    Year = 3,
}

export enum AppLayoutThemes {
    DEFAULT = "blue",
    ADMIN = "admin",
    SUPPORT_VIEW = "support",
}

export enum SideMenuTypes {
    GENERAL = "general",
    ADMIN = "admin",
    SUPPORT_VIEW = "support",
}

export enum UserFilterType {
    Client = "Client",
    Employee = "Employee",
    Supplier = "Supplier",
}

export enum DayType {
    WORK_DAY = "WORK_DAY",
    PUBLIC_HOLIDAY = "PUBLIC_HOLIDAY",
    WEEKEND = "WEEKEND",
    PERSON_VACATION = "PERSON_VACATION",
    PERSON_HALF_VACATION = "PERSON_HALF_VACATION",
    DISMANTLE_OVERTIME = "DISMANTLE_OVERTIME",
    DISEASE_DAY = "DISEASE_DAY",
    UNPAID_VACATION = "UNPAID_VACATION",
    SCHOOL_DAY = "SCHOOL_DAY",
    FURTHER_TRAINING = "FURTHER_TRAINING",
    FREE_DAY = "FREE_DAY",
    NO_REASON = "NO_REASON",
}
