import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    Business,
    BusinessBasicSettingsUpdateRequest,
    BusinessChatSettings,
    BusinessLocationSettingPreferences,
    BusinessTimeTrackingSettings,
    ShiftCriteriaType,
} from "models";
import { DocumentTemplateResponse } from "models/documentTemplates";
import { SelectItem } from "models/general";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService, headerToIgnoreContractExpire } from "../BaseService";
import { isNil } from "lodash-es";
import { Moment } from "moment-timezone";

const businessApiLinkProvider =
    routesForContext().business.api.currentBusiness();
type ApiLinkProviderType = typeof businessApiLinkProvider;

export interface ICurrentBusinessService {
    deleteAllClients: (password: string) => Promise<AppResponse<boolean>>;
    deleteAllClientRegistrations: (
        password: string
    ) => Promise<AppResponse<boolean>>;
    updateExplanationModeVisibility: (
        status: boolean
    ) => Promise<AppResponse<boolean>>;
    getModulePlanOptions(
        locationSettingId: string
    ): Promise<AppResponse<SelectItem[]>>;
    getBasicInfo(): Promise<AppResponse<Business>>;
    refreshExternalToken(): Promise<AppResponse<string>>;
    getBusinessAreas(): Promise<AppResponse<SelectItem[]>>;
    getBusinessGroups(date?: Moment): Promise<AppResponse<SelectItem[]>>;
    getBusinessFunctions(): Promise<AppResponse<SelectItem[]>>;
    deleteAllEmployees(password: string): Promise<AppResponse<boolean>>;
    updateTimeTrackingSettings(
        req: BusinessTimeTrackingSettings
    ): Promise<AppResponse<boolean>>;
    updateBusinessLocationSettingPreferences(
        req: BusinessLocationSettingPreferences
    ): Promise<AppResponse<BusinessLocationSettingPreferences>>;
    getBusinessShiftCriteriaOptions(
        forType: ShiftCriteriaType
    ): Promise<AppResponse<SelectItem[]>>;
    updateBasicSettings(
        request: BusinessBasicSettingsUpdateRequest
    ): Promise<AppResponse<Business>>;
    getBusinessDocumentTemplates(): Promise<
        AppResponse<DocumentTemplateResponse[]>
    >;
    getChatSettings(): Promise<AppResponse<BusinessChatSettings>>;
    updateChatSettings(
        req: BusinessChatSettings
    ): Promise<AppResponse<BusinessChatSettings>>;
}

export function getCurrentBusinessServiceKey(
    name: keyof ICurrentBusinessService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `currBusiness_${name}_${JSON.stringify(data)}`;
}

export class CurrentBusinessService
    extends BaseService
    implements ICurrentBusinessService {
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }
    getModulePlanOptions(
        locationSettingId: string = "0"
    ): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<AppResponse<SelectItem[]>>({
            url: this.baseRoute.getModulePlanOptions(locationSettingId),
            method: "get",
        });
    }
    getBusinessDocumentTemplates(): Promise<
        AppResponse<DocumentTemplateResponse[]>
    > {
        return this.doServerXHR<AppResponse<DocumentTemplateResponse[]>>({
            url: this.baseRoute.getBusinessDocumentTemplates(),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }
    updateExplanationModeVisibility(
        req: boolean
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.updateExplanationModeVisibility(),
            method: "post",
            data: {
                Flag: req,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    updateBusinessLocationSettingPreferences(
        req: BusinessLocationSettingPreferences
    ): Promise<AppResponse<BusinessLocationSettingPreferences>> {
        return this.doServerXHR<BusinessLocationSettingPreferences>({
            url: this.baseRoute.updateBusinessLocationSettingPreferences(),
            method: "post",
            data: req,
            ...headerToIgnoreContractExpire,
        });
    }
    refreshExternalToken(): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.baseRoute.refreshExternalToken(),
            method: "post",
            ...headerToIgnoreContractExpire,
        });
    }

    updateTimeTrackingSettings(
        req: BusinessTimeTrackingSettings
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.updateTimeTrackingSettings(),
            method: "post",
            data: {
                ...req,
                EmpWebFlag: req.AllowManualEmployeeTimeTrackingWeb,
                EmpMobileFlag: req.AllowManualEmployeeTimeTrackingMobile,
                EmpIPadFlag: req.AllowManualEmployeeTimeTrackingIPad,
                ClientWebFlag: req.AllowManualClientTimeTrackingWeb,
                ClientMobileFlag: req.AllowManualClientTimeTrackingMobile,
                ClientIPadFlag: req.AllowManualClientTimeTrackingIPad,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    updateBasicSettings(
        request: BusinessBasicSettingsUpdateRequest
    ): Promise<AppResponse<Business>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.updateBasicSettings(),
            method: "post",
            data: {
                ...request,
                AgeGroupDeadline:
                    request.AgeGroupDeadline?.format(ISO8601_DATE_FORMAT),
                TimeTrackingStart:
                    request.TimeTrackingStart?.format(ISO8601_DATE_FORMAT),
                VacationsCalculationStart:
                    request.VacationsCalculationStart?.format(
                        ISO8601_DATE_FORMAT
                    ),
            },
            ...headerToIgnoreContractExpire,
        });
    }
    getBasicInfo(businessId?: string): Promise<AppResponse<Business>> {
        return this.doServerXHR<Business>({
            url: this.baseRoute.getBasicInfo(
                businessId ? { encodedId: businessId } : undefined
            ),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }
    getChatSettings(): Promise<AppResponse<BusinessChatSettings>> {
        return this.doServerXHR<BusinessChatSettings>({
            url: this.baseRoute.getChatSettings(),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }
    updateChatSettings(
        request: BusinessChatSettings
    ): Promise<AppResponse<BusinessChatSettings>> {
        return this.doServerXHR<BusinessChatSettings>({
            url: this.baseRoute.updateChatSettings(),
            method: "post",
            data: request,
            ...headerToIgnoreContractExpire,
        });
    }

    deleteAllClients(password: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteAllClients(),
            method: "post",
            data: {
                Password: password,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    deleteAllEmployees(password: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteAllEmployees(),
            method: "post",
            data: {
                Password: password,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    deleteAllClientRegistrations(
        password: string
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.deleteAllClientRegistrations(),
            method: "post",
            data: {
                Password: password,
            },
            ...headerToIgnoreContractExpire,
        });
    }
    getBusinessAreas(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessAreas(),
            method: "get",
        });
    }
    getBusinessGroups(date?: Moment, filterOnlyPastGroups: boolean = false): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessGroups(
                !isNil(date) ? {
                    Date:
                        date.format(ISO8601_DATE_FORMAT),
                    FilterOnlyPastGroups: filterOnlyPastGroups
                } : undefined),
            method: "get",
        });
    }
    getBusinessFunctions(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessFunctions(),
            method: "get",
        });
    }
    getBusinessShiftCriteriaOptions(
        forType: ShiftCriteriaType
    ): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessShiftCriteriaOptions({
                forType: forType,
            }),
            method: "get",
        });
    }
}
