import classNames from "classnames";
import { AppLoader } from "components/Loaders";
import { base64ToBlob, openUrlInTab } from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { Optional } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { useQuery } from "react-query";
import {
    DocumentCompileService,
    getDocumentCompileServiceKey,
} from "services/business";
import styles from "./PdfViewerPage.module.scss";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";

export interface PdfViewerPageProps {
    docKey?: string;
    name?: string;
    openInNewTab?: boolean;
    onCloseClick?: () => void;
}

export const PdfViewerPage: React.FC<PdfViewerPageProps> = ({
    docKey,
    openInNewTab = false,
    name,
    onCloseClick,
}) => {
    const showErrorPage = useShowErrorPage(false);
    const [pdfUrl, setPdfUrl] = useState<Optional<string>>(null);
    const { linkProvider } = useRouting();

    const documentCompileService = new DocumentCompileService(
        linkProvider.business.api.documentCompile
    );

    const {
        data,
        isLoading,
        isError,
        refetch: fetchPdf,
    } = useQuery(
        getDocumentCompileServiceKey("getPdfFromKey", {
            docKey,
        }),
        async () => {
            if (docKey) {
                return documentCompileService.getPdfFromKey(docKey);
            }
        },
        {
            enabled: false,
        }
    );

    useEffect(() => {
        if (isError) {
            showUnexpectedErrorToast();
        }
    }, [isError]);
    useEffect(() => {
        if (docKey) {
            if (!isLoading) {
                fetchPdf();
            }
        }
    }, [docKey]);
    useEffect(() => {
        if (!isLoading && data && data.Data) {
            setPdfUrl(
                window.URL.createObjectURL(
                    base64ToBlob(data.Data, "application/pdf")
                )
            );
        }
    }, [data, isLoading]);

    const embedView = useMemo(() => {
        if (pdfUrl) {
            openUrlInTab(pdfUrl, defaultTo(name, "document.pdf"), "_self");
            return (
                <embed
                    style={{ width: "100%", height: "100%" }}
                    src={pdfUrl}
                    type="application/pdf"
                    autoSave="true"
                />
            );
        }
        return null;
    }, [pdfUrl]);
    return (
        <div
            className={classNames(styles.outer, {
                [styles.openInCurrentTab]: !openInNewTab,
            })}
        >
            {isLoading && <AppLoader />}
            {!openInNewTab && (
                <div className={styles.crossImageDiv}>
                    <Image
                        src={ImageAssets.common.crossWhite}
                        onClick={() => {
                            if (onCloseClick) {
                                onCloseClick();
                            }
                        }}
                    />
                </div>
            )}
            {!isLoading && !isNil(embedView) && embedView}
        </div>
    );
};

export default PdfViewerPage;
