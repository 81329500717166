import config from "globals/config";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { defaultTo, isNil } from "lodash-es";
import { BusinessType, getBusinessTypePathString } from "models/business";
import { EmployeeType } from "models/employee/enum";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { routesForContext } from "routing/AppRoutes";

export interface AppRouteParams {
    sessionBusinessId?: string | ":sessionBusinessId";
    sessionBusinessType?: BusinessType | ":sessionBusinessType";
    businessId?: string | ":businessId";
    businessType?: BusinessType | ":businessType";
    clientId?: string | ":clientId";
    employeeId?: string | ":employeeId";
    employeeType?: EmployeeType | ":employeeType";
    clientBusinessId?: string | ":lwcId";
    clientBusinessType?: BusinessType | ":lwcType";
    locationId?: string | ":locationId";
    creditorId?: string | ":creditorId";
}

export function useRouting(sessionBusinessId?: string) {
    const { isAuthenticated, loaded } = useSessionUser();
    const { encodedId, sessionBusiness } = useSessionBusiness();
    const params = useParams();

    const pathProvider = useMemo(() => {
        return routesForContext();
    }, []);
    const relativePathProvider = useMemo(() => {
        return routesForContext(undefined, false);
    }, []);
    const linkProvider = useMemo(() => {
        const {
            businessType,
            businessId,
            employeeId,
            clientId,
            employeeType,
            locationId,
            creditorId,
            lwcId,
            lwcType,
        } = params;
        return isAuthenticated && loaded
            ? routesForContext({
                  sessionBusinessId:
                      encodedId !== null
                          ? defaultTo(sessionBusinessId, encodedId) // to pass different sessionID (needed to close support view)
                          : undefined,
                  sessionBusinessType: sessionBusiness?.Type,
                  clientBusinessId: lwcId,
                  clientBusinessType: lwcType as any,
                  businessId: businessId,
                  employeeId: employeeId,
                  locationId: locationId,
                  clientId: clientId,
                  creditorId: creditorId,
                  employeeType: employeeType as any,
                  businessType: !isNil(businessType)
                      ? (businessType as BusinessType)
                      : businessType,
              })
            : pathProvider;
    }, [
        encodedId,
        sessionBusiness,
        pathProvider,
        isAuthenticated,
        loaded,
        params,
    ]);

    return {
        linkProvider,
        relativePathProvider,
        pathProvider,
    };
}

export function useIFrameRouting() {
    const params = useParams();

    const iframeUrlProvide = useMemo(() => {
        const {
            sessionBusinessType,
            sessionBusinessId,
            businessType,
            businessId,
            clientId,
            employeeId,
            locationId,
            lwcId,
        } = params;

        const type =
            businessType === "lillywait"
                ? "Lillywait"
                : businessType === "industry"
                ? BusinessType.INDUSTRY
                : BusinessType.KINDERGARTEN;

        const sessionBType =
            sessionBusinessType === "lillywait"
                ? "Lillywait"
                : sessionBusinessType === "industry"
                ? BusinessType.INDUSTRY
                : BusinessType.KINDERGARTEN;

        return {
            base: config.serverBaseUrl,
            urlForBusinessSettings: (pattern: string) => {
                return `${config.serverBaseUrl}/${type}/Client/${businessId}/Settings/${pattern}`;
            },
            urlForSessionBusinessAdmin: (pattern: string) => {
                return `${config.serverBaseUrl}/${sessionBType}/Client/${sessionBusinessId}/Admin/${pattern}`;
            },
            urlForLocationSettings: (pattern: string) => {
                return `${config.serverBaseUrl}/Lillywait/Admin/LocationSettings/${pattern}`;
            },
            urlForLwc: (
                pattern: string,
                lwcType: BusinessType = BusinessType.KINDERGARTEN
            ) => {
                const type =
                    lwcType !== BusinessType.ELDERLY_CARE
                        ? getBusinessTypePathString(lwcType, true)
                        : lwcType;
                return `${config.serverBaseUrl}/Lillywait/Clients/${type}s/${lwcId}/${pattern}`;
            },
            urlForEmployee: (pattern: string) => {
                return `${config.serverBaseUrl}/${sessionBType}/Client/${sessionBusinessId}/Employees/${employeeId}/${pattern}`;
            },
            urlForEmployeeCreate: (employeeType: EmployeeType) => {
                return `${config.serverBaseUrl}/${sessionBType}/Client/${sessionBusinessId}/Employees/create?type=${employeeType}`;
            },
            urlForKindergartenClientRegistration: () => {
                return `${config.serverBaseUrl}/${sessionBType}/Client/${sessionBusinessId}/ClientRegistrations`;
            },
            urlForKindergartenClient: (pattern: string) => {
                return `${config.serverBaseUrl}/${sessionBType}/Client/${sessionBusinessId}/Clients/${clientId}/${pattern}`;
            },
        };
    }, [params]);

    return iframeUrlProvide;
}
