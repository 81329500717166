import { AppContentHeader } from "components";
import IFrameContent from "components/IFrameContent";
import { useIFrameRouting } from "hooks/general/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./EmailTemplate.module.scss";

export const EmailTemplate: React.FC = () => {
    const { t } = useTranslation();
    const { urlForSessionBusinessAdmin } = useIFrameRouting();

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("emailTemplate.title")}
                classNameForIcon="pe-7s-mail"
            />
            <IFrameContent
                contentUrl={urlForSessionBusinessAdmin("EmailTemplates")}
            />
        </div>
    );
};

export default EmailTemplate;
