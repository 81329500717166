import { CompensationTemplatesList } from "commonPartials/compensationTemplate";
import { AppContainer } from "components/Containers";
import {
    showSweetAlertConfirmation,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaults, defaultTo, isNil } from "lodash-es";
import {
    BusinessLocationSettingPreferences,
    getDefaultLocationPreferences,
} from "models";
import {
    Business_CompensationTemplates,
    LW_Kindergarten_CompensationTemplates,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";
import styles from "commonPartials/commonStyles.module.scss";
import { CurrentBusinessService } from "services/business/CurrentBusinessService";
import { AppSwitch, AppTooltip } from "components";
import { AppIconTextButton } from "components/Buttons";

export const BusinessCompensationTemplateList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { lwcId } = useParams();
    const { getPermissionMap } = useCheckPermission();
    const [canChangeLocationPreference, setPreference] = useState<
        boolean | undefined
    >(undefined);

    // both(useCurrentBusinessSettingsContext and useCurrentLwcContext)
    // return same context as context type is same
    const { dataItem: business, refetchData } =
        useCurrentBusinessSettingsContext();
    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(business?.EncodedId)
    );
    const { isLoading, mutate: updatePreferences } = useMutation(
        async (data: BusinessLocationSettingPreferences) =>
            await businessService.updateBusinessLocationSettingPreferences(
                data
            ),
        {
            onSuccess: (resp) => {
                if (resp && resp.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("business.settings")} ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );
                    if (refetchData) {
                        refetchData();
                    }
                }
            },
        }
    );

    const isLwc = !isNil(lwcId);
    const permMap = getPermissionMap([
        isLwc
            ? LW_Kindergarten_CompensationTemplates
            : Business_CompensationTemplates,
    ]);

    const lwcBase = linkProvider.lillywait.clients().screens;

    const linkProviderBase = (
        isLwc
            ? lwcBase.kindergartens.withId()
            : linkProvider.business.businessSettings().screens
    ).compensationTemplates;

    const useLocationData = defaultTo(
        business?.LocationPreferences?.CompensationTemplates,
        true
    );
    const toggleSwitch = (
        <AppSwitch
            label={t("business.useSampleLillywaitTemplates")}
            disabled={
                !canChangeLocationPreference ||
                !permMap[PermissionAccessTypes.EDIT] ||
                isLoading
            }
            value={useLocationData}
            labelPosition="left"
            color="green-toggle"
            onChange={(checked) => {
                showSweetAlertConfirmation(
                    t("common.warning"),
                    `${t("compensationTemplate.businessPreferenceWarning")}`,
                    () =>
                        updatePreferences({
                            ...defaults(
                                business?.LocationPreferences,
                                getDefaultLocationPreferences(
                                    business?.Id as number
                                )
                            ),
                            CompensationTemplates: checked,
                        }),
                    t("common.yes")
                );
            }}
        />
    );

    return (
        <AppContainer classes={{ body: styles.appContainerWithLessTopPadding }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ height: "45px" }}>
                    {!useLocationData && (
                        <AppIconTextButton
                            padding="med"
                            className="mb-2"
                            icon="plus"
                            disabled={!permMap[PermissionAccessTypes.CREATE]}
                            text={t("common.create")}
                            onClick={() => {
                                navigate(linkProviderBase.create());
                            }}
                        />
                    )}
                </div>
                {isNil(canChangeLocationPreference) ||
                canChangeLocationPreference ? (
                    toggleSwitch
                ) : (
                    <AppTooltip
                        content={t("business.locationPreferenceMessage")}
                        arrow={true}
                    >
                        {toggleSwitch}
                    </AppTooltip>
                )}
            </div>
            <CompensationTemplatesList
                currentBusiness={business}
                locationId={business?.LocationSettingId}
                basePathProvider={linkProviderBase}
                useLocationData={
                    business?.LocationPreferences?.CompensationTemplates
                }
                permissionsMap={permMap}
                setCanChangeLocationPreference={(val) => {
                    if (val != canChangeLocationPreference) {
                        setPreference(val);
                    }
                }}
                isLwc={isLwc}
            />
        </AppContainer>
    );
};

export default BusinessCompensationTemplateList;
