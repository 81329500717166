import classNames from "classnames";
import { AppButton } from "components/Buttons";
import {
    FULL_DATE,
    FULL_DATE_RANGE_FORMAT,
    YEAR_MONTH_DATE_FORMAT,
    YEAR_MONTH_RANGE_DATE_FORMAT,
} from "globals/constants";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { Optional } from "models/general";
import moment, { max, min, Moment } from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import styles from "./AppButtonRangeDatepicker.module.scss";
import { AppDatePicker } from "./AppDatePicker";
import { AppMonthYearPicker } from "./AppMonthYearPicker";
import { DatePickerType } from "models/datepicker";
import { AppTooltip } from "components/Tooltips";
import { defaultTo, isNil } from "lodash-es";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import AppPopover from "components/AppPopover";
import { AppLoader } from "components/Loaders";

export interface AppButtonRangeDatepickerProps {
    onChange: (newDate: StartAndEndDates) => void;
    value: StartAndEndDates;
    readonly?: boolean;
    useSeparatePickers?: boolean;
    pickerType?: DatePickerType.DATE | DatePickerType.MONTH_YEAR;
    minDate?: Moment;
    maxDate?: Moment;
    className?: string;
    tooltip?: string;
}

export function AppButtonRangeDatepicker(props: AppButtonRangeDatepickerProps) {
    const {
        value: propValue,
        readonly = false,
        useSeparatePickers = true,
        pickerType = DatePickerType.DATE,
        minDate,
        onChange,
        maxDate,
        className,
        tooltip,
    } = props;

    const [value, setValue] = useState<StartAndEndDates>(propValue);
    const displayFormat =
        pickerType === DatePickerType.DATE
            ? FULL_DATE_RANGE_FORMAT
            : YEAR_MONTH_RANGE_DATE_FORMAT;

    const { formatDate } = useLocaleHelpers();

    const [pickerOpen, setPickerOpen] = useState(false);
    const toggleRef = useRef<any>(undefined);

    const Component =
        pickerType === DatePickerType.MONTH_YEAR
            ? AppMonthYearPicker
            : AppDatePicker;

    const getFormattedDateString = () =>
        `${formatDate(
            defaultTo(propValue.StartDate, moment().add(-1, "M")),
            displayFormat
        )} - ${formatDate(
            defaultTo(propValue.EndDate, moment()),
            displayFormat
        )}`;

    const pickerContent = (
        <div
            style={{
                padding: "0px",
                height: "auto",
                display: "flex",
                borderRadius: "20px",
            }}
        >
            {useSeparatePickers ? (
                <div style={{ display: "flex" }}>
                    <Component
                        value={propValue.StartDate}
                        onChange={(val) => {
                            onChange({
                                ...propValue,
                                StartDate: val as Moment,
                                EndDate:
                                    propValue.EndDate &&
                                    (val as Moment) > propValue.EndDate
                                        ? val
                                        : propValue.EndDate,
                            });
                        }}
                        showYearDropdown={false}
                        inline={true}
                        appendToBody={false}
                        selectsStart={true}
                        minDate={minDate}
                        maxDate={maxDate}
                        startDate={propValue.StartDate}
                        endDate={propValue.EndDate}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        customInput={<></>}
                    />
                    <Component
                        value={propValue.EndDate}
                        onChange={(val) => {
                            onChange({
                                ...propValue,
                                StartDate:
                                    val && (val as Moment) < propValue.StartDate
                                        ? val
                                        : propValue.StartDate,
                                EndDate: val as Moment,
                            });
                        }}
                        selectsEnd={true}
                        showYearDropdown={false}
                        inline={true}
                        appendToBody={false}
                        minDate={minDate}
                        maxDate={maxDate}
                        startDate={propValue.StartDate}
                        endDate={propValue.EndDate}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        customInput={<></>}
                    />
                </div>
            ) : (
                <Component
                    value={value.StartDate}
                    onChange={(val) => {}}
                    onRangeChange={(val) => {
                        setValue(val);
                        if (val.EndDate) {
                            // update parent when both dates are selected
                            onChange(val);
                            // close picker
                            setPickerOpen((old) => !old);
                        }
                    }}
                    showYearDropdown={false}
                    inline={true}
                    appendToBody={false}
                    selectsRange={true as any}
                    monthsShown={2}
                    minDate={minDate}
                    maxDate={maxDate}
                    startDate={value.StartDate}
                    endDate={value.EndDate}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    customInput={<></>}
                />
            )}
        </div>
    );

    const content = (
        <div className={classNames(styles.root, className)}>
            <AppPopover
                interactive={true}
                triggerElem={
                    <AppButton
                        rounded={true}
                        ref={toggleRef}
                        onClick={() => {
                            // for some reason onClick is not working here
                            if (!readonly) {
                                setPickerOpen((old) => !old);
                            }
                        }}
                        className={`${styles.button}`}
                    >
                        <>
                            {getFormattedDateString()}
                            <Image
                                className={styles.icon}
                                src={ImageAssets.common.calendarAlt}
                            />
                        </>
                    </AppButton>
                }
                arrow={false}
                placement={"auto"}
                triggerOnClick={true}
                menuOpen={pickerOpen}
                className={classNames(styles.container, {
                    [styles.addMarginLeft]: !useSeparatePickers,
                })}
            >
                {pickerContent}
            </AppPopover>
        </div>
    );
    return !isNil(tooltip) ? (
        <AppTooltip
            content={tooltip}
            allowHtml={false}
            trigger="mouseenter focus"
            arrow={true}
        >
            {content}
        </AppTooltip>
    ) : (
        content
    );
}

export default AppButtonRangeDatepicker;
