import moment from "moment";
import { RemainderType, ReminderUnit } from "./enum";
import { AppReminder } from "./request";

export const getRemainderDefaultValue = (): AppReminder => {
    return {
        Id: 0,
        ReferenceDateTime: moment(),
        SendBefore: true,
        RecurrenceLevel: ReminderUnit.Minute,
        ReminderTypes: [RemainderType.WebsiteNotification],
        UnitValue: 0,
    };
};
