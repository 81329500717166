import ImageAssets from "globals/images/common";
import { defaultTo } from "lodash";
import React from "react";
import {
    FormControl,
    FormControlProps,
    Image,
    InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./SearchField.module.scss";

export interface SearchFieldProps extends Omit<FormControlProps, "onBlur"> {
    onValueChange: (value: string) => void;
    onBlur?: (value: string) => void;
    className?: string;
    iconClassName?: string;
}
export const SearchField = React.memo<SearchFieldProps>((props) => {
    const { t } = useTranslation();
    const {
        value,
        onValueChange,
        onBlur,
        className,
        iconClassName = "",
        ...rest
    } = props;

    return (
        <>
            <InputGroup>
                <FormControl
                    type="text"
                    value={defaultTo(value, "")}
                    onChange={(e) => {
                        onValueChange(e.target.value);
                    }}
                    onBlur={(e: any) => {
                        if (onBlur) {
                            onBlur(e.target.value);
                        }
                    }}
                    placeholder={t("common.search")}
                    className={`${styles.textField} ${className}`}
                    {...rest}
                />
                <div className={`${styles.icon} ${iconClassName}`}>
                    <Image src={ImageAssets.common.search}></Image>
                </div>
            </InputGroup>
        </>
    );
});

export default SearchField;
