import { DashboardResponse, DashboardTabs } from "models/dashboard";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IDashboardService {
    getDashboardStats: (
        request: DashboardTabs
    ) => Promise<AppResponse<DashboardResponse>>;
}

const apiLinkProvider = routesForContext().business.api.dashboard;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getDashboardServiceKey(
    name: keyof IDashboardService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `dashboard_${name}_${JSON.stringify(data)}`;
}
export class DashboardService extends BaseService implements IDashboardService {
    public dashboardRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.dashboardRoutes = apiLinkProvider;
    }

    getDashboardStats(
        tabType: DashboardTabs
    ): Promise<AppResponse<DashboardResponse>> {
        return this.doServerXHR<DashboardResponse>({
            url: this.dashboardRoutes.getDashboardStats({ tabType: tabType }),
            method: "get",
        });
    }
}

export default DashboardService;
