import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { TableSort } from "models/general";
import { EmployeeShiftSortColumn } from "models/employeeShifts";
import React from "react";
import { useTranslation } from "react-i18next";

export interface EmployeeShiftSortingColumnsProps {
    onSortChange: (sort: TableSort<EmployeeShiftSortColumn>) => void;
    sort: TableSort<EmployeeShiftSortColumn>;
}
export const EmployeeShiftSortingColumns: React.FC<
    EmployeeShiftSortingColumnsProps
> = ({ onSortChange, sort }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("employee.shift.shift.name")}
                style={getFixedCssWidths(280)}
                sortColumn={EmployeeShiftSortColumn.Shift}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeShiftSortColumn.Shift
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("common.startDate.name")}
                style={getFixedCssWidths(210)}
                sortColumn={EmployeeShiftSortColumn.StartDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeShiftSortColumn.StartDate
                        ? sort.SortOrder
                        : null
                }
            />

            <AppTableSortColumnIcons
                text={t("common.endDate.name")}
                style={getFixedCssWidths(210)}
                sortColumn={EmployeeShiftSortColumn.EndDate}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == EmployeeShiftSortColumn.EndDate
                        ? sort.SortOrder
                        : null
                }
            />

            <th style={{ ...getFixedCssWidths(120), textAlign: "center" }}>
                {t("employee.shift.excluded")}
            </th>
            <th style={{ ...getFixedCssWidths(120), textAlign: "center" }}>
                {t("employee.shift.included")}
            </th>
            <th style={{ width: "70.5px", minWidth: "70.5px" }} />
        </tr>
    );
};

export default EmployeeShiftSortingColumns;
