import { AppNumberInput } from "components/FormFields";
import { debounce, defaultTo } from "lodash-es";
import { KindergartenClientContractValueItem } from "models";
import styles from "./KindergartenClientContractRow.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import { AppIconButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { t } from "i18next";

export interface KindergartenClientContractSingleValueCellProps {
    value: KindergartenClientContractValueItem;
    canEdit: boolean;
    onChange: (val: number) => void;
    copyContracts: (tillEnd: boolean) => void;
}
const UPDATE_DELAY = 200;

const Component: React.FC<KindergartenClientContractSingleValueCellProps> = ({
    value,
    canEdit,
    onChange,
    copyContracts,
}) => {
    // to re-render number field
    const [resetField, setResetField] = useState(false);

    useEffect(() => {
        setResetField(true);
    }, [value.Value]);
    useEffect(() => {
        if (resetField) {
            setResetField(false);
        }
    }, [resetField]);
    const debouncedOnChange = useMemo(() => {
        return debounce(onChange, UPDATE_DELAY);
    }, [onChange]);

    return (
        <td>
            <div className={styles.valueCell}>
                {!resetField && (
                    <AppNumberInput
                        showLabel={false}
                        showError={false}
                        className={styles.inputField}
                        allowZero={true}
                        disabled={!canEdit || !value.CanEditValue}
                        formatTheInitialValue={true}
                        value={value.Value}
                        isForCurrency={true}
                        onChange={() => {}}
                        style={{
                            height: "39px",
                            padding: "8px",
                            minHeight: "39px",
                        }}
                        onBlur={(val) =>
                            value.CanEditValue
                                ? debouncedOnChange(defaultTo(val, 0))
                                : () => {}
                        }
                        placeholder={value.Text}
                    />
                )}
                <div className={styles.copyIcons}>
                    <AppIconButton
                        buttonType="Other"
                        showToolTip={true}
                        image={ImageAssets.common.copyBlue}
                        tooltipText={t("common.copy")}
                        readonly={!canEdit}
                        onClick={() => {
                            copyContracts(false);
                        }}
                    />
                    <AppIconButton
                        buttonType="Other"
                        image={ImageAssets.common.copyFilled}
                        showToolTip={true}
                        tooltipText={t("common.copyToEnd")}
                        readonly={!canEdit}
                        onClick={() => {
                            copyContracts(true);
                        }}
                    />
                </div>
            </div>
        </td>
    );
};

Component.displayName = "KindergartenClientContractSingleValueCell";
export const KindergartenClientContractSingleValueCell = React.memo(Component);
export default KindergartenClientContractSingleValueCell;
