import { AppDialog, AppDialogFooter, AppDialogProps } from "components/Dialogs";
import { AppLoader } from "components/Loaders";
import { ENGLISH_DATE_FORMAT, GERMAN_DATE_FORMAT } from "globals/constants";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import { AppLocale, AppResponse } from "models/general";
import {
    ClientImportColumns,
    ClientRegistrationImportColumns,
    ColumnMapping,
    DraggableColItem,
    EmployeeImportColumns,
    FileDataColumn,
    ImportColumns,
    ImportDataType,
    RequiredClientCols,
    RequiredClientRegistrationCols,
    RequiredEmployeeCols,
} from "models/importData";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import styles from "./ColumnsMappingDialog.module.scss";
import ColumnsMappingDnD from "./ColumnsMappingDnD";
import ImportDataDateFormatPicker, {
    ImportDateFormats,
} from "./ImportDataDateFormatPicker";

export interface ColumnsMappingDialogProps
    extends Omit<AppDialogProps, "children"> {
    modalOpen: boolean;
    onClose: () => void;
    onChange: (mapping: ColumnMapping, dateFormat?: string) => void;
    fileColumnsList: AppResponse<FileDataColumn[]>;
    loading?: boolean;
    importDataType: ImportDataType;
}

export const ColumnsMappingDialog: React.FC<ColumnsMappingDialogProps> = ({
    fileColumnsList,
    loading,
    importDataType,
    onClose,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const columns =
        importDataType === ImportDataType.CLIENT
            ? ClientImportColumns
            : importDataType === ImportDataType.CLIENT_REGISTRATIONS
            ? ClientRegistrationImportColumns
            : EmployeeImportColumns;

    const [state, setState] = useState<{
        dateFormat?: string;
        mappings: ColumnMapping;
    }>({
        dateFormat:
            appLocale == AppLocale.ENGLISH
                ? (ImportDateFormats.find((x) => x.Text == ENGLISH_DATE_FORMAT)
                      ?.Value as string)
                : appLocale == AppLocale.GERMAN
                ? (ImportDateFormats.find((x) => x.Text == GERMAN_DATE_FORMAT)
                      ?.Value as string)
                : undefined,
        mappings: {
            ...(Object.values(columns).reduce((result, curr) => {
                return {
                    ...result,
                    [curr]: null as DraggableColItem | null,
                };
            }, {}) as any),
        } as ColumnMapping,
    });

    const mappedColumns: DraggableColItem[] = useMemo(() => {
        return Object.values(state.mappings).filter(
            (x) => !isNil(x)
        ) as DraggableColItem[];
    }, [state]);

    const fileColumns: DraggableColItem[] = useMemo(() => {
        if (!loading && fileColumnsList && fileColumnsList.Data) {
            return fileColumnsList.Data.map((c) => {
                return {
                    id: uuid(),
                    name: c.ColumnName,
                };
            });
        } else {
            return [];
        }
    }, [loading, fileColumnsList]);
    useEffect(() => {
        if (fileColumns) {
            const dragcolumns = fileColumns;
            const columnKeys = Object.values(columns) as string[];
            const autoMappings = dragcolumns.filter((x) =>
                columnKeys.includes(x.name)
            );

            if (autoMappings.length > 0) {
                const newMappings: ColumnMapping = autoMappings.reduce(
                    (result, x) => ({
                        ...result,
                        [x.name]: x,
                    }),
                    { ...state.mappings }
                );
                setState({ ...state, mappings: newMappings });
            }
        }
    }, [fileColumns]);

    const isValid: boolean = useMemo(() => {
        return Boolean(
            state.dateFormat != undefined &&
                (
                    (importDataType === ImportDataType.CLIENT
                        ? RequiredClientCols
                        : importDataType === ImportDataType.CLIENT_REGISTRATIONS
                        ? RequiredClientRegistrationCols
                        : RequiredEmployeeCols) as ImportColumns[]
                ).reduce((status, m: ImportColumns) => {
                    return status && !isNil(state.mappings[m]);
                }, true)
        );
    }, [mappedColumns]);

    return (
        <AppDialog
            onClose={() => onClose()}
            size="xl"
            title={t("importData.columnMappingTitle")}
            footer={
                <AppDialogFooter
                    saveButtonText={t("importData.import")}
                    onDialogClose={() => onClose()}
                    disableSaveButton={loading || !isValid}
                    onClickSaveButton={() =>
                        onChange(state.mappings, state.dateFormat)
                    }
                />
            }
            {...rest}
        >
            <>
                {loading ? (
                    <AppLoader />
                ) : (
                    <div className={styles.bodyRoot}>
                        <div
                            className={styles.bodyTitle}
                            style={{ marginBottom: "15px" }}
                        >
                            <h4>{t("importData.dateFormatSelection")}*</h4>
                            <ImportDataDateFormatPicker
                                value={state.dateFormat}
                                onChange={(newFormat: string) =>
                                    setState((oldS) => ({
                                        ...oldS,
                                        dateFormat: newFormat,
                                    }))
                                }
                            />
                        </div>
                        <div className={styles.bodyTitle}>
                            <h4>{t("importData.columnMappingSubTitle")}</h4>
                            <span>{t("importData.columnMappingInfo")}</span>
                        </div>
                        {fileColumns.length > 0 && (
                            <ColumnsMappingDnD
                                mapping={state.mappings}
                                fileColumns={fileColumns}
                                onChange={(newMappings) =>
                                    setState((oldS) => ({
                                        ...oldS,
                                        mappings: newMappings,
                                    }))
                                }
                                importDataType={importDataType}
                            />
                        )}
                    </div>
                )}
            </>
        </AppDialog>
    );
};

export default ColumnsMappingDialog;
