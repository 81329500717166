export enum TodoHeaderTab {
    ALL = "All",
    ASSIGNED_TO_ME = "Assigned to me",
    CREATED_BY_ME = "Created by me",
}

export enum TodoStatus {
    Status_Initial = "Initial",
    Status_Done = "Done",
    Status_Complete = "Complete",
    Status_Reopened = "ReOpened",
}

export enum TodoSortColumn {
    Priority = 1, //default
    Task = 2,
    Description = 3,
    Area = 4,
    Assignee = 5,
    CreatedDate = 6,
    DueDate = 7,
    ReOpenedDate = 8,
    DoneDate = 9,
    CompletionDate = 10,
}
