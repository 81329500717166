import { ISO8601_TIME_FORMAT } from "globals/constants";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import {
    BusinessWorkingDays,
    weekDaysNumToStringDict,
    weekDaysStringToNumDict,
    WorkingDayData,
    WorkingDays,
    WorkingDaysNumber,
    WorkingDaysWithTime,
} from "models";
import { Optional } from "models/general";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business/BusinessGeneralService";

export function useBusinessWorkSchedule(businessId?: number) {
    const { linkProvider } = useRouting();
    const [state, setState] = useState<{
        data: Optional<WorkingDaysWithTime>;
        onlyFlagsMap: Optional<BusinessWorkingDays>;
        todaySchedule: Optional<WorkingDayData>;
        loading: boolean;
    }>({ data: null, onlyFlagsMap: null, loading: true, todaySchedule: null });
    const { sessionBusiness } = useSessionBusiness();
    const businessService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const { isLoading, data: response } = useQuery(
        getBusinessGeneralServiceKey("getBusinessWorkSchedule", {
            businessId: !isNil(businessId)
                ? businessId
                : sessionBusiness
                ? sessionBusiness.Id
                : 0,
        }),
        async () => await businessService.getBusinessWorkSchedule()
    );
    const dayOfWeek = moment().isoWeekday() as WorkingDaysNumber;
    useEffect(() => {
        if (!isLoading && response && response.Data) {
            const data = response.Data;
            const temp: WorkingDaysWithTime = {} as WorkingDaysWithTime;
            const shortData: BusinessWorkingDays = {} as BusinessWorkingDays;
            Object.keys(data)
                .sort(
                    (a, b) =>
                        weekDaysStringToNumDict[a as WorkingDays] -
                        weekDaysStringToNumDict[b as WorkingDays]
                )
                .map((day) => {
                    const workDay = day as WorkingDays;
                    const d = data[workDay];
                    temp[workDay] = {
                        IsWorkingDay: d.IsActive,
                        From: moment(d.From, ISO8601_TIME_FORMAT),
                        To: moment(d.To, ISO8601_TIME_FORMAT),
                    } as WorkingDayData;
                    shortData[workDay] = d.IsActive;
                });

            setState({
                data: temp,
                onlyFlagsMap: shortData,
                todaySchedule: temp[weekDaysNumToStringDict[dayOfWeek]],
                loading: false,
            });
        }
    }, [isLoading, response, dayOfWeek]);

    return state;
}

export default useBusinessWorkSchedule;
