import {
    ValidationRules,
    Validations,
    getInitializedValidityStateFromRules,
} from "globals/helpers/validationHelpers";
import { EmployeeShift } from "models/employeeShifts";
import { getInitializedValidityState } from "models/general";
import { KontoSortColumn } from "./enum";
import { BusinessKonto } from "./model";

export const getKontoSortColumnKeyFromEnum = (
    sortColumn: KontoSortColumn | undefined
): keyof BusinessKonto => {
    switch (sortColumn) {
        case KontoSortColumn.Name:
            return "Name";
        case KontoSortColumn.DisplayId:
            return "DisplayId";
        default:
            return "DisplayId";
    }
};

export function validateKonto(
    shift: BusinessKonto,
    state = getInitializedValidityState([], [])
) {
    const valueValidationRules: ValidationRules<keyof BusinessKonto> = {
        Name: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        shift,
        state
    );

    return state;
}
