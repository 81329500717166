export const NotificationManagementEnTranslations = {
    notificationManagement: {
        all: "All",
        allNotifications: "All Notifications",
        allDownloads: "All Downloads",
        deleteSelected: "Delete",
        deleteMultipleSuccess: "Notifications deleted successfully...",
        deleteSuccess: "Notification deleted successfully...",
        markRead: "Mark as read",
        name: "Notification",
        newNotificationArrived: "New notification arrived",
        newDownloadAvailable: "New download available",
        noMoreNotifications: "No more notifications...",
        noNewNotifications: "No new notifications...",
        noNewDownloads: "No new downloads...",
        noMoreDownloads: "No more downloads...",
        notificationListEmpty: "No notification found...",
        downloadListEmpty: "No download found...",
        notificationMessages: {
            ClientsImport: {
                message: {
                    failure: "Failed to import clients data.",
                    success: "Clients data import successfully completed.",
                },
                title: "Clients Data Import",
            },
            ClientRegistrationsImport: {
                message: {
                    failure: "Failed to import client registrations.",
                    success:
                        "Client registrations import successfully completed.",
                },
                title: "Client Registrations  Import",
            },
            KindergartenExternalRegistrationFromApi: {
                message: {
                    info:
                        "New client registration {{name}}} received from external source.",
                },
                title: "New Client Registrations Received",
            },
            DeleteAllClientRegistrations: {
                message: {
                    failure:
                        "Unable to delete all kindergarten client registrations.",
                    success:
                        "All kindergarten client registrations deleted successfully.",
                },
                title: "Delete All Client Registrations",
            },
            DeleteAllClients: {
                message: {
                    failure: "Unable to delete all kindergarten clients.",
                    success: "All kindergarten clients deleted successfully.",
                },
                title: "Delete All Clients",
            },
            DeleteAllEmployees: {
                message: {
                    failure:
                        "Unable to remove all employees from {{lowercaseBusinessType}}.",
                    success:
                        "All employees removed from {{lowercaseBusinessType}} successfully.",
                },
                title: "Delete All Employees",
            },
            EmployeesImport: {
                message: {
                    failure: "Fail to import employees data.",
                    info: "Employees data import successfully completed.",
                    success: "Employees data import successfully completed.",
                },
                title: "Employees Data Import",
            },
            HqRegisterRequest: {
                message: {
                    info: `You have a new "Business HQ Registration Request".`,
                },
                title: "HQ Registration Request",
            },
            IpadRegister: {
                message: {
                    info: "You have a new iPad Registration request.",
                },
                title: "iPad Registration request",
            },
            LwcDelete: {
                message: {
                    failure: "Failed to delete {{lowercaseBusinessType}}.",
                    success:
                        "{{businessType}} along with all its data is deleted successfully.",
                },
                title: `{{businessType}} "{{name}}" deleted`,
            },
            LwDelete: {
                message: {
                    failure: "Failed to delete {{lowercaseBusinessType}}.",
                    success:
                        "{{businessType}} along with all its data is deleted successfully.",
                },
                title: `LW "{{name}}" deletion`,
            },
            TodoReminder: {
                message: {
                    success: `Your task "{{name}}" is due on {{dueDateTime}}.`,
                },
                title: `Reminder for task "{{name}}"`,
            },
            TodoRepeat: {
                message: {
                    success: 'Todo task "{{name}}" re-created successfully.',
                },
                title: "Todo Task (Auto Repeat)",
            },
            ClientEmployeeDocumentCompilation: {
                message: {
                    failure:
                        "Failed to compile {{lowercaseDocumentArea}} documents.",
                    success:
                        "{{documentArea}} documents compiled successfully.",
                },
                title: "Documents Compiled",
            },
            KindergartenClientAccountingExcel: {
                message: {
                    failure: "Failed to generate client accounting excel.",
                    success: "Client accounting sheet generated successfully.",
                },
                title: "Client Accounting Excel Generated",
            },
            BusinessEmployeeAccountingExcel: {
                message: {
                    failure: "Failed to generate employee accounting excel.",
                    success: "Employee accounting sheet generated successfully.",
                },
                title: "Employee Accounting Excel Generated",
            },
            BusinessSupplierAccountingExcel: {
                message: {
                    failure: "Failed to generate supplier accounting excel.",
                    success: "Supplier accounting sheet generated successfully.",
                },
                title: "Supplier Accounting Excel Generated",
            },
        },
        documentArea: {
            client: "Client",
            employee: "Employee",
        },
        notifications: "Notifications",
        downloads: "Downloads",
        readSuccess: "Notification read successfully.",
        downloadSuccess: "File download started successfully.",
        recent: "Recent",
        remove: "Remove",
        selectAction: "Select Action",
        trash: "Trash",
        unread: "Unread",
    },
};

export default NotificationManagementEnTranslations;
