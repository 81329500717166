import { AppResponse } from "models/general/response";
import { LocationModulePlan } from "models/modulePlans";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface ILocationSettingModulePlanService {
    list(): Promise<AppResponse<LocationModulePlan[]>>;
    get(id: string): Promise<AppResponse<LocationModulePlan>>;
    createUpdate(
        request: LocationModulePlan
    ): Promise<AppResponse<LocationModulePlan>>;
    delete(id: string): Promise<AppResponse<boolean>>;
}

export function getLocationSettingModulePlanServiceKey(
    name: keyof ILocationSettingModulePlanService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `LocationSettingModulePlan_${name}_${JSON.stringify(data)}`;
}

const apiLinkProvider = routesForContext()
    .lillywait.locationSettings()
    .api.withId().modulePlans;

type ApiLinkProviderType = typeof apiLinkProvider;

export class LocationSettingModulePlanService
    extends BaseService
    implements ILocationSettingModulePlanService
{
    public modulePlanRoutes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.modulePlanRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<LocationModulePlan[]>> {
        return this.doServerXHR<LocationModulePlan[]>({
            url: this.modulePlanRoutes.list(),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<LocationModulePlan>> {
        return this.doServerXHR<LocationModulePlan>({
            url: this.modulePlanRoutes.get(id),
            method: "get",
        });
    }

    createUpdate(
        request: LocationModulePlan
    ): Promise<AppResponse<LocationModulePlan>> {
        return this.doServerXHR<LocationModulePlan>({
            url: this.modulePlanRoutes.createUpdate(),
            method: "post",
            data: request,
        });
    }

    delete(id: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.modulePlanRoutes.delete(id),
            method: "post",
        });
    }
}
