import React from "react";
import styles from "./ModulePlanCardSkelton.module.scss";
import { Image } from "react-bootstrap";
import { ImageAssets } from "globals/images";
import Skeleton from "react-loading-skeleton";
import { t } from "i18next";

export interface ModulePlanCardSkeltonProps {
    count: number;
    showPriceRow?: boolean;
}

export const ModulePlanCardSkelton: React.FC<ModulePlanCardSkeltonProps> = ({
    count,
    showPriceRow = false,
}) => {
    return (
        <>
            {Array(count)
                .fill(1)
                .map((x, idx) => (
                    <div key={idx} className={styles.card}>
                        <div className={styles.header}>
                            <div className={styles.basicPlanWithTag}>
                                <div className={styles.basicPlan}>
                                    <Skeleton height={37} width={100} />
                                </div>

                                <Image
                                    src={ImageAssets.common.tagGrayLightest}
                                    className={styles.tagImage}
                                />
                            </div>
                            {showPriceRow && (
                                <div className={styles.perMonthCostDiv}>
                                    <Skeleton height={30} width={80} />
                                    <span className={styles.monthText}>
                                        &nbsp;/{" "}
                                        {t("common.month").toUpperCase()}
                                    </span>
                                </div>
                            )}
                            <hr />
                        </div>
                        <div className={styles.body}>
                            <div className={styles.content}>
                                <div className={styles.description}>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                                <div className={styles.modules}>
                                    {Array(3)
                                        .fill(1)
                                        .map((x, idx) => (
                                            <div
                                                key={idx}
                                                className={styles.moduleItem}
                                            >
                                                <Skeleton
                                                    width={14}
                                                    circle={true}
                                                    className={styles.check}
                                                />
                                                <Skeleton
                                                    height={13}
                                                    width={100}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div style={{ justifyContent: "center" }}>
                                <Skeleton
                                    width={180}
                                    height={45}
                                    className={styles.btnSkelton}
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
};

export default ModulePlanCardSkelton;
