import { cookieAvailable } from "globals/helpers/cookieHelper";
import { AppRouteParams } from "hooks/general/routing";
import { defaults } from "lodash-es";
import {
    Business_BasicData,
    Business_Dashboard_Client,
    Business_Dashboard_Employee,
    EditorType,
    LWSupportPermissions,
    LW_Support_MergeCompanies,
    PermissionAccessTypes,
} from "models";
import { BusinessType } from "models/business";
import { CookiesKey } from "models/general";
import { commonApiRoutes } from "routing/routes/commonApiRoutes";
import noAuthRoutes from "routing/routes/noAuth/routes";
import {
    authUserRoutes,
    businessRoutes,
    kindergartenRoutes,
    lillywaitRoutes,
} from "./routes";
import {
    AuthenticatedUserRoutingContext,
    AuthLessRoutingContext,
} from "./routingContexts";
import BaseRoutingContext from "./routingContexts/BaseRoutingContext";
import { DefaultRouteParams, getUrlForPattern } from "./routingContexts/helper";

export const routesForContext = (
    params?: AppRouteParams,
    buildUrl: boolean = true
) => {
    params = defaults({}, params, DefaultRouteParams);

    // available contexts
    const baseContext = new BaseRoutingContext();
    const authLessRoutingContext = new AuthLessRoutingContext();

    const authLessRoutesInfo = noAuthRoutes(buildUrl, authLessRoutingContext);
    const authUserRoutesInfo = authUserRoutes(
        buildUrl,
        new AuthenticatedUserRoutingContext()
    );
    const businessRoutesInfo = businessRoutes(buildUrl, params);
    const kindergartenRoutesInfo = kindergartenRoutes(buildUrl, params);
    const lillywaitRoutesInfo = lillywaitRoutes(buildUrl, params);

    return {
        base: baseContext.getBaseUrl(),
        apiBase: baseContext.getApiBaseUrl(),
        api: {
            // for api-routes that don't fall in any module
            ...commonApiRoutes(baseContext, params.sessionBusinessId),
            tinymce: ((tinymceBase: string) => {
                return {
                    uploadFile: () =>
                        baseContext.buildUrl(`${tinymceBase}/upload-file`),
                    getDynamicPlaceholdersForEditor: (qParams: {
                        editorType: EditorType;
                    }) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${tinymceBase}/get-dynamic-placeholders`,
                            true,
                            undefined,
                            qParams
                        ),
                };
            })("tinymce"),
            general: ((generalBase: string) => {
                return {
                    getCountries: (locale: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${generalBase}/get-country-options/:locale`,
                            true,
                            { locale: locale }
                        ),
                    getTimezones: (locale: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${generalBase}/get-timezones/:locale`,
                            true,
                            { locale: locale }
                        ),
                    getUserInfo: (email: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${generalBase}/get-user-info`,
                            true,
                            undefined,
                            { email: email }
                        ),
                    getEncodedValue: (id: number) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${generalBase}/get-decode-value/:id`,
                            true,
                            { id: id.toString() }
                        ),
                    getLocationsData: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${generalBase}/find-location-data`,
                            true
                        ),
                };
            })("general"),
        },
        screens: {
            // for screens that don't fall in any module
            home: (
                checkPermission?: (
                    identifier: string | string[],
                    permissionAccessType?: PermissionAccessTypes[]
                ) => boolean,
                isContractExpired: boolean = false
            ) => {
                const hasUserCookie = cookieAvailable(CookiesKey.USER_COOKIE); // on page-refresh we only have user-cookie
                const isAuthenticated =
                    checkPermission &&
                    params?.sessionBusinessId !== ":sessionBusinessId";

                if (!isAuthenticated && !hasUserCookie) {
                    return authLessRoutesInfo.screens.login();
                } else {
                    if (isContractExpired) {
                        if (
                            checkPermission &&
                            checkPermission(
                                [Business_BasicData],
                                [PermissionAccessTypes.EDIT]
                            )
                        ) {
                            return businessRoutesInfo.screens.contractExpired();
                        }
                        return authUserRoutesInfo.screens.editProfile();
                    } else if (checkPermission) {
                        if (
                            checkPermission([
                                Business_Dashboard_Client,
                                Business_Dashboard_Employee,
                            ])
                        ) {
                            return businessRoutesInfo.screens.dashboard();
                        } else if (checkPermission([Business_BasicData])) {
                            // business branches list
                            return businessRoutesInfo.screens.admin.companies();
                        } else if (
                            checkPermission(
                                LWSupportPermissions.filter(
                                    (x) => x !== LW_Support_MergeCompanies
                                )
                            )
                        ) {
                            // business branches list
                            return lillywaitRoutesInfo
                                .support()
                                .screens.list(BusinessType.KINDERGARTEN);
                        }
                    }

                    return !isAuthenticated
                        ? businessRoutesInfo.screens.dashboard() // to initialize me response
                        : authUserRoutesInfo.screens.editProfile();
                }
            },
            documentPreview: (queryParams?: {
                cacheKey: string;
                name?: string;
            }) => {
                // no need to get tabs here as I am already fetching it from context in component
                return getUrlForPattern(
                    baseContext,
                    buildUrl,
                    "document-preview",
                    false,
                    undefined,
                    queryParams
                );
            },
        },
        business: businessRoutesInfo,
        kindergarten: kindergartenRoutesInfo,
        lillywait: lillywaitRoutesInfo,
        noAuth: authLessRoutesInfo,
        authUser: authUserRoutesInfo,
    };
};

export default routesForContext;

// const temp = routesForContext();
// export type RoutesForContextType = typeof temp;
