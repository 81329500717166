import { AppSelect, AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { defaultTo } from "lodash-es";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { COLOR_GREY_LIGHTEST } from "theme/themeConstants";

export enum FilterType {
    Group = "Group",
    Client = "Client",
    Employee = "Employee",
}
export interface MultiSelectItem {
    type?: FilterType;
    label: string;
    value: number | string;
    color?: string; // for userType in pivot view
}

export interface FilterMultiSelectComponentProps {
    data: MultiSelectItem[];
    onChange: (val: MultiSelectItem[]) => void;
    value: MultiSelectItem[];
    loading?: boolean;
    isReadonly?: boolean;
    sort?: boolean;
    className?: string;
    useOldSelect?: boolean;
    usePortal?: boolean;
}

const Dropdown = React.memo((props: FilterMultiSelectComponentProps) => {
    const { t } = useTranslation();
    const propsRef = useRef(props);
    propsRef.current = props;
    const {
        value,
        data: items,
        isReadonly,
        useOldSelect = false,
        onChange,
    } = props;

    const allValue: MultiSelectItem = {
        label: t("common.all"),
        value: "",
    };

    const currentItem = useMemo(() => {
        let selected: MultiSelectItem[] = [];
        const its = items ? items : [];
        const filteredItems = its.filter((x) => x.value != allValue.value); // skip all

        selected = filteredItems.filter(
            // also match type because client and employee can have same ID
            (x) => !!value.find((y) => y.value == x.value && x.type == y.type)
        );

        if (selected.length == 0 || filteredItems.length == selected.length) {
            selected = [allValue];
        }
        return selected;
    }, [value, items]);
    const hasAll = currentItem.find((x) => x.value == allValue.value);

    if (items.length == 3) {
        console.log(items);
    }
    const appSelectProps = {
        options: [allValue, ...items] as MultiSelectItem[],
        isMulti: true,
        isDisabled: isReadonly,
        isLoading: props.loading,
        value: currentItem,
        styles: {
            multiValue: ({ data }: { data: MultiSelectItem }) => {
                if (data.color) {
                    const color = defaultTo(data.color, COLOR_GREY_LIGHTEST);
                    return {
                        background: color,
                    };
                } else {
                    return {};
                }
            },
        },
        menuPortalTarget: props.usePortal == true ? document.body : undefined,
        onChange: (opt: MultiSelectItem[]) => {
            if (opt == null || opt.length == 0) {
                // don't change if already all is selected
                if (!hasAll) {
                    onChange(items);
                }
            } else {
                if (hasAll) {
                    if (
                        !(
                            opt.length == 1 &&
                            opt.filter((x) => x.value != allValue.value)
                        )
                    ) {
                        // user selected option other then all
                        opt = opt.filter((x) => x.value != allValue.value);
                        onChange(opt);
                    }
                } else {
                    if (opt.find((x) => x.value == allValue.value)) {
                        //select all
                        onChange(items);
                    } else {
                        onChange(opt);
                    }
                }
            }
        },
        getOptionLabel: (opt: SimpleOption) => opt.label,
        getOptionValue: (opt: SimpleOption) => opt.value,
    };
    return useOldSelect ? (
        <AppSelectOld {...appSelectProps} />
    ) : (
        <AppSelect {...appSelectProps} />
    );
});

export function FilterMultiSelectComponent(
    props: FilterMultiSelectComponentProps
) {
    const {
        onChange,
        isReadonly = false,
        data,
        sort = true,
        loading = false,
        useOldSelect = false,
        usePortal = false,
        ...rest
    } = props;

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const onChangeDropdown = useCallback(
        (val: MultiSelectItem[]) => onChangeRef.current(val),
        []
    );

    return (
        <Dropdown
            data={
                data
                    ? sort
                        ? data.sort((a, b) => (a.label > b.label ? 1 : -1))
                        : data
                    : []
            }
            isReadonly={isReadonly || loading}
            usePortal={usePortal}
            loading={loading}
            useOldSelect={useOldSelect}
            onChange={onChangeDropdown}
            {...rest}
        />
    );
}

export default FilterMultiSelectComponent;
