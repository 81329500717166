import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { AppTabButtonProps, AppTabButtons } from "components/Tabs";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { BusinessType } from "models";
import { AppPlatforms } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./RoleManagementHeader.module.scss";
export interface RoleManagementHeaderProps {
    onTabChangeHandler: (tab: AppPlatforms) => void;
    selectedTab: AppPlatforms;
    onCreate: () => void;
    readonly: boolean;
}
export const RoleManagementHeader: React.FC<RoleManagementHeaderProps> = ({
    onTabChangeHandler,
    selectedTab,
    onCreate,
    readonly,
}) => {
    const { t } = useTranslation();
    const { sessionBusiness } = useSessionBusiness();

    return (
        <AppContentHeader
            title={t("roleManagement.role.title")}
            classNameForIcon="pe-7s-tools"
        >
            <div>
                <AppTabButtons
                    btnClass={styles.tabButton}
                    tabButtons={[
                        {
                            label: t("roleManagement.role.tab.web"),
                            onClick: () => onTabChangeHandler(AppPlatforms.WEB),
                            active: selectedTab == AppPlatforms.WEB,
                        } as AppTabButtonProps,
                        // {
                        //     label: t("roleManagement.role.tab.app"),
                        //     onClick: () => onTabClick(AppPlatforms.MOBILE),
                        //     active: selectedTab == AppPlatforms.MOBILE,
                        // } as AppTabButtonProps,
                        ...(sessionBusiness?.Type === BusinessType.LW
                            ? []
                            : [
                                  {
                                      label: t("roleManagement.role.tab.ipad"),
                                      onClick: () =>
                                          onTabChangeHandler(AppPlatforms.IPAD),
                                      active: selectedTab == AppPlatforms.IPAD,
                                  } as AppTabButtonProps,
                              ]),
                    ]}
                />
            </div>
            {!readonly ? (
                <AppRoundedTextIconButton onClick={() => onCreate()} />
            ) : (
                <div style={{ width: "205px" }} />
            )}
        </AppContentHeader>
    );
};

export default RoleManagementHeader;
