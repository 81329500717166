import AppContentHeader from "components/AppContentHeader";
import { AppSelect } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer } from "components/Containers";
import { AppCheckbox, SearchField } from "components/FormFields";
import { AppOverlayLoader } from "components/Loaders";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    showSweetAlertConfirmation,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useWebNotificationHub } from "hooks/layout/useWebNotificationHub";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { ResponseMeta, Selectable } from "models/general";
import {
    AppNotificationType,
    constructStatusBaseImageAndColor,
    getNotificationFromResponse,
    NotificationFilterEnum,
    NotificationModel,
    NotificationRequest,
    NotificationResponse,
    translateTitleAndDetail,
} from "models/notification";
import {
    Business_Downloads,
    Business_WebNotification,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import {
    getNotificationServiceKey,
    NotificationService,
} from "services/business";
import styles from "./NotificationsList.module.scss";
import {
    NotificationItem,
    NotificationItemSkeleton,
    NotificationListEnd,
} from "./partials";

interface NotificationsMainContainerState {
    notifications: Selectable<NotificationModel>[];
    meta: ResponseMeta;
    notificationFilter: NotificationFilterEnum;
    search?: string;
}

const PAGE_SIZE = 25;
export const NotificationsList: React.FC = () => {
    const [state, setState] = useState<NotificationsMainContainerState>({
        notifications: [],
        notificationFilter: NotificationFilterEnum.All,
        meta: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
            HasNextPage: false,
        },
    });
    const { getDateFormatForLocale } = useLocaleHelpers();
    const [search, setSearch] = useState("");
    const { getPermissionMap } = useCheckPermission();
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { id } = useSessionUser();
    const { t } = useTranslation();
    const { id: sessionBusinessId } = useSessionBusiness();
    const { signalR } = useWebNotificationHub();
    const [itemsListContainerRef, setItemsListContainerRef] =
        useState<HTMLDivElement | null>(null);

    const notificationPath = linkProvider.business.screens.notifications();

    let type: AppNotificationType = "downloads";
    if (location.pathname.toLowerCase() === notificationPath.toLowerCase()) {
        type = "notifications";
    }

    const permMap = useMemo(() => {
        return getPermissionMap(
            type == "notifications"
                ? Business_WebNotification
                : Business_Downloads
        );
    }, [getPermissionMap]);

    const canEdit = checkPermissionInMap(permMap, [PermissionAccessTypes.EDIT]);

    const canDelete = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const notificationService = new NotificationService(
        linkProvider.business.api.notification
    );

    const request: NotificationRequest = {
        UserId: id,
        Search: state.search, //search not working in backend
        FromDateTime:
            state.notificationFilter.toString().toLowerCase() ==
            NotificationFilterEnum.RECENT.toString().toLowerCase()
                ? moment().subtract(2, "days").format(ISO8601_DATE_FORMAT) // load notifications upto 2 days old
                : null,
        ReadStatus:
            state.notificationFilter.toString().toLowerCase() ==
            NotificationFilterEnum.UNREAD.toString().toLowerCase()
                ? false
                : null,
        ForDownloadsOnly: type === "downloads",
        ForNotificationsOnly: type === "notifications",
        Page: state.meta.PageNumber,
        PageSize: PAGE_SIZE,
    } as NotificationRequest;

    const {
        isLoading: loading,
        isFetching: fetching,
        data: notificationResponse,
        refetch: refetchNotificationList,
    } = useQuery(
        getNotificationServiceKey("getNotificationList", {
            ...request,
            businessId: sessionBusinessId,
        }),
        async () => {
            return await notificationService.getNotificationList(request);
        }
    );

    useEffect(() => {
        if (
            !fetching &&
            notificationResponse &&
            notificationResponse.Data &&
            notificationResponse.Meta
        ) {
            const notificationData = notificationResponse.Data.map((x) =>
                constructStatusBaseImageAndColor(getNotificationFromResponse(x))
            );
            setState({
                ...state,
                notifications:
                    notificationResponse.Meta.PageNumber == 1
                        ? notificationData
                        : [...state.notifications, ...notificationData],
                meta: { ...state.meta, ...notificationResponse.Meta },
            });
        }
    }, [notificationResponse, fetching]);

    const {
        isLoading: bulkLoading,
        data: bulkResponse,
        mutate: bulkDelete,
    } = useMutation(
        async (ids: number[]) => await notificationService.bulkDelete(ids)
    );

    const {
        isLoading: bulkReadLoading,
        data: bulkReadResponse,
        mutate: markAllRead,
    } = useMutation(
        async (ids: number[]) => await notificationService.markAllRead(ids)
    );

    const onChange = (
        list: Selectable<NotificationModel>[],
        refetch?: boolean
    ) => {
        setState({
            ...state,
            notifications: list,
        });
        if (refetch) {
            refetchNotificationList();
        }
    };

    useEffect(() => {
        if (!bulkLoading && bulkResponse) {
            showSweetAlertInfo(
                t("common.success"),
                t(`notificationManagement.deleteMultipleSuccess`),
                "success"
            );
            const newList = state.notifications.filter(
                (x) => x.isChecked == null || x.isChecked == false
            );
            onChange(newList, true);
        }
    }, [bulkLoading, bulkResponse]);

    useEffect(() => {
        if (!bulkReadLoading && bulkReadResponse) {
            showSweetAlertInfo(
                t("common.success"),
                t(`notificationManagement.readSuccess`),
                "success"
            );
            const newList = state.notifications.map((x) => {
                if (x.isChecked == true) {
                    return { ...x, IsRead: true };
                } else {
                    return x;
                }
            });
            onChange(newList, false);
        }
    }, [bulkReadLoading, bulkReadResponse]);

    const checkedIds = state.notifications
        .filter((x) => x.isChecked)
        .map((x) => x.Id);
    let allSelected = false;
    if (state.notifications.length > 0) {
        allSelected =
            state.notifications.filter((x) => !x.isChecked).length == 0;
    }

    const removeClickHandler = (id: number) => {
        onChange(state.notifications.filter((x) => x.Id != id));
    };

    const bulkOptions = [
        // {
        //     label: t("notificationManagement.selectAction"),
        //     value: 0,
        // },
        {
            label: t("notificationManagement.markRead"),
            value: 1,
        },
        ...(canDelete
            ? [
                  {
                      label: t("notificationManagement.deleteSelected"),
                      value: 2,
                  },
              ]
            : []),
    ];
    const bulkOperations = (optionValue: number) => {
        if (optionValue == 1) {
            markAllRead(checkedIds);
        } else if (optionValue == 2) {
            showSweetAlertConfirmation(
                t(`common.deleteSelected`),
                t("common.deleteSelectedConfirmation"),
                () => {
                    bulkDelete(checkedIds);
                },
                t("common.delete.yes"),
                "question",
                true
            );
        }
    };

    const options = Object.keys(NotificationFilterEnum).map(
        (k) =>
            ({
                value: k,
                label: t(
                    `notificationManagement.${k.toString().toLowerCase()}`
                ),
            } as SimpleOption)
    );

    useEffect(() => {
        if (signalR) {
            signalR.on("notificationReceived", (notification: any) => {
                //any applies because signalR gives objects in camel case in response
                if (sessionBusinessId == notification.businessId) {
                    const newNotification = constructStatusBaseImageAndColor(
                        getNotificationFromResponse({
                            Id: notification.id,
                            ForUserId: notification.forUserId,
                            IsRead: notification.isRead,
                            BusinessId: notification.businessId,
                            StatusType: notification.statusType,
                            NotificationType: notification.notificationType,
                            TargetUrl: notification.targetUrl,
                            TimeStamp: notification.timeStamp,
                            Meta: {
                                Name: notification.meta.name,
                                ForBusinessType:
                                    notification.meta.forBusinessType,
                                DueDateTime: notification.meta.dueDateTime,
                            },
                        } as NotificationResponse)
                    );
                    setState((prev) => ({
                        ...prev,
                        notifications: [newNotification, ...prev.notifications],
                    }));
                }
            });
        }
    }, []);

    return (
        <div className={styles.outer}>
            <AppContentHeader
                title={t(`notificationManagement.${type}`)}
                className={styles.headerScroll}
                icon={
                    type == "notifications"
                        ? ImageAssets.common.bellBlue
                        : ImageAssets.common.download
                }
            >
                <div style={{ width: "350px", marginRight: "5px" }}>
                    <AppSelect
                        options={options}
                        // menuPortalTarget={document.body}
                        value={options.find(
                            (x) =>
                                x.value.toString().toLowerCase() ==
                                state.notificationFilter
                                    .toString()
                                    .toLowerCase()
                        )}
                        onChange={(e: any) =>
                            setState({
                                ...state,
                                meta: {
                                    ...state.meta,
                                    PageNumber: 1,
                                },
                                notificationFilter:
                                    e.value as NotificationFilterEnum,
                            })
                        }
                    />
                </div>
            </AppContentHeader>
            <AppContainer
                heightToAdjust={185}
                showHeader={true}
                mediumViewAdjustment={40}
                mobileViewAdjustment={40}
                classes={{
                    title: styles.title,
                }}
                ellipseTitle={false}
                title={
                    <div className={styles.headerOuter}>
                        <div className={styles.headerDelete}>
                            {state.notifications.length > 0 && (
                                <div className={`${styles.checkBox}`}>
                                    <AppCheckbox
                                        checked={allSelected}
                                        onChange={(e: any) => {
                                            onChange(
                                                state.notifications.map((x) => {
                                                    return {
                                                        ...x,
                                                        isChecked:
                                                            e.target.checked,
                                                    };
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            )}
                            {checkedIds.length > 0 && (
                                <div
                                    style={{
                                        width: "250px",
                                        marginLeft: "15px",
                                    }}
                                >
                                    <AppSelect
                                        options={bulkOptions}
                                        value={undefined}
                                        placeholder={t(
                                            "notificationManagement.selectAction"
                                        )}
                                        onChange={(e: SimpleOption) =>
                                            bulkOperations(e.value)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.headerSearch}>
                            <SearchField
                                value={search ? search : ""}
                                onValueChange={(value) => setSearch(value)}
                                iconClassName={styles.searchIconClass}
                                className={styles.searchField}
                                onBlur={(query: string) => setSearch(query)}
                            />
                        </div>
                    </div>
                }
            >
                <div className={styles.bodyOuter}>
                    {bulkLoading && (
                        <AppOverlayLoader
                            fullHeight={false}
                            className={styles.loader}
                        />
                    )}

                    <div
                        className={styles.indexBody}
                        ref={setItemsListContainerRef}
                    >
                        {(loading && state.notifications.length == 0) ||
                        !itemsListContainerRef ? (
                            <NotificationItemSkeleton count={8} />
                        ) : (
                            <InfiniteScroll
                                className={styles.infiniteScroll}
                                dataLength={state.notifications.length}
                                next={() => {
                                    setState({
                                        ...state,
                                        meta: {
                                            ...state.meta,
                                            PageNumber:
                                                defaultTo(
                                                    state.meta.PageNumber,
                                                    1
                                                ) + 1,
                                        },
                                    });
                                }}
                                scrollableTarget={itemsListContainerRef}
                                hasMore={defaultTo(
                                    state.meta.HasNextPage,
                                    true
                                )}
                                loader={<></>}
                                endMessage={
                                    <NotificationListEnd
                                        totalItemCount={
                                            state.notifications.length
                                        }
                                        type={type}
                                    />
                                }
                            >
                                <>
                                    {state.notifications.length > 0 &&
                                        state.notifications
                                            .filter((x) => {
                                                if (
                                                    defaultTo(search, "") == ""
                                                ) {
                                                    return true;
                                                }
                                                const translated =
                                                    translateTitleAndDetail(
                                                        x,
                                                        getDateFormatForLocale()
                                                    );
                                                return (
                                                    defaultTo(
                                                        translated.Title,
                                                        ""
                                                    )
                                                        .toLowerCase()
                                                        .includes(
                                                            search.toLowerCase()
                                                        ) ||
                                                    defaultTo(
                                                        translated.Detail,
                                                        ""
                                                    )
                                                        .toLowerCase()
                                                        .includes(
                                                            search.toLowerCase()
                                                        )
                                                );
                                            })
                                            .map((x, index) => (
                                                <NotificationItem
                                                    key={index}
                                                    value={x}
                                                    type={type}
                                                    canEdit={canEdit}
                                                    canDelete={canDelete}
                                                    onChange={(item) => {
                                                        const refetchCount: boolean =
                                                            false;
                                                        onChange(
                                                            state.notifications.map(
                                                                (oV) => {
                                                                    if (
                                                                        oV.Id ==
                                                                        item.Id
                                                                    ) {
                                                                        return item;
                                                                    } else {
                                                                        return oV;
                                                                    }
                                                                }
                                                            ),
                                                            refetchCount
                                                        );
                                                    }}
                                                    removeClickHandler={
                                                        removeClickHandler
                                                    }
                                                />
                                            ))}
                                    {fetching && (
                                        <NotificationItemSkeleton count={2} />
                                    )}
                                </>
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            </AppContainer>
        </div>
    );
};

export default NotificationsList;
