import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { CompanyDataFieldType } from "./enum";
import { BusinessCompanyDataField } from "./model";

function validateBusinessCompanyDataField(
    record: BusinessCompanyDataField,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof BusinessCompanyDataField> = {
        Value:
            record.Type == CompanyDataFieldType.Numbers
                ? [
                      {
                          rule: Validations.OPTIONAL_NUMBER,
                          message: i18next.t("companyDataField.value.invalid"),
                      },
                      {
                          rule: Validations.MAX,
                          options: {
                              value: record.FieldSize,
                          },
                      },
                  ]
                : [
                      {
                          rule: Validations.MAX_LENGTH,
                          message: i18next.t(
                              "companyDataField.value.maxLength",
                              { val: record.FieldSize }
                          ),
                          options: {
                              value: record.FieldSize + 1,
                          },
                      },
                  ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        record,
        state
    );

    return state;
}

export function validateBusinessCompanyDataFields(
    fields: BusinessCompanyDataField[],
    state = getInitializedValidityState([], [])
) {
    let manager = new ValidityStateManager(state);
    fields.forEach((field) => {
        const id = field.Id.toString();
        manager = manager.replaceFieldState(id, {
            errors: [],
            identifier: id,
            children: validateBusinessCompanyDataField(
                field,
                manager.getFieldState(id)?.children
            ),
        });
    });
    return manager.state;
}
