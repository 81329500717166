import { getFilteredArray } from "globals/helpers/generalHelper";
import { orderBy } from "lodash-es";
import { LwClientHQ } from "models";
import { BusinessStatus } from "models/business";
import { SortOrder, TableSort } from "models/general";
import { LwClientSortColumn } from "./enum";
import { LwClientFilters } from "./model";

export const getLwClientSortColumnKeyFromEnum = (
    clientsSortColumn: LwClientSortColumn | undefined,
    forHq: boolean = false
): string => {
    const base = forHq ? "HqInfo." : "";
    switch (clientsSortColumn) {
        case LwClientSortColumn.DisplayId:
            return `${base}DisplayId`;
        case LwClientSortColumn.FacilityName:
            return `${base}Name`;
        case LwClientSortColumn.CompanyName:
            return `${base}CompanyName`;
        case LwClientSortColumn.Status:
            return `${base}Status`;
        case LwClientSortColumn.IsHQ:
            return `${base}IsHQ`;
        default:
            return `${base}DisplayId`;
    }
};

export const getLwClientsFilterDefaultValue = (): LwClientFilters => {
    return {
        DisplayId: null,
        IsHQ: null,
        Name: null,
        CompanyName: null,
        Status: BusinessStatus.Active,
    };
};
export const filterLwClientsList = (
    data: LwClientHQ[],
    filters: LwClientFilters
): LwClientHQ[] => {
    // first filter branches
    return data
        .map((hq) => {
            const updatedHq = { ...hq };
            updatedHq.Branches = hq.Branches
                ? getFilteredArray(hq.Branches, filters, ["IsHQ"])
                : [];
            const isHqSatisfyFilter =
                getFilteredArray([hq.HqInfo], filters, ["IsHQ"]).length > 0;
            return isHqSatisfyFilter ||
                updatedHq.Branches.length > 0 ||
                filters.IsHQ
                ? updatedHq
                : undefined;
        })
        .filter((x) => x !== undefined) as LwClientHQ[];
};
export const sortLwClientsList = (
    data: LwClientHQ[],
    sort: TableSort<LwClientSortColumn>
) => {
    const filteredOrders = [
        {
            col: getLwClientSortColumnKeyFromEnum(sort.SortColumn, true),
            dir: sort.SortOrder,
        },
        {
            col: getLwClientSortColumnKeyFromEnum(
                LwClientSortColumn.DisplayId,
                true
            ),
            dir: SortOrder.DESC,
        },
    ];

    const sorted = orderBy(
        data,
        filteredOrders.map(({ col }) => col),
        filteredOrders.map(({ dir }) => (dir == SortOrder.ASC ? "asc" : "desc"))
    );
    return sorted;
};
