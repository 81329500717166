import { PropsWithChildren, useEffect } from "react";
import { BaseObject } from "models/general";
import { useWebEventHub } from "hooks/layout/useWebEventHub";
import { useTwilioClient } from "hooks/layout/useTwilioClient";

export const TwilioClientProvider: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}: PropsWithChildren<BaseObject>) => {
    // init signalR
    const { twilioClient } = useTwilioClient();

    return <>{children}</>;
};

export default TwilioClientProvider;
