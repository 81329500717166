import { AppTooltip } from "components";
import { TWO_PRECISION_NUMERIC } from "globals/constants";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { Optional } from "models/general";
import React from "react";
import styles from "./OccupancyTotalValue.module.scss";

export interface OccupancyTotalValueProps {
    value: Optional<number>;
    isNegativeRed?: boolean;
    highlightText?: boolean;
    className?: string;
    tooltipTitle?: string;
    numberFormat?: string;
}

const Component: React.FC<OccupancyTotalValueProps> = ({
    value,
    isNegativeRed = true,
    highlightText = true,
    className = "",
    numberFormat = TWO_PRECISION_NUMERIC,
    tooltipTitle,
}) => {
    const { formatNumber } = useLocaleHelpers();
    let colorClass = "";
    if (highlightText) {
        if (value != null && value < 0) {
            colorClass = isNegativeRed ? styles.red : styles.green;
        } else if (value != null && value > 0) {
            colorClass = isNegativeRed ? styles.green : styles.red;
        }
    }

    const content = (
        <div className={`${className} ${colorClass}  ${styles.main}`}>
            {formatNumber(value, numberFormat)}
        </div>
    );
    return (
        <>
            {tooltipTitle ? (
                <AppTooltip
                    content={tooltipTitle}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    {content}
                </AppTooltip>
            ) : (
                content
            )}
        </>
    );
};
export const OccupancyTotalValue = React.memo(Component);
export default OccupancyTotalValue;
