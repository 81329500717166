import { FeatureHelpItem } from "models/applicationFeatures";
import React from "react";
import { Animated } from "react-animated-css";
import FeatureHelpPlayerOrDetail from "../FeatureHelpPlayerOrDetail";
import styles from "./FeatureHelpItemDetailsDialog.module.scss";

export interface FeatureHelpItemDetailsDialogProps {
    selectedItem: FeatureHelpItem;
}
export const FeatureHelpItemDetailsDialog: React.FC<
    FeatureHelpItemDetailsDialogProps
> = ({ selectedItem }) => {
    return (
        <>
            {/* @ts-ignore */}
            <Animated
                animationIn="slideInRight"
                animationOut="slideOutRight"
                animationInDuration={300}
                animationOutDuration={200}
                isVisible={selectedItem != null}
                animateOnMount={false}
                className={styles.player}
            >
                <FeatureHelpPlayerOrDetail value={selectedItem} />
            </Animated>
        </>
    );
};

export default FeatureHelpItemDetailsDialog;
