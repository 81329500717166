import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppInputField, TypedMaskedInput } from "components/FormFields";
import { ISO8601_DATE_FORMAT, TIME_FORMAT_24H } from "globals/constants";
import {
    getFormattedTimeFromNumber,
    toNumber,
} from "globals/helpers/localizationHelpers";
import { defaultTo } from "lodash-es";
import { ManualEntryRequest } from "models/timeManagement";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styles from "./TimeManagementPersonDetailManualEntry.module.scss";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";

export interface TimeManagementPersonDetailManualEntryProps {
    value: ManualEntryRequest;
    onDurationChangeHandler: (
        isIncrement?: boolean,
        manualMinutes?: number
    ) => void;
    onCommentChange: (val: string) => void;
    readonly?: boolean;
}

export const TimeManagementPersonDetailManualEntry: React.FC<
    TimeManagementPersonDetailManualEntryProps
> = ({ value, onDurationChangeHandler, readonly = false, onCommentChange }) => {
    const { t } = useTranslation();
    const isDelPressed = useRef(false);
    const [state, setState] = useState<{
        comment: string;
        manualTime: string;
    }>({
        comment: value.Comment,
        manualTime: `${getFormattedTimeFromNumber(
            value.Duration,
            "minute",
            TIME_FORMAT_24H
        )}`,
    });

    const canShowField = moment(value.Date, ISO8601_DATE_FORMAT) <= moment();
    return (
        <>
            <td>
                {canShowField && (
                    <div
                        className={styles.manualEntryDiv}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Button
                            disabled={readonly}
                            variant={""}
                            onClick={() => onDurationChangeHandler(false)}
                            className={`${styles.manualEntryBtn} ${styles.btnRed}`}
                        >
                            <FontAwesomeIcon icon="minus" />
                        </Button>
                        <TypedMaskedInput
                            mask={`${
                                state.manualTime.includes("-") ? "-" : ""
                            }99:99`}
                            value={defaultTo(state.manualTime, "00:00")}
                            onChange={(e) => {
                                setState({
                                    ...state,
                                    manualTime: defaultTo(e.target.value, ""),
                                });
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Delete" || e.key == "Backspace") {
                                    // to reset field of delete press
                                    e.stopPropagation();
                                    isDelPressed.current = true;
                                }
                                return e;
                            }}
                            beforeMaskedValueChange={(
                                newState,
                                oldState,
                                userInput
                            ) => {
                                let { value, selection } = newState;

                                if (isDelPressed.current) {
                                    value = "00:00";
                                    selection = { start: 0, end: 0 };
                                    isDelPressed.current = false;
                                }
                                // keep minus if entered by user
                                if (userInput == "-" && !value.includes("-")) {
                                    // add minus and move cursor to start
                                    value = `-${value}`;
                                    selection = { start: 1, end: 1 };
                                } else if (
                                    (userInput == "+" || userInput == "-") &&
                                    value.includes("-")
                                ) {
                                    // remove minus
                                    value = value.slice(1);
                                    // selection = { start: 0, end: 0 };
                                }

                                return {
                                    value,
                                    selection,
                                };
                            }}
                            maskChar={"0"}
                            alwaysShowMask={true}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                            onBlur={() => {
                                let hours = 0;
                                let minutes = 0;
                                const isNegative =
                                    state.manualTime.startsWith("-");
                                const val = isNegative
                                    ? state.manualTime.slice(1)
                                    : state.manualTime;
                                if (state.manualTime.includes(":")) {
                                    const splitted = val.split(":");
                                    hours = toNumber(splitted[0]);
                                    minutes = toNumber(splitted[1]);
                                } else {
                                    hours = toNumber(val.slice(0, 2));
                                    minutes = toNumber(val.slice(3));
                                }
                                if (hours > 99 || minutes > 59) {
                                    showSweetAlertToast(
                                        t("common.error.error"),
                                        t("timeManagement.invalidManualEntry"),
                                        "error"
                                    );
                                    setState({
                                        ...state,
                                        manualTime: `${
                                            value.Duration < 0 ? "-" : ""
                                        }${getFormattedTimeFromNumber(
                                            Math.abs(value.Duration),
                                            "minute",
                                            TIME_FORMAT_24H
                                        )}`,
                                    });
                                } else {
                                    if (state.manualTime == "-00:00") {
                                        setState({
                                            ...state,
                                            manualTime: "00:00",
                                        });
                                    }
                                    onDurationChangeHandler(
                                        undefined,
                                        (hours * 60 + minutes) *
                                            (isNegative ? -1 : 1)
                                    );
                                }
                            }}
                            readOnly={readonly}
                        >
                            {(inputProps: any) => (
                                <AppInputField
                                    {...inputProps}
                                    className={styles.manualEntryField}
                                    classes={{ field: styles.inputField }}
                                    useDefaultOnChange={true}
                                />
                            )}
                        </TypedMaskedInput>
                        <Button
                            disabled={readonly}
                            onClick={() => onDurationChangeHandler(true)}
                            className={`${styles.manualEntryBtn} ${styles.btnBlue}`}
                        >
                            <FontAwesomeIcon icon="plus" />
                        </Button>
                    </div>
                )}
            </td>
            <td onClick={(e) => e.stopPropagation()}>
                {canShowField && (
                    <AppInputField
                        className={styles.commentInput}
                        classes={{ field: styles.inputField }}
                        value={defaultTo(state.comment, "")}
                        readOnly={readonly}
                        onBlur={() => {
                            if (value.Comment != state.comment) {
                                onCommentChange(state.comment);
                            }
                        }}
                        onValueChange={(val?: string) =>
                            setState({ ...state, comment: defaultTo(val, "") })
                        }
                    />
                )}
            </td>
        </>
    );
};

export default TimeManagementPersonDetailManualEntry;
