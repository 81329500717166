import { defaultTo } from "lodash-es";
import { BusinessType, getBusinessTypePathString } from "models/business";
import BaseRoutingContext from "routing/routingContexts/BaseRoutingContext";

export class BusinessSettingRoutingContext extends BaseRoutingContext {
    protected businessType: BusinessType | ":businessType";
    protected businessId: string;

    constructor(
        businessType?: BusinessType | ":businessType" | undefined,
        businessId?: string | undefined
    ) {
        super();
        this.businessType = defaultTo(businessType, ":businessType");
        this.businessId = defaultTo(businessId, ":businessId");
    }

    getContextIdentifier(): string {
        return "business-settings-context";
    }
    getBusinessType(): string {
        const bType =
            this.businessType !== ":businessType"
                ? getBusinessTypePathString(this.businessType)
                : this.businessType;
        return !bType.includes(":") ? bType.toLowerCase() : bType;
    }
    getBaseUrl() {
        return `${super.getBaseUrl()}/${this.getBusinessType()}/${
            this.businessId
        }/settings`;
    }
    getApiBaseUrl() {
        return `${super.getApiBaseUrl()}/business/${this.businessId}`; // for common business APIs
    }
}

export default BusinessSettingRoutingContext;
