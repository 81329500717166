import classNames from "classnames";
import { AppOverlayLoader } from "components/Loaders";
import { isSafari } from "globals/helpers/webInfoHelper";
import { useUpdateSessionBusinessInfo } from "hooks/general/appContextHelpers";
import { useOpenDocumentPreview } from "hooks/general/appHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo, isNil } from "lodash-es";
import { BusinessLogo } from "models/business";
import { TabbedLayoutActiveItem } from "models/general";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useOutletContext } from "react-router";
import styles from "./IFrameContent.module.scss";

export interface IFrameContentProps {
    className?: string;
    contentUrl?: string;
    onRedirect?: (encodedId?: string) => void;
    onUpdate?: () => void;
}
interface IFrameMessageResponse {
    eventId: "redirect" | "logoChange" | "updated";
    jsonData?: string;
    documentKey?: string;
    name?: string;
    encodedId?: string;
}

export const IFrameContent: React.FC<IFrameContentProps> = ({
    className,
    contentUrl,
    onRedirect,
    onUpdate,
}) => {
    const openDocumentPreviewTab = useOpenDocumentPreview();
    const { updateSessionBusinessLogo } = useUpdateSessionBusinessInfo();
    const { ref, height } = useResizeDetector();
    const { t } = useTranslation();
    const [state, setState] = useState<{
        loading: boolean;
        changed: boolean;
        baseUrl?: string;
    }>({
        loading: true,
        baseUrl: defaultTo(contentUrl, ""),
        changed: false,
    });
    const outletContext = useOutletContext<TabbedLayoutActiveItem<any>>();
    const { appLocale } = useLocaleHelpers();

    const srcRef = useRef<string>(defaultTo(contentUrl, ""));
    const iframeRef = useRef<any>(null);

    const updateIframeSrc = (url?: string) => {
        srcRef.current = defaultTo(url, "");
    };
    useEffect(() => {
        const eventHandler = (e: any) => {
            if (e.origin !== location.origin && e.data) {
                if (typeof e.data === "object") {
                    const response: IFrameMessageResponse = e.data;
                    if (response) {
                        try {
                            switch (response.eventId) {
                                case "redirect":
                                    if (!isNil(response.documentKey)) {
                                        openDocumentPreviewTab(
                                            response.documentKey,
                                            response.name
                                        );
                                    } else if (
                                        !isNil(response.encodedId) &&
                                        onRedirect
                                    ) {
                                        onRedirect(response.encodedId);
                                    }
                                    break;
                                case "updated":
                                    if (onUpdate) {
                                        onUpdate();
                                    }
                                    break;
                                case "logoChange":
                                    if (response.jsonData) {
                                        // session-business logo changed
                                        updateSessionBusinessLogo(
                                            JSON.parse(
                                                response.jsonData
                                            ) as BusinessLogo
                                        );
                                    }
                                    break;
                            }
                        } catch (e) {
                            console.debug("exception in iframe message", e);
                        }
                    }
                } else if ((e.data as string).startsWith("http")) {
                    // to handle same url refresh on locale-change
                    const mainUrl = e.data.split("?")[0];
                    const isUrlDifferent =
                        mainUrl !== srcRef.current.split("?")[0];
                    const newUrl =
                        !isUrlDifferent && e.data.includes("Page")
                            ? e.data
                            : mainUrl;

                    if (
                        (isUrlDifferent && newUrl.includes(state.baseUrl)) ||
                        newUrl.includes("Page=")
                    ) {
                        // console.debug("updated", newUrl);
                        updateIframeSrc(newUrl);
                    }
                    if (isSafari()) {
                        // sometimes safari don't trigger iFrame's onload and loader keeps running
                        setTimeout(
                            () =>
                                setState((old) => ({
                                    ...old,
                                    loading: false,
                                })),
                            1500
                        );
                    }
                } else if (e.data === "unloading") {
                    setState((old) => ({
                        ...old,
                        loading: true,
                        changed: false,
                    }));
                } else if (e.data === "timeout") {
                    // session timeout page opened
                    // on get-application-feature request, there is code to refresh user session but for that need to refresh iframe
                    // console.log("timeout", state.baseUrl, contentUrl);
                    updateIframeSrc(srcRef.current);
                    setState((old) => ({
                        ...old,
                        changed: true,
                    }));
                } else if (e.data === "reloaded" && state.loading) {
                    setState((old) => ({
                        ...old,
                        loading: false,
                    }));
                }
            }
        };
        if (window.addEventListener) {
            window.addEventListener("message", eventHandler, false);
        }
        return () => {
            if (window.removeEventListener) {
                window.removeEventListener("message", eventHandler);
            }
        };
    }, []);
    useEffect(() => {
        let toUpdate: string | undefined = undefined;
        if (
            isNil(contentUrl) &&
            outletContext.tab &&
            outletContext.tab.iframeUrl
        ) {
            toUpdate = outletContext.tab?.iframeUrl;
        } else if (contentUrl && contentUrl != state.baseUrl) {
            toUpdate = contentUrl;
        }

        if (!isNil(toUpdate)) {
            updateIframeSrc(toUpdate);
            setState((old) => ({
                ...old,
                baseUrl: toUpdate,
                changed: true,
            }));
        }
    }, [contentUrl, outletContext && outletContext.tab]);

    const iframe = useMemo(() => {
        // to handle same url refresh on locale-change and avoid multi-load of same url on changes
        return defaultTo(srcRef.current, "").length > 0 ? (
            <iframe
                ref={iframeRef}
                onLoad={() => {
                    setState((old) => ({ ...old, loading: false }));
                }}
                src={`${srcRef.current}${
                    srcRef.current.includes("?") ? "&" : "?"
                }culture=${appLocale.toString()}`}
                style={{ height: `${height}px` }}
            >
                <p>{t("iframeNotSupported")}</p>
            </iframe>
        ) : (
            <></>
        );
    }, [srcRef, appLocale, state.changed]);

    useEffect(() => {
        if (state.changed) {
            setState((old) => ({ ...old, changed: false }));
        }
    }, [state.changed]);

    return (
        <div className={classNames(className, styles.root)} ref={ref}>
            {state.loading && <AppOverlayLoader opaque={true} />}
            {state.changed ? <></> : iframe}
        </div>
    );
};

export default IFrameContent;
