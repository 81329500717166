import { AppPopover } from "components";
import { ImageAssets } from "globals/images";
import { BusinessType, PermissionAccessTypes } from "models";
import { BusinessPerson } from "models/general";
import React from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./PermissionManagementUserDetailPopover.module.scss";
interface PermissionManagementUserDetailPopoverProps {
    userCount: number;
    className?: string;
    parentNode?: string;
    persons?: { [key in PermissionAccessTypes]: BusinessPerson[] };
    businessType?: BusinessType;
}

export const PermissionManagementUserDetailPopover =
    React.memo<PermissionManagementUserDetailPopoverProps>(
        ({ persons, className, userCount }) => {
            const { t } = useTranslation();

            return (
                <>
                    <AppPopover
                        triggerOnClick={false}
                        delay={200}
                        trigger={"mouseenter focus"}
                        containerClass={`${className} mb-auto`}
                        placement={"auto-end"}
                        triggerElem={
                            <span>{`${userCount} ${
                                userCount == 1
                                    ? t("roleManagement.role.user")
                                    : t("roleManagement.role.users")
                            }`}</span>
                        }
                    >
                        <Container className={styles.root}>
                            <Row>
                                <Col xs={12}>
                                    <div className={styles.title}>
                                        <div
                                            className={`d-flex ${styles.header}`}
                                        >
                                            <div className={styles.iconHelp}>
                                                <Image
                                                    src={
                                                        ImageAssets.common
                                                            .helpCircleBlue
                                                    }
                                                />
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <span
                                                    className={
                                                        styles.mainHeading
                                                    }
                                                >
                                                    Filed Access
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div
                                        className={`d-flex flex-column ${styles.body}`}
                                    >
                                        <Table striped className="no-gutters">
                                            <tbody>
                                                {persons && (
                                                    <>
                                                        <tr>
                                                            <td
                                                                className={
                                                                    styles.titleTd
                                                                }
                                                            >
                                                                {t(
                                                                    "roleManagement.permissions.show"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {persons.VIEW.map(
                                                                    (
                                                                        x,
                                                                        index
                                                                    ) =>
                                                                        `${
                                                                            x.Name
                                                                        } ${
                                                                            persons
                                                                                .VIEW
                                                                                .length -
                                                                                1 !=
                                                                            index
                                                                                ? ","
                                                                                : ""
                                                                        } `
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                className={
                                                                    styles.titleTd
                                                                }
                                                            >
                                                                {t(
                                                                    "roleManagement.permissions.edit"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {persons.EDIT.map(
                                                                    (
                                                                        x,
                                                                        index
                                                                    ) =>
                                                                        `${
                                                                            x.Name
                                                                        } ${
                                                                            persons
                                                                                .EDIT
                                                                                .length -
                                                                                1 !=
                                                                            index
                                                                                ? ","
                                                                                : ""
                                                                        } `
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                className={
                                                                    styles.titleTd
                                                                }
                                                            >
                                                                {t(
                                                                    "roleManagement.permissions.create"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {persons.CREATE.map(
                                                                    (
                                                                        x,
                                                                        index
                                                                    ) =>
                                                                        `${
                                                                            x.Name
                                                                        } ${
                                                                            persons
                                                                                .CREATE
                                                                                .length -
                                                                                1 !=
                                                                            index
                                                                                ? ","
                                                                                : ""
                                                                        } `
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                className={
                                                                    styles.titleTd
                                                                }
                                                            >
                                                                {t(
                                                                    "roleManagement.permissions.delete"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {persons.DELETE.map(
                                                                    (
                                                                        x,
                                                                        index
                                                                    ) =>
                                                                        `${
                                                                            x.Name
                                                                        } ${
                                                                            persons
                                                                                .DELETE
                                                                                .length -
                                                                                1 !=
                                                                            index
                                                                                ? ","
                                                                                : ""
                                                                        } `
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </AppPopover>
                </>
            );
        }
    );

export default PermissionManagementUserDetailPopover;
