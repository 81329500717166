import { AppSelect } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { ImageAssets } from "globals/images";
import { defaultTo } from "lodash-es";
import { ShiftPlanRequest } from "models/shiftPlan";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ShiftPlanFilterHeader.module.scss";

export interface ShiftPlanFilterHeaderProps {
    value: ShiftPlanRequest;
    areas: SimpleOption[];
    onChange: (req: ShiftPlanRequest) => void;
    disabled: boolean;
}

export const ShiftPlanFilterHeader: React.FC<ShiftPlanFilterHeaderProps> = ({
    value,
    onChange,
    areas,
    disabled,
}) => {
    const { t } = useTranslation();

    const changeWeek = (isNext: boolean = false) => {
        if (!disabled) {
            const week = value.WeekStart.clone().add(isNext ? 1 : -1, "week");
            onChange({
                ...value,
                WeekStart: week.clone().startOf("week"),
                WeekEnd: week.clone().endOf("week"),
            });
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.weekNavigator}>
                <Image
                    className={styles.prev}
                    src={ImageAssets.common.angleLeftGray}
                    onClick={() => {
                        changeWeek();
                    }}
                />

                <span className={styles.weekLabel}>
                    <Image src={ImageAssets.common.calendarAltGrey} />
                    {t("shiftPlan.weekNum", {
                        weekNum: value.WeekStart.format("ww"),
                    })}
                </span>
                <Image
                    className={styles.next}
                    onClick={() => {
                        changeWeek(true);
                    }}
                    src={ImageAssets.common.angleRightGray}
                />
            </div>

            <span className={styles.areaLabel}>
                {t("shiftPlan.workingArea")}
            </span>
            <AppSelect
                className={styles.areasSelect}
                isDisabled={disabled}
                options={areas}
                value={
                    areas.length > 0 && value.AreaId > 0
                        ? areas.find((x: any) => x.value == value.AreaId)
                        : null
                }
                placeholder={t("common.pleaseSelect")}
                onChange={(opt: SimpleOption) => {
                    onChange({
                        ...value,
                        AreaId: defaultTo(opt.value, 0),
                    });
                }}
            />
        </div>
    );
};

export default ShiftPlanFilterHeader;
