import { BusinessType, LwClientHQ } from "models";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILillywaitService {
    getLwClientsList: (
        loadContracts: boolean,
        request?: BusinessType
    ) => Promise<AppResponse<LwClientHQ[]>>;
}

export function getLillywaitServiceKey(
    name: keyof ILillywaitService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `lw_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext().lillywait;
type ApiLinkProviderType = typeof apiLinkProvider;

// for LW CLients and other common LW APIs
export class LillywaitService extends BaseService implements ILillywaitService {
    public routesBase: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routesBase = apiLinkProvider;
    }
    getLwClientsList(
        loadContracts: boolean = true,
        type?: BusinessType
    ): Promise<AppResponse<LwClientHQ[]>> {
        return this.doServerXHR<LwClientHQ[]>({
            url: this.routesBase.clients().api.getLwClientsList({
                type: type,
                loadContracts: loadContracts,
            }),
            method: "get",
        });
    }
}
