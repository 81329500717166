import {
    ModulePlanCard,
    ModulePlanCardSkelton,
} from "commonPartials/modulePlanCard";
import AppContentHeader from "components/AppContentHeader";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import {
    useSessionBusiness,
    useSessionBusinessContractInfo,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { t } from "i18next";
import { defaultTo, uniq } from "lodash-es";
import { Business_ModuleSelect, PermissionAccessTypes } from "models";
import { BusinessContract } from "models/business";
import { LocationModulePlan, ModulePlans } from "models/modulePlans";
import React, { useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import {
    BusinessGeneralService,
    BusinessService,
    getBusinessGeneralServiceKey,
} from "services/business";
import styles from "./BusinessPlanSelect.module.scss";

//Only for LWC
export const BusinessPlanSelect: React.FC = () => {
    const { linkProvider } = useRouting();
    const { currentContract, setBusinessContractInfo } =
        useSessionBusinessContractInfo();
    const { sessionBusinessLocation, id: sessionBusinessId } =
        useSessionBusiness();

    const { checkPermission, userAccessInfo } = useCheckPermission();
    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    const currentContractFields = useMemo(() => {
        return defaultTo(currentContract?.ContractModuleFields, []);
    }, [currentContract]);
    const businessService = new BusinessService(linkProvider.business.api);

    const { isLoading: modulePlanLoading, data: modulePlanCards } = useQuery(
        getBusinessGeneralServiceKey("getLWCModulePlanCards", {
            locationId: sessionBusinessLocation?.Id,
            sessionBusinessId: sessionBusinessId, // on business change refetch
        }),
        async () => {
            if (sessionBusinessLocation) {
                return await businessGeneralService.getLWCModulePlanCards(
                    sessionBusinessLocation.Id
                );
            }
        },
        {
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );

    const {
        isLoading,
        mutate: selectModulePlan,
        data: modulePlanUpdateResponse,
    } = useMutation(
        async ({
            fieldUuid,
            active,
        }: {
            fieldUuid: string;
            active?: boolean;
        }) => {
            if (currentContract?.Id) {
                return await businessService.updateModulePlan(
                    currentContract?.Id.toString(),
                    fieldUuid,
                    defaultTo(
                        modulePlanCards?.Data.find((x) => x.Module?.IsBasic)
                            ?.Uuid,
                        ""
                    ),
                    active
                );
            }
        }
    );

    useEffect(() => {
        if (!isLoading && modulePlanUpdateResponse) {
            if (modulePlanUpdateResponse.Data) {
                let businessContract = currentContract;

                let selectedPlans = defaultTo(modulePlanCards?.Data, []);

                if (
                    !currentContractFields.includes(
                        modulePlanUpdateResponse.Data
                    )
                ) {
                    // new plan selected
                    selectedPlans = selectedPlans.filter(
                        (x) => modulePlanUpdateResponse.Data == x.Uuid // new selected package (only 1 active at a time)
                    );
                } else {
                    // de-selected
                    // previously more than 1 selected
                    selectedPlans = selectedPlans.filter(
                        (x) =>
                            (currentContractFields.length > 1 &&
                                x.Uuid != modulePlanUpdateResponse.Data) ||
                            (currentContractFields.length == 1 &&
                                currentContractFields.includes(x.Uuid))
                    );
                }

                businessContract = {
                    ...(currentContract as BusinessContract),
                    ContractModules: uniq(
                        selectedPlans.reduce((resp: ModulePlans[], card) => {
                            return [
                                ...resp,
                                ...(card && card.Module
                                    ? defaultTo(card.Module.Modules, [])
                                    : []),
                            ];
                        }, [])
                    ),
                    ContractModuleFields: selectedPlans.map((x) => x.Uuid),
                };

                setBusinessContractInfo(businessContract);
            } else if (modulePlanUpdateResponse.Errors) {
                showUnexpectedErrorToast();
            }
        }
    }, [modulePlanUpdateResponse, isLoading]);
    const isDisabled =
        isLoading ||
        userAccessInfo().isSupportViewEnabled ||
        !checkPermission([Business_ModuleSelect], [PermissionAccessTypes.EDIT]);
    return (
        <div>
            <AppContentHeader
                title={t("business.packages")}
                classNameForIcon={"pe-7s-box2"}
            />
            <div className={styles.cardsDiv}>
                {modulePlanLoading ? (
                    <ModulePlanCardSkelton count={2} showPriceRow={true} />
                ) : (
                    modulePlanCards?.Data.map((x, idx) => (
                        <ModulePlanCard
                            key={idx}
                            isLwc={true}
                            value={defaultTo(
                                x.Module,
                                {} as LocationModulePlan
                            )}
                            isSelected={currentContractFields.includes(x.Uuid)}
                            disabled={isDisabled}
                            price={x.FieldValue?.Value}
                            onButtonClick={
                                isDisabled
                                    ? undefined
                                    : () =>
                                          selectModulePlan({
                                              fieldUuid: x.Uuid,
                                              active: !currentContractFields.includes(
                                                  x.Uuid
                                              ),
                                          })
                            }
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default BusinessPlanSelect;
