import { ImageAssets } from "globals/images";
import React from "react";
import { Form, Image } from "react-bootstrap";
import styles from "./AppImageUploaderWithPreview.module.scss";
export interface AppImageUploaderWithPreviewProps {
    file?: File;
    avatarUrl?: string;
    onUpload: (file: File) => void;
    className?: string;
}
export const AppImageUploaderWithPreview: React.FC<
    AppImageUploaderWithPreviewProps
> = ({ file, onUpload, className, avatarUrl }) => {
    const fileRef = React.useRef<any>();
    const onFileClickHandler = () => {
        fileRef.current.click();
    };
    const onChangeHandler = (e: any) => {
        if (e.target.name === "file") {
            onUpload(e.target.files[0]);
        }
    };

    return (
        <div>
            <Form.File
                ref={fileRef}
                id="avatarInput"
                label="avatarInput"
                style={{ display: "none" }}
                name="file"
                multiple={false}
                onChange={onChangeHandler}
            />
            {file != null || avatarUrl != null ? (
                <Image
                    src={
                        file != null
                            ? URL.createObjectURL(file)
                            : avatarUrl != null
                            ? avatarUrl
                            : ImageAssets.common.cameraWithBg
                    }
                    roundedCircle
                    className={`${className} ${styles.image}`}
                    onClick={onFileClickHandler}
                />
            ) : (
                <div
                    className={`${className} ${styles.updateImage}`}
                    onClick={onFileClickHandler}
                >
                    <Image src={ImageAssets.common.cameraWithBg} />
                </div>
            )}
        </div>
    );
};

export default AppImageUploaderWithPreview;
