import cx from "classnames";
import FeatureHelpSection from "commonPartials/explanationMode/FeatureHelpSection";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { AppLayoutBase } from "layouts/appLayout";
import { defaultTo } from "lodash-es";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./AppHeader.module.scss";
import { HeaderLogo } from "./partials";
import { NotificationHeaderIconsContainer } from "./partials/notificationIcon";
import UserHeaderSection from "./partials/UserHeaderSection";

export const APP_HEADER_HEIGHT = 60;
export interface AppHeaderProps extends AppLayoutBase {
    title?: string;
    classNameForIcon?: string;
}
export const AppHeader = React.memo<AppHeaderProps>((props) => {
    const { sessionBusiness, isContractExpired } = useSessionBusiness();
    const { isAuthenticated } = useSessionUser();
    return (
        <>
            {/* @ts-ignore */}
            <TransitionGroup>
                {/* @ts-ignore */}
                <CSSTransition
                    classNames="HeaderAnimation"
                    appear={false}
                    enter={false}
                    exit={false}
                    timeout={{ appear: 1000 }}
                >
                    <div className="app-header header-shadow">
                        <HeaderLogo {...props} noSidebar={isContractExpired} />
                        <div
                            className={cx("app-header__content", {
                                "header-mobile-open":
                                    props.enableMobileMenuRight,
                            })}
                        >
                            <div className="app-header-left">
                                {props.enableMobileMenuRight && (
                                    <span className={styles.mobileBusinessName}>
                                        {defaultTo(sessionBusiness?.Name, "")}
                                    </span>
                                )}
                            </div>
                            <div className="app-header-right">
                                {isAuthenticated &&
                                    sessionBusiness !== null &&
                                    !isContractExpired && (
                                        <>
                                            <FeatureHelpSection />
                                            <NotificationHeaderIconsContainer />
                                            <div
                                                className={styles.verticalBar}
                                            />
                                        </>
                                    )}
                                <UserHeaderSection />
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
});

export default AppHeader;
