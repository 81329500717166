import { DatePickerType } from "models/datepicker";
import React from "react";
import {
    AppReactDatePickerBase,
    AppReactDatePickerBaseProps,
} from "./partials";

export const AppMonthYearPicker: React.FC<
    Omit<
        AppReactDatePickerBaseProps & { shortFormat?: boolean },
        "datePickerType"
    >
> = ({ shortFormat = false, shouldCloseOnSelect = true, ...rest }) => {
    return (
        <AppReactDatePickerBase
            {...rest}
            datePickerType={
                shortFormat
                    ? DatePickerType.MONTH_YEAR_SHORT
                    : DatePickerType.MONTH_YEAR
            }
            showMonthYearPicker
            showFourColumnMonthYearPicker
            shouldCloseOnSelect={shouldCloseOnSelect}
        />
    );
};

export default AppMonthYearPicker;
