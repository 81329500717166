import classNames from "classnames";
import { AppCollapseToggleButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import $ from "jquery";
import { defaultTo } from "lodash-es";
import { Optional } from "models/general";
import { LwClientHQ } from "models/lwClient";
import React, { useCallback, useRef, useState } from "react";
import styles from "./LwClientRow.module.scss";
import LwClientRowContent from "./LwClientRowContent";

export interface LwClientRowProps {
    value: LwClientHQ;
    onClick?: (businessId: string) => void;
    isSupportTable?: boolean;
    onDelete: (hqId: number, businessId?: number) => void;
    canDelete?: boolean;
    colCount: number;
}

export const LwClientRow: React.FC<LwClientRowProps> = ({
    value,
    onClick,
    onDelete,
    isSupportTable = false,
    canDelete = false,
    colCount,
}) => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const collapseRowRef = useRef<Optional<any>>(undefined);
    const initialStyle = useRef<any>(
        !collapse ? { display: "none" } : undefined
    );

    const branches = defaultTo(value.Branches, []);
    const toggleRow = useCallback(() => {
        if (collapseRowRef && collapseRowRef.current) {
            if (collapse) {
                setCollapse(false);
                $(collapseRowRef.current).slideUp(200);
            } else {
                setCollapse(true);
                $(collapseRowRef.current).slideDown(300);
            }
        }
    }, [collapseRowRef.current, collapse]);

    const hasBranches = branches.length > 0;
    return (
        <>
            <tr>
                <td
                    style={{ cursor: "pointer !important" }}
                    onClick={() => (hasBranches ? toggleRow() : {})}
                >
                    <div className={styles.toggleIcon}>
                        {hasBranches && (
                            <AppCollapseToggleButton visible={collapse} />
                        )}
                    </div>
                </td>
                <LwClientRowContent
                    value={value.HqInfo}
                    canDelete={canDelete}
                    onDelete={onDelete}
                    onClick={
                        onClick
                            ? () => onClick(value.HqInfo.EncodedId)
                            : undefined
                    }
                    isSupportTable={isSupportTable}
                />
            </tr>
            {hasBranches && (
                <tr
                    className="row-with-innerTable"
                    style={collapse ? { border: "none !important" } : undefined}
                >
                    <td colSpan={colCount}>
                        <div
                            className="child-table-container"
                            style={{
                                ...defaultTo(initialStyle.current, {}),
                                ...{
                                    margin: "0px -18px",
                                },
                            }}
                            ref={collapseRowRef}
                        >
                            <table
                                className={classNames(
                                    "table-striped",
                                    styles.childTable
                                )}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    {branches.map((b, idx) => (
                                        <tr
                                            key={b.Id}
                                            style={
                                                idx === branches.length - 1
                                                    ? {
                                                          borderBottom:
                                                              "none !important",
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <td style={getFixedCssWidths(50)} />
                                            <LwClientRowContent
                                                value={b}
                                                onDelete={onDelete}
                                                canDelete={canDelete}
                                                subRow={true}
                                                onClick={
                                                    onClick
                                                        ? () =>
                                                              onClick(
                                                                  b.EncodedId
                                                              )
                                                        : undefined
                                                }
                                                isSupportTable={isSupportTable}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default LwClientRow;
