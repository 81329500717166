import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment";
import {
    EmployeeContract,
    EmployeeContractResponse,
    getEmployeeContractEmptyObject,
    getParsedEmployeeContract,
} from "../employeeContract";
import { EmployeeBase, EmployeeListItem, EmployeeListItemBase } from "./model";

export interface BusinessEmployeeResponse extends EmployeeBase {
    DateOfBirth?: string;
    CreatedAt: number;
}
export interface EmployeeListItemResponse extends EmployeeListItemBase {
    CreatedAt: number;
    DateOfBirth?: string;
    CurrentContract: EmployeeContractResponse;
}

export const getParsedEmployees = (
    val: EmployeeListItemResponse
): EmployeeListItem => {
    const contract = val.CurrentContract
        ? getParsedEmployeeContract(val.CurrentContract)
        : {
              ...getEmployeeContractEmptyObject(
                  val.BusinessId,
                  moment(),
                  val.Id
              ),
              ContractStart: null,
          };
    return {
        ...contract,
        ...val,
        WorkArea: contract.Area?.Name,
        EmployeeFunction: contract.Function?.Name,
        IsChargeable: contract.Chargeable,
        AreaId: contract.Area?.Id,
        WeeklyHours: contract.RealWeeklyHours,
        EmployeeFunctionId: contract.Function?.Id,
        CreatedAt: val.CreatedAt ? moment.unix(val.CreatedAt) : null,
        DateOfBirth: val.DateOfBirth
            ? moment(val.DateOfBirth, ISO8601_DATE_FORMAT)
            : null,
    } as EmployeeListItem;
};
