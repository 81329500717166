export enum EmployeeShiftType {
    Exclusion = "Exclusion", //default
    Preference = "Preference",
}
export enum EmployeeShiftSortColumn {
    Shift = 1,
    StartDate = 2,
    EndDate = 3,
    Excluded = 4,
}
