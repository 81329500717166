import { t } from "i18next";
import { isNil, uniq } from "lodash-es";
import { BusinessType } from "models/business";
import { AppPlatforms, BusinessPerson, Optional } from "models/general";
import {
    AccessPermissionRequest,
    RolePermissionsResponse,
} from "models/roleManagement";
import { PermissionAccessTypes } from "./enum";
import {
    AccessPermissionMapping,
    MandatoryDetail,
    PermissionItemDTOWithUserAssignmentsInfo,
    PermissionNeedsToDisable,
} from "./model";
import {
    mandatoryPermissionsForAllUsers,
    ModuleMandatoryAccess,
    ModulesWithoutAccess,
} from "./permissionConstants";

export function satisfySearchQuery(
    permissionMap: AccessPermissionMapping,
    query: string
): Optional<AccessPermissionMapping> {
    query = query.toLowerCase();
    if (permissionMap.DisplayName.toLowerCase().includes(query)) {
        // if matched
        return permissionMap;
    } else if (isNil(permissionMap.Childs)) {
        // base-case (not matched)
        return null;
    } else {
        // recursive step
        const childs = permissionMap.Childs.reduce((val, currChild) => {
            if (satisfySearchQuery(currChild, query) != null) {
                return [...val, currChild];
            }
            return val;
        }, [] as AccessPermissionMapping[]);
        if (childs.length > 0) {
            return {
                ...permissionMap,
                Childs: childs,
            };
        } else {
            return null;
        }
    }
}
const getAccessPermissionMappingModel = (
    parent: string,
    self: string,
    complete: string,
    key?: string,
    haveChild?: boolean,
    isFirstChild?: boolean
): AccessPermissionMapping => {
    return {
        IsCreatePossible:
            !ModulesWithoutAccess[PermissionAccessTypes.CREATE].includes(
                complete
            ),
        IsEditPossible:
            !ModulesWithoutAccess[PermissionAccessTypes.EDIT].includes(
                complete
            ),
        IsDeletePossible:
            !ModulesWithoutAccess[PermissionAccessTypes.DELETE].includes(
                complete
            ),
        IsViewPossible:
            !ModulesWithoutAccess[PermissionAccessTypes.SHOW].includes(
                complete
            ),
        Childs: null,
        CompleteIdentifier: complete,
        SelfIdentifier: self,
        parentHierarchy: parent,
        DisplayName:
            haveChild && isFirstChild
                ? t(`roleManagement.constants.${complete}.${self}`)
                : t(`roleManagement.constants.${complete}`),
    };
};
//create json list into the access permission mapping list
export const getParsedAccessPermissionsList = (
    businessPermissionsJson: string[],
    area: BusinessType
) => {
    const permissions: AccessPermissionMapping[] = [];
    //List of parents module in web
    const allParents = [
        ...uniq(
            businessPermissionsJson.map((x) => {
                x = x.split(".")[0];
                return x;
            })
        ),
    ];

    allParents.forEach((parentKey: string) => {
        const parentModule: AccessPermissionMapping = {
            IsCreatePossible: true,
            IsDeletePossible: true,
            IsEditPossible: true,
            IsViewPossible: true,
            CompleteIdentifier: parentKey,
            SelfIdentifier: parentKey,
            parentHierarchy: null,
            Childs: [],
            DisplayName: t(
                `roleManagement.constants.${parentKey}.${
                    parentKey == "Business_General"
                        ? "General_" + area
                        : parentKey == "Business"
                        ? area
                        : parentKey
                }`
            ),
        };

        // All permission of this specific parent

        let secondHierarchyChildren = businessPermissionsJson.filter((x) =>
            x.startsWith(parentKey + ".")
        );
        secondHierarchyChildren = [
            ...uniq(
                secondHierarchyChildren.map((x) => {
                    x = x.split(".")[1];
                    return x;
                })
            ),
        ];

        secondHierarchyChildren.forEach((secondChildKey) => {
            // checking if any third herarichy exist

            const children = businessPermissionsJson.filter((x) =>
                x.startsWith(`${parentKey}.${secondChildKey}.`)
            );
            let subModule: AccessPermissionMapping | null = null;
            subModule = getAccessPermissionMappingModel(
                parentKey,
                secondChildKey,
                `${parentKey}.${secondChildKey}`,
                secondChildKey,
                children.length > 0,
                true
            );
            if (children.length > 0) {
                const lastChilds: AccessPermissionMapping[] = [];

                children.forEach((child) => {
                    const splitPermissionOfChild = child.split(".");
                    lastChilds.push(
                        getAccessPermissionMappingModel(
                            splitPermissionOfChild[0] +
                                "." +
                                splitPermissionOfChild[1],
                            splitPermissionOfChild[2],
                            child,
                            child
                        )
                    );
                });
                subModule.Childs = lastChilds;
            }
            if (parentModule.Childs) {
                parentModule.Childs.push(subModule);
            }
        });
        permissions.push(parentModule);
    });
    return permissions;
};

//rendering the check and uncheck of the check boxes
export const checkKeyExistInSelectedList = (
    selectList: AccessPermissionRequest[],
    type: PermissionAccessTypes,
    key: string,
    children: AccessPermissionMapping[] | null = null
): boolean => {
    //if parent node the append . and get all the selected values
    //if child code then key must be exact match
    const isExist = selectList.filter((x) => {
        if (x.AccessPermissionKey) {
            if (children && children.length > 0) {
                return x.AccessPermissionKey.startsWith(key + ".");
            } else {
                return x.AccessPermissionKey == key;
            }
        }
        return false;
    });

    //if have child then start with 0
    //in case of last child the child list will be null but selected list have 1 item if checkbox is check
    //thats why in case of last child the count with start from 1
    let totalCount = children ? 0 : 1;

    const allChecks: boolean[] = [];
    if (children) {
        children.forEach((child) => {
            if (child.Childs) {
                switch (type) {
                    case PermissionAccessTypes.CREATE: {
                        totalCount =
                            totalCount +
                            child.Childs.filter(
                                (x) => x.IsCreatePossible == true
                            ).length;
                        break;
                    }
                    case PermissionAccessTypes.DELETE: {
                        totalCount =
                            totalCount +
                            child.Childs.filter(
                                (x) => x.IsDeletePossible == true
                            ).length;
                        break;
                    }
                    case PermissionAccessTypes.EDIT: {
                        totalCount =
                            totalCount +
                            child.Childs.filter((x) => x.IsEditPossible == true)
                                .length;
                        break;
                    }
                    case PermissionAccessTypes.SHOW: {
                        totalCount =
                            totalCount +
                            child.Childs.filter((x) => x.IsViewPossible == true)
                                .length;
                        break;
                    }
                }
            } else {
                switch (type) {
                    case PermissionAccessTypes.CREATE: {
                        if (child.IsCreatePossible) {
                            totalCount = totalCount + 1;
                        }
                        break;
                    }
                    case PermissionAccessTypes.EDIT: {
                        if (child.IsEditPossible) {
                            totalCount = totalCount + 1;
                        }
                        break;
                    }
                    case PermissionAccessTypes.DELETE: {
                        if (child.IsDeletePossible) {
                            totalCount = totalCount + 1;
                        }
                        break;
                    }
                    case PermissionAccessTypes.SHOW: {
                        if (child.IsViewPossible) {
                            totalCount = totalCount + 1;
                        }
                        break;
                    }
                }
            }
        });
    }
    isExist.forEach((x) => {
        switch (type) {
            case PermissionAccessTypes.CREATE:
                allChecks.push(x.CanCreate);
                break;
            case PermissionAccessTypes.EDIT:
                allChecks.push(x.CanEdit);
                break;
            case PermissionAccessTypes.DELETE:
                allChecks.push(x.CanDelete);
                break;
            case PermissionAccessTypes.SHOW:
                allChecks.push(x.CanView);
                break;
        }
    });

    return allChecks.filter((x) => x == true).length == totalCount;
};
//get an empty access permission request
export const getInitialAccessPermissionRequest =
    (): AccessPermissionRequest => {
        return {
            AccessPermissionKey: null,
            CanDelete: false,
            CanEdit: false,
            AccessPermissionId: undefined,
            CanCreate: false,
            CanView: false,
            ParentKey: null,
            UserCount: 0,
            AccessUsers: [],
            UserPermissionsMapping: {
                CREATE: [],
                DELETE: [],
                EDIT: [],
                VIEW: [],
            },
        };
    };
//create new access permission request when ever user click on the checkbox
export const getViewAccessPermissionRequest = (
    accessPermission: AccessPermissionMapping,
    type: PermissionAccessTypes,
    checked: boolean,
    isExist: AccessPermissionRequest | null = null
): AccessPermissionRequest => {
    let response: AccessPermissionRequest = {
        AccessPermissionKey: accessPermission.CompleteIdentifier,
        CanDelete: false,
        CanEdit: false,
        AccessPermissionId: undefined,
        CanCreate: false,
        CanView: false,
        ParentKey: accessPermission.parentHierarchy,
        UserCount: 0,
        AccessUsers: [],
        UserPermissionsMapping: { CREATE: [], DELETE: [], EDIT: [], VIEW: [] },
    };
    if (isExist) {
        response = isExist;
    }
    switch (type) {
        case PermissionAccessTypes.CREATE: {
            response.CanCreate = accessPermission.IsCreatePossible
                ? checked
                : false;
            response.CanView =
                accessPermission.IsCreatePossible &&
                accessPermission.IsViewPossible &&
                checked == true
                    ? checked
                    : response.CanView;
            break;
        }
        case PermissionAccessTypes.EDIT: {
            response.CanEdit = accessPermission.IsEditPossible
                ? checked
                : false;
            response.CanView =
                accessPermission.IsEditPossible &&
                accessPermission.IsViewPossible &&
                checked == true
                    ? checked
                    : response.CanView;
            break;
        }
        case PermissionAccessTypes.DELETE: {
            response.CanDelete = accessPermission.IsDeletePossible
                ? checked
                : false;
            response.CanView =
                accessPermission.IsDeletePossible &&
                accessPermission.IsViewPossible &&
                checked == true
                    ? checked
                    : response.CanView;

            break;
        }
        case PermissionAccessTypes.SHOW: {
            response.CanView = accessPermission.IsViewPossible
                ? checked
                : false;
            break;
        }
    }

    return response;
};
const findKeyInDisableList = (
    disabledList: PermissionNeedsToDisable,
    key: string,
    type: PermissionAccessTypes
) => {
    return disabledList[type].find((x) => x == key);
};
const handleDisableAndEnableOfCheckBox = (
    disabledList: PermissionNeedsToDisable,
    accessPermission: AccessPermissionMapping,
    checked: boolean,
    type: PermissionAccessTypes,
    isExist: Optional<AccessPermissionRequest>
) => {
    const isAlreadyDisabled = findKeyInDisableList(
        disabledList,
        accessPermission.CompleteIdentifier,
        PermissionAccessTypes.SHOW
    );
    if (checked && type != PermissionAccessTypes.SHOW) {
        if (!isAlreadyDisabled) {
            switch (type) {
                case PermissionAccessTypes.CREATE: {
                    if (accessPermission.IsCreatePossible) {
                        disabledList[PermissionAccessTypes.SHOW].push(
                            accessPermission.CompleteIdentifier
                        );
                    }
                    break;
                }
                case PermissionAccessTypes.EDIT: {
                    if (accessPermission.IsEditPossible) {
                        disabledList[PermissionAccessTypes.SHOW].push(
                            accessPermission.CompleteIdentifier
                        );
                    }
                    break;
                }
                case PermissionAccessTypes.DELETE: {
                    if (accessPermission.IsDeletePossible) {
                        disabledList[PermissionAccessTypes.SHOW].push(
                            accessPermission.CompleteIdentifier
                        );
                    }
                    break;
                }
            }
        }
    } else if (
        !checked &&
        type != PermissionAccessTypes.SHOW &&
        isAlreadyDisabled &&
        !mandatoryPermissionsForAllUsers.includes(
            accessPermission.CompleteIdentifier
        )
    ) {
        const updatedValue = getViewAccessPermissionRequest(
            accessPermission,
            type,
            checked,
            isExist
        );
        //do not remove the view key from disable list until can create, edit ,delete are false and
        //key must not be equal to GeneralUserProfileUpdate cos this permission is compulsory for all users

        if (
            !updatedValue.CanCreate &&
            !updatedValue.CanDelete &&
            !updatedValue.CanEdit
        ) {
            disabledList[PermissionAccessTypes.SHOW] =
                disabledList[PermissionAccessTypes.SHOW].length == 1
                    ? []
                    : (disabledList[PermissionAccessTypes.SHOW].map((x) => {
                          if (x != isAlreadyDisabled) {
                              return x;
                          }
                      }) as string[]);
        }
    }
};
export const findKeyInSelectedList = (
    selectedList: AccessPermissionRequest[],
    key: string
): Optional<AccessPermissionRequest> => {
    return selectedList.find((x) => x.AccessPermissionKey == key);
};
export const PermissionsStateChangeHelper = (
    selectedList: AccessPermissionRequest[],
    accessPermission: AccessPermissionMapping,
    permissionNeedToAdd: AccessPermissionRequest[],
    permissionsNeedToUpdate: AccessPermissionRequest[],
    type: PermissionAccessTypes,
    checked: boolean,
    disabledList: PermissionNeedsToDisable,
    mandatoryList: MandatoryDetail[]
) => {
    //checking if select checkbox key is already exist in selected list or not
    const isExist = findKeyInSelectedList(
        selectedList,
        accessPermission.CompleteIdentifier
    );

    //key dose not exist create a new permission and add it in need to add list
    if (!isExist) {
        permissionNeedToAdd.push(
            getViewAccessPermissionRequest(accessPermission, type, checked)
        );
    }
    //key dose exist create new item with pervious key and new values and add it to need to update list
    else {
        if (
            findKeyInDisableList(
                disabledList,
                accessPermission.CompleteIdentifier,
                type
            ) == null
        ) {
            permissionsNeedToUpdate.push(
                getViewAccessPermissionRequest(
                    accessPermission,
                    type,
                    checked,
                    isExist
                )
            );
        }
    }

    if (
        mandatoryList.filter(
            (x) => x.Identifier == accessPermission.CompleteIdentifier
        ).length == 0
    ) {
        //handling disable and enable of the show permission
        handleDisableAndEnableOfCheckBox(
            disabledList,
            accessPermission,
            checked,
            type,
            isExist
        );
    }
};
export const getDisableCheckBoxValue = (
    disableKeysList: PermissionNeedsToDisable,
    type: PermissionAccessTypes,
    completeIdentifier: string
): boolean => {
    const value =
        disableKeysList &&
        disableKeysList[type].find((x) => x == completeIdentifier) != null;

    return value;
};
//check simple id in case of ipad else userId
const deviceIdCheckForUserBinding = (
    deviceType: AppPlatforms,
    userId: string,
    person: BusinessPerson
): boolean => {
    if (deviceType === AppPlatforms.IPAD) {
        return userId === person.Id.toString();
    }
    return userId === person.UserId;
};
export const bindApiResponseViewAccessPermissionModal = (
    rolePermissions: RolePermissionsResponse,
    deviceType: AppPlatforms
): AccessPermissionRequest[] => {
    return rolePermissions.Permissions.map((x) => {
        const splitIdentifier = x.Identifier.split(".");
        //collecting all the user id in accessUsers of all permission (Create , edit , view and delete)
        let accessUser: string[] =
            x.UserAssignmentMap[PermissionAccessTypes.CREATE];
        accessUser = [
            ...accessUser,
            ...x.UserAssignmentMap[PermissionAccessTypes.EDIT],
        ];
        accessUser = [
            ...accessUser,
            ...x.UserAssignmentMap[PermissionAccessTypes.DELETE],
        ];
        accessUser = [
            ...accessUser,
            ...x.UserAssignmentMap[PermissionAccessTypes.SHOW],
        ];
        accessUser = accessUser.filter((item, i, ar) => ar.indexOf(item) === i);

        const response: AccessPermissionRequest = {
            AccessPermissionKey: x.Identifier,
            AccessPermissionId: x.Id,
            CanCreate:
                ModulesWithoutAccess[PermissionAccessTypes.CREATE].find(
                    (y) => y == x.Identifier
                ) == null
                    ? x.CanCreate
                    : false,
            CanDelete:
                ModulesWithoutAccess[PermissionAccessTypes.DELETE].find(
                    (y) => y == x.Identifier
                ) == null
                    ? x.CanDelete
                    : false,
            CanEdit:
                ModulesWithoutAccess[PermissionAccessTypes.EDIT].find(
                    (y) => y == x.Identifier
                ) == null
                    ? x.CanEdit
                    : false,
            CanView:
                ModulesWithoutAccess[PermissionAccessTypes.SHOW].find(
                    (y) => y == x.Identifier
                ) == null
                    ? x.CanView
                    : false,
            UserCount: accessUser.length,
            AccessUsers: accessUser,
            ParentKey:
                splitIdentifier.length == 3
                    ? `${splitIdentifier[0]}.${splitIdentifier[1]}`
                    : splitIdentifier[0],
            UserPermissionsMapping: {
                [PermissionAccessTypes.CREATE]:
                    rolePermissions.UserAssignments.filter(
                        (x) =>
                            accessUser.filter((y) =>
                                deviceIdCheckForUserBinding(deviceType, y, x)
                            ).length > 0
                    ),
                [PermissionAccessTypes.DELETE]:
                    rolePermissions.UserAssignments.filter(
                        (x) =>
                            accessUser.filter((y) =>
                                deviceIdCheckForUserBinding(deviceType, y, x)
                            ).length > 0
                    ),
                [PermissionAccessTypes.EDIT]:
                    rolePermissions.UserAssignments.filter(
                        (x) =>
                            accessUser.filter((y) =>
                                deviceIdCheckForUserBinding(deviceType, y, x)
                            ).length > 0
                    ),
                [PermissionAccessTypes.SHOW]:
                    rolePermissions.UserAssignments.filter(
                        (x) =>
                            accessUser.filter((y) =>
                                deviceIdCheckForUserBinding(deviceType, y, x)
                            ).length > 0
                    ),
            },
        };

        return response;
    });
};
export const handleParentCheckDisable = (
    disableKeysList: PermissionNeedsToDisable,
    type: PermissionAccessTypes,
    accessPermission: AccessPermissionMapping
): boolean => {
    const listOfAllIdentifiers: string[] = [];
    const isCreateViewPossibleChildsList: boolean[] = [];

    const isEditViewPossibleChildsList: boolean[] = [];

    const isDeleteViewPossibleChildsList: boolean[] = [];

    const isViewPossibleChildsList: boolean[] = [];
    if (!accessPermission.Childs) {
        listOfAllIdentifiers.push(accessPermission.CompleteIdentifier);
        isCreateViewPossibleChildsList.push(accessPermission.IsCreatePossible);
        isEditViewPossibleChildsList.push(accessPermission.IsEditPossible);
        isDeleteViewPossibleChildsList.push(accessPermission.IsDeletePossible);
    } else {
        accessPermission.Childs.map((child) => {
            if (child.Childs) {
                child.Childs.map((subChild) => {
                    listOfAllIdentifiers.push(subChild.CompleteIdentifier);
                    switch (type) {
                        case PermissionAccessTypes.CREATE: {
                            isCreateViewPossibleChildsList.push(
                                subChild.IsCreatePossible
                            );
                            break;
                        }
                        case PermissionAccessTypes.EDIT: {
                            isEditViewPossibleChildsList.push(
                                subChild.IsEditPossible
                            );
                            break;
                        }
                        case PermissionAccessTypes.SHOW: {
                            isViewPossibleChildsList.push(
                                subChild.IsViewPossible
                            );
                            break;
                        }
                        case PermissionAccessTypes.DELETE: {
                            isDeleteViewPossibleChildsList.push(
                                subChild.IsDeletePossible
                            );
                            break;
                        }
                    }
                });
            } else {
                listOfAllIdentifiers.push(child.CompleteIdentifier);
                switch (type) {
                    case PermissionAccessTypes.CREATE: {
                        isCreateViewPossibleChildsList.push(
                            child.IsCreatePossible
                        );
                        break;
                    }
                    case PermissionAccessTypes.EDIT: {
                        isEditViewPossibleChildsList.push(child.IsEditPossible);
                        break;
                    }
                    case PermissionAccessTypes.SHOW: {
                        isViewPossibleChildsList.push(child.IsViewPossible);
                        break;
                    }
                    case PermissionAccessTypes.DELETE: {
                        isDeleteViewPossibleChildsList.push(
                            child.IsDeletePossible
                        );
                        break;
                    }
                }
            }
        });
    }
    //Check if all the children are in disable list or not
    if (
        disableKeysList[type].filter(
            (x) => listOfAllIdentifiers.filter((y) => y == x).length > 0
        ).length == listOfAllIdentifiers.length
    ) {
        return true;
    } else {
        //check if all the child is possible true or not
        switch (type) {
            case PermissionAccessTypes.CREATE: {
                if (
                    isCreateViewPossibleChildsList.filter((x) => x == false)
                        .length == listOfAllIdentifiers.length
                ) {
                    return true;
                }
                break;
            }
            case PermissionAccessTypes.EDIT: {
                if (
                    isEditViewPossibleChildsList.filter((x) => x == false)
                        .length == listOfAllIdentifiers.length
                ) {
                    return true;
                }
                break;
            }
            case PermissionAccessTypes.SHOW: {
                if (
                    isViewPossibleChildsList.filter((x) => x == false).length ==
                    listOfAllIdentifiers.length
                ) {
                    return true;
                }
                break;
            }
            case PermissionAccessTypes.DELETE: {
                if (
                    isDeleteViewPossibleChildsList.filter((x) => x == false)
                        .length == listOfAllIdentifiers.length
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};
export const getDefaultSelectedPermissions = (): AccessPermissionRequest[] => {
    return mandatoryPermissionsForAllUsers.map((x) => {
        const temp = x.split(".");
        let parent: string | null = temp.splice(0, temp.length - 1).join(".");
        if (parent.length <= 1) {
            parent = null;
        }

        return {
            ...getInitialAccessPermissionRequest(),
            AccessPermissionKey: x,
            CanView: true,
            ParentKey: parent,
        };
    });
};
export const collectMandatoryNeedToAdd = (
    selectedPermissions: AccessPermissionRequest[],
    existingHandledMandatoryPermissions: MandatoryDetail[]
) => {
    selectedPermissions.forEach((permission) => {
        //check if the permission have any mandatory
        const mandatoryPermissions = permission.ParentKey
            ? ModuleMandatoryAccess[permission.ParentKey]
            : null;

        if (mandatoryPermissions) {
            mandatoryPermissions.forEach((element) => {
                const alreadyHandled = existingHandledMandatoryPermissions.find(
                    (x) =>
                        x.AccessLevel == element.AccessLevel &&
                        x.Identifier == element.Identifier
                );

                if (alreadyHandled && !alreadyHandled.Checked) {
                    alreadyHandled.Checked = true;
                    alreadyHandled.IsProcessed = false;
                } else if (
                    !alreadyHandled &&
                    element.Identifier != permission.AccessPermissionKey
                ) {
                    existingHandledMandatoryPermissions.push({
                        ...element,
                        Checked: true,
                        IsProcessed: false,
                        ParentKey: permission.ParentKey,
                    });
                }
            });
        }
    });
};
export const bindMandatoryWithApiResponse = (
    Permissions: PermissionItemDTOWithUserAssignmentsInfo[]
): MandatoryDetail[] => {
    const mandatoryList: MandatoryDetail[] = [];
    const allParents = [
        ...uniq(
            Permissions.map((x) => {
                return x.Identifier.split(".")[0];
            })
        ),
    ];
    allParents.forEach((parentKey) => {
        const mand = ModuleMandatoryAccess[parentKey];

        if (mand) {
            return mand.map((x) => {
                const temp = x.Identifier.split(".");
                let parent: string | null = temp
                    .splice(0, temp.length - 1)
                    .join(".");
                if (parent.length <= 1) {
                    parent = null;
                }
                mandatoryList.push({
                    AccessLevel: x.AccessLevel,
                    Checked: true,
                    Identifier: x.Identifier,
                    IsProcessed: false,
                    ParentKey: parent,
                });
            });
        }
    });
    return mandatoryList;
};

export const accessLevelCheckWithAccessPermission = (
    type: PermissionAccessTypes,
    req: AccessPermissionRequest
): boolean => {
    switch (type) {
        case PermissionAccessTypes.CREATE: {
            return !req.CanDelete && !req.CanView && !req.CanEdit;
        }
        case PermissionAccessTypes.EDIT: {
            return !req.CanDelete && !req.CanView && !req.CanDelete;
        }
        case PermissionAccessTypes.SHOW: {
            return !req.CanDelete && !req.CanCreate && !req.CanEdit;
        }
        case PermissionAccessTypes.DELETE: {
            return !req.CanCreate && !req.CanView && !req.CanEdit;
        }
    }
};
