import { ImageAssets } from "globals/images/common";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import useCheckPermission from "hooks/permissionCheck/useCheckPermission";
import { defaultTo } from "lodash-es";
import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./HeaderBusinessLogo.module.scss";

export const HeaderBusinessLogo: React.FC = () => {
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();
    const { isContractExpired, logo } = useSessionBusiness();

    return (
        <Link
            to={linkProvider.screens.home(checkPermission, isContractExpired)}
            className={styles.root}
            replace
        >
            <Image src={defaultTo(logo?.FullUrl, ImageAssets.logo.fullLogo)} />
        </Link>
    );
};

export default HeaderBusinessLogo;
