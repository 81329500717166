import { AppRouteParams } from "hooks/general/routing";
import { defaults, isNil } from "lodash-es";
import { BusinessType } from "models/business/enum";
import locationSettingRoutes from "routing/routes/lillywait/locationSetting/routes";
import lwSupportRoutes from "routing/routes/lillywait/support/routes";
import { LillywaitRoutingContext } from "routing/routingContexts";
import {
    DefaultRouteParams,
    getUrlForPattern,
} from "routing/routingContexts/helper";
import lillywaitClientRoutes from "./client/routes";

export const lillywaitRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    params?: AppRouteParams
) => {
    const currParams = defaults({}, params, DefaultRouteParams);
    const context = new LillywaitRoutingContext(
        currParams.sessionBusinessType !== ":sessionBusinessType"
            ? BusinessType.LW
            : currParams.sessionBusinessType,
        currParams.sessionBusinessId
    );
    const base = context.getBaseUrl();
    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {
            registerRequest: ((registerRequestBase: string) => {
                return {
                    createHq: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/create-hq`,
                            true
                        ),
                    list: ({ request }: { request: any }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/list`,
                            true,
                            undefined,
                            request
                        ),
                    getById: (registerRequestId: string) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/get/:id`,
                            true,
                            { id: registerRequestId }
                        ),
                    updateStatus: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/update-status`,
                            true
                        ),
                    resendCompleteRegistrationEmail: (id: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/resend-complete-registration-mail/:id`,
                            true,
                            { id: id.toString() }
                        ),
                    delete: (id: number) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${registerRequestBase}/delete/:id`,
                            true,
                            { id: id.toString() }
                        ),
                };
            })(`hq-register-request`),
        },
        screens: {},
        support: () => lwSupportRoutes(buildUrl, context),
        locationSettings: (id?: string) =>
            locationSettingRoutes(
                buildUrl,
                context,
                "admin",
                !isNil(id) ? id : currParams.locationId
            ),
        clients: (type?: BusinessType, id?: string) => {
            return lillywaitClientRoutes(
                buildUrl,
                context,
                !isNil(id) ? id : currParams.clientBusinessId
            );
        },
        admin: ((adminBase: string) => {
            return {
                locationSetting: (id?: string) =>
                    locationSettingRoutes(
                        buildUrl,
                        context,
                        adminBase,
                        !isNil(id) ? id : currParams.locationId
                    ),
                registrationRequests: ((sectionBase: string) => {
                    return {
                        list: () =>
                            getUrlForPattern(context, buildUrl, sectionBase),
                        createHq: (encodedId: string | ":id" = ":id") =>
                            getUrlForPattern(
                                context,
                                buildUrl,
                                `${sectionBase}/${encodedId}/create-hq`
                            ),
                        createHqWithoutRequest: () =>
                            getUrlForPattern(
                                context,
                                buildUrl,
                                `${sectionBase}/create-hq`
                            ),
                    };
                })(`${adminBase}/registration-requests`),
            };
        })("admin"),
    };
};

export default lillywaitRoutes;
