import { StartAndEndDateFields } from "commonPartials/StartAndEndDateFields";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppOverlayLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { GenerateAccountingExcelRequest } from "models/general";
import { changeEmployeeTypeStringToInt, EmployeeType } from "models/employee";
import { UserType } from "models/general";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { BusinessEmployeeService } from "services/business";
import { KindergartenClientService } from "services/kindergarten";

export interface AccountingExcelCompileDialogProps {
    selectedIds?: number[];
    dialogState: boolean;
    setDialogState: (val: boolean) => void;
    userType: UserType;
    employeeType?: EmployeeType;
}

export const AccountingExcelCompileDialog: React.FC<
    AccountingExcelCompileDialogProps
> = ({ dialogState, selectedIds, setDialogState, userType, employeeType }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [request, setRequest] = useState<StartAndEndDates>({
        StartDate: moment().startOf("month"),
        EndDate: moment().endOf("month"),
    });

    // const [openPdf, setOpenPdf] = useState<boolean>(false);

    const kindergartenClientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );
    const empService = new BusinessEmployeeService(
        linkProvider.business.employees().api
    );

    const {
        data: generateClientAccountingResp,
        isLoading: generatingClientAccountingExcel,
        mutate: generateClientsAccountingExcel,
    } = useMutation(async (editableRequest: GenerateAccountingExcelRequest) =>
        kindergartenClientService.generateAccountingExcel(editableRequest)
    );
    const {
        data: generateEmpAccountingResp,
        isLoading: generatingEmpAccountingExcel,
        mutate: generateEmpAccountingExcel,
    } = useMutation(async (editableRequest: GenerateAccountingExcelRequest) =>
        empService.generateAccountingExcel(editableRequest)
    );

    const onClose = () => setDialogState(false);
    useEffect(() => {
        let showError = false,
            showSuccess = false;
        if (!generatingClientAccountingExcel && generateClientAccountingResp) {
            onClose();
            if (generateClientAccountingResp.Data) {
                // setOpenPdf(true);

                showSuccess = true;
            } else if (generateClientAccountingResp.Code) {
                showError = true;
            }
        } else if (!generatingEmpAccountingExcel && generateEmpAccountingResp) {
            onClose();
            if (generateEmpAccountingResp.Data) {
                // setOpenPdf(true);
                showSuccess = true;
            } else if (generateEmpAccountingResp.Code) {
                showError = true;
            }
        }

        if (showError) {
            showSweetAlertToast(
                t("common.error.error"),
                t("common.clientEmployeeListHeader.accountingExcelFailure"),
                "error"
            );
        } else if (showSuccess) {
            showSweetAlertToast(
                t("common.success"),
                t("common.clientEmployeeListHeader.accountingExcelSuccess"),
                "success"
            );
        }
    }, [
        generatingClientAccountingExcel,
        generateClientAccountingResp,
        generatingEmpAccountingExcel,
        generateEmpAccountingResp,
    ]);

    return (
        <AppDialog
            title={t("common.clientEmployeeListHeader.createAccountingExcel")}
            modalOpen={dialogState}
            size="lg"
            keyboard={false}
            onClose={onClose}
        >
            {generatingClientAccountingExcel && (
                <AppOverlayLoader fullHeight={false} />
            )}
            <div className="row">
                <StartAndEndDateFields
                    colSize={6}
                    useMonthPicker={true}
                    // maxStartDate={moment().endOf("month")}
                    useNoEndDate={false}
                    value={request}
                    onChange={(val: StartAndEndDates) => {
                        setRequest(val);
                    }}
                />
            </div>
            <AppDialogFooter
                onDialogClose={onClose}
                disableSaveButton={generatingClientAccountingExcel}
                onClickSaveButton={() => {
                    const req = {
                        ...request,
                        ForPersonIds: selectedIds,
                    } as GenerateAccountingExcelRequest;
                    if (userType == UserType.Client) {
                        generateClientsAccountingExcel(req);
                    } else {
                        generateEmpAccountingExcel(
                            employeeType
                                ? {
                                      ...req,
                                      ForEmployeeType:
                                          changeEmployeeTypeStringToInt(
                                              employeeType
                                          ),
                                  }
                                : req
                        );
                    }
                }}
                saveButtonText={t("common.create")}
            />
        </AppDialog>
    );
};

export default AccountingExcelCompileDialog;
