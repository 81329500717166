import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { PermissionAccessMap } from "models";
import { KindergartenClientRegistration } from "models/kindergartenClientRegistration/model";
import { useTranslation } from "react-i18next";
import styles from "./KindergartenClientRegistrationRow.module.scss";
import { getYearColor } from "models/kindergartenClientRegistration";
import classNames from "classnames";

export interface KindergartenClientRegistrationRowProps {
    value: KindergartenClientRegistration;
    permMap: PermissionAccessMap;
    onDeleteClick: (id: string) => void;
    onClick: (id: string) => void;
    loading?: boolean;
}

export const KindergartenClientRegistrationRow: React.FC<
    KindergartenClientRegistrationRowProps
> = ({ value, onDeleteClick, permMap, onClick, loading = false }) => {
    const { getDateFormatForLocale, getDateTimeFormatForLocale } =
        useLocaleHelpers();
    const { t } = useTranslation();
    return (
        <tr
            onClick={() => {
                if (permMap.EDIT && value.EncodedId) {
                    onClick(value.EncodedId);
                }
            }}
            key={value.Id}
        >
            <td style={{ paddingLeft: "10px" }}>
                <span className={styles.textEllipse}>{value.Name}</span>
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.Gender ? t(`common.gender.${value.Gender}`) : ""}
                </span>
            </td>
            <td>
                <span
                    style={{ background: getYearColor(value.Year) }}
                    className={classNames(styles.textEllipse, styles.yearLabel)}
                >
                    {value.Year}
                </span>
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.Birthday
                        ? value.Birthday.format(getDateFormatForLocale())
                        : ""}
                    {/* t("kindergartenClient.registrations.hasNoBirthday") */}
                </span>
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.CareStart
                        ? value.CareStart.format(getDateFormatForLocale())
                        : ""}
                    {/* t("kindergartenClient.registrations.hasNoCareStart") */}
                </span>{" "}
            </td>
            {/* <td>
                <span className={styles.textEllipse}>
                    {value.AgeGroupFieldName}
                </span>
            </td> */}
            <td>
                <span className={styles.textEllipse}>
                    {value.CareScopeFieldName}
                </span>
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.Status ? t(`common.status.${value.Status}`) : ""}
                </span>
            </td>
            {/* <td>
                <span className={styles.textEllipse}>
                    {value.ProgressStatus
                        ? t(
                              `kindergartenClient.registration.progressStatus.${value.ProgressStatus}`
                          )
                        : ""}
                </span>
            </td> */}
            <td>
                <BooleanTickCrossIcon value={value.IsSibling} />
            </td>
            <td>
                <BooleanTickCrossIcon value={value.ArrangedPlace} />
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.FromSource
                        ? t(`common.sourceTypes.${value.FromSource}`)
                        : ""}
                </span>
            </td>
            <td>
                <span className={styles.textEllipse}>
                    {value.CreatedAt.format(getDateTimeFormatForLocale())}
                </span>
            </td>
            <td>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.Name}
                    readonly={!permMap.DELETE || loading}
                    onClick={() =>
                        onDeleteClick(value.EncodedId ? value.EncodedId : "")
                    }
                />
            </td>
        </tr>
    );
};
export default KindergartenClientRegistrationRow;
