import useLocaleHelpers from "hooks/general/localeHelpers";
import moment from "moment";
import { Moment } from "moment-timezone";
import React from "react";
import { TimeManagementAccessEventsInfo } from "./poppers";
import styles from "./TimeManagementInOutSummary.module.scss";
import { TimeManagementProgress } from "./TimeManagementProgress";

export interface TimeManagementInOutSummaryProps {
    start?: Moment;
    end?: Moment;
    completedTime?: number; //Minutes
    maxTime?: number; //Minutes
    accessControlId?: number;
}

export const TimeManagementInOutSummary: React.FC<
    TimeManagementInOutSummaryProps
> = ({ start, end, completedTime, maxTime, accessControlId }) => {
    const { getTimeFormatForLocale } = useLocaleHelpers();
    return start == null && end == null ? (
        <></>
    ) : (
        <div className={styles.container}>
            <div style={{ minWidth: "70px" }}>
                {start ? moment(start).format(getTimeFormatForLocale()) : ""}
            </div>
            {end == null ? (
                <div style={{ width: "80px" }}>
                    <TimeManagementProgress
                        endDate={end}
                        completedTime={completedTime}
                        maxTime={maxTime}
                    />
                </div>
            ) : (
                <>
                    <span style={{ display: "inline-flex" }}>-</span>
                    <div className={styles.endDateDiv}>
                        {moment(end).format(getTimeFormatForLocale())}
                    </div>
                </>
            )}
            {accessControlId && (
                <div>
                    <TimeManagementAccessEventsInfo
                        accessControlId={accessControlId}
                    />
                </div>
            )}
        </div>
    );
};

export default TimeManagementInOutSummary;
