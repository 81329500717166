import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppIconWithName, AppTooltip } from "components";
import AppButton from "components/Buttons/AppButton";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    AccessPointAction,
    Business_TimeTracking_Employees,
    Business_TimeTracking_Suppliers,
    ControllerType,
    EmployeeType,
    getAccessPointActionString,
    getDayTypeTranslation,
    ManualEntryRequest,
    ManualTimeTrackingEntryRequest,
    PermissionAccessTypes,
    TimeTrackingRecord,
    TimeTrackingRequestParameters,
} from "models";
import { DurationPeriodType, Optional, UserType } from "models/general";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    getTimeTrackingServiceKey,
    TimeTrackingService,
} from "services/business";
import TimeManagementAccessEventsInfo from "../poppers/TimeManagementAccessEventsInfo";
import TimeManagementColorHighlight from "../TimeManagementColorHighlight";
import TimeManagementPersonDetailManualEntry from "../TimeManagementPersonDetailManualEntry";
import TimeManagementProgress from "../TimeManagementProgress";
import styles from "./TimeManagementPersonDetailTableRow.module.scss";

interface TimeManagementPersonDetailTableRowProps {
    value: TimeTrackingRecord;
    preparationTime?: number;
    filterRequest: TimeTrackingRequestParameters;
    showName?: boolean;
    manualAllowed: boolean;
    onChange: (data: TimeTrackingRecord) => void;
    onRowClick?: (numberId: number) => void;
    personId: Optional<number>;
    refetchData?: () => void;
}

const TimeManagementPersonDetailTableRow: React.FC<
    TimeManagementPersonDetailTableRowProps
> = ({
    value,
    manualAllowed,
    filterRequest,
    refetchData,
    preparationTime = 0,
    onChange,
    onRowClick,
    personId,
}) => {
    const valueRef = useRef(value);
    const { UserType: userType, ForEmployeeType, Date } = filterRequest;
    const { sessionBusiness } = useSessionBusiness();
    const { t } = useTranslation();
    const { getDateFormatForLocale, getTimeFormatForLocale } =
        useLocaleHelpers();
    const { checkPermission } = useCheckPermission();
    const [isIncrement, setIsIncrement] = useState<boolean>(false);
    const { linkProvider } = useRouting();
    const timeTrackingService = new TimeTrackingService(
        linkProvider.business.api.timeTracking
    );
    const hasEditAccess = checkPermission(
        ForEmployeeType == EmployeeType.Supplier
            ? Business_TimeTracking_Suppliers
            : Business_TimeTracking_Employees,
        [PermissionAccessTypes.EDIT]
    );

    const calculateNewValues = useCallback(
        (forIncrement?: boolean, newMinutes?: number) => {
            const increment = !isNil(forIncrement) ? forIncrement : isIncrement;

            const copyValue = {
                ...valueRef.current,
                CareMinutes: defaultTo(valueRef.current.CareMinutes, 0),
            };

            let toAdjust = 15;
            if (newMinutes != null) {
                // if timeStr is not null then re-calculate duration using string
                const oldDuration = defaultTo(copyValue.ManualEntryDuration, 0);
                toAdjust = newMinutes - oldDuration;
            } else {
                toAdjust = !increment ? -15 : 15;
                if (!isNil(forIncrement)) {
                    setIsIncrement(forIncrement);
                }
            }

            copyValue.ManualEntryDuration =
                copyValue.ManualEntryDuration + toAdjust;
            copyValue.CareMinutes = copyValue.CareMinutes + toAdjust;

            copyValue.DifferenceSumMinutes =
                copyValue.CareMinutes - copyValue.MaxContractMinutes;

            if (
                copyValue.DifferenceSumMinutes > 0 &&
                // ignore minutes upto preparationTime from over-time
                copyValue.DifferenceSumMinutes < preparationTime
            ) {
                copyValue.DifferenceSumMinutes = 0;
            }
            return copyValue;
        },
        [isIncrement]
    );

    const {
        isLoading: updating,
        mutate: updateEntry,
        data: response,
        reset,
    } = useMutation(
        getTimeTrackingServiceKey("updateManualEntry"),
        async (params: ManualEntryRequest) => {
            return await timeTrackingService.updateManualEntry(params);
        }
    );
    const onDurationChangeHandler = async (
        isIncrement?: boolean,
        newTimeStr?: number
    ) => {
        const copyValue = calculateNewValues(isIncrement, newTimeStr);

        updateEntry({
            Date: defaultTo(copyValue.Date, Date).format(ISO8601_DATE_FORMAT),
            Comment: copyValue.ManualEntryComment,
            Duration: copyValue.ManualEntryDuration,
            AccessControlId: copyValue.AccessId as number,
            PersonId: copyValue.PersonId,
            PersonType: userType,
        });
        valueRef.current = copyValue;
        // onChange(copyValue);
    };

    const isForEmployee = userType == UserType.Employee;
    const onCommentChange = async (comment: string) => {
        const val = { ...valueRef.current };
        await updateEntry({
            Date: defaultTo(val.Date, Date).format(ISO8601_DATE_FORMAT),
            Comment: comment,
            Duration: val.ManualEntryDuration,
            AccessControlId: val.AccessId as number,
            PersonId: val.PersonId,
            PersonType: userType,
        });
        valueRef.current = {
            ...val,
            ManualEntryComment: comment,
        };
    };

    const {
        isLoading: updatingManualEntry,
        mutate: addNewManualEntry,
        data: responseManualEntry,
        reset: resetManualEntry,
    } = useMutation(
        getTimeTrackingServiceKey("addNewEntry"),
        async (params: ManualTimeTrackingEntryRequest) => {
            return await timeTrackingService.addNewEntry(params);
        }
    );

    useEffect(() => {
        if (!updatingManualEntry && responseManualEntry) {
            if (responseManualEntry && responseManualEntry.Data) {
                if (refetchData) {
                    refetchData();
                }
                showSweetAlertToast(
                    t("common.success"),
                    t(`timeManagement.timeEntryAddedSuccessfully`),
                    "success"
                );
            } else {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("timeManagement.manualEntryError"),
                    "error"
                );
            }
            reset();
        }
    }, [responseManualEntry, updatingManualEntry]);

    useEffect(() => {
        if (!updating && response) {
            const copyValue = { ...valueRef.current };
            if (response && response.Data) {
                if (!copyValue.AccessId) {
                    copyValue.AccessId = response.Data.DailyAccessId;
                }

                onChange(copyValue);
            } else {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("timeManagement.manualEntryError"),
                    "error"
                );
            }
            reset();
        }
    }, [response, updating, isIncrement]);

    const isOut =
        isNil(value.CurrentAction) ||
        value.CurrentAction == AccessPointAction.OUT;

    return (
        <tr
            className={styles.tableRow}
            onClick={() => {
                if (onRowClick) {
                    onRowClick(value.PersonId);
                }
            }}
        >
            {!personId ? (
                <td>
                    <AppIconWithName
                        className={styles.iconName}
                        text={value.PersonName}
                    />
                </td>
            ) : (
                <td className="pl-1 text-left">
                    <span style={{ marginLeft: "15px" }}>
                        {value.Date &&
                            value.Date.format(getDateFormatForLocale())}
                    </span>
                </td>
            )}
            {manualAllowed && (
                <td>
                    {(!personId ||
                        (value.Date &&
                            value.Date.format(getDateFormatForLocale()) ==
                                moment().format(getDateFormatForLocale()))) && (
                        <AppTooltip
                            content={t(
                                `timeManagement.${
                                    isOut ? "startTimer" : "stopTimer"
                                }`
                            )}
                            allowHtml={false}
                            trigger="mouseenter focus"
                            arrow={true}
                        >
                            <AppButton
                                className={`${styles.manualButton} rounded-circle`}
                                colorVariant={isOut ? "blue" : "red"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addNewManualEntry({
                                        EpochInstant: moment().unix(),
                                        PersonId: value.PersonId,
                                        AccessControlId: value.AccessId,
                                        BusinessId:
                                            sessionBusiness?.Id as number,
                                        Type: ControllerType.Main,
                                        PersonType: userType,
                                    });
                                }}
                                disabled={updating}
                            >
                                <FontAwesomeIcon
                                    style={
                                        isOut
                                            ? {
                                                  marginLeft: "3px",
                                              } // to center play button
                                            : undefined
                                    }
                                    icon={isOut ? "play" : "stop"}
                                />
                            </AppButton>
                        </AppTooltip>
                    )}
                </td>
            )}
            <td colSpan={2} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <div className={styles.container}>
                    {/* in */}
                    <div
                        className={value.ArrivedLate ? styles.bgRed : ""}
                        style={{ minWidth: "70px", padding: "0px 8px" }}
                    >
                        {value.FirstEntranceTime &&
                            value.FirstEntranceTime.format(
                                getTimeFormatForLocale()
                            ) +
                                " " +
                                getAccessPointActionString(
                                    AccessPointAction.IN
                                )}
                    </div>
                    {/* out */}
                    {value.FirstEntranceTime && !value.LastExitTime ? (
                        <div
                            style={{
                                width: "80px",
                            }}
                        >
                            <TimeManagementProgress
                                endDate={value.LastExitTime}
                                completedTime={value.CareMinutes}
                                maxTime={value.MaxContractMinutes}
                            />
                        </div>
                    ) : value.LastExitTime ? (
                        <>
                            <span style={{ display: "inline-flex" }}>-</span>
                            <div
                                className={value.LeftEarly ? styles.bgRed : ""}
                                style={{ minWidth: "70px", padding: "0px 8px" }}
                            >
                                {value.LastExitTime.format(
                                    getTimeFormatForLocale()
                                ) +
                                    " " +
                                    getAccessPointActionString(
                                        AccessPointAction.OUT
                                    )}
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    {/* info */}
                    {(value.FirstEntranceTime || value.LastExitTime) &&
                        value.AccessId && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <TimeManagementAccessEventsInfo
                                    accessControlId={value.AccessId}
                                    showTypeInTitle={isForEmployee}
                                    controllerType={ControllerType.Main}
                                />
                                {isForEmployee &&
                                    value.HasBreakEvents == true && (
                                        <TimeManagementAccessEventsInfo
                                            accessControlId={value.AccessId}
                                            useGreenColor={true}
                                            showTypeInTitle={true}
                                            controllerType={
                                                ControllerType.Break
                                            }
                                        />
                                    )}
                            </div>
                        )}
                </div>
            </td>
            {isForEmployee && (
                <td className={`text-center`}>
                    <div
                        className={`${
                            defaultTo(value.ExtraBreakMinutes, 0) > 0
                                ? styles.bgRed
                                : ""
                        } text-center`}
                    >
                        {getFormattedTimeFromNumber(value.BreakMinutes)}
                    </div>
                </td>
            )}
            <td className="text-center">
                {getFormattedTimeFromNumber(value.CareMinutes)}
            </td>
            <td className="text-center">
                {getFormattedTimeFromNumber(value.MaxContractMinutes)}
            </td>
            <td className="text-center">
                <TimeManagementColorHighlight
                    differenceSumMinute={
                        !value.IgnoreDifference &&
                        (isForEmployee ||
                            (userType == UserType.Client &&
                                (value.LastExitTime ||
                                    value.FirstEntranceTime)))
                            ? value.DifferenceSumMinutes
                            : null
                    }
                    isNegativeRed={isForEmployee}
                />
            </td>
            {isForEmployee && (
                <TimeManagementPersonDetailManualEntry
                    value={
                        {
                            Date: defaultTo(value.Date, Date).format(
                                ISO8601_DATE_FORMAT
                            ),
                            Comment: value.ManualEntryComment,
                            Duration: value.ManualEntryDuration,
                            AccessControlId: value.AccessId,
                            DayType: value.DateType,
                        } as ManualEntryRequest
                    }
                    readonly={updating || !hasEditAccess}
                    onDurationChangeHandler={
                        hasEditAccess ? onDurationChangeHandler : () => {}
                    }
                    onCommentChange={hasEditAccess ? onCommentChange : () => {}}
                />
            )}
            <td className="text-left">
                {getDayTypeTranslation(value.DateType, isForEmployee)}
            </td>
        </tr>
    );
};

export default TimeManagementPersonDetailTableRow;
