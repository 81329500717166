import { PropsWithChildren, useEffect } from "react";
import { BaseObject } from "models/general";
import { useWebEventHub } from "hooks/layout/useWebEventHub";

export const AppWebEventProvider: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}: PropsWithChildren<BaseObject>) => {
    // init signalR
    const { configure, dispose, signalR } = useWebEventHub();

    useEffect(() => {
        if (signalR === null) {
            configure();
        }
        return () => {
            // stop signalR on un-mount
            dispose();
        };
    }, []);
    return <>{children}</>;
};

export default AppWebEventProvider;
