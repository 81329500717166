import { ImageAssets } from "globals/images";
import { UserType } from "models/general";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TimeManagementHeaderContainer from "./TimeManagementHeaderContainer";
import styles from "./TimeManagementPersonDetailHeader.module.scss";

export interface TimeManagementPersonDetailHeaderProps {
    userType: UserType;
    onBack: () => void;
    className?: string;
    manualAllowed?: boolean;
}

export const TimeManagementPersonDetailHeader: React.FC<
    TimeManagementPersonDetailHeaderProps
> = ({ userType, onBack, className = "", manualAllowed }) => {
    const { t } = useTranslation();
    return (
        <TimeManagementHeaderContainer className={className}>
            <tbody>
                <tr>
                    <td
                        style={{
                            minWidth: "300px",
                        }}
                    >
                        <div
                            className={styles.headerBack}
                            onClick={() => onBack()}
                        >
                            <Image src={ImageAssets.common.arrowLeft} />
                        </div>
                    </td>
                    {manualAllowed && (
                        <td
                            style={{
                                minWidth: "60px",
                                width: "60px",
                                maxWidth: "60px",
                            }}
                        />
                    )}
                    <td
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            maxWidth: "140px",
                        }}
                    />
                    <td
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            maxWidth: "140px",
                        }}
                    />
                    {userType != UserType.Client && (
                        <>
                            <td
                                style={{
                                    minWidth: "150px",
                                    width: "150px",
                                    maxWidth: "150px",
                                }}
                            >
                                {t("timeManagement.totalBreak")}
                            </td>
                            <td className={styles.lineTd} rowSpan={2}>
                                <div />
                            </td>
                        </>
                    )}
                    <td
                        style={{
                            minWidth: "200px",
                            width: "200px",
                            maxWidth: "200px",
                        }}
                    >
                        {userType != UserType.Client
                            ? t("timeManagement.workingHours")
                            : t("timeManagement.careHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "200px",
                            width: "200px",
                            maxWidth: "200px",
                        }}
                    >
                        {userType != UserType.Client
                            ? t("timeManagement.maxWorkingHours")
                            : t("timeManagement.maxContractHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "200px",
                            width: "200px",
                            maxWidth: "200px",
                        }}
                    >
                        {t("timeManagement.difference")}
                    </td>

                    {userType != UserType.Client && (
                        <>
                            <td className={styles.lineTd} rowSpan={2}>
                                <div />
                            </td>
                            <td
                                style={{
                                    minWidth: "170px",
                                    width: "170px",
                                    maxWidth: "170px",
                                }}
                            >
                                {t("timeManagement.manualEntry")}
                            </td>
                            <td className={styles.lineTd} rowSpan={2}>
                                <div />
                            </td>
                            <td
                                style={{
                                    minWidth: "250px",
                                    width: "250px",
                                    maxWidth: "250px",
                                }}
                            >
                                {t("timeManagement.totalDifference")}
                            </td>
                        </>
                    )}
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td
                        style={{
                            minWidth: "250px",
                            width: "250px",
                            maxWidth: "250px",
                        }}
                    >
                        {userType != UserType.Client
                            ? t("timeManagement.differenceForFilteredYear")
                            : t("timeManagement.description")}
                    </td>
                </tr>
            </tbody>
        </TimeManagementHeaderContainer>
    );
};

export default TimeManagementPersonDetailHeader;
