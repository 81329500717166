import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash-es";
import { Optional, SortOrder } from "models/general";
import React, { useEffect, useState } from "react";
export interface AppTableSortColumnIconsProps<T>
    extends Omit<
        React.DetailedHTMLProps<
            React.ThHTMLAttributes<HTMLTableCellElement>,
            HTMLTableCellElement
        >,
        "onClick"
    > {
    text: string;
    sortColumn: T;
    sortOrder: Optional<SortOrder>;
    onClick: (key: T, order: SortOrder) => void;
    className?: string;
    width?: number;
}

export function AppTableSortColumnIcons<T>(
    props: AppTableSortColumnIconsProps<T>
) {
    const {
        sortColumn,
        sortOrder,
        text,
        onClick,
        style,
        width,
        className = "",
        ...rest
    } = props;
    const [iconClass, setIconClass] = useState<IconProp>("sort");
    useEffect(() => {
        if (sortOrder == null || sortColumn == null) {
            setIconClass("sort");
        } else if (sortOrder != null && sortOrder === SortOrder.ASC) {
            setIconClass("sort-up");
        } else if (sortOrder != null && sortOrder === SortOrder.DESC) {
            setIconClass("sort-down");
        }
    }, [sortOrder]);

    return (
        <th
            className={className}
            style={{
                cursor: "pointer",
                ...style,
                ...(!isNil(width)
                    ? {
                          minWidth: `${width}px`,
                          width: `${width}px`,
                          maxWidth: `${width}px`,
                      }
                    : {}),
            }}
            onClick={() =>
                onClick(
                    sortColumn,
                    sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
                )
            }
            {...rest}
        >
            <div>
                {text}
                <FontAwesomeIcon
                    icon={iconClass}
                    style={{
                        paddingLeft: "10px",
                        fontSize: "0.9rem",
                        width: "20px",
                    }}
                />
            </div>
        </th>
    );
}

export default AppTableSortColumnIcons;
