import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { isNil } from "lodash-es";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { ModulePlanContent } from "./model";

export function validateModulePlanContent(
    content: ModulePlanContent,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof ModulePlanContent> = {
        Title: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "locationSettings.modulePlan.contentDialog.title.missing"
                ),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        content,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}
