import { AppPopover } from "components";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { v4 as uuid } from "uuid";
import { AbsentRecord } from "models";
import React, { useMemo, ReactNode } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AbsentDatesDetailPopover.module.scss";

export interface AbsentDatesDetailPopoverProps {
    value: AbsentRecord[];
    title: string;
    content: ReactNode;
    hasFullTotal?: boolean;
}

export const AbsentDatesDetailPopover: React.FC<
    AbsentDatesDetailPopoverProps
> = ({ value, title, content, hasFullTotal = true }) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();

    const locale = useMemo(() => getDateFormatForLocale(), [t]);
    return (
        <AppPopover
            triggerElem={<span>{content}</span>}
            placement={"left"}
            triggerOnClick={false}
            containerClass={styles.popupDiv}
            trigger={"mouseenter focus"}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <span className="font-weight-bold">{title}</span>
                {value.length > 0 ? (
                    <>
                        <Table
                            style={{
                                margin: "0",
                                cursor: "default !important",
                            }}
                            className={styles.absentsInfoTable}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            cursor: "default !important",
                                        }}
                                    >
                                        {t(
                                            "business.absents.popupTable.startDate"
                                        )}
                                    </th>
                                    <th
                                        style={{
                                            cursor: "default !important",
                                        }}
                                    >
                                        {t(
                                            "business.absents.popupTable.endDate"
                                        )}
                                    </th>
                                    <th
                                        style={{
                                            cursor: "default !important",
                                        }}
                                    >
                                        {t(
                                            `business.absents.popupTable.rangeTotal`
                                        )}
                                    </th>
                                    {hasFullTotal && (
                                        <th
                                            style={{
                                                cursor: "default !important",
                                            }}
                                        >
                                            {t(
                                                "business.absents.popupTable.total"
                                            )}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {value.map((x) => (
                                    <tr key={uuid()}>
                                        <td
                                            style={{
                                                cursor: "default !important",
                                            }}
                                        >
                                            {x.StartDate?.format(locale)}
                                        </td>
                                        <td
                                            style={{
                                                cursor: "default !important",
                                            }}
                                        >
                                            {x.EndDate?.format(locale)}
                                        </td>
                                        <td
                                            style={{
                                                cursor: "default !important",
                                            }}
                                        >
                                            {x.Total}
                                        </td>
                                        {hasFullTotal && (
                                            <td
                                                style={{
                                                    cursor: "default !important",
                                                }}
                                            >
                                                {x.ActualTotal}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <span style={{ margin: "5px", display: "inline-flex" }}>
                        {t("common.noDataFound")}
                    </span>
                )}
            </div>
        </AppPopover>
    );
};

export default AbsentDatesDetailPopover;
