import * as Sentry from "@sentry/browser";
import config from "globals/config";
import ErrorLayout from "layouts/ErrorLayout";
import React, { Component, ErrorInfo, PropsWithChildren } from "react";

export interface ErrorBoundaryProps {
    error: Error;
    errorInfo: React.ErrorInfo;
    eventId: string;
}

export type AppErrorBoundaryProps = PropsWithChildren<{
    TargetComponent?: React.FC<ErrorBoundaryProps>;
}>;
export class AppErrorBoundary extends Component<AppErrorBoundaryProps, any> {
    constructor(props: AppErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null, eventId: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });

        if (config.sentry && config.sentry.enable) {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo as any);
                const eventId = Sentry.captureException(error);
                this.setState({ eventId });
            });
        }
    }

    render() {
        const defaultBehavior = <>{this.props.children}</>;
        if (this.state.errorInfo) {
            // Error path
            const TargetComponent = this.props.TargetComponent;
            return TargetComponent ? (
                <TargetComponent
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                    eventId={this.state.eventId}
                />
            ) : !config.isDevEnv ? (
                <ErrorLayout errorCode={500} />
            ) : (
                <>
                    {(() => {
                        throw this.state.error;
                    })()}
                </>
            );
        }
        // Normally, just render children
        return defaultBehavior;
    }
}

export default AppErrorBoundary;
