import classNames from "classnames";
import { AppContentHeader, AppTimer } from "components";
import AppButtonDatepicker from "components/FormFields/DatePicker/AppButtonDatePicker";
import { AppTabButtonProps, AppTabButtons } from "components/Tabs";
import { ImageAssets } from "globals/images";
import { defaultTo, isNil } from "lodash-es";
import {
    calculateTimerInterval,
    EmployeeType,
    TimeTrackingRequestParameters,
} from "models";
import { DatePickerType } from "models/datepicker";
import { DurationPeriodType, UserType } from "models/general";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { COLOR_DARK_YELLOW, COLOR_RED } from "theme/themeConstants";
import styles from "./TimeManagementMainHeader.module.scss";

interface TimeManagementMainHeaderProps {
    filter: TimeTrackingRequestParameters;
    loading: boolean;
    refetch: () => void;
    onFilterChange: (filter: TimeTrackingRequestParameters) => void;
}
const REFRESH_INTERVAL_MINUTES = 10;
const REFRESH_AT_MINUTE = 5;
const MIN_REFETCH_DELAY = 2;

const TimeManagementMainHeader: React.FC<TimeManagementMainHeaderProps> = ({
    filter,
    loading,
    refetch,
    onFilterChange,
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState<{
        manualRefresh: boolean;
        timer: number | undefined;
    }>({ manualRefresh: false, timer: undefined });

    const calculateDuration = (isFirst = true): number => {
        return calculateTimerInterval(
            REFRESH_INTERVAL_MINUTES,
            REFRESH_AT_MINUTE,
            isFirst ? MIN_REFETCH_DELAY : undefined
        );
    };

    useEffect(() => {
        if (
            filter.UserType == UserType.Client ||
            filter.ForEmployeeType == EmployeeType.Employee
        ) {
            setState((s) => ({
                ...s,
                timer: calculateDuration(state === undefined),
            }));
        }
    }, [filter]);
    useEffect(() => {
        if (isNil(state.timer)) {
            // to reset timer value according to correct remaining time
            setState((old) => ({
                manualRefresh: false,
                timer: old.timer
                    ? old.timer
                    : old.manualRefresh
                    ? REFRESH_INTERVAL_MINUTES // don't run any logic on refresh
                    : calculateDuration(false),
            }));
        }
    }, [state]);

    const datePicker = useMemo(
        () => (
            <AppButtonDatepicker
                value={filter.Date}
                readonly={loading}
                className={styles.picker}
                onChange={(val) =>
                    onFilterChange({
                        ...filter,
                        Date: defaultTo(val, moment()),
                    })
                }
                pickerType={
                    filter.FilterType == DurationPeriodType.Month
                        ? DatePickerType.MONTH_YEAR
                        : DatePickerType.DATE
                }
                maxDate={moment()}
            />
        ),
        [loading, filter]
    );
    const hasTabSection = filter.PersonId == null;
    return (
        <AppContentHeader
            icon={ImageAssets.common.timeManagement}
            className={styles.root}
            title={t(
                `${
                    filter.UserType == UserType.Client
                        ? "timeManagement.timeManagementClients"
                        : filter.ForEmployeeType == EmployeeType.Employee
                        ? "timeManagement.timeManagementEmployees"
                        : "timeManagement.timeManagementSuppliers"
                }`
            )}
        >
            <div className={classNames(styles.headerContent)}>
                {hasTabSection ? (
                    <AppTabButtons
                        loading={loading}
                        btnClass={styles.tabButton}
                        animated={false}
                        activeClass={styles.btnActive}
                        containerClass={styles.tabs}
                        tabButtons={[
                            {
                                label: t("timeManagement.tabs.day"),
                                onClick: () =>
                                    onFilterChange({
                                        ...filter,
                                        Date: moment(),
                                        FilterType: DurationPeriodType.Day,
                                        PreviousFilterType: undefined,
                                    }),
                                active:
                                    filter.FilterType == DurationPeriodType.Day,
                            } as AppTabButtonProps,
                            ...(filter.UserType === UserType.Client
                                ? ([
                                      {
                                          label: t("timeManagement.tabs.week"),
                                          onClick: () =>
                                              onFilterChange({
                                                  ...filter,
                                                  Date: moment().startOf("W"),
                                                  FilterType:
                                                      DurationPeriodType.Week,
                                                  PreviousFilterType: undefined,
                                              }),
                                          active:
                                              filter.FilterType ==
                                              DurationPeriodType.Week,
                                      },
                                  ] as AppTabButtonProps[])
                                : []),
                            {
                                label: t("timeManagement.tabs.month"),
                                onClick: () =>
                                    onFilterChange({
                                        ...filter,
                                        Date: moment().startOf("M"),
                                        FilterType: DurationPeriodType.Month,
                                        PreviousFilterType: undefined,
                                    }),
                                active:
                                    filter.FilterType ==
                                    DurationPeriodType.Month,
                            } as AppTabButtonProps,
                        ]}
                    />
                ) : (
                    <div></div>
                )}
                {datePicker}
                <div
                    className={classNames(styles.refreshButton, {
                        ["disabled"]: loading,
                    })}
                    onClick={() => {
                        if (!loading) {
                            // reset timer and refetch data
                            refetch();
                            setState({ timer: undefined, manualRefresh: true }); // directly override timer
                        }
                    }}
                >
                    <Image src={ImageAssets.common.syncIconWhite} />
                </div>

                <div className={styles.timerContainer}>
                    <div className={styles.border} />
                    <div className={styles.progressDiv}>
                        {state.timer && (
                            <AppTimer
                                maxDuration={REFRESH_INTERVAL_MINUTES}
                                size={65}
                                colors={[COLOR_DARK_YELLOW, COLOR_RED]}
                                remainingTime={state.timer}
                                isPlaying={!loading}
                                isRecurring={true}
                                onComplete={() => {
                                    refetch();
                                    // to re-render timer, otherwise initial time is not reset
                                    // and if tab was inactive then new time should be less than REFRESH_INTERVAL_MINUTES
                                    setState({
                                        manualRefresh: false,
                                        timer: undefined,
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </AppContentHeader>
    );
};

export default TimeManagementMainHeader;
