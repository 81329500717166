import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { AppInputField } from "components/FormFields";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import {
    getFilteredArray,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, orderBy } from "lodash-es";
import {
    BusinessCreditor,
    Business_Creditor_BasicData,
    CreditorSortColumn,
    getCreditorSortColumnKeyFromEnum,
    PermissionAccessTypes,
} from "models";
import { Optional, SortOrder, TableSort } from "models/general";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { CreditorRow } from "screens/business/creditors/partials";
import {
    BusinessCreditorService,
    getBusinessCreditorServiceKey,
} from "services/business";
import styles from "./CreditorsList.module.scss";
interface DataFilters {
    Name: Optional<string>;
}
interface CreditorsListState {
    filters: DataFilters;
    sort: TableSort<CreditorSortColumn>;
    data: BusinessCreditor[] | undefined;
}

const sortData = (
    data: BusinessCreditor[],
    sort: TableSort<CreditorSortColumn>
) => {
    return orderBy(
        data,
        getCreditorSortColumnKeyFromEnum(sort.SortColumn),
        sort.SortOrder == SortOrder.ASC ? "asc" : "desc"
    );
};
const COLUMNS_COUNT = 5;
export const CreditorsList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const creditorsService = new BusinessCreditorService(
        linkProvider.business.api.creditors
    );
    const [state, setState] = useState<CreditorsListState>({
        filters: {} as any,
        sort: {
            SortColumn: CreditorSortColumn.Name,
            SortOrder: SortOrder.DESC,
        },
        data: undefined,
    });
    const navigate = useNavigate();
    const canDelete = checkPermission(
        [Business_Creditor_BasicData],
        [PermissionAccessTypes.DELETE]
    );
    const { isFetching: isLoading, data: response } = useQuery(
        getBusinessCreditorServiceKey("list"),
        async () => await creditorsService.list()
    );
    const filterData = useCallback(
        (
            value?: DataFilters,
            data?: BusinessCreditor[]
        ): BusinessCreditor[] => {
            if (response) {
                const filteredData = getFilteredArray(
                    defaultTo(data, response.Data),
                    defaultTo(value, state.filters)
                );
                return filteredData.length > 0
                    ? sortData(filteredData, state.sort)
                    : [];
            }
            return [];
        },
        [response, state.sort]
    );

    useEffect(() => {
        if (!isLoading && response && response.Data) {
            setState({ ...state, data: filterData(undefined, response.Data) });
        }
    }, [isLoading, response]);

    useEffect(() => {
        // to init on coming back before cache is expired
        if (!isLoading && response && response.Data && !state.data) {
            setState({ ...state, data: filterData(undefined, response.Data) });
        }
    }, []);
    useEffect(() => {
        if (state.filters) {
            setState({ ...state, data: filterData(state.filters) });
        }
    }, [state.filters]);
    useEffect(() => {
        if (state.sort && response) {
            if (state.data) {
                setState({
                    ...state,
                    data: sortData(state.data, state.sort),
                });
            }
        }
    }, [state.sort]);

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("accounting.creditors.title")}
                classNameForIcon="lnr-briefcase"
            >
                <div className={styles.headerRight}>
                    <AppRoundedTextIconButton
                        onClick={() =>
                            navigate(
                                linkProvider.business.screens.creditors.create()
                            )
                        }
                    />
                </div>
            </AppContentHeader>
            <AppTable
                id={"scrollableDiv"}
                containerClass={`${styles.tableOuter}`}
                hover={true}
                stickyHeader={true}
                heightToAdjust={185}
            >
                <thead>
                    <tr>
                        <AppTableSortColumnIcons
                            width={250}
                            text={t("accounting.creditors.columns.name")}
                            sortColumn={CreditorSortColumn.Name}
                            onClick={(key, order) =>
                                setState((old) => ({
                                    ...old,
                                    sort: {
                                        SortColumn: key,
                                        SortOrder: order,
                                    },
                                }))
                            }
                            sortOrder={
                                state.sort.SortColumn == CreditorSortColumn.Name
                                    ? state.sort.SortOrder
                                    : null
                            }
                        />
                        <AppTableSortColumnIcons
                            width={150}
                            text={t("accounting.creditors.columns.email")}
                            sortColumn={CreditorSortColumn.Email}
                            onClick={(key, order) =>
                                setState((old) => ({
                                    ...old,
                                    sort: {
                                        SortColumn: key,
                                        SortOrder: order,
                                    },
                                }))
                            }
                            sortOrder={
                                state.sort.SortColumn ==
                                CreditorSortColumn.Email
                                    ? state.sort.SortOrder
                                    : null
                            }
                        />
                        <AppTableSortColumnIcons
                            width={150}
                            text={t("accounting.creditors.columns.telephone")}
                            sortColumn={CreditorSortColumn.Telephone}
                            onClick={(key, order) =>
                                setState((old) => ({
                                    ...old,
                                    sort: {
                                        SortColumn: key,
                                        SortOrder: order,
                                    },
                                }))
                            }
                            sortOrder={
                                state.sort.SortColumn ==
                                CreditorSortColumn.Telephone
                                    ? state.sort.SortOrder
                                    : null
                            }
                        />
                        <th style={getFixedCssWidths(300, false)}>
                            {t("accounting.creditors.columns.address")}
                        </th>
                        <th style={getFixedCssWidths(60)} />
                    </tr>
                </thead>
                <tbody>
                    <tr className="filters-row">
                        <td>
                            <AppInputField
                                showClearButton={true}
                                value={state.filters?.Name}
                                onValueChange={(val) =>
                                    setState({
                                        ...state,
                                        filters: {
                                            ...state.filters,
                                            Name: val,
                                        },
                                    })
                                }
                            />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>

                    {isLoading || !state.data ? (
                        <AppTableSkeleton rows={10} columns={COLUMNS_COUNT} />
                    ) : state.data && state.data.length > 0 ? (
                        state.data.map((x) => {
                            return (
                                <CreditorRow
                                    key={x.EncodedId}
                                    value={x}
                                    canDelete={canDelete}
                                    onDelete={(id: number) => {
                                        // deletedHq (not possible yet)
                                        setState((old) => ({
                                            ...old,
                                            data: defaultTo(
                                                old?.data,
                                                []
                                            ).filter((x) => x.Id !== id),
                                        }));
                                    }}
                                    onClick={() =>
                                        navigate(
                                            linkProvider.business.screens.creditors
                                                .withId(x.EncodedId)
                                                .edit()
                                        )
                                    }
                                />
                            );
                        })
                    ) : (
                        <tr>
                            <td
                                colSpan={COLUMNS_COUNT}
                                style={{ textAlign: "center" }}
                            >
                                {t("common.noDataFound")}
                            </td>
                        </tr>
                    )}

                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default CreditorsList;
