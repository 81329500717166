import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageAssets } from "globals/images";
import React from "react";

export interface BooleanTickCrossIconProps {
    value: boolean;
    showCross?: boolean;
}

export const BooleanTickCrossIcon: React.FC<BooleanTickCrossIconProps> = ({
    value,
    showCross = false,
}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {value ? (
                <div className="text-success">
                    <FontAwesomeIcon
                        icon="check-circle"
                        style={{ fontSize: "1.1rem" }}
                    />

                    {/* <img
                        src={ImageAssets.common.checkCircleBlue}
                        style={{
                            objectFit: "contain",
                            width: "20px",
                            height: "20px",
                        }}
                    /> */}
                </div>
            ) : (
                <div className={showCross ? "text-danger" : ""}>
                    {showCross ? <FontAwesomeIcon icon="times" /> : "-"}
                </div>
            )}
        </div>
    );
};

export default BooleanTickCrossIcon;
