import arrayMove from "array-move";
import CareScopeTemplateFieldRow from "./CareScopeTemplateFieldRow";
import CareScopeTemplateSurchargeFieldGroupContainer from "./CareScopeTemplateSurchargeFieldGroupContainer";
import CareScopeTemplateSurchargeFieldRow from "./CareScopeTemplateSurchargeFieldRow";
import { AppIconTextButton } from "components/Buttons";
import { AppFieldsWrapperContainer } from "components/Containers";
import { AppSortable } from "components/Sortable";
import { defaultTo } from "lodash-es";
import {
    CareScopeTemplateField,
    CareScopeTemplateFieldType,
    CareScopeTemplateFieldTypeManager,
    CareScopeTemplateSurchargeField,
    CareScopeTemplateSurchargeFieldGroup,
} from "models";
import { ValidityState } from "models/general";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SortableElement } from "react-sortable-hoc";

export const getSortableItemForType = (fieldType: CareScopeTemplateFieldType) =>
    fieldType == CareScopeTemplateFieldType.MAIN
        ? SortableElement(CareScopeTemplateFieldRow)
        : fieldType == CareScopeTemplateFieldType.SURCHARGE_GROUP
        ? SortableElement(CareScopeTemplateSurchargeFieldGroupContainer)
        : SortableElement(CareScopeTemplateSurchargeFieldRow);

export interface CareScopeTemplateFieldsContainerProps<
    T extends
        | CareScopeTemplateField
        | CareScopeTemplateSurchargeField
        | CareScopeTemplateSurchargeFieldGroup
> {
    fields: T[];
    onChange: (newFields: T[]) => void;
    readonly?: boolean;
    groupUuid?: string; // only for SurchargeField container
    fieldType: CareScopeTemplateFieldType;
    validityState?: ValidityState;
    locationSettingId?: string;
    businessId?: string;
}

export const CareScopeTemplateFieldsContainer = <
    T extends
        | CareScopeTemplateField
        | CareScopeTemplateSurchargeField
        | CareScopeTemplateSurchargeFieldGroup
>({
    fieldType,
    fields,
    onChange,
    readonly = false,
    groupUuid,
    validityState,
}: CareScopeTemplateFieldsContainerProps<T>) => {
    const { t } = useTranslation();

    const { fieldManager, SortableItem } = useMemo(() => {
        return {
            fieldManager: CareScopeTemplateFieldTypeManager[fieldType],
            SortableItem: getSortableItemForType(fieldType),
        };
    }, [fieldType]);

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (!readonly) {
            onChange(arrayMove(fields, oldIndex, newIndex));
        }
    };

    return (
        <AppFieldsWrapperContainer
            title={t(
                fieldType == CareScopeTemplateFieldType.MAIN
                    ? "careScopeTemplate.title"
                    : fieldType == CareScopeTemplateFieldType.SURCHARGE_GROUP
                    ? "careScopeTemplate.surchargeGroups"
                    : "careScopeTemplate.surcharges"
            )}
            isCollapsible={true}
        >
            <>
                {(!readonly || defaultTo(fields, []).length == 0) && (
                    <AppIconTextButton
                        text={t("common.addNew")}
                        icon={"plus"}
                        padding="med"
                        style={{ maxWidth: "180px", textAlign: "start" }}
                        disabled={readonly}
                        onClick={() => {
                            onChange([
                                ...fields,
                                fieldManager.defaultValue(
                                    fieldType ==
                                        CareScopeTemplateFieldType.SURCHARGE
                                        ? groupUuid
                                        : undefined
                                ) as T,
                            ]);
                        }}
                    />
                )}

                {fields && (
                    <AppSortable<T>
                        SortableItem={SortableItem}
                        list={fields}
                        handleSortEnd={handleSortEnd}
                        validityState={validityState}
                        readonly={readonly}
                        onChange={(field) => {
                            const oldIndex = fields.findIndex(
                                (f) => f.Uuid == field.Uuid
                            );
                            if (oldIndex >= 0) {
                                onChange(
                                    fields.map((nf) => {
                                        if (nf.Uuid == field.Uuid) {
                                            return field;
                                        } else {
                                            return nf;
                                        }
                                    })
                                );
                            } else {
                                onChange([...fields, field]);
                            }
                        }}
                        onDelete={(uuid: string) => {
                            onChange(fields.filter((f) => f.Uuid != uuid));
                        }}
                    />
                )}
            </>
        </AppFieldsWrapperContainer>
    );
};

export default CareScopeTemplateFieldsContainer;
