import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import { BusinessKonto } from "models/konto";

export interface IBusinessKontoService {
    list(): Promise<AppResponse<BusinessKonto[]>>;
    get(id: string): Promise<AppResponse<BusinessKonto>>;
    createUpdate(model: BusinessKonto): Promise<AppResponse<BusinessKonto>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.currentBusiness().konto;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessKontoServiceKey(
    name: keyof IBusinessKontoService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessKontoService_${name}_${JSON.stringify(data)}`;
}

export class BusinessKontoService
    extends BaseService
    implements IBusinessKontoService
{
    public kontoRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.kontoRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<BusinessKonto[]>> {
        return this.doServerXHR<BusinessKonto[]>({
            url: this.kontoRoutes.list(),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<BusinessKonto>> {
        return this.doServerXHR<BusinessKonto>({
            url: this.kontoRoutes.get(id),
            method: "get",
        });
    }

    createUpdate(model: BusinessKonto): Promise<AppResponse<BusinessKonto>> {
        return this.doServerXHR<BusinessKonto>({
            url: this.kontoRoutes.createUpdate(),
            method: "post",
            data: model,
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.kontoRoutes.delete(id),
            method: "delete",
        });
    }
}

export default BusinessKontoService;
