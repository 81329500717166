import AppContentHeader from "components/AppContentHeader";
import IFrameContent from "components/IFrameContent";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { EmployeeType, getEmployeeTypeFromPath } from "models";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import styles from "./BusinessEmployeeCreate.module.scss";

export const BusinessEmployeeCreate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { urlForEmployeeCreate } = useIFrameRouting();
    const { linkProvider } = useRouting();

    const { employeeType: employeeTypeParams } = useParams();
    const employeeType = getEmployeeTypeFromPath(employeeTypeParams as string);

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t(
                    `employee.createTitle.${defaultTo(
                        employeeTypeParams,
                        "employees"
                    ).toLowerCase()}`
                )}
                classNameForIcon="pe-7s-user"
                hasGoBack={true}
                backUrl={linkProvider.business.employees().screens.list()}
            />
            <IFrameContent
                contentUrl={urlForEmployeeCreate(employeeType)}
                onRedirect={(encodedId) => {
                    // wait for small time for data to be refetched
                    setTimeout(() => {
                        navigate(
                            linkProvider.business
                                .employees()
                                .screens.withId(encodedId)
                                .contract.list()
                        );
                    }, 500);
                }}
            />
        </div>
    );
};

export default BusinessEmployeeCreate;
