import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppSwitch, ErrorMessage } from "components";
import { AppColoredMultiSelect } from "components/AppSelect";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppLoader } from "components/Loaders";
import {
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { useAppContext } from "hoc/providers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import {
    AppLocale,
    CustomColorItemOption,
    LocaleEnumToTranslationKey,
} from "models/general";
import { ErrorInfo } from "models/general/validityState";
import {
    LocationModulePlan,
    ModulePlanLocale,
    ModulePlans,
} from "models/modulePlans";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    getLocationSettingModulePlanServiceKey,
    LocationSettingModulePlanService,
} from "services/lillywait/locationSettings";
import { useCurrentLocationSettingsContext } from "../../LocationSettingTabs";
import LocationSettingModulePlanContentDialog from "./LocationSettingModulePlanContentDialog";
import styles from "./LocationSettingModulePlanDialog.module.scss";

export interface LocationSettingModulePlanDialogProps {
    editId?: string;
    dialogOpen: boolean;
    readonly: boolean;
    hasDeletePermission: boolean;
    onChange: (modulePlan?: LocationModulePlan, close?: boolean) => void;
    onDeleteSuccess: () => void;
}

interface LocationSettingModulePlanDialogState extends LocationModulePlan {
    openDialog?: boolean;
    currentLocale?: string;
}

export const LocationSettingModulePlanDialog: React.FC<
    LocationSettingModulePlanDialogProps
> = ({
    editId,
    dialogOpen,
    readonly,
    hasDeletePermission,
    onChange: onChangeParent,
    onDeleteSuccess,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const currentLocation = useCurrentLocationSettingsContext();
    const { locale } = useAppContext();

    const [state, setState] = useState<LocationSettingModulePlanDialogState>({
        Id: 0,
        Modules: [] as ModulePlans[],
        openDialog: false,
        IsBasic: false,
        LocationSettingId: currentLocation.dataItem?.Id,
    });

    const isDefaultSelected =
        state.LocaleContents &&
        state.LocaleContents.filter((x) => x.IsDefault).length > 0;

    const isStateValid = useMemo(() => {
        return !(
            state.LocaleContents &&
            state.LocaleContents.length > 0 &&
            (!isDefaultSelected ||
                state.LocaleContents.filter((x) => !x.UseDefault && !x.Content)
                    .length > 0)
        );
    }, [state.LocaleContents]);

    const multiSelectOptions = Object.keys(ModulePlans)
        // .filter((x) => state.IsBasic || x != ModulePlans.Basic)
        .map((x) => {
            return {
                label: t(
                    `locationSettings.modulePlan.module.${x.toLowerCase()}`
                ),
                value: x,
            } as CustomColorItemOption;
        });

    const onChange = (localContent: ModulePlanLocale) => {
        if (state.LocaleContents) {
            const isDefaultChanged =
                localContent.IsDefault &&
                !state.LocaleContents.filter(
                    (x) => x.Locale == localContent.Locale
                )[0].IsDefault;

            setState({
                ...state,
                LocaleContents: state.LocaleContents?.map((x) => {
                    if (x.Locale == localContent.Locale) {
                        return localContent;
                    }
                    return {
                        ...x,
                        IsDefault: isDefaultChanged ? false : x.IsDefault,
                    };
                }),
            });
        }
    };

    const onClick = (loc: string) => {
        setState({
            ...state,
            openDialog: true,
            currentLocale: loc,
        });
    };

    const locationSettingModulePlanService =
        new LocationSettingModulePlanService(
            linkProvider.lillywait.locationSettings().api.withId().modulePlans
        );

    const {
        isLoading: createUpdateLoading,
        data: createUpdateRes,
        mutate: createUpdate,
    } = useMutation(
        async () => await locationSettingModulePlanService.createUpdate(state),
        {
            onSuccess: (locModulePlan) => {
                if (locModulePlan?.Data) {
                    onChangeParent(locModulePlan?.Data, !isNil(editId));
                }
            },
        }
    );

    const {
        isLoading: deleteLoading,
        data: deleteRes,
        mutate: deleteModule,
    } = useMutation(
        async () =>
            await locationSettingModulePlanService.delete(
                defaultTo(state.EncodedId, "")
            )
    );

    useEffect(() => {
        if (!deleteLoading && deleteRes) {
            if (deleteRes.Data) {
                showSweetAlertInfo(
                    t("common.success"),
                    t(`common.delete.success`),
                    "success"
                );
                onDeleteSuccess();
            } else if (deleteRes.Message) {
                showSweetAlertToast(
                    t("common.error.error"),
                    deleteRes.Message,
                    "error"
                );
            }
        }
    }, [deleteLoading]);

    const { isRefetching: getLoading, data: modulePlanRes } = useQuery(
        getLocationSettingModulePlanServiceKey("get", {
            id: editId,
            locationId: currentLocation?.dataItem?.Id,
        }),
        async () => {
            if (editId) {
                return await locationSettingModulePlanService.get(editId);
            }
        }
    );

    useEffect(() => {
        if (modulePlanRes) {
            setState(modulePlanRes?.Data);
        }
    }, [modulePlanRes]);

    const activeContent = useMemo(() => {
        const defaultContent = state.LocaleContents?.filter(
            (x) => x.IsDefault
        )[0];
        const currentContent = state.LocaleContents?.filter(
            (x) => x.Locale == locale
        )[0];

        return currentContent?.UseDefault
            ? defaultContent?.Content
            : currentContent?.Content;
    }, [state, locale, modulePlanRes]);

    return state.openDialog && state.LocaleContents && state.currentLocale ? (
        <LocationSettingModulePlanContentDialog
            currentLocale={state.currentLocale}
            value={
                state.LocaleContents?.filter(
                    (x) => x.Locale == state.currentLocale
                )[0].Content
            }
            dialogOpen={state.openDialog}
            onDialogClose={() =>
                setState({
                    ...state,
                    openDialog: false,
                })
            }
            onSave={(content) => {
                setState({
                    ...state,
                    openDialog: false,
                    LocaleContents: state.LocaleContents?.map((x) => {
                        if (x.Locale == state.currentLocale) {
                            return {
                                ...x,
                                UseDefault: false,
                                Content: content,
                            };
                        }
                        return x;
                    }),
                });
            }}
            readonly={readonly}
        />
    ) : (
        <AppDialog
            title={defaultTo(
                activeContent?.Title,
                t(
                    `locationSettings.modulePlan.${
                        state.Id > 0 ? "editPackage" : "createNewPackage"
                    }`
                )
            )}
            modalOpen={dialogOpen}
            onClose={() => onChangeParent(createUpdateRes?.Data, true)}
            keyboard={false}
            size={"lg"}
            footer={
                <AppDialogFooter
                    disableSaveButton={
                        getLoading || createUpdateLoading || !isStateValid
                    }
                    disableCancelButton={createUpdateLoading}
                    disableDeleteButton={createUpdateLoading}
                    onDialogClose={() =>
                        onChangeParent(createUpdateRes?.Data, true)
                    }
                    onClickSaveButton={() => createUpdate()}
                    showDeleteBtn={
                        state.LocaleContents &&
                        state.LocaleContents.length > 0 &&
                        !state.IsBasic &&
                        hasDeletePermission
                    }
                    onClickDeleteButton={() => deleteModule()}
                />
            }
        >
            <div
                style={{ position: "relative" }}
                className="d-flex flex-column"
            >
                {getLoading || (createUpdateLoading && isNil(editId)) ? (
                    <AppLoader
                        useLargeLoader={
                            state.LocaleContents &&
                            state.LocaleContents.length > 0
                        }
                    />
                ) : (
                    <>
                        <div>
                            <AppColoredMultiSelect
                                isReadonly={readonly}
                                value={multiSelectOptions.filter(
                                    (x) =>
                                        state.Modules &&
                                        state.Modules.includes(
                                            x.value as ModulePlans
                                        )
                                )}
                                useOldUi={true}
                                onChange={(value) => {
                                    const newVal = value
                                        .filter((x) => x.value != null)
                                        .map((x) => x.value as ModulePlans);
                                    if (
                                        state.IsBasic &&
                                        !newVal.includes(ModulePlans.Basic)
                                    ) {
                                        newVal.push(ModulePlans.Basic);
                                    }
                                    setState({
                                        ...state,
                                        Modules:
                                            defaultTo(newVal, []).length == 0
                                                ? multiSelectOptions.map(
                                                      (x) =>
                                                          x.value as ModulePlans
                                                  ) // all selected
                                                : newVal,
                                    });
                                }}
                                data={multiSelectOptions}
                            />
                        </div>
                        {state.LocaleContents &&
                            state.LocaleContents.length > 0 && (
                                <div className={styles.localeOuter}>
                                    {state.LocaleContents?.map((x, idx) => (
                                        <div
                                            key={idx}
                                            className={styles.localeItem}
                                        >
                                            <div
                                                className={styles.localeName}
                                                onClick={() =>
                                                    onClick(x.Locale)
                                                }
                                            >
                                                <span>
                                                    {t(
                                                        `common.language.${
                                                            LocaleEnumToTranslationKey[
                                                                x.Locale as AppLocale
                                                            ]
                                                        }`
                                                    )}
                                                </span>
                                                <ErrorMessage
                                                    showEmpty={true}
                                                    errorInfo={
                                                        x.UseDefault ||
                                                        (x.Content &&
                                                            !isNil(
                                                                x.Content.Title
                                                            ) &&
                                                            x.Content.Title
                                                                .length > 0)
                                                            ? undefined
                                                            : ({
                                                                  message: t(
                                                                      "locationSettings.modulePlan.enterLocaleContent"
                                                                  ),
                                                              } as ErrorInfo)
                                                    }
                                                />
                                            </div>
                                            <AppSwitch
                                                id={`isDefault${x.Locale}`}
                                                className={
                                                    styles.isDefaultSwitch
                                                }
                                                label={t(
                                                    "locationSettings.modulePlan.isDefault"
                                                )}
                                                value={x.IsDefault}
                                                onChange={(checked) =>
                                                    onChange({
                                                        ...x,
                                                        IsDefault: checked,
                                                        UseDefault:
                                                            checked == true
                                                                ? false
                                                                : x.UseDefault,
                                                    })
                                                }
                                            />
                                            <AppSwitch
                                                id={`useDefault${x.Locale}`}
                                                className={
                                                    styles.useDefaultCheckbox
                                                }
                                                label={t(
                                                    "locationSettings.modulePlan.useDefault"
                                                )}
                                                disabled={x.IsDefault}
                                                value={x.UseDefault}
                                                onChange={(checked) =>
                                                    onChange({
                                                        ...x,
                                                        UseDefault: checked,
                                                    })
                                                }
                                            />
                                            <div
                                                className={styles.angleIcon}
                                                onClick={() =>
                                                    onClick(x.Locale)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={"angle-right"}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </>
                )}
            </div>
        </AppDialog>
    );
};

export default LocationSettingModulePlanDialog;
