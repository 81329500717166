import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { MetisMenu } from "components";
import { useAuthenticatedPageContext } from "hoc/providers";
import { useAppSidebarMenu } from "hooks/layout/sidebar/useAppSidebarMenu";
import useCheckPermission from "hooks/permissionCheck/useCheckPermission";
import { isNil } from "lodash-es";
import { AppLayoutThemes } from "models/general/enum";
import { MetisMenuContentType } from "models/metisMenu";
import {
    BusinessAdminPermissions,
    KindergartenAdminPermissions,
    LWAdminPermissions,
    LWSupportPermissions,
} from "models/permissionManagement";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./AppSidebar.module.scss";

export const AppSidebar = React.memo(() => {
    const { t } = useTranslation();
    const { userAccessInfo } = useCheckPermission();
    const { getSideBarMenu } = useAppSidebarMenu();
    const { pagePermissions } = useAuthenticatedPageContext();
    const { isSupportViewEnabled } = userAccessInfo();
    const [state, setState] = useState<{
        main: MetisMenuContentType[];
        bottom: MetisMenuContentType[];
    }>({
        main: [],
        bottom: [],
    });
    const [menuType, setMenuType] = useState<{
        isAdminMenu: boolean;
        isSupportViewMenu: boolean;
        isGeneralMenu: boolean;
    }>({
        isAdminMenu: false,
        isSupportViewMenu: false,
        isGeneralMenu: false,
    });
    useEffect(() => {
        if (pagePermissions) {
            const isAdminMenu = [
                ...BusinessAdminPermissions,
                ...KindergartenAdminPermissions,
                ...LWAdminPermissions,
            ].some((x) => pagePermissions.some((y) => x === y));
            const isSupportViewMenu = LWSupportPermissions.some((x) =>
                pagePermissions.some((y) => x === y)
            );
            const isGeneralMenu = !isAdminMenu && !isSupportViewMenu;

            setMenuType((old) => {
                if (
                    isAdminMenu === old.isAdminMenu &&
                    isSupportViewMenu === old.isSupportViewMenu &&
                    isGeneralMenu === old.isGeneralMenu
                ) {
                    // no change
                    return old;
                }
                return {
                    isAdminMenu: isAdminMenu,
                    isSupportViewMenu: isSupportViewMenu,
                    isGeneralMenu: isGeneralMenu,
                };
            });
        }
    }, [pagePermissions]);

    useEffect(() => {
        if (!isNil(pagePermissions)) {
            const { bottomMenu, mainMenu } = getSideBarMenu(
                menuType.isAdminMenu,
                menuType.isSupportViewMenu
            );

            setState({ main: mainMenu, bottom: bottomMenu });
        }
    }, [menuType, t]);

    const appTheme = menuType.isAdminMenu
        ? AppLayoutThemes.ADMIN
        : menuType.isSupportViewMenu || isSupportViewEnabled
        ? AppLayoutThemes.SUPPORT_VIEW
        : AppLayoutThemes.DEFAULT;

    return (
        <>
            {/* @ts-ignore */}
            <TransitionGroup className="app-sidebar sidebar-shadow">
                {/* @ts-ignore */}
                <CSSTransition
                    classNames="SidebarAnimation"
                    in={true}
                    timeout={1500}
                    appear={true}
                >
                    <div className={`app-sidebar__inner ${styles.main}`}>
                        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                            <h5 className={`app-sidebar__heading_${appTheme}`}>
                                {t("sidebar.menu")}
                            </h5>
                            <MetisMenu
                                content={state.main}
                                className={`app-theme-${appTheme}`}
                            />
                        </div>
                        {/* bottom menu */}
                        <div style={{ marginBottom: "-25px" }}>
                            <MetisMenu
                                showActiveLink={false}
                                content={state.bottom}
                                classItem="app-theme-support" // default theme
                            />
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
});

export default AppSidebar;
