import ImageAssets from "globals/images/common";
import { FieldType } from "models";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppTooltip from "./AppTooltip";
import styles from "./ValidationTooltip.module.scss";
interface ValidationTooltipProps {
    type?: FieldType;
    trigger?: any;
}

export function ValidationTooltip(props: ValidationTooltipProps) {
    const { t } = useTranslation();

    const {
        type = FieldType.PASSWORD,
        trigger = (
            <Image
                className={styles.tooltipIcon}
                src={ImageAssets.common.questionCircleBlue}
            />
        ),
    } = props;

    const title =
        type === FieldType.PASSWORD
            ? t("common.passwordCriteria.title")
            : t("common.emailCriteria.title");
    const content = (
        <div className="tippy-custom-popover">
            <div className="header">
                <h4>{title}</h4>
            </div>
            <div>
                <ul className="popover-ul">
                    {type === FieldType.PASSWORD ? (
                        <>
                            <li>{t("common.passwordCriteria.criteria1")}</li>
                            <li>{t("common.passwordCriteria.criteria2")}</li>
                            <li>{t("common.passwordCriteria.criteria3")}</li>
                            <li>{t("common.passwordCriteria.criteria4")}</li>
                            <li>{t("common.passwordCriteria.criteria5")}</li>
                        </>
                    ) : (
                        <>
                            <li>{t("common.emailCriteria.criteria1")}</li>
                            <li>{t("common.emailCriteria.criteria2")}</li>
                            <li>{t("common.emailCriteria.criteria3")}</li>
                        </>
                    )}
                </ul>
            </div>
        </div>
    );

    return (
        <AppTooltip
            content={content}
            allowHtml={true}
            trigger="mouseenter focus"
            arrow={true}
            maxWidth="400px"
        >
            {trigger}
        </AppTooltip>
    );
}

export default ValidationTooltip;
