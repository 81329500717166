import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { EmployeeShift } from "./model";

export function validateEmployeeShift(
    shift: EmployeeShift,
    state = getInitializedValidityState([], [])
) {
    const valueValidationRules: ValidationRules<keyof EmployeeShift> = {
        Type: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("employee.shift.type.missing"),
            },
        ],
        ShiftId: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("employee.shift.shift.missing"),
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        shift,
        state
    );

    if (shift.Days && shift.Days.length === 0) {
        let mngr = new ValidityStateManager(state);
        mngr = mngr.addErrorInfo("Days", {
            code: "Days",
            message: i18next.t("employee.shift.days.missing"),
        });
        state = mngr.state;
    }
    return state;
}
