import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetisMenuContentType } from "models/metisMenu";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { BusinessType } from "models/business";
import { GeneralUserProfileUpdate } from "models/permissionManagement/permissionConstants";
import routesForContext from "routing/AppRoutes";
import { businessRoutesMap } from "routing/routes/business/routesMap";
import { locationSettingRoutesMap } from "routing/routes/lillywait/locationSetting/routesMap";
import { lillywaitRoutesMap } from "routing/routes/lillywait/routesMap";
import { ImageAssets } from "globals/images";
import { Image } from "react-bootstrap";

const routesTemp = routesForContext();
export const adminNav = (
    linkProvider: typeof routesTemp,
    relativePathProvider: typeof routesTemp,
    businessType: BusinessType,
    hasNoAccess: boolean
): MetisMenuContentType[] => {
    const isLw = businessType === BusinessType.LW;
    const isKindergarten = businessType === BusinessType.KINDERGARTEN;
    return hasNoAccess
        ? [
              {
                  label: t("sidebar.editProfile"),
                  icon: <FontAwesomeIcon icon={"user-cog"} />,
                  to: linkProvider.authUser.screens.editProfile(),
                  permissions: [GeneralUserProfileUpdate],
              },
          ]
        : [
              {
                  iconClass: "lnr-apartment",
                  label: t("sidebar.company"),
                  to: linkProvider.business.screens.admin.companies(),
                  activeIfPatternLike: ["admin/branches", "/settings/"],
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.admin.companies()
                      ].permKeys,
                      []
                  ),
              },
              ...(isLw
                  ? [
                        {
                            iconClass: "lnr-map-marker",
                            label: t("sidebar.locationSetting"),
                            activeIfPatternLike: "location-settings",
                            to: linkProvider.lillywait.admin
                                .locationSetting()
                                .screens.list(),
                            permissions: defaultTo(
                                locationSettingRoutesMap[
                                    relativePathProvider.lillywait.admin
                                        .locationSetting()
                                        .screens.list()
                                ].permKeys,
                                []
                            ),
                        },
                        {
                            iconClass: "pe-7s-plus",
                            activeIfPatternLike: "registration-requests",
                            label: t("sidebar.createClients"),
                            to: linkProvider.lillywait.admin.registrationRequests.list(),
                            permissions: defaultTo(
                                lillywaitRoutesMap[
                                    relativePathProvider.lillywait.admin.registrationRequests.list()
                                ].permKeys,
                                []
                            ),
                        },
                    ]
                  : []),
              {
                  iconClass: "pe-7s-copy-file",
                  label: t("sidebar.documentManager.title"),
                  activeIfPatternLike: ["admin/documents", "admin/letterheads"],
                  subMenu: [
                      {
                          label: t("sidebar.documentManager.templates"),
                          to: linkProvider.business.screens.admin.documentManager.list(),
                          activeIfPatternLike: ["admin/documents"],
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.admin.documentManager.list()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t(
                              "sidebar.documentManager.letterHeadsAndFooter"
                          ),
                          activeIfPatternLike: ["admin/letterheads"],
                          to: linkProvider.business.screens.admin.letterheadsManager.list(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.admin.letterheadsManager.list()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              {
                  iconClass: "pe-7s-folder",
                  label: t("sidebar.archive"),
                  activeIfPatternLike: ["archive"],
                  to: linkProvider.business.screens.admin.archiveManager(),
                  permissions: defaultTo(
                      businessRoutesMap[
                          relativePathProvider.business.screens.admin.archiveManager()
                      ].permKeys,
                      []
                  ),
              },
              //   ...(isKindergarten
              //       ? [
              //             {
              //                 iconClass: "pe-7s-mail",
              //                 label: t("sidebar.emailManager"),
              //                 activeIfPatternLike: "email-manager",
              //                 to: linkProvider.business.screens.admin.emailManager.list(),
              //                 permissions: defaultTo(
              //                     businessRoutesMap[
              //                         relativePathProvider.business.screens.admin.emailManager.list()
              //                     ].permKeys,
              //                     []
              //                 ),
              //             },
              //         ]
              //       : []),
              {
                  iconClass: "pe-7s-user",
                  label: t("sidebar.roleManagement.title"),
                  activeIfPatternLike: ["admin/users", "admin/roles"],
                  subMenu: [
                      {
                          label: t("sidebar.roleManagement.users"),
                          to: linkProvider.business.screens.admin.usersManagement.list(),
                          activeIfPatternLike: "admin/users",
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.admin.usersManagement.list()
                              ].permKeys,
                              []
                          ),
                      },
                      {
                          label: t("sidebar.roleManagement.permissions"),
                          activeIfPatternLike: "admin/roles",
                          to: linkProvider.business.screens.admin.rolesManagement.list(),
                          permissions: defaultTo(
                              businessRoutesMap[
                                  relativePathProvider.business.screens.admin.rolesManagement.list()
                              ].permKeys,
                              []
                          ),
                      },
                  ],
              },
              ...(!isLw
                  ? [
                        {
                            iconClass: "pe-7s-box2",
                            label: t("sidebar.packages"),
                            to: linkProvider.business.screens.admin.selectModules(),
                            permissions: defaultTo(
                                businessRoutesMap[
                                    relativePathProvider.business.screens.admin.selectModules()
                                ].permKeys,
                                []
                            ),
                        },
                    ]
                  : []),
          ];
};
