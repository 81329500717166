import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { getInitializedValidityState } from "models/general";
import { BankData } from "./model";

export function validateBankData(
    shift: BankData,
    state = getInitializedValidityState([], [])
) {
    const valueValidationRules: ValidationRules<keyof BankData> = {
        IBAN: [
            {
                rule: Validations.REQUIRED,
            },
        ],
        BIC: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        shift,
        state
    );

    return state;
}
