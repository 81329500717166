import { BankDataCreateEdit } from "commonPartials";
import { AppLoader } from "components/Loaders";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveKindergartenClient } from "screens/kindergarten/clients/tabs/KindergartenClientTabs";

export const ClientBankDataCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const { dataItem: client } = useActiveKindergartenClient();
    const linkProviderBase = linkProvider.kindergarten
        .clients()
        .screens.client.withId().bankData;

    return isNil(client) ? (
        <AppLoader />
    ) : (
        <BankDataCreateEdit
            onBack={() => navigate(linkProviderBase.list())}
            request={{ ClientId: client.Id as number }}
        />
    );
};

export default ClientBankDataCreate;
