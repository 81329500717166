import { AppTooltip } from "components";
import { AppDeleteButton } from "components/Buttons";
import {
    ellipseTextUsingLength,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import { BusinessShift } from "models/businessShifts";
import React from "react";
import { useTranslation } from "react-i18next";

export interface BusinessShiftTableRowProps {
    hasDeletePermission: boolean;
    value: BusinessShift;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

export const BusinessShiftTableRow: React.FC<BusinessShiftTableRowProps> = ({
    hasDeletePermission,
    value,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale, getTimeFormatForLocale } =
        useLocaleHelpers();

    const areas = defaultTo(
        value.Areas && value.Areas.map((x) => x.Name).join(", "),
        ""
    );

    const nameEllipse = ellipseTextUsingLength(value.Name, 25);
    const areasEllipse = ellipseTextUsingLength(areas, 30);

    return (
        <tr
            onClick={() => {
                if (value.EncodedId) {
                    onClick(value.EncodedId);
                } else {
                    onClick(defaultTo(value.Id, 0).toString());
                }
            }}
        >
            <td
                style={{ paddingLeft: "10px" }}
                className={"ellipsis-text single-line"}
            >
                {nameEllipse.length == value.Name.length ? (
                    <span>{value.Name}</span>
                ) : (
                    <AppTooltip
                        allowHtml={false}
                        trigger="mouseenter focus"
                        arrow={true}
                        content={value.Name}
                    >
                        <span>{nameEllipse}</span>
                    </AppTooltip>
                )}
            </td>
            <td>{value.StartTime.format(getTimeFormatForLocale())}</td>
            <td>{value.EndTime.format(getTimeFormatForLocale())}</td>
            <td className={"ellipsis-text single-line"}>
                {areasEllipse.length == areas.length ? (
                    <span>{areas}</span>
                ) : (
                    <AppTooltip
                        allowHtml={false}
                        trigger="mouseenter focus"
                        arrow={true}
                        content={areas}
                    >
                        <span className={"ellipsis-text single-line"}>
                            {areasEllipse}
                        </span>
                    </AppTooltip>
                )}
            </td>
            <td>{value.StartDate.format(getDateFormatForLocale())}</td>
            <td>
                {value.EndDate
                    ? value.EndDate.format(getDateFormatForLocale())
                    : t("common.endDate.hasNoEndDate")}
            </td>
            <td style={getFixedCssWidths(60)}>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.Name}
                    readonly={!hasDeletePermission}
                    onClick={() => {
                        if (value.EncodedId) {
                            onDeleteBtnClick(value.EncodedId);
                        } else {
                            onDeleteBtnClick(defaultTo(value.Id, 0).toString());
                        }
                    }}
                />
            </td>
        </tr>
    );
};

export default BusinessShiftTableRow;
