import AppContentHeader from "components/AppContentHeader";
import { AppTabButtonProps, AppTabButtons } from "components/Tabs";
import { dashboardImages } from "globals/images/dashboard";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import {
    Business_Dashboard_Client,
    Business_Dashboard_Employee,
    DashboardTabs,
} from "models";
import { BusinessType } from "models/business";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DashboardHeader.module.scss";

interface DashboardHeaderProps {
    onTabChangeHandler: (tab: DashboardTabs) => void;
    selectedTab: DashboardTabs;
    businessType: BusinessType;
    loading?: boolean;
}
export const DashboardHeader = React.memo<DashboardHeaderProps>(
    ({ onTabChangeHandler, selectedTab, businessType, loading = false }) => {
        const { t } = useTranslation();
        const { checkPermission } = useCheckPermission();

        const buttonsList = useMemo(() => {
            const hasClientViewPermission = checkPermission(
                Business_Dashboard_Client
            );
            const hasEmployeeViewPermission = checkPermission(
                Business_Dashboard_Employee
            );

            return [
                ...(hasClientViewPermission &&
                businessType === BusinessType.KINDERGARTEN
                    ? [
                          {
                              label: t("common.kindergarten.clients"),
                              onClick: () =>
                                  onTabChangeHandler(DashboardTabs.CLIENTS),
                              active: selectedTab === DashboardTabs.CLIENTS,
                          } as AppTabButtonProps,
                      ]
                    : []),
                ...(hasEmployeeViewPermission === true
                    ? [
                          {
                              label: t("common.employees"),
                              onClick: () =>
                                  onTabChangeHandler(DashboardTabs.EMPLOYEES),
                              active: selectedTab === DashboardTabs.EMPLOYEES,
                          } as AppTabButtonProps,
                      ]
                    : []),
            ];
        }, [checkPermission, t, selectedTab]);
        return (
            <>
                <AppContentHeader
                    title={t("dashboard.overview")}
                    icon={dashboardImages.dashboard}
                >
                    <div>
                        <AppTabButtons
                            loading={loading}
                            btnClass={styles.tabButton}
                            tabButtons={buttonsList}
                        />
                    </div>
                    <div
                        className={"d-none d-xl-block"}
                        style={{ width: "10%" }}
                    />
                </AppContentHeader>
            </>
        );
    }
);

export default DashboardHeader;
