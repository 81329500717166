import ImageAssets from "globals/images/common";
import React from "react";
import { Image } from "react-bootstrap";
import styles from "./AppIconWithName.module.scss";

export interface AppIconWithNameProps {
    text: string;
    className?: string;
    showIcon?: boolean;
    iconClass?: string;
    iconPath?: string;
    textEllipseClass?: string;
}

export const AppIconWithName: React.FC<AppIconWithNameProps> = ({
    text,
    className = "",
    showIcon = true,
    iconClass = "",
    iconPath = ImageAssets.common.userOutlineGray,
    textEllipseClass = "",
}) => {
    return (
        <div className={`${className} ${styles.userIconName}`}>
            {showIcon && (
                <span className={` ${iconClass} ${styles.userIconSpan}`}>
                    <Image className={styles.userIcon} src={iconPath} />
                </span>
            )}
            <p className={`${textEllipseClass} ${styles.textEllipse}`}>
                {text}
            </p>
        </div>
    );
};

export default AppIconWithName;
