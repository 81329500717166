import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    BusinessShiftPlanDataResponse,
    EmployeeShiftAssignment,
    EmployeeShiftAssignmentResponse,
    ShiftPlanOptionsRequest,
    ShiftPlanRequest,
} from "models/shiftPlan";
import { BusinessShiftResponse } from "models";
import { ISO8601_DATE_FORMAT } from "globals/constants";

export interface IShiftPlanService {
    getWeeklyShiftPlan: (
        reqParams: ShiftPlanRequest
    ) => Promise<AppResponse<BusinessShiftPlanDataResponse>>;
    getWeeklyShiftPlanPDF: (
        reqParams: ShiftPlanRequest
    ) => Promise<AppResponse<string>>;
    createWeeklyShiftPlan: (
        reqParams: ShiftPlanRequest
    ) => Promise<AppResponse<boolean>>;
    deleteWeeklyShiftPlan: (
        reqParams: ShiftPlanRequest,
        password: string
    ) => Promise<AppResponse<boolean>>;
    addUpdateShiftAssignment: (
        reqParams: EmployeeShiftAssignment
    ) => Promise<AppResponse<EmployeeShiftAssignmentResponse>>;
    deleteShiftAssignment: (id: number) => Promise<AppResponse<boolean>>;
    fetchShiftOptionsForEmployee: (
        req: ShiftPlanOptionsRequest
    ) => Promise<AppResponse<BusinessShiftResponse[]>>;
}

const apiLinkProvider = routesForContext().business.api.shiftPlan;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getShiftPlanServiceKey(
    name: keyof IShiftPlanService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `shiftPlan_${name}_${JSON.stringify(data)}`;
}
export class ShiftPlanService extends BaseService implements IShiftPlanService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    getWeeklyShiftPlan(
        reqParams: ShiftPlanRequest
    ): Promise<AppResponse<BusinessShiftPlanDataResponse>> {
        return this.doServerXHR<AppResponse<BusinessShiftPlanDataResponse>>({
            url: this.routes.getWeeklyShiftPlan(reqParams),
            method: "get",
        });
    }
    getWeeklyShiftPlanPDF(
        reqParams: ShiftPlanRequest
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<AppResponse<string>>({
            url: this.routes.getWeeklyShiftPlanPDF(reqParams),
            method: "get",
        });
    }
    createWeeklyShiftPlan(
        reqParams: ShiftPlanRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.routes.createWeeklyShiftPlan(reqParams),
            method: "post",
        });
    }
    deleteWeeklyShiftPlan(
        reqParams: ShiftPlanRequest,
        password: string
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.routes.deleteWeeklyShiftPlan(reqParams),
            method: "delete",
            data: {
                Password: password,
            },
        });
    }

    addUpdateShiftAssignment(
        reqParams: EmployeeShiftAssignment
    ): Promise<AppResponse<EmployeeShiftAssignmentResponse>> {
        return this.doServerXHR<AppResponse<EmployeeShiftAssignmentResponse>>({
            url: this.routes.addUpdateShiftAssignment(),
            method: "post",
            data: {
                ...reqParams,
                Date: reqParams.Date.format(ISO8601_DATE_FORMAT),
            },
        });
    }
    deleteShiftAssignment(id: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.routes.deleteShiftAssignment(id),
            method: "delete",
        });
    }
    fetchShiftOptionsForEmployee(
        req: ShiftPlanOptionsRequest
    ): Promise<AppResponse<BusinessShiftResponse[]>> {
        return this.doServerXHR<AppResponse<BusinessShiftResponse[]>>({
            url: this.routes.getShiftOptions(req),
            method: "get",
        });
    }
}

export default ShiftPlanService;
