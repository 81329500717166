import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    KindergartenClientContractCopyRequest,
    KindergartenClientContractDetail,
    KindergartenClientContractsListResponse,
    KindergartenClientContractUpdateRequest,
} from "models";
import { ISO8601_DATE_FORMAT } from "globals/constants";

export interface IKindergartenClientContractService {
    contractsList(
        year: number
    ): Promise<AppResponse<KindergartenClientContractsListResponse>>;
    update(
        clientReq: KindergartenClientContractUpdateRequest
    ): Promise<AppResponse<KindergartenClientContractDetail>>;
    copy(
        reqParams: KindergartenClientContractCopyRequest,
        tillEnd: boolean // for copy-to-end
    ): Promise<AppResponse<boolean>>;
}

export function getKindergartenClientContractServiceKey(
    name: keyof IKindergartenClientContractService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `kindergartenClientContracts_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext()
    .kindergarten.clients()
    .api.withId().clientContracts;
type ApiLinkProviderType = typeof apiLinkProvider;

export class KindergartenClientContractService
    extends BaseService
    implements IKindergartenClientContractService
{
    public routes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    contractsList(
        year: number
    ): Promise<AppResponse<KindergartenClientContractsListResponse>> {
        return this.doServerXHR<
            AppResponse<KindergartenClientContractsListResponse>
        >({
            url: this.routes.list(year),
            method: "get",
        });
    }
    update(
        clientReq: KindergartenClientContractUpdateRequest
    ): Promise<AppResponse<KindergartenClientContractDetail>> {
        return this.doServerXHR<KindergartenClientContractDetail>({
            url: this.routes.update(clientReq.Id),
            method: "post",
            data: {
                ...clientReq,
                MonthYear: clientReq.MonthYear.format(ISO8601_DATE_FORMAT),
            },
        });
    }
    copy(
        val: KindergartenClientContractCopyRequest,
        tillEnd: boolean = false
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.copy(val.Id.toString(), tillEnd),
            method: "post",
            data: val,
        });
    }
}
