import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    BusinessEmployeeBenefit,
    Business_Payroll_AdditionalBenefits,
    EmployeeBenefitStatus,
    PermissionAccessTypes,
} from "models";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./EmployeeAdditionalBenefitRow.module.scss";

export interface EmployeeAdditionalBenefitRowContentProps {
    value: BusinessEmployeeBenefit;
    onDeleteClick: (id: string) => void;
}

export const EmployeeAdditionalBenefitRowContent: React.FC<
    EmployeeAdditionalBenefitRowContentProps
> = ({ value, onDeleteClick }) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale, getFormattedCurrency } = useLocaleHelpers();
    const { checkPermission } = useCheckPermission();
    const hasDeletePermission = checkPermission(
        Business_Payroll_AdditionalBenefits,
        [PermissionAccessTypes.DELETE]
    );

    /* specifying sizes here because it will be rendered in sub-table as well */
    return (
        <>
            <td style={getFixedCssWidths(110)}></td>
            <td style={getFixedCssWidths(300)}></td>
            <td style={getFixedCssWidths(180)}>
                {value.StartDate.format(getDateFormatForLocale())}
            </td>
            <td style={getFixedCssWidths(180)}>
                <span className={styles.textEllipse}>
                    {value.EndDate
                        ? value.EndDate.format(getDateFormatForLocale())
                        : t("common.endDate.hasNoEndDate")}
                </span>
            </td>
            <td style={getFixedCssWidths(190)}>
                {t(
                    `common.status.${
                        EmployeeBenefitStatus[Number(value.Status)]
                    }`
                )}
            </td>
            <td style={getFixedCssWidths(250)}>
                <span className={styles.textEllipse}>{value.Reason}</span>
            </td>
            <td style={getFixedCssWidths(150)}>
                {getFormattedCurrency(value.Amount)}
            </td>
            <td style={getFixedCssWidths(60)}>
                <AppDeleteButton
                    getConfirmation={true}
                    name={value.Reason}
                    readonly={!hasDeletePermission}
                    onClick={() => onDeleteClick(value.EncodedId)}
                />
            </td>
        </>
    );
};

export default EmployeeAdditionalBenefitRowContent;
