import { AppNumberInput } from "components/FormFields";
import { debounce, defaultTo } from "lodash-es";
import styles from "./KindergartenClientContractRow.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import { AppIconButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { t } from "i18next";
import { Optional } from "models/general";

export interface KindergartenClientContractNumberInputCell {
    value: Optional<number>;
    canEdit: boolean;
    style?: any;
    placeholder?: string;
    onChange: (val: Optional<number>) => void;
    copyContracts: (tillEnd: boolean) => void;
}
const UPDATE_DELAY = 200;

const Component: React.FC<KindergartenClientContractNumberInputCell> = ({
    value,
    canEdit,
    onChange,
    placeholder,
    style,
    copyContracts,
}) => {
    // to re-render number field
    const [resetField, setResetField] = useState(false);

    useEffect(() => {
        setResetField(true);
    }, [value]);

    useEffect(() => {
        if (resetField) {
            setResetField(false);
        }
    }, [resetField]);

    const debouncedOnChange = useMemo(() => {
        return debounce(onChange, UPDATE_DELAY);
    }, [onChange]);

    return (
        <td>
            <div className={styles.valueCell}>
                {!resetField && (
                    <AppNumberInput
                        showLabel={false}
                        showError={false}
                        allowZero={true}
                        disabled={!canEdit}
                        formatTheInitialValue={true}
                        value={value}
                        isForCurrency={true}
                        onChange={() => {}}
                        style={{
                            height: "39px",
                            padding: "8px",
                            minHeight: "39px",
                            ...defaultTo(style, {}),
                        }}
                        onBlur={(val) => debouncedOnChange(val)}
                        placeholder={placeholder}
                    />
                )}
                <div className={styles.copyIcons}>
                    <AppIconButton
                        buttonType="Other"
                        showToolTip={true}
                        image={ImageAssets.common.copyBlue}
                        tooltipText={t("common.copy")}
                        readonly={!canEdit}
                        onClick={() => {
                            copyContracts(false);
                        }}
                    />
                    <AppIconButton
                        buttonType="Other"
                        image={ImageAssets.common.copyFilled}
                        showToolTip={true}
                        tooltipText={t("common.copyToEnd")}
                        readonly={!canEdit}
                        onClick={() => {
                            copyContracts(true);
                        }}
                    />
                </div>
            </div>
        </td>
    );
};

Component.displayName = "KindergartenClientContractNumberInputCell";
export const KindergartenClientContractNumberInputCell = React.memo(Component);
export default KindergartenClientContractNumberInputCell;
