import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import { isNil } from "lodash-es";
import {
    CalendarEventTemplate,
    CalendarEventTemplateDetailsResponse,
    CalendarEventTemplateResponse,
} from "models/calendar";
import { Optional, Templatable } from "models/general";
import moment from "moment";
import { Moment } from "moment-timezone";
import { COLOR_BLUE, COLOR_GREY, COLOR_RED } from "theme/themeConstants";
import { PivotDurationItemType } from "./enum";
import {
    CalendarEventTemplateDetails,
    CalendarPivotEvent,
    PivotDurationItem,
} from "./model";

export const EventColorsMap: {
    [key in PivotDurationItemType]: { bg: string; text: string };
} = {
    [PivotDurationItemType.Disease]: { bg: COLOR_RED, text: "#fff" },
    [PivotDurationItemType.Vacation]: { bg: COLOR_BLUE, text: "#fff" },
    [PivotDurationItemType.SchoolDay]: { bg: COLOR_GREY, text: "#000" },
    [PivotDurationItemType.FreeDay]: { bg: COLOR_GREY, text: "#000" },
    [PivotDurationItemType.FurtherTraining]: { bg: COLOR_GREY, text: "#000" },
    [PivotDurationItemType.AbsentNone]: { bg: COLOR_GREY, text: "#000" },
};

export function getDefaultCalendarEventTemplate(
    currDate: Moment,
    isNew?: boolean
): CalendarEventTemplateDetails {
    return {
        Id: null,
        EncodedId: null,
        UpdatedBy: {} as any,
        CreatedBy: {} as any,
        EventDay: currDate,
        EndDate: undefined,
        StartTime: null,
        EndTime: null,
        AllDay: true,
        SendThisEvent: !isNew,
        ClientIds: null,
        EmployeeIds: null,
        GroupIds: null,
        ReceiptTypes: null,
        Body: {
            Template: "",
            Placeholders: [],
            Version: "",
            Text: "",
        } as Templatable,
        Title: null,
        Receipts: [],
    };
}
export const getCalendarEventTime = (
    day?: Optional<Moment | string>,
    time?: Optional<Moment | string>,
    allDay: boolean = false
): Date => {
    const dayStr = !isNil(day)
        ? typeof day === "string"
            ? day
            : day.format(ISO8601_DATE_FORMAT)
        : undefined;
    const timeStr = !isNil(time)
        ? typeof time === "string"
            ? time.length > 5
                ? "00:00"
                : time
            : time.format(ISO8601_TIME_FORMAT)
        : moment().format(ISO8601_TIME_FORMAT);
    const parsedStr =
        dayStr && time && !allDay
            ? `${dayStr} ${timeStr}`
            : dayStr
            ? dayStr
            : timeStr;
    return new Date(parsedStr);
};
export const getParsedCalendarEvent = (
    e: CalendarEventTemplateResponse | CalendarEventTemplateDetailsResponse
): CalendarEventTemplate | CalendarEventTemplateDetails => {
    // if (e.EndDate) {
    //     console.log(
    //         e.EndDate,
    //         moment(e.EndDate, ISO8601_DATE_FORMAT),
    //         moment().tz(),
    //         moment(e.EndDate, ISO8601_DATE_FORMAT).format(ISO8601_DATE_FORMAT)
    //     );
    // }
    return {
        ...e,
        StartTime: e.StartTime
            ? moment(e.StartTime, ISO8601_TIME_FORMAT)
            : undefined,
        EndTime: e.EndTime ? moment(e.EndTime, ISO8601_TIME_FORMAT) : undefined,
        EndDate: e.EndDate ? moment(e.EndDate, ISO8601_DATE_FORMAT) : undefined,
        EventDay: e.EventDay
            ? moment(e.EventDay, ISO8601_DATE_FORMAT)
            : undefined,
    };
};
export const mapDurationItemsToCalendarItems = (
    list: PivotDurationItem[],
    resourceId: string
): CalendarPivotEvent[] => {
    return list.map((item) => {
        return {
            Id: item.Id,
            Title: item.Name,
            ResourceId: resourceId,
            Type: item.Type,
            EventDay: item.DateStart,
            StartTime: item.DateStart,
            EndTime: item.DateEnd,
            AllDay: false,
        } as CalendarPivotEvent;
    });
};
