import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import { BankData, BankDataRequest } from "models";

export interface IBankDataService {
    list(req: BankDataRequest): Promise<AppResponse<BankData>>;
    get(id: string): Promise<AppResponse<BankData>>;
    createUpdate(model: BankData): Promise<AppResponse<BankData>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.bankDatas;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBankDataServiceKey(
    name: keyof IBankDataService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BankDataService_${name}_${JSON.stringify(data)}`;
}

export class BankDataService extends BaseService implements IBankDataService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    list(req: BankDataRequest): Promise<AppResponse<BankData>> {
        return this.doServerXHR<BankData>({
            url: this.routes.list(req),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<BankData>> {
        return this.doServerXHR<BankData>({
            url: this.routes.get(id),
            method: "get",
        });
    }

    createUpdate(model: BankData): Promise<AppResponse<BankData>> {
        return this.doServerXHR<BankData>({
            url: this.routes.createUpdate(),
            method: "post",
            data: model,
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.routes.delete(id),
            method: "delete",
        });
    }
}

export default BankDataService;
