import { isNil } from "lodash-es";
import { Business, BusinessType } from "models";
import { Optional, SelectItem } from "models/general";
import { AppResponse } from "models/general/response";
import {
    LocationSettingDetailsResponse,
    LocationSettingListItem,
    LocationSettingResponse,
} from "models/locationSetting";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

const apiLinkProvider = routesForContext().lillywait.locationSettings().api;
type ApiLinkProviderType = typeof apiLinkProvider;

export interface ILocationSettingsService {
    getList: (
        type?: BusinessType,
        lillywaitId?: Optional<string>
    ) => Promise<AppResponse<LocationSettingListItem[]>>;
    getLocationClients: () => Promise<AppResponse<Business[]>>;
    getBasicInfo: (
        id?: string
    ) => Promise<AppResponse<LocationSettingResponse>>;
    getDetails: (
        id?: string
    ) => Promise<AppResponse<LocationSettingDetailsResponse>>;
    delete: (id?: string) => Promise<AppResponse<boolean>>;
    getLetterheadOptions(): Promise<AppResponse<SelectItem[]>>;
}

export function getLocationSettingsServiceKey(
    name: keyof ILocationSettingsService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `locationSettings_${name}_${JSON.stringify(data)}`;
}

export class LocationSettingsService
    extends BaseService
    implements ILocationSettingsService
{
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }

    getList(
        type?: Optional<BusinessType>,
        lillywaitId?: Optional<string>
    ): Promise<AppResponse<LocationSettingListItem[]>> {
        return this.doServerXHR<AppResponse<LocationSettingListItem[]>>({
            url: this.baseRoute.getList({
                BusinessType: !isNil(type) ? type : undefined,
                LillywaitId: lillywaitId?.toString(),
            }),
            method: "get",
        });
    }

    getLocationClients(): Promise<AppResponse<Business[]>> {
        return this.doServerXHR<AppResponse<Business[]>>({
            url: this.baseRoute.withId().getLocationClients(),
            method: "get",
        });
    }

    getLetterheadOptions(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<AppResponse<SelectItem[]>>({
            url: this.baseRoute.withId().getLetterheadOptions(),
            method: "get",
        });
    }

    getBasicInfo(
        id?: string | undefined
    ): Promise<AppResponse<LocationSettingResponse>> {
        return this.doServerXHR<AppResponse<LocationSettingResponse>>({
            url: this.baseRoute.withId(id).getBasicInfo(),
            method: "get",
        });
    }

    getDetails(
        id?: string | undefined
    ): Promise<AppResponse<LocationSettingDetailsResponse>> {
        return this.doServerXHR<AppResponse<LocationSettingDetailsResponse>>({
            url: this.baseRoute.withId(id).getDetails(),
            method: "get",
        });
    }

    delete(id?: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.baseRoute.withId(id).delete(),
            method: "delete",
        });
    }
}
