import "animate.css";
import "pc-bootstrap4-datetimepicker";
import AppHelmet from "components/AppHelmet";
import config from "globals/config";
import "globals/fontAwesomeIcons";
import AppErrorBoundary from "hoc/AppErrorBoundary";
import { AppContextProvider, TwilioClientProvider } from "hoc/providers";
import AppWebNotificationProvider from "hoc/providers/AppWebNotificationProvider";
import CultureProvider from "hoc/providers/CultureProvider";
import ErrorPageProvider from "hoc/providers/ErrorPageProvider";
import React from "react";
import { I18nextProvider } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
// CSS Imports
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "routing/router/AppRouter";
import i18n from "./localization/i18next";
import AppWebEventProvider from "hoc/providers/AppWebEventProvider";
import { withProfiler } from "@sentry/react";
// import { writeTranslationsToExcel } from "localization/translations/conversionScripts";

// (window as any).writeTranslationsToExcel = writeTranslationsToExcel;
const DEFAULT_QUERY_CACHE_SECONDS = 11; // 11sec default cache time (should be more than stale time)
const DEFAULT_QUERY_STALE_SECONDS = 10; // 10sec default stale time
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            enabled: true,
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            // uses longest cache time if specified on query as well
            cacheTime: DEFAULT_QUERY_CACHE_SECONDS * 1000,
            staleTime: DEFAULT_QUERY_STALE_SECONDS * 1000,
            retry: 0,
        },
    },
});

function App() {
    // const temp = (
    //     <ToastContainer
    //         position="bottom-right"
    //         autoClose={10000}
    //         theme="colored"
    //     />
    // );
    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    <BrowserRouter>
                        <AppErrorBoundary>
                            <AppHelmet title={config.siteName} />
                            <I18nextProvider i18n={i18n}>
                                <CultureProvider>
                                    <AppWebNotificationProvider>
                                        <AppWebEventProvider>
                                            <ErrorPageProvider>
                                                {/* wrap all layouts in error-layout so we can catch and show errorCode using param on any layout */}
                                                <TwilioClientProvider>
                                                    <AppRouter />
                                                </TwilioClientProvider>
                                            </ErrorPageProvider>
                                        </AppWebEventProvider>
                                    </AppWebNotificationProvider>
                                </CultureProvider>
                            </I18nextProvider>
                        </AppErrorBoundary>
                    </BrowserRouter>
                </AppContextProvider>
            </QueryClientProvider>
        </React.Fragment>
    );
}

export default withProfiler(App);
