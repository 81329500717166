import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    AllBusinessPermissionModules,
    BusinessType,
    getParsedAccessPermissionsList,
} from "models";
import { AppPlatforms } from "models/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business/BusinessGeneralService";

export function useFetchAllBusinessModuleKeys() {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const [state, setState] = useState<
        AllBusinessPermissionModules & { dataLoaded: boolean }
    >({
        [AppPlatforms.IPAD]: [],
        [AppPlatforms.MOBILE]: [],
        [AppPlatforms.WEB]: [],
        dataLoaded: false,
    });

    const { encodedId, sessionBusiness } = useSessionBusiness();
    const businessService = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const { isFetching, data: response } = useQuery(
        getBusinessGeneralServiceKey("getAllBusinessModuleKeys", encodedId),
        async () => await businessService.getAllBusinessModuleKeys()
    );
    useEffect(() => {
        if (!isFetching && response && response.Data) {
            const data = response.Data;
            //for set all the permissions for mobile, website and ipad
            //calls in permission_management
            const businessType = defaultTo(
                sessionBusiness?.Type,
                BusinessType.KINDERGARTEN
            );
            //Complete parsed list with mapping to create check boxes
            const newState = {
                [AppPlatforms.IPAD]: getParsedAccessPermissionsList(
                    data.IPad,
                    businessType
                ),
                [AppPlatforms.MOBILE]: getParsedAccessPermissionsList(
                    data.Mobile,
                    businessType
                ),
                [AppPlatforms.WEB]: getParsedAccessPermissionsList(
                    data.Website,
                    businessType
                ),
            };
            setState({ ...newState, dataLoaded: true });
        }
    }, [isFetching, t]);

    return state;
}

export default useFetchAllBusinessModuleKeys;
