import { AppContentHeader } from "components";
import AppLoader from "components/Loaders/AppLoader";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import LocationSettingTabs from "./partials/LocationSettingTabs";
import styles from "./CreditorsEditLayout.module.scss";
import { useEffect, useState } from "react";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useParams } from "react-router";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import {
    BusinessCreditorService,
    getBusinessCreditorServiceKey,
} from "services/business";
import { CreditorTabs } from "screens/business/creditors/tabs";
import { BusinessCreditor } from "models";

export const CreditorsEditLayout: React.FC = () => {
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { creditorId } = useParams();
    const { checkPermission } = useCheckPermission();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<NavigationTab | undefined>(
        undefined
    );

    const creditorsService = new BusinessCreditorService(
        linkProvider.business.api.creditors
    );
    const urlBase = linkProvider.business.screens.creditors;
    const {
        isLoading,
        isStale,
        data: response,
        error,
        refetch,
    } = useQuery(
        getBusinessCreditorServiceKey("get", {
            creditorId: creditorId,
        }),
        async () => await creditorsService.get(creditorId),
        {
            refetchOnWindowFocus: true,
            select: (data) => (data.Data ? data.Data : null),
        }
    );

    useEffect(() => {
        if (creditorId === ":creditorId") {
            navigate(urlBase.list(), {
                replace: true,
            });
        } else if (
            location.pathname.endsWith(`creditors/${creditorId}`) ||
            location.pathname.endsWith(`creditors/${creditorId}/`)
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(urlBase.withId(creditorId).edit(), {
                replace: true,
            });
        }
    }, [location.pathname, creditorId]);

    useEffect(() => {
        if (!isLoading && (response || error)) {
            let hasError = !isNil(error);
            if (!hasError && response) {
                // id in url is wrong
                hasError = isNil(response);
            }
            if (hasError) {
                showErrorPage(404);
            }
        }
    }, [isLoading, response, error]);

    const data = !isLoading && response ? response : null;

    return isNil(data) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={data.Name}
                classNameForIcon="lnr-briefcase"
                hasGoBack={true}
                backUrl={urlBase.list()}
            />
            <div className={styles.tabs}>
                <CreditorTabs onActiveTabChange={setCurrentTab} />
                <div className={styles.content}>
                    <Outlet
                        context={
                            {
                                dataItem: data,
                                tab: currentTab,
                                refetchData: () => {
                                    refetch();
                                },
                            } as TabbedLayoutActiveItem<BusinessCreditor>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CreditorsEditLayout;
