import classNames from "classnames";
import { AppDropdownMenu, DropdownMenuItem } from "components/AppDropdownMenu";
import { AvatarItem } from "components/FormFields";
import {
    copyToClipboard,
    getHumanFriendlySize,
    openUrlInTab,
} from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useCheckPermission } from "hooks/permissionCheck";
import { debounce } from "lodash-es";
import {
    ChatMessageType,
    MemberAttribute,
    MessageAttribute,
} from "models/chat";
import { AvatarInfo, Optional } from "models/general";
import {
    Business_Chat,
    PermissionAccessTypes,
} from "models/permissionManagement";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Message } from "twilio-chat";
import styles from "./ChatBubbleItem.module.scss";

interface ChatBubbleItemProps {
    isPrivateChat: boolean;
    message: Message;
    userId: string;
    isRead: boolean;
    messageEditClickHandler: (message: Message) => void;
}

// eslint-disable-next-line react/display-name
export const ChatBubbleItem = React.memo<ChatBubbleItemProps>(
    ({
        message,
        userId,
        messageEditClickHandler,
        isPrivateChat = false,
        isRead,
    }) => {
        const { t } = useTranslation();
        const { checkPermission } = useCheckPermission();
        const hasEditPermission = checkPermission(Business_Chat, [
            PermissionAccessTypes.EDIT,
        ]);
        const { getTimeFormatForLocale } = useLocaleHelpers();

        const isOwnMessage: boolean = message.author === userId;
        const [memberLoading, setMemberLoading] = useState(true);
        const [memberInfo, setMemberInfo] =
            useState<Optional<AvatarInfo>>(null);
        const fetchMemberInfo = useMemo(() => {
            return debounce(() => {
                if (message) {
                    message
                        .getMember()
                        .then((member) => {
                            const memberAttributes =
                                member.attributes as MemberAttribute;

                            setMemberInfo(memberAttributes.MemberInfo);
                        })
                        .catch(() => {
                            const messageAttributes =
                                message.attributes as MessageAttribute;
                            const dumpyMemberInfo: AvatarInfo = {
                                Name:
                                    messageAttributes === null
                                        ? ""
                                        : messageAttributes.Name,
                                Avatar: ImageAssets.common.person,
                                BackgroundColor: "",
                                BorderColor: "",
                                Initial: "",
                            };
                            setMemberInfo(dumpyMemberInfo);
                        })
                        .finally(() => setMemberLoading(false));
                }
            }, 10);
        }, [message]);

        useEffect(() => {
            fetchMemberInfo();
            return () => fetchMemberInfo.cancel();
        }, []);
        const copyHandler = () => {
            copyToClipboard(message.body);
            showSweetAlertToast(t("chat.messageCopied"), "", "success");
        };
        const MessageDisplayHandler = () => {
            if (message.type === ChatMessageType.MEDIA.toString()) {
                const downloadFile = async () => {
                    const url = await message.media.getContentTemporaryUrl();
                    openUrlInTab(url, message.media.filename);
                };
                return (
                    <div className={styles.mediaItemText}>
                        <span>
                            <span
                                className={classNames(
                                    "ellipsis-text",
                                    styles.mediaName
                                )}
                                title={message.media.filename}
                                onClick={downloadFile}
                            >
                                {message.media.filename}
                            </span>
                            <span>
                                {getHumanFriendlySize(message.media.size)}
                            </span>
                        </span>
                        <div className={styles.mediaBorder}></div>
                        <div className={styles.imageDiv} onClick={downloadFile}>
                            <Image
                                src={
                                    isOwnMessage
                                        ? ImageAssets.common.downloadArrowWhite
                                        : ImageAssets.common.downloadArrowBlue
                                }
                            />
                        </div>
                    </div>
                );
            }

            return (
                <span style={{ whiteSpace: "break-spaces" }}>
                    {message.body}
                </span>
            );
        };

        useEffect(() => {
            return () => fetchMemberInfo.cancel();
        }, []);
        const onEditHandle = () => {
            if (hasEditPermission) {
                messageEditClickHandler(message);
            } else {
                showSweetAlertToast(
                    "Permission(s)",
                    "Sorry! Not Enough Permission(s)",
                    "warning"
                );
            }
        };

        return (
            <>
                {!(memberLoading || memberInfo == null) && (
                    <div key={message.memberSid} className={styles.listItem}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: isOwnMessage
                                    ? "row-reverse"
                                    : "row",
                                textAlign: isOwnMessage ? "right" : "left",
                            }}
                        >
                            <AvatarItem
                                value={memberInfo}
                                className={`${styles.avatar} ${
                                    isPrivateChat
                                        ? styles.avatarMarginPrivate
                                        : styles.avatarMarginGroup
                                }`}
                            />
                            <div className={styles.messageTextDiv}>
                                <div
                                    className={classNames(
                                        styles.messageTimeInfo,
                                        {
                                            [styles.ownMessageInfo]:
                                                isOwnMessage,
                                        }
                                    )}
                                >
                                    {!isPrivateChat && (
                                        <div className={styles.author}>
                                            <span>
                                                {isOwnMessage
                                                    ? t("chat.you")
                                                    : memberInfo &&
                                                      memberInfo.Name &&
                                                      memberInfo.Name}
                                            </span>
                                        </div>
                                    )}
                                    <div className={styles.timeSpan}>
                                        {moment(message.dateCreated).format(
                                            getTimeFormatForLocale()
                                        )}
                                    </div>
                                    <div>
                                        <Image
                                            className={styles.messageTick}
                                            src={
                                                isRead
                                                    ? ImageAssets.common
                                                          .tickBlue
                                                    : ImageAssets.common
                                                          .tickGray
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${
                                        styles.messageBodyContainer
                                    } ${
                                        isOwnMessage
                                            ? styles.ownMessageBodyAlignment
                                            : styles.otherMessageBodyAlignment
                                    }`}
                                >
                                    {isOwnMessage &&
                                        message.type ===
                                            ChatMessageType.TEXT.toString() && (
                                            <div
                                                className={
                                                    styles.messageOptions
                                                }
                                            >
                                                <AppDropdownMenu
                                                    trigger={
                                                        <Image
                                                            className={
                                                                styles.menuIcon
                                                            }
                                                            src={
                                                                ImageAssets
                                                                    .common
                                                                    .menuGray
                                                            }
                                                        />
                                                    }
                                                    menuItems={
                                                        [
                                                            {
                                                                label: t(
                                                                    "common.edit"
                                                                ),
                                                                onClick:
                                                                    onEditHandle,
                                                            },
                                                            {
                                                                label: t(
                                                                    "common.copy"
                                                                ),
                                                                onClick:
                                                                    copyHandler,
                                                            },
                                                        ] as DropdownMenuItem[]
                                                    }
                                                />
                                            </div>
                                        )}

                                    <div
                                        className={`${styles.messageBubble} ${
                                            isOwnMessage
                                                ? styles.ownMessageBubble
                                                : styles.otherMessageBubble
                                        }`}
                                    >
                                        {MessageDisplayHandler()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

export default ChatBubbleItem;
