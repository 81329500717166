import { AppLoader, AppOverlayLoader } from "components/Loaders";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import {
    useActiveDashboardTab,
    useSessionBusiness,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck/useCheckPermission";
import { defaultTo, isNil } from "lodash-es";
import { Business_Dashboard_Client } from "models";
import { BusinessType } from "models/business";
import {
    DashboardCardTypes,
    DashboardResponse,
    DashboardSections,
    DashboardTabs,
    stringDictionaryToMomentDictionary,
} from "models/dashboard";
import { Optional } from "models/general";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
    DashboardService,
    getDashboardServiceKey,
} from "services/business/DashboardService";
import styles from "./Dashboard.module.scss";
import { DashboardCard, DashboardHeader, DashboardSection } from "./partials";

export const Dashboard: React.FC = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { linkProvider } = useRouting();
    const { activeTab: userActiveTAB, setActiveTab: setUserActiveTab } =
        useActiveDashboardTab();
    const { checkPermission } = useCheckPermission();
    const { encodedId: encodedBusinessId, sessionBusiness } =
        useSessionBusiness();

    const hasClientViewPermission = checkPermission(Business_Dashboard_Client);
    const businessType = defaultTo(
        sessionBusiness?.Type,
        BusinessType.KINDERGARTEN
    );
    const [response, setResponse] =
        useState<Optional<DashboardResponse>>(undefined);
    const [state, setSelectedTab] = useState<{
        selectedTab: DashboardTabs;
    }>({
        selectedTab:
            hasClientViewPermission &&
            businessType === BusinessType.KINDERGARTEN
                ? searchParams.get("tab")
                    ? (searchParams.get("tab") as DashboardTabs)
                    : userActiveTAB
                : DashboardTabs.EMPLOYEES,
    });

    const dashboardService = new DashboardService(
        linkProvider.business.api.dashboard
    );

    const {
        isLoading: loading,
        isFetching,
        data,
        error,
    } = useQuery(
        getDashboardServiceKey("getDashboardStats", {
            encodedBusinessId: encodedBusinessId,
            tab: state.selectedTab,
        }),
        async () => await dashboardService.getDashboardStats(state.selectedTab), // automatically refetch based on key change
        {
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );

    // useEffect(() => {
    //     setSearchParams(
    //         { ...searchParams, tab: state.selectedTab },
    //         { replace: true }
    //     );
    // }, [state.selectedTab]);
    useEffect(() => {
        if (data) {
            setResponse(data.Data);
            setUserActiveTab(state.selectedTab);
            setSearchParams(
                { ...searchParams, tab: state.selectedTab },
                { replace: true }
            );
        } else if (!isFetching && error) {
            showUnexpectedErrorToast();
        }
    }, [isFetching, data, error]);

    // console.log(isFetching);
    return (
        <div className={styles.root}>
            <DashboardHeader
                loading={isFetching}
                onTabChangeHandler={(tab: DashboardTabs) => {
                    setSelectedTab({ selectedTab: tab });
                }}
                selectedTab={state.selectedTab}
                businessType={businessType}
            />
            <div className={styles.contentContainer}>
                {loading || isNil(response) ? (
                    <AppLoader fullHeight={true} />
                ) : (
                    <>
                        {loading && <AppOverlayLoader />}
                        <Row className={`${styles.cardsContainer} mb-4`}>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.activeContracts")}
                                    firstHeading={t("dashboard.thisMonth")}
                                    secondHeading={t(
                                        "dashboard.inTheNextMonth"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .ACTIVE_CONTRACTS
                                    }
                                    cardType={
                                        DashboardCardTypes.ACTIVE_CONTRACTS
                                    }
                                    selectedTab={state.selectedTab}
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.startingContracts")}
                                    firstHeading={t("dashboard.thisMonth")}
                                    secondHeading={t(
                                        "dashboard.inTheNextMonth"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .STARTING_CONTRACTS
                                    }
                                    cardType={
                                        DashboardCardTypes.STARTING_CONTRACTS
                                    }
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                    selectedTab={state.selectedTab}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.endingContracts")}
                                    firstHeading={t("dashboard.thisMonth")}
                                    secondHeading={t(
                                        "dashboard.inTheNextMonth"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .ENDING_CONTRACTS
                                    }
                                    cardType={
                                        DashboardCardTypes.ENDING_CONTRACTS
                                    }
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                    selectedTab={state.selectedTab}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.activeContracts")}
                                    firstHeading={t(
                                        state.selectedTab ==
                                            DashboardTabs.CLIENTS
                                            ? "dashboard.inThe3Month"
                                            : "dashboard.averageForTheNext3Months"
                                    )}
                                    secondHeading={t(
                                        state.selectedTab ==
                                            DashboardTabs.CLIENTS
                                            ? "dashboard.inThe6Month"
                                            : "dashboard.averageForTheNext6Months"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .ACTIVE_CONTRACTS
                                    }
                                    isSecondRow={true}
                                    cardType={
                                        DashboardCardTypes.ACTIVE_CONTRACTS
                                    }
                                    selectedTab={state.selectedTab}
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.startingContracts")}
                                    firstHeading={t(
                                        "dashboard.totalForTheNext3Months"
                                    )}
                                    secondHeading={t(
                                        "dashboard.totalForTheNext6Months"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .STARTING_CONTRACTS
                                    }
                                    isSecondRow={true}
                                    cardType={
                                        DashboardCardTypes.STARTING_CONTRACTS
                                    }
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                    selectedTab={state.selectedTab}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <DashboardCard
                                    title={t("dashboard.endingContracts")}
                                    firstHeading={t(
                                        "dashboard.totalForTheNext3Months"
                                    )}
                                    secondHeading={t(
                                        "dashboard.totalForTheNext6Months"
                                    )}
                                    counts={
                                        response.DashboardCardsStats
                                            .ENDING_CONTRACTS
                                    }
                                    isSecondRow={true}
                                    cardType={
                                        DashboardCardTypes.ENDING_CONTRACTS
                                    }
                                    showPersonInfo={true}
                                    persons={response.Persons}
                                    selectedTab={state.selectedTab}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <DashboardSection
                                    selectedTab={state.selectedTab}
                                    sectionType={DashboardSections.DISEASES}
                                    persons={response.Persons}
                                    personsDatesInfo={stringDictionaryToMomentDictionary(
                                        response.DiseasesStats.PersonsDatesInfo
                                    )}
                                    sections={[
                                        {
                                            countWithPersonInfo:
                                                response.DiseasesStats.Today,
                                            title: t(
                                                "dashboard.totalDiseasesDays"
                                            ),
                                            sectionRange: "today",
                                            subtitle: t("common.today"),
                                            countClassName: styles.green,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.DiseasesStats
                                                    .CurrentMonth,
                                            title: t("dashboard.diseaseDays"),
                                            sectionRange: "currMonth",
                                            subtitle: t("dashboard.thisMonth"),
                                            countClassName: styles.primary,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.DiseasesStats
                                                    .PreviousMonth,
                                            title: t("dashboard.diseaseDays"),
                                            sectionRange: "prevMonth",
                                            subtitle: t(
                                                "dashboard.inThePreviousMonth"
                                            ),
                                            countClassName: styles.darkYellow,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.DiseasesStats
                                                    .CurrentYear,
                                            title: t("dashboard.diseaseDays"),
                                            sectionRange: "currYear",
                                            subtitle: t("dashboard.thsYear"),
                                            countClassName: styles.red,
                                            showPersonInfo: true,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <DashboardSection
                                    selectedTab={state.selectedTab}
                                    sectionType={DashboardSections.VACATION}
                                    persons={response.Persons}
                                    personsDatesInfo={stringDictionaryToMomentDictionary(
                                        response.VacationsStats.PersonsDatesInfo
                                    )}
                                    sections={[
                                        {
                                            countWithPersonInfo:
                                                response.VacationsStats.Today,
                                            title: t(
                                                "dashboard.totalVacationDays"
                                            ),
                                            sectionRange: "today",
                                            subtitle: t("common.today"),
                                            countClassName: styles.green,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.VacationsStats
                                                    .CurrentMonth,
                                            title: t("dashboard.vacationDays"),
                                            subtitle: t("dashboard.thisMonth"),
                                            countClassName: styles.primary,
                                            showPersonInfo: true,
                                            sectionRange: "currMonth",
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.VacationsStats
                                                    .PreviousMonth,
                                            title: t("dashboard.vacationDays"),
                                            sectionRange: "prevMonth",
                                            subtitle: t(
                                                "dashboard.inThePreviousMonth"
                                            ),
                                            countClassName: styles.darkYellow,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.VacationsStats
                                                    .CurrentYear,
                                            title: t("dashboard.vacationDays"),
                                            sectionRange: "currYear",
                                            subtitle: t("dashboard.thsYear"),
                                            countClassName: styles.red,
                                            showPersonInfo: true,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DashboardSection
                                    selectedTab={state.selectedTab}
                                    sectionType={DashboardSections.MISSING}
                                    persons={response.Persons}
                                    personsDatesInfo={stringDictionaryToMomentDictionary(
                                        response.MissingStats.PersonsDatesInfo
                                    )}
                                    sections={[
                                        {
                                            countWithPersonInfo:
                                                response.MissingStats.Today,
                                            title: t("common.missing"),
                                            subtitle: t("common.today"),
                                            sectionRange: "today",
                                            countClassName: styles.green,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.MissingStats
                                                    .CurrentMonth,
                                            title: t("common.missing"),
                                            subtitle: t("dashboard.thisMonth"),
                                            sectionRange: "currMonth",
                                            countClassName: styles.primary,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.MissingStats
                                                    .PreviousMonth,
                                            title: t("dashboard.missingDays"),
                                            sectionRange: "prevMonth",
                                            subtitle: t(
                                                "dashboard.inThePreviousMonth"
                                            ),
                                            countClassName: styles.darkYellow,
                                            showPersonInfo: true,
                                        },
                                        {
                                            countWithPersonInfo:
                                                response.MissingStats
                                                    .CurrentYear,
                                            title: t("dashboard.missingDays"),
                                            sectionRange: "currYear",
                                            subtitle: t("dashboard.thsYear"),
                                            countClassName: styles.red,
                                            showPersonInfo: true,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
