import bsLocale from "@fullcalendar/core/locales/bs";
import csLocale from "@fullcalendar/core/locales/cs";
import deLocale from "@fullcalendar/core/locales/de";
import elLocale from "@fullcalendar/core/locales/el";
import esLocale from "@fullcalendar/core/locales/es";
import etLocale from "@fullcalendar/core/locales/et";
import fiLocale from "@fullcalendar/core/locales/fi";
import frLocale from "@fullcalendar/core/locales/fr";
import hrLocale from "@fullcalendar/core/locales/hr";
import huLocale from "@fullcalendar/core/locales/hu";
import itLocale from "@fullcalendar/core/locales/it";
import nlLocale from "@fullcalendar/core/locales/nl";
import plLocale from "@fullcalendar/core/locales/pl";
import skLocale from "@fullcalendar/core/locales/sk";
import slLocale from "@fullcalendar/core/locales/sl";
import FullCalendar from "@fullcalendar/react";
import { EventApi, EventInput } from "@fullcalendar/core";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import { AppLoader, AppOverlayLoader } from "components/Loaders";
import config from "globals/config";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { momentFromJSLocalDateTime } from "globals/helpers/localizationHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import $ from "jquery";
import { defaultTo, isNil } from "lodash-es";
import { BusinessHolidayResponse } from "models";
import {
    CalendarEventTemplate,
    CalendarPivotEvent,
    CalendarPivotResource,
    EventColorsMap,
    getCalendarEventTime,
    PivotDurationItemType,
} from "models/calendar";
import moment, { Moment } from "moment-timezone";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    COLOR_GREY_LIGHT,
    COLOR_LIGHT_GREEN,
    COLOR_PINK,
    COLOR_YELLOW,
    COLOR_YELLOW_LIGHT,
} from "theme/themeConstants";
import { DateRangeRequest } from "models/general/request";
import { UserFilterType } from "models/general";

const MONTH_VIEW: string = "dayGridMonth";
const WEEK_VIEW: string = "timeGridWeek";
const DAY_VIEW: string = "timeGridDay";
const PIVOT_VIEW: string = "resourceTimelineMonth";
export interface FullCalendarComponentProps {
    onDateClick: (date: any) => void;
    onDatesChange: (filter: DateRangeRequest, isPivot: boolean) => void;
    onEventClick: (event: EventApi) => void;
    isPivot?: boolean;
    events?: (CalendarEventTemplate | CalendarPivotEvent)[];
    holidays?: BusinessHolidayResponse[];
    loading?: boolean;
    resources?: CalendarPivotResource[];
    showPivot: boolean;
    showOverlayLoader?: boolean;
    updateQueryParam?: (view: string, initialDate: Moment) => void;
    initialDate: Date;
    initialView?: string;
    ref?: React.RefObject<any>;
}

function Component(props: FullCalendarComponentProps, ref: any) {
    const {
        onDateClick,
        loading = false,
        showOverlayLoader = true,
        onEventClick,
        resources,
        holidays,
        events = [],
        isPivot = false,
        showPivot,
        initialDate,
        initialView = MONTH_VIEW,
        updateQueryParam,
    } = props;

    const { appLocale, isWeekend } = useLocaleHelpers();
    const { t } = useTranslation();
    const initial = useRef({
        initialView: initialView,
        initialDate: initialDate,
    });
    const holidayEvents = defaultTo(holidays, []).map((res) => ({
        Id: res.Id,
        Title: res.Name,
        AllDay: true,
        EventDay: res.Holiday,
        StartTime: undefined,
        EndTime: undefined,
        isHoliday: true,
    }));
    const isPivotView = (viewType: string) => viewType == PIVOT_VIEW;
    const canShowPivotView = initial.current.initialView == MONTH_VIEW;
    const eventsWithHolidays = [...holidayEvents, ...events];

    const showCalendar =
        !isPivot || (isPivot && !(showOverlayLoader && loading));
    const loader = isPivot ? (
        // for pivot view need to re-render calendar otherwise it shows rows of wrong height
        <AppLoader fullHeight />
    ) : (
        <AppOverlayLoader fullHeight />
    );

    return (
        <>
            {showOverlayLoader && loading && loader}
            {showCalendar && (
                <FullCalendar
                    ref={ref}
                    initialView={initial.current.initialView}
                    initialDate={initial.current.initialDate}
                    themeSystem={"bootstrap"}
                    dayMaxEventRows={!isPivot}
                    resourceAreaWidth={200}
                    schedulerLicenseKey={config.fullCalendar.licenseKey}
                    eventBackgroundColor={COLOR_YELLOW}
                    eventBorderColor={"transparent"}
                    // eventBorderColor={!isPivot ? COLOR_DARK_YELLOW : "transparent"}
                    eventTextColor={"#000"}
                    displayEventEnd={true}
                    // eventOrder={"title, start,end"}
                    nowIndicator={!isPivot} // red line
                    eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                        meridiem: false,
                    }}
                    locales={[
                        deLocale,
                        itLocale,
                        bsLocale,
                        plLocale,
                        hrLocale,
                        csLocale,
                        huLocale,
                        skLocale,
                        slLocale,
                        frLocale,
                        esLocale,
                        nlLocale,
                        fiLocale,
                        etLocale,
                        elLocale,
                    ]}
                    locale={appLocale}
                    headerToolbar={{
                        left: "prev,next",
                        center: "title",
                        right: `${
                            showPivot && canShowPivotView
                                ? `${PIVOT_VIEW}, `
                                : ""
                        }${MONTH_VIEW}${
                            !isPivot ? `,${WEEK_VIEW},${DAY_VIEW}` : ""
                        }`,
                    }}
                    views={{
                        ...(showPivot
                            ? {
                                  resourceTimelineMonth: {
                                      type: PIVOT_VIEW,
                                      buttonText: t("calendar.pivot.name"),
                                  },
                              }
                            : {}),
                        dayGridMonth: {
                            buttonText: t("common.month"),
                        },
                        timeGridWeek: {
                            buttonText: t("common.week"),
                        },
                        timeGridDay: {
                            buttonText: t("common.day"),
                        },
                    }}
                    datesSet={(info) => {
                        const view = info.view;
                        const oldView = initial.current.initialView;
                        const isPivot = isPivotView(info.view.type);

                        const start = momentFromJSLocalDateTime(
                                view.currentStart
                            ),
                            end = momentFromJSLocalDateTime(view.currentEnd);
                        let newDate = start;
                        let makeApiCall = true;

                        if (
                            oldView != view.type ||
                            view.currentStart.toDateString() !=
                                initial.current.initialDate.toDateString()
                        ) {
                            initial.current = {
                                initialView: view.type,
                                initialDate: view.currentStart,
                            };
                            if (updateQueryParam) {
                                updateQueryParam(
                                    initial.current.initialView,
                                    newDate
                                );
                            }
                        }
                        if (
                            !isPivot &&
                            oldView != initial.current.initialView
                        ) {
                            // on view change, calendar should be moved to current-date
                            const today = moment();
                            newDate = today;
                            if (ref && ref.current) {
                                makeApiCall = start <= today && end >= today;
                                ref.current.getApi().gotoDate(today.toDate());
                            }
                        }

                        if (makeApiCall) {
                            props.onDatesChange(
                                {
                                    StartDate: start,
                                    EndDate: end,
                                },
                                isPivot
                            );
                        }
                    }}
                    resourceAreaHeaderDidMount={(args) => {
                        // to change resource column title
                        $(args.el).text(
                            t("calendar.pivot.resource").toString()
                        );
                    }}
                    resourceLabelDidMount={(args: any) => {
                        // to color resource label according to user-type in pivot view
                        try {
                            if (
                                args.resource.extendedProps.type ==
                                UserFilterType.Employee
                            ) {
                                $(args.el).addClass("bgBlue");
                            } else if (
                                args.resource.extendedProps.type ==
                                UserFilterType.Supplier
                            ) {
                                $(args.el).addClass("bgOrange");
                            }
                        } catch (e) {}
                    }}
                    resourceOrder={"sortTitle"} //sort resources by this key
                    resources={resources}
                    events={eventsWithHolidays}
                    dayCellDidMount={(arg: any) => {
                        if (arg.isToday) {
                            $(arg.el).css(
                                "background-color",
                                COLOR_YELLOW_LIGHT
                            );
                        }
                        if (isWeekend(arg.date)) {
                            $(arg.el).css("background-color", COLOR_GREY_LIGHT);
                            $(arg.el).addClass("disabled");
                        }
                    }}
                    resourceLaneDidMount={(args: any) => {
                        $(args.el).css("min-height", "32px");
                    }}
                    slotLaneDidMount={(arg) => {
                        if (arg.isToday) {
                            $(arg.el).css(
                                "background-color",
                                COLOR_YELLOW_LIGHT
                            );
                        }

                        const currDate = arg.date
                            ? momentFromJSLocalDateTime(arg.date).format(
                                  ISO8601_DATE_FORMAT
                              )
                            : undefined;
                        if (
                            currDate &&
                            !isNil(
                                holidays?.find((h) => h.Holiday === currDate)
                            )
                        ) {
                            $(arg.el).css("background-color", COLOR_PINK);
                        }
                    }}
                    eventClick={(info) => {
                        if (
                            isNil(
                                holidays?.find(
                                    (h) =>
                                        info.event.extendedProps &&
                                        h.Id === info.event.extendedProps.id
                                )
                            ) &&
                            !isPivotView(info.view.type)
                        ) {
                            onEventClick(info.event);
                        }
                    }}
                    dateClick={(info) => {
                        if (
                            !isWeekend(info.date) &&
                            !isPivotView(info.view.type)
                        ) {
                            onDateClick(info);
                        }
                    }}
                    eventContent={(args) => {
                        return `${args.event.title} ${args.timeText}`;
                    }}
                    viewDidMount={() => {}}
                    plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        resourceTimelinePlugin,
                        interactionPlugin,
                        bootstrapPlugin,
                    ]}
                    //@ts-ignore
                    eventDataTransform={(
                        event: CalendarEventTemplate | CalendarPivotEvent
                    ) => {
                        // for some reason its intellisense is giving error so ignoring its type here
                        if (isNil(event)) {
                            return undefined; //discard event
                        }

                        const start = getCalendarEventTime(
                            event.EventDay,
                            event.StartTime,
                            event.AllDay
                        );

                        const calculateEnd =
                            !event.AllDay || !isNil(event.EndDate);
                        let end = undefined;
                        if (calculateEnd) {
                            end = getCalendarEventTime(
                                event.EndDate
                                    ? isNil(event.EndTime) &&
                                      typeof event.EndDate !== "string"
                                        ? event.EndDate.clone().add(1, "d") // event end-date is non-inclusive i.e. end-date should be 1 more than actual end date for AllDay events
                                        : event.EndDate
                                    : event.EventDay,
                                event.EndTime,
                                event.AllDay
                            );
                        }

                        const newEvent: EventInput = {
                            id: event.EncodedId as string, // undefined for holiday events
                            title: event.Title as string,
                            start: start,
                            end: end,
                            allDay: event.AllDay,
                            extendedProps: {
                                id: event.Id,
                                encodedId: event.EncodedId,
                            }, // for comparing and checking for event duplicate (to avoid click)
                        };

                        if ("ResourceId" in event) {
                            // for pivot event
                            const color = EventColorsMap[event.Type];
                            newEvent.start = moment(
                                event.StartTime,
                                ISO8601_DATE_FORMAT
                            ).toDate();
                            // end date should be after actual event end, also time is ignored in allDay events
                            // https://fullcalendar.io/docs/event-object
                            newEvent.end = moment(
                                event.EndTime,
                                ISO8601_DATE_FORMAT
                            )
                                .add(1, "d")
                                .toDate();
                            newEvent.resourceId = event.ResourceId;
                            newEvent.id = undefined;
                            newEvent.allDay = true;
                            if (!newEvent.title) {
                                let text = "";
                                switch (event.Type) {
                                    case PivotDurationItemType.Disease:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.disease"
                                        ).toString();
                                        break;
                                    case PivotDurationItemType.Vacation:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.vacation"
                                        ).toString();
                                        break;
                                    case PivotDurationItemType.SchoolDay:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.school"
                                        ).toString();
                                        break;
                                    case PivotDurationItemType.FreeDay:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.free"
                                        ).toString();
                                        break;
                                    case PivotDurationItemType.FurtherTraining:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.training"
                                        ).toString();
                                        break;
                                    case PivotDurationItemType.AbsentNone:
                                        text = t(
                                            "calendar.pivot.eventAlphabets.none"
                                        ).toString();
                                        break;
                                    default:
                                        break;
                                }
                                newEvent.title = text;
                            }
                            newEvent.backgroundColor = color.bg;
                            newEvent.textColor = color.text;
                        } else if (event.isHoliday) {
                            newEvent.id = event.Id?.toString();
                            newEvent.backgroundColor = COLOR_PINK;
                        } else if (!isNil(event.EndDate)) {
                            newEvent.id = event.Id?.toString();
                            newEvent.backgroundColor = COLOR_LIGHT_GREEN;
                            newEvent.className = "multi-day-event";
                        }

                        return newEvent;
                    }}
                />
            )}
        </>
    );
}
export const FullCalendarComponent = React.memo(React.forwardRef(Component));

export default FullCalendarComponent;
