import { SimpleOption } from "components/AppSelect/partials";
import { AppTable, AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ResponseMeta, SelectItem } from "models/general";
import { SortOrder } from "models/general/sort";
import {
    getTodoFromResponse,
    TodoFilterRequest,
    TodoRequest,
    TodoSortColumn,
} from "models/todo";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "react-query";
import TodoService, { getTodoServiceKey } from "services/business/TodoService";
import {
    TodoManagementHeader,
    TodoManagementTableFilterRow,
    TodoManagementTableRow,
    TodoManagementTableSkelton,
} from "./partials";

export interface TodoManagementTableState {
    list: TodoRequest[];
    filter: TodoFilterRequest;
    meta: ResponseMeta;
}

const PAGE_SIZE = 25;
export const TodoManagementTable: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { id: sessionBusinessId } = useSessionBusiness();

    const todoService = new TodoService(linkProvider.business.api.todo);

    const [state, setState] = useState<TodoManagementTableState>({
        list: [],
        filter: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
            SortColumn: TodoSortColumn.Priority,
            SortOrder: SortOrder.DESC,
            CreatedByMe: true,
        },
        meta: {
            HasNextPage: false,
        },
    });

    const {
        isLoading: todoListLoading,
        data: todoList,
        isRefetching: todoListRefetching,
        refetch: refetchTodoList,
    } = useQuery(
        getTodoServiceKey("getTodoList", {
            ...state.filter,
            businessId: sessionBusinessId,
        }),
        async () => await todoService.getTodoList(state.filter), // automatically refetch based on key change
        {
            refetchOnMount: true,
        }
    );

    useEffect(() => {
        if (
            !todoListLoading &&
            !todoListRefetching &&
            todoList &&
            todoList.Data &&
            todoList.Data.List
        ) {
            setState({
                ...state,
                list:
                    todoList.Meta?.PageNumber == 1
                        ? todoList.Data.List.map((x) => getTodoFromResponse(x))
                        : [
                              ...state.list,
                              ...todoList.Data.List.map((x) =>
                                  getTodoFromResponse(x)
                              ),
                          ],
                meta: todoList.Meta ? todoList.Meta : ({} as ResponseMeta),
            });
        }
    }, [todoList, todoListLoading, todoListRefetching]);

    const onSortChange = (sortColumn: TodoSortColumn, sortOrder: SortOrder) => {
        setState({
            ...state,
            filter: {
                ...state.filter,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };

    const onFilterChange = (filter: TodoFilterRequest) => {
        setState({
            ...state,
            filter: filter,
        });
    };

    return (
        <div>
            <TodoManagementHeader
                showRightSideSection={true}
                filter={state.filter}
                onFilterChange={(val) => {
                    setState({
                        ...state,
                        filter: val,
                    });
                }}
            />
            <InfiniteScroll
                style={{ overflow: "hidden !important" }}
                dataLength={state.list && state.list.length}
                next={() => {
                    onFilterChange({
                        ...state.filter,
                        PageNumber: defaultTo(state.filter?.PageNumber, 1) + 1,
                    });
                }}
                loader={<></>}
                hasMore={defaultTo(state.meta.HasNextPage, true)}
                scrollableTarget="scrollableDiv"
            >
                <AppTable
                    id={"scrollableDiv"}
                    heightToAdjust={185}
                    mediumViewAdjustment={105}
                    stickyHeader={true}
                    mobileViewAdjustment={105}
                    tableClass={"table-striped"}
                    hover={true}
                >
                    <thead>
                        <tr>
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(120)}
                                text={t("todoManagement.priority")}
                                sortColumn={TodoSortColumn.Priority}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    TodoSortColumn.Priority
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("todoManagement.task.name")}
                                sortColumn={TodoSortColumn.Task}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    TodoSortColumn.Task
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("todoManagement.description.name")}
                                sortColumn={TodoSortColumn.Description}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    TodoSortColumn.Description
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("todoManagement.area")}
                                sortColumn={TodoSortColumn.Area}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    TodoSortColumn.Area
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("todoManagement.assignee")}
                                sortColumn={TodoSortColumn.Assignee}
                                sortOrder={
                                    state.filter.SortColumn ===
                                    TodoSortColumn.Assignee
                                        ? state.filter.SortOrder
                                        : undefined
                                }
                                onClick={onSortChange}
                            />
                            <th style={getFixedCssWidths(210)}>
                                {t("todoManagement.creationDate")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("todoManagement.dueDate")}
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                {t("todoManagement.reOpen")}
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("todoManagement.doneSubmitted")}
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                {t("todoManagement.doneDate")}
                            </th>
                            <th style={getFixedCssWidths(60)} />
                            <th style={getFixedCssWidths(70)} />
                        </tr>
                    </thead>
                    <tbody>
                        <TodoManagementTableFilterRow
                            areas={
                                todoList && todoList.Data
                                    ? todoList.Data.BusinessAreas.map(
                                          (x: SelectItem) => {
                                              return {
                                                  label: x.Text,
                                                  value: x.Value,
                                              } as SimpleOption;
                                          }
                                      )
                                    : []
                            }
                            employees={
                                todoList && todoList.Data
                                    ? todoList.Data.BusinessEmployees.map(
                                          (x: SelectItem) => {
                                              return {
                                                  label: x.Text,
                                                  value: x.Value,
                                              } as SimpleOption;
                                          }
                                      )
                                    : []
                            }
                            filter={state.filter}
                            onFilterChange={(val) => {
                                if (val.PageNumber == state.meta.PageNumber) {
                                    val.PageNumber = 1;
                                }
                                setState({
                                    ...state,
                                    filter: val,
                                });
                            }}
                        />
                        <tr className="dummy-row">
                            <td colSpan={12} />
                        </tr>

                        {state.list &&
                            state.list.length > 0 &&
                            state.list.map((x, index) => (
                                <TodoManagementTableRow
                                    key={index}
                                    value={x}
                                    onFilterChange={() => refetchTodoList()}
                                />
                            ))}
                        {(todoListLoading || todoListRefetching) && (
                            <TodoManagementTableSkelton
                                count={
                                    state.list && state.list.length > 0 ? 2 : 12
                                }
                            />
                        )}
                        {state.list &&
                            state.list.length === 0 &&
                            !todoListLoading &&
                            !todoListRefetching && (
                                <tr>
                                    <td
                                        colSpan={12}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t("todoManagement.listEmpty")}
                                    </td>
                                </tr>
                            )}
                        <tr className="dummy-row">
                            <td colSpan={12} />
                        </tr>
                    </tbody>
                </AppTable>
            </InfiniteScroll>
        </div>
    );
};

export default TodoManagementTable;
