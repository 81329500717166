import { AppColoredMultiSelect } from "components/AppSelect";
import { SearchField } from "components/FormFields";
import { SEARCH_DELAY_TIME } from "globals/constants";
import { DocumentTemplateFilter } from "models/documentTemplates";
import { CustomColorItemOption } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./DocumentTemplateSearchSection.module.scss";

interface DocumentTemplateSearchSectionProps {
    filter: DocumentTemplateFilter;
    showTags: boolean;
    className?: string;
    onFilterChange: (filter: DocumentTemplateFilter) => void;
    selectListData: CustomColorItemOption[];
    loading: boolean;
    isReadonly?: boolean;
    onChange: (values: CustomColorItemOption[]) => void;
    selectedTags: CustomColorItemOption[];
}
export const DocumentTemplateSearchSection: React.FC<
    DocumentTemplateSearchSectionProps
> = ({
    filter,
    showTags,
    onFilterChange,
    className,
    selectListData,
    loading,
    isReadonly = false,
    onChange,
    selectedTags,
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState(filter.search);

    useEffect(() => {
        //when tab change internal state not change.
        if (search != filter.search && filter.search == "") {
            setSearch(filter.search);
        }
    }, [filter.search]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                onFilterChange({ ...filter, search: search });
            }, SEARCH_DELAY_TIME);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);
    return (
        <div className={`flex-space-between mb-3 ${className} ${styles.root}`}>
            <div className="d-flex align-items-center ">
                {showTags && (
                    <>
                        <span className={`mr-2 ${styles.tagTitle}`}>
                            {t("templateDocumentManager.tags")}:
                        </span>
                        <div style={{ width: "500px" }}>
                            <AppColoredMultiSelect
                                value={selectedTags}
                                onChange={onChange}
                                loading={loading}
                                data={selectListData}
                                isReadonly={isReadonly}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className={styles.searchDiv}>
                <SearchField
                    value={search}
                    onValueChange={(value) => setSearch(value)}
                    className={styles.searchField}
                />
            </div>
        </div>
    );
};

export default DocumentTemplateSearchSection;
