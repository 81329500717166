import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { BusinessType } from "models";
import { NavigationTab } from "models/general";
import {
    LW_Industry_AccessControl,
    LW_Industry_BasicData,
    LW_Industry_Contracts,
    LW_Industry_Courses,
    LW_Industry_Documents,
    LW_Industry_Functions,
    LW_Industry_Groups,
    // LW_Industry_Kontos,
    LW_Industry_PublicHolidays,
    LW_Industry_Shifts,
    LW_Industry_WorkingAreas,
    LW_Industry_WorkingDays,
    LW_Industry_IPads,
    LW_Industry_MealTemplates,
    LW_Industry_Settings,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export interface LwcIndustryTabsProps {
    onActiveTabChange: (tab: NavigationTab) => void;
}
export const LwcIndustryTabs: React.FC<LwcIndustryTabsProps> = ({
    onActiveTabChange,
}) => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const { urlForLwc: iframeRouteForLwc } = useIFrameRouting();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.lillywait
        .clients()
        .screens.industries.withId(lwcId);
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const getTranslation = (tabName: string) => t(`business.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(LW_Industry_BasicData),
                url: linkBase.edit(),
                iframeUrl: iframeRouteForLwc(
                    "BasicData",
                    BusinessType.INDUSTRY
                ), // to-do remove this and use BusinessEditFields
            },
            {
                label: getTranslation("contracts"),
                hasAccess: checkPermission([LW_Industry_Contracts]),
                url: linkBase.contracts(),
            },
            {
                label: getTranslation("accessControl"),
                hasAccess: checkPermission(LW_Industry_AccessControl),
                url: linkBase.accessControl.list(),
                iframeUrl: iframeRouteForLwc(
                    "AccessControl",
                    BusinessType.INDUSTRY
                ),
            },
            {
                label: getTranslation("workingGroups"),
                hasAccess: checkPermission(LW_Industry_Groups),
                url: linkBase.groups.list(),
                iframeUrl: iframeRouteForLwc("Groups", BusinessType.INDUSTRY),
            },
            {
                label: getTranslation("courses"),
                hasAccess: checkPermission(LW_Industry_Courses),
                url: linkBase.courses.list(),
            },
            {
                label: getTranslation("documents"),
                hasAccess: checkPermission(LW_Industry_Documents),
                url: linkBase.documents(),
                iframeUrl: iframeRouteForLwc(
                    "Documents",
                    BusinessType.INDUSTRY
                ),
            },
            {
                label: getTranslation("workingAreas"),
                hasAccess: checkPermission(LW_Industry_WorkingAreas),
                url: linkBase.areas.list(),
                iframeUrl: iframeRouteForLwc("Areas", BusinessType.INDUSTRY),
            },
            {
                label: getTranslation("shifts"),
                hasAccess: checkPermission(LW_Industry_Shifts),
                url: linkBase.shifts.list(),
                iframeUrl: iframeRouteForLwc("Shifts", BusinessType.INDUSTRY),
            },
            // {
            //     label: getTranslation("meal-templates"),
            //     hasAccess: checkPermission(LW_Industry_MealTemplates),
            //     url: linkBase.shifts.list(),
            //     iframeUrl: iframeRouteForLwc("MealTemplates",BusinessType.INDUSTRY),
            // },
            {
                label: getTranslation("functions"),
                hasAccess: checkPermission(LW_Industry_Functions),
                url: linkBase.functions.list(),
                iframeUrl: iframeRouteForLwc(
                    "Functions",
                    BusinessType.INDUSTRY
                ),
            },
            {
                label: getTranslation("publicHolidays"),
                hasAccess: checkPermission(LW_Industry_PublicHolidays),
                url: linkBase.publicHolidays(),
                iframeUrl: iframeRouteForLwc("Holidays", BusinessType.INDUSTRY),
            },
            {
                label: getTranslation("openingHours"),
                hasAccess: checkPermission(LW_Industry_WorkingDays),
                url: linkBase.workingHours(),
            },
            {
                label: getTranslation("settings"),
                hasAccess: checkPermission(LW_Industry_BasicData),
                url: linkBase.basicSettings(),
            },
            {
                label: getTranslation("chatSettings"),
                hasAccess: checkPermission(LW_Industry_Settings),
                url: linkBase.chatSettings(),
            },
            {
                label: getTranslation("timeTracking"),
                hasAccess: checkPermission(LW_Industry_BasicData),
                url: linkBase.timeTracking(),
            },
            {
                label: getTranslation("ipads"),
                hasAccess: checkPermission(LW_Industry_IPads),
                url: linkBase.ipads(),
                iframeUrl: iframeRouteForLwc("Ipads", BusinessType.INDUSTRY),
            },
            {
                label: getTranslation("externalIntegration"),
                hasAccess: checkPermission(LW_Industry_BasicData),
                url: linkBase.externalIntegration(),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={5}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default LwcIndustryTabs;
