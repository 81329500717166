import { isNil } from "lodash-es";
import {
    ColumnMapping,
    DraggableColItem,
    ImportColumns,
    ImportDataType,
    TargetColumnNames,
} from "models/importData";
import React, { useMemo } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ColumnDroppableList } from "./ColumnDroppableList";
import styles from "./ColumnsMappingDnD.module.scss";

export interface ColumnsMappingDnDProps {
    onChange: (mapping: ColumnMapping) => void;
    mapping: ColumnMapping;
    fileColumns: DraggableColItem[];
    importDataType: ImportDataType;
}

const UN_MAPPED = "unMapped";
const TypescriptDragDropContext: any = DragDropContext;
export const ColumnsMappingDnD: React.FC<ColumnsMappingDnDProps> = ({
    onChange,
    mapping,
    fileColumns,
    importDataType,
}) => {
    const { t } = useTranslation();
    const notMapped = useMemo(() => {
        const mappedIds = Object.values(mapping)
            .filter((x) => !isNil(x))
            .map((x) => x?.id);

        return fileColumns.filter((x) => !mappedIds.includes(x.id));
    }, [fileColumns, mapping]);

    const onDragEnd = (result: DropResult) => {
        const { source, destination, reason } = result;
        if (reason == "CANCEL" || !destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            const draggableId = result.draggableId;
            const draggedCol = fileColumns.find((x) => x.id == draggableId);
            let newUnMapped = notMapped.map((m) => m);
            const newMapping = { ...mapping };
            let success = true;
            if (destination.droppableId == UN_MAPPED) {
                // moved back to unMapped array
                if (draggedCol) {
                    newUnMapped.splice(destination.index, 0, draggedCol);
                }
            } else {
                if (isNil(mapping[destination.droppableId as ImportColumns])) {
                    newMapping[destination.droppableId as ImportColumns] =
                        draggedCol;
                } else {
                    success = false;
                }
            }
            if (success) {
                if (source.droppableId == UN_MAPPED) {
                    // remove from unMapped
                    newUnMapped = newUnMapped.filter(
                        (x) => x.id !== draggableId
                    );
                } else {
                    newMapping[source.droppableId as ImportColumns] = null;
                }

                onChange(newMapping);
            }
        }
    };

    return (
        <div className={styles.dndRoot}>
            <TypescriptDragDropContext onDragEnd={onDragEnd}>
                <div className={styles.horizontalListsContainer}>
                    {Object.keys(TargetColumnNames[importDataType]).map(
                        (targetCol) => {
                            const colData =
                                TargetColumnNames[importDataType][
                                    targetCol as ImportColumns
                                ];

                            return (
                                <ColumnDroppableList
                                    key={targetCol}
                                    items={
                                        !isNil(
                                            mapping[targetCol as ImportColumns]
                                        )
                                            ? ([
                                                  mapping[
                                                      targetCol as ImportColumns
                                                  ],
                                              ] as DraggableColItem[])
                                            : []
                                    }
                                    droppableId={targetCol}
                                    colName={t(colData.name).toString()}
                                    isRequired={colData.required}
                                />
                            );
                        }
                    )}
                </div>
                <ColumnDroppableList
                    items={notMapped}
                    droppableId={UN_MAPPED}
                    colName={t("importData.unMapped").toString()}
                />
            </TypescriptDragDropContext>
        </div>
    );
};

export default ColumnsMappingDnD;
