import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import { StartAndEndDates } from "./model";

export function validateStartEndDate(
    field: StartAndEndDates,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof StartAndEndDates> = {
        StartDate: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("common.startDate.missing"),
            },
            ...(field.EndDate
                ? [
                      {
                          rule: Validations.LESSER_DATE,
                          options: {
                              value: field.EndDate.clone().endOf("day"),
                          },
                      },
                  ]
                : []),
        ],
        EndDate: field.StartDate
            ? [
                  {
                      rule: Validations.GREATER_DATE,
                      options: {
                          value: field.StartDate.clone().startOf("day"),
                      },
                  },
              ]
            : [],
    };

    state = getInitializedValidityStateFromRules(validationRules, field, state);

    return state;
}
