import { AppContentHeader } from "components";
import AppLoader from "components/Loaders/AppLoader";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import LocationSettingTabs from "./partials/LocationSettingTabs";
import styles from "./LocationSettingsEditLayout.module.scss";
import { useEffect, useState } from "react";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useParams } from "react-router";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import {
    getLocationSettingsServiceKey,
    LocationSettingsService,
} from "services/lillywait/locationSettings";
import { LocationSetting } from "models/locationSetting/model";
import { parseLocationSettingResponse } from "models/locationSetting";
import { LocationSettingTabs } from "screens/lillywait/locationSettings/tabs";

export const LocationSettingsEditLayout: React.FC = () => {
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { locationId } = useParams();
    const { checkPermission } = useCheckPermission();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<NavigationTab | undefined>(
        undefined
    );

    const urlBase = linkProvider.lillywait.locationSettings();
    const locationSettingsService = new LocationSettingsService(urlBase.api);
    const {
        isLoading,
        isStale,
        data: response,
        error,
        refetch,
    } = useQuery(
        getLocationSettingsServiceKey("getBasicInfo", {
            locationId: locationId,
        }),
        async () => await locationSettingsService.getBasicInfo(locationId),
        {
            refetchOnWindowFocus: true,
            select: (data) =>
                data.Data ? parseLocationSettingResponse(data.Data) : null,
        }
    );
    useEffect(() => {
        if (locationId === ":locationId") {
            navigate(urlBase.screens.list(), {
                replace: true,
            });
        } else if (
            location.pathname.endsWith(`location-settings/${locationId}`) ||
            location.pathname.endsWith(`location-settings/${locationId}/`)
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(urlBase.screens.withId(locationId).edit(), {
                replace: true,
            });
        }
    }, [location.pathname, locationId]);

    useEffect(() => {
        if (!isLoading && (response || error)) {
            let hasError = !isNil(error);
            if (!hasError && response) {
                // id in url is wrong
                hasError = isNil(response);
            }
            if (hasError) {
                showErrorPage(404);
            }
        }
    }, [isLoading, response, error]);

    const data = !isLoading && response ? response : null;

    return isNil(data) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={data.Name}
                classNameForIcon="lnr-map-marker"
                hasGoBack={true}
                backUrl={urlBase.screens.list()}
            />
            <div className={styles.tabs}>
                <LocationSettingTabs
                    onActiveTabChange={setCurrentTab}
                    forBusiness={data.ForBusiness}
                />
                <div className={styles.content}>
                    <Outlet
                        context={
                            {
                                dataItem: data,
                                tab: currentTab,
                                refetchData: () => {
                                    refetch();
                                },
                            } as TabbedLayoutActiveItem<LocationSetting>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default LocationSettingsEditLayout;
