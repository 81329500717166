import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "localization/i18next";
import { getInitializedValidityState } from "models/general/validityState";
import { Moment } from "moment-timezone";
import { HqRegisterRequestStatus } from "./enum";
import { RegisterRequestBase } from "./model";

export interface LoginRequest {
    Email: string;
    Password: string;
}

export interface RegisterRequest extends RegisterRequestBase {
    Password: string;
    ConfirmPassword: string;
    CompanyName: string;
}

export interface ForgotPasswordRequest {
    Email: string;
}

export interface ResetPasswordRequest {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

export function getRegisterDefaultValue(Id?: number) {
    return {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        ConfirmPassword: "",
    } as RegisterRequest;
}

export function getResetPasswordDefaultValue(
    code: string | null,
    email: string | null
) {
    return {
        Code: code,
        Email: email,
        Password: "",
        ConfirmPassword: "",
    } as ResetPasswordRequest;
}

export function validateLogin(
    val: LoginRequest,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.email.required"),
            },
            {
                rule: Validations.EMAIL,
                message: i18next.t("auth.errorMessages.email.invalid"),
            },
        ],
        val.Email,
        "Email"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.password.required"),
            },
            {
                rule: Validations.PASSWORD,
            },
        ],
        val.Password,
        "Password"
    );
    return state;
}

export function validateRegister(
    val: RegisterRequest,
    isEmployeeSignup: boolean = false,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.firstName.required"),
            },
            {
                rule: Validations.MIN_LENGTH,
                options: {
                    value: 2,
                },
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 99,
                },
            },
        ],
        val.FirstName,
        "FirstName"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.lastName.required"),
            },
            {
                rule: Validations.MIN_LENGTH,
                options: {
                    value: 2,
                },
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 99,
                },
            },
        ],
        val.LastName,
        "LastName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.email.required"),
            },
            {
                rule: Validations.EMAIL,
            },
        ],
        val.Email,
        "Email"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.password.required"),
            },
            {
                rule: Validations.PASSWORD,
            },
        ],
        val.Password,
        "Password"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "auth.errorMessages.confirmPassword.required"
                ),
            },
            {
                rule: Validations.PASSWORD,
            },
            {
                rule: Validations.COMPARE,
                options: {
                    value: val.Password,
                },
                message: i18next.t(
                    "auth.errorMessages.confirmPassword.compare"
                ),
            },
        ],
        val.ConfirmPassword,
        "ConfirmPassword"
    );

    if (!isEmployeeSignup) {
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t(
                        "auth.errorMessages.companyName.required"
                    ),
                },
                {
                    rule: Validations.MIN_LENGTH,
                    options: {
                        value: 2,
                    },
                },
                {
                    rule: Validations.MAX_LENGTH,
                    options: {
                        value: 99,
                    },
                },
            ],
            val.CompanyName,
            "CompanyName"
        );
        state = applyValidations(
            state,
            [
                {
                    rule: Validations.REQUIRED,
                    message: i18next.t(
                        "auth.errorMessages.facilityName.required"
                    ),
                },
                {
                    rule: Validations.MIN_LENGTH,
                    options: {
                        value: 2,
                    },
                },
                {
                    rule: Validations.MAX_LENGTH,
                    options: {
                        value: 99,
                    },
                },
            ],
            val.FacilityName,
            "FacilityName"
        );
    }
    return state;
}

export function validateForgotPassword(
    val: ForgotPasswordRequest,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.email.required"),
            },
            {
                rule: Validations.EMAIL,
            },
        ],
        val.Email,
        "Email"
    );
    return state;
}

export function validateResetPassword(
    val: ResetPasswordRequest,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.email.required"),
            },
            {
                rule: Validations.EMAIL,
            },
        ],
        val.Email,
        "Email"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.errorMessages.password.required"),
            },
            {
                rule: Validations.PASSWORD,
            },
        ],
        val.Password,
        "Password"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "auth.errorMessages.confirmPassword.required"
                ),
            },
            {
                rule: Validations.PASSWORD,
            },
            {
                rule: Validations.COMPARE,
                options: {
                    value: val.Password,
                },
                message: i18next.t(
                    "auth.errorMessages.confirmPassword.compare"
                ),
            },
        ],
        val.ConfirmPassword,
        "ConfirmPassword"
    );
    return state;
}

export interface HqRegisterRequestModel extends RegisterRequestBase {
    Phone: string;
    LillywaitId: number;
    CreatedAt?: Moment; //For Register Request table View
    EncodedId: string; //For Register Request table View
    Status: HqRegisterRequestStatus; //For Register Request table View
    UserRegistrationCompleted?: boolean; //For Register Request table View
    AcceptTermsAndConditions?: boolean;
}

export const getHqRegisterRequestDefaultValue = (LillywaitId?: number) => {
    return {
        Phone: "",
        FirstName: "",
        LastName: "",
        Email: "",
        FacilityName: "",
        LillywaitId: 74, // for now Hard-code Id of 1st LillywaitHQ
        AcceptTermsAndConditions: false,
    } as HqRegisterRequestModel;
};

export function validateHqRegisterRequest(
    val: HqRegisterRequestModel,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.hqRegisterRequest.firstName.required"),
            },
            {
                rule: Validations.MIN_LENGTH,
                options: {
                    value: 2,
                },
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 99,
                },
            },
        ],
        val.FirstName,
        "FirstName"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.hqRegisterRequest.lastName.required"),
            },
            {
                rule: Validations.MIN_LENGTH,
                options: {
                    value: 2,
                },
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 99,
                },
            },
        ],
        val.LastName,
        "LastName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.hqRegisterRequest.email.required"),
            },
            {
                rule: Validations.EMAIL,
            },
        ],
        val.Email,
        "Email"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("auth.hqRegisterRequest.phone.required"),
            },
        ],
        val.Phone,
        "Phone"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "auth.hqRegisterRequest.facilityName.required"
                ),
            },
            {
                rule: Validations.MIN_LENGTH,
                options: {
                    value: 2,
                },
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 99,
                },
            },
        ],
        val.FacilityName,
        "FacilityName"
    );

    return state;
}
