import classNames from "classnames";
import { AppButton } from "components/Buttons";
import { AppNumberInput } from "components/FormFields";
import { defaultTo } from "lodash-es";
import { ReminderUnit } from "models/appReminder";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TodoManagementSendRemainder.module.scss";

export interface TodoManagementSendRemainderProp {
    readonly: boolean;
    unitValue: number;
    activeButton: ReminderUnit;
    className?: string;
    onUnitValueChange: (val: number | null) => void;
    onSelectButtonChange: (val: ReminderUnit) => void;
}

const remainderUnitList = Object.keys(ReminderUnit)
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x))
    .sort((n1, n2) => n2 - n1);

const Component: React.FC<TodoManagementSendRemainderProp> = ({
    readonly,
    unitValue,
    activeButton,
    className = "",
    onUnitValueChange,
    onSelectButtonChange,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`${styles.displayFlex} ${className}`}
            style={{ maxWidth: "100%" }}
        >
            <AppNumberInput
                className={classNames("mr-2 mt-1 mb-1", styles.numberField)}
                readonly={readonly}
                showLabel={false}
                value={defaultTo(unitValue, 0)}
                format={"0"}
                placeholder={""}
                onChange={readonly ? () => {} : onUnitValueChange}
            />
            {remainderUnitList.map((x, idx) => (
                <AppButton
                    padding="low"
                    style={{ height: "44px", maxWidth: "92px" }}
                    key={idx}
                    disabled={readonly}
                    active={activeButton === (x as ReminderUnit)}
                    className={classNames(
                        styles.filterButton,
                        {
                            [styles.active]:
                                activeButton == (x as ReminderUnit),
                        },
                        "mr-2"
                    )}
                    onClick={() => {
                        if (!readonly) {
                            onSelectButtonChange(x as ReminderUnit);
                        }
                    }}
                >
                    {t(
                        `common.${ReminderUnit[x]
                            .toString()
                            .toLocaleLowerCase()}`
                    )}
                </AppButton>
            ))}
        </div>
    );
};

Component.displayName = "TodoManagementSendRemainder";
export const TodoManagementSendRemainder = React.memo(Component);
export default TodoManagementSendRemainder;
