import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { defaultTo } from "lodash-es";
import { Templatable } from "models/general";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general/validityState";
import { DocumentTemplateFilters, EditableSection } from "./model";

export function validateEditableSectionRow(
    item: EditableSection,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof Templatable> = {
        Template: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("editableSections.missing"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(item.Data, {
            Template: null,
            Text: null,
            Placeholders: [],
        }),
        state
    );

    return state;
}

export function validateEditableSectionFields(
    items: EditableSection[],
    state = getInitializedValidityState([], [])
) {
    let manager = new ValidityStateManager(state);
    items.forEach((field) => {
        const id = field.Uuid;
        manager = manager.replaceFieldState(id, {
            errors: [],
            identifier: id,
            children: validateEditableSectionRow(
                field,
                manager.getFieldState(id)?.children
            ),
        });
    });
    return manager.state;
}

export function validateAdditionalFilters(
    filters: DocumentTemplateFilters,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof DocumentTemplateFilters> = {
        ClientsOlderThanMonths: [
            {
                rule: Validations.MIN,
                message: i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: 1,
                },
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        validationRules,
        filters,
        state
    );

    return state;
}
