import {
    ValidationRules,
    Validations,
    getInitializedValidityStateFromRules,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    getInitializedValidityState,
    validateAddressFields,
    ValidityStateManager,
} from "models/general";
import { CreditorSortColumn } from "./enum";
import { BusinessCreditor } from "./model";

export const getCreditorSortColumnKeyFromEnum = (
    sortColumn: CreditorSortColumn | undefined
): keyof BusinessCreditor => {
    switch (sortColumn) {
        case CreditorSortColumn.Name:
            return "Name";
        case CreditorSortColumn.Email:
            return "Email";
        case CreditorSortColumn.Telephone:
            return "Telephone";
        default:
            return "Name";
    }
};
export function validateBusinessCreditor(
    creditor: BusinessCreditor,
    state = getInitializedValidityState([], [])
) {
    const valueValidationRules: ValidationRules<keyof BusinessCreditor> = {
        Name: [
            {
                rule: Validations.REQUIRED,
            },
        ],
        Email: [
            {
                rule: Validations.REQUIRED,
            },
            {
                rule: Validations.EMAIL,
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        creditor,
        state
    );
    let manager = new ValidityStateManager(state);
    if (creditor.Address) {
        const validations = validateAddressFields(creditor.Address);
        manager = manager.addChildren("Address", validations);
    }
    return manager.state;
}
