import React from "react";

export interface AppHelmetProps {
    title?: string;
}

const getBrowserTabTitle = (name: string | undefined) => {
    return `${name && name.length > 0 ? name + " | " : ""}Lillywait`;
};
export const AppHelmet: React.FC<AppHelmetProps> = ({ title }) => {
    return (
        <>
            <title>{getBrowserTabTitle(title)}</title>
        </>
    );
};

export default AppHelmet;
