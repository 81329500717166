export enum AccessPointAction {
    IN = "IN",
    OUT = "OUT",
    START = "START",
    STOP = "STOP",
}
export enum ControllerType {
    Main = "Main",
    Break = "Break",
}
