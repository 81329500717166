import { Feature_Business_ModuleSelect } from "models/applicationFeatures";
import { PermissionAccessTypes } from "../enum";
import {
    Business_BasicData,
    Business_Calendar,
    Business_Contracts,
    Business_Creditor_BasicData,
    Business_Dashboard_Client,
    Business_Dashboard_Employee,
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_DataImport_Employee,
    Business_Downloads,
    Business_Employee_App,
    Business_Employee_BasicData,
    Business_Employee_Documents,
    Business_Employee_Groups,
    Business_Employee_SalaryStatement,
    Business_Ipads,
    Business_Logo,
    Business_MealTemplates,
    Business_Payroll_Absences,
    Business_RoleManagement_UserRoles_Mobile,
    Business_TimeTracking_Clients,
    Business_TimeTracking_Employees,
    Business_TimeTracking_Suppliers,
    Business_WebNotification,
    Business_WorkingHours,
    GeneralUserPasswordChange,
    GeneralUserProfileUpdate,
    Ipad_TimeTracking_Clients,
    Ipad_TimeTracking_Employees,
    Kindergarten_Client_App,
    Kindergarten_Client_BasicData,
    Kindergarten_Client_Groups,
    Kindergarten_Client_Contracts,
    Kindergarten_Client_MedicalData,
    LocationSettings_BasicData,
    LocationSettings_ExplanationMode,
    LocationSettings_MealTemplates,
    LocationSettings_WorkingDays,
    LW_ElderlyCare_BasicData,
    LW_ElderlyCare_Contracts,
    LW_ElderlyCare_Documents,
    LW_ElderlyCare_WorkingDays,
    LW_Kindergarten_BasicData,
    LW_Kindergarten_Contracts,
    LW_Kindergarten_Documents,
    Kindergarten_Occupancy_Planing,
    LW_Kindergarten_WorkingDays,
    // LW_Kindergarten_WebRegistration,
    LW_Industry_BasicData,
    LW_Industry_Contracts,
    LW_Industry_Documents,
    LW_Industry_WorkingDays,
    LW_School_BasicData,
    LW_School_Contracts,
    LW_School_Documents,
    LW_School_WorkingDays,
    LW_Support_ElderlyCare,
    LW_Support_Kindergartens,
    LW_Support_MergeCompanies,
    LW_Support_Schools,
    Mobile_Calendar_Events,
    Mobile_Chat,
    Mobile_Messages,
    LW_Support_Industry,
    Kindergarten_Age_Group_Planing,
} from "./constants";

export const ModulesWithoutAccess: {
    [key in PermissionAccessTypes]: string[];
} = {
    [PermissionAccessTypes.CREATE]: [
        Mobile_Messages,
        Mobile_Chat,
        Mobile_Calendar_Events,
        Ipad_TimeTracking_Employees,
        Ipad_TimeTracking_Clients,
        GeneralUserProfileUpdate,
        GeneralUserPasswordChange,
        Business_Payroll_Absences,
        Feature_Business_ModuleSelect,
        Business_TimeTracking_Employees,
        Business_TimeTracking_Suppliers,
        Business_TimeTracking_Clients,
        Business_Dashboard_Client,
        Business_Dashboard_Employee,
        Business_Employee_Groups,
        Kindergarten_Occupancy_Planing,
        Kindergarten_Age_Group_Planing,
        LW_School_Contracts,
        LW_School_WorkingDays,
        LW_ElderlyCare_Contracts,
        LW_ElderlyCare_WorkingDays,
        LW_Kindergarten_Contracts,
        LW_Kindergarten_WorkingDays,
        LW_Industry_Contracts,
        LW_Industry_WorkingDays,
        // LW_Kindergarten_CompensationTemplates,
        // LW_Kindergarten_EmployeeKeyCalculation,
        // LW_Kindergarten_CareScopes,
        // LW_Kindergarten_AgeGroups,
        LW_Support_MergeCompanies,
        LW_Support_Kindergartens,
        LW_Support_Industry,
        LW_Support_Schools,
        LW_Support_ElderlyCare,
        LocationSettings_WorkingDays,
        LocationSettings_ExplanationMode,
        Business_Logo,
        Business_Ipads,
        Business_WorkingHours,
        Business_RoleManagement_UserRoles_Mobile,
        Business_Employee_Groups,
        Kindergarten_Client_Contracts,
        Kindergarten_Client_Groups,
        Kindergarten_Client_MedicalData,
        Business_Employee_App,
        Kindergarten_Client_App,
        Business_WebNotification,
        Business_Downloads,
    ],
    [PermissionAccessTypes.EDIT]: [
        Mobile_Messages,
        Mobile_Calendar_Events,
        Ipad_TimeTracking_Employees,
        Ipad_TimeTracking_Clients,
        Business_TimeTracking_Clients,
        Business_Dashboard_Employee,
        Business_Dashboard_Client,
        Business_Employee_Documents,
        LW_School_Contracts,
        LW_School_Documents,
        LW_ElderlyCare_Contracts,
        LW_ElderlyCare_Documents,
        Kindergarten_Age_Group_Planing,
        Kindergarten_Occupancy_Planing,
        Business_Payroll_Absences,
        LW_Kindergarten_Contracts,
        LW_Kindergarten_Documents,
        LW_Industry_Contracts,
        LW_Industry_Documents,
        LW_Support_Kindergartens,
        LW_Support_Industry,
        LW_Support_Schools,
        LW_Support_ElderlyCare,
        Business_DataImport_Client,
        Business_DataImport_ClientRegistration,
        Business_DataImport_Employee,
        Business_TimeTracking_Clients,
    ],
    [PermissionAccessTypes.DELETE]: [
        Mobile_Chat,
        Ipad_TimeTracking_Clients,
        Ipad_TimeTracking_Employees,
        Business_Payroll_Absences,
        GeneralUserProfileUpdate,
        GeneralUserPasswordChange,
        Feature_Business_ModuleSelect,
        Business_TimeTracking_Employees,
        Business_TimeTracking_Suppliers,
        Kindergarten_Age_Group_Planing,
        Business_TimeTracking_Clients,
        Business_Dashboard_Client,
        Business_Dashboard_Employee,
        Kindergarten_Occupancy_Planing,
        Business_Employee_Groups,
        Business_Employee_SalaryStatement,
        LW_School_Contracts,
        LW_School_Documents,
        LW_School_WorkingDays,
        LW_ElderlyCare_Contracts,
        LW_ElderlyCare_Documents,
        LW_ElderlyCare_WorkingDays,
        LW_Industry_Contracts,
        LW_Industry_Documents,
        LW_Industry_WorkingDays,
        LW_Kindergarten_Contracts,
        LW_Kindergarten_Documents,
        LW_Kindergarten_WorkingDays,
        // LW_Kindergarten_CompensationTemplates,
        // LW_Kindergarten_EmployeeKeyCalculation,
        // LW_Kindergarten_CareScopes,
        // LW_Kindergarten_AgeGroups,
        // LW_Kindergarten_WebRegistration,
        LW_Support_MergeCompanies,
        LW_Support_Kindergartens,
        LW_Support_Industry,
        LW_Support_Schools,
        LW_Support_ElderlyCare,
        LocationSettings_WorkingDays,
        Business_Contracts,
        Business_Dashboard_Employee,
        Business_Dashboard_Client,
        Business_TimeTracking_Clients,
        Business_WorkingHours,
        Business_Employee_Groups,
        Kindergarten_Client_Contracts,
        Kindergarten_Client_Groups,
        Kindergarten_Client_MedicalData,
        Business_Employee_App,
        Kindergarten_Client_App,
    ],
    [PermissionAccessTypes.SHOW]: [LW_Support_MergeCompanies],
};

export const ModuleMandatoryAccess: {
    [key in string]: {
        Identifier: string;
        AccessLevel: PermissionAccessTypes;
    }[];
} = {
    ["Business"]: [
        {
            Identifier: Business_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["Business_Employee"]: [
        {
            Identifier: Business_Employee_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["Business_General.Calendar"]: [
        {
            Identifier: Business_Calendar,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["Kindergarten_Client"]: [
        {
            Identifier: Kindergarten_Client_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["Business_General.Creditors"]: [
        {
            Identifier: Business_Creditor_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    //meal template have business meal template base and business data mandatory
    ["Business.Meal_Templates"]: [
        {
            Identifier: Business_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
        {
            Identifier: Business_MealTemplates,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LW_Client.Kindergarten"]: [
        {
            Identifier: LW_Kindergarten_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LW_Client.Industry"]: [
        {
            Identifier: LW_Industry_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LW_Client.School"]: [
        {
            Identifier: LW_School_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LW_Client.ElderlyCare"]: [
        {
            Identifier: LW_ElderlyCare_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LocationSettings"]: [
        {
            Identifier: LocationSettings_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
    ["LocationSettings.Meal_Templates"]: [
        {
            Identifier: LocationSettings_BasicData,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
        {
            Identifier: LocationSettings_MealTemplates,
            AccessLevel: PermissionAccessTypes.SHOW,
        },
    ],
};
