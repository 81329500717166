import StartAndEndDateFields from "commonPartials/StartAndEndDateFields";
import { AppButton } from "components/Buttons";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import {
    BusinessContractData,
    validateRenewBusinessContract,
} from "models/business";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import moment from "moment-timezone";
import React, { useState, useMemo } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { BusinessContractService } from "services/business";
import styles from "./RenewContractFields.module.scss";

export interface RenewContractFieldsProps {
    businessId: number;
    onSuccess: () => void;
    onCancel?: () => void;
    showTitle?: boolean;
}
export const RenewContractFields: React.FC<RenewContractFieldsProps> = ({
    businessId,
    onSuccess,
    onCancel,
    showTitle = false,
}) => {
    const { t } = useTranslation();

    const [state, setState] = useState<BusinessContractData>({
        StartDate: moment().startOf("month"),
        EndDate: null,
        BusinessId: businessId,
    } as BusinessContractData);

    const validityStateManager = useMemo(() => {
        if (state) {
            const validationState = validateRenewBusinessContract(state);
            return new ValidityStateManager(validationState);
        }
        return new ValidityStateManager(getInitializedValidityState([], []));
    }, [state]);

    const { linkProvider } = useRouting();
    const contractService = new BusinessContractService(
        linkProvider.business.api.currentBusiness().contracts
    );

    const { isLoading: renewContractLoading, mutate: renewContract } =
        useMutation(
            async (data: BusinessContractData) =>
                await contractService.renewContract(data),
            {
                onSuccess: (res) => {
                    if (res.Data) {
                        onSuccess();
                    } else if (res.Message) {
                        showSweetAlertToast(
                            t("common.error.error"),
                            res.Message,
                            "error"
                        );
                    } else {
                        showSweetAlertToast(
                            t("common.error.error"),
                            t("common.actions.errors.unableToUpdate"),
                            "error"
                        );
                    }
                },
                onError: () => {
                    showUnexpectedErrorToast();
                },
            }
        );

    return (
        <div>
            {showTitle && <h5>{t("businessContract.renew.title")}</h5>}
            <Row>
                <StartAndEndDateFields
                    useMonthPicker={true}
                    appendToBody={true}
                    minStartDate={moment().startOf("month")}
                    value={{
                        StartDate: state.StartDate,
                        EndDate: state.EndDate,
                    }}
                    onChange={({ StartDate: StartDate, EndDate: EndDate }) => {
                        setState({
                            ...state,
                            StartDate: StartDate,
                            EndDate: EndDate,
                        });
                    }}
                />
            </Row>
            <div className={styles.footer}>
                {onCancel && (
                    <AppButton
                        variant="light"
                        className={styles.cancel}
                        onClick={onCancel}
                    >
                        {t("common.cancel")}
                    </AppButton>
                )}
                <AppButton
                    padding="med"
                    disabled={
                        renewContractLoading ||
                        !validityStateManager.isStateValid()
                    }
                    onClick={() => renewContract(state)}
                >
                    {t("common.saveChanges")}
                </AppButton>
            </div>
        </div>
    );
};

export default RenewContractFields;
