import { HubConnection } from "@microsoft/signalr";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { cookieAvailable } from "globals/helpers/cookieHelper";
import {
    getInitialLocale,
    initNumeralAndMoment,
    setTimezone,
    stringToAppLocale,
} from "globals/helpers/localizationHelpers";
import { isNil } from "lodash-es";
import {
    BusinessContext,
    BusinessSettingResponse,
    LoginResponse,
    MeResponse,
    parseBusinessSettingResponse,
    UserCredentials,
} from "models";
import { AppLocale, AxiosCommonHeaders, CookiesKey } from "models/general/enum";
import { parseLocationSettingResponse } from "models/locationSetting/response";
import moment from "moment";
import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { initAxios } from "services/helpers";
import { Client } from "twilio-chat";

//userCredentials dost have an culture attribute
//but creating it out aside again to set the default value
export interface SupportViewData {
    isSupportViewEnabled: boolean;
    actualSessionBusinessInfo: BusinessContext;
}
export interface AppMainContext {
    isContextLoaded: boolean;
    userInfo: MeResponse | null;
    userCredentials: UserCredentials;
    supportViewData: SupportViewData | null;
    locale: AppLocale;
    rememberMe: boolean | null;
    signalRInstance: HubConnection | null;
    signalREventInstance: HubConnection | null;
    twilioClient: Client | null;
    sessionTimeOut: boolean | null;
    setSupportViewData: (data: SupportViewData | null) => void;
    setUserInfo: (user: MeResponse | null) => void;
    setUserCredentials: (value: UserCredentials) => void;
    setLocale: (value: string) => void;
    setSessionTimeOut: (value: boolean) => void;
    setIsContextLoaded: (value: boolean) => void;
    setRememberMe: (value: boolean | null) => void;
    setSignalRInstance: (value: HubConnection | null) => void;
    setSignalREventInstance: (value: HubConnection | null) => void;
    setTwilioClient: (value: Client | null) => void;
}
const AppContextProviderDefaultValue: AppMainContext = {
    isContextLoaded: !cookieAvailable(CookiesKey.USER_COOKIE), // if cookie is available it should be false
    userInfo: null,
    userCredentials: null,
    rememberMe: false,
    supportViewData: null,
    sessionTimeOut: null,
    signalRInstance: null,
    twilioClient: null,
    signalREventInstance: null,
    locale: getInitialLocale(),
    setSessionTimeOut: () => {},
    setSupportViewData: () => {},
    setUserInfo: () => {},
    setRememberMe: () => {},
    setIsContextLoaded: () => {},
    setUserCredentials: () => {},
    setSignalRInstance: () => {},
    setSignalREventInstance: () => {},
    setTwilioClient: () => {},
    setLocale: () => {},
};
const AppContext = createContext<AppMainContext>(
    AppContextProviderDefaultValue
);

export const AppContextProvider: React.FC = ({ children }) => {
    const { i18n } = useTranslation();
    const defaultContext: AppMainContext = AppContextProviderDefaultValue;

    const [userInfo, setUserState] = useState<MeResponse | null>(
        defaultContext.userInfo
    );
    const [userCredentials, setCredentials] = useState<LoginResponse | null>(
        defaultContext.userCredentials
    );
    const [language, setLanguage] = useState<AppLocale>(defaultContext.locale);
    const [rememberMe, setRememberMe] = useState<boolean | null>(
        defaultContext.rememberMe
    );
    const [signalRInstance, setSignalRInstance] =
        useState<HubConnection | null>(defaultContext.signalRInstance);
    const [twilioClient, setTwilioClient] = useState<Client | null>(
        defaultContext.twilioClient
    );
    const [signalREventInstance, setSignalREventInstance] =
        useState<HubConnection | null>(defaultContext.signalREventInstance);
    const [isContextLoaded, setIsContextLoaded] = useState<boolean>(
        defaultContext.isContextLoaded
    );
    const [sessionTimeOut, setSessionTimeOut] = useState<boolean | null>(
        defaultContext.sessionTimeOut
    );
    const [supportViewData, setSupportViewData] =
        useState<SupportViewData | null>(defaultContext.supportViewData);

    return (
        <AppContext.Provider
            value={
                {
                    sessionTimeOut: sessionTimeOut,
                    userInfo: userInfo,
                    locale: language,
                    twilioClient: twilioClient,
                    signalRInstance: signalRInstance,
                    signalREventInstance: signalREventInstance,
                    isContextLoaded: isContextLoaded,
                    userCredentials: userCredentials,
                    supportViewData: supportViewData,
                    rememberMe: rememberMe,
                    setSessionTimeOut: setSessionTimeOut,
                    setSupportViewData: setSupportViewData,
                    setSignalRInstance: setSignalRInstance,
                    setTwilioClient: setTwilioClient,
                    setSignalREventInstance: setSignalREventInstance,
                    setUserInfo: (data: MeResponse | null) => {
                        const processedData = data;
                        if (!isNil(data) && processedData) {
                            if (
                                data.SessionBusiness &&
                                data.SessionBusiness.Timezone
                            ) {
                                // change active timezone
                                setTimezone(data.SessionBusiness.Timezone);
                            }
                            if (data.SessionBusiness) {
                                processedData.SessionBusiness = {
                                    ...data.SessionBusiness,
                                    BusinessSetting:
                                        parseBusinessSettingResponse(
                                            data.SessionBusiness
                                                .BusinessSetting as BusinessSettingResponse
                                        ),
                                };
                            }
                            if (data.SessionBusinessLocation) {
                                processedData.SessionBusinessLocation =
                                    parseLocationSettingResponse(
                                        data.SessionBusinessLocation as any
                                    );
                            }
                            if (data.CurrentContractModules) {
                                processedData.CurrentContractModules = {
                                    ...data.CurrentContractModules,
                                    MonthYear: moment(
                                        data.CurrentContractModules.MonthYear,
                                        ISO8601_DATE_FORMAT
                                    ),
                                };
                            }
                        }
                        setUserState(processedData);
                    },
                    setIsContextLoaded: setIsContextLoaded,
                    setLocale: (lng: string) => {
                        const locale = stringToAppLocale(lng);
                        initAxios(AxiosCommonHeaders.ACCEPT_LANGUAGE, lng);

                        i18n.changeLanguage(locale.substring(0, 2));
                        setLanguage(locale);
                        initNumeralAndMoment(locale);
                    },
                    setUserCredentials: setCredentials,
                    setRememberMe: setRememberMe,
                } as AppMainContext
            }
        >
            {children}
        </AppContext.Provider>
    );
};
export const useAppContext = () => useContext(AppContext);
