import { AppTable, AppTableSkeleton } from "components/Table";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import useDeleteRow from "hooks/useDeleteRow";
import { defaultTo } from "lodash-es";
import {
    Business_Employee_BasicData,
    EmployeeListFilters,
    EmployeeListItem,
    EmployeeSortColumn,
    PermissionAccessTypes,
} from "models";
import { Optional, TableSort } from "models/general";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    BusinessEmployeeService,
    getBusinessEmployeeServiceKey,
} from "services/business";
import { BusinessEmployeeTableFilter } from "./BusinessEmployeeTableFilters";
import { BusinessEmployeeSortingColumns } from "./BusinessEmployeeTableHeader";
import { BusinessEmployeeTableRows } from "./BusinessEmployeeTableRows";
export interface EmployeeTableProps {
    value: Optional<EmployeeListItem[]>;
    sort: TableSort<EmployeeSortColumn>;
    loading: boolean;
    filterValues: EmployeeListFilters;
    completeResponse?: EmployeeListItem[];
    onChangeCompleteResponse?: (val: EmployeeListItem[]) => void;
    onSortChange?: (sort: TableSort<EmployeeSortColumn>) => void;
    onEditClick: (encodedEmployeeId: string) => void;
    onFilterChange: (value: EmployeeListFilters) => void;
    onChange: (list: EmployeeListItem[]) => void;
}

const COLUMNS_COUNT = 16;
export const BusinessEmployeeTable: React.FC<EmployeeTableProps> = ({
    value: data,
    loading,
    filterValues,
    sort,
    completeResponse,
    onChangeCompleteResponse,
    onSortChange,
    onEditClick,
    onFilterChange,
    onChange,
}) => {
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const employeeService = new BusinessEmployeeService(
        linkProvider.business.employees().api
    );
    const { setDeleteInfo, handleResponse: handleDeleteResponse } =
        useDeleteRow();

    const {
        isLoading: deleting,
        mutate: deleteEmployee,
        data: deleteResponse,
        reset: resetDelete,
        error: deleteError,
    } = useMutation(
        getBusinessEmployeeServiceKey("delete"),
        async (id: string) => await employeeService.delete(id)
    );

    const canDelete = checkPermission(Business_Employee_BasicData, [
        PermissionAccessTypes.DELETE,
    ]);

    const onDeleteSuccess = useCallback(
        (id: number) => {
            if (onChangeCompleteResponse && completeResponse) {
                onChangeCompleteResponse(
                    completeResponse?.filter((c) => c.Id !== id)
                );
            }
        },
        [onChangeCompleteResponse, completeResponse]
    );
    useEffect(() => {
        if (!deleting && (deleteResponse || deleteError)) {
            handleDeleteResponse(onDeleteSuccess, deleteResponse, deleteError);
            resetDelete();
        }
    }, [
        deleteResponse,
        deleting,
        handleDeleteResponse,
        deleteError,
        onDeleteSuccess,
    ]);
    let allSelected = false;
    if (data && data.length > 0) {
        allSelected = data.filter((x) => !x.isChecked).length == 0;
    }

    return (
        <>
            <AppTable
                id={"scrollableDiv"}
                tableClass={"table-striped"}
                hover={true}
                stickyHeader={true}
            >
                <thead>
                    <BusinessEmployeeSortingColumns
                        onSortChange={defaultTo(onSortChange, () => {})}
                        sort={sort}
                        allSelected={allSelected}
                        onCheckAll={(val: boolean) => {
                            data &&
                                onChange &&
                                onChange(
                                    data.map((x) => {
                                        return {
                                            ...x,
                                            isChecked: val,
                                        };
                                    })
                                );
                        }}
                    />
                </thead>
                <tbody>
                    <BusinessEmployeeTableFilter
                        values={filterValues}
                        data={defaultTo(completeResponse, [])}
                        onFilterChange={defaultTo(onFilterChange, () => {})}
                        loading={loading}
                    />
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>

                    {data &&
                        data.length > 0 &&
                        data.map((x) => (
                            <BusinessEmployeeTableRows
                                onEditClick={onEditClick}
                                onDelete={() => {
                                    if (canDelete) {
                                        deleteEmployee(x.EncodedId);
                                        setDeleteInfo({
                                            id: x.EncodedId,
                                            displayName: x.Name,
                                        });
                                    }
                                }}
                                key={x.Id}
                                value={x}
                                canDelete={canDelete}
                                loading={loading || deleting}
                                onSelectChange={(val: boolean, id: number) => {
                                    onChange(
                                        data.map((oV) => {
                                            if (oV.Id == id) {
                                                oV.isChecked = val;
                                                return oV;
                                            } else {
                                                return oV;
                                            }
                                        })
                                    );
                                }}
                            />
                        ))}

                    {loading && (
                        <AppTableSkeleton
                            rows={data && data.length > 0 ? 2 : 12}
                            columns={COLUMNS_COUNT}
                        />
                    )}
                    {data && data.length == 0 && !loading && (
                        <tr>
                            <td
                                colSpan={COLUMNS_COUNT}
                                style={{ textAlign: "center" }}
                            >
                                {t("common.noDataFound")}
                            </td>
                        </tr>
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </>
    );
};

export default BusinessEmployeeTable;
