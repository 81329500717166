import { AppSelectOld } from "components/AppSelect";
import {
    AppDatePicker,
    AppInputField,
    AppNumberInput,
} from "components/FormFields";
import { appTableFilterSelectStyles } from "components/Table/AppTable";
import { FORMAT_INTEGER } from "globals/constants";
import { getUniqueList } from "globals/helpers/generalHelper";
import { isNil } from "lodash-es";
import { EmployeeListFilters, EmployeeListItem, EmployeeStatus } from "models";
import { BoolToYesNo, SelectItem } from "models/general";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
export interface BusinessEmployeeTableFilterProps {
    values: EmployeeListFilters;
    data: EmployeeListItem[];
    onFilterChange: (values: EmployeeListFilters) => void;
    loading: boolean;
}
export const BusinessEmployeeTableFilter: React.FC<
    BusinessEmployeeTableFilterProps
> = ({ onFilterChange, values, data, loading }) => {
    const { t } = useTranslation();
    const allOption: SelectItem = {
        Text: t("common.all"),
        Value: null,
    };
    const statusOptions: SelectItem[] = [
        allOption,
        ...Object.values(EmployeeStatus).map((x) => {
            return {
                Text: t(`common.status.${x.toString()}`),
                Value: x.toString(),
            } as SelectItem;
        }),
    ];

    const { areaOptions, functionOptions, groupsOptions } = useMemo(() => {
        return {
            groupsOptions: [
                allOption,
                ...(data
                    ? [
                          ...data
                              .filter((x) => Boolean(x.GroupId))
                              .map((x) => {
                                  return { Text: x.Group, Value: x.GroupId };
                              }),
                      ]
                    : []),
            ] as SelectItem[],
            areaOptions: [
                allOption,
                ...(data
                    .map((x) => {
                        return x.Area
                            ? ({
                                  Text: x.Area.Name,
                                  Value: x.Area.Id,
                              } as SelectItem)
                            : undefined;
                    })
                    .filter((x) => !isNil(x)) as SelectItem[]),
            ],
            functionOptions: [
                allOption,
                ...(data
                    .map((x) => {
                        return x.Function
                            ? ({
                                  Text: x.Function.Name,
                                  Value: x.Function.Id,
                              } as SelectItem)
                            : undefined;
                    })
                    .filter((x) => !isNil(x)) as SelectItem[]),
            ],
        };
    }, [data, allOption]);

    const appOptions: SelectItem[] = [
        allOption,
        ...Object.values(BoolToYesNo)
            .filter((x) => typeof x === "number")
            .map((x) => {
                return {
                    Text: t(`common.${x === 1 ? "yes" : "no"}`),
                    Value: x.toString(),
                } as SelectItem;
            }),
    ];

    return (
        <tr className="filters-row">
            <td className="default-padding">
                <AppNumberInput
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    format={FORMAT_INTEGER}
                    showPlaceHolder={false}
                    value={values?.DisplayId}
                    onChange={(e) => {
                        onFilterChange({ ...values, DisplayId: e });
                    }}
                />
            </td>
            <td>
                <AppInputField
                    showClearButton={true}
                    value={values.Name}
                    onValueChange={(val) =>
                        onFilterChange({ ...values, Name: val })
                    }
                />
            </td>
            <td>
                <AppDatePicker
                    showLabel={false}
                    showCalenderIcon={false}
                    appendToBody={true}
                    showClearButton={true}
                    value={values.ContractStart}
                    onChange={(date) =>
                        onFilterChange({ ...values, ContractStart: date })
                    }
                    useDefault={false}
                />
            </td>
            <td>
                <AppDatePicker
                    showLabel={false}
                    showCalenderIcon={false}
                    showClearButton={true}
                    value={values.ContractEnd}
                    appendToBody={true}
                    onChange={(date) =>
                        onFilterChange({ ...values, ContractEnd: date })
                    }
                    useDefault={false}
                />
            </td>
            <td>
                <AppSelectOld
                    menuPortalTarget={document.body}
                    options={statusOptions}
                    styles={appTableFilterSelectStyles}
                    showDropdownIndicator={isNil(values.Status)}
                    showCustomClearIndicator={true}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        values.Status == null
                            ? allOption
                            : statusOptions.find(
                                  (x) => x.Value === values.Status
                              )
                    }
                    onChange={(e: SelectItem) => {
                        onFilterChange({
                            ...values,
                            Status: e ? (e.Value as EmployeeStatus) : null,
                        });
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.GroupId)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(groupsOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={groupsOptions.find(
                        (x) => x.Value === values.GroupId
                    )}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        e &&
                        onFilterChange({
                            ...values,
                            GroupId: e.Value as number,
                        })
                    }
                />
            </td>
            <td>
                <AppSelectOld
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    showDropdownIndicator={isNil(values.EmployeeFunctionId)}
                    showCustomClearIndicator={true}
                    options={getUniqueList(functionOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        onFilterChange({
                            ...values,
                            EmployeeFunctionId: e ? (e.Value as number) : null,
                        })
                    }
                    value={
                        values.EmployeeFunctionId == null
                            ? allOption
                            : functionOptions.find(
                                  (x) => x.Value === values.EmployeeFunctionId
                              )
                    }
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.AreaId)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(areaOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        onFilterChange({
                            ...values,
                            AreaId: e ? (e.Value as number) : null,
                        })
                    }
                    value={
                        values.AreaId == null
                            ? allOption
                            : areaOptions.find((x) => x.Value === values.AreaId)
                    }
                />
            </td>
            <td>
                <AppNumberInput
                    showClearButton={true}
                    showIcon={false}
                    showLabel={false}
                    formatTheInitialValue={false}
                    showEmptyField={true}
                    showPlaceHolder={false}
                    allowZero={true}
                    format={FORMAT_INTEGER}
                    value={values?.WeeklyHours}
                    onChange={(e) => {
                        onFilterChange({ ...values, WeeklyHours: e });
                    }}
                />
            </td>
            <td />
            <td />
            <td />
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.IsChargeable)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        values.IsChargeable == null
                            ? allOption
                            : appOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          values.IsChargeable
                                  )
                    }
                    options={appOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                IsChargeable:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td />
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(values.HasMobileAccount)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        values.HasMobileAccount == null
                            ? allOption
                            : appOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          values.HasMobileAccount
                                  )
                    }
                    options={appOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onFilterChange({
                                ...values,
                                HasMobileAccount:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td></td>
        </tr>
    );
};

export default BusinessEmployeeTableFilter;
