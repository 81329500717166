import { useEffect, useMemo, useState } from "react";
import { defaultTo, isNil } from "lodash-es";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";
import { useRouting } from "hooks/general/routing";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { useMutation } from "react-query";
import { BusinessTimeTrackingSettings } from "models/business/model";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { AppContainer } from "components/Containers";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import {
    BusinessType,
    Business_BasicData,
    Business_Settings,
    LW_Industry_Settings,
    LW_Kindergarten_BasicData,
    LW_Kindergarten_Settings,
} from "models";
import styles from "./TimeTrackingSettings.module.scss";
import { useParams } from "react-router-dom";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import TimeTrackingFlagsPartial from "screens/business/settingTabs/TimeTrackingFlagsPartial";
import { Row, Col, Form, Image } from "react-bootstrap";
import { AppSwitch, AppTooltip } from "components";
import { AppCheckbox } from "components/FormFields";
import { ImageAssets } from "globals/images";
import classNames from "classnames";
import { AppButton } from "components/Buttons";
import { TabContentWithHeadingSection } from "commonPartials";

export const TimeTrackingSettings: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] = useState<BusinessTimeTrackingSettings | null>(
        null
    );
    const { dataItem: currentBusiness, refetchData } =
        useCurrentBusinessSettingsContext();
    const { lwcId } = useParams();
    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();
    const isLwc = !isNil(lwcId);
    const { getPermissionMap } = useBusinessCheckPermission();
    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(lwcId)
    );

    useEffect(() => {
        if (currentBusiness) {
            setState({
                AllowManualEmployeeTimeTrackingWeb:
                    currentBusiness.BusinessSetting
                        .AllowManualEmployeeTimeTrackingWeb,
                AllowManualEmployeeTimeTrackingIPad:
                    currentBusiness.BusinessSetting
                        .AllowManualEmployeeTimeTrackingIPad,
                AllowManualEmployeeTimeTrackingMobile:
                    currentBusiness.BusinessSetting
                        .AllowManualEmployeeTimeTrackingMobile,
                AllowManualClientTimeTrackingWeb:
                    currentBusiness.BusinessSetting
                        .AllowManualClientTimeTrackingWeb,
                AllowManualClientTimeTrackingIPad:
                    currentBusiness.BusinessSetting
                        .AllowManualClientTimeTrackingIPad,
                AllowManualClientTimeTrackingMobile:
                    currentBusiness.BusinessSetting
                        .AllowManualClientTimeTrackingMobile,
                TriggerEmployeeAutoOUT:
                    currentBusiness.BusinessSetting.TriggerEmployeeAutoOUT,
                TriggerEmployeeAutoOUT_WorkingHours:
                    currentBusiness.BusinessSetting
                        .TriggerEmployeeAutoOUT_WorkingHours,
                TriggerClientAutoOUT:
                    currentBusiness.BusinessSetting.TriggerClientAutoOUT,
                TriggerEmployeeBreakAutoEnd:
                    currentBusiness.BusinessSetting.TriggerEmployeeBreakAutoEnd,
            });
        }
    }, []);
    const {
        isLoading: updating,
        data: updateResponse,
        mutate: updateSettings,
    } = useMutation(
        getCurrentBusinessServiceKey("updateTimeTrackingSettings"),
        async () =>
            !isNil(state)
                ? await businessService.updateTimeTrackingSettings(state)
                : false
    );
    const permMap = useMemo(() => {
        return !isLwc
            ? getPermissionMap(
                  currentBusiness?.Id as number,
                  currentBusiness?.HqId as number,
                  Business_Settings
              )
            : getPermissionMap(
                  sessionBusinessId,
                  sessionHqId,
                  currentBusiness?.Type == BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_Settings
                      : LW_Industry_Settings
              );
    }, [getPermissionMap]);
    useEffect(() => {
        if (!updating && updateResponse) {
            if (updateResponse.Data != null) {
                if (updateResponse.Data) {
                    if (refetchData) {
                        refetchData();
                    }
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("business.timeTrackingSettings.settingsUpdated")}`,
                        "success"
                    );
                }
            } else if (updateResponse.Code != null) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("common.error.requestFailed")}`,
                    "error"
                );
            }
        }
    }, [updating, updateResponse]);

    const showParentSettings =
        currentBusiness?.Type === BusinessType.KINDERGARTEN;

    return (
        <AppContainer>
            <Row>
                {showParentSettings && (
                    <Col lg={6}>
                        <TabContentWithHeadingSection
                            className={styles.parentFlags}
                            title={t(
                                "business.timeTrackingSettings.titleParents"
                            )}
                        >
                            <TimeTrackingFlagsPartial
                                permMap={permMap}
                                value={
                                    state
                                        ? {
                                              web: defaultTo(
                                                  state.AllowManualClientTimeTrackingWeb,
                                                  false
                                              ),
                                              mobile: defaultTo(
                                                  state.AllowManualClientTimeTrackingMobile,
                                                  false
                                              ),
                                              iPad: defaultTo(
                                                  state.AllowManualClientTimeTrackingIPad,
                                                  false
                                              ),
                                          }
                                        : {
                                              web: false,
                                              mobile: false,
                                              iPad: false,
                                          }
                                }
                                onChange={(val) => {
                                    setState((old) => ({
                                        ...old,
                                        AllowManualClientTimeTrackingWeb:
                                            val.web,
                                        AllowManualClientTimeTrackingMobile:
                                            val.mobile,
                                        AllowManualClientTimeTrackingIPad:
                                            val.iPad,
                                    }));
                                }}
                                disabled={updating}
                                onSave={updateSettings}
                            />
                        </TabContentWithHeadingSection>
                    </Col>
                )}
                <Col lg={showParentSettings ? 6 : 12}>
                    <TabContentWithHeadingSection
                        title={t(
                            "business.timeTrackingSettings.titleEmployees"
                        )}
                    >
                        <TimeTrackingFlagsPartial
                            permMap={permMap}
                            value={
                                state
                                    ? {
                                          web: defaultTo(
                                              state.AllowManualEmployeeTimeTrackingWeb,
                                              false
                                          ),
                                          mobile: defaultTo(
                                              state.AllowManualEmployeeTimeTrackingMobile,
                                              false
                                          ),
                                          iPad: defaultTo(
                                              state.AllowManualEmployeeTimeTrackingIPad,
                                              false
                                          ),
                                      }
                                    : { web: false, mobile: false, iPad: false }
                            }
                            onChange={(val) => {
                                setState((old) => ({
                                    ...old,
                                    AllowManualEmployeeTimeTrackingWeb: val.web,
                                    AllowManualEmployeeTimeTrackingMobile:
                                        val.mobile,
                                    AllowManualEmployeeTimeTrackingIPad:
                                        val.iPad,
                                }));
                            }}
                            disabled={updating}
                            onSave={updateSettings}
                        />
                    </TabContentWithHeadingSection>
                </Col>
            </Row>
            <hr className={styles.hrLine} />
            <Row style={{ marginBottom: "10px" }}>
                {showParentSettings && (
                    <Col lg={6} style={{ display: "flex" }}>
                        <TabContentWithHeadingSection
                            className={styles.parentFlags}
                            title={t(
                                "business.timeTrackingSettings.autoStopChildcare"
                            )}
                        >
                            <AppSwitch
                                label={t(
                                    "business.timeTrackingSettings.allowAutoOutClient"
                                )}
                                disabled={!permMap.EDIT}
                                className={styles.switch}
                                value={defaultTo(
                                    state?.TriggerClientAutoOUT,
                                    false
                                )}
                                onChange={(checked) => {
                                    setState({
                                        ...state,
                                        TriggerClientAutoOUT: checked,
                                    });
                                }}
                            />
                        </TabContentWithHeadingSection>
                    </Col>
                )}
                <Col lg={showParentSettings ? 6 : 12}>
                    <TabContentWithHeadingSection
                        title={t("business.timeTrackingSettings.autoStopWork")}
                    >
                        <>
                            <AppSwitch
                                label={t(
                                    "business.timeTrackingSettings.allowAutoOutEmp"
                                )}
                                disabled={!permMap.EDIT}
                                className={styles.switch}
                                value={defaultTo(
                                    state?.TriggerEmployeeAutoOUT,
                                    false
                                )}
                                onChange={(checked) => {
                                    setState({
                                        ...state,
                                        TriggerEmployeeAutoOUT: checked,
                                    });
                                }}
                            />
                            {state?.TriggerEmployeeAutoOUT && (
                                <Row style={{ margin: "5px -15px 10px -15px" }}>
                                    <Col
                                        md={6}
                                        className={styles.checkboxContainer}
                                    >
                                        <AppCheckbox
                                            label={""}
                                            readOnly={!permMap.EDIT}
                                            className={styles.inlineCheckbox}
                                            checked={defaultTo(
                                                !state?.TriggerEmployeeAutoOUT_WorkingHours,
                                                true
                                            )}
                                            onChange={(e) => {
                                                const checked =
                                                    e.target.checked;
                                                setState({
                                                    ...state,
                                                    TriggerEmployeeAutoOUT_WorkingHours:
                                                        !checked,
                                                });
                                            }}
                                        />
                                        <Form.Label
                                            style={{
                                                color: "#0d1c26",
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            {t(
                                                "business.timeTrackingSettings.byClosingHours"
                                            )}
                                            <AppTooltip
                                                content={
                                                    <span>
                                                        {t(
                                                            "business.timeTrackingSettings.byClosingHours_exp"
                                                        )}
                                                    </span>
                                                }
                                                allowHtml={true}
                                                trigger="mouseenter focus"
                                                arrow={true}
                                                maxWidth="400px"
                                            >
                                                <Image
                                                    className={classNames(
                                                        "ml-2"
                                                    )}
                                                    style={{
                                                        width: "16px",
                                                        height: "16px",
                                                    }}
                                                    src={
                                                        ImageAssets.common
                                                            .questionCircleBlue
                                                    }
                                                />
                                            </AppTooltip>
                                        </Form.Label>
                                    </Col>
                                    <Col
                                        md={6}
                                        className={styles.checkboxContainer}
                                    >
                                        <AppCheckbox
                                            label={""}
                                            readOnly={!permMap.EDIT}
                                            className={styles.inlineCheckbox}
                                            checked={defaultTo(
                                                state?.TriggerEmployeeAutoOUT_WorkingHours,
                                                false
                                            )}
                                            onChange={(e) => {
                                                const checked =
                                                    e.target.checked;
                                                setState({
                                                    ...state,
                                                    TriggerEmployeeAutoOUT_WorkingHours:
                                                        checked,
                                                });
                                            }}
                                        />
                                        <Form.Label
                                            style={{
                                                color: "#0d1c26",
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            {t(
                                                "business.timeTrackingSettings.byWorkingHours"
                                            )}
                                            <AppTooltip
                                                content={
                                                    <span>
                                                        {t(
                                                            "business.timeTrackingSettings.byWorkingHours_exp"
                                                        )}
                                                    </span>
                                                }
                                                allowHtml={true}
                                                trigger="mouseenter focus"
                                                arrow={true}
                                                maxWidth="400px"
                                            >
                                                <Image
                                                    className={classNames(
                                                        "ml-2"
                                                    )}
                                                    style={{
                                                        width: "16px",
                                                        height: "16px",
                                                    }}
                                                    src={
                                                        ImageAssets.common
                                                            .questionCircleBlue
                                                    }
                                                />
                                            </AppTooltip>
                                        </Form.Label>
                                    </Col>
                                </Row>
                            )}
                            <AppSwitch
                                label={t(
                                    "business.timeTrackingSettings.allowAutoBreakOutEmp"
                                )}
                                style={{ marginTop: "5px" }}
                                disabled={!permMap.EDIT}
                                className={styles.switch}
                                value={defaultTo(
                                    state?.TriggerEmployeeBreakAutoEnd,
                                    false
                                )}
                                onChange={(checked) => {
                                    setState({
                                        ...state,
                                        TriggerEmployeeBreakAutoEnd: checked,
                                    });
                                }}
                            />
                        </>
                    </TabContentWithHeadingSection>
                </Col>
            </Row>
            <div style={{ marginTop: "15px" }}>
                <AppButton
                    padding="med"
                    className={styles.btn}
                    disabled={!permMap.EDIT}
                    onClick={() => updateSettings()}
                >
                    {t("common.save")}
                </AppButton>
            </div>
        </AppContainer>
    );
};

export default TimeTrackingSettings;
