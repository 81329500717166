import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { isNil, orderBy } from "lodash-es";
import {
    BusinessType,
    Business_Shifts,
    LW_Industry_Courses,
    LW_Industry_Shifts,
    LW_Kindergarten_Shifts,
    PermissionAccessTypes,
} from "models";
import { SortOrder, TableSort } from "models/general";
import { BusinessShift, BusinessShiftSortColumn } from "models/businessShifts";
import {
    getBusinessShiftKeyFromEnum,
    parseBusinessShiftModelResponse,
} from "models/businessShifts/helper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import {
    BusinessShiftService,
    getBusinessShiftServiceKey,
} from "services/business";
import { BusinessShiftTable } from "./partials";
import styles from "commonPartials/commonStyles.module.scss";

interface BusinessShiftListState {
    list: BusinessShift[];
    sort: TableSort<BusinessShiftSortColumn>;
}

export const BusinessShiftList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { lwcId } = useParams();
    const { t } = useTranslation();
    const { checkPermission, getPermissionMap } = useCheckPermission();
    const [state, setState] = useState<BusinessShiftListState>({
        list: [],
        sort: {
            SortColumn: BusinessShiftSortColumn.StartTime,
            SortOrder: SortOrder.ASC,
        },
    });

    // both(useCurrentBusinessSettingsContext and useCurrentLwcContext)
    // return same context as context type is same
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();

    const shiftService = new BusinessShiftService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).shift
    );

    const {
        isLoading,
        isRefetching,
        data: listRes,
    } = useQuery(
        getBusinessShiftServiceKey("list", {
            businessId: currentBusiness?.Id,
        }),
        async () => await shiftService.list(),
        { cacheTime: 0 }
    );

    useEffect(() => {
        if (!isLoading && !isRefetching && listRes?.Data) {
            const sortedList = orderBy(
                listRes.Data.map((x) => {
                    return parseBusinessShiftModelResponse(x);
                }),
                getBusinessShiftKeyFromEnum(state.sort.SortColumn),
                state.sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
            );
            setState({
                ...state,
                list: sortedList,
            });
        }
    }, [isLoading, isRefetching]);

    const { mutate: deleteTemplate } = useMutation(
        async (id: string) => await shiftService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    const toDelete = state.list.filter(
                        (x) => x.Id == deleteRes.Data
                    )[0];

                    showSweetAlertToast(
                        t("common.success"),
                        `${toDelete.Name} ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );

                    setState({
                        ...state,
                        list: state.list.filter((x) => x.Id != deleteRes.Data),
                    });
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;

    const linkProviderBase = isLwc
        ? (currentBusiness?.Type == BusinessType.KINDERGARTEN
              ? lwcBase.kindergartens
              : lwcBase.industries
          ).withId()
        : linkProvider.business.businessSettings().screens;

    const permissions = getPermissionMap([
        isLwc
            ? currentBusiness?.Type == BusinessType.KINDERGARTEN
                ? LW_Kindergarten_Shifts
                : LW_Industry_Shifts
            : Business_Shifts,
    ]);

    const onSortChange = (sort: TableSort<BusinessShiftSortColumn>) => {
        const sortedList = orderBy(
            state.list,
            getBusinessShiftKeyFromEnum(sort.SortColumn),
            sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
        );
        setState({
            ...state,
            sort: sort,
            list: sortedList,
        });
    };

    return (
        <AppContainer
            classes={
                !isLwc
                    ? { body: styles.appContainerWithLessTopPadding }
                    : undefined
            }
        >
            {permissions.CREATE && (
                <AppIconTextButton
                    padding="med"
                    className="mb-2"
                    icon="plus"
                    text={t("common.create")}
                    onClick={() => {
                        navigate(linkProviderBase.shifts.create());
                    }}
                />
            )}
            <BusinessShiftTable
                list={state.list}
                sort={state.sort}
                onClick={(id) => {
                    navigate(linkProviderBase.shifts.edit(id));
                }}
                onSortChange={onSortChange}
                onDeleteBtnClick={(id) => deleteTemplate(id)}
                loading={isLoading || isRefetching}
                hasDeletePermission={permissions.DELETE}
            />
        </AppContainer>
    );
};

export default BusinessShiftList;
