import { AppButton } from "components/Buttons";
import AppAttachedFilesList from "components/File/AppAttachedFilesList";
import { getGeneralAcceptFile } from "globals/helpers/generalHelper";
import { defaultTo } from "lodash-es";
import { PermissionAccessMap } from "models";
import { IFileWithId } from "models/general";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AppFilesListComponent.module.scss";

export interface FilesListAndRequest {
    Files: IFileWithId[];
    files?: any[]; //for update purpose
}
export interface AppFilesListComponentProps {
    value: FilesListAndRequest;
    heading?: string;
    className?: string;
    permMap?: PermissionAccessMap;
    maxHeight?: string;
    acceptedFiles?: string[];
    onChange: (val: FilesListAndRequest) => void;
    onDelete: (id: number) => void;
}

export const AppFilesListComponent: React.FC<AppFilesListComponentProps> = ({
    value,
    heading,
    className = "",
    permMap,
    maxHeight,
    onChange,
    acceptedFiles = getGeneralAcceptFile(),
    onDelete,
}) => {
    const { t } = useTranslation();
    heading = defaultTo(heading, t("common.files.name"));

    const [reRender, setReRender] = useState(false);
    const fileRef: any = useRef(null);
    const onFileClickHandler = () => {
        fileRef.current.click();
    };

    const onFileChangeHandler = (e: any) => {
        if (e.target.name === "file") {
            onChange({
                ...value,
                files: e.target.files as any[],
            });
        }
    };

    useEffect(() => {
        if (!reRender) {
            setReRender(true);
        }
    }, [value.Files]);
    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);
    return (
        <div className={`${className} ${styles.root}`}>
            <AppAttachedFilesList
                className={styles.marginTop_33}
                heading={heading}
                files={value.Files}
                maxHeight={maxHeight}
                canDeleteFile={permMap?.DELETE}
                onFileDelete={onDelete}
            />

            {permMap?.EDIT && (
                <div
                    className={`${styles.displayFlex} mt-3`}
                    style={{ justifyContent: "start" }}
                >
                    <AppButton
                        onClick={() => {
                            onFileClickHandler();
                        }}
                    >
                        {t("common.files.addFiles")}
                    </AppButton>
                    {value.files && (
                        <div className={"ml-2"}>
                            {t(
                                `common.fileSelected.${
                                    value.files.length == 1
                                        ? "single"
                                        : "multiple"
                                }`,
                                {
                                    count: value.files.length,
                                }
                            )}
                        </div>
                    )}
                    {!reRender && (
                        <Form.File
                            ref={fileRef}
                            id="fileUploadBtn"
                            style={{ display: "none" }}
                            name="file"
                            accept={acceptedFiles.toString()}
                            multiple={true}
                            onChange={onFileChangeHandler}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AppFilesListComponent;
