import React from "react";
import { ChannelListSkeleton } from "./ChannelListSkeleton";

interface ChatChannelLoaderProps {
    loading: boolean;
    res: any;
    children: () => React.ReactNode;
}
export const ChatChannelLoader = React.memo<ChatChannelLoaderProps>(
    ({ children, loading, res }) => {
        return (
            <>
                {loading && (res == null || res.length == 0) && (
                    <ChannelListSkeleton />
                )}
                {!loading && res != null && children()}
            </>
        );
    }
);

export default ChatChannelLoader;
