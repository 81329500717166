// Layout
import authBack from "assets/images/authBack.png";
import circlesBg from "assets/images/circlesBg.png";
import fullBalloon from "assets/images/baloon.png";
import halfBalloon from "assets/images/halfBaloon.png";

// Logo
import balloonLogoSmall from "assets/images/logo/balloon_Logo_small.png";
import balloonLogoMedium from "assets/images/logo/balloon_lillywait73x97.png";
import balloonLogoLarge from "assets/images/logo/balloon_lillywaitIcon400-1.png";
import fullLogo from "assets/images/logo/lillywaitlogofull.png";
import textLogo from "assets/images/logo/lillywaitlogo.png";
// Document Manager
import doc from "assets/images/documentManager/doc.png";
import excel from "assets/images/documentManager/excel.png";
import docPreview from "assets/images/documentManager/docPreview.png";
import pdf from "assets/images/documentManager/pdf.png";
import report from "assets/images/documentManager/report.png";
import images from "assets/images/documentManager/images.png";

// Common Icons
import dummyUser from "assets/images/user.png";
import allMail from "assets/images/icons/all-mail.png";
import angleRightGray from "assets/images/icons/angle-right-gray.png";
import angleLeftGray from "assets/images/icons/angle-left-gray.png";
import arrowLeft from "assets/images/icons/arrow-left.png";
import arrowLeftWhite from "assets/images/icons/arrow-left-white.png";
import attachment from "assets/images/icons/attachment.png";
import pdfFileWhite from "assets/images/icons/pdf-file-white.png";
import bell from "assets/images/icons/bell.svg";
import bellBlue from "assets/images/icons/bell-blue.svg";
import calendar from "assets/images/icons/calendar.png";
import calendarAlt from "assets/images/icons/calendar-alt.png";
import calendarAltGrey from "assets/images/icons/calendar-alt-grey.png";
import exclamationCircle from "assets/images/icons/exclamation-circle.png";
import eyeCross from "assets/images/icons/eye-cross.png";
import eye from "assets/images/icons/eye.png";
import apple from "assets/images/icons/apple-alt.png";
import camera from "assets/images/icons/camera.png";
import cameraWithBg from "assets/images/icons/cameraWithBg.png";
import cameraWithBgSquare from "assets/images/icons/cameraWithBg-rectangle.png";
import checkCircle from "assets/images/icons/check-circle.svg";
import checkCircleBlue from "assets/images/icons/check-circle-blue.svg";
import crossCircleRed from "assets/images/icons/cross-circle-red.png";
import chickenLeg from "assets/images/icons/chicken-leg.png";
import clients from "assets/images/icons/clients.png";
import clock from "assets/images/icons/clock.png";
import clockGray from "assets/images/icons/clock-gray.png";
import coffee from "assets/images/icons/coffee.png";
import commentYellow from "assets/images/icons/comment-yellow.svg";
import commentCheck from "assets/images/icons/comment-check.png";
import commentCheckGreen from "assets/images/icons/comment-check-green.png";
import confirmation from "assets/images/icons/confirmation.png";
import copyFilled from "assets/images/icons/copy-filled.png";
import copyBlue from "assets/images/icons/copy-blue.svg";
import copyGray from "assets/images/icons/copy-gray.svg";
import copyYellow from "assets/images/icons/copy-yellow.svg";
import crossRed from "assets/images/icons/cross.png";
import crossBlue from "assets/images/icons/cross-blue.png";
import crossGray from "assets/images/icons/cross-gray.png";
import crossWhite from "assets/images/icons/cross-white.png";
import download from "assets/images/icons/download.png";
import downloadArrowBlue from "assets/images/icons/download-arrow-blue.svg";
import downloadArrowBlack from "assets/images/icons/download-arrow.svg";
import downloadArrowWhite from "assets/images/icons/download-arrow-white.svg";
import exclamationTriangleBlack from "assets/images/icons/exclamation-triangle-black.png";
import exclamationTriangleRed from "assets/images/icons/exclamation-triangle.png";
import envelope from "assets/images/icons/envelope.png";
import envelopeWhite from "assets/images/icons/envelope-white.png";
import fileBlank from "assets/images/icons/file-blank.png";
import fileBlack from "assets/images/icons/file-black.png";
import fileYellow from "assets/images/icons/file-yellow.png";
import fileBlue from "assets/images/icons/file-blue.png";
import fireworks from "assets/images/icons/fireworks.png";
import fish from "assets/images/icons/fish.png";
import helpCircleBlue from "assets/images/icons/help-circle-blue.png";
import helpCircleGray from "assets/images/icons/help-circle-gray.png";
import inboxIn from "assets/images/icons/inbox-in.svg";
import inboxInGray from "assets/images/icons/inbox-in-gray.svg";
import lockGray from "assets/images/icons/lock-gray.png";
import meal from "assets/images/icons/meal.png";
import menu from "assets/images/icons/menu.svg";
import menuGray from "assets/images/icons/menu-gray.svg";
import mobileConnected from "assets/images/icons/mobile-connected.svg";
import mobileNotConnected from "assets/images/icons/mobile-not-connected.svg";
import paperClip from "assets/images/icons/paperclip.svg";
import editBlue from "assets/images/icons/pencil.png";
import editGray from "assets/images/icons/pencil-gray.png";
import editWhite from "assets/images/icons/pencil-white.svg";
import person from "assets/images/icons/person.png";
import plus from "assets/images/icons/plus-white.svg";
import plusBlue from "assets/images/icons/plus-blue.svg";
import questionCircleBlue from "assets/images/icons/question-circle-blue.png";
import questionCircleBlack from "assets/images/icons/question-circle.png";
import repeatWhite from "assets/images/icons/repeat-white.png";
import search from "assets/images/icons/search.svg";
import security from "assets/images/icons/security.png";
import send from "assets/images/icons/send.svg";
import sendBlue from "assets/images/icons/send-blue.svg";
import sendGray from "assets/images/icons/send-gray.svg";
import smiley from "assets/images/icons/smiley.png";
import star from "assets/images/icons/star.svg";
import starBlue from "assets/images/icons/star-blue.svg";
import starFill from "assets/images/icons/star-fill.png";
import syncButton from "assets/images/icons/sync-button.png";
import syncIconWhite from "assets/images/icons/sync-icon.png";
import syncAlt from "assets/images/icons/sync-alt.svg";
import tickBlue from "assets/images/icons/tick-blue.png";
import tickGray from "assets/images/icons/tick-gray.png";
import timeManagement from "assets/images/icons/time-management.png";
import table from "assets/images/icons/table.svg";
import tableBlue from "assets/images/icons/table-blue.png";
import userOutline from "assets/images/icons/user.png";
import userOutlineBlue from "assets/images/icons/user-blue.png";
import userOutlineGray from "assets/images/icons/user-gray.png";
import userCheckBlue from "assets/images/icons/user-check-blue.png";
import userTagGreen from "assets/images/icons/user-tag-green.png";
import upload from "assets/images/icons/upload.png";
import trashBlue from "assets/images/icons/trash-blue.svg";
import trashRed from "assets/images/icons/trash-red.svg";
import trashGray from "assets/images/icons/trash-gray.svg";
import tabSlidingArrow from "assets/images/icons/tab-sliding-arrow.png";
import tagGrayLightest from "assets/images/icons/tag-grayLightest.svg";
import tagGreen from "assets/images/icons/tag-green.svg";

export const ImageAssets = {
    common: {
        allMail: allMail,
        angleRightGray: angleRightGray,
        angleLeftGray: angleLeftGray,
        apple: apple,
        arrowLeft: arrowLeft,
        arrowLeftWhite: arrowLeftWhite,
        attachment: attachment,
        bell: bell,
        bellBlue: bellBlue,
        calendar: calendar,
        calendarAlt: calendarAlt,
        calendarAltGrey: calendarAltGrey,
        camera: camera,
        cameraWithBg: cameraWithBg,
        cameraWithBgTriangle: cameraWithBgSquare,
        checkCircle: checkCircle,
        checkCircleBlue: checkCircleBlue,
        chickenLeg: chickenLeg,
        clients: clients,
        clock: clock,
        clockGray: clockGray,
        coffee: coffee,
        commentYellow: commentYellow,
        commentCheck: commentCheck,
        commentCheckGreen: commentCheckGreen,
        confirmation: confirmation,
        copyBlue: copyBlue,
        syncButton: syncButton,
        syncIconWhite: syncIconWhite,
        syncAlt: syncAlt,
        copyFilled: copyFilled,
        copyGray: copyGray,
        copyYellow: copyYellow,
        crossBlue: crossBlue,
        crossGray: crossGray,
        crossRed: crossRed,
        crossCircleRed: crossCircleRed,
        pdfFileWhite: pdfFileWhite,
        crossWhite: crossWhite,
        tabSlidingArrow: tabSlidingArrow,
        download: download,
        downloadArrowBlack: downloadArrowBlack,
        downloadArrowBlue: downloadArrowBlue,
        downloadArrowWhite: downloadArrowWhite,
        dummyUser: dummyUser,
        editBlue: editBlue,
        editGray: editGray,
        editWhite: editWhite,
        envelope: envelope,
        envelopeWhite: envelopeWhite,
        exclamationCircle: exclamationCircle,
        exclamationTriangleBlack: exclamationTriangleBlack,
        exclamationTriangleRed: exclamationTriangleRed,
        eye: eye,
        eyeCross: eyeCross,
        fileBlack: fileBlack,
        fileBlank: fileBlank,
        fileBlue: fileBlue,
        fileYellow: fileYellow,
        fireworks: fireworks,
        fish: fish,
        helpCircleBlue: helpCircleBlue,
        helpCircleGray: helpCircleGray,
        inboxIn: inboxIn,
        inboxInGray: inboxInGray,
        lockGray: lockGray,
        meal: meal,
        menu: menu,
        menuGray: menuGray,
        mobileConnected: mobileConnected,
        mobileNotConnected: mobileNotConnected,
        paperClip: paperClip,
        person: person,
        plus: plus,
        plusBlue: plusBlue,
        tableBlue: tableBlue,
        table: table,
        questionCircleBlack: questionCircleBlack,
        questionCircleBlue: questionCircleBlue,
        repeatWhite: repeatWhite,
        search: search,
        security: security,
        send: send,
        sendBlue: sendBlue,
        sendGray: sendGray,
        smiley: smiley,
        star: star,
        starBlue: starBlue,
        starFill: starFill,
        tagGrayLightest: tagGrayLightest,
        tagGreen: tagGreen,
        tickBlue: tickBlue,
        tickGray: tickGray,
        timeManagement: timeManagement,
        trashBlue: trashBlue,
        trashGray: trashGray,
        trashRed: trashRed,
        upload: upload,
        userCheckBlue: userCheckBlue,
        userOutline: userOutline,
        userOutlineBlue: userOutlineBlue,
        userOutlineGray: userOutlineGray,
        userTagGreen: userTagGreen,
    },
    documentManager: {
        doc: doc,
        docPreview: docPreview,
        excel: excel,
        images: images,
        pdf: pdf,
        report: report,
    },
    layout: {
        authBack: authBack,
        circlesBg: circlesBg,
        fullBalloon: fullBalloon,
        halfBalloon: halfBalloon,
    },
    logo: {
        balloon_l: balloonLogoLarge,
        balloon_m: balloonLogoMedium,
        balloon_s: balloonLogoSmall,
        fullLogo: fullLogo,
        textLogo: textLogo,
    },
};

export default ImageAssets;
