import {
    LW_Support_ElderlyCare,
    LW_Support_Industry,
    LW_Support_Kindergartens,
    LW_Support_MergeCompanies,
    LW_Support_Schools,
    PermissionAccessTypes,
} from "models";
import {
    Feature_LW_Clients_ElderlyCares_Support,
    Feature_LW_Clients_Industries,
    Feature_LW_Clients_Industries_Support,
    Feature_LW_Clients_Kindergartens_Support,
    Feature_LW_Clients_Schools_Support,
    Feature_LW_Support_MergeCompanies,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import LwcSupportList from "screens/lillywait/support/LwcSupportList";
import MergeCompanies from "screens/lillywait/support/MergeCompanies";
import lwSupportRoutes from "./routes";

const relativeRoutes = lwSupportRoutes(false).screens;
const fullRoutes = lwSupportRoutes().screens;

export const lwSupportRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.list()]: {
        component: LwcSupportList,
        fullPattern: fullRoutes.list(),
        pattern: relativeRoutes.list(),
        permKeys: [
            LW_Support_Kindergartens,
            LW_Support_ElderlyCare,
            LW_Support_Industry,
            LW_Support_Schools,
        ],
        featureKeys: [
            Feature_LW_Clients_Kindergartens_Support,
            Feature_LW_Clients_Schools_Support,
            Feature_LW_Clients_Industries_Support,
            Feature_LW_Clients_ElderlyCares_Support,
        ],
    },
    [relativeRoutes.mergeCompanies()]: {
        component: MergeCompanies,
        fullPattern: fullRoutes.mergeCompanies(),
        pattern: relativeRoutes.mergeCompanies(),
        permKeys: [LW_Support_MergeCompanies],
        permAccessTypes: [PermissionAccessTypes.EDIT],
        featureKeys: [Feature_LW_Support_MergeCompanies],
    },
};
