import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { SalaryGroupTemplate, SalaryGroupTemplateField } from "./model";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";

export function validateSalaryGroupTemplateField(
    field: SalaryGroupTemplateField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof SalaryGroupTemplateField> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.name.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.shortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    return state;
}

export function validateSalaryGroupTemplate(
    template: SalaryGroupTemplate,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof SalaryGroupTemplate> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateName.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateShortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        template,
        state
    );

    let manager = new ValidityStateManager(state);
    template.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateSalaryGroupTemplateField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });

    return manager.state;
}
