import React from "react";
import { Button, Image } from "react-bootstrap";
import styles from "./AppCircleIconButton.module.scss";

export interface AppCircleIconButtonProps {
    className?: string;
    onClick: () => void;
    iconUrl: string;
    readonly?: boolean;
}

export const AppCircleIconButton: React.FC<AppCircleIconButtonProps> = ({
    className = "",
    onClick,
    iconUrl,
    readonly = false,
}) => {
    return (
        <Button
            onClick={() => onClick()}
            className={`rounded-circle ${className}`}
            disabled={readonly}
        >
            <Image className={styles.btnIcon} src={iconUrl} />
        </Button>
    );
};

export default AppCircleIconButton;
