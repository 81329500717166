import { ISO8601_DATE_FORMAT } from "globals/constants";
import { AppResponse } from "models/general";
import {
    KindergartenClientRegistrationRequest,
    KindergartenClientRegistrationResponse,
    KindergartenClientRegistrationsListResponse,
} from "models/kindergartenClientRegistration";
import {
    ClientRegistrationContact,
    KindergartenClientRegistration,
} from "models/kindergartenClientRegistration/model";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface IKindergartenClientRegistrationService {
    list(
        request: KindergartenClientRegistrationRequest
    ): Promise<AppResponse<KindergartenClientRegistrationsListResponse>>;
    createUpdate(
        model: KindergartenClientRegistration
    ): Promise<AppResponse<KindergartenClientRegistrationResponse>>;
    contactCreateUpdate(
        model: ClientRegistrationContact,
        registrationId: string
    ): Promise<AppResponse<ClientRegistrationContact>>;
    get(
        id: string
    ): Promise<AppResponse<KindergartenClientRegistrationResponse>>;
    delete(id: string): Promise<AppResponse<number>>;
    deleteContact(
        id: string,
        registrationId: string
    ): Promise<AppResponse<number>>;
    deleteFile(id: number): Promise<AppResponse<number>>;
}

const apiLinkProvider =
    routesForContext().kindergarten.clients().api.clientRegistration;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getKindergartenClientRegistrationServiceKey(
    name: keyof IKindergartenClientRegistrationService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `kindergartenClients_${name}_${JSON.stringify(data)}`;
}

export class KindergartenClientRegistrationService
    extends BaseService
    implements IKindergartenClientRegistrationService
{
    public clientRegistrationRoutes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.clientRegistrationRoutes = apiLinkProvider;
    }
    list(
        request: KindergartenClientRegistrationRequest
    ): Promise<AppResponse<KindergartenClientRegistrationsListResponse>> {
        return this.doServerXHR<
            AppResponse<KindergartenClientRegistrationsListResponse>
        >({
            url: this.clientRegistrationRoutes.list(request),
            method: "get",
        });
    }
    constructFormData({ files, ...model }: KindergartenClientRegistration) {
        const ob = {
            ...model,
            BirthDate: model.Birthday.format(ISO8601_DATE_FORMAT),
            CareStart: model.CareStart.format(ISO8601_DATE_FORMAT),
            CreatedAt: model.CreatedAt.format(ISO8601_DATE_FORMAT),
        };

        const fD = new FormData();
        if (files) {
            for (let i = 0; i < files.length; i++) {
                fD.append("files", files[i]);
            }
        }

        fD.append("Data", JSON.stringify(ob));
        return fD;
    }
    createUpdate(
        model: KindergartenClientRegistration
    ): Promise<AppResponse<KindergartenClientRegistrationResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<
            AppResponse<KindergartenClientRegistrationResponse>
        >({
            url: this.clientRegistrationRoutes.createUpdate(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    contactCreateUpdate(
        model: ClientRegistrationContact,
        registrationId: string
    ): Promise<AppResponse<ClientRegistrationContact>> {
        return this.doServerXHR<
            AppResponse<KindergartenClientRegistrationResponse>
        >({
            url: this.clientRegistrationRoutes.parentCreateUpdate(
                registrationId
            ),
            method: "post",
            data: model,
        });
    }
    get(
        id: string
    ): Promise<AppResponse<KindergartenClientRegistrationResponse>> {
        return this.doServerXHR<
            AppResponse<KindergartenClientRegistrationResponse>
        >({
            url: this.clientRegistrationRoutes.get(id),
            method: "get",
        });
    }
    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<AppResponse<number>>({
            url: this.clientRegistrationRoutes.delete(id),
            method: "post",
        });
    }
    deleteContact(
        id: string,
        registrationId: string
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<AppResponse<number>>({
            url: this.clientRegistrationRoutes.deleteContact(
                id,
                registrationId
            ),
            method: "post",
        });
    }
    deleteFile(id: number): Promise<AppResponse<number>> {
        return this.doServerXHR<AppResponse<number>>({
            url: this.clientRegistrationRoutes.deleteFile(id),
            method: "post",
        });
    }
}
