import { AppSelectOld } from "components/AppSelect";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    AppCheckbox,
    AppDatePicker,
    AppInputField,
    AppLastNameSurnameFields,
} from "components/FormFields";
import { AppLoader } from "components/Loaders";
import { getPersonShortName } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Kindergarten_Client_BasicData, PermissionAccessTypes } from "models";
import {
    ErrorCodes,
    Gender,
    Optional,
    SelectItem,
    ValidityStateManager,
} from "models/general";
import {
    getKindergartenClientAgeGroupYear,
    getKindergartenClientBasicInfoInitialValues as getKindergartenClientBasicDataInitialValues,
    getKindergartenClientRequest,
    getKindergartenClientStartSchool,
    getKindergartenClientStatus,
    KindergartenClient,
    KindergartenClientRequest,
    KindergartenClientStatus,
    validateKindergartenClientBasicInfo,
} from "models/kindergartenClient";
import { Moment } from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { KindergartenClientService } from "services/kindergarten";
import styles from "./ClientCommonStyle.module.scss";

const selectStyles = {
    input: (base: any) => ({
        ...base,
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
    }),
};
export interface ClientCreateEditBasicDataProps {
    isEdit: boolean;
    value?: Optional<KindergartenClient>;
    deadLine?: Optional<Moment>;
    isLoading?: boolean;
    schoolStart?: Moment;
    onSuccess?: () => void;
    onChange?: (value: KindergartenClient) => void;
}
export const ClientCreateEditBasicData: React.FC<
    ClientCreateEditBasicDataProps
> = ({
    deadLine,
    value,
    schoolStart,
    isEdit,
    isLoading,
    onSuccess,
    onChange,
}) => {
    const { checkPermission } = useCheckPermission();

    let hasEditPermission = true;
    if (isEdit) {
        hasEditPermission = checkPermission(Kindergarten_Client_BasicData, [
            PermissionAccessTypes.EDIT,
        ]);
    }

    const [state, setState] = useState<KindergartenClient>(
        value ? value : getKindergartenClientBasicDataInitialValues()
    );

    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const clientService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );

    const navigate = useNavigate();
    const {
        isLoading: creating,
        data: createResponse,
        mutate: addUpdate,
    } = useMutation(
        async (val: KindergartenClientRequest) =>
            await clientService.addUpdate(val),
        {
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess();
                }
            },
        }
    );
    const {
        isLoading: loadingCustomerNumber,
        data: customerNumberResponse,
        mutate: getCustomerNumber,
    } = useMutation(
        async (lastName: string) =>
            await clientService.getCustomerNumber(lastName)
    );

    const validityStateManager = useMemo(() => {
        const validationState = validateKindergartenClientBasicInfo(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    const { genderOptions, statusOptions } = useMemo(() => {
        const pleaseSelectItem: SelectItem = {
            Text: t("common.pleaseSelect"),
            Value: null,
        };
        const genderOptions: SelectItem[] = [
            pleaseSelectItem,
            ...Object.values(Gender).map((x) => {
                return {
                    Text: t(`common.gender.${x.toString()}`),
                    Value: x,
                } as SelectItem;
            }),
        ];

        const statusOptions: SelectItem[] = [
            ...Object.values(KindergartenClientStatus).map((x) => {
                return {
                    Text: t(`common.status.${x.toString()}`),
                    Value: x,
                } as SelectItem;
            }),
        ];
        return {
            genderOptions,
            statusOptions,
        };
    }, [t]);

    useEffect(() => {
        if (!loadingCustomerNumber && customerNumberResponse) {
            setState({
                ...state,
                CustomerNumber: customerNumberResponse.Data,
            });
        }
    }, [loadingCustomerNumber, customerNumberResponse]);

    useEffect(() => {
        if (state.EndOfCOntract && state.CareBeginning) {
            setState({
                ...state,
                Status: getKindergartenClientStatus(
                    state.CareBeginning,
                    state.EndOfCOntract
                ),
            });
        }
    }, [state.EndOfCOntract, state.CareBeginning]);

    useEffect(() => {
        if (state.AgeGroup) {
            setState({
                ...state,
                SchoolStart: getKindergartenClientStartSchool(
                    state.AgeGroup,
                    schoolStart
                ),
            });
        }
    }, [state.AgeGroup]);

    useEffect(() => {
        if (state.Birthday) {
            setState({
                ...state,
                AgeGroup: getKindergartenClientAgeGroupYear(
                    state.Birthday,
                    deadLine
                ),
            });
        }
    }, [state.Birthday]);

    useEffect(() => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!Boolean(state.FirstName) && !Boolean(state.LastName)) {
            setState({
                ...state,
                ShorthandSymbol: "",
            });
        }
        if (Boolean(state.FirstName) || Boolean(state.LastName)) {
            setState({
                ...state,
                ShorthandSymbol: getPersonShortName(
                    state.FirstName,
                    state.LastName
                ),
            });
        }
    }, [state.FirstName, state.LastName]);

    const onSubmitHandler = () => {
        addUpdate(getKindergartenClientRequest(state));
        if (!isNil(state.Id) && onChange) {
            onChange(state);
        }
    };

    useEffect(() => {
        if (!creating && createResponse && createResponse.Data) {
            if (isEdit) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createResponse.Data.FirstName}" ${t(
                        "common.actions.updatedSuccessfully"
                    )}`,
                    "success"
                );
            } else {
                showSweetAlertToast(
                    t("common.success"),
                    `"${createResponse.Data.FirstName}" ${t(
                        "common.actions.createdSuccessfully"
                    )}`,
                    "success"
                );

                // navigate to client edit (after waiting for sometime till new data is fetched)
                setTimeout(() => {
                    navigate(
                        linkProvider.kindergarten
                            .clients()
                            .screens.client.withId(
                                createResponse.Data.EncodedId
                            )
                            .edit()
                    );
                }, 500);
            }
        } else if (
            !creating &&
            createResponse &&
            createResponse.Code != null &&
            createResponse.Code == ErrorCodes.VALIDATION_ERROR
        ) {
            showSweetAlertToast(
                t("common.error.error"),
                createResponse.Errors && createResponse.Errors[0].Message
                    ? createResponse.Errors[0].Message
                    : "",
                "error"
            );
        }
    }, [createResponse, creating]);

    const fields = useMemo(() => {
        return (
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        showEmptyError={true}
                        label={t("kindergartenClient.basicInfo.firstName.name")}
                        value={state.FirstName}
                        disabled={!hasEditPermission}
                        error={validityStateManager.getFirstErrorInfo(
                            "FirstName"
                        )}
                        onValueChange={(val) => {
                            setState({
                                ...state,
                                FirstName: defaultTo(val, ""),
                            });
                        }}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>{t("common.gender.name")}</Form.Label>
                        <AppSelectOld
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            options={genderOptions}
                            value={genderOptions.find(
                                (x) => x.Value == state.Gender
                            )}
                            showEmptyError={true}
                            isDisabled={!hasEditPermission}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    Gender: e.Value as string,
                                })
                            }
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        useDefault={false}
                        label={t("kindergartenClient.basicInfo.endOfContract")}
                        appendToBody={true}
                        minDate={state.CareBeginning}
                        value={state.EndOfCOntract}
                        readOnly={!hasEditPermission}
                        onChange={(e) =>
                            setState({
                                ...state,
                                EndOfCOntract: e,
                                CareBeginning: state.CareBeginning
                                    ? state.CareBeginning
                                    : e,
                            })
                        }
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppLastNameSurnameFields
                        showAddSurName={isEdit}
                        lastName={state.LastName}
                        surName={state.NewLastName}
                        onLastNameChange={(e) =>
                            setState({ ...state, LastName: e })
                        }
                        onSurnameChange={(e) =>
                            setState({ ...state, NewLastName: e })
                        }
                        lastNameProps={{
                            disabled: !hasEditPermission,
                            error: validityStateManager.getFirstErrorInfo(
                                "LastName"
                            ),
                            onBlur: (value) => {
                                if (!isEdit) {
                                    // eslint-disable-next-line no-extra-boolean-cast
                                    if (Boolean(value)) {
                                        getCustomerNumber(value);
                                    } else {
                                        setState({
                                            ...state,
                                            CustomerNumber: "",
                                        });
                                    }
                                }
                            },
                        }}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        className={styles.height42}
                        useDefault={false}
                        label={t("kindergartenClient.basicInfo.birthday.name")}
                        appendToBody={true}
                        readOnly={!hasEditPermission}
                        value={state.Birthday}
                        showEmptyError={true}
                        onChange={(e) =>
                            setState({
                                ...state,
                                Birthday: e,
                            })
                        }
                        error={validityStateManager.getFirstErrorInfo(
                            "Birthday"
                        )}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        label={t("kindergartenClient.basicInfo.ageGroup")}
                        value={defaultTo(state.AgeGroup, "")}
                        showEmptyError={true}
                        disabled={true}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        showEmptyError={true}
                        label={t(
                            "kindergartenClient.basicInfo.shorthandSymbol"
                        )}
                        value={state.ShorthandSymbol}
                        disabled={true}
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        readOnly={!hasEditPermission}
                        className={styles.height42}
                        appendToBody={true}
                        useDefault={false}
                        showEmptyError={true}
                        label={t("kindergartenClient.basicInfo.careBeginning")}
                        maxDate={state.EndOfCOntract}
                        value={state.CareBeginning}
                        onChange={(e) =>
                            setState({
                                ...state,
                                CareBeginning: e,
                            })
                        }
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                            {t("kindergartenClient.basicInfo.status")}
                        </Form.Label>
                        <AppSelectOld
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            options={statusOptions}
                            value={statusOptions.find(
                                (x) => x.Value == state.Status
                            )}
                            showEmptyError={true}
                            isDisabled={true}
                            getOptionLabel={(opt: SelectItem) => opt.Text}
                            getOptionValue={(opt: SelectItem) => opt.Value}
                            onChange={(e: SelectItem) =>
                                setState({
                                    ...state,
                                    Status: e.Value as KindergartenClientStatus,
                                })
                            }
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppInputField
                        label={t("kindergartenClient.basicInfo.customerNumber")}
                        disabled={true}
                        showEmptyError={true}
                        value={defaultTo(state.CustomerNumber, "")}
                        onValueChange={(val) =>
                            setState({
                                ...state,
                                CustomerNumber: val,
                            })
                        }
                    />
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <AppDatePicker
                        className={styles.height42}
                        useDefault={false}
                        disabled={true}
                        showEmptyError={true}
                        appendToBody={true}
                        label={t("kindergartenClient.basicInfo.schoolStart")}
                        value={state.SchoolStart}
                        placeholder=""
                        readOnly={true}
                        displayFormat={getDateFormatForLocale()}
                        onChange={(e) =>
                            setState({
                                ...state,
                                SchoolStart: e,
                            })
                        }
                    />
                </Col>
                <Col xs={4} style={{ marginTop: "35px" }}>
                    <AppCheckbox
                        disabled={!hasEditPermission}
                        label={t("kindergartenClient.basicInfo.provision")}
                        checked={state.Provision}
                        onChange={(e) =>
                            setState({
                                ...state,
                                Provision: e.target.checked,
                                SchoolStart:
                                    state.SchoolStart &&
                                    (e.target.checked
                                        ? state.SchoolStart.add(1, "year")
                                        : state.SchoolStart.add(-1, "year")),
                            })
                        }
                    />
                </Col>
            </Row>
        );
    }, [validityStateManager, state, hasEditPermission]);
    if (isLoading) {
        console.log("isLoading");
        return <AppLoader />;
    }
    return (
        <AppContainer
            title={t("kindergartenClient.basicInfo.title")}
            // initiallyOpen={true}
            // isCollapsible={true}
            useMaxHeight={true}
            heightToAdjust={190}
            mobileViewAdjustment={0}
            mediumViewAdjustment={0}
            showHeader={isEdit}
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            (isEdit && !hasEditPermission) ||
                            !validityStateManager.isStateValid() ||
                            creating,
                        onClick: onSubmitHandler,
                    }}
                    // onCancelClick={() => (window.location.href = baseUrl)}
                />
            }
        >
            {fields}
        </AppContainer>
    );
};

export default ClientCreateEditBasicData;
