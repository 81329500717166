import { ImageAssets } from "globals/images";
import React from "react";
import { CustomArrowProps } from "react-slick";
import styles from "./AppSlidingTabArrows.module.scss";

export interface AppSlidingTabArrowsProps extends CustomArrowProps {
    position: "left" | "right";
    slidesToShow: number;
}
export const AppSlidingTabArrows: React.FC<AppSlidingTabArrowsProps> = ({
    position,
    slidesToShow,
    currentSlide = 0,
    slideCount = 0,
    onClick,
    className = "",
}) => {
    const rightCount = slideCount - currentSlide - slidesToShow;
    const leftCount = currentSlide;
    return (
        <div
            style={{
                position: "absolute",
                top: "6px",
                right: position === "right" ? 0 : undefined,
                left: position === "left" ? 0 : undefined,
            }}
        >
            {position === "left" ? (
                <div className="d-flex align-items-center">
                    <div
                        style={{
                            cursor: className.includes("slick-disabled")
                                ? "not-allowed"
                                : "pointer",
                        }}
                        onClick={onClick}
                    >
                        {/* <FontAwesomeIcon
                            className={styles.icon}
                            icon="angle-left"
                        /> */}
                        <img
                            className={styles.icon}
                            style={{ transform: "rotate(180deg)" }}
                            src={ImageAssets.common.tabSlidingArrow}
                        />
                    </div>
                    <div
                        className={`${styles.number} ${
                            leftCount > 0 && styles.numberBlue
                        } ml-2`}
                    >
                        {leftCount < 0 ? 0 : leftCount}
                        {leftCount > 0 && "+"}
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center">
                    <div
                        className={`${styles.number} ${
                            rightCount > 0 && styles.numberBlue
                        }`}
                    >
                        {rightCount < 0 ? 0 : rightCount}
                        {rightCount > 0 && "+"}
                    </div>
                    <div
                        onClick={onClick}
                        style={{
                            cursor: className.includes("slick-disabled")
                                ? "not-allowed"
                                : "pointer",
                        }}
                    >
                        <img
                            className={`${styles.icon} ml-2`}
                            src={ImageAssets.common.tabSlidingArrow}
                        />
                        {/* <FontAwesomeIcon
                            className={`${styles.icon} ml-2`}
                            icon="angle-right"
                        /> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppSlidingTabArrows;
