import {
    getFileDisplayName,
    getHumanFriendlySize,
} from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { defaultTo } from "lodash-es";
import { IFileWithId } from "models/general";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./AppAttachedFilesList.module.scss";

export interface AppAttachedFilesListProps {
    files?: IFileWithId[];
    heading?: string;
    className?: string;
    canDeleteFile?: boolean;
    maxHeight?: string;
    onFileDelete: (fileId: number) => void;
}

export const AppAttachedFilesList: React.FC<AppAttachedFilesListProps> = ({
    files,
    heading,
    className = "",
    canDeleteFile = false,
    maxHeight,
    onFileDelete,
}) => {
    const { t } = useTranslation();
    files = defaultTo(files, []);
    return (
        <div className={`${className} ${styles.root}`}>
            {heading && <div className={styles.heading}>{heading}</div>}
            <div
                className={styles.fileList}
                style={{ ...{ maxHeight: maxHeight } }}
            >
                {files.length > 0 ? (
                    files.map((f, index) => {
                        const name = getFileDisplayName(f);
                        return (
                            <div key={index} className={styles.fileItem}>
                                <div className={styles.fileName} title={name}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={f.FullUrl}
                                    >
                                        {name}
                                    </a>
                                </div>
                                <div className={styles.fileSize}>
                                    {getHumanFriendlySize(f.SizeInBytes)}
                                </div>
                                <div
                                    className={`${styles.delete} ${
                                        canDeleteFile
                                            ? styles.fileDeleteBtn
                                            : "disabled"
                                    }`}
                                    onClick={() => {
                                        if (canDeleteFile) {
                                            onFileDelete(f.Id);
                                        }
                                    }}
                                >
                                    <Image
                                        src={`${
                                            canDeleteFile
                                                ? ImageAssets.common.crossBlue
                                                : ImageAssets.common.crossGray
                                        }`}
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {t(`common.files.noFileFound`)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppAttachedFilesList;
