import { ISO8601_DATE_FORMAT, ISO8601_TIME_FORMAT } from "globals/constants";
import { defaultTo, isNil } from "lodash-es";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { BusinessShiftSortColumn, ShiftCriteriaType } from "./enum";
import { BusinessShift, BusinessShiftCriteria } from "./model";
import { BusinessShiftResponse } from "./response";

export const StringCriteriaTypes = [
    ShiftCriteriaType.Area,
    ShiftCriteriaType.Floor,
];
export const getEmptyShiftCriteria = (
    shiftId?: number
): BusinessShiftCriteria => {
    return {
        ShiftCriteriaType: ShiftCriteriaType.Function,
        uuid: uuid(),
        ShiftId: defaultTo(shiftId, 0),
        MinEmployees: 1,
    } as BusinessShiftCriteria;
};
export const parseBusinessShiftModelResponse = (
    res: BusinessShiftResponse
): BusinessShift => {
    return {
        ...res,
        StartDate: moment(res.StartDate, ISO8601_DATE_FORMAT),
        EndDate: !isNil(res.EndDate)
            ? moment(res.EndDate, ISO8601_DATE_FORMAT)
            : null,
        StartTime: res.StartTime
            ? moment(res.StartTime, ISO8601_TIME_FORMAT)
            : undefined,
        EndTime: res.EndTime
            ? moment(res.EndTime, ISO8601_TIME_FORMAT)
            : undefined,
        AreaIds:
            res.Areas && res.Areas.length > 0
                ? res.Areas?.map((x) => x.Id)
                : ([] as number[]),
        Criterias: defaultTo(res.Criterias, []).map((c) => ({
            ...c,
            uuid: uuid(), // needed for handling error messages
        })),
    } as BusinessShift;
};

export const getBusinessShiftKeyFromEnum = (
    sortColumn: BusinessShiftSortColumn | undefined
): keyof BusinessShift => {
    switch (sortColumn) {
        case BusinessShiftSortColumn.Name:
            return "Name";
        case BusinessShiftSortColumn.StartTime:
            return "StartTime";
        case BusinessShiftSortColumn.EndTime:
            return "EndTime";
        case BusinessShiftSortColumn.StartDate:
            return "StartDate";
        case BusinessShiftSortColumn.EndDate:
            return "EndDate";
        case BusinessShiftSortColumn.WorkingAreaId:
            return "Areas";
        default:
            return "Name";
    }
};
