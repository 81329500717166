import { AppTable, AppTableSkeleton } from "components/Table";
import { SortOrder, TableSort } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BankDataSortingColumns } from "./BankDataSortingColumns";
import BankDataTableRow from "./BankDataTableRow";
import {
    BankData,
    BankDataRequest,
    BankDataSortColumn,
    getBankDataSortKeyFromEnum,
} from "models/bankData";
import { PermissionAccessMap } from "models";
import { useRouting } from "hooks/general/routing";
import { BankDataService, getBankDataServiceKey } from "services/business";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useMutation, useQuery } from "react-query";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { orderBy } from "lodash-es";
export interface BankDataTableProps {
    permMap: PermissionAccessMap;
    request: BankDataRequest;
    onClick: (id: string) => void;
}
interface BankDataListState {
    list: BankData[];
    sort: TableSort<BankDataSortColumn>;
}
const COLUMNS_COUNT = 7;
export const BankDataTable: React.FC<BankDataTableProps> = ({
    permMap,
    onClick,
    request,
}) => {
    const { t } = useTranslation();
    const { encodedId } = useSessionBusiness();
    const { linkProvider } = useRouting();

    const [state, setState] = useState<BankDataListState>({
        list: [],
        sort: {
            SortColumn: BankDataSortColumn.IBAN,
            SortOrder: SortOrder.ASC,
        },
    });

    const bankDataService = new BankDataService(
        linkProvider.business.api.bankDatas
    );

    const {
        isLoading,
        isRefetching,
        data: listRes,
    } = useQuery(
        getBankDataServiceKey("list", {
            ...request,
            businessId: encodedId,
        }),
        async () => await bankDataService.list(request)
    );

    useEffect(() => {
        if (!isLoading && !isRefetching && listRes?.Data) {
            const sortedList = orderBy(
                listRes.Data as BankData[],
                getBankDataSortKeyFromEnum(state.sort.SortColumn),
                state.sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
            );
            setState({
                ...state,
                list: sortedList,
            });
        }
    }, [isLoading, isRefetching]);

    const { mutate: deleteBankData } = useMutation(
        async (id: string) => await bankDataService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    const toDelete = state.list.filter(
                        (x) => x.Id == deleteRes.Data
                    )[0];

                    showSweetAlertToast(
                        t("common.success"),
                        `IBAN "${toDelete.IBAN}" ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );

                    setState({
                        ...state,
                        list: state.list.filter((x) => x.Id != deleteRes.Data),
                    });
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );
    const onSortChange = (sort: TableSort<BankDataSortColumn>) => {
        const sortedList = orderBy(
            state.list,
            getBankDataSortKeyFromEnum(sort.SortColumn),
            sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
        );
        setState({
            ...state,
            sort: sort,
            list: sortedList,
        });
    };

    return (
        <AppTable hover={state.list.length > 0} heightToAdjust={150}>
            <thead>
                <BankDataSortingColumns
                    sort={state.sort}
                    onSortChange={onSortChange}
                />
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                {isLoading || isRefetching ? (
                    <AppTableSkeleton columns={COLUMNS_COUNT} rows={10} />
                ) : state.list.length > 0 ? (
                    state.list.map((x, idx) => (
                        <BankDataTableRow
                            key={idx}
                            value={x}
                            onClick={onClick}
                            onDeleteBtnClick={() =>
                                deleteBankData(x.EncodedId as string)
                            }
                            hasDeletePermission={permMap.DELETE}
                        />
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={COLUMNS_COUNT}
                            style={{ textAlign: "center" }}
                        >
                            {t("common.noDataFound")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default BankDataTable;
