export const CalendarEnTranslations = {
    calendar: {
        createdBy: "Created by",
        body: {
            missing: "Event body is required.",
        },
        calendar: "Calendar",
        createButton: "Create new event",
        endTime: {
            lessThanStart: "End time cannot be lesser than start time.",
            missing: "End time is required.",
            name: "End Time",
        },
        event: "Event",
        eventDay: {
            invalid: "Event date is required.",
            name: "Event Date",
        },
        singleDayEvent: "Single Day Event",
        endDate: {
            name: "End Date",
        },
        files: {
            missing: "File is required.",
            name: "Attachments",
            placeholder: "Choose file...",
        },
        fullDayEvent: "Full day event",
        newEvent: "New Event",
        pivot: {
            eventAlphabets: {
                disease: "D",
                school: "S",
                training: "T",
                free: "F",
                none: "N",
                vacation: "V",
            },
            name: "Pivot",
            resource: "Resource",
        },
        send: "Send",
        sendThisEvent: "Send this event to App",
        startTime: {
            invalid: "Start time must be less than end-time.",
            missing: "Start time is required.",
            name: "Start Time",
        },
        title: {
            missing: "Title is required.",
            name: "Title",
            placeholder: "Message Title",
        },
        updateEvent: "Update Event",
    },
};
export default CalendarEnTranslations;
