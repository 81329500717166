import { SimpleOption } from "components/AppSelect/partials";
import { ImageAssets } from "globals/images";
import React from "react";
import { Image } from "react-bootstrap";
import { components, IndicatorProps } from "react-select";
export type CrossIndicatorProps<OptionType> = IndicatorProps<
    OptionType,
    false
> & {
    onChange: (value: OptionType | undefined) => void;
};

const TypedClearIndicator: any = components.ClearIndicator;

export function CrossIndicator<OptionType = SimpleOption>(
    props: CrossIndicatorProps<OptionType>
) {
    const { onChange, ...rest } = props;

    const emptyOption = props.options.find((x: SimpleOption) => {
        if (
            // return the option with null value
            Object.keys(x).some((k) => {
                return x[k] == null;
            })
        ) {
            return x;
        }
    });

    return (
        <TypedClearIndicator {...rest}>
            <div style={{ cursor: "pointer" }}>
                <Image
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange(emptyOption);
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange(emptyOption);
                    }}
                    src={ImageAssets.common.crossRed}
                    style={{ width: "12px", height: "12px" }}
                />
            </div>
        </TypedClearIndicator>
    );
}

export default CrossIndicator;
