//General
export const GeneralUserProfileUpdate = "General.General_User_Profile_Update";
export const GeneralUserPasswordChange = "General.General_User_Password_Change";

// BusinessGeneralSubModulesConstants

export const Business_TimeTracking_Employees =
    "Business_General.TimeTracking.Employees";
export const Business_TimeTracking_Suppliers =
    "Business_General.TimeTracking.Suppliers";
export const Business_Dashboard_Client = "Business_General.Dashboard.Client";
export const Business_ToDo = "Business_General.ToDo";
export const Business_Calendar = "Business_General.Calendar.Basic";
export const Business_Calendar_Pivot = "Business_General.Calendar.Pivot";
export const Business_Dashboard_Employee =
    "Business_General.Dashboard.Employee";
export const Business_TimeTracking_Clients =
    "Business_General.TimeTracking.Clients";
export const Business_Payroll_AdditionalBenefits =
    "Business_General.Payroll.AdditionalBenefits";
export const Business_Payroll_Absences = "Business_General.Payroll.Absences";
export const Business_Chat = "Business_General.Chat";
export const Business_ShiftPlan = "Business_General.ShiftPlan";
export const Business_MailboxMessages = "Business_General.Mailbox_Messages";
export const Business_WebNotification = "Business_General.WebNotification";
export const Business_Downloads = "Business_General.Downloads";
export const Business_ModuleSelect = "Business_General.ModuleSelect";
export const Business_Creditor_BasicData =
    "Business_General.Creditors.Basic_Data";
export const Business_Creditor_BankData =
    "Business_General.Creditors.Bank_Data";

//Document Manager
export const Business_DocumentManager_Template =
    "Business_Document_Manager.Template";
export const Business_DocumentManager_Letterhead =
    "Business_Document_Manager.Letterhead";

// Admin Area
// Business Tabs
export const Business_BasicData = "Business.Basic_Data";
export const Business_Settings = "Business.Settings";
export const Business_AccessControl = "Business.Access_Control";
export const Business_Contracts = "Business.Contracts";
export const Business_CompensationTemplates = "Business.CompensationTemplates";
export const Business_EmployeeKeyCalculation =
    "Business.Employee_Key_Calculation";
export const Business_Courses = "Business.Courses";
export const Business_CareScopes = "Business.Care_Scopes";
export const Business_SalaryGroups = "Business.Salary_Groups";
export const Business_AgeGroups = "Business.Age_Groups";
export const Business_Groups = "Business.Groups";
export const Business_WorkingArea = "Business.Working_Area";
export const Business_Shifts = "Business.Shifts";
// export const Business_Kontos = "Business.Konto";
export const Business_Functions = "Business.Functions";
export const Business_Printers = "Business.Printers";
export const Business_Ipads = "Business.Ipads";
export const Business_PrinterJobs = "Business.Print_Jobs";
export const Business_WorkingHours = "Business.Working_Hours";
export const Business_PublicHolidays = "Business.Public_Holidays";
export const Business_Logo = "Business.Logo";
export const Business_MealTemplates = "Business.Meal_Templates.Basic";
export const Business_MealTemplates_Meal = "Business.Meal_Templates.Meal";
export const Business_MealTemplates_MealGroup =
    "Business.Meal_Templates.Meal_Group";
export const Business_DataImport_Client = "Business.Data_Import.Client";
export const Business_DataImport_ClientRegistration =
    "Business.Data_Import.ClientRegistration";
export const Business_DataImport_Employee = "Business.Data_Import.Employee";
export const Business_EmailTemplate = "Business.Email_Template";
export const Business_Archives = "Business.Archives";
export const Business_WebRegistration = "Business.Web_Registration";

// RoleManagement
export const Business_RoleManagement_UserRoles_Web =
    "Business_Role_Management.User_Roles.Web";
export const Business_RoleManagement_UserRoles_Mobile =
    "Business_Role_Management.User_Roles.Mobile";
export const Business_RoleManagement_PermissionGroups_Web =
    "Business_Role_Management.Permission_Groups.Web";
export const Business_RoleManagement_PermissionGroups_Mobile =
    "Business_Role_Management.Permission_Groups.Mobile";
export const Business_RoleManagement_PermissionGroups_Ipad =
    "Business_Role_Management.Permission_Groups.Ipad";

// Business Employees
export const Business_Employee_BasicData = "Business_Employee.Basic_Data";
export const Business_Employee_ContractData = "Business_Employee.Contract_Data";
export const Business_Employee_Documents = "Business_Employee.Documents";
export const Business_Employee_AccessControl =
    "Business_Employee.Access_Control";
export const Business_Employee_Diseases = "Business_Employee.Diseases";
export const Business_Employee_Vacations = "Business_Employee.Vacations";
export const Business_Employee_Groups = "Business_Employee.Groups";
export const Business_Employee_Shifts = "Business_Employee.Shift";
export const Business_Employee_Absences = "Business_Employee.Absences";
export const Business_Employee_SalaryStatement =
    "Business_Employee.Salary_Statement";
export const Business_Employee_BankData = "Business_Employee.Bank_Data";
export const Business_Employee_App = "Business_Employee.App";

// LWC (Client Businesses)
// Kindergarten
export const LW_Kindergarten_BasicData = "LW_Client.Kindergarten.Basic_Data";
export const LW_Kindergarten_Settings = "LW_Client.Kindergarten.Settings";
export const LW_Kindergarten_Contracts = "LW_Client.Kindergarten.Contracts";
export const LW_Kindergarten_AccessControl =
    "LW_Client.Kindergarten.Access_Control";
export const LW_Kindergarten_Documents = "LW_Client.Kindergarten.Documents";
export const LW_Kindergarten_WorkingDays =
    "LW_Client.Kindergarten.Working_Days";
export const LW_Kindergarten_PublicHolidays =
    "LW_Client.Kindergarten.Public_Holidays";
export const LW_Kindergarten_CompensationTemplates =
    "LW_Client.Kindergarten.CompensationTemplates";
export const LW_Kindergarten_EmployeeKeyCalculation =
    "LW_Client.Kindergarten.Employee_Key_Calculation";
export const LW_Kindergarten_IPads = "LW_Client.Kindergarten.IPads";
export const LW_Kindergarten_AgeGroups = "LW_Client.Kindergarten.AgeGroups";
export const LW_Kindergarten_CareScopes = "LW_Client.Kindergarten.CareScopes";
export const LW_Kindergarten_SalaryGroups =
    "LW_Client.Kindergarten.SalaryGroups";
export const LW_Kindergarten_Groups = "LW_Client.Kindergarten.Groups";
export const LW_Kindergarten_MealTemplates =
    "LW_Client.Kindergarten.Meal_Templates";
export const LW_Kindergarten_WorkingAreas =
    "LW_Client.Kindergarten.Working_Area";
export const LW_Kindergarten_Shifts = "LW_Client.Kindergarten.Shifts";
export const LW_Kindergarten_Courses = "LW_Client.Kindergarten.Courses";
// export const LW_Kindergarten_Kontos = "LW_Client.Kindergarten.Kontos";
export const LW_Kindergarten_Functions = "LW_Client.Kindergarten.Functions";
export const LW_Kindergarten_WebRegistration =
    "LW_Client.Kindergarten.Web_Registration";

// Industry
export const LW_Industry_BasicData = "LW_Client.Industry.Basic_Data";
export const LW_Industry_Settings = "LW_Client.Industry.Settings";
export const LW_Industry_Contracts = "LW_Client.Industry.Contracts";
export const LW_Industry_AccessControl = "LW_Client.Industry.Access_Control";
export const LW_Industry_Documents = "LW_Client.Industry.Documents";
export const LW_Industry_WorkingDays = "LW_Client.Industry.Working_Days";
export const LW_Industry_PublicHolidays = "LW_Client.Industry.Public_Holidays";
export const LW_Industry_Groups = "LW_Client.Industry.Groups";
export const LW_Industry_WorkingAreas = "LW_Client.Industry.Working_Area";
export const LW_Industry_Shifts = "LW_Client.Industry.Shifts";
export const LW_Industry_Courses = "LW_Client.Industry.Courses";
export const LW_Industry_IPads = "LW_Client.Industry.IPads";
export const LW_Industry_Functions = "LW_Client.Industry.Functions";
export const LW_Industry_Kontos = "LW_Client.Industry.Kontos";
export const LW_Industry_MealTemplates = "LW_Client.Industry.Meal_Templates";
// export const LW_Industry_CompensationTemplates =
//     "LW_Client.Industry.CompensationTemplates";
// export const LW_Industry_EmployeeKeyCalculation =
//     "LW_Client.Industry.Employee_Key_Calculation";
// export const LW_Industry_CareScopes = "LW_Client.Industry.CareScopes";
// export const LW_Industry_SalaryGroups = "LW_Client.Industry.SalaryGroups";
// export const LW_Industry_AgeGroups = "LW_Client.Industry.AgeGroups";

//Schools
export const LW_School_BasicData = "LW_Client.School.Basic_Data";
export const LW_School_Settings = "LW_Client.School.Settings";
export const LW_School_Contracts = "LW_Client.School.Contracts";
export const LW_School_Documents = "LW_Client.School.Documents";
export const LW_School_WorkingDays = "LW_Client.School.Working_Days";
export const LW_School_PublicHolidays = "LW_Client.School.Public_Holidays";

//Elderly Care
export const LW_ElderlyCare_BasicData = "LW_Client.ElderlyCare.Basic_Data";
export const LW_ElderlyCare_Settings = "LW_Client.ElderlyCare.Settings";
export const LW_ElderlyCare_Contracts = "LW_Client.ElderlyCare.Contracts";
export const LW_ElderlyCare_Documents = "LW_Client.ElderlyCare.Documents";
export const LW_ElderlyCare_WorkingDays = "LW_Client.ElderlyCare.Working_Days";
export const LW_ElderlyCare_PublicHolidays =
    "LW_Client.ElderlyCare.Public_Holidays";

export const LW_HqRegisterRequests = "Business.HqRegisterRequests";
// SupportView
export const LW_Support_MergeCompanies = "LW_Support.MergeCompanies";
export const LW_Support_Kindergartens = "LW_Support.Kindergartens";
export const LW_Support_Industry = "LW_Support.Industry";
export const LW_Support_Schools = "LW_Support.Schools";
export const LW_Support_ElderlyCare = "LW_Support.ElderlyCare";

// LocationSettings
export const LocationSettings_BasicData = "LocationSettings.Basic_Data";
export const LocationSettings_CompensationTemplates =
    "LocationSettings.CompensationTemplates";
export const LocationSettings_EmployeeKeyCalculation =
    "LocationSettings.Employee_Key_Calculation";
export const LocationSettings_CareScopes = "LocationSettings.Care_Scopes";
export const LocationSettings_AgeGroups = "LocationSettings.Age_Groups";
export const LocationSettings_SalaryGroups = "LocationSettings.Salary_Groups";
export const LocationSettings_Areas = "LocationSettings.Areas";
export const LocationSettings_Functions = "LocationSettings.Functions";
export const LocationSettings_PublicHolidays =
    "LocationSettings.Public_Holidays";
export const LocationSettings_DocumentTemplates =
    "LocationSettings.Document_Templates";
export const LocationSettings_DocumentLetterheads =
    "LocationSettings.Document_Letterheads";
export const LocationSettings_WorkingDays = "LocationSettings.Working_Days";
export const LocationSettings_EmailTemplate = "LocationSettings.Email_Template";
export const LocationSettings_CompanyData = "LocationSettings.Company_Data";
export const LocationSettings_Vouchers = "LocationSettings.Vouchers";
export const LocationSettings_Affiliates = "LocationSettings.Affiliates";
export const LocationSettings_ExplanationMode =
    "LocationSettings.Explanation_Mode";
export const LocationSettings_MealTemplates =
    "LocationSettings.Meal_Templates.Basic";
export const LocationSettings_MealTemplates_Meal =
    "LocationSettings.Meal_Templates.Meal";
export const LocationSettings_MealTemplates_MealGroup =
    "LocationSettings.Meal_Templates.Meal_Group";
export const LocationSettings_ModulePlan = "LocationSettings.ModulePlan";

// Kindergarten Clients (LWCC)
export const Kindergarten_Client_BasicData = "Kindergarten_Client.Basic_Data";
export const Kindergarten_Client_Contracts = "Kindergarten_Client.Contracts";
export const Kindergarten_Client_Documents = "Kindergarten_Client.Documents";
export const Kindergarten_Client_AccessControl =
    "Kindergarten_Client.Access_Control";
export const Kindergarten_Client_Diseases = "Kindergarten_Client.Diseases";
export const Kindergarten_Client_Vacations = "Kindergarten_Client.Vacations";
export const Kindergarten_Client_MedicalData =
    "Kindergarten_Client.Medical_Data";
export const Kindergarten_Client_Family = "Kindergarten_Client.Family";
export const Kindergarten_Client_Groups = "Kindergarten_Client.Groups";
export const Kindergarten_Client_BankData = "Kindergarten_Client.Bank_Data";
export const Kindergarten_Client_App = "Kindergarten_Client.App";
export const Kindergarten_Occupancy_Planing =
    "Kindergarten_Client.Occupancy_Planing";
export const Kindergarten_Age_Group_Planing =
    "Kindergarten_Client.Age_Group_Planing";
export const Kindergarten_Client_ExternalRegistrations =
    "Kindergarten_Client.External_Registrations";

// Mobile Screens
export const Mobile_Messages = "Screens.Messages";
export const Mobile_Chat = "Screens.Chat";
export const Mobile_Calendar_Events = "Screens.Calendar_Events";

// Ipad Screens
export const Ipad_TimeTracking_Employees = "TimeTracking.Employees";
export const Ipad_TimeTracking_Clients = "TimeTracking.Clients";

export const mandatoryPermissionsForAllUsers = [
    GeneralUserProfileUpdate,
    Business_WebNotification,
    Business_Downloads,
];
