import { EmployeeType } from "models";
import { UserType } from "models/general";
import React from "react";
import { TimeManagementMain } from "./TimeManagementMain";

export const TimeManagementSupplier: React.FC = () => {
    return (
        <TimeManagementMain
            userType={UserType.Employee}
            employeeType={EmployeeType.Supplier}
        />
    );
};

export default TimeManagementSupplier;
