import { Person, QRCodeBase } from "./models";

export interface AppResponse<T> extends ErrorResponse, SuccessResponse<T> { }

export interface SuccessResponse<T> {
    Meta?: ResponseMeta;
    Data: T;
}
export interface MetaDataFieldsResponse {
    CreatedAt?: number,
    UpdatedAt?: number,
    LastUpdatedById?: string,
    LastUpdatedBy?: Person
}
export interface ResponseMeta {
    LastItemOnPage?: number;
    FirstItemOnPage?: number;
    HasPreviousPage?: true;
    HasNextPage?: boolean;
    IsFirstPage?: boolean;
    IsLastPage?: boolean;
    PageCount?: number;
    TotalItemCount?: number;
    PageSize?: number;
    PageNumber?: number;
}

export interface ErrorResponse {
    Code?: ErrorCodes;
    Message?: string;
    Errors?: ErrorItem[];
}
export interface ErrorItem {
    Field: string;
    Message: string;
}
export interface QRCodeResponse extends QRCodeBase {
    CreatedAt: number;
    ValidTill: string;
    Code: string;
}

export interface BusinessSettingsListDataWithLocationFlag<T> {
    Data: T[];
    CanChangeLocationPreference: boolean;
}

export enum ErrorCodes {
    UNKNOWN_USER = "UNKNOWN_USER",
    UNKNOWN_USER_EMAIL = "UNKNOWN_USER_EMAIL",
    CONTRACT_DATES_OVERLAP = "ERROR_CONTRACT_DATES_OVERLAP",
    GROUP_DATES_OVERLAP = "ERROR_GROUP_DATES_OVERLAP",
    USER_UNCONFIRMED = "USER_UNCONFIRMED",
    USER_ALREADY_CONFIRMED = "USER_ALREADY_CONFIRMED",
    UNKNOWN_BUSINESS = "UNKNOWN_BUSINESS",
    INCORRECT_CREDENTIALS = "INCORRECT_CREDENTIALS",
    INCORRECT_REFRESH_TOKEN = "INCORRECT_REFRESH_TOKEN",
    BUSINESS_ALREADY_LINKED = "BUSINESS_ALREADY_LINKED",
    USER_DOESNOT_BELONG_TO_BUSINESS = "USER_DOESNOT_BELONG_TO_BUSINESS",
    VALIDATION_ERROR = "VALIDATION_ERROR",
    GENERAL_ERROR = "GENERAL_ERROR",
    UNEXPECTED_INPUT = "UNEXPECTED_INPUT",
    UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
    AUTHENTICATION_NEEDED = "AUTHENTICATION_NEEDED",
    ACCESS_DENIED = "ACCESS_DENIED",
    BAD_INPUT = "BAD_INPUT",
    MISSING_RESOURCE = "MISSING_RESOURCE",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    CARD_CREDENTIALS_ALREADY_EXIST = "CARD_CREDENTIALS_ALREADY_EXIST",
}
