import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContentHeader } from "components";
import { SearchField } from "components/FormFields";
import { AppOverlayLoader } from "components/Loaders";
import AppTabButtons, {
    AppTabButtonProps,
} from "components/Tabs/AppTabButtons";
import { saveInStorage } from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { useChatHelper, useGetCachedChannels } from "hooks/business";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { debounce, defaultTo, isNil } from "lodash-es";
import {
    AddChannelRequestModel,
    AddRemoveMemberRequest,
    ChannelCacheDTO,
    ChannelResponseModel,
    ChannelType,
    ChatTabsType,
    getChannelMessage,
    getChannelResponseModelObj,
    getChannelsList,
    getChatCacheRequest,
    getMembersInfoOfGroupsByChannel,
    getSortedChannels,
    GetUsersForChannelRequest,
} from "models/chat";
import { Optional } from "models/general";
import {
    Business_Chat,
    PermissionAccessTypes,
} from "models/permissionManagement";
import moment, { Moment } from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ChatService } from "services/business/ChatService";
import { Channel, ChannelDescriptor, Message } from "twilio-chat";
import { ChannelsListBox } from "./ChannelsListBox";
import { ChannelUserDialog } from "./ChannelUserDialog";
import styles from "./Chat.module.scss";
import ChatBox from "./ChatBox";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import { useTwilioClient } from "hooks/layout/useTwilioClient";

interface ChatState {
    activeTab: ChatTabsType;
    selectedChannel: Optional<ChannelResponseModel>;
    channels: ChannelResponseModel[];
    searchValue: string;
    refreshingChannelMembers: boolean;
    loadingTwilioChannels: boolean;
}

export const Chat: React.FC = () => {
    const { userId, getChatCacheKey, businessId } = useChatHelper();

    const { twilioClient: client, tokenResponse } = useTwilioClient();
    const [state, setState] = useState<ChatState>({
        selectedChannel: null,
        channels: [],
        activeTab: ChatTabsType.GROUPS,
        searchValue: "",
        loadingTwilioChannels: true,
        refreshingChannelMembers: false,
    });
    //for caching channels

    //for twilio events
    const handleOtherClientUpdate = useRef<any>(null);
    const onChannelUpdatedHandler = useRef<any>(null);
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();

    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const [addNewModelType, setAddNewModelType] =
        useState<GetUsersForChannelRequest>({
            ChannelSid: "",
            UsersType: ChatTabsType.GROUPS,
        });
    const [modelOpen, setModelOpen] = useState(false);
    const [modelLoading, setModelLoading] = useState(false);
    const [channelLoading, setChannelLoading] = useState(true);
    const { t } = useTranslation();
    const chatService = new ChatService(linkProvider.business.api.chat);
    const hasAddPermission = checkPermission(Business_Chat, [
        PermissionAccessTypes.CREATE,
    ]);

    //--------------hook for api calling -----------//

    const {
        isLoading: creatingChannel,
        data: createChannelResponse,
        mutate: createChannel,
    } = useMutation(
        async (params: AddChannelRequestModel) =>
            await chatService.createChannel(params)
    );

    const { data: addMembersResponse, mutate: addMembers } = useMutation(
        async (params: AddRemoveMemberRequest) =>
            await chatService.addMembers(params)
    );

    const { loading: loadingCachedChannels, response: responseCachedChannels } =
        useGetCachedChannels(getChatCacheKey());

    const { data: removeMembersResponse, mutate: removeMembers } = useMutation(
        async (params: AddRemoveMemberRequest) =>
            await chatService.removeMembers(params)
    );

    const { mutate: setCache, data: cacheUpdateResponse } = useMutation(
        async (params: ChannelCacheDTO[]) =>
            await chatService.setCachedChannels(params)
    );

    //-----------------------------------------//

    const updateChatCache = (channelsList: ChannelResponseModel[]) => {
        const toUpdate = getChatCacheRequest(channelsList);
        saveInStorage(getChatCacheKey(), toUpdate);
        // console.log("saved", stateRef.current.channels);
        setCache(toUpdate);
    };

    const onSearchValueChange = (value: string) => {
        setState({
            ...state,
            searchValue: value,
        });
    };
    //Response from cached channel
    //if response is empty then get channels from twilio api
    //set new cache and load the page

    const createNewStateForChannels = (
        channelsList: ChannelResponseModel[],
        oldState: ChatState,
        activeTab: ChatTabsType
    ) => {
        const oldSelectedChannel = channelsList
            ? channelsList.find(
                  (c) => c.ChannelId == oldState.selectedChannel?.ChannelId
              )
            : null;

        const newSelectedChannel =
            channelsList.length > 0 &&
            (oldState.selectedChannel == null ||
                oldSelectedChannel == null || // old channel is deleted
                (oldSelectedChannel.ChannelType == ChannelType.ONE_TO_ONE && // tab changed
                    activeTab != ChatTabsType.CHATS) ||
                (oldSelectedChannel.ChannelType != ChannelType.ONE_TO_ONE &&
                    activeTab == ChatTabsType.CHATS))
                ? channelsList.find((x) =>
                      activeTab == ChatTabsType.CHATS
                          ? x.ChannelType == ChannelType.ONE_TO_ONE
                          : x.ChannelType != ChannelType.ONE_TO_ONE
                  )
                : oldState.selectedChannel;

        return {
            channels:
                oldState.selectedChannel == null
                    ? [...channelsList]
                    : [
                          ...channelsList.map((x) => {
                              if (
                                  newSelectedChannel &&
                                  x.ChannelId === newSelectedChannel.ChannelId
                              ) {
                                  // set unread count to zero for selectedChannel
                                  return { ...x, UnReadMessageCount: 0 };
                              }
                              return x;
                          }),
                      ],
            selectedChannel: newSelectedChannel,
        };
    };
    const setCacheWithNewChannels = debounce(async (updateState?: boolean) => {
        const channelsList = await getChannelsList(
            client,
            businessId,
            userId,
            onChannelUpdatedHandler.current,
            handleOtherClientUpdate.current
        );

        if (updateState) {
            setState((oldState) => ({
                ...oldState,
                loadingTwilioChannels: false,
                ...createNewStateForChannels(
                    channelsList,
                    oldState,
                    oldState.activeTab
                ),
            }));
            updateChatCache(channelsList);
        }
    }, 2000); // wait for 2sec to call again to avoid data-refetching on frequent route changes

    //set cached channel response into the state
    //and call setCachedWithNewChannel to get latest channel and update cache
    useEffect(() => {
        (async () => {
            if (!loadingCachedChannels && responseCachedChannels && client) {
                setChannelLoading(true);
                if (
                    responseCachedChannels.Data &&
                    responseCachedChannels.Data.length > 0
                ) {
                    const channelList = [...responseCachedChannels.Data];

                    setState({
                        ...state,
                        channels: channelList,
                        selectedChannel:
                            state.selectedChannel == null &&
                            channelList.length > 0
                                ? channelList.find((x) =>
                                      state.activeTab == ChatTabsType.CHATS
                                          ? x.ChannelType ==
                                            ChannelType.ONE_TO_ONE
                                          : x.ChannelType !=
                                            ChannelType.ONE_TO_ONE
                                  )
                                : state.selectedChannel,
                    });
                    setChannelLoading(false);
                }

                // if (state.channels.length == 0) {
                //     setChannelLoading(true);
                // }

                await setCacheWithNewChannels(true);

                setChannelLoading(false);
            }
        })();
    }, [loadingCachedChannels, responseCachedChannels, client]);

    useEffect(() => {
        if (cacheUpdateResponse && cacheUpdateResponse.Data === true) {
            if (
                state.selectedChannel != null &&
                !state.refreshingChannelMembers
            ) {
                // allow tab to change then trigger to refetch members list
                onChannelSelectHandler(state.selectedChannel);
            }
        }
    }, [cacheUpdateResponse]);

    //handle clear chat
    const updateClearChatAt = (channelSid: string, chatClearAt: Moment) => {
        const copyState = { ...state };
        const index = copyState.channels.findIndex(
            (x) => x.ChannelId === channelSid
        );
        copyState.channels[index].ChatClearAt = chatClearAt;
        copyState.channels[index].LastMessage = null;
        setState({ ...copyState });
    };

    const readAllMessagesHandler = (channelSid: string) => {
        const copyState = { ...state };
        const index = copyState.channels.findIndex(
            (x) => x.ChannelId === channelSid
        );
        if (copyState.channels[index].UnReadMessageCount != 0) {
            copyState.channels[index].UnReadMessageCount = 0;
            setState({ ...copyState });
        }
    };

    //To update channels last message, count and date
    const readDeletedChannelInList = React.useMemo(() => {
        return async (channel: Channel) => {
            if (client) {
                const userChannels = await client.getUserChannelDescriptors();
                const userChannelSubscription: Optional<ChannelDescriptor> =
                    userChannels.items.find((x) => x.sid == channel.sid);

                const obj = await getChannelResponseModelObj(
                    userId,
                    client,
                    channel,
                    userChannelSubscription
                );
                if (obj) {
                    setState((prev) => ({
                        ...prev,
                        channels: [...prev.channels, obj],
                    }));
                }
            }
        };
    }, [state.channels, client]);
    //updated the channel item
    //adding last message time
    //adding un-react message count
    React.useMemo(() => {
        onChannelUpdatedHandler.current = async (channelChange: any) => {
            const { channel } = channelChange;
            if (channelChange.updateReasons[0] === "lastMessage") {
                const lastMessage: Message = (
                    await channelChange.channel.getMessages(1)
                ).items[0];

                setState((prev) => {
                    const copyState = { ...prev };
                    const index = copyState.channels.findIndex(
                        (x) => x.ChannelId === channel.sid
                    );
                    if (index < 0) {
                        copyState.channels = [...copyState.channels];
                    }
                    if (index >= 0) {
                        if (
                            copyState.selectedChannel == null ||
                            copyState.selectedChannel.ChannelId != channel.sid
                        ) {
                            copyState.channels[index].UnReadMessageCount =
                                defaultTo(
                                    copyState.channels[index]
                                        .UnReadMessageCount,
                                    0
                                ) + 1;
                        }

                        copyState.channels[index].LastMessage =
                            getChannelMessage(lastMessage);
                        copyState.channels[index].LastMessageActualDate =
                            moment(channel.lastMessage.dateCreated);
                        copyState.channels[index].LastMessageTime = moment(
                            channel.lastMessage.dateCreated
                        );
                        copyState.channels = getSortedChannels(
                            copyState.channels
                        );
                    } else {
                        readDeletedChannelInList(channelChange.channel);
                    }
                    return copyState;
                });
            }
        };
    }, [state.channels, state.selectedChannel]);

    React.useMemo(() => {
        //To update online status and if user already open the channel of one to one
        //this function will reassign the selected channel to update the chat box header
        //online status and also refreshing the ref of the handlerTwilioUserChange

        handleOtherClientUpdate.current = (user: any) => {
            setState((oldState) => {
                const copyState = { ...oldState };
                const remaining = copyState.channels.filter(
                    (item) => item.OtherMemberIdentityId === user.user.identity
                );

                for (const item of remaining) {
                    const index = copyState.channels.findIndex(
                        (x) => x.ChannelId === item.ChannelId
                    );

                    copyState.channels[index].IsOnline = user.user.online;
                }
                return copyState;
            });
        };
    }, [state.channels]);

    const removeChannelFromLocal = (channelSid: string) => {
        const responseCopy = [...state.channels];
        const index = responseCopy.findIndex(
            (item) => item.ChannelId === channelSid
        );

        if (index > -1) {
            responseCopy.splice(index, 1);
        }
        setState({
            ...state,
            selectedChannel: null,
        });
        setState((oldState) => ({
            ...oldState,
            channels: [...responseCopy],
            selectedChannel: responseCopy.find((x) =>
                state.activeTab == ChatTabsType.CHATS
                    ? x.ChannelType == ChannelType.ONE_TO_ONE
                    : x.ChannelType != ChannelType.ONE_TO_ONE
            ),
        }));
    };

    const onTabChangeHandler = (value: ChatTabsType) => {
        setState((old) => ({
            ...old,
            activeTab: value,
            ...createNewStateForChannels(old.channels, old, value),
        }));
    };

    ///Refresh the channels list and select
    // the newly created channels
    //and close the model

    const onModelCloseHandler = () => {
        setModelOpen(false);
        setModelLoading(false);
        setShowLoadingOverlay(false);
    };

    useEffect(() => {
        (async () => {
            if (
                client &&
                defaultTo(state?.selectedChannel?.ChannelId, "").length > 0
            ) {
                const selectTwilioChannel = await client.getChannelBySid(
                    defaultTo(state?.selectedChannel?.ChannelId, "")
                );
                const copyState = { ...state };
                const index = copyState.channels.findIndex(
                    (x) => x.ChannelId === selectTwilioChannel.sid
                );
                if (index >= 0 && !isNil(copyState.channels[index])) {
                    const members = getMembersInfoOfGroupsByChannel(
                        await selectTwilioChannel.getMembers()
                    );

                    copyState.channels[index].MemberCount =
                        await selectTwilioChannel.getMembersCount();

                    copyState.channels[index].ChannelInfo = members.slice(0, 3); // first 3 members in header info
                    copyState.channels[index].MembersDetails = members;
                    setState({
                        ...copyState,
                        selectedChannel: copyState.channels[index],
                    });
                }
                onModelCloseHandler();
            }
        })();
    }, [addMembersResponse, removeMembersResponse]);

    const filterChatChannelsInTab = (
        tabType: ChatTabsType,
        searchValue: string
    ): ChannelResponseModel[] => {
        const filteredChannels = state.channels.filter((item) => {
            const isGroup = tabType == ChatTabsType.GROUPS;
            const isOneToOne = tabType == ChatTabsType.CHATS;
            let resp = true;
            if (isGroup) {
                resp = item.ChannelType != ChannelType.ONE_TO_ONE;
            } else if (isOneToOne) {
                resp = item.ChannelType == ChannelType.ONE_TO_ONE;
            } else {
                resp = false;
            }

            return (
                resp &&
                (defaultTo(item.ChannelOriginalName, "")
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                    (item.ChannelDisplayName &&
                        item.ChannelDisplayName.toLowerCase().includes(
                            searchValue.toLowerCase()
                        )))
            );
        });
        return getSortedChannels(filteredChannels);
    };

    const onModelOpenHandler = (tab: ChatTabsType, channelSid: string) => {
        setAddNewModelType({ ChannelSid: channelSid, UsersType: tab });
        setModelOpen(true);
    };

    useEffect(() => {
        (async () => {
            if (!creatingChannel && createChannelResponse) {
                if (createChannelResponse.Data) {
                    const channelsList = await getChannelsList(
                        client,
                        businessId,
                        userId,
                        onChannelUpdatedHandler.current,
                        handleOtherClientUpdate.current,
                        createChannelResponse.Data
                    );

                    setState({
                        ...state,
                        channels: [...state.channels, ...channelsList],
                        selectedChannel: channelsList.find(
                            (x) => x.ChannelId === createChannelResponse.Data
                        ),
                    });
                } else if (createChannelResponse.Code) {
                    showUnexpectedErrorToast();
                }
                onModelCloseHandler();
            }
        })();
    }, [creatingChannel, createChannelResponse]);

    const onChannelSelectHandler = async (
        channelResponseModel: ChannelResponseModel
    ) => {
        if (!state.refreshingChannelMembers) {
            if (
                client &&
                defaultTo(channelResponseModel.ChannelId, "").length > 0
            ) {
                setState((old) => ({
                    ...old,
                    selectedChannel: channelResponseModel,
                    refreshingChannelMembers:
                        old.selectedChannel == null ||
                        old.selectedChannel.ChannelId !=
                            channelResponseModel.ChannelId // don't show loader again if same channel
                            ? true
                            : false,
                }));
                // fetch new members info
                const selectTwilioChannel = await client.getChannelBySid(
                    defaultTo(channelResponseModel.ChannelId, "")
                );
                const copyState = { ...state };
                const index = copyState.channels.findIndex(
                    (x) => x.ChannelId === selectTwilioChannel.sid
                );
                if (index >= 0 && !isNil(copyState.channels[index])) {
                    const members = getMembersInfoOfGroupsByChannel(
                        await selectTwilioChannel.getMembers()
                    );

                    copyState.channels[index].MemberCount =
                        await selectTwilioChannel.getMembersCount();

                    copyState.channels[index].MembersDetails = members;
                    if (
                        !isNil(copyState.channels[index].ChannelType) &&
                        [
                            ChannelType.BUSINESS_DEFAULT_GROUP,
                            ChannelType.BUSINESS_USERS_GROUP,
                        ].includes(
                            copyState.channels[index].ChannelType as ChannelType
                        )
                    ) {
                        copyState.channels[index].ChannelInfo = members.slice(
                            0,
                            3
                        ); // first 3 members in header info
                    }
                }

                setTimeout(() => {
                    setState((old) => ({
                        ...old,
                        refreshingChannelMembers: false,
                        channels: copyState.channels,
                        selectedChannel:
                            old.selectedChannel != null &&
                            old.selectedChannel.ChannelId ==
                                selectTwilioChannel.sid
                                ? copyState.channels[index]
                                : old.selectedChannel,
                    }));
                }, 300);
            }
        }
    };
    //to update the cache on component unmount
    const stateRef = useRef<any>(null);
    // keep it outside so ref is always up-to-date
    stateRef.current = state;
    //to remove all the event when user move the next page
    //and update the cache with new channels
    useEffect(() => {
        return () => {
            setCacheWithNewChannels.cancel();
            onChannelUpdatedHandler.current = undefined;
            updateChatCache(stateRef.current.channels);
        };
    }, []);

    const AddNewChannelHandler = (req: AddChannelRequestModel) => {
        if (state.loadingTwilioChannels) {
            return;
        }
        setShowLoadingOverlay(true);
        setModelLoading(true);
        if (addNewModelType.UsersType === ChatTabsType.ADD_MEMBERS) {
            addMembers({
                ChannelSid: addNewModelType.ChannelSid,
                MembersIdentities: req.MembersIdsList,
            });
        } else if (addNewModelType.UsersType === ChatTabsType.REMOVE_MEMBERS) {
            removeMembers({
                ChannelSid: addNewModelType.ChannelSid,
                MembersIdentities: req.MembersIdsList,
            });
        } else {
            req.AdminId = userId;
            createChannel(req);
            setState({
                ...state,
                activeTab:
                    req.ChannelType === ChannelType.ONE_TO_ONE
                        ? ChatTabsType.CHATS
                        : ChatTabsType.GROUPS,
            });
        }
    };

    const loading =
        showLoadingOverlay ||
        loadingCachedChannels ||
        state.loadingTwilioChannels;

    return (
        <>
            <AppContentHeader
                title={t("chat.title.title")}
                icon={ImageAssets.common.commentYellow}
            >
                <div>
                    <AppTabButtons
                        className={styles.appTab}
                        btnClass={styles.tabButton}
                        loading={state.refreshingChannelMembers}
                        tabButtons={[
                            {
                                label: t("chat.tabs.groups"),
                                onClick: () =>
                                    onTabChangeHandler(ChatTabsType.GROUPS),
                                active: state.activeTab === ChatTabsType.GROUPS,
                                extraContent: hasAddPermission && {
                                    loading: loading,
                                    onClick: () =>
                                        onModelOpenHandler(
                                            ChatTabsType.GROUPS,
                                            ""
                                        ),
                                },
                            } as AppTabButtonProps,
                            {
                                label: t("chat.tabs.chats"),
                                onClick: () =>
                                    onTabChangeHandler(ChatTabsType.CHATS),
                                active: state.activeTab === ChatTabsType.CHATS,
                                extraContent: hasAddPermission && {
                                    loading: loading,
                                    onClick: () =>
                                        onModelOpenHandler(
                                            ChatTabsType.CHATS,
                                            ""
                                        ),
                                },
                            } as AppTabButtonProps,
                        ]}
                    />
                </div>
            </AppContentHeader>

            {modelOpen && (
                <ChannelUserDialog
                    modalOpen={modelOpen}
                    onClose={onModelCloseHandler}
                    onChange={AddNewChannelHandler}
                    tabType={addNewModelType}
                    loading={modelLoading}
                />
            )}

            <div className={styles.root}>
                {showLoadingOverlay && <AppOverlayLoader />}
                <div className={styles.left}>
                    <div className={styles.searchModule}>
                        <SearchField
                            value={state.searchValue}
                            onValueChange={onSearchValueChange}
                        />
                    </div>
                    <div className={styles.showLatest}>
                        <span>{t("chat.showLatest")}</span>
                        <FontAwesomeIcon icon="chevron-down" />
                    </div>
                    <div className={styles.channelBoxDiv}>
                        <ChannelsListBox
                            activeChannel={defaultTo(
                                state.selectedChannel?.ChannelId,
                                ""
                            )}
                            loading={
                                channelLoading ||
                                (state.channels.length == 0 &&
                                    state.loadingTwilioChannels)
                            }
                            disabled={state.refreshingChannelMembers}
                            channelResponseList={filterChatChannelsInTab(
                                state.activeTab,
                                state.searchValue
                            )}
                            onChannelSelect={onChannelSelectHandler}
                        />
                    </div>
                </div>
                <div className={styles.right}>
                    {state.selectedChannel && (
                        <ChatBox
                            twilioChannelLoading={state.loadingTwilioChannels}
                            selectedChannel={state.selectedChannel}
                            updateSelectedChannel={(
                                updated: ChannelResponseModel
                            ) => {
                                setState({
                                    ...state,
                                    selectedChannel: updated,
                                    channels: state.channels.map((c) => {
                                        if (c.ChannelId == updated.ChannelId) {
                                            return updated;
                                        }
                                        return c;
                                    }),
                                });
                            }}
                            client={client}
                            setLoading={setShowLoadingOverlay}
                            blockMembershipRoleSid={
                                tokenResponse ? tokenResponse.BlockRoleSid : ""
                            }
                            userId={userId}
                            removeChannelFromLocal={removeChannelFromLocal}
                            onModelOpenHandler={onModelOpenHandler}
                            readAllMessagesHandler={readAllMessagesHandler}
                            updateClearChatAt={updateClearChatAt}
                            onChannelUpdatedHandler={
                                onChannelUpdatedHandler.current
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Chat;
