import { ISO8601_DATE_FORMAT } from "globals/constants";
import { AppResponse } from "models/general";
import {
    ChildFamilyResponse,
    ControllerType,
    DailyAccessEntryResponse,
    ManualEntryRequest,
    ManualTimeTrackingEntryRequest,
    TimeTrackingRequestParameters,
    TimeTrackingTableDataResponse,
} from "models/timeManagement";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface ITimeTrackingService {
    getDailyAccessDetails: (
        accessControlId: number
    ) => Promise<AppResponse<DailyAccessEntryResponse[]>>;

    getChildFamilyInfo: (
        childId: number
    ) => Promise<AppResponse<ChildFamilyResponse>>;
    addNewEntry: (
        req: ManualTimeTrackingEntryRequest
    ) => Promise<AppResponse<DailyAccessEntryResponse>>;

    updateManualEntry: (
        req: ManualEntryRequest
    ) => Promise<AppResponse<DailyAccessEntryResponse>>;

    getEventList: (
        reqParams: TimeTrackingRequestParameters
    ) => Promise<AppResponse<TimeTrackingTableDataResponse>>;

    syncBusinessEvents: () => Promise<AppResponse<boolean>>;
}

export function getTimeTrackingServiceKey(
    name: keyof ITimeTrackingService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `timeTracking_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext().business.api.timeTracking;
type ApiLinkProviderType = typeof apiLinkProvider;

export class TimeTrackingService
    extends BaseService
    implements ITimeTrackingService
{
    public routes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    getChildFamilyInfo(
        childId: number
    ): Promise<AppResponse<ChildFamilyResponse>> {
        return this.doServerXHR<AppResponse<ChildFamilyResponse>>({
            url: this.routes.childFamilyInfo(childId),
            method: "get",
        });
    }

    getDailyAccessDetails(
        accessControlId: number,
        controllerType = ControllerType.Main
    ): Promise<AppResponse<DailyAccessEntryResponse[]>> {
        return this.doServerXHR<AppResponse<DailyAccessEntryResponse[]>>({
            url: this.routes.dailyAccessDetails(
                accessControlId,
                controllerType
            ),
            method: "get",
        });
    }
    updateManualEntry(
        req: ManualEntryRequest // For LW Employee table
    ): Promise<AppResponse<DailyAccessEntryResponse>> {
        return this.doServerXHR<boolean>({
            url: this.routes.updateManualEntry(),
            method: "post",
            data: req,
        });
    }
    addNewEntry(
        req: ManualTimeTrackingEntryRequest // for LWCC
    ): Promise<AppResponse<DailyAccessEntryResponse>> {
        return this.doServerXHR<boolean>({
            url: this.routes.addNewEntry(),
            method: "post",
            data: req,
        });
    }
    syncBusinessEvents(): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.syncBusiness(),
            method: "post",
        });
    }

    getEventList(
        reqParams: TimeTrackingRequestParameters
    ): Promise<AppResponse<TimeTrackingTableDataResponse>> {
        return this.doServerXHR<AppResponse<TimeTrackingTableDataResponse>>({
            url: this.routes.getEventList({
                ...reqParams,
                Date: reqParams.Date.format(ISO8601_DATE_FORMAT),
            } as any),
            method: "get",
        });
    }
}
