import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { Business_MailboxMessages, PermissionAccessTypes } from "models";
import { MessageNavigation } from "models/messages";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";
import MessageService, {
    getMessageServiceKey,
} from "services/business/MessageService";
import styles from "./MessagesLayout.module.scss";
import { MessagesNav } from "./partials";
import MessagesList from "./partials/messageLists/MessagesListPartial";

type MailboxContext = {
    refetchMessageCount: () => void;
    activeMenu: MessageNavigation;
    search?: string;
};

interface MessageLayoutState {
    loading: boolean; //for list
    search?: string;
    activeMenu: MessageNavigation;
}

export const MessagesLayout: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const location = useLocation();

    const [state, setState] = useState<MessageLayoutState>({
        activeMenu: MessageNavigation.Sent,
        loading: false,
    });

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const { data: messageCount, refetch: refetchMessageCount } = useQuery(
        getMessageServiceKey("getMessageCount"),
        async () => await messageService.getMessageCount() // automatically refetch based on key change
    );

    const isCreate = location.pathname.endsWith("create");
    const isDetail = location.pathname.endsWith("details");

    const redirectToList = () => {
        navigate(linkProvider.business.screens.mailbox.list());
    };

    const { checkPermission } = useCheckPermission();

    const hasCreatePermission = checkPermission(Business_MailboxMessages, [
        PermissionAccessTypes.CREATE,
    ]);

    const onCreateClick = () => {
        if (hasCreatePermission) {
            navigate(linkProvider.business.screens.mailbox.create());
        }
    };

    return (
        <>
            <AppContentHeader
                title={t("common.messages")}
                classNameForIcon="pe-7s-mail"
            >
                <AppRoundedTextIconButton
                    className={styles.createBtn}
                    onClick={onCreateClick}
                    text={t("message.newMessage")}
                />
            </AppContentHeader>
            <div className={styles.messageMain}>
                <MessagesNav
                    isCreate={isCreate}
                    activeMenu={state.activeMenu}
                    messageCount={defaultTo(messageCount?.Data, {
                        InboxMessages: 0,
                        SentMessages: 0,
                        FavMessages: 0,
                        TrashMessages: 0,
                    })}
                    search={state.search}
                    setSearch={(value) => {
                        setState({
                            ...state,
                            search: value,
                        });
                    }}
                    onChange={(active) => {
                        setState({
                            ...state,
                            activeMenu: active,
                        });
                        if (isCreate || isDetail) {
                            redirectToList();
                        }
                    }}
                />
                <Outlet
                    context={{
                        refetchMessageCount,
                        activeMenu: state.activeMenu,
                        search: state.search,
                    }}
                />
            </div>
        </>
    );
};

export default MessagesList;

export function useMessageLayoutContext() {
    return useOutletContext<MailboxContext>();
}
