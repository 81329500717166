import arrayMove from "array-move";
import { AppIconTextButton } from "components/Buttons";
import { AppFieldsWrapperContainer } from "components/Containers";
import { AppSortable } from "components/Sortable";
import { defaultTo } from "lodash-es";
import { SalaryGroupTemplateField, getDefaultSalaryGroupField } from "models";
import { ValidityState } from "models/general";
import { useTranslation } from "react-i18next";
import { SortableElement } from "react-sortable-hoc";
import SalaryGroupTemplateFieldRow from "./SalaryGroupTemplateFieldRow";
export interface SalaryGroupTemplateFieldsContainerProps<
    T extends SalaryGroupTemplateField
> {
    fields: T[];
    onChange: (newFields: T[]) => void;
    readonly?: boolean;
    validityState?: ValidityState;
}

const SortableItem = SortableElement(SalaryGroupTemplateFieldRow);
export const SalaryGroupTemplateFieldsContainer = <
    T extends SalaryGroupTemplateField
>({
    fields,
    onChange,
    readonly = false,
    validityState,
}: SalaryGroupTemplateFieldsContainerProps<T>) => {
    const { t } = useTranslation();

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (!readonly) {
            onChange(arrayMove(fields, oldIndex, newIndex));
        }
    };

    return (
        <AppFieldsWrapperContainer
            title={t("salaryGroupTemplate.title")}
            isCollapsible={true}
        >
            <>
                {(!readonly || defaultTo(fields, []).length == 0) && (
                    <AppIconTextButton
                        text={t("common.addNew")}
                        icon={"plus"}
                        padding="med"
                        style={{ maxWidth: "180px", textAlign: "start" }}
                        disabled={readonly}
                        onClick={() => {
                            onChange([
                                ...fields,
                                getDefaultSalaryGroupField() as T,
                            ]);
                        }}
                    />
                )}

                {fields && (
                    <AppSortable<T>
                        SortableItem={SortableItem}
                        list={fields}
                        handleSortEnd={handleSortEnd}
                        validityState={validityState}
                        readonly={readonly}
                        onChange={(field) => {
                            const oldIndex = fields.findIndex(
                                (f) => f.Uuid == field.Uuid
                            );
                            if (oldIndex >= 0) {
                                onChange(
                                    fields.map((nf) => {
                                        if (nf.Uuid == field.Uuid) {
                                            return field;
                                        } else {
                                            return nf;
                                        }
                                    })
                                );
                            } else {
                                onChange([...fields, field]);
                            }
                        }}
                        onDelete={(uuid: string) => {
                            onChange(fields.filter((f) => f.Uuid != uuid));
                        }}
                    />
                )}
            </>
        </AppFieldsWrapperContainer>
    );
};

export default SalaryGroupTemplateFieldsContainer;
