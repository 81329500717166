import { TippyProps } from "@tippyjs/react";
import { ErrorInfo } from "models/general/validityState";
import React from "react";
import AppTooltip from "./AppTooltip";

export interface AppTooltipErrorProps extends TippyProps {
    className?: string;
    arrow?: boolean;
    trigger: string;
    error: ErrorInfo;
}

export const AppTooltipError = React.memo<AppTooltipErrorProps>((props) => {
    const {
        className = "",
        arrow = false,
        trigger = "mouseenter focus",
        error,
        ...rest
    } = props;
    return (
        <AppTooltip
            theme={"error"}
            content={error && error.message}
            className={className}
            arrow={arrow}
            allowHTML={false}
            trigger={trigger}
            animation="fade"
            delay={100}
            {...rest}
        />
    );
});

export default AppTooltipError;
