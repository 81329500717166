import { AppContentHeader } from "components";
import AppRoundedTextIconButton from "components/Buttons/AppRoundedTextIconButton";
import { SearchField } from "components/FormFields";
import AppTabButtons from "components/Tabs/AppTabButtons";
import { SEARCH_DELAY_TIME } from "globals/constants";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import {
    Business_ToDo,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { TodoFilterRequest } from "models/todo";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./TodoManagementHeader.module.scss";

export interface TodoManagementHeaderProp {
    showRightSideSection?: boolean;
    filter?: TodoFilterRequest;
    onFilterChange: (filter: TodoFilterRequest) => void;
}

export const TodoManagementHeader: React.FC<TodoManagementHeaderProp> = ({
    showRightSideSection = false,
    filter,
    onFilterChange,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { checkPermission } = useCheckPermission();

    const hasTodoCreatePermission = checkPermission(Business_ToDo, [
        PermissionAccessTypes.CREATE,
    ]);
    const onCreateClick = () => {
        navigate(linkProvider.business.screens.todoManagement.create());
    };

    const [search, setSearch] = useState<string | null>(null);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                onFilterChange({
                    ...filter,
                    SearchString: search,
                    PageNumber: 1,
                });
            }, SEARCH_DELAY_TIME);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);
    return (
        <AppContentHeader
            title={t("todoManagement.todo")}
            classNameForIcon="pe-7s-users"
        >
            {showRightSideSection && (
                <>
                    <AppTabButtons
                        btnClass={styles.tabButton}
                        animated={false}
                        activeClass={styles.btnActive}
                        containerClass={styles.tabs}
                        tabButtons={[
                            {
                                label: t("todoManagement.all"),
                                onClick: () =>
                                    onFilterChange({
                                        ...filter,
                                        CreatedByMe: undefined,
                                        AssignedToMe: undefined,
                                    }),

                                active:
                                    (filter?.CreatedByMe === undefined ||
                                        !filter.CreatedByMe) &&
                                    (filter?.AssignedToMe === undefined ||
                                        !filter.AssignedToMe),
                            },
                            {
                                label: t("todoManagement.assignToMe"),
                                onClick: () =>
                                    onFilterChange({
                                        ...filter,
                                        CreatedByMe: undefined,
                                        AssignedToMe: true,
                                    }),
                                style: { minWidth: "205px" },
                                active: filter?.AssignedToMe,
                            },
                            {
                                label: t("todoManagement.createdByMe"),
                                onClick: () =>
                                    onFilterChange({
                                        ...filter,
                                        CreatedByMe: true,
                                        AssignedToMe: undefined,
                                    }),
                                style: { minWidth: "225px" },
                                active: filter?.CreatedByMe,
                            },
                        ]}
                    />
                    <div className={styles.headerRightSection}>
                        <div className={styles.searchField}>
                            <SearchField
                                value={defaultTo(search, "")}
                                onValueChange={(value: string) =>
                                    setSearch(value)
                                }
                            />
                        </div>
                        <div className={styles.createBtn}>
                            <AppRoundedTextIconButton
                                readOnly={!hasTodoCreatePermission}
                                onClick={() => onCreateClick()}
                            />
                        </div>
                    </div>
                </>
            )}
        </AppContentHeader>
    );
};

export default TodoManagementHeader;
