import { ISO8601_TIME_FORMAT } from "globals/constants";
import moment from "moment";
import { Moment } from "moment-timezone";
import { WorkingDays, WorkingDaysNumber } from "./enum";
import {
    BusinessWorkingDays,
    WorkingDayData,
    WorkingDaysNumberMapping,
    WorkingDaysStringMapping,
} from "./model";

export const weekDaysStringToNumDict: WorkingDaysStringMapping = {
    [WorkingDays.Monday]: WorkingDaysNumber.Monday,
    [WorkingDays.Tuesday]: WorkingDaysNumber.Tuesday,
    [WorkingDays.Wednesday]: WorkingDaysNumber.Wednesday,
    [WorkingDays.Thursday]: WorkingDaysNumber.Thursday,
    [WorkingDays.Friday]: WorkingDaysNumber.Friday,
    [WorkingDays.Saturday]: WorkingDaysNumber.Saturday,
    [WorkingDays.Sunday]: WorkingDaysNumber.Sunday,
};

export const weekDaysNumToStringDict: WorkingDaysNumberMapping = {
    [WorkingDaysNumber.Monday]: WorkingDays.Monday,
    [WorkingDaysNumber.Tuesday]: WorkingDays.Tuesday,
    [WorkingDaysNumber.Wednesday]: WorkingDays.Wednesday,
    [WorkingDaysNumber.Thursday]: WorkingDays.Thursday,
    [WorkingDaysNumber.Friday]: WorkingDays.Friday,
    [WorkingDaysNumber.Saturday]: WorkingDays.Saturday,
    [WorkingDaysNumber.Sunday]: WorkingDays.Sunday,
};
export function getWorkingDaysCount(
    startDate: Moment,
    endDate: Moment,
    workingDays: BusinessWorkingDays
) {
    let count = 0;

    const curDate = startDate.clone();
    while (curDate.isSameOrBefore(endDate, "day")) {
        const dayOfWeek = curDate.isoWeekday() as WorkingDaysNumber;

        if (workingDays[weekDaysNumToStringDict[dayOfWeek]]) {
            count++;
        }
        curDate.add(1, "day");
    }

    return count;
}
export function isBusinessCurrentlyOpen(
    dayInfo: WorkingDayData,
    allowedTimeHoursCushion: number = 1
) {
    if (dayInfo) {
        if (!dayInfo.IsWorkingDay) {
            return false;
        }

        if (dayInfo.IsOpen24Hours) {
            return true;
        }

        const currTime = moment(
            moment().format(ISO8601_TIME_FORMAT),
            ISO8601_TIME_FORMAT
        );
        const start =
            allowedTimeHoursCushion == 0
                ? dayInfo.From
                : dayInfo.From.clone().add(
                      -1 * allowedTimeHoursCushion,
                      "hours"
                  );
        const end =
            allowedTimeHoursCushion == 0
                ? dayInfo.To
                : dayInfo.To.clone().add(allowedTimeHoursCushion, "hours");

        return dayInfo.IsWorkingDay && currTime >= start && currTime <= end;
    }
    return false;
}
