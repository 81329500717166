import React from "react";
import styles from "./WorkingDayDataRow.module.scss";
import { Col, Form } from "react-bootstrap";
import { WorkingDayData, WorkingDays } from "models";
import { AppSwitch, ErrorMessage } from "components";
import { AppCheckbox, AppTimePicker } from "components/FormFields";
import { ValidityStateManager, ValidityState } from "models/general";
import { Moment } from "moment-timezone";
import { useTranslation } from "react-i18next";

export interface WorkingDayDataRowProps {
    day: WorkingDays;
    value: WorkingDayData;
    onChange: (field: WorkingDayData) => void;
    validityState?: ValidityState;
    readonly?: boolean;
}

export const WorkingDayDataRow: React.FC<WorkingDayDataRowProps> = ({
    onChange,
    readonly,
    day: name,
    value,
    validityState,
}) => {
    const { t } = useTranslation();
    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.rowRoot}>
            <Form.Row style={{ overflow: "inherit" }}>
                <Col
                    sm={3}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "220px",
                    }}
                >
                    <AppCheckbox
                        id={`day-${name}`}
                        checked={value.IsActive}
                        disabled={readonly}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            onChange({
                                ...value,
                                IsActive: checked,
                            });
                        }}
                        label={t(`common.days.${name}`)}
                    />
                </Col>

                <Col sm={3}>
                    <AppTimePicker
                        readOnly={readonly || value.IsOpen24Hours}
                        label={t("workingDays.from.name")}
                        className={styles.inlineTimeField}
                        placeholder={t("workingDays.from.name")}
                        value={value.From}
                        onChange={(val) => {
                            onChange({
                                ...value,
                                From: val as Moment,
                            });
                        }}
                    />
                    <ErrorMessage
                        style={{ marginLeft: "50px" }}
                        errorInfo={validityStateManager.getFirstErrorInfo(
                            "From"
                        )}
                    />
                </Col>
                <Col sm={3}>
                    <AppTimePicker
                        readOnly={readonly || value.IsOpen24Hours}
                        className={styles.inlineTimeField}
                        label={t("workingDays.to.name")}
                        placeholder={t("workingDays.to.name")}
                        value={value.To}
                        onChange={(val) => {
                            onChange({
                                ...value,
                                To: val as Moment,
                            });
                        }}
                    />
                    <ErrorMessage
                        style={{ marginLeft: "50px" }}
                        errorInfo={validityStateManager.getFirstErrorInfo("To")}
                    />
                </Col>
                <Col
                    sm={3}
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        maxWidth: "250px",
                    }}
                >
                    <AppSwitch
                        label={t("workingDays.open24Hours.name")}
                        disabled={readonly}
                        className={styles.switch}
                        value={value.IsOpen24Hours}
                        labelPosition="left"
                        color="blue-toggle"
                        onChange={(checked) =>
                            onChange({
                                ...value,
                                IsOpen24Hours: checked,
                                ...(!checked
                                    ? {}
                                    : {
                                          From: value.From.startOf("day"),
                                          To: value.To.add(1, "d").startOf(
                                              "day"
                                          ),
                                      }),
                            })
                        }
                    />
                </Col>
            </Form.Row>
        </div>
    );
};
export default WorkingDayDataRow;
