/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from "i18next";
import $ from "jquery";
import { defaultTo, isNil } from "lodash-es";
import {
    DynamicPlaceholderType,
    getPlaceholderTypeName,
    Placeholder,
    PlaceholderOptionsBase,
    PlaceholderType,
} from "models/tinymceEditor";
import tinymce from "tinymce";
import { v4 as uuid } from "uuid";
import {
    DynamicInputPlaceholderHandler,
    getLocalizedDynamicPlaceholderName,
    getLocalizedDynamicPlaceholderTypeName,
    getPreparedPlaceholder,
    PlaceholderContainerClass,
    placeholderHandlersMap,
} from "./PlaceholderHandlers";
import { PlaceholderPluginOptions, PlaceholderTypeHandler } from "./Types";

// no need to add languages here, it seems to be working fine without it
// tinymce.PluginManager.requireLangPack(
//     "placeholder",
//     Object.values(AppLocale)
//         .map((s) => s.split("-")[0])
//         .filter((l) => l != "en")
//         .join(",")
// );

const pluginName = "placeholder";
const pluginText = i18next.t("placeholders");

tinymce.PluginManager.add(pluginName, function (editor: any, url) {
    let toggledType: DynamicPlaceholderType = "All";
    let sidebarElem: any = null;
    const uuidVal = uuid();
    const pluginOptions: PlaceholderPluginOptions = editor.getParam(
        "placeholder_options"
    );

    function insertOrUpdatePlaceholder(placeholder: Placeholder) {
        let placeholders: Placeholder[] = [];
        if (
            pluginOptions
                .getPlaceholders()
                .find((p) => p.Guid === placeholder.Guid) != null
        ) {
            //edit
            placeholders = pluginOptions.getPlaceholders().map((p) => {
                if (p.Guid === placeholder.Guid) {
                    return placeholder;
                } else {
                    return p;
                }
            });
        } else {
            //new
            placeholders = [...pluginOptions.getPlaceholders(), placeholder];
        }

        pluginOptions.onPlaceholderChange(placeholders);
        setTimeout(() => {
            editor.insertContent(placeholder.Output);
        }, 100);
    }

    function addOrUpdatePlaceholder(
        data: PlaceholderOptionsBase,
        existing: Placeholder,
        typeHandler: PlaceholderTypeHandler
    ) {
        data = typeHandler.adaptOptions(data);
        if (typeHandler.isValidOptions(data)) {
            const newPlaceholder = getPreparedPlaceholder(
                existing,
                data,
                typeHandler
            );
            insertOrUpdatePlaceholder(newPlaceholder);
            return true;
        }
        return false;
    }

    function openPlaceholderCreateEditDialog(
        placeholder: Placeholder | null,
        typeHandler: PlaceholderTypeHandler
    ) {
        if (isNil(typeHandler)) {
            return;
        }
        const dialogProps = typeHandler.getDialogProps(
            placeholder,
            pluginOptions
        );
        const title =
            placeholder != null
                ? i18next.t("placeholders.editPlaceholder")
                : i18next.t("placeholders.addPlaceholder");

        editor.windowManager.open(
            {
                title: title,
                body: {
                    type: "panel",
                    items: dialogProps.items,
                },
                buttons: [
                    {
                        type: "cancel",
                        text: "Close",
                    },
                    {
                        type: "submit",
                        text: "Save",
                        primary: true,
                    },
                ],
                initialData: dialogProps.initialData,
                onSubmit: function (api: any) {
                    const data: any = api.getData();
                    if (
                        addOrUpdatePlaceholder(
                            typeHandler.Type === PlaceholderType.DynamicInput
                                ? ({
                                      ...data,
                                      Field: JSON.parse(data.Field),
                                  } as PlaceholderOptionsBase)
                                : (data as PlaceholderOptionsBase),
                            placeholder as Placeholder,
                            typeHandler
                        )
                    ) {
                        api.close();
                    }
                },
            },
            {}
        );
    }

    function hookEvents() {
        const $: any = tinymce.dom.DomQuery;

        const allowedFormatCommands = [
            "mceToggleFormat",
            "mceApplyTextcolor",
            "mceRemoveTextcolor",
            "FontName",
            "FontSize",
            "Italic",
            "Bold",
            "Underline",
            "Strikethrough",
            "Subscript",
            "Superscript",
            "JustifyCenter",
            "JustifyLeft",
            "JustifyRight",
            "JustifyFull",
            "LineHeight",
            "RemoveFormat",
        ];

        function wrapBody(isBodySelected: boolean = false) {
            const body = $(editor.getBody());
            if (body.find(".wrapper").length === 0) {
                body.html(`<div class='wrapper'>${body.html()}</div>`);
                if (isBodySelected) {
                    editor.selection.select($("div.wrapper")[0]);
                }
            }
        }
        // Register a event before certain commands run that will turn contenteditable off temporarily on noneditable fields
        editor.on("BeforeExecCommand", function (e: any) {
            const node = editor.selection.getNode();
            const isBodySelected = node.nodeName.toLowerCase() === "body";
            wrapBody(isBodySelected);

            if (allowedFormatCommands.indexOf(e.command) !== -1) {
                // The commands we want to permit formatting noneditable items
                // just wrap nonEditable placeholders content with new span tag so formatting can be applied
                $(editor.getBody())
                    .find(`.${nonEditableClass}:not(.${lockClass})`)
                    .each((i: number, e: any) => {
                        const elem = $(e);
                        if (
                            elem.parent().prop("tagName").toLowerCase() !==
                                "span" ||
                            elem.parent().text() !== elem.text()
                        ) {
                            elem.wrap("<span></span>");
                        }
                        elem.attr("contenteditable", null);
                    });
            }
        });
        // Turn the contenteditable attribute back to false after the command has executed
        editor.on("ExecCommand", function (e: any) {
            $(editor.getBody())
                .find(`.${nonEditableClass}:not(.${lockClass})`)
                .attr("contenteditable", false);
        });
    }

    let selectedGuid: string | null | undefined;

    let typeHandler: PlaceholderTypeHandler;
    function updateSidebarHtml() {
        const typeTemp = typeHandler as DynamicInputPlaceholderHandler;

        const toggleBtns = `<div id="${uuidVal}"class="toggle-btns-row">
                ${typeTemp
                    .getPlaceholderTypeToggles(
                        pluginOptions.dynamicPlaceholderProps
                    )
                    .map((t) => {
                        return `
                        <button class="btn type-toggle-btn placeholder-chip ${
                            t.val === toggledType ? "active" : ""
                        }" data-type="${t.val}"> ${t.name}</button>
                    `;
                    })
                    .join("")}
                </div>`;
        const titleElem = `<h4 class="title">${i18next.t(
            "tinymce.placeholdersPanelTitle"
        )}</h4>`;
        const html = `
                <div class="right-editor-panel">
                ${titleElem}
                    ${toggleBtns}
                    <div class="placeholders-sidebar-container">
                        ${typeTemp
                            .getDynamicPlaceholdersForType(
                                toggledType,
                                pluginOptions
                            )
                            .map((i) => {
                                return `<button draggable="true" onclick="return false" class="btn btn-outline-primary placeholder-chip" data-val='${JSON.stringify(
                                    i.field
                                )}'>
                                    ${
                                        toggledType === "All"
                                            ? getLocalizedDynamicPlaceholderTypeName(
                                                  i.field.Namespace
                                              ) + "=>"
                                            : ""
                                    }${getLocalizedDynamicPlaceholderName(
                                    i.field.Identifier
                                )}
                                </button>`;
                            })
                            .join("")}
                    </div>
                </div>`;
        if (sidebarElem) {
            sidebarElem.innerHTML = html;
        }
    }
    if (defaultTo(pluginOptions.includeSidebar, false)) {
        pluginOptions.allowedTypes = pluginOptions.allowedTypes.filter(
            (t) => t !== PlaceholderType.DynamicInput
        );
        typeHandler = placeholderHandlersMap[PlaceholderType.DynamicInput];

        editor.on("dragover", function (e: any) {
            // by default browsers block drop in containers
            e.preventDefault();
        });

        $(document).on("click", `#${uuidVal} .type-toggle-btn`, function (e) {
            e.preventDefault();
            e.stopPropagation();
            toggledType = $(this)
                .attr("data-type")
                ?.toString() as DynamicPlaceholderType;
            updateSidebarHtml();
        });

        $(document).on("dragstart", ".placeholder-chip", function (e: any) {
            e.originalEvent.dataTransfer.setData(
                "placeholder",
                $(this).attr("data-val")
            );
        });
        editor.on("drop", function (e: any) {
            if (e.dataTransfer) {
                e.preventDefault();
                const field = e.dataTransfer.getData("placeholder");
                if (field) {
                    const placeholder = getPreparedPlaceholder(
                        null,
                        typeHandler.adaptOptions({
                            Field: JSON.parse(field),
                        } as any),
                        typeHandler
                    );
                    insertOrUpdatePlaceholder(placeholder);
                }
            }
        });

        editor.ui.registry.addSidebar("placeholderSidebar", {
            tooltip: i18next.t("placeholders.PlaceholderSidebar"),
            icon: "code-sample",
            onSetup: function (api: any) {
                sidebarElem = api.element();
                const hasPlaceholders =
                    pluginOptions.dynamicPlaceholderProps.length > 0;
                if (hasPlaceholders) {
                    updateSidebarHtml();

                    setTimeout(() => {
                        editor.execCommand(
                            "togglesidebar",
                            true,
                            "placeholdersidebar"
                        );
                    }, 1000);
                }
            },
            onShow: function (api: any) {
                // console.log("Show panel", api.element());
            },
            onHide: function (api: any) {
                // console.log("Hide panel", api.element());
            },
        });
    }

    editor.ui.registry.addButton("placeholderEdit", {
        icon: "edit-block",
        text: "Edit",
        tooltip: "Edit",
        onAction: () => {
            if (selectedGuid !== null) {
                const placeholder = pluginOptions
                    .getPlaceholders()
                    .find((p) => p.Guid === selectedGuid);
                selectedGuid = undefined;
                if (placeholder != null) {
                    openPlaceholderCreateEditDialog(
                        placeholder,
                        placeholderHandlersMap[placeholder.Type]
                    );
                }
            }
        },
    });

    function addContextToolbar() {
        const isPlaceholderTag = function (node: any) {
            const isPlaceholder =
                node.nodeName.toLowerCase() === "span" &&
                node.classList[1] === PlaceholderContainerClass;
            if (isPlaceholder) {
                selectedGuid = node.attributes["data-guid"].nodeValue;
            }
            return isPlaceholder;
        };

        editor.ui.registry.addContextToolbar("placeholder-context-toolbar", {
            predicate: isPlaceholderTag,
            items: "placeholderEdit",
            position: "selection",
            scope: "node",
        });
    }

    hookEvents();
    addContextToolbar();
    if (pluginOptions.allowedTypes.length > 0) {
        editor.ui.registry.addSplitButton(pluginName, {
            text: getPlaceholderTypeName(pluginOptions.allowedTypes[0]),
            onAction: function (_: any) {
                openPlaceholderCreateEditDialog(
                    null,
                    placeholderHandlersMap[pluginOptions.allowedTypes[0]]
                );
            },
            onItemAction: function (buttonApi: any, value: PlaceholderType) {
                openPlaceholderCreateEditDialog(
                    null,
                    placeholderHandlersMap[value]
                );
            },
            fetch: function (callback: any) {
                callback(
                    Object.values(pluginOptions.allowedTypes).map((t) => {
                        return {
                            type: "choiceitem",
                            text: getPlaceholderTypeName(t),
                            value: t,
                        };
                    })
                );
            },
        });
    }
    const nonEditableClass = editor.getParam(
        "noneditable_noneditable_class",
        "mceNonEditable"
    );
    const lockClass = "custom-content-lock";
    editor.ui.registry.addToggleButton("lockContent", {
        tooltip: "Lock Content",
        icon: "lock",
        onAction: function (api: any) {
            let node = $(editor.selection.getNode());
            if (
                !node.hasClass("placeholder-tag") &&
                node.parents(lockClass).length === 0
            ) {
                const isReadonly = node.hasClass(lockClass);
                if (node.prop("tagName").toLowerCase() === "body") {
                    if (node.children(".wrapper").length === 0) {
                        //never make body non-editable
                        node.wrapInner(() => `<div class='wrapper'></div>`);
                    }
                    node = node.find(".wrapper");
                }
                node.attr("contenteditable", isReadonly.toString()); // class will be removed
                api.setActive(!isReadonly);
                node.toggleClass(lockClass);
                node.toggleClass(nonEditableClass);
            }
        },
        onSetup: function (api: any) {
            editor.on("NodeChange", function (state: any) {
                const node = $(editor.selection.getNode());
                api.setActive(
                    node.hasClass(lockClass) ||
                        node.parents(lockClass).length > 0
                );
            });
        },
    });
    return {
        getMetadata: function () {
            return {
                name: pluginText,
                url: "http://exampleplugindocsurl.com",
            };
        },
    };
});
