import { BankDataCreateEdit } from "commonPartials";
import { AppLoader } from "components/Loaders";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentCreditorContext } from "screens/business/creditors/tabs/CreditorTabs";

export const CreditorBankDataCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const { dataItem: creditor } = useCurrentCreditorContext();
    const linkProviderBase =
        linkProvider.business.screens.creditors.withId().bankData;

    return isNil(creditor) ? (
        <AppLoader />
    ) : (
        <BankDataCreateEdit
            onBack={() => navigate(linkProviderBase.list())}
            request={{ CreditorId: creditor.Id }}
        />
    );
};

export default CreditorBankDataCreate;
