import { DatePickerType } from "models/datepicker";
import React from "react";
import {
    AppReactDatePickerBase,
    AppReactDatePickerBaseProps,
} from "./partials";
export const AppDatePicker: React.FC<
    Omit<AppReactDatePickerBaseProps, "datePickerType">
> = ({ showYearDropdown = true, ...rest }) => {
    return (
        <AppReactDatePickerBase
            {...rest}
            datePickerType={DatePickerType.DATE}
            showYearDropdown={showYearDropdown}
        />
    );
};

export default AppDatePicker;
