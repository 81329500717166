import { useEffect, useMemo, useState } from "react";
import { defaultTo, isNil } from "lodash-es";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";
import { useRouting } from "hooks/general/routing";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { useMutation, useQuery } from "react-query";
import { BusinessChatSettings } from "models/business/model";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { AppContainer } from "components/Containers";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import {
    BusinessType,
    Business_Settings,
    getDefaultChatSettings,
    LW_Industry_Settings,
    LW_Kindergarten_Settings,
} from "models";
import { useParams } from "react-router-dom";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { Row, Col } from "react-bootstrap";
import ChatSettingsFlagsPartial from "./ChatSettingsFlagsPartial";
import { AppSwitch } from "components";
import { AppButton } from "components/Buttons";
import styles from "./BusinessChatSettingsLayout.module.scss";
import classNames from "classnames";
import { UserType } from "models/general";

export const BusinessChatSettingsLayout: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { dataItem: currentBusiness, refetchData } =
        useCurrentBusinessSettingsContext();
    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();
    const [state, setState] = useState<BusinessChatSettings | null>(null);
    const { lwcId } = useParams();
    const isLwc = !isNil(lwcId);
    const { getPermissionMap } = useBusinessCheckPermission();
    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(lwcId)
    );

    useEffect(() => {
        if (currentBusiness && !state) {
            setState(getDefaultChatSettings(currentBusiness.Id));
        }
    }, [currentBusiness]);
    const { isLoading: loading, data: settings } = useQuery(
        getCurrentBusinessServiceKey("getChatSettings"),
        async () => await businessService.getChatSettings()
    );

    useEffect(() => {
        if (settings && !loading && settings.Data) {
            setState(settings.Data);
        }
    }, [loading, settings]);

    const {
        isLoading: updating,
        data: updateResponse,
        mutate: updateFlags,
    } = useMutation(
        getCurrentBusinessServiceKey("updateChatSettings"),
        async (flags: BusinessChatSettings) =>
            await businessService.updateChatSettings(flags)
    );
    const permMap = useMemo(() => {
        return !isLwc
            ? getPermissionMap(
                  currentBusiness?.Id as number,
                  currentBusiness?.HqId as number,
                  Business_Settings
              )
            : getPermissionMap(
                  sessionBusinessId,
                  sessionHqId,
                  currentBusiness?.Type == BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_Settings
                      : LW_Industry_Settings
              );
    }, [getPermissionMap]);

    useEffect(() => {
        if (!updating && updateResponse) {
            if (updateResponse.Data != null) {
                if (updateResponse.Data) {
                    if (refetchData) {
                        refetchData();
                    }
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("business.chatSettings.settingsUpdated")}`,
                        "success"
                    );
                }
            } else if (updateResponse.Code != null) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("common.error.requestFailed")}`,
                    "error"
                );
            }
        }
    }, [updating, updateResponse]);

    const showParentSettings =
        currentBusiness?.Type === BusinessType.KINDERGARTEN;
    return (
        <AppContainer>
            <div className={styles.title}>
                {t("business.chatSettings.generalSettings")}
            </div>
            <Row style={{ marginBottom: "15px" }}>
                <Col sm={12}>
                    <AppSwitch
                        label={t(
                            "business.chatSettings.employeeParentCareGroup"
                        )}
                        disabled={!permMap.EDIT}
                        className={styles.switch}
                        value={defaultTo(
                            state?.AllowAllUsersBusinessCareGroup,
                            true
                        )}
                        onChange={(checked) => {
                            if (state) {
                                setState({
                                    ...state,
                                    AllowAllUsersBusinessCareGroup: checked,
                                });
                            }
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <AppSwitch
                        label={t(
                            "business.chatSettings.employeeParentCompanyGroup"
                        )}
                        disabled={!permMap.EDIT}
                        className={styles.switch}
                        value={defaultTo(
                            state?.AllowAllUsersBusinessDefaultGroup,
                            false
                        )}
                        onChange={(checked) => {
                            if (state) {
                                setState({
                                    ...state,
                                    AllowAllUsersBusinessDefaultGroup: checked,
                                });
                            }
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <AppSwitch
                        label={t(
                            "business.chatSettings.webUserInEmployeeParentCareGroupChat"
                        )}
                        disabled={
                            !permMap.EDIT ||
                            !state?.AllowAllUsersBusinessCareGroup
                        }
                        className={styles.switch}
                        value={defaultTo(
                            state?.AllowWebUsersInAllUsersCareGroupChats,
                            false
                        )}
                        onChange={(checked) => {
                            if (state) {
                                setState({
                                    ...state,
                                    AllowWebUsersInAllUsersCareGroupChats:
                                        checked,
                                });
                            }
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <AppSwitch
                        label={t(
                            "business.chatSettings.webUserInEmployeeParentCompanyChat"
                        )}
                        disabled={
                            !permMap.EDIT ||
                            !state?.AllowAllUsersBusinessDefaultGroup
                        }
                        className={styles.switch}
                        value={defaultTo(
                            state?.AllowWebUsersInAllUserFacilityChats,
                            false
                        )}
                        onChange={(checked) => {
                            if (state) {
                                setState({
                                    ...state,
                                    AllowWebUsersInAllUserFacilityChats:
                                        checked,
                                });
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                {showParentSettings && (
                    <Col lg={6}>
                        <ChatSettingsFlagsPartial
                            permMap={permMap}
                            userType={UserType.Parent}
                            className={styles.parentFlags}
                            value={
                                state
                                    ? {
                                          businessDefaultGroup: defaultTo(
                                              state.AllowParentBusinessDefaultGroup,
                                              true
                                          ),
                                          businessCareGroup: defaultTo(
                                              state.AllowParentBusinessCareGroup,
                                              true
                                          ),
                                          webUserCareGroupChannel: defaultTo(
                                              state.AllowWebUsersInParentCareGroupChats,
                                              true
                                          ),
                                          webUserFacilityChannel: defaultTo(
                                              state.AllowWebUsersInParentFacilityChats,
                                              true
                                          ),
                                          chatWithCareGroupParents: defaultTo(
                                              state.AllowParentSingleChatWithCareGroupParents,
                                              true
                                          ),
                                          chatWithCareGroupEmployees: defaultTo(
                                              state.AllowParentSingleChatWithCareGroupEmployees,
                                              true
                                          ),
                                          chatWithAllParents: defaultTo(
                                              state.AllowParentSingleChatWithCompanyParents,
                                              false
                                          ),
                                          chatWithAllEmployees: defaultTo(
                                              state.AllowParentSingleChatWithCompanyEmployees,
                                              false
                                          ),
                                      }
                                    : {
                                          businessDefaultGroup: true,
                                          businessCareGroup: true,
                                          chatWithCareGroupParents: true,
                                          chatWithCareGroupEmployees: true,
                                          chatWithAllParents: false,
                                          chatWithAllEmployees: false,
                                          webUserCareGroupChannel: true,
                                          webUserFacilityChannel: true,
                                      }
                            }
                            onChange={(val) => {
                                if (state) {
                                    setState({
                                        ...state,
                                        AllowParentBusinessDefaultGroup:
                                            val.businessDefaultGroup,
                                        AllowParentBusinessCareGroup:
                                            val.businessCareGroup,
                                        AllowWebUsersInParentCareGroupChats:
                                            val.webUserCareGroupChannel,
                                        AllowWebUsersInParentFacilityChats:
                                            val.webUserFacilityChannel,
                                        AllowParentSingleChatWithCareGroupParents:
                                            val.chatWithCareGroupParents,
                                        AllowParentSingleChatWithCareGroupEmployees:
                                            val.chatWithCareGroupEmployees,
                                        AllowParentSingleChatWithCompanyParents:
                                            val.chatWithAllParents,
                                        AllowParentSingleChatWithCompanyEmployees:
                                            val.chatWithAllEmployees,
                                    });
                                }
                            }}
                            title={t("business.chatSettings.titleParents")}
                        />
                    </Col>
                )}
                <Col lg={showParentSettings ? 6 : 12}>
                    <ChatSettingsFlagsPartial
                        permMap={permMap}
                        // className={styles.parentFlags}
                        value={
                            state
                                ? {
                                      businessDefaultGroup: defaultTo(
                                          state.AllowEmployeeBusinessDefaultGroup,
                                          true
                                      ),
                                      businessCareGroup: defaultTo(
                                          state.AllowEmployeeBusinessCareGroup,
                                          true
                                      ),
                                      webUserCareGroupChannel: defaultTo(
                                          state.AllowWebUsersInEmployeeCareGroupChats,
                                          true
                                      ),
                                      webUserFacilityChannel: defaultTo(
                                          state.AllowWebUsersInEmployeeFacilityChats,
                                          true
                                      ),
                                      chatWithCareGroupParents: defaultTo(
                                          state.AllowEmployeeSingleChatWithCareGroupParents,
                                          true
                                      ),
                                      chatWithCareGroupEmployees: defaultTo(
                                          state.AllowEmployeeSingleChatWithCareGroupEmployees,
                                          true
                                      ),
                                      chatWithAllParents: defaultTo(
                                          state.AllowEmployeeSingleChatWithCompanyParents,
                                          false
                                      ),
                                      chatWithAllEmployees: defaultTo(
                                          state.AllowEmployeeSingleChatWithCompanyEmployees,
                                          true
                                      ),
                                  }
                                : {
                                      businessDefaultGroup: true,
                                      businessCareGroup: true,
                                      chatWithCareGroupParents: true,
                                      chatWithCareGroupEmployees: true,
                                      chatWithAllParents: false,
                                      chatWithAllEmployees: true,
                                      webUserCareGroupChannel: true,
                                      webUserFacilityChannel: true,
                                  }
                        }
                        onChange={(val) => {
                            if (state) {
                                setState({
                                    ...state,
                                    AllowEmployeeBusinessDefaultGroup:
                                        val.businessDefaultGroup,
                                    AllowEmployeeBusinessCareGroup:
                                        val.businessCareGroup,
                                    AllowWebUsersInEmployeeCareGroupChats:
                                        val.webUserCareGroupChannel,
                                    AllowWebUsersInEmployeeFacilityChats:
                                        val.webUserFacilityChannel,
                                    AllowEmployeeSingleChatWithCareGroupParents:
                                        val.chatWithCareGroupParents,
                                    AllowEmployeeSingleChatWithCareGroupEmployees:
                                        val.chatWithCareGroupEmployees,
                                    AllowEmployeeSingleChatWithCompanyParents:
                                        val.chatWithAllParents,
                                    AllowEmployeeSingleChatWithCompanyEmployees:
                                        val.chatWithAllEmployees,
                                });
                            }
                        }}
                        title={t("business.chatSettings.titleEmployees")}
                    />
                </Col>
            </Row>
            <div style={{ marginTop: "10px" }}>
                <AppButton
                    padding="med"
                    className={styles.btn}
                    disabled={updating || loading || !permMap.EDIT}
                    onClick={() => (state ? updateFlags(state) : {})}
                >
                    {t("common.save")}
                </AppButton>
                <AppButton
                    padding="med"
                    variant="light"
                    colorVariant="transparent"
                    className={classNames(styles.btn, styles.cancel)}
                    onClick={() => {
                        if (state) {
                            setState({
                                ...getDefaultChatSettings(
                                    currentBusiness ? currentBusiness.Id : 0
                                ),
                                Id: state.Id,
                                BusinessId: state.BusinessId,
                            });
                        }
                    }}
                >
                    {t("common.reset")}
                </AppButton>
            </div>
        </AppContainer>
    );
};

export default BusinessChatSettingsLayout;
