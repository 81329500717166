import {
    AuthEnTranslations,
    BusinessEnTranslations,
    CalendarEnTranslations,
    ChatEnTranslations,
    CommonEnTranslations,
    DataImportEnTranslations,
    DocumentsAndEditorEnTranslations,
    EmojiPickerEnTranslations,
    EmployeeEnTranslations,
    ErrorEnTranslations,
    KindergartenClientEnTranslations,
    AccountingEnTranslations,
    LocationSettingEnTranslations,
    LwClientEnTranslations,
    MealTemplateEnTranslations,
    MessagesEnTranslations,
    NotificationManagementEnTranslations,
    RoleManagementEnTranslations,
    SidebarEnTranslations,
    SettingTemplatesEnTranslations,
    TimeManagementEnTranslations,
    PlanningReportsEnTranslations,
    TodoManagementEnTranslations,
    ShiftPlanEnTranslations,
    EmployeeAdditionalBenefitsEnTranslations,
} from "./modules";

export const enTranslation = {
    translation: {
        ...SidebarEnTranslations,
        ...AuthEnTranslations,
        ...BusinessEnTranslations,
        ...KindergartenClientEnTranslations,
        ...CommonEnTranslations,
        ...PlanningReportsEnTranslations,
        ...DataImportEnTranslations,
        ...DocumentsAndEditorEnTranslations,
        ...EmployeeEnTranslations,
        ...MealTemplateEnTranslations,
        ...NotificationManagementEnTranslations,
        ...RoleManagementEnTranslations,
        ...SettingTemplatesEnTranslations,
        ...TimeManagementEnTranslations,
        ...LocationSettingEnTranslations,
        ...TodoManagementEnTranslations,
        ...ErrorEnTranslations,
        ...ShiftPlanEnTranslations,
        ...EmojiPickerEnTranslations,
        ...LwClientEnTranslations,
        ...ChatEnTranslations,
        ...CalendarEnTranslations,
        ...AccountingEnTranslations,
        ...MessagesEnTranslations,
        ...EmployeeAdditionalBenefitsEnTranslations,
        header: {
            myAccount: "My Account",
            logout: "Logout",
        },
        userProfile: {
            editProfile: "Edit Profile",
            manage: "Manage",
            manageProfile: "Manage Profile",
            firstName: "First Name",
            lastName: "Last Name",
            avatar: {
                name: "Avatar",
                helpMessage:
                    "For best result try to upload square images of size at-least",
            },
            changePassword: "Change Password",
            oldPassword: "Old Password",
            newPassword: "New Password",
            confirmPassword: "Confirm Password",
            success: "User profile updated successfully.",
            error: "Unable to update user profile...",
        },
        accessControl: {
            accessControlChipCheck: {
                name: "Chip Check",
                chipExistMessage: `This chip exist on "{{ControllerName}}" ({{ControllerType}}).`,
                chipNotExistMessage: `This chip doesn't exist on "{{ControllerName}}" ({{ControllerType}}).`,
                chipUsedMessage: "This chip is used",
                chipNotUsedMessage: "This chip is not in use.",
            },
            actions: "Actions",
            addCard: "Add Card",
            cardChip: {
                missing: "Card / Chip is required",
                name: "Card / Chip",
            },
            cardDeleted: "Card deleted successfully.",
            cardEnabled: "Card enabled successfully.",
            cardDisabled: "Card disabled successfully.",
            credentialsInvalid:
                "Unable to connect. Please check your settings and try again.",
            credentialsUpdated: "Card credentials successfully updated.",
            credentialsValid: "Connection Successful",
            disabled: "Disabled",
            enabled: "Enabled",
            pin: {
                name: "Pin",
                number: "Only numbers are allowed",
            },
            status: "Status",
            syncStartMessage:
                "Data is syncing, latest events will be synced in few minutes...",
            toBeConfigured: "To Be Configured",
            tokenUser: "Token User",
            tooltip: {
                add: "Add",
                delete: "Delete",
                disabled: "Disable",
                enable: "Enable",
                refresh: "Refresh",
            },
        },
        iframeNotSupported: "Your browser does not support iFrames.",
        sentry: {
            userReportedIssue: "User reported issue",
            dialogTitle: "Report Issue Dialog",
        },
        cookieMsg: {
            title: "Cookies Required",
            cookieNotEnabled:
                "To use the website kindly enable your browser cookies.",
        },
        clientStatus: {
            active: "Active",
            inActive: "Inactive",
            leader: "Leader",
        },
        companyDataField: {
            value: {
                invalid: "Entered value is not numeric",
                maxLength: "Length cannot greater than {{val}}",
                name: "Value",
            },
        },
        dashboard: {
            activeContracts: "Active Contracts",
            averageForTheNext3Months: "Average for the next 3 months",
            averageForTheNext6Months: "Average for the next 6 months",
            diseaseDays: "Diseases Days",
            endingContracts: "Ending Contracts",
            inTheNextMonth: "In the next month",
            inThe3Month: "In the 3rd month",
            inThe6Month: "In the 6th month",
            inThePreviousMonth: "In the previous month",
            missingDays: "Missing Days",
            overview: "Overview",
            startingContracts: "Starting Contracts",
            thisMonth: "This month",
            thsYear: "This year",
            totalDiseasesDays: "Total Diseases Days",
            totalForTheNext3Months: "Total for the next 3 months",
            totalForTheNext6Months: "Total for the next 6 months",
            totalVacationDays: "Total Vacation Days",
            vacationDays: "Vacation Days",
        },
        documentArchive: {
            allowMultiple: "Allow Multiple",
            archive: "Archive",
            archiveListEmpty: "No archive found",
            area: "Area",
            createArchive: "Create New Archive",
            created: "Created",
            documentExistError:
                "Document Archive has data in it and can’t be deleted, kindly remove uploaded archive documents and try again",
            documentName: "Archive Name",
            id: "ID",
            name: {
                missing: "Name is required.",
                name: "Name",
                placeholder: "Name",
            },
            updateArchive: "Update Archive",
        },
        emailTemplate: {
            title: "Email Template",
            clientRegistration: {
                intro: {
                    missing: "Intro is required",
                    name: "Intro",
                    placeholder: "Email intro text",
                },
                outro: {
                    missing: "Outro is required",
                    name: "Outro",
                    placeholder: "Email outro text",
                },
            },
            type: {
                missing: "Type is required",
                name: "Template Type",
            },
            types: {
                ClientRegistrationEmail: "Client Registration",
            },
        },
        employeeCalculationField: {
            addNewField: "Add New Field",
            ageEnd: {
                invalid: '"AgeGroup end" should be greater than AgeGroup start',
                missing: '"AgeGroup end" is required',
                nameDay: "AgeGroup End in Days",
                nameMonth: "AgeGroup End in Month",
                number: "Entered value is not numeric",
            },
            ageStart: {
                invalid: '"AgeGroup start" should be less than AgeGroup end',
                missing: '"AgeGroup start" is required',
                nameDay: "AgeGroup Start in Days",
                nameMonth: "AgeGroup Start in Month",
                number: "Entered value is not numeric",
            },
            canEnterValue: {
                name: "Is Value Editable?",
            },
            careEnd: {
                invalid:
                    '"Caretime End in Minutes" should be greater than Caretime start',
                missing: '"Caretime End in Minutes" is required',
                name: "Caretime End in Minutes",
                number: "Entered value is not numeric",
            },
            careHour: {
                name: "Care Hour",
            },
            careStart: {
                invalid:
                    '"Caretime Start in Minutes" should be less than Caretime end',
                missing: '"Caretime Start in Minutes" is required',
                name: "Caretime Start in Minutes",
                number: "Entered value is not numeric",
            },
            columnTwoName: {
                missing: "Value is required",
                name: "Heading Column 2",
            },
            columnTwoValue: {
                invalid: "Entered value is not numeric",
                missing: "Value is required",
                name: "Staff key",
            },
            fieldType: {
                ageHour: "Age / CareHours",
                simple: "Simple",
            },
            name: {
                missing: "Field name is required",
                name: "Field Name",
            },
            noLocationMessage:
                "No location-setting is associated with this business so you can only create simple fields for now.",
            shortName: {
                missing: "Short name is required",
                name: "Short Name",
            },
            type: {
                name: "Field Type",
            },
            value: {
                invalid: "Entered value is not numeric",
                missing: "Value is required",
                name: "Value",
            },
        },
        employeeClientFilter: {
            client: "Client",
            employee: "Employee",
            supplier: "Supplier",
            group: "Group",
            resource: "Resource",
            users: "Users",
        },
        employeeIpadCode: {
            codeGenerationError: "Code generation error",
            generateCode: "Generate Code",
            generatedSuccess: "Code generated successfully",
            generatingCode: "Generating Code",
            ipadCode: "iPad Code",
            ipadRole: "iPad Role",
            roleUpdated: "Role updated successfully",
        },
        explanationMode: {
            toggleLabel: "Explanation Mode",
        },
        helpItem: {
            explanatoryVideo: "Explanatory Videos",
            fieldsExplained: "Fields Explained",
            type: {
                VIDEO: "Video",
                EXPLANATION: "Explanation",
                title: "Type",
            },
            addNew: "Add New",
            description: {
                name: "Explanation",
                placeholder: "Explanation",
                missing: "Explanation is required",
            },
            title: {
                missing: "'Header Text' is required",
                name: "Header Text",
                placeholder: "Header Text",
            },
            url: {
                missing: "Video URL is required",
                name: "Video Url",
                placeholder: "vimeo.com/*",
                vimeo: "Only vimeo.com URLs are allowed for now",
            },
        },
        hqRegisterRequestManagement: {
            createdAt: "Created At",
            createHq: "Create HQ",
            email: "Email",
            facilityName: "Facility Name",
            firstName: "First Name",
            lastName: "Last Name",
            mailSentToUser: "Mail sent to user.",
            phone: "Phone",
            createClients: "Create Clients",
            registerRequests: "Register Requests",
            resendCompletionEmail: "Resend Registration Email",
            setupBusiness: "Setup Business",
            status: "Status",
            statusUpdated: "Status updated successfully.",
            statusVals: {
                completed: "Completed",
                inprogress: "In-Progress",
                pending: "Pending",
                rejected: "Rejected",
            },
        },
        jobSchedule: {
            noLimitRecurrence: "No Repeat Limit",
            recurrenceDayMissing: "At-least one recurrence day is required",
            repeat: "Repeat",
            time: {
                missing: "Time is required.",
                name: "Time",
            },
        },
        location: {
            city: {
                missing: "City is required.",
                name: "City",
            },
            country: {
                missing: "Country is required.",
                name: "Country",
            },
            postalCode: {
                missing: "Postal code is required.",
                name: "Postal Code",
            },
            state: {
                missing: "State is required.",
                name: "State",
            },
            zipCodes: {
                missing: "ZIP code(s) is required.",
                name: "ZIP Codes",
            },
        },
        printer: {
            samplePrintFail:
                "Unable to send sample print. Kindly confirm your printer's email and try again.",
            samplePrintSent:
                "Sample print sent successfully. Your printer will print it in few minutes.",
            sendingPrint: "Sending Print...",
        },
        qrCode: {
            codeExpired: "Code Expired, please refresh code",
            codeGenerated: "New code generated",
            connectedToApp: "Connected to App",
            generate: "Generate",
            messageNotSent: "Message fail to send",
            messageSent: "Mail Sent",
            notConnectedToApp: "Not Connected to App",
            personAlreadyRegistered: "Person already registered on mobile",
            refresh: "Refresh",
            pdfTitle: "QR Code {{name}}.pdf",
            pdfSuccess: "PDF generated successfully.",
            noQrCode: {
                line1: "You will not be shown a QR code for registration because there are no email addresses for the guardians.",
                line2: "Please enter email addresses under master data to enable setup for the APP.",
            },
            validTill: "Code is Valid Till",
        },
        recipients: {
            employees: "Employees",
            groups: "Kindergarten Groups",
            parents: "Parents",
            save: "Save",
        },
        templateDocumentManager: {
            adopt: "Adopt",
            cloneSuccess: "Template cloned successfully",
            cloneTemplate: "Clone Template",
            created: "Created",
            createNew: "Create New",
            displayIdAlreadyInUse: "Entered ID is already in use.",
            displayIdInvalid: "Display Id is not in a correct format",
            documentName: "Document Name",
            documents: "Documents",
            documentType: {
                client: "Client",
                client_list: "Client List",
                employee: "Employee",
                employee_list: "Employee List",
            },
            group: "Group",
            id: "ID",
            noLocation: {
                firstLine:
                    "Company is not associated with any Location Setting.",
                secondLine:
                    "Update city and zipcode in company-setting to link with location",
            },
            tab: {
                ownDocuments: "Own Documents",
                templateLibrary: "Template Library",
            },
            tags: "Tags",
            templateListEmpty: "No template found",
        },
        voucherAffiliation: {
            codeCopiedToClipboard: "Code copied to clipboard",
            commission: {
                invalid: "Commission in invalid",
                maxValue: "Percentage max value is 100",
                minValue: "Commission must be positive",
                missing: "Commission is required",
                name: "Commission",
            },
            discount: {
                invalid: "Discount is invalid",
                maxValue: "Percentage max value is 100",
                minValue: "Discount must be positive",
                missing: "Discount is required",
                name: "Discount",
            },
            hasCommission: "Has commission",
            isPercentage: "Is percentage",
        },
        workingDays: {
            open24Hours: {
                name: "Is Open 24 Hours?",
            },
            from: {
                invalid: "Opening time must be less than closing time.",
                missing: "Opening time is required",
                name: "From",
            },
            to: {
                invalid: "Closing time must be more than opening time.",
                missing: "Closing time is required",
                name: "To",
            },
        },
    },
};

export default enTranslation;
