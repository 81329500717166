import { useNavigate, useSearchParams } from "react-router-dom";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { BaseObject } from "models/general";
import ErrorLayout, { ERROR_CODE_PARAM_KEY } from "layouts/ErrorLayout";
import { AppEmitterEvents, eventEmitter } from "globals/eventEmitter";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { noAuthRoutes } from "routing/routes";
import { defaultTo } from "lodash-es";
import { useLocation } from "react-router";

// to set app culture
export const ErrorPageProvider: React.FC<PropsWithChildren<BaseObject>> = ({
    children,
}: PropsWithChildren<BaseObject>) => {
    //if errorCode params passed in url
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const showErrorPage = useShowErrorPage();
    useEffect(() => {
        const handler1 = () => {
            showErrorPage(403);
        };
        const handler2 = () => {
            showErrorPage(404);
        };
        const handler3 = () => {
            navigate(
                noAuthRoutes().screens.sessionTimeOut({
                    redirectUrl: `${location.pathname}${defaultTo(
                        location.search,
                        ""
                    )}`,
                }),
                {
                    replace: true,
                }
            );
        };

        if (eventEmitter) {
            // handle error events emitted from outside react like from BaseService
            eventEmitter.on(AppEmitterEvents.ACCESS_DENIED, handler1);
            eventEmitter.on(AppEmitterEvents.NOT_FOUND, handler2);
            eventEmitter.on(AppEmitterEvents.SESSION_TIMEOUT, handler3);
        }

        return () => {
            eventEmitter.off(AppEmitterEvents.ACCESS_DENIED, handler1);
            eventEmitter.off(AppEmitterEvents.NOT_FOUND, handler2);
            eventEmitter.off(AppEmitterEvents.SESSION_TIMEOUT, handler3);
        };
    }, [location]);

    const hasError = useMemo(() => {
        const errorCode = searchParams.get(ERROR_CODE_PARAM_KEY);
        return !!errorCode;
    }, [searchParams]);
    return (
        <>
            {hasError && <ErrorLayout />}
            {children}
        </>
    );
};

export default ErrorPageProvider;
