import { AppLayoutBase } from "layouts/appLayout";
import HeaderBusinessLogo from "layouts/appLayout/partials/header/partials/HeaderBusinessLogo";
import React, { useState } from "react";
import { HamburgerElastic } from "react-animated-burgers";
import { COLOR_PRIMARY } from "theme/themeConstants";
import AppMobileMenu from "./AppMobileMenu";
export interface HeaderLogoProps extends AppLayoutBase {
    noSidebar?: boolean;
}
export interface HeaderLogoState {
    active: boolean;
}
export const HeaderLogo: React.FC<HeaderLogoProps> = (props) => {
    const {
        setEnableClosedSidebar,
        enableClosedSidebar,
        noSidebar = false,
    } = props;

    const toggleEnableClosedSidebar = () => {
        setEnableClosedSidebar(!enableClosedSidebar);
    };
    const [state, setState] = useState<HeaderLogoState>({
        active: false,
    });

    return (
        <>
            <div className="app-header__logo">
                <div className="logo-src" style={{ paddingLeft: "6px" }}>
                    <HeaderBusinessLogo />
                </div>

                {!noSidebar && (
                    <div className="header__pane ml-auto">
                        <div onClick={toggleEnableClosedSidebar}>
                            <HamburgerElastic
                                isActive={enableClosedSidebar}
                                toggleButton={() =>
                                    setState({
                                        ...state,
                                        active: !state.active,
                                    })
                                }
                                barColor={COLOR_PRIMARY}
                                buttonWidth={24}
                            />
                        </div>
                    </div>
                )}
            </div>
            <AppMobileMenu {...props} />
        </>
    );
};

export default HeaderLogo;
