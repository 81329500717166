import classNames from "classnames";
import {
    AppCollapseToggleButton,
    AppDeleteButton,
    AppMenuButton,
} from "components/Buttons";
import {
    getBusinessSettingFlagsPreferences,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import { t } from "i18next";
import $ from "jquery";
import { defaultTo, isNil } from "lodash-es";
import { CareScopeTemplate } from "models/careScopeTemplate";
import { isTemplateActive, Optional } from "models/general";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { COLOR_LIGHT_GREEN } from "theme/themeConstants";
import CareScopeTemplateSubRowContent from "./CareScopeTemplateSubRowContent";
import commonStyles from "commonPartials/commonStyles.module.scss";
import { value } from "numeral";
import { PermissionAccessMap, PermissionAccessTypes } from "models";
import {
    CLONE_PARAM_KEY,
    FOLLOWUP_PARAM_KEY,
    TEMPLATE_ID_PARAM_KEY,
} from "globals/queryParams";
import TemplateRowActiveTag from "components/TemplateRowActiveTag";

export interface CareScopeTemplateRowProps {
    template: CareScopeTemplate;
    permissionsMap: PermissionAccessMap;
    createPath: string; //to create followups
    isLwc: boolean;
    useLocationData?: boolean;
    loading?: boolean;
    isForLocationSetting?: boolean;
    onClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
}

const COLUMNS_COUNT = 4;
export const CareScopeTemplateRow: React.FC<CareScopeTemplateRowProps> = ({
    template,
    permissionsMap,
    createPath,
    isLwc,
    useLocationData = true,
    isForLocationSetting = false,
    loading = false,
    onClick,
    onDeleteClick,
}) => {
    const navigate = useNavigate();

    const [collapse, setCollapse] = useState<boolean>(true);
    const collapseRowRef = useRef<Optional<any>>(undefined);
    const initialStyle = useRef<any>(
        !collapse ? { display: "none" } : undefined
    );

    const followUps = [template, ...defaultTo(template.FollowUpTemplates, [])];
    const toggleRow = useCallback(() => {
        if (collapseRowRef && collapseRowRef.current) {
            if (collapse) {
                setCollapse(false);
                $(collapseRowRef.current).slideUp(200);
            } else {
                setCollapse(true);
                $(collapseRowRef.current).slideDown(300);
            }
        }
    }, [collapseRowRef.current, collapse]);

    const existHasNoEndDate =
        followUps.filter((x) => isNil(x.EndDate)).length > 0;

    const createEmptyFollowUp = () =>
        navigate({
            pathname: createPath,
            search: `${TEMPLATE_ID_PARAM_KEY}=${template.EncodedId}&${FOLLOWUP_PARAM_KEY}=true`,
        });

    const { isDisabled, canClone, canDelete } = useMemo(() => {
        return getBusinessSettingFlagsPreferences(
            useLocationData,
            isForLocationSetting,
            template.LocationSettingId,
            template.BusinessId
        );
    }, [
        useLocationData,
        isForLocationSetting,
        template.LocationSettingId,
        template.BusinessId,
    ]);
    const columnsCount = COLUMNS_COUNT + (isForLocationSetting ? 0 : 1);
    return (
        <>
            <tr
                onClick={() => toggleRow()}
                className={isDisabled ? commonStyles.disabledRow : undefined}
            >
                <td style={{ cursor: "pointer !important" }}>
                    <div>
                        <AppCollapseToggleButton visible={collapse} />
                    </div>
                </td>
                <td className={"ellipsis-text single-line"}>
                    <span>{template.Name}</span>
                </td>
                <td>{template.ShortName}</td>
                {!isForLocationSetting && (
                    <td style={{ display: "flex", justifyContent: "center" }}>
                        <TemplateRowActiveTag active={!isDisabled} />
                    </td>
                )}
                <td>
                    {canDelete && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <AppDeleteButton
                                getConfirmation={true}
                                name={template.Name}
                                readonly={
                                    !permissionsMap[
                                        PermissionAccessTypes.DELETE
                                    ] || loading
                                }
                                onClick={() => {
                                    if (template.EncodedId) {
                                        onDeleteClick(template.EncodedId);
                                    }
                                }}
                            />
                        </div>
                    )}
                </td>
            </tr>
            <tr
                className="row-with-innerTable"
                style={collapse ? { border: "none !important" } : undefined}
            >
                <td colSpan={columnsCount}>
                    <div
                        className="child-table-container"
                        style={initialStyle.current}
                        ref={collapseRowRef}
                    >
                        <table
                            className={classNames("table-striped")}
                            style={{
                                width: "100%",
                            }}
                        >
                            <tbody>
                                {followUps.map((fT, idx) => {
                                    const onCellClick = () => {
                                        if (fT.EncodedId) {
                                            onClick(fT.EncodedId);
                                        }
                                    };
                                    return (
                                        <tr
                                            key={fT.Id}
                                            className={
                                                isDisabled
                                                    ? commonStyles.disabledRow
                                                    : undefined
                                            }
                                            style={{
                                                cursor: "pointer",
                                                ...(idx ===
                                                    followUps.length - 1 && {
                                                    borderBottom:
                                                        "none !important",
                                                }),
                                                ...(!isDisabled &&
                                                    isTemplateActive(fT) && {
                                                        backgroundColor:
                                                            COLOR_LIGHT_GREEN,
                                                    }),
                                            }}
                                        >
                                            <td style={getFixedCssWidths(60)} />
                                            <CareScopeTemplateSubRowContent
                                                template={fT}
                                                isParentTemplate={idx == 0}
                                                canClone={canClone}
                                                onClick={onCellClick}
                                                showDelete={canDelete}
                                                isForLocationSetting={
                                                    isForLocationSetting
                                                }
                                                hasDeletePermission={
                                                    permissionsMap[
                                                        PermissionAccessTypes
                                                            .DELETE
                                                    ] && !loading
                                                }
                                                onClone={() => {
                                                    navigate({
                                                        pathname: createPath,
                                                        search: `${TEMPLATE_ID_PARAM_KEY}=${fT.EncodedId}&${CLONE_PARAM_KEY}=true`,
                                                    });
                                                }}
                                                onDeleteClick={onDeleteClick}
                                            />
                                        </tr>
                                    );
                                })}
                                {!existHasNoEndDate &&
                                    !isLwc &&
                                    permissionsMap[
                                        PermissionAccessTypes.CREATE
                                    ] &&
                                    !isDisabled &&
                                    (isForLocationSetting ||
                                        (!isForLocationSetting &&
                                            template.BusinessId)) && (
                                        <tr
                                            style={{
                                                background:
                                                    "transparent !important",
                                            }}
                                        >
                                            <td
                                                style={getFixedCssWidths(60)}
                                            ></td>
                                            <td colSpan={columnsCount - 1}>
                                                <AppMenuButton
                                                    text={t(
                                                        "templates.createFollowUpTemplate"
                                                    )}
                                                    onClick={() =>
                                                        createEmptyFollowUp()
                                                    }
                                                    menuItems={[
                                                        {
                                                            label: t(
                                                                "templates.createNewEmptyTemplate"
                                                            ),
                                                            onClick: () =>
                                                                createEmptyFollowUp(),
                                                        },
                                                        {
                                                            label: t(
                                                                "templates.createNewTemplateWithData"
                                                            ),
                                                            onClick: () =>
                                                                navigate({
                                                                    pathname:
                                                                        createPath,
                                                                    search: `${TEMPLATE_ID_PARAM_KEY}=${template.EncodedId}&${CLONE_PARAM_KEY}=true&${FOLLOWUP_PARAM_KEY}=true`,
                                                                }),
                                                        },
                                                    ]}
                                                />
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default CareScopeTemplateRow;
