import { SalaryGroupTemplateCreateEdit } from "commonPartials/salaryGroupTemplate";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    BusinessType,
    Business_AgeGroups,
    Business_SalaryGroups,
    LW_Kindergarten_AgeGroups,
    LW_Kindergarten_SalaryGroups,
} from "models";
import React from "react";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";

export const BusinessSalaryGroupTemplateEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();

    const { id, lwcId } = useParams();
    const currentBusiness = useCurrentBusinessSettingsContext();

    // const isLw = currentBusiness?.dataItem?.Type == BusinessType.LW;

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = (
        isLwc
            ? lwcBase.kindergartens.withId()
            : linkProvider.business.businessSettings().screens
    ).salaryGroups;

    const permMap = getPermissionMap([
        isLwc ? LW_Kindergarten_SalaryGroups : Business_SalaryGroups,
    ]);
    const useLocationData = defaultTo(
        currentBusiness.dataItem?.LocationPreferences?.SalaryGroup,
        true
    );
    return (
        <SalaryGroupTemplateCreateEdit
            id={id}
            currentBusiness={currentBusiness.dataItem}
            locationId={currentBusiness.dataItem?.LocationSettingId}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
            useLocationData={useLocationData}
        />
    );
};

export default BusinessSalaryGroupTemplateEdit;
