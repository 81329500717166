import classNames from "classnames";
import {
    AppCollapseToggleButton,
    AppCollapseToggleButtonProps,
    AppGoBackButton,
} from "components/Buttons";
import { defaultTo } from "lodash-es";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { AppContainerWithHeaderClasses } from "./AppContainer";
import styles from "./AppContainerHeader.module.scss";

export interface AppContainerHeaderProps {
    isCollapsible?: boolean;
    onBack?: () => void;
    classes?: AppContainerWithHeaderClasses;
    titleLoading?: boolean;
    showTitleSkeleton?: boolean;
    title?: string | React.ReactNode;
    ellipseTitle?: boolean;
    actionMenu?: React.ReactNode;
    rightContent?: React.ReactNode;
    collapseToggleButtonProps?: AppCollapseToggleButtonProps;
    collapseState: boolean;
    toggleCollapse: () => void;
}

export const AppContainerHeader: React.FC<AppContainerHeaderProps> = ({
    isCollapsible = false,
    titleLoading = false,
    showTitleSkeleton = false,
    ellipseTitle = true,
    classes,
    title,
    actionMenu,
    rightContent,
    collapseToggleButtonProps,
    collapseState,
    onBack,
    toggleCollapse,
}) => {
    return (
        <div
            className={`${styles.header} ${defaultTo(classes?.header, "")}`}
            style={{ cursor: isCollapsible ? "pointer" : undefined }}
            onClick={() => isCollapsible && toggleCollapse()}
        >
            {onBack && <AppGoBackButton hasText={false} onClick={onBack} />}
            <div className={`${styles.title} ${defaultTo(classes?.title, "")}`}>
                {showTitleSkeleton && titleLoading ? (
                    <Skeleton width={300} height={25} />
                ) : (
                    <span title={typeof title == "string" ? title : undefined}>
                        {title}
                    </span>
                )}
            </div>
            <div
                className={classNames(
                    styles.actionMenu,
                    defaultTo(classes?.actionMenu, ""),
                    {
                        ["ellipsis-text single-line"]: ellipseTitle,
                    }
                )}
            >
                <div className="d-flex ">
                    {actionMenu && actionMenu}
                    {rightContent && rightContent}
                    {isCollapsible && (
                        <AppCollapseToggleButton
                            visible={collapseState}
                            onClick={() => toggleCollapse()}
                            {...collapseToggleButtonProps}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppContainerHeader;
