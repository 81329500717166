import { useRouting } from "hooks/general/routing";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business/BusinessGeneralService";

export const useLwcBusinessesSelectList = () => {
    const { linkProvider } = useRouting();
    const service = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const {
        isFetching: loading,
        data,
        error,
        refetch,
    } = useQuery(
        getBusinessGeneralServiceKey("getLwcBusinessesSelectList"),
        async () => await service.getLwcBusinessesSelectList()
    );

    return {
        data,
        loading,
        error,
        refetch,
    };
};
