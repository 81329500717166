import { SearchField } from "components/FormFields";
import { defaultTo } from "lodash-es";
import { Optional, UserType } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TimeManagementDateRangeHeader.module.scss";
import TimeManagementHeaderContainer from "./TimeManagementHeaderContainer";

export interface TimeManagementDateRangeHeaderProps {
    type: UserType;
    searchValue: Optional<string>;
    className?: string;
    onSearchValueChangeHandler: (val: string) => void;
}

export const TimeManagementDateRangeHeader: React.FC<
    TimeManagementDateRangeHeaderProps
> = ({ type, searchValue, className = "", onSearchValueChangeHandler }) => {
    const { t } = useTranslation();
    return (
        <TimeManagementHeaderContainer className={className}>
            <tbody>
                <tr>
                    <td style={{ minWidth: "450px", padding: 5 }}>
                        <SearchField
                            className={styles.select}
                            value={defaultTo(searchValue, "")}
                            onValueChange={(val) =>
                                onSearchValueChangeHandler(val)
                            }
                        />
                    </td>
                    <td className={styles.tbWidth}>
                        {type == UserType.Employee
                            ? t("timeManagement.workingHours")
                            : t("timeManagement.careHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td className={styles.tbWidth}>
                        {type == UserType.Employee
                            ? t("timeManagement.maxWorkingHours")
                            : t("timeManagement.maxContractHours")}
                    </td>
                    <td className={styles.lineTd} rowSpan={2}>
                        <div />
                    </td>
                    <td className={styles.tbWidth}>
                        {t("timeManagement.difference")}
                    </td>

                    {type == UserType.Client && (
                        <>
                            <td className={styles.lineTd} rowSpan={2}>
                                <div />
                            </td>
                            <td className={styles.tbWidth}>
                                {t("timeManagement.totalOverdraft")}
                            </td>
                        </>
                    )}
                </tr>
            </tbody>
        </TimeManagementHeaderContainer>
    );
};

export default TimeManagementDateRangeHeader;
