import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import { AppCheckbox } from "components/FormFields";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash";
import { EmployeeListItem } from "models";
import { Selectable } from "models/general";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./BusinessEmployeeTableRows.module.scss";

export interface BusinessEmployeeTableRowsProps {
    value: Selectable<EmployeeListItem>;
    onDelete: () => void;
    canDelete: boolean;
    loading: boolean;
    onEditClick: (id: string) => void;
    onSelectChange: (val: boolean, id: number) => void;
}

export const BusinessEmployeeTableRows: React.FC<
    BusinessEmployeeTableRowsProps
> = ({
    value,
    onDelete,
    canDelete,
    loading: refetchLoading,
    onEditClick,
    onSelectChange,
}) => {
    const { t } = useTranslation();

    const { getDateFormatForLocale, getFormattedCurrency, formatNumber } =
        useLocaleHelpers();

    const onRowClickHandler = (EncodedId: string) => {
        if (!refetchLoading) {
            onEditClick(EncodedId);
        }
    };

    return (
        <tr>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
                className={styles.displayId}
            >
                {value.DisplayId}
            </td>
            <td
                style={{ maxWidth: "300px" }}
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <div
                    style={{ display: "flex", alignItems: "center" }}
                    className={styles.textEllipse}
                >
                    <span className={styles.textEllipse}>{value.Name}</span>
                </div>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.ContractStart &&
                    value.ContractStart.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.ContractEnd &&
                    value.ContractEnd.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {t(`common.status.${value.Status}`)}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <div className={styles.functionTextEllipse}>
                    {defaultTo(value.Group, "")}
                </div>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <div className={styles.functionTextEllipse}>
                    {value.EmployeeFunction && value.EmployeeFunction}
                </div>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <div className={styles.functionTextEllipse}>
                    {value.WorkArea && value.WorkArea}
                </div>
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {formatNumber(value.WeeklyHours)}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.EndOfTrialPeriod &&
                    value.EndOfTrialPeriod.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.ExpirationDate &&
                    value.ExpirationDate.format(getDateFormatForLocale())}
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.Holidays && value.Holidays}
            </td>
            <td
                className="text-center"
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <BooleanTickCrossIcon value={value.Chargeable} />
            </td>
            {/* <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                {value.RealGrossSalary &&
                    getFormattedCurrency(value.RealGrossSalary)}
            </td> */}
            <td>
                <AppCheckbox
                    checked={defaultTo(value.isChecked, false)}
                    onChange={() => onSelectChange(!value.isChecked, value.Id)}
                    // disabled={readonly}
                />
            </td>
            <td
                onClick={() => {
                    onRowClickHandler(value.EncodedId);
                }}
            >
                <Image
                    className={styles.mobileImage}
                    src={
                        value.HasMobileAccount
                            ? ImageAssets.common.mobileConnected
                            : ImageAssets.common.mobileNotConnected
                    }
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() => {
                        onDelete();
                    }}
                    readonly={!canDelete || refetchLoading}
                    name={value.Name}
                    getConfirmation={true}
                />
            </td>
        </tr>
    );
};

export default BusinessEmployeeTableRows;
