import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppPopover from "components/AppPopover";
import { defaultTo } from "lodash-es";
import React, { useCallback, useState } from "react";
import styles from "./AppDropdownMenu.module.scss";

export interface DropdownMenuItem {
    label: string;
    onClick?: () => void;
}
export interface AppDropdownMenuProps {
    menuItems: DropdownMenuItem[];
    trigger?: any;
    className?: any;
}

export function AppDropdownMenu(props: AppDropdownMenuProps) {
    const {
        menuItems,
        className = "",
        trigger = <FontAwesomeIcon icon={"ellipsis-h"} />,
    } = props;

    const [menuOpen, setMenuOpen] = useState<boolean | null>(null);
    const closeMenu = useCallback(() => {
        setMenuOpen(false);
        setTimeout(() => {
            setMenuOpen(null);
        }, 500);
    }, [setMenuOpen]);

    return (
        <AppPopover
            triggerElem={trigger}
            arrow={false}
            placement={"auto"}
            menuOpen={menuOpen}
            className={`${className} ${styles.menuCard}`}
        >
            <>
                {menuItems.map((item, idx) => {
                    return (
                        <a
                            className={styles.menuItem}
                            key={`${idx}-${item.label}`}
                            onClick={defaultTo(() => {
                                if (item.onClick) {
                                    item.onClick();
                                }
                                closeMenu();
                            }, closeMenu)}
                        >
                            {item.label}
                        </a>
                    );
                })}
            </>
        </AppPopover>
    );
}

export default AppDropdownMenu;
