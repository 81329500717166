import { EditProfileFields } from "commonPartials";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { useEditProfile } from "hooks/layout/useEditProfile";
import { useCheckPermission } from "hooks/permissionCheck";
import { GeneralUserProfileUpdate, GeneralUserPasswordChange } from "models";
import { getInitializedValidityState } from "models/general";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
export interface EditProfileDialogProps {
    open: boolean;
    close: () => void;
}
export const EditProfileDialog: React.FC<EditProfileDialogProps> = ({
    close,
    open,
}) => {
    const { t } = useTranslation();
    const {
        updateProfile,
        validityState,
        setValidityState,
        state,
        setState,
        loading,
    } = useEditProfile();
    const [isValid, setIsValid] = useState<boolean>(true);

    const { getPermissionMap } = useCheckPermission();

    const permMap = getPermissionMap(GeneralUserProfileUpdate);
    const passwordPermMap = getPermissionMap(GeneralUserPasswordChange);
    return (
        <AppDialog
            modalOpen={open}
            onClose={() => {
                if (!loading) {
                    close();
                }
            }}
            title={t("userProfile.editProfile")}
            size="lg"
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <AppDialogFooter
                        onDialogClose={loading ? undefined : close}
                        onClickSaveButton={() => {
                            setValidityState(
                                getInitializedValidityState([], [])
                            ); // to reset file type errors
                            updateProfile(state);
                        }}
                        disableSaveButton={!isValid || loading || !permMap.EDIT}
                    />
                </div>
            }
        >
            <EditProfileFields
                state={state}
                setState={setState}
                validityState={validityState}
                setIsValid={setIsValid}
                canChangePassword={passwordPermMap.EDIT}
            />
        </AppDialog>
    );
};

export default EditProfileDialog;
