import { AppButton } from "components/Buttons";
import { config } from "globals/config";
import { showSentryDialog } from "globals/sentry";
import { ErrorBoundaryProps } from "hoc";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { defaultTo } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./RenderError.module.scss";

export interface RenderErrorProps extends ErrorBoundaryProps {}

export function RenderError(props: RenderErrorProps) {
    const { error, errorInfo, eventId } = props;
    const { t } = useTranslation();
    const { user } = useSessionUser();

    return (
        <React.Fragment>
            <div className={styles.root}>
                <h4>{t("errorBoundary.somethingWentWrong")}</h4>
                <details style={{ whiteSpace: "pre-wrap" }}>
                    {config.isDevEnv ? (
                        <>
                            {error && error.toString()}
                            <br />
                            {errorInfo.componentStack}
                        </>
                    ) : (
                        t("errorBoundary.message")
                    )}
                </details>
                <AppButton
                    onClick={() =>
                        showSentryDialog(
                            user
                                ? {
                                      name: defaultTo(
                                          user.Name,
                                          "Unknown User"
                                      ),
                                      email: user.Email,
                                  }
                                : undefined,
                            eventId
                        )
                    }
                >
                    {t("errorBoundary.reportAdditionalFeedback")}
                </AppButton>
            </div>
        </React.Fragment>
    );
}

export default RenderError;
