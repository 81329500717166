import React from "react";
import styles from "./FeatureHelpThumbnailOverlay.module.scss";

export interface FeatureHelpThumbnailOverlayProps {
    title: string;
}
export const FeatureHelpThumbnailOverlay: React.FC<
    FeatureHelpThumbnailOverlayProps
> = ({ title }) => {
    return (
        <div className={styles.root}>
            <div className={`${styles.titleOfOverlay} ellipsis-text`}>
                <span>{title}</span>
            </div>
            <div className={styles.iconContainer}>
                <i className={`${styles.icon} fas fa-play`} />
            </div>
        </div>
    );
};

export default FeatureHelpThumbnailOverlay;
