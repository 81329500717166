import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AppContentHeader } from "components";
import { AppLoader } from "components/Loaders/AppLoader";
import { ImageAssets } from "globals/images";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { Optional, TabbedLayoutActiveItem } from "models/general";
import { KindergartenClientListing } from "models/kindergartenClient";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { KINDERGARTEN_CLIENT_PATH_PREFIX } from "routing/routes/kindergarten/client";
import styles from "./KindergartenClientEditLayout.module.scss";
import { useKindergartenClientContext } from "./KindergartenClientsContextProvider";
import { KindergartenClientTabs } from "./tabs";

export const KindergartenClientEditLayout = () => {
    const {
        clients,
        navigateUsingCompleteResponse,
        setNavigateUsingCompleteResponse,
        completeResponse,
        isRefetching: reloadingList,
        refetchData,
    } = useKindergartenClientContext();
    const showErrorPage = useShowErrorPage();
    const { clientId } = useParams();
    const location = useLocation();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const [retest404, setRetest404] = useState(false);

    const [state, setState] = useState<
        TabbedLayoutActiveItem<KindergartenClientListing>
    >({ index: undefined, dataItem: undefined });

    useEffect(() => {
        if (
            location.pathname.endsWith(`clients/${clientId}`) ||
            location.pathname.endsWith(`clients/${clientId}/`)
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(
                linkProvider.kindergarten
                    .clients()
                    .screens.client.withId()
                    .edit(),
                { replace: true }
            );
        }
    }, [location.pathname, clientId]);

    const targetSearchList = useMemo(() => {
        return navigateUsingCompleteResponse ? completeResponse : clients;
    }, [completeResponse, clients, navigateUsingCompleteResponse]);

    const navigateToIndex = useCallback(
        (targetIdx: Optional<number>) => {
            if (targetSearchList && !isNil(targetIdx)) {
                const target = targetSearchList[targetIdx];
                if (!isNil(target)) {
                    // only need to change client Id not the url Path, but react-router provide no way to do this so manually changing ID in path
                    const newPath = location.pathname.replace(
                        `${KINDERGARTEN_CLIENT_PATH_PREFIX}/${clientId}`,
                        `${KINDERGARTEN_CLIENT_PATH_PREFIX}/${target.EncodedId}`
                    );

                    navigate(newPath);
                }
            }
        },
        [targetSearchList, clientId, location.pathname]
    );
    useEffect(() => {
        if (
            targetSearchList &&
            targetSearchList.length > 0 &&
            !reloadingList &&
            clientId
        ) {
            let found = targetSearchList.findIndex(
                (x) => x.EncodedId === clientId
            );
            let item: KindergartenClientListing | null = null;
            if (found < 0) {
                if (completeResponse) {
                    found = completeResponse.findIndex(
                        (x) => x.EncodedId === clientId
                    );
                }
                if (found < 0) {
                    // once try refetch
                    if (!retest404 && refetchData) {
                        setRetest404(true);
                        refetchData();
                    } else {
                        showErrorPage(404);
                    }
                } else {
                    item =
                        completeResponse && completeResponse.length > found
                            ? completeResponse[found]
                            : null;
                    setNavigateUsingCompleteResponse(true);
                }
            } else {
                item = targetSearchList[found];
            }
            setState((old) => ({
                ...old,
                index: found,
                dataItem: item,
            }));
        }
    }, [clientId, targetSearchList, reloadingList]);

    const isBaseRoute = useMemo(() => {
        let isBase = true;
        if (location.pathname && !isNil(state.dataItem)) {
            // navigation buttons should be visible only on base routes
            const firstLevel = location.pathname.split(
                `/${KINDERGARTEN_CLIENT_PATH_PREFIX}/${state.dataItem.EncodedId}/`
            );
            if (firstLevel.length > 1) {
                isBase = firstLevel[1].split("/").length === 1;
            }
        }
        return isBase;
    }, [location.pathname, state]);

    const clientInfo = state.dataItem;
    const currIndex = state.index;
    const showNavigationButtons = !isNil(currIndex) && isBaseRoute;
    return isNil(clientInfo) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={clientInfo.Name}
                icon={ImageAssets.common.clients}
                hasGoBack={true}
                onBack={() => {
                    navigate(
                        linkProvider.kindergarten
                            .clients()
                            .screens.client.list()
                    );
                    // instead use query invalidationOnMutation
                    // if (refetchData) {
                    //     // refetch data in-case something is updated
                    //     refetchData();
                    // }
                }}
            >
                {showNavigationButtons && (
                    <div className={styles.headerNavigation}>
                        <Button
                            onClick={() => navigateToIndex(currIndex - 1)}
                            disabled={currIndex === 0}
                        >
                            <FontAwesomeIcon icon="chevron-left" />
                        </Button>
                        <Button
                            onClick={() => navigateToIndex(currIndex + 1)}
                            disabled={currIndex + 1 === clients?.length}
                        >
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </div>
                )}
            </AppContentHeader>
            <div className={styles.tabs}>
                <KindergartenClientTabs
                    activeClient={clientInfo}
                    onActiveTabChange={(tab) => {
                        setState((old) => {
                            return old.tab !== tab ? { ...old, tab: tab } : old;
                        });
                    }}
                />
                <div
                    className={classNames(styles.content, {
                        [styles.withoutNavigation]: !showNavigationButtons,
                    })}
                >
                    <Outlet context={{ ...state, refetchData: refetchData }} />
                </div>
            </div>
        </div>
    );
};

export default KindergartenClientEditLayout;
