import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { CourseGroupField } from "./model";
import { BusinessCourseCreateEditRequest } from "./request";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";

export function validateBusinessCourses(
    template: BusinessCourseCreateEditRequest,
    state = getInitializedValidityState([], [])
) {
    const careScopeValidationRules: ValidationRules<
        keyof BusinessCourseCreateEditRequest
    > = {
        Name: [
            {
                rule: Validations.REQUIRED,
                // message: t("business.courses.missing.name"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.courses.missing.shortName"),
            },
        ],
        Floor: [
            {
                rule: Validations.REQUIRED,
                // message: t("business.courses.missing.floor"),
            },
        ],
        Area: [
            {
                rule: Validations.REQUIRED,
                // message: t("business.courses.missing.area"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        careScopeValidationRules,
        template,
        state
    );

    let manager = new ValidityStateManager(state);
    template.Groups.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateCoursesGroupField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });

    return manager.state;
}

export function validateCoursesGroupField(
    field: CourseGroupField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof CourseGroupField> = {
        GroupName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.courses.missing.groupName"),
            },
        ],
        GroupShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.courses.missing.groupShortName"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    return state;
}
