import { AppCardWithColorUnderline } from "components";
import {
    DashboardCardTypes,
    DashBoardReportStat,
    DashboardTabs,
} from "models/dashboard";
import { BusinessPerson } from "models/general";
import React from "react";
import { Card } from "react-bootstrap";
import {
    COLOR_DARK_YELLOW,
    COLOR_GREEN,
    COLOR_PRIMARY,
} from "theme/themeConstants";
import styles from "./DashboardCard.module.scss";
import DashboardCardSection from "./DashboardCardSection";
interface DashboardCardProps {
    title: string;
    cardType: DashboardCardTypes;
    firstHeading: string;
    secondHeading: string;
    counts: DashBoardReportStat;
    isSecondRow?: boolean;
    showPersonInfo?: boolean;
    persons?: BusinessPerson[];
    selectedTab: DashboardTabs;
}
const Component: React.FC<DashboardCardProps> = ({
    firstHeading,
    counts,
    secondHeading,
    title,
    cardType,
    isSecondRow = false,
    showPersonInfo = false,
    persons,
    selectedTab,
}) => {
    const renderColorCode = (): string => {
        if (cardType === DashboardCardTypes.ACTIVE_CONTRACTS) {
            return COLOR_GREEN;
        } else if (cardType === DashboardCardTypes.STARTING_CONTRACTS) {
            return COLOR_PRIMARY;
        } else {
            return COLOR_DARK_YELLOW;
        }
    };
    return (
        <>
            <AppCardWithColorUnderline colorCode={renderColorCode()}>
                <Card.Body className="d-flex flex-column">
                    <div className={styles.title}>{title}</div>
                    <div className={styles.cardContent}>
                        <DashboardCardSection
                            title={title}
                            heading={firstHeading}
                            showPersonInfo={showPersonInfo}
                            cardType={cardType}
                            statsWithPersonInfo={
                                !isSecondRow
                                    ? counts.CurrentMonth
                                    : counts.AverageOfNext3Months
                            }
                            persons={persons}
                            selectedTab={selectedTab}
                        />
                        <DashboardCardSection
                            className={styles.rightSection}
                            title={title}
                            heading={secondHeading}
                            showPersonInfo={showPersonInfo}
                            cardType={cardType}
                            statsWithPersonInfo={
                                !isSecondRow
                                    ? counts.NextMonth
                                    : counts.AverageOfNext6Months
                            }
                            persons={persons}
                            selectedTab={selectedTab}
                        />
                    </div>
                </Card.Body>
            </AppCardWithColorUnderline>
        </>
    );
};

Component.displayName = "DashboardCard";
export const DashboardCard = React.memo(Component);
export default DashboardCard;
