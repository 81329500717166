/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from "i18next";
import defaultTo from "lodash-es/defaultTo";
import {
    getInitializedValidityState,
    ValidityState,
    ValidityStateManager,
} from "models/general/validityState";
import { Moment } from "moment-timezone";
import { toNumber, validateHtmlTemplate } from "./localizationHelpers";
import { isNumber } from "./generalHelper";
import { isNil } from "lodash-es";
import { FULL_DATE_TIME_FORMAT } from "globals/constants";

export const EmailRegex =
    // |(".+") is extra as it will allow emails starting with (".")
    // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9öäü]+\.)+[a-zA-Z]{2,}))$/;
    /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9öäü]+\.)+[a-zA-Z]{2,}))$/;
export const PasswordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[\w\W]{8,}$/;

export type ValidationRules<T extends string> = Partial<
    Record<T, InputValidationRule[]>
>;

export enum Validations {
    REQUIRED = "required",
    EMAIL = "email",
    OPTIONAL_EMAIL = "optional_email",
    PASSWORD = "password",
    COMPARE = "compare",
    // DATE = "date",
    NUMBER = "number",
    OPTIONAL_NUMBER = "optional_number",
    MIN = "min",
    VIMEO = "vimeo_url",
    MAX = "max",
    MAX_LENGTH = "max_length",
    MIN_LENGTH = "min_length",
    ARRAY_REQUIRED = "array_required",
    GREATER_DATE = "greater_date",
    LESSER_DATE = "lesser_date",
    GREATER_THAN = "greater_than",
    LESSER_THAN = "lesser_than",
    LENGTH = "length",
    NUMBER_STRING = "number_string",
}

export interface InputValidationRule {
    rule: Validations;
    message?: string;
    options?: any & { fieldName?: string };
}

export interface RuleDefinition {
    validate: (value: any, options: any) => boolean;
    formatMessage: (
        message: string,
        fieldName: string,
        value: any,
        options: any
    ) => string;
}

const validators: { [index in Validations]: RuleDefinition } = {
    [Validations.EMAIL]: {
        validate: (str: string) => {
            const mailFormat = RegExp(EmailRegex);
            if (str && mailFormat.exec(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.email"),
    },
    [Validations.OPTIONAL_EMAIL]: {
        validate: (str: string) => {
            const mailFormat = RegExp(EmailRegex);
            if (!str || str === "" || mailFormat.exec(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.email"),
    },
    [Validations.PASSWORD]: {
        validate: (str: string) => {
            const passFormat = RegExp(PasswordRegex);
            if (str && passFormat.exec(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.password"),
    },
    [Validations.COMPARE]: {
        validate: (str: string, options: any) => {
            if (str === options.value) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.compare", {
                      field: fieldName,
                  }),
    },
    [Validations.LENGTH]: {
        validate: (str: string | number, options: any) => {
            if (
                !str ||
                str === "" ||
                str.toString().trim().length === options.value
            ) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, options) =>
            message
                ? message
                : i18next.t("common.errorMessages.length", {
                      field: fieldName,
                      value: options.value,
                  }),
    },
    [Validations.NUMBER_STRING]: {
        validate: (str: string) => {
            const numberFormat = RegExp(/^\d+$/);
            if (!str || str === "" || numberFormat.exec(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, options) =>
            message ? message : i18next.t("common.errorMessages.number"),
    },
    [Validations.VIMEO]: {
        validate: (str: string) => {
            if (str && str.includes("vimeo.com/")) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.vimeo"),
    },
    [Validations.NUMBER]: {
        validate: (str: any) => {
            if (isNumber(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.number"),
    },
    [Validations.OPTIONAL_NUMBER]: {
        validate: (str: any) => {
            if (
                !str ||
                (str && str.toString().trim().length === 0) ||
                isNumber(str, true)
            ) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.number"),
    },
    [Validations.MIN]: {
        validate: (str: string | number, options: any) => {
            if (
                (!str && typeof str == "string") ||
                (str && typeof str == "string" && str.trim().length === 0)
            ) {
                return true;
            } else if (str != null) {
                if (
                    (typeof str == "string" &&
                        isNumber(str) &&
                        toNumber(str) >= options.value) ||
                    str >= options.value
                ) {
                    return true;
                }
            }
            return false;
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.minNumber"),
    },
    [Validations.MAX_LENGTH]: {
        validate: (str: any, options: any) => {
            if ((str && str.length < options.value) || !str) {
                return true;
            }
            return false;
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.maxLength", {
                      val: option.value,
                  }),
    },
    [Validations.MIN_LENGTH]: {
        validate: (str: any, options: any) => {
            if ((str && str.length > options.value) || !str) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.minLength"),
    },
    [Validations.MAX]: {
        validate: (str: number | string, options: any) => {
            if (
                (!str && typeof str == "string") ||
                (str && typeof str == "string" && str.trim().length === 0)
            ) {
                return true;
            } else if (str != null) {
                if (
                    (typeof str == "string" &&
                        isNumber(str) &&
                        toNumber(str) <= options.value) ||
                    str <= options.value
                ) {
                    return true;
                }
            }
            return false;
        },
        formatMessage: (message, fieldName, value, option) =>
            message ? message : i18next.t("common.errorMessages.maxNumber"),
    },
    [Validations.REQUIRED]: {
        validate: (value, options: any) => {
            if (options && options.isTemplate) {
                return validateHtmlTemplate(value);
            }
            return !!(!isNil(value) && value.toString().trim().length > 0);
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.required", {
                      field: fieldName,
                  }),
    },
    [Validations.ARRAY_REQUIRED]: {
        validate: (value) => {
            return !!(!isNil(value) && value.length > 0);
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.required", {
                      field: fieldName,
                  }),
    },
    [Validations.LESSER_THAN]: {
        validate: (value: number, options: any) => {
            return isNil(options.value)
                ? true
                : isNil(value)
                ? false
                : value <= options.value;
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.lessThanInvalid", {
                      field: fieldName,
                  }),
    },
    [Validations.GREATER_THAN]: {
        validate: (value: number, options: any) => {
            return isNil(value)
                ? true
                : isNil(options.value)
                ? false
                : value >= options.value;
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.errorMessages.greaterThanInvalid", {
                      field: fieldName,
                  }),
    },
    [Validations.LESSER_DATE]: {
        validate: (value: Moment, options: any) => {
            return isNil(options.value)
                ? true
                : isNil(value)
                ? false
                : value <= options.value;
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.startDate.invalid", {
                      field: fieldName,
                  }),
    },
    [Validations.GREATER_DATE]: {
        validate: (value: Moment, options: any) => {
            return isNil(value)
                ? true
                : isNil(options.value)
                ? false
                : value >= options.value;
            // : value.diff(options.value, "seconds") >= 0;
        },
        formatMessage: (message, fieldName, value, option) =>
            message
                ? message
                : i18next.t("common.endDate.invalid", {
                      field: fieldName,
                  }),
    },
};

export function applyValidations(
    state: ValidityState,
    rules: InputValidationRule[],
    value: any,
    field: string
): ValidityState {
    let isValid = true;
    let stateManager: ValidityStateManager = new ValidityStateManager(state);
    rules.forEach((rule) => {
        const result = !validators[rule.rule].validate(value, rule.options);

        if (result) {
            isValid = false;
            stateManager = stateManager.replaceErrors(field, {
                code: rule.rule,
                message: validators[rule.rule].formatMessage(
                    defaultTo(rule.message, ""),
                    field,
                    value,
                    rule.options
                ),
            });
        }
    });
    if (isValid) {
        stateManager = stateManager.replaceErrors(field, null);
    }
    return stateManager.state;
}

export function getInitializedValidityStateFromRules(
    rules: ValidationRules<string>,
    values: {
        [index: string]: any;
    },
    initialState: ValidityState = getInitializedValidityState([], [])
) {
    let state = initialState;
    for (const k of Object.keys(rules)) {
        state = applyValidations(state, defaultTo(rules[k], []), values[k], k);
    }
    return state;
}
