import { ISO8601_DATE_FORMAT } from "globals/constants";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    EmployeeShift,
    EmployeeShiftResponse,
    EmployeeShiftsListResponse,
} from "models";

export interface IEmployeeShiftService {
    list(): Promise<AppResponse<EmployeeShiftsListResponse>>;
    get(id: string): Promise<AppResponse<EmployeeShiftResponse>>;
    createUpdate(
        model: EmployeeShift
    ): Promise<AppResponse<EmployeeShiftResponse>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext()
    .business.employees()
    .api.withId().shifts;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getEmployeeShiftServiceKey(
    name: keyof IEmployeeShiftService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `EmployeeShiftService_${name}_${JSON.stringify(data)}`;
}

export class EmployeeShiftService
    extends BaseService
    implements IEmployeeShiftService
{
    public shiftRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.shiftRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<EmployeeShiftsListResponse>> {
        return this.doServerXHR<EmployeeShiftsListResponse>({
            url: this.shiftRoutes.list(),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<EmployeeShiftResponse>> {
        return this.doServerXHR<EmployeeShiftResponse>({
            url: this.shiftRoutes.get(id),
            method: "get",
        });
    }

    createUpdate(
        model: EmployeeShift
    ): Promise<AppResponse<EmployeeShiftResponse>> {
        return this.doServerXHR<EmployeeShiftResponse>({
            url: this.shiftRoutes.createUpdate(),
            method: "post",
            data: {
                ...model,
                StartDate: model.StartDate?.format(ISO8601_DATE_FORMAT),
                EndDate: model.EndDate
                    ? model.EndDate.format(ISO8601_DATE_FORMAT)
                    : undefined,
            },
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.shiftRoutes.delete(id),
            method: "delete",
        });
    }
}

export default EmployeeShiftService;
