import { useAppContext } from "hoc/providers/AppContextProvider";
import { useSessionUser } from "hooks/general/appContextHelpers";
import {
    AllowedSupportViewPermissions,
    constructAllowedPermissionObject,
    createPermissionMap,
    isPermissionInScope,
    PermissionAccessTypes,
} from "models/permissionManagement";
import { PermissionAccessMap } from "models/permissionManagement/model";
import { useCallback, useMemo } from "react";

// for checking permission in business other than sessionBusiness
export const useBusinessCheckPermission = () => {
    const { isAuthenticated } = useSessionUser();
    const { userInfo, supportViewData } = useAppContext();
    const permissions = useMemo(() => {
        return userInfo != null && userInfo.AccessInfo
            ? userInfo.AccessInfo.AllPermissions
            : [];
    }, [isAuthenticated, userInfo]);

    const getPermissionMap = useCallback(
        (
            businessId: number,
            hqId: number,
            identifiers: string | string[]
        ): PermissionAccessMap => {
            if ("string" === typeof identifiers) {
                identifiers = [identifiers];
            }

            const filteredPermissions =
                supportViewData &&
                supportViewData.isSupportViewEnabled &&
                identifiers.every(
                    (p) => AllowedSupportViewPermissions.includes(p) // permission is for support screen (non admin screens)
                )
                    ? constructAllowedPermissionObject(identifiers)
                    : permissions.filter((x) => {
                          const inScope = isPermissionInScope(
                              hqId,
                              businessId,
                              x.Scope
                          );

                          return inScope && identifiers.includes(x.Identifier);
                      });

            return createPermissionMap(filteredPermissions);
        },
        [permissions, supportViewData]
    );
    const checkPermission = useCallback(
        (
            businessId: number,
            hqId: number,
            identifiers: string | string[],
            permissionAccessType: PermissionAccessTypes[] = [
                PermissionAccessTypes.SHOW,
            ]
        ): boolean => {
            const permMap = getPermissionMap(businessId, hqId, identifiers);
            return permissionAccessType.some((p) => permMap[p]);
        },
        [getPermissionMap]
    );

    return {
        checkPermission,
        getPermissionMap,
    };
};

export default useBusinessCheckPermission;
