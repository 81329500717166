import { AppNumberInput } from "components/FormFields";
import { FORMAT_INTEGER } from "globals/constants";
import { Optional } from "models/general";
import React, { useEffect, useState } from "react";
import styles from "./AdditionalKidInput.module.scss";

export interface AdditionalKidInputProps {
    value: Optional<number>;
    readonly?: boolean;
    onChange: (val: Optional<number>) => void;
}

export const AdditionalKidInput: React.FC<AdditionalKidInputProps> = ({
    value,
    readonly,
    onChange,
}) => {
    const [state, setState] = useState<Optional<number>>(value);

    useEffect(() => {
        setState(value);
    }, [value]);
    return (
        <AppNumberInput
            className={styles.numberInput}
            showLabel={false}
            classes={{
                field: styles.inputField,
            }}
            format={FORMAT_INTEGER}
            value={value}
            showPlaceHolder={false}
            allowZero={true}
            readOnly={readonly}
            onChange={(val) => {
                setState(val);
            }}
            onBlur={() => {
                onChange(state);
            }}
        />
    );
};

export default AdditionalKidInput;
