import { BusinessType } from "models/business";
import { Optional } from "models/general";
import { LillywaitRoutingContext } from "routing/routingContexts";
import {
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";

export const lillywaitClientRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: LillywaitRoutingContext = new LillywaitRoutingContext(),
    clientId: string = ":lwcId"
) => {
    const clientsBase = "lw-clients";
    const base = `${context.getBaseUrl()}/${clientsBase}`;

    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {
            getLwClientsList: (reqParams?: {
                type?: BusinessType;
                loadContracts: boolean;
            }) =>
                getUrlForPattern(
                    context,
                    true,
                    `get-lw-clients-list`,
                    true,
                    undefined,
                    reqParams
                ),
        },
        screens: {
            kindergartens: ((kindergartenBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(context, buildUrl, kindergartenBase),
                    withId: (lwcEncodedId?: Optional<string> | ":lwcId") =>
                        ((editBase: string) => {
                            const subPathBase = buildUrl
                                ? `${kindergartenBase}/${editBase}/`
                                : ``;
                            return {
                                base: () => `${kindergartenBase}/${editBase}`,
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}basic-data`
                                    );
                                },
                                publicHolidays: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}public-holidays`
                                    ),
                                documents: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}documents`
                                    ),
                                workingHours: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}working-hours`
                                    ),
                                basicSettings: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}settings`
                                    ),
                                externalIntegration: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}external-integration`
                                    ),
                                chatSettings: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}chat-settings`
                                    ),
                                timeTracking: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}time-tracking`
                                    ),
                                ipads: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}ipads`
                                    ),
                                contracts: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}contracts`
                                    );
                                },
                                kontos: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}kontos`
                                    );
                                },
                                accessControl: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}access-control`),
                                compensationTemplates: ((
                                    sectionBase: string
                                ) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}compensation-templates`),
                                mealTemplates: ((sectionBase: string) => {
                                    return {
                                        ...getCrudUrlsForSection(
                                            sectionBase,
                                            context,
                                            buildUrl
                                        ),
                                        meals: (
                                            encodedId: string | ":id" = ":id"
                                        ) =>
                                            getUrlForPattern(
                                                context,
                                                buildUrl,
                                                `${sectionBase}/${encodedId}/meals`
                                            ),
                                    };
                                })(`${subPathBase}meal-templates`),
                                shifts: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}shifts`),
                                employeeKeyCalculations: ((
                                    sectionBase: string
                                ) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}employee-key-calculations`),
                                careScopes: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}care-scopes`),
                                ageGroups: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}age-groups`),
                                salaryGroups: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}salary-groups`),
                                groups: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}groups`),
                                courses: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}courses`),
                                areas: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}areas`),
                                functions: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}functions`),
                            };
                        })(`${lwcEncodedId ? lwcEncodedId : clientId}`),
                };
            })(`${clientsBase}/kindergartens`),
            industries: ((industryBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(context, buildUrl, industryBase),
                    withId: (lwcEncodedId?: Optional<string> | ":lwcId") =>
                        ((editBase: string) => {
                            const subPathBase = buildUrl
                                ? `${industryBase}/${editBase}/`
                                : ``;
                            return {
                                base: () => `${industryBase}/${editBase}`,
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}basic-data`
                                    );
                                },
                                publicHolidays: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}public-holidays`
                                    ),
                                documents: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}documents`
                                    ),
                                workingHours: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}working-hours`
                                    ),
                                basicSettings: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}settings`
                                    ),
                                externalIntegration: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}external-integration`
                                    ),
                                chatSettings: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}chat-settings`
                                    ),
                                timeTracking: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}time-tracking`
                                    ),
                                ipads: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}ipads`
                                    ),
                                contracts: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}contracts`
                                    );
                                },
                                kontos: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}kontos`
                                    );
                                },
                                accessControl: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}access-control`),
                                shifts: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}shifts`),
                                groups: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}groups`),
                                courses: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}courses`),
                                areas: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}areas`),
                                functions: ((sectionBase: string) => {
                                    return getCrudUrlsForSection(
                                        sectionBase,
                                        context,
                                        buildUrl
                                    );
                                })(`${subPathBase}functions`),
                            };
                        })(`${lwcEncodedId ? lwcEncodedId : clientId}`),
                };
            })(`${clientsBase}/industries`),
            schools: ((schoolBase: string) => {
                return {
                    list: () => getUrlForPattern(context, buildUrl, schoolBase),
                    withId: (lwcEncodedId?: Optional<string> | ":lwcId") =>
                        ((editBase: string) => {
                            const subPathBase = buildUrl
                                ? `${schoolBase}/${editBase}/`
                                : ``;
                            return {
                                base: () => `${schoolBase}/${editBase}`,
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}basic-data`
                                    );
                                },

                                externalIntegration: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}external-integration`
                                    ),

                                publicHolidays: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}public-holidays`
                                    ),
                                workingHours: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}working-hours`
                                    ),
                                documents: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}documents`
                                    ),
                                contracts: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}contracts`
                                    ),
                            };
                        })(`${lwcEncodedId ? lwcEncodedId : clientId}`),
                };
            })(`${clientsBase}/schools`),
            elderlyCares: ((elderlyCareBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(context, buildUrl, elderlyCareBase),
                    withId: (lwcEncodedId?: Optional<string> | ":lwcId") =>
                        ((editBase: string) => {
                            const subPathBase = buildUrl
                                ? `${elderlyCareBase}/${editBase}/`
                                : ``;
                            return {
                                base: () => `${elderlyCareBase}/${editBase}`,
                                edit: () => {
                                    return getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}basic-data`
                                    );
                                },
                                externalIntegration: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}external-integration`
                                    ),
                                publicHolidays: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}public-holidays`
                                    ),
                                workingHours: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}working-hours`
                                    ),
                                documents: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}documents`
                                    ),
                                contracts: () =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${subPathBase}contracts`
                                    ),
                            };
                        })(`${lwcEncodedId ? lwcEncodedId : clientId}`),
                };
            })(`${clientsBase}/elderly-cares`),
        },
    };
};

export default lillywaitClientRoutes;
