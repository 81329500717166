import classNames from "classnames";
import ErrorMessage from "components/ErrorMessage";
import { ImageAssets } from "globals/images/common";
import { defaults, defaultTo, isNil } from "lodash-es";
import { ErrorInfo, Optional } from "models/general";
import React, { forwardRef, Ref } from "react";
import {
    Form,
    FormControl,
    FormControlProps,
    Image,
    InputGroup,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./AppInputField.module.scss";

export interface AppInputFieldProps extends Omit<FormControlProps, "value"> {
    label?: string | React.ReactNode;
    onFocus?: (val: string) => void;
    onBlur?: (val: string) => void;
    placeholder?: string;
    error?: Optional<ErrorInfo>;
    showEmptyError?: boolean;
    value?: Optional<string | string[] | number>;
    disableAutoComplete?: boolean;
    ignoreKeyPress?: boolean;
    showClearButton?: boolean;
    onValueChange?: (value: string | undefined) => void;
    // defaultOnChange?: React.ChangeEventHandler;
    onKeyDown?: (e: any) => void;
    useDefaultOnChange?: boolean;
    leftIcon?: React.ReactNode;
    style?: any;
    classes?: {
        label?: string;
        field?: string;
    };
    rightIcon?: React.ReactNode;
}
function Component(props: AppInputFieldProps, ref: Ref<any>) {
    const {
        label,
        placeholder,
        error = null,
        disableAutoComplete = true,
        onBlur,
        onFocus,
        className,
        useDefaultOnChange = false,
        showEmptyError = false,
        showClearButton,
        classes: passedClasses,
        value,
        onValueChange,
        onChange: defaultOnChange,
        onKeyDown,
        style,
        ignoreKeyPress,
        leftIcon,
        rightIcon,
        ...rest
    } = props;
    const classes = defaults(passedClasses, {
        label: "",
        field: "",
    });

    return (
        <Form.Group
            controlId={uuid()}
            className={className}
            style={{ position: "relative" }}
        >
            {label && (
                <Form.Label className={classNames(styles.label, classes.label)}>
                    {label}
                </Form.Label>
            )}
            <InputGroup>
                {leftIcon ? (
                    <div
                        className={classNames(
                            styles.leftIconDiv,
                            "left-input-icon"
                        )}
                    >
                        {leftIcon}
                    </div>
                ) : (
                    <></>
                )}
                <FormControl
                    ref={ref}
                    onFocus={(e: any) => onFocus && onFocus(e.target.value)}
                    onBlur={(e: any) => onBlur && onBlur(e.target.value)}
                    className={classNames(
                        styles.input,
                        classes.field,
                        {
                            [styles.leftPadding]: leftIcon,
                        },
                        {
                            [styles.rightPadding]: rightIcon || showClearButton,
                        }
                    )}
                    {...rest}
                    onChange={(e) => {
                        // console.log("changed", e.target.value);
                        if (onValueChange) {
                            // console.debug("val changed");
                            onValueChange(e.target.value);
                        } else if (useDefaultOnChange && defaultOnChange) {
                            // console.debug("default changed");
                            defaultOnChange(e);
                        } else {
                            console.debug(
                                "else",
                                useDefaultOnChange,
                                defaultOnChange
                            );
                        }
                    }}
                    value={defaultTo(value, "")}
                    autoComplete={disableAutoComplete ? uuid() : undefined}
                    placeholder={placeholder}
                    style={style}
                    onKeyDown={(e: any) => {
                        if (ignoreKeyPress || onKeyDown) {
                            if (
                                ignoreKeyPress &&
                                !(e.key === "Tab" || e.keyCode === 9)
                            ) {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            } else if (onKeyDown) {
                                onKeyDown(e);
                            }
                        }
                    }}
                />
                <div
                    className={classNames(
                        styles.rightIconDiv,
                        "right-input-icon"
                    )}
                >
                    {showClearButton &&
                    !isNil(value) &&
                    value.toString().length > 0 ? (
                        <Image
                            src={ImageAssets.common.crossRed}
                            className={classNames(styles.clearButton)}
                            onClick={() => {
                                onValueChange && onValueChange(undefined);
                            }}
                        />
                    ) : (
                        rightIcon
                    )}
                </div>
            </InputGroup>
            <ErrorMessage showEmpty={showEmptyError} errorInfo={error} />
        </Form.Group>
    );
}

export const AppInputField = forwardRef(Component);
export default AppInputField;
