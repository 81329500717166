export const COLOR_PRIMARY = "#16aaff";
export const COLOR_PRIMARY_DARK = "#169eff";
export const COLOR_PURPLE_LIGHT = "#9900cc";

export const COLOR_TEXT_GREY = "#9e9e9e";
export const COLOR_BORDER_GREY = "#ced4da";
export const COLOR_TEXT_DARK = "#0d1c26";
export const COLOR_GREY = "#cccccc";
export const COLOR_GREY_LIGHTEST = "#dfdfdf";
export const COLOR_GREY_LIGHT = "#f5f5f5";
export const COLOR_GREY_LIGHTER = "#dedede";

export const COLOR_BLUE = COLOR_PRIMARY;
export const COLOR_BLUE_LIGHT = "#e1f3ff";
export const COLOR_RED = "#da3916";
export const COLOR_LIGHT_RED = "#ff9e9e";
export const COLOR_LIGHT_RED2 = "#ff7373";
export const COLOR_YELLOW = "#fff69b";
export const COLOR_DARK_YELLOW = "#ffcc00";
export const COLOR_YELLOW_LIGHT = "#ffffd4";
export const COLOR_ORANGE_LIGHT = "#f7b924";
export const COLOR_PINK = "#ffb6e6";
export const COLOR_GREEN = "#2fc47d";
export const COLOR_LIGHT_GREEN = "#d3ffcc";

export const INPUT_COLOR = "#495057";
