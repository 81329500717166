import { PropsWithChildren, useEffect } from "react";
import { BaseObject } from "models/general";
import { useWebNotificationHub } from "hooks/layout/useWebNotificationHub";

export const AppWebNotificationProvider: React.FC<
    PropsWithChildren<BaseObject>
> = ({ children }: PropsWithChildren<BaseObject>) => {
    // init signalR
    const { configure, dispose, signalR } = useWebNotificationHub();

    useEffect(() => {
        if (signalR === null) {
            configure();
        }

        return () => {
            // stop signalR on un-mount
            dispose();
        };
    }, []);
    return <>{children}</>;
};

export default AppWebNotificationProvider;
