import classNames from "classnames";
import ImageAssets from "globals/images/common";
import { defaultTo, isNil } from "lodash-es";
import React from "react";
import { ButtonProps, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppButton, AppButtonProps } from "./AppButton";
import styles from "./AppRoundedTextIconButton.module.scss";
export interface AppRoundedTextIconButtonProps extends AppButtonProps {
    href?: string;
    onClick?: () => void;
    text?: string;
    iconUrl?: string;
    readOnly?: boolean;
    style?: any;
    className?: string;
}
export const AppRoundedTextIconButton: React.FC<
    AppRoundedTextIconButtonProps
> = ({
    href,
    onClick,
    text,
    iconUrl = ImageAssets.common.plus,
    readOnly = false,
    className,
    padding = "default",
    rounded = true,
    variant = "success",
    style,
    ...rest
}) => {
    const { t } = useTranslation();
    const componentProps = {
        padding,
        variant,
        ...rest,
        className: readOnly ? styles.disabled : "",
        onClick: () => {
            if (!readOnly && onClick) {
                onClick();
            }
        },
    } as AppButtonProps;
    const content = (
        <>
            {defaultTo(text, t("common.createNew"))}
            <Image className={styles.btnImage} src={iconUrl} />
        </>
    );
    return (
        <div className={classNames(styles.buttonDiv, className)} style={style}>
            {!isNil(href) ? (
                <Link to={href} {...componentProps}>
                    {content}
                </Link>
            ) : (
                <AppButton {...componentProps} rounded={rounded}>
                    {content}
                </AppButton>
            )}
        </div>
    );
};

export default AppRoundedTextIconButton;
