import { BusinessType } from "models/business";
import BusinessRoutingContext from "../BusinessRoutingContext";

export class KindergartenRoutingContext extends BusinessRoutingContext {
    constructor(
        businessType?: BusinessType.KINDERGARTEN | ":sessionBusinessType",
        businessId?: string | undefined
    ) {
        super(businessType, businessId);
    }
    getContextIdentifier(): string {
        return "kindergarten-context";
    }
    getApiBaseUrl() {
        return `${super.getBaseApiUrl()}/kindergarten/${this.businessId}`;
    }
}

export default KindergartenRoutingContext;
