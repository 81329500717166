import i18next from "i18next";
import { defaultTo, uniq } from "lodash-es";
import {
    DynamicInputPlaceholderOptions,
    DynamicPlaceholderType,
    EditableSectionPlaceholderOptions,
    GenderPlaceholderOptions,
    OpenInputPlaceholderOptions,
    Placeholder,
    PlaceholderOptionsBase,
    PlaceholderType,
    PlaceholderTypeOptions,
} from "models";
import { v4 as uuid } from "uuid";
import {
    DynamicPlaceholderFields,
    PlaceholderPluginOptions,
    PlaceholderTypeHandler,
} from "./Types";

export const PlaceholderContainerClass = "placeholder-tag";

export function getPreparedPlaceholder(
    existing: Placeholder | null,
    options: PlaceholderOptionsBase,
    handler: PlaceholderTypeHandler
): Placeholder {
    const guid = existing == null ? uuid() : existing.Guid;
    const newPlaceholder: Placeholder = {
        Guid: guid,
        Options: options as any,
        Type: handler.Type,
        Output: null,
    };
    const handlerOutput = handler.getUserOutput(newPlaceholder);
    newPlaceholder.Output = `<span class='mceNonEditable ${PlaceholderContainerClass}' data-guid="${guid}" 
    data-isSection="${
        newPlaceholder.Type === PlaceholderType.EditableSection ? 1 : 0
    }">${handlerOutput}</span>`;
    return newPlaceholder;
}

export class GenderPlaceholderHandler implements PlaceholderTypeHandler {
    Type: PlaceholderType.GenderSpecific = PlaceholderType.GenderSpecific;
    isValidOptions(options: any) {
        const opts = options as GenderPlaceholderOptions;
        if (
            defaultTo(opts.Male, "").length === 0 ||
            defaultTo(opts.Female, "").length === 0
        ) {
            return false;
        }
        return true;
    }
    adaptOptions(options: any) {
        return {
            ...options,
            Type: this.Type,
        };
    }
    getDialogProps(placeholder: Placeholder) {
        const items = [
            {
                type: "input",
                name: "Male",
                label: i18next.t("tinymce.malePlaceholder"),
            },
            {
                type: "input",
                name: "Female",
                label: i18next.t("tinymce.femalePlaceholder"),
            },
        ];
        return {
            items,
            initialData: (placeholder == null
                ? {}
                : placeholder.Options) as GenderPlaceholderOptions,
        };
    }

    getUserOutput(placeholder: Placeholder): string {
        const opts = placeholder.Options as GenderPlaceholderOptions;
        return `{{${i18next.t("placeholders.genderSpecific.male")}:${
            opts.Male
        }|${i18next.t("placeholders.genderSpecific.female")}:${opts.Female}}}`;
    }
}

export class OpenInputPlaceholderHandler implements PlaceholderTypeHandler {
    Type: PlaceholderType.OpenInput = PlaceholderType.OpenInput;
    isValidOptions(options: any) {
        const opts = options as OpenInputPlaceholderOptions;
        if (defaultTo(opts.Name, "").length === 0) {
            return false;
        }
        return true;
    }
    adaptOptions(options: any) {
        return {
            ...options,
            Type: this.Type,
            Value: null,
        } as OpenInputPlaceholderOptions;
    }
    getDialogProps(placeholder: Placeholder) {
        const items = [
            {
                type: "input",
                name: "Name",
                label: i18next.t("tinymce.name"),
            },
        ];
        return {
            items,
            initialData: (placeholder == null
                ? {}
                : placeholder.Options) as OpenInputPlaceholderOptions,
        };
    }

    getUserOutput(placeholder: Placeholder): string {
        const opts = placeholder.Options as OpenInputPlaceholderOptions;
        return `{{${opts.Name}}}`;
    }
}

export class EditableSectionPlaceholderHandler
    implements PlaceholderTypeHandler
{
    Type: PlaceholderType.EditableSection = PlaceholderType.EditableSection;
    isValidOptions(options: PlaceholderTypeOptions) {
        const opts = options as EditableSectionPlaceholderOptions;
        if (defaultTo(opts.Name, "").length === 0) {
            return false;
        }
        return true;
    }
    adaptOptions(options: PlaceholderTypeOptions) {
        return {
            ...options,
            Type: this.Type,
        } as EditableSectionPlaceholderOptions;
    }
    getDialogProps(placeholder: Placeholder) {
        const items = [
            {
                type: "input",
                name: "Name",
                label: i18next.t("tinymce.sectionName"),
            },
        ];
        return {
            items,
            initialData: (placeholder == null
                ? {}
                : placeholder.Options) as EditableSectionPlaceholderOptions,
        };
    }

    getUserOutput(placeholder: Placeholder): string {
        const opts = placeholder.Options as EditableSectionPlaceholderOptions;
        return `{{----${opts.Name}----}}`;
    }
}

export function getLocalizedDynamicPlaceholderName(name: string) {
    return i18next.t(`placeholders.list.${name}`).toString();
}

export function getLocalizedDynamicPlaceholderTypeName(n: string) {
    return i18next
        .t(
            `placeholders.dynamicPlaceholderToggleTypes.${
                n === "Client" ? "ClientRoot" : n
            }`
        )
        .toString();
}

export class DynamicInputPlaceholderHandler implements PlaceholderTypeHandler {
    Type: PlaceholderType.DynamicInput = PlaceholderType.DynamicInput;

    isValidOptions(options: PlaceholderTypeOptions) {
        const opts = options as DynamicInputPlaceholderOptions;
        if (defaultTo(opts.Field, { Identifier: "" }).Identifier.length === 0) {
            return false;
        }
        return true;
    }
    adaptOptions(options: PlaceholderTypeOptions) {
        return {
            ...options,
            Type: this.Type,
        } as DynamicInputPlaceholderOptions;
    }
    getDialogProps(
        placeholder: Placeholder,
        options: PlaceholderPluginOptions
    ) {
        const items = [
            {
                type: "selectbox", // component type
                name: "Field", // identifier
                label: i18next.t("tinymce.fieldName"),
                size: 1, // number of visible values (optional)
                items: options.dynamicPlaceholderProps.map((f) => {
                    return {
                        value: JSON.stringify(f.field),
                        text: `${getLocalizedDynamicPlaceholderTypeName(
                            f.field.Namespace
                        )}=>${getLocalizedDynamicPlaceholderName(
                            f.field.Identifier
                        )}`,
                    };
                }),
            },
        ];
        return {
            items,
            initialData: (placeholder == null
                ? {}
                : {
                      ...placeholder.Options,
                      Field: JSON.stringify(
                          (
                              placeholder.Options as DynamicInputPlaceholderOptions
                          ).Field
                      ),
                  }) as DynamicInputPlaceholderOptions,
        };
    }

    getPlaceholderTypeToggles(options: DynamicPlaceholderFields[]): {
        name: string;
        val: DynamicPlaceholderType;
    }[] {
        return ["All", ...uniq(options.map((p) => p.field.Namespace))].map(
            (n) => ({
                name: getLocalizedDynamicPlaceholderTypeName(n),
                val: n as DynamicPlaceholderType,
            })
        );
    }

    getDynamicPlaceholdersForType(
        type: DynamicPlaceholderType,
        options: PlaceholderPluginOptions
    ) {
        const items = options.dynamicPlaceholderProps.filter(
            (f) => type === "All" || f.field.Namespace === type
        );
        return items;
    }

    getUserOutput(placeholder: Placeholder): string {
        const opts = placeholder.Options as DynamicInputPlaceholderOptions;
        const localizedName = getLocalizedDynamicPlaceholderName(
            opts.Field.Identifier
        );

        return `{{${getLocalizedDynamicPlaceholderTypeName(
            opts.Field.Namespace
        )} => ${localizedName}}}`;
    }
}

export const placeholderHandlersMap: {
    [key in PlaceholderType]: PlaceholderTypeHandler;
} = {
    [PlaceholderType.GenderSpecific]: new GenderPlaceholderHandler(),
    [PlaceholderType.OpenInput]: new OpenInputPlaceholderHandler(),
    [PlaceholderType.DynamicInput]: new DynamicInputPlaceholderHandler(),
    [PlaceholderType.EditableSection]: new EditableSectionPlaceholderHandler(),
};
