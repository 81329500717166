import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    Business_WorkingHours,
    LW_ElderlyCare_WorkingDays,
    LW_Industry_WorkingDays,
    LW_Kindergarten_WorkingDays,
    LW_School_WorkingDays,
} from "models/permissionManagement";
import React from "react";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";
import { BusinessType } from "models";
import { WorkingDaysSetting } from "commonPartials";
import { useSessionBusiness } from "hooks/general/appContextHelpers";

export const BusinessWorkingDays: React.FC = () => {
    const { lwcId } = useParams();

    const { dataItem: business } = useCurrentBusinessSettingsContext();
    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();
    const { getPermissionMap } = useBusinessCheckPermission();
    const isLwc = !isNil(lwcId);
    const permMap = isLwc
        ? getPermissionMap(business?.Id as number, business?.HqId as number, [
              business?.Type == BusinessType.KINDERGARTEN
                  ? LW_Kindergarten_WorkingDays
                  : business?.Type == BusinessType.SCHOOL
                  ? LW_School_WorkingDays
                  : business?.Type == BusinessType.INDUSTRY
                  ? LW_Industry_WorkingDays
                  : LW_ElderlyCare_WorkingDays,
          ])
        : getPermissionMap(sessionBusinessId as number, sessionHqId as number, [
              Business_WorkingHours,
          ]);

    return (
        <WorkingDaysSetting
            currentBusiness={business}
            locationId={business?.LocationSettingId}
            permissionsMap={permMap}
        />
    );
};

export default BusinessWorkingDays;
