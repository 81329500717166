import classNames from "classnames";
import { getHtmlCoordinates } from "globals/helpers/generalHelper";
import { ImageAssets } from "globals/images";
import { defaultTo, isNil } from "lodash-es";
import React, { ReactNode, useEffect, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styles from "./AppTabButtons.module.scss";
export interface ExtraContentProps {
    content?: ReactNode;
    onClick?: () => void;
    loading?: boolean;
    className?: string;
}
export interface AppTabButtonProps extends ButtonProps {
    label: string;
    extraContent?: ExtraContentProps;
}
interface AppTabButtonsComponentProps {
    tabButtons: AppTabButtonProps[];
    className?: string;
    animated?: boolean;
    containerClass?: string;
    btnClass?: string;
    activeClass?: string;
    loading?: boolean;
}

const ExtraContent: React.FC<ExtraContentProps> = ({
    onClick,
    content,
    loading = false,
}) => {
    const [hover, setHover] = useState(false);
    const defaultContent = (
        <img
            onClick={(e) => {
                if (!loading && onClick) {
                    onClick();
                }
                if (e && e.target) {
                    (e.target as any).blur();
                    if ((e.target as any).closest("button")) {
                        (e.target as any).closest("button").blur();
                    }
                }
            }}
            onMouseOver={(e): void => {
                setHover(true);
                e.currentTarget.src = ImageAssets.common.plusBlue;
            }}
            onMouseOut={(e): void => {
                setHover(false);
                e.currentTarget.src = ImageAssets.common.plus;
            }}
            src={ImageAssets.common.plus}
            className={styles.rightContentIcon}
        />
    );
    return (
        <div className={classNames(styles.rightContent)}>
            <div
                className={classNames(
                    styles.content,
                    {
                        [styles.hover]: hover,
                    },
                    {
                        [styles.disabled]: loading,
                    }
                )}
            >
                {content ? content : defaultContent}
            </div>
        </div>
    );
};
const Component = React.memo<AppTabButtonsComponentProps>(
    ({
        tabButtons,
        animated = true,
        className = "",
        btnClass = "",
        activeClass = "",
        containerClass,
        loading = false,
    }) => {
        const [activeBtnStart, setActiveBtnStart] = useState<number>(-15);
        const [activeBtn, setActiveBtn] = useState<HTMLButtonElement | null>(
            null
        );
        useEffect(() => {
            if (activeBtn) {
                const pos = getHtmlCoordinates(activeBtn);
                if (pos) {
                    if (animated) {
                        setActiveBtnStart(pos.left);
                    }
                    // timeout is to keep button text color
                    setTimeout(() => activeBtn.blur(), 250);
                }
            }
        }, [activeBtn, animated]);

        const finalActiveClass = `${activeClass} ${styles.active}`;

        return (
            <div className={`${styles.root} ${className}`}>
                <div
                    className={classNames(
                        `${styles.container} ${containerClass}`,
                        { [styles.animated]: animated },
                        {
                            // allowOverflow for buttons with content
                            [styles.allowOverflow]: tabButtons.some(
                                (b) => !isNil(b.extraContent)
                            ),
                        }
                    )}
                >
                    {animated && (
                        <div
                            className={`${styles.tabButton}  ${btnClass} ${activeClass} ${styles.activeSelector}`}
                            style={{ left: activeBtnStart }}
                        />
                    )}
                    {tabButtons.map(({ onClick, extraContent, ...button }) => (
                        <Button
                            ref={(ref: any) => {
                                if (button.active && ref) {
                                    setActiveBtn(ref);
                                }
                            }}
                            onClick={(e) => {
                                if (!loading && onClick) {
                                    onClick(e);
                                }
                                if (e && e.target) {
                                    (e.target as any).blur();
                                }
                            }}
                            key={button.label}
                            {...button}
                            className={classNames(
                                `${styles.tabButton} ${btnClass}`,
                                {
                                    [defaultTo(button.className, "")]: !isNil(
                                        button.className
                                    ),
                                },
                                {
                                    [styles.hoverColor]: !button.active,
                                },
                                {
                                    [styles.withContent]: !isNil(extraContent),
                                },
                                {
                                    [styles.disabled]:
                                        loading && !button.active,
                                },
                                {
                                    [finalActiveClass]: button.active,
                                }
                            )}
                        >
                            <span className={styles.text}>{button.label}</span>
                            {extraContent && (
                                <ExtraContent
                                    {...extraContent}
                                    loading={defaultTo(
                                        extraContent.loading,
                                        loading
                                    )}
                                />
                            )}
                        </Button>
                    ))}
                </div>
            </div>
        );
    }
);
Component.displayName = "AppTabButtons";
export const AppTabButtons = React.memo(Component);
export default AppTabButtons;
