import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import { ContactInfo, KindergartenClient } from "./model";

export function validateKindergartenClientBasicInfo(
    obj: KindergartenClient,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "kindergartenClient.basicInfo.firstName.missing"
                ),
            },
        ],
        obj.FirstName,
        "FirstName"
    );
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "kindergartenClient.basicInfo.lastName.missing"
                ),
            },
        ],
        obj.LastName,
        "LastName"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "kindergartenClient.basicInfo.birthday.missing"
                ),
            },
        ],
        obj.Birthday,
        "Birthday"
    );

    return state;
}
export function validateClientContactInfo(
    obj: ContactInfo,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.OPTIONAL_EMAIL,
                message: i18next.t(
                    "kindergartenClient.contactInfo.email.invalid"
                ),
            },
        ],
        obj.Email,
        "Email"
    );

    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "kindergartenClient.contactInfo.firstName.missing"
                ),
            },
        ],
        obj.FirstName,
        "FirstName"
    );

    return state;
}
