import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import AppButton, { AppButtonProps } from "./AppButton";
import styles from "./AppIconTextButton.module.scss";

export interface AppIconTextButtonProps
    extends Omit<AppButtonProps, "children"> {
    icon: IconName;
    text: string;
}

export const AppIconTextButton: React.FC<AppIconTextButtonProps> = ({
    icon,
    text: buttonText,
    className = "",
    variant = "primary",
    ...rest
}) => {
    return (
        <AppButton
            {...rest}
            variant={variant}
            className={classNames(styles.btn, className)}
        >
            <span>
                <FontAwesomeIcon icon={icon} className={styles.icon} />
                {buttonText}
            </span>
        </AppButton>
    );
};

export default AppIconTextButton;
