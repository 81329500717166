import { RenewContractFields } from "commonPartials";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";

export const RenewContractView: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const { dataItem: currentBusiness, refetchData } =
        useCurrentBusinessSettingsContext();

    const redirect = () =>
        navigate(linkProvider.business.businessSettings().screens.contracts());
    useEffect(() => {
        if (currentBusiness?.HasNonCancelledContract) {
            redirect();
        }
    }, [currentBusiness]);
    return currentBusiness ? (
        <AppContainer
            showHeader={true}
            onBack={redirect}
            title={t("businessContract.renew.title")}
        >
            <RenewContractFields
                businessId={currentBusiness?.Id}
                onSuccess={() => {
                    showSweetAlertToast(
                        t("common.success"),
                        t(`businessContract.renew.contractRenewedSuccessfully`),
                        "success"
                    );
                    if (refetchData) {
                        refetchData();
                    }
                    setTimeout(() => {
                        redirect();
                    }, 1000);
                }}
            />
        </AppContainer>
    ) : (
        <></>
    );
};

export default RenewContractView;
