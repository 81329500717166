import { BusinessType } from "models/business";
import BusinessRoutingContext from "../BusinessRoutingContext";

export class LillywaitRoutingContext extends BusinessRoutingContext {
    constructor(
        businessType?: BusinessType.LW | ":sessionBusinessType",
        businessId?: string | undefined
    ) {
        super(businessType, businessId);
    }
    getContextIdentifier(): string {
        return "lillywait-context";
    }
    getApiBaseUrl() {
        return `${super.getBaseApiUrl()}/lillywait/${this.businessId}`;
    }
}

export default LillywaitRoutingContext;
