import React, { useMemo } from "react";
import { SortableContainer, SortableHandle } from "react-sortable-hoc";
import styles from "./AppSortable.module.scss";
import { ValidityState, ValidityStateManager } from "models/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { defaultTo } from "lodash-es";

interface FieldsContainerProps {
    children: React.ReactNode;
}

export const DragHandle: any = SortableHandle(
    ({ className = "" }: { className?: string }) => (
        <div className={classNames(styles.rowDragHandle, className)}>
            <FontAwesomeIcon icon="bars" className={styles.icon} />
        </div>
    )
);

const FieldsContainer: React.FunctionComponent<FieldsContainerProps> = (
    props: FieldsContainerProps
) => {
    return <div className={styles.rowsContainer}>{props.children}</div>;
};

const SortableFieldsList: any = SortableContainer(FieldsContainer);

export interface AppSortableProps<T> {
    SortableItem: any;
    handleSortEnd: ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => void;
    list: T[];
    onChange: (field: T) => void;
    validityState?: ValidityState;
    readonly?: boolean;
    onDelete?: (uuid: string) => void;
    itemCommonProps?: any;
}

export function AppSortable<T>(props: AppSortableProps<T>) {
    const {
        SortableItem,
        handleSortEnd,
        itemCommonProps,
        list,
        onChange,
        validityState,
        readonly,
        onDelete,
    } = props;

    const stateManager = useMemo(() => {
        return new ValidityStateManager(validityState);
    }, [list]);

    return (
        <SortableFieldsList
            lockAxis={"y"}
            helperClass={styles.sortableRow}
            lockToContainerEdges={true}
            lockOffset={"50%"}
            onSortEnd={handleSortEnd}
            useDragHandle={true}
        >
            {list.map((f, idx) => (
                <SortableItem
                    key={(f as any).Uuid}
                    index={idx}
                    validityState={
                        stateManager.getFieldState((f as any).Uuid)?.children
                    }
                    value={f}
                    readonly={readonly}
                    onChange={onChange}
                    onDelete={onDelete}
                    {...defaultTo(itemCommonProps, {})}
                />
            ))}
        </SortableFieldsList>
    );
}

export default AppSortable;
