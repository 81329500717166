import { AppTooltip } from "components";
import { AvatarInfo } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ChannelHeaderInfo.module.scss";
import { ChatAvatars } from "./ChatAvatars";
import { ChatUsersDetailPopover } from "./ChatUsersDetailPopover";
interface ChannelHeaderInfoProps {
    members: number;
    channelName: string;
    channelInfos: AvatarInfo[];
    membersDetails: AvatarInfo[];
    showOnlineState?: boolean;
    tooltip?: string;
    onlineState?: boolean;
}

// eslint-disable-next-line react/display-name
export const ChannelHeaderInfo = React.memo<ChannelHeaderInfoProps>(
    ({
        channelName,
        membersDetails,
        members,
        channelInfos,
        onlineState = false,
        tooltip,
        showOnlineState = false,
    }) => {
        const { t } = useTranslation();
        const nameSpan = (
            <span className={styles.channelNameText}>{channelName}</span>
        );
        return (
            <>
                <div className={styles.root}>
                    <div className={styles.avatars}>
                        <ChatAvatars channelInfos={channelInfos} />
                    </div>
                    <div className={styles.textDiv}>
                        {tooltip ? (
                            <AppTooltip
                                content={tooltip}
                                allowHtml={false}
                                trigger="mouseenter focus"
                                arrow={true}
                            >
                                {nameSpan}
                            </AppTooltip>
                        ) : (
                            nameSpan
                        )}
                        <span className={styles.channelMemberCountText}>
                            {showOnlineState ? (
                                onlineState ? (
                                    t("common.online")
                                ) : (
                                    t("common.offline")
                                )
                            ) : (
                                <ChatUsersDetailPopover
                                    mainHeading={t("chat.membersInfo")}
                                    targetElement={
                                        <span style={{ cursor: "pointer" }}>
                                            {`${members} ${t(
                                                `common.${
                                                    members == 1
                                                        ? "person"
                                                        : "persons"
                                                }`
                                            )}`}
                                        </span>
                                    }
                                    persons={membersDetails}
                                />
                            )}
                        </span>
                    </div>
                </div>
            </>
        );
    }
);

export default ChannelHeaderInfo;
