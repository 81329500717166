
export const CHART_COLORS_ARRAY = [
    "#16aaff",// (Primary Bright Blue)
    "#ff6f61",// (Bright Coral)
    "#ffca3a",// (Vibrant Yellow)
    "#4caf50",// (Bright Green)
    "#ff4081",// (Bright Pink)
    "#ff8a65",// (Coral Orange)
    "#29b6f6",// (Sky Blue)
    "#ab47bc",// (Vivid Purple)
    "#ff7043",// (Bright Orange)
    "#64b5f6",// (Light Blue)
    "#ffd54f",// (Golden Yellow)
    "#81c784",// (Soft Green)
    "#ff5722",// (Deep Orange)
    "#ff9800",// (Amber)
    "#ce93d8",// (Lavender Purple)
    "#9575cd",// (Soft Violet)
    "#4db6ac",// (Aqua Green)
    "#ffab91",// (Peach)
    "#8e24aa",// (Deep Purple)
    "#ffc1c1"
]
export const getChartColor = (idx: number = 0) => {
    // will use 10 distinct colors and cycle them based on mod
    return CHART_COLORS_ARRAY[Math.ceil(idx) % 20];
}