import { AppTooltip } from "components";
import { AppDeleteButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { AvatarItem } from "components/FormFields";
import { copyToClipboard } from "globals/helpers/generalHelper";
import { formatCompleteLongDateTime } from "globals/helpers/localizationHelpers";
import {
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Business_MailboxMessages, PermissionAccessTypes } from "models";
import {
    MessageNavigation,
    MessageTemplate,
    MessageTemplateWithStatuses,
} from "models/messages";
import moment from "moment";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { useNavigate } from "react-router";
import { useMessageLayoutContext } from "screens/business/messages/MessagesLayout";
import { MessageService } from "services/business";
import MessageDetailFile from "./MessageDetailFile";
import styles from "./MessageDetailPartial.module.scss";
import MessageDetailSendReceipt from "./MessageDetailSendReceipt";

export interface MessageDetailPartialProps {
    value: MessageTemplateWithStatuses;
    onChange: (template: MessageTemplate) => void;
    showLoading: () => void;
}

export const MessageDetailPartial: React.FC<MessageDetailPartialProps> = ({
    value,
    onChange,
    showLoading,
}) => {
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const { refetchMessageCount, activeMenu } = useMessageLayoutContext();
    const { ref, width } = useResizeDetector();
    const { appLocale } = useLocaleHelpers();

    const hasDeletePermission = checkPermission(
        [Business_MailboxMessages],
        [PermissionAccessTypes.DELETE]
    );
    const hasEditPermission = checkPermission(
        [Business_MailboxMessages],
        [PermissionAccessTypes.EDIT]
    );

    const onBack = () => {
        navigate(linkProvider.business.screens.mailbox.list());
    };

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const {
        data: responseFav,
        isLoading: loadingFav,
        mutate: updateFav,
    } = useMutation(
        async (fav: boolean) =>
            await messageService.updateTemplateFavorite(
                value.Message.Id.toString(),
                fav
            )
    );

    const {
        data: responseDel,
        isLoading: loadingDel,
        mutate: deleteTemplate,
    } = useMutation(
        async (templateId: string) => await messageService.delete(templateId)
    );

    const formattedDate =
        value.Message && value.Message.CreatedAt
            ? formatCompleteLongDateTime(
                  moment.unix(value.Message.CreatedAt),
                  appLocale
              )
            : "";

    useEffect(() => {
        if (!loadingFav && responseFav) {
            onChange({
                ...value.Message,
                IsFavorite: responseFav.Data,
            });

            showSweetAlertToast(
                t("common.success"),
                responseFav.Data
                    ? t("message.favorites.markAsFavorites")
                    : t("message.favorites.removedFromFavorite"),
                "success"
            );
        }
    }, [responseFav, loadingFav]);

    useEffect(() => {
        if (!loadingDel && responseDel) {
            showSweetAlertInfo(
                t("common.success"),
                t(
                    `message.${
                        activeMenu == MessageNavigation.Trash
                            ? "deletedSuccessfully"
                            : "movedToTrash"
                    }`
                ),
                "success"
            );
            refetchMessageCount();
            onBack();
        }
    }, [loadingDel, responseDel]);

    return (
        <div ref={ref} className={styles.detailOuter}>
            <AppContainer
                showHeader={true}
                heightToAdjust={230}
                mediumViewAdjustment={30}
                mobileViewAdjustment={40}
                onBack={() => onBack()}
                title={
                    <div className={styles.headerRoot}>
                        <div className={styles.content}>
                            {value.Message && value.Message.SenderInfo && (
                                <AvatarItem
                                    value={value.Message.SenderInfo}
                                    className={styles.headerImage}
                                    isOnline={false}
                                />
                            )}
                            {value.Message && value.Message.SenderInfo && (
                                <span className={styles.headerText}>
                                    {value.Message.SenderInfo.Name}
                                </span>
                            )}
                            <span className={styles.headerDate}>
                                {formattedDate}
                            </span>
                        </div>
                    </div>
                }
            >
                <div className={styles.detailBody}>
                    <div className={styles.messageContent}>
                        <div className={styles.detailTitle}>
                            {value && value.Message && (
                                <p>
                                    {value.Message.Title}{" "}
                                    {value.Message.Files &&
                                        value.Message.Files.length > 0 && (
                                            <span
                                                className={styles.attachments}
                                            >
                                                <Image
                                                    src={
                                                        ImageAssets.common
                                                            .paperClip
                                                    }
                                                />
                                            </span>
                                        )}
                                </p>
                            )}

                            <div className={styles.titleRight}>
                                {activeMenu != MessageNavigation.Trash && (
                                    <span>
                                        <Image
                                            onClick={() => {
                                                if (hasEditPermission) {
                                                    updateFav(
                                                        !value.Message
                                                            .IsFavorite
                                                    );
                                                }
                                            }}
                                            src={
                                                value.Message.IsFavorite
                                                    ? ImageAssets.common
                                                          .starFill
                                                    : ImageAssets.common.star
                                            }
                                        />
                                    </span>
                                )}
                                <span>
                                    <AppTooltip
                                        content={t(
                                            "common.copyDescriptionText"
                                        )}
                                        allowHtml={false}
                                        trigger="mouseenter focus"
                                        arrow={true}
                                    >
                                        <Image
                                            onClick={() => {
                                                if (
                                                    !isNil(
                                                        value.Message.Body?.Text
                                                    )
                                                ) {
                                                    copyToClipboard(
                                                        value.Message.Body.Text
                                                    );
                                                    showSweetAlertToast(
                                                        t("common.copied"),
                                                        t(
                                                            "common.copiedToClipboard"
                                                        ),
                                                        "success"
                                                    );
                                                }
                                            }}
                                            src={ImageAssets.common.copyGray}
                                        />
                                    </AppTooltip>
                                </span>
                                <span>
                                    <AppDeleteButton
                                        readonly={
                                            !hasDeletePermission ||
                                            loadingDel ||
                                            !isNil(value.Message.ForToDoId)
                                        }
                                        onClick={() => {
                                            deleteTemplate(
                                                value.Message.Id.toString()
                                            );
                                            showLoading();
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={styles.detailDesc}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: defaultTo(
                                        value.Message.Body.Template,
                                        ""
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    {value &&
                        value.Message.Files &&
                        value.Message.Files.length > 0 && (
                            <>
                                <div className="nav-item-divider nav-item" />
                                <div className={styles.imageSection}>
                                    <div className={styles.attachmentsSection}>
                                        {value.Message.Files.map((x, index) => (
                                            <MessageDetailFile
                                                key={index}
                                                file={x}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </AppContainer>
            {value.MessageStatuses.length > 0 && (
                <MessageDetailSendReceipt
                    users={value.MessageStatuses}
                    width={defaultTo(width, 0)}
                />
            )}
        </div>
    );
};

export default MessageDetailPartial;
