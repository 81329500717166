import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { EmpKeyTemplate, EmpKeyTemplateField } from "./model";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";

export function validateEmpKeyTemplateField(
    field: EmpKeyTemplateField,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof EmpKeyTemplateField> = {
        AgeGroupFieldUuid: [
            {
                rule: Validations.REQUIRED,
            },
        ],
        // CareScopeFieldUuid: [
        //     {
        //         rule: Validations.REQUIRED,
        //     },
        // ],
        EmpKey: [
            {
                rule: Validations.OPTIONAL_NUMBER,
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 0 },
                message: t("common.errorMessages.positiveNum"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    return state;
}

export function validateEmpKeyTemplate(
    template: EmpKeyTemplate,
    state = getInitializedValidityState([], [])
) {
    const templateValidationRules: ValidationRules<keyof EmpKeyTemplate> = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateName.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateShortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        templateValidationRules,
        template,
        state
    );

    let manager = new ValidityStateManager(state);
    template.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateEmpKeyTemplateField(
                field,
                manager.getFieldState(field.Uuid)?.children
            ),
        });
    });

    return manager.state;
}
