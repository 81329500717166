import classNames from "classnames";
import { AppTableTotal, ClientEmployeeListHeader } from "commonPartials";
import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    Business_Employee_BasicData,
    EmployeeType,
    PermissionAccessTypes,
} from "models";
import { getEmployeeTypePathBase } from "models/employee/helper";
import { BaseObject, UserType } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useBusinessEmployeesContext } from "./BusinessEmployeesContextProvider";
import styles from "./BusinessEmployeesList.module.scss";
import { BusinessEmployeeTable } from "./partials";

export const BusinessEmployeesList: React.FC<BaseObject> = () => {
    const { linkProvider } = useRouting();
    const navigation = useNavigate();
    const { t } = useTranslation();

    const { checkPermission } = useCheckPermission();
    const {
        employees,
        filters,
        onFilterChange,
        onSortChange,
        sort,
        setNavigateUsingCompleteResponse,
        isLoading,
        completeResponse,
        onChangeCompleteResponse,
        onChangeEmployeesList,
    } = useBusinessEmployeesContext();

    const hasCreatePermission = checkPermission(Business_Employee_BasicData, [
        PermissionAccessTypes.CREATE,
    ]);

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t(
                    `employee.title.${getEmployeeTypePathBase(
                        defaultTo(filters.EmployeeType, EmployeeType.Employee)
                    )}`
                )}
                classNameForIcon="pe-7s-user"
            >
                <div className="d-flex align-items-center">
                    <ClientEmployeeListHeader
                        selectedIds={employees
                            ?.filter((x) => x.isChecked)
                            .map((x) => {
                                return defaultTo(x.Id, 0);
                            })
                            .filter((x) => x != 0)}
                        userType={UserType.Employee}
                        employeeType={filters.EmployeeType}
                        hasCreatePermission={hasCreatePermission}
                    />
                    {hasCreatePermission && (
                        <AppRoundedTextIconButton
                            className={classNames(styles.createBtn, "ml-3")}
                            onClick={() =>
                                navigation(
                                    linkProvider.business
                                        .employees(filters.EmployeeType)
                                        .screens.create()
                                )
                            }
                        />
                    )}
                </div>
            </AppContentHeader>
            <div className={styles.content}>
                <BusinessEmployeeTable
                    onEditClick={(encodedId: string) => {
                        setNavigateUsingCompleteResponse(false);
                        navigation(
                            linkProvider.business
                                .employees()
                                .screens.withId(encodedId)
                                .edit()
                        );
                    }}
                    sort={sort}
                    filterValues={filters}
                    onFilterChange={onFilterChange}
                    loading={defaultTo(isNil(employees) || isLoading, true)}
                    value={employees}
                    onSortChange={onSortChange}
                    completeResponse={completeResponse}
                    onChangeCompleteResponse={onChangeCompleteResponse}
                    onChange={onChangeEmployeesList}
                />
                <AppTableTotal
                    text={t(`employee.total${filters.EmployeeType}s`)}
                    count={employees ? employees.length : 0}
                />
            </div>
        </div>
    );
};

export default BusinessEmployeesList;
