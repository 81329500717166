import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import { EmployeeShift, EmployeeShiftType } from "models/employeeShifts";
import React from "react";
import { useTranslation } from "react-i18next";

export interface EmployeeShiftTableRowProps {
    hasDeletePermission: boolean;
    loading?: boolean;
    value: EmployeeShift;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

export const EmployeeShiftTableRow: React.FC<EmployeeShiftTableRowProps> = ({
    hasDeletePermission,
    value,
    loading = false,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();
    const { getDateFormatForLocale } = useLocaleHelpers();

    return (
        <tr
            onClick={() => {
                if (value.EncodedId) {
                    onClick(value.EncodedId);
                } else {
                    onClick(defaultTo(value.Id, 0).toString());
                }
            }}
        >
            <td className={"ellipsis-text single-line"}>
                <span style={{ paddingLeft: "10px" }}>{value.Shift.Name}</span>
            </td>
            <td>{value.StartDate.format(getDateFormatForLocale())}</td>
            <td>
                {value.EndDate
                    ? value.EndDate.format(getDateFormatForLocale())
                    : t("common.endDate.hasNoEndDate")}
            </td>
            <td>
                <BooleanTickCrossIcon
                    value={value.Type === EmployeeShiftType.Exclusion}
                />
            </td>
            <td>
                <BooleanTickCrossIcon
                    value={value.Type === EmployeeShiftType.Preference}
                />
            </td>
            <td style={getFixedCssWidths(60)}>
                <AppDeleteButton
                    getConfirmation={true}
                    readonly={!hasDeletePermission || loading}
                    name={
                        value.Shift
                            ? value.Shift.Name
                            : t("employee.shift.shift.name")
                    }
                    onClick={() => {
                        if (value.EncodedId) {
                            onDeleteBtnClick(value.EncodedId);
                        } else {
                            onDeleteBtnClick(defaultTo(value.Id, 0).toString());
                        }
                    }}
                />
            </td>
        </tr>
    );
};

export default EmployeeShiftTableRow;
