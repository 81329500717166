import {
    EditProfileRequest,
    ForgotPasswordRequest,
    HqRegisterRequestModel,
    LoginRequest,
    LoginResponse,
    MeResponse,
    RegisterRequest,
    ResetPasswordRequest,
    User,
    UserTokenResponse,
} from "models";
import { BusinessPerson } from "models/general/models";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "./BaseService";

export interface IAuthService {
    login: (data: LoginRequest) => Promise<AppResponse<LoginResponse>>;
    logout: () => Promise<AppResponse<boolean>>;
    me: () => Promise<AppResponse<MeResponse>>;
    renewSession: (
        refreshToken: string
    ) => Promise<AppResponse<UserTokenResponse>>;
    hqRegisterRequest: (
        request: HqRegisterRequestModel
    ) => Promise<AppResponse<boolean>>;
    getEmployeeInfoRegister: (
        userId: string
    ) => Promise<AppResponse<BusinessPerson>>;
    getCompleteRegisterInfo: (
        userId: string,
        code: string
    ) => Promise<AppResponse<BusinessPerson>>;
    setEmployeeInfoRegister: (
        request: RegisterRequest
    ) => Promise<AppResponse<boolean>>;
    completeRegister: (
        request: RegisterRequest
    ) => Promise<AppResponse<boolean>>;
    forgotPassword: (
        request: ForgotPasswordRequest
    ) => Promise<AppResponse<boolean>>;
    resetPassword: (
        request: ResetPasswordRequest
    ) => Promise<AppResponse<boolean>>;
    editProfile: (
        editProfile: EditProfileRequest
    ) => Promise<AppResponse<User>>;
    confirmEmail(
        userId: string,
        code: string
    ): Promise<AppResponse<BusinessPerson>>;
}

const apiLinkProvider = routesForContext().noAuth.api;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getAuthServiceKey(name: keyof IAuthService, data?: any) {
    if (!data) {
        return name;
    }
    return `auth_${name}_${JSON.stringify(data)}`;
}

export class AuthService extends BaseService implements IAuthService {
    public authRoutes: ApiLinkProviderType;
    constructor(routes: ApiLinkProviderType) {
        super();
        this.authRoutes = routes;
    }
    login(request: LoginRequest): Promise<AppResponse<LoginResponse>> {
        return this.doServerXHR<AppResponse<LoginResponse>>({
            url: this.authRoutes.login(),
            method: "post",
            data: request,
        });
    }
    me(): Promise<AppResponse<MeResponse>> {
        return this.doServerXHR<AppResponse<MeResponse>>({
            url: this.authRoutes.me(),
            method: "post",
        });
    }
    logout(): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.logout(),
            method: "post",
        });
    }
    renewSession = (
        refreshToken: string
    ): Promise<AppResponse<UserTokenResponse>> => {
        // for some reason this was undefined here in some cases with normal function
        return (this ? this : new BaseService()).doServerXHR<
            AppResponse<UserTokenResponse>
        >({
            url: (this.authRoutes
                ? this.authRoutes
                : new AuthService(routesForContext().noAuth.api).authRoutes
            ).renewSession({ refreshToken: refreshToken }),
            method: "post",
        });
    };
    editProfile(request: EditProfileRequest): Promise<AppResponse<User>> {
        const fD = new FormData();

        if (request.fileRequest) {
            fD.append("file", request.fileRequest);
        }
        fD.append("Data", JSON.stringify(request));
        return this.doServerXHR<AppResponse<User>>({
            url: this.authRoutes.editProfile(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
    hqRegisterRequest(
        request: HqRegisterRequestModel
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.hqRegister(),
            method: "post",
            data: request,
        });
    }

    getCompleteRegisterInfo(
        userId: string,
        code: string
    ): Promise<AppResponse<BusinessPerson>> {
        return this.doServerXHR<AppResponse<BusinessPerson>>({
            url: this.authRoutes.getCompleteRegisterInfo(userId, code),
            method: "get",
        });
    }
    confirmEmail(
        userId: string,
        code: string
    ): Promise<AppResponse<BusinessPerson>> {
        return this.doServerXHR<AppResponse<BusinessPerson>>({
            url: this.authRoutes.confirmEmail(userId, code),
            method: "post",
        });
    }
    getEmployeeInfoRegister(
        userId: string
    ): Promise<AppResponse<BusinessPerson>> {
        return this.doServerXHR<AppResponse<BusinessPerson>>({
            url: this.authRoutes.getEmployeeInfoRegister(userId),
            method: "get",
        });
    }

    setEmployeeInfoRegister(
        request: RegisterRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.employeeInfoRegister(),
            method: "post",
            data: request,
        });
    }

    completeRegister(request: RegisterRequest): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.completeRegister(),
            method: "post",
            data: request,
        });
    }

    forgotPassword(
        request: ForgotPasswordRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.forgotPassword(),
            method: "post",
            data: request,
        });
    }

    resetPassword(
        request: ResetPasswordRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<AppResponse<boolean>>({
            url: this.authRoutes.resetPassword(),
            method: "post",
            data: request,
        });
    }
}

export default AuthService;
