import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { Kindergarten_Client_BankData } from "models";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "commonPartials/commonStyles.module.scss";
import { BankDataTable } from "commonPartials";
import { AppLoader } from "components/Loaders";
import { useActiveKindergartenClient } from "screens/kindergarten/clients/tabs/KindergartenClientTabs";
import { isNil } from "lodash-es";

export const ClientBankDataList: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { dataItem: client } = useActiveKindergartenClient();
    const { getPermissionMap } = useCheckPermission();
    const permMap = getPermissionMap(Kindergarten_Client_BankData);

    const linkProviderBase = linkProvider.kindergarten
        .clients()
        .screens.client.withId().bankData;

    return (
        <AppContainer
            classes={{ body: styles.appContainerWithLessTopPadding }}
            heightToAdjust={215}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                }}
            >
                <AppIconTextButton
                    padding="med"
                    className="mb-2"
                    icon="plus"
                    style={{
                        marginRight: "20px",
                        marginBottom: "0px !important",
                    }}
                    text={t("common.create")}
                    onClick={() => {
                        navigate(linkProviderBase.create());
                    }}
                />
            </div>
            {isNil(client) ? (
                <AppLoader />
            ) : (
                <BankDataTable
                    onClick={(id) => {
                        navigate(linkProviderBase.edit(id));
                    }}
                    permMap={permMap}
                    request={{
                        ClientId: client.Id as number,
                    }}
                />
            )}
        </AppContainer>
    );
};

export default ClientBankDataList;
