import {
    AgeGroupTemplateField,
    CareScopeTemplateField,
    CareScopeTemplateSurchargeField,
    SalaryGroupTemplateField,
} from "models";
import { DateRangeRequest } from "models/general";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "./BaseService";

export interface ILocationBusinessOptionsService {
    getCareScopeOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<CareScopeTemplateField[]>>;
    getSurchargeOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<CareScopeTemplateSurchargeField[]>>;
    getSalaryGroupOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<SalaryGroupTemplateField[]>>;
    getAgeGroupOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<AgeGroupTemplateField[]>>;
}

const apiLinkProvider = routesForContext().api.locationBusinessOptions(0);
type ApiLinkProviderType = typeof apiLinkProvider;

export function getLocationBusinessOptionsServiceKey(
    name: keyof ILocationBusinessOptionsService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `LocationBusinessOptions_${name}_${JSON.stringify(data)}`;
}
export class LocationBusinessOptionsService
    extends BaseService
    implements ILocationBusinessOptionsService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    getCareScopeOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<CareScopeTemplateField[]>> {
        return this.doServerXHR<CareScopeTemplateField[]>({
            url: this.routes.careScopeOptions(request),
            method: "get",
        });
    }
    getSurchargeOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<CareScopeTemplateSurchargeField[]>> {
        return this.doServerXHR<CareScopeTemplateSurchargeField[]>({
            url: this.routes.subChargeOptions(request),
            method: "get",
        });
    }
    getAgeGroupOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<AgeGroupTemplateField[]>> {
        return this.doServerXHR<AgeGroupTemplateField[]>({
            url: this.routes.ageGroupOptions(request),
            method: "get",
        });
    }
    getSalaryGroupOptions(
        request: DateRangeRequest
    ): Promise<AppResponse<SalaryGroupTemplateField[]>> {
        return this.doServerXHR<SalaryGroupTemplateField[]>({
            url: this.routes.salaryGroupOptions(request),
            method: "get",
        });
    }
}

export default LocationBusinessOptionsService;
