import { ImageAssets } from "globals/images";
import { useAuthenticatedPageContext } from "hoc/providers";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash";
import { FeatureHelpItem, HelpItemType } from "models/applicationFeatures";
import { getNumberToHelpItemEnumTypeBinding } from "models/applicationFeatures/helper";
import { Optional } from "models/general";
import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { useQuery } from "react-query";
import {
    BusinessGeneralService,
    getBusinessGeneralServiceKey,
} from "services/business";
import styles from "./FeatureHelpSection.module.scss";
import {
    FeatureHelpItemDetailsDialog,
    FeatureHelpSideBarMenuDialog,
} from "./partials";

interface FeatureHelpSectionState {
    videosList: FeatureHelpItem[];
    explanationList: FeatureHelpItem[];
    helpExist: boolean;
}
const FeatureHelpSection: React.FC = () => {
    const { linkProvider } = useRouting();
    const { pageFeatures } = useAuthenticatedPageContext();
    const { sessionBusinessLocation } = useSessionBusiness();
    const [state, setState] = useState<FeatureHelpSectionState | null>(null);
    const [selectedItem, setSelectedItem] =
        useState<Optional<FeatureHelpItem>>(null);

    const selectedId = useRef(0);

    const onSelectItem = (value: Optional<FeatureHelpItem>) => {
        if (
            selectedId.current == 0 ||
            selectedId.current != defaultTo(value && value.Id, 0)
        ) {
            setSelectedItem(null); // to re-animate
            if (!isNil(value)) {
                setTimeout(() => {
                    selectedId.current = value.Id as number;
                    setSelectedItem(value);
                }, 100);
            } else {
                // menu closes
                selectedId.current = 0;
            }
        }
    };
    const service = new BusinessGeneralService(
        linkProvider.business.api.general
    );
    const {
        isLoading: loading,
        data: response,
        refetch,
    } = useQuery(
        getBusinessGeneralServiceKey("getApplicationFeatures"),
        async () =>
            await service.getApplicationFeatures({
                featureId: defaultTo(pageFeatures, [])[0] as string,
                locationSettingId: defaultTo(sessionBusinessLocation?.Id, 0),
            }),
        { enabled: false }
    );

    useEffect(() => {
        if (pageFeatures && pageFeatures.length > 0) {
            setTimeout(() => {
                // to avoid immediate refetching on redirection
                refetch();
            }, 200);
        }
    }, [pageFeatures, sessionBusinessLocation]);

    useEffect(() => {
        if (!loading && response && response.Data) {
            setState({
                helpExist: response.Data.Flag,
                explanationList: response.Data.FeatureHelpItems.filter(
                    (x) =>
                        getNumberToHelpItemEnumTypeBinding(x.HelpItem.Type) ===
                        HelpItemType.EXPLANATION
                ),
                videosList: response.Data.FeatureHelpItems.filter(
                    (x) =>
                        getNumberToHelpItemEnumTypeBinding(x.HelpItem.Type) ===
                        HelpItemType.VIDEO
                ),
            });
        }
    }, [loading, response]);

    const [open, setOpen] = useState(false);
    return state && state.helpExist ? (
        <>
            <span
                className={`${styles.navIcon}`}
                onClick={() => {
                    setOpen((old) => !old);
                }}
            >
                <img
                    src={ImageAssets.common.questionCircleBlack}
                    alt="help-icon"
                />
            </span>

            {/* @ts-ignore */}
            <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={open}
                animationInDuration={400}
                animationOutDuration={400}
                className={styles.root}
                animateOnMount={false}
            >
                <div className={styles.container}>
                    <FeatureHelpItemDetailsDialog
                        selectedItem={selectedItem as FeatureHelpItem}
                    />
                    <FeatureHelpSideBarMenuDialog
                        onSelectItem={onSelectItem}
                        open={open}
                        listOfExplanation={state.explanationList}
                        listOfVideos={state.videosList}
                        onClose={() => setOpen(false)}
                    />
                </div>
            </Animated>
        </>
    ) : (
        <></>
    );
};

export default FeatureHelpSection;
