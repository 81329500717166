import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import { ValidityStateManager, ValidityState } from "models/general";
import {
    AppInputField,
    AppNumberInput,
    AppSwitch,
} from "components/FormFields";
import { useTranslation } from "react-i18next";
import { DragHandle } from "components/Sortable";
import { AppDeleteButton } from "components/Buttons";
import { CareScopeTemplateField } from "models/careScopeTemplate";
import styles from "./CareScopeTemplateRow.module.scss";
import { FORMAT_INTEGER } from "globals/constants";

export interface CareScopeTemplateFieldRowProps {
    value: CareScopeTemplateField;
    isLocation?: boolean;
    onChange: (field: CareScopeTemplateField) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
}

export const CareScopeTemplateFieldRow: React.FC<
    CareScopeTemplateFieldRowProps
> = ({ value, onChange, validityState, onDelete, readonly = false }) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />

                <Form.Row>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppInputField
                            label={t("templates.name.name")}
                            showEmptyError={true}
                            value={value.Name}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Name: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.name.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Name"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppInputField
                            label={t("templates.shortName.name")}
                            showEmptyError={true}
                            value={value.ShortName}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    ShortName: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.shortName.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "ShortName"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppNumberInput
                            label={t("careScopeTemplate.startTime.name")}
                            showError={true}
                            formatTheInitialValue={true}
                            showEmptyError={true}
                            readOnly={readonly}
                            allowZero={true}
                            format={FORMAT_INTEGER}
                            value={value.CareStartInMinutes}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    CareStartInMinutes: defaultTo(val, 0),
                                })
                            }
                            placeholder={t("careScopeTemplate.startTime.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "CareStartInMinutes"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <AppNumberInput
                            label={t("careScopeTemplate.endTime.name")}
                            showError={true}
                            formatTheInitialValue={true}
                            showEmptyError={true}
                            allowZero={true}
                            readOnly={readonly}
                            format={FORMAT_INTEGER}
                            value={value.CareEndInMinutes}
                            onChange={(val) =>
                                onChange({
                                    ...value,
                                    CareEndInMinutes: defaultTo(val, 0),
                                })
                            }
                            placeholder={t("careScopeTemplate.endTime.name")}
                            error={validityStateManager.getFirstErrorInfo(
                                "CareEndInMinutes"
                            )}
                        />
                    </Col>
                    <Col xs={12} md={8} lg={3} xl={4}>
                        <AppInputField
                            label={t("templates.description")}
                            showEmptyError={true}
                            value={value.Description}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Description: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("templates.description")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Description"
                            )}
                        />
                    </Col>
                </Form.Row>
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default CareScopeTemplateFieldRow;
