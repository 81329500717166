import { AppQrCodeType, AppResponse, QRCodeResponse } from "models/general";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface IAppQRCodeService {
    refreshCode: (
        forArea: AppQrCodeType,
        targetEntityId: number,
        id: number
    ) => Promise<AppResponse<QRCodeResponse>>;
    sendEmail(codeId: number): Promise<AppResponse<boolean>>;
    generatePdf(codeId: number): Promise<AppResponse<string>>;
}

const apiLinkProvider =
    routesForContext().business.api.currentBusiness().qrCodes;
type ApiLinkProviderType = typeof apiLinkProvider;

export class AppQRCodeService extends BaseService implements IAppQRCodeService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }
    refreshCode(
        forArea: AppQrCodeType,
        targetEntityId: number,
        id: number
    ): Promise<AppResponse<QRCodeResponse>> {
        const formData = new FormData();
        formData.append("forArea", (forArea as number).toString());
        formData.append("targetEntityId", targetEntityId.toString());
        formData.append("id", id.toString());

        return this.doServerXHR<QRCodeResponse>({
            url: this.routes.refreshCode(),
            method: "post",
            data: formData,
        });
    }

    sendEmail(codeId: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.routes.sendEmail(codeId),
            method: "post",
        });
    }

    generatePdf(codeId: number): Promise<AppResponse<string>> {
        return this.doServerXHR<boolean>({
            url: this.routes.generatePdf(codeId),
            method: "post",
        });
    }
}
