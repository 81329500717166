import { ISO8601_DATE_FORMAT } from "globals/constants";
import { BusinessContractData, BusinessContractsListResponse } from "models";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService, headerToIgnoreContractExpire } from "../BaseService";

const apiLinkProvider =
    routesForContext().business.api.currentBusiness().contracts;

type ApiLinkProviderType = typeof apiLinkProvider;

export interface IBusinessContractService {
    cancelContract: () => Promise<AppResponse<string>>;
    renewContract(request: BusinessContractData): Promise<AppResponse<boolean>>;
    copyContractModules(
        id: string,
        limit?: number
    ): Promise<AppResponse<boolean>>;
    updateAdjustedPrice(
        id: string,
        price: number
    ): Promise<AppResponse<number>>;
    redeemVoucher(code: string): Promise<AppResponse<boolean>>;
    getContractsDetail(): Promise<AppResponse<BusinessContractsListResponse>>;
}

export function getBusinessContractServiceKey(
    name: keyof IBusinessContractService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `businessContract_${name}_${JSON.stringify(data)}`;
}

export class BusinessContractService
    extends BaseService
    implements IBusinessContractService
{
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }

    getContractsDetail(): Promise<AppResponse<BusinessContractsListResponse>> {
        return this.doServerXHR<BusinessContractsListResponse>({
            url: this.baseRoute.list(),
            method: "get",
            ...headerToIgnoreContractExpire,
        });
    }
    copyContractModules(
        id: string,
        limit?: number
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.copyContractModules(id, limit),
            method: "post",
        });
    }
    updateAdjustedPrice(
        id: string,
        price?: number
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.updateAdjustedPrice(id, price),
            method: "post",
        });
    }
    redeemVoucher(code: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.redeemVoucher(code),
            method: "post",
        });
    }
    cancelContract(): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.baseRoute.cancelContract(),
            method: "post",
            ...headerToIgnoreContractExpire,
        });
    }
    renewContract(
        request: BusinessContractData
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.renewContract(),
            method: "post",
            data: {
                ...request,
                StartDate: request.StartDate.format(ISO8601_DATE_FORMAT),
                EndDate: request.EndDate
                    ? request.EndDate.format(ISO8601_DATE_FORMAT)
                    : null,
            },
            ...headerToIgnoreContractExpire,
        });
    }
}
