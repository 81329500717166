import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    EmployeeContract,
    EmployeeContractResponse,
} from "models/employeeContract";
import { AppResponse } from "models/general";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface IBusinessEmployeeContractService {
    list(): Promise<AppResponse<EmployeeContractResponse[]>>;
    create(
        model: EmployeeContract
    ): Promise<AppResponse<EmployeeContractResponse>>;
    update(
        model: EmployeeContract
    ): Promise<AppResponse<EmployeeContractResponse>>;
    get(id: string): Promise<AppResponse<EmployeeContractResponse>>;
    delete(id: number): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext()
    .business.employees()
    .api.withId().employeeContract;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessEmployeeContractServiceKey(
    name: keyof IBusinessEmployeeContractService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `EmployeeContract_${name}_${JSON.stringify(data)}`;
}
export class BusinessEmployeeContractService
    extends BaseService
    implements IBusinessEmployeeContractService
{
    public ContractRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.ContractRoutes = apiLinkProvider;
    }

    list(): Promise<AppResponse<EmployeeContractResponse[]>> {
        return this.doServerXHR<AppResponse<EmployeeContractResponse[]>>({
            url: this.ContractRoutes.list(),
            method: "get",
        });
    }
    constructFormData(model: EmployeeContract) {
        const ob = {
            ...model,
            StartDate: model.ContractStart?.format(ISO8601_DATE_FORMAT),
            EndDate: model.ContractEnd
                ? model.ContractEnd.format(ISO8601_DATE_FORMAT)
                : undefined,
            EndOfTrialPeriod:
                model.EndOfTrialPeriod?.format(ISO8601_DATE_FORMAT),
            ExpirationDate: model.ExpirationDate?.format(ISO8601_DATE_FORMAT),
        };
        // const fd = new FormData();
        // fd.append("Data", JSON.stringify(ob));
        return ob;
    }
    create(
        model: EmployeeContract
    ): Promise<AppResponse<EmployeeContractResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<AppResponse<EmployeeContractResponse>>({
            url: this.ContractRoutes.create(),
            method: "post",
            data: fD,
        });
    }
    update(
        model: EmployeeContract
    ): Promise<AppResponse<EmployeeContractResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<AppResponse<EmployeeContractResponse>>({
            url: this.ContractRoutes.update(),
            method: "post",
            data: fD,
        });
    }
    get(id: string): Promise<AppResponse<EmployeeContractResponse>> {
        return this.doServerXHR<AppResponse<EmployeeContractResponse>>({
            url: this.ContractRoutes.get(id),
            method: "get",
        });
    }
    delete(id: number): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.ContractRoutes.delete(id),
            method: "post",
        });
    }
}

export default BusinessEmployeeContractService;
