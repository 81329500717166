export const LocationSettingEnTranslations = {
    locationSettings: {
        columns: {
            branch: "Branch",
            city: "City",
            country: "Country",
            name: "Name",
            state: "State",
            zipCode: "ZIP Code",
        },
        createTitle: "Create",
        deleteError: "Unable to delete location-setting.",
        modulePlan: {
            addFeature: "Add Feature",
            editPackage: "Edit Package",
            selectPackage: "Select Package",
            packageSelected: "Package Selected",
            selected: "Selected",
            module: {
                basic: "Basic",
                mobileapps: "Mobile Apps",
                accesscontrol: "Access Control",
                timetracking: "Time Tracking",
                documentmanager: "Document Manager",
            },
            contentDialog: {
                description: "Description",
                features: {
                    name: "Features",
                    invalid: "Entered feature text is not valid.",
                },
                title: { name: "Title", missing: "Title is required." },
            },
            createNewPackage: "Create New Package",
            enterLocaleContent: "Please enter locale content.",
            isDefault: "Is Default",
            isIncluded: "Is Included",
            useDefault: "Use Default",
            plan: "Plan",
            contentMissing: "Content Missing",
        },
        tabs: {
            affiliate: "Affiliate",
            basicData: "Details",
            companyData: "Company Data",
            documentTemplate: "Document Templates",
            emailTemplates: "Email Templates",
            explanationMode: "Explanation Mode",
            employeeKeyCalculations: "Employee Key Calculations",
            careScopes: "Scope of Care",
            ageGroups: "Age Groups",
            salaryGroups: "Salary Groups",
            functions: "Functions",
            letterheads: "Letterheads",
            mealGroups: "Meal Groups",
            packages: "Packages",
            publicHolidays: "Public Holidays",
            settings: "Settings",
            compensationTemplates: "Compensation Templates",
            voucher: "Voucher",
            workingAreas: "Working Areas",
            workingDays: "Working Days",
        },
        title: "Location Settings",
    },
};

export default LocationSettingEnTranslations;
