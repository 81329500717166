import classNames from "classnames";
import { AppOverlayLoader } from "components/Loaders";
import { useCollapsibleHelper } from "hooks/general/appHelpers";
import { defaultTo } from "lodash";
import { Optional } from "models/general";
import React, { forwardRef, MutableRefObject, Ref, useRef } from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { AppCollapseToggleButtonProps } from "../../Buttons";
import styles from "./AppContainer.module.scss";
import AppContainerHeader from "./AppContainerHeader";

export interface AppContainerWithHeaderProps {
    isCollapsible?: boolean;
    initiallyOpen?: boolean;
    classes?: AppContainerWithHeaderClasses;
    title?: string | React.ReactNode;
    children: React.ReactNode;
    actionMenu?: React.ReactNode;
    showHeader?: boolean;
    titleLoading?: boolean;
    showTitleSkeleton?: boolean;
    onBack?: () => void;
    footer?: React.ReactNode;
    headerRightContent?: React.ReactNode;
    isTabContainer?: boolean;
    collapseToggleButtonProps?: AppCollapseToggleButtonProps;
    loadingValue?: boolean;
    ellipseTitle?: boolean;
    useMaxHeight?: boolean;
    minHeight?: number;
    hasInnerScroll?: boolean;
    heightToAdjust?: number;
    mobileViewAdjustment?: number;
    mediumViewAdjustment?: number;
    contentBodyHeightAdjustment?: number;
    containerBodyRef?: MutableRefObject<HTMLDivElement | null>;
}
export interface AppContainerWithHeaderClasses {
    root?: string;
    header?: string;
    actionMenu?: string;
    title?: string | React.ReactNode;
    body?: string;
    bodyParent?: string;
    footer?: string;
}
function Component(
    {
        children,
        title,
        actionMenu,
        classes,
        isCollapsible = false,
        showHeader = false,
        collapseToggleButtonProps,
        headerRightContent,
        initiallyOpen,
        isTabContainer = false,
        titleLoading = false,
        showTitleSkeleton = false,
        loadingValue,
        footer,
        containerBodyRef,
        onBack,
        ellipseTitle = true,
        useMaxHeight = true,
        hasInnerScroll = false,
        contentBodyHeightAdjustment = 75,
        heightToAdjust = 244, // height to subtract from vh to add scroll
        mobileViewAdjustment = 15, // additional height to subtract for mobile
        mediumViewAdjustment = 15, // additional height to subtract for medium 450<s<860 (table width not screen width)
        minHeight,
    }: AppContainerWithHeaderProps,
    ref: Ref<any>
) {
    const { width, ref: resizeRef } = useResizeDetector();
    const collapseContainerRef = useRef<Optional<any>>(undefined);

    const { collapse, toggleCollapse, initialStyle } = useCollapsibleHelper(
        collapseContainerRef,
        initiallyOpen
    );

    const body = (
        <div
            ref={(newRef) => {
                collapseContainerRef.current = newRef;
                if (containerBodyRef) {
                    containerBodyRef.current = newRef;
                }
            }}
            className={classNames(styles.body, defaultTo(classes?.body, ""))}
            style={{
                ...(isCollapsible ? initialStyle.current : {}),
            }}
        >
            {children}
            {footer && <div className={styles.footer}>{footer}</div>}
        </div>
    );

    const heightToAdjustCalculated =
        defaultTo(width, 860) >= 860
            ? heightToAdjust
            : defaultTo(width, 450) > 450
            ? heightToAdjust + mediumViewAdjustment
            : heightToAdjust + mobileViewAdjustment;

    return (
        <div
            ref={resizeRef}
            className={classNames(
                styles.root,
                defaultTo(classes?.root, ""),
                {
                    [styles.tabStyling]: isTabContainer,
                },
                {
                    [styles.overflowHidden]: loadingValue,
                }
            )}
            style={{
                ...{
                    borderBottom:
                        isCollapsible && !collapse ? "0px" : undefined,
                },
                ...(useMaxHeight
                    ? {
                          maxHeight: `calc(100vh - ${heightToAdjustCalculated}px)`,
                      }
                    : undefined),
                ...(minHeight
                    ? {
                          minHeight: `${minHeight}px !important`,
                      }
                    : undefined),
            }}
        >
            {loadingValue && <AppOverlayLoader />}
            {showHeader && (
                <AppContainerHeader
                    isCollapsible={isCollapsible}
                    classes={classes}
                    titleLoading={titleLoading}
                    showTitleSkeleton={showTitleSkeleton}
                    title={title}
                    ellipseTitle={ellipseTitle}
                    onBack={onBack}
                    actionMenu={actionMenu}
                    rightContent={headerRightContent}
                    collapseToggleButtonProps={collapseToggleButtonProps}
                    collapseState={collapse}
                    toggleCollapse={toggleCollapse}
                />
            )}
            <div
                ref={ref}
                className={classNames(
                    { [styles.innerScroll]: hasInnerScroll },
                    defaultTo(classes?.bodyParent, "")
                )}
                style={
                    hasInnerScroll
                        ? {
                              maxHeight: `calc(100vh - ${
                                  heightToAdjustCalculated +
                                  contentBodyHeightAdjustment
                              }px)`,
                          }
                        : undefined
                }
            >
                {body}
            </div>
        </div>
    );
}

export const AppContainer = forwardRef(Component);
export default AppContainer;
