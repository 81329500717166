import { AppNotificationType } from "models/notification";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./NotificationListEnd.module.scss";

export interface NotificationListEndProps {
    totalItemCount: number;
    type: AppNotificationType;
}

export const NotificationListEnd: React.FC<NotificationListEndProps> = ({
    totalItemCount,
    type,
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <h5>
                {t(
                    `notificationManagement.${
                        totalItemCount && totalItemCount > 0
                            ? type === "notifications"
                                ? "noMoreNotifications"
                                : "noMoreDownloads"
                            : type === "notifications"
                            ? "notificationListEmpty"
                            : "downloadListEmpty"
                    }`
                )}
            </h5>
        </div>
    );
};

export default NotificationListEnd;
