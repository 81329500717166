import { AppContentHeader } from "components";
import { AppButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditProfileFields } from "commonPartials";
import { ImageAssets } from "globals/images";
import { useEditProfile } from "hooks/layout/useEditProfile";
import { getInitializedValidityState } from "models/general";
import { useCheckPermission } from "hooks/permissionCheck";
import { GeneralUserPasswordChange, GeneralUserProfileUpdate } from "models";

export const EditProfile = () => {
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const {
        updateProfile,
        validityState,
        setValidityState,
        state,
        setState,
        loading,
    } = useEditProfile();

    const { getPermissionMap } = useCheckPermission();

    const permMap = getPermissionMap(GeneralUserProfileUpdate);
    const passwordPermMap = getPermissionMap(GeneralUserPasswordChange);
    return (
        <>
            <AppContentHeader
                title={t("userProfile.editProfile")}
                icon={ImageAssets.common.userCheckBlue}
            />
            <AppContainer
                footer={
                    <div>
                        <AppButton
                            onClick={() => {
                                setValidityState(
                                    getInitializedValidityState([], [])
                                ); // to reset file type errors
                                updateProfile(state);
                            }}
                            disabled={!isValid || loading || !permMap.EDIT}
                        >
                            {t("common.saveChanges")}
                        </AppButton>
                    </div>
                }
            >
                <EditProfileFields
                    state={state}
                    validityState={validityState}
                    setState={setState}
                    canChangePassword={passwordPermMap.EDIT}
                    setIsValid={setIsValid}
                />
            </AppContainer>
        </>
    );
};

export default EditProfile;
