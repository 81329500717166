import {
    AgeGroupTemplateCreateEdit,
    SalaryGroupTemplateCreateEdit,
} from "commonPartials";
import {
    CLONE_PARAM_KEY,
    FOLLOWUP_PARAM_KEY,
    TEMPLATE_ID_PARAM_KEY,
} from "globals/queryParams";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { LocationSettings_SalaryGroups } from "models";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useCurrentLocationSettingsContext } from "../LocationSettingTabs";

export const LocationSalaryGroupTemplateCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const { getPermissionMap } = useCheckPermission();
    const [searchParams] = useSearchParams();

    const parentId = searchParams.get(TEMPLATE_ID_PARAM_KEY);
    const isClone = searchParams.get(CLONE_PARAM_KEY) == "true";
    const isFollowup = searchParams.get(FOLLOWUP_PARAM_KEY) == "true";

    const currentLocation = useCurrentLocationSettingsContext();

    const permMap = getPermissionMap([LocationSettings_SalaryGroups]);

    const linkProviderBase = linkProvider.lillywait
        .locationSettings()
        .screens.withId().salaryGroups;

    return (
        <SalaryGroupTemplateCreateEdit
            id={parentId}
            currentBusiness={null}
            locationId={currentLocation.dataItem?.Id}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
            createOptions={{ clone: isClone, followup: isFollowup }}
        />
    );
};

export default LocationSalaryGroupTemplateCreate;
