import classNames from "classnames";
import { AppButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import { AnimatedAppHeaderContainer } from "layouts/appLayout/partials/header/partials";
import { AppNotificationType, NotificationModel } from "models/notification";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./NotificationHeaderIcon.module.scss";
import NotificationsIconListItem from "./NotificationsIconListItem";

export interface NotificationsIconProps {
    value: NotificationModel[];
    onChange: (updated: NotificationModel) => void; // can't return complete array here because only partial array is sent to component
    type: AppNotificationType;
    openedMenu?: AppNotificationType;
    setOpenedMenu?: (menu?: AppNotificationType) => void;
}

export const NotificationHeaderIcon: React.FC<NotificationsIconProps> = ({
    value,
    onChange,
    type,
    setOpenedMenu,
    openedMenu,
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [inTransition, setInTransition] = useState(false);
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const triggerRef = useRef<HTMLElement>();
    const openMenuRef = useRef<AppNotificationType | undefined>(openedMenu);

    useEffect(() => {
        openMenuRef.current = openedMenu;
        setTimeout(() => {
            // in case 2nd menu is opened, then type will be different so close current menu
            if (openMenuRef.current != type) {
                setOpenDialog(false);
            }
        }, 500);
    }, [openedMenu]);

    const triggerCallback = useCallback(
        (e) => {
            setOpenDialog(true);
            if (inTransition && setOpenedMenu) {
                // already in transition, set on mouseOut, again set openedMenu as menu should not be closed
                setOpenedMenu(type);

                setInTransition(false);
            }
            if (e.type == "mouseenter") {
                // when mouse is moved from menu to triggerIcon, mouseEnter is not triggered
                if (!inTransition) {
                    setInTransition(true);
                }
            }
        },
        [inTransition]
    );

    return (
        <div
            className={styles.root}
            ref={triggerRef as any}
            onMouseEnter={triggerCallback}
            onMouseOver={triggerCallback}
            onMouseLeave={() => {
                setTimeout(() => {
                    if (
                        (inTransition && openMenuRef.current == undefined) ||
                        !inTransition
                    ) {
                        // on main container hover, openedMenu is set, if after 0.5sec it is not set then mouse is left, so close menu
                        setOpenDialog(false);
                    }
                    setInTransition(false);
                }, 200);
            }}
            onTouchEnd={(e) => {
                // to handle touch events
                if (setOpenedMenu) {
                    setOpenedMenu(type);
                }
                setOpenDialog((x) => !x);
                e.preventDefault();
            }}
        >
            <span className={styles.navIcon}>
                {value.filter((x) => x.IsRead == false).length > 0 && (
                    <span className={`${styles.dotWrapper}`}>
                        <span></span>
                    </span>
                )}
                <Image
                    src={
                        type == "downloads"
                            ? ImageAssets.common.downloadArrowBlack
                            : ImageAssets.common.bell
                    }
                    alt={`${type}_toggle_Icon`}
                    className={styles.navIconImg}
                />
            </span>
            <AnimatedAppHeaderContainer
                open={openDialog}
                setOpen={(val, useHoverLogic = true) => {
                    if (!useHoverLogic) {
                        // to handle touch click, just toggle menu
                        setOpenDialog(val);
                        if (setOpenedMenu) {
                            setOpenedMenu(val ? type : undefined);
                        }
                    } else {
                        if (setOpenedMenu) {
                            if (!val) {
                                setOpenedMenu(undefined);
                            } else {
                                setOpenedMenu(type);
                            }
                        }

                        if (!val) {
                            // on close set transition to true, for checking in triggerCallback
                            setInTransition(true);
                        } else {
                            setInTransition(false);
                        }

                        setTimeout(() => {
                            // if withing 0.5sec, mouse is move to trigger icon it should not be closed
                            if (openMenuRef.current == undefined) {
                                setOpenDialog(false);
                            } else {
                                setOpenDialog(true);
                            }
                            setInTransition(false);
                        }, 200);
                    }
                }}
                triggerRef={triggerRef as any}
                positionTop={20}
                triggerOnClick={false}
                positionRight={0}
            >
                <div className={classNames(styles.containerMain, `shadow`)}>
                    {value && value.length > 0 ? (
                        value.map((item, idx) => {
                            return (
                                <NotificationsIconListItem
                                    type={type}
                                    value={item}
                                    onChange={(val: NotificationModel) => {
                                        onChange(val);
                                    }}
                                    onCloseDialog={() => {
                                        if (triggerRef.current) {
                                            triggerRef.current.click();
                                        }
                                    }}
                                    key={idx}
                                    showHr={value.length != idx + 1}
                                />
                            );
                        })
                    ) : (
                        <h6 className={styles.noNotifications}>
                            {t(
                                `notificationManagement.${
                                    type === "notifications"
                                        ? "noNewNotifications"
                                        : "noNewDownloads"
                                }`
                            )}
                        </h6>
                    )}
                    <div className={styles.btnDiv}>
                        <Link
                            to={
                                type == "notifications"
                                    ? linkProvider.business.screens.notifications()
                                    : linkProvider.business.screens.downloads()
                            }
                            onClick={() => setOpenDialog(false)}
                        >
                            <AppButton padding={"med"}>
                                {t(
                                    `notificationManagement.${
                                        type === "notifications"
                                            ? "allNotifications"
                                            : "allDownloads"
                                    }`
                                )}
                            </AppButton>
                        </Link>
                    </div>
                </div>
            </AnimatedAppHeaderContainer>
        </div>
    );
};

export default NotificationHeaderIcon;
