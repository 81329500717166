import { useSessionUser } from "hooks/general/appContextHelpers";
import { useOnLogoutUser } from "hooks/general/authHelpers";
import { useRouting } from "hooks/general/routing";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { AuthService, getAuthServiceKey } from "services";
import styles from "./Logout.module.scss";

export const Logout = () => {
    const { isAuthenticated } = useSessionUser();
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const onUserLogout = useOnLogoutUser();
    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isLoading,
        data,
        mutate: logoutUser,
    } = useMutation(
        getAuthServiceKey("logout"),
        async () => await authService.logout()
    );

    useEffect(() => {
        if (!isAuthenticated || (!isLoading && data)) {
            onUserLogout(!isAuthenticated);
        }
    }, [isAuthenticated, data]);
    return (
        <Button
            variant="dark"
            className={styles.logoutButton}
            onClick={() => {
                logoutUser();
            }}
        >
            {t("header.logout")}
        </Button>
    );
};

export default Logout;
