import {
    Business_Archives,
    Business_BasicData,
    Business_Calendar,
    Business_Chat,
    Business_Dashboard_Client,
    Business_Dashboard_Employee,
    Business_DocumentManager_Letterhead,
    Business_DocumentManager_Template,
    Business_Downloads,
    Business_EmailTemplate,
    Business_MailboxMessages,
    Business_ModuleSelect,
    Business_Payroll_Absences,
    Business_Payroll_AdditionalBenefits,
    Business_RoleManagement_PermissionGroups_Ipad,
    Business_RoleManagement_PermissionGroups_Mobile,
    Business_RoleManagement_PermissionGroups_Web,
    Business_RoleManagement_UserRoles_Mobile,
    Business_RoleManagement_UserRoles_Web,
    Business_TimeTracking_Clients,
    Business_TimeTracking_Employees,
    Business_TimeTracking_Suppliers,
    Business_ToDo,
    Business_ShiftPlan,
    Business_WebNotification,
    ModulePlans,
    PermissionAccessTypes,
    Business_Creditor_BasicData,
    Business_Creditor_BankData,
} from "models";
import {
    Feature_Business_Branches,
    Feature_Business_Calendar,
    Feature_Business_Chat,
    Feature_Business_Dashboard,
    Feature_Business_DocumentLetterheads,
    Feature_Business_DocumentTemplates,
    Feature_Business_Document_Archive,
    Feature_Business_Downloads,
    Feature_Business_EmailTemplates,
    Feature_Business_Messages,
    Feature_Business_ModuleSelect,
    Feature_Business_Payroll_Absences,
    Feature_Business_Payroll_AdditionalBenefits,
    Feature_Business_Role_Management,
    Feature_Business_Time_Tracking_Employee,
    Feature_Business_Time_Tracking_Supplier,
    Feature_Business_ToDo,
    Feature_Business_User_Access_Management,
    Feature_Business_ShiftPlan,
    Feature_Business_WebNotification,
    Feature_Kindergarten_Time_Tracking_Client,
    Feature_Business_Creditor,
    Feature_Business_Creditor_BankData,
    Feature_Business_Creditor_AddUpdate,
    Feature_Business_Creditor_BankData_AddUpdate,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import {
    EmployeeAdditionalBenefits,
    AbsentsReport,
    BusinessCreate,
    BusinessHQAndBranches,
    Dashboard,
    DocumentArchiveManagement,
    DocumentLetterhead,
    DocumentTemplateManager,
    MessagesCreate,
    MessagesDetail,
    MessagesLayout,
    MessagesList,
    NotificationsList,
    PermissionEdit,
    RenewBusinessContract,
    RoleManagement,
    TodoManagementCreateEdit,
    TodoManagementTable,
    ShiftPlanMain,
    CreditorsList,
    CreditorsCreate,
    CreditorsEditLayout,
} from "screens/business";
import { Calendar } from "screens/business/calendar";
import { Chat } from "screens/business/chat";
import { DocumentTemplateCreateAndEditIframe } from "screens/business/documentTemplate";
import { BusinessPlanSelect } from "screens/business";
import {
    TimeManagementClient,
    TimeManagementEmployee,
    TimeManagementSupplier,
} from "screens/business/timeTracking";
import { UserManagement } from "screens/business/userManagement";
import { EmailTemplate } from "screens/kindergarten";
import { businessEmployeeRoutesMap } from "./employee/routesMap";
import businessRoutes from "./routes";
import Dummy from "screens/Dummy";
import CreditorsEdit from "screens/business/creditors/CreditorsEdit";
import {
    CreditorBankDataCreate,
    CreditorBankDataEdit,
    CreditorBankDataList,
} from "screens/business/creditors/tabs";

const fullRoutes = businessRoutes();
const relativeRoutes = businessRoutes(false);

export const businessRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.screens.dashboard()]: {
        component: Dashboard,
        index: true,
        fullPattern: fullRoutes.screens.dashboard(),
        pattern: relativeRoutes.screens.dashboard(),
        permKeys: [Business_Dashboard_Client, Business_Dashboard_Employee],
        featureKeys: [Feature_Business_Dashboard],
    },
    [relativeRoutes.screens.contractExpired()]: {
        component: RenewBusinessContract,
        fullPattern: fullRoutes.screens.contractExpired(),
        pattern: relativeRoutes.screens.contractExpired(),
        permKeys: [Business_BasicData],
        permAccessTypes: [PermissionAccessTypes.EDIT],
    },
    ...businessEmployeeRoutesMap,
    [relativeRoutes.screens.chat()]: {
        component: Chat,
        fullPattern: fullRoutes.screens.chat(),
        pattern: relativeRoutes.screens.chat(),
        permKeys: [Business_Chat],
        requiredModule: [ModulePlans.MobileApps],
        featureKeys: [Feature_Business_Chat],
    },
    [relativeRoutes.screens.calendar()]: {
        component: Calendar,
        fullPattern: fullRoutes.screens.calendar(),
        pattern: relativeRoutes.screens.calendar(),
        permKeys: [Business_Calendar],
        requiredModule: [ModulePlans.MobileApps],
        featureKeys: [Feature_Business_Calendar],
    },
    [relativeRoutes.screens.todoManagement.list()]: {
        component: TodoManagementTable,
        fullPattern: fullRoutes.screens.todoManagement.list(),
        pattern: relativeRoutes.screens.todoManagement.list(),
        permKeys: [Business_ToDo],
        featureKeys: [Feature_Business_ToDo],
    },
    [relativeRoutes.screens.todoManagement.create()]: {
        component: TodoManagementCreateEdit,
        fullPattern: fullRoutes.screens.todoManagement.create(),
        pattern: relativeRoutes.screens.todoManagement.create(),
        permKeys: [Business_ToDo],
        featureKeys: [Feature_Business_ToDo],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.screens.todoManagement.edit()]: {
        component: TodoManagementCreateEdit,
        fullPattern: fullRoutes.screens.todoManagement.edit(),
        pattern: relativeRoutes.screens.todoManagement.edit(),
        permKeys: [Business_ToDo],
        featureKeys: [Feature_Business_ToDo],
    },
    [relativeRoutes.screens.notifications()]: {
        component: NotificationsList,
        fullPattern: fullRoutes.screens.notifications(),
        pattern: relativeRoutes.screens.notifications(),
        permKeys: [Business_WebNotification],
        featureKeys: [Feature_Business_WebNotification],
    },
    [relativeRoutes.screens.downloads()]: {
        component: NotificationsList,
        fullPattern: fullRoutes.screens.downloads(),
        pattern: relativeRoutes.screens.downloads(),
        permKeys: [Business_Downloads],
        featureKeys: [Feature_Business_Downloads],
    },
    [relativeRoutes.screens.mailbox.list()]: {
        component: MessagesLayout,
        fullPattern: fullRoutes.screens.mailbox.list(),
        pattern: relativeRoutes.screens.mailbox.list(),
        permKeys: [Business_MailboxMessages],
        requiredModule: [ModulePlans.MobileApps],
        featureKeys: [Feature_Business_Messages],
        nestedScreens: {
            [relativeRoutes.screens.mailbox.list()]: {
                index: true,
                component: MessagesList,
                fullPattern: fullRoutes.screens.mailbox.list(),
                permKeys: [Business_MailboxMessages],
                featureKeys: [Feature_Business_Messages],
            },
            [relativeRoutes.screens.mailbox.create()]: {
                component: MessagesCreate,
                fullPattern: fullRoutes.screens.mailbox.create(),
                pattern: relativeRoutes.screens.mailbox.create(),
                permKeys: [Business_MailboxMessages],
                featureKeys: [Feature_Business_Messages],
                permAccessTypes: [PermissionAccessTypes.CREATE],
            },
            [relativeRoutes.screens.mailbox.edit()]: {
                component: MessagesDetail,
                fullPattern: fullRoutes.screens.mailbox.edit(),
                pattern: relativeRoutes.screens.mailbox.edit(),
                permKeys: [Business_MailboxMessages],
                featureKeys: [Feature_Business_Messages],
                permAccessTypes: [
                    PermissionAccessTypes.SHOW,
                    PermissionAccessTypes.EDIT,
                ],
            },
        },
    },
    [relativeRoutes.screens.payroll.absences()]: {
        component: AbsentsReport,
        fullPattern: fullRoutes.screens.payroll.absences(),
        pattern: relativeRoutes.screens.payroll.absences(),
        permKeys: [Business_Payroll_Absences],
        featureKeys: [Feature_Business_Payroll_Absences],
    },
    [relativeRoutes.screens.payroll.additionalBenefits()]: {
        component: EmployeeAdditionalBenefits,
        fullPattern: fullRoutes.screens.payroll.additionalBenefits(),
        pattern: relativeRoutes.screens.payroll.additionalBenefits(),
        permKeys: [Business_Payroll_AdditionalBenefits],
        featureKeys: [Feature_Business_Payroll_AdditionalBenefits],
    },
    [relativeRoutes.screens.shiftPlan.main()]: {
        component: ShiftPlanMain,
        fullPattern: fullRoutes.screens.shiftPlan.main(),
        pattern: relativeRoutes.screens.shiftPlan.main(),
        permKeys: [Business_ShiftPlan],
        featureKeys: [Feature_Business_ShiftPlan],
    },
    [relativeRoutes.screens.shiftPlan.shifts()]: {
        component: ShiftPlanMain,
        fullPattern: fullRoutes.screens.shiftPlan.shifts(),
        pattern: relativeRoutes.screens.shiftPlan.shifts(),
        permKeys: [Business_ShiftPlan],
        featureKeys: [Feature_Business_ShiftPlan],
    },
    [relativeRoutes.screens.shiftPlan.groups()]: {
        component: ShiftPlanMain,
        fullPattern: fullRoutes.screens.shiftPlan.groups(),
        pattern: relativeRoutes.screens.shiftPlan.groups(),
        permKeys: [Business_ShiftPlan],
        featureKeys: [Feature_Business_ShiftPlan],
    },
    [relativeRoutes.screens.admin.selectModules()]: {
        component: BusinessPlanSelect,
        fullPattern: fullRoutes.screens.admin.selectModules(),
        pattern: relativeRoutes.screens.admin.selectModules(),
        permKeys: [Business_ModuleSelect],
        featureKeys: [Feature_Business_ModuleSelect],
    },
    [relativeRoutes.screens.timeTracking.clientView()]: {
        component: TimeManagementClient,
        fullPattern: fullRoutes.screens.timeTracking.clientView(),
        pattern: relativeRoutes.screens.timeTracking.clientView(),
        permKeys: [Business_TimeTracking_Clients],
        requiredModule: [ModulePlans.TimeTracking],
        featureKeys: [Feature_Kindergarten_Time_Tracking_Client],
    },
    [relativeRoutes.screens.timeTracking.employeeView()]: {
        component: TimeManagementEmployee,
        fullPattern: fullRoutes.screens.timeTracking.employeeView(),
        pattern: relativeRoutes.screens.timeTracking.employeeView(),
        permKeys: [Business_TimeTracking_Employees],
        requiredModule: [ModulePlans.TimeTracking],
        featureKeys: [Feature_Business_Time_Tracking_Employee],
    },
    [relativeRoutes.screens.timeTracking.supplierView()]: {
        component: TimeManagementSupplier,
        fullPattern: fullRoutes.screens.timeTracking.supplierView(),
        pattern: relativeRoutes.screens.timeTracking.supplierView(),
        permKeys: [Business_TimeTracking_Suppliers],
        requiredModule: [ModulePlans.TimeTracking],
        featureKeys: [Feature_Business_Time_Tracking_Supplier],
    },
    [relativeRoutes.screens.admin.companies()]: {
        component: BusinessHQAndBranches,
        fullPattern: fullRoutes.screens.admin.companies(),
        pattern: relativeRoutes.screens.admin.companies(),
        permKeys: [Business_BasicData],
        featureKeys: [Feature_Business_Branches],
    },
    [relativeRoutes.screens.admin.branchCreate()]: {
        component: BusinessCreate,
        fullPattern: fullRoutes.screens.admin.branchCreate(),
        pattern: relativeRoutes.screens.admin.branchCreate(),
        permKeys: [Business_BasicData],
        permAccessTypes: [PermissionAccessTypes.CREATE],
        featureKeys: [Feature_Business_Branches],
    },
    [relativeRoutes.screens.admin.archiveManager()]: {
        component: DocumentArchiveManagement,
        fullPattern: fullRoutes.screens.admin.archiveManager(),
        pattern: relativeRoutes.screens.admin.archiveManager(),
        permKeys: [Business_Archives],
        featureKeys: [Feature_Business_Document_Archive],
    },
    [relativeRoutes.screens.admin.rolesManagement.list()]: {
        component: RoleManagement,
        fullPattern: fullRoutes.screens.admin.rolesManagement.list(),
        pattern: relativeRoutes.screens.admin.rolesManagement.list(),
        permKeys: [
            Business_RoleManagement_PermissionGroups_Web,
            Business_RoleManagement_PermissionGroups_Ipad,
            Business_RoleManagement_PermissionGroups_Mobile,
        ],
        featureKeys: [Feature_Business_Role_Management],
        nestedScreens: {
            [relativeRoutes.screens.admin.rolesManagement.create()]: {
                component: RoleManagement,
                fullPattern: fullRoutes.screens.admin.rolesManagement.create(),
                pattern: relativeRoutes.screens.admin.rolesManagement.create(),
            },
            [relativeRoutes.screens.admin.rolesManagement.edit()]: {
                component: RoleManagement,
                fullPattern: fullRoutes.screens.admin.rolesManagement.edit(),
                pattern: relativeRoutes.screens.admin.rolesManagement.edit(),
            },
        },
    },
    [relativeRoutes.screens.admin.rolesManagement.permissionEdit()]: {
        component: PermissionEdit,
        fullPattern: fullRoutes.screens.admin.rolesManagement.permissionEdit(),
        pattern: relativeRoutes.screens.admin.rolesManagement.permissionEdit(),
        permKeys: [
            Business_RoleManagement_PermissionGroups_Web,
            Business_RoleManagement_PermissionGroups_Ipad,
            Business_RoleManagement_PermissionGroups_Mobile,
        ],
        featureKeys: [Feature_Business_Role_Management],
    },
    [relativeRoutes.screens.admin.usersManagement.list()]: {
        component: UserManagement,
        fullPattern: fullRoutes.screens.admin.usersManagement.list(),
        pattern: relativeRoutes.screens.admin.usersManagement.list(),
        permKeys: [
            Business_RoleManagement_UserRoles_Mobile,
            Business_RoleManagement_UserRoles_Web,
        ],
        featureKeys: [Feature_Business_User_Access_Management],
        nestedScreens: {
            [relativeRoutes.screens.admin.usersManagement.edit()]: {
                component: UserManagement,
                fullPattern: fullRoutes.screens.admin.usersManagement.edit(),
                pattern: relativeRoutes.screens.admin.usersManagement.edit(),
            },
            [relativeRoutes.screens.admin.usersManagement.create()]: {
                component: UserManagement,
                fullPattern: fullRoutes.screens.admin.usersManagement.create(),
                pattern: relativeRoutes.screens.admin.usersManagement.create(),
            },
            // ["*"]: {
            //     component: UserManagement,
            //     fullPattern: "*",
            //     pattern: "*",
            //     index: true,
            // },
        },
    },
    [relativeRoutes.screens.admin.emailManager.list()]: {
        component: EmailTemplate,
        fullPattern: fullRoutes.screens.admin.emailManager.list(),
        pattern: relativeRoutes.screens.admin.emailManager.list(),
        permKeys: [Business_EmailTemplate],
        featureKeys: [Feature_Business_EmailTemplates],
    },
    [relativeRoutes.screens.admin.documentManager.list()]: {
        component: DocumentTemplateManager,
        fullPattern: fullRoutes.screens.admin.documentManager.list(),
        pattern: relativeRoutes.screens.admin.documentManager.list(),
        permKeys: [Business_DocumentManager_Template],
        requiredModule: [ModulePlans.DocumentManager],
        featureKeys: [Feature_Business_DocumentTemplates],
    },
    [relativeRoutes.screens.admin.documentManager.create()]: {
        component: DocumentTemplateCreateAndEditIframe,
        fullPattern: fullRoutes.screens.admin.documentManager.create(),
        pattern: relativeRoutes.screens.admin.documentManager.create(),
        permKeys: [Business_DocumentManager_Template],
        featureKeys: [Feature_Business_DocumentTemplates],
        requiredModule: [ModulePlans.DocumentManager],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.screens.admin.documentManager.edit()]: {
        component: DocumentTemplateCreateAndEditIframe,
        fullPattern: fullRoutes.screens.admin.documentManager.edit(),
        pattern: relativeRoutes.screens.admin.documentManager.edit(),
        permKeys: [Business_DocumentManager_Template],
        requiredModule: [ModulePlans.DocumentManager],
        featureKeys: [Feature_Business_DocumentTemplates],
    },
    [relativeRoutes.screens.admin.letterheadsManager.list()]: {
        component: DocumentLetterhead,
        fullPattern: fullRoutes.screens.admin.letterheadsManager.list(),
        pattern: relativeRoutes.screens.admin.letterheadsManager.list(),
        permKeys: [Business_DocumentManager_Letterhead],
        requiredModule: [ModulePlans.DocumentManager],
        featureKeys: [Feature_Business_DocumentLetterheads],
    },
    [relativeRoutes.screens.creditors.list()]: {
        component: CreditorsList,
        fullPattern: relativeRoutes.screens.creditors.list(),
        pattern: relativeRoutes.screens.creditors.list(),
        permKeys: [Business_Creditor_BasicData],
        featureKeys: [Feature_Business_Creditor],
    },
    [relativeRoutes.screens.creditors.create()]: {
        component: CreditorsCreate,
        fullPattern: relativeRoutes.screens.creditors.create(),
        pattern: relativeRoutes.screens.creditors.create(),
        permKeys: [Business_Creditor_BasicData],
        featureKeys: [Feature_Business_Creditor],
        permAccessTypes: [PermissionAccessTypes.CREATE],
    },
    [relativeRoutes.screens.creditors.withId().base()]: {
        component: CreditorsEditLayout,
        fullPattern: relativeRoutes.screens.creditors.withId().base(),
        pattern: relativeRoutes.screens.creditors.withId().base(),
        permKeys: [Business_Creditor_BasicData],
        featureKeys: [Feature_Business_Creditor_AddUpdate],
        nestedScreens: {
            [relativeRoutes.screens.creditors.withId().edit()]: {
                component: CreditorsEdit,
                index: true,
                pattern: relativeRoutes.screens.creditors.withId().edit(),
                fullPattern: relativeRoutes.screens.creditors.withId().edit(),
            },
            [relativeRoutes.screens.creditors.withId().bankData.list()]: {
                component: CreditorBankDataList,
                fullPattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.list(),
                pattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.list(),
                permKeys: [Business_Creditor_BankData],
                featureKeys: [Feature_Business_Creditor_BankData],
            },
            [relativeRoutes.screens.creditors.withId().bankData.create()]: {
                component: CreditorBankDataCreate,
                fullPattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.create(),
                pattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [Business_Creditor_BankData],
                featureKeys: [Feature_Business_Creditor_BankData_AddUpdate],
            },
            [relativeRoutes.screens.creditors.withId().bankData.edit()]: {
                component: CreditorBankDataEdit,
                fullPattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.edit(),
                pattern: relativeRoutes.screens.creditors
                    .withId()
                    .bankData.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [Business_Creditor_BankData],
                featureKeys: [Feature_Business_Creditor_BankData_AddUpdate],
            },
        },
    },
};
