import classNames from "classnames";
import { AppTable } from "components/Table";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import useBusinessWorkSchedule from "hooks/generalApiCalls/useBusinessWorkSchedule";
import { isNil } from "lodash-es";
import {
    PermissionAccessMap,
    checkPermissionInMap,
    PermissionAccessTypes,
    WorkingDays,
    BusinessWorkingDays,
    weekDaysNumToStringDict,
    WorkingDaysNumber,
} from "models";
import { Optional } from "models/general";
import {
    BusinessShiftPlanData,
    ShiftPlanRequest,
    ShiftPlanRowTrack,
} from "models/shiftPlan";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ShiftPlanTableCellItem from "screens/business/shiftPlan/partials/ShiftPlanTableCellItem";
import styles from "./ShiftPlanTable.module.scss";

interface ShiftPlanTableProps {
    data: BusinessShiftPlanData;
    mappingInfo: ShiftPlanRowTrack[];
    disabled?: boolean;
    containerHeight?: number;
    isEditView: boolean;
    request: ShiftPlanRequest;
    permMap: PermissionAccessMap;
    onLoadChange: (val: boolean) => void;
    onChange: () => void;
}

const SINGLE_ROW_HEIGHT = 43;
const emptyRow = (i: string, dummyRow: boolean = true) => (
    <tr
        key={i}
        className={classNames(styles.fillerRow, { [styles.title]: !dummyRow })}
    >
        {Array(7)
            .fill(7)
            .map((e, ix) => (
                <td key={ix}></td>
            ))}
    </tr>
);
export const ShiftPlanTable: React.FC<ShiftPlanTableProps> = ({
    request,
    data,
    mappingInfo,
    disabled = false,
    isEditView,
    containerHeight,
    permMap,
    onChange,
    onLoadChange,
}) => {
    const { t } = useTranslation();
    // to fetch available shift options for employee and update shift
    const [editCell, setEditCell] = useState<Optional<string>>(undefined);
    const { getDayMonthShortFormatForLocale } = useLocaleHelpers();
    const { id: businessId } = useSessionBusiness();

    const { data: workingDays } = useBusinessWorkSchedule(businessId);
    const minNumOfRows = containerHeight
        ? (containerHeight - 200) / SINGLE_ROW_HEIGHT
        : 1;
    const neededEmptyRows = Math.max(
        0,
        Math.floor(minNumOfRows - (mappingInfo.length - 1))
    );
    useEffect(() => {
        if (!isEditView && editCell) {
            setEditCell(undefined);
        }
    }, [isEditView]);
    const dates = () => {
        const dates = [];
        for (
            let d = request.WeekStart;
            d.isSameOrBefore(request.WeekEnd, "day");
            d = d.clone().add(1, "d")
        ) {
            dates.push(d.clone());
        }
        return dates;
    };

    const workingDaysInfo = workingDays
        ? (Object.keys(workingDays).reduce((res, x: string) => {
              res[x] = workingDays[x as WorkingDays].IsWorkingDay;
              return res;
          }, {} as any) as BusinessWorkingDays)
        : (Object.values(WorkingDays).reduce((res, x) => {
              res[x] = true;
              return res;
          }, {} as any) as BusinessWorkingDays);
    return (
        <div className={styles.root}>
            <AppTable
                hover={false}
                tableClass={styles.table}
                stickyHeader={true}
                containerClass={styles.tableContainer}
            >
                <thead>
                    <tr>
                        {dates().map((d, idx) => {
                            return (
                                <th
                                    key={idx}
                                    className={classNames(
                                        styles.column,
                                        styles.headerWeekDay
                                    )}
                                >
                                    {d.format(
                                        `dd, ${getDayMonthShortFormatForLocale()}`
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={7}></td>
                    </tr>
                    {mappingInfo.slice(1).map((info, idx) => {
                        if (isNil(info.EmployeeId)) {
                            return emptyRow(idx.toString(), false);
                        }
                        const emp = data.Employees.find(
                            (x) => x.Id == info.EmployeeId
                        );

                        return (
                            <tr key={idx}>
                                {dates().map((d, i) => {
                                    const unavailableOnDate =
                                        !emp ||
                                        emp.UnAvailableOnDates.some(
                                            (u) => u.diff(d, "days") == 0
                                        );
                                    const isWorkingDay =
                                        workingDaysInfo[
                                            weekDaysNumToStringDict[
                                                d.isoWeekday() as WorkingDaysNumber
                                            ]
                                        ];
                                    return (
                                        <td
                                            key={i}
                                            className={styles.shiftCell}
                                        >
                                            <ShiftPlanTableCellItem
                                                isAvailable={!unavailableOnDate}
                                                date={d}
                                                isWorkingDay={isWorkingDay}
                                                editCell={editCell}
                                                setEditCell={setEditCell}
                                                isEditView={isEditView}
                                                disabled={disabled}
                                                onChange={() => {
                                                    onChange();
                                                }}
                                                areaId={request.AreaId}
                                                rowInfo={info}
                                                canDelete={checkPermissionInMap(
                                                    permMap,
                                                    [
                                                        PermissionAccessTypes.DELETE,
                                                    ]
                                                )}
                                                canEdit={checkPermissionInMap(
                                                    permMap,
                                                    [PermissionAccessTypes.EDIT]
                                                )}
                                                value={data.ShiftAssignments.find(
                                                    (x) =>
                                                        x.EmployeeId ==
                                                            info.EmployeeId &&
                                                        (!info.ShiftId ||
                                                            x.ShiftId ==
                                                                info.ShiftId) &&
                                                        x.Date.diff(
                                                            d,
                                                            "days"
                                                        ) == 0
                                                )}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {/* render empty rows to increase table height */}
                    {neededEmptyRows > 0 &&
                        Array(neededEmptyRows)
                            .fill(0)
                            .map((r, i) => {
                                return emptyRow(i.toString());
                            })}
                </tbody>
            </AppTable>
        </div>
    );
};

export default ShiftPlanTable;
