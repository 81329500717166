import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_Employee_Shifts,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getBusinessShiftServiceKey } from "services/business";
import { EmployeeShiftCreateEdit } from "./partials";
import { parseEmployeeShiftResponse } from "models/employeeShifts";
import EmployeeShiftService from "services/business/EmployeeShiftService";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";

export const EmployeeShiftEdit: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { id } = useParams();
    const { checkPermission } = useCheckPermission();

    const currentEmployee = useActiveBusinessEmployee();

    const shiftService = new EmployeeShiftService(
        linkProvider.business.employees().api.withId().shifts
    );

    const {
        isLoading: getLoading,
        isRefetching: getRefetching,
        data: res,
    } = useQuery(
        getBusinessShiftServiceKey("get", {
            employeeId: currentEmployee?.dataItem?.Id,
            id: id,
        }),
        async () => {
            if (id) {
                return await shiftService.get(id);
            }
        }
    );

    useEffect(() => {
        if (!getLoading && !getRefetching && res) {
            if (res.Errors) {
                showErrorPage(404);
            }
        }
    }, [getLoading, getRefetching]);

    const linkProviderBase = linkProvider.business.employees().screens.withId();

    const hasEditPermission = checkPermission(
        [Business_Employee_Shifts],
        [PermissionAccessTypes.EDIT]
    );

    return (
        <div style={{ position: "relative" }}>
            {getLoading || getRefetching ? (
                <AppLoader />
            ) : (
                <EmployeeShiftCreateEdit
                    onBack={(year?: number) =>
                        navigate(
                            linkProviderBase.shifts.list(),
                            year ? { state: { year: year } } : undefined
                        )
                    }
                    value={
                        res?.Data
                            ? parseEmployeeShiftResponse(res?.Data)
                            : undefined
                    }
                    readonly={!hasEditPermission}
                />
            )}
        </div>
    );
};

export default EmployeeShiftEdit;
