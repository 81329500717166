import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { TableSort } from "models/general";
import { BankDataSortColumn } from "models/bankData";
import React from "react";
import { useTranslation } from "react-i18next";

export interface BankDataSortingColumnsProps {
    onSortChange: (sort: TableSort<BankDataSortColumn>) => void;
    sort: TableSort<BankDataSortColumn>;
}
export const BankDataSortingColumns: React.FC<BankDataSortingColumnsProps> = ({
    onSortChange,
    sort,
}) => {
    const { t } = useTranslation();
    return (
        <tr>
            <AppTableSortColumnIcons
                text={t("business.bankData.IBAN")}
                style={getFixedCssWidths(200)}
                sortColumn={BankDataSortColumn.IBAN}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.IBAN
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("business.bankData.accountOwner")}
                style={getFixedCssWidths(200)}
                sortColumn={BankDataSortColumn.AccountOwner}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.AccountOwner
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("business.bankData.accountNumber")}
                style={getFixedCssWidths(200)}
                sortColumn={BankDataSortColumn.AccountNumber}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.AccountNumber
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("business.bankData.bankName")}
                style={getFixedCssWidths(175)}
                sortColumn={BankDataSortColumn.BankName}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.BankName
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("business.bankData.bankCode")}
                style={getFixedCssWidths(150)}
                sortColumn={BankDataSortColumn.BankCode}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.BankCode
                        ? sort.SortOrder
                        : null
                }
            />
            <AppTableSortColumnIcons
                text={t("business.bankData.BIC")}
                style={getFixedCssWidths(150)}
                sortColumn={BankDataSortColumn.BIC}
                onClick={(key, order) =>
                    onSortChange({
                        SortColumn: key,
                        SortOrder: order,
                    })
                }
                sortOrder={
                    sort.SortColumn == BankDataSortColumn.BIC
                        ? sort.SortOrder
                        : null
                }
            />
            <th style={getFixedCssWidths(70)} />
        </tr>
    );
};

export default BankDataSortingColumns;
