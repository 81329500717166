import { AppYearNavigationComponent } from "components";
import { AppButton } from "components/Buttons";
import { AppTable, AppTableSkeleton } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { isNil, orderBy } from "lodash-es";
import {
    BusinessContractDetailItem,
    BusinessType,
    checkPermissionInMap,
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RedeemVoucherDialog from "screens/business/settingTabs/contracts/partials/RedeemVoucherDialog";
import styles from "./BusinessMonthlyContracts.module.scss";
import BusinessMonthlyContractRow from "screens/business/settingTabs/contracts/partials/BusinessMonthlyContractRow";

export interface BusinessMonthlyContractsProps {
    value: BusinessContractDetailItem[];
    years?: number[];
    loading: boolean;
    refetching: boolean;
    isLwc: boolean;
    type?: BusinessType;
    hasContract?: boolean;
    permMap: PermissionAccessMap;
    refetchData: () => void;
}

export const BusinessMonthlyContracts: React.FC<
    BusinessMonthlyContractsProps
> = ({
    value,
    permMap,
    isLwc,
    loading,
    refetching,
    refetchData,
    hasContract = false,
    type = BusinessType.KINDERGARTEN,
    years = [moment().year()],
}) => {
    const { t } = useTranslation();
    const [activeYear, setActiveYear] = useState<number>(moment().year());
    const [dialogOpen, setDialogOpen] = useState(false);
    const [updating, setUpdating] = useState(false);

    const isKindergarten = type == BusinessType.KINDERGARTEN;
    let COLUMNS = 7;
    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    const yearData = orderBy(
        value.filter((x) => x.MonthYear?.year() == activeYear),
        ["MonthYear"],
        ["asc"]
    );
    const hasVouchers = yearData.some((x) => x.VoucherDiscounts.length > 0);
    const hasAffiliates = yearData.some((x) => !isNil(x.AffiliateDiscount));
    const hasFreeMonths = yearData.some((x) => !isNil(x.IsFreeMonth));

    if (isKindergarten) {
        COLUMNS += 2;
    }
    if (hasVouchers && hasAffiliates) {
        COLUMNS += 3;
    } else if (hasVouchers || hasAffiliates || hasFreeMonths) {
        COLUMNS += 2;
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <AppYearNavigationComponent
                    years={years}
                    value={activeYear}
                    readonly={loading}
                    onChange={(y: number) => setActiveYear(y)}
                />
                {hasEditPermission && (
                    <>
                        <AppButton
                            variant="success"
                            padding="default"
                            disabled={loading || !hasContract}
                            onClick={() => {
                                setDialogOpen(true);
                            }}
                        >
                            {t("business.contracts.redeemVoucher")}
                        </AppButton>
                        <RedeemVoucherDialog
                            onClose={() => setDialogOpen(false)}
                            onChange={() => {
                                refetchData();
                                setDialogOpen(false);
                            }}
                            modalOpen={dialogOpen}
                        />
                    </>
                )}
            </div>
            <AppTable
                tableClass={"table-striped"}
                containerClass={styles.table}
                stickyHeader={true}
            >
                <thead>
                    <tr>
                        <th style={getFixedCssWidths(100)} />
                        {isKindergarten && (
                            <th
                                colSpan={2}
                                style={{
                                    ...getFixedCssWidths(200),
                                    textAlign: "center",
                                }}
                            >
                                {t("business.contracts.feeChildren")}
                            </th>
                        )}
                        <th
                            colSpan={2}
                            style={{
                                ...getFixedCssWidths(200),
                                textAlign: "center",
                            }}
                        >
                            {t("business.contracts.feeEmployees")}
                        </th>
                        <th
                            style={{
                                ...getFixedCssWidths(300),
                            }}
                        >
                            {t("business.contracts.modules")}
                        </th>
                        {hasVouchers && (
                            <th style={getFixedCssWidths(150)}>
                                {t("business.contracts.vouchers.title")}
                            </th>
                        )}
                        {hasAffiliates && (
                            <th style={getFixedCssWidths(150)}>
                                {t("business.contracts.affiliates")}
                            </th>
                        )}
                        {/* Cover cell */}
                        <th style={{ width: "100%" }}></th>
                        <th style={getFixedCssWidths(180)}>
                            {t("business.contracts.discountedPrice")}
                        </th>
                        {(hasAffiliates || hasVouchers || hasFreeMonths) && (
                            <th style={getFixedCssWidths(150)}>
                                {t("business.contracts.totalDiscount")}
                            </th>
                        )}
                        <th style={getFixedCssWidths(150)}>
                            {t("business.contracts.total")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>

                    {value &&
                        yearData.length > 0 &&
                        yearData.map((x) => (
                            <BusinessMonthlyContractRow
                                key={x.Id}
                                hasAffiliates={hasAffiliates}
                                hasVouchers={hasVouchers}
                                hasFreeMonths={hasFreeMonths}
                                loading={refetching}
                                isLwc={isLwc}
                                updating={updating}
                                setUpdating={setUpdating}
                                isKindergarten={isKindergarten}
                                value={x}
                                onChange={refetchData}
                                permMap={permMap}
                            />
                        ))}

                    {loading && <AppTableSkeleton columns={COLUMNS} rows={2} />}

                    {value && value.length == 0 && !loading && (
                        <tr>
                            <td
                                colSpan={COLUMNS}
                                style={{ textAlign: "center" }}
                            >
                                {t("business.contracts.noContractFound")}
                            </td>
                        </tr>
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default BusinessMonthlyContracts;
