import { useIFrameRouting, useRouting } from "hooks/general/routing";
import layoutsRouteMap from "layouts/layoutsRouteMap";
import { Navigate, Route, Routes } from "react-router-dom";
import { businessRoutesMap } from "routing/routes/business/routesMap";
import { noAuthRoutesMap } from "routing/routes/noAuth/routesMap";
import { AuthenticatedRoute, RestrictedRoute } from "hoc/routeModifiers";
import { renderRoutes, route404 } from "./helper";
import { authUserRoutesMap } from "routing/routes/authUser/routesMap";
import { kindergartenRoutesMap } from "routing/routes/kindergarten/routesMap";
import { lillywaitRoutesMap } from "routing/routes/lillywait/routesMap";
import { businessSettingRoutesMap } from "routing/routes/business/settings/routesMap";
import BusinessSettingLayout from "screens/business/hqAndBranches/BusinessSettingLayout";
import { Business_BasicData } from "models";
import { lwSupportRoutesMap } from "routing/routes/lillywait/support/routesMap";
import { DocumentPreview } from "screens/generalAuthRoutes";
import { thirdPartyCookieEnabled } from "globals/helpers/generalHelper";
import { useTranslation } from "react-i18next";
import { showSweetAlertInfo } from "globals/helpers/sweetAlertHelper";
import { useEffect } from "react";
import { withSentryReactRouterV6Routing } from "@sentry/react";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

// Main APP router that will render route for our every react screen
export const AppRouter = () => {
    const { linkProvider, pathProvider } = useRouting();
    const { t } = useTranslation();
    const { base: serverBase } = useIFrameRouting();

    const MainLayout = layoutsRouteMap[pathProvider.business.base].component;
    const NoAuthLayout = layoutsRouteMap[pathProvider.noAuth.base].component;

    useEffect(() => {
        thirdPartyCookieEnabled(serverBase as string, () => {
            showSweetAlertInfo(
                t("cookieMsg.title"),
                t(`cookieMsg.cookieNotEnabled`),
                "warning"
            );
        });
    }, []);

    return (
        <>
            <SentryRoutes>
                <Route
                    path="/"
                    element={
                        <Navigate to={linkProvider.noAuth.screens.login()} />
                    }
                />
                <Route
                    path={pathProvider.noAuth.base}
                    element={<NoAuthLayout />}
                >
                    {renderRoutes(noAuthRoutesMap)}
                </Route>
                <Route
                    path={pathProvider.business.base}
                    element={
                        <AuthenticatedRoute>
                            <MainLayout />
                        </AuthenticatedRoute>
                    }
                >
                    {renderRoutes(businessRoutesMap)}
                    {renderRoutes(kindergartenRoutesMap)}
                    {renderRoutes(lillywaitRoutesMap)}
                    {renderRoutes(lwSupportRoutesMap)}
                </Route>
                <Route
                    path={pathProvider.business.businessSettings().base}
                    element={
                        <AuthenticatedRoute>
                            <MainLayout>
                                <RestrictedRoute
                                    permKeys={[Business_BasicData]}
                                >
                                    <BusinessSettingLayout />
                                </RestrictedRoute>
                            </MainLayout>
                        </AuthenticatedRoute>
                    }
                >
                    {renderRoutes(
                        businessSettingRoutesMap,
                        undefined,
                        "businessId"
                    )}
                </Route>
                <Route
                    path={pathProvider.authUser.base}
                    element={
                        <AuthenticatedRoute>
                            <MainLayout />
                        </AuthenticatedRoute>
                    }
                >
                    {renderRoutes(authUserRoutesMap)}
                </Route>
                <Route
                    key="document-preview-page"
                    path={pathProvider.screens.documentPreview()}
                    element={<DocumentPreview />}
                />
                {/* ErrorLayout if not matched any other */}
                {route404}
            </SentryRoutes>
        </>
    );
};

export default AppRouter;
