import classNames from "classnames";
import { AvatarItem } from "components/FormFields";
import { ImageAssets } from "globals/images";
import { useChatHelper } from "hooks/business/chatHelper";
import { defaultTo } from "lodash-es";
import {
    ChannelResponseModel,
    ChatMessageType,
    getChannelName,
} from "models/chat";
import React from "react";
import { Badge, Image, ListGroup, ListGroupItemProps } from "react-bootstrap";
import styles from "./ChannelItem.module.scss";
interface ChannelItemProps extends ListGroupItemProps {
    channel: ChannelResponseModel;
    onChannelSelect?: (channelResponseModel: ChannelResponseModel) => void;
    message?: string;
    readOnly?: boolean;
}
// eslint-disable-next-line react/display-name
export const ChannelItem = React.memo<ChannelItemProps>(
    ({
        channel,
        onChannelSelect,
        message,
        readOnly,
        className,
        disabled,
        ...rest
    }) => {
        const onListItemClickHandler = () => {
            if (onChannelSelect && !rest.active) {
                onChannelSelect(channel);
            }
        };
        const { todayYesterdayOtherDateHandler } = useChatHelper();
        const renderItemRowClasses = () => {
            if (!readOnly) {
                return `${styles.itemInnerRoot} ${
                    rest.active ? styles.active : ""
                }`;
            }
            return "d-flex";
        };
        const lastMessageHandler = () => {
            if (channel.LastMessage) {
                if (channel.LastMessage.Type === ChatMessageType.TEXT) {
                    return (
                        <span className={styles.lastMessage}>
                            {channel.LastMessage.Body}
                        </span>
                    );
                } else {
                    return (
                        <>
                            <Image
                                className={styles.clipIcon}
                                src={ImageAssets.common.paperClip}
                            />
                            <span className={styles.lastMessage}>
                                {channel.LastMessage.FileName}
                            </span>
                        </>
                    );
                }
            }
        };

        const showRightSection =
            channel.LastMessageTime ||
            defaultTo(channel.UnReadMessageCount, 0) > 0;

        return (
            <ListGroup.Item
                action
                as={"div"}
                className={classNames(className, styles.item, {
                    [styles.notAllowed]: disabled,
                })}
                onClick={!disabled ? onListItemClickHandler : () => {}}
                {...rest}
            >
                <div
                    style={{ width: "100%" }}
                    className={renderItemRowClasses()}
                >
                    <div className={styles.avatar}>
                        <AvatarItem
                            isOnline={channel.IsOnline}
                            onlineStatuesClassName={styles.onlineStatusDiv}
                            value={{
                                Avatar: channel.Avatar,
                                BackgroundColor: channel.BackgroundColor,
                                BorderColor: channel.BorderColor,
                                Initial: channel.Initial,
                            }}
                            className={styles.image}
                        />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentRows}>
                            <div
                                className={classNames(styles.leftSection, {
                                    ["w-100"]: !showRightSection,
                                })}
                            >
                                <p className={styles.name}>
                                    {channel.ChannelDisplayName
                                        ? channel.ChannelDisplayName
                                        : getChannelName(
                                              channel.ChannelOriginalName,
                                              channel.ChannelType,
                                              channel.ChannelUserType
                                          )}
                                </p>
                                {(channel.LastMessage || channel.Email) && (
                                    <div
                                        style={{
                                            minHeight: "20px",
                                            maxWidth: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "2px",
                                        }}
                                    >
                                        {channel.LastMessage ? (
                                            message ? (
                                                message
                                            ) : (
                                                lastMessageHandler()
                                            )
                                        ) : (
                                            <span
                                                className={styles.lastMessage}
                                            >
                                                {channel.Email}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            {showRightSection && (
                                <div className={styles.rightSection}>
                                    {channel.LastMessageTime && (
                                        <span
                                            className={styles.lastMessageTime}
                                        >
                                            {todayYesterdayOtherDateHandler(
                                                channel.LastMessageTime,
                                                true
                                            )}
                                        </span>
                                    )}
                                    {defaultTo(channel.UnReadMessageCount, 0) >
                                        0 && (
                                        <Badge
                                            variant={"warning"}
                                            className={
                                                styles.unreadMessageBadge
                                            }
                                        >
                                            {channel.UnReadMessageCount}
                                        </Badge>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ListGroup.Item>
        );
    }
);

export default ChannelItem;
