import { AppCollapseToggleButton } from "components/Buttons";
import { AppCheckbox } from "components/FormFields";
import { AppTable, AppTableSkeleton } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    AccessPermissionMapping,
    AccessPermissionRequest,
    checkKeyExistInSelectedList,
    handleParentCheckDisable,
    PermissionAccessTypes,
    PermissionNeedsToDisable,
} from "models";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PermissionManagementTable.module.scss";
import PermissionManagementTableRows from "./PermissionManagementTableRow";
export interface PermissionManagementTableProps {
    permission: AccessPermissionMapping;
    loading: boolean;
    selectedList: AccessPermissionRequest[];
    tableName: string;
    tableId: string;
    defaultToggleValue: boolean;
    checksNeedToDisable: PermissionNeedsToDisable;
    onPermissionChange: (
        selectPermission: AccessPermissionMapping,
        type: PermissionAccessTypes,
        checked: boolean
    ) => void;
}

const COLUMNS_COUNT = 7;
export const PermissionManagementTable =
    React.memo<PermissionManagementTableProps>(
        ({
            loading,
            permission,
            tableName,
            defaultToggleValue,
            onPermissionChange,
            tableId,
            selectedList,
            checksNeedToDisable,
        }) => {
            const { t } = useTranslation();
            const [visible, setVisibility] = useState(defaultToggleValue);

            const tableToggle = () => {
                setVisibility(!visible);
            };
            return (
                <AppTable
                    id={"scrollableDiv"}
                    collapsed={false}
                    useMaxHeight={false}
                    key={tableId}
                    containerClass={`${styles.tableOuter}`}
                >
                    <thead>
                        <tr>
                            <th style={getFixedCssWidths(250, false)}>
                                {tableName}
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                <div className="d-flex justify-content-end align-content-center">
                                    <div>
                                        {t("roleManagement.permissions.show")}
                                    </div>
                                    <div className="ml-1">
                                        <AppCheckbox
                                            disabled={
                                                !permission.IsViewPossible ||
                                                handleParentCheckDisable(
                                                    checksNeedToDisable,
                                                    PermissionAccessTypes.SHOW,
                                                    permission
                                                )
                                            }
                                            onChange={(e) =>
                                                onPermissionChange(
                                                    permission,
                                                    PermissionAccessTypes.SHOW,
                                                    e.target.checked
                                                )
                                            }
                                            checked={
                                                selectedList &&
                                                checkKeyExistInSelectedList(
                                                    selectedList,
                                                    PermissionAccessTypes.SHOW,
                                                    permission.CompleteIdentifier,
                                                    permission.Childs
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                <div className="d-flex justify-content-end align-content-center">
                                    <div>
                                        {t("roleManagement.permissions.edit")}
                                    </div>
                                    <div className="ml-1">
                                        <AppCheckbox
                                            onChange={(e) =>
                                                onPermissionChange(
                                                    permission,
                                                    PermissionAccessTypes.EDIT,
                                                    e.target.checked
                                                )
                                            }
                                            checked={
                                                selectedList &&
                                                checkKeyExistInSelectedList(
                                                    selectedList,
                                                    PermissionAccessTypes.EDIT,
                                                    permission.CompleteIdentifier,
                                                    permission.Childs
                                                )
                                            }
                                            disabled={handleParentCheckDisable(
                                                checksNeedToDisable,
                                                PermissionAccessTypes.EDIT,
                                                permission
                                            )}
                                        />
                                    </div>
                                </div>
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                <div className="d-flex justify-content-end align-content-center">
                                    <div>
                                        {t("roleManagement.permissions.create")}
                                    </div>
                                    <div className="ml-1">
                                        <AppCheckbox
                                            onChange={(e) =>
                                                onPermissionChange(
                                                    permission,
                                                    PermissionAccessTypes.CREATE,
                                                    e.target.checked
                                                )
                                            }
                                            checked={
                                                selectedList &&
                                                checkKeyExistInSelectedList(
                                                    selectedList,
                                                    PermissionAccessTypes.CREATE,
                                                    permission.CompleteIdentifier,
                                                    permission.Childs
                                                )
                                            }
                                            disabled={handleParentCheckDisable(
                                                checksNeedToDisable,
                                                PermissionAccessTypes.CREATE,
                                                permission
                                            )}
                                        />
                                    </div>
                                </div>
                            </th>
                            <th style={getFixedCssWidths(150)}>
                                <div className="d-flex justify-content-end align-content-center">
                                    <div>
                                        {t("roleManagement.permissions.delete")}
                                    </div>
                                    <div className="ml-1">
                                        <AppCheckbox
                                            onChange={(e) =>
                                                onPermissionChange(
                                                    permission,
                                                    PermissionAccessTypes.DELETE,
                                                    e.target.checked
                                                )
                                            }
                                            checked={
                                                selectedList &&
                                                checkKeyExistInSelectedList(
                                                    selectedList,
                                                    PermissionAccessTypes.DELETE,
                                                    permission.CompleteIdentifier,
                                                    permission.Childs
                                                )
                                            }
                                            disabled={handleParentCheckDisable(
                                                checksNeedToDisable,
                                                PermissionAccessTypes.DELETE,
                                                permission
                                            )}
                                        />
                                    </div>
                                </div>
                            </th>
                            <th style={getFixedCssWidths(200)}>
                                {t("roleManagement.permissions.fieldAccess")}
                            </th>
                            <th
                                onClick={() => tableToggle()}
                                style={{
                                    ...getFixedCssWidths(60),
                                    cursor: "pointer",
                                }}
                            >
                                <AppCollapseToggleButton
                                    className={styles.toggleButton}
                                    visible={visible}
                                />
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        className={
                            visible
                                ? styles.displayTableRow
                                : styles.displayNone
                        }
                    >
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>

                        {permission &&
                            permission.Childs &&
                            permission.Childs.length > 0 &&
                            permission.Childs.map((x, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <PermissionManagementTableRows
                                    key={index}
                                    permission={x}
                                    checksNeedToDisable={checksNeedToDisable}
                                    selectedList={selectedList}
                                    onPermissionChange={onPermissionChange}
                                />
                            ))}

                        {loading && (
                            <AppTableSkeleton
                                rows={
                                    permission &&
                                    permission.Childs &&
                                    permission.Childs.length > 0
                                        ? 2
                                        : 12
                                }
                                columns={COLUMNS_COUNT}
                            />
                        )}

                        {permission &&
                            permission.Childs &&
                            permission.Childs.length == 0 &&
                            !loading && (
                                <tr>
                                    <td
                                        colSpan={COLUMNS_COUNT}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t("roleManagement.role.roleListEmpty")}
                                    </td>
                                </tr>
                            )}
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                    </tbody>
                </AppTable>
            );
        }
    );

export default PermissionManagementTable;
