import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AppButton } from "components/Buttons/AppButton";
import { max, min } from "lodash-es";
import moment from "moment";
import React from "react";
import styles from "./AppYearNavigationComponent.module.scss";

export interface AppYearNavigationComponentProps {
    value?: number;
    readonly?: boolean;
    years?: number[];
    onChange: (y: number) => void;
    className?: string;
}

export const AppYearNavigationComponent: React.FC<
    AppYearNavigationComponentProps
> = ({
    value = moment().year(),
    years = [moment().year()],
    onChange,
    className = undefined,
    readonly = false,
}) => {
    return (
        <div className={classNames(styles.root, className)}>
            <AppButton
                className={styles.button}
                padding={"default"}
                onClick={() => onChange(value - 1)}
                disabled={!years.includes(value - 1) || readonly}
            >
                <FontAwesomeIcon icon={"less-than"} />
            </AppButton>
            <span>{value}</span>
            <AppButton
                className={styles.button}
                padding={"default"}
                disabled={!years.includes(value + 1) || readonly}
                onClick={() => onChange(value + 1)}
            >
                <FontAwesomeIcon icon={"greater-than"} />
            </AppButton>
        </div>
    );
};

export default AppYearNavigationComponent;
