import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_Employee_AccessControl,
    Business_Employee_App,
    Business_Employee_BankData,
    Business_Employee_BasicData,
    Business_Employee_ContractData,
    Business_Employee_Diseases,
    Business_Employee_Documents,
    Business_Employee_Groups,
    Business_Employee_Shifts,
    Business_Employee_SalaryStatement,
    Business_Employee_Absences,
    Business_Employee_Vacations,
    EmployeeBasicInfo,
    EmployeeListItem,
    EmployeeStatus,
    PermissionAccessTypes,
    BusinessType,
} from "models";
import {
    AppResponse,
    NavigationTab,
    TabbedLayoutActiveItem,
} from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useOutletContext, useParams } from "react-router";
import {
    BusinessEmployeeService,
    getBusinessEmployeeServiceKey,
} from "services/business";

// not in edit-layout to resolve circular dependency
export const useActiveBusinessEmployee = () => {
    return useOutletContext<TabbedLayoutActiveItem<EmployeeListItem>>();
};

export const EmployeeTabs = ({
    activeEmployee,
    onActiveTabChange,
}: {
    activeEmployee?: EmployeeListItem;
    onActiveTabChange: (tab: NavigationTab) => void;
}) => {
    const { linkProvider } = useRouting();
    const { urlForEmployee } = useIFrameRouting();
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const { employeeId } = useParams();
    const { sessionBusiness } = useSessionBusiness();
    const [tabs, setTabs] = useState<NavigationTab[]>([]);
    const linkBase = linkProvider.business
        .employees()
        .screens.withId(employeeId);

    // refetch basic info to update tabs after regular intervals
    const employeeService = new BusinessEmployeeService(
        linkProvider.business.employees().api
    );

    const {
        isFetching: fetchingInfo,
        data: empInfo,
        refetch: refetchInfo,
    } = useQuery(
        getBusinessEmployeeServiceKey("getBasicInfo", {
            EncodedId: activeEmployee?.EncodedId,
        }),
        async () => await employeeService.getBasicInfo(),
        {
            initialData: {
                Data: activeEmployee as EmployeeBasicInfo,
            } as AppResponse<EmployeeBasicInfo>,
            select: (res) =>
                res ? res.Data : (activeEmployee as EmployeeBasicInfo),
        }
    );

    useEffect(() => {
        // ToDO: for now refetching on every path change but eventually it should be on contractData update or employee name update
        refetchInfo();
    }, [location.pathname]);

    const getTranslation = (tabName: string) => t(`employee.tabs.${tabName}`);

    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission(Business_Employee_BasicData, [
                    PermissionAccessTypes.SHOW,
                ]),
                url: linkBase.edit(),
                iframeUrl: urlForEmployee("Edit"),
            },
            {
                label: getTranslation("contractData"),
                hasAccess: checkPermission(Business_Employee_ContractData),
                url: linkBase.contract.list(),
                iframeUrl: urlForEmployee("Contracts"),
            },
            {
                label: getTranslation("documents"),
                hasAccess: checkPermission(Business_Employee_Documents),
                url: linkBase.documents.list(),
                iframeUrl: urlForEmployee("Documents"),
            },
            {
                label: getTranslation("accessControl"),
                hasAccess: checkPermission(Business_Employee_AccessControl),
                url: linkBase.accessControl(),
                iframeUrl: urlForEmployee("AccessControl"),
            },
            {
                label: getTranslation("diseases"),
                hasAccess: checkPermission(Business_Employee_Diseases),
                url: linkBase.diseases.list(),
                iframeUrl: urlForEmployee("Diseases"),
            },
            {
                label: getTranslation("vacations"),
                hasAccess: checkPermission(Business_Employee_Vacations),
                url: linkBase.vacations.list(),
            },
            {
                label: getTranslation("shifts"),
                hasAccess: checkPermission(Business_Employee_Shifts),
                url: linkBase.shifts.list(),
            },
            {
                label: getTranslation("absences"),
                hasAccess: checkPermission(Business_Employee_Absences),
                url: linkBase.absences.list(),
                iframeUrl: urlForEmployee("Absences"),
            },
            {
                label: getTranslation(
                    sessionBusiness != null &&
                        sessionBusiness.Type == BusinessType.KINDERGARTEN
                        ? "careGroups"
                        : "workingGroups"
                ),
                hasAccess: checkPermission(Business_Employee_Groups),
                url: linkBase.careGroups(),
                iframeUrl: urlForEmployee("Groups"),
            },
            {
                label: getTranslation("salaryStatements"),
                hasAccess: checkPermission(Business_Employee_SalaryStatement),
                url: linkBase.salaryStatement(),
                iframeUrl: urlForEmployee("SalaryStatements"),
            },
            {
                label: getTranslation("bankData"),
                hasAccess: checkPermission(Business_Employee_BankData),
                url: linkBase.bankData.list(),
            },
            {
                label: getTranslation("app"),
                hasAccess:
                    empInfo?.Status === EmployeeStatus.Active &&
                    checkPermission(Business_Employee_App),
                url: linkBase.app(),
                iframeUrl: urlForEmployee("App"),
            },
        ]);
    }, [t, checkPermission, employeeId, empInfo]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={7}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default EmployeeTabs;
