import { AppTable } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import {
    checkPermissionInMap,
    KindergartenClientContractCopyRequest,
    KindergartenClientContractsList,
    KindergartenClientContractUpdateRequest,
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "../KindergartenClientContractsListScreen.module.scss";
import { useMutation } from "react-query";
import { v4 as uuid } from "uuid";
import {
    KindergartenClientContractService,
    getKindergartenClientContractServiceKey,
} from "services/kindergarten";
import KindergartenClientContractRow from "./KindergartenClientContractRow";

export interface KindergartenClientMonthlyContractsTableProps {
    data: KindergartenClientContractsList;
    permMap: PermissionAccessMap;
    loading: boolean;
    refetchData: () => void;
}
const multiOptionHeaderStyle = {
    ...getFixedCssWidths(350),
    textAlign: "center",
} as any;
export const KindergartenClientMonthlyContractsTable: React.FC<
    KindergartenClientMonthlyContractsTableProps
> = ({ data, permMap, refetchData, loading }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const apiBase = useMemo(() => {
        return linkProvider.kindergarten.clients().api.withId().clientContracts;
    }, [linkProvider]);

    const contractsService = new KindergartenClientContractService(apiBase);

    const { mutate: copyContract, isLoading: copying } = useMutation(
        getKindergartenClientContractServiceKey("copy"),
        async (data: {
            req: KindergartenClientContractCopyRequest;
            tillEnd?: boolean;
        }) => await contractsService.copy(data.req, data.tillEnd),
        {
            onSuccess: (data) => {
                if (data && !isNil(data.Data)) {
                    refetchData();
                }
            },
        }
    );
    const { mutate: updateContract, isLoading: updating } = useMutation(
        getKindergartenClientContractServiceKey("update"),
        async (req: KindergartenClientContractUpdateRequest) =>
            await contractsService.update(req),
        {
            onSuccess: (data) => {
                if (data && !isNil(data.Data)) {
                    refetchData();
                }
            },
        }
    );

    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);

    return (
        <AppTable
            heightToAdjust={340}
            tableClass={"table-striped"}
            stickyHeader={true}
            containerClass={styles.table}
        >
            <thead>
                <tr>
                    <th style={getFixedCssWidths(120)}>{t("common.month")}</th>
                    {!isNil(data.ColumnsUIInfo.mealGroup) && (
                        <th colSpan={2} style={multiOptionHeaderStyle}>
                            {t("kindergartenClient.contracts.mealGroup")}
                        </th>
                    )}
                    {!isNil(data.ColumnsUIInfo.careScope) && (
                        <th colSpan={2} style={multiOptionHeaderStyle}>
                            {t("kindergartenClient.contracts.careScope")}
                        </th>
                    )}
                    {!isNil(data.ColumnsUIInfo.salaryGroup) && (
                        <th colSpan={2} style={multiOptionHeaderStyle}>
                            {t("kindergartenClient.contracts.salaryGroup")}
                        </th>
                    )}
                    <th
                        style={{
                            ...getFixedCssWidths(300),
                            textAlign: "center",
                        }}
                    >
                        {t("kindergartenClient.contracts.externalContract")}
                    </th>
                    {data.ColumnsUIInfo.surCharges.map((c) => (
                        <th colSpan={2} style={multiOptionHeaderStyle} key={c}>
                            {data.Columns[c].Name}
                        </th>
                    ))}
                    {data.ColumnsUIInfo.multiOption.map((c) => (
                        <th colSpan={2} style={multiOptionHeaderStyle} key={c}>
                            {data.Columns[c].Name}
                        </th>
                    ))}
                    {data.ColumnsUIInfo.singleValue.map((c) => (
                        <th
                            style={{
                                ...getFixedCssWidths(200),
                            }}
                            key={c}
                        >
                            {data.Columns[c].Name}
                        </th>
                    ))}
                    <th
                        style={{
                            ...getFixedCssWidths(220),
                            textAlign: "center",
                        }}
                    >
                        {t("kindergartenClient.contracts.manualInput")}
                    </th>
                    {/* Cover cell */}
                    <th style={{ width: "100%" }}></th>
                    <th style={getFixedCssWidths(110)}>
                        {t("kindergartenClient.contracts.total")}
                    </th>
                    {hasEditPermission && (
                        <th style={getFixedCssWidths(120)}></th> // copy and copy to end
                    )}
                </tr>
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={data.ColumnsUIInfo.columnsCount} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={data.ColumnsUIInfo.columnsCount} />
                </tr>
                {data.MonthlyContracts.map((mC, idx) => {
                    const key = `${mC.Id}_${mC.ClientId}_${idx}`;
                    return (
                        <KindergartenClientContractRow
                            key={key}
                            // keyString={key}
                            value={mC}
                            columnsMapping={data.ColumnsUIInfo}
                            canEdit={
                                !loading &&
                                hasEditPermission &&
                                !updating &&
                                !copying
                            }
                            onChange={
                                !hasEditPermission
                                    ? () => {}
                                    : (
                                          req: KindergartenClientContractUpdateRequest
                                      ) => updateContract(req)
                            }
                            onCopy={
                                !hasEditPermission
                                    ? () => {}
                                    : (
                                          req: KindergartenClientContractCopyRequest,
                                          tillEnd: boolean
                                      ) =>
                                          copyContract({
                                              req: req,
                                              tillEnd: tillEnd,
                                          })
                            }
                        />
                    );
                })}
                {data.MonthlyContracts.length == 0 && (
                    <tr>
                        <td
                            colSpan={data.ColumnsUIInfo.columnsCount}
                            style={{ textAlign: "center" }}
                        >
                            {t("kindergartenClient.contracts.noContractFound")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={data.ColumnsUIInfo.columnsCount} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default KindergartenClientMonthlyContractsTable;
