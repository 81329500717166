import { StartAndEndDateFields } from "commonPartials";
import { ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer, AppContainerFooter } from "components/Containers";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { ValidityStateManager } from "models/general";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import {
    EmployeeVacationService,
    getEmployeeVacationServiceKey,
} from "services/business";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";
import {
    EmployeeVacation,
    EmployeeVacationStatus,
    getDefaultVacationValue,
    getVacationStatusTranslation,
    getWorkingDaysCount,
    validateEmployeeVacation,
} from "models";
import {
    AppCheckbox,
    AppInputField,
    AppNumberInput,
} from "components/FormFields";
import styles from "./EmployeeVacationCreateEdit.module.scss";
import useBusinessWorkSchedule from "hooks/generalApiCalls/useBusinessWorkSchedule";
import moment from "moment";
import useLocaleHelpers from "hooks/general/localeHelpers";

export interface EmployeeVacationCreateEditProps {
    value?: EmployeeVacation;
    readonly?: boolean;
    onBack: (year?: number) => void;
}

export const EmployeeVacationCreateEdit: React.FC<
    EmployeeVacationCreateEditProps
> = ({ value, readonly = false, onBack }) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    const queryClient = useQueryClient();
    const { dataItem: employee } = useActiveBusinessEmployee();
    const [state, setState] = useState<EmployeeVacation>(
        defaultTo(value, getDefaultVacationValue(employee ? employee.Id : 0))
    );

    const { onlyFlagsMap: workingDays } = useBusinessWorkSchedule(
        employee?.BusinessId
    );

    const vacationService = new EmployeeVacationService(
        linkProvider.business.employees().api.withId().vacations
    );

    useEffect(() => {
        if (workingDays && value != null && !value.ManualCorrection) {
            setState((s) => ({ ...s, Total: calculateNewTotal(value) }));
        }
    }, [workingDays]);

    const calculateNewTotal = useCallback(
        (newVal: EmployeeVacation) => {
            const count = workingDays
                ? getWorkingDaysCount(
                      newVal.DateStart,
                      defaultTo(newVal.DateEnd, moment()),
                      workingDays
                  )
                : 0;
            return newVal.HalfDayVacation
                ? count * 0.5
                : newVal.HalfDayGiftVacation ||
                  newVal.DismantlingOvertime ||
                  newVal.UnpaidVacation
                ? 0
                : count;
        },
        [workingDays]
    );
    const goBack = () => {
        onBack(state.DateStart ? state.DateStart.year() : undefined);
    };
    const { isLoading: createUpdateLoading, mutate: createUpdate } =
        useMutation(async () => await vacationService.createUpdate(state), {
            onSuccess: (res) => {
                if (res.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        t(
                            `common.actions.${
                                state.Id && state.Id > 0
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        ),
                        "success"
                    );
                    queryClient.invalidateQueries(
                        getEmployeeVacationServiceKey("list", {
                            employeeId: employee?.EncodedId,
                        })
                    );
                    goBack();
                } else if (res.Message) {
                    showSweetAlertToast("Error", res.Message, "error");
                } else {
                    showUnexpectedErrorToast();
                }
            },
        });

    const validityStateManager = useMemo(() => {
        const validationState = validateEmployeeVacation(state);
        return new ValidityStateManager(validationState);
    }, [state, t]);

    const pleaseSelectOption = {
        label: t("common.pleaseSelect"),
        value: "",
    } as SimpleOption;

    const updateState = (vacation: EmployeeVacation) => {
        const newState = {
            ...vacation,
            EndDate:
                state.HalfDayGiftVacation ||
                state.HalfDayVacation ||
                state.ManualCorrection
                    ? vacation.DateStart // same end-date
                    : vacation.DateEnd,
        };
        setState({
            ...newState,
            Total: !newState.ManualCorrection
                ? calculateNewTotal(newState)
                : newState.Total,
        });
    };
    const statusOptions = [
        pleaseSelectOption,
        ...Object.values(EmployeeVacationStatus)
            .filter((x) => typeof x == "number")
            .map((x) => {
                return {
                    label: getVacationStatusTranslation(
                        x as EmployeeVacationStatus
                    ),
                    value: x,
                } as SimpleOption;
            }),
    ];

    return (
        <AppContainer
            showHeader={true}
            onBack={goBack}
            title={
                !isNil(state.Id)
                    ? t("employee.vacations.editTitle")
                    : t("employee.vacations.createTitle")
            }
            footer={
                <AppContainerFooter
                    primaryButtonProps={{
                        disabled:
                            readonly ||
                            createUpdateLoading ||
                            !validityStateManager.isStateValid(),
                        onClick: () => {
                            createUpdate();
                        },
                    }}
                />
            }
        >
            <div className={styles.container}>
                <div>
                    <Row>
                        <StartAndEndDateFields
                            colSize={12}
                            allowSameEndDate={true}
                            appendToBody={true}
                            useNoEndDate={false}
                            maxEndDate={
                                state.ManualCorrection ||
                                state.HalfDayGiftVacation ||
                                state.HalfDayVacation
                                    ? state.DateStart
                                    : undefined
                            }
                            value={{
                                StartDate: state.DateStart,
                                EndDate: state.DateEnd,
                            }}
                            onChange={({
                                StartDate: startDate,
                                EndDate: endDate,
                            }) => {
                                updateState({
                                    ...state,
                                    DateStart: startDate,
                                    DateEnd: endDate,
                                });
                            }}
                            readonly={readonly}
                        />
                        <Col md={12} style={{ marginBottom: "8px" }}>
                            <AppCheckbox
                                className={styles.checkBox}
                                label={t(
                                    `employee.vacations.dismantlingOvertime`
                                )}
                                onChange={(check) =>
                                    updateState({
                                        ...state,
                                        DismantlingOvertime:
                                            check.target.checked,
                                    })
                                }
                                disabled={
                                    readonly ||
                                    state.HalfDayVacation ||
                                    state.ManualCorrection
                                }
                                checked={state.DismantlingOvertime}
                            />
                        </Col>
                        <Col md={12} style={{ marginBottom: "8px" }}>
                            <AppCheckbox
                                className={styles.checkBox}
                                label={t(`employee.vacations.halfDayVacation`)}
                                onChange={(check) =>
                                    updateState({
                                        ...state,
                                        HalfDayVacation: check.target.checked,
                                    })
                                }
                                disabled={
                                    readonly ||
                                    state.Total > 1 ||
                                    state.ManualCorrection ||
                                    state.DismantlingOvertime ||
                                    state.UnpaidVacation
                                }
                                checked={state.HalfDayVacation}
                            />
                        </Col>
                        <Col md={12} style={{ marginBottom: "8px" }}>
                            <AppCheckbox
                                className={styles.checkBox}
                                label={t(
                                    `employee.vacations.halfDayVacationAsGift`
                                )}
                                onChange={(check) =>
                                    updateState({
                                        ...state,
                                        HalfDayGiftVacation:
                                            check.target.checked,
                                    })
                                }
                                disabled={
                                    readonly ||
                                    state.ManualCorrection ||
                                    state.Total > 1
                                }
                                checked={state.HalfDayGiftVacation}
                            />
                        </Col>
                        <Col md={12} style={{ marginBottom: "8px" }}>
                            <AppCheckbox
                                className={styles.checkBox}
                                label={t(`employee.vacations.unpaidVacation`)}
                                onChange={(check) =>
                                    setState({
                                        ...state,
                                        UnpaidVacation: check.target.checked,
                                    })
                                }
                                disabled={
                                    readonly ||
                                    state.HalfDayVacation ||
                                    state.ManualCorrection
                                }
                                checked={state.UnpaidVacation}
                            />
                        </Col>
                        <Col md={12} style={{ marginBottom: "8px" }}>
                            <AppCheckbox
                                className={styles.checkBox}
                                label={t(`employee.vacations.manualCorrection`)}
                                onChange={(check) =>
                                    updateState({
                                        ...state,
                                        ManualCorrection: check.target.checked,
                                        ...(check.target.checked
                                            ? {
                                                  DateEnd: state.DateStart,
                                                  HalfDayVacation: false,
                                                  HalfDayGiftVacation: false,
                                                  DismantlingOvertime: false,
                                                  UnpaidVacation: false,
                                              }
                                            : {}),
                                    })
                                }
                                disabled={readonly}
                                checked={state.ManualCorrection}
                            />
                        </Col>
                    </Row>
                </div>
                <div style={{ marginLeft: "15px" }}>
                    <Row>
                        <Col md={12}>
                            <Form.Label>
                                {t("employee.vacations.comment")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                as={"textarea"}
                                style={{
                                    height: "auto !important",
                                    resize: "none",
                                    fontSize: "0.95rem",
                                    marginBottom: "10px",
                                }}
                                rows={6}
                                value={defaultTo(state.Comment, "")}
                                disabled={readonly}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        Comment: e.target.value,
                                    });
                                }}
                                placeholder={t("employee.vacations.comment")}
                            />
                        </Col>
                        <Col md={12}>
                            {state.ManualCorrection ? (
                                <AppNumberInput
                                    value={state.Total}
                                    label={t("employee.vacations.total")}
                                    showError={true}
                                    onChange={(val) =>
                                        setState({
                                            ...state,
                                            Total: val as number,
                                        })
                                    }
                                    allowZero={true}
                                    placeholder={t("employee.vacations.total")}
                                    readOnly={
                                        readonly || !state.ManualCorrection
                                    }
                                    showEmptyError={true}
                                    error={validityStateManager.getFirstErrorInfo(
                                        "Total"
                                    )}
                                />
                            ) : (
                                <AppInputField
                                    label={t("employee.vacations.total")}
                                    value={formatNumber(state?.Total)}
                                    readOnly={true}
                                    showEmptyError={true}
                                    onValueChange={() => {}}
                                />
                            )}
                        </Col>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>
                                    {t("employee.vacations.status.name")}
                                </Form.Label>
                                <AppSelectOld
                                    value={
                                        state.Status == null
                                            ? pleaseSelectOption
                                            : statusOptions.find(
                                                  (x) =>
                                                      x.value === state.Status
                                              )
                                    }
                                    menuPortalTarget={document.body}
                                    isDisabled={readonly}
                                    onChange={(value: SimpleOption) => {
                                        setState({
                                            ...state,
                                            Status: value.value,
                                        });
                                    }}
                                    options={statusOptions}
                                />
                                <ErrorMessage
                                    showEmpty={true}
                                    errorInfo={validityStateManager.getFirstErrorInfo(
                                        "Status"
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>
        </AppContainer>
    );
};

export default EmployeeVacationCreateEdit;
