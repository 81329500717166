import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { isNil } from "lodash-es";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { EmployeeVacation } from "./model";

export function validateEmployeeVacation(
    vacation: EmployeeVacation,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof EmployeeVacation> = {
        DateStart: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("common.startDate.invalid"),
            },
            ...(!isNil(vacation.DateEnd)
                ? [
                      {
                          rule: Validations.LESSER_DATE,
                          message: i18next.t("common.startDate.invalid"),
                          options: {
                              value: vacation.DateEnd.clone().endOf("d"),
                          },
                      },
                  ]
                : []),
        ],
        DateEnd: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("common.endDate.missing"),
            },
            ...(!isNil(vacation.DateStart)
                ? [
                      {
                          rule: Validations.GREATER_DATE,
                          message: i18next.t("common.endDate.invalid"),
                          options: {
                              value: vacation.DateStart.clone().startOf("d"),
                          },
                      },
                  ]
                : []),
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        vacation,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}
