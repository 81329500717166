import i18next from "i18next";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { COLOR_PRIMARY, COLOR_RED } from "theme/themeConstants";

export function showSweetAlertConfirmation(
    title: string,
    message: string,
    onSuccess: () => void,
    yesText: string = i18next.t("common.yes"),
    iconType: SweetAlertIcon = "warning",
    showCancelButton: boolean = true,
    cancelButtonText: string = i18next.t("common.cancel")
) {
    Swal.fire({
        title: title,
        html: message,
        icon: iconType,
        showCancelButton: showCancelButton,
        confirmButtonColor: COLOR_RED,
        cancelButtonColor: COLOR_PRIMARY,
        cancelButtonText: cancelButtonText,
        confirmButtonText: yesText,
    }).then((result) => {
        if (result.value && onSuccess) {
            onSuccess();
        }
    });
}
export function hideSwalLoader() {
    Swal.close();
}
export function showSwalLoader(title: string, message: string = "") {
    Swal.fire({
        title: title,
        html: message,
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        },
    } as any);
}
export function showSweetAlertInfo(
    title: string,
    message: string,
    iconType: SweetAlertIcon = "warning",
    onSuccess?: () => void,
    yesText: string = i18next.t("common.ok")
) {
    Swal.fire({
        title: title,
        html: message,
        icon: iconType,
        confirmButtonText: yesText,
    }).then((result) => {
        if (result.value && onSuccess) {
            onSuccess();
        }
    });
}
export const showUnexpectedErrorToast = () => {
    showSweetAlertToast(
        i18next.t("common.error.error"),
        `${i18next.t("common.error.unexpectedError")}`,
        "error"
    );
};
export function showSweetAlertToast(
    title: string,
    message: string,
    iconType: SweetAlertIcon = "warning",
    timer: number = 3000,
    showButton: boolean = false,
    yesText: string = i18next.t("common.ok"),
    onSuccess?: () => void
) {
    Swal.fire({
        title: title,
        html: message,
        icon: iconType,
        timer: timer,
        confirmButtonText: yesText,
        showConfirmButton: showButton,
        toast: true,
        position: "bottom",
    }).then((result) => {
        if (result.value && onSuccess) {
            onSuccess();
        }
    });
}
