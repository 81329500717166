import AppIconWithName from "components/AppIconWithName";
import { AppDeleteButton, AppIconButton } from "components/Buttons";
import { AppNumberInput } from "components/FormFields";
import {
    formatNumericValue,
    getIconByExtension,
} from "globals/helpers/generalHelper";
import {
    showSweetAlertConfirmation,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { DocumentArchive } from "models/archive";
import { UpdateDisplayIdResponseAndRequest } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { DocumentArchiveService } from "services/business";
import styles from "./DocumentArchiveTableRows.module.scss";

export interface DocumentArchiveTableRowsProps {
    value: DocumentArchive;
    updateDisplayId: (req: UpdateDisplayIdResponseAndRequest) => void;
    removeItem: (Id: number) => void;
    hasEditPermission: boolean;
    hasDeletePermission: boolean;
    checkDisplayIdAlreadyExist: (displayId: number) => boolean;
    filterList: () => void;
    checkDisplayIdIsInRange: (displayId: number) => boolean;
    onEditClick: (id: number) => void;
}

export const DocumentArchiveTableRows: React.FC<
    DocumentArchiveTableRowsProps
> = ({
    value,
    updateDisplayId,
    removeItem,
    hasEditPermission,
    hasDeletePermission,
    filterList,
    onEditClick,
    checkDisplayIdAlreadyExist,
    checkDisplayIdIsInRange,
}) => {
    const { t } = useTranslation();
    const [isDisplayIdEditable, setDisplayIdEditable] = useState(false);
    const { linkProvider } = useRouting();
    const [displayId, setDisplayId] = useState<number | null>(value.DisplayId);

    const documentArchiveService = new DocumentArchiveService(
        linkProvider.business.api.currentBusiness().documentArchive
    );

    const { getDateTimeFormatForLocale } = useLocaleHelpers();

    const {
        isLoading: loading,
        data: response,
        mutate: updateDisplayIdApiCall,
    } = useMutation(
        async (request: UpdateDisplayIdResponseAndRequest) =>
            await documentArchiveService.updateDisplayId(request)
    );

    const {
        isLoading: deleteLoading,
        data: deleteResponse,
        mutate: deleteDocumentArchive,
    } = useMutation(
        async (documentArchiveId: number) =>
            await documentArchiveService.delete(documentArchiveId)
    );

    useEffect(() => {
        if (!loading && response && response.Data) {
            if (!checkDisplayIdIsInRange(response.Data.DisplayId)) {
                removeItem(value.Id);
            } else {
                updateDisplayId(response.Data);
                filterList();
            }
            showSweetAlertToast(
                t("common.success"),
                t("common.actions.displayIdUpdatedSuccessfully"),
                "success"
            );
        } else if (
            !loading &&
            response?.Errors &&
            response?.Errors.length > 0 &&
            response.Code
        ) {
            showSweetAlertToast(
                t("common.error.error"),
                response.Errors[0].Message,
                "error"
            );
        }
        setDisplayIdEditable(false);
    }, [response, loading]);

    const onDisplayIdBlur = () => {
        if (displayId != null && checkDisplayIdAlreadyExist(displayId)) {
            showSweetAlertToast(
                t("common.success"),
                t("templateDocumentManager.displayIdAlreadyInUse"),
                "error"
            );
            setDisplayIdEditable(false);
            setDisplayId(value.DisplayId);
            return;
        }
        if (displayId) {
            updateDisplayIdApiCall({
                DisplayId: displayId,
                Id: value.Id,
            });
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t("templateDocumentManager.displayIdInvalid"),
                "error"
            );
            setDisplayIdEditable(false);
            setDisplayId(value.DisplayId);
        }
    };

    const deleteRecord = (
        id: number,
        name?: string,
        fileExtension?: string
    ) => {
        if (fileExtension) {
            showSweetAlertToast(
                t("common.error.error"),
                t("documentArchive.documentExistError"),
                "error"
            );
            return;
        }
        showSweetAlertConfirmation(
            t("common.delete.title"),
            `${t("common.deleteMessage", { name: name })}  ${t(
                "documentArchive.archive"
            ).toLocaleLowerCase()}?`,
            () => deleteDocumentArchive(id),
            t("common.delete.yes")
        );
    };

    useEffect(() => {
        if (!deleteLoading && deleteResponse) {
            if (deleteResponse.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${value.Name}" ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
            }
            removeItem(value.Id);
        }
    }, [deleteResponse, deleteLoading]);

    return (
        <tr>
            <td
                onClick={() => {
                    setDisplayIdEditable(true);
                }}
                className={styles.displayId}
            >
                {hasEditPermission && isDisplayIdEditable ? (
                    <AppNumberInput
                        className={styles.fieldContainer}
                        classes={{ field: styles.displayField }}
                        value={value.DisplayId}
                        showClearButton={false}
                        showIcon={false}
                        showLabel={false}
                        formatTheInitialValue={false}
                        showEmptyField={true}
                        showPlaceHolder={false}
                        onChange={(e) => {
                            setDisplayId(e);
                        }}
                        onBlur={() => onDisplayIdBlur()}
                    />
                ) : (
                    <span>{formatNumericValue(value.DisplayId, "000")}</span>
                )}
            </td>
            <td>
                <AppIconWithName
                    iconPath={getIconByExtension(value.FileExtension)}
                    text={defaultTo(value.Name, "")}
                    className={styles.icon}
                />
            </td>

            <td>{value.CreatedAt?.format(getDateTimeFormatForLocale())}</td>
            <td>
                {value.CreationArea.map((x, index) => (
                    <span key={index}>
                        {t(
                            `templateDocumentManager.documentType.${x.toLowerCase()}`
                        )}
                        {index + 1 != value.CreationArea.length && ", "}
                    </span>
                ))}
            </td>
            <td>
                <AppIconButton
                    onClick={() => onEditClick(value.Id)}
                    buttonType="Edit"
                    readonly={!hasEditPermission || deleteLoading}
                />
            </td>
            <td>
                <AppDeleteButton
                    onClick={() =>
                        deleteRecord(value.Id, value.Name, value.FileExtension)
                    }
                    readonly={!hasDeletePermission || deleteLoading}
                />
            </td>
        </tr>
    );
};

export default DocumentArchiveTableRows;
