export const MealTemplateEnTranslations = {
    mealTemplateManagement: {
        addPrice: "Add Price",
        addToMealGroups: "Add to Meal Group",
        createMeal: "Create Meal",
        createMealGroup: "Create Meal Group",
        description: {
            missing: "Description is required",
            name: "Description",
            placeholder: "Description",
        },
        editMeal: "Edit Meal",
        editMealGroup: "Edit Meal Group",
        meals: "Meals",
        noMoreMealGroups: "No more meal groups...",
        noMoreMeals: "No more meals...",
        price: {
            minValue: "The price must be greater than zero.",
            name: "Price",
        },
        selectMeals: "Select Meals",
        tab: {
            mealGroups: "Meal Groups",
            meals: "Meals",
        },
        title: {
            missing: "Title is required",
            name: "Title",
            placeholder: "Title",
        },
        useInCompensations: "Use in Compensations",
    },
};

export default MealTemplateEnTranslations;
