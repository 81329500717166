import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppIconButton } from "components/Buttons";
import { appTableFilterSelectStyles } from "components/Table";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import {
    ClientContractOptionItem,
    CompensationTemplateType,
    KindergartenClientContractType,
    KindergartenClientContractValueItem,
} from "models";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./KindergartenClientContractRow.module.scss";

export interface KindergartenClientContractDropdownCellProps {
    value: KindergartenClientContractValueItem;
    options: ClientContractOptionItem[];
    key: string;
    type: KindergartenClientContractType;
    canEdit: boolean;
    onChange: (val: string) => void;
    copyContracts: (tillEnd: boolean) => void;
}

const Component: React.FC<KindergartenClientContractDropdownCellProps> = ({
    value,
    options,
    type,
    canEdit,
    onChange,
    copyContracts,
}) => {
    const { t } = useTranslation();
    const { getFormattedCurrency } = useLocaleHelpers();

    const { selectOptions, selected, selectedId } = useMemo(() => {
        const pleaseSelectOption = {
            label: "-", //t("common.pleaseSelect")
            value: null, // to reset value
        } as SimpleOption;

        const selectedOptions = [
            ...(type != CompensationTemplateType.MultiOption
                ? [pleaseSelectOption]
                : []),
            ...options.map(
                (o) =>
                    ({
                        label: o.Text,
                        value: o.Id,
                    } as SimpleOption)
            ),
        ];
        const useUuid = [
            CompensationTemplateType.CareScope,
            CompensationTemplateType.SalaryGroup,
            CompensationTemplateType.Surcharge,
        ];
        const selectedId = isNil(value)
            ? null
            : useUuid.includes(type as any)
            ? value.SpecialUuid
            : value.ValueId?.toString();

        return {
            selectedId: selectedId,
            selectOptions: selectedOptions,
            selected: (!isNil(selectedId)
                ? selectedOptions.find((o) => o.value == selectedId)
                : pleaseSelectOption) as SimpleOption,
        };
    }, [options, value, t]);

    return (
        <>
            <td>
                <AppSelectOld
                    menuPortalTarget={document.body}
                    options={selectOptions}
                    value={selected}
                    styles={appTableFilterSelectStyles}
                    isDisabled={!canEdit}
                    onChange={(opt: SimpleOption) => {
                        if (opt.value != selectedId) {
                            onChange(opt.value);
                        }
                    }}
                />
            </td>
            <td>
                <div className={styles.valueCell}>
                    <span>{getFormattedCurrency(value.Value)}</span>
                    <div className={styles.copyIcons}>
                        <AppIconButton
                            buttonType="Other"
                            showToolTip={true}
                            image={ImageAssets.common.copyBlue}
                            tooltipText={t("common.copy")}
                            readonly={!canEdit}
                            onClick={() => {
                                copyContracts(false);
                            }}
                        />
                        <AppIconButton
                            buttonType="Other"
                            image={ImageAssets.common.copyFilled}
                            showToolTip={true}
                            readonly={!canEdit}
                            tooltipText={t("common.copyToEnd")}
                            onClick={() => {
                                copyContracts(true);
                            }}
                        />
                    </div>
                </div>
            </td>
        </>
    );
};

Component.displayName = "KindergartenClientContractDropdownCell";
export const KindergartenClientContractDropdownCell = React.memo(Component);
export default KindergartenClientContractDropdownCell;
