import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { ConfirmationPageData } from "models";
import { AppPlatforms } from "models/general/enum";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { WithAuthLoader } from "..";
import ConfirmationPageContent from "screens/auth/partials/confirmation/ConfirmationPageContent";
import { ImageAssets } from "globals/images";

export const CONFIRM_EMAIL_PLATFORM_PARAM_KEY = "platform";
const CONFIRM_EMAIL_USER_ID_PARAM_KEY = "userId";
const CONFIRM_EMAIL_CODE_PARAM_KEY = "code";

export interface EmailConfirmationProps extends WithAuthLoader {
    value: ConfirmationPageData;
    onLogin: () => void;
}

export const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
    setLoading,
    value,
    onLogin,
}: EmailConfirmationProps) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const [data, setData] = useState<ConfirmationPageData | null>(null);

    const authService = new AuthService(linkProvider.noAuth.api);

    const {
        isLoading,
        isIdle,
        mutate: confirmEmail,
        data: response,
    } = useMutation(
        async (data: { userId: string; code: string }) =>
            await authService.confirmEmail(data.userId, data.code)
    );

    const [searchParams] = useSearchParams();

    const platform: AppPlatforms = defaultTo(
        searchParams.get(CONFIRM_EMAIL_PLATFORM_PARAM_KEY) as AppPlatforms,
        AppPlatforms.WEB
    );

    useEffect(() => {
        const userId = searchParams.get(CONFIRM_EMAIL_USER_ID_PARAM_KEY);
        const code = searchParams.get(CONFIRM_EMAIL_CODE_PARAM_KEY);
        if (userId && code) {
            confirmEmail({ userId, code });
            console.debug(userId, code);
        }
    }, []);
    useEffect(() => {
        setLoading(isLoading);
        if (!isIdle) {
            if (!isLoading && response && response.Data) {
                setData(value);
            }
            if (!isLoading && (!response || !response.Data)) {
                setData({
                    pageTitle: t(
                        "auth.confirmation.emailConfirmFailed.pageTitle"
                    ),
                    mainHeading: t(
                        "auth.confirmation.emailConfirmFailed.title"
                    ),
                    desc: response?.Message
                        ? response?.Message
                        : t("common.error.unexpectedError"),
                    imgUrl: ImageAssets.common.exclamationTriangleRed,
                });
            }
        }
    }, [isLoading, response]);

    return (
        <>
            {data && (
                <ConfirmationPageContent
                    value={data}
                    onLogin={
                        platform === AppPlatforms.WEB && response?.Data
                            ? onLogin
                            : undefined
                    }
                />
            )}
        </>
    );
};

export default EmailConfirmation;
