import { AppIconTextButton } from "components/Buttons";
import { AppFieldsWrapperContainer } from "components/Containers";
import { AppSortable } from "components/Sortable";
import { defaultTo } from "lodash-es";
import { CourseGroupField } from "models/businessCourses";
import { arrayMove, SortableElement } from "react-sortable-hoc";
import { ValidityState } from "models/general";
import CourseGroupsFieldRow from "screens/business/settingTabs/courses/partials/createEditPartials/CourseGroupsFieldRow";
import { getDefaultCourseGroupField } from "models/businessCourses/helper";
import { useTranslation } from "react-i18next";

export interface CourseGroupsFieldsContainerProps<CourseGroupField> {
    fields: CourseGroupField[];
    onChange: (newFields: CourseGroupField[]) => void;
    readonly?: boolean;
    validityState?: ValidityState;
}
const sortableItem = SortableElement(CourseGroupsFieldRow);
export const CourseGroupsFieldsContainer = <T extends CourseGroupField>({
    fields,
    onChange,
    readonly = false,
    validityState,
}: CourseGroupsFieldsContainerProps<T>) => {
    const fieldManager = getDefaultCourseGroupField();
    const { t } = useTranslation();

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (!readonly) {
            onChange(arrayMove(fields, oldIndex, newIndex));
        }
    };
    return (
        <AppFieldsWrapperContainer
            title={t("business.courses.createEdit.courseGroups")}
            isCollapsible={true}
        >
            <>
                {(!readonly || defaultTo(fields, []).length == 0) && (
                    <AppIconTextButton
                        text={t("common.addNew")}
                        icon={"plus"}
                        padding="med"
                        style={{ maxWidth: "180px", textAlign: "start" }}
                        disabled={readonly}
                        onClick={() => {
                            onChange([...fields, fieldManager as T]);
                        }}
                    />
                )}

                {fields && (
                    <AppSortable<T>
                        SortableItem={sortableItem}
                        list={fields}
                        handleSortEnd={handleSortEnd}
                        validityState={validityState}
                        readonly={readonly}
                        onChange={(field) => {
                            const oldIndex = fields.findIndex(
                                (f) => f.Uuid == field.Uuid
                            );
                            if (oldIndex >= 0) {
                                onChange(
                                    fields.map((nf) => {
                                        if (nf.Uuid == field.Uuid) {
                                            return field;
                                        } else {
                                            return nf;
                                        }
                                    })
                                );
                            } else {
                                onChange([...fields, field]);
                            }
                        }}
                        onDelete={(uuid: string) => {
                            onChange(fields.filter((f) => f.Uuid != uuid));
                        }}
                    />
                )}
            </>
        </AppFieldsWrapperContainer>
    );
};

export default CourseGroupsFieldsContainer;
