import { BusinessType } from "models";
import { DocumentTemplateRequest } from "models/documentTemplates";
import { Optional } from "models/general/models";
import { LillywaitRoutingContext } from "routing/routingContexts";
import {
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";

export const locationSettingRoutes = (
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: LillywaitRoutingContext = new LillywaitRoutingContext(),
    adminBase: string = "admin",
    locationId: string = ":locationId"
) => {
    const sectionApiBase = `location-settings`;
    const sectionBase = `${adminBase}/location-settings`;
    const base = `${context.getBaseUrl()}/${sectionBase}`;
    const apiBase = `${context.getApiBaseUrl()}/${sectionApiBase}`;

    return {
        base: base,
        apiBase: apiBase,
        api: {
            getList: (queryData?: {
                BusinessType?: BusinessType;
                LillywaitId?: string;
            }) => {
                return getUrlForPattern(
                    context,
                    true,
                    `${sectionApiBase}/list`,
                    true,
                    undefined,
                    queryData
                );
            },
            getLocationSettingsForClients: (obj: any) =>
                getUrlForPattern(
                    context,
                    true,
                    `${sectionApiBase}/get-location-settings-for-clients`,
                    true,
                    undefined,
                    obj
                ),
            withId: (encodedId?: string | ":locationId") =>
                ((idBase: string) => {
                    return {
                        getBasicInfo: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/get-basic-info`,
                                true
                            ),
                        getLocationClients: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/get-location-clients`,
                                true
                            ),
                        getLetterheadOptions: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/get-location-letterheads`,
                                true
                            ),
                        getDetails: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/get-details`,
                                true
                            ),
                        delete: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/delete`,
                                true
                            ),
                        update: () =>
                            getUrlForPattern(
                                context,
                                true,
                                `${idBase}/update`,
                                true
                            ),
                        documentTemplates: ((documentBase: string) => {
                            return {
                                getTemplateById: (templateId: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/:templateId`,
                                        true,
                                        { templateId: templateId }
                                    ),
                                getTemplates: (
                                    request: DocumentTemplateRequest
                                ) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/list`,
                                        true,
                                        undefined,
                                        { ...request }
                                    ),
                                updateDisplayId: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/update-display-id`,
                                        true
                                    ),
                                copyLWCTemplates: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/copy-lwc-templates`,
                                        true
                                    ),
                                copyTemplatesBetweenLocations: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/copy-templates-from-location`,
                                        true
                                    ),
                                delete: (templateId: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${documentBase}/delete/:templateId`,
                                        true,
                                        { templateId: templateId }
                                    ),
                            };
                        })(`${idBase}/document-templates`),
                        internalCompensationTemplate: ((
                            internalCompensationBase: string
                        ) => {
                            return {
                                list: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${internalCompensationBase}/list`,
                                        true
                                    ),
                                getTemplateById: (
                                    templateId: string,
                                    clone?: boolean,
                                    followup?: boolean
                                ) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${internalCompensationBase}/:templateId`,
                                        true,
                                        { templateId: templateId },
                                        clone || followup
                                            ? {
                                                  clone: clone,
                                                  followup: followup,
                                              }
                                            : undefined
                                    ),
                                create: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${internalCompensationBase}/create`,
                                        true
                                    ),
                                edit: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${internalCompensationBase}/edit`,
                                        true
                                    ),
                                delete: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${internalCompensationBase}/delete/:id`,
                                        true,
                                        { id: id }
                                    ),
                            };
                        })(`${idBase}/internal-compensation-template`),
                        modulePlans: ((modulePlanBase: string) => {
                            return {
                                list: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${modulePlanBase}/list`,
                                        true
                                    ),
                                get: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${modulePlanBase}/:id`,
                                        true,
                                        {
                                            id: id,
                                        }
                                    ),
                                createUpdate: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${modulePlanBase}/create-update`,
                                        true
                                    ),
                                delete: (id: string) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${modulePlanBase}/delete/:id`,
                                        true,
                                        {
                                            id: id,
                                        }
                                    ),
                            };
                        })(`${idBase}/module-plans`),
                    };
                })(`${sectionApiBase}/${encodedId ? encodedId : locationId}`),
        },
        screens: {
            list: () => {
                return getUrlForPattern(context, buildUrl, sectionBase);
            },
            create: () =>
                getUrlForPattern(context, true, `${sectionBase}/create`),
            withId: (locationEncodedId?: Optional<string> | ":locationId") =>
                ((idBase: string) => {
                    const subPathBase = buildUrl
                        ? `${sectionBase}/${idBase}/`
                        : ``;
                    return {
                        base: () => `${sectionBase}/${idBase}`,
                        edit: () => {
                            return getUrlForPattern(
                                context,
                                buildUrl,
                                `${subPathBase}edit`
                            );
                        },
                        settings: () =>
                            getUrlForPattern(
                                context,
                                buildUrl,
                                `${subPathBase}settings`
                            ),
                        workingHours: () =>
                            getUrlForPattern(
                                context,
                                buildUrl,
                                `${subPathBase}working-hours`
                            ),
                        publicHolidays: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}public-holidays`),
                        vouchers: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}vouchers`),
                        affiliate: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}affiliates`),
                        companyDataFields: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}company-data`),
                        emailTemplate: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}email-template`),
                        explanationMode: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl,
                                false
                            );
                        })(`${subPathBase}explanation-mode`),
                        compensationTemplates: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}compensations-templates`),
                        employeeKeyCalculations: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}employee-key-calculations`),
                        careScopes: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}care-scopes`),
                        ageGroups: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}age-groups`),
                        salaryGroups: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}salary-groups`),
                        areas: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}areas`),
                        mealTemplates: ((sectionBase: string) => {
                            return {
                                ...getCrudUrlsForSection(
                                    sectionBase,
                                    context,
                                    buildUrl
                                ),
                                meals: (encodedId: string | ":id" = ":id") =>
                                    getUrlForPattern(
                                        context,
                                        buildUrl,
                                        `${sectionBase}/${encodedId}/meals`
                                    ),
                            };
                        })(`${subPathBase}meal-templates`),
                        functions: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}functions`),
                        letterheads: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}letterheads`),
                        modules: () =>
                            getUrlForPattern(
                                context,
                                buildUrl,
                                `${subPathBase}module-plan`
                            ),
                        documentTemplates: ((sectionBase: string) => {
                            return getCrudUrlsForSection(
                                sectionBase,
                                context,
                                buildUrl
                            );
                        })(`${subPathBase}document-templates`),
                    };
                })(`${locationEncodedId ? locationEncodedId : locationId}`),
        },
    };
};

export default locationSettingRoutes;
