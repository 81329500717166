import classNames from "classnames";
import React from "react";
import { AppLoader } from "./AppLoader";
import styles from "./AppOverlayLoader.module.scss";

interface AppOverlayLoaderProps {
    iconClass?: string;
    fullHeight?: boolean;
    blur?: boolean;
    opaque?: boolean;
    className?: string;
}

export const AppOverlayLoader: React.FC<AppOverlayLoaderProps> = ({
    opaque = false,
    blur = false,
    fullHeight = true,
    iconClass = "",
    className = "",
}) => {
    return (
        <div
            className={classNames(
                className,
                styles.loadingOverlay,
                { [styles.fullHeight]: fullHeight },
                {
                    [styles.blur]: blur,
                },
                {
                    [styles.opaque]: opaque,
                }
            )}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <AppLoader
                iconClass={`${iconClass} large-progress-icon`}
                fullHeight={fullHeight}
            />
        </div>
    );
};

export default AppOverlayLoader;
