import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import styles from "./AppCollapseToggleButton.module.scss";
export interface AppCollapseToggleButtonProps {
    visible: boolean;
    className?: string;
    onClick?: () => void;
    iconClassName?: string;
}
export const AppCollapseToggleButton: React.FC<
    AppCollapseToggleButtonProps
> = ({ visible, className, onClick, iconClassName }) => {
    return (
        <div onClick={onClick} className={`${styles.toggleIcon} ${className}`}>
            <FontAwesomeIcon
                icon={"chevron-down"}
                className={classNames(iconClassName, styles.icon, {
                    [styles.rotate]: visible,
                })}
            />
        </div>
    );
};

export default AppCollapseToggleButton;
