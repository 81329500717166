import { AppResponse } from "models/general/response";
import { FileDataColumn } from "models/importData";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IBusinessImportDataService {
    getFileColumns(file: File): Promise<AppResponse<FileDataColumn[]>>;
    importClients(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>>;
    importClientRegistrations(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>>;
    importEmployee(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>>;
}

const businessApiLinkProvider =
    routesForContext().business.api.currentBusiness().importData;
type ApiLinkProviderType = typeof businessApiLinkProvider;

export function getBusinessImportDataServiceKey(
    name: keyof IBusinessImportDataService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `importData_${name}_${JSON.stringify(data)}`;
}

export class BusinessImportDataService
    extends BaseService
    implements IBusinessImportDataService
{
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }

    constructFormData(
        file: File,
        mappings?: { [k in string]: string | null },
        dateFormat?: string
    ) {
        const fD = new FormData();
        if (mappings) {
            fD.append("Mapping", JSON.stringify(mappings));
        }
        if (dateFormat) {
            fD.append("dateFormat", dateFormat);
        }
        fD.append("file", file);

        return fD;
    }

    getFileColumns(file: File): Promise<AppResponse<FileDataColumn[]>> {
        const fD = this.constructFormData(file);
        return this.doServerXHR<string[]>({
            url: this.baseRoute.getColumns(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }

    importClients(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>> {
        const fD = this.constructFormData(file, mappings, dateFormat);
        return this.doServerXHR<boolean>({
            url: this.baseRoute.importClients(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }

    importClientRegistrations(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>> {
        const fD = this.constructFormData(file, mappings, dateFormat);
        return this.doServerXHR<boolean>({
            url: this.baseRoute.importClientRegistrations(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }

    importEmployee(
        file: File,
        mappings: { [k in string]: string | null },
        dateFormat: string
    ): Promise<AppResponse<boolean>> {
        const fD = this.constructFormData(file, mappings, dateFormat);
        return this.doServerXHR<boolean>({
            url: this.baseRoute.importEmployees(),
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: fD,
        });
    }
}
