import classNames from "classnames";
import { defaultTo } from "lodash-es";
import { MetisMenuClassStoreType, MetisMenuItemType } from "models/metisMenu";
import React from "react";
import MetisMenuItem from "./MetisMenuItem";
export interface MetisMenuContainerProps {
    visible?: boolean;
    items: MetisMenuItemType[];
    classStore: MetisMenuClassStoreType;
    toggleMenu?: (value: number) => void;
}
export const MetisMenuContainer = React.memo<MetisMenuContainerProps>(
    ({ classStore, items, visible, toggleMenu }) => {
        return (
            <ul
                className={classNames(
                    classStore.classContainer,
                    visible && classStore.classContainerVisible
                )}
            >
                {items.map((item, i) => (
                    <MetisMenuItem
                        onClick={item.onItemClick}
                        key={defaultTo(item.to, `_${i}`)}
                        toggleMenu={toggleMenu}
                        classStore={classStore}
                        item={item}
                    />
                ))}
            </ul>
        );
    }
);

export default MetisMenuContainer;
