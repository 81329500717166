import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";
import styles from "./AppSwitch.module.scss";

export interface AppSwitchProps
    extends Omit<FormCheckInputProps, "value" | "onChange"> {
    label: string;
    value: boolean;
    style?: CSSProperties;
    labelPosition?: "right" | "left";
    onChange: (value: boolean) => void;
    className?: string;
    disabled?: boolean;
    extraContent?: ReactNode;
    color?: "blue-toggle" | "red-toggle" | "green-toggle";
    hideLabel?: boolean;
}
function Component(props: AppSwitchProps, ref: any) {
    const {
        label,
        onChange,
        style = { marginRight: "-6px" },
        value,
        labelPosition = "right",
        className = "",
        color = "blue-toggle",
        extraContent,
        disabled,
        hideLabel: isHideLabel = false,
        ...rest
    } = props;

    return (
        <div
            className={`${!disabled ? color : ""} ${className} d-flex `}
            style={style}
            ref={ref}
        >
            {!isHideLabel && labelPosition === "left" && (
                <Form.Label style={{ marginRight: 10 }}>{label}</Form.Label>
            )}

            <Form.Check
                type="switch"
                id={`${label.split(" ").join("")}_${uuid()}`}
                label={!isHideLabel && labelPosition === "right" && label}
                checked={value}
                className={classNames({ [styles.disabled]: disabled })}
                disabled={disabled}
                onChange={(e) => {
                    onChange(e.target.checked);
                }}
                {...rest}
            />
            {extraContent && extraContent}
        </div>
    );
}

export const AppSwitch = React.forwardRef(Component);
export default AppSwitch;
