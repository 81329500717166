import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { getInitializedValidityState } from "models/general";
import { KindergartenClientRegistration } from "models/kindergartenClientRegistration/model";
import { t } from "i18next";
import { defaultTo } from "lodash-es";

export function validateClientRegistrationBasicInfo(
    obj: KindergartenClientRegistration,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof KindergartenClientRegistration> = {
        FirstName: [
            {
                rule: Validations.REQUIRED,
                message: t("kindergartenClient.basicInfo.firstName.missing"),
            },
        ],
        Gender: [
            {
                rule: Validations.REQUIRED,
                message: t("common.gender.missing"),
            },
        ],
        CountryCode: [
            {
                rule: Validations.REQUIRED,
                message: t(
                    "kindergartenClient.registration.nationality.missing"
                ),
            },
        ],
        // AgeGroupFieldUuid: [
        //     {
        //         rule: Validations.REQUIRED,
        //         message: t("kindergartenClient.registration.ageGroup.missing"),
        //     },
        // ],
        CareScopeFieldUuid: [
            {
                rule: Validations.REQUIRED,
                message: t(
                    "kindergartenClient.registration.scopeOfCare.missing"
                ),
            },
        ],
        Birthday: [
            {
                rule: Validations.REQUIRED,
                message: t("kindergartenClient.basicInfo.birthday.missing"),
            },
        ],
        Status: [
            {
                rule: Validations.REQUIRED,
                message: t("kindergartenClient.registration.status.missing"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(obj, {}),
        state
    );

    return state;
}
