export enum DocumentTemplateTabType {
    OWN_DOCUMENTS = "OWN_DOCUMENTS",
    TEMPLATE_LIBRARY = "TEMPLATE_LIBRARY",
}
export enum DocumentTag {
    Employee = "Employee",
    Client = "Client",
    Business = "Business",
    Client_List = "Client_List",
    Employee_List = "Employee_List",
}

export enum DocumentType {
    Employee = "Employee",
    Client = "Client",
    Client_List = "Client_List",
    Employee_List = "Employee_List",
}
export enum DocumentTemplateSortColumn {
    Name = 0,
    Type = 1,
    CreatedAt = 2,
    DisplayId = 3,
    ClientDisplayId = 4,
    EmployeeDisplayId = 5,
}
