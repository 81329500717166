import { defaults, defaultTo, isNil } from "lodash-es";
import React, { useRef } from "react";
import { Props } from "react-select/src/Select";
import { FixedSizeListProps } from "react-window";
import {
    COLOR_BLUE_LIGHT,
    COLOR_BORDER_GREY,
    COLOR_GREY_LIGHTEST,
    COLOR_PRIMARY,
    COLOR_TEXT_DARK,
    INPUT_COLOR,
} from "theme/themeConstants";
import { MenuList as ReactWindowMenuList } from "./CustomComponents";
import { CustomSelectComponentSelectProps, CustomSelectProps } from "./types";
import { v4 as uuid } from "uuid";

const ccomponents = {
    ReactWindowMenuList,
};

function Component<
    TProps extends CustomSelectProps<SProps, OptionType>,
    SProps extends Props<OptionType>,
    OptionType
>({ components, ...props }: TProps) {
    const {
        styles,
        showDropdownIndicator,
        fullWidth,
        allowReload,
        useReactWindow = false,
        overrideStyles = false,
        onReload,
        fixedSizeListProps,
    } = props;
    // const classes = useStyles(props);

    defaults(
        components,
        useReactWindow
            ? {
                  MenuList: ccomponents.ReactWindowMenuList,
              }
            : {}
    );

    // if (components) {
    //     const temp = components as any;
    //     const rand = uuid();
    //     const Input = ({ autoComplete, ...props }: any) => (
    //         <temp.Input {...props} id={rand} autoComplete={rand} />
    //     );
    //     components = {
    //         ...components,
    //         Input,
    //     };
    // }
    const extraInjectedProps: CustomSelectComponentSelectProps = {
        // classes,
        controlProps: {
            fullWidth: fullWidth,
            allowReload,
            onReload,
        },
        fixedSizeListProps: fixedSizeListProps
            ? fixedSizeListProps
            : ({} as FixedSizeListProps),
    };

    const TComponent: any | undefined = props.Component;

    return (
        <TComponent
            {...props}
            components={components}
            inputProps={{ autoComplete: uuid() }}
            theme={(theme: any) => ({
                ...theme,
                borderRadius: 3,
                fontSize: "0.95rem",
                colors: {
                    ...theme.colors,
                    text: "black !important",
                    primary75: COLOR_BLUE_LIGHT,
                    primary: COLOR_PRIMARY,
                },
            })}
            {...extraInjectedProps}
            styles={{
                input: (base: any) => ({
                    ...base,
                    borderWidth: 0,
                    color: COLOR_TEXT_DARK,
                    fontSize: "0.95rem",
                    ...defaultTo(styles?.input, {}),
                }),
                menuPortal: (base: any) => ({
                    ...base,
                    zIndex: base.zIndex && base.zIndex > 20 ? base.zIndex : 20,
                    ...defaultTo(styles?.menuPortal, {}),
                }),
                menu: (base: any) => ({
                    ...base,
                    marginTop: "2px",
                    zIndex: 6,
                    borderRadius: "0.25rem",
                    ...defaultTo(styles?.menu, {}),
                }),
                control: (base: any, state: any) => {
                    const temp = {
                        ...base,
                        color: INPUT_COLOR,
                        borderColor: `${COLOR_BORDER_GREY} !important`,
                        borderRadius: "0.25rem",
                        // ...(props.isMulti
                        //     ? { padding: "4px 0px", margin: "-4px" }
                        //     : {}),
                        ...defaultTo(styles?.control, {}),
                    };

                    return state.isFocused
                        ? {
                              ...temp,
                              backgroundColor: "white",
                              borderColor: `${COLOR_PRIMARY} !important`,
                              borderWidth: "1px",
                              boxShadow: "0 0 0 0.2rem #e1f3ff !important",
                              outline: 0,
                          }
                        : temp;
                },
                option: (base: any, state: any) => ({
                    ...base,
                    fontWeight: 400,
                    fontSize: "0.95rem",
                    color: state.isSelected ? "white" : COLOR_TEXT_DARK,
                    ...defaultTo(styles?.option, {}),
                }),
                indicatorSeparator: (base: any) => ({
                    ...base,
                    display: "none",
                    ...defaultTo(styles?.indicatorSeparator, {}),
                }),
                valueContainer: (base: any) => ({
                    ...base,
                    overflowY: "auto",
                    minHeight: "42px",
                    maxHeight: "150px",
                    ...defaultTo(styles?.valueContainer, {}),
                }),
                multiValue: (base: any) => ({
                    ...base,
                    background: COLOR_GREY_LIGHTEST,
                    borderRadius: "4px",
                    padding: "0px",
                    fontSize: "0.925rem !important",
                    margin: "1px",
                    ...defaultTo(styles?.multiValue, {}),
                }),
                multiValueLabel: (base: any) => ({
                    ...base,
                    color: COLOR_TEXT_DARK,
                    padding: "0px",
                    fontSize: "0.925rem !important",
                    ...defaultTo(styles?.multiValueLabel, {}),
                }),
                singleValue: (base: any) => ({
                    ...base,
                    ...defaultTo(styles?.singleValue, {}),
                }),
                indicatorsContainer: (base: any) => ({
                    ...base,
                    minHeight: "100%",
                    ">img": {
                        display: "flex",
                    },
                    ...defaultTo(styles?.indicatorsContainer, {}),
                }),
                indicatorContainer: (base: any) => ({
                    ...base,
                    ...defaultTo(styles?.indicatorContainer, {}),
                }),
                dropdownIndicator: (base: any) => ({
                    ...base,
                    padding: "5px",
                    height: "100%",
                    display: !showDropdownIndicator ? "none" : "flex",
                    alignItems: "center",
                    ...defaultTo(styles?.dropdownIndicator, {}),
                }),
                ...(overrideStyles ? styles : {}),
            }}
        />
    );
}
Component.displayName = "CustomSelect";
Component.defaultProps = {
    menuShouldBlockScroll: false,
    showDropdownIndicator: true,
    hideSelectedOptions: false,
    components: ccomponents,
    filterOption: undefined,
    isMulti: false,
    isSearchable: true,
    placeholder: "",
    isClearable: false,
    allowReload: false,
    onReload: () => {},
    value: null,
    fullWidth: false,
};

export const CustomSelectOld = Component;
export default CustomSelectOld;
