import { AgeGroupTemplateCreateEdit } from "commonPartials";
import {
    CLONE_PARAM_KEY,
    FOLLOWUP_PARAM_KEY,
    TEMPLATE_ID_PARAM_KEY,
} from "globals/queryParams";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Business_AgeGroups, LW_Kindergarten_AgeGroups } from "models";
import React from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCurrentBusinessSettingsContext } from "../../hqAndBranches/partials/BusinessTabs";

export const BusinessAgeGroupTemplateCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const { getPermissionMap: getBusinessPermissionMap } =
        useBusinessCheckPermission();
    const { getPermissionMap } = useCheckPermission();
    const [searchParams] = useSearchParams();

    const parentId = searchParams.get(TEMPLATE_ID_PARAM_KEY);
    const isClone = searchParams.get(CLONE_PARAM_KEY) == "true";
    const isFollowup = searchParams.get(FOLLOWUP_PARAM_KEY) == "true";
    // const locationTemplateId = searchParams.get(LOCATION_TEMPLATE_ID_PARAM_KEY);

    const currentBusiness = useCurrentBusinessSettingsContext();

    const isLwc = !isNil(lwcId);
    const lwcBase = linkProvider.lillywait.clients().screens;
    const linkProviderBase = (
        isLwc
            ? lwcBase.kindergartens.withId()
            : linkProvider.business.businessSettings().screens
    ).ageGroups;

    const permMap = !isLwc
        ? getBusinessPermissionMap(
              currentBusiness?.dataItem?.Id as number,
              currentBusiness?.dataItem?.HqId as number,
              [Business_AgeGroups]
          )
        : getPermissionMap([LW_Kindergarten_AgeGroups]);
    const useLocationData = defaultTo(
        currentBusiness.dataItem?.LocationPreferences?.AgeGroup,
        true
    );

    return (
        <AgeGroupTemplateCreateEdit
            id={parentId}
            currentBusiness={currentBusiness.dataItem}
            locationId={currentBusiness.dataItem?.LocationSettingId}
            basePathProvider={linkProviderBase}
            permissionsMap={permMap}
            createOptions={{ clone: isClone, followup: isFollowup }}
            useLocationData={useLocationData}
        />
    );
};

export default BusinessAgeGroupTemplateCreate;
