import classNames from "classnames";
import { AppButton } from "components/Buttons";
import { FULL_DATE, YEAR_MONTH_DATE_FORMAT } from "globals/constants";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { Optional } from "models/general";
import moment, { Moment } from "moment-timezone";
import { useRef, useState } from "react";
import { Image } from "react-bootstrap";
import styles from "./AppButtonDatePicker.module.scss";
import { AppDatePicker } from "./AppDatePicker";
import { AppMonthYearPicker } from "./AppMonthYearPicker";
import { DatePickerType } from "models/datepicker";
import { AppTooltip } from "components/Tooltips";
import { isNil } from "lodash-es";

export interface AppButtonDatepickerProps {
    onChange: (newDate: Optional<Moment>) => void;
    value: Moment;
    readonly?: boolean;
    pickerType?: DatePickerType.DATE | DatePickerType.MONTH_YEAR;
    minDate?: Moment;
    maxDate?: Moment;
    className?: string;
    tooltip?: string;
}

export function AppButtonDatepicker(props: AppButtonDatepickerProps) {
    const {
        value,
        readonly = false,
        pickerType = DatePickerType.DATE,
        minDate,
        onChange,
        maxDate,
        className,
        tooltip,
    } = props;

    const displayFormat =
        pickerType === DatePickerType.DATE ? FULL_DATE : YEAR_MONTH_DATE_FORMAT;

    const { formatDate } = useLocaleHelpers();

    const [pickerOpen, setPickerOpen] = useState(false);
    const toggleRef = useRef<any>(undefined);

    const Component =
        pickerType === DatePickerType.MONTH_YEAR
            ? AppMonthYearPicker
            : AppDatePicker;

    const content = (
        <div className={classNames(styles.root, className)}>
            <AppButton
                rounded={true}
                ref={toggleRef}
                onClick={() => {
                    // for some reason onClick is not working here
                    if (!readonly) {
                        setPickerOpen((old) => !old);
                    }
                }}
                className={`${styles.button}`}
            >
                <>
                    {formatDate(value ? value : moment(), displayFormat)}
                    <Image
                        className={styles.icon}
                        src={ImageAssets.common.calendarAlt}
                    />
                </>
            </AppButton>

            {pickerOpen && (
                <div style={{ position: "absolute", left: 0, bottom: 5 }}>
                    <Component
                        value={value}
                        onChange={onChange}
                        maxDate={maxDate}
                        toggleRef={toggleRef}
                        appendToBody={true}
                        minDate={minDate}
                        setOpen={setPickerOpen}
                        shouldCloseOnSelect={true}
                        open={pickerOpen}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        customInput={<></>}
                    />
                </div>
            )}
        </div>
    );
    return !isNil(tooltip) ? (
        <AppTooltip
            content={tooltip}
            allowHtml={false}
            trigger="mouseenter focus"
            arrow={true}
        >
            {content}
        </AppTooltip>
    ) : (
        content
    );
}

export default AppButtonDatepicker;
