import {
    DashboardCardTypes,
    DashboardStatsWithPersonInfo,
    DashboardTabs,
} from "models/dashboard";
import { BusinessPerson } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./DashboardCardSection.module.scss";
import { DashboardPersonInfoPopper } from "./DashboardPersonInfoPopper";
import { FORMAT_INTEGER } from "globals/constants";
import { defaultTo } from "lodash-es";
import useLocaleHelpers from "hooks/general/localeHelpers";
interface DashboardCardSectionProps {
    title: string;
    heading: string;
    statsWithPersonInfo: DashboardStatsWithPersonInfo;
    cardType: DashboardCardTypes;
    showPersonInfo?: boolean;
    className?: string;
    persons?: BusinessPerson[];
    selectedTab: DashboardTabs;
}
const Component: React.FC<DashboardCardSectionProps> = ({
    title,
    heading,
    statsWithPersonInfo: statsWithPersonInfo,
    cardType,
    showPersonInfo = false,
    className = "",
    persons,
    selectedTab,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    const getPersons = () => {
        if (statsWithPersonInfo.PersonIds.length > 0) {
            return persons?.filter(
                (x) =>
                    statsWithPersonInfo.PersonIds.filter((y) => y === x.Id)
                        .length !== 0
            );
        }
        return [];
    };

    return (
        <div className={`${styles[cardType]} ${className}`}>
            <span className={styles.heading}>{heading}</span>
            <div className={styles.count}>
                {formatNumber(
                    defaultTo(statsWithPersonInfo.Average, 0) > 0
                        ? defaultTo(statsWithPersonInfo.Average, 0)
                        : statsWithPersonInfo.Count,
                    FORMAT_INTEGER
                )}
                {showPersonInfo && statsWithPersonInfo.Count > 0 && (
                    <DashboardPersonInfoPopper
                        mainHeading={`${title} ${
                            selectedTab === DashboardTabs.CLIENTS
                                ? t("common.clients")
                                : t("common.employees")
                        }`}
                        subHeading={heading}
                        className={styles.marginTopSix}
                        persons={getPersons()}
                    ></DashboardPersonInfoPopper>
                )}
            </div>
        </div>
    );
};
Component.displayName = "DashboardCardSection";
export const DashboardCardSection = React.memo(Component);
export default DashboardCardSection;
