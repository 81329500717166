import moment from "moment-timezone";
import { UserRoleManagementItem, UserRoleManagementItemBase } from "./model";

export interface UserRoleManagementItemResponse
    extends UserRoleManagementItemBase {
    CreatedAt: number;
}

export const parseUserItemResponse = (
    req: UserRoleManagementItemResponse
): UserRoleManagementItem => {
    return { ...req, CreatedAt: moment.unix(req.CreatedAt) };
};
