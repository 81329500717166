import { ISO8601_DATE_FORMAT } from "globals/constants";
import { BusinessCoursesSortColumn } from "models/businessCourses/enum";
import { BusinessCourse, CourseGroupField } from "./model";
import { BusinessCourseCreateEditRequest } from "./request";
import { BusinessCourseResponse } from "./response";
import moment from "moment-timezone";
import { v4 } from "uuid";

export const getBusinessCoursesFromResponse = (
    req: BusinessCourseResponse
): BusinessCourse => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
    } as BusinessCourse;
};

export const getBusinessCoursesSortColumnKeyFromEnum = (
    sortColumn: BusinessCoursesSortColumn | undefined
) => {
    switch (sortColumn) {
        case BusinessCoursesSortColumn.Name:
            return "Name";
        case BusinessCoursesSortColumn.ShortName:
            return "ShortName";
        case BusinessCoursesSortColumn.Floor:
            return "Floor";
        case BusinessCoursesSortColumn.Area:
            return "Area";
        case BusinessCoursesSortColumn.StartDate:
            return "StartDate";
        case BusinessCoursesSortColumn.EndDate:
            return "EndDate";
        default:
            return "StartDate";
    }
};

export const getBusinessCoursesCreateEdit = (
    req: BusinessCourse
): BusinessCourseCreateEditRequest => {
    return {
        ...req,
    } as BusinessCourseCreateEditRequest;
};

export const getDefaultCourseGroupField = (): CourseGroupField => {
    return {
        Uuid: v4(),
        GroupName: "",
        GroupShortName: "",
        CourseId: 0,
        Order: 0,
    };
};

export const getDefaultBusinessCourses =
    (): BusinessCourseCreateEditRequest => {
        const fields: CourseGroupField[] = [];

        return {
            StartDate: moment(),
            Id: 0,
            Name: "",
            BusinessId: 0,
            ShortName: "",
            Groups: fields,
        } as BusinessCourseCreateEditRequest;
    };
