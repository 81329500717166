export const ErrorEnTranslations = {
    commonErrors: {
        required: "{{name}} is required",
    },
    errorBoundary: {
        reportAdditionalFeedback: "Report additional feedback",
        somethingWentWrong: "Something went wrong...",
        message:
            "We have received the response and we will try to resolve it ASAP.",
    },
    errorPage: {
        button: {
            home: "Home",
            login: "Login",
        },
        error400: {
            desc: "The request is not in valid format",
            title: "Bad Request",
        },
        error401: {
            desc: "You are not authorized to access this data",
            title: "No access rights",
        },
        error403: {
            desc: "You don't have permissions to access this data",
            title: "Forbidden",
        },
        error404: {
            desc: "Seems like we've lost a page. Lilly will be back in 10 minutes and they're looking for ...",
            title: "Page not found",
        },
        error500: {
            desc1: "An error has occurred.",
            desc2: "We apologize for this!",
            title: "Error",
        },
        error501: {
            desc: "This function is not yet functional.",
            title: "In development",
        },
    },
};

export default ErrorEnTranslations;
