import React from "react";
import Skeleton from "react-loading-skeleton";

export interface TodoManagementTableSkeltonProps {
    count: number;
}

export const TodoManagementTableSkelton: React.FC<
    TodoManagementTableSkeltonProps
> = ({ count }) => {
    return (
        <>
            {Array(count)
                .fill(count)
                .map((e, i) => (
                    <tr key={i}>
                        <td style={{ minWidth: "120px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "200px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "200px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "200px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "200px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "210px" }}>
                            <Skeleton />
                        </td>
                        <td style={{ minWidth: "200px" }}>
                            <Skeleton />
                        </td>
                        <td
                            style={{
                                width: "100px",
                                minWidth: "100px",
                                maxWidth: "100px",
                            }}
                        >
                            <Skeleton />
                        </td>
                        <td
                            style={{
                                width: "200px",
                                minWidth: "200px",
                                maxWidth: "200px",
                            }}
                        >
                            <Skeleton />
                        </td>
                        <td
                            style={{
                                width: "150px",
                                minWidth: "150px",
                                maxWidth: "150px",
                            }}
                        >
                            <Skeleton />
                        </td>
                        <td
                            style={{
                                width: "50px",
                                minWidth: "50px",
                                maxWidth: "50px",
                            }}
                        >
                            <Skeleton />
                        </td>
                        <td
                            style={{
                                width: "50px",
                                minWidth: "50px",
                                maxWidth: "50px",
                            }}
                        >
                            <Skeleton />
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default TodoManagementTableSkelton;
