import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { AppLayoutBase } from "layouts/appLayout";
import React, { useState } from "react";
import { HamburgerSlider } from "react-animated-burgers";
import Button from "react-bootstrap/Button";
import { COLOR_PRIMARY } from "theme/themeConstants";

export interface AppMobileMenuProps extends AppLayoutBase {}
export interface AppMobileMenuState {
    active: boolean;
    mobile: boolean;
    activeSecondaryMenuMobile: boolean;
}
export const AppMobileMenu: React.FC<AppMobileMenuProps> = ({
    enableMobileMenuRight: enableMobileMenuSmall,
    setEnableMobileMenu,
    setEnableMobileMenuRight: setEnableMobileMenuSmall,
    enableMobileMenu,
}) => {
    const [state, setState] = useState<AppMobileMenuState>({
        active: false,
        mobile: false,
        activeSecondaryMenuMobile: false,
    });
    const toggleMobileSidebar = () => {
        setEnableMobileMenu(!enableMobileMenu);
    };

    const toggleMobileSmall = () => {
        setEnableMobileMenuSmall(!enableMobileMenuSmall);
    };

    return (
        <>
            <div className="app-header__mobile-menu">
                <div onClick={toggleMobileSidebar}>
                    <HamburgerSlider
                        isActive={enableMobileMenu}
                        barColor={COLOR_PRIMARY}
                        buttonWidth={24}
                        toggleButton={() =>
                            setState({
                                ...state,
                                mobile: !state.mobile,
                            })
                        }
                    />
                </div>
            </div>
            <div className="app-header__menu">
                <span onClick={toggleMobileSmall}>
                    <Button
                        size="sm"
                        className={cx("btn-icon btn-icon-only", {
                            active: state.activeSecondaryMenuMobile,
                        })}
                        style={{ background: COLOR_PRIMARY }}
                        onClick={() =>
                            setState({
                                ...state,
                                activeSecondaryMenuMobile:
                                    !state.activeSecondaryMenuMobile,
                            })
                        }
                    >
                        <div className="btn-icon-wrapper">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </div>
                    </Button>
                </span>
            </div>
        </>
    );
};

export default AppMobileMenu;
