import { TIME_FORMAT_12H } from "globals/constants";
import { loadFromStorage } from "globals/helpers/generalHelper";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import i18next from "i18next";
import { defaultTo } from "lodash-es";
import {
    ChannelCacheDTO,
    ChannelResponseModel,
    getChatCacheResponse,
} from "models/chat";
import { AppResponse, Optional } from "models/general";
import moment from "moment";
import { Moment } from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChatService } from "services/business/ChatService";
import { useLocaleHelpers } from "../general/localeHelpers";
export const useChatHelper = () => {
    const { getDateFormatForLocale, isToday, isYesterday } = useLocaleHelpers();
    const { sessionBusiness } = useSessionBusiness();
    const { user } = useSessionUser();
    const toReturn = useMemo(() => {
        return {
            todayYesterdayOtherDateHandler: (
                date: Date | string | Moment,
                // eslint-disable-next-line @typescript-eslint/no-inferrable-types
                showTimeForToday: boolean = false,
                showDateWithTodayOrYesterday: boolean = false
            ): Optional<string> => {
                if (date == null) {
                    return null;
                }
                // if date is for today -> show time (if flag is true) else show "Today"
                // if yesterday show "Yesterday"
                // Else show shortDate
                let momentDateTime: Moment;
                if (typeof date == "string") {
                    momentDateTime = moment(date, getDateFormatForLocale());
                } else {
                    momentDateTime = moment(date);
                }
                if (isToday(momentDateTime)) {
                    return showTimeForToday
                        ? momentDateTime.format(TIME_FORMAT_12H)
                        : showDateWithTodayOrYesterday
                        ? `${i18next.t(
                              "common.date.today"
                          )}, ${momentDateTime.format("D MMMM")}`
                        : i18next.t("common.date.today");
                } else if (isYesterday(momentDateTime)) {
                    return showDateWithTodayOrYesterday
                        ? `${i18next.t(
                              "common.date.yesterday"
                          )}, ${momentDateTime.format("D MMMM")}`
                        : i18next.t("common.date.yesterday");
                } else {
                    return momentDateTime.format(getDateFormatForLocale());
                }
            },
            getChatCacheKey: () => `${user?.Id}_${sessionBusiness?.Id}`,
            userId: defaultTo(user?.Id, ""),
            businessId: defaultTo(sessionBusiness?.Id, 0),
        };
    }, [getDateFormatForLocale]);
    return toReturn;
};
export const useGetCachedChannels = (chatCacheKey: string) => {
    const { linkProvider } = useRouting();
    const service = new ChatService(linkProvider.business.api.chat);
    const [response, setResponse] = useState<AppResponse<
        ChannelResponseModel[]
    > | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const refetch = useCallback(() => {
        // load from local storage (if exist)
        const localCache: ChannelCacheDTO[] = loadFromStorage(chatCacheKey);
        if (!localCache) {
            // if not in local cache load from redis cache (saved for 7 days)
            setLoading(true);
            return service
                .getCachedChannels()
                .then((val) => {
                    setResponse({
                        ...val,
                        Data: val.Data
                            ? getChatCacheResponse(
                                  JSON.parse(val.Data) as ChannelCacheDTO[]
                              )
                            : [],
                    });
                    setLoading(false);
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
            setResponse({
                Data: getChatCacheResponse(localCache),
            });
        }
    }, []);
    useEffect(() => {
        refetch();
    }, []);
    return {
        response,
        loading,
        refetch,
    };
};
