import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { AppTabButtonProps, AppTabButtons } from "components/Tabs";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_RoleManagement_PermissionGroups_Web,
    PermissionAccessTypes,
} from "models";
import { AppPlatforms } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./UserManagementHeader.module.scss";
export interface UserManagementHeaderProps {
    onTabChangeHandler: (tab: AppPlatforms) => void;
    selectedTab: AppPlatforms;
    onCreate: () => void;
}
export const UserManagementHeader: React.FC<UserManagementHeaderProps> = ({
    onTabChangeHandler,
    selectedTab,
    onCreate,
}) => {
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const onTabClick = (tab: AppPlatforms) => {
        onTabChangeHandler(tab);
    };

    const hasCreatePermission = checkPermission(
        Business_RoleManagement_PermissionGroups_Web,
        [PermissionAccessTypes.CREATE]
    );

    return (
        <AppContentHeader
            title={t("roleManagement.user.title")}
            classNameForIcon="pe-7s-users"
        >
            <AppTabButtons
                btnClass={styles.tabButton}
                tabButtons={[
                    {
                        label: t("roleManagement.user.tab.web"),
                        onClick: () => onTabClick(AppPlatforms.WEB),
                        active: selectedTab === AppPlatforms.WEB,
                    } as AppTabButtonProps,
                    // {
                    //     label: t("roleManagement.user.tab.app"),
                    //     onClick: () => onTabClick(AppPlatforms.MOBILE),
                    //     active: selectedTab === AppPlatforms.MOBILE,
                    // } as AppTabButtonProps,
                ]}
            />

            {selectedTab == AppPlatforms.WEB && hasCreatePermission ? (
                <AppRoundedTextIconButton onClick={onCreate} />
            ) : (
                <div style={{ width: "205px" }} />
            )}
        </AppContentHeader>
    );
};

export default UserManagementHeader;
