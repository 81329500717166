import { AppPlatforms, BusinessPerson } from "models/general";
import { PermissionItemDTOWithUserAssignmentsInfo } from "models/permissionManagement";
import moment from "moment-timezone";
import { Role, RoleBase } from "./model";

export interface RolePermissionsResponse {
    ScopeId: number;
    RoleId: string;
    RoleName: string;
    IsEditable: boolean;
    Device: AppPlatforms;
    /// <summary>
    /// List of all Users who has access for at-least one AccessPermission
    /// </summary>
    UserAssignments: BusinessPerson[];
    Permissions: PermissionItemDTOWithUserAssignmentsInfo[];
}
export interface RoleResponse extends RoleBase {
    CreatedAt: number;
}
export interface RoleListingResponse {
    ActiveUserRoles: string[];
    Roles: RoleResponse[];
}

export const parseRoleResponse = (req: RoleResponse): Role => {
    return { ...req, CreatedAt: moment.unix(req.CreatedAt) };
};
