import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    KindergartenClientReportPlanningRequest,
    KindergartenClientOccupancyResponse,
} from "models";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { KindergartenAgeGroupReportResponse } from "models/kindergartenAgeGroupReport";

export interface IKindergartenPlanningReportsService {
    getOccupancyReport(
        req: KindergartenClientReportPlanningRequest
    ): Promise<AppResponse<KindergartenClientOccupancyResponse>>;
    getAgeGroupReport(
        req: KindergartenClientReportPlanningRequest
    ): Promise<AppResponse<KindergartenAgeGroupReportResponse>>;
}

export function getKindergartenPlanningReportsServiceKey(
    name: keyof IKindergartenPlanningReportsService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `kindergartenPlanningReports_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext().kindergarten.api.kindergartenReports;

type ApiLinkProviderType = typeof apiLinkProvider;

export class KindergartenPlanningReportsService
    extends BaseService
    implements IKindergartenPlanningReportsService {
    public routes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    getOccupancyReport(
        req: KindergartenClientReportPlanningRequest
    ): Promise<AppResponse<KindergartenClientOccupancyResponse>> {
        return this.doServerXHR<
            AppResponse<KindergartenClientOccupancyResponse>
        >({
            url: this.routes.occupancyPlanningReport(),
            method: "post",
            data: {
                ...req,
                StartMonth: req.StartMonth.format(ISO8601_DATE_FORMAT),
            },
        });
    }
    getAgeGroupReport(
        req: KindergartenClientReportPlanningRequest
    ): Promise<AppResponse<KindergartenAgeGroupReportResponse>> {
        return this.doServerXHR<
            AppResponse<KindergartenAgeGroupReportResponse>
        >({
            url: this.routes.ageGroupPlanningReport(),
            method: "post",
            data: {
                ...req,
                StartMonth: req.StartMonth.format(ISO8601_DATE_FORMAT),
            },
        });
    }
}
