import { useRouting } from "hooks/general/routing";
import { BusinessType } from "models";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
    getLwcTypeFromPathPattern,
    validLwcPathPatterns,
} from "routing/routes/lillywait/support/routes";
import { LwClientsData } from "../common";

export const LwcSupportList: React.FC = () => {
    const { linkProvider } = useRouting();
    const { lwcType } = useParams();
    const navigate = useNavigate();
    const [type, setType] = useState(getLwcTypeFromPathPattern(lwcType as any));

    useEffect(() => {
        if (lwcType && !validLwcPathPatterns.includes(lwcType as any)) {
            // if type is wrong redirect to kindergarten support
            navigate(
                linkProvider.lillywait
                    .support()
                    .screens.list(BusinessType.KINDERGARTEN),
                {
                    replace: true,
                }
            );
        } else {
            setType(getLwcTypeFromPathPattern(lwcType as any));
        }
    }, [lwcType]);
    return <LwClientsData lwcType={type} isSupportTable={true} />;
};

export default LwcSupportList;
