import {
    CopyDocumentsBetweenLocationsRequest,
    CopyLWCDocumentTemplatesRequest,
    DocumentTemplateItemResponse,
    DocumentTemplateRequest,
    DocumentTemplateResponse,
} from "models/documentTemplates";
import { UpdateDisplayIdResponseAndRequest } from "models/general";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../../BaseService";

export interface ILocationDocumentTemplateService {
    getTemplateById(
        templateId: string
    ): Promise<AppResponse<DocumentTemplateItemResponse>>;
    getTemplates(
        request: DocumentTemplateRequest
    ): Promise<AppResponse<DocumentTemplateResponse>>;
    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>>;
    copyLWCTemplates(
        request: CopyLWCDocumentTemplatesRequest
    ): Promise<AppResponse<boolean>>;
    copyTemplatesBetweenLocations(
        request: CopyDocumentsBetweenLocationsRequest
    ): Promise<AppResponse<boolean>>;
    delete(id: string): Promise<AppResponse<boolean>>;
}

const apiLinkProvider = routesForContext()
    .lillywait.locationSettings()
    .api.withId().documentTemplates;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getLocationDocumentTemplateServiceKey(
    name: keyof ILocationDocumentTemplateService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `location_documentTemplates_${name}_${JSON.stringify(data)}`;
}
export class LocationDocumentTemplateService
    extends BaseService
    implements ILocationDocumentTemplateService
{
    public documentRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.documentRoutes = apiLinkProvider;
    }

    getTemplateById(
        templateId: string
    ): Promise<AppResponse<DocumentTemplateItemResponse>> {
        return this.doServerXHR<DocumentTemplateItemResponse>({
            url: this.documentRoutes.getTemplateById(templateId),
            method: "get",
        });
    }

    getTemplates(
        request: DocumentTemplateRequest
    ): Promise<AppResponse<DocumentTemplateResponse>> {
        return this.doServerXHR<DocumentTemplateResponse>({
            url: this.documentRoutes.getTemplates(request),
            method: "get",
        });
    }

    updateDisplayId(
        req: UpdateDisplayIdResponseAndRequest
    ): Promise<AppResponse<UpdateDisplayIdResponseAndRequest>> {
        const formData = new FormData();
        formData.append("displayId", req.DisplayId.toString());
        formData.append("id", req.Id.toString());
        if (req.userType) {
            formData.append("userType", req.userType.toString());
        }

        return this.doServerXHR<boolean>({
            url: this.documentRoutes.updateDisplayId(),
            method: "post",
            data: formData,
        });
    }

    copyLWCTemplates(
        request: CopyLWCDocumentTemplatesRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.copyLWCTemplates(),
            method: "post",
            data: request,
        });
    }

    copyTemplatesBetweenLocations(
        request: CopyDocumentsBetweenLocationsRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.copyTemplatesBetweenLocations(),
            method: "post",
            data: request,
        });
    }

    delete(id: string): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.documentRoutes.delete(id),
            method: "post",
        });
    }
}

export default LocationDocumentTemplateService;
