import { isNil, orderBy } from "lodash-es";

export enum SortOrder {
    ASC = 0,
    DESC = 1,
}
export type SortDirection = "asc" | "desc" | false;

export interface ColumnSort<T> {
    col: keyof T;
    dir?: SortOrder;
}

export function sortData<T>(data: T[], orders: ColumnSort<T>[]): T[] {
    const filteredOrders = orders.filter((order) => !isNil(order.dir));
    return orderBy(
        data,
        filteredOrders.map(({ col }) => col),
        filteredOrders.map(({ dir }) => (dir == SortOrder.ASC ? "asc" : "desc"))
    );
}
