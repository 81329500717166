import { ISO8601_TIME_FORMAT } from "globals/constants";
import { weekDaysStringToNumDict } from "models/workingDays/helper";
import { WorkingDaysWithTime, WorkingDayData } from "models/workingDays/model";
import moment from "moment";
import { WorkingDays } from "./enum";

export interface WorkingDayDataResponse {
    IsOpen24Hours: boolean;
    IsWorkingDay: boolean;
    IsActive: boolean;
    From: string;
    To: string;
}

export type WorkingDaysWithTimeJson = {
    [key in WorkingDays]: WorkingDayDataResponse;
};

export const parseWorkingDaysResponse = (
    resp: WorkingDaysWithTimeJson
): WorkingDaysWithTime => {
    const temp: WorkingDaysWithTime = {} as WorkingDaysWithTime;
    Object.keys(resp)
        .sort(
            (a: string, b: string) =>
                weekDaysStringToNumDict[a as WorkingDays] -
                weekDaysStringToNumDict[b as WorkingDays]
        )
        .map((dayStr: string) => {
            const day = dayStr as WorkingDays;
            const d = resp[day];
            temp[day] = {
                ...d,
                From: moment(d.From, ISO8601_TIME_FORMAT),
                To: moment(d.To, ISO8601_TIME_FORMAT),
            } as WorkingDayData;
        });
    return temp;
};
