export enum BusinessShiftSortColumn {
    Name = 1, //default
    StartTime = 2,
    EndTime = 3,
    WorkingAreaId = 4,
    StartDate = 5,
    EndDate = 6,
}
export enum ShiftCriteriaType {
    Function = "Function",
    Area = "Area",
    Floor = "Floor",
    Group = "Group",
}
