import { AppTooltip } from "components";
import { AppCircleIconButton } from "components/Buttons";
import { openUrlInTab } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash";
import {
    DocumentTag,
    DocumentTemplateManagerItem,
    DocumentTemplateRequest,
    DocumentTemplateSortColumn,
    DocumentTemplateTabType,
    parseDocumentTemplateResponse,
} from "models/documentTemplates";
import { EmployeeType } from "models/employee";
import { UserType } from "models/general";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
    BusinessGeneralService,
    DocumentTemplateService,
    getDocumentTemplateServiceKey,
} from "services/business";
import styles from "./ClientEmployeeListHeader.module.scss";
import {
    AccountingExcelCompileDialog,
    ListDocumentCompilationDialog,
} from "./partials";

export interface ClientEmployeeListHeaderProps {
    selectedIds?: number[];
    userType: UserType;
    onlyRepresentativeEmails?: boolean;
    hasCreatePermission: boolean;
    employeeType?: EmployeeType;
}

export interface ClientEmployeeListHeaderState {
    documentTableDialogOpen: boolean;
    accountingExcelDialog: boolean;
    editableDialogOpen: boolean;
    isEditable: boolean;
}

export const ClientEmployeeListHeader: React.FC<
    ClientEmployeeListHeaderProps
> = ({
    selectedIds,
    userType,
    onlyRepresentativeEmails,
    hasCreatePermission,
    employeeType,
}) => {
    const { linkProvider } = useRouting();
    const { lwcId, lwcType } = useParams();
    const { sessionBusiness } = useSessionBusiness();
    const { t } = useTranslation();
    const [dialogState, setDialogState] =
        useState<ClientEmployeeListHeaderState>({
            documentTableDialogOpen: false,
            editableDialogOpen: false,
            accountingExcelDialog: false,
            isEditable: false,
        });

    onlyRepresentativeEmails = defaultTo(onlyRepresentativeEmails, false);
    let documentTypes: DocumentTag[] = [];
    if (userType == UserType.Client) {
        documentTypes = [DocumentTag.Client, DocumentTag.Client_List];
    } else {
        documentTypes = [DocumentTag.Employee, DocumentTag.Employee_List];
    }

    const documentTemplateService = new DocumentTemplateService(
        linkProvider.business.api.currentBusiness(
            lwcId,
            sessionBusiness?.Type
        ).documentTemplate
    );

    const request = {
        DocumentTabType: DocumentTemplateTabType.OWN_DOCUMENTS,
        SortColumn: DocumentTemplateSortColumn.Name,
        // SortOrder: state.sort.sortOrder,
        DocumentTypes: documentTypes,
    } as DocumentTemplateRequest;

    const { data: templateList } = useQuery(
        getDocumentTemplateServiceKey("getBusinessOrLocationTemplateList", {
            request,
        }),
        async () =>
            documentTemplateService.getBusinessOrLocationTemplateList(request),
        {
            select: (data) =>
                parseDocumentTemplateResponse(
                    data.Data.DocumentTemplates,
                    data.Data.Tags
                ),
        }
    );

    const businessGeneralService = new BusinessGeneralService(
        linkProvider.business.api.general
    );

    const {
        isLoading: personEmailLoading,
        data: personEmails,
        mutate: refetchPersonEmails,
    } = useMutation(
        async (ids: number[]) =>
            await businessGeneralService.getPersonEmails(
                ids,
                userType,
                onlyRepresentativeEmails
            )
    );

    useEffect(() => {
        if (!personEmailLoading && personEmails && personEmails.Data) {
            if (personEmails.Data.length > 0) {
                const url = `mailto:?bcc=${personEmails.Data.toString()}`;
                openUrlInTab(url);
            } else {
                showSweetAlertToast(
                    t("common.clientEmployeeListHeader.missingEmail.title"),
                    t("common.clientEmployeeListHeader.missingEmail.desc"),
                    "warning"
                );
            }
        }
    }, [personEmailLoading, personEmails]);

    const templateExistInList = (item: DocumentTemplateManagerItem) => {
        return (
            item.Types.filter(
                (y) =>
                    y.value ==
                    (userType == UserType.Client
                        ? DocumentTag.Client_List
                        : DocumentTag.Employee_List)
            ).length != 0
        );
    };

    const selectedItems = (item: DocumentTemplateManagerItem) => {
        return (
            item.Types.filter(
                (y) =>
                    y.value ==
                        (userType == UserType.Client
                            ? DocumentTag.Client
                            : DocumentTag.Employee) ||
                    y.value ==
                        (userType == UserType.Client
                            ? DocumentTag.Client_List
                            : DocumentTag.Employee_List)
            ).length != 0
        );
    };

    const showIcon = (
        templates: DocumentTemplateManagerItem[],
        isEditable: boolean
    ) => {
        return (
            templates
                .filter((x) => x.IsEditable == isEditable)
                .filter((x) =>
                    selectedIds && selectedIds.length > 0
                        ? selectedItems(x)
                        : templateExistInList(x)
                ).length > 0
        );
    };

    return (
        <div>
            {templateList && (
                <ListDocumentCompilationDialog
                    documents={templateList.filter((x) =>
                        selectedIds && selectedIds.length > 0
                            ? selectedItems(x)
                            : templateExistInList(x)
                    )}
                    selectedIds={selectedIds}
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    userType={userType}
                    employeeType={employeeType}
                    hasCreatePermission={hasCreatePermission}
                />
            )}

            <AccountingExcelCompileDialog
                selectedIds={selectedIds}
                dialogState={dialogState.accountingExcelDialog}
                setDialogState={(val: boolean) => {
                    setDialogState((old) => ({
                        ...old,
                        accountingExcelDialog: val,
                    }));
                }}
                userType={userType}
                employeeType={employeeType}
            />
            <div className={styles.outer}>
                {selectedIds && selectedIds.length > 0 && (
                    <AppTooltip
                        content={t("common.clientEmployeeListHeader.sendEmail")}
                        allowHtml={false}
                        trigger="mouseenter focus"
                        arrow={true}
                    >
                        <div>
                            <AppCircleIconButton
                                className={`${styles.btnCircle}`}
                                onClick={() => {
                                    if (!personEmailLoading) {
                                        refetchPersonEmails(selectedIds);
                                    }
                                }}
                                iconUrl={ImageAssets.common.envelopeWhite}
                            />
                        </div>
                    </AppTooltip>
                )}
                <AppTooltip
                    content={t(
                        "common.clientEmployeeListHeader.createAccountingExcel"
                    )}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    <Image
                        onClick={() =>
                            setDialogState({
                                accountingExcelDialog: true,
                                documentTableDialogOpen: false,
                                editableDialogOpen: false,
                                isEditable: false,
                            })
                        }
                        className={styles.docAndPdfImages}
                        src={ImageAssets.documentManager.excel}
                    />
                </AppTooltip>
                {templateList && templateList.length > 0 && (
                    <>
                        {showIcon(templateList, false) && (
                            <Image
                                onClick={() =>
                                    setDialogState({
                                        documentTableDialogOpen: true,
                                        accountingExcelDialog: false,
                                        editableDialogOpen: false,
                                        isEditable: false,
                                    })
                                }
                                className={styles.docAndPdfImages}
                                src={ImageAssets.documentManager.pdf}
                            />
                        )}
                        {showIcon(templateList, true) &&
                            hasCreatePermission && (
                                <Image
                                    onClick={() =>
                                        setDialogState({
                                            documentTableDialogOpen: true,
                                            accountingExcelDialog: false,
                                            editableDialogOpen: false,
                                            isEditable: true,
                                        })
                                    }
                                    className={styles.docAndPdfImages}
                                    src={ImageAssets.documentManager.doc}
                                />
                            )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ClientEmployeeListHeader;
