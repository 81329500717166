import { Moment } from "moment-timezone";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import styles from "./TimeManagementProgress.module.scss";

export interface TimeManagementProgressProps {
    endDate?: Moment;
    completedTime?: number; //Minutes
    maxTime?: number; //Minutes
}

export const TimeManagementProgress: React.FC<TimeManagementProgressProps> = ({
    endDate,
    completedTime,
    maxTime,
}) => {
    let isOvertime = false;

    const getProgressValue = () => {
        let progressPercentage = 0;

        if (
            endDate == null &&
            completedTime != null &&
            maxTime != null &&
            maxTime > 0
        ) {
            progressPercentage = (completedTime / maxTime) * 100;

            if (progressPercentage > 100) {
                isOvertime = true;
            }
        }
        return progressPercentage;
    };

    const progressValue: number = getProgressValue();

    return (
        <>
            {endDate == null ? (
                <ProgressBar
                    className={`${styles.progressBar} ${
                        isOvertime ? styles.bgColorRed : ""
                    }`}
                    variant="warning"
                    now={isOvertime ? 100 : progressValue}
                    max={isOvertime ? progressValue : 100}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default TimeManagementProgress;
