import { isNil } from "lodash-es";
import { MeResponse } from "models/auth/response";
import { Optional } from "models/general";
import { PermissionAccessTypes } from "./enum";
import { AccessPermission, PermissionAccessMap, RoleScope } from "./model";

export const extractActiveRolePermissions = (
    userInfo: MeResponse | null
): AccessPermission[] => {
    if (userInfo != null) {
        if (userInfo.AccessInfo && userInfo.AccessInfo.ActiveRoleScope) {
            return userInfo.AccessInfo.ActiveRoleScope.AccessPermissions;
        }
    }
    return [];
};

export const constructAllowedPermissionObject = (
    identifier: string[]
): AccessPermission[] => {
    return identifier.map(
        (p) =>
            ({
                // for support view
                RoleScopeId: 0,
                Id: 0,
                Identifier: p,
                CanCreate: true,
                CanDelete: true,
                CanEdit: true,
                CanView: true,
                AccessMap: {
                    [PermissionAccessTypes.CREATE]: true,
                    [PermissionAccessTypes.EDIT]: true,
                    [PermissionAccessTypes.DELETE]: true,
                    [PermissionAccessTypes.SHOW]: true,
                },
            } as AccessPermission)
    );
};

export const createPermissionMap = (
    permissions: AccessPermission[] | undefined
): PermissionAccessMap => {
    if (permissions && permissions.length > 0) {
        return permissions.length > 1
            ? {
                  [PermissionAccessTypes.CREATE]: permissions.some(
                      (p) => p.AccessMap[PermissionAccessTypes.CREATE]
                  ),
                  [PermissionAccessTypes.EDIT]: permissions.some(
                      (p) => p.AccessMap[PermissionAccessTypes.EDIT]
                  ),
                  [PermissionAccessTypes.DELETE]: permissions.some(
                      (p) => p.AccessMap[PermissionAccessTypes.DELETE]
                  ),
                  [PermissionAccessTypes.SHOW]: permissions.some(
                      (p) => p.AccessMap[PermissionAccessTypes.SHOW]
                  ),
              }
            : permissions[0].AccessMap;
    }
    return {
        [PermissionAccessTypes.CREATE]: false,
        [PermissionAccessTypes.EDIT]: false,
        [PermissionAccessTypes.DELETE]: false,
        [PermissionAccessTypes.SHOW]: false,
    };
};

export const checkPermissionAccess = (
    permissions: AccessPermission[] | undefined,
    permissionAccessType: PermissionAccessTypes
): boolean => {
    if (permissions && permissions.length > 0) {
        return permissions.some((p) => p.AccessMap[permissionAccessType]);
    }
    return false;
};
export const checkPermissionInMap = (
    map: PermissionAccessMap,
    permissionAccessType: PermissionAccessTypes[]
): boolean => {
    return permissionAccessType.some((p) => map[p]);
};

export const isPermissionInScope = (
    hqId: number,
    businessId: number,
    scope: Optional<RoleScope>
) => {
    return (
        !isNil(scope) &&
        ((!isNil(scope.BusinessId) && // permission is for target business
            scope.BusinessId === businessId) ||
            (isNil(scope.BusinessId) && // permission is for target business's HQ
                hqId === scope.HqId &&
                (scope.BranchIds === null ||
                    scope.BranchIds.includes(businessId))))
    );
};
