import { BusinessCreateEditFields } from "commonPartials/businessCreateEditField";
import AppContentHeader from "components/AppContentHeader";
import { AppContainer } from "components/Containers";
import { useRouting } from "hooks/general/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { getBusinessServiceKey } from "services/business";

export const BusinessCreate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const queryClient = useQueryClient();

    return (
        <div>
            <AppContentHeader
                title={t("common.administration")}
                classNameForIcon="lnr-apartment"
                hasGoBack={true}
                backUrl={linkProvider.business.screens.admin.companies()}
            />
            <AppContainer
                showHeader={false}
                heightToAdjust={190}
                mobileViewAdjustment={-20}
                mediumViewAdjustment={-20}
            >
                <BusinessCreateEditFields
                    isCreate={true}
                    currentBusiness={undefined}
                    onSuccess={() => {
                        queryClient.invalidateQueries(
                            getBusinessServiceKey("getAdminBusinessesList")
                        );
                        navigate(
                            linkProvider.business.screens.admin.companies()
                        );
                    }}
                />
            </AppContainer>
        </div>
    );
};

export default BusinessCreate;
