import IFrameContent from "components/IFrameContent";
import {
    LocationSettings_Affiliates,
    LocationSettings_Areas,
    LocationSettings_BasicData,
    LocationSettings_CompanyData,
    LocationSettings_DocumentLetterheads,
    LocationSettings_DocumentTemplates,
    LocationSettings_EmailTemplate,
    LocationSettings_EmployeeKeyCalculation,
    LocationSettings_ExplanationMode,
    LocationSettings_CareScopes,
    LocationSettings_Functions,
    LocationSettings_CompensationTemplates,
    LocationSettings_MealTemplates,
    LocationSettings_MealTemplates_Meal,
    LocationSettings_MealTemplates_MealGroup,
    LocationSettings_ModulePlan,
    LocationSettings_PublicHolidays,
    LocationSettings_Vouchers,
    LocationSettings_WorkingDays,
    ModulePlans,
    PermissionAccessTypes,
    LocationSettings_AgeGroups,
    LocationSettings_SalaryGroups,
} from "models";
import {
    Feature_LW_LocationSetting,
    Feature_LW_LocationSetting_AddUpdate,
    Feature_LW_LocationSetting_Affiliate,
    Feature_LW_LocationSetting_Affiliate_AddUpdate,
    Feature_LW_LocationSetting_ApplicationFeature,
    Feature_LW_LocationSetting_ApplicationFeature_AddUpdate,
    Feature_LW_LocationSetting_CompanyData,
    Feature_LW_LocationSetting_CompanyData_AddUpdate,
    Feature_LW_LocationSetting_DocumentTemplate,
    Feature_LW_LocationSetting_DocumentTemplate_AddUpdate,
    Feature_LW_LocationSetting_EmailTemplate,
    Feature_LW_LocationSetting_EmailTemplate_AddUpdate,
    Feature_LW_LocationSetting_EmployeeKey,
    Feature_LW_LocationSetting_EmployeeKey_AddUpdate,
    Feature_LW_LocationSetting_CareScopes,
    Feature_LW_LocationSetting_CareScope_AddUpdate,
    Feature_LW_LocationSetting_Functions,
    Feature_LW_LocationSetting_Functions_AddUpdate,
    Feature_LW_LocationSetting_Holidays,
    Feature_LW_LocationSetting_Holidays_AddUpdate,
    Feature_LW_LocationSetting_Letterhead,
    Feature_LW_LocationSetting_Letterhead_AddUpdate,
    Feature_LW_LocationSetting_MealTemplates,
    Feature_LW_LocationSetting_MealTemplates_AddUpdate,
    Feature_LW_LocationSetting_ModulePlan,
    Feature_LW_LocationSetting_CompensationTemplates,
    Feature_LW_LocationSetting_CompensationTemplates_AddUpdate,
    Feature_LW_LocationSetting_Voucher,
    Feature_LW_LocationSetting_Voucher_AddUpdate,
    Feature_LW_LocationSetting_WorkingArea,
    Feature_LW_LocationSetting_WorkingArea_AddUpdate,
    Feature_LW_LocationSetting_AgeGroup,
    Feature_LW_LocationSetting_AgeGroup_AddUpdate,
    Feature_LW_LocationSetting_SalaryGroup,
    Feature_LW_LocationSetting_SalaryGroup_AddUpdate,
} from "models/applicationFeatures";
import { RoutesMap } from "routing";
import Dummy from "screens/Dummy";
import {
    LocationSettingsCreate,
    LocationSettingsEditLayout,
    LocationSettingsList,
} from "screens/lillywait";
import {
    LocationAgeGroupTemplateCreate,
    LocationAgeGroupTemplateEdit,
    LocationAgeGroupTemplateList,
    LocationCareScopeTemplateCreate,
    LocationCareScopeTemplateEdit,
    LocationCareScopeTemplateList,
    LocationEmpKeyTemplateCreate,
    LocationEmpKeyTemplateEdit,
    LocationEmpKeyTemplateList,
    LocationSalaryGroupTemplateCreate,
    LocationSalaryGroupTemplateEdit,
    LocationSalaryGroupTemplateList,
    LocationWorkingDays,
} from "screens/lillywait/locationSettings/tabs";
import {
    LocationDocumentTemplateCreateAndEditIframe,
    LocationDocumentTemplates,
} from "screens/lillywait/locationSettings/tabs/documentTemplate";
import {
    LocationCompensationTemplateCreate,
    LocationCompensationTemplateEdit,
    LocationCompensationTemplateList,
} from "screens/lillywait/locationSettings/tabs/locationCompensationTemplate";
import { LocationSettingModulePlans } from "screens/lillywait/locationSettings/tabs/locationSettingModulePlan";
import locationSettingRoutes from "./routes";

const relativeRoutes = locationSettingRoutes(false).screens;
const fullRoutes = locationSettingRoutes().screens;

export const locationSettingRoutesMap: RoutesMap = {
    // don't include layouts here
    [relativeRoutes.list()]: {
        component: LocationSettingsList,
        fullPattern: fullRoutes.list(),
        pattern: relativeRoutes.list(),
        permKeys: [LocationSettings_BasicData],
        featureKeys: [Feature_LW_LocationSetting],
    },
    [relativeRoutes.create()]: {
        component: LocationSettingsCreate,
        fullPattern: fullRoutes.create(),
        pattern: relativeRoutes.create(),
        permKeys: [LocationSettings_BasicData],
        permAccessTypes: [PermissionAccessTypes.CREATE],
        featureKeys: [Feature_LW_LocationSetting_AddUpdate],
    },
    [relativeRoutes.withId().base()]: {
        component: LocationSettingsEditLayout,
        fullPattern: fullRoutes.withId().base(),
        pattern: relativeRoutes.withId().base(),
        permKeys: [LocationSettings_BasicData],
        featureKeys: [Feature_LW_LocationSetting_AddUpdate],
        nestedScreens: {
            [relativeRoutes.withId().edit()]: {
                component: IFrameContent, // ToDo create AddUpdate view
                index: true,
                pattern: relativeRoutes.withId().edit(),
                fullPattern: fullRoutes.withId().edit(),
            },
            [relativeRoutes.withId().settings()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().settings(),
                pattern: relativeRoutes.withId().settings(),
                permKeys: [LocationSettings_BasicData],
                featureKeys: [Feature_LW_LocationSetting_AddUpdate],
            },
            [relativeRoutes.withId().workingHours()]: {
                component: LocationWorkingDays,
                fullPattern: fullRoutes.withId().workingHours(),
                pattern: relativeRoutes.withId().workingHours(),
                permKeys: [LocationSettings_WorkingDays],
            },
            [relativeRoutes.withId().publicHolidays.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().publicHolidays.list(),
                pattern: relativeRoutes.withId().publicHolidays.list(),
                permKeys: [LocationSettings_PublicHolidays],
                featureKeys: [Feature_LW_LocationSetting_Holidays_AddUpdate],
            },
            [relativeRoutes.withId().publicHolidays.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().publicHolidays.create(),
                pattern: relativeRoutes.withId().publicHolidays.create(),
                permKeys: [LocationSettings_PublicHolidays],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [Feature_LW_LocationSetting_Holidays],
            },
            [relativeRoutes.withId().publicHolidays.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().publicHolidays.edit(),
                pattern: relativeRoutes.withId().publicHolidays.edit(),
                permKeys: [LocationSettings_PublicHolidays],
                permAccessTypes: [PermissionAccessTypes.EDIT],
                featureKeys: [Feature_LW_LocationSetting_Holidays],
            },
            [relativeRoutes.withId().compensationTemplates.list()]: {
                component: LocationCompensationTemplateList,
                fullPattern: fullRoutes.withId().compensationTemplates.list(),
                pattern: relativeRoutes.withId().compensationTemplates.list(),
                permKeys: [LocationSettings_CompensationTemplates],
                featureKeys: [Feature_LW_LocationSetting_CompensationTemplates],
            },
            [relativeRoutes.withId().compensationTemplates.create()]: {
                component: LocationCompensationTemplateCreate,
                fullPattern: fullRoutes.withId().compensationTemplates.create(),
                pattern: relativeRoutes.withId().compensationTemplates.create(),
                permKeys: [LocationSettings_CompensationTemplates],
                permAccessTypes: [PermissionAccessTypes.CREATE],
                featureKeys: [
                    Feature_LW_LocationSetting_CompensationTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.withId().compensationTemplates.edit()]: {
                component: LocationCompensationTemplateEdit,
                fullPattern: fullRoutes.withId().compensationTemplates.edit(),
                pattern: relativeRoutes.withId().compensationTemplates.edit(),
                permKeys: [LocationSettings_CompensationTemplates],
                permAccessTypes: [PermissionAccessTypes.EDIT],
                featureKeys: [
                    Feature_LW_LocationSetting_CompensationTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.withId().employeeKeyCalculations.list()]: {
                component: LocationEmpKeyTemplateList,
                fullPattern: fullRoutes.withId().employeeKeyCalculations.list(),
                pattern: relativeRoutes.withId().employeeKeyCalculations.list(),
                permKeys: [LocationSettings_EmployeeKeyCalculation],
                featureKeys: [Feature_LW_LocationSetting_EmployeeKey],
            },
            [relativeRoutes.withId().employeeKeyCalculations.create()]: {
                component: LocationEmpKeyTemplateCreate,
                fullPattern: fullRoutes
                    .withId()
                    .employeeKeyCalculations.create(),
                pattern: relativeRoutes
                    .withId()
                    .employeeKeyCalculations.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_EmployeeKeyCalculation],
                featureKeys: [Feature_LW_LocationSetting_EmployeeKey],
            },
            [relativeRoutes.withId().employeeKeyCalculations.edit()]: {
                component: LocationEmpKeyTemplateEdit,
                fullPattern: fullRoutes.withId().employeeKeyCalculations.edit(),
                pattern: relativeRoutes.withId().employeeKeyCalculations.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_EmployeeKeyCalculation],
                featureKeys: [Feature_LW_LocationSetting_EmployeeKey_AddUpdate],
            },
            [relativeRoutes.withId().careScopes.list()]: {
                component: LocationCareScopeTemplateList,
                fullPattern: fullRoutes.withId().careScopes.list(),
                pattern: relativeRoutes.withId().careScopes.list(),
                permKeys: [LocationSettings_CareScopes],
                featureKeys: [Feature_LW_LocationSetting_CareScopes],
            },
            [relativeRoutes.withId().careScopes.create()]: {
                component: LocationCareScopeTemplateCreate,
                fullPattern: fullRoutes.withId().careScopes.create(),
                pattern: relativeRoutes.withId().careScopes.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_CareScopes],
                featureKeys: [Feature_LW_LocationSetting_CareScope_AddUpdate],
            },
            [relativeRoutes.withId().careScopes.edit()]: {
                component: LocationCareScopeTemplateEdit,
                fullPattern: fullRoutes.withId().careScopes.edit(),
                pattern: relativeRoutes.withId().careScopes.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_CareScopes],
                featureKeys: [Feature_LW_LocationSetting_CareScope_AddUpdate],
            },
            [relativeRoutes.withId().ageGroups.list()]: {
                component: LocationAgeGroupTemplateList,
                fullPattern: fullRoutes.withId().ageGroups.list(),
                pattern: relativeRoutes.withId().ageGroups.list(),
                permKeys: [LocationSettings_AgeGroups],
                featureKeys: [Feature_LW_LocationSetting_AgeGroup],
            },
            [relativeRoutes.withId().ageGroups.create()]: {
                component: LocationAgeGroupTemplateCreate,
                fullPattern: fullRoutes.withId().ageGroups.create(),
                pattern: relativeRoutes.withId().ageGroups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_AgeGroups],
                featureKeys: [Feature_LW_LocationSetting_AgeGroup_AddUpdate],
            },
            [relativeRoutes.withId().ageGroups.edit()]: {
                component: LocationAgeGroupTemplateEdit,
                fullPattern: fullRoutes.withId().ageGroups.edit(),
                pattern: relativeRoutes.withId().ageGroups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_AgeGroups],
                featureKeys: [Feature_LW_LocationSetting_AgeGroup_AddUpdate],
            },
            [relativeRoutes.withId().salaryGroups.list()]: {
                component: LocationSalaryGroupTemplateList,
                fullPattern: fullRoutes.withId().salaryGroups.list(),
                pattern: relativeRoutes.withId().salaryGroups.list(),
                permKeys: [LocationSettings_SalaryGroups],
                featureKeys: [Feature_LW_LocationSetting_SalaryGroup],
            },
            [relativeRoutes.withId().salaryGroups.create()]: {
                component: LocationSalaryGroupTemplateCreate,
                fullPattern: fullRoutes.withId().salaryGroups.create(),
                pattern: relativeRoutes.withId().salaryGroups.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_SalaryGroups],
                featureKeys: [Feature_LW_LocationSetting_SalaryGroup_AddUpdate],
            },
            [relativeRoutes.withId().salaryGroups.edit()]: {
                component: LocationSalaryGroupTemplateEdit,
                fullPattern: fullRoutes.withId().salaryGroups.edit(),
                pattern: relativeRoutes.withId().salaryGroups.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_SalaryGroups],
                featureKeys: [Feature_LW_LocationSetting_SalaryGroup_AddUpdate],
            },
            [relativeRoutes.withId().areas.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().areas.list(),
                pattern: relativeRoutes.withId().areas.list(),
                permKeys: [LocationSettings_WorkingDays],
                featureKeys: [Feature_LW_LocationSetting_WorkingArea],
            },
            [relativeRoutes.withId().areas.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().areas.create(),
                pattern: relativeRoutes.withId().areas.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_Areas],
                featureKeys: [Feature_LW_LocationSetting_WorkingArea_AddUpdate],
            },
            [relativeRoutes.withId().areas.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().areas.edit(),
                pattern: relativeRoutes.withId().areas.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_Areas],
                featureKeys: [Feature_LW_LocationSetting_WorkingArea_AddUpdate],
            },
            [relativeRoutes.withId().emailTemplate.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().emailTemplate.list(),
                pattern: relativeRoutes.withId().emailTemplate.list(),
                permKeys: [LocationSettings_EmailTemplate],
                featureKeys: [Feature_LW_LocationSetting_EmailTemplate],
            },
            [relativeRoutes.withId().emailTemplate.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().emailTemplate.create(),
                pattern: relativeRoutes.withId().emailTemplate.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_EmailTemplate],
                featureKeys: [
                    Feature_LW_LocationSetting_EmailTemplate_AddUpdate,
                ],
            },
            [relativeRoutes.withId().emailTemplate.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().emailTemplate.edit(),
                pattern: relativeRoutes.withId().emailTemplate.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_EmailTemplate],
                featureKeys: [
                    Feature_LW_LocationSetting_EmailTemplate_AddUpdate,
                ],
            },
            [relativeRoutes.withId().functions.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().functions.list(),
                pattern: relativeRoutes.withId().functions.list(),
                permKeys: [LocationSettings_Functions],
                featureKeys: [Feature_LW_LocationSetting_Functions],
            },
            [relativeRoutes.withId().functions.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().functions.create(),
                pattern: relativeRoutes.withId().functions.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_Functions],
                featureKeys: [Feature_LW_LocationSetting_Functions_AddUpdate],
            },
            [relativeRoutes.withId().functions.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().functions.edit(),
                pattern: relativeRoutes.withId().functions.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_Functions],
                featureKeys: [Feature_LW_LocationSetting_Functions_AddUpdate],
            },
            [relativeRoutes.withId().mealTemplates.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().mealTemplates.list(),
                pattern: relativeRoutes.withId().mealTemplates.list(),
                permKeys: [LocationSettings_MealTemplates],
                featureKeys: [Feature_LW_LocationSetting_MealTemplates],
            },
            [relativeRoutes.withId().mealTemplates.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().mealTemplates.create(),
                pattern: relativeRoutes.withId().mealTemplates.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_MealTemplates],
                featureKeys: [
                    Feature_LW_LocationSetting_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.withId().mealTemplates.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().mealTemplates.edit(),
                pattern: relativeRoutes.withId().mealTemplates.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_MealTemplates],
                featureKeys: [
                    Feature_LW_LocationSetting_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.withId().mealTemplates.meals()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().mealTemplates.meals(),
                pattern: relativeRoutes.withId().mealTemplates.meals(),
                permKeys: [
                    LocationSettings_MealTemplates_Meal,
                    LocationSettings_MealTemplates_MealGroup,
                ],
                featureKeys: [
                    Feature_LW_LocationSetting_MealTemplates_AddUpdate,
                ],
            },
            [relativeRoutes.withId().documentTemplates.list()]: {
                component: LocationDocumentTemplates,
                fullPattern: fullRoutes.withId().documentTemplates.list(),
                pattern: relativeRoutes.withId().documentTemplates.list(),
                permKeys: [LocationSettings_DocumentTemplates],
                requiredModule: [ModulePlans.DocumentManager],
                featureKeys: [Feature_LW_LocationSetting_DocumentTemplate],
            },
            [relativeRoutes.withId().documentTemplates.create()]: {
                component: LocationDocumentTemplateCreateAndEditIframe,
                fullPattern: fullRoutes.withId().documentTemplates.create(),
                pattern: relativeRoutes.withId().documentTemplates.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                requiredModule: [ModulePlans.DocumentManager],
                permKeys: [LocationSettings_DocumentTemplates],
                featureKeys: [
                    Feature_LW_LocationSetting_DocumentTemplate_AddUpdate,
                ],
            },
            [relativeRoutes.withId().documentTemplates.edit()]: {
                component: LocationDocumentTemplateCreateAndEditIframe,
                fullPattern: fullRoutes.withId().documentTemplates.edit(),
                pattern: relativeRoutes.withId().documentTemplates.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_DocumentTemplates],
                requiredModule: [ModulePlans.DocumentManager],
                featureKeys: [
                    Feature_LW_LocationSetting_DocumentTemplate_AddUpdate,
                ],
            },
            [relativeRoutes.withId().letterheads.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().letterheads.list(),
                pattern: relativeRoutes.withId().letterheads.list(),
                permKeys: [LocationSettings_DocumentLetterheads],
                requiredModule: [ModulePlans.DocumentManager],
                featureKeys: [Feature_LW_LocationSetting_Letterhead],
            },
            [relativeRoutes.withId().letterheads.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().letterheads.create(),
                pattern: relativeRoutes.withId().letterheads.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_DocumentLetterheads],
                requiredModule: [ModulePlans.DocumentManager],
                featureKeys: [Feature_LW_LocationSetting_Letterhead_AddUpdate],
            },
            [relativeRoutes.withId().letterheads.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().letterheads.edit(),
                pattern: relativeRoutes.withId().letterheads.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_DocumentLetterheads],
                requiredModule: [ModulePlans.DocumentManager],
                featureKeys: [Feature_LW_LocationSetting_Letterhead_AddUpdate],
            },
            [relativeRoutes.withId().affiliate.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().affiliate.list(),
                pattern: relativeRoutes.withId().affiliate.list(),
                permKeys: [LocationSettings_Affiliates],
                featureKeys: [Feature_LW_LocationSetting_Affiliate],
            },
            [relativeRoutes.withId().affiliate.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().affiliate.create(),
                pattern: relativeRoutes.withId().affiliate.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_Affiliates],
                featureKeys: [Feature_LW_LocationSetting_Affiliate_AddUpdate],
            },
            [relativeRoutes.withId().affiliate.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().affiliate.edit(),
                pattern: relativeRoutes.withId().affiliate.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_Affiliates],
                featureKeys: [Feature_LW_LocationSetting_Affiliate_AddUpdate],
            },
            [relativeRoutes.withId().vouchers.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().vouchers.list(),
                pattern: relativeRoutes.withId().vouchers.list(),
                permKeys: [LocationSettings_Vouchers],
                featureKeys: [Feature_LW_LocationSetting_Voucher],
            },
            [relativeRoutes.withId().vouchers.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().vouchers.create(),
                pattern: relativeRoutes.withId().vouchers.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_Vouchers],
                featureKeys: [Feature_LW_LocationSetting_Voucher_AddUpdate],
            },
            [relativeRoutes.withId().vouchers.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().vouchers.edit(),
                pattern: relativeRoutes.withId().vouchers.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_Vouchers],
                featureKeys: [Feature_LW_LocationSetting_Voucher_AddUpdate],
            },
            [relativeRoutes.withId().companyDataFields.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().companyDataFields.list(),
                pattern: relativeRoutes.withId().companyDataFields.list(),
                permKeys: [LocationSettings_CompanyData],
                featureKeys: [Feature_LW_LocationSetting_CompanyData],
            },
            [relativeRoutes.withId().companyDataFields.create()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().companyDataFields.create(),
                pattern: relativeRoutes.withId().companyDataFields.create(),
                permAccessTypes: [PermissionAccessTypes.CREATE],
                permKeys: [LocationSettings_CompanyData],
                featureKeys: [Feature_LW_LocationSetting_CompanyData_AddUpdate],
            },
            [relativeRoutes.withId().companyDataFields.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().companyDataFields.edit(),
                pattern: relativeRoutes.withId().companyDataFields.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_CompanyData],
                featureKeys: [Feature_LW_LocationSetting_CompanyData_AddUpdate],
            },
            [relativeRoutes.withId().explanationMode.list()]: {
                component: IFrameContent,
                fullPattern: fullRoutes.withId().explanationMode.list(),
                pattern: relativeRoutes.withId().explanationMode.list(),
                permKeys: [LocationSettings_ExplanationMode],
                featureKeys: [Feature_LW_LocationSetting_ApplicationFeature],
            },
            [relativeRoutes.withId().explanationMode.edit()]: {
                component: Dummy,
                fullPattern: fullRoutes.withId().explanationMode.edit(),
                pattern: relativeRoutes.withId().explanationMode.edit(),
                permAccessTypes: [PermissionAccessTypes.EDIT],
                permKeys: [LocationSettings_ExplanationMode],
                featureKeys: [
                    Feature_LW_LocationSetting_ApplicationFeature_AddUpdate,
                ],
            },
            [relativeRoutes.withId().modules()]: {
                component: LocationSettingModulePlans,
                fullPattern: fullRoutes.withId().modules(),
                pattern: relativeRoutes.withId().modules(),
                permKeys: [LocationSettings_ModulePlan],
                featureKeys: [Feature_LW_LocationSetting_ModulePlan],
            },
        },
    },
};
