import { AppTable, AppTableSkeleton } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    AgeGroupTemplate,
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models";
import { CareScopeTemplate } from "models/careScopeTemplate";
import React from "react";
import { useTranslation } from "react-i18next";
import { AgeGroupTemplateRow } from "./tablePartials";

export interface AgeGroupTemplateTableProps {
    list: AgeGroupTemplate[];
    permissionsMap: PermissionAccessMap;
    createPath: string;
    onClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    loading: boolean;
    deleting: boolean;
    useLocationData?: boolean;
    isForLocationSetting?: boolean;
    isLwc?: boolean;
}

const COLUMNS_COUNT = 4;
export const AgeGroupTemplateTable: React.FC<AgeGroupTemplateTableProps> = ({
    list,
    permissionsMap,
    createPath,
    onClick,
    onDeleteClick,
    loading,
    isLwc = false,
    deleting,
    useLocationData = true,
    isForLocationSetting = false,
}) => {
    const { t } = useTranslation();
    const columnsCount = COLUMNS_COUNT + (isForLocationSetting ? 0 : 1);
    return (
        <AppTable
            heightToAdjust={337}
            stickyHeader={true}
            mobileViewAdjustment={33}
            mediumViewAdjustment={33}
        >
            <thead>
                <tr>
                    <th style={getFixedCssWidths(60)} />
                    <th
                        style={{
                            width: isForLocationSetting ? "47.5%" : "37.5%",
                        }}
                    >
                        {t("templates.templateName.name")}
                    </th>
                    <th>{t("templates.templateShortName.name")}</th>
                    {!isForLocationSetting && (
                        <th style={getFixedCssWidths(120)} />
                    )}
                    <th style={getFixedCssWidths(70)} />
                </tr>
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={columnsCount} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={columnsCount} />
                </tr>
                {loading ? (
                    <AppTableSkeleton columns={columnsCount} rows={10} />
                ) : list.length > 0 ? (
                    list.map((x, idx) => (
                        <AgeGroupTemplateRow
                            key={idx}
                            template={x}
                            onClick={onClick}
                            onDeleteClick={onDeleteClick}
                            createPath={createPath}
                            loading={deleting}
                            isLwc={isLwc}
                            permissionsMap={permissionsMap}
                            useLocationData={useLocationData}
                            isForLocationSetting={isForLocationSetting}
                        />
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={columnsCount}
                            style={{ textAlign: "center" }}
                        >
                            {t("common.noDataFound")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={columnsCount} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default AgeGroupTemplateTable;
