export const TimeManagementEnTranslations = {
    timeManagement: {
        accessDetails: "Access Details",
        actions: {
            in: "IN",
            out: "OUT",
            start: "START",
            stop: "STOP",
        },
        active: "Active",
        allClients: "All Clients",
        allGroups: "All Groups",
        availableStaff: {
            availableStaff: "Available Staff",
            currentAction: "Current Action",
            employeeKey: "Employee Key",
            name: "Available Staff",
        },
        careHours: "Care Hours",
        controllers: {
            Break: "Break",
            Main: "Main",
        },
        dayType: {
            diseaseDay: "Disease Day",
            personVacation: "Vacation",
            personHalfVacation: "Half Vacation",
            dismantleOvertime: "Dismantle Overtime",
            weekend: "Weekend",
            publicHoliday: "Public holiday",
            workDay: "Work Day",
            careDay: "Care Day",
            unpaidVacation: "Unpaid Vacation",
            schoolDay: "School Day",
            furtherTraining: "Further Training",
            noReason: "No Reason",
            freeDay: "Free Day",
        },
        description: "Description",
        totalOverdraft: "Total Overdraft",
        difference: "Difference",
        differenceForFilteredYear: "Total Difference filtered Year",
        differenceUptoFilteredMonth: "Difference up to filtered Month",
        employees: "Employees",
        info: "Info",
        infoTags: {
            a: "A",
            allergies: "Allergies",
            drugs: "Drugs",
            f: "F",
            family: {
                contactNumber: "Contact Number",
                family: "Family",
                firstName: "First Name",
                idNumber: "ID Number",
                lastName: "Last Name",
            },
            familyDoctorContactInfo: "Family Doctor Contact Info",
            foodIntolerances: "Food Intolerances",
            medicalInfo: "Medical Info",
            miscellaneous: "Miscellaneous",
            u: "U",
            v: "V",
            vaccinations: "Vaccinations",
        },
        manualEntry: "Manual Entry",
        invalidManualEntry:
            "Please enter a valid duration between 00:00 and 99:59",
        manualEntryComment: "Manual Entry Comment",
        timeEntryAddedSuccessfully: "Time entry added successfully.",
        manualEntryError: "Unable to update manual entry...",
        startTimer: "Start Timer",
        stopTimer: "Stop Timer",
        maxContractHours: "Max Contract Hours",
        maxWorkingHours: "Max Working Hours",
        neededEmployees: "Needed Employees",
        tabs: {
            day: "Day",
            month: "Month",
            week: "Week",
        },
        timeManagementClients: "Time Management Clients",
        timeManagementEmployees: "Time Management Employees",
        timeManagementSuppliers: "Time Management Suppliers",
        totalActiveClients: "Total active clients",
        totalBreak: "Total Break",
        totalDifference: "Total Difference",
        workingHours: "Working Hours",
    },
};

export default TimeManagementEnTranslations;
