import { Person } from "models/general";
import { MessageTemplate } from "./request";

export interface MessageCount {
    InboxMessages: number;
    SentMessages: number;
    FavMessages: number;
    TrashMessages: number;
}

export enum MessageNavigation {
    Inbox,
    Sent,
    Favorite,
    Trash,
}

export interface ReceiptItem {
    Identifier: string;
    Type: string;
    Name: string;
    IsValid: boolean;
    TypeId: string;
    IdentifierHash: string;
}

export interface MessageTemplateWithStatuses {
    Message: MessageTemplate;
    MessageStatuses: UserMessageStatus[];
}

export interface UserMessageStatus extends Person {
    IsRead: boolean;
    IsSent: boolean;
    HasMobileUser: boolean;
    UserReceiptType: string;
}
