import BaseRoutingContext from "./BaseRoutingContext";

export class AuthenticatedUserRoutingContext extends BaseRoutingContext {
    static base: string = "user";
    static apiBase: string = "user";
    getContextIdentifier(): string {
        return "auth-context";
    }
    getBaseUrl() {
        return `${super.getBaseUrl()}/${AuthenticatedUserRoutingContext.base}`;
    }
    getApiBaseUrl() {
        return `${super.getApiBaseUrl()}/${
            AuthenticatedUserRoutingContext.apiBase
        }`;
    }
}

export default AuthenticatedUserRoutingContext;
