import { ISO8601_DATE_FORMAT } from "globals/constants";
import { isNil } from "lodash-es";
import { parseBusinessShiftModelResponse } from "models/businessShifts/helper";
import moment from "moment";
import { EmployeeShiftSortColumn } from "./enum";
import { EmployeeShift } from "./model";
import { EmployeeShiftResponse } from "./response";

export const parseEmployeeShiftResponse = (
    res: EmployeeShiftResponse
): EmployeeShift => {
    return {
        ...res,
        StartDate: moment(res.DateStart, ISO8601_DATE_FORMAT),
        EndDate: !isNil(res.DateEnd)
            ? moment(res.DateEnd, ISO8601_DATE_FORMAT)
            : null,
        Shift: res.Shift
            ? parseBusinessShiftModelResponse(res.Shift as any)
            : null,
    } as EmployeeShift;
};

export const getEmployeeShiftSortKeyFromEnum = (
    sortColumn: EmployeeShiftSortColumn | undefined
): keyof EmployeeShift | string => {
    switch (sortColumn) {
        case EmployeeShiftSortColumn.Shift:
            return "Shift.Name";
        case EmployeeShiftSortColumn.StartDate:
            return "StartDate";
        case EmployeeShiftSortColumn.EndDate:
            return "EndDate";
        default:
            return "StartDate";
    }
};
