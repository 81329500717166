import {
    EmployeeStatus,
    EmployeeTypeInteger,
    EmployeeSortColumn,
    EmployeeType,
} from "./enum";
import { EmployeeListFilters, EmployeeListItem } from "./model";

export const changeEmployeeStateIntToString = (
    value: number
): EmployeeStatus => {
    switch (value) {
        case 0:
            return EmployeeStatus.OnAdvance;
        case 1:
            return EmployeeStatus.Active;
        case 2:
            return EmployeeStatus.Expired;
        default:
            return EmployeeStatus.Expired;
    }
};
export const changeEmployeeTypeStringToInt = (
    value: EmployeeType
): EmployeeTypeInteger => {
    switch (value) {
        case EmployeeType.Candidate:
            return EmployeeTypeInteger.Candidate;
        case EmployeeType.Supplier:
            return EmployeeTypeInteger.Supplier;
        default:
            return EmployeeTypeInteger.Employee;
    }
};
export const getEmployeeTypePathBase = (
    value: EmployeeType,
    lowerCase: boolean = true
) => {
    return `${lowerCase ? value.toLocaleLowerCase() : value}s`;
};
export const getEmployeeTypeFromPath = (pathname: string): EmployeeType => {
    let type = EmployeeType.Employee;
    if (pathname.includes(getEmployeeTypePathBase(EmployeeType.Supplier))) {
        type = EmployeeType.Supplier;
    } else if (
        pathname.includes(getEmployeeTypePathBase(EmployeeType.Candidate))
    ) {
        type = EmployeeType.Candidate;
    }

    return type;
};

export const getEmployeeFilterDefaultValue = (
    pathname: string = ""
): EmployeeListFilters => {
    return {
        DisplayId: null,
        ContractEnd: null,
        ContractStart: null,
        HasMobileAccount: null,
        Name: null,
        EmployeeType: getEmployeeTypeFromPath(pathname),
        EmployeeFunctionId: null,
        GroupId: null,
        WeeklyHours: null,
        AreaId: null,
        Status: EmployeeStatus.Active,
    };
};
export const getEmployeeSortColumnKeyFromEnum = (
    sortColumn: EmployeeSortColumn | undefined
): keyof EmployeeListItem => {
    switch (sortColumn) {
        case EmployeeSortColumn.Id:
            return "Id";
        case EmployeeSortColumn.Name:
            return "Name";
        case EmployeeSortColumn.EmployeeFunction:
            return "EmployeeFunction";
        case EmployeeSortColumn.WorkArea:
            return "WorkArea";
        case EmployeeSortColumn.HoursPerWeek:
            return "RealWeeklyHours";
        case EmployeeSortColumn.Chargeable:
            return "Chargeable";
        case EmployeeSortColumn.GrossSalary:
            return "GrossSalary";
        case EmployeeSortColumn.ContractStart:
            return "ContractStart";
        case EmployeeSortColumn.ContractEnd:
            return "ContractEnd";
        case EmployeeSortColumn.ExpirationDate:
            return "ExpirationDate";
        case EmployeeSortColumn.TrialEnd:
            return "EndOfTrialPeriod";
        case EmployeeSortColumn.Holidays:
            return "Holidays";
        case EmployeeSortColumn.DisplayId:
            return "DisplayId";
        case EmployeeSortColumn.Group:
            return "Group";
        case EmployeeSortColumn.App:
            return "HasMobileAccount";
        case EmployeeSortColumn.Status:
            return "Status";
        default:
            return "DisplayId";
    }
};
