import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useWebNotificationHub } from "hooks/layout/useWebNotificationHub";
import lodash from "lodash";
import {
    AppNotificationType,
    constructNotificationModel,
    DownloadableNotifications,
    getNotificationFromResponse,
    NotificationModel,
    NotificationRequest,
    NotificationResponse,
    NotificationStatus,
} from "models/notification";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    getNotificationServiceKey,
    NotificationService,
} from "services/business";
import { NotificationHeaderIcon } from "./partials";
import { useLocation } from "react-router";

const PAGE_SIZE = 5;

export const NotificationHeaderIconsContainer: React.FC = () => {
    const [state, setState] = useState<NotificationModel[]>([]);
    const [openedMenu, setOpenedMenu] = useState<
        AppNotificationType | undefined
    >(undefined);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { signalR } = useWebNotificationHub();
    const { getDateFormatForLocale } = useLocaleHelpers();
    const { id } = useSessionUser();
    const { linkProvider } = useRouting();
    const { id: businessId } = useSessionBusiness();
    const notificationService = new NotificationService(
        linkProvider.business.api.notification
    );

    const { sessionBusiness } = useSessionBusiness();

    const notificationRequest = useMemo(
        () =>
            ({
                Page: 1,
                PageSize: PAGE_SIZE,
                UserId: id,
                FromDateTime: moment()
                    .subtract(2, "days")
                    .format(ISO8601_DATE_FORMAT),
            } as NotificationRequest),
        [id, pathname]
    );

    const {
        isFetching: loading,
        data: parsedNotifications,
        error: notificationsFetchError,
        isRefetching: refetching,
        refetch: refetchNotification,
    } = useQuery(
        getNotificationServiceKey("getNotificationList", notificationRequest),
        async () =>
            await notificationService.getNotificationList(notificationRequest), // automatically refetch based on key change
        {
            select: (resp) => {
                return resp.Data.map((x) =>
                    constructNotificationModel(
                        getNotificationFromResponse(x),
                        getDateFormatForLocale()
                    )
                );
            },
        }
    );

    useEffect(() => {
        if (!loading) {
            if (parsedNotifications) {
                setState(parsedNotifications);
            }
            if (notificationsFetchError) {
                showUnexpectedErrorToast();
            }
        }
    }, [loading]);

    useEffect(() => {
        refetchNotification();
    }, [sessionBusiness]);

    useEffect(() => {
        if (signalR) {
            signalR.on("notificationReceived", (notification: any) => {
                //any applies because signalR gives objects in camel case in response
                if (businessId == notification.businessId) {
                    const newNotification = constructNotificationModel(
                        getNotificationFromResponse({
                            Id: notification.id,
                            ForUserId: notification.forUserId,
                            IsRead: notification.isRead,
                            BusinessId: notification.businessId,
                            StatusType: notification.statusType,
                            NotificationType: notification.notificationType,
                            TargetUrl: notification.targetUrl,
                            TimeStamp: notification.timeStamp,
                            Meta: {
                                Name: notification.meta.name,
                                ForBusinessType:
                                    notification.meta.forBusinessType,
                                DueDateTime: notification.meta.dueDateTime,
                            },
                        } as NotificationResponse),
                        getDateFormatForLocale()
                    );
                    setState((old) => [newNotification, ...old]);

                    showSweetAlertToast(
                        t("common.info"),
                        t(
                            `notificationManagement.${
                                DownloadableNotifications.includes(
                                    newNotification.NotificationType
                                ) &&
                                newNotification.StatusType ==
                                    NotificationStatus.SUCCESS
                                    ? "newDownloadAvailable"
                                    : "newNotificationArrived"
                            }`
                        ),
                        "info"
                    );
                }
            });
            signalR.on("refetchNotification", () => {
                refetchNotification();
            });

            return () => {
                // off on un-mount for all
                signalR.off("refetchNotification");
                signalR.off("notificationReceived");
            };
        }
    }, [signalR]);

    const notifications = useMemo(() => {
        const notificationsOnly = state.filter(
            (x) =>
                !DownloadableNotifications.includes(x.NotificationType) ||
                x.StatusType != NotificationStatus.SUCCESS
        );

        return lodash
            .sortBy(notificationsOnly, ["timestamp"], ["desc"])
            .slice(0, 5); // get first 5
    }, [state]);
    const downloads = useMemo(() => {
        const downloadsOnly = state.filter(
            (x) =>
                DownloadableNotifications.includes(x.NotificationType) &&
                x.StatusType == NotificationStatus.SUCCESS
        );

        return lodash
            .sortBy(downloadsOnly, ["timestamp"], ["desc"])
            .slice(0, 5); // get first 5
    }, [state]);

    const onChange = (newVal: NotificationModel) => {
        setState((old) => {
            return old.map((x) => {
                if (x.Id === newVal.Id) {
                    return newVal;
                }
                return x;
            });
        });
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: "80px",
                justifyContent: "space-between",
                paddingLeft: "10px",
            }}
        >
            <NotificationHeaderIcon
                value={downloads}
                onChange={onChange}
                type={"downloads"}
                openedMenu={openedMenu}
                setOpenedMenu={setOpenedMenu}
            />
            <NotificationHeaderIcon
                value={notifications}
                onChange={onChange}
                type={"notifications"}
                openedMenu={openedMenu}
                setOpenedMenu={setOpenedMenu}
            />
        </div>
    );
};

export default NotificationHeaderIconsContainer;
