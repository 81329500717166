import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general";
import moment from "moment";
import { ArchiveDocument, DocumentArchive } from "./model";
import { DocumentArchiveItemResponse } from "./response";

export const getInitialDocumentArchiveValues = (): DocumentArchive => {
    return {
        Id: 0,
        DisplayId: 0,
        AllowMultiple: false,
        CreationArea: [],
        Files: [],
        Name: "",
    };
};

export function validateDocumentArchive(
    documentArchive: DocumentArchive,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.REQUIRED,
                message: i18next.t("documentArchive.name.missing"),
            },
        ],
        documentArchive.Name,
        "Name"
    );
    return state;
}

export const getDocumentArchive = (
    val: DocumentArchiveItemResponse
): DocumentArchive => {
    return {
        ...val,
        CreatedAt: moment.unix(val.CreatedAt),
        Documents: val.Documents?.map((x) => {
            return {
                ...x,
                UploadedAt: moment.unix(x.UploadedAt),
            } as ArchiveDocument;
        }),
    } as DocumentArchive;
};
