import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import { DATE_TIME } from "globals/constants";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { formatCompleteLongDateTime } from "globals/helpers/localizationHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import {
    EmployeeVacation,
    getVacationStatusTranslation,
} from "models/employeeVacation";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR_LIGHT_GREEN } from "theme/themeConstants";

export interface EmployeeVacationTableRowProps {
    hasDeletePermission: boolean;
    loading?: boolean;
    value: EmployeeVacation;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

export const EmployeeVacationTableRow: React.FC<
    EmployeeVacationTableRowProps
> = ({
    hasDeletePermission,
    value,
    loading = false,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();
    const { getDateTimeFormatForLocale } = useLocaleHelpers();
    const { getDateFormatForLocale, formatNumber } = useLocaleHelpers();
    const onClickToUse = () => {
        if (value.EncodedId) {
            onClick(value.EncodedId);
        } else {
            onClick(defaultTo(value.Id, 0).toString());
        }
    };
    return (
        <>
            <tr
                onClick={onClickToUse}
                style={
                    value.EntryForCurrYear
                        ? {
                              backgroundColor: COLOR_LIGHT_GREEN,
                          }
                        : undefined
                }
            >
                <td style={{ paddingLeft: "5px" }}>
                    {value.DateStart.format(getDateFormatForLocale())}
                </td>
                <td>
                    {value.DateEnd
                        ? value.DateEnd.format(getDateFormatForLocale())
                        : ""}
                </td>
                <td>{formatNumber(value.Total)}</td>
                <td>
                    <BooleanTickCrossIcon
                        showCross={true}
                        value={value.DismantlingOvertime}
                    />
                </td>
                <td>
                    <BooleanTickCrossIcon
                        showCross={true}
                        value={value.HalfDayVacation}
                    />
                </td>
                <td>
                    <BooleanTickCrossIcon
                        showCross={true}
                        value={value.HalfDayGiftVacation}
                    />
                </td>
                <td>
                    <BooleanTickCrossIcon
                        showCross={true}
                        value={value.UnpaidVacation}
                    />
                </td>
                <td>
                    <BooleanTickCrossIcon
                        showCross={true}
                        value={value.ManualCorrection}
                    />
                </td>
                <td>{getVacationStatusTranslation(value.Status)}</td>
                <td>
                    {value.CreatedAt != null
                        ? value.CreatedAt.format(getDateTimeFormatForLocale())
                        : t("common.unknown")}
                </td>
                <td>
                    {value.UpdatedAt != null
                        ? value.UpdatedAt.format(getDateTimeFormatForLocale())
                        : t("common.unknown")}
                </td>
                <td>{value.UpdatedBy ? value.UpdatedBy.Name : ""}</td>
                <td style={getFixedCssWidths(70)}>
                    <AppDeleteButton
                        getConfirmation={true}
                        readonly={!hasDeletePermission || loading}
                        name={`${t(
                            "employee.vacations.vacation"
                        )} ${value.DateStart.format(
                            getDateFormatForLocale()
                        )} - ${value.DateEnd?.format(
                            getDateFormatForLocale()
                        )}`}
                        onClick={() => {
                            if (value.EncodedId) {
                                onDeleteBtnClick(value.EncodedId);
                            } else {
                                onDeleteBtnClick(
                                    defaultTo(value.Id, 0).toString()
                                );
                            }
                        }}
                    />
                </td>
            </tr>
            {value.EntryForCurrYear && (
                <tr
                    onClick={onClickToUse}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
                >
                    <td style={{ paddingLeft: "25px" }}>
                        {value.EntryForCurrYear.DateStart.format(
                            getDateFormatForLocale()
                        )}
                    </td>
                    <td>
                        {value.EntryForCurrYear.DateEnd
                            ? value.EntryForCurrYear.DateEnd.format(
                                  getDateFormatForLocale()
                              )
                            : ""}
                    </td>
                    <td>{value.EntryForCurrYear.Total}</td>
                    <td colSpan={10} />
                </tr>
            )}
        </>
    );
};

export default EmployeeVacationTableRow;
