import { useRouting } from "hooks/general/routing";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NavigationTab } from "models/general";
import LwcEditLayout from "screens/lillywait/common/LwcEditLayout";
import LwcIndustryTabs from "./tabs/LwcIndustryTabs";

export const LwcIndustryEditLayout: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<NavigationTab | undefined>(
        undefined
    );

    return (
        <LwcEditLayout
            backUrl={linkProvider.lillywait.clients().screens.industries.list()}
            currentTab={currentTab}
            defaultRoute={linkProvider.lillywait
                .clients()
                .screens.industries.withId()
                .edit()}
            tabs={<LwcIndustryTabs onActiveTabChange={setCurrentTab} />}
        />
    );
};

export default LwcIndustryEditLayout;
