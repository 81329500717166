import {
    showSwalLoader,
    showSweetAlertInfo,
} from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { ColumnMapping, ImportDataType } from "models/importData";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { BusinessImportDataService } from "services/business";
import { ColumnsMappingDialog } from "./ColumnsMappingDialog";
import { ImportDataDialog } from "./ImportDataDialog";

export interface ImportDataDialogContainerProps {
    opened: boolean;
    onClose: () => void;
    importDataType: ImportDataType;
    sampleFileUrl: string;
}

export interface ImportDataState {
    file?: File;
    isColumnsDialogOpen: boolean;
    isFileDialogOpen: boolean;
    canShowStatusDialog: boolean;
    isStatusAlertShown: boolean;
}
export const ImportDataDialogContainer: React.FC<
    ImportDataDialogContainerProps
> = ({ opened, importDataType, sampleFileUrl, onClose }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { getDateFormatForLocale } = useLocaleHelpers();
    const [state, setState] = useState<ImportDataState>({
        isColumnsDialogOpen: false,
        isFileDialogOpen: opened,
        canShowStatusDialog: false,
        isStatusAlertShown: false,
    });

    const businessImportService = new BusinessImportDataService(
        linkProvider.business.api.currentBusiness().importData
    );

    const {
        isLoading: gettingColumns,
        data: apiResponse,
        mutate: getColumnsInfo,
    } = useMutation(
        async (request: File) =>
            await businessImportService.getFileColumns(request)
    );

    const {
        isLoading: importing,
        data: importResponse,
        mutate: importData,
    } = useMutation(
        async ({ file, mappings }: { file: File; mappings: ColumnMapping }) => {
            const dateFormate = getDateFormatForLocale();
            const flatMapping = {
                ...Object.entries(mappings)
                    .map(([key, value]) => ({
                        [key]: value ? value.name : null,
                    }))
                    .reduce((result, curr) => {
                        return {
                            ...result,
                            ...curr,
                        };
                    }, {}),
            };
            if (importDataType == ImportDataType.CLIENT) {
                return await businessImportService.importClients(
                    file,
                    flatMapping,
                    dateFormate
                );
            } else if (importDataType == ImportDataType.EMPLOYEE) {
                return await businessImportService.importEmployee(
                    file,
                    flatMapping,
                    dateFormate
                );
            } else {
                return await businessImportService.importClientRegistrations(
                    file,
                    flatMapping,
                    dateFormate
                );
            }
        }
    );

    useEffect(() => {
        if ((importing || importResponse) && state.canShowStatusDialog) {
            if (importing && !state.isStatusAlertShown) {
                showSwalLoader(t("importData.uploadMessage"), "");
                setState({
                    ...state,
                    isStatusAlertShown: true,
                });
            } else {
                const onDialogClose = () => {
                    setState({
                        ...state,
                        canShowStatusDialog: false,
                        isFileDialogOpen: false,
                        isStatusAlertShown: false,
                    });
                    onClose();
                };
                if (importResponse) {
                    if (importResponse.Data) {
                        showSweetAlertInfo(
                            t("common.success"),
                            t("importData.successMessage"),
                            "success",
                            onDialogClose
                        );
                    } else {
                        showSweetAlertInfo(
                            t("common.error.error"),
                            t("importData.errorMessage"),
                            "error",
                            onDialogClose,
                            t("common.cancel")
                        );
                    }
                }
            }
        }
    }, [importing, importResponse, state]);

    return state.isFileDialogOpen ? (
        <ImportDataDialog
            modalOpen={state.isFileDialogOpen}
            sampleFileUrl={sampleFileUrl}
            file={state.file}
            onChange={(file?: File) => {
                setState({
                    ...state,
                    file: file,
                    isFileDialogOpen: false,
                    isColumnsDialogOpen: file != undefined || true,
                });
                if (file) {
                    getColumnsInfo(file);
                }
            }}
            onClose={() => {
                setState({
                    ...state,
                    isFileDialogOpen: false,
                    isColumnsDialogOpen: false,
                });
                onClose();
            }}
        />
    ) : state.isColumnsDialogOpen && !importing ? (
        <ColumnsMappingDialog
            importDataType={importDataType}
            modalOpen={state.isColumnsDialogOpen}
            fileColumnsList={apiResponse as any}
            loading={gettingColumns}
            onChange={(data: ColumnMapping) => {
                //api call to send data to server
                if (state.file) {
                    importData({ file: state.file, mappings: data });
                }
                setState({
                    ...state,
                    isFileDialogOpen: false,
                    canShowStatusDialog: true,
                    isColumnsDialogOpen: false,
                });
            }}
            onClose={() => {
                setState({
                    ...state,
                    isFileDialogOpen: true,
                    isColumnsDialogOpen: false,
                });
            }}
        />
    ) : (
        <></>
    );
};

export default ImportDataDialogContainer;
