import * as Sentry from "@sentry/react";
import { config } from "globals/config";
import { t } from "i18next";
import {
    Router,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import React from "react";

export function initSentry() {
    if (config.sentry.enable) {
        Sentry.init({
            dsn: config.sentry.dsn,
            enabled: config.sentry.enable,
            environment: config.sentry.env,
            release: config.release,
            beforeSend: (event) => {
                const exception = event.exception?.values && event.exception?.values[0]
                if (exception?.value?.toLowerCase().includes("Non-Error exception captured with keys:")) {
                    return null;
                }
                return event;
            },
            integrations: [
                new Sentry.BrowserTracing({
                    finalTimeout: 300 * 1000,
                    tracePropagationTargets: [config.apiBaseUrl],
                    shouldCreateSpanForRequest: (url) => {
                        // Do not create spans for outgoing requests to a `/health/` endpoint
                        return !url.match(/\/health\/?$/);
                    },

                    // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        React.useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    ),
                }),
                new Sentry.Replay(),
            ],
            tracesSampleRate: 1.0,
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
        });
    }
}

export function showSentryDialog(
    // custom?: boolean,
    userInfo: { email: string; name: string } | undefined = {
        email: "",
        name: "",
    },
    eventId?: string
) {
    // custom = defaultTo(custom, false);
    let settings: Sentry.ReportDialogOptions = { eventId: eventId };

    if (!eventId) {
        eventId = Sentry.captureEvent({
            message: t("sentry.userReportedIssue"),
        });
    }

    // if (custom) {
    //     settings = {
    //         ...settings,
    //         title: i18next.t("sentry.dialogTitle"),
    //         subtitle: "",
    //         labelSubmit: i18next.t("common.submit"),
    //         subtitle2: "",
    //     };
    // }
    if (userInfo.email.length > 0) {
        settings = {
            ...settings,
            user: userInfo,
        };
    }

    Sentry.showReportDialog(settings);
}
