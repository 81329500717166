import {
    applyValidations,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import { getInitializedValidityState } from "models/general/validityState";
import { Moment } from "moment-timezone";
import { RemainderType, ReminderUnit } from "./enum";

export interface AppReminder {
    Id: number;
    ReferenceDateTime: Moment;
    SendBefore: boolean; //always true
    RecurrenceLevel: ReminderUnit;
    ReminderTypes: RemainderType[];
    UnitValue: number;
}

export function validateReminder(
    reminder: AppReminder,
    state = getInitializedValidityState([], [])
) {
    state = applyValidations(
        state,
        [
            {
                rule: Validations.MIN,
                message: i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: 1,
                },
            },
        ],
        reminder.UnitValue,
        "UnitValue"
    );
    return state;
}
