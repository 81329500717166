import WorkingDayDataRow from "./WorkingDayDataRow";
import { AppButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { AppLoader } from "components/Loaders";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import {
    parseWorkingDaysResponse,
    PermissionAccessMap,
    validateWorkingDays,
    WorkingDayData,
    WorkingDays,
    WorkingDaysWithTime,
} from "models";
import { Business } from "models/business";
import { Optional, ValidityStateManager } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { getWorkingDaysServiceKey, WorkingDaysService } from "services";
import commonStyles from "commonPartials/commonStyles.module.scss";

export interface WorkingDaysSettingProps {
    currentBusiness: Optional<Business>; // null for location-setting views
    locationId?: number; // for lwc it will be lwc location-setting id
    permissionsMap: PermissionAccessMap;
    onSuccess?: () => void;
}

export const WorkingDaysSetting: React.FC<WorkingDaysSettingProps> = ({
    currentBusiness,
    locationId,
    permissionsMap,
    onSuccess,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const workingDaysService = new WorkingDaysService(
        linkProvider.api.workingHours(
            locationId ? (locationId as number) : 0,
            currentBusiness?.Id
        )
    );

    const [state, setState] = useState<WorkingDaysWithTime>(
        {} as WorkingDaysWithTime
    );

    const {
        isLoading: loadingData,
        isFetching: fetchingData,
        data: dataRes,
    } = useQuery(
        getWorkingDaysServiceKey("getWorkingDays", {
            locationId: locationId,
            bId: currentBusiness?.Id,
        }),
        async () => {
            return await workingDaysService.getWorkingDays();
        },
        { cacheTime: 15000 }
    );
    const {
        isLoading: updateLoading,
        data: updateRes,
        mutate: updateWorkingDays,
    } = useMutation(async (request: WorkingDaysWithTime) => {
        return await workingDaysService.updateWorkingDays(request);
    });

    useEffect(() => {
        if (!loadingData && dataRes) {
            if (dataRes.Data) {
                setState(parseWorkingDaysResponse(dataRes.Data));
            }
        }
    }, [loadingData, dataRes]);

    useEffect(() => {
        if (!updateLoading && updateRes) {
            if (updateRes.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `${t(`common.openingHours`)} ${t(
                        "common.actions.updatedSuccessfully"
                    )}`,
                    "success"
                );
                if (onSuccess) {
                    onSuccess();
                }
            } else if (updateRes.Errors) {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToUpdate"),
                    "error"
                );
            }
        }
    }, [updateLoading, updateRes]);

    //For validation
    const stateManager = useMemo(() => {
        return new ValidityStateManager(validateWorkingDays(state));
    }, [state]);

    return fetchingData ? (
        <AppLoader />
    ) : (
        <AppContainer
            classes={{ body: commonStyles.appContainerWithLessTopPadding }}
        >
            <div style={{ minHeight: "600px" }}>
                {Object.keys(state).map((f) => {
                    return (
                        <WorkingDayDataRow
                            key={f}
                            validityState={
                                stateManager.getFieldState(f)?.children
                            }
                            value={state[f as WorkingDays]}
                            day={f as WorkingDays}
                            readonly={!permissionsMap.EDIT}
                            onChange={(data: WorkingDayData) => {
                                setState({
                                    ...state,
                                    [f]: data,
                                });
                            }}
                        />
                    );
                })}
                <div style={{ marginTop: "10px" }}>
                    <AppButton
                        padding="med"
                        disabled={updateLoading || !permissionsMap.EDIT}
                        onClick={() => updateWorkingDays(state)}
                    >
                        {t("common.saveChanges")}
                    </AppButton>
                </div>
            </div>
        </AppContainer>
    );
};

export default WorkingDaysSetting;
