import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import {
    useSessionUser,
    useUpdateSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { t } from "i18next";
import { defaultTo } from "lodash-es";
import { EditProfileRequest } from "models";
import {
    getInitializedValidityState,
    ValidityState,
    getValidityStateFromApiResponse,
} from "models/general";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { AuthService } from "services";

export const useEditProfile = () => {
    const { user } = useSessionUser();
    const { linkProvider } = useRouting();
    const { updateUserInfo } = useUpdateSessionUser();
    const [validityState, setValidityState] = useState<ValidityState>(
        getInitializedValidityState([], [])
    );
    const [state, setState] = useState<EditProfileRequest>({
        FirstName: defaultTo(user?.FirstName, ""),
        LastName: defaultTo(user?.LastName, ""),
        Locale: defaultTo(user?.Locale, ""),
        Avatar: user?.Avatar ? user.Avatar : undefined,
    });

    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isLoading: loading,
        data: response,
        isError,
        mutate: updateProfile,
    } = useMutation(
        async (val: EditProfileRequest) => await authService.editProfile(val)
    );

    useEffect(() => {
        if (!loading && response) {
            if (
                response.Data &&
                (!response.Errors || response.Errors.length == 0)
            ) {
                updateUserInfo(response.Data, true);
                setState({
                    ...state,
                    NewPassword: undefined,
                    OldPassword: undefined,
                    ConfirmPassword: undefined,
                });
                showSweetAlertToast(
                    t("common.success"),
                    `${t("userProfile.success")}`,
                    "success"
                );
            } else {
                setValidityState(
                    getValidityStateFromApiResponse(response, validityState)
                );
            }
        } else if (!loading && isError) {
            showSweetAlertToast(
                t("common.error.error"),
                t("userProfile.error"),
                "error"
            );
        }
    }, [loading, response, isError]);

    return {
        updateProfile,
        validityState,
        setValidityState,
        state,
        setState,
        loading,
    };
};
