import classNames from "classnames";
import { getFormattedHoursFromMinutes } from "globals/helpers/localizationHelpers";
import { defaultTo } from "lodash-es";
import {
    BusinessShiftPlanData,
    ShiftPlanPerson,
    ShiftPlanRowTrack,
} from "models/shiftPlan";
import React, { useEffect } from "react";
import styles from "./ShiftPlanEmployeesInfoSection.module.scss";
import { AppTooltip } from "components/Tooltips";
import { useTranslation } from "react-i18next";

export interface ShiftPlanEmployeesInfoSectionProps {
    data: Omit<BusinessShiftPlanData, "ShiftAssignments">;
    mapping: ShiftPlanRowTrack[];
}

export const ShiftPlanEmployeesInfoSection: React.FC<
    ShiftPlanEmployeesInfoSectionProps
> = ({ data, mapping }) => {
    const { t } = useTranslation();
    const renderEmployeeItem = (e: ShiftPlanPerson, i: number) => {
        return (
            <div className={styles.item} key={`${e.EncodedId}_${i}`}>
                <span className={styles.name}>{e.Name}</span>
                <span
                    className={classNames(styles.hours, {
                        [styles.red]:
                            e.AssignedContractMinutes > e.MaxContractMin,
                    })}
                >
                    <AppTooltip content={t("shiftPlan.actualHours")}>
                        <span>
                            {getFormattedHoursFromMinutes(
                                defaultTo(e.AssignedContractMinutes, 0)
                            )}
                        </span>
                    </AppTooltip>
                    {"/"}
                    <AppTooltip content={t("shiftPlan.contractHours")}>
                        <span>
                            {getFormattedHoursFromMinutes(
                                defaultTo(e.MaxContractMin, 0)
                            )}
                        </span>
                    </AppTooltip>
                    {` h`}
                </span>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            {mapping.map((m, idx) => {
                if (!m.EmployeeId) {
                    return (
                        <div key={`${idx}_${m.text}`} className={styles.title}>
                            {m.text}
                        </div>
                    );
                }

                const emp = data.Employees.find((x) => x.Id == m.EmployeeId);
                return emp ? renderEmployeeItem(emp, idx) : <></>;
            })}
        </div>
    );
};

export default ShiftPlanEmployeesInfoSection;
