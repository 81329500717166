import { AppDialog, AppDialogFooter } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import { AppSortable } from "components/Sortable";
import { ImageAssets } from "globals/images";
import { defaultTo, isNil } from "lodash-es";
import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { arrayMove, SortableElement } from "react-sortable-hoc";
import LocationSettingModuleField from "./LocationSettingModuleField";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    ModuleFeatureInfo,
    ModulePlanContent,
    validateModulePlanContent,
} from "models/modulePlans";
import { v4 as uuid } from "uuid";
import TinymceEditor from "components/TinymceEditor";
import { INPUT_COLOR } from "theme/themeConstants";
import {
    AppLocale,
    LocaleEnumToTranslationKey,
    ValidityStateManager,
} from "models/general";
import { AppTooltip } from "components";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";

export interface LocationSettingModulePlanContentDialogProps {
    value?: ModulePlanContent;
    currentLocale: string;
    dialogOpen: boolean;
    readonly: boolean;
    onDialogClose: () => void;
    onSave: (content: ModulePlanContent) => void;
}

const SortableItem: any = SortableElement(LocationSettingModuleField);

export const LocationSettingModulePlanContentDialog: React.FC<
    LocationSettingModulePlanContentDialogProps
> = ({ value, currentLocale, readonly, dialogOpen, onDialogClose, onSave }) => {
    const contentValue = defaultTo(value, {
        Id: 0,
        Title: "",
        Description: "",
        Features: [],
    } as ModulePlanContent);
    contentValue.Features = contentValue.Features.map((x) => {
        return { ...x, Uuid: isNil(x.Uuid) ? uuid() : x.Uuid };
    });

    const { t } = useTranslation();
    const [content, setContent] = useState<ModulePlanContent>(contentValue);

    const validityStateManager = useMemo(() => {
        const validityState = validateModulePlanContent(content);
        return new ValidityStateManager(validityState);
    }, [content]);

    const [defaultFeatureText, setDefaultFeatureText] = useState("");

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        setContent({
            ...content,
            Features: arrayMove(content.Features, oldIndex, newIndex).map(
                (f, idx) => {
                    return { ...f, Order: idx } as ModuleFeatureInfo;
                }
            ),
        });
    };

    const title = t(
        `common.language.${
            LocaleEnumToTranslationKey[currentLocale as AppLocale]
        }`
    );

    const addNewContent = () => {
        if (
            !readonly &&
            !isNil(defaultFeatureText) &&
            defaultFeatureText.length > 0 &&
            content.Features.filter((x) => x.Text == defaultFeatureText)
                .length == 0
        ) {
            setContent({
                ...content,
                Features: [
                    ...content.Features,
                    {
                        Uuid: uuid(),
                        Text: defaultFeatureText,
                        IsIncluded: true,
                        Order: content.Features.length,
                    } as ModuleFeatureInfo,
                ],
            });
            setDefaultFeatureText("");
        } else {
            showSweetAlertToast(
                t("common.error.error"),
                t("locationSettings.modulePlan.contentDialog.features.invalid"),
                "error"
            );
        }
    };

    return (
        <AppDialog
            title={defaultTo(value?.Title, title)}
            modalOpen={dialogOpen}
            onClose={onDialogClose}
            size={"lg"}
            footer={
                <AppDialogFooter
                    onDialogClose={onDialogClose}
                    disableSaveButton={!validityStateManager.isStateValid()}
                    onClickSaveButton={() => onSave(content)}
                />
            }
        >
            <Row>
                <Col md={12}>
                    <AppInputField
                        label={t(
                            "locationSettings.modulePlan.contentDialog.title.name"
                        )}
                        showEmptyError={true}
                        onValueChange={(val) =>
                            setContent({
                                ...content,
                                Title: defaultTo(val, ""),
                            })
                        }
                        value={content.Title}
                        error={validityStateManager.getFirstErrorInfo("Title")}
                    />
                </Col>
                <Col md={12} className={"mb-2"}>
                    <Form.Label>
                        {t(
                            "locationSettings.modulePlan.contentDialog.description"
                        )}
                    </Form.Label>
                    <TinymceEditor
                        value={{
                            placeholders: [],
                            html: content.Description,
                        }}
                        allowedPlaceholderTypes={[]}
                        fontColor={INPUT_COLOR}
                        tinymceProps={{
                            menubar: false,
                            contextmenu: false,
                            max_height: 100,
                            min_height: 100,
                            toolbar: false,
                        }}
                        onChange={(newState) => {
                            setContent({
                                ...content,
                                Description: defaultTo(newState.html, ""),
                            });
                        }}
                    />
                </Col>
                <Col md={12}>
                    <Form.Label className="font-weight-bold">
                        {t(
                            "locationSettings.modulePlan.contentDialog.features.name"
                        )}
                    </Form.Label>
                </Col>
                <Col md={12}>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="flex-grow-1 mr-3">
                            <AppInputField
                                className="mb-0"
                                value={defaultFeatureText}
                                readOnly={readonly}
                                onValueChange={(val) => {
                                    setDefaultFeatureText(defaultTo(val, ""));
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter" || e.keyCode === 13) {
                                        addNewContent();
                                    }
                                }}
                            />
                        </div>
                        <AppTooltip
                            content={t(
                                "locationSettings.modulePlan.addFeature"
                            )}
                        >
                            <Image
                                onClick={() => addNewContent()}
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                }}
                                src={ImageAssets.common.plusBlue}
                            />
                        </AppTooltip>
                    </div>
                </Col>
                <Col md={12}>
                    <AppSortable<ModuleFeatureInfo>
                        SortableItem={SortableItem}
                        list={content.Features}
                        onChange={(feature) => {
                            setContent({
                                ...content,
                                Features: content.Features.map((x) => {
                                    if (x.Uuid == feature.Uuid) {
                                        return feature;
                                    }
                                    return x;
                                }),
                            });
                        }}
                        readonly={readonly}
                        onDelete={(uuid) =>
                            setContent({
                                ...content,
                                Features: content.Features.filter(
                                    (x) => x.Uuid != uuid
                                ),
                            })
                        }
                        handleSortEnd={handleSortEnd}
                        itemCommonProps={{ contentFeatures: content.Features }}
                    />
                </Col>
            </Row>
        </AppDialog>
    );
};

export default LocationSettingModulePlanContentDialog;
