export const AuthEnTranslations = {
    auth: {
        linkExpired: {
            title: "OOOOpppppps...",
            text: "This link is expired. Please request a new one from company admin.",
        },
        userAlreadyRegistered: "User has already been registered.",
        companyEmail: "Company Email",
        companyName: "Company Name",
        completeUserRegistration: {
            // for Registration Request Completion (Set password)
            title: "Set Password",
        },
        confirmation: {
            emailConfirmed: {
                desc: "You can Log in now.",
                pageTitle: "Register successful",
                title: "Thank you for your Registration!",
            },
            emailConfirmFailed: {
                pageTitle: "Email verification failed",
                title: "Email verification failed!",
            },
            forgotPassword: {
                desc: "Password reset link has been sent to your email address. Please also check your spam folder.",
                pageTitle: "Forgot password",
                title: "Forgot Password!",
            },
            registerRequestConfirmation: {
                desc: "We have received your registration request. We will review it and get in touch with you.",
                pageTitle: "Request Received",
                title: "Request Received!",
            },
            registrationComplete: {
                desc: "You can Log in now.",
                pageTitle: "Register successful",
                title: "Thank you for your Registration!",
            },
            resetPassword: {
                desc: "Your password has been reset.",
                mobile: {
                    pageTitle: "App Password Reset",
                    title: "App Password Reset !",
                },
                website: {
                    pageTitle: "Reset Password",
                    title: "Reset Password!",
                },
            },
        },
        confirmPassword: "Confirm Password",
        email: "Email",
        employeeInfoRegister: {
            title: "Please Assign a Password",
        },
        errorMessages: {
            companyName: {
                required: "Company name is required.",
            },
            confirmPassword: {
                compare: "Password and confirm password not match.",
                required: "Confirm password is required.",
            },
            email: {
                invalid: "Please check that your email is the correct format",
                required: "Email is required.",
            },
            facilityName: {
                required: "Facility name is required.",
            },
            firstName: {
                required: "First name is required.",
            },
            lastName: {
                required: "Last name is required.",
            },
            password: {
                required: "Password is required.",
            },
            registration: "Unable to create user, try again later...",
        },
        facilityName: "Facility Name",
        firstName: "First Name",
        forgotPassword: {
            desc: "Enter your email address and a password will be sent to you",
            pageTitle: "Forgot Password",
            sendEmail: "Send Email",
            title: "Forgot Password!",
        },
        hqRegisterRequest: {
            email: {
                invalid: "Please check that your email is the correct format",
                name: "Email",
                required: "Email is required.",
            },
            errorMessage:
                "Unable to send registration request, try again later...",
            facilityName: {
                name: "Facility Name",
                required: "Facility name is required.",
            },
            firstName: {
                name: "First Name",
                required: "First name is required.",
            },
            lastName: {
                name: "Last Name",
                required: "Last name is required.",
            },
            name: "Register",
            pageTitle: "Register",
            phone: {
                name: "Phone",
                required: "Phone is required.",
            },
            send: "Register",
            title: "Register now free of charge",
        },
        lastName: "Last Name",
        login: {
            forgotPassword: "Forgot your password?",
            name: "Log in",
            pageTitle: "Login",
            rememberMe: "Remember me",
            title: "Welcome to Lillywait!",
        },
        password: "Password",
        registration: {
            name: "Registration",
            pageTitle: "Register",
            register: "Register",
            termsConditions: {
                context1: "By completing this you agree to our ",
                context2: "Terms and Conditions",
                context3: " and ",
                context4: "Privacy Policy",
                context5:
                    "After registration, neither payments nor cancellation are necessary.",
            },
            title: "Test Lillywait now for free!",
        },
        resetPassword: {
            desc: "Enter a new password and confirm it with another input",
            mobile: {
                pageTitle: "App Reset Password",
                title: "App Reset Password!",
            },
            name: "Reset",
            newPassword: "New Password",
            website: {
                pageTitle: "Reset password",
                title: "Reset Password!",
            },
        },
        sessionTimeOut: {
            pageTitle: "Session expired",
            title: "Session Timed Out",
        },
    },
};

export default AuthEnTranslations;
