import { CustomSelectInput } from "components/AppSelect/AppSelectOld";
import React from "react";
import ReactSelect from "react-select";
import { CustomSelect } from "./partials";
import { ComposedSelect, SelectProps, SimpleOption } from "./partials/types";

export type AppSelectProps<OptionType = SimpleOption> = ComposedSelect<
    SelectProps<OptionType>,
    OptionType
>;

function Component(props: AppSelectProps) {
    return (
        <CustomSelect
            onReload={() => {}}
            {...props}
            Component={ReactSelect}
            components={{
                Input: CustomSelectInput,
            }}
        />
    );
}

export const AppSelect = Component;
export default AppSelect;
