import classNames from "classnames";
import React, { CSSProperties, forwardRef, Ref } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import cssClasses from "./AppButton.module.scss";

export interface AppButtonProps extends ButtonProps {
    buttonColor?: string;
    fontColor?: string;
    colorVariant?: "blue" | "red" | "transparent";
    padding?: "low" | "med" | "max" | "default";
    rounded?: boolean;
    useShadow?: boolean;
    onCustomClick?: () => void;
    children?: React.ReactElement;
}

function Component(
    {
        buttonColor,
        fontColor,
        children,
        colorVariant,
        padding = "max",
        rounded = false,
        useShadow = false,
        onCustomClick,
        className,
        style,
        ...rest
    }: AppButtonProps,
    ref: Ref<any>
) {
    const styles: CSSProperties = {
        ...style,
        border: "none",
        ...(padding !== "default"
            ? {
                  padding:
                      padding === "max"
                          ? "11px 24px"
                          : padding === "med"
                          ? "8px 16px"
                          : "4px 12px",
              }
            : {}),
        ...(buttonColor
            ? { backgroundColor: `${buttonColor}  !important` }
            : {}),
        ...(useShadow
            ? {
                  boxShadow: `0 0.125rem 0.625rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15)`,
              }
            : {}),
        ...(fontColor ? { color: fontColor } : {}),
        ...(rounded ? { borderRadius: "50px" } : {}),
    };

    if (onCustomClick) {
        rest.onClick = (e: any) => {
            if (ref) {
                if (e.target === ref) {
                    onCustomClick();
                }
            }
        };
    }
    return (
        <Button
            {...rest}
            ref={ref}
            style={styles}
            className={classNames(
                className,
                { [cssClasses.btnTransparent]: colorVariant == "transparent" },
                { [cssClasses.btnRed]: colorVariant == "red" },
                { [cssClasses.btnBlue]: colorVariant == "blue" }
            )}
        >
            {children ? children : <></>}
        </Button>
    );
}
export const AppButton = forwardRef(Component);
export default AppButton;
