export const EmojiPickerEnTranslations = {
    emojiPicker: {
        title: "Pick Emoji",
        search: "Search",
        clear: "Clear", // Accessible label on "clear" button
        notfound: "No Emoji Found",
        skintext: "Choose your default skin tone",
        categories: {
            search: "Search Results",
            recent: "Frequently Used",
            smileys: "Smileys & Emotion",
            people: "People & Body",
            nature: "Animals & Nature",
            foods: "Food & Drink",
            activity: "Activity",
            places: "Travel & Places",
            objects: "Objects",
            symbols: "Symbols",
            flags: "Flags",
            custom: "Custom",
        },
        categorieslabel: "Emoji categories", // Accessible title for the list of categories
        skintones: {
            1: "Default Skin Tone",
            2: "Light Skin Tone",
            3: "Medium-Light Skin Tone",
            4: "Medium Skin Tone",
            5: "Medium-Dark Skin Tone",
            6: "Dark Skin Tone",
        },
    },
};

export default EmojiPickerEnTranslations;
