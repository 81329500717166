import { useRouting } from "hooks/general/routing";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AuthLoader, ResetPasswordFields } from "./partials";

export const RESET_PASSWORD_CODE_PARAM_KEY = "code";
export const RESET_PASSWORD_EMAIL_PARAM_KEY = "email";
export const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { linkProvider } = useRouting();

    const code = searchParams.get(RESET_PASSWORD_CODE_PARAM_KEY);
    const email = searchParams.get(RESET_PASSWORD_EMAIL_PARAM_KEY);

    useEffect(() => {
        if (!code || !email) {
            navigate(linkProvider.noAuth.screens.login());
        }
    }, []);

    return code && email ? (
        <AuthLoader
            renderContentWithLoader={(setLoading) => (
                <ResetPasswordFields setLoading={setLoading} />
            )}
        />
    ) : (
        <></>
    );
};

export default ResetPassword;
