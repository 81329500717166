import classNames from "classnames";
import RenewContractFields from "commonPartials/RenewContractFields";
import { AppContentHeader, ErrorMessage } from "components";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppContainer } from "components/Containers";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import {
    useSessionBusiness,
    useSessionUser,
    useUpdateSessionBusinessInfo,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import { BusinessType, CompanyType } from "models/business";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { BusinessService, getBusinessServiceKey } from "services/business";

export const RenewBusinessContract: React.FC = () => {
    const { sessionBusiness, encodedId, isContractExpired } =
        useSessionBusiness();
    const { user } = useSessionUser();
    const { t } = useTranslation();
    const { checkPermission } = useCheckPermission();
    const navigate = useNavigate();
    const { setSessionBusinessAndLocation: updateSessionBusinessContext } =
        useUpdateSessionBusinessInfo();

    const isSessionBusinessIsLw = sessionBusiness?.Type == BusinessType.LW;

    const { linkProvider } = useRouting();
    const businessService = new BusinessService(linkProvider.business.api);

    const companyType = CompanyType.Headquarter;

    const { isLoading, data: response } = useQuery(
        getBusinessServiceKey("getAdminBusinessesList", {
            userId: user?.Id,
            companyType,
        }),
        async () => await businessService.getAdminBusinessesList(companyType)
    );

    useEffect(() => {
        if (!isContractExpired) {
            navigate(linkProvider.screens.home(checkPermission));
        }
    }, []);
    useEffect(() => {
        if (changeSessionHQResp?.Data) {
            navigate(linkProvider.screens.home(checkPermission));
        }
    }, [encodedId]);

    const {
        isLoading: changeSessionHQLoading,
        data: changeSessionHQResp,
        mutate: changeSessionHQ,
    } = useMutation(
        getBusinessServiceKey("changeActiveHq"),
        async (hqId: number) => await businessService.changeActiveHq(hqId),
        {
            onError: () => {
                showSweetAlertInfo(
                    t("common.error.error"),
                    t(`business.unableToChangeActiveBusiness`),
                    "error"
                );
            },
        }
    );

    useEffect(() => {
        if (!changeSessionHQLoading && changeSessionHQResp?.Data) {
            updateSessionBusinessContext(changeSessionHQResp.Data);
            showSweetAlertToast(
                t("common.success"),
                t(`business.activeBusinessChanged`),
                "success"
            );
        }
    }, [changeSessionHQLoading, changeSessionHQResp]);

    const defaultOption: SimpleOption = {
        label: t("business.changeHQ").toString(),
        value: "",
    };
    const businessHqs =
        response && response.Data
            ? ([
                  defaultOption,
                  ...response.Data.map((x) => {
                      return {
                          label: x.HqInfo.Name,
                          value: x.Id,
                      };
                  }),
              ] as SimpleOption[])
            : [defaultOption];

    return (
        <div>
            <AppContentHeader
                title={defaultTo(sessionBusiness?.Name, "")}
                classNameForIcon={"pe-7s-diamond"}
            />
            {!isSessionBusinessIsLw && sessionBusiness && (
                <AppContainer>
                    <div
                        className="d-flex align-items-center pb-2"
                        style={{ marginTop: "-1rem" }}
                    >
                        <h5 className={classNames("text-danger")}>
                            {t("businessContract.renew.titleErrorMessage")}
                        </h5>
                        {response?.Data && response.Data.length > 1 && (
                            <div
                                style={{
                                    ...getFixedCssWidths(350),
                                    marginLeft: "auto",
                                }}
                            >
                                <AppSelectOld
                                    className="mt-3"
                                    menuPortalTarget={document.body}
                                    menuPlacement="top"
                                    isLoading={isLoading}
                                    options={businessHqs}
                                    value={businessHqs[0]}
                                    getOptionLabel={(opt: SimpleOption) =>
                                        opt.label
                                    }
                                    getOptionValue={(opt: SimpleOption) =>
                                        opt.value
                                    }
                                    onChange={(e: SimpleOption) => {
                                        changeSessionHQ(e.value);
                                    }}
                                />
                                <ErrorMessage showEmpty={true} />
                            </div>
                        )}
                    </div>
                    <RenewContractFields
                        businessId={sessionBusiness?.Id}
                        onSuccess={() => {
                            showSweetAlertToast(
                                t("common.success"),
                                t(
                                    `businessContract.renew.contractRenewedSuccessfully`
                                ),
                                "success"
                            );
                            setTimeout(() => {
                                // need to do full refresh here
                                window.location.href =
                                    linkProvider.screens.home(checkPermission);
                            }, 3000);
                        }}
                    />
                </AppContainer>
            )}
        </div>
    );
};

export default RenewBusinessContract;
