import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { WorkingDays } from "./enum";
import { WorkingDayData, WorkingDaysWithTime } from "./model";

//validation scheme for this row
export function validateWorkingDayData(
    field: WorkingDayData,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof WorkingDayData> =
        !field.IsOpen24Hours
            ? {
                  From: [
                      {
                          rule: Validations.REQUIRED,
                          message: i18next.t("workingDays.from.missing"),
                      },
                      {
                          rule: Validations.LESSER_DATE,
                          message: i18next.t("workingDays.from.invalid"),
                          options: {
                              value: field.To,
                          },
                      },
                  ],
                  To: [
                      {
                          rule: Validations.REQUIRED,
                          message: i18next.t("workingDays.to.missing"),
                      },
                      {
                          rule: Validations.GREATER_DATE,
                          options: {
                              value: field.From,
                          },
                          message: i18next.t("workingDays.to.invalid"),
                      },
                  ],
              }
            : {};
    state = getInitializedValidityStateFromRules(validationRules, field, state);

    return state;
}

// loop on fields list and passing to the validation methods
export function validateWorkingDays(
    fields: WorkingDaysWithTime,
    state = getInitializedValidityState([], [])
) {
    let manager = new ValidityStateManager(state);
    Object.keys(fields).map((dayName) => {
        manager = manager.replaceFieldState(dayName, {
            errors: [],
            identifier: dayName,
            children: validateWorkingDayData(
                fields[dayName as WorkingDays],
                manager.getFieldState(dayName)?.children
            ),
        });
    });
    return manager.state;
}
