import { AppDialog, AppDialogFooter, AppDialogProps } from "components/Dialogs";
import ErrorMessage from "components/ErrorMessage";
import { Validations } from "globals/helpers/validationHelpers";
import { defaults } from "lodash-es";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import React, { useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FILE_KEY: string = "file";
export interface SelectFileDialogProps
    extends Omit<AppDialogProps, "children"> {
    file?: File;
    modalOpen: boolean;
    onClose: (file?: File) => void;
    fileKey?: string;
    allowedFileTypes?: string[];
    labels?: {
        fileLabel?: string;
        nextButton?: string;
        cancel?: string;
    };
    messages?: {
        fileNotAllowed?: string;
        fileMissing?: string;
        chooseFile?: string;
    };
    children?: React.ReactNode;
}

export const SelectFileDialog: React.FC<SelectFileDialogProps> = ({
    title,
    file: oldFile,
    onChange,
    fileKey = FILE_KEY,
    allowedFileTypes = [".xlsx", ".xls"],
    children,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();

    const labels = defaults(rest.labels, {
        fileLabel: t("fileDialog.fileLabel").toString(),
        nextButton: t("fileDialog.next").toString(),
        cancel: t("common.cancel").toString(),
    });
    const messages = defaults(rest.messages, {
        fileNotAllowed: t("fileDialog.fileNotAllowed").toString(),
        fileMissing: t("fileDialog.fileMissing").toString(),
        chooseFile: t("common.fileInput.chooseFile").toString(),
    });
    const [file, setFile] = useState<any>(oldFile);

    const validityStateManager = useMemo(() => {
        let mgr = new ValidityStateManager(getInitializedValidityState([], []));
        if (file == null) {
            mgr = mgr.replaceErrors(fileKey, {
                message: messages.fileMissing,
                code: Validations.REQUIRED,
            });
        } else {
            //check extension
            const tmp = file.name.split(".");
            const extension = `.${tmp[tmp.length - 1]}`;
            if (!allowedFileTypes.includes(extension)) {
                mgr = mgr.replaceErrors(fileKey, {
                    message: messages.fileNotAllowed,
                    code: Validations.REQUIRED,
                });
            }
        }
        return mgr;
    }, [file]);

    const filesInputRef: any = useRef(null);
    const filesSelectedTextRef = useRef(null);
    const filesSelectedText = useMemo(() => {
        let txt = messages.chooseFile;
        if (file) {
            txt = file.name;
        }
        return txt;
    }, [file]);

    return (
        <AppDialog
            onClose={() => onClose()}
            footer={
                <AppDialogFooter
                    onDialogClose={onClose}
                    onClickSaveButton={() => onChange(file)}
                    saveButtonText={labels.nextButton}
                    disableSaveButton={
                        file == null || !validityStateManager.isStateValid()
                    }
                />
            }
            {...rest}
        >
            <div className="flex-column" style={{ margin: "10px 0px" }}>
                <Form.Label>{labels.fileLabel}</Form.Label>
                <Form.Group controlId="files" className="custom-file">
                    <Form.Control
                        ref={filesInputRef}
                        type="file"
                        accept={allowedFileTypes.join(", ")}
                        className="custom-file-input"
                        onChange={(e) => {
                            if (
                                filesInputRef.current &&
                                filesInputRef.current.files.length > 0
                            ) {
                                setFile(filesInputRef.current.files[0]);
                            } else {
                                setFile(null);
                            }
                        }}
                    />
                    <Form.Label
                        className="custom-file-label"
                        ref={filesSelectedTextRef}
                    >
                        {filesSelectedText}
                    </Form.Label>
                    <ErrorMessage
                        errorInfo={validityStateManager.getFirstErrorInfo(
                            fileKey
                        )}
                    />
                </Form.Group>
            </div>
            {children}
        </AppDialog>
    );
};

export default SelectFileDialog;
