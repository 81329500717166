import { defaults, defaultTo } from "lodash-es";
import React from "react";
import { Props } from "react-select/src/Select";
import { FixedSizeListProps } from "react-window";
import {
    COLOR_BLUE_LIGHT,
    COLOR_GREY_LIGHTEST,
    COLOR_PRIMARY,
    COLOR_TEXT_DARK,
    COLOR_TEXT_GREY,
    INPUT_COLOR,
} from "theme/themeConstants";
import { MenuList as ReactWindowMenuList } from "./CustomComponents";
import { CustomSelectComponentSelectProps, CustomSelectProps } from "./types";
import { OptionTypeBase } from "react-select";

const ccomponents = {
    ReactWindowMenuList,
};

function Component<
    TProps extends CustomSelectProps<SProps, OptionType>,
    SProps extends Props<OptionType>,
    OptionType extends OptionTypeBase
>({ components, ...props }: TProps) {
    const {
        styles,
        showDropdownIndicator,
        fullWidth,
        allowReload,
        useReactWindow = false,
        overrideStyles = false,
        onReload,
        fixedSizeListProps,
    } = props;

    defaults(
        components,
        useReactWindow
            ? {
                  MenuList: ccomponents.ReactWindowMenuList,
              }
            : {}
    );

    const extraInjectedProps: CustomSelectComponentSelectProps = {
        controlProps: {
            fullWidth: fullWidth,
            allowReload,
            onReload,
        },
        fixedSizeListProps:
            fixedSizeListProps != undefined
                ? fixedSizeListProps
                : ({} as FixedSizeListProps),
    };

    const TComponent: any = props.Component;

    return (
        <TComponent
            {...props}
            components={components}
            theme={(theme: any) => ({
                ...theme,
                borderRadius: 3,
                fontSize: "0.95rem",
                colors: {
                    ...theme.colors,
                    text: "black !important",
                    primary75: COLOR_BLUE_LIGHT,
                    primary: COLOR_PRIMARY,
                },
            })}
            {...extraInjectedProps}
            styles={{
                input: (base: any) => ({
                    ...base,
                    borderWidth: 0,
                    color: COLOR_TEXT_DARK,
                    fontSize: "0.95rem",
                    ...defaultTo(styles?.input, {}),
                }),
                menuPortal: (base: any) => ({
                    ...base,
                    zIndex: base.zIndex && base.zIndex > 9 ? base.zIndex : 9,
                    ...defaultTo(styles?.menuPortal, {}),
                }),
                control: (base: any, state: any) => {
                    const temp = {
                        ...base,
                        padding: "0px 20px",
                        fontSize: "0.95rem",
                        color: INPUT_COLOR,
                        cursor: "pointer",
                        borderRadius: "1.25rem",
                        borderWidth: 0,
                        boxShadow: "0 2px 7px hsl(30deg 0% 0% / 10%)",
                        ...defaultTo(styles?.control, {}),
                    };
                    return state.isFocused
                        ? {
                              ...temp,
                              zIndex: 8,
                              backgroundColor: "white",
                              borderWidth: "1px",
                              borderColor: "#16aaff !important",
                              boxShadow: "0 0 0 0.2rem #e1f3ff !important",
                              outline: 0,
                          }
                        : temp;
                },
                option: (base: any, state: any) => {
                    return {
                        ...base,
                        fontWeight: 400,
                        fontSize: "0.95rem",
                        color: state.isSelected ? "white" : COLOR_TEXT_DARK,
                        borderRadius: "5px",
                        padding: "5px 12px",
                        textAlign: "start !important",
                        display: "block",
                        overflow: "hidden",
                        wordBreak: "break-all",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        "&:active": {
                            color: "white",
                        },
                        "&:hover": !state.isSelected
                            ? {
                                  backgroundColor: COLOR_BLUE_LIGHT,
                              }
                            : {},
                        ...defaultTo(styles?.option, {}),
                    };
                },
                indicatorSeparator: (base: any) => ({
                    ...base,
                    display: "none",
                    ...defaultTo(styles?.indicatorSeparator, {}),
                }),
                valueContainer: (base: any) => ({
                    ...base,
                    overflowY: "auto",
                    maxHeight: "150px",
                    ...defaultTo(styles?.valueContainer, {}),
                }),
                indicatorsContainer: (base: any) => ({
                    ...base,
                    minHeight: "100%",
                    ">img": {
                        display: "flex",
                    },
                    ...defaultTo(styles?.indicatorsContainer, {}),
                }),
                dropdownIndicator: (base: any) => ({
                    ...base,
                    padding: "5px",
                    height: "100%",
                    color: COLOR_TEXT_GREY,
                    alignItems: "center",
                    display: !showDropdownIndicator ? "none" : "flex",
                    ...defaultTo(styles?.dropdownIndicator, {}),
                }),
                multiValueRemove: (base: any) => ({
                    ...base,
                    cursor: "pointer !important",
                    borderRadius: "4px",
                    padding: "4px 2px",
                    marginLeft: "5px",
                    "& svg": {
                        color: COLOR_TEXT_DARK,
                        height: "16px",
                        width: "18px",
                    },
                    ...defaultTo(styles?.multiValueRemove, {}),
                }),
                multiValue: (base: any, data: any) => {
                    const receivedStyles = defaultTo(
                        typeof styles?.multiValue == "function"
                            ? styles?.multiValue(data)
                            : styles?.multiValue,
                        {}
                    );
                    return {
                        ...base,
                        background: COLOR_GREY_LIGHTEST,
                        borderRadius: "4px",
                        padding: "0px",
                        fontSize: "0.925rem !important",
                        margin: "1px",
                        ...receivedStyles,
                    };
                },
                multiValueLabel: (base: any) => ({
                    ...base,
                    color: COLOR_TEXT_DARK,
                    padding: "0px",
                    fontSize: "0.925rem !important",
                    ...defaultTo(styles?.multiValueLabel, {}),
                }),
                menu: (base: any) => {
                    return {
                        ...base,
                        zIndex: 5,
                        ...defaultTo(styles?.menu, {}),
                        marginTop: "-17px",
                        padding: "25px 10px 10px 10px",
                        boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)",
                        borderRadius: "0 0 1.25rem 1.25rem",
                    };
                },
                ...(overrideStyles ? styles : {}),
            }}
        />
    );
}
Component.displayName = "CustomSelect";
Component.defaultProps = {
    menuShouldBlockScroll: false,
    showDropdownIndicator: true,
    hideSelectedOptions: false,
    components: ccomponents,
    filterOption: undefined,
    isMulti: false,
    isSearchable: true,
    placeholder: "",
    isClearable: false,
    allowReload: false,
    onReload: () => {},
    value: null,
    fullWidth: false,
};

export const CustomSelect = Component;
export default CustomSelect;
