import { ISO8601_DATE_FORMAT } from "globals/constants";
import { v4 } from "uuid";
import moment from "moment";
import {
    CareScopeTemplate,
    CareScopeTemplateSurchargeFieldGroup,
} from "./model";
import { CareScopeTemplateResponse } from "./response";
import {
    CareScopeTemplateField,
    CareScopeTemplateSurchargeField,
} from "./model";
import { CareScopeTemplateFieldType } from "./enum";

export const getCareScopeTemplateFromResponse = (
    req: CareScopeTemplateResponse
): CareScopeTemplate => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        ParentEndDate: req.ParentEndDate
            ? moment(req.ParentEndDate, ISO8601_DATE_FORMAT)
            : undefined,
        FollowUpTemplates:
            req.FollowUpTemplates?.length > 0
                ? req.FollowUpTemplates.map((x) => {
                      return getCareScopeTemplateFromResponse(x);
                  })
                : ([] as CareScopeTemplate[]),
    } as CareScopeTemplate;
};

export const getDefaultCareScopeField = (): CareScopeTemplateField => {
    return {
        Uuid: v4(),
        Order: 0,
        Name: "",
        ShortName: "",
        CareScopeId: 0,
        Description: "",
        CareEndInMinutes: 0,
        CareStartInMinutes: 0,
    };
};

export const getDefaultCareScopeSurChargeFieldGroup =
    (): CareScopeTemplateSurchargeFieldGroup => {
        return {
            Uuid: v4(),
            Order: 0,
            Name: "",
            CareScopeId: 0,
            Fields: [],
        };
    };
export const getDefaultCareScopeSurChargeField = (
    groupUuid: string
): CareScopeTemplateSurchargeField => {
    return {
        Uuid: v4(),
        Order: 0,
        Name: "",
        ShortName: "",
        FieldGroupUuid: groupUuid,
        Description: "",
    };
};

export const CareScopeTemplateFieldTypeManager = {
    [CareScopeTemplateFieldType.MAIN]: {
        defaultValue: () => {
            return getDefaultCareScopeField();
        },
    },
    [CareScopeTemplateFieldType.SURCHARGE_GROUP]: {
        defaultValue: () => {
            return getDefaultCareScopeSurChargeFieldGroup();
        },
    },
    [CareScopeTemplateFieldType.SURCHARGE]: {
        defaultValue: (groupUuid?: string) => {
            return getDefaultCareScopeSurChargeField(groupUuid as string);
        },
    },
};

export const getDefaultCareScopeTemplate = (
    locationId?: number,
    businessId?: number
): CareScopeTemplate => {
    const fields: CareScopeTemplateField[] = [getDefaultCareScopeField()];

    return {
        StartDate: moment(),
        Id: 0,
        Name: "",
        LocationSettingId: locationId,
        BusinessId: businessId,
        ShortName: "",
        Fields: fields,
        SurchargeFieldGroups: [],
    } as CareScopeTemplate;
};
