import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { defaultTo } from "lodash";
import { CustomColorItemOption } from "models/general";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    COLOR_GREY_LIGHTEST,
    COLOR_LIGHT_RED,
    COLOR_RED,
    COLOR_TEXT_DARK,
} from "theme/themeConstants";
import AppSelect from "./AppSelect";
import AppSelectOld from "./AppSelectOld";

export interface AppColoredMultiSelectDropdownProps {
    onChange: (val: CustomColorItemOption[]) => void;
    data: CustomColorItemOption[];
    value: CustomColorItemOption[];
    loading?: boolean;
    isReadonly?: boolean;
    useOldUi?: boolean;
    allowAllSelection?: boolean;
    hasNoneValue?: boolean;
    noneLabel?: string;
    menuPortalTarget?: any;
}

const Dropdown = React.memo((props: AppColoredMultiSelectDropdownProps) => {
    const { t } = useTranslation();

    const {
        value,
        data: items,
        onChange,
        isReadonly,
        allowAllSelection = true,
        hasNoneValue = false,
        noneLabel = t("common.none"),
    } = props;

    const allValue: CustomColorItemOption = {
        label: t("common.all"),
        value: "",
    };

    const val = defaultTo(value, []);
    const noneValue: CustomColorItemOption = {
        label: noneLabel ? noneLabel : t("common.none"),
        value: null,
    };

    const itemsList = useMemo((): CustomColorItemOption[] => {
        if (items) {
            return items.map((x) => ({
                label: x.label,
                value: x.value,
                color: x.color,
            }));
        }
        return [];
    }, [items]);

    const currentItem = useMemo(() => {
        let selected: CustomColorItemOption[] = [];
        const its = itemsList;
        const filteredItems = its.filter(
            (x) => x.value != allValue.value && x.value !== noneValue.value
        );

        selected = filteredItems.filter(
            (x) => !!val.find((y) => y.value == x.value)
        );

        if (selected.length == filteredItems.length) {
            selected = [allValue];
        } else if (selected.length == 0) {
            if (hasNoneValue) {
                selected = [noneValue];
            } else {
                selected = [allValue];
            }
        }
        return selected;
    }, [value, itemsList]);

    const hasAll = currentItem.find((x) => x.value == allValue.value);
    const hasNone = currentItem.find((x) => x.value == noneValue.value);
    const Component = !props.useOldUi ? AppSelect : AppSelectOld;

    return (
        <Component
            isDisabled={isReadonly}
            options={
                [
                    ...(allowAllSelection ? [allValue] : []),
                    ...(hasNoneValue ? [noneValue] : []),
                    ...itemsList,
                ] as CustomColorItemOption[]
            }
            isMulti={true}
            maxHeight={350}
            useReactWindow={true}
            // menuIsOpen={true}
            isLoading={props.loading}
            menuPortalTarget={props.menuPortalTarget}
            value={currentItem}
            overrideStyles={true}
            styles={{
                multiValue: (styles: any, { data }: { data: any }) => {
                    const color = defaultTo(data.color, COLOR_GREY_LIGHTEST);
                    return {
                        ...styles,
                        backgroundColor:
                            data.value != null ? color : COLOR_LIGHT_RED,
                        borderRadius: "4px",
                        padding: "0px",
                        margin: "1px",
                    };
                },
                multiValueRemove: (base: any) => ({
                    ...base,
                    cursor: "pointer !important",
                    "& svg": {
                        color: COLOR_TEXT_DARK,
                        height: "16px",
                        width: "18px",
                    },
                }),
                multiValueLabel: (base: any) => {
                    return {
                        ...base,
                        color: COLOR_TEXT_DARK,
                        fontSize: "0.85rem !important",
                    };
                },
                option: (base: any, data: any) => {
                    return {
                        ...base,
                        borderRadius: "5px",
                        textAlign: "left",
                        color: `${
                            data.value != null
                                ? data.isSelected
                                    ? "white"
                                    : COLOR_TEXT_DARK
                                : COLOR_RED
                        }`,
                    };
                },
            }}
            onChange={(opt: CustomColorItemOption[]) => {
                if (opt == null || opt.length == 0) {
                    if (!hasNoneValue) {
                        if (!hasAll) {
                            // don't change if already All is selected
                            onChange(items);
                        }
                    } else if (!hasNone) {
                        // don't change if already None is selected
                        onChange([]);
                    }
                } else {
                    if (hasAll) {
                        if (
                            !(
                                opt.length == 1 &&
                                opt.filter((x) => x.value != allValue.value)
                            )
                        ) {
                            // user selected option other then all
                            opt = opt.filter((x) => x.value != allValue.value);
                            const selectedNone =
                                opt.filter((x) => x.value == noneValue.value)
                                    .length > 0;
                            onChange(!selectedNone ? opt : []);
                        }
                    } else if (hasNone) {
                        if (
                            !(
                                opt.length == 1 &&
                                opt.filter((x) => x.value != noneValue.value)
                            )
                        ) {
                            // user selected option other then none
                            opt = opt.filter((x) => x.value != noneValue.value);
                            const selectedAll =
                                opt.filter((x) => x.value == allValue.value)
                                    .length > 0;
                            onChange(!selectedAll ? opt : items);
                        }
                    } else {
                        if (opt.find((x) => x.value == allValue.value)) {
                            //select all
                            if (allowAllSelection) {
                                onChange(items);
                            } // else do nothing (don't select all)
                        } else if (
                            opt.find((x) => x.value == noneValue.value)
                        ) {
                            //select None
                            onChange([]);
                        } else {
                            if (
                                opt.length == items.length &&
                                !allowAllSelection
                            ) {
                                showSweetAlertToast(
                                    t("common.warning"),
                                    t(
                                        `common.errorMessages.allOptionsCantBeSelected`
                                    ),
                                    "warning"
                                );
                            } else {
                                onChange(opt);
                            }
                        }
                    }
                }
            }}
        />
    );
});

export function Component(props: AppColoredMultiSelectDropdownProps) {
    const {
        loading = false,
        isReadonly = false,
        useOldUi = false,
        onChange,
        ...rest
    } = props;

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const onChangeDropdown = useCallback(
        (val: CustomColorItemOption[]) => onChangeRef.current(val),
        []
    );

    return (
        <Dropdown
            isReadonly={isReadonly || loading}
            loading={loading}
            useOldUi={useOldUi}
            onChange={onChangeDropdown}
            {...rest}
        />
    );
}

Component.displayName = "AppColoredMultiSelect";
export const AppColoredMultiSelect = React.memo(Component);
export default AppColoredMultiSelect;
