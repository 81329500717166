import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import AppIconTextButton from "components/Buttons/AppIconTextButton";
import { AppContainer } from "components/Containers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { BusinessType } from "models";
import { AppLocale } from "models/general";
import { ImportDataType } from "models/importData";
import {
    Business_DataImport_Client,
    Business_DataImport_ClientRegistration,
    Business_DataImport_Employee,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import styles from "./ImportDataTab.module.scss";
import { HandleDeleteAll, ImportDataDialogContainer } from "./partials";

export const ImportDataTab: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { appLocale: locale } = useLocaleHelpers();

    const currentBusiness = useCurrentBusinessSettingsContext();
    const { getPermissionMap } = useBusinessCheckPermission();
    const { businessType } = useParams();

    const isBusinessTypeKindergarten =
        businessType == BusinessType.KINDERGARTEN.toLowerCase();

    const clientPermMap = useMemo(() => {
        return getPermissionMap(
            currentBusiness.dataItem?.Id as number,
            currentBusiness.dataItem?.HqId as number,
            Business_DataImport_Client
        );
    }, [getPermissionMap]);
    const hasClientCreatePermission = checkPermissionInMap(clientPermMap, [
        PermissionAccessTypes.CREATE,
    ]);
    const hasClientDeletePermission = checkPermissionInMap(clientPermMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const employeePermMap = useMemo(() => {
        return getPermissionMap(
            currentBusiness.dataItem?.Id as number,
            currentBusiness.dataItem?.HqId as number,
            Business_DataImport_Employee
        );
    }, [getPermissionMap]);
    const hasEmployeeCreatePermission = checkPermissionInMap(employeePermMap, [
        PermissionAccessTypes.CREATE,
    ]);
    const hasEmployeeDeletePermission = checkPermissionInMap(employeePermMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const clientRegistrationPermMap = useMemo(() => {
        return getPermissionMap(
            currentBusiness.dataItem?.Id as number,
            currentBusiness.dataItem?.HqId as number,
            Business_DataImport_ClientRegistration
        );
    }, [getPermissionMap]);
    const hasClientRegistrationCreatePermission = checkPermissionInMap(
        clientRegistrationPermMap,
        [PermissionAccessTypes.CREATE]
    );
    const hasClientRegistrationDeletePermission = checkPermissionInMap(
        clientRegistrationPermMap,
        [PermissionAccessTypes.DELETE]
    );

    const getFileUrl = () => {
        const preUrl = "/files";
        let fileUrl = "Employee-Import";
        if (state.importDataType) {
            if (state.importDataType == ImportDataType.CLIENT) {
                fileUrl = "Client-Import";
            } else if (
                state.importDataType == ImportDataType.CLIENT_REGISTRATIONS
            ) {
                fileUrl = "ClientRegistration-Import";
            }
        }
        return `${preUrl}/${fileUrl}-${
            locale == AppLocale.GERMAN ? "DE" : "EN"
        }.xlsx`;
    };

    const [state, setState] = useState<{
        importOpen: boolean;
        deleteOpen: boolean;
        importDataType?: ImportDataType;
    }>({ importOpen: false, deleteOpen: false });

    return (
        <AppContainer>
            {state.importOpen && state.importDataType && (
                <ImportDataDialogContainer
                    opened={state.importOpen}
                    sampleFileUrl={getFileUrl()}
                    onClose={() =>
                        setState({ importOpen: false, deleteOpen: false })
                    }
                    importDataType={state.importDataType}
                />
            )}
            {state.deleteOpen && state.importDataType && (
                <HandleDeleteAll
                    importType={state.importDataType}
                    onClose={() =>
                        setState({
                            importOpen: false,
                            deleteOpen: false,
                            importDataType: undefined,
                        })
                    }
                />
            )}
            <div className={styles.btnsContainer}>
                {isBusinessTypeKindergarten && (
                    <AppIconTextButton
                        className={styles.btn}
                        style={{ minWidth: "250px" }}
                        text={t("importData.buttons.importClients")}
                        icon={"file-import"}
                        disabled={!hasClientCreatePermission}
                        onClick={() => {
                            setState({
                                ...state,
                                importOpen: true,
                                importDataType: ImportDataType.CLIENT,
                            });
                        }}
                    />
                )}
                <AppIconTextButton
                    className={styles.btn}
                    style={{ minWidth: "250px" }}
                    text={t("importData.buttons.importEmployees")}
                    icon={"file-import"}
                    disabled={!hasEmployeeCreatePermission}
                    onClick={() => {
                        setState({
                            ...state,
                            importOpen: true,
                            importDataType: ImportDataType.EMPLOYEE,
                        });
                    }}
                />
                {isBusinessTypeKindergarten && (
                    <AppIconTextButton
                        className={styles.btn}
                        style={{ minWidth: "315px" }}
                        text={t("importData.buttons.importClientRegistrations")}
                        icon={"file-import"}
                        disabled={!hasClientRegistrationCreatePermission}
                        onClick={() => {
                            setState({
                                ...state,
                                importOpen: true,
                                importDataType:
                                    ImportDataType.CLIENT_REGISTRATIONS,
                            });
                        }}
                    />
                )}
            </div>
            <hr />
            <div className={styles.btnsContainer}>
                {isBusinessTypeKindergarten && (
                    <AppIconTextButton
                        disabled={!hasClientDeletePermission}
                        className={styles.btn}
                        style={{ minWidth: "250px" }}
                        text={t("importData.buttons.deleteAllClients")}
                        variant="danger"
                        icon={"file-import"}
                        onClick={() =>
                            setState({
                                ...state,
                                deleteOpen: true,
                                importDataType: ImportDataType.CLIENT,
                            })
                        }
                    />
                )}
                <AppIconTextButton
                    className={styles.btn}
                    style={{ minWidth: "250px" }}
                    variant="danger"
                    text={t("importData.buttons.deleteAllEmployees")}
                    icon={"file-import"}
                    disabled={!hasEmployeeDeletePermission}
                    onClick={() =>
                        setState({
                            ...state,
                            deleteOpen: true,
                            importDataType: ImportDataType.EMPLOYEE,
                        })
                    }
                />
                {isBusinessTypeKindergarten && (
                    <AppIconTextButton
                        className={styles.btn}
                        style={{ minWidth: "315px" }}
                        variant="danger"
                        text={t(
                            "importData.buttons.deleteAllClientRegistrations"
                        )}
                        icon={"file-import"}
                        disabled={!hasClientRegistrationDeletePermission}
                        onClick={() =>
                            setState({
                                ...state,
                                deleteOpen: true,
                                importDataType:
                                    ImportDataType.CLIENT_REGISTRATIONS,
                            })
                        }
                    />
                )}
            </div>
        </AppContainer>
    );
};

export default ImportDataTab;
