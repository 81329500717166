import { AppPlatforms, AppResponse } from "models/general";
import {
    RoleCreateEditRequest,
    RolePaginationRequest,
    RolePermissionUpdateRequest,
} from "models/roleManagement/request";
import {
    RoleListingResponse,
    RolePermissionsResponse,
    RoleResponse,
} from "models/roleManagement/response";
import routesForContext from "routing/AppRoutes";
import BaseService from "services/BaseService";

export interface IRoleManagementService {
    create: (role: RoleCreateEditRequest) => Promise<AppResponse<RoleResponse>>;
    update: (role: RoleCreateEditRequest) => Promise<AppResponse<RoleResponse>>;
    getRoleList: (
        reqParams: RolePaginationRequest
    ) => Promise<AppResponse<RoleListingResponse>>;
    clone: (roleId: string) => Promise<AppResponse<RoleResponse>>;
    delete: (
        roleId: string,
        deviceType: AppPlatforms
    ) => Promise<AppResponse<string>>;
    updateRolePermission: (
        req: RolePermissionUpdateRequest
    ) => Promise<AppResponse<boolean>>;
    getRolePermissions(
        roleId: string
    ): Promise<AppResponse<RolePermissionsResponse>>;
}

const apiLinkProvider = routesForContext().business.api.roleManagement;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getRoleManagementServiceKey(
    name: keyof IRoleManagementService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `roleManagement_${name}_${JSON.stringify(data)}`;
}
export class RoleManagementService
    extends BaseService
    implements IRoleManagementService
{
    public roleRoutes: ApiLinkProviderType;

    constructor(routes: ApiLinkProviderType) {
        super();
        this.roleRoutes = routes;
    }

    getRoleList(
        reqParams: RolePaginationRequest
    ): Promise<AppResponse<RoleListingResponse>> {
        return this.doServerXHR<AppResponse<RoleListingResponse>>({
            url: this.roleRoutes.list(reqParams),
            method: "get",
        });
    }
    getRolePermissions(
        roleId: string
    ): Promise<AppResponse<RolePermissionsResponse>> {
        return roleId.length > 5
            ? this.doServerXHR<AppResponse<RolePermissionsResponse>>({
                  url: this.roleRoutes.getRolePermission(roleId),
                  method: "get",
              })
            : (undefined as any);
    }

    create(role: RoleCreateEditRequest): Promise<AppResponse<RoleResponse>> {
        return this.doServerXHR<RoleResponse>({
            url: this.roleRoutes.create(),
            method: "post",
            data: role,
        });
    }

    update(role: RoleCreateEditRequest): Promise<AppResponse<RoleResponse>> {
        return this.doServerXHR<RoleResponse>({
            url: this.roleRoutes.update(),
            method: "post",
            data: role,
        });
    }

    clone(roleId: string): Promise<AppResponse<RoleResponse>> {
        return this.doServerXHR<AppResponse<RoleResponse>>({
            url: this.roleRoutes.clone(roleId),
            method: "post",
        });
    }

    delete(
        roleId: string,
        deviceType: AppPlatforms
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.roleRoutes.delete(roleId, deviceType),
            method: "post",
        });
    }

    updateRolePermission(
        req: RolePermissionUpdateRequest
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.roleRoutes.updateRolePermissions(),
            method: "post",
            data: req,
        });
    }
}
