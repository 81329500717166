import { BusinessDetails } from "models";
import { AppResponse } from "models/general/response";
import {
    HqRegisterFilterRequest,
    HqRegisterRequestResponse,
} from "models/registrationRequests";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface ILillywaitRegistrationRequestsService {
    createHq(
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>>;

    list(
        request: HqRegisterFilterRequest
    ): Promise<AppResponse<HqRegisterRequestResponse[]>>;

    getById(
        registerRequestId: string
    ): Promise<AppResponse<HqRegisterRequestResponse>>;

    updateStatus(
        id: number,
        updateStatus: string
    ): Promise<AppResponse<HqRegisterRequestResponse>>;

    resendCompleteRegistrationEmailToRequestPerson(
        id: number
    ): Promise<AppResponse<number>>;

    delete(id: number): Promise<AppResponse<number>>;
}

export function getRegistrationRequestsServiceKey(
    name: keyof ILillywaitRegistrationRequestsService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `lwRegisterRequests_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext().lillywait.api.registerRequest;
type ApiLinkProviderType = typeof apiLinkProvider;

export class LillywaitRegistrationRequestsService
    extends BaseService
    implements ILillywaitRegistrationRequestsService
{
    public hqRegisterRoutes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.hqRegisterRoutes = apiLinkProvider;
    }
    createHq(
        businessDetail: BusinessDetails
    ): Promise<AppResponse<BusinessDetails>> {
        return this.doServerXHR<BusinessDetails>({
            url: this.hqRegisterRoutes.createHq(),
            method: "post",
            data: businessDetail,
        });
    }

    list(
        request: HqRegisterFilterRequest
    ): Promise<AppResponse<HqRegisterRequestResponse[]>> {
        return this.doServerXHR<HqRegisterRequestResponse[]>({
            url: this.hqRegisterRoutes.list({ request }),
            method: "get",
        });
    }
    getById(
        registerRequestId: string
    ): Promise<AppResponse<HqRegisterRequestResponse>> {
        return this.doServerXHR<HqRegisterRequestResponse>({
            url: this.hqRegisterRoutes.getById(registerRequestId),
            method: "get",
        });
    }

    updateStatus(
        id: number,
        updateStatus: string
    ): Promise<AppResponse<HqRegisterRequestResponse>> {
        return this.doServerXHR<HqRegisterRequestResponse>({
            url: this.hqRegisterRoutes.updateStatus(),
            method: "post",
            data: { Id: id, Status: updateStatus },
        });
    }

    resendCompleteRegistrationEmailToRequestPerson(
        id: number
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.hqRegisterRoutes.resendCompleteRegistrationEmail(id),
            method: "post",
        });
    }

    delete(id: number): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.hqRegisterRoutes.delete(id),
            method: "post",
        });
    }
}
