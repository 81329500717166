import { DateRangeRequest, serializeDateRangeRequest } from "models/general";
import BaseRoutingContext from "routing/routingContexts/BaseRoutingContext";
import { getUrlForPattern } from "routing/routingContexts/helper";

// for api routes that are common for all: location-setting, lwc and business-settings
export const COMMON_ROUTE_AREA_LOCATION: string = "location-setting";
export const COMMON_ROUTE_AREA_BUSINESS: string = "business";

export const commonApiRoutes = (
    baseContext: BaseRoutingContext = new BaseRoutingContext(),
    sessionBusinessId?: string // used for location-setting
) => {
    return {
        careScopeTemplates: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/list`,
                            true
                        ),
                    getDetails: (
                        id: string,
                        clone?: boolean,
                        followup?: boolean,
                        isBusinessFollowup?: boolean
                    ) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get/:id`,
                            true,
                            { id: id },
                            {
                                clone: clone,
                                followup: followup,
                                isBusinessFollowup: isBusinessFollowup,
                            }
                        ),
                    create: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/create`,
                            true
                        ),
                    edit: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/edit`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/delete/:id`,
                            true,
                            { id: id }
                        ),
                };
            })(
                `care-scope-templates/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        ageGroupTemplates: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/list`,
                            true
                        ),
                    getDetails: (
                        id: string,
                        clone?: boolean,
                        followup?: boolean,
                        isBusinessFollowup?: boolean
                    ) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get/:id`,
                            true,
                            { id: id },
                            {
                                clone: clone,
                                followup: followup,
                                isBusinessFollowup: isBusinessFollowup,
                            }
                        ),
                    create: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/create`,
                            true
                        ),
                    edit: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/edit`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/delete/:id`,
                            true,
                            { id: id }
                        ),
                };
            })(
                `age-group-templates/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        salaryGroupTemplates: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/list`,
                            true
                        ),
                    getDetails: (
                        id: string,
                        clone?: boolean,
                        followup?: boolean,
                        isBusinessFollowup?: boolean
                    ) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get/:id`,
                            true,
                            { id: id },
                            {
                                clone: clone,
                                followup: followup,
                                isBusinessFollowup: isBusinessFollowup,
                            }
                        ),
                    create: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/create`,
                            true
                        ),
                    edit: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/edit`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/delete/:id`,
                            true,
                            { id: id }
                        ),
                };
            })(
                `salary-group-templates/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        compensationTemplates: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/list`,
                            true
                        ),
                    getDetails: (
                        id: string,
                        clone?: boolean,
                        followup?: boolean,
                        isBusinessFollowup?: boolean
                    ) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get/:id`,
                            true,
                            { id: id },
                            {
                                clone: clone,
                                followup: followup,
                                isBusinessFollowup: isBusinessFollowup,
                            }
                        ),
                    create: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/create`,
                            true
                        ),
                    edit: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/edit`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/delete/:id`,
                            true,
                            { id: id }
                        ),
                };
            })(
                `compensation-templates/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        empKeyTemplates: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    list: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/list`,
                            true
                        ),
                    getDetails: (
                        id: string,
                        clone?: boolean,
                        followup?: boolean,
                        isBusinessFollowup?: boolean
                    ) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get/:id`,
                            true,
                            { id: id },
                            {
                                clone: clone,
                                followup: followup,
                                isBusinessFollowup: isBusinessFollowup,
                            }
                        ),
                    create: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/create`,
                            true
                        ),
                    edit: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/edit`,
                            true
                        ),
                    delete: (id: string) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/delete/:id`,
                            true,
                            { id: id }
                        ),
                };
            })(
                `emp-key-templates/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        locationBusinessOptions: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    careScopeOptions: (req: DateRangeRequest) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/care-scope-options`,
                            true,
                            undefined,
                            serializeDateRangeRequest(req)
                        ),
                    subChargeOptions: (req: DateRangeRequest) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/surcharge-options`,
                            true,
                            undefined,
                            serializeDateRangeRequest(req)
                        ),
                    ageGroupOptions: (req: DateRangeRequest) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/age-group-options`,
                            true,
                            undefined,
                            serializeDateRangeRequest(req)
                        ),
                    salaryGroupOptions: (req: DateRangeRequest) =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/salary-group-options`,
                            true,
                            undefined,
                            serializeDateRangeRequest(req)
                        ),
                };
            })(
                `location-business-options/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
        workingHours: (
            locationSettingId: number | string,
            businessId?: number | string
        ) =>
            ((routeBase: string) => {
                return {
                    getWorkingDays: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/get-business-work-hours`,
                            true
                        ),
                    updateWorkingDays: () =>
                        getUrlForPattern(
                            baseContext,
                            true,
                            `${routeBase}/update-working-hours`,
                            true
                        ),
                };
            })(
                `working-days/${locationSettingId}/${
                    businessId
                        ? COMMON_ROUTE_AREA_BUSINESS
                        : COMMON_ROUTE_AREA_LOCATION
                }/${businessId ? businessId : sessionBusinessId}`
            ),
    };
};
