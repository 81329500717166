export const DocumentsAndEditorEnTranslations = {
    additionalFilters: {
        dialogTitle: "Additional Settings",
        minClientAge: "Filter clients older than months:",
        toggleTooltip: "Additional Settings",
    },
    editableSections: {
        missing: "This section must contain a text.",
    },
    documentTemplate: {
        documentsCopyDialog: {
            facility: "Facility",
            sourceLocation: "Source Location",
            documentTemplates: "Document Templates",
            cloneLetterhead: "Also clone letterhead?",
            letterheads: "Letterheads",
            templatesCopied: "Templates copied successfully...",
            copyLWCDocuments: "Copy Documents from a Facility",
            copyBetweenLocations:
                "Copy document templates from another location",
            validations: {
                sourceCompany: "Please select company to copy templates from.",
                sourceLocation:
                    "Please select source location to copy templates from.",
                templateIds: "Templates selection is required.",
            },
        },
        locationDocuments: "Location Documents",
        cloneFromLWC: "Copy from a Facility",
        cloneFromAnotherLocation: "Copy from another location-setting",
        cloneError: "Unable to cloned Document Template.",
        cloneSuccess: "Document cloned successfully.",
        createdSuccess: "Document created successfully.",
        creatingDocument: "Creating Document...",
        deletedSuccess: "Document deleted successfully.",
        previewError: "Unable to generate Document Preview.",
        updateError: "Unable to update Document Template.",
        updateSuccess: "Document Template updated successfully.",
    },
    letterhead: {
        title: "Letterheads",
        footer: {
            missing: "Footer template is required",
            name: "Footer Template",
        },
        header: {
            missing: "Header template is required",
            name: "Header Template",
        },
        updateError: "Unable to update Document Letterhead.",
        updateSuccess: "Document Letterhead updated successfully.",
    },
    placeholders: {
        addPlaceholder: "Add Placeholder",
        dynamicPlaceholderToggleTypes: {
            All: "All",
            Client: {
                ParentPrimary: "Client Primary Contact",
                ParentSecondary: "Client Secondary Contact",
            },
            ClientRegistration: "Client Registration",
            ClientRoot: "Client",
            Contact: "Contact",
            Employee: "Employee",
            Business: "Business",
            Kindergarten: "Kindergarten",
            Other: "Other",
        },
        editPlaceholder: "Edit Placeholder",
        genderSpecific: {
            female: "Female",
            male: "Male",
        },
        list: {
            AgeGroup: "Age Group",
            BankName: "Bank Name",
            BIC: "BIC",
            BirthCity: "Birth City",
            Birthday: "Birthday",
            BirthName: "Birth Name",
            BusinessType: "Type",
            CareBeginning: "Care Beginning",
            CareHours: "Care Hours",
            CareStart: "Care Start",
            City: "City",
            CommercialRegister: "Commercial Register",
            CompanyId: "Company ID",
            Country: "Country",
            CurrentDate: "Current Date",
            CurrentDateAndTime: "Current Date and Time",
            CurrentTime: "Current Time",
            CustomerNumber: "Customer Number",
            EducationalLeadership: "Educational Leadership",
            Email: "Email",
            EndOfContract: "End of Contract",
            FatherFirstName: "Father First Name",
            FatherFullName: "Father Full Name",
            FatherLastName: "Father Last Name",
            FirmName: "Firm Name",
            FirstName: "First Name",
            FullName: "Full Name",
            Gender: "Gender",
            HouseNumber: "House Number",
            IBAN: "IBAN",
            Internet: "Internet",
            IsbjCarrier: "ISBJ Carrier Number",
            IsbjSetup: "ISBJ Setup Number",
            LandLine: "Land Line",
            LastName: "Last Name",
            ManagingDirector: "Managing Director",
            MobileNumber: "Mobile Number",
            MotherFirstName: "Mother First Name",
            MotherFullName: "Mother Full Name",
            MotherLastName: "Mother Last Name",
            Name: "Name",
            Nationality: "Nationality",
            OfficePhone: "Office Phone",
            PhoneCentral: "Phone Central",
            PostalCode: "Postal Code",
            PrivatePhone: "Private Phone",
            SchoolStart: "School Start",
            ShortCompany: "Short Company Name",
            ShortInstitution: "Short Institution Name",
            ShortName: "Short Name",
            Status: "Status",
            Street: "Street",
            StreetNum: "Street Number",
            TaxNum: "Tax Number",
            TeleFax: "Telefax",
            Timezone: "Timezone",
            WorkPhone: "Work Phone",
        },
        placeholders: "Placeholders",
        PlaceholderSidebar: "Placeholder Sidebar",
        types: {
            DynamicInput: "Dynamic",
            EditableSection: "Editable Section",
            GenderSpecific: "Gender",
            OpenInput: "Open",
        },
    },
    tinymce: {
        femalePlaceholder: "Female placeholder",
        fieldName: "Field name",
        malePlaceholder: "Male placeholder",
        name: "Name",
        placeholdersPanelTitle: "Fields",
        sectionName: "Section Name",
    },
};

export default DocumentsAndEditorEnTranslations;
