import arrayMove from "array-move";
import { AppSwitch } from "components";
import { AppIconTextButton } from "components/Buttons";
import { AppSortable } from "components/Sortable";
import { ValidityState } from "models/general";
import {
    LWClientCompensationTemplateField,
    CompensationTemplateOptionField,
    CompensationTemplateType,
    CompensationTemplateFieldTypeManager,
    CompensationTemplateTypeToFieldType,
    CompensationTemplateCareScopeField,
    CompensationTemplateSalaryGroupField,
} from "models/compensationTemplate";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SortableElement } from "react-sortable-hoc";
import CompensationTemplateFieldRow from "./CompensationTemplateFieldRow";
import { CompensationTemplateCareScopeFieldsContainer } from "./CompensationTemplateCareScopeFieldsContainer";
import { StartAndEndDates } from "models/StartAndEndDateFields";
import CompensationTemplateSalaryGroupFieldsContainer from "commonPartials/compensationTemplate/partials/createEditPartials/LWClientFields/CompensationTemplateSalaryGroupFieldsContainer";

export interface LWClientCompensationTemplateFieldsContainerProps {
    fields: LWClientCompensationTemplateField[];
    templateDates: StartAndEndDates;
    templateType: CompensationTemplateType;
    onChange: (
        newFields: LWClientCompensationTemplateField[],
        canEdit?: boolean
    ) => void;
    readonly?: boolean;
    isForLocation: boolean;
    validityState?: ValidityState;
    canEditValue?: boolean;
    locationSettingId?: number;
    businessId: string;
}

const SortableItem: any = SortableElement(CompensationTemplateFieldRow);

export const LWClientCompensationTemplateFieldsContainer: React.FC<
    LWClientCompensationTemplateFieldsContainerProps
> = ({
    templateType,
    fields,
    onChange,
    readonly = false,
    validityState,
    isForLocation,
    templateDates,
    canEditValue = false,
    locationSettingId,
    businessId,
}) => {
    const { t } = useTranslation();

    const fieldManager = useMemo(() => {
        if (
            [CompensationTemplateType.LillywaitCompensation].includes(
                templateType
            )
        ) {
            return undefined;
        }

        return CompensationTemplateFieldTypeManager[
            CompensationTemplateTypeToFieldType[templateType]
        ];
    }, [templateType]);

    const handleSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (
            !readonly &&
            !(fields[oldIndex as number] as CompensationTemplateOptionField)
                .IsDefault &&
            !(fields[newIndex as number] as CompensationTemplateOptionField)
                .IsDefault
        ) {
            onChange(arrayMove(fields, oldIndex, newIndex));
        }
    };

    return [
        CompensationTemplateType.CareScope,
        CompensationTemplateType.Surcharge,
    ].includes(templateType) ? (
        <CompensationTemplateCareScopeFieldsContainer
            fields={fields as CompensationTemplateCareScopeField[]}
            validityState={validityState}
            onChange={(newFields: CompensationTemplateCareScopeField[]) =>
                onChange(newFields)
            }
            readonly={readonly}
            templateType={templateType}
            locationSettingId={locationSettingId}
            templateDates={templateDates}
            isForLocation={isForLocation}
            businessId={businessId}
            templateId={
                fields.length > 0
                    ? (fields[0].CompensationTemplateId as number)
                    : 0
            }
        />
    ) : templateType == CompensationTemplateType.SalaryGroup ? (
        <CompensationTemplateSalaryGroupFieldsContainer
            fields={fields as CompensationTemplateSalaryGroupField[]}
            validityState={validityState}
            onChange={(newFields: CompensationTemplateSalaryGroupField[]) =>
                onChange(newFields)
            }
            readonly={readonly}
            templateType={templateType}
            locationSettingId={locationSettingId}
            templateDates={templateDates}
            isForLocation={isForLocation}
            businessId={businessId}
            templateId={
                fields.length > 0
                    ? (fields[0].CompensationTemplateId as number)
                    : 0
            }
        />
    ) : (
        <>
            {/* <Row className="mb-2">
                <Col sm={6} xs={12} md={6}> */}
            {templateType == CompensationTemplateType.Simple && (
                <AppSwitch
                    id={"canEditValSwitch"}
                    value={canEditValue}
                    disabled={readonly}
                    onChange={(checked) => {
                        onChange(fields, checked);
                    }}
                    label={t("compensationTemplate.canEnterValue")}
                />
            )}
            {/* </Col>
            </Row> */}

            {templateType == CompensationTemplateType.MultiOption && (
                <AppIconTextButton
                    text={t("compensationTemplate.addNewOption")}
                    icon={"plus"}
                    padding="med"
                    disabled={readonly}
                    onClick={() => {
                        if (fieldManager) {
                            onChange([
                                ...fields,
                                fieldManager.defaultValue() as CompensationTemplateOptionField,
                            ]);
                        }
                    }}
                />
            )}
            {((templateType == CompensationTemplateType.Simple &&
                !canEditValue) ||
                (templateType == CompensationTemplateType.MultiOption &&
                    fields)) && (
                <AppSortable<CompensationTemplateOptionField>
                    SortableItem={SortableItem}
                    list={fields as CompensationTemplateOptionField[]}
                    handleSortEnd={handleSortEnd}
                    validityState={validityState}
                    readonly={readonly}
                    onChange={(field) => {
                        const oldIndex = fields.findIndex(
                            (f) => f.Uuid == field.Uuid
                        );
                        if (oldIndex >= 0) {
                            onChange(
                                fields.map((nf) => {
                                    if (nf.Uuid == field.Uuid) {
                                        return field;
                                    } else {
                                        return nf;
                                    }
                                })
                            );
                        } else {
                            onChange([...fields, field]);
                        }
                    }}
                    onDelete={(uuid: string) => {
                        onChange(fields.filter((f) => f.Uuid != uuid));
                    }}
                />
            )}
        </>
    );
};

export default LWClientCompensationTemplateFieldsContainer;
