export const SettingTemplatesEnTranslations = {
    templates: {
        templateName: {
            missing: "Template Name is required",
            name: "Template Name",
        },
        templateShortName: {
            missing: "Template short name is required",
            name: "Template Short Name",
        },
        name: {
            missing: "Name is required",
            name: "Name",
        },
        shortName: {
            missing: "Short name is required",
            name: "Short Name",
        },
        addFollowUpTemplate: "Add new follow-up template ({{name}})",
        createFollowUpTemplate: "Create follow-up template",
        createNewEmptyTemplate: "Create new empty template",
        createNewTemplateWithData: "Create new template with data",
        createTemplate: "Create Template",
        description: "Description",
    },
    empKeyTemplate: {
        title: "Employee Key Calculation",
        ageGroup: "Age Group: {{name}}",
        scopeOfCare: {
            shortName: "Scope of Care Shortname",
        },
        employeeKey: "Employee Key",
    },
    ageGroupTemplate: {
        title: "Age Group",
        ageStart: {
            invalid: "Age group start should be greater than age group end.",
            missing: "Age group start is required",
            name: "Age Group first {{unit}}",
            number: "Entered value is not numeric",
        },
        ageEnd: {
            invalid: "Age group end should be less than age group start.",
            missing: "Age group end is required",
            name: "Age Group last {{unit}}",
            number: "Entered value is not numeric",
        },
    },
    salaryGroupTemplate: {
        title: "Salary Group",
    },
    careScopeTemplate: {
        title: "Scope of Care",
        surcharges: "Surcharges",
        surchargeGroups: "Surcharge Groups",
        surchargeGroup: {
            name: "Surcharge Group",
            empty: "Please add at-least 1 surcharge.",
        },
        groupName: "Group Name",
        description: "Description",
        startTime: {
            invalid: "Start time should be greater than end time.",
            missing: "Start time is required",
            name: "Minimal time in Minutes",
            number: "Entered value is not numeric",
        },
        endTime: {
            invalid: "End time should be less than start time",
            missing: "End time is required",
            name: "Maximal time in Minutes",
            number: "Entered value is not numeric",
        },
    },
    compensationTemplate: {
        businessPreferenceWarning:
            "Changing this setting will re-generate all the client contracts according to the new settings. Are you sure you want to do it?",
        compensation: "Compensation",
        ageGroup: {
            name: "Age Group",
        },
        careScope: "Scope of Care: {{name}}",
        surcharge: "Surcharge: {{name}}",
        ageGroupWithName: "Age Group: {{name}}",
        templateType: "Template Type",
        variablePayments: "Variable Payments",
        addNewCriteria: "Add New Criteria",
        addNewOption: "Add New Option",
        appliesTo: "Applies to",
        isInternal: "This is an internal compensation type",
        canEnterValue: "Can Enter Value?",
        comment: "Comments",
        types: {
            Simple: "Simple",
            MultiOption: "Multi Option",
            CareScope: "Scope of Care",
            Surcharge: "Surcharge",
            SalaryGroup: "Salary Group",
        },
        employeeKey: {
            invalid: "Employee Key is not numeric",
            missing: "Employee Key is required",
            name: "Employee Key",
        },
        euroMonth: "Euro/Month",
        euroYear: "Euro/Year",
        fixedPayments: "Fixed Payments",
        forBusiness: {
            missing: "For Business is required.",
            name: "For Business",
        },
        forLocation: {
            missing: "For Location is required.",
            name: "For Location",
        },
        freeMonths: {
            name: "Free Months",
        },
        type: {
            name: "Type",
        },
        locationModuleId: {
            missing: "Please select module.",
        },
        maxRate: {
            invalid: "Entered value is not numeric.",
            missing: "Max amount is required.",
            name: "Maximum Monthly Amount",
        },
        minRate: {
            invalid: "Entered value is not numeric.",
            missing: "Min amount is required.",
            name: "Lowest Monthly Amount",
        },
        modules: {
            name: "Modules",
        },
        modulesPricing: {
            add: "Add Module Pricing",
            name: "Modules Pricing",
        },
        newValue: {
            missing: "New value is required",
            name: "New Value",
        },
        oldValue: {
            missing: "Old value is required",
            name: "Old value",
        },
        rangeEnd: {
            invalid: "Entered value is not numeric.",
            name: "To",
            noLimit: "No Limit",
        },
        rangeStart: {
            invalid: "Entered value is not numeric.",
            missing: "'From' is required.",
            name: "From",
        },
        rate: {
            invalid: "Entered value is not numeric.",
            missing: "Rate is required.",
            name: "Rate",
        },
        totalCost: "Total cost",
        value: {
            invalid: "Entered value is not numeric",
            missing: "Value is required",
            name: "Value",
        },
    },
};

export default SettingTemplatesEnTranslations;
