import { HqRegisterRequestModel } from "models/auth";
import moment from "moment";
import { HqRegisterRequestResponse } from "./response";

export const getHqRegisterRequestFromResponse = (
    response: HqRegisterRequestResponse
): HqRegisterRequestModel => {
    return {
        ...response,
        CreatedAt: moment.unix(response.CreatedAt),
    } as HqRegisterRequestModel;
};
