import { defaults } from "lodash";
import Slider, { createSliderWithTooltip, SliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import styles from "./AppSlider.module.scss";

export interface AppSliderProps extends SliderProps {
    color?: string;
    tipFormatter?: (value: number) => React.ReactNode;
    tipProps?: {
        prefixCls?: string;
        overlay?: string;
        placement?: string;
        visible?: boolean;
    };
}
const SliderWithTooltip = createSliderWithTooltip(Slider);
export const AppSlider: React.FC<AppSliderProps> = ({
    handleStyle = {},
    trackStyle = {},
    tipFormatter = (val) => `${val}`,
    tipProps,
    color,
    railStyle,
    ...props
}) => {
    if (color) {
        trackStyle = { backgroundColor: color, ...trackStyle };
        handleStyle = {
            // background: "white",
            backgroundColor: "white",
            border: `5px solid ${color}`,
            ...handleStyle,
        };
    }
    props = defaults({ min: 0, max: 100 }, props);
    tipProps = defaults(tipProps, {
        visible: true,
        prefixCls: `${styles.appSliderTooltip}`,
    });
    railStyle = defaults(railStyle, {
        height: "2px",
    });
    return (
        <div className={"d-flex align-items-center"}>
            <span
                style={{ paddingRight: "20px" }}
                className={styles.appSliderSpan}
            >
                {props.min}
            </span>
            <SliderWithTooltip
                handleStyle={handleStyle}
                trackStyle={trackStyle}
                tipProps={tipProps}
                railStyle={railStyle}
                tipFormatter={tipFormatter}
                {...props}
            />
            <span
                style={{ paddingLeft: "20px" }}
                className={styles.appSliderSpan}
            >
                {props.max}
            </span>
        </div>
    );
};

export default AppSlider;
