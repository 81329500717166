import { AppContentHeader } from "components";
import AppLoader from "components/Loaders/AppLoader";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    Business,
    BusinessSettingResponse,
    parseBusinessSettingResponse,
} from "models/business";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import moment from "moment";
import { ReactNode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useOutletContext, useParams } from "react-router";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { getLillywaitServiceKey } from "services/lillywait";
import styles from "./LwcEditLayout.module.scss";

export interface LwcEditLayoutProps {
    tabs: ReactNode;
    backUrl: string;
    currentTab?: NavigationTab;
    defaultRoute?: string;
}

// not in edit-layout to resolve circular dependency
export const useCurrentLwcContext = () => {
    return useOutletContext<TabbedLayoutActiveItem<Business>>();
};
export const LwcEditLayout: React.FC<LwcEditLayoutProps> = ({
    tabs,
    backUrl,
    currentTab,
    defaultRoute,
}) => {
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { lwcId } = useParams();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { user } = useSessionUser();
    const queryClient = useQueryClient();

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );
    const {
        isLoading,
        data: response,
        refetch,
        error,
    } = useQuery(
        getCurrentBusinessServiceKey("getBasicInfo", {
            userId: user?.Id,
            businessId: lwcId,
        }),
        async () => await businessService.getBasicInfo(lwcId)
    );

    useEffect(() => {
        if (
            defaultRoute &&
            (location.pathname.endsWith(`${lwcId}`) ||
                location.pathname.endsWith(`${lwcId}/`))
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(defaultRoute, { replace: true });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!isLoading && (response || error)) {
            let hasError = !isNil(error);
            if (!hasError && response) {
                // id in url is wrong
                hasError = isNil(response.Data);
            }
            if (hasError) {
                showErrorPage(404);
            }
        }
    }, [isLoading, response, error]);

    const data = useMemo(() => {
        return !isLoading && response && response.Data
            ? {
                  ...response.Data,
                  BusinessSetting: parseBusinessSettingResponse(
                      response.Data.BusinessSetting as BusinessSettingResponse
                  ),
              }
            : null;
    }, [isLoading.valueOf, response]);

    return isNil(data) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={data.Name}
                classNameForIcon="pe-7s-diamond"
                hasGoBack={true}
                backUrl={backUrl}
            />
            <div className={styles.tabs}>
                {tabs}
                <div className={styles.content}>
                    <Outlet
                        context={
                            {
                                dataItem: data,
                                tab: currentTab,
                                refetchData: () => {
                                    queryClient.invalidateQueries(
                                        getLillywaitServiceKey(
                                            "getLwClientsList"
                                        )
                                    );
                                    refetch();
                                },
                            } as TabbedLayoutActiveItem<Business>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default LwcEditLayout;
