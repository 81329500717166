import { AppResponse } from "models/general/response";
import {
    EmployeeAbsencesRequestParameters,
    PersonAbsentsResponse,
} from "models/businessAbsents";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IAbsentsService {
    getEmployeeAbsences: (
        reqParams: EmployeeAbsencesRequestParameters
    ) => Promise<AppResponse<PersonAbsentsResponse[]>>;
}

const apiLinkProvider = routesForContext().business.api.absences;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getAbsentsServiceKey(name: keyof IAbsentsService, data?: any) {
    if (!data) {
        return name;
    }
    return `absents_${name}_${JSON.stringify(data)}`;
}
export class AbsentsService extends BaseService implements IAbsentsService {
    public routes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.routes = apiLinkProvider;
    }

    getEmployeeAbsences(
        reqParams: EmployeeAbsencesRequestParameters
    ): Promise<AppResponse<PersonAbsentsResponse[]>> {
        return this.doServerXHR<AppResponse<PersonAbsentsResponse[]>>({
            url: this.routes.getEmployeeAbsences(reqParams),
            method: "get",
        });
    }
}

export default AbsentsService;
