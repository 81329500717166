import ImageAssets from "globals/images/common";
import i18next from "i18next";
import { defaultTo } from "lodash";
import { ConfirmationPages, LoginRegisterTabs } from "models/auth/enum";
import { AppPlatforms } from "models/general/enum";
import { AuthTabModel, ConfirmationPageData, LoginVm } from "./model";

export const getLoginDefaultValue = (): LoginVm => {
    return {
        Email: "",
        Password: "",
        RememberMe: true,
    } as LoginVm;
};

export function getConfirmationPageData(
    type: ConfirmationPages,
    platform?: AppPlatforms
): ConfirmationPageData {
    switch (type) {
        case ConfirmationPages.REGISTER_REQUEST_CONFIRMATION:
            return {
                pageTitle: i18next.t(
                    "auth.confirmation.registerRequestConfirmation.pageTitle"
                ),
                mainHeading: i18next.t(
                    "auth.confirmation.registerRequestConfirmation.title"
                ),
                desc: i18next.t(
                    "auth.confirmation.registerRequestConfirmation.desc"
                ),
                imgUrl: ImageAssets.common.confirmation,
            } as ConfirmationPageData;
        case ConfirmationPages.REGISTER_INFO_COMPLETED:
            return {
                pageTitle: i18next.t(
                    "auth.confirmation.registrationComplete.pageTitle"
                ),
                mainHeading: i18next.t(
                    "auth.confirmation.registrationComplete.title"
                ),
                desc: i18next.t("auth.confirmation.registrationComplete.desc"),
                imgUrl: ImageAssets.common.fireworks,
            } as ConfirmationPageData;
        case ConfirmationPages.EMAIL_CONFIRMED:
            return {
                pageTitle: i18next.t(
                    "auth.confirmation.emailConfirmed.pageTitle"
                ),
                mainHeading: i18next.t(
                    "auth.confirmation.emailConfirmed.title"
                ),
                desc: i18next.t("auth.confirmation.emailConfirmed.desc"),
                imgUrl: ImageAssets.common.fireworks,
            } as ConfirmationPageData;
        case ConfirmationPages.FORGOT_PASSWORD:
            return {
                pageTitle: i18next.t(
                    "auth.confirmation.forgotPassword.pageTitle"
                ),
                mainHeading: i18next.t(
                    "auth.confirmation.forgotPassword.title"
                ),
                desc: i18next.t("auth.confirmation.forgotPassword.desc"),
                imgUrl: ImageAssets.common.confirmation,
            } as ConfirmationPageData;
        case ConfirmationPages.RESET_PASSWORD: {
            const keyStart = `auth.confirmation.resetPassword.${defaultTo(
                platform,
                AppPlatforms.WEB
            ).toLowerCase()}`;
            return {
                pageTitle: i18next.t(`${keyStart}.pageTitle`),
                mainHeading: i18next.t(`${keyStart}.title`),
                desc: i18next.t("auth.confirmation.resetPassword.desc"),
                imgUrl: ImageAssets.common.security,
            } as ConfirmationPageData;
        }
    }
}

export function getLoginRegisterPageData(
    type: LoginRegisterTabs
): AuthTabModel {
    switch (type) {
        case LoginRegisterTabs.LOGIN:
            return {
                pageTitle: i18next.t("auth.login.pageTitle"),
                mainHeading: i18next.t("auth.login.title"),
            } as AuthTabModel;
        case LoginRegisterTabs.REGISTER:
            return {
                pageTitle: i18next.t("auth.hqRegisterRequest.pageTitle"),
                mainHeading: i18next.t("auth.hqRegisterRequest.title"),
            } as AuthTabModel;
        case LoginRegisterTabs.SESSION_TIME_OUT:
            return {
                pageTitle: i18next.t("auth.sessionTimeOut.pageTitle"),
                mainHeading: i18next.t("auth.sessionTimeOut.title"),
            } as AuthTabModel;
    }
}
