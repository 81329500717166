import {
    JS_LOCAL_DATE_TIME_PARSE_FORMAT,
    LONG_DATE_TIME_FORMAT,
    LONG_DATE_TIME_GERMAN_FORMAT,
} from "globals/constants";
import { cookieAvailable, getCookie } from "globals/helpers/cookieHelper";
import { isNil } from "lodash-es";
import { Optional } from "models/general";
import { AppLocale, CookiesKey } from "models/general/enum";
import "moment-duration-format";
import moment, { Moment } from "moment-timezone";
import "moment/locale/bs";
import "moment/locale/cs";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/et";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/hr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/sk";
import "moment/locale/sl";
import numeral from "numeral";

export function stringToAppLocale(lang: string): AppLocale {
    const newLocale = lang.toLocaleLowerCase();
    switch (newLocale) {
        case AppLocale.GERMAN.toLocaleLowerCase():
            return AppLocale.GERMAN;
        case AppLocale.ITALIAN.toLocaleLowerCase():
            return AppLocale.ITALIAN;
        case AppLocale.BOSNIAN.toLocaleLowerCase():
            return AppLocale.BOSNIAN;
        case AppLocale.POLISH.toLocaleLowerCase():
            return AppLocale.POLISH;
        case AppLocale.CROATIAN.toLocaleLowerCase():
            return AppLocale.CROATIAN;
        case AppLocale.CZECH.toLocaleLowerCase():
            return AppLocale.CZECH;
        case AppLocale.HUNGARIAN.toLocaleLowerCase():
            return AppLocale.HUNGARIAN;
        case AppLocale.SLOVAK.toLocaleLowerCase():
            return AppLocale.SLOVAK;
        case AppLocale.SLOVENIAN.toLocaleLowerCase():
            return AppLocale.SLOVENIAN;
        case AppLocale.FRENCH.toLocaleLowerCase():
            return AppLocale.FRENCH;
        case AppLocale.SPANISH.toLocaleLowerCase():
            return AppLocale.SPANISH;
        case AppLocale.DUTCH.toLocaleLowerCase():
            return AppLocale.DUTCH;
        case AppLocale.ESTONIAN.toLocaleLowerCase():
            return AppLocale.ESTONIAN;
        case AppLocale.FINNISH.toLocaleLowerCase():
            return AppLocale.FINNISH;
        case AppLocale.GREEK.toLocaleLowerCase():
            return AppLocale.GREEK;
    }

    return AppLocale.ENGLISH;
}
export function getBrowserLanguage(): AppLocale {
    const lang = (navigator as any).userLanguage || navigator.language;
    return stringToAppLocale(lang);
}
export function getInitialLocale() {
    return cookieAvailable(CookiesKey.USER_LOCALE)
        ? (getCookie(CookiesKey.USER_LOCALE) as AppLocale)
        : getBrowserLanguage();
}
export function toNumber(value: any) {
    return numeral(value).value();
}

export function validateHtmlTemplate(value: string): boolean {
    return $(value).text().trim().length > 0 || $(value).find("img").length > 0;
}

export function momentFromJSLocalDateTime(date: Date) {
    // create moment by converting date to full date string (without ZONE)
    // because JS Date is always in browser's timezone not in business tz
    // Date.localeString is not same for all browsers, can't use ISO string as it is converted w.r.t local timezone
    return moment(
        `${
            date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()} ${numeral(
            date.getHours()
        ).format("00")}:${numeral(date.getMinutes()).format("00")}:${numeral(
            date.getSeconds()
        ).format("00")}`,
        JS_LOCAL_DATE_TIME_PARSE_FORMAT
    );
}

export function jsLocalDateTimeFromMoment(date: Optional<Moment>) {
    // create moment by converting date to full date string (without ZONE)
    // because JS Date is always in browser's timezone not in business tz
    // and if converted directly using toDate then it will change date's zone
    // and value will be wrong

    return !isNil(date)
        ? new Date(date.format(JS_LOCAL_DATE_TIME_PARSE_FORMAT))
        : undefined;
}

export function getFormattedTimeFromNumber(
    value: Optional<number>,
    duration: "minute" | "hours" | "seconds" = "minute",
    format: string = "hh:mm:ss",
    options: { trim: boolean } = { trim: false }
) {
    return !isNil(value)
        ? (moment.duration(value, duration) as any).format(format, options)
        : "";
}
export function getFormattedHoursFromMinutes(value: Optional<number>) {
    return !isNil(value) ? numeral(value / 60).format("0,0.[0]") : "";
}

export function formatCompleteLongDateTime(
    date: Moment,
    locale: AppLocale
): string {
    let formattedDate = "";
    if (date) {
        const clonedDate = date.clone();
        if (moment().diff(date, "days") >= 1) {
            formattedDate = date.fromNow(); // 'n days ago' etc.
        } else {
            const calendarString = date.calendar().split(" ");
            if (calendarString.length > 0) {
                formattedDate = calendarString[0]; // 'Today', 'Yesterday', 'Tomorrow'
            }
        }
        formattedDate += clonedDate.format(
            `, ${
                locale == AppLocale.ENGLISH
                    ? LONG_DATE_TIME_FORMAT
                    : LONG_DATE_TIME_GERMAN_FORMAT
            }`
        );
    }
    return formattedDate;
}

export const setTimezone = (ianaTimezone: string) => {
    moment.tz.setDefault(ianaTimezone);
};

export function registerNumeralLocales() {
    const germanFormat = {
        delimiters: {
            thousands: ".", // existing "de" locale has " " as thousand delimiter
            decimal: ",",
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t",
        },
        ordinal: function (number: any) {
            return ".";
        },
        currency: {
            symbol: "€",
        },
    };
    // add missing locales
    numeral.register("locale", "de-DE", germanFormat);
    numeral.register("locale", "bs", germanFormat);
    numeral.register("locale", "hr", germanFormat);
    numeral.register("locale", "el", germanFormat);
}

export const initNumeralAndMoment = (locale: AppLocale) => {
    const iso2 = locale.substring(0, 2);
    if (locale == AppLocale.GERMAN) {
        numeral.locale("de-DE"); // custom locale
    } else if (locale == AppLocale.DUTCH) {
        numeral.locale("nl-nl"); // custom locale
    } else {
        numeral.locale(iso2);
    }
    moment.locale(iso2);
};
