export enum ImportDataType {
    CLIENT = "Client",
    EMPLOYEE = "Employee",
    CLIENT_REGISTRATIONS = "ClientRegistration",
}
// <--------------Client Mapping------------------>
export enum ClientImportColumns {
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    CARE_START = "careStart",
    END_OF_CONTRACT = "endOfContract",
    BIRTHDAY = "birthday",
    GENDER = "gender",
    GENDER_1 = "gender1",
    GENDER_2 = "gender2",
    FIRST_NAME_1 = "firstName1",
    FIRST_NAME_2 = "firstName2",
    LAST_NAME_1 = "lastName1",
    LAST_NAME_2 = "lastName2",
    EMAIL_1 = "email1",
    EMAIL_2 = "email2",
    HOUSE_NUMBER_1 = "houseNumber1",
    HOUSE_NUMBER_2 = "houseNumber2",
    POST_CODE_1 = "postCode1",
    POST_CODE_2 = "postCode2",
    CITY_1 = "city1",
    CITY_2 = "city2",
    STREET_1 = "street1",
    STREET_2 = "street2",
    WORK_PHONE_1 = "workPhone1",
    WORK_PHONE_2 = "workPhone2",
    PRIVATE_PHONE_1 = "privatePhone1",
    PRIVATE_PHONE_2 = "privatePhone2",
    MOBILE_PHONE_1 = "mobilePhone1",
    MOBILE_PHONE_2 = "mobilePhone2",
}
// <--------------Client Registration Mapping------------------>
export enum ClientRegistrationImportColumns {
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    GENDER = "gender",
    BIRTHDAY = "birthday",
    CARE_START = "careStart",
    NATIONALITY = "nationality",
    IS_SIBLING = "isSibling",
    MIGRATION_BACKGROUND = "migrationBackground",
    PLACE_CHANGED = "placeChanged",
    ARRANGED_PLACE = "arrangedPlace",
    STATUS = "status",
    NOTE = "note",
    CARE_SCOPE = "care_scope",
    CREATED_AT = "created_at",

    FIRST_NAME_MOTHER = "firstName_mother",
    LAST_NAME_MOTHER = "lastName_mother",
    GENDER_MOTHER = "gender_mother",
    EMAIL_MOTHER = "email_mother",
    HOUSE_NUMBER_MOTHER = "houseNumber_mother",
    POST_CODE_MOTHER = "postCode_mother",
    CITY_MOTHER = "city_mother",
    STREET_MOTHER = "street_mother",
    WORK_PHONE_MOTHER = "workPhone_mother",
    PRIVATE_PHONE_MOTHER = "privatePhone_mother",
    MOBILE_PHONE_MOTHER = "mobilePhone_mother",

    GENDER_FATHER = "gender_father",
    FIRST_NAME_FATHER = "firstName_father",
    LAST_NAME_FATHER = "lastName_father",
    EMAIL_FATHER = "email_father",
    HOUSE_NUMBER_FATHER = "houseNumber_father",
    POST_CODE_FATHER = "postCode_father",
    CITY_FATHER = "city_father",
    STREET_FATHER = "street_father",
    WORK_PHONE_FATHER = "workPhone_father",
    PRIVATE_PHONE_FATHER = "privatePhone_father",
    MOBILE_PHONE_FATHER = "mobilePhone_father",
}

// <--------------Employee Mapping------------------>

export enum EmployeeImportColumns {
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    BIRTH_NAME = "birthName",
    BIRTH_DAY = "birthday",
    GENDER = "gender",
    EMAIL = "email",
    POST_CODE = "postCode",
    CITY = "city",
    STREET = "street",
    PHONE_NUMBER = "phoneNumber",
    LAND_LINE_NUMBER = "landlineNumber",
    BIRTH_COUNTRY = "birthCountry",
    BIRTH_CITY = "birthCity",
    HOUSE_NUMBER = "houseNumber",
}
