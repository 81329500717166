import { AppLoader } from "components/Loaders";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Business_Creditor_BasicData,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React from "react";
import CreditorCreateEditFields from "screens/business/creditors/partials/CreditorCreateEditFields";
import { useCurrentCreditorContext } from "./tabs/CreditorTabs";

export const CreditorsEdit: React.FC = () => {
    const { checkPermission } = useCheckPermission();
    const { linkProvider } = useRouting();
    const showErrorPage = useShowErrorPage();

    const currentCreditorContext = useCurrentCreditorContext();
    const hasEditPermission = checkPermission(Business_Creditor_BasicData, [
        PermissionAccessTypes.EDIT,
    ]);

    return (
        <>
            {!currentCreditorContext.dataItem ? (
                <AppLoader />
            ) : (
                <CreditorCreateEditFields
                    value={currentCreditorContext.dataItem}
                    readonly={!hasEditPermission}
                    onSuccess={() =>
                        currentCreditorContext.refetchData
                            ? currentCreditorContext.refetchData()
                            : {}
                    }
                />
            )}
        </>
    );
};

export default CreditorsEdit;
