import React from "react";
import { Form, Col } from "react-bootstrap";
import { defaultTo } from "lodash-es";
import { ValidityStateManager, ValidityState } from "models/general";
import { AppInputField } from "components/FormFields";
import { useTranslation } from "react-i18next";
import { DragHandle } from "components/Sortable";
import { AppDeleteButton } from "components/Buttons";
import {
    CareScopeTemplateFieldType,
    CareScopeTemplateSurchargeField,
    CareScopeTemplateSurchargeFieldGroup,
} from "models/careScopeTemplate";
import styles from "./CareScopeTemplateRow.module.scss";
import { CareScopeTemplateFieldsContainer } from "./CareScopeTemplateFieldsContainer";
import { ErrorMessage } from "components";

export interface CareScopeTemplateSurchargeFieldGroupContainerProps {
    value: CareScopeTemplateSurchargeFieldGroup;
    isLocation?: boolean;
    onChange: (field: CareScopeTemplateSurchargeFieldGroup) => void;
    validityState?: ValidityState;
    onDelete: (uuid: string) => void;
    readonly?: boolean;
}

export const CareScopeTemplateSurchargeFieldGroupContainer: React.FC<
    CareScopeTemplateSurchargeFieldGroupContainerProps
> = ({ value, onChange, validityState, onDelete, readonly = false }) => {
    const { t } = useTranslation();

    const validityStateManager = new ValidityStateManager(validityState);

    return (
        <div className={styles.fieldRowRoot}>
            <div className={styles.rowContent}>
                <AppDeleteButton
                    readonly={readonly}
                    className={styles.rowDel}
                    onClick={() => {
                        if (!readonly && onDelete) {
                            onDelete(value.Uuid);
                        }
                    }}
                />
                <Form.Row>
                    <Col sm={6} xs={12} md={6}>
                        <AppInputField
                            label={t("careScopeTemplate.groupName")}
                            showEmptyError={true}
                            value={value.Name}
                            disabled={readonly}
                            onValueChange={(val) => {
                                onChange({
                                    ...value,
                                    Name: defaultTo(val, ""),
                                });
                            }}
                            placeholder={t("careScopeTemplate.groupName")}
                            error={validityStateManager.getFirstErrorInfo(
                                "Name"
                            )}
                        />
                    </Col>
                    <Col
                        sm={6}
                        xs={12}
                        md={6}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        {value.Fields.length == 0 && (
                            <ErrorMessage
                                errorInfo={validityStateManager.getFirstErrorInfo(
                                    "Fields"
                                )}
                            />
                        )}
                    </Col>
                </Form.Row>

                <CareScopeTemplateFieldsContainer<CareScopeTemplateSurchargeField>
                    onChange={(newFields) => {
                        onChange({
                            ...value,
                            Fields: newFields.map((f, idx) => {
                                return { ...f, Order: idx };
                            }),
                        });
                    }}
                    groupUuid={value.Uuid}
                    validityState={validityStateManager.state}
                    fieldType={CareScopeTemplateFieldType.SURCHARGE}
                    fields={value.Fields}
                    readonly={readonly}
                />
            </div>
            {!readonly && <DragHandle />}
        </div>
    );
};

export default CareScopeTemplateSurchargeFieldGroupContainer;
