import { AppGradientButton } from "components/Buttons";
import { AppCheckbox, AuthInputField } from "components/FormFields";
import { createUserAuthCookies } from "globals/helpers/cookieHelper";
import { ImageAssets } from "globals/images";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { useInitUserInfo, useInitUserSession } from "hooks/general/authHelpers";
import { useRouting } from "hooks/general/routing";
import { getLoginDefaultValue, LoginVm, validateLogin } from "models/auth";
import {
    getValidityStateFromApiResponse,
    ValidityStateManager,
} from "models/general/validityState";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { WithAuthLoader } from "screens/auth/partials/AuthLoader";
import { AuthService, getAuthServiceKey } from "services/AuthService";
import styles from "./LoginFields.module.scss";

interface LoginFieldsProps extends WithAuthLoader {}

export const LoginFields: React.FC<LoginFieldsProps> = ({ setLoading }) => {
    const { linkProvider } = useRouting();
    const [state, setState] = useState<LoginVm>(getLoginDefaultValue());
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation();
    const { initSession } = useInitUserSession();
    const { initUserInfo, setContextLoaded } = useInitUserInfo();
    const authService = new AuthService(linkProvider.noAuth.api);
    const { setRememberMe, userInfo } = useAppContext();
    const {
        isLoading: loading,
        data: loginResponse,
        isError: loginFailed,
        mutate: login,
    } = useMutation(
        getAuthServiceKey("login"),
        async () =>
            await authService.login({
                Email: state.Email,
                Password: state.Password,
            })
    );
    const {
        isFetching: loadingMe,
        data,
        isStale,
        refetch: getMe,
        isError: meFailed,
    } = useQuery(getAuthServiceKey("me"), async () => await authService.me(), {
        enabled: false,
        cacheTime: 5000, // otherwise it will re-use response even if user logout (within min of login)
    });

    const validityStateManager = useMemo(() => {
        let validityState = validateLogin(state);
        if (!showError && loginResponse && loginResponse.Errors) {
            setShowError(true);
            validityState = getValidityStateFromApiResponse(
                loginResponse,
                validityState
            );
        }
        return new ValidityStateManager(validityState);
    }, [loginResponse, state]);

    const onButtonClick = () => {
        if (validityStateManager.isStateValid()) {
            setShowError(false);
            setLoading(true);
            login();
        } else {
            setShowError(true);
        }
    };

    useEffect(() => {
        setLoading(loading);
        if (!loading && loginResponse) {
            if (!loginResponse.Errors || loginResponse.Errors.length === 0) {
                // response errors.length=1 is to ignore ErrorCode=400 which may occur if user try to login in multiple tabs
                if (loginResponse.Data) {
                    initSession(loginResponse.Data, state.RememberMe);
                    //calling for user data
                    setLoading(true);
                    getMe();
                }
            } else {
                setLoading(false);
            }
        } else if (!loading && loginFailed) {
            setLoading(false);
        }
    }, [loading, loginResponse, loginFailed]);

    useEffect(() => {
        if (!loadingMe && data && data.Data && !isStale) {
            initUserInfo(data.Data);
            setLoading(false);
        } else if (!loading && meFailed) {
            setLoading(false);
        }
    }, [data, isStale, loadingMe]);

    useEffect(() => {
        if (
            userInfo &&
            userInfo.User &&
            loginResponse &&
            !loading &&
            !loadingMe
        ) {
            if (loginResponse.Data) {
                // createUserCookies after me response, otherwise it redirects to wrong route
                //Create user Cookie
                createUserAuthCookies(loginResponse.Data, state.RememberMe);
                setContextLoaded();
            }
        }
    }, [userInfo, loginResponse, state, loading, loadingMe]);

    return (
        <div style={{ marginTop: "20px" }}>
            <AuthInputField
                value={state.Email}
                type="email"
                onValueChange={(value) =>
                    setState({
                        ...state,
                        Email: value,
                    })
                }
                onBlur={(email) =>
                    setState({
                        ...state,
                        Email: email,
                    })
                }
                iconPath={ImageAssets.common.envelope}
                placeholder={t("auth.email")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("Email")
                        : undefined
                }
            />
            <AuthInputField
                value={state.Password}
                type="password"
                onValueChange={(value) =>
                    setState({
                        ...state,
                        Password: value,
                    })
                }
                onBlur={(email) =>
                    setState({
                        ...state,
                        Password: email,
                    })
                }
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                        onButtonClick();
                    }
                }}
                iconPath={ImageAssets.common.lockGray}
                placeholder={t("auth.password")}
                error={
                    showError
                        ? validityStateManager.getFirstErrorInfo("Password")
                        : undefined
                }
            />
            <div className={styles.rememberForget}>
                <div className={styles.checkBox}>
                    <AppCheckbox
                        checked={state.RememberMe}
                        onChange={(e) => {
                            setState({
                                ...state,
                                RememberMe: e.target.checked,
                            });
                            setRememberMe(e.target.checked);
                        }}
                        label={t("auth.login.rememberMe")}
                    />
                </div>
                <div className={styles.forgotLink}>
                    <Link to={linkProvider.noAuth.screens.forgotPassword()}>
                        {t("auth.login.forgotPassword")}
                    </Link>
                </div>
            </div>
            <AppGradientButton
                className={styles.loginButton}
                disabled={showError && !validityStateManager.isStateValid()}
                onClick={() => {
                    onButtonClick();
                }}
                text={t("auth.login.name")}
            />
        </div>
    );
};

export default LoginFields;
