import { ISO8601_DATE_FORMAT } from "globals/constants";
import { v4 } from "uuid";
import moment from "moment";
import { AgeGroupTemplate } from "./model";
import { AgeGroupTemplateResponse } from "./response";
import { AgeGroupTemplateField } from "./model";
import { DurationPeriodType } from "models/general";

export const getAgeGroupTemplateFromResponse = (
    req: AgeGroupTemplateResponse
): AgeGroupTemplate => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        ParentEndDate: req.ParentEndDate
            ? moment(req.ParentEndDate, ISO8601_DATE_FORMAT)
            : undefined,
        FollowUpTemplates:
            req.FollowUpTemplates?.length > 0
                ? req.FollowUpTemplates.map((x) => {
                      return getAgeGroupTemplateFromResponse(x);
                  })
                : ([] as AgeGroupTemplate[]),
    } as AgeGroupTemplate;
};

export const getDefaultAgeGroupField = (): AgeGroupTemplateField => {
    return {
        Uuid: v4(),
        Order: 0,
        Name: "",
        ShortName: "",
        AgeGroupId: 0,
        Description: "",
        TimeUnit: DurationPeriodType.Month,
        AgeStart: 0,
        AgeEnd: 0,
    };
};

export const getDefaultAgeGroupTemplate = (
    locationId?: number,
    businessId?: number
): AgeGroupTemplate => {
    const fields: AgeGroupTemplateField[] = [getDefaultAgeGroupField()];

    return {
        StartDate: moment(),
        Id: 0,
        Name: "",
        LocationSettingId: locationId,
        BusinessId: businessId,
        ShortName: "",
        Fields: fields,
    } as AgeGroupTemplate;
};
