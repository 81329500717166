import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    BusinessEmployeeBenefit,
    BusinessEmployeeBenefitResponse,
    EmployeeBenefitRequestParameters,
} from "models/employeeAdditionalBenefit";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IEmployeeAdditionalBenefitService {
    list(
        request: EmployeeBenefitRequestParameters
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse[]>>;
    get(id: string): Promise<AppResponse<BusinessEmployeeBenefitResponse>>;
    create(
        model: BusinessEmployeeBenefit
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse>>;
    update(
        model: BusinessEmployeeBenefit
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse>>;
    delete(id: string): Promise<AppResponse<number>>;
}

const apiLinkProvider = routesForContext().business.api.additionalBenefits;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getEmployeeAdditionalBenefitServiceKey(
    name: keyof IEmployeeAdditionalBenefitService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `BusinessEmployeeBenefit_${name}_${JSON.stringify(data)}`;
}

export class EmployeeAdditionalBenefitService
    extends BaseService
    implements IEmployeeAdditionalBenefitService
{
    public additionalBenefitRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.additionalBenefitRoutes = apiLinkProvider;
    }

    list(
        request: EmployeeBenefitRequestParameters
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse[]>> {
        return this.doServerXHR<BusinessEmployeeBenefitResponse[]>({
            url: this.additionalBenefitRoutes.list({ request }),
            method: "get",
        });
    }

    get(id: string): Promise<AppResponse<BusinessEmployeeBenefitResponse>> {
        return this.doServerXHR<BusinessEmployeeBenefitResponse>({
            url: this.additionalBenefitRoutes.get(id),
            method: "get",
        });
    }

    constructFormData(model: BusinessEmployeeBenefit) {
        const ob = {
            ...model,
            StartDate: model.StartDate?.format(ISO8601_DATE_FORMAT),
            EndDate: model.EndDate
                ? model.EndDate.format(ISO8601_DATE_FORMAT)
                : undefined,
        };
        return ob;
    }

    create(
        model: BusinessEmployeeBenefit
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<BusinessEmployeeBenefitResponse>({
            url: this.additionalBenefitRoutes.create(),
            method: "post",
            data: fD,
        });
    }

    update(
        model: BusinessEmployeeBenefit
    ): Promise<AppResponse<BusinessEmployeeBenefitResponse>> {
        const fD = this.constructFormData(model);
        return this.doServerXHR<BusinessEmployeeBenefitResponse>({
            url: this.additionalBenefitRoutes.update(),
            method: "post",
            data: fD,
        });
    }

    delete(id: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.additionalBenefitRoutes.delete(id),
            method: "delete",
        });
    }
}

export default EmployeeAdditionalBenefitService;
