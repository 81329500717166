import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styles from "./AppGradientButton.module.scss";

export interface AppGradientButtonProps extends ButtonProps {
    text: string;
    animate?: boolean;
}

// eslint-disable-next-line react/display-name
export const AppGradientButton = React.memo<AppGradientButtonProps>((props) => {
    const { className = "", text, animate = true, ...rest } = props;
    return (
        <Button
            className={`${styles.gradientButton} ${className} ${
                styles.button
            } ${animate ? styles.animate : ""}`}
            {...rest}
        >
            {text}
        </Button>
    );
});

export default AppGradientButton;
