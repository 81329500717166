export const SidebarEnTranslations = {
    sidebar: {
        menu: "MENU",
        home: "Home",
        clients: "Clients",
        editProfile: "Edit Profile",
        kindergartenClients: {
            contracts: "Contracts",
            registrations: "Registrations",
        },
        emailManager: "Email Manager",
        lwClients: {
            title: "Clients",
            industry: "Industry",
            kindergarten: "Kindergarten",
            school: "School",
            elderlyCare: "Elderly Care",
        },
        lw: {
            mergeCompanies: "Merge Companies",
        },
        reports: {
            title: "Reports",
            occupancy: "Occupancy",
            ageGroupPlaning: "Age Group Planning"
        },
        employees: {
            title: "Employees",
            contracts: "Contracts",
            suppliers: "Suppliers",
        },
        shiftPlan: {
            title: "Shift Plan",
            employees: "Employees",
            groups: "Groups",
            shifts: "Shifts",
        },
        toDo: "To Do",
        messages: "Messages",
        chat: "Chat",
        calendar: "Calendar",
        timeManagement: {
            title: "Time Management",
            employees: "Employees",
            suppliers: "Suppliers",
        },
        payroll: {
            title: "Payroll",
            additionalBenefits: "Additional Benefits",
            absences: "Absences",
        },
        accounting: {
            title: "Accounting",
            creditors: "Creditors",
        },
        administration: "Administration",
        company: "Company",
        locationSetting: "Location Setting",
        createClients: "Create Clients",
        documentManager: {
            title: "Document Manager",
            templates: "Templates",
            letterHeadsAndFooter: "Letterheads And Footer",
        },
        archive: "Archive",
        roleManagement: {
            title: "Role Management",
            users: "Users",
            permissions: "Permissions",
        },
        support: "Support",
        goBack: "Go Back",
        packages: "Packages",
    },
};
export default SidebarEnTranslations;
