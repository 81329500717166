import { errorPageImages } from "globals/images/error";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./error.module.scss";

export const Error501: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.errorMsg}>
            <div className={styles.errorImage}>
                <img src={errorPageImages.e501} alt="" />
            </div>
            <div>
                <div className={styles.circleIcon}>
                    <span className={styles.pinkIcon}></span>
                    <span className={styles.blueIcon}></span>
                </div>
            </div>
            <div>
                <div className={styles.errorDesc}>
                    <h1>{t("errorPage.error501.title")}</h1>
                    <p>{t("errorPage.error501.desc")}</p>
                </div>
            </div>
        </div>
    );
};

export default Error501;
