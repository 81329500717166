import { AppContentHeader } from "components";
import AppLoader from "components/Loaders/AppLoader";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import {
    useBusinessCheckPermission,
    useCheckPermission,
} from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import { Business_BasicData, PermissionAccessTypes } from "models";
import {
    Business,
    BusinessType,
    getBusinessTypePathString,
} from "models/business";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BusinessTabs from "./partials/BusinessTabs";
import { ExplanationModeToggle } from "screens/business/settingTabs/ExplanationModeToggle";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import styles from "./BusinessSettingLayout.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useParams } from "react-router";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import { FeatureNotAvailableOverlay } from "layouts/appLayout/partials";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment";

export const BusinessSettingLayout: React.FC = () => {
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { businessType, businessId } = useParams();
    const { checkPermission } = useBusinessCheckPermission();
    const { moduleAccess } = useCheckPermission();
    const navigate = useNavigate();
    const showErrorPage = useShowErrorPage();
    const { t } = useTranslation();
    const { user } = useSessionUser();
    const [currentTab, setCurrentTab] = useState<NavigationTab | undefined>(
        undefined
    );

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );
    const {
        isLoading,
        isStale,
        data: response,
        error,
        refetch,
    } = useQuery(
        getCurrentBusinessServiceKey("getBasicInfo", {
            userId: user?.Id,
            businessId: businessId,
        }),
        async () => await businessService.getBasicInfo(businessId),
        { refetchOnWindowFocus: true }
    );

    useEffect(() => {
        if (
            location.pathname.endsWith("/settings") ||
            location.pathname.endsWith("settings/")
        ) {
            // used base layout pathname only
            // redirect to index tab
            navigate(
                linkProvider.business.businessSettings().screens.basicData(),
                { replace: true }
            );
        }
    }, [location.pathname]);
    useEffect(() => {
        if (!isLoading && (response || error)) {
            let hasError = !isNil(error);
            if (!hasError && response) {
                // id in url is wrong or businessType is wrong
                hasError =
                    isNil(response.Data) ||
                    getBusinessTypePathString(
                        response.Data.Type
                    ).toLocaleLowerCase() !== businessType;
            }

            if (hasError) {
                showErrorPage(404);
            }
        }
    }, [isLoading, response, error]);

    const data = useMemo(() => {
        return !isLoading &&
            response &&
            response.Data &&
            response.Data.BusinessSetting
            ? {
                  ...response.Data,
                  BusinessSetting: {
                      ...response.Data.BusinessSetting,
                      AgeGroupDeadline: response.Data.BusinessSetting
                          .AgeGroupDeadline
                          ? moment(
                                response.Data.BusinessSetting.AgeGroupDeadline,
                                ISO8601_DATE_FORMAT
                            )
                          : undefined,
                      TimeTrackingStart: response.Data.BusinessSetting
                          .TimeTrackingStart
                          ? moment(
                                response.Data.BusinessSetting.TimeTrackingStart,
                                ISO8601_DATE_FORMAT
                            )
                          : undefined,
                      VacationsCalculationStart: response.Data.BusinessSetting
                          .VacationsCalculationStart
                          ? moment(
                                response.Data.BusinessSetting
                                    .VacationsCalculationStart,
                                ISO8601_DATE_FORMAT
                            )
                          : undefined,
                  },
              }
            : null;
    }, [isLoading.valueOf, response]);

    return isNil(data) ? (
        <AppLoader />
    ) : (
        <div className={styles.root}>
            <AppContentHeader
                title={data.Name}
                classNameForIcon="lnr-apartment"
                hasGoBack={true}
                backUrl={linkProvider.business.screens.admin.companies()}
            >
                <ExplanationModeToggle
                    value={data.IsExplanationModeVisible}
                    readonly={
                        data.Type === BusinessType.LW ||
                        !checkPermission(
                            data.Id,
                            data.HqId,
                            Business_BasicData,
                            [PermissionAccessTypes.EDIT]
                        )
                    }
                />
            </AppContentHeader>
            <div className={styles.tabs}>
                <BusinessTabs
                    businessId={data.Id}
                    businessType={data.Type}
                    hqId={data.HqId}
                    onActiveTabChange={setCurrentTab}
                />
                <div className={styles.content}>
                    {!moduleAccess.hasAccess && !moduleAccess.showOnRoot && (
                        <FeatureNotAvailableOverlay />
                    )}
                    <Outlet
                        context={
                            {
                                dataItem: data,
                                tab: currentTab,
                                refetchData: () => {
                                    refetch();
                                },
                            } as TabbedLayoutActiveItem<Business>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default BusinessSettingLayout;
