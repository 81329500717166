import { SearchField } from "components/FormFields";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    Business_RoleManagement_UserRoles_Mobile,
    Business_RoleManagement_UserRoles_Web,
    defaultUserRoleItemValue,
    getUserSortColumnKeyFromEnum,
    parseUserItemResponse,
    UserCreateEditRequest,
    UserManagementFilter,
    UserRoleManagementItem,
    UserSortColumn,
} from "models";
import {
    AppPlatforms,
    Optional,
    ResponseMeta,
    sortData,
    SortOrder,
    TableSort,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
    UserManagementCreateEditDialog,
    UserManagementHeader,
    UserManagementTable,
} from "screens/business/userManagement/partials";
import UsersRoleManagementService, {
    getUsersRoleManagementServiceKey,
} from "services/business/UsersRoleManagementService";
import styles from "./UserManagement.module.scss";

export interface UserManagementState {
    selectedTab: AppPlatforms;
    filter: UserManagementFilter;
    editState: Optional<UserRoleManagementItem>;
    users: UserRoleManagementItem[] | undefined;
    openDialog: boolean;
    meta: Optional<ResponseMeta>;
    sort: TableSort<UserSortColumn>;
}
export const UserManagement: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { id: userId } = useSessionUser();
    const { linkProvider } = useRouting();
    const service = new UsersRoleManagementService(
        linkProvider.business.api.userManagement
    );

    const { getPermissionMap } = useCheckPermission();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();

    const [state, setState] = useState<UserManagementState>({
        selectedTab: searchParams.get("tab")
            ? (searchParams.get("tab") as AppPlatforms)
            : AppPlatforms.WEB,
        filter: { Name: "" },
        users: undefined,
        editState: defaultUserRoleItemValue(),
        openDialog: false,
        sort: {
            SortColumn: UserSortColumn.Name,
            SortOrder: SortOrder.ASC,
        },
        meta: null,
    });

    useEffect(() => {
        setSearchParams(
            { ...searchParams, tab: state.selectedTab },
            { replace: true }
        );
    }, [state.selectedTab]);

    const {
        isLoading: loadingUpdate,
        data: updateResponse,
        mutate: updateUser,
    } = useMutation(
        getUsersRoleManagementServiceKey("update"),
        async (req: UserCreateEditRequest) => await service.update(req)
    );
    const {
        isFetching: loadingGetUsers,
        isRefetching: refetching,
        data: usersResponse,
        refetch: refetchUsers,
    } = useQuery(
        getUsersRoleManagementServiceKey("getUserList", state.selectedTab),
        async () => await service.getUserList({ ForDevice: state.selectedTab }),
        {
            cacheTime: 0,
            select: (resp) =>
                resp.Data ? resp.Data.map((u) => parseUserItemResponse(u)) : [],
        }
    );

    useEffect(() => {
        if (!loadingGetUsers && !refetching && usersResponse) {
            const updatedState = {
                ...state,
                users: usersResponse,
            };
            const isEdit = !isNil(id);
            const isCreate = location.pathname.includes("create");

            if (isEdit || isCreate) {
                updatedState.openDialog = true;
                if (isEdit) {
                    updatedState.editState = usersResponse.find(
                        (x) => x.Id?.toString() === id
                    );
                }
            }
            setState(updatedState);
        }
    }, [loadingGetUsers, refetching]);
    useEffect(() => {
        if (!loadingUpdate && updateResponse && updateResponse.Data) {
            // used only to update user active state
            setState((old) => ({
                ...old,
                users: old.users?.map((u) => {
                    if (u.Id === updateResponse.Data.Id) {
                        return { ...u, IsActive: updateResponse.Data.IsActive };
                    }
                    return u;
                }),
            }));
        }
    }, [loadingUpdate, updateResponse]);

    const onTabChangeHandler = (tab: AppPlatforms) => {
        setState({ ...state, selectedTab: tab });
    };

    const onDialogClose = () => {
        navigate({
            pathname:
                linkProvider.business.screens.admin.usersManagement.list(),
            search: `?tab=${state.selectedTab}`,
        });
        setState({ ...state, openDialog: false, editState: null });
    };

    // useEffect(() => {
    //     // route validations
    //     const base = pathProvider.business.screens.admin.usersManagement;
    //     const validPaths = [base.list(), base.create(), base.edit()];
    //     if (validPaths.every((p) => !matchPath(p, location.pathname))) {
    //         showErrorPage(404);
    //     }
    // }, [location.pathname]);

    const onDialogOpen = (id?: string) => {
        const baseRoute = linkProvider.business.screens.admin.usersManagement;
        const toUpdate = {
            ...state,
            openDialog: true,
        };
        if (id) {
            navigate(baseRoute.edit(id));
            toUpdate.editState = state.users?.find((x) => x.Id == id);
        } else {
            navigate(baseRoute.create());
        }
        setState(toUpdate);
    };

    const removeItem = (id: string) => {
        setState({
            ...state,
            users: state.users?.filter((x) => x.Id !== id),
        });
    };

    useEffect(() => {
        if (state.users && usersResponse) {
            const filterList = usersResponse.filter((x) =>
                x.Name?.toLocaleLowerCase().includes(
                    state.filter.Name.toLocaleLowerCase()
                )
            );
            setState({
                ...state,
                users: sortData(filterList, [
                    {
                        col: getUserSortColumnKeyFromEnum(
                            state.sort.SortColumn
                        ),
                        dir: state.sort.SortOrder,
                    },
                ]) as UserRoleManagementItem[],
            });
        }
    }, [state.filter.Name]);
    useEffect(() => {
        if (state.users) {
            setState({
                ...state,
                users: sortData(state.users, [
                    {
                        col: getUserSortColumnKeyFromEnum(
                            state.sort.SortColumn
                        ),
                        dir: state.sort.SortOrder,
                    },
                ]) as UserRoleManagementItem[],
            });
        }
    }, [state.sort]);

    const onActiveStateChange = (
        user: UserRoleManagementItem,
        checked: boolean
    ) => {
        const userCreateEditRequest: UserCreateEditRequest = {
            ...user,
            IsActive: checked,
            UserId: user.Id as string,
            ForDevice: state.selectedTab.toString(),
        };

        updateUser(userCreateEditRequest);
    };

    const accessMap = useMemo(() => {
        let permissionString = "";
        switch (state.selectedTab) {
            case AppPlatforms.MOBILE:
                permissionString = Business_RoleManagement_UserRoles_Mobile;
                break;
            case AppPlatforms.WEB:
                permissionString = Business_RoleManagement_UserRoles_Web;
                break;
        }
        return getPermissionMap(permissionString);
    }, [state.selectedTab]);

    return (
        <>
            {state.openDialog && (
                <UserManagementCreateEditDialog
                    value={state.editState}
                    loading={loadingGetUsers || loadingUpdate}
                    modalOpen={state.openDialog}
                    onClose={onDialogClose}
                    deviceType={state.selectedTab}
                    title={
                        state.editState && state.editState.Id
                            ? t("common.edit")
                            : t("common.create")
                    }
                    onChange={(val: UserRoleManagementItem) => {
                        onDialogClose();
                        refetchUsers();
                    }}
                    readonly={userId === state.editState?.Id || !accessMap.EDIT}
                />
            )}
            <UserManagementHeader
                onTabChangeHandler={onTabChangeHandler}
                selectedTab={state.selectedTab}
                onCreate={() => onDialogOpen()}
            />
            <div className="d-flex justify-content-end mb-3">
                <div>
                    <SearchField
                        value={state.filter.Name}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                filter: { ...state.filter, Name: value },
                            })
                        }
                        className={styles.searchField}
                    />
                </div>
            </div>
            <UserManagementTable
                onSortChange={(sort) => {
                    setState({ ...state, sort: sort });
                }}
                users={state.users as UserRoleManagementItem[]}
                onActiveStateChange={onActiveStateChange}
                onEditClick={(userId) => {
                    onDialogOpen(userId);
                }}
                loading={loadingGetUsers}
                removeItem={removeItem}
                sort={state.sort}
                accessInfo={accessMap}
            />
        </>
    );
};

export default UserManagement;
