import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppSelectOld } from "components/AppSelect";
import { SimpleOption } from "components/AppSelect/partials";
import { AppButton, AppDeleteButton } from "components/Buttons";
import { AppTooltip } from "components/Tooltips";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    showSweetAlertConfirmation,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { isNil } from "lodash-es";
import {
    checkPermissionInMap,
    HqRegisterRequestModel,
    HqRegisterRequestStatus,
    LW_HqRegisterRequests,
    PermissionAccessTypes,
} from "models";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { LillywaitRegistrationRequestsService } from "services/lillywait/LillywaitRegistrationRequestsService";
import styles from "./HqRegisterRequestTableRow.module.scss";

const selectStyles = {
    valueContainer: (base: any) => ({
        ...base,
        minHeight: "27px",
        height: "27px",
    }),
};
export interface HqRegisterRequestTableRowProps {
    value: HqRegisterRequestModel;
    onStatusChange: (id: number, status: string) => void;
    onDelete: (id: number) => void;
}

export const HqRegisterRequestTableRow: React.FC<
    HqRegisterRequestTableRowProps
> = ({ value, onStatusChange, onDelete }) => {
    const { t } = useTranslation();
    const { getDateTimeFormatForLocale } = useLocaleHelpers();
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();

    const createHqUrl =
        linkProvider.lillywait.admin.registrationRequests.createHq(
            value.EncodedId
        );

    const registrationRequestsService =
        new LillywaitRegistrationRequestsService(
            linkProvider.lillywait.api.registerRequest
        );

    const permMap = useMemo(() => {
        return getPermissionMap(LW_HqRegisterRequests);
    }, [getPermissionMap]);
    const hasCreatePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.CREATE,
    ]);

    const hasDeletePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.DELETE,
    ]);

    const options = Object.values(HqRegisterRequestStatus)
        .map((x) => {
            return {
                value: x,
                label: t(
                    `hqRegisterRequestManagement.statusVals.${x
                        .toString()
                        .toLowerCase()}`
                ),
            };
        })
        .filter((x) => x.value != HqRegisterRequestStatus.STATUS_COMPLETED); // Complete set from backend

    const { mutate: resendCompletionMailToRequestPerson } = useMutation(
        async (id: number) =>
            await registrationRequestsService.resendCompleteRegistrationEmailToRequestPerson(
                id
            ),
        {
            onSuccess: () => {
                showSweetAlertToast(
                    t("common.success"),
                    t("hqRegisterRequestManagement.mailSentToUser"),
                    "success"
                );
            },
        }
    );

    return (
        <tr className={styles.tableRow}>
            <td
                style={{
                    ...getFixedCssWidths(200),
                    paddingLeft: "15px",
                }}
            >
                <p className={styles.textEllipse}>{value.FirstName}</p>
            </td>
            <td style={getFixedCssWidths(200)}>
                <p className={styles.textEllipse}>{value.LastName}</p>
            </td>
            <td style={getFixedCssWidths(200)}>
                <p className={styles.textEllipse}>{value.FacilityName}</p>
            </td>
            <td style={{ minWidth: "300px" }}>
                <div style={{ width: "260px" }}>
                    <p className={styles.textEllipse}>{value.Email}</p>
                </div>
            </td>
            <td style={getFixedCssWidths(200)}>
                <p className={styles.textEllipse}>{value.Phone}</p>
            </td>
            <td style={{ padding: "4px 15px" }}>
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value.Status !=
                    HqRegisterRequestStatus.STATUS_COMPLETED ? (
                        <AppSelectOld
                            options={options}
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                            value={options.find((x) => x.value == value.Status)}
                            onChange={(opt: SimpleOption) => {
                                if (value.Id) {
                                    onStatusChange(value.Id, opt.value);
                                }
                            }}
                        />
                    ) : (
                        <span className={styles.green}>
                            {t(
                                `hqRegisterRequestManagement.statusVals.${value.Status.toString().toLowerCase()}`
                            )}
                        </span>
                    )}
                </div>
            </td>
            <td style={getFixedCssWidths(190)}>
                {value.CreatedAt &&
                    value.CreatedAt.format(getDateTimeFormatForLocale())}
            </td>
            <td style={{ textAlign: "center" }}>
                <Link
                    to={
                        hasCreatePermission &&
                        value.Status != HqRegisterRequestStatus.STATUS_COMPLETED
                            ? createHqUrl
                            : ""
                    }
                >
                    <AppButton
                        style={{ minWidth: "160px" }}
                        padding="low"
                        disabled={
                            !hasCreatePermission ||
                            value.Status !=
                                HqRegisterRequestStatus.STATUS_INPROGRESS
                        }
                    >
                        {t("hqRegisterRequestManagement.setupBusiness")}
                    </AppButton>
                </Link>
            </td>
            <th style={getFixedCssWidths(70)}>
                <AppTooltip
                    content={t(
                        "hqRegisterRequestManagement.resendCompletionEmail"
                    )}
                    allowHtml={false}
                    trigger="mouseenter focus"
                    arrow={true}
                >
                    <div>
                        <FontAwesomeIcon
                            icon={faSync}
                            className={`${
                                value.Status ==
                                    HqRegisterRequestStatus.STATUS_COMPLETED &&
                                value.UserRegistrationCompleted == false
                                    ? styles.cursorPointer
                                    : "disabled"
                            }`}
                            onClick={() => {
                                if (
                                    value.Status ==
                                        HqRegisterRequestStatus.STATUS_COMPLETED &&
                                    value.UserRegistrationCompleted == false &&
                                    !isNil(value.Id)
                                ) {
                                    resendCompletionMailToRequestPerson(
                                        value.Id
                                    );
                                }
                            }}
                        />
                    </div>
                </AppTooltip>
            </th>
            <td>
                <AppDeleteButton
                    name=""
                    readonly={!hasDeletePermission}
                    onClick={() =>
                        showSweetAlertConfirmation(
                            t("common.delete.title"),
                            t("common.deleteSelectedConfirmation"),
                            () => {
                                if (value.Id) {
                                    onDelete(value.Id);
                                }
                            },
                            t("common.delete.yes"),
                            "question",
                            true
                        )
                    }
                />
            </td>
        </tr>
    );
};

export default HqRegisterRequestTableRow;
