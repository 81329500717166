import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { TableSort } from "models/general";
import { BusinessKonto, KontoSortColumn } from "models/konto";
import React from "react";
import { useTranslation } from "react-i18next";
import BusinessKontoTableRow from "./BusinessKontoTableRow";

export interface BusinessKontoTableProps {
    hasDeletePermission: boolean;
    loading: boolean;
    list: BusinessKonto[];
    sort: TableSort<KontoSortColumn>;
    onSortChange: (sort: TableSort<KontoSortColumn>) => void;
    onDeleteBtnClick: (id: string) => void;
    onClick: (id: string) => void;
}

const COLUMNS_COUNT = 3;
export const BusinessKontoTable: React.FC<BusinessKontoTableProps> = ({
    hasDeletePermission,
    loading,
    list,
    sort,
    onSortChange,
    onDeleteBtnClick,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <AppTable hover={list.length > 0}>
            <thead>
                <tr>
                    <AppTableSortColumnIcons
                        text={t("business.kontos.displayId")}
                        style={getFixedCssWidths(200)}
                        sortColumn={KontoSortColumn.DisplayId}
                        onClick={(key, order) =>
                            onSortChange({
                                SortColumn: key,
                                SortOrder: order,
                            })
                        }
                        sortOrder={
                            sort.SortColumn == KontoSortColumn.DisplayId
                                ? sort.SortOrder
                                : null
                        }
                    />

                    <AppTableSortColumnIcons
                        text={t("business.kontos.name")}
                        sortColumn={KontoSortColumn.Name}
                        onClick={(key, order) =>
                            onSortChange({
                                SortColumn: key,
                                SortOrder: order,
                            })
                        }
                        sortOrder={
                            sort.SortColumn == KontoSortColumn.Name
                                ? sort.SortOrder
                                : null
                        }
                    />
                    <th style={getFixedCssWidths(70)} />
                </tr>
            </thead>
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
                {loading ? (
                    <AppTableSkeleton columns={COLUMNS_COUNT} rows={10} />
                ) : list.length > 0 ? (
                    list.map((x, idx) => (
                        <BusinessKontoTableRow
                            key={idx}
                            value={x}
                            onClick={onClick}
                            onDeleteBtnClick={onDeleteBtnClick}
                            hasDeletePermission={hasDeletePermission}
                        />
                    ))
                ) : (
                    <tr>
                        <td
                            colSpan={COLUMNS_COUNT}
                            style={{ textAlign: "center" }}
                        >
                            {t("common.noDataFound")}
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={COLUMNS_COUNT} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default BusinessKontoTable;
