import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TemplateRowActiveTag.module.scss";

export interface TemplateRowActiveTagProps {
    active: boolean;
    className?: string;
}

export const TemplateRowActiveTag: React.FC<TemplateRowActiveTagProps> = ({
    active,
    className,
}) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.active]: active,
            })}
        >
            <span
                className="ellipsis-text single-line"
                style={{ whiteSpace: "initial" }}
            >
                {t(`common.status.${active ? "Active" : "notActive"}`)}
            </span>
        </div>
    );
};

export default TemplateRowActiveTag;
