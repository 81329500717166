export enum ReceiptType {
    Employee = "Employee",
    Parent = "Parent",
    Group = "Group",
}

export enum Resource {
    Client,
    Employee,
}

export enum MessageSortColumn {
    Id = 0,
    DateCreated = 1,
    Title = 2,
    HasAttachments = 3,
}
