import AppContentHeader from "components/AppContentHeader";
import AppButtonDatepicker from "components/FormFields/DatePicker/AppButtonDatePicker";
import AppButtonRangeDatepicker from "components/FormFields/DatePicker/AppButtonRangeDatePicker";
import { AppTableSkeleton } from "components/Table";
import AppTable from "components/Table/AppTable";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { getFilteredArray } from "globals/helpers/generalHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    AbsentsTableFilters,
    EmployeeAbsencesRequestParameters,
    EmployeeType,
    parsePersonAbsents,
    PersonAbsents,
    sortAbsentsData,
} from "models";
import { AbsentsTableSortColumn } from "models/businessAbsents/enum";
import { DatePickerType } from "models/datepicker/enum";
import { BaseObject, SortOrder, TableSort } from "models/general";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AbsentsTableFilterRow } from "screens/business/absences/partials";
import AbsentRow from "screens/business/absences/partials/AbsentRow";
import AbsentsTableHeaderRow from "screens/business/absences/partials/AbsentsTableHeaderRow";
import AbsentsService, {
    getAbsentsServiceKey,
} from "services/business/AbsentsService";
import styles from "./AbsentsReport.module.scss";

interface AbsentsReportState {
    request: EmployeeAbsencesRequestParameters;
    sort: TableSort<AbsentsTableSortColumn>;
    filters: AbsentsTableFilters;
    data: PersonAbsents[];
}
const COLUMNS_COUNT = 11;
export const AbsentsReport: React.FC<BaseObject> = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const { encodedId: encodedBusinessId, id } = useSessionBusiness();

    const [state, setState] = useState<AbsentsReportState>({
        request: {
            StartDate: moment().add(-1, "months"),
            EndDate: moment(),
            BusinessId: id,
            ForEmployeeType: EmployeeType.Employee,
        },
        data: [],
        sort: {
            SortColumn: AbsentsTableSortColumn.TotalAbsences,
            SortOrder: SortOrder.DESC,
        },
        filters: {},
    });

    const absentsService = new AbsentsService(
        linkProvider.business.api.absences
    );

    const {
        isLoading: loading,
        isFetching,
        data: response,
    } = useQuery(
        getAbsentsServiceKey("getEmployeeAbsences", {
            encodedBusinessId: encodedBusinessId,
            businessId: id,
            cutOff: state.request.StartDate,
            end: state.request.EndDate,
        }),
        async () => await absentsService.getEmployeeAbsences(state.request), // automatically refetch based on key change
        {
            select: (res) => res.Data.map((x) => parsePersonAbsents(x)),
        }
    );

    const filterData = useCallback(
        (
            value?: AbsentsTableFilters,
            data?: PersonAbsents[]
        ): PersonAbsents[] => {
            if (response) {
                const filteredData = getFilteredArray(
                    defaultTo(data, response),
                    defaultTo(value, state.filters)
                );
                return filteredData.length > 0
                    ? sortAbsentsData(filteredData, state.sort)
                    : [];
            }
            return [];
        },
        [response, state.sort]
    );

    useEffect(() => {
        if (!isFetching) {
            setState({ ...state, data: filterData(undefined, response) });
        }
    }, [isFetching]);

    useEffect(() => {
        if (state.filters && response) {
            setState({ ...state, data: filterData(state.filters) });
        }
    }, [state.filters]);
    useEffect(() => {
        if (state.sort && response) {
            if (state.data) {
                setState({
                    ...state,
                    data: sortAbsentsData(state.data, state.sort),
                });
            }
        }
    }, [state.sort]);

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t(`business.absents.title`)}
                classNameForIcon="pe-7s-note2"
            >
                <div className={styles.headerContent}>
                    <AppButtonRangeDatepicker
                        value={{
                            StartDate: state.request.StartDate,
                            EndDate: state.request.EndDate,
                        }}
                        readonly={loading}
                        className={styles.picker}
                        // tooltip={t("business.absents.cutOffDate")}
                        onChange={(val) => {
                            if (
                                val.StartDate.format(ISO8601_DATE_FORMAT) !=
                                    state.request.StartDate.format(
                                        ISO8601_DATE_FORMAT
                                    ) ||
                                (val.EndDate != state.request.EndDate &&
                                    val.EndDate?.format(ISO8601_DATE_FORMAT) !=
                                        state.request.EndDate?.format(
                                            ISO8601_DATE_FORMAT
                                        ))
                            ) {
                                setState({
                                    ...state,
                                    request: {
                                        ...state.request,
                                        StartDate: val.StartDate,
                                        EndDate: defaultTo(
                                            val.EndDate,
                                            moment()
                                        ),
                                    },
                                });
                            }
                        }}
                        pickerType={DatePickerType.DATE}
                        maxDate={moment()}
                    />
                </div>
            </AppContentHeader>
            <AppTable
                containerClass={`${styles.tableOuter}`}
                heightToAdjust={190}
                stickyHeader={true}
                mediumViewAdjustment={40}
            >
                <thead>
                    <AbsentsTableHeaderRow
                        value={state.sort}
                        onChange={(
                            key: AbsentsTableSortColumn,
                            order: SortOrder
                        ) =>
                            setState({
                                ...state,
                                sort: {
                                    SortColumn: key,
                                    SortOrder: order,
                                },
                            })
                        }
                    />
                </thead>
                <tbody>
                    <AbsentsTableFilterRow
                        value={state.filters}
                        onChange={(filters) =>
                            setState({
                                ...state,
                                filters: filters,
                            })
                        }
                    />
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                    {!isFetching &&
                        (state.data.length > 0 ? (
                            state.data.map((x, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <AbsentRow
                                            key={x.EncodedId}
                                            value={x}
                                        />
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan={COLUMNS_COUNT}
                                    style={{ textAlign: "center" }}
                                >
                                    {t("common.noDataFound")}
                                </td>
                            </tr>
                        ))}
                    {(isFetching || !response) && (
                        <AppTableSkeleton columns={COLUMNS_COUNT} rows={10} />
                    )}

                    <tr className="dummy-row">
                        <td colSpan={COLUMNS_COUNT} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default AbsentsReport;
