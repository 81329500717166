import { AppTooltip } from "components";
import { getParamName } from "globals/helpers/generalHelper";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { isFirefox } from "globals/helpers/webInfoHelper";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { AppNotificationType, NotificationModel } from "models/notification";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { NotificationService } from "services/business";
import styles from "./NotificationsIconListItem.module.scss";

export interface NotificationsIconListItemProps {
    value: NotificationModel;
    type: AppNotificationType;
    showHr?: boolean;
    onChange: (updated: NotificationModel) => void;
    onCloseDialog: () => void;
}

export const NotificationsIconListItem: React.FC<
    NotificationsIconListItemProps
> = ({ value, type, onChange, onCloseDialog, showHr = true }) => {
    const { t } = useTranslation();

    const { linkProvider } = useRouting();

    const notificationService = new NotificationService(
        linkProvider.business.api.notification
    );

    const { mutate: markRead } = useMutation(
        async (notificationId: number) =>
            await notificationService.markRead(notificationId),
        {
            onSuccess: () => {
                showSweetAlertToast(
                    t("common.success"),
                    t(
                        `notificationManagement.${
                            type == "downloads"
                                ? "downloadSuccess"
                                : "readSuccess"
                        }`
                    ),
                    "success"
                );
                onChange({
                    ...value,
                    IsRead: true,
                });
            },
        }
    );

    const row = (
        <>
            <div className={styles.firstRow}>
                <Image
                    src={value.IconUrl}
                    className={styles.notificationIcon}
                />
                <div className={styles.titleDiv}>
                    <AppTooltip
                        content={value.Title}
                        allowHtml={false}
                        trigger="mouseenter focus"
                        arrow={true}
                    >
                        <p
                            className={`${styles.title} ${
                                value.TargetUrl ? styles.cursorPointer : ""
                            }`}
                        >
                            {value.Title}
                        </p>
                    </AppTooltip>
                </div>
            </div>
            <div className={styles.secondRow}>
                <div className={styles.timeStamp}>
                    {value.TimeStamp.fromNow()}
                </div>
                {!value.IsRead && (
                    <div className={styles.dotWrapper}>
                        <span></span>
                    </div>
                )}
            </div>
        </>
    );

    const onClick = () => {
        if (!value.IsRead) {
            markRead(value.Id);
        }
        onCloseDialog();
    };

    return (
        value && (
            <>
                {type == "notifications" && value.TargetUrl ? (
                    <Link
                        className={styles.outerDiv}
                        to={getParamName(value.TargetUrl)}
                        onClick={() => {
                            onClick();
                        }}
                    >
                        {row}
                    </Link>
                ) : (
                    <span
                        className={styles.outerDiv}
                        onClick={() => {
                            if (value.TargetUrl) {
                                window.open(
                                    value.TargetUrl,
                                    isFirefox() ? "_blank" : "_self" // firefox blocks click and clears signalR if opened in same tab
                                );
                            }
                            onClick();
                        }}
                    >
                        {row}
                    </span>
                )}
                {showHr && <hr className={styles.hrStyling} />}
            </>
        )
    );
};

export default NotificationsIconListItem;
