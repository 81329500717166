import classnames from "classnames";
import { AppTooltip } from "components/Tooltips";
import useCheckPermission from "hooks/permissionCheck/useCheckPermission";
import {
    getParentPermissions,
    MetisMenuCommonProps,
    MetisMenuItemType,
} from "models/metisMenu";
import React from "react";
import { MetisMenuContainer } from "./MetisMenuContainer";
import styles from "./MetisMenuItem.module.scss";
import { MetisMenuLinker } from "./MetisMenuLinker";

export interface MetisMenuItemProps extends MetisMenuCommonProps {
    item: MetisMenuItemType;
    onClick?: (value: MetisMenuItemType) => void;
    toggleMenu?: (value: number) => void;
}

const LABEL_TEXT_COUNT = 23;
export const MetisMenuItem = React.memo<MetisMenuItemProps>(
    ({ classStore, item, onClick, toggleMenu }) => {
        const { label, icon, iconClass, classItem } = item;
        const { checkPermission } = useCheckPermission();

        let permissions = item.permissions;
        if (item.hasSubMenu) {
            permissions = getParentPermissions(item);
        }
        if (
            permissions &&
            permissions.length > 0 &&
            !checkPermission(permissions)
        ) {
            return null;
        }

        const showTooltip = label.length > LABEL_TEXT_COUNT + 1;

        return (
            <li
                className={classnames(
                    classItem ? classItem : classStore.classItem,
                    item.active && classStore.classItemActive,
                    item.hasActiveChild && classStore.classItemHasActiveChild,
                    item.hasSubMenu &&
                        item.isSubMenuVisible &&
                        classStore.classItemHasVisibleChild
                )}
            >
                <MetisMenuLinker
                    item={item}
                    toggleMenu={toggleMenu}
                    classStore={classStore}
                    className={styles.linkItem}
                    onClick={onClick}
                >
                    {iconClass ? (
                        <i className={`${iconClass} ${classStore.classIcon}`} />
                    ) : (
                        icon
                    )}
                    {showTooltip ? (
                        <AppTooltip
                            content={label}
                            allowHtml={false}
                            trigger="mouseenter focus"
                            arrow={true}
                        >
                            <span>
                                {`${label.substring(0, LABEL_TEXT_COUNT)}...`}
                            </span>
                        </AppTooltip>
                    ) : (
                        label
                    )}
                    {item.hasSubMenu && (
                        <i
                            className={classnames(
                                classStore.classStateIcon
                                    ? classStore.classStateIcon
                                    : "",
                                item.isSubMenuVisible
                                    ? classStore.iconNameStateVisible
                                    : classStore.iconNameStateHidden
                            )}
                        />
                    )}
                </MetisMenuLinker>
                {item.hasSubMenu && item && item.subMenuItems && (
                    <MetisMenuContainer
                        items={item.subMenuItems}
                        classStore={classStore}
                        visible={item.isSubMenuVisible}
                    />
                )}
            </li>
        );
    }
);

export default MetisMenuItem;
