import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { config } from "globals/config";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { useCallback } from "react";

const handleException = (e: any, retry?: () => void) => {
    console.debug("Catched signal-R error:\n", e);
    if (retry) {
        console.debug('Unable to connect with notifications-signalR, retrying after 10sec');
        setTimeout(() => {
            retry();
        }, 10000);
    }
}
export function useWebNotificationHub() {
    const { signalRInstance, setSignalRInstance } = useAppContext();

    const configure = () => {
        const notificationsHubConnection = new HubConnectionBuilder()
            .withUrl(`${config.serverBaseUrl}/signal-r/webNotification`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = () =>
            notificationsHubConnection.start().catch(e => {
                handleException(e, () => startConnection())
            });
        notificationsHubConnection.onreconnecting(() =>
            console.debug("Re-connecting notifications signal-R.")
        );

        notificationsHubConnection.onreconnected(() =>
            console.debug("Re-connected notifications signal-R successfully.")
        );
        notificationsHubConnection.onclose(() => {
            setTimeout(() => {
                // reconnect again after 5sec because we need to continuously listen for notifications
                startConnection();
            }, 5000);
            console.debug("Notifications signal-r disconnected. Retrying after 5sec.");
        });

        startConnection();
        setSignalRInstance(notificationsHubConnection);
    };

    const dispose = useCallback(() => {
        if (signalRInstance) {
            signalRInstance.stop().catch(handleException);
        }
        console.debug('Notifications signal-r disposed.')
        setSignalRInstance(null);
    }, [signalRInstance]);

    return { configure, dispose, signalR: signalRInstance };
}
