import { AppSlidingTabs } from "components/Tabs/SlidingTab";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { BusinessCreditor, BusinessType } from "models";
import { NavigationTab, TabbedLayoutActiveItem } from "models/general";
import {
    Business_Creditor_BankData,
    Business_Creditor_BasicData,
} from "models/permissionManagement/permissionConstants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router";

// not in edit-layout to resolve circular dependency
export const useCurrentCreditorContext = () => {
    return useOutletContext<TabbedLayoutActiveItem<BusinessCreditor>>();
};
export interface CreditorTabsProps {
    onActiveTabChange: (tab: NavigationTab) => void;
}
export const CreditorTabs: React.FC<CreditorTabsProps> = ({
    onActiveTabChange,
}) => {
    const { linkProvider } = useRouting();
    const { creditorId } = useParams();
    const { checkPermission } = useCheckPermission();
    const { t } = useTranslation();
    const linkBase = linkProvider.business.screens.creditors.withId();
    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    const getTranslation = (tabName: string) =>
        t(`accounting.creditors.tabs.${tabName}`);
    useEffect(() => {
        setTabs([
            {
                label: getTranslation("basicData"),
                hasAccess: checkPermission([Business_Creditor_BasicData]),
                url: linkBase.edit(),
            },
            {
                label: getTranslation("bankData"),
                hasAccess: checkPermission(Business_Creditor_BankData),
                url: linkBase.bankData.list(),
            },
        ]);
    }, [t, checkPermission]);

    return tabs.length > 0 ? (
        <AppSlidingTabs
            tabs={tabs}
            // slidesToShow={5}
            onActiveChange={onActiveTabChange}
        />
    ) : (
        <></>
    );
};

export default CreditorTabs;
