import { BusinessContextResponse } from "models";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

const businessApiLinkProvider = routesForContext().lillywait.support().api;
type ApiLinkProviderType = typeof businessApiLinkProvider;

export interface ILillywaitSupportService {
    enterSupportMode(
        forLwcId: number
    ): Promise<AppResponse<BusinessContextResponse>>;
    exitSupportMode(): Promise<AppResponse<BusinessContextResponse>>;
    mergeCompanies: (
        sourceId: number,
        targetId: number
    ) => Promise<AppResponse<boolean>>;
}

export function getLillywaitSupportServiceKey(
    name: keyof ILillywaitSupportService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `lwSupport_${name}_${JSON.stringify(data)}`;
}

export class LillywaitSupportService
    extends BaseService
    implements ILillywaitSupportService
{
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }
    enterSupportMode(
        forLwcId: number
    ): Promise<AppResponse<BusinessContextResponse>> {
        return this.doServerXHR<BusinessContextResponse>({
            url: this.baseRoute.enterSupportView({ forLwcId: forLwcId }),
            method: "post",
        });
    }
    exitSupportMode(): Promise<AppResponse<BusinessContextResponse>> {
        return this.doServerXHR<BusinessContextResponse>({
            url: this.baseRoute.exitSupportView(),
            method: "post",
        });
    }
    mergeCompanies(
        sourceId: number,
        targetId: number
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.mergeCompanies({
                sourceId: sourceId,
                targetId: targetId,
            }),
            method: "post",
        });
    }
}
