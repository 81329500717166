import i18next from "i18next";
import { PlaceholderType } from "./enum";

export function getPlaceholderTypeName(type: PlaceholderType) {
    return i18next.t(`placeholders.types.${type}`);
}

export function getPlaceholderLocalizedName(name: string) {
    return i18next.t(`placeholders.list.${name}`);
}
