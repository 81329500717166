import { ErrorMessage } from "components";
import { AppInputField } from "components/FormFields";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo, isNil } from "lodash-es";
import { SelectItem, ValidityStateManager } from "models/general";
import { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BusinessIPad, validateBusinessIPad } from "models/businessIpad";
import { AppDialog, AppDialogFooter } from "components/Dialogs";
import AppSelectOld from "components/AppSelect/AppSelectOld";

export interface BusinessIPadEditDialogProps {
    value: BusinessIPad;
    dialogOpen: boolean;
    readonly?: boolean;
    onDialogClose: () => void;
    statusOptions: SelectItem[];
    onSave: (value: BusinessIPad) => void;
}

export const BusinessIPadEditDialog: React.FC<BusinessIPadEditDialogProps> = ({
    value,
    dialogOpen,
    onDialogClose,
    onSave,
    statusOptions,
    readonly = false,
}) => {
    const { appLocale } = useLocaleHelpers();
    const { t } = useTranslation();

    const [state, setState] = useState<BusinessIPad>(value);

    const validityStateManager = useMemo(() => {
        return new ValidityStateManager(
            state ? validateBusinessIPad(state) : undefined
        );
    }, [state, appLocale]);

    return (
        <AppDialog
            title={t("business.ipads.updateIpad")}
            modalOpen={dialogOpen}
            onClose={onDialogClose}
            size="lg"
            footer={
                <AppDialogFooter
                    onDialogClose={onDialogClose}
                    disableSaveButton={
                        readonly || !validityStateManager.isStateValid()
                    }
                    onClickSaveButton={() => {
                        onDialogClose();
                        onSave(state);
                    }}
                    saveButtonText={t("common.update")}
                />
            }
        >
            <div style={{ position: "relative" }}>
                <Row>
                    <Col md={12}>
                        <AppInputField
                            label={t("business.ipads.name")}
                            showEmptyError={true}
                            value={state.Name}
                            disabled={readonly}
                            onValueChange={(val) => {
                                setState({
                                    ...state,
                                    Name: defaultTo(val, ""),
                                });
                            }}
                            error={validityStateManager.getFirstErrorInfo(
                                "Name"
                            )}
                        />
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label className="text-dark">
                                {t("business.ipads.status.name")}
                            </Form.Label>
                            <AppSelectOld
                                isDisabled={readonly}
                                options={statusOptions}
                                value={
                                    state.Status
                                        ? statusOptions.filter(
                                              (x) => x.Value == state.Status
                                          )
                                        : statusOptions[0]
                                }
                                getOptionLabel={(opt: SelectItem) => opt.Text}
                                getOptionValue={(opt: SelectItem) => opt.Value}
                                onChange={(opt: SelectItem) => {
                                    if (!isNil(opt.Value)) {
                                        setState({
                                            ...state,
                                            Status: opt.Value as number,
                                        });
                                    }
                                }}
                            />
                            <ErrorMessage
                                errorInfo={validityStateManager.getFirstErrorInfo(
                                    "Status"
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </AppDialog>
    );
};
export default BusinessIPadEditDialog;
