import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TWO_PRECISION_NUMERIC } from "globals/constants";
import { toNumber } from "globals/helpers/localizationHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import useNumberInput from "hooks/general/useNumberInput";
import { AppCurrencyCode, Optional } from "models/general";
import { ErrorInfo } from "models/general/validityState";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY } from "theme/themeConstants";
import AppInputField, { AppInputFieldProps } from "./AppInputField";

export interface NumberFieldProps
    extends Omit<AppInputFieldProps, "onChange" | "onBlur"> {
    onChange: (newD: number | null) => void;
    onBlur?: (newD: number | null) => void;
    value?: Optional<number>;
    label?: string | React.ReactNode;
    placeholder?: string;
    showError?: boolean;
    readonly?: boolean;
    showLabel?: boolean;
    showIcon?: boolean;
    format?: string;
    showEmptyField?: boolean;
    isForCurrency?: boolean;
    allowZero?: boolean;
    className?: string;
    iconToShow?: IconProp; // will be ignored if it is for currency
    error?: Optional<ErrorInfo>;
    showPlaceHolder?: boolean;
    formatOnBlur?: boolean;
    formatTheInitialValue?: boolean;
    showClearButton?: boolean;
}

export const AppNumberInput: React.FC<NumberFieldProps> = (
    props: NumberFieldProps
) => {
    const { t } = useTranslation();
    const { currencyCode } = useLocaleHelpers();
    const {
        onBlur = () => {},
        onChange,
        label = t("common.value"),
        readonly = false,
        placeholder = t("common.value"),
        showError: required = false,
        allowZero = false,
        showPlaceHolder = true,
        format = TWO_PRECISION_NUMERIC,
        value,
        showLabel = true,
        className = "",
        iconToShow,
        isForCurrency = false,
        error = null,
        showIcon = true,
        showEmptyField = false,
        formatTheInitialValue = false,
        formatOnBlur = true,
        showClearButton,
        ...rest
    } = props;

    let icon = iconToShow;

    if (isForCurrency) {
        icon =
            currencyCode === AppCurrencyCode.USD ? "dollar-sign" : "euro-sign";
    }
    const {
        inputValue,
        onInputChange,
        onBlur: onInputBlur,
    } = useNumberInput(onChange, value, {
        format: format,
        formatOnBlur: formatOnBlur,
        allowZero: allowZero,
        formatTheInitialValue: formatTheInitialValue,
    });

    // useEffect(() => {
    //     if (value === 0 && toNumber(inputValue) !== 0) {
    //         console.log("changed to zero");
    //         onInputChange("0");
    //     }
    // }, [value, inputValue]);
    const handleValue = (): string => {
        if (showEmptyField && !allowZero) {
            // eslint-disable-next-line no-extra-boolean-cast
            return Boolean(toNumber(inputValue)) ? inputValue : "";
        }
        return inputValue;
    };

    return (
        <AppInputField
            className={className}
            label={showLabel && label ? label : undefined}
            value={handleValue()}
            placeholder={showPlaceHolder ? placeholder : undefined}
            rightIcon={
                (isForCurrency || showIcon) && icon ? (
                    // <InputGroup.Prepend
                    //     style={{
                    //         left: "1px",
                    //         top: "1px",
                    //         height: "42px",
                    //         zIndex: "4",
                    //     }}
                    // >
                    //     <InputGroup.Text>
                    <FontAwesomeIcon
                        icon={icon}
                        style={{ color: COLOR_PRIMARY }}
                    />
                ) : //     </InputGroup.Text>
                // </InputGroup.Prepend>
                undefined
            }
            disabled={readonly}
            onBlur={() => {
                onBlur(onInputBlur());
            }}
            showClearButton={showClearButton}
            onValueChange={(val: string | undefined) => {
                if (showEmptyField) {
                    if (val === "") {
                        onInputChange(null);
                        return;
                    }
                }
                onInputChange(val ? val : null);
            }}
            error={required && error ? error : null}
            {...rest}
        />
    );
};

export default AppNumberInput;
