import { useRouting } from "hooks/general/routing";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EmployeeShiftCreateEdit } from "./partials";

export const EmployeeShiftCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const linkProviderBase = linkProvider.business.employees().screens.withId();

    return (
        <EmployeeShiftCreateEdit
            onBack={(year?: number) =>
                navigate(
                    linkProviderBase.shifts.list(),
                    year ? { state: { year: year } } : undefined
                )
            }
        />
    );
};

export default EmployeeShiftCreate;
