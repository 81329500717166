/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import { useEffect, useMemo, useRef, useState } from "react";
import tinymce from "tinymce";
import { Editor } from "@tinymce/tinymce-react";
import { Placeholder, PlaceholderType } from "models";
import {
    PlaceholderPluginOptions,
    DynamicPlaceholderFields,
} from "tinymce/plugins/placeholder/Types";
import { TinymceService } from "services/TinymceService";
import { defaultTo } from "lodash-es";
import { AppLocale, Optional } from "models/general";

// import language-packs
import "tinymce/langs/de.js";
import "tinymce/langs/it.js";
import "tinymce/langs/bs.js";
import "tinymce/langs/pl.js";
import "tinymce/langs/hr.js";
import "tinymce/langs/cs.js";
import "tinymce/langs/hu.js";
import "tinymce/langs/sk.js";
import "tinymce/langs/sl.js";
import "tinymce/langs/fr.js";
import "tinymce/langs/es.js";
import "tinymce/langs/nl.js";
import "tinymce/langs/et.js";
import "tinymce/langs/fi.js";
import "tinymce/langs/el.js";
// Theme
import "tinymce/themes/silver";
import "tinymce/icons/default";
// Add plugins
import "tinymce/plugins/placeholder/plugin";
import "tinymce/plugins/image/plugin";
import "tinymce/plugins/paste";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/preview";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/save";
import "tinymce/plugins/table";

import fontsAndCustomizations from "theme/tinymce/tinymceCustom.scss";
import styles from "./TinymceEditor.module.scss";
import classNames from "classnames";
import useLocaleHelpers from "hooks/general/localeHelpers";

export interface EditorState {
    placeholders: Placeholder[];
    html: string | null;
}

export function appendContentInActiveEditor(content: string) {
    if (tinymce.activeEditor) {
        tinymce.activeEditor.execCommand("mceInsertContent", false, content);
    }
}
const pluginsToUse = [
    "link",
    "placeholder",
    "lists",
    "autolink",
    "pagebreak",
    "noneditable",
    "textpattern",
    "image",
    "table",
    // "preview",
    // "paste",
    // "spellchecker",
    "media",
];
export function getDocumentEditorPlugins(): {
    toolbar: string;
    menubar: string;
    contextmenu: string;
} {
    //fontselect
    return {
        menubar: "edit insert format table",
        contextmenu: "link image",
        toolbar:
            "placeholderSidebar | lockContent | placeholder | undo redo |  fontsizeselect fontselect formatselect | bold italic backcolor | pagebreak | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image",
    };
}

export function getCommonPluginOptions(): any {
    return {
        pagebreak_separator: `<div style="page-break-before: always;"></div>`,
        pagebreak_split_block: true,
        fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt",
    };
}

const tinymceService = new TinymceService();
export function getImagePluginProps(): any {
    return {
        automatic_uploads: true,
        file_picker_types: "image",
        paste_data_images: true,
        image_dimensions: true,
        image_upload_credentials: true,
        images_upload_handler: function (
            blobInfo: any,
            success: any,
            failure: any,
            progress: any
        ) {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());
            tinymceService
                .uploadFile(formData)
                .then((data) => {
                    if (data.Code === undefined && data.Data) {
                        success(data.Data, { width: "100%" });
                    }
                })
                .catch((data) => {
                    failure(data);
                });
        },
    };
}

const lockClass = "custom-content-lock";
export interface TinymceEditorProps {
    value: EditorState;
    setFullWidth?: boolean;
    readonly?: boolean;
    tinymceProps: any;
    allowedPlaceholderTypes?: PlaceholderType[];
    dynamicPlaceholderProps?: DynamicPlaceholderFields[];
    onChange: (state: EditorState) => void;
    showPlaceholdersSidebar?: boolean;
    fontColor?: string;
}

const TINYMCE_LANGUAGES_PATH = "/tinymce/js/langs";
export function TinymceEditor({
    readonly = false,
    fontColor = "black",
    ...props
}: TinymceEditorProps) {
    const propsRel = useRef<TinymceEditorProps>(props);
    const editorRef = useRef<Optional<any>>();
    const [reInit, setReInit] = useState<boolean>(false);
    const { appLocale } = useLocaleHelpers();
    const placeHoldersRef = useRef<Placeholder[]>(
        propsRel.current.value?.placeholders || []
    );
    const getPlaceholders = (): Placeholder[] => placeHoldersRef.current;
    useEffect(() => {
        // re-init on locale change
        setReInit(true);
    }, [appLocale]);
    useEffect(() => {
        if (reInit) {
            setReInit(false);
        }
    }, [reInit]);
    const initialVal = useMemo(() => {
        placeHoldersRef.current = props.value?.placeholders || [];
        return props.value.html != null ? props.value.html : undefined;
    }, [reInit]);

    return reInit ? (
        <></>
    ) : (
        <div className={classNames("app-tinymce-editor", styles.root)}>
            <Editor
                disabled={readonly}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={initialVal}
                plugins={pluginsToUse}
                init={{
                    toolbar_mode: "sliding",
                    branding: false,
                    skin_url: "/tinymce/skins/ui/oxide",
                    content_css: [
                        "/tinymce/skins/content.min.css",
                        fontsAndCustomizations.toString(),
                    ],
                    // content_css: [fontsAndCustomizations.toString()],
                    content_style: `
                        p { margin: 0px; } 
                        body {
                            font-family: arial;
                            font-size: 0.9rem;
                            margin: 10px 15px;
                            color: ${fontColor};
                            ${
                                readonly
                                    ? `
                                        background-color: rgba(0,0,0,0.08);
                                        cursor: not-allowed;
                                    `
                                    : ""
                            }
                        }
                        img {
                            max-width: 100%;
                            object-fit: contain;
                        }
                        .custom-content-lock {
                            cursor: not-allowed !important;
                        }                        
                    `,
                    ...propsRel.current.tinymceProps,
                    font_formats: `Andale Mono=andale mono,times; Arial=arial,sans-serif; Arial Black=arial black,avant garde; 
                        Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; 
                        Georgia=georgia,palatino;Helvetica=helvetica; Impact=impact,chicago;Josefin Sans=Josefin Sans; Oswald=Oswald; 
                        Roboto=Roboto,sans-serif;Sukhumvit Set=SukhumvitSet; Times New Roman=times new roman,times; Terminal=Terminal;
                        Tahoma=tahoma,arial,helvetica,sans-serif;Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva`,
                    statusbar: false,
                    language:
                        appLocale !== AppLocale.ENGLISH
                            ? appLocale.substring(0, 2)
                            : undefined,
                    // language_url:
                    //     appLocale !== AppLocale.EN
                    //         ? `${TINYMCE_LANGUAGES_PATH}/${appLocale.substring(
                    //               0,
                    //               2
                    //           )}.js`
                    //         : undefined,
                    imagePluginFullWidthFlag: propsRel.current.setFullWidth,
                    placeholder_options: {
                        onPlaceholderChange: (placeholders) => {
                            placeHoldersRef.current = placeholders;
                        },
                        allowedTypes: defaultTo(
                            props.allowedPlaceholderTypes,
                            []
                        ),
                        getPlaceholders: getPlaceholders,
                        includeSidebar: props.showPlaceholdersSidebar,
                        dynamicPlaceholderProps: defaultTo(
                            props.dynamicPlaceholderProps,
                            []
                        ),
                    } as PlaceholderPluginOptions,
                    init_instance_callback: function (editor: any) {
                        /* 
                            The following two hacks fix some weirdness with the way the textcolor
                            plugin works - namely, it was attempting to apply color and background-color
                            directly on the element that had the noneditable css class on it instead of putting
                            a span around it as underline does.
                        */
                        editor.formatter.get("forecolor")[0].exact = true;
                        editor.formatter.get("hilitecolor")[0].exact = true;
                    },
                    setup: (editor: any) => {
                        const $ = tinymce.dom.DomQuery;
                        const nonEditableClass = editor.getParam(
                            "noneditable_noneditable_class",
                            "mceNonEditable"
                        );

                        editor.on("keydown", function (event: any) {
                            const node = $(editor.selection.getNode());
                            const code = event.keyCode;

                            //disable locked content delete on Del and BackSpace
                            if (code === 8 || code === 46 || code === 13) {
                                const target =
                                    code === 8 ? node.prev() : node.next();
                                if (
                                    node.hasClass(lockClass) ||
                                    node.parents(lockClass).length ||
                                    node.find(`.${lockClass}`).length ||
                                    target.hasClass(lockClass) ||
                                    target.parents(lockClass).length ||
                                    (code === 13 &&
                                        node.hasClass(nonEditableClass))
                                ) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    return false;
                                }
                            }
                        });
                    },
                }}
                onEditorChange={(newHtml, editor) => {
                    let content = newHtml;
                    if (defaultTo(propsRel.current.setFullWidth, false)) {
                        // searching through all the img tags in the content..
                        content = content.replace(
                            /<img ([^>]*src=)(?![^>]*width=)/g,
                            function (full: any, key: any, value: any) {
                                return `<img width='100%' src=`;
                            }
                        );
                    }
                    props.onChange({
                        html: content,
                        placeholders: placeHoldersRef.current,
                    });
                }}
            />
        </div>
    );
}

export default TinymceEditor;
