import { ISO8601_DATE_FORMAT } from "globals/constants";
import { v4 } from "uuid";
import moment from "moment";
import { SalaryGroupTemplate } from "./model";
import { SalaryGroupTemplateResponse } from "./response";
import { SalaryGroupTemplateField } from "./model";
import { DurationPeriodType } from "models/general";

export const getSalaryGroupTemplateFromResponse = (
    req: SalaryGroupTemplateResponse
): SalaryGroupTemplate => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        ParentEndDate: req.ParentEndDate
            ? moment(req.ParentEndDate, ISO8601_DATE_FORMAT)
            : undefined,
        FollowUpTemplates:
            req.FollowUpTemplates?.length > 0
                ? req.FollowUpTemplates.map((x) => {
                      return getSalaryGroupTemplateFromResponse(x);
                  })
                : ([] as SalaryGroupTemplate[]),
    } as SalaryGroupTemplate;
};

export const getDefaultSalaryGroupField = (): SalaryGroupTemplateField => {
    return {
        Uuid: v4(),
        Order: 0,
        Name: "",
        ShortName: "",
        SalaryGroupId: 0,
        Description: "",
    };
};

export const getDefaultSalaryGroupTemplate = (
    locationId?: number,
    businessId?: number
): SalaryGroupTemplate => {
    const fields: SalaryGroupTemplateField[] = [getDefaultSalaryGroupField()];

    return {
        StartDate: moment(),
        Id: 0,
        Name: "",
        LocationSettingId: locationId,
        BusinessId: businessId,
        ShortName: "",
        Fields: fields,
    } as SalaryGroupTemplate;
};
