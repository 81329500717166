import { ChannelResponseModel, ChannelType } from "models/chat";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ChannelsListBox.module.scss";
import { ChannelItem, ChatChannelLoader } from "./partials";
interface ChannelBoxProps {
    channelResponseList: ChannelResponseModel[];
    activeChannel: string;
    loading: boolean;
    disabled?: boolean;
    onChannelSelect: (channelResponseModel: ChannelResponseModel) => void;
}
// eslint-disable-next-line react/display-name
export const ChannelsListBox = React.memo<ChannelBoxProps>(
    ({
        channelResponseList,
        activeChannel,
        loading,
        onChannelSelect,
        disabled,
    }) => {
        const { t } = useTranslation();
        return (
            <>
                <ChatChannelLoader res={channelResponseList} loading={loading}>
                    {() => {
                        if (channelResponseList.length > 0) {
                            return (
                                <ListGroup as={"div"} className={styles.root}>
                                    {channelResponseList.map((item) => {
                                        if (
                                            item.ChannelType ==
                                            ChannelType.ONE_TO_ONE
                                        ) {
                                            item.ChannelUserType = undefined; // for channel list don't show name in ONE-TO-ONE
                                        }
                                        return (
                                            <ChannelItem
                                                key={item.ChannelId}
                                                active={
                                                    item.ChannelId ==
                                                    activeChannel
                                                }
                                                disabled={disabled}
                                                channel={item}
                                                onChannelSelect={
                                                    onChannelSelect
                                                }
                                            />
                                        );
                                    })}
                                </ListGroup>
                            );
                        }
                        return (
                            <div className={styles.chatEmptyTextDiv}>
                                {t("chat.emptyMessage")}
                            </div>
                        );
                    }}
                </ChatChannelLoader>
            </>
        );
    }
);

export default ChannelsListBox;
