export enum EmployeeStatus {
    OnAdvance = "OnAdvance",
    Active = "Active",
    Expired = "Expired",
}
export enum EmployeeType {
    Employee = "Employee",
    Supplier = "Supplier",
    Candidate = "Candidate",
}
export enum EmployeeTypeInteger {
    Employee = 0,
    Supplier = 1,
    Candidate = 2,
}

export enum EmployeeSortColumn {
    Id = 0,
    Name = 1,
    EmployeeFunction = 2,
    WorkArea = 3,
    HoursPerWeek = 4,
    Chargeable = 5,
    GrossSalary = 6,
    ContractStart = 7,
    ContractEnd = 8,
    ExpirationDate = 9,
    TrialEnd = 10,
    Holidays = 11,
    DisplayId = 12,
    App = 13,
    Status = 14,
    Group = 15,
}
