import { ISO8601_DATE_FORMAT } from "globals/constants";
import { v4 } from "uuid";
import moment from "moment";
import {
    CompensationTemplateType,
    LwCompensationTemplateAppliesTo,
    CompensationTemplateFieldType,
    LWCompensationTemplateFieldCategory,
} from "./enum";
import {
    CompensationTemplateField as CompensationTemplateField,
    CompensationTemplateOptionField as CompensationTemplateOptionField,
    CompensationTemplateSimpleField as CompensationTemplateSimpleField,
    CompensationTemplate as CompensationTemplate,
    LillywaitCompensationTemplateMainField as LillywaitCompensationTemplateMainField,
    LillywaitCompensationTemplateModulePriceField,
    CompensationTemplateCareScopeField,
    CompensationTemplateSalaryGroupField,
} from "./model";
import { CompensationTemplateResponse as CompensationTemplateResponse } from "./response";
import { BusinessType } from "models/business";
import { defaultTo } from "lodash-es";

export const CompensationTemplateTypeToFieldType: {
    [key in CompensationTemplateType]: CompensationTemplateFieldType;
} = {
    [CompensationTemplateType.Simple]: CompensationTemplateFieldType.Simple,
    [CompensationTemplateType.MultiOption]:
        CompensationTemplateFieldType.Option,
    [CompensationTemplateType.SalaryGroup]:
        CompensationTemplateFieldType.SalaryGroup,
    [CompensationTemplateType.CareScope]:
        CompensationTemplateFieldType.CareScope,
    [CompensationTemplateType.Surcharge]:
        CompensationTemplateFieldType.Surcharge,
    [CompensationTemplateType.LillywaitCompensation]:
        CompensationTemplateFieldType.LillywaitCompensation,
};
export const getDefaultFieldsForCompensationType = (
    type: CompensationTemplateType,
    fields: CompensationTemplateField[],
    canEdit: boolean = true
) => {
    return fields.length == 0
        ? type == CompensationTemplateType.Simple
            ? !canEdit
                ? [getDefaultCompensationTemplateBaseField()]
                : []
            : type == CompensationTemplateType.MultiOption
            ? [getDefaultZeroValueOptionField()]
            : type == CompensationTemplateType.SalaryGroup
            ? [getDefaultCompensationSalaryGroupField()]
            : fields
        : fields;
};
export const getCompensationTemplateFromResponse = (
    req: CompensationTemplateResponse
): CompensationTemplate => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        ParentEndDate: req.ParentEndDate
            ? moment(req.ParentEndDate, ISO8601_DATE_FORMAT)
            : undefined,
        FollowUpTemplates:
            req.FollowUpTemplates?.length > 0
                ? req.FollowUpTemplates.map((x) => {
                      return getCompensationTemplateFromResponse(x);
                  })
                : ([] as CompensationTemplate[]),
        Fields: getDefaultFieldsForCompensationType(
            req.Type,
            defaultTo(req.Fields, []),
            req.CanEditValue
        ),
    } as CompensationTemplate;
};

export const getDefaultCompensationSalaryGroupField =
    (): CompensationTemplateSalaryGroupField => {
        return {
            Uuid: v4(),
            Order: 0,
            Type: CompensationTemplateFieldType.SalaryGroup,
            AgeGroupFieldUuid: "",
        };
    };
export const getDefaultOptionField = (): CompensationTemplateOptionField => {
    return {
        Uuid: v4(),
        Order: 0,
        Type: CompensationTemplateFieldType.Option,
        Name: "",
        ShortName: "",
        ValueId: 0,
        FieldValue: { Value: 0 },
        IsDefault: false,
    };
};
export const getDefaultCompensationTemplateCareScopeField = (
    type:
        | CompensationTemplateFieldType.CareScope
        | CompensationTemplateFieldType.Surcharge
): CompensationTemplateCareScopeField => {
    return {
        Uuid: v4(),
        Order: 0,
        Type: type,
        AgeGroupFieldUuid: "",
    };
};
export const getDefaultCompensationTemplateSalaryGroupField =
    (): CompensationTemplateSalaryGroupField => {
        return {
            Uuid: v4(),
            Order: 0,
            Type: CompensationTemplateFieldType.SalaryGroup,
            AgeGroupFieldUuid: "",
        };
    };

export function getDefaultZeroValueOptionField(): CompensationTemplateOptionField {
    return {
        Uuid: v4(),
        Order: 0,
        Type: CompensationTemplateFieldType.Option,
        Name: "-",
        ShortName: "-",
        ValueId: 0,
        FieldValue: {
            Value: 0,
        },
        IsDefault: true,
    };
}

export const getDefaultLillywaitCompensationTemplateField = (
    category: LWCompensationTemplateFieldCategory = LWCompensationTemplateFieldCategory.Slab,
    appliesTo: LwCompensationTemplateAppliesTo = LwCompensationTemplateAppliesTo.Client
): LillywaitCompensationTemplateMainField => {
    const obj: LillywaitCompensationTemplateMainField = {
        Uuid: v4(),
        Type: CompensationTemplateFieldType.LillywaitCompensation,
        Order: 0,
        CompensationTemplateId: 0,
        AppliesTo: appliesTo,
        Category: category,
        RangeStart: 0,
        RangeEnd: null,
        ValueId: 0,
        FieldValue: {
            Value: 0,
        },
    };
    switch (category) {
        case LWCompensationTemplateFieldCategory.Range:
            obj.RangeStart = 1;
            obj.Name = null;
            break;
        case LWCompensationTemplateFieldCategory.Slab:
            obj.SlabSize = 1;
            break;
    }
    return obj;
};

export const getDefaultLillywaitCompensationTemplateModuleField =
    (): LillywaitCompensationTemplateModulePriceField => {
        return {
            Uuid: v4(),
            Order: 0,
            Type: CompensationTemplateFieldType.LillywaitModule,
            ValueId: 0,
            FieldValue: {
                Value: 0,
            },
        };
    };

export const CompensationTemplateFieldTypeManager = {
    [CompensationTemplateFieldType.Option]: {
        defaultValue: () => {
            return getDefaultOptionField();
        },
    },
    [CompensationTemplateFieldType.Simple]: {
        defaultValue: () => {
            return getDefaultCompensationTemplateBaseField();
        },
    },
    [CompensationTemplateFieldType.CareScope]: {
        defaultValue: () => {
            return getDefaultCompensationTemplateCareScopeField(
                CompensationTemplateFieldType.CareScope
            );
        },
    },
    [CompensationTemplateFieldType.SalaryGroup]: {
        defaultValue: () => {
            return getDefaultCompensationTemplateSalaryGroupField();
        },
    },
    [CompensationTemplateFieldType.Surcharge]: {
        defaultValue: () => {
            return getDefaultCompensationTemplateCareScopeField(
                CompensationTemplateFieldType.Surcharge
            );
        },
    },
    [CompensationTemplateFieldType.LillywaitCompensation]: {
        defaultValue: (
            category: LWCompensationTemplateFieldCategory = LWCompensationTemplateFieldCategory.Slab,
            appliesTo: LwCompensationTemplateAppliesTo = LwCompensationTemplateAppliesTo.Client
        ) => {
            return getDefaultLillywaitCompensationTemplateField(
                category,
                appliesTo
            );
        },
    },
    [CompensationTemplateFieldType.LillywaitModule]: {
        defaultValue: () => {
            return getDefaultLillywaitCompensationTemplateModuleField();
        },
    },
};

export const getDefaultCompensationTemplateBaseField =
    (): CompensationTemplateSimpleField => {
        return {
            Uuid: v4(),
            Order: 0,
            Type: CompensationTemplateFieldType.Simple,
            ValueId: 0,
            FieldValue: { Value: 0 },
        };
    };

export const getDefaultCompensationTemplate = (
    templateType: CompensationTemplateType,
    isForLW?: boolean
): CompensationTemplate => {
    let fields: CompensationTemplateField[] = [];

    if (isForLW) {
        templateType = CompensationTemplateType.LillywaitCompensation;
    }

    switch (templateType) {
        case CompensationTemplateType.Simple:
            fields = [getDefaultCompensationTemplateBaseField()];
            break;
        case CompensationTemplateType.MultiOption:
            fields = [getDefaultZeroValueOptionField()];
            break;
        case CompensationTemplateType.LillywaitCompensation:
            fields = [
                getDefaultLillywaitCompensationTemplateField(
                    LWCompensationTemplateFieldCategory.Slab,
                    LwCompensationTemplateAppliesTo.Client
                ),
                getDefaultLillywaitCompensationTemplateField(
                    LWCompensationTemplateFieldCategory.Slab,
                    LwCompensationTemplateAppliesTo.Employee
                ),
            ];
            break;
        default:
            fields = []; // for CareScope/Surcharge as data is fetched from API
    }

    return {
        StartDate: moment(),
        Id: 0,
        IsInternal: true,
        Name: "",
        ShortName: "",
        Type: templateType,
        CanEditValue: false,
        Fields: fields,
        oldFields: [],
        ForBusiness:
            templateType == CompensationTemplateType.LillywaitCompensation
                ? BusinessType.KINDERGARTEN
                : undefined,
    } as CompensationTemplate;
};
