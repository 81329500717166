import diseasesIcon from "assets/images/dashboard/diseases.png";
import statIcon from "assets/images/dashboard/dashboard.png";
import missingIcon from "assets/images/dashboard/missing.png";
import vacationsIcon from "assets/images/dashboard/vacations.png";

export const dashboardImages = {
    dashboard: statIcon,
    diseases: diseasesIcon,
    missing: missingIcon,
    vacations: vacationsIcon,
};
