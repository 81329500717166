import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import DeleteWithPassword from "commonPartials/deleteWithPassword/DeleteWithPassword";
import { AppDeleteButton } from "components/Buttons";
import { COOKIE_SHORT_EXPIRY } from "globals/constants";
import { setCookie } from "globals/helpers/cookieHelper";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    showSwalLoader,
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import {
    useSessionBusiness,
    useUpdateSessionBusinessInfo,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { t } from "i18next";
import { isNil } from "lodash-es";
import { Business, CompanyType } from "models";
import { BusinessType } from "models/business";
import { CookiesKey } from "models/general";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import {
    BusinessService,
    getBusinessServiceKey,
} from "services/business/BusinessService";
import styles from "./AdminBusinessBranchRowContent.module.scss";

export interface AdminBusinessBranchRowContentProps {
    value: Business;
    onClick?: () => void;
    onDelete: (hqId: number, businessId?: number) => void;
    canDelete?: boolean;
    isPrimary: boolean;
}

export const AdminBusinessBranchRowContent: React.FC<
    AdminBusinessBranchRowContentProps
> = ({ value, onClick, canDelete, isPrimary, onDelete }) => {
    const { linkProvider } = useRouting();
    const { sessionBusiness } = useSessionBusiness();
    const navigate = useNavigate();
    const { checkPermission } = useCheckPermission();
    const [lwDeleteOpen, setLwDeleteOpen] = useState<boolean>(false);
    const [redirectState, setRedirectState] = useState<{
        redirect: boolean;
        home: boolean;
    }>({ home: false, redirect: false });
    const [buttonHover, setButtonHover] = useState<boolean>(false);
    const { setSessionBusinessAndLocation: updateSessionBusinessContext } =
        useUpdateSessionBusinessInfo();
    const businessService = new BusinessService(linkProvider.business.api);
    const {
        isLoading: changingSessionBusiness,
        data: changeSessionBusinessResponse,
        isError: changeSessionBusinessError,
        reset: changeSessionBusinessReset,
        mutate: changeSessionBusiness,
    } = useMutation(
        getBusinessServiceKey("changeSessionBusiness"),
        async (businessId: number) =>
            await businessService.changeSessionBusiness(businessId)
    );
    const {
        isLoading: changingSessionHQ,
        data: changeSessionHQResponse,
        isError: changeSessionHQError,
        reset: changeSessionHQReset,
        mutate: changeSessionHQ,
    } = useMutation(
        getBusinessServiceKey("changeActiveHq"),
        async (businessId: number) =>
            await businessService.changeActiveHq(businessId)
    );

    const {
        isLoading: deletingBusiness,
        data: deleteBusinessResponse,
        isError: deleteBusinessError,
        reset: deleteBusinessReset,
        mutate: deleteBusiness,
    } = useMutation(
        getBusinessServiceKey("deleteBusiness"),
        async (businessId: number) =>
            await businessService.deleteBusiness(businessId)
    );
    const {
        isLoading: deletingLw,
        data: deleteLwResponse,
        reset: deleteLwReset,
        isError: deleteLwError,
        mutate: deleteLw,
    } = useMutation(
        getBusinessServiceKey("deleteLw"),
        async (data: {
            password: string;
            businessId: number;
            hqId: number | null;
        }) =>
            await businessService.deleteLw(
                data.password,
                data.businessId,
                data.hqId
            )
    );
    const showError = (message: string) => {
        showSweetAlertInfo(
            t("common.error.error"),
            message,
            "error",
            () => setLwDeleteOpen(false),
            t("common.cancel")
        );
    };

    useEffect(() => {
        if (changingSessionHQ || changingSessionBusiness) {
            showSwalLoader(
                t("common.pleaseWait"),
                t("business.switchingActiveBusiness")
            );
        }
    }, [changingSessionHQ, changingSessionBusiness]);
    useEffect(() => {
        if (!deletingLw && deleteLwResponse) {
            if (deleteLwResponse.Data) {
                showSweetAlertInfo(
                    t("common.success"),
                    t(`deleteLw.successMessage`),
                    "success",
                    () => setLwDeleteOpen(false)
                );
                onDelete(value.HqId, value.Id);
            } else {
                showError(t(`deleteLw.errorMessage`));
            }
            deleteLwReset();
        } else if (deleteLwError) {
            showError(t(`passwordDialog.incorrectPassword`));
            deleteLwReset();
        }
    }, [deletingLw, deleteLwResponse, deleteLwError, deleteLwReset]);
    useEffect(() => {
        if (!deletingBusiness && deleteBusinessResponse) {
            if (deleteBusinessResponse.Data) {
                showSweetAlertInfo(
                    t("common.success"),
                    t(`common.delete.success`),
                    "success"
                );
                onDelete(value.HqId, value.Id);
            } else {
                showError(t(`delete.error`));
            }
            deleteBusinessReset();
        } else if (deleteBusinessError) {
            showSweetAlertInfo(
                t("common.error.error"),
                t(`common.actions.errors.unableToDelete`),
                "error"
            );
            deleteBusinessReset();
        }
    }, [
        deletingBusiness,
        deleteBusinessResponse,
        deleteBusinessError,
        deleteBusinessReset,
    ]);
    useEffect(() => {
        if (
            (!changingSessionBusiness && changeSessionBusinessResponse) ||
            (!changingSessionHQ && changeSessionHQResponse)
        ) {
            if (
                changeSessionBusinessResponse?.Data ||
                changeSessionHQResponse?.Data
            ) {
                const data = !isNil(changeSessionHQResponse)
                    ? changeSessionHQResponse.Data
                    : changeSessionBusinessResponse?.Data;

                if (data) {
                    // update context
                    // business type changed so need to ignore for first render otherwise it will redirect to current businessState
                    setCookie(CookiesKey.IGNORE_WRONG_URL_PARAM, true, {
                        expires: COOKIE_SHORT_EXPIRY(),
                    });
                    setRedirectState({
                        redirect: true, // for changing sessionBusinessId in route
                        home:
                            sessionBusiness?.Type !== data.SessionBusiness.Type, // if business type is changed, redirect to home
                    });
                    updateSessionBusinessContext(data);
                    showSweetAlertToast(
                        t("common.success"),
                        t(`business.activeBusinessChanged`),
                        "success"
                    );
                    changeSessionBusinessReset();
                    changeSessionHQReset();
                }
            }
        } else if (changeSessionBusinessError || changeSessionHQError) {
            showSweetAlertInfo(
                t("common.error.error"),
                t(`business.unableToChangeActiveBusiness`),
                "error"
            );
        }
    }, [
        changingSessionBusiness,
        changeSessionBusinessResponse,
        changeSessionBusinessError,
        changingSessionHQ,
        changeSessionHQResponse,
        changeSessionHQError,
    ]);

    useEffect(() => {
        if (redirectState.redirect) {
            if (redirectState.home) {
                navigate(linkProvider.screens.home(checkPermission));
            } else {
                navigate(linkProvider.business.screens.admin.companies());
            }
            // required for same type sessionBusiness change
            setRedirectState({ home: false, redirect: false });
        }
    }, [linkProvider, redirectState, checkPermission]);

    return (
        <>
            {lwDeleteOpen && (
                <DeleteWithPassword
                    opened={lwDeleteOpen}
                    onClose={() => setLwDeleteOpen(false)}
                    buttonText={t(`deleteLw.deleteAll`)}
                    onSubmit={(password: string) => {
                        deleteLw({
                            password: password,
                            businessId: value.Id,
                            hqId:
                                value.BranchType == CompanyType.Headquarter
                                    ? value.HqId
                                    : null,
                        });
                        showSwalLoader(t(`deleteLw.progressMessage`), "");
                    }}
                />
            )}
            {/* specifying sizes here because it will be rendered in sub-table as well */}
            <td
                onClick={onClick}
                style={getFixedCssWidths(250)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.Name}</span>
            </td>
            <td onClick={onClick} style={getFixedCssWidths(200)}>
                {value.Address.StreetAddress}
            </td>
            <td onClick={onClick} style={getFixedCssWidths(150)}>
                {value.Address.StreetNumber}
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(150, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.Address.PostCode}</span>
            </td>
            <td
                onClick={onClick}
                style={getFixedCssWidths(150, false)}
                className={"ellipsis-text single-line"}
            >
                <span>{value.Address.City}</span>
            </td>
            <td style={{ ...getFixedCssWidths(150), padding: "4px 10px" }}>
                <Button
                    size="sm"
                    variant={
                        isPrimary && !buttonHover
                            ? "success"
                            : "outline-success"
                    }
                    className={cx("w-100 btn-transition", styles.button, {
                        "text-white": isPrimary && !buttonHover,
                    })}
                    // disabled={
                    //     !value.HasActiveContract &&
                    //     value.Type !== BusinessType.LW
                    // }
                    onMouseEnter={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                    onClick={() => {
                        if (!isPrimary) {
                            // update session business
                            if (value.BranchType === CompanyType.Branch) {
                                changeSessionBusiness(value.Id);
                            }
                            if (value.BranchType === CompanyType.Headquarter) {
                                changeSessionHQ(value.HqId);
                            }
                        }
                    }}
                >
                    <>
                        <span className="pr-2">
                            <FontAwesomeIcon icon="cog" />
                        </span>
                        <span className={styles.text}>
                            {t(
                                `common.${
                                    isPrimary || (!isPrimary && buttonHover)
                                        ? "primary"
                                        : "secondary"
                                }`
                            )}
                        </span>
                    </>
                </Button>
            </td>
            <td style={{ ...getFixedCssWidths(51), paddingLeft: "10px" }}>
                <AppDeleteButton
                    onClick={() => {
                        if (value.Type === BusinessType.LW) {
                            setLwDeleteOpen(true);
                        } else {
                            deleteBusiness(value.Id);
                        }
                    }}
                    readonly={!canDelete || deletingLw || deletingBusiness}
                    getConfirmation={true}
                    name={value.Name}
                />
            </td>
        </>
    );
};

export default AdminBusinessBranchRowContent;
