import AppIconWithName from "components/AppIconWithName";
import AppPopover from "components/AppPopover";
import { AppTooltip } from "components/Tooltips";
import { ImageAssets } from "globals/images/common";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { BusinessPerson } from "models/general";
import { sortData, SortOrder } from "models/general/sort";
import moment from "moment";
import { Moment } from "moment-timezone";
import React from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./DashboardPersonInfoPopper.module.scss";

interface DashboardPersonInfoPopperProps {
    mainHeading: string;
    subHeading: string;
    className?: string;
    persons: BusinessPerson[] | undefined;
    duration?: "today" | "currMonth" | "prevMonth" | "currYear"; // to configure date range
    personsDatesInfo?: { [key in number]: Moment[] | null };
}

interface PersonInfoWithToolTipAndCount {
    id: number;
    name: string;
    tooltip: string;
    count: number;
    dates: Moment[];
}
export const DashboardPersonInfoPopper: React.FC<
    DashboardPersonInfoPopperProps
> = ({
    mainHeading,
    subHeading,
    className = "",
    persons,
    duration,
    personsDatesInfo,
}) => {
    const { getDateFormatForLocale } = useLocaleHelpers();
    const todayDate = moment().date();
    const month = moment().month();
    const prevMonth = moment().add(-1, "months").month();
    const year =
        prevMonth === 11 && duration === "prevMonth"
            ? moment().year() - 1 // prevYear
            : moment().year();

    const processedPersonsData: PersonInfoWithToolTipAndCount[] | undefined =
        persons?.map((p) => {
            let obj: PersonInfoWithToolTipAndCount = {
                id: p.Id,
                name: p.Name,
            } as PersonInfoWithToolTipAndCount;
            if (duration && personsDatesInfo) {
                const data = personsDatesInfo[p.Id];
                if (data) {
                    const dates = data.filter((x) => {
                        let isInDuration = x.year() === year;
                        if (duration === "currMonth" || duration === "today") {
                            isInDuration = isInDuration && x.month() === month;
                        }
                        if (duration === "today") {
                            isInDuration =
                                isInDuration && x.date() === todayDate;
                        } else if (duration === "prevMonth") {
                            isInDuration =
                                isInDuration && x.month() === prevMonth;
                        }

                        return isInDuration;
                    });

                    obj = {
                        ...obj,
                        tooltip: dates
                            .map((x) => x.format(getDateFormatForLocale()))
                            .join(", "),
                        count: dates.length,
                        dates: dates,
                    };
                }
            }
            return obj;
        });

    const sortedData = processedPersonsData
        ? sortData(processedPersonsData, [
              { col: "count", dir: SortOrder.DESC },
              { col: "name", dir: SortOrder.ASC },
          ])
        : undefined;
    return (
        <AppPopover
            triggerOnClick={false}
            delay={200}
            trigger={"mouseenter focus"}
            containerClass={`${className} mb-auto`}
            placement={"auto-end"}
            triggerElem={
                <span className={styles.triggerIcon}>
                    <Image src={ImageAssets.common.helpCircleGray} />
                </span>
            }
        >
            <Container className={styles.root}>
                <Row>
                    <Col xs={12}>
                        <div className={styles.title}>
                            <div className={`d-flex ${styles.header}`}>
                                <div className={styles.iconHelp}>
                                    <Image
                                        src={ImageAssets.common.helpCircleBlue}
                                    ></Image>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className={styles.mainHeading}>
                                        {mainHeading}
                                    </span>
                                    <span className={styles.subHeading}>
                                        {subHeading}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.underline}></div>
                    </Col>
                    <Col xs={12}>
                        <div className={`d-flex flex-column ${styles.body}`}>
                            <Table striped className="no-gutters">
                                <tbody>
                                    {sortedData &&
                                        sortedData.map((personData) => (
                                            <tr key={uuid()}>
                                                <td>
                                                    <AppIconWithName
                                                        text={personData.name}
                                                        iconClass={styles.icon}
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "35px",
                                                        minWidth: "35px",
                                                    }}
                                                >
                                                    {personData.count > 0 &&
                                                        duration !==
                                                            "today" && (
                                                            <AppTooltip
                                                                content={
                                                                    personData.tooltip
                                                                }
                                                                allowHtml={
                                                                    false
                                                                }
                                                                trigger="mouseenter focus"
                                                                arrow={true}
                                                            >
                                                                <div
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        personData.count
                                                                    }
                                                                </div>
                                                            </AppTooltip>
                                                        )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AppPopover>
    );
};

export default DashboardPersonInfoPopper;
