import { SearchField } from "components/FormFields";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import {
    Business_RoleManagement_PermissionGroups_Ipad,
    Business_RoleManagement_PermissionGroups_Mobile,
    Business_RoleManagement_PermissionGroups_Web,
    defaultRoleValue,
    getRoleSortColumnKeyFromEnum,
    parseRoleResponse,
    PermissionAccessTypes,
    Role,
    RoleManagementFilter,
    RoleSortColumn,
} from "models";
import { BusinessType, CompanyType } from "models/business/enum";
import {
    AppPlatforms,
    Optional,
    sortData,
    SortOrder,
    TableSort,
} from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
    RoleCreateEditDialog,
    RoleManagementHeader,
    RoleManagementTable,
} from "screens/business/roleManagement/partials";
import {
    getRoleManagementServiceKey,
    RoleManagementService,
} from "services/business/RoleManagementService";
import styles from "./RoleManagement.module.scss";
export interface RoleManagementState {
    editState: Optional<Role>;
    roles: Role[] | undefined;
    openDialog: boolean;
    filter: RoleManagementFilter;
    selectedTab: AppPlatforms;
    sort: TableSort<RoleSortColumn>;
    userRoles: string[];
}

export const RoleManagement: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { getPermissionMap } = useCheckPermission();
    const { linkProvider } = useRouting();
    const {
        id: businessId,
        encodedId: businessEncodedId,
        sessionBusiness,
    } = useSessionBusiness();

    const { businessType, activeHqId, isHq } = useMemo(() => {
        return sessionBusiness
            ? {
                  businessType: sessionBusiness.Type,
                  activeHqId: sessionBusiness.HqId,
                  isHq: sessionBusiness?.BranchType === CompanyType.Headquarter,
              }
            : {
                  businessType: BusinessType.KINDERGARTEN,
                  activeHqId: 0,
                  isHq: false,
              };
    }, [sessionBusiness]);

    const service = new RoleManagementService(
        linkProvider.business.api.roleManagement
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();

    const [state, setState] = useState<RoleManagementState>({
        editState: defaultRoleValue(),
        roles: undefined,
        userRoles: [],
        openDialog: false,
        selectedTab: searchParams.get("tab")
            ? (searchParams.get("tab") as AppPlatforms)
            : AppPlatforms.WEB,
        filter: { Name: "" },
        sort: {
            SortOrder: SortOrder.ASC,
            SortColumn: RoleSortColumn.Name,
        },
    });

    const {
        isFetching: loadingGetRoles,
        data: response,
        refetch: refetchRoles,
        isRefetching: refetching,
    } = useQuery(
        getRoleManagementServiceKey("getRoleList", state.selectedTab),
        async () => await service.getRoleList({ ForDevice: state.selectedTab }),
        {
            cacheTime: 0,
            select: (resp) => {
                if (resp.Data) {
                    return {
                        roles: resp.Data.Roles.map((u) => parseRoleResponse(u)),
                        currUserRoles: resp.Data.ActiveUserRoles,
                    };
                }
                return { roles: [] as Role[], currUserRoles: [] };
            },
        }
    );

    const { roles: rolesResponse, currUserRoles } = defaultTo(response, {
        roles: [] as Role[],
        currUserRoles: [],
    });
    useEffect(() => {
        if (state.roles && rolesResponse) {
            const filterList = rolesResponse.filter((x) =>
                x.Name?.toLocaleLowerCase().includes(
                    state.filter.Name.toLocaleLowerCase()
                )
            );
            setState({
                ...state,
                roles: sortData(filterList, [
                    {
                        col: getRoleSortColumnKeyFromEnum(
                            state.sort.SortColumn
                        ),
                        dir: state.sort.SortOrder,
                    },
                ]) as Role[],
            });
        }
    }, [state.filter.Name]);
    useEffect(() => {
        if (state.roles) {
            setState({
                ...state,
                roles: sortData(state.roles, [
                    {
                        col: getRoleSortColumnKeyFromEnum(
                            state.sort.SortColumn
                        ),
                        dir: state.sort.SortOrder,
                    },
                ]) as Role[],
            });
        }
    }, [state.sort]);

    useEffect(() => {
        if (!loadingGetRoles && !refetching && rolesResponse) {
            const updatedState = {
                ...state,
                roles: rolesResponse,
                userRoles: currUserRoles,
            } as RoleManagementState;
            const isEdit = !isNil(id);
            const isCreate = location.pathname.includes("create");

            if (isEdit || isCreate) {
                updatedState.openDialog = true;
                if (isEdit) {
                    updatedState.editState = rolesResponse.find(
                        (x) => x.Id?.toString() === id
                    );
                }
            }
            setState(updatedState);
        }
    }, [loadingGetRoles]);

    const onDialogOpen = (id?: string) => {
        const baseRoute = linkProvider.business.screens.admin.rolesManagement;
        const toUpdate = {
            ...state,
            openDialog: true,
        };
        if (id) {
            navigate(baseRoute.edit(id));
            toUpdate.editState = state.roles?.find((x) => x.Id == id);
        } else {
            toUpdate.editState = undefined;
            navigate(baseRoute.create());
        }
        setState(toUpdate);
    };

    const onDialogClose = () => {
        navigate({
            pathname:
                linkProvider.business.screens.admin.rolesManagement.list(),
            search: `?tab=${state.selectedTab}`,
        });
        setState({ ...state, openDialog: false, editState: null });
    };
    const onTabChangeHandler = (tab: AppPlatforms) => {
        setState({ ...state, selectedTab: tab });
    };

    const removeItem = (Id: string) => {
        setState({
            ...state,
            roles: state.roles?.filter((x) => x.Id != Id),
        });
    };

    const {
        isLoading: loadingClone,
        data: cloneResponse,
        error: cloneError,
        mutate: cloneRole,
    } = useMutation(
        getRoleManagementServiceKey("clone"),
        async (id: string) => await service.clone(id)
    );

    useEffect(() => {
        if (!loadingClone) {
            if (cloneResponse && cloneResponse.Data) {
                showSweetAlertToast(
                    t("common.success"),
                    `"${cloneResponse.Data.Name}" ${t(
                        "common.actions.clonedSuccessfully"
                    )}`,
                    "success"
                );
                refetchRoles();
            } else if (
                !loadingClone &&
                cloneResponse &&
                cloneResponse.Code != null &&
                cloneResponse.Errors
            ) {
                showSweetAlertToast(
                    t("common.error.error"),
                    cloneResponse.Errors[0].Message,
                    "error"
                );
            } else if (cloneResponse || cloneError) {
                showUnexpectedErrorToast();
            }
        }
    }, [cloneResponse, loadingClone]);

    useEffect(() => {
        setSearchParams(
            { ...searchParams, tab: state.selectedTab },
            { replace: true }
        );
    }, [state.selectedTab]);
    const permissionMap = useMemo(() => {
        let permissionString = "";
        switch (state.selectedTab) {
            case AppPlatforms.MOBILE:
                permissionString =
                    Business_RoleManagement_PermissionGroups_Mobile;
                break;
            case AppPlatforms.IPAD:
                permissionString =
                    Business_RoleManagement_PermissionGroups_Ipad;
                break;
            case AppPlatforms.WEB:
                permissionString = Business_RoleManagement_PermissionGroups_Web;
                break;
        }

        return getPermissionMap(permissionString);
    }, [state.selectedTab]);

    return (
        <>
            <RoleManagementHeader
                onTabChangeHandler={onTabChangeHandler}
                selectedTab={state.selectedTab}
                onCreate={() => onDialogOpen()}
                readonly={!permissionMap[PermissionAccessTypes.CREATE]}
            />
            <div className="d-flex justify-content-end mb-3">
                <div>
                    <SearchField
                        value={state.filter.Name}
                        onValueChange={(value) =>
                            setState({
                                ...state,
                                filter: { ...state.filter, Name: value },
                            })
                        }
                        className={styles.searchField}
                    />
                </div>
            </div>
            <RoleManagementTable
                loading={loadingGetRoles}
                roles={state.roles}
                openDialog={(roleId?: string) => onDialogOpen(roleId)}
                onDelete={removeItem}
                onSortChange={(sort) => {
                    setState({ ...state, sort: sort });
                }}
                sort={state.sort}
                cloneRole={cloneRole}
                deviceType={state.selectedTab}
                permissionMap={permissionMap}
            />
            {state.openDialog && (
                <RoleCreateEditDialog
                    value={state.editState}
                    modalOpen={state.openDialog}
                    onClose={() => onDialogClose()}
                    readonly={
                        (state.editState && !state.editState.IsEditable) ||
                        !isNil(
                            state.userRoles &&
                                state.userRoles.find(
                                    (x) => state.editState?.Id === x
                                )
                        ) ||
                        (state.editState && !permissionMap.EDIT) ||
                        (isNil(state.editState) && !permissionMap.CREATE)
                    }
                    deviceType={state.selectedTab}
                    title={
                        state.editState?.Id
                            ? t("common.edit")
                            : t("common.create")
                    }
                    activeHqId={activeHqId}
                    isHq={isHq}
                    onChange={(val: Role) => {
                        onDialogClose();
                        refetchRoles();
                    }}
                    loading={loadingGetRoles}
                />
            )}
        </>
    );
};

export default RoleManagement;
