import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import i18next from "i18next";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { BusinessEmployeeBenefit } from "./model";

export function validateEmployeeAdditionalBenefit(
    employeeBenefit: BusinessEmployeeBenefit,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof BusinessEmployeeBenefit> = {
        EmployeeId: [
            {
                rule: Validations.NUMBER,
                message: i18next.t(
                    "employeeAdditionalBenefits.createEdit.employee.missing"
                ),
            },
        ],
        Amount: [
            {
                rule: Validations.MIN,
                message: i18next.t("common.errorMessages.positiveNum"),
                options: {
                    value: 0.01,
                },
            },
        ],
        Reason: [
            {
                rule: Validations.REQUIRED,
                message: i18next.t(
                    "employeeAdditionalBenefits.createEdit.reason.missing"
                ),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        employeeBenefit,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}
