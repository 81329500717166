import { AppDialog } from "components/Dialogs";
import { AppInputField } from "components/FormFields";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { BusinessKonto, validateKonto } from "models";

import { Optional, ValidityStateManager } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import {
    BusinessKontoService,
    getBusinessKontoServiceKey,
} from "services/business";

import styles from "./BusinessKontoCreateEditDialog.module.scss";

export interface BusinessKontoCreateEditDialogProps {
    modalOpen: boolean;
    onClose: () => void;
    loading: boolean;
    onChange: (value: BusinessKonto) => void;
    value: Optional<BusinessKonto>;
    readonly?: boolean;
    title: string;
}

export const BusinessKontoCreateEditDialog: React.FC<
    BusinessKontoCreateEditDialogProps
> = ({
    value,
    loading,
    modalOpen,
    onClose,
    onChange,
    title,
    readonly = false,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();
    const service = new BusinessKontoService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).konto
    );
    const isEdit = value && value.Id;

    const {
        data: createEditResponse,
        mutate: createEditKonto,
        error: createEditError,
        isLoading: updating,
    } = useMutation(
        getBusinessKontoServiceKey("createUpdate"),
        async (req: BusinessKonto) => await service.createUpdate(req)
    );

    const [state, setState] = useState<BusinessKonto>(
        isEdit
            ? value
            : ({
                  Id: 0,
                  DisplayId: 0,
                  Name: "",
                  BusinessId: currentBusiness?.Id,
              } as BusinessKonto)
    );
    const validityStateManager = useMemo(() => {
        const validationState = validateKonto(state);
        return new ValidityStateManager(validationState);
    }, [state]);

    useEffect(() => {
        if (!updating) {
            if (createEditError) {
                showUnexpectedErrorToast();
            } else if (createEditResponse) {
                if (createEditResponse.Data) {
                    showSweetAlertToast(
                        t("common.success"),
                        `"${createEditResponse.Data.Name}" ${t(
                            `common.actions.${
                                !isEdit
                                    ? "createdSuccessfully"
                                    : "updatedSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    onChange(createEditResponse.Data);
                } else if (createEditResponse.Code != null) {
                    let message = "Error";
                    if (
                        createEditResponse.Errors &&
                        createEditResponse.Errors.length > 0
                    ) {
                        message = createEditResponse.Errors[0].Message;
                    } else if (createEditResponse.Message) {
                        message = createEditResponse.Message;
                    }
                    showSweetAlertToast(
                        t("common.error.error"),
                        message,
                        "error"
                    );
                }
            }
        }
    }, [createEditResponse, updating]);

    return (
        <AppDialog title={title} onClose={onClose} modalOpen={modalOpen}>
            <Row>
                {isEdit && (
                    <Col xs={3}>
                        <AppInputField
                            label={t("business.kontos.displayId")}
                            placeholder={t("business.kontos.displayId")}
                            disabled={true}
                            value={state.DisplayId}
                            onValueChange={(v?: string) => {}}
                        />
                    </Col>
                )}
                <Col xs={isEdit ? 9 : 12} className="mt-1">
                    <AppInputField
                        label={t("business.kontos.name")}
                        placeholder={t("business.kontos.name")}
                        disabled={readonly}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Name")}
                        value={state.Name}
                        onValueChange={(v?: string) =>
                            setState((o) => ({
                                ...o,
                                Name: defaultTo(v, ""),
                            }))
                        }
                    />
                </Col>
                <Col xs={12} className={styles.footer}>
                    <Button className={styles.cancel} onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <Button
                        className={styles.saveBtn}
                        disabled={
                            loading ||
                            updating ||
                            !validityStateManager.isStateValid() ||
                            readonly
                        }
                        onClick={() => {
                            createEditKonto({ ...state });
                        }}
                    >
                        {t("common.save")}
                    </Button>
                </Col>
            </Row>
        </AppDialog>
    );
};

export default BusinessKontoCreateEditDialog;
