import { useAppContext } from "hoc/providers/AppContextProvider";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { getChannelsList } from "models/chat";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ChatService, getChatServiceKey } from "services/business";
import { Client, ConnectionState, State } from "twilio-chat";

export function useTwilioClient() {
    const { twilioClient, setTwilioClient } = useAppContext();
    const [initializing, setInitializing] = useState(false);
    const { linkProvider } = useRouting();
    const chatService = new ChatService(linkProvider.business.api.chat);

    const { id: businessId } = useSessionBusiness();
    const { id: userId } = useSessionUser();

    const {
        data: token,
        refetch: refetchToken,
        isFetching,
    } = useQuery(
        getChatServiceKey("getToken", { userId, businessId }),
        async () =>
            userId && businessId ? await chatService.getToken(userId) : null
    );

    useEffect(() => {
        if (
            !isFetching &&
            token &&
            token.Data &&
            !initializing &&
            isNil(twilioClient)
        ) {
            const client = new Client(token.Data.Token);
            setInitializing(true);
            client.on("tokenAboutToExpire", () => {
                refetchToken();
            });
            client.on("tokenExpired", () => {
                refetchToken();
            });
            client.on("connectionStateChanged", (e: ConnectionState) => {
                console.debug("conn state", e, new Date());
            });
            client.on("stateChanged", (e: State) => {
                console.debug("client state changed", e, new Date());
                if (e == "initialized") {
                    getChannelsList(
                        twilioClient,
                        businessId,
                        userId as string,
                        null,
                        () => {}
                    );
                    setTwilioClient(client);
                    // console.log("twilio client initialized");
                    setInitializing(false);
                }
            });
        }
    }, [token, isFetching]);

    return { twilioClient, tokenResponse: token && token.Data };
}
