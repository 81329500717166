import { ImageAssets } from "globals/images";
import React from "react";
import { Col, Container, Image, Modal, ModalProps, Row } from "react-bootstrap";
import styles from "./AppDialog.module.scss";
export interface AppDialogProps extends ModalProps {
    children: React.ReactNode;
    modalOpen: boolean;
    onClose: () => void;
    title?: string;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    showCross?: boolean;
}
export const AppDialog: React.FC<AppDialogProps> = ({
    children,
    modalOpen,
    onClose,
    header,
    title,
    footer,
    showCross = true,
    ...rest
}) => {
    return (
        <>
            <Modal
                {...rest}
                backdrop={"static"}
                enforceFocus={false}
                className={`animated slideInRight ${styles.dialogRoot}`}
                animation={true}
                show={modalOpen}
                onHide={onClose}
            >
                <Modal.Body>
                    {showCross && (
                        <Image
                            onClick={onClose}
                            src={ImageAssets.common.crossWhite}
                            className={styles.closeIcon}
                        />
                    )}

                    <Container className={styles.bodyRoot}>
                        <Row>
                            {(header || title) && (
                                <Col xs={12}>
                                    <div className={styles.title}>
                                        {header}
                                        {title}
                                    </div>
                                    <div className={styles.underline} />
                                </Col>
                            )}
                            <Col xs={12}>{children}</Col>
                            <Col xs={12}>{footer}</Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AppDialog;
