import { AppButton, AppIconTextButton } from "components/Buttons";
import { AppTable, AppTableSkeleton } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import {
    BusinessContractData,
    checkPermissionInMap,
    PermissionAccessMap,
    PermissionAccessTypes,
} from "models";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    BusinessContractService,
    getBusinessContractServiceKey,
} from "services/business";
import styles from "./BusinessContractDatasTable.module.scss";
import { COLOR_LIGHT_GREEN, COLOR_LIGHT_RED } from "theme/themeConstants";
import { useNavigate } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";

export interface BusinessContractDatasTableProps {
    value: BusinessContractData[];
    loading: boolean;
    permMap: PermissionAccessMap;
    onChange: () => void;
    canCreate?: boolean;
}

const COLUMNS = 4;
export const BusinessContractDatasTable: React.FC<
    BusinessContractDatasTableProps
> = ({ value, permMap, loading, onChange, canCreate = false }) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { formatDate } = useLocaleHelpers();

    const navigate = useNavigate();
    const contractsService = new BusinessContractService(
        linkProvider.business.api.currentBusiness().contracts
    );

    const { refetchData } = useCurrentBusinessSettingsContext();

    const { mutate: cancelContract, isLoading: cancelling } = useMutation(
        getBusinessContractServiceKey("cancelContract"),
        async () => await contractsService.cancelContract(),
        {
            onSuccess: (data) => {
                if (data && data.Data) {
                    onChange();
                    if (refetchData) {
                        refetchData();
                    }
                }
            },
        }
    );
    const hasEditPermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.EDIT,
    ]);
    const hasCreatePermission = checkPermissionInMap(permMap, [
        PermissionAccessTypes.CREATE,
    ]);

    return (
        <div className={styles.root}>
            {hasCreatePermission && canCreate && (
                <div className={styles.header}>
                    <AppIconTextButton
                        variant="success"
                        padding="default"
                        disabled={loading}
                        icon={"plus"}
                        onClick={() => {
                            navigate(
                                linkProvider.business
                                    .businessSettings()
                                    .screens.renewContract()
                            );
                        }}
                        text={t("business.contracts.renewContract")}
                    />
                </div>
            )}
            <AppTable
                tableClass={"table-striped"}
                containerClass={styles.table}
            >
                <thead>
                    <tr>
                        <th style={{ minWidth: "250px", width: "25%" }}>
                            {t("business.contracts.startDate")}
                        </th>
                        <th style={{ minWidth: "250px", width: "25%" }}>
                            {t("business.contracts.endDate")}
                        </th>
                        <th style={{ minWidth: "250px", width: "25%" }}>
                            {t("business.contracts.cancelledOn")}
                        </th>
                        <th style={{ minWidth: "150px" }} />
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>

                    {value &&
                        value.length > 0 &&
                        value.map((x, idx) => {
                            const isActive =
                                !x.CancelledOn &&
                                moment().isSameOrAfter(x.StartDate, "day") &&
                                (!x.EndDate ||
                                    x.EndDate.isSameOrAfter(moment(), "day"));
                            const isCancelled =
                                x.CancelledOn &&
                                x.CancelledOn.clone()
                                    .endOf("month")
                                    .isBefore(moment(), "day");

                            return (
                                <tr
                                    key={`${x.EncodedId}_${idx}`}
                                    style={
                                        isCancelled || isActive
                                            ? {
                                                  backgroundColor: isActive
                                                      ? COLOR_LIGHT_GREEN
                                                      : COLOR_LIGHT_RED, // cancelled
                                              }
                                            : undefined
                                    }
                                >
                                    <td style={{ paddingLeft: "10px" }}>
                                        {formatDate(x.StartDate)}
                                    </td>
                                    <td>
                                        {x.EndDate
                                            ? formatDate(x.EndDate)
                                            : t("common.endDate.hasNoEndDate")}
                                    </td>
                                    <td>
                                        {x.CancelledOn
                                            ? formatDate(x.CancelledOn)
                                            : isActive
                                            ? t("common.status.Active")
                                            : t("common.status.Expired")}
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                        {isNil(x.CancelledOn) &&
                                            isActive &&
                                            hasEditPermission && (
                                                <AppButton
                                                    variant="danger"
                                                    disabled={cancelling}
                                                    padding="low"
                                                    onClick={() => {
                                                        cancelContract();
                                                    }}
                                                >
                                                    {t(
                                                        "business.contracts.cancelContract"
                                                    )}
                                                </AppButton>
                                            )}
                                    </td>
                                </tr>
                            );
                        })}

                    {loading && <AppTableSkeleton columns={COLUMNS} rows={2} />}

                    {value && value.length == 0 && !loading && (
                        <tr>
                            <td
                                colSpan={COLUMNS}
                                style={{ textAlign: "center" }}
                            >
                                {t("business.contracts.noContractFound")}
                            </td>
                        </tr>
                    )}
                    <tr className="dummy-row">
                        <td colSpan={COLUMNS} />
                    </tr>
                </tbody>
            </AppTable>
        </div>
    );
};

export default BusinessContractDatasTable;
