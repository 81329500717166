export const RoleManagementEnTranslations = {
    roleManagement: {
        constants: {
            Business: {
                Access_Control: "Access Control",
                Archives: "Archives",
                Basic_Data: "Basic Data",
                Settings: "Settings",
                Chat_Settings: "Chat Settings",
                Contract_Data: "Contract Data",
                Contracts: "Contracts",
                Data_Import: {
                    Client: "Children Data",
                    ClientRegistration: "Child Registration",
                    Data_Import: "Data Import",
                    Employee: "Employee",
                },
                Email_Template: "Email Template",
                Functions: "Functions",
                Groups: "Groups",
                Courses: "Courses",
                HqRegisterRequests: "HQ Register Requests",
                CompensationTemplates: "Compensation Templates",
                Ipads: "iPads",
                Kindergarten: "Kindergarten",
                Industry: "Industry",
                Logo: "Logo",
                LW: "Lillywait",
                Meal_Templates: {
                    Basic: "Basic",
                    Meal: "Meal",
                    Meal_Group: "Meal Group",
                    Meal_Templates: "Meal Templates",
                },
                Employee_Key_Calculation: "Employee Key Calculation",
                Age_Groups: "Age Groups",
                Salary_Groups: "Salary Groups",
                Care_Scopes: "Scope of Care",
                Print_Jobs: "Print Jobs",
                Printers: "Printers",
                Public_Holidays: "Public Holidays",
                School: "School",
                Web_Registration: "Web Registration",
                Working_Area: "Working Area",
                Shifts: "Shifts",
                Konto: "Kontos",
                Working_Hours: "Working Hours",
            },
            Business_Document_Manager: {
                Business_Document_Manager: "Business Document Manager",
                Letterhead: "Letterhead",
                Template: "Template",
            },
            Business_Employee: {
                Access_Control: "Access Control",
                App: "App",
                Bank_Data: "Bank Data",
                Basic_Data: "Basic Data",
                Business_Employee: "Employees",
                Contract_Data: "Contract Data",
                Diseases: "Diseases",
                Documents: "Documents",
                Groups: "Groups",
                Shift: "Shift",
                Salary_Statement: "Salary Statement",
                Absences: "Absences",
                Vacations: "Vacations",
            },
            // roleManagement.constants
            Business_General: {
                Calendar: {
                    Basic: "Basic",
                    Calendar: "Calendar",
                    Pivot: "Pivot",
                },
                Creditors: {
                    Creditors: "Creditors",
                    Basic_Data: "Basic Data",
                    Bank_Data: "Bank Data",
                },
                Chat: "Chat",
                ShiftPlan: "Shift Plan",
                ModuleSelect: "Module Select",
                Dashboard: {
                    Client: "Client",
                    Dashboard: "Dashboard",
                    Employee: "Employee",
                },
                General_Kindergarten: "General Kindergarten",
                General_LW: "General Lillywait",
                General_School: "General School",
                General_Industry: "General Industry",
                Kindergarten: "Kindergarten",
                LW: "Lillywait",
                Mailbox_Messages: "Mailbox Messages",
                School: "School",
                //roleManagement.constants.Business_General
                Payroll: {
                    Payroll: "Payroll",
                    Absences: "Absences",
                    AdditionalBenefits: "Additional Benefits",
                },
                TimeTracking: {
                    Clients: "Clients",
                    Employees: "Employees",
                    Suppliers: "Suppliers",
                    // case2: if current heirarchy level is not last, module has child permissions as well
                    // past heirarchy name twice roleManagement.constants.Business_General.TimeTracking
                    TimeTracking: "Time Tracking",
                },
                ToDo: "Todo",
                WebNotification: "Web Notification",
                Downloads: "Downloads",
            },
            Business_Role_Management: {
                Business_Role_Management: "Role Management",
                Permission_Groups: {
                    Ipad: "iPad",
                    Mobile: "Mobile",
                    Permission_Groups: "Permission Groups",
                    Web: "Web",
                },
                User_Roles: {
                    Mobile: "Mobile",
                    User_Roles: "User Roles",
                    Web: "Web",
                },
            },
            General: {
                General: "General",
                General_User_Password_Change: "User Password Change",
                General_User_Profile_Update: "User Profile Update",
            },
            Kindergarten_Client: {
                Access_Control: "Access Control",
                App: "App",
                Bank_Data: "Bank Data",
                Basic_Data: "Basic Data",
                Diseases: "Diseases",
                Documents: "Documents",
                Family: "Family",
                Groups: "Groups",
                Contracts: "Contracts",
                Kindergarten_Client: "Client",
                Medical_Data: "Medical Data",
                Vacations: "Vacations",
                External_Registrations: "Registrations",
                Occupancy_Planing: "Occupancy Planning Report",
                Age_Group_Planing: "Age Group Planning Report",
            },
            LocationSettings: {
                Affiliates: "Affiliates",
                Areas: "Areas",
                Basic_Data: "Basic Data",
                Company_Data: "Company Data",
                Document_Letterheads: "Document Letterheads",
                Document_Templates: "Document Templates",
                Email_Template: "Email Template",
                Explanation_Mode: "Explanation Mode",
                Employee_Key_Calculation: "Employee Key Calculation",
                Age_Groups: "Age Groups",
                Care_Scopes: "Scope of Care",
                Salary_Groups: "Salary Groups",
                Functions: "Functions",
                ModulePlan: "Module Plans",
                CompensationTemplates: "Compensation Templates",
                LocationSettings: "Location Settings",
                Meal_Templates: {
                    Basic: "Basic",
                    Meal: "Meal",
                    Meal_Group: "Meal Group",
                    Meal_Templates: "Meal Templates",
                },
                Public_Holidays: "Public Holidays",
                Vouchers: "Vouchers",
                Working_Days: "Working Days",
            },
            LW_Client: {
                ElderlyCare: {
                    Basic_Data: "Basic Data",
                    Contract_Data: "Contract Data",
                    Settings: "Settings",
                    Contracts: "Contracts",
                    Documents: "Documents",
                    ElderlyCare: "Elderly Care",
                    Public_Holidays: "Public Holidays",
                    Working_Days: "Working Days",
                },
                Kindergarten: {
                    Access_Control: "Access Control",
                    Basic_Data: "Basic Data",
                    Settings: "Settings",
                    Chat_Settings: "Chat Settings",
                    Contract_Data: "Contract Data",
                    Contracts: "Contracts",
                    Documents: "Documents",
                    Employee_Key_Calculation: "Employee Key Calculation",
                    AgeGroups: "Age Groups",
                    CareScopes: "Scope of Care",
                    SalaryGroups: "Salary Groups",
                    Functions: "Functions",
                    Groups: "Groups",
                    Courses: "Courses",
                    CompensationTemplates: "Compensation Templates",
                    Kindergarten: "Kindergarten",
                    Public_Holidays: "Public Holidays",
                    Meal_Templates: "Meal Groups",
                    Web_Registration: "Web Registration",
                    Working_Area: "Working Area",
                    Shifts: "Shifts",
                    Kontos: "Kontos",
                    IPads: "iPads",
                    Working_Days: "Working Days",
                },
                Industry: {
                    Access_Control: "Access Control",
                    Basic_Data: "Basic Data",
                    Settings: "Settings",
                    Chat_Settings: "Chat Settings",
                    Contract_Data: "Contract Data",
                    Contracts: "Contracts",
                    Documents: "Documents",
                    Functions: "Functions",
                    Groups: "Groups",
                    Courses: "Courses",
                    Industry: "Industry",
                    Public_Holidays: "Public Holidays",
                    Web_Registration: "Web Registration",
                    Working_Area: "Working Area",
                    Shifts: "Shifts",
                    Kontos: "Kontos",
                    IPads: "iPads",
                    Working_Days: "Working Days",
                    Employee_Key_Calculation: "Employee Key Calculation",
                    AgeGroups: "Age Groups",
                    CareScopes: "Scope of Care",
                    SalaryGroups: "Salary Groups",
                    CompensationTemplates: "Compensation Templates",
                    Meal_Templates: "Meal Groups",
                },
                LW_Client: "Client",
                School: {
                    Basic_Data: "Basic Data",
                    Contract_Data: "Contract Data",
                    Contracts: "Contracts",
                    Documents: "Documents",
                    Public_Holidays: "Public Holidays",
                    Settings: "Settings",
                    School: "School",
                    Working_Days: "Working Days",
                },
            },
            LW_Support: {
                ElderlyCare: "Elderly Care",
                Kindergartens: "Kindergartens",
                Industry: "Industry",
                LW_Support: "LW Support",
                MergeCompanies: "Merge Companies",
                Schools: "Schools",
            },
            Screens: {
                Calendar_Events: "Calendar Events",
                Chat: "Chat",
                Messages: "Messages",
                Screens: "Screens",
            },
            TimeTracking: {
                Clients: "Clients",
                Employees: "Employees",
                Suppliers: "Suppliers",
                TimeTracking: "Time Tracking",
            },
        },
        permissions: {
            create: "Create",
            delete: "Delete",
            edit: "Edit",
            fieldAccess: "Field Access",
            permissionEdit: "Permission Edit",
            show: "Show",
            tableName: "Table Name",
        },
        role: {
            assignmentSummary: "Assignment Summary",
            assignToUsers: "Assigned to users",
            create: "Create Role",
            createDialogTitle: "Create",
            description: {
                name: "Description",
                placeholder: "Description",
            },
            displayId: "ID",
            editRolePermissions: "Edit role permissions",
            hqBranches: "HQ Branches",
            name: {
                missing: "Name is missing",
                name: "Name",
                placeholder: "Name",
            },
            noUser: "No one",
            role: "Role",
            roleListEmpty: "No role found",
            tab: {
                app: "Mobile",
                ipad: "iPad",
                web: "Web",
            },
            title: "Permissions",
            user: "user",
            users: "users",
        },
        user: {
            active: "Active",
            description: "Description",
            email: {
                missing: "Email is required.",
                name: "Email",
                placeholder: "Email",
            },
            firstName: {
                missing: "First Name is required.",
                name: "First Name",
                placeholder: "First Name",
            },
            lastName: {
                missing: "Last Name is required.",
                name: "Last Name",
                placeholder: "Last Name",
            },
            name: "Name",
            permissionGroup: {
                missing: "Permission Group is required.",
                name: "Permission Group",
                placeholder: "Full access",
            },
            tab: {
                app: "Mobile",
                ipad: "iPad",
                web: "Web",
            },
            title: "Users",
            userListEmpty: "No user found",
        },
    },
};

export default RoleManagementEnTranslations;
