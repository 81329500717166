import { AppCheckbox, AvatarItem } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { Selectable } from "models/general";
import { MessageNavigation, MessageTemplate } from "models/messages";
import moment from "moment";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { MessageService } from "services/business";
import styles from "./MessageItem.module.scss";

export interface MessageItemProps {
    value: Selectable<MessageTemplate>;
    onChange: (template: Selectable<MessageTemplate>) => void;
    activeMenu: MessageNavigation;
    canEdit: boolean;
}

export const MessagesItem: React.FC<MessageItemProps> = ({
    value,
    onChange,
    activeMenu,
    canEdit,
}) => {
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const { getDateTimeFormatForLocale } = useLocaleHelpers();
    const onEdit = (id: string) => {
        navigate(linkProvider.business.screens.mailbox.edit(id));
    };
    const { t } = useTranslation();

    const messageService = new MessageService(
        linkProvider.business.api.message
    );

    const messageInfo =
        activeMenu === MessageNavigation.Sent && value.RecipientsInfo
            ? value.RecipientsInfo
            : value.SenderInfo;

    const {
        data: responseFav,
        isLoading: loadingFav,
        mutate: updateFav,
    } = useMutation(
        async (fav: boolean) =>
            await messageService.updateTemplateFavorite(
                value.Id.toString(),
                fav
            )
    );

    useEffect(() => {
        if (!loadingFav && responseFav) {
            showSweetAlertToast(
                t("common.success"),
                responseFav.Data
                    ? t("message.favorites.markAsFavorites")
                    : t("message.favorites.removedFromFavorite"),
                "success"
            );

            onChange({
                ...value,
                IsFavorite: responseFav.Data,
            });
        }
    }, [responseFav, loadingFav]);

    return (
        value && (
            <div className={styles.itemOuter}>
                <div className={styles.itemLeft}>
                    <div className={styles.checkBox}>
                        <AppCheckbox
                            checked={defaultTo(value.isChecked, false)}
                            onChange={() => {
                                onChange({
                                    ...value,
                                    isChecked: !value.isChecked,
                                });
                            }}
                            disabled={!canEdit}
                        />
                    </div>
                    <div className={styles.iconStar}>
                        <Image
                            onClick={() => {
                                canEdit &&
                                    activeMenu != MessageNavigation.Trash &&
                                    updateFav(!value.IsFavorite);
                            }}
                            className={
                                activeMenu == MessageNavigation.Trash
                                    ? "disabled"
                                    : ""
                            }
                            src={
                                value.IsFavorite &&
                                activeMenu != MessageNavigation.Trash
                                    ? ImageAssets.common.starFill
                                    : ImageAssets.common.star
                            }
                        />
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (value.EncodedId) {
                            onEdit(value.EncodedId);
                        }
                    }}
                    className={styles.content}
                >
                    <AvatarItem
                        value={messageInfo}
                        className={styles.circleImage}
                    />
                    <div className={styles.textDiv}>
                        <span className={styles.senderName}>
                            {messageInfo &&
                                (isNil(messageInfo.Email) &&
                                messageInfo.Name == "MultipleReceivers"
                                    ? t("message.multipleReceivers")
                                    : messageInfo.Name)}
                        </span>
                        <span
                            className={`ellipsis-text ${styles.senderMessage}`}
                        >
                            <p>{value.Body && value.Body.Text}</p>
                        </span>
                    </div>
                </div>
                <div className={styles.itemRight}>
                    <div className={styles.attachments}>
                        {value && value.Files && value.Files.length > 0 && (
                            <Image src={ImageAssets.common.paperClip} />
                        )}
                    </div>
                    <span className={styles.date}>
                        {value.CreatedAt
                            ? moment
                                  .unix(value.CreatedAt)
                                  .format(getDateTimeFormatForLocale())
                            : ""}
                    </span>
                </div>
            </div>
        )
    );
};

export default MessagesItem;
