import { RoleSortColumn } from "./enum";
import { Role } from "./model";

export function defaultRoleValue() {
    return {
        Name: "",
        Description: "",
        IsEditable: true,
    } as Role;
}

export const getRoleSortColumnKeyFromEnum = (
    sortColumn: RoleSortColumn | undefined
): keyof Role => {
    switch (sortColumn) {
        case RoleSortColumn.CreatedAt:
            return "CreatedAt";
        case RoleSortColumn.Name:
            return "Name";
        default:
            return "Name";
    }
};
