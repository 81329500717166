import { BankDataSortColumn } from "./enum";
import { BankData } from "./model";

export const getBankDataSortKeyFromEnum = (
    sortColumn: BankDataSortColumn | undefined
): keyof BankData | string => {
    switch (sortColumn) {
        case BankDataSortColumn.AccountNumber:
            return "AccountNumber";
        case BankDataSortColumn.AccountOwner:
            return "AccountOwner";
        case BankDataSortColumn.BIC:
            return "BIC";
        case BankDataSortColumn.BankCode:
            return "BankCode";
        case BankDataSortColumn.BankName:
            return "BankName";
        case BankDataSortColumn.IBAN:
            return "IBAN";
        default:
            return "IBAN";
    }
};
