import { dashboardImages } from "globals/images";
import {
    DashboardSection as DashboardSectionModel,
    DashboardSections,
    DashboardTabs,
} from "models";
import { BusinessPerson } from "models/general";
import { Moment } from "moment-timezone";
import React from "react";
import { Card, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import styles from "./DashboardSection.module.scss";
import DashboardSectionCol from "./DashboardSectionCol";
interface DashboardSectionProps {
    sections: DashboardSectionModel[];
    sectionType: DashboardSections;
    persons?: BusinessPerson[];
    selectedTab: DashboardTabs;
    personsDatesInfo?: { [key in number]: Moment[] | null };
}
const Component: React.FC<DashboardSectionProps> = ({
    sectionType,
    sections,
    persons,
    selectedTab,
    personsDatesInfo,
}) => {
    const { t } = useTranslation();
    const imageUrl = () => {
        const icons = dashboardImages;
        switch (sectionType) {
            case DashboardSections.DISEASES:
                return icons.diseases;
            case DashboardSections.VACATION:
                return icons.vacations;
            case DashboardSections.MISSING:
                return icons.missing;
        }
    };

    return (
        <>
            <Card>
                <Card.Body style={{ paddingRight: "25px" }}>
                    <div className={styles.header}>
                        <div>
                            <Image src={imageUrl()} />
                        </div>
                        <div>{t(`common.${sectionType.toString()}`)}</div>
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                    <Row>
                        {sections.map((section) => (
                            <DashboardSectionCol
                                headingTitle={t(
                                    `common.${sectionType.toString()}`
                                )}
                                key={uuid()}
                                section={section}
                                sectionType={sectionType}
                                persons={persons}
                                selectedTab={selectedTab}
                                personsDatesInfo={personsDatesInfo}
                            />
                        ))}
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

Component.displayName = "DashboardSection";
export const DashboardSection = React.memo(Component);
export default DashboardSection;
