import Tippy, { TippyProps } from "@tippyjs/react";
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/themes/light.css";
import styles from "./AppPopover.module.scss";

export interface AppPopoverProps extends TippyProps {
    onClick?: () => void;
    triggerElem: ReactNode;
    menuOpen?: boolean | null;
    containerClass?: string;
    triggerOnClick?: boolean;
}

export const AppPopover: React.FC<AppPopoverProps> = ({
    onClick = () => {},
    triggerElem,
    menuOpen,
    children,
    arrow = true,
    containerClass = "",
    className = "",
    triggerOnClick = true,
    appendTo = document.body,
    ...rest
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const containerRef = useRef(null);
    useEffect(() => {
        if (menuOpen != null) {
            setOpen(menuOpen);
        }
    }, [menuOpen]);
    const toggleMenu = useCallback(() => {
        setOpen((old) => !old);
    }, [open, setOpen]);

    return (
        <div
            ref={containerRef}
            className={`${styles.containerClass} ${containerClass} app-popover`}
        >
            <Tippy
                theme={"light"}
                content={<div className={styles.cardRoot}>{children}</div>}
                onClickOutside={() => setOpen(false)}
                visible={triggerOnClick ? open : undefined}
                allowHTML={true}
                arrow={arrow}
                appendTo={appendTo}
                maxWidth={500}
                {...rest}
                className={`${className} ${styles.menuCard}`}
                interactive={true}
                animation={"scale"}
            >
                <div
                    className={styles.trigger}
                    onClick={(e) => {
                        if (triggerOnClick) {
                            //Prevent the parent click
                            e.stopPropagation();
                            toggleMenu();
                            onClick();
                        }
                    }}
                >
                    {triggerElem}
                </div>
            </Tippy>
        </div>
    );
};

export default AppPopover;
