import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import styles from "commonPartials/commonStyles.module.scss";
import { useCheckPermission } from "hooks/permissionCheck";
import { Business_Employee_ContractData } from "models";
import { useRouting } from "hooks/general/routing";
import { useNavigate } from "react-router";
import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import {
    getFilteredArray,
    getFixedCssWidths,
} from "globals/helpers/generalHelper";
import {
    EmployeeContract,
    EmployeeContractFilters,
    EmployeeContractSortColumn,
    getEmployeeContractSortColumnKeyFromEnum,
    getParsedEmployeeContract,
} from "models/employeeContract";
import { SortOrder, TableSort } from "models/general";
import { useEffect, useMemo, useState } from "react";
import { defaultTo, isNil, orderBy } from "lodash-es";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import BusinessEmployeeContractService, {
    getBusinessEmployeeContractServiceKey,
} from "services/business/BusinessEmployeeContractService";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import {
    EmployeeContractFilterRow,
    EmployeeContractRow,
} from "screens/business/employees/tabs/employeeContracts/partials";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment";

interface EmployeeContractDataState {
    filter: EmployeeContractFilters;
    sort: TableSort<EmployeeContractSortColumn>;
    list: EmployeeContract[];
}

export const EMPLOYEE_CONTRACT_START_DATE_PARAM = "startDate";
const COLUMNS_COUNT = 10;
export const EmployeeContractList: React.FC = () => {
    const { getPermissionMap } = useCheckPermission();
    const navigate = useNavigate();
    const { linkProvider } = useRouting();
    const { t } = useTranslation();

    const permMap = getPermissionMap(Business_Employee_ContractData);

    const linkProviderBase = linkProvider.business
        .employees()
        .screens.withId().contract;

    const contractService = new BusinessEmployeeContractService(
        linkProvider.business.employees().api.withId().employeeContract
    );
    const [state, setState] = useState<EmployeeContractDataState>({
        filter: {} as EmployeeContractFilters,
        sort: {
            SortColumn: EmployeeContractSortColumn.ContractStart,
            SortOrder: SortOrder.ASC,
        },
        list: [],
    });

    const {
        isFetching: loading,
        isRefetching: refetching,
        refetch,
        data: listResp,
    } = useQuery(
        getBusinessEmployeeContractServiceKey("list", {
            ...state.filter,
        }),
        async () => await contractService.list(),
        {
            enabled: false,
            select: (resp) => {
                if (resp.Data) {
                    const newList = resp.Data.map((x) =>
                        getParsedEmployeeContract(x)
                    );
                    return newList;
                }
                return state.list;
            },
        }
    );

    useEffect(() => {
        refetch();
    }, [linkProvider]);

    useEffect(() => {
        if (!loading && listResp) {
            setState({
                ...state,
                list: listResp,
            });
        }
    }, [loading, refetching]);

    const onSortChange = (
        sortColumn: EmployeeContractSortColumn,
        sortOrder: SortOrder
    ) => {
        setState({
            ...state,
            sort: {
                ...state.sort,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };

    const list = useMemo(() => {
        const filteredList = getFilteredArray(state.list, state.filter);

        let orderList = filteredList;
        orderList = orderBy(
            orderList,
            getEmployeeContractSortColumnKeyFromEnum(state.sort.SortColumn),
            state.sort.SortOrder == SortOrder.ASC ? "asc" : "desc"
        );
        return orderList;
    }, [state]);

    const { disableCreate, nextContractDate } = useMemo(() => {
        const disableCreate = state.list?.some((x) => {
            return isNil(x.ContractEnd);
        });

        const resp = defaultTo(state.list, [])?.sort((a, b) =>
            !isNil(b.ContractEnd) && !isNil(a.ContractEnd)
                ? a.ContractEnd < b.ContractEnd
                    ? 1
                    : -1
                : -1
        );

        const nextContractDate =
            resp.length > 0 ? resp[0].ContractEnd?.clone().add(1, "d") : null;

        return {
            disableCreate,
            nextContractDate,
        };
    }, [state.list]);
    const { mutate: deleteContract } = useMutation(
        async (id?: number) => await contractService.delete(id ? id : 0),
        {
            onSuccess: (res) => {
                showSweetAlertToast(
                    t("common.success"),
                    `${t("employee.contract.employeeContract")} ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
                setState((old) => ({
                    ...old,
                    list: old.list.filter((x) => x.Id != res.Data),
                }));
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    return (
        <div>
            <AppContainer
                classes={{ body: styles.appContainerWithLessTopPadding }}
            >
                {permMap.CREATE && (
                    <AppIconTextButton
                        padding="med"
                        className="mb-2"
                        icon="plus"
                        disabled={disableCreate}
                        text={t("common.create")}
                        onClick={() => {
                            navigate({
                                pathname: linkProviderBase.create(),
                                search: isNil(nextContractDate)
                                    ? undefined
                                    : `${EMPLOYEE_CONTRACT_START_DATE_PARAM}=${nextContractDate?.format(
                                          ISO8601_DATE_FORMAT
                                      )}`,
                            });
                        }}
                    />
                )}

                <div>
                    <AppTable
                        heightToAdjust={340}
                        mediumViewAdjustment={20}
                        mobileViewAdjustment={20}
                        hover={true}
                    >
                        <thead>
                            <tr>
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t("employee.contract.contractStart")}
                                    sortColumn={
                                        EmployeeContractSortColumn.ContractStart
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.ContractStart
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t("employee.contract.contractEnd")}
                                    sortColumn={
                                        EmployeeContractSortColumn.ContractEnd
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.ContractEnd
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t(
                                        "employee.contract.monthsTrialPeriod"
                                    )}
                                    sortColumn={
                                        EmployeeContractSortColumn.MonthsTrialPeriod
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.MonthsTrialPeriod
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t(
                                        "employee.contract.endOfTrialPeriod"
                                    )}
                                    sortColumn={
                                        EmployeeContractSortColumn.EndOfTrialPeriod
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.EndOfTrialPeriod
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(200)}
                                    text={t(
                                        "employee.contract.monthsExpiration"
                                    )}
                                    sortColumn={
                                        EmployeeContractSortColumn.MonthsExpiration
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.MonthsExpiration
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(230)}
                                    text={t("employee.contract.expirationDate")}
                                    sortColumn={
                                        EmployeeContractSortColumn.ExpirationDate
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.ExpirationDate
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(230)}
                                    text={t(
                                        "employee.contract.realWeeklyHours"
                                    )}
                                    sortColumn={
                                        EmployeeContractSortColumn.RealWeeklyHours
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.RealWeeklyHours
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t(
                                        "employee.contract.workingDaysAWeek"
                                    )}
                                    sortColumn={
                                        EmployeeContractSortColumn.WorkingDaysAWeek
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.WorkingDaysAWeek
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <AppTableSortColumnIcons
                                    style={getFixedCssWidths(250)}
                                    text={t("employee.contract.pauseInMinutes")}
                                    sortColumn={
                                        EmployeeContractSortColumn.PauseInMinutes
                                    }
                                    sortOrder={
                                        state.sort.SortColumn ===
                                        EmployeeContractSortColumn.PauseInMinutes
                                            ? state.sort.SortOrder
                                            : undefined
                                    }
                                    onClick={onSortChange}
                                />
                                <td style={getFixedCssWidths(60)}></td>
                            </tr>
                        </thead>
                        <tbody>
                            <EmployeeContractFilterRow
                                value={state.filter}
                                onChange={(filter) =>
                                    setState({ ...state, filter: filter })
                                }
                            />

                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                            {list?.length > 0 ? (
                                list.map((b, idx) => (
                                    <EmployeeContractRow
                                        key={`${idx}_${b.Id}`}
                                        value={b}
                                        permMap={permMap}
                                        onDeleteClick={(id) =>
                                            deleteContract(id)
                                        }
                                        onClick={(id) => {
                                            navigate({
                                                pathname:
                                                    linkProviderBase.edit(id),
                                                // search: isNil(nextContractDate)
                                                //     ? undefined
                                                //     : `${EMPLOYEE_CONTRACT_START_DATE_PARAM}=${nextContractDate?.format(
                                                //           ISO8601_DATE_FORMAT
                                                //       )}`,
                                            });
                                        }}
                                    />
                                ))
                            ) : loading ? (
                                <AppTableSkeleton
                                    columns={COLUMNS_COUNT}
                                    rows={10}
                                />
                            ) : (
                                <tr>
                                    <td
                                        colSpan={COLUMNS_COUNT}
                                        style={{ textAlign: "center" }}
                                    >
                                        {t("common.noDataFound")}
                                    </td>
                                </tr>
                            )}
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                        </tbody>
                    </AppTable>
                </div>
            </AppContainer>
        </div>
    );
};
export default EmployeeContractList;
