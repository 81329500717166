import { DatePickerType } from "models/datepicker";
import moment from "moment";
import React from "react";
import {
    AppReactDatePickerBase,
    AppReactDatePickerBaseProps,
} from "./partials";

export const AppDateMonthPicker: React.FC<
    Omit<AppReactDatePickerBaseProps, "datePickerType"> & {
        restrictYear?: boolean;
    }
> = ({ shouldCloseOnSelect = true, restrictYear = true, ...rest }) => {
    return (
        <AppReactDatePickerBase
            maxDate={restrictYear ? moment().endOf("y") : undefined}
            {...rest}
            datePickerType={DatePickerType.DATE_MONTH}
            // showMonthDropdown
            showYearPicker={false}
            shouldCloseOnSelect={shouldCloseOnSelect}
        />
    );
};

export default AppDateMonthPicker;
