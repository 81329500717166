import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment-timezone";
import { EmployeeBenefitSortColumn } from "./enum";
import {
    BusinessEmployeeBenefit,
    EmployeeWithAdditionalBenefits,
} from "./model";
import { BusinessEmployeeBenefitResponse } from "./response";

export const getBusinessEmployeeBenefitFromResponse = (
    req: BusinessEmployeeBenefitResponse
): BusinessEmployeeBenefit => {
    return {
        ...req,
        StartDate: moment(req.StartDate, ISO8601_DATE_FORMAT),
        EndDate: req.EndDate
            ? moment(req.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
        CreatedAt: moment.unix(req.CreatedAt),
    };
};

export const getEmployeeBenefitSortColumnKeyFromEnum = (
    sortColumn: EmployeeBenefitSortColumn | undefined
) => {
    switch (sortColumn) {
        case EmployeeBenefitSortColumn.DisplayId:
            return "Employee.DisplayId";
        case EmployeeBenefitSortColumn.EmployeeName:
            return "Employee.Name";
        case EmployeeBenefitSortColumn.StartDate:
            return "StartDate";
        case EmployeeBenefitSortColumn.EndDate:
            return "EndDate";
        case EmployeeBenefitSortColumn.Reason:
            return "Reason";
        case EmployeeBenefitSortColumn.Status:
            return "Status";
        case EmployeeBenefitSortColumn.Amount:
            return "Amount";
        default:
            return "StartDate";
    }
};

export const getDefaultBenefitValue = (
    userId: string
): BusinessEmployeeBenefit => {
    return {
        Id: undefined,
        StartDate: moment(),
        CreatedByUserId: userId,
        Reason: "",
        Amount: 0,
    } as BusinessEmployeeBenefit;
};
