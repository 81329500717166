import { AppIconTextButton } from "components/Buttons";
import { AppContainer } from "components/Containers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { isNil, orderBy } from "lodash-es";
import {
    BusinessKonto,
    // Business_Kontos,
    getKontoSortColumnKeyFromEnum,
    KontoSortColumn,
    PermissionAccessTypes,
} from "models";
import { Optional, SortOrder, TableSort } from "models/general";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import {
    BusinessKontoService,
    getBusinessKontoServiceKey,
} from "services/business";
import { BusinessKontoCreateEditDialog, BusinessKontoTable } from "./partials";
import styles from "commonPartials/commonStyles.module.scss";

interface BusinessKontoListState {
    list: BusinessKonto[];
    sort: TableSort<KontoSortColumn>;
    editState: Optional<BusinessKonto>;
    openDialog: boolean;
}

const sortList = (list: BusinessKonto[], sort: TableSort<KontoSortColumn>) => {
    return orderBy(
        list,
        getKontoSortColumnKeyFromEnum(sort.SortColumn),
        sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
    );
};
export const BusinessKontoList: React.FC = () => {
    const { linkProvider } = useRouting();

    const { lwcId } = useParams();
    const { t } = useTranslation();
    const { checkPermission } = useBusinessCheckPermission();
    const [state, setState] = useState<BusinessKontoListState>({
        list: [],
        editState: null,
        openDialog: false,
        sort: {
            SortColumn: KontoSortColumn.DisplayId,
            SortOrder: SortOrder.ASC,
        },
    });

    // both(useCurrentBusinessSettingsContext and useCurrentLwcContext)
    // return same context as context type is same
    const { dataItem: currentBusiness } = useCurrentBusinessSettingsContext();

    const kontoService = new BusinessKontoService(
        linkProvider.business.api.currentBusiness(
            currentBusiness?.EncodedId
        ).konto
    );

    const {
        isLoading,
        isRefetching,
        data: listRes,
    } = useQuery(
        getBusinessKontoServiceKey("list", {
            businessId: currentBusiness?.Id,
        }),
        async () => await kontoService.list()
    );

    useEffect(() => {
        if (!isLoading && !isRefetching && listRes?.Data) {
            setState({
                ...state,
                list: sortList(listRes.Data, state.sort),
            });
        }
    }, [isLoading, isRefetching]);

    const isLwc = !isNil(lwcId);

    const { mutate: deleteKonto, isLoading: deleting } = useMutation(
        async (id: string) => await kontoService.delete(id),
        {
            onSuccess: (deleteRes) => {
                if (deleteRes?.Data) {
                    const toDelete = state.list.filter(
                        (x) => x.Id == deleteRes.Data
                    )[0];

                    showSweetAlertToast(
                        t("common.success"),
                        `${toDelete.Name} ${t(
                            "common.actions.updatedSuccessfully"
                        )}`,
                        "success"
                    );

                    setState((old) => ({
                        ...old,
                        list: old.list.filter((x) => x.Id != deleteRes.Data),
                    }));
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const hasEditPermission = true;
    const hasDeletePermission = true;
    // const hasDeletePermission = checkPermission(
    //     currentBusiness?.Id as number,
    //     currentBusiness?.HqId as number,
    //     [isLwc?LW_Kindergarten_Kontos:Business_Kontos],
    //     [PermissionAccessTypes.DELETE]
    // );
    // const hasEditPermission = checkPermission(
    //     currentBusiness?.Id as number,
    //     currentBusiness?.HqId as number,
    //     [isLwc?LW_Kindergarten_Kontos:Business_Kontos],
    //     [PermissionAccessTypes.EDIT]
    // );

    const onSortChange = (sort: TableSort<KontoSortColumn>) => {
        setState({
            ...state,
            sort: sort,
            list: sortList(state.list, sort),
        });
    };

    const onDialogOpen = (id?: string) => {
        const toUpdate = {
            ...state,
            openDialog: true,
        };
        if (id) {
            toUpdate.editState = state.list?.find((x) => x.EncodedId == id);
        }

        setState(toUpdate);
    };
    const onClose = () =>
        setState({ ...state, openDialog: false, editState: null });
    return (
        <AppContainer
            classes={
                !isLwc
                    ? { body: styles.appContainerWithLessTopPadding }
                    : undefined
            }
        >
            {state.openDialog && (
                <BusinessKontoCreateEditDialog
                    value={state.editState}
                    loading={isLoading}
                    modalOpen={state.openDialog}
                    onClose={onClose}
                    title={
                        state.editState && state.editState.Id
                            ? t("business.kontos.editKonto")
                            : t("business.kontos.createKonto")
                    }
                    onChange={(val: BusinessKonto) => {
                        setState({
                            ...state,
                            openDialog: false,
                            editState: null,
                            list: sortList(
                                isNil(state.editState)
                                    ? [...state.list, val]
                                    : state.list.map((k) => {
                                          if (k.Id == val.Id) {
                                              return val;
                                          } else {
                                              return k;
                                          }
                                      }),
                                state.sort
                            ),
                        });
                    }}
                    readonly={!hasEditPermission}
                />
            )}
            {!isLwc && (
                <AppIconTextButton
                    padding="med"
                    className="mb-2"
                    icon="plus"
                    text={t("common.addNew")}
                    onClick={() => {
                        onDialogOpen();
                    }}
                />
            )}
            <BusinessKontoTable
                list={state.list}
                sort={state.sort}
                onClick={(id) => {
                    onDialogOpen(id);
                }}
                onSortChange={onSortChange}
                onDeleteBtnClick={(id) => deleteKonto(id)}
                loading={isLoading || isRefetching}
                hasDeletePermission={hasDeletePermission || deleting}
            />
        </AppContainer>
    );
};

export default BusinessKontoList;
