import ErrorMessage from "components/ErrorMessage";
import TinymceEditor from "components/TinymceEditor";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaults } from "lodash-es";
import { EditableSection } from "models";
import {
    Templatable,
    ValidityState,
    ValidityStateManager,
} from "models/general";
import React from "react";
import { Col, Form } from "react-bootstrap";
import styles from "./EditableSectionRow.module.scss";

export interface EditableSectionRowProps {
    value: EditableSection;
    onChange: (field: EditableSection) => void;
    validityState?: ValidityState;
    readonly?: boolean;
}

export const EditableSectionRow: React.FC<EditableSectionRowProps> = ({
    value,
    onChange,
    validityState,
    readonly = false,
}) => {
    const { appLocale: locale } = useLocaleHelpers();

    const validityStateManager = validityState
        ? new ValidityStateManager(validityState)
        : undefined;

    return (
        <div className={styles.root}>
            <div className={styles.fieldRowRoot}>
                <div className={styles.rowContent}>
                    <Form.Row>
                        <React.Fragment>
                            <Col md={12}>
                                <Form.Group controlId={value.Uuid}>
                                    <Form.Label className={styles.label}>
                                        {value.Name}
                                    </Form.Label>
                                    <TinymceEditor
                                        value={defaults(
                                            value.Data && {
                                                placeholders:
                                                    value.Data.Placeholders,
                                                html: value.Data.Template,
                                            },
                                            {
                                                placeholders: [],
                                                html: "",
                                            }
                                        )}
                                        dynamicPlaceholderProps={[]}
                                        allowedPlaceholderTypes={[]}
                                        tinymceProps={{
                                            language: locale,
                                            readonly: readonly,
                                            plugins: [
                                                "link",
                                                "lists",
                                                "autolink",
                                                "textpattern",
                                            ],
                                            toolbar: false,
                                            menubar: "",
                                        }}
                                        onChange={(newState) => {
                                            onChange({
                                                ...value,
                                                Data: {
                                                    ...value.Data,
                                                    Placeholders:
                                                        newState.placeholders,
                                                    Template: newState.html,
                                                } as Templatable,
                                            });
                                        }}
                                    />
                                    <ErrorMessage
                                        errorInfo={validityStateManager?.getFirstErrorInfo(
                                            "Template"
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </React.Fragment>
                    </Form.Row>
                </div>
            </div>
        </div>
    );
};

export default EditableSectionRow;
