import classNames from "classnames";
import {
    handleMenuToggle,
    MetisMenuClassStore,
    MetisMenuItemType,
    MetisMenuProps,
    processMenuItems,
    updateActiveLink,
} from "models/metisMenu";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MetisMenuContainer } from "./partials";

export const MetisMenu: React.FC<MetisMenuProps> = ({
    showActiveLink = true,
    content,
    ...props
}) => {
    const location = useLocation();
    const classStore = MetisMenuClassStore({ ...props }); // get all classes to  use

    const [contents, setContents] = useState<MetisMenuItemType[]>([]);
    useEffect(() => {
        if (content.length > 0) {
            const currentLink = showActiveLink ? location.pathname : "";

            const convertedList = content.map((x, i) => {
                return processMenuItems(x, i);
            });
            setContents(updateActiveLink(convertedList, currentLink));
        }
    }, [content]);

    const toggleMenu = (id: number) => {
        setContents((old) => handleMenuToggle(old, id));
    };
    useEffect(() => {
        if (showActiveLink) {
            const list = updateActiveLink(contents, location.pathname);
            setContents([...list]);
        }
    }, [location.pathname, showActiveLink]);

    return (
        <div className={classNames(classStore.className)}>
            <div
                className={
                    classStore.classMainWrapper
                        ? classStore.classMainWrapper
                        : ""
                }
            >
                <MetisMenuContainer
                    items={contents}
                    toggleMenu={toggleMenu}
                    classStore={classStore}
                    visible={false}
                />
            </div>
        </div>
    );
};

export default MetisMenu;
