import { AppButton } from "components/Buttons";
import { EmojiPicker } from "components/FormFields";
import { ImageAssets } from "globals/images";
import { useAutoresizeTextArea } from "hooks/general/reactHelpers";
import { defaultTo, isNil } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Form, FormControl, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import styles from "./ChatMessageField.module.scss";
interface ChatMessageFieldProps {
    onMessageChange: (text: string) => void;
    text: string;
    btnLoading: boolean;
    onClick: () => void;
    isEdit: boolean;
    onEdit: () => void;
    onEditCancel: () => void;
    onFileUpload: (file: File) => void;
    disabled?: boolean;
}
function countOccurrences(str: string, value: string | RegExp): number {
    const regExp = new RegExp(value, "gi");
    return (str.match(regExp) || []).length;
}
const MAX_HEIGHT = 170;
const LINE_HEIGHT = 24;
export const ChatMessageField: React.FC<ChatMessageFieldProps> = ({
    onMessageChange,
    text,
    btnLoading,
    onEditCancel,
    onClick,
    isEdit,
    onEdit,
    onFileUpload,
    disabled,
}) => {
    const fileRef = React.useRef<any>(null);
    const entersCountRef = React.useRef<number>(0);
    const [fieldHeight, setFieldHeight] = useState<number>(28);
    const textareaRef = React.useRef<any>(null);
    const onFileClickHandler = () => {
        fileRef.current.click();
    };
    const { t } = useTranslation();

    const onFileChangeHandler = () => {
        if (fileRef.current && fileRef.current.files.length > 0) {
            onFileUpload(fileRef.current.files[0]);
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            if (textareaRef.current.scrollHeight <= MAX_HEIGHT) {
                // We need to reset the height momentarily to get the correct scrollHeight for the textarea
                // assuming each character takes 6px (because text can wrap without enter and on delete we need to reduce height)
                const maxCharPerLine =
                    defaultTo($(textareaRef.current).width(), 0) / 6;
                const entersInStr =
                    countOccurrences(textareaRef.current.value, /\n/g) +
                    Math.ceil(
                        textareaRef.current.value.length / maxCharPerLine
                    );

                let newH = Math.min(
                    textareaRef.current.scrollHeight,
                    MAX_HEIGHT
                );

                if (
                    newH < fieldHeight ||
                    entersInStr < entersCountRef.current
                ) {
                    // if new height is less then old height or there are no more enters
                    textareaRef.current.style.height = `${LINE_HEIGHT + 2}px`;
                    newH = Math.min(
                        textareaRef.current.scrollHeight,
                        MAX_HEIGHT
                    );
                }

                entersCountRef.current = entersInStr;
                if (newH != fieldHeight) {
                    textareaRef.current.style.height = `${newH}px !important`;
                    setFieldHeight(newH);
                }
            }
        }
    }, [textareaRef.current, text]);

    const loading = btnLoading || disabled;
    const blockSend = loading || text.trim().length == 0;

    return (
        <div className={styles.root}>
            <div className={styles.textSection}>
                {isEdit && (
                    <div className={styles.editCannel}>
                        <Image
                            onClick={onEditCancel}
                            className={styles.icon}
                            src={ImageAssets.common.crossBlue}
                        />
                    </div>
                )}
                <div className={styles.messageFieldDiv}>
                    <textarea
                        className={styles.messageTextField}
                        value={text}
                        onChange={(e) => {
                            if (!loading) {
                                onMessageChange(e.target.value);
                            }
                        }}
                        ref={textareaRef}
                        style={{
                            resize: "none",
                            height: `${fieldHeight}px`,
                        }}
                        rows={1} //Math.min(Math.ceil(fieldHeight / LINE_HEIGHT), 1)
                        onKeyDown={(e: any) => {
                            if (
                                e.keyCode == 13 &&
                                !e.shiftKey &&
                                (!blockSend || text.length == 0)
                            ) {
                                e.preventDefault();
                                e.stopPropagation();
                                if (isEdit) {
                                    onEdit();
                                } else {
                                    onClick();
                                }
                                return false;
                            } else if (loading) {
                                return false;
                            }
                        }}
                        placeholder={t("chat.typeYourMessage")}
                    />
                </div>
                <div className={styles.iconsSection}>
                    <div className={styles.uploadIcon}>
                        <Form.File
                            ref={fileRef}
                            id="exampleFormControlFile2"
                            label="Example file input"
                            style={{ display: "none" }}
                            name="file"
                            multiple={false}
                            onChange={onFileChangeHandler}
                        />
                        <Image
                            className={styles.icon}
                            style={{
                                cursor: !disabled ? "pointer" : "default",
                            }}
                            onClick={() => !disabled && onFileClickHandler()}
                            src={ImageAssets.common.paperClip}
                        />
                    </div>
                    <div className={styles.emojiIcon}>
                        <EmojiPicker
                            trigger={
                                <Image
                                    className={styles.icon}
                                    src={ImageAssets.common.smiley}
                                />
                            }
                            onChange={(updatedVal: string) => {
                                onMessageChange(updatedVal);
                            }}
                            value={text}
                        />
                    </div>
                </div>
                <AppButton
                    rounded={true}
                    className={styles.sendButton}
                    onClick={isEdit ? onEdit : onClick}
                    disabled={blockSend}
                >
                    <>
                        {isEdit ? t("common.edit") : t("chat.send")}
                        <Image
                            className={styles.icon}
                            src={ImageAssets.common.send}
                        />
                    </>
                </AppButton>
            </div>
        </div>
    );
};

export default ChatMessageField;
