import { AppIconTextButton, AppMenuButton } from "components/Buttons";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash";
import commonStyles from "commonPartials/commonStyles.module.scss";
import styles from "./LocationDocumentTemplates.module.scss";
import { isNil, orderBy } from "lodash-es";
import {
    DocumentTemplateFilter,
    DocumentTemplateManagerItem,
    DocumentTemplateRequest,
    DocumentTemplateSortColumn,
    DocumentTemplateTabType,
    parseDocumentTemplateResponse,
} from "models/documentTemplates";
import {
    CustomColorItemOption,
    ResponseMeta,
    SortOrder,
    TableSort,
    UpdateDisplayIdResponseAndRequest,
} from "models/general";
import {
    LocationSettings_DocumentTemplates,
    PermissionAccessTypes,
} from "models/permissionManagement";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DocumentTemplatesList from "./DocumentTemplatesList";
import { DocumentTemplateSearchSection } from "commonPartials/documentTemplates";
import { AppContainer } from "components/Containers";
import {
    LocationDocumentTemplateService,
    getLocationDocumentTemplateServiceKey,
} from "services/lillywait/locationSettings";
import {
    CloneLocationDocumentTemplatesDialog,
    CloneLWCDocumentTemplatesDialog,
} from "screens/lillywait/locationSettings/tabs/documentTemplate/partials";
import { useCurrentLocationSettingsContext } from "screens/lillywait/locationSettings/tabs/LocationSettingTabs";

interface LocationDocumentTemplatesState {
    selectedTab: DocumentTemplateTabType;
    filter: DocumentTemplateFilter;
    meta: ResponseMeta;
    sort: TableSort<DocumentTemplateSortColumn>;
    list: DocumentTemplateManagerItem[];
    selectList: CustomColorItemOption[];
    selectedTags: CustomColorItemOption[];
    dialogOpen?: undefined | "lwc" | "locations";
}

const PAGE_SIZE = 25;
export const LocationDocumentTemplates: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { id: sessionBusinessId } = useSessionBusiness();

    const documentTemplateService = new LocationDocumentTemplateService(
        linkProvider.lillywait.locationSettings().api.withId().documentTemplates
    );

    const [state, setState] = useState<LocationDocumentTemplatesState>({
        selectedTab: DocumentTemplateTabType.OWN_DOCUMENTS,
        filter: { search: "", tags: "" },
        meta: { PageNumber: 1, PageSize: PAGE_SIZE, HasNextPage: false },
        sort: {
            SortColumn: DocumentTemplateSortColumn.DisplayId,
            SortOrder: SortOrder.ASC,
        },
        list: [],
        selectList: [],
        selectedTags: [],
    });

    const { checkPermission } = useCheckPermission();
    const navigate = useNavigate();
    const hasCreatePermission = checkPermission(
        LocationSettings_DocumentTemplates,
        [PermissionAccessTypes.CREATE]
    );

    const { dataItem: location } = useCurrentLocationSettingsContext();
    const locationId = location?.Id;

    const request: DocumentTemplateRequest = {
        Page: state.meta.PageNumber,
        PageSize: PAGE_SIZE,
        DocumentTabType: state.selectedTab,
        Name: state.filter.search,
        SortColumn: state.sort.SortColumn,
        SortOrder: state.sort.SortOrder,
        DocumentTypes: state.selectedTags.map((x) =>
            x.value?.toString()
        ) as string[],
    };

    const {
        isLoading: loading,
        isRefetching: refetching,
        data: response,
        refetch: refetch,
    } = useQuery(
        getLocationDocumentTemplateServiceKey("getTemplates", {
            ...request,
            businessId: sessionBusinessId,
            locationId: locationId,
        }),
        async () => {
            return await documentTemplateService.getTemplates(request);
        },
        {
            // cacheTime: 5000,
        }
    );

    const onFilterChange = (filter: DocumentTemplateFilter) => {
        setState((oldState) => ({
            ...oldState,
            filter: filter,
            meta: {
                ...state.meta,
                PageNumber: 1,
            },
        }));
    };
    const filterList = () => {
        setState({
            ...state,
            list: orderBy(
                state.list,
                ["DisplayId"],
                state.sort.SortOrder == SortOrder.ASC ? ["asc"] : ["desc"]
            ) as DocumentTemplateManagerItem[],
        });
    };

    const onSelectedTagsChange = (values: CustomColorItemOption[]) => {
        setState({
            ...state,
            selectedTags: values,
        });
    };

    useEffect(() => {
        if (!loading && response) {
            const documents = parseDocumentTemplateResponse(
                response.Data.DocumentTemplates,
                response.Data.Tags
            );

            setState({
                ...state,
                list:
                    response.Meta?.PageNumber == 1
                        ? documents
                        : [...state.list, ...documents],
                meta: {
                    ...response.Meta,
                    PageSize:
                        response.Meta?.PageSize != PAGE_SIZE
                            ? PAGE_SIZE
                            : response.Meta.PageSize,
                },
                selectList: response.Data.Tags.map((x) => {
                    return {
                        value: x.label,
                        label: t(
                            `templateDocumentManager.documentType.${x.label.toLocaleLowerCase()}`
                        ),
                        color: x.color,
                    };
                }),
            });
        }
    }, [response]);

    const refetchDocumentTemplates = (
        pageNumber: number,
        closeDialog?: boolean
    ) => {
        setState({
            ...state,
            dialogOpen: closeDialog ? undefined : state.dialogOpen,
            meta: {
                ...state.meta,
                PageNumber: pageNumber,
            },
        });
        refetch();
    };

    const updateDisplayId = (req: UpdateDisplayIdResponseAndRequest) => {
        setState({
            ...state,
            list: state.list.map((x) => {
                if (x.Id == req.Id) {
                    x.DisplayId = req.DisplayId;
                }
                return x;
            }),
        });
    };

    const removeItem = (Id: number) => {
        setState({
            ...state,
            list: state.list.filter((x) => x.Id != Id),
            meta: {
                ...state.meta,
                PageNumber:
                    state.meta.HasNextPage &&
                    defaultTo(state.meta.PageNumber, 1) > 1
                        ? 1
                        : state.meta.PageNumber,
            },
        });

        if (state.meta.HasNextPage && state.meta.PageNumber == 1) {
            refetch();
        }
    };

    const renderContent = () => {
        return (
            <DocumentTemplatesList
                templates={state.list}
                sort={state.sort}
                updateDisplayId={updateDisplayId}
                removeItem={removeItem}
                metaState={state.meta}
                refetchDocumentTemplate={refetchDocumentTemplates}
                onSortChange={(sort) => {
                    setState({
                        ...state,
                        sort: sort,
                        meta: {
                            ...state.meta,
                            PageNumber: 1,
                        },
                    });
                }}
                filterList={filterList}
                loading={loading || refetching}
                request={{
                    DocumentTabType: state.selectedTab,
                    DocumentTypes: state.selectedTags.map(
                        (x) => x.value as string
                    ),
                    Name: state.filter.search,
                    Page: state.meta.PageNumber,
                    PageSize: state.meta.PageSize,
                    SortColumn: state.sort.SortColumn,
                    SortOrder: state.sort.SortOrder,
                }}
            />
        );
    };

    return (
        <>
            {state.dialogOpen == "locations" && (
                <CloneLocationDocumentTemplatesDialog
                    onClose={() => {
                        setState({ ...state, dialogOpen: undefined });
                    }}
                    onChange={() => {
                        // refetch list
                        refetchDocumentTemplates(1, true);
                    }}
                />
            )}
            {state.dialogOpen == "lwc" && (
                <CloneLWCDocumentTemplatesDialog
                    onClose={() => {
                        setState({ ...state, dialogOpen: undefined });
                    }}
                    onChange={() => {
                        // refetch list
                        refetchDocumentTemplates(1, true);
                    }}
                />
            )}
            <AppContainer
                classes={{ body: commonStyles.appContainerWithLessTopPadding }}
                mediumViewAdjustment={10}
                mobileViewAdjustment={10}
                heightToAdjust={225}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    className={styles.header}
                >
                    {hasCreatePermission && (
                        <AppMenuButton
                            text={t("common.create")}
                            onClick={() =>
                                navigate(
                                    linkProvider.lillywait
                                        .locationSettings()
                                        .screens.withId()
                                        .documentTemplates.create()
                                )
                            }
                            style={{ minWidth: "120px" }}
                            disabled={!hasCreatePermission}
                            menuItems={[
                                {
                                    label: t("documentTemplate.cloneFromLWC"),
                                    onClick: () => {
                                        setState({
                                            ...state,
                                            dialogOpen: "lwc",
                                        });
                                    },
                                },
                                {
                                    label: t(
                                        "documentTemplate.cloneFromAnotherLocation"
                                    ),
                                    onClick: () => {
                                        setState({
                                            ...state,
                                            dialogOpen: "locations",
                                        });
                                    },
                                },
                            ]}
                        />
                    )}
                    <DocumentTemplateSearchSection
                        filter={state.filter}
                        className={styles.filterRoot}
                        showTags={true}
                        onFilterChange={onFilterChange}
                        selectListData={state.selectList}
                        loading={loading || refetching}
                        onChange={onSelectedTagsChange}
                        selectedTags={state.selectedTags}
                    />
                </div>
                {renderContent()}
            </AppContainer>
        </>
    );
};

export default LocationDocumentTemplates;
