import { createContext, useContext, useState } from "react";

export interface IAuthenticatePageContext {
    pagePermissions: string[] | undefined;
    pageFeatures: string[] | undefined; // for explanation Mode HELP
    requiredModules: string[] | undefined; // for explanation Mode HELP
    requiredModuleRootCheck: boolean; // for explanation Mode HELP
    setPagePermissions: (perms: string[] | undefined) => void;
    setPageFeatureKey: (perms: string[] | undefined) => void;
    setRequiredModules: (perms: string[] | undefined) => void;
    setRequiredModuleRootCheck: (val: boolean) => void;
}
const defaultAuthenticatedPageContext: IAuthenticatePageContext = {
    pagePermissions: undefined,
    pageFeatures: undefined,
    requiredModules: undefined,
    requiredModuleRootCheck: false,
    setPagePermissions: () => {},
    setRequiredModules: () => {},
    setPageFeatureKey: () => {},
    setRequiredModuleRootCheck: () => {},
};

const AuthenticatedPageContext = createContext<IAuthenticatePageContext>(
    defaultAuthenticatedPageContext
);

export const useAuthenticatedPageContext = () =>
    useContext(AuthenticatedPageContext);

export const AuthenticatedPageContextProvider: React.FC = ({ children }) => {
    const [pagePermissions, setPagePermissions] = useState<
        string[] | undefined
    >(undefined);
    const [pageFeatures, setPageFeatures] = useState<string[] | undefined>(
        undefined
    );
    const [requiredModules, setRequiredModules] = useState<
        string[] | undefined
    >(undefined);
    const [requiredModuleRootCheck, setRequiredModuleRootCheck] =
        useState<boolean>(false);
    return (
        <AuthenticatedPageContext.Provider
            value={{
                pagePermissions: pagePermissions,
                pageFeatures: pageFeatures,
                requiredModules: requiredModules,
                requiredModuleRootCheck: requiredModuleRootCheck,
                setRequiredModules: setRequiredModules,
                setPagePermissions: setPagePermissions,
                setPageFeatureKey: setPageFeatures,
                setRequiredModuleRootCheck: setRequiredModuleRootCheck,
            }}
        >
            {children}
        </AuthenticatedPageContext.Provider>
    );
};
