import classNames from "classnames";
import AppGoBackButton from "components/Buttons/AppGoBackButton";
import { useWindowSize } from "hooks/general/reactHelpers";
import React, { useMemo } from "react";
import { Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styles from "./AppContentHeader.module.scss";

export interface AppContentHeaderProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    title: string;
    titleLoading?: boolean;
    showTitleSkeleton?: boolean;
    icon?: string;
    classNameForIcon?: string;
    className?: string;
    hasGoBack?: boolean;

    onBack?: () => void;
    backUrl?: string;
}

export const AppContentHeader: React.FC<AppContentHeaderProps> = ({
    icon,
    title,
    titleLoading,
    showTitleSkeleton = false,

    children,
    classNameForIcon,
    className,
    backUrl,
    hasGoBack = false,
    onBack,
    ...rest
}) => {
    return (
        <div
            className={classNames(
                "app-inner-layout paddingLeft",
                className,
                styles.root
            )}
            {...rest}
        >
            <div className="app-page-title">
                <div
                    className="page-title-wrapper"
                    style={{ margin: "0px !important" }}
                >
                    <div
                        className={`flex-space-between align-items-center ${styles.container}`}
                    >
                        <div className={styles.leftSection}>
                            {hasGoBack && (
                                <AppGoBackButton
                                    className={styles.goBack}
                                    backPath={backUrl}
                                    onClick={onBack}
                                />
                            )}
                            <div
                                className={`page-title-heading ${styles.headingSection}`}
                                style={{ margin: 0 }}
                            >
                                <div className="page-title-icon justify-content-center">
                                    {classNameForIcon && !icon ? (
                                        <i
                                            className={`${styles.iconClass}  ${classNameForIcon}`}
                                        />
                                    ) : (
                                        <Image
                                            className={`${styles.headerIcon} `}
                                            src={icon}
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {showTitleSkeleton && titleLoading ? (
                                        <Skeleton width={300} height={28} />
                                    ) : (
                                        <span>{title}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppContentHeader;
