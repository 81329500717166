import { ISO8601_DATE_FORMAT } from "globals/constants";
import { isNil } from "lodash-es";
import {
    AgeGroupTemplateField,
    AllPermissionModules,
    BusinessSelectItem,
    CompanyType,
    WorkingDaysWithTimeJson,
} from "models";
import {
    ApplicationFeatureRequest,
    ApplicationFeatureResponse,
} from "models/applicationFeatures";
import {
    BusinessHolidayResponse,
    UserFilterRequest,
    UserFilterResponse,
} from "models/business";
import {
    DocumentTag,
    DocumentTemplateItemResponse,
} from "models/documentTemplates";
import {
    ActiveFilterDataRequest,
    AppPlatforms,
    SelectItem,
    UserType,
} from "models/general";
import { AppResponse } from "models/general/response";
import { LillywaitCompensationTemplateModulePriceField } from "models/compensationTemplate";
import { ContactInfo } from "models/kindergartenClient";
import { Moment } from "moment-timezone";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IBusinessGeneralService {
    getUserFilterOptions: (
        req: UserFilterRequest
    ) => Promise<AppResponse<UserFilterResponse>>;
    getContactInfo: (email: string) => Promise<AppResponse<ContactInfo>>;
    sendTestPrint: (printerId: number) => Promise<AppResponse<boolean>>;
    getLwcBusinessesSelectList(
        type?: CompanyType
    ): Promise<AppResponse<BusinessSelectItem[]>>;
    getBusinessRolesByDevice: (
        device: AppPlatforms
    ) => Promise<AppResponse<SelectItem[]>>;
    getIpadRoles(): Promise<AppResponse<SelectItem[]>>;
    getHqBranchesWithAccess(
        hqId: number
    ): Promise<AppResponse<BusinessSelectItem[]>>;
    getBusinessWorkSchedule(
        businessId?: number
    ): Promise<AppResponse<WorkingDaysWithTimeJson>>;
    getPersonEmails(
        personIds: number[],
        userType: UserType
    ): Promise<AppResponse<string[]>>;
    getBusinessHolidays(): Promise<AppResponse<BusinessHolidayResponse[]>>;
    getBusinessEmployees(
        request: ActiveFilterDataRequest
    ): Promise<AppResponse<SelectItem[]>>;
    getBusinessShifts(): Promise<AppResponse<SelectItem[]>>;
    getBusinessKontos(): Promise<AppResponse<SelectItem[]>>;
    getApplicationFeatures(
        value: ApplicationFeatureRequest
    ): Promise<AppResponse<ApplicationFeatureResponse>>;
    getLWCModulePlanCards(
        locationSettingId: number
    ): Promise<AppResponse<LillywaitCompensationTemplateModulePriceField[]>>;
    getAllBusinessModuleKeys(): Promise<AppResponse<AllPermissionModules>>;
    calculateClientAgeGroup(
        birthday: Moment
    ): Promise<AppResponse<AgeGroupTemplateField>>;
}

const businessApiLinkProvider = routesForContext().business.api.general;
type ApiLinkProviderType = typeof businessApiLinkProvider;

export function getBusinessGeneralServiceKey(
    name: keyof IBusinessGeneralService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `businessGeneral_${name}_${JSON.stringify(data)}`;
}

export class BusinessGeneralService
    extends BaseService
    implements IBusinessGeneralService
{
    public baseRoute: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }
    getDocumentTemplates(
        tag: DocumentTag,
        loadEditable: boolean
    ): Promise<AppResponse<DocumentTemplateItemResponse[]>> {
        return this.doServerXHR<DocumentTemplateItemResponse[]>({
            url: this.baseRoute.getDocumentTemplate({
                type: tag,
                loadEditable: loadEditable,
            }),
            method: "get",
        });
    }
    getBusinessWorkSchedule(
        businessId?: number
    ): Promise<AppResponse<WorkingDaysWithTimeJson>> {
        return this.doServerXHR<WorkingDaysWithTimeJson>({
            url: this.baseRoute.getBusinessWorkSchedule(
                !isNil(businessId)
                    ? {
                          businessId: businessId,
                      }
                    : undefined
            ),
            method: "get",
        });
    }
    getLWCModulePlanCards(
        locationSettingId: number
    ): Promise<AppResponse<LillywaitCompensationTemplateModulePriceField[]>> {
        // TODO: Add typing
        return this.doServerXHR<
            LillywaitCompensationTemplateModulePriceField[]
        >({
            url: this.baseRoute.getLWCModulePlanCards({
                locationSettingId: locationSettingId,
            }),
            method: "get",
        });
    }
    getPersonEmails(
        personIds: number[],
        userType: UserType,
        onlyRepresentatives: boolean = false
    ): Promise<AppResponse<string[]>> {
        return this.doServerXHR<string[]>({
            url: this.baseRoute.getPersonEmails(),
            method: "post",
            data: {
                PersonIds: personIds,
                UserType: userType,
                OnlyRepresentatives: onlyRepresentatives,
            },
        });
    }
    getUserFilterOptions(
        request: UserFilterRequest
    ): Promise<AppResponse<UserFilterResponse>> {
        return this.doServerXHR<UserFilterResponse>({
            url: this.baseRoute.userFilterOptions({
                ...request,
                forDate: !isNil(request.forDate)
                    ? (request.forDate as Moment).format(ISO8601_DATE_FORMAT)
                    : undefined,
            }),
            method: "get",
        });
    }
    getAllBusinessModuleKeys(): Promise<AppResponse<AllPermissionModules>> {
        return this.doServerXHR<AllPermissionModules>({
            url: this.baseRoute.getAllBusinessModuleKeys(),
            method: "get",
        });
    }
    getBusinessHolidays(): Promise<AppResponse<BusinessHolidayResponse[]>> {
        return this.doServerXHR<BusinessHolidayResponse>({
            url: this.baseRoute.getBusinessHolidays(),
            method: "get",
        });
    }
    sendTestPrint(printerId: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.sendTestPrint({ printerId: printerId }),
            method: "get",
        });
    }
    sendTestPrintJob(Id: number): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.sendTestPrintJob({ printerJobId: Id }),
            method: "get",
        });
    }

    getHqBranchesWithAccess(
        hqId: number
    ): Promise<AppResponse<BusinessSelectItem[]>> {
        return this.doServerXHR<AppResponse<BusinessSelectItem[]>>({
            url: this.baseRoute.getHqBranchesWithAccess({ hqId: hqId }),
            method: "get",
        });
    }

    getApplicationFeatures(
        value: ApplicationFeatureRequest
    ): Promise<AppResponse<ApplicationFeatureResponse>> {
        return this.doServerXHR<string>({
            url: this.baseRoute.getApplicationFeatures(value),
            method: "get",
        });
    }
    getLwcBusinessesSelectList(
        type?: CompanyType
    ): Promise<AppResponse<BusinessSelectItem[]>> {
        return this.doServerXHR<BusinessSelectItem[]>({
            url: this.baseRoute.getBusinessSelectList(
                type ? { companyType: type } : undefined
            ),
            method: "get",
        });
    }
    getContactInfo(email: string): Promise<AppResponse<ContactInfo>> {
        return this.doServerXHR<ContactInfo>({
            url: this.baseRoute.getContactInfo({ email: email }),
            method: "get",
        });
    }
    getBusinessRolesByDevice(
        device: AppPlatforms
    ): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessRolesByDevice({ device: device }),
            method: "get",
        });
    }
    getIpadRoles(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getIpadRoles(),
            method: "get",
        });
    }
    updateIpadRole(
        employeeId: number,
        ipadRoleId: string
    ): Promise<AppResponse<boolean>> {
        return this.doServerXHR<boolean>({
            url: this.baseRoute.updateIpadRole({
                employeeId: employeeId,
                ipadRoleId: ipadRoleId,
            }),
            method: "post",
        });
    }
    getBusinessEmployees(
        request: ActiveFilterDataRequest
    ): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessEmployees(request),
            method: "get",
        });
    }
    getBusinessShifts(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessShifts(),
            method: "get",
        });
    }
    getBusinessKontos(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<SelectItem[]>({
            url: this.baseRoute.getBusinessKontos(),
            method: "get",
        });
    }
    calculateClientAgeGroup(
        birthday: Moment
    ): Promise<AppResponse<AgeGroupTemplateField>> {
        return this.doServerXHR<AgeGroupTemplateField>({
            url: this.baseRoute.calculateClientAgeGroup(
                birthday.format(ISO8601_DATE_FORMAT)
            ),
            method: "get",
        });
    }
}
