import { AppGradientButton } from "components/Buttons";
import { ConfirmationPageData } from "models";
import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AuthHeader } from "../";
import styles from "./ConfirmationPageContent.module.scss";

export interface ConfirmationPageContentProps {
    value: ConfirmationPageData;
    onLogin?: () => void;
}
export const ConfirmationPageContent: React.FC<
    ConfirmationPageContentProps
> = ({ value: pageData, onLogin }) => {
    const { t } = useTranslation();

    return (
        <>
            <AuthHeader
                mainHeading={pageData.mainHeading}
                pageTitle={pageData.pageTitle}
            />
            <div className={styles.imageAndDesc}>
                <Image src={pageData.imgUrl} />
                <div className={styles.desc}>{pageData.desc}</div>
            </div>
            {onLogin && (
                <AppGradientButton
                    className={styles.confirmationButton}
                    onClick={() => {
                        onLogin();
                    }}
                    text={t("auth.login.name")}
                />
            )}
        </>
    );
};

export default ConfirmationPageContent;
