import { AppIconWithName } from "components";
import { AppTable } from "components/Table";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import { t } from "i18next";
import { UserType } from "models/general";
import { TimeTrackingRecord } from "models/timeManagement";
import React from "react";
import TimeManagementColorHighlight from "screens/business/timeTracking/partials/TimeManagementColorHighlight";
import { v4 as uuid } from "uuid";
import styles from "./TimeManagementDateRangeTable.module.scss";

export interface TimeManagementDateRangeTableProps {
    record: TimeTrackingRecord[];
    userType: UserType;
    onClick: (val: number) => void;
    isEmpty?: boolean;
    className?: string;
    emptyString?: string;
}

export const TimeManagementDateRangeTable: React.FC<
    TimeManagementDateRangeTableProps
> = ({
    record,
    userType,
    onClick,
    isEmpty = record.length <= 0,
    className = "",
    emptyString = t("common.noDataFound"),
}) => {
    const colSpan = userType != UserType.Client ? 4 : 5;
    return (
        <AppTable
            hover={true}
            containerClass={`${styles.tableBody} ${className} ${
                userType == UserType.Client ? styles.tableBodyClient : ""
            }`}
            useMaxHeight={false}
        >
            <tbody>
                <tr className="dummy-row">
                    <td colSpan={colSpan} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={colSpan} />
                </tr>
                {!isEmpty ? (
                    record.map((x) => (
                        <tr key={uuid()} onClick={() => onClick(x.PersonId)}>
                            <td style={{ minWidth: "450px" }}>
                                <AppIconWithName text={x.PersonName} />
                            </td>
                            <td className={`text-center ${styles.tbWidth}`}>
                                {getFormattedTimeFromNumber(x.CareMinutes)}
                            </td>
                            <td className={`text-center ${styles.tbWidth}`}>
                                {getFormattedTimeFromNumber(
                                    x.MaxContractMinutes
                                )}
                            </td>
                            <td className={`text-center ${styles.tbWidth}`}>
                                <TimeManagementColorHighlight
                                    differenceSumMinute={
                                        !x.IgnoreDifference
                                            ? x.DifferenceSumMinutes
                                            : null
                                    }
                                    isNegativeRed={
                                        userType == UserType.Employee
                                    }
                                />
                            </td>
                            {userType == UserType.Client && (
                                <td className={`text-center ${styles.tbWidth}`}>
                                    <TimeManagementColorHighlight
                                        differenceSumMinute={
                                            !x.IgnoreDifference
                                                ? x.OverdraftSumMinutes
                                                : null
                                        }
                                        isNegativeRed={false}
                                    />
                                </td>
                            )}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={colSpan}>
                            <div
                                style={{
                                    fontSize: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {emptyString}
                            </div>
                        </td>
                    </tr>
                )}
                <tr className="dummy-row">
                    <td colSpan={colSpan} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default TimeManagementDateRangeTable;
