export enum EmployeeVacationStatus {
    Approved,
    Declined,
    Requested,
}

export enum EmployeeVacationSortColumn {
    StartDate,
    EndDate,
    Status,
    Total,
}
