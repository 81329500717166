export enum DashboardTabs {
    CLIENTS = "CLIENTS",
    EMPLOYEES = "EMPLOYEES",
}
export enum DashboardCardTypes {
    ACTIVE_CONTRACTS = "ACTIVE_CONTRACTS",
    STARTING_CONTRACTS = "STARTING_CONTRACTS",
    ENDING_CONTRACTS = "ENDING_CONTRACTS",
    DISEASES = "DISEASES",
    VACATION = "VACATION",
}

export enum DashboardSections {
    DISEASES = "diseases",
    VACATION = "vacations",
    MISSING = "missing",
}
