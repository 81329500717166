import classNames from "classnames";
import { useWindowSize } from "hooks/general/reactHelpers";
import { defaultTo } from "lodash-es";
import React from "react";
import { Table } from "react-bootstrap";
import { COLOR_BORDER_GREY } from "theme/themeConstants";
import styles from "./AppTable.module.scss";

export interface AppTableProps {
    id?: string;
    containerClass?: string;
    tableClass?: string;
    children: React.ReactNode;
    collapsed?: boolean;
    styleHeader?: boolean;
    response?: boolean;
    useMaxHeight?: boolean;
    stickyHeader?: boolean;
    heightToAdjust?: number;
    mobileViewAdjustment?: number;
    mediumViewAdjustment?: number;
    mediumBreak?: number;
    mobileBreak?: number;
    hover?: boolean;
}
export const appTableFilterSelectStyles = {
    control: {
        height: "39px !important",
    },
    indicatorsContainer: {
        height: "100%",
    },
    valueContainer: {
        minHeight: "39px",
        height: "39px",
    },
};
export const AppTable: React.FC<AppTableProps> = ({
    id,
    containerClass = "",
    tableClass = "",
    children,
    collapsed = false,
    stickyHeader = false,
    styleHeader = true,
    useMaxHeight = true,
    heightToAdjust = 215, // height to subtract from vh to add scroll
    mobileViewAdjustment = 50, // additional height to subtract for mobile
    mediumViewAdjustment = 50, // additional height to subtract for medium 450<s<860 (table width not screen width)
    response = false, //if true the horizontal scroll will append the end of the table
    hover = false,
    mediumBreak = 900,
    mobileBreak = 500,
}) => {
    const { width } = useWindowSize();

    return (
        <div
            id={id}
            className={`${styles.root} ${containerClass} ${
                collapsed ? styles.noBottomBorder : ""
            }`}
            style={
                useMaxHeight
                    ? {
                          maxHeight: `calc(100vh - ${
                              defaultTo(width, mediumBreak) >= mediumBreak
                                  ? heightToAdjust //large
                                  : defaultTo(width, mobileBreak) > mobileBreak
                                  ? heightToAdjust + mediumViewAdjustment // medium
                                  : heightToAdjust + mobileViewAdjustment // mobile
                          }px)`,
                      }
                    : undefined
            }
        >
            {/* second wrapper div is to fix border issue in firefox */}
            <Table
                responsive={response}
                hover={hover}
                className={classNames(
                    styles.table,
                    tableClass,
                    { [styles.showCursor]: hover },
                    { [styles.hideCursor]: !hover },
                    { [styles.stickyHeader]: stickyHeader },
                    { [styles.styleHeader]: styleHeader }
                )}
            >
                {children}
            </Table>
        </div>
    );
};

export default AppTable;
