import { AppContentHeader } from "components";
import IFrameContent from "components/IFrameContent";
import { useIFrameRouting, useRouting } from "hooks/general/routing";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./LocationSettingsCreate.module.scss";

export const LocationSettingsCreate: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { urlForLocationSettings: forLocationSettings } = useIFrameRouting();
    const { linkProvider } = useRouting();
    const backUrl = linkProvider.lillywait.locationSettings().screens.list();

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("locationSettings.createTitle")}
                classNameForIcon="lnr-map-marker"
                hasGoBack={true}
                backUrl={backUrl}
            />
            <IFrameContent
                contentUrl={forLocationSettings(`Create`)}
                onRedirect={(encodedId) =>
                    navigate(
                        linkProvider.lillywait
                            .locationSettings()
                            .screens.withId(encodedId)
                            .edit()
                    )
                }
            />
        </div>
    );
};

export default LocationSettingsCreate;
