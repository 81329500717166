export const config = {
    fullCalendar: {
        licenseKey: process.env.REACT_APP_FULLCALENDAR_KEY,
    },
    siteName: process.env.REACT_APP_SITE_NAME,
    isDevEnv: process.env.REACT_APP_ENV === "dev",
    apiBaseUrl: `${process.env.REACT_APP_SERVER_BASE_URL}/${process.env.REACT_APP_API_BASE_PATH}`,
    serverBaseUrl: process.env.REACT_APP_SERVER_BASE_URL,
    release: process.env.REACT_APP_RELEASE,
    encryptionSecret: process.env.ENCRYPTION_SECRET
        ? process.env.ENCRYPTION_SECRET
        : "ENCRYPTION_KEY_NOT_FOUND",
    sentry: {
        enable: process.env.REACT_APP_ENABLE_SENTRY === "true",
        dsn: process.env.REACT_APP_SENTRY_DSN,
        env: process.env.REACT_APP_SENTRY_ENV,
    },
    commitHash: process.env.REACT_APP_COMMIT_HASH,
};

export default config;
