import { AppButton } from "components/Buttons";
import { AppLoader } from "components/Loaders";
import { SEARCH_DELAY_TIME } from "globals/constants";
import { base64ToBlob } from "globals/helpers/generalHelper";
import { useRouting } from "hooks/general/routing";
import {
    EditableSection,
    EmployeeType,
    validateEditableSectionFields,
} from "models";
import { DocumentEditableSectionRequest } from "models/documentCompile";
import { DocumentType } from "models/documentTemplates";
import { UserType, ValidityStateManager } from "models/general";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    DocumentCompileService,
    getDocumentCompileServiceKey,
} from "services/business";
import styles from "./EditableSectionContainer.module.scss";
import EditableSectionRow from "./EditableSectionRow";

export interface EditableSectionContainerProps {
    documentType: DocumentType;
    templateId: number;
    readonly?: boolean;
    selectedIds?: number[];
    userType: UserType;
    employeeType?: EmployeeType;
    onResumeClick: (request: DocumentEditableSectionRequest) => void;
}
export const EditableSectionContainer: React.FC<
    EditableSectionContainerProps
> = ({
    documentType,
    templateId,
    selectedIds,
    readonly,
    employeeType,
    userType,
    onResumeClick,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const [fields, setFields] = useState<EditableSection[]>([]);
    const stateManager = useMemo(() => {
        return new ValidityStateManager(validateEditableSectionFields(fields));
    }, [fields]);

    const documentCompileService = new DocumentCompileService(
        linkProvider.business.api.documentCompile
    );

    const request = {
        TemplateId: templateId,
        SelectedIds:
            selectedIds && selectedIds.length > 0 ? selectedIds : undefined,
        DocumentArea: documentType,
        ClientId:
            userType == UserType.Client && selectedIds && selectedIds.length > 0
                ? selectedIds[0]
                : undefined,
        EmployeeId:
            userType == UserType.Employee &&
            selectedIds &&
            selectedIds.length > 0
                ? selectedIds[0]
                : undefined,
        EmployeeType: employeeType,
        EditableSectionsJson: JSON.stringify(fields).toString(),
    } as DocumentEditableSectionRequest;

    const {
        data: pdfUrl,
        isRefetching: pdfRefetching,
        refetch: getPdf,
    } = useQuery(
        getDocumentCompileServiceKey("getPdf", {
            request,
        }),
        async () => documentCompileService.getPdf(request),
        {
            select: (value) => {
                return window.URL.createObjectURL(
                    base64ToBlob(value.Data, "application/pdf")
                );
            },
            enabled: false,
        }
    );

    useEffect(() => {
        if (!pdfRefetching) {
            const delayDebounceFn = setTimeout(() => {
                getPdf();
            }, SEARCH_DELAY_TIME);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [fields]);

    const { data: editableSections, isRefetching: loading } = useQuery(
        getDocumentCompileServiceKey("getEditableData", {
            templateId,
        }),
        async () =>
            documentCompileService.getEditableData(templateId.toString()),
        {
            enabled: true,
        }
    );

    useEffect(() => {
        if (!loading && editableSections?.Data) {
            setFields(editableSections.Data);
        }
    }, [loading, editableSections]);

    return (
        <div className={styles.fieldsContainerRoot}>
            <Row className="w-100">
                <Col md={6} style={{ minWidth: "301px" }}>
                    {fields.map((f) => {
                        const id = f.Uuid;
                        return (
                            <EditableSectionRow
                                key={id}
                                validityState={
                                    stateManager.getFieldState(id)?.children
                                }
                                value={f}
                                readonly={readonly}
                                onChange={(field) => {
                                    const fieldId = field.Uuid;
                                    const oldIndex = fields.findIndex(
                                        (f) => f.Uuid == fieldId
                                    );
                                    if (oldIndex >= 0) {
                                        setFields(
                                            fields.map((nf) => {
                                                if (nf.Uuid == fieldId) {
                                                    return field;
                                                } else {
                                                    return nf;
                                                }
                                            })
                                        );
                                    } else {
                                        setFields([...fields, field]);
                                    }
                                }}
                            />
                        );
                    })}
                </Col>
                <Col md={6} className={styles.embedDiv}>
                    {!pdfRefetching && pdfUrl ? (
                        <embed
                            style={{ width: "100%", height: "100%" }}
                            src={pdfUrl}
                            type="application/pdf"
                        />
                    ) : (
                        <AppLoader fullHeight={false} />
                    )}
                </Col>
                <Col md={12}>
                    <AppButton
                        className="mt-3 float-right"
                        disabled={loading}
                        onClick={() => {
                            if (!loading) {
                                onResumeClick(request);
                            }
                        }}
                    >
                        {t("common.continue")}
                    </AppButton>
                </Col>
            </Row>
        </div>
    );
};

export default EditableSectionContainer;
