import { AppTable } from "components/Table";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo } from "lodash-es";
import {
    KindergartenClientOccupancyResponse,
    OccupancyReportType,
} from "models";
import { DATEPICKER_MONTH_YEAR_FORMAT } from "models/datepicker";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    PersonInfoPopper,
    OccupancyReportTotalsTable,
    AdditionalKidInput,
} from "./partials";
import styles from "./OccupancyReportTable.module.scss";
import { Optional } from "models/general";
import { Moment } from "moment-timezone";
import EmpKeyGroupPopper from "screens/kindergarten/occupancyReport/partials/EmpKeyGroupPopper";

export interface OccupancyReportTableProps {
    data: KindergartenClientOccupancyResponse;
    onChange: (val: KindergartenClientOccupancyResponse) => void;
}
export const OccupancyReportTable = ({
    data,
    onChange,
}: OccupancyReportTableProps) => {
    const { formatNumber } = useLocaleHelpers();
    const { t } = useTranslation();

    const months = useMemo(() => {
        return data.Totals.map((x) => x.Month);
    }, [data.Totals]);

    const columns = 2 + months.length * 5;
    let surchargeRowAdded = false;

    const onValChange = useCallback(
        (val: Optional<number>, key: string, month: Moment) => {
            onChange({
                ...data,
                Data: data.Data.map((d) => {
                    if (d.Key == key) {
                        return {
                            ...d,
                            MonthStats: d.MonthStats.map((dM) => {
                                if (
                                    dM.Month.toISOString() ==
                                    month.toISOString()
                                ) {
                                    return {
                                        ...dM,
                                        AdditionalKids: val,
                                    };
                                }
                                return dM;
                            }),
                        };
                    }
                    return d;
                }),
            });
        },
        [data.Data]
    );

    return (
        <div className={styles.root}>
            <AppTable
                containerClass={`${styles.tableRoot}`}
                useMaxHeight={false}
                stickyHeader={true}
            >
                <colgroup>
                    <col width={300} />
                    <col width={150} />
                    {months.map((m) => {
                        return (
                            <React.Fragment key={m.toISOString()}>
                                <col width={140} />
                                <col width={90} />
                                <col width={100} />
                                <col width={100} />
                                <col width={100} />
                            </React.Fragment>
                        );
                    })}
                </colgroup>
                <thead className={styles.tableHeading}>
                    <tr style={{ height: "30px" }}>
                        {/* month names row */}
                        <th
                            style={{
                                ...getFixedCssWidths(300),
                                textAlign: "start",
                            }}
                        ></th>
                        <th
                            style={{
                                ...getFixedCssWidths(150),
                            }}
                        ></th>
                        {months.map((m) => {
                            return (
                                <th
                                    style={{
                                        ...getFixedCssWidths(530),
                                        textAlign: "center",
                                    }} // 150 per column
                                    key={m.toISOString()}
                                    colSpan={5}
                                    className={styles.monthHeaderCell}
                                >
                                    <span>
                                        {m.format(DATEPICKER_MONTH_YEAR_FORMAT)}
                                    </span>
                                </th>
                            );
                        })}
                    </tr>
                    <tr className={styles.secondRow}>
                        <th
                            style={{
                                textAlign: "start",
                            }}
                        >
                            {t(`occupancyPlanning.table.ageOfChild`)}
                        </th>
                        <th
                            style={{
                                textAlign: "center",
                            }}
                        >
                            {t(`occupancyPlanning.table.careHours`)}
                        </th>
                        {months.map((m) => {
                            return (
                                <React.Fragment key={m.toISOString()}>
                                    <th
                                        className="ellipsis-text"
                                        style={{
                                            textAlign: "center",
                                            width: "210px !important",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {t(
                                            `occupancyPlanning.table.employeeKey`
                                        )}
                                    </th>
                                    <th
                                        className="ellipsis-text"
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {t(
                                            `occupancyPlanning.table.numberKids`
                                        )}
                                    </th>
                                    <th
                                        className="ellipsis-text"
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {t(
                                            `occupancyPlanning.table.numberRegistrations`
                                        )}
                                    </th>
                                    <th
                                        className="ellipsis-text"
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {t(
                                            `occupancyPlanning.table.additionalKids`
                                        )}
                                    </th>
                                    <th
                                        className="ellipsis-text"
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {t(
                                            `occupancyPlanning.table.requiredEmployees`
                                        )}
                                    </th>
                                </React.Fragment>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr className="dummy-row">
                        <td colSpan={columns} />
                    </tr>
                    <tr className="dummy-row">
                        <td colSpan={columns} />
                    </tr>
                    {!data ||
                    data.Data.length == 0 ||
                    data.Data.filter((x) => {
                        return x.MonthStats.some(
                            (y) =>
                                defaultTo(y.AdditionalKids, 0) > 0 ||
                                defaultTo(y.NumberRegistrations, 0) > 0 ||
                                defaultTo(y.NumberKids, 0) > 0
                        );
                    }).length == 0 ? (
                        <tr>
                            <td colSpan={columns}>
                                <div
                                    style={{
                                        fontSize: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {t("common.noDataFound")}
                                </div>
                            </td>
                        </tr>
                    ) : (
                        data.Data.filter((x) => {
                            return x.MonthStats.some(
                                (y) =>
                                    defaultTo(y.AdditionalKids, 0) > 0 ||
                                    defaultTo(y.NumberRegistrations, 0) > 0 ||
                                    defaultTo(y.NumberKids, 0) > 0
                            );
                        }).map((record) => {
                            const showSurchargeRow =
                                !surchargeRowAdded &&
                                record.Type ==
                                    OccupancyReportType.TYPE_SURCHARGE;
                            if (showSurchargeRow) {
                                surchargeRowAdded = true;
                            }
                            return (
                                <React.Fragment key={record.Key}>
                                    {showSurchargeRow && (
                                        <tr>
                                            <td
                                                colSpan={columns}
                                                style={{
                                                    fontWeight: 600,
                                                    textAlign: "start",
                                                }}
                                            >
                                                {t(
                                                    "occupancyPlanning.surcharges"
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    <tr style={{ height: "35px" }}>
                                        <td
                                            className={
                                                "ellipsis-text single-line"
                                            }
                                            style={{
                                                textAlign: "start",
                                            }}
                                        >
                                            <span>{record.Name}</span>
                                        </td>
                                        <td>
                                            {record.CareScopeField
                                                ? record.CareScopeField
                                                      .ShortName
                                                : ""}
                                        </td>
                                        {record.MonthStats.map((m) => {
                                            let requiredEmpKey =
                                                m.EmpKey *
                                                (defaultTo(
                                                    m.AdditionalKids,
                                                    0
                                                ) +
                                                    // m.NumberRegistrations +
                                                    m.NumberKids);
                                            if (
                                                record.Type ==
                                                OccupancyReportType.TYPE_SURCHARGE
                                            ) {
                                                requiredEmpKey = 0;
                                                const empKeysTemp = defaultTo(
                                                    m.AgeGroupEmpKeys,
                                                    {}
                                                );
                                                Object.keys(
                                                    defaultTo(empKeysTemp, {})
                                                ).map((a) => {
                                                    requiredEmpKey +=
                                                        empKeysTemp[a] *
                                                        defaultTo(
                                                            defaultTo(
                                                                m.AgeGroupKidsCount,
                                                                {}
                                                            )[a],
                                                            1
                                                        );
                                                });
                                            }
                                            return (
                                                <React.Fragment
                                                    key={`${m.Month.toISOString()}_${
                                                        record.Key
                                                    }`}
                                                >
                                                    <td>
                                                        <EmpKeyGroupPopper
                                                            value={
                                                                m.AgeGroupEmpKeys
                                                            }
                                                            text={formatNumber(
                                                                m.EmpKey,
                                                                FOUR_PRECISION_NUMERIC
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <PersonInfoPopper
                                                            persons={
                                                                m.KindergartenClients
                                                            }
                                                            title={t(
                                                                "occupancyPlanning.activeKids"
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <PersonInfoPopper
                                                            persons={
                                                                m.Registrations
                                                            }
                                                            title={t(
                                                                "occupancyPlanning.newRegistrations"
                                                            )}
                                                        />
                                                    </td>
                                                    <td>
                                                        <AdditionalKidInput
                                                            value={
                                                                m.AdditionalKids
                                                            }
                                                            readonly={
                                                                record.Type !=
                                                                OccupancyReportType.TYPE_AGE_GROUP
                                                            }
                                                            onChange={(val) => {
                                                                onValChange(
                                                                    val,
                                                                    record.Key,
                                                                    m.Month
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "end",
                                                            paddingRight:
                                                                "20px",
                                                        }}
                                                    >
                                                        {formatNumber(
                                                            requiredEmpKey,
                                                            FOUR_PRECISION_NUMERIC
                                                        )}
                                                    </td>
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            );
                        })
                    )}
                    <tr className="dummy-row">
                        <td colSpan={columns} />
                    </tr>
                </tbody>
            </AppTable>
            <OccupancyReportTotalsTable months={months} data={data} />
        </div>
    );
};

export default OccupancyReportTable;
