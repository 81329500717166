import React, { useEffect, useState } from "react";
import { AppContentHeader } from "components";
import { AppRoundedTextIconButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import {
    Kindergarten_Client_ExternalRegistrations,
    PermissionAccessMap,
} from "models";
import { Outlet, useNavigate } from "react-router";
import styles from "./KindergartenClientRegistration.module.scss";
import { useTranslation } from "react-i18next";
import routesForContext from "routing/AppRoutes";
import { kindergartenClientRoutes } from "routing/routes/kindergarten/client";
import { useLocation } from "react-router";

const basePath =
    routesForContext().kindergarten.clients().screens.registrations;

export interface KindergartenClientRegistrationOutletContext {
    setHeaderTitle: (title: string) => void;
    basePath: typeof basePath;
    permMap: PermissionAccessMap;
}
export const KindergartenClientRegistrationMain: React.FC = () => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { getPermissionMap } = useCheckPermission();
    const permMap = getPermissionMap(Kindergarten_Client_ExternalRegistrations);

    const [headerTitle, setHeaderTitle] = useState(
        t("kindergartenClient.registration.title").toString()
    );
    const linkProviderBase =
        linkProvider.kindergarten.clients().screens.registrations;

    useEffect(() => {
        if (location.pathname.endsWith("registrations")) {
            setHeaderTitle(
                t("kindergartenClient.registration.title").toString()
            );
        }
    }, [location.pathname, t]);
    return (
        <div className={styles.root}>
            <AppContentHeader
                title={headerTitle}
                icon={ImageAssets.common.clients}
            >
                <div className="d-flex align-items-center">
                    {location.pathname.endsWith("registrations") &&
                        permMap.CREATE && (
                            <AppRoundedTextIconButton
                                className="ml-3"
                                onClick={() =>
                                    navigate(linkProviderBase.create())
                                }
                            />
                        )}
                </div>
            </AppContentHeader>
            <div className={styles.content}>
                <Outlet
                    context={{
                        basePath: linkProviderBase,
                        setHeaderTitle,
                        permMap,
                    }}
                />
            </div>
        </div>
    );
};
export default KindergartenClientRegistrationMain;
