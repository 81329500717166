import {
    DocumentCompileRequest,
    DocumentEditableSectionRequest,
} from "models/documentCompile";
import { AppResponse } from "models/general/response";
import { EditableSection } from "models/tinymceEditor";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";

export interface IDocumentCompileService {
    getEditableData(
        templateId: string
    ): Promise<AppResponse<EditableSection[]>>;

    getPdf(
        request: DocumentEditableSectionRequest
    ): Promise<AppResponse<string>>;

    compileWithEditableSection(
        request: DocumentEditableSectionRequest
    ): Promise<AppResponse<string>>;

    compile(request: DocumentCompileRequest): Promise<AppResponse<string>>;

    getPdfFromKey(cacheKey: string): Promise<AppResponse<string>>;
    downloadPdfFromKey(cacheKey: string, name?: string): Promise<any>;
}

const apiLinkProvider = routesForContext().business.api.documentCompile;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getDocumentCompileServiceKey(
    name: keyof IDocumentCompileService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `docCompiler_${name}_${JSON.stringify(data)}`;
}
export class DocumentCompileService
    extends BaseService
    implements IDocumentCompileService
{
    public documentRoutes: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.documentRoutes = apiLinkProvider;
    }

    getEditableData(
        templateId: string
    ): Promise<AppResponse<EditableSection[]>> {
        return this.doServerXHR<EditableSection[]>({
            url: this.documentRoutes.getEditableData(templateId),
            method: "get",
        });
    }

    getPdf(
        request: DocumentEditableSectionRequest
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.documentRoutes.getPdf(),
            method: "post",
            data: request,
        });
    }

    compileWithEditableSection(
        request: DocumentEditableSectionRequest
    ): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.documentRoutes.compileWithEditableSection(),
            method: "post",
            data: request,
        });
    }

    compile(request: DocumentCompileRequest): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.documentRoutes.compileDocument(),
            method: "post",
            data: request,
        });
    }

    getPdfFromKey(cacheKey: string): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.documentRoutes.getPdfFromKey(cacheKey),
            method: "get",
        });
    }
    downloadPdfFromKey(cacheKey: string, name?: string): Promise<any> {
        return this.doServerXHR<string>(
            {
                url: this.documentRoutes.downloadPdfFromKey(cacheKey, name),
                method: "get",
            },
            false
        );
    }
}

export default DocumentCompileService;
