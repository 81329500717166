import { useTranslation } from "react-i18next";
import { DeleteConfirmPasswordDialog } from "./DeleteConfirmPasswordDialog";

export interface DeleteDataDialogRendererProps {
    opened: boolean;
    buttonText?: string;
    requireMasterPassword?: boolean;
    onSubmit: (password: string, masterPassword?: string) => void;
    onClose?: () => void;
}

export function DeleteWithPassword(props: DeleteDataDialogRendererProps) {
    const { t } = useTranslation();
    const {
        opened,
        onSubmit,
        onClose,
        requireMasterPassword = false,
        buttonText = t("common.deleteText"),
    } = props;

    return opened ? (
        <DeleteConfirmPasswordDialog
            buttonText={buttonText}
            requireMasterPassword={requireMasterPassword}
            modalOpen={opened}
            onChange={(password, masterPassword) => {
                onSubmit(password, masterPassword);
            }}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
            }}
        />
    ) : (
        <></>
    );
}

export default DeleteWithPassword;
