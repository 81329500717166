import { FULL_DATE_TIME_FORMAT, ISO8601_DATE_FORMAT } from "globals/constants";
import { Optional } from "models/general";
import moment from "moment";
import { ChannelType, ChatTabsType } from "./enum";
import { ChannelCacheDTO } from "./models";
import { ChannelResponseModel } from "./response";
export interface GetUsersForChannelRequest {
    ChannelSid: string;
    UsersType: ChatTabsType;
}
export interface AddChannelRequestModel {
    file: Optional<File>;
    GroupName: string;
    MembersIdsList: string[];
    AdminId: string;
    ChannelType: ChannelType;
}
export interface AddRemoveMemberRequest {
    ChannelSid: string;
    MembersIdentities: string[];
}
export interface PinChannelRequest {
    ChannelSid: string;
    UserId: string;
    IsPinned: boolean;
}
export const getChatCacheRequest = (
    channels: ChannelResponseModel[]
): ChannelCacheDTO[] => {
    return channels.map((x) => {
        return {
            ...x,
            CreatedDate: x.CreatedDate
                ? x.CreatedDate.format(ISO8601_DATE_FORMAT)
                : null,
            PinnedAt: x.PinnedAt
                ? x.PinnedAt.format(ISO8601_DATE_FORMAT)
                : null,
            ChatClearAt: x.ChatClearAt
                ? x.ChatClearAt.format(ISO8601_DATE_FORMAT)
                : null,
            LastMessageActualDate: x.LastMessageActualDate
                ? x.LastMessageActualDate.format(FULL_DATE_TIME_FORMAT)
                : null,
            LastMessageTime: x.LastMessageTime
                ? moment(x.LastMessageTime, FULL_DATE_TIME_FORMAT).valueOf()
                : null,
        } as ChannelCacheDTO;
    }) as ChannelCacheDTO[];
};
