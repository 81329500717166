import { AppCollapseToggleButton } from "components/Buttons";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useCollapsibleHelper } from "hooks/general/appHelpers";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import { defaultTo } from "lodash-es";
import {
    BusinessHQ,
    Business_BasicData,
    checkPermissionInMap,
    PermissionAccessTypes,
} from "models";
import { Optional } from "models/general";
import React, { useRef } from "react";
import AdminBusinessBranchRowContent from "screens/business/hqAndBranches/partials/AdminBusinessBranchRowContent";
import styles from "./AdminBusinessBranchRow.module.scss";

export interface AdminBusinessBranchRowProps {
    value: BusinessHQ;
    onClick?: (businessId: string) => void;
    canDelete?: boolean;
    onDelete: (hqId: number, businessId?: number) => void;
}

export const AdminBusinessBranchRow: React.FC<AdminBusinessBranchRowProps> = ({
    value,
    onClick,
    canDelete,
    onDelete,
}) => {
    const { id: sessionBusinessId } = useSessionBusiness();
    const collapseRowRef = useRef<Optional<any>>(undefined);
    const { collapse, toggleCollapse, initialStyle } =
        useCollapsibleHelper(collapseRowRef);

    const branches = defaultTo(value.Branches, []);
    const { getPermissionMap } = useBusinessCheckPermission();
    const hasBranches = branches.length > 0;
    return (
        <>
            <tr>
                <td
                    style={{ cursor: "pointer !important" }}
                    onClick={() => (hasBranches ? toggleCollapse() : {})}
                >
                    <div className={styles.toggleIcon}>
                        {hasBranches && (
                            <AppCollapseToggleButton visible={collapse} />
                        )}
                    </div>
                </td>
                <AdminBusinessBranchRowContent
                    value={value.HqInfo}
                    onClick={
                        value.HasHQAccess && onClick
                            ? () => onClick(value.HqInfo.EncodedId)
                            : undefined
                    }
                    onDelete={onDelete}
                    canDelete={false}
                    isPrimary={value.BusinessId === sessionBusinessId}
                />
            </tr>
            {hasBranches && (
                <tr className="row-with-innerTable">
                    <td colSpan={8}>
                        <div
                            className="child-table-container"
                            style={{
                                paddingLeft: "1px",
                                ...(initialStyle.current
                                    ? initialStyle.current
                                    : {}),
                            }}
                            ref={collapseRowRef}
                        >
                            <table
                                className="table-striped"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    {branches.map((b) => {
                                        const permMap = getPermissionMap(
                                            b.Id,
                                            b.HqId,
                                            Business_BasicData
                                        );
                                        return (
                                            <tr key={b.Id}>
                                                <td
                                                    style={getFixedCssWidths(
                                                        50
                                                    )}
                                                />
                                                <AdminBusinessBranchRowContent
                                                    value={b}
                                                    isPrimary={
                                                        b.Id ===
                                                        sessionBusinessId
                                                    }
                                                    onDelete={onDelete}
                                                    onClick={
                                                        onClick &&
                                                        checkPermissionInMap(
                                                            permMap,
                                                            [
                                                                PermissionAccessTypes.SHOW,
                                                            ]
                                                        )
                                                            ? () =>
                                                                  onClick(
                                                                      b.EncodedId
                                                                  )
                                                            : undefined
                                                    }
                                                    canDelete={
                                                        canDelete &&
                                                        sessionBusinessId !==
                                                            b.Id
                                                    }
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default AdminBusinessBranchRow;
