import { BankDataCreateEdit } from "commonPartials";
import { AppLoader } from "components/Loaders";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveBusinessEmployee } from "screens/business/employees/tabs/EmployeeTabs";

export const EmployeeBankDataCreate: React.FC = () => {
    const { linkProvider } = useRouting();
    const navigate = useNavigate();

    const { dataItem: employee } = useActiveBusinessEmployee();
    const linkProviderBase = linkProvider.business
        .employees()
        .screens.withId().bankData;

    return isNil(employee) ? (
        <AppLoader />
    ) : (
        <BankDataCreateEdit
            onBack={() => navigate(linkProviderBase.list())}
            request={{ EmployeeId: employee.Id }}
        />
    );
};

export default EmployeeBankDataCreate;
