import { RestrictedRoute } from "hoc/routeModifiers";
import ErrorLayout from "layouts/ErrorLayout";
import { Route } from "react-router-dom";
import { RoutesMap } from "routing";

export const route404 = (
    <Route key="404-page" path="*" element={<ErrorLayout errorCode={404} />} />
);

export const renderRoutes = (
    routesMap: RoutesMap,
    defaultRestrictor: React.FC<any> | undefined = undefined, // to use for every route of routeMap,
    parentCheckPermissionForParam?: "businessId"
) => {
    return [
        ...Object.keys(routesMap).map((routeKey) => {
            const {
                fullPattern,
                pattern,
                index,
                component: Component,
                nestedScreens,
                checkPermissionForParam = parentCheckPermissionForParam,
                permKeys,
                requiredModuleRootCheck = true,
                featureKeys,
                requiredModule,
                permAccessTypes,
                routeRestrictor,
            } = routesMap[routeKey];

            // defaultRestrictor has low priority then inner Restrictor
            const Restrictor = routeRestrictor
                ? routeRestrictor
                : defaultRestrictor;

            const restrictedComponent =
                permKeys && permKeys.length > 0 ? (
                    <RestrictedRoute
                        permKeys={permKeys}
                        requiredModules={requiredModule}
                        requiredModuleRootCheck={requiredModuleRootCheck}
                        applicationFeatureKeys={featureKeys}
                        accessType={permAccessTypes}
                        checkPermissionForParam={checkPermissionForParam}
                    >
                        <Component />
                    </RestrictedRoute>
                ) : (
                    <Component />
                );

            // index ? (
            //     <Route
            //         key={fullPattern}
            //         index={index}
            //         element={
            //             Restrictor ? (
            //                 <Restrictor>{restrictedComponent}</Restrictor>
            //             ) : (
            //                 restrictedComponent
            //             )
            //         }
            //     />
            // ) :
            return (
                <Route
                    key={fullPattern}
                    path={pattern}
                    index={index}
                    element={
                        Restrictor ? (
                            <Restrictor>{restrictedComponent}</Restrictor>
                        ) : (
                            restrictedComponent
                        )
                    }
                >
                    {/* recursive call */}
                    {nestedScreens ? (
                        renderRoutes(
                            nestedScreens,
                            undefined,
                            checkPermissionForParam
                        )
                    ) : (
                        <></>
                    )}
                </Route>
            );
        }),
    ];
};
