import { AvatarItem } from "components/FormFields";
import { AvatarInfo } from "models/general";
import React from "react";
import styles from "./ChatAvatars.module.scss";
interface ChatAvatarsProps {
    channelInfos: AvatarInfo[];
}
export const ChatAvatars = React.memo<ChatAvatarsProps>(({ channelInfos }) => {
    return (
        <div className={styles.root}>
            {channelInfos.map((item, index) => (
                <div
                    key={`${index}-avatar`}
                    className={styles.avatarRoot}
                    style={{ zIndex: index }}
                >
                    <AvatarItem value={item} className={styles.avatar} />
                </div>
            ))}
        </div>
    );
});

export default ChatAvatars;
