import { ISO8601_DATE_FORMAT } from "globals/constants";
import {
    BusinessEmployee,
    EmployeeBasicInfo,
    EmployeeIpadInfo,
} from "models/employee/model";
import { EmployeesListRequest } from "models/employee/request";
import {
    BusinessEmployeeResponse,
    EmployeeListItemResponse,
} from "models/employee/response";
import { AppResponse } from "models/general/response";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import { GenerateAccountingExcelRequest, IFile, serializeDateRangeRequest } from "models/general";

export interface IBusinessEmployeeService {
    generateUniqueIpadCode(Id: number): Promise<AppResponse<EmployeeIpadInfo>>;
    delete(encodedEmployeeId: string): Promise<AppResponse<number>>;
    getBasicInfo(): Promise<AppResponse<EmployeeBasicInfo>>;
    createUpdate(
        req: BusinessEmployee
    ): Promise<AppResponse<BusinessEmployeeResponse>>;
    generateAccountingExcel(
        reqParams?: GenerateAccountingExcelRequest
    ): Promise<AppResponse<IFile>>;
    getEmployeesList(
        reqParams?: EmployeesListRequest
    ): Promise<AppResponse<EmployeeListItemResponse[]>>;
}

const apiLinkProvider = routesForContext().business.employees().api;
type ApiLinkProviderType = typeof apiLinkProvider;

export function getBusinessEmployeeServiceKey(
    name: keyof IBusinessEmployeeService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `employee_${name}_${JSON.stringify(data)}`;
}
export class BusinessEmployeeService
    extends BaseService
    implements IBusinessEmployeeService {
    public baseRoute: ApiLinkProviderType;
    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.baseRoute = apiLinkProvider;
    }
    getEmployeesList(
        reqParams?: EmployeesListRequest
    ): Promise<AppResponse<EmployeeListItemResponse[]>> {
        return this.doServerXHR<AppResponse<EmployeeListItemResponse[]>>({
            url: this.baseRoute.getEmployeesList(reqParams),
            method: "get",
        });
    }
    generateAccountingExcel(
        req: GenerateAccountingExcelRequest
    ): Promise<AppResponse<IFile>> {
        return this.doServerXHR<IFile>({
            url: this.baseRoute.generateAccountingExcel(),
            method: "post",
            data: {
                ...req,
                ...serializeDateRangeRequest(req),
            },
        });
    }
    getBasicInfo(): Promise<AppResponse<EmployeeBasicInfo>> {
        return this.doServerXHR<AppResponse<EmployeeBasicInfo>>({
            url: this.baseRoute.withId().basicInfo(),
            method: "get",
        });
    }
    createUpdate(
        req: BusinessEmployee
    ): Promise<AppResponse<BusinessEmployeeResponse>> {
        return this.doServerXHR<AppResponse<EmployeeBasicInfo>>({
            url: this.baseRoute.addUpdate(),
            method: "post",
            data: {
                ...req,
                DateOfBirth: req.DateOfBirth
                    ? req.DateOfBirth.format(ISO8601_DATE_FORMAT)
                    : null,
            },
        });
    }
    delete(encodedEmployeeId: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.baseRoute.withId(encodedEmployeeId).deleteEmployee(),
            method: "post",
        });
    }
    generateUniqueIpadCode(): Promise<AppResponse<EmployeeIpadInfo>> {
        return this.doServerXHR<string>({
            url: this.baseRoute.withId().generateUniqueIpadCode(),
            method: "get",
        });
    }
}

export default BusinessEmployeeService;
