import { SimpleOption } from "components/AppSelect/partials";
import { AppLoader, AppOverlayLoader } from "components/Loaders";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import {
    useSessionBusiness,
    useSessionUser,
} from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaults, defaultTo, isNil } from "lodash-es";
import { Business_ShiftPlan } from "models";
import { BaseObject, Optional } from "models/general";
import {
    BusinessShiftPlanData,
    getShiftPlanMappingInfo,
    parseBusinessShiftPlanData,
    ShiftPlanRequest,
} from "models/shiftPlan";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import {
    ShiftPlanEmployeesInfoSection,
    ShiftPlanTable,
} from "screens/business/shiftPlan/partials";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import ShiftPlanHeaderContent from "screens/business/shiftPlan/partials/ShiftPlanHeaderContent";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
    getShiftPlanServiceKey,
    ShiftPlanService,
} from "services/business";
import styles from "./ShiftPlanMain.module.scss";
import ShiftPlanFilterHeader from "screens/business/shiftPlan/partials/ShiftPlanFilterHeader";
import {
    getMillisecondsForMinutes,
    loadFromStorage,
    saveInStorage,
} from "globals/helpers/generalHelper";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import useLocaleHelpers from "hooks/general/localeHelpers";
import AppButton from "components/Buttons/AppButton";

interface ShiftPlanMainState {
    request: ShiftPlanRequest;
    data: Optional<BusinessShiftPlanData>;
    isEditView: boolean;
    isLoading: boolean;
    initialLoad: boolean; // to stop week change based on locale after first load
}
const dummyData = {
    Employees: [],
    Shifts: [],
    Groups: [],
    ShiftAssignments: [],
};

export const ShiftPlanMain: React.FC<BaseObject> = () => {
    const { pathname } = useLocation();
    const { linkProvider } = useRouting();
    const { appLocale } = useLocaleHelpers();
    const { t } = useTranslation();
    const { height: fullContainerHeight, ref } = useResizeDetector();
    const { id: userId } = useSessionUser();
    const { encodedId: encodedBusinessId } = useSessionBusiness();
    const { getPermissionMap } = useCheckPermission();
    const storageKey = `${encodedBusinessId}_${userId}_${appLocale}_shiftPlanFilters`;

    const savedFilters = loadFromStorage(storageKey);
    const [state, setState] = useState<ShiftPlanMainState>({
        request: {
            ...(savedFilters
                ? {
                      ...savedFilters,
                      WeekStart: moment(
                          savedFilters.WeekStart,
                          ISO8601_DATE_FORMAT
                      ),
                      WeekEnd: moment(
                          savedFilters.WeekEnd,
                          ISO8601_DATE_FORMAT
                      ),
                  }
                : {
                      AreaId: 0,
                      WeekStart: moment().startOf("week"),
                      WeekEnd: moment().endOf("week"),
                  }),
            GroupByGroups: pathname.includes("/groups"),
            GroupByShift: pathname.includes("/shifts"),
        },
        data: dummyData,
        isEditView: false,
        isLoading: false,
        initialLoad: true,
    });

    const permMap = useMemo(() => {
        return getPermissionMap(Business_ShiftPlan);
    }, [getPermissionMap]);
    const shiftPlanService = new ShiftPlanService(
        linkProvider.business.api.shiftPlan
    );
    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(encodedBusinessId as string)
    );

    const { isLoading: areasLoading, data: areasResponse } = useQuery(
        getCurrentBusinessServiceKey("getBusinessAreas", {
            encodedBusinessId: encodedBusinessId,
        }),
        async () => await currentBusinessService.getBusinessAreas()
    );

    const emptyOption = useMemo(() => {
        return {
            label: t("common.pleaseSelect"),
            value: "",
        } as SimpleOption;
    }, [t]);
    const {
        isLoading: loading,
        data: response,
        refetch: refetchShiftPlan,
    } = useQuery(
        getShiftPlanServiceKey("getWeeklyShiftPlan", {
            encodedBusinessId: encodedBusinessId,
            weekStart: state.request.WeekStart,
            weekEnd: state.request.WeekEnd,
            areaId: state.request.AreaId,
            groupByShift: state.request.GroupByShift,
            groupByGroup: state.request.GroupByGroups,
        }),
        async () => await shiftPlanService.getWeeklyShiftPlan(state.request),
        {
            enabled: false,
            cacheTime: getMillisecondsForMinutes(5),
            onError: () => {
                setState((s) => ({
                    ...s,
                    isLoading: false,
                    data: dummyData,
                }));
            },
        }
    );

    useEffect(() => {
        if (!state.initialLoad) {
            // create new moment object to get correct data for new locale
            const currWeek = moment(
                state.request.WeekStart.format(ISO8601_DATE_FORMAT),
                ISO8601_DATE_FORMAT
            ).add(2, "d");
            setState((o) => ({
                ...o,
                request: {
                    ...o.request,
                    // changing week w.r.t current selected week on locale change
                    WeekStart: currWeek.clone().startOf("week"),
                    WeekEnd: currWeek.clone().endOf("week"),
                },
            }));
        } else {
            setTimeout(
                () => setState((o) => ({ ...o, initialLoad: false })),
                500
            );
        }
    }, [t]);
    useEffect(() => {
        if (state.request.AreaId > 0 || (!areasLoading && areasResponse)) {
            saveInStorage(storageKey, {
                ...state.request,
                WeekStart: state.request.WeekStart.format(ISO8601_DATE_FORMAT),
                WeekEnd: state.request.WeekEnd.format(ISO8601_DATE_FORMAT),
            });
            refetchShiftPlan();
        }
    }, [state.request, areasResponse]);
    useEffect(() => {
        if (
            (pathname.includes("/groups") && !state.request.GroupByGroups) ||
            (!pathname.includes("/groups") && state.request.GroupByGroups) ||
            (pathname.includes("/shifts") && !state.request.GroupByShift) ||
            (!pathname.includes("/shifts") && state.request.GroupByShift)
        ) {
            setState((o) => ({
                ...o,
                request: {
                    ...o.request,
                    GroupByGroups: pathname.includes("/groups"),
                    GroupByShift: pathname.includes("/shifts"),
                },
            }));
        }
    }, [pathname]);
    useEffect(() => {
        if (!loading && response) {
            if (response.Data) {
                setState((o) => ({
                    ...o,
                    data: parseBusinessShiftPlanData(response.Data),
                }));
            } else if (response.Errors) {
                // showUnexpectedErrorToast();
            }
        }
    }, [response, loading]);
    const workingAreas = useMemo(() => {
        if (
            !areasLoading &&
            areasResponse &&
            areasResponse.Data &&
            areasResponse.Data.length > 0
        ) {
            if (state.request.AreaId == 0) {
                setState((o) => ({
                    ...o,
                    request: {
                        ...o.request,
                        AreaId: parseInt(areasResponse.Data[0].Value as string),
                    },
                }));
            }
            return [
                ...areasResponse.Data.map((x) => {
                    return {
                        label: x.Text,
                        value: parseInt(x.Value as string),
                    } as SimpleOption;
                }),
            ];
        }
        return [emptyOption];
    }, [areasResponse, areasLoading]);

    const mappingInfo = getShiftPlanMappingInfo(
        state.data,
        state.request.GroupByGroups,
        state.request.GroupByShift
    );
    const onLoadChange = (val: boolean) => {
        setState((o) => ({ ...o, isLoading: val }));
    };

    return (
        <div className={styles.root} ref={ref}>
            <ShiftPlanHeaderContent
                permMap={permMap}
                request={state.request}
                onChange={() => refetchShiftPlan()}
                isEditView={state.isEditView}
                disabled={loading || areasLoading}
                onEdit={(val: boolean) => {
                    setState((o) => ({ ...o, isEditView: val }));
                }}
                onLoadChange={onLoadChange}
            />
            <div className={styles.contentRoot}>
                {isNil(state.data) ? (
                    <AppLoader />
                ) : (
                    <>
                        {(loading || state.isLoading) && <AppOverlayLoader />}
                        <div className={styles.filterRow}>
                            <ShiftPlanFilterHeader
                                value={state.request}
                                onChange={(req) => {
                                    setState((o) => ({
                                        ...o,
                                        request: {
                                            ...o.request,
                                            ...req,
                                        },
                                    }));
                                }}
                                areas={workingAreas}
                                disabled={loading || areasLoading}
                            />
                            <AppButton
                                rounded={true}
                                variant={"primary"}
                                className={styles.currWeek}
                                disabled={loading || areasLoading}
                                onClick={() => {
                                    setState((o) => ({
                                        ...o,
                                        request: {
                                            ...o.request,
                                            WeekStart: moment().startOf("week"),
                                            WeekEnd: moment().endOf("week"),
                                        },
                                    }));
                                }}
                            >
                                {t("shiftPlan.currentWeek")}
                            </AppButton>
                        </div>
                        <div className={styles.content}>
                            <ShiftPlanEmployeesInfoSection
                                data={state.data}
                                mapping={mappingInfo}
                            />
                            <ShiftPlanTable
                                data={state.data}
                                request={state.request}
                                mappingInfo={mappingInfo}
                                containerHeight={fullContainerHeight}
                                onChange={() => refetchShiftPlan()}
                                permMap={permMap}
                                isEditView={state.isEditView}
                                onLoadChange={onLoadChange}
                                disabled={loading || areasLoading}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ShiftPlanMain;
