import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { defaultTo, isNil } from "lodash-es";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import {
    CompensationTemplateFieldType,
    LWCompensationTemplateFieldCategory,
} from "./enum";
import {
    CompensationTemplate,
    CompensationTemplateField,
    CompensationTemplateFieldValue,
    CompensationTemplateOptionField,
    LillywaitCompensationTemplateMainField,
    LillywaitCompensationTemplateModulePriceField,
} from "./model";

export function validateLwCompensationField(
    field: LillywaitCompensationTemplateMainField,
    state = getInitializedValidityState([], []),
    overlap: boolean
) {
    const valueValidationRules: ValidationRules<
        keyof CompensationTemplateFieldValue
    > = {
        Value: [
            {
                rule: Validations.REQUIRED,
                message: t("compensationTemplate.value.missing"),
            },
            {
                rule: Validations.NUMBER,
                message: t("compensationTemplate.value.invalid"),
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        defaultTo(field.FieldValue, {}),
        state
    );
    let rules: ValidationRules<keyof LillywaitCompensationTemplateMainField> = {
        AppliesTo: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };
    if (field.Category == LWCompensationTemplateFieldCategory.Range) {
        rules = {
            ...rules,
            Name: [
                {
                    rule: Validations.REQUIRED,
                    message: t("templates.name.missing"),
                },
            ],
            RangeStart: [
                {
                    rule: Validations.REQUIRED,
                    message: t("compensationTemplate.rangeStart.missing"),
                },
                {
                    rule: Validations.NUMBER,
                    message: t("compensationTemplate.rangeStart.invalid"),
                },
                {
                    rule: Validations.GREATER_THAN,
                    options: { value: 1 },
                    message: t("common.errorMessages.positiveNum"),
                },
                {
                    rule: Validations.LESSER_THAN,
                    options: { value: field.RangeEnd },
                },
            ],
            RangeEnd: [
                {
                    rule: Validations.OPTIONAL_NUMBER,
                    message: t("compensationTemplate.rangeEnd.invalid"),
                },
                {
                    rule: Validations.GREATER_THAN,
                    options: { value: field.RangeStart },
                },
            ],
        };
    } else if (field.Category == LWCompensationTemplateFieldCategory.Slab) {
        rules = {
            ...rules,
            MinRate: [
                {
                    rule: Validations.REQUIRED,
                    message: t("compensationTemplate.minRate.missing"),
                },
                {
                    rule: Validations.NUMBER,
                    message: t("compensationTemplate.minRate.invalid"),
                },
                {
                    rule: Validations.GREATER_THAN,
                    options: { value: 0 },
                    message: t("common.errorMessages.positiveNum"),
                },
                {
                    rule: Validations.LESSER_THAN,
                    options: { value: field.MaxRate },
                },
            ],
            MaxRate: [
                {
                    rule: Validations.REQUIRED,
                    message: t("compensationTemplate.maxRate.missing"),
                },
                {
                    rule: Validations.NUMBER,
                    message: t("compensationTemplate.maxRate.invalid"),
                },
                {
                    rule: Validations.GREATER_THAN,
                    options: { value: Math.max(1, field.MinRate as number) },
                },
            ],
        };
    }

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );

    if (overlap) {
        let manager = new ValidityStateManager(state);
        manager = manager.addErrorInfo("RangeStart", {
            code: "",
            message: t("compensationTemplate.variablePaymentOverlap"),
        });
        state = manager.state;
    }

    return state;
}

export function validateLwCompensationModulePriceField(
    field: LillywaitCompensationTemplateModulePriceField,
    state = getInitializedValidityState([], [])
) {
    const valueValidationRules: ValidationRules<
        keyof CompensationTemplateFieldValue
    > = {
        Value: [
            {
                rule: Validations.OPTIONAL_NUMBER,
                message: t("compensationTemplate.value.invalid"),
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 0 },
                message: t("common.errorMessages.positiveNum"),
            },
        ],
    };
    state = getInitializedValidityStateFromRules(
        valueValidationRules,
        defaultTo(field.FieldValue, {}),
        state
    );
    const rules: ValidationRules<
        keyof LillywaitCompensationTemplateModulePriceField
    > = {
        LocationModuleId: [
            {
                rule: Validations.REQUIRED,
                message: t("compensationTemplate.locationModuleId.missing"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        rules,
        defaultTo(field, {}),
        state
    );
    return state;
}

export function validateCompensationField(
    field: CompensationTemplateField,
    state = getInitializedValidityState([], []),
    canEditValue: boolean,
    isLocation: boolean,
    isLillywaitCompensationOverlap: boolean
) {
    if (field.Type == CompensationTemplateFieldType.LillywaitCompensation) {
        state = validateLwCompensationField(
            field,
            state,
            isLillywaitCompensationOverlap
        );
    } else if (field.Type == CompensationTemplateFieldType.LillywaitModule) {
        state = validateLwCompensationModulePriceField(field, state);
    } else {
        const valueValidationRules: ValidationRules<
            keyof CompensationTemplateFieldValue
        > = {
            Value: [
                CompensationTemplateFieldType.CareScope,
                CompensationTemplateFieldType.Surcharge,
            ].includes(field.Type)
                ? [
                      {
                          rule: Validations.OPTIONAL_NUMBER,
                          message: t("compensationTemplate.value.invalid"),
                      },
                  ]
                : [
                      {
                          rule: Validations.REQUIRED,
                          message: t("compensationTemplate.value.missing"),
                      },
                      {
                          rule: Validations.NUMBER,
                          message: t("compensationTemplate.value.invalid"),
                      },
                  ],
        };

        const optionFieldValidationRules: ValidationRules<
            keyof CompensationTemplateOptionField
        > = {
            Name: [
                {
                    rule: Validations.REQUIRED,
                    message: t("templates.name.missing"),
                },
            ],
            ShortName: [
                {
                    rule: Validations.REQUIRED,
                    message: t("templates.shortName.missing"),
                },
                {
                    rule: Validations.MAX_LENGTH,
                    options: {
                        value: 30,
                    },
                },
            ],
        };
        if (field.Type == CompensationTemplateFieldType.Option) {
            state = getInitializedValidityStateFromRules(
                optionFieldValidationRules,
                field,
                state
            );
        }

        if (
            (field.Type == CompensationTemplateFieldType.Simple &&
                !canEditValue) ||
            field.Type != CompensationTemplateFieldType.Simple
        ) {
            // Care Scope fields will also be auto validated here

            state = getInitializedValidityStateFromRules(
                valueValidationRules,
                defaultTo(field.FieldValue, {}),
                state
            );
        }
    }
    return state;
}

export function validateCompensationTemplate(
    template: CompensationTemplate,
    isLocation: boolean = false,
    state = getInitializedValidityState([], [])
) {
    const internalCompensationValidationRules: ValidationRules<
        keyof CompensationTemplate
    > = {
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateName.missing"),
            },
        ],
        ShortName: [
            {
                rule: Validations.REQUIRED,
                message: t("templates.templateShortName.missing"),
            },
            {
                rule: Validations.MAX_LENGTH,
                options: {
                    value: 30,
                },
            },
        ],
        FreeMonths: isLocation
            ? [
                  {
                      rule: Validations.OPTIONAL_NUMBER,
                  },
                  {
                      rule: Validations.GREATER_THAN,
                      options: { value: 0 },
                      message: t("common.errorMessages.positiveNum"),
                  },
              ]
            : [],
        ForBusiness: isLocation
            ? [
                  {
                      rule: Validations.REQUIRED,
                      message: t("compensationTemplate.forBusiness.missing"),
                  },
              ]
            : [],
        AppliesToLocationSettingId: isLocation
            ? [
                  {
                      rule: Validations.REQUIRED,
                      message: t("compensationTemplate.forLocation.missing"),
                  },
              ]
            : [],
    };

    state = getInitializedValidityStateFromRules(
        internalCompensationValidationRules,
        template,
        state
    );

    let overlapFields: LillywaitCompensationTemplateMainField[] = [];
    if (
        template.Fields.filter(
            (x) =>
                x.Type == CompensationTemplateFieldType.LillywaitCompensation &&
                (x as LillywaitCompensationTemplateMainField).Category ==
                    LWCompensationTemplateFieldCategory.Range
        ).length > 0
    ) {
        const moduleFields = template.Fields.filter(
            (x) =>
                x.Type == CompensationTemplateFieldType.LillywaitCompensation &&
                (x as LillywaitCompensationTemplateMainField).Category ==
                    LWCompensationTemplateFieldCategory.Range
        ).map((x) => x as LillywaitCompensationTemplateMainField);

        overlapFields = moduleFields.filter(
            (value) =>
                moduleFields.filter(
                    (x) =>
                        x.AppliesTo == value.AppliesTo &&
                        x.Uuid !== value.Uuid &&
                        ((isNil(x.RangeEnd) &&
                            !isNil(value.RangeEnd) &&
                            value.RangeEnd >= x.RangeStart) ||
                            (!isNil(x.RangeEnd) &&
                                !isNil(value.RangeEnd) &&
                                x.RangeStart <= value.RangeEnd &&
                                x.RangeEnd >= value.RangeStart) ||
                            (isNil(value.RangeEnd) && isNil(x.RangeEnd)))
                ).length > 0
        );
    }

    let manager = new ValidityStateManager(state);
    template.Fields.forEach((field) => {
        manager = manager.replaceFieldState(field.Uuid, {
            errors: [],
            identifier: field.Uuid,
            children: validateCompensationField(
                field,
                manager.getFieldState(field.Uuid)?.children,
                template.CanEditValue,
                isLocation,
                overlapFields.length > 0 &&
                    overlapFields.filter((x) => x.Uuid == field.Uuid).length > 0
            ),
        });
    });

    return manager.state;
}
