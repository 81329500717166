import { ISO8601_DATE_FORMAT } from "globals/constants";
import { CompensationTemplateType } from "models/compensationTemplate";
import { KindergartenClientContractType, ClientContractType } from "./enum";
import moment from "moment";
import {
    ClientContractColumnTitle,
    ClientContractsTableColumnKeys,
    KindergartenClientContractDetailBase,
    KindergartenClientContractsList,
    KindergartenClientContractsListBase,
} from "./model";
import { defaultTo } from "lodash-es";

export interface KindergartenClientContractDetailResponse
    extends KindergartenClientContractDetailBase {
    MonthYear: string;
}
export interface KindergartenClientContractsListResponse
    extends KindergartenClientContractsListBase {
    MonthlyContracts: KindergartenClientContractDetailResponse[];
}

const columnsOrderMap = (columns: {
    [key in string]: ClientContractColumnTitle;
}) => {
    const keys = Object.keys(columns);
    const getColKey = (type: KindergartenClientContractType) =>
        keys.find((k) => columns[k].Type == type);

    return {
        columnsCount:
            6 + // Month+Total+Voucher+ManualInput+Empty+copy
            keys.length * 2 - // 2 column for each with dropdown
            keys.filter(
                (k) => columns[k].Type == CompensationTemplateType.Simple
            ).length, // 1 column for simple templates
        mealGroup: getColKey(ClientContractType.MealGroup),
        salaryGroup: getColKey(CompensationTemplateType.SalaryGroup),
        careScope: getColKey(CompensationTemplateType.CareScope),
        surCharges: keys
            .filter(
                (k) => columns[k].Type == CompensationTemplateType.Surcharge
            )
            .sort(
                (a, b) =>
                    defaultTo(columns[a].Order, 0) -
                    defaultTo(columns[b].Order, 0)
            ),
        multiOption: keys.filter(
            (k) => columns[k].Type == CompensationTemplateType.MultiOption
        ),
        singleValue: keys.filter(
            (k) => columns[k].Type == CompensationTemplateType.Simple
        ),
    } as ClientContractsTableColumnKeys;
};

export const parseKindergartenClientContractListResponse = (
    response: KindergartenClientContractsListResponse
): KindergartenClientContractsList => {
    return {
        ...response,
        ColumnsUIInfo: columnsOrderMap(response.Columns),
        MonthlyContracts: response.MonthlyContracts.map((c) => ({
            ...c,
            MonthYear: moment(c.MonthYear, ISO8601_DATE_FORMAT),
        })),
    };
};
