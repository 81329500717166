import {
    AppTable,
    AppTableSkeleton,
    AppTableSortColumnIcons,
} from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import {
    showSweetAlertInfo,
    showSweetAlertToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import { SortOrder } from "models/general";
import {
    getHqRegisterRequestFromResponse,
    HqRegisterRequestSortColumn,
} from "models/registrationRequests";
import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation } from "react-query";
import { LillywaitRegistrationRequestsService } from "services/lillywait/LillywaitRegistrationRequestsService";
import { HqRegisterRequestState } from "../HqRegisterRequests";
import styles from "./HqRegisterRequestTable.module.scss";
import HqRegisterRequestTableRow from "./HqRegisterRequestTableRow";

export interface HqRegisterRequestTableProps {
    loading: boolean;
    value: HqRegisterRequestState;
    onChange: (value: HqRegisterRequestState) => void;
}

const COLUMNS_COUNT = 10;
export const HqRegisterRequestTable: React.FC<HqRegisterRequestTableProps> = ({
    loading,
    value,
    onChange,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();

    const registrationRequestsService =
        new LillywaitRegistrationRequestsService(
            linkProvider.lillywait.api.registerRequest
        );

    const { mutate: deleteRequest } = useMutation(
        async (id: number) => await registrationRequestsService.delete(id),
        {
            onSuccess: (res) => {
                onChange({
                    ...value,
                    data: value.data.filter((x) => x.Id != res.Data),
                });

                showSweetAlertInfo(
                    t("common.success"),
                    t("common.delete.success"),
                    "success"
                );
            },
        }
    );

    const { mutate: updateStatus } = useMutation(
        async ({ id, status }: { id: number; status: string }) =>
            await registrationRequestsService.updateStatus(id, status),
        {
            onSuccess: (res) => {
                onChange({
                    ...value,
                    data: value.data.map((x) => {
                        if (x.Id == res.Data.Id) {
                            return getHqRegisterRequestFromResponse(res.Data);
                        } else {
                            return x;
                        }
                    }),
                });

                showSweetAlertToast(
                    t("common.success"),
                    t("hqRegisterRequestManagement.statusUpdated"),
                    "success"
                );
            },
        }
    );

    const onSortChange = (
        sortColumn?: HqRegisterRequestSortColumn,
        sortOrder?: SortOrder
    ) => {
        onChange({
            ...value,
            filter: {
                ...value.filter,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
                PageNumber: 1,
            },
        });
    };

    return (
        <div>
            <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={value.data && value.data.length}
                next={() => {
                    onChange({
                        ...value,
                        filter: {
                            ...value.filter,
                            PageNumber:
                                defaultTo(value.filter.PageNumber, 1) + 1,
                        },
                    });
                }}
                loader={<></>}
                hasMore={defaultTo(value.meta.HasNextPage, false)}
                scrollableTarget="scrollableDiv"
            >
                <AppTable
                    id={"scrollableDiv"}
                    heightToAdjust={185}
                    mobileViewAdjustment={45}
                    stickyHeader={true}
                    mediumViewAdjustment={45}
                    containerClass={`${styles.tableOuter}`}
                    tableClass={`${styles.table} table-striped`}
                >
                    <thead>
                        <tr>
                            <AppTableSortColumnIcons
                                style={{
                                    ...getFixedCssWidths(200),
                                    paddingLeft: "28px",
                                }}
                                text={t(
                                    "hqRegisterRequestManagement.firstName"
                                )}
                                className={styles.displayId}
                                sortColumn={
                                    HqRegisterRequestSortColumn.FirstName
                                }
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.FirstName
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("hqRegisterRequestManagement.lastName")}
                                className={styles.displayId}
                                sortColumn={
                                    HqRegisterRequestSortColumn.LastName
                                }
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.LastName
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(220)}
                                text={t(
                                    "hqRegisterRequestManagement.facilityName"
                                )}
                                className={styles.displayId}
                                sortColumn={
                                    HqRegisterRequestSortColumn.FacilityName
                                }
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.FacilityName
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(300)}
                                text={t("hqRegisterRequestManagement.email")}
                                className={styles.displayId}
                                sortColumn={HqRegisterRequestSortColumn.Email}
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.Email
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={getFixedCssWidths(200)}
                                text={t("hqRegisterRequestManagement.phone")}
                                className={styles.displayId}
                                sortColumn={HqRegisterRequestSortColumn.Phone}
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.Phone
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={{
                                    ...getFixedCssWidths(170),
                                    textAlign: "center",
                                }}
                                text={t("hqRegisterRequestManagement.status")}
                                className={styles.displayId}
                                sortColumn={HqRegisterRequestSortColumn.Status}
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.Status
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <AppTableSortColumnIcons
                                style={{
                                    ...getFixedCssWidths(190),
                                    textAlign: "center",
                                }}
                                text={t(
                                    "hqRegisterRequestManagement.createdAt"
                                )}
                                className={styles.displayId}
                                sortColumn={
                                    HqRegisterRequestSortColumn.CreatedAt
                                }
                                onClick={(key, order) =>
                                    onSortChange(key, order)
                                }
                                sortOrder={
                                    value.filter.SortColumn ==
                                    HqRegisterRequestSortColumn.CreatedAt
                                        ? value.filter.SortOrder
                                        : null
                                }
                            />
                            <th style={getFixedCssWidths(310)} />
                            <th style={getFixedCssWidths(70)} />
                            <th style={getFixedCssWidths(70)} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                        {value.data &&
                            value.data.length > 0 &&
                            value.data.map((x, index) => (
                                <HqRegisterRequestTableRow
                                    key={index}
                                    value={x}
                                    onDelete={deleteRequest}
                                    onStatusChange={(id, status) =>
                                        updateStatus({ id, status })
                                    }
                                />
                            ))}
                        {loading && (
                            <AppTableSkeleton
                                columns={COLUMNS_COUNT}
                                rows={
                                    value.data && value.data.length > 0 ? 2 : 12
                                }
                            />
                        )}
                        {value.data && value.data.length == 0 && !loading && (
                            <tr>
                                <td
                                    colSpan={COLUMNS_COUNT}
                                    style={{ textAlign: "center" }}
                                >
                                    {t("common.noDataFound")}
                                </td>
                            </tr>
                        )}
                        <tr className="dummy-row">
                            <td colSpan={COLUMNS_COUNT} />
                        </tr>
                    </tbody>
                </AppTable>
            </InfiniteScroll>
        </div>
    );
};

export default HqRegisterRequestTable;
