export enum CompensationTemplateType {
    Simple = "Simple",
    MultiOption = "MultiOption",
    CareScope = "CareScope",
    Surcharge = "Surcharge",
    SalaryGroup = "SalaryGroup",
    LillywaitCompensation = "LillywaitCompensation",
}

export enum CompensationTemplateFieldType {
    Simple = "Simple",
    Option = "Option",
    CareScope = "CareScope",
    Surcharge = "Surcharge",
    SalaryGroup = "SalaryGroup",
    LillywaitCompensation = "LillywaitCompensation",
    LillywaitModule = "LillywaitModule",
}

export enum LWCompensationTemplateFieldCategory {
    Range = "Range",
    Slab = "Slab",
}

export enum LwCompensationTemplateAppliesTo {
    Client = "Client",
    Employee = "Employee",
}
