import React from "react";
import Skeleton from "react-loading-skeleton";
import itemStyles from "./MessageItemSkeleton.module.scss";

export interface MessageItemSkeletonProps {
    count: number;
}

export const MessageItemSkeleton: React.FC<MessageItemSkeletonProps> = ({
    count,
}) => {
    return (
        <>
            {Array(count)
                .fill(count)
                .map((e, i) => (
                    <React.Fragment key={i}>
                        <div className={itemStyles.itemOuter}>
                            <div>
                                <Skeleton height={20} width={20} />
                            </div>
                            <div className={itemStyles.box}>
                                {/* Star */}
                                <Skeleton height={17} width={17} />
                            </div>
                            <div
                                className={itemStyles.circleImage}
                                style={{ margin: "15px", marginLeft: "0px" }}
                            >
                                <Skeleton
                                    circle={true}
                                    height={50}
                                    width={50}
                                />
                            </div>
                            <div className={itemStyles.textDiv}>
                                <Skeleton height={14} width={200} />
                                <Skeleton
                                    height={10}
                                    style={{ marginTop: "10px" }}
                                />
                            </div>
                            <span className={itemStyles.date}>
                                <Skeleton height={10} width={80} />
                            </span>
                        </div>
                        <div className="nav-item-divider nav-item"></div>
                    </React.Fragment>
                ))}
        </>
    );
};

export default MessageItemSkeleton;
