import { getHtmlCoordinates } from "globals/helpers/generalHelper";
import { defaults } from "lodash-es";
import React, { ReactNode, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import styles from "./AppTabs.module.scss";
export interface AppTab {
    key?: string;
    title: string | ReactNode;
    onButtonClick: () => void;
    isActive: boolean;
    content: ReactNode;
}
export interface AppTabsProps {
    tabs: AppTab[];
    animated?: boolean;
    className?: string;
    classes?: {
        active?: string;
        button?: string;
        tabButtonsContainer?: string;
        contentContainer?: string;
    };
}
const Component: React.FC<AppTabsProps> = ({
    tabs,
    animated = true,
    className = "",
    classes,
}) => {
    classes = defaults(classes, {
        active: "",
        button: "",
        buttonsContainer: "",
        tabsContainer: "",
    });

    const [transitionState, setTransitionState] = useState<{
        activeTabBtnStart: number;
        tabChanged: boolean;
    }>({ activeTabBtnStart: 0, tabChanged: false });

    const [activeBtn, setActiveBtn] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (activeBtn && animated) {
            const btn = getHtmlCoordinates(activeBtn);
            const posChanged =
                btn != null && btn.left !== transitionState.activeTabBtnStart;
            const tabSwitch = animated && !transitionState.tabChanged;

            const newState = { ...transitionState };

            if (posChanged || tabSwitch) {
                if (posChanged) {
                    newState.activeTabBtnStart = btn.left;
                }
                if (tabSwitch) {
                    newState.tabChanged = true;
                }
                setTimeout(
                    () =>
                        setTransitionState((s) => ({
                            ...s,
                            tabChanged: false,
                        })),
                    800
                );
            }

            setTransitionState(newState);
        }
    }, [activeBtn, animated]);

    return (
        <div className={`${styles.root} ${className}`}>
            <div
                className={`${styles.buttonsContainer} ${
                    classes.tabButtonsContainer
                } ${animated && styles.animated}`}
            >
                {animated && (
                    <>
                        <div
                            className={`${styles.tabBar} ${styles.initialTabsBar}`}
                        />
                        <div
                            className={`${styles.tabBar} ${styles.activeSelector} ${classes.active} `}
                            style={{
                                left: transitionState.activeTabBtnStart,
                                width: activeBtn
                                    ? `${activeBtn.offsetWidth}px`
                                    : `${100 * (1 / tabs.length)}%`,
                            }}
                        />
                    </>
                )}
                {tabs.map((tabBtn: AppTab) => (
                    <Button
                        ref={(ref: HTMLButtonElement) => {
                            if (tabBtn.isActive === true && ref) {
                                setActiveBtn(ref);
                            }
                        }}
                        style={{
                            width: `${100 * (1 / tabs.length)}%`,
                        }}
                        onClick={tabBtn.onButtonClick}
                        key={tabBtn.key ? tabBtn.key : uuid()}
                        className={`${styles.tabButton} ${classes?.button} ${
                            tabBtn.isActive
                                ? `${classes?.active} ${styles.active}`
                                : ""
                        }`}
                    >
                        {tabBtn.title}
                    </Button>
                ))}
            </div>
            <div
                className={`${styles.tabsContainer} ${
                    classes.contentContainer
                } ${animated && styles.animated}`}
            >
                <div
                    className={`${styles.tabWrapper} ${
                        animated && transitionState.tabChanged
                            ? styles.tabSwitching
                            : ""
                    }`}
                >
                    {tabs.find((x) => x.isActive) &&
                        tabs.find((x) => x.isActive)?.content}
                </div>
            </div>
        </div>
    );
};

Component.displayName = "AppTabs";
export const AppTabs = React.memo(Component);
export default AppTabs;
