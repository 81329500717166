import { useEffect, useMemo, useState } from "react";
import { isNil } from "lodash-es";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useTranslation } from "react-i18next";
import { useRouting } from "hooks/general/routing";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import { useMutation } from "react-query";
import { useCurrentBusinessSettingsContext } from "screens/business/hqAndBranches/partials/BusinessTabs";
import { AppContainer } from "components/Containers";
import { useBusinessCheckPermission } from "hooks/permissionCheck";
import {
    BusinessType,
    Business_BasicData,
    LW_ElderlyCare_BasicData,
    LW_Industry_BasicData,
    LW_Kindergarten_BasicData,
    LW_School_BasicData,
} from "models";
import { Col, Form, Image, Row } from "react-bootstrap";
import { AppButton } from "components/Buttons";
import { AppInputField } from "components/FormFields";
import { useParams } from "react-router";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { AppTooltip } from "components";
import classNames from "classnames";
import { ImageAssets } from "globals/images";
import { copyToClipboard } from "globals/helpers/generalHelper";

export const BusinessExternalIntegration: React.FC = () => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const [state, setState] = useState<string | undefined>(undefined);
    const { dataItem: currentBusiness, refetchData } =
        useCurrentBusinessSettingsContext();

    const { lwcId } = useParams();
    const isLwc = !isNil(lwcId);

    const { getPermissionMap } = useBusinessCheckPermission();

    const { id: sessionBusinessId, hqId: sessionHqId } = useSessionBusiness();

    const businessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness(lwcId)
    );

    useEffect(() => {
        if (currentBusiness && currentBusiness.ExternalAccessToken != state) {
            setState(currentBusiness.ExternalAccessToken);
        }
    }, [currentBusiness]);

    const {
        isLoading: updating,
        data: updateResponse,
        mutate: refreshToken,
    } = useMutation(
        getCurrentBusinessServiceKey("refreshExternalToken"),
        async () => businessService.refreshExternalToken()
    );
    const readonly = useMemo(() => {
        const permMap = !isLwc
            ? getPermissionMap(
                  currentBusiness?.Id as number,
                  currentBusiness?.HqId as number,
                  Business_BasicData
              )
            : getPermissionMap(
                  sessionBusinessId,
                  sessionHqId,
                  currentBusiness?.Type == BusinessType.KINDERGARTEN
                      ? LW_Kindergarten_BasicData
                      : currentBusiness?.Type == BusinessType.INDUSTRY
                      ? LW_Industry_BasicData
                      : currentBusiness?.Type == BusinessType.ELDERLY_CARE
                      ? LW_ElderlyCare_BasicData
                      : LW_School_BasicData
              );
        return !permMap.CREATE && !permMap.EDIT;
    }, [getPermissionMap]);

    useEffect(() => {
        if (!updating && updateResponse) {
            if (updateResponse.Data != null) {
                if (updateResponse.Data) {
                    setState(updateResponse.Data);
                    if (refetchData) {
                        // also trigger refetch so value is updated in state
                        refetchData();
                    }
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("common.successMessage")}`,
                        "success"
                    );
                }
            } else if (updateResponse.Code != null) {
                showSweetAlertToast(
                    t("common.error.error"),
                    `${t("common.error.requestFailed")}`,
                    "error"
                );
            }
        }
    }, [updating, updateResponse]);

    return (
        <AppContainer>
            <Row>
                <Col md={12}>
                    <h2 style={{ fontSize: "1.1rem", fontWeight: 700 }}>
                        {t("business.basicSettings.externalToken.heading")}
                    </h2>
                </Col>
                <Col md={8} lg={6}>
                    <Form.Label
                        style={{
                            color: "#0d1c26",
                            fontSize: "0.9rem",
                        }}
                    >
                        {t("business.basicSettings.externalToken.name")}
                        <AppTooltip
                            content={
                                <span>
                                    {t(
                                        "business.basicSettings.externalToken.infoText"
                                    )}
                                </span>
                            }
                            allowHtml={true}
                            trigger="mouseenter focus"
                            arrow={true}
                            maxWidth="400px"
                        >
                            <Image
                                className={classNames("ml-2")}
                                style={{ width: "16px", height: "16px" }}
                                src={ImageAssets.common.questionCircleBlue}
                            />
                        </AppTooltip>
                    </Form.Label>
                    <AppInputField
                        label={false}
                        value={state}
                        readOnly={true}
                        rightIcon={
                            state ? (
                                <AppTooltip
                                    content={<span>{t("common.copy")}</span>}
                                    allowHtml={true}
                                    trigger="mouseenter focus"
                                    arrow={true}
                                >
                                    <Image
                                        src={ImageAssets.common.copyFilled}
                                        style={{
                                            top: "10px",
                                            width: "20px",
                                            height: "20px",
                                        }}
                                        onClick={() => {
                                            if (state) {
                                                if (copyToClipboard(state)) {
                                                    showSweetAlertToast(
                                                        t("common.copied"),
                                                        t(
                                                            "common.copiedToClipboard"
                                                        ),
                                                        "success"
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </AppTooltip>
                            ) : undefined
                        }
                    />
                </Col>
            </Row>
            <div style={{ marginTop: "10px" }}>
                <AppButton
                    padding="med"
                    disabled={updating || readonly}
                    onClick={() => refreshToken()}
                >
                    {t("business.refreshToken")}
                </AppButton>
            </div>
        </AppContainer>
    );
};

export default BusinessExternalIntegration;
