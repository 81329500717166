export const PlanningReportsEnTranslations = {
    ageGroupPlanning: {
        title: "Age Group Planning",
        active: "Active",
        free: "Free",
        registrationStats: "Registrations Received",
        noRegistrations: "No registrations found for the month.",
        noYearRegistrations: "No registrations found for the year ({{year}}).",
        ageGroupCapacity: "Calculated capacity per Age Group: {{size}}",
        monthlyStats: "Registrations received for month {{month}}",
        yearlyStats: 'Registrations received for year {{year}}',
        kidsCount: 'Kids Count',
        ageGroup: 'Age Group'

    },
    occupancyPlanning: {
        title: "Occupancy Planning",
        table: {
            ageOfChild: "Age of the Child",
            careHours: "Care Hours",
            employeeKey: "Employee Key",
            numberKids: "Kids",
            numberRegistrations: "Registrations",
            additionalKids: "Additional Kids",
            requiredEmployees: "Required Employees",
        },
        totals: {
            total: 'Total:',
            totalEmpNeeded: "Total Pedagogical Employee Needed:",
            totalHoursNeeded: "Total number of hours per week required:",
            totalHoursAvailable: "Total number of hours available per week:",
            differenceHours: "Difference hours per week:",
            freeOrRequiredEmp: "Free or Required Employees:",
            startingContracts: "Starting contracts:",
            endingContracts: "Ending contracts:",
            startingClientContracts: "Starting Childcare Contracts",
            endingClientContracts: "Ending Childcare Contracts",
            startingEmployeeContracts: "Starting Employee Contracts",
            endingEmployeeContracts: "Ending Employee Contracts",
        },
        ageGroup: "Age Group",
        surcharges: "Surcharges",
        startingContracts: "Starting Contracts",
        endingContracts: "Ending Contracts",
        activeKids: "Active Kids",
        newRegistrations: "New Registrations",
    },
};
export default PlanningReportsEnTranslations;
