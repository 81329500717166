import { PersonAbsents } from "models";
import React from "react";
import { AbsentDatesDetailPopover } from "./AbsentDatesDetailPopover";
import { useTranslation } from "react-i18next";
import useLocaleHelpers from "hooks/general/localeHelpers";
import styles from "./AbsentRow.module.scss";
import AppPopover from "components/AppPopover";
import { defaultTo } from "lodash-es";

export interface AbsentRowProps {
    value: PersonAbsents;
    onClick?: () => void;
}

export const AbsentRow: React.FC<AbsentRowProps> = ({ value, onClick }) => {
    const { t } = useTranslation();
    const { formatNumber } = useLocaleHelpers();
    return (
        <tr onClick={onClick}>
            <td style={{ paddingLeft: "10px" }}>{value.DisplayId}</td>
            <td>
                <p className={styles.textEllipse}>{value.Name}</p>
            </td>
            <td>{value.TrueTotalWorkingDays}</td>
            <td>
                <AbsentDatesDetailPopover
                    content={<>{value.TotalSchoolDays}</>}
                    title={t("business.absents.table.schoolDays")}
                    value={value.SchoolDays}
                    hasFullTotal={false}
                />
            </td>
            <td>
                <AbsentDatesDetailPopover
                    content={<>{value.TotalVacationDays}</>}
                    title={t("business.absents.table.vacations")}
                    value={value.VacationDays}
                />
            </td>
            <td>
                <AbsentDatesDetailPopover
                    content={<>{value.TotalSickDays}</>}
                    title={t("business.absents.table.sickDays")}
                    value={value.SickDays}
                />
            </td>
            <td>
                <AbsentDatesDetailPopover
                    content={<>{value.TotalUndeclaredAbsents}</>}
                    title={t("business.absents.table.absenceWithoutReason")}
                    value={value.UndeclaredAbsents}
                    hasFullTotal={false}
                />
            </td>
            <td>{value.TotalAttendances}</td>
            <td>{Math.max(0, defaultTo(value.AbsencesWithoutVacations, 0))}</td>
            <td>
                <AppPopover
                    triggerElem={<>{value.TotalAbsences}</>}
                    placement={"auto"}
                    triggerOnClick={false}
                    containerClass={styles.popupDiv}
                    trigger={"mouseenter focus"}
                >
                    <div className={styles.absenceSummaryPopup}>
                        <div>
                            <span>{t("business.absents.table.sickDays")}:</span>
                            {value.TotalSickDays}
                        </div>
                        <div>
                            <span>
                                {t("business.absents.table.vacations")}:
                            </span>
                            {value.TotalVacationDays}
                        </div>
                        <div>
                            <span>
                                {t(
                                    "business.absents.table.absenceWithoutReason"
                                )}
                                :
                            </span>
                            {value.TotalUndeclaredAbsents}
                        </div>
                        <div>
                            <span>
                                {t("business.absents.table.pregnancyLeaves")}:
                            </span>
                            {value.TotalPregnantSickDays}
                        </div>
                    </div>
                </AppPopover>
            </td>
            <td>{`${formatNumber(value.AbsencePercentage)}%`}</td>
        </tr>
    );
};

export default AbsentRow;
