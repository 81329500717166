import config from "globals/config";
import { BaseObject } from "models/general";
import qs from "qs";
import { generatePath } from "react-router-dom";
import { IRoutingContext } from "routing";

export class BaseRoutingContext implements IRoutingContext {
    getContextIdentifier(): string {
        return "base-context";
    }
    getBaseUrl() {
        return ""; // no need to specify path for react
    }
    getApiBaseUrl() {
        return config.apiBaseUrl
            ? config.apiBaseUrl
            : "https://localhost:44340/web-api";
    }

    buildUrl<T extends BaseObject>(
        url: string,
        forApi: boolean = false,
        params: Record<string, string> | undefined = undefined,
        queryParams: T | undefined = undefined,
        build: boolean = true
    ): string {
        if (!build) {
            return url;
        }

        if (queryParams !== undefined) {
            url = `${url}?${qs.stringify(queryParams, {
                skipNulls: true,
            })}`;
        }

        if (params !== undefined) {
            url = generatePath(url, params);
        }
        if (!url.includes("http")) {
            // add baseUrl at the end because if URL has port this doesn't work
            url = `${forApi ? this.getApiBaseUrl() : this.getBaseUrl()}${
                url.length > 0 ? "/" : ""
            }${url}`;
        }
        return url;
    }
}
export default BaseRoutingContext;
