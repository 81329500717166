import {
    BusinessContractDataResponse,
    BusinessSettingResponse,
} from "models/business/response";
import {
    BusinessChatSettings,
    BusinessContractData,
    BusinessContractDetailItem,
    BusinessDetails,
    BusinessLocationSettingPreferences,
    BusinessSetting,
} from "./model";
import { BusinessType } from "./enum";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import moment from "moment";

export const getBusinessTypePathString = (
    type: BusinessType,
    forPlural: boolean = false
): string => {
    return type === BusinessType.LW
        ? "Lillywait"
        : type === BusinessType.INDUSTRY
        ? forPlural
            ? "Industrie"
            : "Industry"
        : type === BusinessType.ELDERLY_CARE
        ? "Elderly-Care" // as in path we have 'elderly-care'
        : type;
};

export const getBusinessDefaultValue = (): BusinessDetails => {
    return {} as BusinessDetails;
};
export const getDefaultLocationPreferences = (
    bId: number
): BusinessLocationSettingPreferences => {
    return {
        BusinessId: bId,
        CompensationTemplates: true,
        MealGroups: true,
        EmployeeKeyCalculations: true,
        AgeGroup: true,
        ScopeOfCare: true,
        WorkingAreas: true,
        WorkingDays: true,
        Functions: true,
        PublicHolidays: true,
    } as BusinessLocationSettingPreferences;
};
export const getDefaultChatSettings = (bId: number): BusinessChatSettings => {
    return {
        Id: 0,
        BusinessId: bId,
        AllowAllUsersBusinessCareGroup: true,
        AllowWebUsersInAllUsersCareGroupChats: true,
        AllowAllUsersBusinessDefaultGroup: false,
        AllowWebUsersInAllUserFacilityChats: false,
        AllowParentBusinessDefaultGroup: true,
        AllowParentBusinessCareGroup: true,
        AllowParentSingleChatWithCareGroupParents: true,
        AllowParentSingleChatWithCareGroupEmployees: true,
        AllowParentSingleChatWithCompanyEmployees: false,
        AllowParentSingleChatWithCompanyParents: false,
        AllowEmployeeBusinessDefaultGroup: true,
        AllowEmployeeBusinessCareGroup: true,
        AllowEmployeeSingleChatWithCareGroupParents: true,
        AllowEmployeeSingleChatWithCareGroupEmployees: true,
        AllowEmployeeSingleChatWithCompanyEmployees: true,
        AllowEmployeeSingleChatWithCompanyParents: false,
    } as BusinessChatSettings;
};
export const parseBusinessContractItemResponse = (
    resp: BusinessContractDetailItem
): BusinessContractDetailItem => {
    return {
        ...resp,
        MonthYear: moment(resp.MonthYear, ISO8601_DATE_FORMAT),
        VoucherDiscounts: resp.VoucherDiscounts
            ? resp.VoucherDiscounts.map((x) => ({
                  ...x,
                  StartDate: moment(x.StartDate, ISO8601_DATE_FORMAT),
                  EndDate: x.EndDate
                      ? moment(x.EndDate, ISO8601_DATE_FORMAT)
                      : undefined,
              }))
            : [],
        AffiliateDiscount: resp.AffiliateDiscount
            ? {
                  ...resp.AffiliateDiscount,
                  StartDate: moment(
                      resp.AffiliateDiscount.StartDate,
                      ISO8601_DATE_FORMAT
                  ),
                  EndDate: resp.AffiliateDiscount.EndDate
                      ? moment(
                            resp.AffiliateDiscount.EndDate,
                            ISO8601_DATE_FORMAT
                        )
                      : undefined,
              }
            : null,
    };
};
export const parseContractDataResponse = (
    resp: BusinessContractDataResponse
): BusinessContractData => {
    return {
        ...resp,
        StartDate: moment(resp.StartDate, ISO8601_DATE_FORMAT),
        CancelledOn: resp.CancelledOn
            ? moment(resp.CancelledOn, ISO8601_DATE_FORMAT)
            : undefined,
        EndDate: resp.EndDate
            ? moment(resp.EndDate, ISO8601_DATE_FORMAT)
            : undefined,
    };
};
export const parseBusinessSettingResponse = (
    response: BusinessSettingResponse
): BusinessSetting => {
    return {
        ...response,
        AgeGroupDeadline: response.AgeGroupDeadline
            ? moment(response.AgeGroupDeadline, ISO8601_DATE_FORMAT)
            : undefined,
        TimeTrackingStart: response.TimeTrackingStart
            ? moment(response.TimeTrackingStart, ISO8601_DATE_FORMAT)
            : undefined,
        VacationsCalculationStart: response.VacationsCalculationStart
            ? moment(response.VacationsCalculationStart, ISO8601_DATE_FORMAT)
            : undefined,
    };
};
