import { ErrorMessage } from "components";
import { AppGradientButton } from "components/Buttons";
import { AuthInputField } from "components/FormFields";
import ImageAssets from "globals/images/common";
import { useRouting } from "hooks/general/routing";
import { defaultTo } from "lodash-es";
import {
    getResetPasswordDefaultValue,
    ResetPasswordRequest,
    validateResetPassword,
} from "models";
import { AppPlatforms } from "models/general/enum";
import {
    getValidityStateFromApiResponse,
    ValidityStateManager,
} from "models/general/validityState";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { WithAuthLoader } from "screens/auth/partials/AuthLoader";
import { AuthHeader } from "screens/auth/partials/header";
import { AuthService } from "services";
import styles from "./ResetPasswordFields.module.scss";

export interface ResetPasswordFieldsProps extends WithAuthLoader {}

export const ResetPasswordFields: React.FC<ResetPasswordFieldsProps> = ({
    setLoading,
}) => {
    const { linkProvider } = useRouting();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);

    const code = query.get("code");
    const email = query.get("email");
    const platform: AppPlatforms = defaultTo(
        query.get("platform") as AppPlatforms,
        AppPlatforms.WEB
    );

    const authService = new AuthService(linkProvider.noAuth.api);
    const {
        isLoading: loading,
        data: response,
        mutate: reset,
    } = useMutation(
        async (val: ResetPasswordRequest) =>
            await authService.resetPassword(val)
    );

    const redirect = () => {
        navigate(
            linkProvider.noAuth.screens.resetPasswordConformation({
                platform: platform,
            })
        );
    };

    const [state, setState] = useState<ResetPasswordRequest>(
        getResetPasswordDefaultValue(code, email)
    );

    const [showError, setShowError] = useState(false);

    const validityStateManager = useMemo(() => {
        let validityState = validateResetPassword(state);
        if (!showError && response) {
            setShowError(true);
            validityState = getValidityStateFromApiResponse(response);
        }

        return new ValidityStateManager(validityState);
    }, [response, state]);

    const onButtonClick = () => {
        if (validityStateManager.isStateValid()) {
            setShowError(false);
            reset(state);
        } else {
            setShowError(true);
        }
    };

    useEffect(() => {
        setLoading(loading);
        if (!loading && response && response.Data) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, redirect, response]);

    const textKey = `auth.resetPassword.${platform.toLowerCase()}`;
    return (
        <>
            <AuthHeader
                mainHeading={t(`${textKey}.title`)}
                pageTitle={t(`${textKey}.pageTitle`)}
            />
            <div>
                <div className={styles.desc}>
                    {t("auth.resetPassword.desc")}
                </div>
                {/* <ErrorMessage
                    errorInfo={validityStateManager.getFirstErrorInfo(
                        "GeneralError"
                    )}
                /> */}
                <AuthInputField
                    value={state.Email}
                    useBubbleError={true}
                    type="text"
                    disabled={true}
                    onValueChange={(value) =>
                        setState({
                            ...state,
                            Email: value,
                        })
                    }
                    onBlur={(value) =>
                        setState({
                            ...state,
                            Email: value,
                        })
                    }
                    iconPath={ImageAssets.common.envelope}
                    placeholder={t("auth.companyEmail")}
                    error={
                        showError
                            ? validityStateManager.getFirstErrorInfo("Email")
                            : undefined
                    }
                />
                <AuthInputField
                    value={state.Password}
                    showHelp={true}
                    useBubbleError={true}
                    type="password"
                    onValueChange={(value) =>
                        setState({
                            ...state,
                            Password: value,
                        })
                    }
                    onBlur={(value) =>
                        setState({
                            ...state,
                            Password: value,
                        })
                    }
                    iconPath={ImageAssets.common.lockGray}
                    placeholder={t("auth.password")}
                    error={
                        showError
                            ? validityStateManager.getFirstErrorInfo("Password")
                            : undefined
                    }
                />
                <AuthInputField
                    value={state.ConfirmPassword}
                    useBubbleError={true}
                    type="password"
                    onValueChange={(value) =>
                        setState({
                            ...state,
                            ConfirmPassword: value,
                        })
                    }
                    onBlur={(value) =>
                        setState({
                            ...state,
                            ConfirmPassword: value,
                        })
                    }
                    iconPath={ImageAssets.common.lockGray}
                    placeholder={t("auth.confirmPassword")}
                    error={
                        showError
                            ? validityStateManager.getFirstErrorInfo(
                                  "ConfirmPassword"
                              )
                            : undefined
                    }
                />
                <AppGradientButton
                    className={styles.resetButton}
                    disabled={showError && !validityStateManager.isStateValid()}
                    onClick={() => {
                        onButtonClick();
                    }}
                    text={t("auth.resetPassword.name")}
                />
            </div>
        </>
    );
};

export default ResetPasswordFields;
