import { Optional } from "models/general";
import {
    ClientImportColumns,
    ClientRegistrationImportColumns,
    EmployeeImportColumns,
} from "./enum";

export interface FileDataColumn {
    ColumnName: string;
}
export interface ColumnSpec {
    name: string;
    required: boolean;
}

export interface DraggableColItem {
    id: string;
    name: string;
}

export type ClientColumnMapping = {
    [key in ClientImportColumns]: Optional<DraggableColItem>;
};

export type ClientRegistrationColumnMapping = {
    [key in ClientRegistrationImportColumns]: Optional<DraggableColItem>;
};

export type EmployeeColumnMapping = {
    [key in EmployeeImportColumns]: Optional<DraggableColItem>;
};

export type ColumnMapping = ClientColumnMapping &
    ClientRegistrationColumnMapping &
    EmployeeColumnMapping;

const AllCols = {
    ...EmployeeImportColumns,
    ...ClientImportColumns,
    ...ClientRegistrationImportColumns,
} as const;

type CombinedKeys = keyof typeof AllCols;
export type ImportColumns = typeof AllCols[CombinedKeys];

export type ColumnNameMapping = { [key in ImportColumns]: ColumnSpec };
